<div *ngIf="edm.isStaff()" class="row btn-bar">
    <span *ngIf="!customButtons">
        <span *ngIf="!edm.singleEntityOnly">
            <button *ngIf="!edm.editing" type="button" class="btn btn-sm btn-primary" [disabled]="!edm.canAdd()" (click)="edm.onAdd()">Add</button>
            <button *ngIf="!edm.editing" type="button" class="btn btn-sm btn-primary" [disabled]="!edm.hasEdits()" (click)="edm.onSave()">Save</button>
            <button *ngIf="edm.editing" type="button" class="btn btn-sm btn-primary" (click)="edm.onDelete()">Delete</button>
            <button *ngIf="edm.editing" type="button" class="btn btn-sm btn-primary" [disabled]="!edm.canApply()" (click)="edm.onApply()">Apply</button>
            <button *ngIf="edm.canShowBack()" type="button" class="btn btn-sm btn-primary"  (click)="edm.onBack()">Back</button>
            <button *ngIf="!edm.canShowBack()" type="button" class="btn btn-sm btn-primary" [disabled]="!edm.canCancel()" (click)="edm.onCancel()">Cancel</button>
        </span>
    <span *ngIf="edm.singleEntityOnly">
            <button type="button" class="btn btn-sm btn-primary" (click)="edm.onEdit()">{{edm.editing ? 'View' : 'Edit'}}</button>
            <button type="button" class="btn btn-sm btn-primary" [disabled]="!edm.hasEdits()" (click)="edm.onSave()">Save</button>
            <button type="button" class="btn btn-sm btn-primary" [disabled]="!edm.hasEdits()" (click)="edm.onCancel()">Cancel</button>
        </span>
    </span>
    <ng-content select="[customButtons]"></ng-content>

    <span *ngIf="edm.hasEdits()" class="label label-warning">
        You cannot leave this tab until you either <b>Save</b>, <b>Apply</b> or <b>Cancel</b>
    </span>

    <span *ngIf="edm.status" [ngClass]="edm.status.classes">{{edm.status.message}}</span>
</div>

<div *ngIf="edm.hasErrors()">
    <div class="row" *ngFor="let errorMessage of edm.errorMessages">
        <div class="col-md-10 alert-danger">{{ errorMessage }}</div>
    </div>
</div>

<div>
    <page-status [attr.data-hidden]='edm.editing' [pageState]="edm.pageState"></page-status>
</div>

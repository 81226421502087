<div class="indent-top-sm"></div>
<editor-header [edm]="_edm" [femaRestricted]="true"></editor-header>

<i *ngIf="_isLoading" class="fa fa-circle-o-notch fa-spin" style="font-size:38px; color: #337ab7; margin-left: 24px;"></i>

<p class="alert-warning bold">{{_validationMessage}}</p>

<div *ngIf="_registeredFlavors && (_registeredFlavors.length > 0)" [attr.data-hidden]="_edm.editing" style="width: 40%">
	<h4 header>Registered Flavors</h4>

	<dx-data-grid [dataSource]="_registeredFlavors"
	              [columnAutoWidth]="true"
	              [showRowLines]="true"
	              [rowAlternationEnabled]="true"
	              [showBorders]="true"
	              [hoverStateEnabled]="true"
	              [allowColumnReordering]="true"
	              [allowColumnResizing]="true">
		<dxo-paging [enabled]="false"></dxo-paging>
		<dxo-sorting mode="single"></dxo-sorting>

		<dxi-column caption="Agency" alignment="left" cellTemplate="agency" width="160"></dxi-column>
		<div *dxTemplate="let cell of 'agency'">
			{{ cell.data.evaluatingAgency() }}
		</div>

		<dxi-column caption="ID" dataField="numberFlavis_JECFA" alignment="left" width="140"></dxi-column>
	</dx-data-grid>
</div>

<div style="padding-right: 40px;" *ngIf="_flavorStatuses && (_flavorStatuses.length > 0)" [attr.data-hidden]="_edm.editing">
	<h4 header>Regulatory Status</h4>

	<dx-data-grid [dataSource]="_flavorStatuses"
	              [columnAutoWidth]="true"
	              [showRowLines]="true"
	              [rowAlternationEnabled]="true"
	              [showBorders]="true"
	              [hoverStateEnabled]="true"
	              [allowColumnReordering]="true"
	              [allowColumnResizing]="true"
	              [wordWrapEnabled]="true"
	              (onSelectionChanged)="onSelectRegulatoryStatus($event)">>
		<dxo-paging [enabled]="false"></dxo-paging>
		<dxo-sorting mode="single"></dxo-sorting>
		<dxo-selection mode="single"></dxo-selection>

		<dxi-column caption="Agency" alignment="left" cellTemplate="agency"></dxi-column>
		<div *dxTemplate="let cell of 'agency'">
			{{ cell.data.evaluatingAgency() }}
		</div>

		<dxi-column caption="Agency Title" alignment="left" cellTemplate="agencyTitle" width="180"></dxi-column>
		<div *dxTemplate="let cell of 'agencyTitle'">
			{{ cell.data.agencyTitle() }}
		</div>

		<dxi-column caption="Reference" dataField="evaluatorId" alignment="left"></dxi-column>
		<dxi-column caption="Determination" alignment="left" cellTemplate="determination"></dxi-column>
		<div *dxTemplate="let cell of 'determination'">
			{{ cell.data.prefixedDetermination() }}
		</div>

		<dxi-column caption="Web or File Link" alignment="left" cellTemplate="link"></dxi-column>
		<div *dxTemplate="let cell of 'link'">
			<a (click)="selectWebRef(cell.data)">{{cell.data.linkDisplayExt()}}</a>
		</div>

<!--
		<dxi-column [visible]="_edm.isStaff()" caption="Web Reference" alignment="left" cellTemplate="webReference"></dxi-column>
		<div *dxTemplate="let cell of 'webReference'">
			{{cell.data.webReferenceExt()}}
		</div>
-->

		<dxi-column [visible]="_edm.isStaff()" caption="Editable" alignment="left" cellTemplate="editable"></dxi-column>
		<div *dxTemplate="let cell of 'editable'">
			<input type="checkbox" disabled [checked]="isRegulatoryStatus(cell.data)"/>
		</div>
	</dx-data-grid>
</div>

<div *ngIf="_edm.editing">
	<regulatory-status #self [entity]="_edm.currentEntity" [statusType]="'flavor'"></regulatory-status>
</div>

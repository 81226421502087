import { Component, Input, OnInit } from '@angular/core';

import { ReferenceState, UnitOfWork } from '../../services/common';
import { UtilFns } from '../../utils/common';
import { Reference, WorkflowRecordedAction } from '../../entities/EntityModels';
import { WorkflowActivityCompletedItem } from '../../entities/projections/WorkflowActivityCompletedItem';

@Component({
    selector: 'workflow-history',
    templateUrl: './workflow-history.html'
})

export class WorkflowHistoryComponent implements OnInit {

    _isDataLoading: boolean;
    _unfinishedReasons: string[];
    _workflowActivityCompletedItems: WorkflowActivityCompletedItem[];
    _workflowPendingActions: WorkflowRecordedAction[];

    @Input() entity: Reference;
    @Input() editable: boolean;

    constructor(private _uow: UnitOfWork, private _referenceState: ReferenceState) {
    }

    ngOnInit() {
        this.loadData();
    }

    public loadData() {

        this._unfinishedReasons = [];
        this._workflowActivityCompletedItems = [];
        this._workflowPendingActions = [];

        if (this.entity == null) {
            return;
        }

        this._isDataLoading = true;

        this._referenceState.enumerateMissingData(this.entity).then(e => {
            if (e > 0) {
                // Number of missing data elements
                this._unfinishedReasons = this._referenceState.unfinishedReasons;
            }

            this._workflowActivityCompletedItems = this._referenceState.getCompletedWorkflowActions(this.entity);
            this._isDataLoading = false;
        });
    }

    get utilFns() {
        return UtilFns;
    }

    hasCompletedActions(): boolean {
        return (this._workflowActivityCompletedItems != null && this._workflowActivityCompletedItems.length > 0);
    }

    hasPendingActions(): boolean {
        return (this._unfinishedReasons != null && this._unfinishedReasons.length > 0);
    }

    hasData(): boolean {
        return (this.hasCompletedActions() || this.hasPendingActions());
    }

    public get currentReferenceId(): number {
        if (this.entity) {
            return this.entity.referenceId;
        }
        return null;
    }

    public onReferenceChange(reference: Reference) {
        this.entity = reference;
        this.loadData();
    }

    public removeAction(c: WorkflowActivityCompletedItem) {
        this._referenceState.processDeleteWorkflowAction(this.entity, c);
        this._workflowActivityCompletedItems = this._workflowActivityCompletedItems.filter(w => w.workflowActivityCompletedItemId != c.workflowActivityCompletedItemId);
    }
}

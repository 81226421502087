import { Injectable } from '@angular/core';

@Injectable()
export class BusyService {
	private busyCounter: number = 0;

	get isBusy(): boolean {
		return this.busyCounter > 0;
	}

	async busy<T>(op: Promise<T>): Promise<T> {
		this.busyCounter++;

		try {
			return await op;
		} finally {
			this.busyCounter--;
		}
	}
}
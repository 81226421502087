import { Component, Input } from '@angular/core';

import { Reference } from '../../entities/Reference';
import { DocUniqReference } from '../../entities/DocUniqReference';

import {UnitOfWork, UserManager} from '../../services/common';

@Component({
    selector: 'reference-header',
    templateUrl: './reference-header.html',
})
export class ReferenceHeaderComponent {
    @Input() reference: Reference;
    @Input() title: string;

    constructor(public _userManager: UserManager, public _uow: UnitOfWork) {
    }

    isStaff() {
        return this._userManager.currentUser.isStaff;
    }
}

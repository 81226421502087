import { EntityBase } from './EntityBase';

import { Validator } from 'breeze-client';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

export class FSAG extends EntityBase {

   /// <code> Place custom code between <code> tags
   get normNote() {
       return (this.note || '').toLowerCase();
   }
   /// </code>

   // Generated code. Do not place code below this line.
   sAGID: number;
   sAG: string;
   parent: number;
   sibling: number;
   child: number;
   note: string;
   priority: string;
   created: Date;
   createdUser: string;
   modified: Date;
   modifyUser: string;
   rowVersion: number;
}


import { EntityBase } from './EntityBase';
import { Material } from './Material';

import { Validator } from 'breeze-client';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

export class ECOSARToxicEffect extends EntityBase {

    /// <code> Place custom code between <code> tags

    /// </code>

    // Generated code. Do not place code below this line.
    eCOSARToxicEffectId: number;
    materialId: number;
    fish_96hr_LC50_Class: string;
    fish_96hr_LC50_mg_L_ppm: string;
    daphnid_48hr_LC50_Class: string;
    daphnid_48hr_LC50_mg_L_ppm: string;
    greenAlgae_96hr_EC50_Class: string;
    greenAlgae_96hr_EC50_mg_L_ppm: string;
    created: Date;
    createdUser: string;
    modified: Date;
    modifyUser: string;
    rowVersion: number;
    material: Material;
}

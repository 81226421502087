<div class="topic-page">
    <div class="row topic-title h1-sm bold inline">{{getPageTitle()}}</div>
</div>
<div class="row tab-container-vert ">
    <div class="col-md-1-8 tab-titles  no-print">
        <ul class="nav nav-tabs nav-stacked ">
            <li *ngFor="let r of _routes; let ix=index" role="presentation" [class.active]="isActiveTab(r) ">
                <a *ngIf="r.path && canShow(r) " [routerLink]="[ r.path ] ">
                    <span>{{r.title}}</span>
                </a>
                <a *ngIf="canShow(r) && (!r.path) "><span>{{r.title}}</span></a>
            </li>
        </ul>
    </div>
    <div class="col-md-7-8 tab-content ">
        <router-outlet></router-outlet>
    </div>
</div>
<div  style="background-color: #B4CDCD; width:100%;">
    <div class="archive-document-search-grid-container">
        <!-- Row 1 -->
        <span class="bold document-selector-toggle-text document-filter-text-padding inline item-show-recently-archived-label">Show:</span>
        <button class="btn-select item-recentlyarchived-button" [ngStyle]="{ 'background-color': filterButtonColor('recentlymodified') }" (click)="onSelectFilterType('recentlyarchived')">Recently Archived</button>

        <!-- Row 2 -->
        <span class="bold document-selector-toggle-text document-filter-text-padding inline item-archive-find-by-label">Filter By:</span>
        <button class="btn-select item-archived-referenceid-button" [ngStyle]="{ 'background-color': filterButtonColor('reference') }" (click)="onSelectFilterType('reference')">Reference</button>

        <!-- Row 2 -->
        <span *ngIf="showSearchElements" class="bold document-selector-toggle-text display-block document-filter-text-padding item-archive-searchterm-label">Search Terms:</span>
        <input *ngIf="showSearchElements" class="item-archive-search-input background-white" type="text" [(ngModel)]="_searchTerm">
        <button *ngIf="showSearchElements" class="btn btn-primary navbar-btn item-search-button" type="button" (click)="search()" [disabled]="!canSearch()">Search</button>

        <!-- Row 3 -->
        <span class="item-archive-search-message italic">{{searchTypeMessage}}</span>

        <!-- Row 4 -->
        <span class="alert-message item-archive-error-message">{{_searchErrorMessage}}</span>

    </div>

    <div class="item-document-search-label">
        <span class="bold page-subtitle">Search Results</span>
    </div>

    <div *ngIf="searchType == filterSearchType.REFERENCEID">
        <div *ngIf="_rifmDocumentArchivesEx != null && _rifmDocumentArchivesEx.length > 0">
            <table class="table table-striped table-hover table-bordered table-condensed" style="width: auto;">
                <thead style="background-color: #838B83 !important;">
                <tr>
                    <td class="centered" style="width: 65px;">Ref Id</td>
                    <td class="centered" style="width: 90px;">Brief Reference</td>
                    <td class="text-left" style="width: 85px;">File Name</td>
                    <td class="centered" style="width: 120px;">Archived Date</td>
                    <td class="centered" style="width: 80px;">Archived By</td>
                    <td class="centered" style="width: 60px;">View</td>
                    <td class="centered"style="width: 70px;">Restore</td>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let rd of _rifmDocumentArchivesEx" [ngStyle]="{ 'background-color': rd.backgroundColor }">
                    <td class="centered bold">{{rd.data.referenceId}}</td>
                    <td class="centered">{{rd.data.briefReference}}</td>
                    <td>
                        <a *ngIf="rd.data.originalFileName" (click)="onView(rd)">
                            <img class="centered-image" src="assets/images/ViewPDF.png" width="24"/>
                        </a>
                    </td>
                    <td class="centered">{{_stateMap.utilFns.formatDate(rd.data.created, "YYYY-MM-DD HH:mm:ss")}}</td>
                    <td class="text-left">{{rd.data.createdUser}}</td>
                    <td>
                        <a (click)="onView(rd)">
                            <img class="centered-image" src="assets/images/ViewPDF.png" width="24"/>
                        </a>
                    </td>
                    <td><a class="inline" style="color: #B0171F;" (click)="onRestore(rd)">Restore</a></td>
                </tr>
                </tbody>
            </table>
        </div>

    </div>

    <div *ngIf="searchType == filterSearchType.RECENTLYARCHIVED">
        <div *ngIf="_recentRIFMDocumentArchivesEx != null && _recentRIFMDocumentArchivesEx.length > 0" class="background-white">
            <table class="table table-striped table-hover table-bordered table-condensed" style="width: auto;">
                <thead style="background-color: #838B83 !important;">
                <tr>
                    <td class="centered" style="width: 65px;">Ref Id</td>
                    <td class="centered" style="width: 90px;">Brief Reference</td>
                    <td class="text-left" style="width: 95px;">File Name</td>
                    <td class="centered" style="width: 80px;">Archived Date</td>
                    <td class="centered" style="width: 80px;">Archived By</td>
                    <td class="centered" style="width: 60px;">View</td>
                    <td class="centered"style="width: 65px;">Restore</td>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let rd of _recentRIFMDocumentArchivesEx" [ngStyle]="{ 'background-color': rd.backgroundColor }">
                    <td class="centered">{{rd.data.referenceId}}</td>
                    <td class="centered">{{rd.data.briefReference}}</td>
                    <td class="text-left">{{rd.data.originalFileName}}</td>
                    <td class="centered">{{_stateMap.utilFns.formatDate(rd.data.modified)}}</td>
                    <td class="text-left">{{rd.data.modifyUser}}</td>
                    <td>
                        <a (click)="onView(rd)">
                            <img class="centered-image" src="assets/images/ViewPDF.png" width="24"/>
                        </a>
                    </td>
                    <td class="centered"><a class="inline" style="color: #B0171F;" (click)="onRestore(rd)">Restore</a></td>
                </tr>
                </tbody>
            </table>
        </div>

    </div>

</div>

import { JournalListItem } from './JournalListItem';
import { Material } from '../Material';
import { MaterialExposureSurvey } from '../MaterialExposureSurvey';

export class SelectableEntity<T> {
    backgroundColor: string;
    data: T;
    sortField: string;
    isMultiple: boolean;
    selected: boolean;

    constructor(obj: T, color: string, sortField: string = '', isMultiple: boolean = false) {
        this.backgroundColor    = color;
        this.data               = obj;
        this.sortField          = sortField;
        this.isMultiple         = isMultiple;
        this.selected           = false;
    }

    public static compareJournals(a: SelectableEntity<JournalListItem>, b: SelectableEntity<JournalListItem>) {
        if (a.data.relevance < b.data.relevance) {
            return -1;
        }
        if (a.data.relevance > b.data.relevance) {
            return 1;
        }
        if (a.data.journalName < b.data.journalName) {
            return -1;
        }
        if (a.data.journalName > b.data.journalName) {
            return 1;
        }
        return 0;
    }
}

import { EntityBase } from './EntityBase';
import { Material } from './Material';
import { TypeFoodCategory } from './TypeFoodCategory';

import { Validator } from 'breeze-client';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

export class MaterialUses extends EntityBase {

   /// <code> Place custom code between <code> tags
   
   /// </code>

   // Generated code. Do not place code below this line.
   materialUseId: number;
   materialId: number;
   typeFoodCategoryId: number;
   averageUsual: number;
   averageMaximum: number;
   created: Date;
   createdUser: string;
   modified: Date;
   modifyUser: string;
   rowVersion: number;
   material: Material;
   typeFoodCategory: TypeFoodCategory;
}


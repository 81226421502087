import { Component, Input, OnInit } from '@angular/core';

import { provideParent, UnitOfWork } from '../../services/common';
import { EditPropParent } from '../../controls/common';

import { TypePredictionClass } from '../../entities/EntityModels';

@Component({
    selector: 'type-prediction-class-editor',
    templateUrl: './type-prediction-class-editor.html',
    providers: [provideParent(TypePredictionClassEditorComponent, EditPropParent)]
})
export class TypePredictionClassEditorComponent implements OnInit, EditPropParent {
    @Input() entity: TypePredictionClass;

    public predictionClassKey: string;

    constructor(public uow: UnitOfWork) {

    }

    ngOnInit() {
        this.predictionClassKey = this.entity.predictionClass;  // Work-around due to the type of primary key
    }

    checkIfNull(value: string) {
        if (value == '') {
            return null;
        } else {
            return value;
        }
    }

    getError(propName: string) {
        if (this.entity == null) {
            return;
        }

        const err = this.entity.getErrorFor(propName);
        if (err) {
            return err;
        }
        return null;
    }
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import * as _ from 'lodash';

import { StateMap, UnitOfWork, UserManager } from '../../services/common';
import { EntityManagerProvider } from '../../services/entity-manager-provider';
import { UtilFns } from '../../utils/common';
import { UserAcknowledgement } from '../../entities/EntityModels';
import { UserAcknowledgementComponent } from './user-acknowledgement.component';

import { RIFMDownloadableFile } from  '../../entities/EntityModels';
import {HttpClient, HttpHeaders, HTTP_INTERCEPTORS} from '@angular/common/http';
import {Observable} from 'rxjs';

import { ROUTES } from '../routes';
import { HOME_ROUTES } from './home.routes';

@Component({
    selector: 'home',
    templateUrl: './home-page.html'
})
export class HomePageComponent implements OnInit {
    @ViewChild(UserAcknowledgementComponent, { static: true }) _userAcknowledgementComponent: UserAcknowledgementComponent;

    _documentManagementUrl: string;
    _hasAcceptedUseAgreement = false;
    _isInitialized = false;
    _isLoading = false;
    _validationMessage: string;

    _currentEntity: UserAcknowledgement;

    constructor(private _emProvider: EntityManagerProvider, public _userManager: UserManager, public _router: Router, private _httpClient: HttpClient,
                private _route: ActivatedRoute, public _location: Location, public _stateMap: StateMap, public _uow: UnitOfWork) {

    }

    ngOnInit() {
        this._emProvider.prepare().then(() => {
            this._isInitialized = true;

            const params = { userEmail: this._userManager.currentUser.name };
            return this._uow.fetchTyped('Misc/UserAcknowledgementForUserEmail', UserAcknowledgement, params)
                .then(r => {
                    if (r != null && r.length > 0) {
                        const entities = _.sortBy(r, (a: any) => a.userAcknowledgementDate).reverse();
                        this._currentEntity = <any>entities[0];
                        if (this.validateUserAcknowledgement(this._currentEntity)) {
                            return;
                        }
                    }

                    // Missing a user acknowledgement record or the last accept date has expired
                    return this.showUserAcknowledgement()
                        .then(a => {
                            if (this._hasAcceptedUseAgreement == false) {
                                this.logout();
                            }
                            return;
                        });
                });
        });

        this.getDocumentManagementUrl().then(url => {
            this._documentManagementUrl                 = url + '/RIFMDownloadableFile';
            this._stateMap.documentManagementBaseUrl    = url; // This is needed for the show-and-download-pdf.component to avoid a timing issue with the serviceUrl.
        });
    }

    private validateUserAcknowledgement(ack: UserAcknowledgement): boolean {

        const msDay = 24 * 60 * 60 * 1000; // Number of milliseconds in a day
        const today = new Date();

        const diffDays = Math.round(Math.abs(today.getTime() - ack.userAcknowledgementDate.getTime()) / (msDay));

        return (this._userManager.UserAcknowledgementDuration >= diffDays);
    }

    private navigateLocal(path: string) {
        this._router.navigate(['../' + path], { relativeTo: this._route });
    }

    isAuthorizedDownloadUser() {
        return this._userManager.currentUser.isInRole('authorizeddownloaduser');
    }

    isIFRA() {
        return this._userManager.currentUser.isInRole('ifra');
    }

    isStaff() {
        return this._userManager.currentUser.isStaff;
    }

    downloadRequestForm() {
        this._isLoading = true;
        this.exportDownloadableFile('RIFMReportRequestForm.docx', 'UserSupport');
        this._isLoading = false;
    }

    downloadIFRANCSDocument() {
        this._isLoading = true;
        this.exportDownloadableFile('IFRA NCS BOTANICAL DEFINITION AND PROCESSING METHOD.pdf', 'Whats New');
        this._isLoading = false;
    }

    goIntro() {
        this.navigateLocal(HOME_ROUTES.Introduction.path);
    }

    goDatabaseSubscription() {
        this.navigateLocal(HOME_ROUTES.DatabaseSubscription.path);
    }

    goIFRAAttachments() {
        this.navigateLocal(HOME_ROUTES.IFRAAttachments.path);
    }

    goSafetyAssessments() {
        this.navigateLocal(HOME_ROUTES.SafetyAssessments.path);
    }

    goMaterialGroups() {
        this.navigateLocal(HOME_ROUTES.MaterialGroups.path);
    }

    goSearchAuthors() {
        this.navigateLocal(HOME_ROUTES.SearchAuthors.path);
    }

    goSearchSynonyms() {
        this.navigateLocal(HOME_ROUTES.SearchSynonyms.path);
    }


    goSearchStructure() {
        this.navigateLocal(HOME_ROUTES.SearchStructure.path);
    }

    goToolsAndModels() {
        this.navigateLocal(HOME_ROUTES.ToolsAndModels.path);
    }

    goReportDatabaseChanges() {
        this.navigateLocal(HOME_ROUTES.ReportDatabaseChanges.path);
    }

    goSystemRequirements() {
        this.navigateLocal(HOME_ROUTES.SystemRequirements.path);
    }

    goStaffEditing() {
        if (this._userManager.currentUser.isInRole('fema') == false) {
            this._router.navigate(UtilFns.asRouterParts(ROUTES.Staff)); // Currently defaults to Material Edit which is not available for FEMA Staff
        } else {
            this._router.navigate(UtilFns.asRouterParts(ROUTES.Staff, ROUTES.Staff.childRoutes.Reference));
        }
    }

    goIFRASurveyUses() {
        this.navigateLocal(HOME_ROUTES.IFRASurveyUse.path);
    }

    goMaterialClusters() {
        this.navigateLocal(HOME_ROUTES.MaterialClusters.path);
    }

    goExposureAdmin() {
        this._router.navigate(UtilFns.asRouterParts(ROUTES.Exposure, ROUTES.Exposure.childRoutes.ExposureSurveys));
    }

    goDocManagement() {
        this._router.navigate(UtilFns.asRouterParts(ROUTES.DocumentManagement, ROUTES.DocumentManagement.childRoutes.RIFMDocuments));
    }

    goEnvironmentalData() {
        this.navigateLocal(HOME_ROUTES.EnvironmentalData.path);
    }

    showUserAcknowledgement() {
        return UtilFns.showModal(this._userAcknowledgementComponent, this).then((result) => {
            this.onUserAcknowledgementAccept(result);
        });
    }

    onUserAcknowledgementAccept(accept: boolean) {
        if (accept == null || accept == false) {
            this._hasAcceptedUseAgreement = false;
            return false;
        }

        if (this._currentEntity == null) { // Add a new record to the User Acknowledgement table
            const params = {
                userEmail: this._userManager.currentUser.name,
                userAcknowledgementDate: new Date()
            };

            const userAcknowledgement = this._uow.userAcknowledgementFactory.create(params);
        } else {  // Update the license agreement with a new acceptance date
            this._currentEntity.userAcknowledgementDate = new Date();
        }

        this._uow.commit();
        this._hasAcceptedUseAgreement = true;
        return true;
    }

    canShowDocManagementAdmin() {

        if (this._stateMap.currentUser.isStaff) {
            if (this._stateMap.currentUser.isInRole('consultant') || this._stateMap.currentUser.isInRole('FEMA')) {
                return false;
            } else {
                return true;
            }
        }

        return false;
    }

    canShowExposureAdmin() {

        if (this._stateMap.currentUser.isStaff) {
            if (this._stateMap.currentUser.isInRole('consultant') || this._stateMap.currentUser.isInRole('FEMA')) {
                return false;
            } else {
                return true;
            }
        }

        return false;
    }

    logout() {
        return this._userManager.logout().then(() => {
            this._location.replaceState('/home');
            document.location.reload();
        });
    }

    public getDocumentManagementUrl(): Promise<any> {
        return this._uow.fetch('DocumentManagement/DocumentManagementURL', {}).then(dm => {
            return dm[0];
        });
    }

    // ************************************************************************
    // document management web api url
    // ************************************************************************
    getDownloadFileURL(fileName: string, subFolder: string): Observable<any> {
        let url: string;
        url = this._documentManagementUrl + '/GetFileBySubFolderAndName?fileName=' + fileName + '&subFolder=' + subFolder ;

        return this._httpClient.get<any>(url, { responseType: 'blob' as 'json'});
    }

    public exportDownloadableFile(fileName: string, subFolder: string) {
        this._isLoading = true;
        this._validationMessage = '';
        this.getDownloadFileURL(fileName, subFolder)
            .subscribe(
                (response: Blob) => {
                    const data = response;

                    const file = new Blob([data], { type: 'application/pdf' });
                    const fileURL = URL.createObjectURL(file);

                    // window.open(fileURL);
                    const a         = document.createElement('a');
                    a.href        = fileURL;
                    a.target      = '_blank';
                    a.download    = fileName;
                    document.body.appendChild(a);
                    a.click();

                    this._validationMessage = 'File download successful.';

                },
                (error) => {
                    console.error('Request failed with error: ' + error.message);
                    this._validationMessage = error.message;
                    this._isLoading = false;
                },
                () => {
                    this._isLoading = false;
                });
    }
}

import { UnitOfWork } from '../../../services/unit-of-work';
import { StateMap } from '../../../services/state-map';
import { BusyService } from '../../../services/busy.service';
import { Injectable } from '@angular/core';

@Injectable()
export class MaterialLoader {
	constructor(private uow: UnitOfWork, private stateMap: StateMap, private busyService: BusyService) {
	}

	load(materialId: number) {
		if (materialId == null) {
			return Promise.resolve(null);
		}

		let shouldLoad = false;
		if (this.stateMap.currentMaterial == null) {
			shouldLoad = true;
		} else if (this.stateMap.currentMaterial.materialId != materialId) {
			shouldLoad = true;
		}

		if (!shouldLoad) {
			return Promise.resolve(this.stateMap.currentMaterial);
		}

		const promise = this.uow.materialRepository
			.withId(materialId)
			.then(r => {
				this.stateMap.currentMaterial = r;
				return r;
			});

		return this.busyService.busy(promise);
	}
}
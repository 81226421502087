import { Component, Input, ViewChild } from '@angular/core';
import { ExposureSurveyMaterialMostRecent } from '../../entities/EntityModels';
import { UserManager } from '../../services/common';

import { UtilFns } from '../../utils/common';
import { PrintToPdfDocComponent } from '../main/print-to-pdf-doc.component';


@Component({
    selector: 'exposure-survey-last-report',
    templateUrl: './exposure-survey-last-report.html',
})
export class ExposureSurveyLastReportComponent {

    @ViewChild(PrintToPdfDocComponent, { static: true }) _printToPdfDocComponent: PrintToPdfDocComponent;

    @Input() reportItems: ExposureSurveyMaterialMostRecent[];

    constructor(private _userManager: UserManager) {
    }

    get utilFns() {
        return UtilFns;
    }

    printExposureSurveyLastReport() {
        const ele = document.querySelector('#report-data');
        UtilFns.printElement(ele);
    }

    exportReportFile() {
        const params: { key: string, value: string }[] = [];

        params.push({ key: 'reporttype', value: 'mostrecentsurvey' });

        const url = this._userManager.getReportUrl(params);
        window.open(url, '_self');
        return false;
    }

}

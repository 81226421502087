import { Injectable } from '@angular/core';
import * as _ from 'lodash';

import { Entity } from 'breeze-client';
import {Material, TypeWorkflowContact, TypeWorkflowMaterialAction, TypeWorkflowMaterialActionTransition, WorkflowMaterialRecordedAction} from '../entities/EntityModels';
import { WorkflowEntityState, WorkflowState } from './workflow-state';
import { UnitOfWork } from './unit-of-work';
import {UserManager} from './user-manager';

export enum SurveyReportedUseChangeType { ADD, EDIT, DELETE}

@Injectable()
export class MaterialWorkflowService {

    public _typeWorkflowContacts: TypeWorkflowContact[] = [];
    public _typeWorkflowMaterialActionTransitions: TypeWorkflowMaterialActionTransition[] = [];


    constructor(private _uow: UnitOfWork, private _userManager: UserManager) {

        this._uow.typeWorkflowContactRepository.all().then(w => {
            this._typeWorkflowContacts = (w != null) ? w : [];
        });

        this._uow.typeWorkflowMaterialActionTransitionRepository.all()
            .then(r => {
                this._typeWorkflowMaterialActionTransitions = r;
            });
    }

    // ----------------------------------------------------------------------------------------------------------------------------------
    // Volume of Use editing actions.
    // ----------------------------------------------------------------------------------------------------------------------------------
    public recordNewReportingOrganization(workflowEntityState: WorkflowEntityState, notes: string, userName: string) {

        const workflow = new WorkflowState();

        workflow.workflowEntityName     = 'ReportingOrganization';
        workflow.workflowNotes          = notes;
        workflow.workflowContactId      = this.findWorkflowContactId(userName);

        this.recordMaterialWorkflowActions(workflow, 'ADDED');

    }

    public recordChangedReportingOrganizationName(workflowEntityState: WorkflowEntityState, notes: string, userName: string) {

        const workflow = new WorkflowState();

        workflow.workflowEntityName     = 'ReportingOrganization';
        workflow.workflowPropertyName   = 'organizationName';
        workflow.workflowNotes          = notes;
        workflow.workflowContactId      = this.findWorkflowContactId(userName);

        this.recordMaterialWorkflowActions(workflow, 'MODIFIED');

    }

    // tslint:disable-next-line:max-line-length
    public recordMaterialSurveyReportedUseChange(materialId: number, workflowEntityState: WorkflowEntityState, changeNumberValue: number, surveyId: number, notes: string, userNotes: string, userName: string) {

        const workflow = new WorkflowState();

        workflow.workflowMaterialId         = materialId;
        workflow.workflowEntityName         = 'SurveyReportedUse';
        workflow.workflowPropertyName       = 'kilogramsConsumed';
        workflow.workflowChangeValueNumber  = changeNumberValue;
        workflow.workflowNotes              = notes;
        workflow.workflowActionObjectId     = surveyId;
        workflow.workflowActionPropertyName = 'surveyId';
        workflow.workflowUserNotes          = userNotes;
        workflow.workflowContactId          = this.findWorkflowContactId(userName);

        switch (workflowEntityState) {
            case WorkflowEntityState.Added: {
                this.recordMaterialWorkflowActions(workflow, 'ADDED');
                break;
            }
            case WorkflowEntityState.Deleted: {
                this.recordMaterialWorkflowActions(workflow, 'DELETED');
                break;
            }
            case WorkflowEntityState.Modified: {
                this.recordMaterialWorkflowActions(workflow, 'MODIFIED');
                break;
            }
            default: {
                break;
            }
        }
    }

    public recordMaterialSurveyReportedUseUserNotesOnlyChange(materialId: number, workflowEntityState: WorkflowEntityState, surveyId: number, notes: string, userNotes: string, userName: string) {

        const workflow = new WorkflowState();

        workflow.workflowMaterialId         = materialId;
        workflow.workflowEntityName         = 'SurveyReportedUse';
        workflow.workflowPropertyName       = 'userNotes';
        workflow.workflowNotes              = notes;
        workflow.workflowActionObjectId     = surveyId;
        workflow.workflowActionPropertyName = 'surveyId';
        workflow.workflowUserNotes          = userNotes;
        workflow.workflowContactId          = this.findWorkflowContactId(userName);

        this.recordMaterialWorkflowActions(workflow, 'MODIFIED');
    }

    // ----------------------------------------------------------------------------------------------------------------------------------
    // * UVS File Actions
    // ----------------------------------------------------------------------------------------------------------------------------------
    public recordMaterialUVSFileAdded(materialId: number, workflowEntityState: WorkflowEntityState, fileName: string, analyticalResultId: number, userName: string) {

        const workflow = new WorkflowState();

        workflow.workflowMaterialId         = materialId;
        workflow.workflowEntityName         = 'AnalyticalResult';
        workflow.workflowPropertyName       = 'uvsfile';
        workflow.workflowChangeValueString 	= fileName;
        workflow.workflowNotes              = 'UVS file added. File name is: ' + fileName;
        workflow.workflowActionObjectId     = analyticalResultId;
        workflow.workflowActionPropertyName = 'analyticalresultid';
        workflow.workflowContactId          = this.findWorkflowContactId(userName);

        this.recordMaterialWorkflowActions(workflow, 'ADDED');
    }

    // tslint:disable-next-line:max-line-length
    public recordMaterialUVSFileDeleted(materialId: number, workflowEntityState: WorkflowEntityState, fileName: string, analyticalResultId: number, userName: string) {

        const workflow = new WorkflowState();

        workflow.workflowMaterialId         = materialId;
        workflow.workflowEntityName         = 'AnalyticalResult';
        workflow.workflowPropertyName       = 'uvsfile';
        workflow.workflowChangeValueString 	= fileName;
        workflow.workflowNotes              = 'UVS file archived and removed. File name is: ' + fileName;
        workflow.workflowActionObjectId     = analyticalResultId;
        workflow.workflowActionPropertyName = 'analyticalresultid';
        workflow.workflowContactId          = this.findWorkflowContactId(userName);

        this.recordMaterialWorkflowActions(workflow, 'DELETED');
    }

    // ----------------------------------------------------------------------------------------------------------------------------------
    // * Material Data Changes
    // ----------------------------------------------------------------------------------------------------------------------------------
    public recordMaterialMonographNumberChange(materialId: number, workflowEntityState: WorkflowEntityState, monographNumberNew: number, monographNumberPrevious: number, userName: string) {

        const workflow = new WorkflowState();

        const newMonographNumber        = (monographNumberNew == null) ? 'unassigned' : '' + monographNumberNew;
        const previousMonographNumber   = (monographNumberPrevious == null) ? 'unassigned' : '' + monographNumberPrevious;

        workflow.workflowMaterialId         = materialId;
        workflow.workflowEntityName         = 'Material';
        workflow.workflowPropertyName       = 'monographNumber';
        workflow.workflowChangeValueNumber 	= monographNumberNew;
        workflow.workflowNotes              = 'Monograph Number changed from ' + previousMonographNumber + ' to ' + newMonographNumber;
        workflow.workflowActionObjectId     = materialId;
        workflow.workflowActionPropertyName = 'materialId';
        workflow.workflowContactId          = this.findWorkflowContactId(userName);

        this.recordMaterialWorkflowActions(workflow, 'MODIFIED');
    }

    public recordMaterialBotanicalPartChange(materialId: number, workflowEntityState: WorkflowEntityState, botanicalPartNew: string, botanicalPartPrevious: string, userName: string) {

        const workflow = new WorkflowState();

        const newBotanicalPart 		= (this.isValidString(botanicalPartNew)) ? botanicalPartNew : 'unassigned';
        const previousBotanicalPart = (this.isValidString(botanicalPartPrevious)) ? botanicalPartPrevious : 'unassigned';

        workflow.workflowMaterialId         = materialId;
        workflow.workflowEntityName         = 'Material';
        workflow.workflowPropertyName       = 'typeBotanicalSubdivisionId';
        workflow.workflowChangeValueString 	= botanicalPartNew;
        workflow.workflowNotes              = 'Botanical part changed from ' + previousBotanicalPart + ' to ' + newBotanicalPart;
        workflow.workflowActionObjectId     = materialId;
        workflow.workflowActionPropertyName = 'materialId';
        workflow.workflowContactId          = this.findWorkflowContactId(userName);

        this.recordMaterialWorkflowActions(workflow, 'MODIFIED');
    }

    public recordMaterialExtractTypeChange(materialId: number, workflowEntityState: WorkflowEntityState, extractTypeNew: string, extractTypePrevious: string, userName: string) {

        const workflow = new WorkflowState();

        const newExtractType 		= (this.isValidString(extractTypeNew)) ? extractTypeNew : 'unassigned';
        const previousExtractType 	= (this.isValidString(extractTypePrevious)) ? extractTypePrevious : 'unassigned';

        workflow.workflowMaterialId         = materialId;
        workflow.workflowEntityName         = 'Material';
        workflow.workflowPropertyName       = 'typeEssentialOilId';
        workflow.workflowChangeValueString 	= extractTypeNew;
        workflow.workflowNotes              = 'Extract type changed from ' + previousExtractType + ' to ' + newExtractType;
        workflow.workflowActionObjectId     = materialId;
        workflow.workflowActionPropertyName = 'materialId';
        workflow.workflowContactId          = this.findWorkflowContactId(userName);

        this.recordMaterialWorkflowActions(workflow, 'MODIFIED');
    }

    public recordMaterialFEMANumberChange(materialId: number, workflowEntityState: WorkflowEntityState, femaNumberNew: number, femaNumberPrevious: number, userName: string) {

        const workflow = new WorkflowState();

        const newFEMANumber 		= (femaNumberNew == null) ? 'unassigned' : '' + femaNumberNew;
        const previousFEMANumber 	= (femaNumberPrevious == null) ? 'unassigned' : '' + femaNumberPrevious;

        workflow.workflowMaterialId         = materialId;
        workflow.workflowEntityName         = 'Material';
        workflow.workflowPropertyName       = 'femaNumber';
        workflow.workflowChangeValueNumber 	= femaNumberNew;
        workflow.workflowNotes              = 'FEMA Number changed from ' + previousFEMANumber + ' to ' + newFEMANumber;
        workflow.workflowActionObjectId     = materialId;
        workflow.workflowActionPropertyName = 'materialId';
        workflow.workflowContactId          = this.findWorkflowContactId(userName);

        this.recordMaterialWorkflowActions(workflow, 'MODIFIED');
    }

    public recordMaterialRealCASNumberChange(materialId: number, workflowEntityState: WorkflowEntityState, realCASNumberNew: string, realCASNumberPrevious: string, userName: string) {

        const workflow = new WorkflowState();

        const newCASNumber 		= (realCASNumberNew == null || realCASNumberNew == '') ? 'unassigned' : '' + realCASNumberNew;
        const previousCASNumber = (realCASNumberPrevious == null || realCASNumberPrevious == '') ? 'unassigned' : '' + realCASNumberPrevious;

        workflow.workflowMaterialId         = materialId;
        workflow.workflowEntityName         = 'Material';
        workflow.workflowPropertyName       = 'realCASNumber';
        workflow.workflowChangeValueString 	= newCASNumber;
        workflow.workflowNotes              = 'CAS Number changed from ' + previousCASNumber + ' to ' + newCASNumber;
        workflow.workflowActionObjectId     = materialId;
        workflow.workflowActionPropertyName = 'materialId';
        workflow.workflowContactId          = this.findWorkflowContactId(userName);

        this.recordMaterialWorkflowActions(workflow, 'MODIFIED');
    }

    // ----------------------------------------------------------------------------------------------------------------------------------
    // * Material Add and Delete
    // ----------------------------------------------------------------------------------------------------------------------------------
    public recordMaterialAdded(materialId: number, workflowEntityState: WorkflowEntityState, realCASNumber: string, userName: string) {

        const workflow = new WorkflowState();

        workflow.workflowMaterialId         = materialId;
        workflow.workflowEntityName         = 'Material';
        workflow.workflowPropertyName       = 'realCASNumber';
        workflow.workflowChangeValueString 	= realCASNumber;
        workflow.workflowNotes              = 'Material added';
        workflow.workflowActionObjectId     = materialId;
        workflow.workflowActionPropertyName = 'materialId';
        workflow.workflowContactId          = this.findWorkflowContactId(userName);

        this.recordMaterialWorkflowActions(workflow, 'ADDED');
    }

    public recordMaterialDeleted(materialId: number, workflowEntityState: WorkflowEntityState, realCASNumber: string, userName: string) {

        const workflow = new WorkflowState();

        workflow.workflowMaterialId         = null;
        workflow.workflowEntityName         = 'Material';
        workflow.workflowPropertyName       = 'realCASNumber';
        workflow.workflowChangeValueString 	= realCASNumber;
        workflow.workflowNotes              = 'Attempt to delete Material with MaterialId: ' + materialId + ' and CAS Number: ' + realCASNumber;
        workflow.workflowActionObjectId     = materialId;
        workflow.workflowActionPropertyName = 'materialId';
        workflow.workflowContactId          = this.findWorkflowContactId(userName);

        this.recordMaterialWorkflowActions(workflow, 'DELETED');
    }

    // ----------------------------------------------------------------------------------------------------------------------------------
    // * record activity
    // ----------------------------------------------------------------------------------------------------------------------------------
    public recordMaterialWorkflowActions(wfs: WorkflowState, entityState: string) {

        const transitions = this._typeWorkflowMaterialActionTransitions
            // tslint:disable-next-line:max-line-length
            .filter(w => w.entityName == wfs.workflowEntityName && w.entityState == entityState && ((w.propertyName == null && wfs.workflowPropertyName == null) || (w.propertyName == wfs.workflowPropertyName)));

        if (transitions != null && transitions.length > 0) {

            transitions.forEach(t => {
                this.createWorkflowMaterialRecordedAction(wfs, t.typeWorkflowMaterialActionId);
            });
        }
    }

    private createWorkflowMaterialRecordedAction(wfs: WorkflowState, actionCurrent: string, actionNext?: string) {
        const params = { typeWorkflowMaterialActionId: actionCurrent };

        const action = this._uow.workflowMaterialRecordedActionFactory.create(params);

        action.entityName           = wfs.workflowEntityName;
        action.notes                = wfs.workflowNotes;
        action.userNotes            = wfs.workflowUserNotes;
        action.sequenceNumber       = 1;

        if (wfs.workflowMaterialId != null) {
            action.materialId           = wfs.workflowMaterialId;
        }

        if (wfs.workflowPropertyName != null) {
            action.propertyName         = wfs.workflowPropertyName;
        }

        if (wfs.workflowChangeValueNumber != null) {
            action.changeValueNumber    = wfs.workflowChangeValueNumber;
        }

        if (wfs.workflowChangeValueString != null) {
            action.changeValueString    = wfs.workflowChangeValueString;
        }

        if (wfs.workflowActionObjectId != null) {
            action.actionObjectId       = wfs.workflowActionObjectId;
        }

        if (wfs.workflowActionPropertyName != null) {
            action.actionPropertyName   = wfs.workflowActionPropertyName;
        }

        if (actionNext != null) {
            action.completedWorkflowMaterialActionId = actionNext;
        }

        if (wfs.workflowContactId > 0) {
            action.createdWorkflowMaterialContactId = wfs.workflowContactId;
        }
    }

    public findWorkflowContactId(userName: string) {
        const contacts = this._typeWorkflowContacts.filter(c => c.emailAddress.toUpperCase() == userName.toUpperCase());

        if (contacts != null && contacts.length > 0) {
            return contacts[0].workflowContactId;
        }

        return 0;
    }

    isValidString(teststring: string) {
        if (!teststring) { return false; }

        return (teststring.trim().length > 0);
    }
}

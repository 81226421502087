import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';

import { StateMap, UnitOfWork } from '../../services/common';
import { Reference } from '../../entities/Reference';
import { from, Observable } from 'rxjs';

@Injectable()
export class ReferenceResolver implements Resolve<Reference> {
    private _url: string;

    constructor(private _uow: UnitOfWork, private _stateMap: StateMap, private _router: Router) {
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
        // save the original url where we are coming from.
        this._url = this._router.url;
        const id = parseInt(route.params['id'], 10);
        return from(this.fetch(id));
    }

    fetch(referenceId: number): Promise<any> {
        const cr = this._stateMap.currentReference;
        if (cr && cr.referenceId == referenceId) {
            return Promise.resolve(cr);
        }

        this._uow.clearEntityManager();
        return this._uow.referenceRepository.withId(referenceId).then(r => {
            if (!r) {
                // this means that the reference is either not available for this login or has been deleted.
                const p = this._stateMap.yesNoComponent.showModal('Reference not available',
                    'Reference has been added to the RIFM Database and Toxicological Data entry is in progress', ['Ok']);
                return p.then(() => {
                    // so we return to our original location.
                    this._router.navigateByUrl(this._url);
                    return r;
                });
            }
            this._stateMap.currentReference = r;
            this.fetchHasDataInfo(r); // fetch info about which tabs have data - lazily.
            return r;
        });

    }

    fetchHasDataInfo(reference: Reference) {

        const referenceId = reference.referenceId;
        // determine which tabs have data

        const q = { referenceId: referenceId };
        this._uow.referenceRelationRepository.whereWithParams(q).then(r => {
            this._stateMap.setHasReferenceDataFor('reference-relations', r.length > 0);
        });

        this._uow.referenceRepository.populateBiologicalData(reference).then(r => {
            this._stateMap.setHasReferenceDataFor('reference-data', r.length > 0);
        })
    }

}

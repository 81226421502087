<div id="dialog-root" class="modal fade bs-example-modal-lg" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header custom-header">
                <span class="modal-title" id="myModalLabel">How to print to PDF</span>
                <button type="button" class="btn btn-default pull-right text-uppercase text-bold" data-dismiss="modal"><span class="fa fa-times"></span> Close</button>
            </div>
            <div class="modal-body custom-body">
                <div>
                    <b>Print any report in PDF format</b> directly from the browser. Listed below are steps to print them
                    in each of the supported browsers.
                </div>
                <br>
                <div style="margin-left: 30px">
                    <b>On Google Chrome: </b><br /> (1) Click the Print button seen on Reports or in the Header to see a
                    dialog pop-up that allows printing.<br /><br /> (2) Set the Destination printer to Microsoft Print as
                    PDF and then click on the Print button.<br /><br />
                    <img style="margin-left: 45px" width="90%" src="assets/images/system-requirements/ChromeSaveAsPDF.PNG" />
                    <br /><br /> (3) A dialog called Save Print Output As should pop-up. On this dialog, specify a location
                    and filename for the PDF document, and click on Save to generate a PDF document.<br /><br />
                    <img style="margin-left: 45px" width="90%" src="assets/images/system-requirements/ChromeSavePrintOutputAs.PNG" />
                </div>
                <br>
                <div style="margin-left: 30px">
                    <b>On Microsoft Edge: </b><br /> (1) Click the Print button seen on Reports or in the Header to see a
                    dialog pop-up that allows printing.<br /><br /> (2) Set the Printer to Microsoft Print to PDF and then
                    click on the Print button. <br /><br />
                    <img style="margin-left: 45px" width="90%" src="assets/images/system-requirements/EdgeSaveAsPDF.PNG" />
                    <br /><br /> (3) A dialog called Save Print Output As should pop-up. On this dialog, specify a location
                    and filename for the PDF document, and click on Save to generate a PDF document.<br /><br />
                    <img style="margin-left: 45px" width="90%" src="assets/images/system-requirements/EdgeSavePrintOutputAs.PNG" />
                </div>
                <br>
                <div style="margin-left: 30px">
                    <b>On Firefox: </b><br /> (1) Click the Print button seen on Reports or in the Header to see a dialog
                    pop-up that allows printing.<br /><br /> (2) Set the Printer Name to Microsoft Print to PDF and then
                    click OK. <br /><br />
                    <img style="margin-left: 45px" width="90%" src="assets/images/system-requirements/FirefoxSaveAsPDF.PNG" />
                    <br /><br /> (3) A dialog called Save Print Output As should pop-up. On this dialog, specify a location
                    and filename for the PDF document, and click on Save to generate a PDF document.<br /><br />
                    <img style="margin-left: 45px" width="90%" src="assets/images/system-requirements/FirefoxSavePrintOutputAs.PNG" />
                </div>
                <br>
                <div style="margin-left: 30px">
                    <b>On Internet Explorer 11: </b><br /> (1) Click the Print button seen on Reports or in the Header to
                    see a dialog pop-up that allows printing.<br /><br /> (2) In the Select printer section, check the checkbox
                    for Microsoft Print to PDF and then click on the Print button. <br /><br />
                    <img style="margin-left: 45px" width="90%" src="assets/images/system-requirements/IE11SaveAsPDF.PNG" />
                    <br /><br /> (3) A dialog called Save Print Output As should pop-up. On this dialog, specify a location
                    and filename for the PDF document, and click on Save to generate a PDF document.<br /><br />
                    <img style="margin-left: 45px" width="90%" src="assets/images/system-requirements/IE11SavePrintOutputAs.PNG" />
                </div>
                <br>
                <div style="margin-left: 30px">
                    <b>On Internet Explorer 10: </b><br /> (1) Click the Print button seen on Reports or in the Header to
                    see a dialog pop-up that allows printing.<br /><br /> (2) In the Select printer section, select item
                    Send to OneNote 2007 and then click on the Print button. <br /><br />
                    <img style="margin-left: 45px" width="90%" src="assets/images/system-requirements/IE10SaveAsOneNote.PNG" />
                    <br /><br /> (3) This should launch OneNote. Click on this, and go to the File menu. Here onwards, at
                    least two options are available to convert the document to PDF format. The first option involves installing
                    a OneNote plug-in that allows publishing the document as a PDF. See below:<br /> <br />
                    <img style="margin-left: 45px" width="90%" src="assets/images/system-requirements/IE10PublishAsPDF.PNG" />
                    <br /><br /> (4) The second option would be to save the document as a Microsoft Word document, and then
                    use any available plug-in to convert from Microsoft Word to PDF. Saving the document using any of these
                    options should generate a PDF. <br /><br />
                    <img style="margin-left: 45px" width="90%" src="assets/images/system-requirements/IE10SaveAsWordToPDF.PNG" />
                    <br /><br />
                </div>
            </div>
        </div>
    </div>
</div>

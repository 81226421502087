import { EntityBase } from './EntityBase';
import { Material } from './Material';
import { TypeMaterialIdentifier } from './TypeMaterialIdentifier';

import { Validator } from 'breeze-client';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

export class MaterialAdditionalIdentifier extends EntityBase {

   /// <code> Place custom code between <code> tags
   constructor();
    constructor(obj: MaterialAdditionalIdentifier);

    constructor(obj?: MaterialAdditionalIdentifier) {
        super();

        this.materialAdditionalIdentifierId = obj && obj.materialAdditionalIdentifierId || 0;
        this.materialId                     = obj && obj.materialId || 0;
        this.cASNumber                      = obj && obj.cASNumber || null;
        this.eCL                            = obj && obj.eCL || null;
        this.eINECSNumber                   = obj && obj.eINECSNumber || null;
        this.eCNNumber                      = obj && obj.eCNNumber || null;
        this.dSLRegistration                = obj && obj.dSLRegistration || false;
        this.eCNRegistration                = obj && obj.eCNRegistration || false;
        this.eINECSRegistration             = obj && obj.eINECSRegistration || false;
        this.eCInventoryNumber              = obj && obj.eCInventoryNumber || null;
        this.nDSLRegistration               = obj && obj.nDSLRegistration || false;
        this.reachRegistration              = obj && obj.reachRegistration || false;
        this.tSCARegistration               = obj && obj.tSCARegistration || false;
        this.typeMaterialIdentifierId       = obj && obj.typeMaterialIdentifierId || null;
        this.created                        = obj && obj.created || null;
        this.createdUser                    = obj && obj.createdUser || null;
        this.modified                       = obj && obj.modified || null;
        this.modifyUser                     = obj && obj.modifyUser || null;
    }

    hasChanges(obj: MaterialAdditionalIdentifier) : boolean {
        if (obj == null) {return true;}

        if (this.materialAdditionalIdentifierId != obj.materialAdditionalIdentifierId) {return true;}
        if (this.materialId                 != obj.materialId) { return true; }
        if (this.cASNumber                  != obj.cASNumber) { return true; }
        if (this.eCL                        != obj.eCL) { return true; }
        if (this.eINECSNumber               != obj.eINECSNumber) { return true; }
        if (this.eCNNumber                  != obj.eCNNumber) { return true; }
        if (this.eCInventoryNumber          != obj.eCInventoryNumber) { return true; }
        if (this.dSLRegistration            != obj.dSLRegistration) { return true; }
        if (this.eCNRegistration            != obj.eCNRegistration) { return true; }
        if (this.eINECSRegistration         != obj.eINECSRegistration) { return true; }
        if (this.nDSLRegistration           != obj.nDSLRegistration) { return true; }
        if (this.reachRegistration          != obj.reachRegistration) { return true; }
        if (this.tSCARegistration           != obj.tSCARegistration) { return true; }
        if (this.typeMaterialIdentifierId   != obj.typeMaterialIdentifierId) { return true; }

        return false;
    }

    public hasRegistration() {
        return this.eINECSRegistration || this.dSLRegistration || this.nDSLRegistration || this.tSCARegistration || this.reachRegistration;
    }
   /// </code>

   // Generated code. Do not place code below this line.
   materialAdditionalIdentifierId: number;
   materialId: number;
   cASNumber: string;
   eINECSNumber: string;
   eCNNumber: string;
   eCL: string;
   dSLRegistration: boolean;
   eINECSRegistration: boolean;
   eCNRegistration: boolean;
   nDSLRegistration: boolean;
   reachRegistration: boolean;
   tSCARegistration: boolean;
   typeMaterialIdentifierId: string;
   eCInventoryNumber: string;
   created: Date;
   createdUser: string;
   modified: Date;
   modifyUser: string;
   rowVersion: number;
   material: Material;
   typeMaterialIdentifier: TypeMaterialIdentifier;
}


<editor-header [edm]="_edm" [femaRestricted]="true"></editor-header>

<default-table *ngIf="_edm.hasEntities()" [attr.data-hidden]="_edm.editing">
    <thead>
        <tr>
            <td>Topic</td>
            <td>Determination</td>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let e of _edm.entities" (click)="_edm.onSelect(e)">
            <td>{{e.topic}}</td>
            <td>{{e.determination}}</td>
            <!-- <td>{{s.entityAspect.entityState}}</td> -->
        </tr>
    </tbody>
</default-table>

<div *ngIf="_edm.editing">
    <house-status #self [entity]="_edm.currentEntity"></house-status>
</div>
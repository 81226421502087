import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';

import { StateMap } from '../../services/common';
import { LocationFns } from '../../utils/common';
import { TabContainer } from '../../controls/common';

import { Material } from '../../entities/EntityModels';
import { MATERIAL_ROUTES } from './material.routes';

@Component({
    selector: 'prediction-human-health',
    templateUrl: './prediction-human-health.html',
})
export class PredictionHumanHealthComponent implements OnInit, AfterViewInit {
    material: Material;
    @ViewChild(TabContainer, { static: true }) _tabContainer: TabContainer;
    _tab: number;

    constructor(public _stateMap: StateMap, public _location: Location) {
    }

    ngOnInit() {
        this.material = this._stateMap.currentMaterial;
        this._stateMap.currentRouteName = MATERIAL_ROUTES.Predictions.name;
        this._tab = LocationFns.getTab(this._location, 1) || 0;

    }

    ngAfterViewInit() {
        setTimeout(() => {
            this._tabContainer.selectTab(this._tab);
        }, 1);

    }

    isStaff() {
        return this._stateMap.currentUser.isStaff;
    }

}

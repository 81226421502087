import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { UserManager } from './user-manager';

@Injectable()
export class AuthHttpInterceptor implements HttpInterceptor {

    constructor(private userManager: UserManager) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add withCredentials to ensure that auth cookies are sent on CORS requests
        request = request.clone({
            withCredentials: true
        });

        let accessToken: string;
        if (request && request.url.includes('RIFMDocumentFileService')) {
            accessToken = this.userManager.currentUser.authenticationToken;
        } else {
            accessToken = this.userManager.accessToken;
        }
        // add token to http headers
        if (accessToken) {
            const authReq = request.clone({
                headers: request.headers.set('Authorization', 'Bearer ' + accessToken)
            });
            return next.handle(authReq)
                .pipe(tap((event: HttpEvent<any>) => {
                }, (err: any) => {
                    if (err.status === 401) {
                        this.userManager.logout();
                    }
                }));
        } else {
            return next.handle(request);
        }

    }
}

import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';

import { SearchService, StateMap, UnitOfWork } from '../../services/common';
import { LocationFns } from '../../utils/common';
import { TabContainer } from '../../controls/common';

import { MATERIAL_ROUTES } from './material.routes';

import { Material } from '../../entities/Material';

@Component({
    selector: 'relations',
    templateUrl: './relations.html',
})
export class RelationsComponent implements OnInit, AfterViewInit {
    material: Material;
    @ViewChild(TabContainer, { static: true }) _tabContainer: TabContainer;
    _tab: number;

    constructor(public _uow: UnitOfWork, public _stateMap: StateMap, public _searchService: SearchService, public _location: Location) {

    }

    ngOnInit() {
        this.material = this._stateMap.currentMaterial;
        this._stateMap.currentRouteName = MATERIAL_ROUTES.Relations.name;

        this._tab = LocationFns.getTab(this._location) || 0;
    }

    ngAfterViewInit() {
        setTimeout(() => {
            this._tabContainer.selectTab(this._tab);
        }, 1);

    }

    canDeactivate() {
        return !this.hasEdits();
    }

    hasEdits() {
        return this._uow.hasChanges();
    }


}

<div class="form-horizontal">

    <edit-prop label="CAS No" prop="registrationCASNumber" szs="2,3" >
        <input class="form-control" type="text" [(ngModel)]="entity.registrationCASNumber">
    </edit-prop>

    <edit-prop label="Name" prop="registrationName" szs="2,3" >
        <input class="form-control" type="text" [(ngModel)]="entity.registrationName">
    </edit-prop>

    <edit-prop label="Type" prop="registrationType" szs="2,3" >
        <input class="form-control" type="text" [(ngModel)]="entity.registrationType">
    </edit-prop>
    
    <edit-prop label="Dossier Web Link" prop="dossierWebLink" szs="2,3" >
        <input class="form-control" type="text" [(ngModel)]="entity.dossierWebLink">
    </edit-prop>

    <edit-prop label="Tonnage Band" prop="typeREACHTonnageBandId" szs="2,3" >
        <select class="form-control" [ngModel]="entity.typeREACHTonnageBandId" (change)="entity.typeREACHTonnageBandId=$event.target.value" >
            <option selected value>-- None --</option>
            <option *ngFor="let t of _types" [value]="t.typeREACHTonnageBandId">{{t.totalTonnageBand}}</option>
        </select>
    </edit-prop>

</div>
import { Component, Input, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { StateMap, UnitOfWork } from '../../services/common';
import { UtilFns } from '../../utils/common';

import { MATERIAL_ROUTES } from './material.routes';
import { Material } from '../../entities/Material';
import { MaterialConsumptionListItem } from '../../entities/projections/MaterialConsumptionListItem';


@Component({
    selector: 'material-consumptions',
    templateUrl: './material-consumptions.html',
})
export class MaterialConsumptionsComponent implements OnInit {
    @Input() material: Material;

    _material: Material;
    _materialConsumptions: MaterialConsumptionListItem[] = [];
    _consumptionsYearGroups: { year: string, consumptions: MaterialConsumptionListItem[], collapsed?: boolean }[] = [];
    _unitOfMeasure: string;

    _isLoading = true;

    constructor(public _uow: UnitOfWork, public _stateMap: StateMap) {

    }

    ngOnInit() {
        this._stateMap.currentRouteName = MATERIAL_ROUTES.MaterialConsumptions.name;
        this._material = this._stateMap.currentMaterial;
        if (this._material == null) {
            return;
        }

        this._isLoading = true;
        this._uow.fetch('MaterialReportedUses/FetchMaterialConsumption', {materialId: this._material.materialId}).then(r => {
            if (r != null && r.length > 0) {
                this._materialConsumptions = r;

                const groups = _.groupBy(this._materialConsumptions, c => c.surveyYear);

                this._consumptionsYearGroups = _.map(groups, (v, k) => {
                    return {year: k, consumptions: v, isCollapsed: false};
                });

                this._consumptionsYearGroups = _.sortBy(this._consumptionsYearGroups, (g) => g.year).reverse();
            }
            this._isLoading = false;
        });

        this._unitOfMeasure = 'Kilograms';
    }

    public hasConsumptions(): boolean {
        return (this._materialConsumptions != null && this._materialConsumptions.length > 0);
    }

    public get utilFns() {
        return UtilFns;
    }

    private yearGroupCollapse(ix: number) {
        this._consumptionsYearGroups[ix].collapsed = !this._consumptionsYearGroups[ix].collapsed;
    }

    public expandAll(expand: boolean) {
        setTimeout(() => {
            this._consumptionsYearGroups.forEach(g => g.collapsed = !expand);
        }, 0);
    }

    public setUnitOfMeasure(uom: string) {
        this._unitOfMeasure = uom;
    }

}

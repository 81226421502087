export interface AppConfig {
    enableLogging: boolean;
    environment?: string;
    sentryIoDsn?: string;
}

export const Settings = {
    enableLogging: true,
    environment: <string>null,
    sentryIoDsn: <string>null,

    initialize(value: AppConfig) {
        this.enableLogging = value.enableLogging;
        this.environment = value.environment;
        this.sentryIoDsn = value.sentryIoDsn;
    }
};

import { Component, Input, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import * as _ from 'lodash';
import { StateMap, UnitOfWork, UserManager } from '../../services/common';
import { LocationFns } from '../../utils/common';
import { PageState } from '../../controls/common';

import { MATERIAL_ROUTES } from './material.routes';
import { MaterialConsumption } from '../../entities/EntityModels';

@Component({
    selector: 'fragrance',
    templateUrl: './fragrance.html',
})
export class FragranceComponent implements OnInit {
    @Input() materialId: number;

    // _entities: MaterialConsumption[];
    _pageState = new PageState('Fragrance Use');
    _crossTab: {}[];
    _totals: {} = {};

    constructor(public _uow: UnitOfWork, public _userManager: UserManager, public _stateMap: StateMap,
                public _location: Location) {
    }

    ngOnInit() {
        LocationFns.setTab(this._location, 0);

        this._stateMap.currentRouteName = MATERIAL_ROUTES.FragranceUse.name;

        const p = {materialId: this.materialId, materialType: 'fragrance'};
        this._pageState.isLoading = true;
        this._uow.materialConsumptionRepository.whereWithParams(p).then(r => {
            this._crossTab = this.crossTabulate(r);
            this._pageState.isLoaded = this._crossTab;
        });
    }

    onSelect(crossTabRow: {}) {
    }

    crossTabulate(mcs: MaterialConsumption[]) {
        mcs = _.sortBy(mcs, ['surveyYear']);
        // because of 'bad' data in the db.
        const areaMap: { [area: string]: string; } = {
            'China': 'China',
            'Japan': 'Japan',
            'Asia-Pacific': 'Asia-Pacific',
            'Europe': 'Europe',
            'EEC': 'Europe',
            'North America': 'North America',
            'USA': 'North America',
            'South America': 'South America',
            'SOUTHAMERICA': 'South America'
        };
        const r: {} [] = [];
        let currentRow = {};
        let currentYear = '';
        mcs.forEach(mc => {
            const year = mc.surveyYear;
            if (year != currentYear) {
                currentYear = year;
                currentRow = {year: year};
                r.push(currentRow);
            }
            const area = areaMap[mc.geographicalArea] || 'Other';
            currentRow[area] = (currentRow[area] || 0) + mc.kilogramsConsumed;
            currentRow['total'] = (currentRow['total'] || 0) + mc.kilogramsConsumed;
            this._totals[area] = (this._totals[area] || 0) + mc.kilogramsConsumed;
            this._totals['total'] = (this._totals['total'] || 0) + mc.kilogramsConsumed;
        });
        return r;
    }
}

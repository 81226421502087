import { EntityBase } from './EntityBase';
import { Validator } from 'breeze-client';
import { Journal } from './Journal';
import { TypeJournalSubscription } from './TypeJournalSubscription';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>
export class JournalSubscription extends EntityBase {

    /// <code> Place custom code between <code> tags

    /// </code>

    // Generated code. Do not place code below this line.
    journalSubscriptionId: number;
    journalId: number;
    typeJournalSubscriptionId: string;
    subscriptionStartMonth: number;
    subscriptionStartYear: number;
    subscriptionEndMonth: number;
    subscriptionEndYear: number;
    issuesPerYear: string;
    department: string;
    subscriptionPayer: string;
    publisher: string;
    membershipSubscription: string;
    subscriptionAccountNumbers: string;
    subscriptionContact: string;
    paymentActivity: string;
    orderNumber: string;
    notes: string;
    created: Date;
    createdUser: string;
    modified: Date;
    modifyUser: string;
    rowVersion: number;
    journal: Journal;
    typeJournalSubscription: TypeJournalSubscription;
}


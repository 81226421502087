import { EntityBase } from './EntityBase';
import { Synonym } from './Synonym';
import { TypeSynonymUse } from './TypeSynonymUse';

import { Validator } from 'breeze-client';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

export class SynonymNamer extends EntityBase {

   /// <code> Place custom code between <code> tags
   
   /// </code>

   // Generated code. Do not place code below this line.
   synonymId: number;
   typeSynonymUseId: string;
   created: Date;
   createdUser: string;
   modified: Date;
   modifyUser: string;
   rowVersion: number;
   synonym: Synonym;
   typeSynonymUse: TypeSynonymUse;
}


import { Injectable } from '@angular/core';
import { Location } from '@angular/common';

import { EntityManagerProvider } from './entity-manager-provider';

import * as Raven from 'raven-js';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Router, UrlTree } from '@angular/router';

declare const $: any;
declare var ga: Function; // Google analytics

@Injectable()
export class UserManager {
    private _fileServiceURL: string;
    private _oauthRedirectURL: string;

    _currentUser: User = User.nullUser;
    _loginHandle: any;
    _loginErrorMessage: string;
    _userAcknowledgmentDurationDays: number;

    sessionDurationMinutes: number;

    public static versionNo = '2.0.7.12';

    constructor(public _http: HttpClient, private _emProvider: EntityManagerProvider, private _location: Location, private _router: Router) {
    }

    public get currentUser() {
        return this._currentUser;
    }

    public get accessToken() {
        return sessionStorage.getItem('accessToken');
    }

    public set accessToken(value: string) {
        if (value && value.length > 20) {
            sessionStorage.setItem('accessToken', value);
        } else {
            sessionStorage.removeItem('accessToken');
        }
    }

    public get destUrl() {
        return sessionStorage.getItem('destUrl');
    }

    public set destUrl(value: string) {
        if (value && value.length > 4) {
            sessionStorage.setItem('destUrl', value);
        } else {
            sessionStorage.removeItem('destUrl');
        }
    }

    public logout() {
        this._currentUser = User.nullUser;
        return this._http.get(`${environment.appUrl}/breeze/logout`)
            .toPromise()
            .then(() => {
                Raven.setUserContext();
                this.accessToken = null;
            }).catch(() => {
                console.log('Unable to logout.');
            });
    }

    public async checkIfLoggedInOAuth(): Promise<boolean | UrlTree> {

        this.checkSessionTimeout();

        if (!this._currentUser.isNullUser) {
            // December 2020: Prevent users assigned role of "AuthorizedDownloadUser"
            if (this._currentUser.isAuthorizedDownloadUser) {
                return Promise.resolve(false);
            }

            // Make sure the user hasn't logged out from a different tab
            const isUserLoggedInPromise = this._http.get(`${environment.appUrl}/breeze/TestIfLoggedIn`).toPromise();
            const res = await this.handleAuthResponse(isUserLoggedInPromise, true);
            if (res) {
                return res;
            }
        }

        const loc = window.location.href;

        if (loc.toLowerCase().indexOf('logout') > -1) {
            return this.showLogin().then(() => false);
        }

        if (!loc.includes('after-login')) {
            // remember url so we can return to it after login
            this.destUrl = this._location.path();
        }

        // Get user from the server if current accessToken or session is valid, so we don't need to login again
        const isLoggedInPromise = this._http.get(`${environment.appUrl}/breeze/TestIfLoggedIn`).toPromise();
        const result = await this.handleAuthResponse(isLoggedInPromise, false);
        if (result) { return result; }

        // Get the URL for YM login
        if (!this.isValidString(this._oauthRedirectURL)) {
            await this.queryOAuthRedirectURL().then(url => {
                if (this.isValidString(url)) {
                    this._oauthRedirectURL = url;
                }
            });
        }

        if (loc.toLowerCase().indexOf('after-login') == -1) {
            // Not logged in and null User so redirect to YM OAuth site.
            window.location.href = this._oauthRedirectURL;
            return false;
        }

        let code: string;
        const params = loc.match(/code=([^&]*)/);
        if (params != null) {
            code = params[1];
        } else {
            this._loginErrorMessage = 'Error, missing OAuth code';
            return this.showLogin().then(() => false);
        }

        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        const validateTokenPromise = this._http.post(`${environment.appUrl}/breeze/validateOAuthSessionToken`, JSON.stringify({code: code}), { headers: headers })
            .toPromise();
        return this.handleAuthResponse(validateTokenPromise);
    }

    /** Handle the response from the server for login or testIfLoggedIn. Sets the currentUser. */
    private handleAuthResponse(requestPromise: Promise<Object>, showLogin: boolean = true): Promise<boolean | UrlTree> {
        return requestPromise.then(res => {
            this.initializeUserFromResponse(<any>res);
            return this._http.get(`${environment.appUrl}/breeze/fileServiceURL`, {})
                .toPromise()
                .then((url: any) => {
                    this._fileServiceURL = url;
                    return this._emProvider.prepare();
                });
        })
        .then(() => {
            const allowed = !this._currentUser.isAuthorizedDownloadUser;
            if (allowed && window.location.href.toLowerCase().includes('after-login')) {
                const loc = this.destUrl;
                if (loc) {
                    const tree = this._router.parseUrl(loc);
                    this.destUrl = null;
                    return tree;
                }
            }
            return allowed;
        })
        .catch(e => {
            // log error if not a 405 ( Method not allowed) error.
            if (e.status != 405) {
                console.log('login check received unknown error: ' + e.status);
            }
            this._loginErrorMessage = 'Error attempting to validate RIFM Database user.';
            if (showLogin) {
                return this.showLogin().then(() => false);
            } else {
                return false;
            }
        });

    }

    public checkSessionTimeout() {
        // force logout after sessionDurationMinutes - timer is reset each time a top level route is hit.
        if (!this.sessionDurationMinutes) {
            return;
        }

        if (this._loginHandle) {
            clearTimeout(this._loginHandle);
        }
        if (this.sessionDurationMinutes) {
            this._loginHandle = setTimeout(() => {
                return this.logout().then(() => {
                    // doesn't seem to work with virtual dirs - but works fine locally
                    // document.location.href='/home';
                    this._location.replaceState('/home');
                    document.location.reload();
                });
            }, this.sessionDurationMinutes * 60 * 1000);
        }
    }

    public getFileServiceFilePathUrl(filePath: string) {
        return this.getFileServiceUrl('GetFileByPath.aspx?filepath=' + filePath);
    }

    public getFFIDSUrl(materialId: number) {
        return `${this.fileServiceUrl}GetFFIDS.aspx?materialid=${materialId.toString()}&sessionid=${this.currentUser.authenticationToken}`;
    }

    public getMonographUrl(monographNumber: number) {
        return `${this.fileServiceUrl}GetMonograph.aspx?fname=${monographNumber.toString()}.pdf&sessionid=${this.currentUser.authenticationToken}`;
    }

    public getExpertPanelSummaryUrl(narrativeLink: string) {
        return `${this.fileServiceUrl}GetExpertPanelSummary.aspx?fname=${narrativeLink}&sessionid=${this.currentUser.authenticationToken}`;
    }

    public getFileServiceUrl(paramString: string) {
        return this.fileServiceUrl + paramString + '&sessionid=' + this.currentUser.authenticationToken;
    }

    public getReportUrl(params: { key: string, value: string }[]) {
        let url = `${this.fileServiceUrl}GetReport.aspx?&sessionid=${this.currentUser.authenticationToken}`;

        params.forEach(function (el) {
            url = url + '&' + el.key + '=' + el.value;
        });

        return url;
    }

    public getSearchServiceReportUrl() {
        return `${this.fileServiceUrl}GetSearchServiceReferences.aspx?&sessionid=${this.currentUser.authenticationToken}`;
    }

    public getUVUrl(fileName: string) {
        return `${this.fileServiceUrl}GetUV.aspx?fname=${fileName}&sessionid=${this.currentUser.authenticationToken}`;
    }

    public get UserAcknowledgementDuration(): number {
        return this._userAcknowledgmentDurationDays;
    }

    showLogin(): Promise<any> {
        return new Promise((resolve) => {
            const ele = <any>$('#login-modal');
            ele.modal({ backdrop: 'static', keyboard: false })
                .on('hidden.bs.modal', () => {
                    resolve(true);
                });
        });
    }

    initializeUserFromResponse(data: any) {
        this._currentUser = new User(data.UserName, data.AuthenticationToken, data.Roles, new Date(data.SessionStartTime), data.SessionDuration, data.IsAuthorizedDownloadUser);

        if (data.AccessToken) {
            this.accessToken = data.AccessToken;
        }

        this._userAcknowledgmentDurationDays = Number(data.UserAcknowledgementDuration);
        this.sessionDurationMinutes = data.SessionDuration || 6 * 60;
        // Set user context for logging purposes
        Raven.setUserContext({
            id: data.$id,
            email: data.UserName,
            username: data.UserName
        });
        // uncomment to test session timeout;
        // this.sessionDurationMinutes = .1;
        this.checkSessionTimeout();

        // initialize Google Analytics
        this.trackUser();

    }

    public get fileServiceUrl(): string {
        if (this._fileServiceURL == null) {
            this._http.get(`${environment.appUrl}/breeze/fileServiceURL`, {})
                .toPromise().then((url: any) => {
                this._fileServiceURL = url;
                return this._fileServiceURL;
            });
        } else {
            return this._fileServiceURL;
        }
    }

    public queryOAuthRedirectURL(): Promise<string> {
        return this._http.get(`${environment.appUrl}/breeze/oAuthRedirectURL`, {})
            .toPromise()
            .then((url: any) => {
                return url;
            });
    }

    public get OAuthRedirectURL(): string {
        return this._oauthRedirectURL;
    }

    isValidString(teststring: string) {
        if (!teststring) { return false; }

        return (teststring.trim().length > 0);
    }

    // Google Analytics
    trackUser() {
        if (this._currentUser == null) { return; }
        ga('set', {
            'userId': this._currentUser.name,
            'appVersion': UserManager.versionNo
        });
    }
}

export class User {

    public static nullUser = new User('Null User', null, [], new Date(), 1E6, false);
    _roles: string[];
    _tempRoles: string[];
    _isAuthorizedDownloadUser = false;

    constructor(public _name: string, public _authenticationToken: string,
                roles: string[],
                public _sessionStartTime: Date,
                public _sessionDuration: number,
                public _dbAuthorizedDownloadUser: boolean) {
        this._roles = roles.map(r => r.toLowerCase());
        this._isAuthorizedDownloadUser = _dbAuthorizedDownloadUser;
    }

    public get name() {
        return this._name;
    }

    public get isNullUser() {
        return this === User.nullUser;
    }

    // used by staff or admin user to simulate regular user.
    public toggleRoles() {
        if (this._tempRoles) {
            this._tempRoles = null;
        } else {
            this._tempRoles = ['subscriber'];
        }
    }

    public get authenticationToken() {
        return this._authenticationToken;
    }

    public get isAuthorizedDownloadUser() {
        return this._isAuthorizedDownloadUser;
    }

    public set isAuthorizedDownloadUser(value) {
        this._isAuthorizedDownloadUser = value;
    }

    public get isAdmin() {
        return this.isInRole('admin');
    }

    public get isStaff() {
        return this.isInRole('staff') || this.isInRole('admin');
    }

    public get isReallyStaff() {
        return this.isInRole('staff', true) || this.isInRole('admin', true);
    }

    public isInRole(roleName: string, useRealRoles?: boolean): boolean {
        const roles = useRealRoles ? this._roles : this._tempRoles || this._roles;
        return roles.indexOf(roleName.toLowerCase()) >= 0;
    }

    public get sessionStartTime(): Date {
        return this._sessionStartTime;
    }

    public get sessionDuration(): number {
        return this._sessionDuration;
    }
}




import { Injectable } from '@angular/core';
import * as _ from 'lodash';

import { Entity } from 'breeze-client';
import { AnalyticalResult, ExperimentalMaterial, ExperimentalResult, Reference } from '../entities/EntityModels';
import { WorkflowActivityCompletedItem } from '../entities/projections/WorkflowActivityCompletedItem';

import { HttpClient } from '@angular/common/http';
import { ReferenceFinishedService, ReferenceFinishState } from './reference-finished-service';
import { WorkflowService } from './workflow-service';
import { WorkflowDeletionService } from './workflow-deletion-service';
import { UnitOfWork } from './unit-of-work';
import { WorkflowEntityState, WorkflowState } from './workflow-state';

@Injectable()
export class ReferenceState {

    private _referenceFinishedService: ReferenceFinishedService;
    private _workflowService: WorkflowService;
    private _workflowDeletionService: WorkflowDeletionService;


    constructor(private _http: HttpClient, private _uow: UnitOfWork, referenceFinishedService: ReferenceFinishedService,
                workflowService: WorkflowService) {

        // const injector = appInjector();
        this._referenceFinishedService = referenceFinishedService;
        this._workflowService = workflowService;
        this._workflowDeletionService = new WorkflowDeletionService(this._uow, this._workflowService, this._referenceFinishedService);
    }

    // ----------------------------------------------------------------------------------------------------------------------------------
    // Logic for specific actions to insert that aren't recorded as entity or property changes.
    // ----------------------------------------------------------------------------------------------------------------------------------
    public recordReferenceFinishedStateChange(reference: Reference) {

        const workflow = new WorkflowState();

        workflow.workflowReferenceId = reference.referenceId;
        workflow.workflowEntityName = reference.entityType.shortName;
        workflow.workflowPropertyName = (reference.finished) ? 'finished=true' : 'finished=false';
        workflow.workflowEntityState = WorkflowEntityState.Modified;

        this._workflowService.addWorkflowEvent(workflow, reference);

    }

    // Record an Action that the staff member has marked a Reference as Finished from the UI
    public finishOverride(reference: Reference) {

        const workflow = new WorkflowState();

        workflow.workflowReferenceId = reference.referenceId;
        workflow.workflowEntityName = reference.entityType.shortName;
        workflow.workflowEntityState = WorkflowEntityState.Modified;
        workflow.workflowPropertyName = 'finished=override';

        this._workflowService.addWorkflowEvent(workflow, reference);
    }

    public recordAnalyticalResultAdd(reference: Reference, materialId: number) {

        const workflow = new WorkflowState();

        workflow.workflowReferenceId = reference.referenceId;
        // workflow.workflowMaterialId     = materialId; This is causing an issue with navigation of the entity
        workflow.workflowEntityName = 'AnalyticalResult';
        workflow.workflowEntityState = WorkflowEntityState.Added;

        this._workflowService.addWorkflowEvent(workflow, reference);

        this.recordLinkedAction(reference);
    }

    public recordAnalyticalResultDelete(reference: Reference, materialId: number) {

        const workflow = new WorkflowState();

        workflow.workflowReferenceId = reference.referenceId;
        // workflow.workflowMaterialId     = materialId; This is causing an issue with navigation of the entity
        workflow.workflowEntityName = 'AnalyticalResult';
        workflow.workflowEntityState = WorkflowEntityState.Deleted;

        this._workflowService.addWorkflowEvent(workflow, reference);

        this.recordLinkedAction(reference);
    }

    public recordAnalyticalResultsChecked(reference: Reference) {
        const workflow = new WorkflowState();

        workflow.workflowReferenceId = reference.referenceId;
        workflow.workflowEntityName = reference.entityType.shortName;
        workflow.workflowPropertyName = 'linkedAnalyticalResultsChecked';
        workflow.workflowEntityState = WorkflowEntityState.Modified;

        this._workflowService.addWorkflowEvent(workflow, reference);
    }

    // ----------------------------------------------------------------------------------------------------------------------------------
    // Protocol, Material Links and Tox Data
    // ----------------------------------------------------------------------------------------------------------------------------------
    public recordProtocolAdd(reference: Reference) {

        const workflow = new WorkflowState();

        workflow.workflowReferenceId = reference.referenceId;
        workflow.workflowEntityName = 'BiologicalData';
        workflow.workflowEntityState = WorkflowEntityState.Added;

        const datacheck = (this._referenceFinishedService.hasIncompleteProtocolData(reference));
        const pending = this._workflowService.referenceActionsWithPendingRequiredAction(reference, 'PROTOCOL ADDED');

        if (datacheck.missingBiologicalData == false && (pending != null && pending.length > 0)) {
            workflow.workflowPropertyName = 'more';
        }
        this._workflowService.addWorkflowEvent(workflow, reference);
    }

    public recordProtocolDelete(reference: Reference) {

        const workflow = new WorkflowState();

        workflow.workflowReferenceId = reference.referenceId;
        workflow.workflowEntityName = 'BiologicalData';
        workflow.workflowEntityState = WorkflowEntityState.Deleted;

        this._workflowService.addWorkflowEvent(workflow, reference);
    }

    // Add action to mark linked requirement for a Reference complete
    public recordLinkedAction(reference: Reference) {
        const workflow = new WorkflowState();

        workflow.workflowReferenceId = reference.referenceId;
        workflow.workflowEntityName = 'Reference';
        workflow.workflowPropertyName = 'linkable';
        workflow.workflowEntityState = WorkflowEntityState.Modified;

        this._workflowService.addWorkflowEvent(workflow, reference);
    }

    public recordMaterialLinkAdd(reference: Reference) {

        const workflow = new WorkflowState();

        workflow.workflowReferenceId = reference.referenceId;
        workflow.workflowEntityName = 'ExperimentalMaterial';
        workflow.workflowEntityState = WorkflowEntityState.Added;

        const datacheck = (this._referenceFinishedService.hasIncompleteProtocolData(reference));
        // var pending = this._workflowService.referenceActionsWithPendingRequiredAction(reference, 'MATERIAL LINKED');

        // Each Protocol requires a Material Link (Exp Mat) so even though a Material Link may have been created, there may be more Protocols still missing a link
        if (datacheck.missingExperimentalMaterials) {
            workflow.workflowPropertyName = 'incomplete';
        } else {
            workflow.workflowPropertyName = 'more';
        }

        this._workflowService.addWorkflowEvent(workflow, reference);

        this.recordLinkedAction(reference);
    }

    public recordMaterialLinkDelete(reference: Reference) {

        const workflow = new WorkflowState();

        workflow.workflowReferenceId = reference.referenceId;
        workflow.workflowEntityName = 'ExperimentalMaterial';
        workflow.workflowEntityState = WorkflowEntityState.Deleted;

        const datacheck = (this._referenceFinishedService.hasIncompleteProtocolData(reference));

        if (datacheck.missingBiologicalData == false && datacheck.missingExperimentalMaterials == false && datacheck.missingExperimentalResults == false) {
            // The bio data could again have a full complement of data but trigger an audit request to make sure all changes are valid
            workflow.workflowPropertyName = 'addauditafterdelete';
        }

        this._workflowService.addWorkflowEvent(workflow, reference);

        this.recordLinkedAction(reference);
    }

    public recordToxDataAdd(reference: Reference) {

        const workflow = new WorkflowState();

        workflow.workflowReferenceId = reference.referenceId;
        workflow.workflowEntityName = 'ExperimentalResult';
        workflow.workflowEntityState = WorkflowEntityState.Added;

        const datacheck = (this._referenceFinishedService.hasIncompleteProtocolData(reference));

        // Each Experimental Material (Material Link) requires Tox Data (Exp Result) so even though Tox Data may have been created,
        // there may be more Experimental Materials still missing Tox Data
        if (datacheck.missingExperimentalResults) {
            workflow.workflowPropertyName = 'incomplete'; // Will not complete the Tox Data requirement or add a new pending task
        } else {
            workflow.workflowPropertyName = 'more';
        }

        this._workflowService.addWorkflowEvent(workflow, reference);
    }

    public recordToxDataDelete(reference: Reference) {

        const workflow = new WorkflowState();

        workflow.workflowReferenceId = reference.referenceId;
        workflow.workflowEntityName = 'ExperimentalResult';
        workflow.workflowEntityState = WorkflowEntityState.Deleted;

        this._workflowService.addWorkflowEvent(workflow, reference);
    }

    public recordToxAudit(reference: Reference) {
        const workflow = new WorkflowState();

        workflow.workflowReferenceId = reference.referenceId;
        workflow.workflowEntityName = reference.entityType.shortName;
        workflow.workflowPropertyName = 'toxDataAudited';
        workflow.workflowEntityState = WorkflowEntityState.Modified;

        this._workflowService.addWorkflowEvent(workflow, reference);
    }

    public recordSpecialLinkAdd(reference: Reference) {
        const workflow = new WorkflowState();

        workflow.workflowReferenceId = reference.referenceId;
        workflow.workflowEntityName = 'SpecialLink';
        workflow.workflowEntityState = WorkflowEntityState.Added;

        this._workflowService.addWorkflowEvent(workflow, reference);

        this.recordLinkedAction(reference);
    }

    public recordSpecialLinkDelete(reference: Reference) {
        const workflow = new WorkflowState();

        workflow.workflowReferenceId = reference.referenceId;
        workflow.workflowEntityName = 'SpecialLink';
        workflow.workflowEntityState = WorkflowEntityState.Deleted;

        this._workflowService.addWorkflowEvent(workflow, reference);
    }

    // ----------------------------------------------------------------------------------------------------------------------------------
    // Hold Actions
    // ----------------------------------------------------------------------------------------------------------------------------------
    public recordAddHold(reference: Reference, reason: string) {
        const workflow = new WorkflowState();

        workflow.workflowReferenceId = reference.referenceId;
        workflow.workflowEntityName = reference.entityType.shortName;
        workflow.workflowPropertyName = 'addHold';
        workflow.workflowHoldReason = reason;
        workflow.workflowEntityState = WorkflowEntityState.Modified;

        this._workflowService.addWorkflowEvent(workflow, reference);
    }

    public recordRemoveHold(reference: Reference) {
        const workflow = new WorkflowState();

        workflow.workflowReferenceId = reference.referenceId;
        workflow.workflowEntityName = reference.entityType.shortName;
        workflow.workflowPropertyName = 'removeHold';
        workflow.workflowEntityState = WorkflowEntityState.Modified;

        this._workflowService.addWorkflowEvent(workflow, reference);
    }

    public getHoldAction(reference: Reference): { isOnHold: boolean; holdReason: string } {

        if (reference.workflowRecordedActions == null || reference.workflowRecordedActions.length == 0) {
            return { isOnHold: false, holdReason: '' };
        }

        const pending = reference.workflowRecordedActions.filter(a => a.completedWorkflowActionId == 'REMOVE HOLD' && a.actionCompletedDate == null);

        if (pending == null || pending.length == 0) {
            return { isOnHold: false, holdReason: '' };
        }

        return { isOnHold: true, holdReason: pending[0].holdReason };
    }

    // ----------------------------------------------------------------------------------------------------------------------------------
    // Reference Document Management
    // ----------------------------------------------------------------------------------------------------------------------------------
    public recordRIFMDocument(reference: Reference, workflowEntityState: WorkflowEntityState, archived: boolean) {

        const workflow = new WorkflowState();

        workflow.workflowReferenceId    = reference.referenceId;
        workflow.workflowEntityName 	= reference.entityType.shortName;
        workflow.workflowEntityState    = workflowEntityState;
        workflow.workflowPropertyName   = (archived) ? 'rIFMDocumentArchive' : 'rIFMDocument';

        this._workflowService.addWorkflowEvent(workflow, reference);
    }

    public recordRIFMDocumentAdded(reference: Reference, workflowEntityState: WorkflowEntityState) {

        const workflow = new WorkflowState();

        workflow.workflowReferenceId    = reference.referenceId;
        workflow.workflowEntityName 	= reference.entityType.shortName;
        workflow.workflowEntityState    = workflowEntityState;
        workflow.workflowPropertyName   = 'rIFMDocument';

        this._workflowService.addWorkflowEvent(workflow, reference);
    }

    public recordRIFMDocumentArchive(reference: Reference, workflowEntityState: WorkflowEntityState) {

        const workflow = new WorkflowState();

        workflow.workflowReferenceId    = reference.referenceId;
        workflow.workflowEntityName 	= reference.entityType.shortName;
        workflow.workflowEntityState    = workflowEntityState;
        workflow.workflowPropertyName 	= 'archived';

        this._workflowService.addWorkflowEvent(workflow, reference);
    }

    public recordRIFMDocumentFileModified(reference: Reference, workflowEntityState: WorkflowEntityState) {

        const workflow = new WorkflowState();

        workflow.workflowReferenceId    = reference.referenceId;
        workflow.workflowEntityName 	= reference.entityType.shortName;
        workflow.workflowEntityState    = workflowEntityState;
        workflow.workflowPropertyName   = 'file';

        this._workflowService.addWorkflowEvent(workflow, reference);
    }

    public recordRIFMDocumentFileDelete(reference: Reference, workflowEntityState: WorkflowEntityState) {

        const workflow = new WorkflowState();

        workflow.workflowReferenceId    = reference.referenceId;
        workflow.workflowEntityName 	= reference.entityType.shortName;
        workflow.workflowEntityState    = workflowEntityState; // Should be Modified
        workflow.workflowPropertyName 	= 'file deleted';

        this._workflowService.addWorkflowEvent(workflow, reference);
    }

    public recordRIFMDocumentRemove(reference: Reference, workflowEntityState: WorkflowEntityState) {

        const workflow = new WorkflowState();

        workflow.workflowReferenceId    = reference.referenceId;
        workflow.workflowEntityName 	= reference.entityType.shortName;
        workflow.workflowEntityState    = workflowEntityState; // Should be Deleted
        workflow.workflowPropertyName 	= 'rIFMDocument';

        this._workflowService.addWorkflowEvent(workflow, reference);
    }

    public recordRIFMDocumentReplace(reference: Reference, workflowEntityState: WorkflowEntityState) {

        const workflow = new WorkflowState();

        workflow.workflowReferenceId    = reference.referenceId;
        workflow.workflowEntityName 	= reference.entityType.shortName;
        workflow.workflowEntityState    = workflowEntityState;
        workflow.workflowPropertyName 	= 'replaced';

        this._workflowService.addWorkflowEvent(workflow, reference);
    }

    public recordRIFMDocumentRestore(reference: Reference, workflowEntityState: WorkflowEntityState) {

        const workflow = new WorkflowState();

        workflow.workflowReferenceId    = reference.referenceId;
        workflow.workflowEntityName 	= reference.entityType.shortName;
        workflow.workflowEntityState    = workflowEntityState;
        workflow.workflowPropertyName 	= 'restored';

        this._workflowService.addWorkflowEvent(workflow, reference);
    }

    // ******************************************************************************************************
    // Update actions that can occur at the time the Reference is created
    // ******************************************************************************************************
    public recordLinkable(reference: Reference) {
        const workflow = new WorkflowState();

        workflow.workflowReferenceId = reference.referenceId;
        workflow.workflowEntityName = reference.entityType.shortName;
        workflow.workflowPropertyName = 'linkable=true';
        workflow.workflowEntityState = WorkflowEntityState.Modified;

        this._workflowService.addWorkflowEvent(workflow, reference);
    }

    public recordSearchService(reference: Reference) {
        const workflow = new WorkflowState();

        workflow.workflowReferenceId = reference.referenceId;
        workflow.workflowEntityName = reference.entityType.shortName;
        workflow.workflowPropertyName = 'typeSearchServiceId=' + reference.typeSearchServiceId;
        workflow.workflowEntityState = WorkflowEntityState.Modified;

        this._workflowService.addWorkflowEvent(workflow, reference);
    }

    // ----------------------------------------------------------------------------------------------------------------------------------
    // Evaluate Reference to determine if it has unaudited tox data
    // ----------------------------------------------------------------------------------------------------------------------------------
    public hasUnauditedToxData(reference: Reference) {

        if (reference.workflowRecordedActions == null || reference.workflowRecordedActions.length == 0) {
            return false;
        }

        const pending = reference.workflowRecordedActions.filter(a => a.completedWorkflowActionId == 'AUDIT TOX DATA' && a.actionCompletedDate == null);

        if (pending == null || pending.length == 0) {
            return false;
        }

        return true;
    }

    public hasAuditedToxData(reference: Reference) {

        if (reference.workflowRecordedActions == null || reference.workflowRecordedActions.length == 0) {
            return false;
        }

        // Make sure there aren't more recent pending actions
        const completed = this._workflowService.referenceMostRecentCompletedAction(reference, 'AUDIT TOX DATA');
        const pending = this._workflowService.referenceMostRecentPendingAction(reference, 'AUDIT TOX DATA');

        if (completed == null) {
            return false;
        }

        if (pending == null) { // Has completed action but no pending ones
            return true;
        }

        return false; // Has pending actions
    }

    // ----------------------------------------------------------------------------------------------------------------------------------
    // Evaluate Reference to determine if it has unchecked or checked AnalyticalResults
    // ----------------------------------------------------------------------------------------------------------------------------------
    public hasUncheckedAnalyticalResults(reference: Reference) {

        if (reference.workflowRecordedActions == null || reference.workflowRecordedActions.length == 0) {
            return false;
        }

        const pending = reference.workflowRecordedActions.filter(a => a.completedWorkflowActionId == 'CHECK PHYSICAL PROPERTY' && a.actionCompletedDate == null);

        if (pending == null || pending.length == 0) {
            return false;
        }

        return true;
    }

    public hasCheckedAnalyticalResults(reference: Reference) {

        if (reference.workflowRecordedActions == null || reference.workflowRecordedActions.length == 0) {
            return false;
        }

        // Make sure there aren't more recent pending actions
        const completed = this._workflowService.referenceMostRecentCompletedAction(reference, 'CHECK PHYSICAL PROPERTY');
        const pending = this._workflowService.referenceMostRecentPendingAction(reference, 'CHECK PHYSICAL PROPERTY');

        if (completed == null) {
            return false;
        }

        if (pending == null) { // Has completed action but no pending ones
            return true;
        }

        return false; // Has pending actions
    }

    // ----------------------------------------------------------------------------------------------------------------------------------
    // Evaluate list of entity/property changes
    // ----------------------------------------------------------------------------------------------------------------------------------
    public recordEntityChange(e: Entity, reference: Reference, relatedEntityKey?: string) {

        const workflow = new WorkflowState();

        workflow.workflowReferenceId = reference.referenceId; // Even though the update may have been on a related table, the event is associated with the Reference being edited.
        workflow.workflowEntityName = e.entityType.name;

        if (relatedEntityKey != null) {   // The related object key is also stored.
            workflow.workflowObjectId = relatedEntityKey;
        }

        if (e.entityAspect.entityState.isAdded()) {

            workflow.workflowEntityState = WorkflowEntityState.Added;

            this._workflowService.addWorkflowEvent(workflow, reference);

            if (e.entityType.shortName == 'Reference' && reference.linkable) {
                this.recordLinkable(reference);
            }

            if (e.entityType.shortName == 'Reference' && reference.typeSearchServiceId != 'Not Sent') {
                this.recordSearchService(reference);
            }
        }

        if (e.entityAspect.entityState.isModified()) {

            const changedProperties = e.entityAspect.originalValues;
            for (const propName in changedProperties) {

                if (!this._workflowService.ignoreThisProperty(propName)) {

                    if (this.appendValueToReferenceProperty(propName) && e.entityType.shortName == 'Reference') {
                        workflow.workflowPropertyName = propName + '=' + e[propName].toString();
                    } else {
                        workflow.workflowPropertyName = propName;
                    }

                    const xtraPropertyInfo = this.specialProcessing(reference, propName, e[propName]);
                    if (xtraPropertyInfo != '') {
                        workflow.workflowPropertyName = workflow.workflowPropertyName.concat(xtraPropertyInfo);
                    }

                    workflow.workflowEntityState = WorkflowEntityState.Modified;

                    this._workflowService.addWorkflowEvent(workflow, reference);
                }
            }
        }
    }

    private appendValueToReferenceProperty(propertyName: string): boolean {
        if (propertyName == 'complete' || propertyName == 'linkable' || propertyName == 'typeSearchServiceId') {
            return true;
        }

        return false;
    }

    private specialProcessing(reference: Reference, propertyName: string, state: boolean): string {
        if (propertyName == 'linkable' && state == true) {
            const actions = this._workflowService.referenceActions(reference, 'SENT TO');
            if (actions != null && actions.length > 0) {
                return ',ignore sent';
            }
        }

        return '';
    }

    // ----------------------------------------------------------------------------------------------------------------------------------
    // Evaluate Pending Workflow when linked data has been deleted
    // ----------------------------------------------------------------------------------------------------------------------------------
    public reconcileLinkedAnalyticalResultDeletesWithPendingActions(reference: Reference, analyticalResults?: AnalyticalResult[]) {
        return (this._workflowDeletionService.reconcileLinkedAnalyticalResultDeletesWithPendingActions(reference, analyticalResults));
    }

    public hasBioDataDeletionsToProcess(reference: Reference): boolean {
        return (this._workflowDeletionService.hasBioDataDeletionsToProcess(reference));
    }

    // Close any pending actions that don't make sense when Protocol or Tox Data is Deleted
    public reconcileProtocolDeletesWithPendingActions(reference: Reference, expmats?: ExperimentalMaterial[]) {
        this._workflowDeletionService.reconcileProtocolDeletesWithPendingActions(reference, expmats);
    }

    // ----------------------------------------------------------------------------------------------------------------------------------
    // Reference Completed Tasks
    // ----------------------------------------------------------------------------------------------------------------------------------
    public getCompletedWorkflowActions(reference: Reference): WorkflowActivityCompletedItem[] {
        const completedActions: WorkflowActivityCompletedItem[] = [];

        if (reference.workflowRecordedActions == null || reference.workflowRecordedActions.length == 0) {
            return completedActions;
        }

        let count = 0;

        const actions = reference.workflowRecordedActions.filter(a => a.typeWorkflowAction.description != null);
        actions.forEach(a => {
            let sentToName: string;

            if (this.isSentToOrNotifyAction(a.typeWorkflowActionId)) {
                if (a.completedWorkflowActionId == 'REASSIGN') {
                    sentToName = 'Reassigned from ' + ((a.sentToTypeWorkflowContact) ? a.sentToTypeWorkflowContact.firstName : '');
                } else {
                    sentToName = ((a.sentToTypeWorkflowContact) ? a.sentToTypeWorkflowContact.firstName : '');
                }
            } else {
                sentToName = '';
            }

            const item: WorkflowActivityCompletedItem = {
                workflowActivityCompletedItemId: count++,
                workflowRecordedActionId: a.workflowRecordedActionId,
                workflowContactId: a.createdWorkflowContactId,
                staffFirstName: (a.createdTypeWorkflowContact) ? a.createdTypeWorkflowContact.firstName : a.createdUser,
                typeWorkflowActionId: a.typeWorkflowActionId,
                workflowActionDescription: a.typeWorkflowAction.description,
                created: a.created,
                actionData: sentToName,
                canDeleteFromWorkflowHistory: a.typeWorkflowAction.canDeleteFromWorkflowHistory,
                completedOnlyType: a.typeWorkflowAction.completedOnlyType
            };
            completedActions.push(item);
        });

        const completedNext = reference.workflowRecordedActions
            .filter(p => p.actionCompletedDate != null && p.completedTypeWorkflowAction.completedOnlyType && p.propertyName != 'incomplete');

        if (completedNext != null && completedNext.length > 0) {

            completedNext.forEach(c => {
                const item: WorkflowActivityCompletedItem = {
                    workflowActivityCompletedItemId: count++,
                    workflowRecordedActionId: c.workflowRecordedActionId,
                    workflowContactId: c.completedWorkflowContactId,
                    staffFirstName: (c.completedTypeWorkflowContact) ? c.completedTypeWorkflowContact.firstName : c.completedUser,
                    typeWorkflowActionId: c.completedWorkflowActionId,
                    workflowActionDescription: c.completedTypeWorkflowAction.description,
                    created: c.actionCompletedDate,
                    actionData: (c.sentToTypeWorkflowContact) ? c.sentToTypeWorkflowContact.firstName : '',
                    canDeleteFromWorkflowHistory: c.completedTypeWorkflowAction.canDeleteFromWorkflowHistory,
                    completedOnlyType: c.completedTypeWorkflowAction.completedOnlyType
                };
                completedActions.push(item);
            });
        }

        return _.sortBy(completedActions, c => c.created).reverse();
    }

    private isSentToOrNotifyAction(actionId: string): boolean {
        return (actionId == 'SENT TO' || actionId == 'SENT AGAIN' || actionId == 'NOTIFY');
    }

    // ----------------------------------------------------------------------------------------------------------------------------------
    // Reference Finished State proxy
    // ----------------------------------------------------------------------------------------------------------------------------------
    public isReferenceFinished(reference: Reference): Promise<ReferenceFinishState> {
        return this._referenceFinishedService.isReferenceFinished(reference);
    }

    public get unfinishedReasons(): string[] {
        return this._referenceFinishedService.unfinishedReasons;
    }

    public enumerateMissingData(reference: Reference): Promise<number> {
        return this._referenceFinishedService.enumerateMissingData(reference);
    }

    // ----------------------------------------------------------------------------------------------------------------------------------
    // Workflow Deletion Service proxy
    // ----------------------------------------------------------------------------------------------------------------------------------
    public processDeleteWorkflowAction(reference: Reference, action: WorkflowActivityCompletedItem) {
        this._workflowDeletionService.processDeleteWorkflowAction(reference, action);
    }
}

import { CanDeactivate } from '@angular/router';
import { from, Observable } from 'rxjs';
import { Injectable } from "@angular/core";

export interface CanComponentDeactivate {
    canDeactivate: () => boolean | Promise<boolean> | Observable<boolean>;
}

@Injectable()
export class CanDeactivateGuard implements CanDeactivate<CanComponentDeactivate> {
    canDeactivate(component: CanComponentDeactivate): Observable<boolean> | boolean {
        if (!component.canDeactivate) {
            return true;
        }
        const r = <any>component.canDeactivate();
        if (r == undefined) {
            return r;
        }
        if (typeof r === 'boolean') {
            return r;
        }
        return r.then ? from(<Promise<any>>r) : r;
    }
}

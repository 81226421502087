<span *ngIf="_edm.editing" class="inline">
    <p class="inline bold" style="margin-left: 12px;">{{_adding ? "Adding an Exposure Survey Measure" : "Editing an Exposure Survey Measure" }}</p>
    <img style="margin-left: 10px; margin-top: 8px; height: 38px; width: 38px;" class="inline" src="assets/images/MeasureDataValue_Blue.png" />
</span>

<div class="row">
    <i *ngIf="isLoading" class="fa fa-circle-o-notch fa-spin" style="font-size:38px; color: #337ab7;"></i>
</div>

<div *ngIf="_validationMessage">
    <p class="alert-danger">{{_validationMessage}}</p>
</div>

<div *ngIf="!_edm.editing">
    <div style="margin-top: 12px;">
        <div class="row" style="margin-left: 1px;">
            <p class="inline bold" style="margin-left: 12px; font-size: 12px;">Select an External Material to see Available Exposure Surveys: </p>
            <select *ngIf="_exposureAdditionalMaterials != null && _exposureAdditionalMaterials.length > 0"
                    class="dropdown-toggle list-background-white" [ngModel]="_selectedExposureAdditionalMaterialIndex" style="width:200px; margin-left: 8px;"
                    (change)="filterExposureAdditionalMaterialByIndex($event.target.value)">
                <option *ngFor="let am of _exposureAdditionalMaterials; let ix = index" class="dropdown" [value]="ix">{{am.cASNumber}}</option>
            </select>
        </div>

        <div class="row" style="margin-top: 18px; margin-left: 10px;">
            <div *ngIf="_selectedExposureAdditionalMaterial">
                <span class="bold inline" style="font-size: 12px;">Selected CAS No.:</span>
                <span class="form-control-static">{{_selectedExposureAdditionalMaterial.cASNumber}}</span>

                <span class="bold inline some-space" style="margin-left: 12px; font-size: 12px;">External Material Name:</span>
                <span class="form-control-static">{{_selectedExposureAdditionalMaterial.materialName}}</span>
            </div>
        </div>
    </div>

    <div class="row exposure-survey-details-container">
        <div class="float-left" style="width: 44%;">
            <span *ngIf="_exposureAdditionalMaterialSurveys != null && _exposureAdditionalMaterialSurveys.length > 0" class="inline">
                <span class="bold inline" style="margin-top:18px; margin-left: 9px; padding-right: 4px;">Available Exposure Surveys: </span>
                <select id="exposure-survey-list" class="dropdown-toggle" [ngModel]="_selectedExposureSurveyIndex" (change)="filterExposureSurveyByIndex($event.target.value)">
                    <option *ngFor="let ams of _exposureAdditionalMaterialSurveys; let ix = index" class="dropdown" [value]="ix">{{ams.exposureSurvey?.exposureSurveyMonthYearNumber}}</option>
                </select>
            </span>
        </div>

        <div *ngIf="_currentExposureAdditionalMaterialSurvey" class="float-right" style="width: 55%; margin-top: 17px;">
            <div class="inline" style="border: 1px solid #D3CCBF; background-color: aliceblue; padding: 4px;">
                <span class="inline text-bold text-left page-subtitle right-indent">For the Selected Exposure Survey: </span>
                <span class="inline text-bold text-left page-subtitle">{{_selectedExposureSurvey.exposureSurveyMonthYear}}</span>
                <span class="inline text-bold text-left page-subtitle"> with Number: </span>
                <span class="inline text-bold text-left page-subtitle">{{_selectedExposureSurvey.exposureSurveyNumber}}</span>
                <br />
                <span class="inline text-bold text-left page-subtitle" style="margin-left: 1px;">The Response Status was: </span>
                <span class="inline text-bold text-left page-subtitle" style="background-color: #AFE7FF;">{{_currentExposureAdditionalMaterialSurvey.typeExposureSurveyResponse.responseType}}</span>
                <br />
                <a class="inline left-indent" (click)="onShowMaterialSurveys()"><strong>(Back to Material List)</strong></a>
            </div>
        </div>
    </div>

</div>

<div *ngIf="_unsurveyedMessage">
    <p class="alert alert-info">{{_unsurveyedMessage}}</p>
</div>

<div class="row" style="margin-left: 10px;">
    <editor-header [edm]="_edm" [customButtons]="true" style="margin-left: -4px !important;">
        <span customButtons>
            <button *ngIf="!_edm.editing" type="button" class="btn btn-sm btn-primary" [disabled]="!canSave()" (click)="onSave()">Save</button>
            <button *ngIf="_edm.editing" type="button" class="btn btn-sm btn-primary" [disabled]="!canApply()" (click)="onApply()">Apply</button>
            <button *ngIf="canShowBack()" type="button" class="btn btn-sm btn-primary" (click)="onBack()">Back</button>
            <button *ngIf="canCancel()" type="button" class="btn btn-sm btn-primary" (click)="onCancel()">Cancel</button>
        </span>
    </editor-header>
    <button *ngIf="(canAdd())" type="button" class="inline btn btn-survey-measure" style="width:130px; margin-left: 0px;" (click)="onAdd()">Add Measure</button>
</div>

<div *ngIf="!_edm.editing">
    <div style="margin-left: 10px;">
        <h4 *ngIf="(_exposureAdditionalMaterialSurveyMeasuresEx == null || _exposureAdditionalMaterialSurveyMeasuresEx.length < 1) && _currentExposureAdditionalMaterialSurvey != null">There is no Measure data for this external material for the selected survey.</h4>
        <span *ngIf="_exposureAdditionalMaterialSurveyMeasuresEx != null && _exposureAdditionalMaterialSurveyMeasuresEx.length > 0" class="inline">
            <span class="bold inline subtitle-margin-top" header>Exposure Survey Measure Data</span>
            <img style="margin-left: 10px;" class="inline multiple-measure-comment-image" src="assets/images/MultipleExposureMeasures.png" />
            <span class="inline italic notify-text">Indicates multiple runs of the same measure within an Exposure Survey.</span>
        </span>
        <default-table *ngIf="_exposureAdditionalMaterialSurveyMeasuresEx != null && _exposureAdditionalMaterialSurveyMeasuresEx.length > 0">
            <thead>
                <tr>
                    <td>Edit</td>
                    <td>Remove</td>
                    <td>Multiple Runs</td>
                    <td>Measure</td>
                    <td>Value</td>
                    <td>Date Entered</td>
                    <td>Notes</td>
                    <td>Creme Version</td>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let mx of _exposureAdditionalMaterialSurveyMeasuresEx">
                    <td><a (click)="onEdit(mx)">Edit</a></td>
                    <td><a (click)="onRemove(mx)">Remove</a></td>
                    <td><img *ngIf="mx.isMultiple" class="multiple-measure-image" src="assets/images/MultipleExposureMeasures.png" /></td>
                    <td>{{mx.data.typeExposureMeasure.measureDescription}}</td>
                    <td>{{mx.data.measureQualitativeResult || mx.data.measureQuantitativeResult}}</td>
                    <td>{{utilFns.formatDate(mx.data.created)}}</td>
                    <td class="text-left">{{mx.data.measureNotes}}</td>
                    <td class="text-center">{{mx.data.typeCremeVersion.cremeVersion}}</td>
                </tr>
            </tbody>
        </default-table>
    </div>
</div>

<div *ngIf="_edm.editing" style="margin-bottom: 10px;">
    <exposure-additional-material-survey-measure-editor #self [entity]="_edm.currentEntity"></exposure-additional-material-survey-measure-editor>
</div>

import {Component, OnInit, ViewChild} from '@angular/core';
import {Location} from '@angular/common';
import {ActivatedRoute, Router} from '@angular/router';

import {EditorService, ReferenceFinishState, ReferenceState, StateMap, UnitOfWork} from '../../services/common';
import {EditManager, IEditHost, UtilFns} from '../../utils/common';
import {TabContainer} from '../../controls/common';

import {BiologicalData, ExperimentalMaterial, Reference} from '../../entities/EntityModels';

import {ReferenceSelectorComponent} from '../reference/reference-selector.component';
import {ROUTES} from '../routes';
import {STAFF_ROUTES} from './staff.routes';

import {ExpMaterialEditorComponent} from './exp-material-editor.component';
import {CopyStatus, ExpMaterialFromTemplateComponent} from './exp-material-from-template.component';
import {ReferenceLoader} from './services/reference-loader';
import * as _ from 'lodash';

export class ProtocolData {
    expMaterialIdsForSorting: number[];
    subReference: string;
}

@Component({
    selector: 'protocol-editor',
    templateUrl: './protocol-editor.html',
})
export class ProtocolEditorComponent implements OnInit, IEditHost<BiologicalData> {
    @ViewChild(TabContainer) _tabContainer: TabContainer;
    @ViewChild(ReferenceSelectorComponent, { static: true }) _referenceSelectorComponent: ReferenceSelectorComponent;
    @ViewChild(ExpMaterialEditorComponent) _expMaterialEditorComponent: ExpMaterialEditorComponent;
    @ViewChild(ExpMaterialFromTemplateComponent) _expMaterialFromTemplateComponent: ExpMaterialFromTemplateComponent;

    _edm: EditManager<BiologicalData>;
    _edmExpMat: EditManager<ExperimentalMaterial>;
    _reference: Reference;
    // _nextBioDataId: number = 0;
    _currentIx = -1;
    // Workflow
    _errorMessage = '';
    _hasAddsOrDeletes = true;
    _finishedMessage: string;

    _copyingExperimentalMaterial = false;
    _sharedProtocolData = new ProtocolData();
    _templateExperimentalMaterial: ExperimentalMaterial;

    constructor(public _uow: UnitOfWork, public _stateMap: StateMap, public _editorService: EditorService,
                public _location: Location, public _router: Router,
                private _referenceState: ReferenceState,
                private route: ActivatedRoute,
                private referenceLoader: ReferenceLoader) {

        this._stateMap.currentRouteName = STAFF_ROUTES.Protocol.name;
    }

    ngOnInit() {
        this._edm = this._editorService.createEditManager<BiologicalData>(this, 'Protocol');
        this._edm.pageState.canShowMessage = false; // never show pageState messages.
        this._edmExpMat = this._editorService.createEditManager<ExperimentalMaterial>(this, 'Experimental Material');

        this.route.params
            .subscribe(params => {
                const referenceId: number = params['referenceId'];
                if (referenceId) {
                    this.referenceLoader.load(referenceId)
                        .then(() => {
                            this.fetchBiologicalData(this._stateMap.currentReference);
                        });
                }
            });
    }

    focusInitialTab() {
        setTimeout(() => {
            if (this._tabContainer) {
                this._tabContainer.selectTab(0);
            }
        }, 1);
    }

    canDeactivate() {
        return !this._edm.hasEdits();
    }

    get _currentBd() {
        return this._edm.currentEntity;
    }

    hasChanges() {
        return this._uow.hasChanges();
    }

    onSelect(bd: BiologicalData) {
        this._edm.currentEntity = bd;

        this.updateExpMats();
    }

    onEditBiologicalData(bd: BiologicalData) {
        this._edm.onSelect(bd);
        this.focusInitialTab();
    }

    updateExpMats() {
        const bd = this._edm.currentEntity;
        if (bd) {
            this._edmExpMat.entities = bd.experimentalMaterials;
            this._sharedProtocolData.expMaterialIdsForSorting = this._edmExpMat.entities.map(m => m.materialId);
            this._sharedProtocolData.subReference = bd.subReference;
        } else {
            this._edmExpMat.entities = [];
            this._sharedProtocolData.expMaterialIdsForSorting = [];
            this._sharedProtocolData.subReference = '';
        }
    }

    onKeyDown(event: any) {
        if (event.keyIdentifier == 'Up') {
            this._edm.movePrev();
        } else if (event.keyIdentifier == 'Down') {
            this._edm.moveNext();
        }
        this.updateExpMats();
    }

    onSelectReference() {
        UtilFns.showModal(this._referenceSelectorComponent, this, null).then(rli => {
            if (rli == null) {
                return;
            }
            return this.fetchReference(rli.referenceId);
        });
    }

    onMore() {
        this._router.navigate(UtilFns.asRouterParts(ROUTES.Reference, this._edm.currentEntity.referenceId));
    }

    canSelectReference() {
        if (this._reference && this.hasChanges()) {
            return false;
        }
        if (this._edm && this._edm.editing) {
            return false;
        }
        if (this._edmExpMat && this._edmExpMat.editing) {
            return false;
        }

        return (this._copyingExperimentalMaterial) ? false : true;
    }


    fetchReference(referenceId: number) {
        this.clearEdms();
        this._edm.pageState.isLoading = true;

        return this._uow.fetch('References/GetForEdit', { referenceId: referenceId }).then(refs => {
            const ref: Reference = <Reference>(refs && refs.length) ? refs[0] : null;
            return this.fetchBiologicalData(ref);
        });
    }

    fetchBiologicalData(reference: Reference) {
        this._edm.pageState.isLoading = true;
        this._reference = reference;
        this._stateMap.staffReferenceId = reference.referenceId;
        this._stateMap.currentReference = reference;
        if (!reference) {
            return;
        }
        this._uow.referenceRepository.populateBiologicalData(reference).then(() => {
            const r = reference.biologicalDatas;
            this._edm.entities = UtilFns.sort(r, true, (bd: BiologicalData) => bd.subReference || '');
            if (this._edm.entities.length) {
                this.onSelect(this._edm.entities[0]);
                this.sortExperimentalResults();
            }
            this._edm.pageState.isLoading = false;
        }).catch(() => {
            this._edm.pageState.isLoading = false;
        });
    }

    sortExperimentalResults() {
        this._edm.entities.forEach(bd => {
            bd.experimentalMaterials.forEach(em => {
                UtilFns.sortNumeric(em.experimentalResults, true, (expRes) => {
                    return expRes.dose;
                });
            });
        });

    }

    // invoked after EM/ER copy
    refreshBioData() {
        this._edm.pageState.isLoading = true;
        return this._uow.referenceRepository.populateBiologicalData(this._reference).then(() => {
            const r = this._reference.biologicalDatas;
            this._edm.entities = UtilFns.sort(r, true, (bd: BiologicalData) => bd.subReference || '');
            if (this._edm.entities.length) {
                this.onSelect(this._edm.entities[0]);
                this.sortExperimentalResults();
            }
            this._edm.pageState.isLoading = false;
        }).catch(() => {
            this._edm.pageState.isLoading = false;
        });
    }

    onAddBioData() {
        const params = {
            referenceId: this._reference.referenceId,
            typeTimeUnitId: 'none',
            typeUsefulnessId: 'NA'
        };

        const biodata = this._uow.biologicalDataFactory.create(params);
        // biodata.typeStudyId = null;
        if (!biodata.entityAspect.entityState.isAdded()) {
            this._edm.setStatus({ message: 'Add failed - validation error on current entity', classes: 'label-danger', isTemp: true });
        }
        this._edm.currentEntity = biodata;

        this.updateExpMats();
        this._edm.onSelect(biodata);
    }

    onAddExpMat() {
        if (!this._currentBd) {
            return;
        }
        const params = {
            biologicalDataId: this._currentBd.biologicalDataId,
            typeExperimentVehicleId: 'NONE',
            typeStudyMixtureId: 'Not Evaluated'
        };
        const expMat = this._uow.experimentalMaterialFactory.create(params);
        // this._edmExpMat.entities.push(expMat);
        this._edmExpMat.onSelect(expMat);
    }

    onCopyExpMat(expMat: ExperimentalMaterial) {
        this._templateExperimentalMaterial = expMat;
        this._copyingExperimentalMaterial = true;
    }

    onDeleteBioData(bd: BiologicalData) {
        return this._stateMap.yesNoComponent.showModal('Delete entire protocol',
            'Are you sure?').then(ok => {
            if (ok) {
                if (bd.entityAspect.entityState.isAdded() == false) {
                    this._referenceState.recordProtocolDelete(this._reference);
                }
                bd.cascadeDelete();
                this.updateExpMats();
            }
        });
    }

    onDeleteExpMat(expMat: ExperimentalMaterial) {
        if (expMat.entityAspect.entityState.isAdded() == false) {
            this._referenceState.recordMaterialLinkDelete(this._stateMap.currentReference);
        }
        expMat.cascadeDelete();

    }

    isEditing() {
        return this._edm.editing || this._edmExpMat.editing;
    }

    canShowSave() {
        if (this._copyingExperimentalMaterial) { return false; }

        return !this.isEditing();
    }

    // public because part of IEditHost
    canSave() {
        return this._uow.hasChanges();
    }

    onSave() {
        if (!this._edm.validateBeforeSave()) {
            return Promise.resolve();
        }

        this._finishedMessage = '';

        return this.recordWorkflowChanges()
            .then(() => {
                this._referenceState.isReferenceFinished(this._reference).then(f => {

                    const finished: boolean = (f != ReferenceFinishState.MissingData);

                    // Leave previously finished References finished unless there is bio/tox data added or deleted
                    if (finished != this._reference.finished) {
                        // if the reference will be finished or, the reference is going to be unfinished as a result of bio/tox data add/deletes
                        if (finished || this._hasAddsOrDeletes == true) {
                            this._finishedMessage = (finished) ?
                                'The Reference is now marked complete, you may need to Save again to confirm.' :
                                'The Reference has been Unfinished as a result of these edits.';

                            if (this._copyingExperimentalMaterial) {
                                this._expMaterialFromTemplateComponent._savingUnfinishedReferenceDuringCopy = true;
                            }

                            this._reference.finished = finished;
                            this._referenceState.recordReferenceFinishedStateChange(this._reference);
                        }
                    }

                    return this._edm.commitSave()
                        .then(() => {
                            this._edmExpMat.entities.forEach(expMat => expMat.clearDeletedChildren());
                            this.sortExperimentalResults();

                            if (this._edm.currentEntity != null && this._edm.currentEntity.experimentalMaterials != null && this._edm.currentEntity.experimentalMaterials.length > 0) {
                                this._sharedProtocolData.expMaterialIdsForSorting   = this._edm.currentEntity.experimentalMaterials.map(m => m.materialId);
                                this._sharedProtocolData.subReference               = this._edm.currentEntity.subReference;
                            } else {
                                this._sharedProtocolData.expMaterialIdsForSorting = [];
                                this._sharedProtocolData.subReference  = '';
                            }
                            if (this._copyingExperimentalMaterial) {
                                this._expMaterialFromTemplateComponent._savingUnfinishedReferenceDuringCopy = false;
                            }
                        });
                });
            });
    }

    canShowBack() {
        if (this._edm.editing) {
            return (this._edm.canShowBack() && !this._uow.hasChanges()); // changed December,2022. Changes to Sub Study changes weren't being detected.
        } else if (this._edmExpMat.editing) {
            return !this.expMatHasChanges();
        } else if (this._uow.hasChanges()) {
            return false;
        } else {
            return true;
        }
    }

    onBack() {
        if (this._edm.editing) {
            return this._edm.editing = false;
        } else if (this._edmExpMat.editing) {
            return this._edmExpMat.editing = false;
        } else if (this._copyingExperimentalMaterial) {
            // needs refreshed if the user stops midway, after Step 2, without creating Experimental Results
            if (this._expMaterialFromTemplateComponent._copyStatus == CopyStatus.EXPERIMENTALMATERIALSSAVED) {
                this.refreshBioData().then(r => {
                    this._expMaterialFromTemplateComponent.onBack();
                    this._copyingExperimentalMaterial = false;
                });
            } else {
                this._expMaterialFromTemplateComponent.onBack();
                this._copyingExperimentalMaterial = false;
            }
        } else {
            this.clearEdms();
        }
    }

    clearEdms() {
        this._reference = null;
        this._edm.entities = null;
        this._edmExpMat.entities = null;
    }

    canShowApply() {
        return this.isEditing();
    }

    canApply() {
        if (this._edm.editing) {
            return this._uow.hasChanges() && !this._edm.currentEntity.entityAspect.hasValidationErrors;
        } else if (this._edmExpMat.editing) {
            return this.expMatHasChanges() && this.expMatValidate();

        }
    }

    expMatValidate() {
        const currentExpMat = this._edmExpMat.currentEntity;
        if (!currentExpMat) {
            return true;
        }
        if (!currentExpMat.entityAspect.validateEntity()) {
            return false;
        }
        if (currentExpMat.materialId == null || currentExpMat.materialId < 1) {
            return false;
        }
        return currentExpMat.experimentalResults.every(er => er.entityAspect.validateEntity());
        // no need to validate experimentalToxicEffects because no errors are possible with current interface.
    }

    expMatHasChanges() {
        const currentExpMat = this._edmExpMat.currentEntity;
        if (!currentExpMat) {
            return false;
        }
        if (currentExpMat.getDeletedChildren().length) {
            return true;
        }
        return currentExpMat.entityAspect.entityState.isAddedModifiedOrDeleted() ||
            (currentExpMat.experimentalResults.some(er =>
                er.entityAspect.entityState.isAddedModifiedOrDeleted() ||
                er.experimentalToxicEffects.some(et => et.entityAspect.entityState.isAddedModifiedOrDeleted())
            ));
    }

    expMatCancel() {
        const currentExpMat = this._edmExpMat.currentEntity;
        if (!currentExpMat) {
            return false;
        }

        currentExpMat.getDeletedChildren().forEach(e => e.entityAspect.rejectChanges());
        currentExpMat.clearDeletedChildren();

        currentExpMat.experimentalResults
            .forEach(er => {
                er.experimentalToxicEffects
                    .map(et => et)
                    .forEach(et => et.entityAspect.rejectChanges());
            });

        currentExpMat.experimentalResults
            .map(er => er)
            .forEach(er => er.entityAspect.rejectChanges());

        currentExpMat.entityAspect.rejectChanges();
        this._edmExpMat.clearErrors();
        if (currentExpMat.entityAspect.entityState.isDetached()) {
            this._edmExpMat.editing = false;
        }

        if (this._expMaterialEditorComponent) {
            this._expMaterialEditorComponent.onCancel();
        }
    }

    onApply() {
        if (this._edm.editing) {
            return this._edm.onApplyCore();
        } else if (this._edmExpMat.editing) {
            if (!this.expMatValidate()) {
                return;
            }
            return this._edmExpMat.onApplyCore();
        }
    }

    canShowCancel() {
        if (this._copyingExperimentalMaterial) { return false; }

        return !this.canShowBack();
    }

    canCancel() {
        if (this._edm.editing) {
            return this._edm.canCancel();
        } else if (this._edmExpMat.editing) {
            return this.expMatHasChanges();
        } else if (this._uow.hasChanges()) {
            return true;
        } else {
            return false;
        }
    }

    onCancel() {
        if (this._edm.editing) {
            if (this._edm.currentEntity.entityAspect.entityState.isAdded()) {
                // this gets rid of any entities attached to an added then rolled back protocol/biodata.
                this._edm.currentEntity.cascadeDelete();
                this._edm.editing = false;
            }

            this._edm.onCancelCore();
            this.updateExpMats();

        } else if (this._edmExpMat.editing) {
            this.expMatCancel();
        } else if (this._uow.hasChanges()) {
            this._edm.onCancelCore();
            this.expMatCancel();
            this.updateExpMats();
        }
        this._copyingExperimentalMaterial = false;

        if (this._copyingExperimentalMaterial) {
            this._expMaterialFromTemplateComponent._savingUnfinishedReferenceDuringCopy = false;
        }
    }

    private recordWorkflowChanges(): Promise<any> {

        this._hasAddsOrDeletes = false;

        // Handle deletion of pending workflow tasks that are no longer relevant when data is deleted first, then add new actions
        if (this._referenceState.hasBioDataDeletionsToProcess(this._reference)) {
            this._referenceState.reconcileProtocolDeletesWithPendingActions(this._reference, this._edmExpMat.entities);
            this._hasAddsOrDeletes = true;
        }

        const changedEntities = this._edm.entities;

        if (changedEntities == null || changedEntities.length == 0) {
            return Promise.resolve(true);
        }

        let stateChangeNewBioData = false;
        let stateChangeNewMaterialLink = false;
        let stateChangeNewToxData = false;


        changedEntities.forEach(e => {

            if (e.entityType.name.indexOf('BiologicalData') > -1) {

                if (e.entityAspect.entityState.isAdded()) {
                    stateChangeNewBioData = true;
                }

                if (e.experimentalMaterials != null && e.experimentalMaterials.length > 0) {
                    const matLink = e.experimentalMaterials.filter(em => em.entityAspect.entityState.isAdded());
                    if (matLink != null && matLink.length > 0) {
                        stateChangeNewMaterialLink = true;
                    }

                    e.experimentalMaterials.forEach(em => {
                        if (em.experimentalResults != null && em.experimentalResults.length > 0) {
                            const toxAdd = em.experimentalResults.filter(er => er.entityAspect.entityState.isAdded());
                            if (toxAdd != null && toxAdd.length > 0) {
                                stateChangeNewToxData = true;
                            }
                        }
                    });
                }
            }
        });

        if (stateChangeNewBioData) {
            this._referenceState.recordProtocolAdd(this._reference);
        }

        if (stateChangeNewMaterialLink) {
            this._referenceState.recordMaterialLinkAdd(this._reference);
        }

        if (stateChangeNewToxData) {
            this._referenceState.recordToxDataAdd(this._reference);
        }

        // Check if added tox data satisfies the Finished state for the Reference
        if (stateChangeNewBioData || stateChangeNewMaterialLink || stateChangeNewToxData) {
            this._hasAddsOrDeletes = true;
        }
        return Promise.resolve(true);
    }

    public referenceFinishedStatesHaveChanged(): boolean {
        return (this._finishedMessage && this._finishedMessage.length > 0);
    }

    // getBioDataIndex(bd: BiologicalData) {
    //     return this._edm.entities.indexOf(bd);
    // }

    // setBioDataIndex(ix: number) {
    //     ix = Math.max(ix, 0);
    //     var entities = this._edm.entities;
    //     if (entities) {
    //         this.onSelect(entities[ Math.min(ix, entities.length-1)]);
    //     } else {
    //         this.onSelect(null);
    //     }
    // }

    copyExperimentalMaterialEventHandler(event: string) {
        // @ts-ignore
        switch (event) {
            case 'applychanges': {
                this._edmExpMat.onApplyCore();
                this._expMaterialFromTemplateComponent.applyComplete();
                break;
            }
            case 'savechanges': {

                const bioDataId = this._currentBd.biologicalDataId;
                const expMatId  = this._templateExperimentalMaterial.experimentalMaterialId;

                this.onSave().then(() => {
                    this.refreshBioData().then(r => {
                        // reset original editing selections so the copy can continue

                        const bioDataIdx        = this.findBiologicalDataIndex(bioDataId);
                        const expMaterialIdx    = this.findExperimentalMaterialIndex(bioDataIdx, expMatId);

                        if (bioDataIdx < 0 || expMaterialIdx < 0) {
                            this._copyingExperimentalMaterial = false;
                            this._errorMessage = 'Unexpected error occurred. The copy process cannot continue.';
                            return;
                        }

                        this.onSelect(this._edm.entities[bioDataIdx]);
                        this.onCopyExpMat(this._edm.entities[bioDataIdx].experimentalMaterials[expMaterialIdx]);

                        this._expMaterialFromTemplateComponent.saveComplete();
                    });
                });
                break;
            }
            default: {
                break;
            }
        }
    }

    findBiologicalDataIndex(id: number): number {
        return this._edm.entities.findIndex(bd => bd.biologicalDataId == id);
    }

    findExperimentalMaterialIndex(bioDataIdx: number, expMatId: number): number {
        return this._edm.entities[bioDataIdx].experimentalMaterials.findIndex(em => em.experimentalMaterialId == expMatId);
    }
}

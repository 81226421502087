import { EntityBase } from './EntityBase';

import { Validator } from 'breeze-client';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

export class TypeReferenceRelation extends EntityBase {

   /// <code> Place custom code between <code> tags
   
   /// </code>

   // Generated code. Do not place code below this line.
   typeReferenceRelationId: string;
   label: string;
   inverseTypeReferenceRelationId: string;
   description: string;
   rowVersion: number;
   inverseTypeReferenceRelation: TypeReferenceRelation;
}


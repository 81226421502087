import { Component, Input, OnInit } from '@angular/core';

import { provideParent, UnitOfWork } from '../../services/common';
import { EditPropParent } from '../../controls/common';
import { MaterialUses, TypeFoodCategory } from '../../entities/EntityModels';

@Component({
    selector: 'flavor-uses-item',
    templateUrl: './flavor-uses-item.html',
    providers: [provideParent(FlavorUsesItemComponent, EditPropParent)]
})
export class FlavorUsesItemComponent implements OnInit, EditPropParent {
    @Input() entity: MaterialUses;
    _typeFoodCategories: TypeFoodCategory[];

    constructor(public uow: UnitOfWork) {

    }

    ngOnInit() {
        this.uow.typeFoodCategoryRepository.all().then(r => {
            this._typeFoodCategories = r;

        });
    }

    hasChanges() {
        return (!this.entity.entityAspect.entityState.isUnchanged());
    }

    getError(propName: string) {
        return this.entity.getErrorFor(propName);
    }

    apply() {
    }
}

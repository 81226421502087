import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';

import { StateMap, UnitOfWork, UserManager } from '../../services/common';
import { EditManager, IEditHost, LocationFns } from '../../utils/common';

import { MATERIAL_ROUTES } from './material.routes';
import { RegulatoryStatusComponent } from './regulatory-status.component';
import { RegulatoryStatus } from '../../entities/EntityModels';

@Component({
    selector: 'regulatory-statuses',
    templateUrl: './regulatory-statuses.html',
})
export class RegulatoryStatusesComponent implements IEditHost<RegulatoryStatus>, OnInit {
    @Input() materialId: number;
    @Input() statusType: string;

    @ViewChild(RegulatoryStatusComponent) _editingComponent: RegulatoryStatusComponent;

    _edm: EditManager<RegulatoryStatus>;

    constructor(public _uow: UnitOfWork, public _userManager: UserManager, public _stateMap: StateMap, public _location: Location) {

    }

    ngOnInit() {
        this._stateMap.currentRouteName = MATERIAL_ROUTES.Status.name;
        let tab: number;
        if (this.statusType == 'general') {
            tab = 0;
        } else if (this.statusType == 'ifra') {
            tab = 1;
        } else if (this.statusType == 'occupational') {
            tab = 2;
        } else {
            tab = 0;
        }
        LocationFns.setTab(this._location, tab);
        const displayName = (this.statusType == 'ifra') ? 'IFRA' : this.statusType;
        this._edm = new EditManager<RegulatoryStatus>(this, this._uow, this._stateMap, displayName);

        this._edm.pageState.isLoading = true;
        const p = {materialId: this.materialId, statusType: this.statusType};
        this._uow.regulatoryStatusRepository.whereWithParams(p).then(r => {
            this._edm.entities = r;
            this._edm.pageState.isLoaded = r;
        });

    }

    canDeactivate() {
        return !this._edm.hasEdits();
    }

    formatWebReference(regulatoryStatus: RegulatoryStatus) {
        if (regulatoryStatus.webReferenceExt() == null || (regulatoryStatus.webReferenceExt().indexOf('http') == 0)) {
            return regulatoryStatus.webReferenceExt();
        } else {
            return this._userManager.getFileServiceFilePathUrl(regulatoryStatus.webReferenceExt());
        }
    }


    selectWebRef(e: RegulatoryStatus) {
        const url = this.formatWebReference(e);
        if (url) {
            window.open(url);
        }
    }

    onAdd() {
        if (!this._edm.validateCurrent()) {
            return;
        }
        const rs = this._uow.regulatoryStatusFactory.create();
        rs.materialId = this.materialId;
        this._edm.entities.push(rs);
        this._edm.onSelect(rs);
    }


}

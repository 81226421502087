<div class="indent-left-md">
    <div class="" style="margin-bottom: 10px;">
        <div class="home-page-title">Select a Fragrance Structure Activity Group</div>
        <select style="font-size: 18px" *ngIf="_FSAGs" (change)="selectFSAG($event)" [(ngModel)]="_fsagIx" class="btn btn-primary dropdown-toggle" data-toggle="dropdown"
        aria-haspopup="true" aria-expanded="false">
            <option *ngFor="let fsag of _FSAGs; let ix = index" [value]="ix" class="dropdown"> {{fsag?.note}}</option>
        </select>
        <page-status [pageState]="_pageState"></page-status>
    </div>

    <div *ngIf="_canShowMaterials" class="bold message">There are {{_materialFSAGs.length || "no" }} related materials
        <button *ngIf="_materialFSAGs?.length" type="button" class="btn btn-primary btn-sm" (click)="selectEntireTable()">Select entire table</button>
    </div>
    
    <tab-container (tabChange)="onTabChange($event)">
        <ng-template *ngIf="_materialFSAGs && _materialFSAGs.length" tab-pane title="Groups ">
            <default-table>
                <thead>
                    <tr>
                        <td>CAS No.</td>
                        <td>RIFM ID</td>
                        <td>Principal Name</td>
                        <td>Structure</td>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let e of _materialFSAGs "  (click)="onCasSelect(e)">
                        <td (click)="onCasSelect(e) ">
                            <a>{{e.material.realCASNumber || '(No CAS#)'}}</a>
                        </td>
                        <td>{{e.material.formattedRIFMId}}</td>
                        <td>{{e.material.principalSynonym().synonymWordOrWebVersion()}}</td>
                        <td><img *ngIf="e.material.base32SmileUrl" [src]="e.material.base32SmileUrl" /></td>
                    </tr>
                </tbody>
            </default-table>
        </ng-template>
        <ng-template *ngIf="_materialIds && _materialIds.length " tab-pane title="Human Health Studies ">
            <human-health [materialIds]="_materialIds " [toxType]=" 'H' " [tabPath]=" '1' " [shouldFetchMaterials]="true" ></human-health>
        </ng-template>
        <ng-template *ngIf="_materialIds && _materialIds.length " tab-pane title="Environmental Studies ">
            <human-health [materialIds]="_materialIds " [toxType]=" 'E' " [tabPath]=" '2' " [shouldFetchMaterials]="true"></human-health>
        </ng-template>
        <ng-template *ngIf="_materialIds && _materialIds.length " tab-pane title="Non-tox References ">
            <tox-data-other [materialIds]="_materialIds " [tabPath]=" '3' "></tox-data-other>
        </ng-template>
    </tab-container>

</div>

import { EntityBase } from './EntityBase';

import { Validator } from 'breeze-client';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

export class TypeWorkflowContact extends EntityBase {

   /// <code> Place custom code between <code> tags
   
   /// </code>

   // Generated code. Do not place code below this line.
   workflowContactId: number;
   firstName: string;
   lastName: string;
   emailAddress: string;
   notifyOnAddReference: boolean;
   consultant: boolean;
   workflowStaff: boolean;
   created: Date;
   createdUser: string;
   modified: Date;
   modifyUser: string;
   rowVersion: number;
}


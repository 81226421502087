import {AfterViewInit, Component, forwardRef, Inject, Input, OnInit, ViewChild} from '@angular/core';

import {provideParent, UnitOfWork} from '../../services/common';
import {EditPropParent} from '../../controls/common';
import {UtilFns} from '../../utils/common';
import {AnalyticalResult, TypeAnalyticalProcedure} from '../../entities/EntityModels';
import {ReferenceSelectorComponent} from '../reference/reference-selector.component';
import {ShowAndDownloadPDFComponent} from '../docmanagement/show-and-download-pdf.component';
import {AnalyticalResultsComponent, UVFileAction, UVFileData} from './analytical-results.component';
import * as _ from 'lodash';

@Component({
    selector: 'analytical-result',
    templateUrl: './analytical-result.html',
    providers: [provideParent(AnalyticalResultComponent, EditPropParent)]
})
export class AnalyticalResultComponent implements OnInit, EditPropParent, AfterViewInit {
    @Input() entity: AnalyticalResult;
    @ViewChild(ReferenceSelectorComponent, { static: true }) _referenceSelectorComponent: ReferenceSelectorComponent;
    @ViewChild(ShowAndDownloadPDFComponent, { static: true }) _showAndDownloadPDFComponent: ShowAndDownloadPDFComponent;

    public _data: UVFileData = new UVFileData();
    public _fileInputElement: any; // html element reference
    public _typeAnalyticalProcedures: TypeAnalyticalProcedure[];
    public _uvsFileActionLabels: string[];
    public _uvsFileCancelRemoveLabels: string[];

    public _loading = false;
    public _validationMessage: string;

    constructor(public uow: UnitOfWork, @Inject(forwardRef(() => AnalyticalResultsComponent)) public _parent: AnalyticalResultsComponent) {

        this._data = _parent._data;
        this._uvsFileActionLabels = ['', 'File will be Added', 'File will be Removed'];
        this._uvsFileCancelRemoveLabels = ['Remove', 'Cancel Add', 'Cancel Remove'];
    }

    ngOnInit() {
        this.uow.typeAnalyticalProcedureRepository.all().then(taps => {
            this._typeAnalyticalProcedures = taps.sort((a, b) => {
                return a.analyticalProcedure.localeCompare(b.analyticalProcedure);
            });
        });

        if (this.entity.typeAnalyticalProcedureID == 'UVS') {
            if (this.entity.uvsFileList != null && this.entity.uvsFileList.length > 0) {
                this.prepareUVSFiles(this.entity.uvsFileList);
            }
        }
    }

    ngAfterViewInit() {
        this._fileInputElement = $('#fileInputElement')[0] as HTMLInputElement;
    }

    getError(propName: string) {
        return this.entity.getErrorFor(propName);
    }

    public prepareUVSFiles(fileList: string[]) {

        const fileData: any = null;

        // check to see if the file data has already been inserted into the shared data array.
        // this can happen when a UVS record is edited after the "Apply" button has been pressed
        const dataFiles = this._data.uvFiles.filter(a => a.analyticalResultId == this.entity.analyticalResultId);
        if (dataFiles == null || dataFiles.length == 0) {
            const analyticalResultId = this.entity.analyticalResultId;
            fileList.map((fn, idx) => {
                this._data.uvFiles.push({ key: idx, fileName: fn, processType: UVFileAction.NONE, processResultMsg: '', analyticalResultId, fileData });
            });
        }
    }

    // hasChanges() {
    //     return (!this.entity.entityAspect.entityState.isUnchanged());
    // }

    isAdded() {
        return this.entity.entityAspect.entityState.isAdded();
    }

    onSourceChange(source: string) {
        this.entity.referenceId = null;
    }

    onSelectReference() {
        UtilFns.showModal(this._referenceSelectorComponent, this, null).then(rli => {
            if (rli == null) {
                return;
            }
            this.entity.referenceId = rli.referenceId;
            this._loading = true;
            return this.uow.referenceRepository.withId(rli.referenceId).then(r => {
                this.entity.analyticalSource = r.briefReference;
                this._loading = false;
            });
        });
    }

    // uvs data
    // **********************************************************
    // **********************************************************
    entityUVFiles() {
        if (this._data.uvFiles == null || this._data.uvFiles.length == 0) {
            return null;
        }
        return this._data.uvFiles.filter(a => a.analyticalResultId == this.entity.analyticalResultId);
    }

    hasUVFileData() {
        return (this.entityUVFiles() != null);
    }

    disablePropertyTypeSelection() {
        if (this.entity == null) { return true; }

        if (this.entity.entityAspect.entityState.isAdded()) { return false; }

        return (this.entity.typeAnalyticalProcedureID == 'UVS');
    }

   canShowFileInformationalMessage(): boolean {
         if (this.entity.typeAnalyticalProcedureID != 'UVS') { return false; }

        const files = this._data.uvFiles.filter(f => f.processType != UVFileAction.DELETE);
        return (files == null || files.length == 0);
    }

    public onCancelAddUVSFile(idx: number) {
        const currentFileData = this._data.uvFiles.filter(f => f.key != idx);

        if (currentFileData == null || currentFileData.length == 0) {
            this._data.uvFiles = [];
            return;
        }

        // reindex the array of file data
        this._data.uvFiles = currentFileData.map( (obj, index) => {
            obj.key = index;
            return obj;
        });
    }

    onAddUVSFile(files: FileList) {
        const fileData: any = files.item(0);

        this._loading = true;
        this._validationMessage         = '';

        const analyticalResultId = this.entity.analyticalResultId;
        this._data.addCount++; // used for file name

        // the file name includes a sequence number that considers all uvs files assigned to the material
        const params = { materialId: this.entity.materialID, incrementNumber:  this._data.addCount };
        this.uow.fetch('Materials/FetchNextUVSFileName', params).then(fn => {
            if (fn != null && fn.length > 0) {
                const nextFileName = fn[0];
                this._data.uvFiles.push({ key: this._data.uvFiles.length, fileName: nextFileName, processType: UVFileAction.ADD, processResultMsg: '', analyticalResultId, fileData });

                this.setObservedResultForUVTypes();

                this._loading = false;

            }
        });
    }

    onPropertyTypeChange() {
        if (this.entity.typeAnalyticalProcedureID != 'UVS') {
           this.resetUVData();
        }
    }

    onRemoveUVSFile(idx: number) {
        const processType = this._data.uvFiles[idx].processType;
        switch (processType) {
            case UVFileAction.ADD: {
                this.onCancelAddUVSFile(idx);
                break;
            }
            case UVFileAction.DELETE: {
                this._data.uvFiles[idx].processType = UVFileAction.NONE;
                break;
            }
            case UVFileAction.NONE: {
                this._data.uvFiles[idx].processType = UVFileAction.DELETE;
                break;
            }
            default: {
                break;
            }
        }

        this.setObservedResultForUVTypes();
    }

    onShowUVSFile(idx: number) {
        const uvFiles = this.entityUVFiles();

        this._showAndDownloadPDFComponent.fileName = uvFiles[idx].fileName;
        UtilFns.showModal(this._showAndDownloadPDFComponent, this).then(m => {
            return;
        });
    }

    resetUVData() {
        const currentFileData = _.clone(this._data.uvFiles);
        this._data.uvFiles = currentFileData.filter(a => a.analyticalResultId != this.entity.analyticalResultId);
    }

    setObservedResultForUVTypes() {
        if (this._data.uvFiles != null && this._data.uvFiles.length > 0) {
            const uvsfiles = this._data.uvFiles.filter(f => f.analyticalResultId == this.entity.analyticalResultId &&  f.processType != UVFileAction.DELETE);
            this.entity.observedResult = (uvsfiles != null && uvsfiles.length > 0) ? uvsfiles.map(fn => fn.fileName).join(',') : null;
        } else {
            this.entity.observedResult = null;
        }
    }

    translateUVSFileActionToLabel(idx: number) {
        return (this._uvsFileActionLabels[this._data.uvFiles[idx].processType]);
    }

    translateUVSFileCancelRemoveToLabel(idx: number) {
        return (this._uvsFileCancelRemoveLabels[this._data.uvFiles[idx].processType]);
    }

    isUVAdd(idx: number) {
      return (this._data.uvFiles[idx].processType == UVFileAction.ADD);
    }

    isUVS() {
        if (this.entity == null) { return false; }
        return (this.entity.typeAnalyticalProcedureID == 'UVS');
    }

    isValidString(teststring: string) {
        if (!teststring) { return false; }
        return (teststring.trim().length > 0);
    }
}

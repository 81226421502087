import { Component, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { StateMap, UnitOfWork, UserManager } from '../../services/common';
import { Announcement } from '../../entities/EntityModels';

@Component({
    selector: 'whats-new',
    templateUrl: './whats-new.html'
})
export class WhatsNewComponent implements OnInit {

    _announcements: Announcement[];

    constructor(public _uow: UnitOfWork, public _stateMap: StateMap, public _userManager: UserManager) {

    }

    ngOnInit() {
        this._uow.announcementRepository.all().then(r => {
            this._announcements = _.orderBy(r, a => a.created, 'desc');
        });

    }

    isStaff() {
        return this._stateMap.currentUser.isStaff;
    }

    linkTo(e: Announcement) {
        let url: string;
        const webLink = e.webLink;
        if (webLink.startsWith('http')) {
            url = webLink;
        } else {
            url = this._userManager.getFileServiceFilePathUrl(webLink);
        }

        window.open(url, '_self');
        return false;
    }

}

import { ReferenceDetailsComponent } from './reference-details.component';
import { ReferenceDataComponent } from './reference-data.component';
import { ReferenceRelationsComponent } from './reference-relations.component';

export const REFERENCE_ROUTES = {
    ReferenceDetails: {
        title: 'Reference',
        path: 'reference-details',
        name: 'ReferenceDetails',
        component: ReferenceDetailsComponent
    },
    ReferenceData: {
        title: 'Reference Data',
        path: 'reference-data',
        name: 'ReferenceData',
        component: ReferenceDataComponent
    },
    ReferenceRelations: {
        title: 'Related References',
        path: 'reference-relations',
        name: 'ReferenceRelations',
        component: ReferenceRelationsComponent
    },
};

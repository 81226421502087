<div *ngIf="entity" class="form-horizontal indent-top-sm">

    <div *ngIf="isAdded()">
        <span class="bold">Select an External Material: </span>
        <select *ngIf="_data.edmAdditionalMaterial.entities != null && _data.edmAdditionalMaterial.entities.length > 0" 
                class="dropdown-toggle list-background-white" [ngModel]="_selectedExposureAdditionalMaterialIndex" style="width:180px;"
                (change)="filterExposureAdditionalMaterialByIndex($event.target.value)">
            <option *ngFor="let am of _data.edmAdditionalMaterial.entities; let ix = index" class="dropdown" [value]="ix">{{am.cASNumber}}</option>
        </select>
    </div>

    <div class="subtitle-margin-top">
        <edit-prop label="Cas No." szs="2,2">
            <span class="form-control-static">{{entity.exposureAdditionalMaterial?.cASNumber}}</span>
        </edit-prop>

        <edit-prop label="Material Name" szs="2,4">
            <span class="form-control-static">{{entity.exposureAdditionalMaterial?.materialName}}</span>
        </edit-prop>

        <edit-prop label="Member Company" szs="2,4">
            <span class="form-control-static">{{entity.exposureAdditionalMaterial?.memberCompany}}</span>
        </edit-prop>

        <edit-prop label="Notes" prop="notes" szs="2,5">
            <textarea class="form-control" rows=3 [(ngModel)]="entity.notes"></textarea>
        </edit-prop>

        <edit-prop label="Response Type" prop="typeExposureSurveyResponseId" szs="2,3">
            <select class="form-control" *ngIf="_typeExposureSurveyResponses" [ngModel]="entity.typeExposureSurveyResponseId" (change)="entity.typeExposureSurveyResponseId=$event.target.value">
                <option *ngFor="let t of _typeExposureSurveyResponses" [value]="t.typeExposureSurveyResponseId">{{t.responseType}}</option>
            </select>
        </edit-prop>

        <edit-prop label="Banned Material" szs="2,1">
            <input class="form-control" type="checkbox" [(ngModel)]="entity.bannedMaterial">
        </edit-prop>
    </div>
</div>

<div class="row">
    <i *ngIf="_isLoading" class="fa fa-circle-o-notch fa-spin" style="font-size:38px; color: #337ab7;"></i>
</div>


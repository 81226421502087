import { Component, Input, OnInit, ViewChild } from '@angular/core';
import * as _ from 'lodash';

import { MolImageService, StateMap, UnitOfWork } from '../../services/common';
import { UtilFns } from '../../utils/common';
import { PageState } from '../../controls/common';
import { PrintToPdfDocComponent } from '../main/print-to-pdf-doc.component';

import {
    ExperimentalMaterial,
    Material,
    MaterialAdditionalIdentifier,
    MaterialConsumption,
    MaterialUses,
    RegulatoryStatus
} from '../../entities/EntityModels';

@Component({
    selector: 'synopsis-report',
    templateUrl: './synopsis-report.html',
})
export class SynopsisReportComponent implements OnInit {
    @ViewChild(PrintToPdfDocComponent, { static: true }) _printToPdfDocComponent: PrintToPdfDocComponent;

    @Input() _material: Material;
    @Input() _typeStudyIds: string[];
    @Input() _expMats: ExperimentalMaterial[];
    @Input() _pageState: PageState;
    @Input() _rifmAsAuthorOnly: boolean;

    _reportDate: Date;
    _flavorConsumptions: MaterialConsumption[];
    _uses: MaterialUses[];
    _flavorStatuses: RegulatoryStatus[];
    _ifraStatuses: RegulatoryStatus[];
    _occupationalStatuses: RegulatoryStatus[];
    _hhExpMats: ExperimentalMaterial[];
    _envExpMats: ExperimentalMaterial[];
    _refCitations: string[];
    _matIds: MaterialAdditionalIdentifier[];

    constructor(public _uow: UnitOfWork, public _stateMap: StateMap, public _molImageService: MolImageService) {
    }

    ngOnInit() {
        this.fetch();
    }

    isStaff() {
        return this._stateMap.currentUser.isStaff;
    }

    fetch() {
        this._reportDate = new Date();
        const materialId = this._material.materialId;

        const pFlc = {materialId: materialId, materialType: 'flavor'};
        this._uow.materialConsumptionRepository.whereWithParams(pFlc).then(r => {
            this._flavorConsumptions = r;
            this._pageState.isLoading = false;
        });

        const pUses = {materialId: materialId};
        this._uow.materialUsesRepository.whereWithParams(pUses).then(r => {
            this._uses = r;
        });

        const pFlavor = {materialId: materialId, statusType: 'flavor'};
        this._uow.regulatoryStatusRepository.whereWithParams(pFlavor).then(r => {
            this._flavorStatuses = r;
        });

        const pIfra = {materialId: materialId, statusType: 'ifra'};
        this._uow.regulatoryStatusRepository.whereWithParams(pIfra).then(r => {
            this._ifraStatuses = r;
        });

        const pOcc = {materialId: materialId, statusType: 'occupational'};
        this._uow.regulatoryStatusRepository.whereWithParams(pOcc).then(r => {
            this._occupationalStatuses = r;
        });

        const pMatIds = {materialId: materialId};
        this._uow.fetchTyped('Materials/MaterialAdditionalIdentifierForMaterial', MaterialAdditionalIdentifier, pMatIds).then(r => {
            this._matIds = r;
        });

        if (this._rifmAsAuthorOnly) {
            const params = {materialId: materialId};
            this._uow.fetch('Misc/GetRIFMAsAuthorReferenceIds', params).then(r => {
                if (r && r.length > 0) {
                    this.filterExpMatsForRIFMAsAuthor(r);
                }
            });
        } else {
            this.filterExpMats();
        }

    }

    filterExpMats() {
        let expMats = this._expMats;
        if (this._typeStudyIds != null) {
            expMats = expMats.filter(x => this._typeStudyIds.indexOf(x.biologicalData.typeStudyId) >= 0);
        }
        this._hhExpMats = expMats.filter(x => x.biologicalData.humanHealthData);
        if (this._hhExpMats) {
            this._hhExpMats.forEach(em => {
                UtilFns.sortNumeric(em.experimentalResults, true, (expRes) => {
                    return expRes.dose;
                });
            });
        }

        this._envExpMats = expMats.filter(x => x.biologicalData.environmentalData);
        if (this._envExpMats) {
            this._envExpMats.forEach(em => {
                UtilFns.sortNumeric(em.experimentalResults, true, (expRes) => {
                    return expRes.dose;
                });
            });
        }

        const refs = expMats.map(em => em.biologicalData.reference);
        const citations = _.uniq(refs).map(r => r.citationText(this.isStaff()));
        this._refCitations = _.sortBy(citations);
    }

    filterExpMatsForRIFMAsAuthor(referenceIds: number[]) {
        let expMats = this._expMats;
        if (this._typeStudyIds != null) {
            expMats = expMats.filter(x => this._typeStudyIds.indexOf(x.biologicalData.typeStudyId) >= 0 && referenceIds.indexOf(x.biologicalData.referenceId) >= 0);
        } else {
            expMats = expMats.filter(x => referenceIds.indexOf(x.biologicalData.referenceId) >= 0);
        }
        this._hhExpMats = expMats.filter(x => x.biologicalData.humanHealthData);
        if (this._hhExpMats) {
            this._hhExpMats.forEach(em => {
                UtilFns.sortNumeric(em.experimentalResults, true, (expRes) => {
                    return expRes.dose;
                });
            });
        }

        this._envExpMats = expMats.filter(x => x.biologicalData.environmentalData);
        if (this._envExpMats) {
            this._envExpMats.forEach(em => {
                UtilFns.sortNumeric(em.experimentalResults, true, (expRes) => {
                    return expRes.dose;
                });
            });
        }

        const refs = expMats.map(em => em.biologicalData.reference);
        const citations = _.uniq(refs).map(r => r.citationText(this.isStaff()));
        this._refCitations = _.sortBy(citations);
    }

    printReport() {
        // need to insure that we get to an element below the bootstrap modal styling.
        const ele = document.querySelector('#SynopsisReport .modal-content');
        UtilFns.printElement(ele);
    }

    getSmileUrl(material: Material) {
        if (material.smiles == null) {
            return null;
        }
        return this._molImageService.getImageUrlForSmile(material.smiles);
    }


    showPrintToPdfDoc() {
        setTimeout(() => {
            this._printToPdfDocComponent.showModal();
        }, 0);
    }
}


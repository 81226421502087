import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { UtilFns } from '../../utils/common';
import { PrintToPdfDocComponent } from '../main/print-to-pdf-doc.component';

import { BiologicalData, ExperimentalMaterial } from '../../entities/EntityModels';

@Component({
    selector: 'report',
    templateUrl: './reference-data-report.html',
})
export class ReferenceDataReportComponent implements OnInit {
    @ViewChild(PrintToPdfDocComponent, { static: true }) _printToPdfDocComponent: PrintToPdfDocComponent;

    @Input() bd: BiologicalData;
    @Input() selectedExpMaterial: ExperimentalMaterial;

    ngOnInit() {

    }

    _selectedExpMaterials: ExperimentalMaterial[] = [];


    getSelectedExpMaterials(bd: BiologicalData) {
        if (this.selectedExpMaterial) {
            // HACK - because of Angular2 devMode bug.
            this._selectedExpMaterials.length = 0;
            this._selectedExpMaterials.push(bd.experimentalMaterials.filter(expMat => expMat == this.selectedExpMaterial)[0]);
            return this._selectedExpMaterials;
        } else {
            return bd.experimentalMaterials;
        }
    }

    printFullReport() {
        // need to insure that we get to an element below the bootstrap modal styling.
        const ele = document.querySelector('#FullReport .modal-content');
        UtilFns.printElement(ele);
    }

    showPrintToPdfDoc() {
        this._printToPdfDocComponent.showModal();
    }
}


<div style="margin-bottom: 10px">
    <div class="inline" style="font-size: 18px;margin-bottom: 10px;" *ngIf="_data.edm.editing">{{_data.adding ? "Adding a Material" : "Editing a Material" }}</div>
    <span *ngIf="(!_data.adding && !_data.inSubEditor) && canEdit()">
        <button type="button" class="inline btn btn-sm btn-primary" (click)="_data.edm.onAdd()">Add a Material</button>
        <button type="button" class="inline btn btn-sm btn-primary" (click)="onEdit()"> {{ "Edit " + (_data.edm.editing ? "Another " : "a ") + "Material" }}</button>
    </span>
</div>

<div class="row">
    <i *ngIf="_isLoading" class="fa fa-circle-o-notch fa-spin" style="font-size:38px; color: #337ab7; margin-left: 24px;"></i>
</div>

<div *ngIf="_data.edm.editing && !_data.adding" style="margin-bottom: 8px;">
    <span class="workflow-asterisk inline vertical-top">**</span>
    <a class="inline bold" (click)="onShowMaterialWorkflowHistory()">Show Material Workflow History
        <img class="inline" style="border-radius: 5px; margin-left: 4px; " src="assets/images/ExposureReport.png" width="24"/>
    </a>
</div>

<editor-header *ngIf="_data.edm.editing && !_data.inSubEditor" [edm]="_data.edm" [customButtons]="true">
    <span customButtons>
        <span>
            <button *ngIf="!_data.adding" type="button" class="btn btn-sm btn-primary" [disabled]="!_data.edm.canDelete()" (click)="_data.edm.onDelete()">Delete</button>
            <button type="button" class="btn btn-sm btn-primary" [disabled]="!canSave()" (click)="_data.edm.onSave()">Save</button>
            <button *ngIf="canCancel()" type="button" class="btn btn-sm btn-primary" [disabled]="!canCancel()" (click)="_data.edm.onCancel()">Cancel</button>
            <button *ngIf="!_data.adding && !canCancel()" type="button" class="btn btn-sm btn-primary" (click)="onMore()">More...</button>
        </span>
    </span>
</editor-header>

<p class="alert alert-message bold">{{_userMessage}}</p>

<div class="material-edit-container" *ngIf="_data.edm.editing">
    <div style="padding: 2px;">
        <tab-container>
            <ng-template [canDeactivate]="canDeactivateEditor()" tab-pane title="Material Base" active="1">
                <material-editor-base></material-editor-base>
            </ng-template>
            <ng-template *ngIf="!_data.adding" [canDeactivate]="canDeactivateEditor()" tab-pane title="Cramer Class">
                <predictions-cramer-editor></predictions-cramer-editor>
            </ng-template>
        </tab-container>
    </div>
</div>

<material-selector></material-selector>

<div *ngIf="_canShowWorkflowModal">
    <material-workflow-history [material]="_data.entity" [activityType]="_workflowActivityType"></material-workflow-history>
</div>

import { Component, Input, OnInit } from '@angular/core';

import { provideParent, UnitOfWork } from '../../services/common';
import { EditPropParent } from '../../controls/common';
import { Prediction, TypePrediction, TypePredictionClass } from '../../entities/EntityModels';

@Component({
    selector: 'prediction-cramer-editor',
    templateUrl: './prediction-cramer-editor.html',
    providers: [provideParent(PredictionCramerEditorComponent, EditPropParent)]
})
export class PredictionCramerEditorComponent implements OnInit, EditPropParent {
    @Input() prediction: Prediction;
    _typePredictions: TypePrediction[];
    _typePredictionClasses: TypePredictionClass[];

    _pageTitle: string;

    constructor(public uow: UnitOfWork) {

    }

    ngOnInit() {
        this.uow.typePredictionRepository.all()
            .then(r => {
                this._typePredictions = r;
                this.uow.typePredictionClassRepository.all()
                    .then(data => {
                        this._typePredictionClasses = data;
                    });
            });
    }

    checkIfNull(value: string) {
        if (value == '') {
            return null;
        } else {
            return value;
        }
    }

    getError(propName: string) {
        if (this.prediction == null) {
            return;
        }

        const err = this.prediction.getErrorFor(propName);
        if (err) {
            return err;
        }
        return null;
    }
}

import { EntityBase } from './EntityBase';

import { Validator } from 'breeze-client';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

export class RIFMReport extends EntityBase {

    /// <code> Place custom code between <code> tags

    /// </code>

    // Generated code. Do not place code below this line.
    rIFMReportId: number;
    reportTopic: string;
    applicationComponent: string;
    reportFileName: string;
    reportTitle: string;
    reportDescription: string;
    reportWebLink: string;
    enabled: boolean;
    created: Date;
    createdUser: string;
    modified: Date;
    modifyUser: string;
    rowVersion: number;
}

import { Injectable } from '@angular/core';
import * as _ from 'lodash';

import { Reference, TypeWorkflowActionTransition, WorkflowRecordedAction } from '../entities/EntityModels';
import { UserManager } from './user-manager';
import { UnitOfWork } from './unit-of-work';
import { WorkflowEntityState, WorkflowState } from './workflow-state';

@Injectable()
export class WorkflowService {
    private _typeWorkflowActionTransitions: TypeWorkflowActionTransition[] = [];
    private _mappedActions: { actionCurrent: string, markCompleteOnly: boolean, actionNext: string, doNotComplete: boolean }[] = [];

    constructor(private _uow: UnitOfWork, private _userManager: UserManager) {

        this._uow.typeWorkflowActionTransitionRepository.all()
            .then(r => {
                this._typeWorkflowActionTransitions = r;
            });
    }

    addWorkflowEvent(workflowState: WorkflowState, reference: Reference): void {

        this.mapStateToAction(workflowState); // More than 1 action can be associated with an event

        if (this._mappedActions != null && this._mappedActions.length > 0) {

            this._mappedActions.forEach(a => {

                if (workflowState.workflowReferenceId != null && a.doNotComplete == false) {
                    this.updatePendingWorkflowRecordedActionsForReference(workflowState, a.actionCurrent,
                        reference, (a.markCompleteOnly) ? a.actionNext : null); // Mark Complete records can change the completed action id.
                }

                // When markComplete is set to true, the action is only a completion step of a pending action so the previous record(s) is updated and no new actions are inserted.
                if (a.markCompleteOnly == false) {
                    this.createWorkflowRecordedAction(workflowState, a.actionCurrent, a.actionNext);
                }
            });
        }
    }

    ignoreThisProperty(propertyName: string): boolean {
        if (propertyName == 'created'
            || propertyName == 'createdUser'
            || propertyName == 'modified'
            || propertyName == 'modifyUser') {
            return true;
        }

        return false;
    }

    private mapStateToAction(workflowState: WorkflowState) {
        const name = workflowState.parsedEntityName();
        const state = this.convertEntityStateToWorkflowActionState(workflowState.workflowEntityState);
        const prop = (workflowState.workflowPropertyName == null) ? null : workflowState.workflowPropertyName; // Can be null for ADDED entities

        this._mappedActions = [];

        try {
            if (this._typeWorkflowActionTransitions != null) {

                const actions = this._typeWorkflowActionTransitions
                    .filter(w => w.entityName == name && w.entityState == state && ((w.propertyName == null && prop == null) || (w.propertyName == prop)));

                if (actions != null && actions.length > 0) {

                    actions.forEach(a => {
                        this._mappedActions.push({
                            actionCurrent: a.typeWorkflowActionId,
                            markCompleteOnly: a.markComplete,
                            actionNext: a.completedWorkflowActionId,
                            doNotComplete: a.doNotComplete
                        });
                    });

                    return;
                }
            }
        } catch (e) {
            alert(e.message);
        }
    }

    private convertEntityStateToWorkflowActionState(entityState: WorkflowEntityState): string {
        switch (entityState) {
            case WorkflowEntityState.Added:
                return 'ADDED';
            case WorkflowEntityState.Modified:
                return 'MODIFIED';
            case WorkflowEntityState.Deleted:
                return 'DELETED';
            default:
                return 'UNKNOWN';
        }
    }

    private createWorkflowRecordedAction(wfs: WorkflowState, actionCurrent: string, actionNext?: string) {
        const params = { typeWorkflowActionId: actionCurrent };

        const workflowRecordedAction = this._uow.workflowRecordedActionFactory.create(params);

        if (wfs.parsedEntityName() != null) {
            workflowRecordedAction.entityName = wfs.parsedEntityName();
        }

        if (wfs.workflowPropertyName != null) {
            workflowRecordedAction.propertyName = wfs.workflowPropertyName;
        }

        // Record the Reference or Material Id of the entity event being recorded
        if (wfs.workflowReferenceId != null) {
            workflowRecordedAction.referenceId = wfs.workflowReferenceId;
        }

        if (wfs.workflowMaterialId != null) {
            workflowRecordedAction.materialId = wfs.workflowMaterialId;
        }

        if (wfs.workflowObjectId != null) {
            workflowRecordedAction.actionObjectId = wfs.workflowObjectId;
        }

        if (actionNext != null) {
            workflowRecordedAction.completedWorkflowActionId = actionNext;
        }

        if (wfs.workflowContactId != null) {
            workflowRecordedAction.sentToContactId = wfs.workflowContactId;
        }

        if (wfs.workflowNotes != null) {
            workflowRecordedAction.notes = wfs.workflowNotes;
        }

        if (wfs.workflowHoldReason != null) {
            workflowRecordedAction.holdReason = wfs.workflowHoldReason;
        }

        return this._uow.typeWorkflowContactRepository.all().then(r => {
            const contact = r.filter(c => c.emailAddress.toUpperCase() == this._userManager.currentUser.name.toUpperCase());

            if (contact != null && contact.length > 0) {
                workflowRecordedAction.createdWorkflowContactId = contact[0].workflowContactId;
            }
        });
    }

    // Mark related pending actions complete
    private updatePendingWorkflowRecordedActionsForReference(wfs: WorkflowState, actionCurrent: string, reference: Reference, actionChange?: string) {

        const currentUserName = this._userManager.currentUser.name;
        let workflowContactId = 0;

        this._uow.typeWorkflowContactRepository.all().then(r => {
            const contact = r.filter(c => c.emailAddress.toUpperCase() == this._userManager.currentUser.name.toUpperCase());

            if (contact != null && contact.length > 0) {
                workflowContactId = contact[0].workflowContactId;
            }

            if (reference.workflowRecordedActions != null && reference.workflowRecordedActions.length > 0) {

                const pending = reference.workflowRecordedActions.filter(a => a.completedWorkflowActionId == actionCurrent && a.actionCompletedDate == null);

                if (pending != null && pending.length > 0) {
                    pending.forEach(p => {
                        p['actionCompletedDate'] = new Date();
                        if (workflowContactId > 0) {
                            p['completedWorkflowContactId'] = workflowContactId;
                        }
                        p['completedUser'] = currentUserName;
                        if (actionChange) {
                            p['completedWorkflowActionId'] = actionChange;
                        }
                    });
                }
            }

            // New actions are created with the uow repository factory so check here since new actions are not committed and accessible via the Reference navigation list
            const changedEntities = this._uow._emProvider.manager().getChanges();

            if (changedEntities != null) {

                changedEntities.forEach(e => {

                    if (e.entityType.name.indexOf('WorkflowRecordedAction') > -1) {

                        if (e.entityAspect.entityState.isAdded()) {

                            if (e['completedWorkflowActionId'] == actionCurrent && e['actionCompletedDate'] == null) {
                                e['actionCompletedDate'] = new Date();
                                if (workflowContactId > 0) {
                                    e['completedWorkflowContactId'] = workflowContactId;
                                }
                                e['completedUser'] = currentUserName;
                                if (actionChange) {
                                    e['completedWorkflowActionId'] = actionChange;
                                }
                            }
                        }
                    }
                });
            }
        });
    }

    // This is primarily invoked when a SentTo is changed before commit
    rejectWorkflowRecordedNotification(workflowState: WorkflowState, reference: Reference) {

        this.mapStateToAction(workflowState);

        if (this._mappedActions == null || this._mappedActions.length == 0) {
            return;
        }

        this._mappedActions.forEach(map => {
            const notifications = reference.workflowRecordedActions
                .filter(a => a.typeWorkflowActionId == map.actionCurrent && a.sentToContactId == workflowState.workflowContactId);

            if (notifications == null || notifications.length == 0) {
                return;
            }

            notifications.forEach(n => {
                if (n.entityAspect.entityState.isAdded) {
                    n.entityAspect.rejectChanges();
                }
            });
        });
    }

    referenceActions(reference: Reference, action: string): WorkflowRecordedAction[] {

        if (reference.workflowRecordedActions != null && reference.workflowRecordedActions.length > 0) {
            return reference.workflowRecordedActions.filter(a => a.typeWorkflowActionId == action);
        }

        return null;
    }

    referenceActionsWithPendingRequiredAction(reference: Reference, action: string): WorkflowRecordedAction[] {

        if (reference.workflowRecordedActions != null && reference.workflowRecordedActions.length > 0) {
            return reference.workflowRecordedActions.filter(a => a.typeWorkflowActionId == action && a.completedWorkflowActionId != null && a.actionCompletedDate == null);
        }

        return null;
    }

    referenceMostRecentPendingAction(reference: Reference, action: string): WorkflowRecordedAction {

        let mostrecentaction: WorkflowRecordedAction = null;

        if (reference.workflowRecordedActions != null && reference.workflowRecordedActions.length > 0) {

            const completed = reference.workflowRecordedActions.filter(a => a.completedWorkflowActionId == action && a.actionCompletedDate == null);

            if (completed != null && completed.length > 0) {
                const sorted = completed.sort(function (date1, date2) {
                    return date2.created.getTime() - date1.created.getTime();
                });

                mostrecentaction = sorted[0];
            }
        }

        // New actions are created with the uow repository factory so check here since new actions are not committed and accessible via the Reference navigation list
        const changedEntities = this._uow._emProvider.manager().getChanges();

        if (changedEntities != null) {

            changedEntities.forEach(e => {

                if (e.entityType.name.indexOf('WorkflowRecordedAction') > -1) {

                    if (e.entityAspect.entityState.isAdded()) {
                        if (e['completedWorkflowActionId'] == action && e['actionCompletedDate'] == null) {
                            if (mostrecentaction == null) {
                                mostrecentaction = e.entityAspect.entity as WorkflowRecordedAction;
                            } else if (e['created'] > mostrecentaction.created) {
                                mostrecentaction = e.entityAspect.entity as WorkflowRecordedAction;
                            }
                        }
                    }
                }
            });
        }

        return mostrecentaction;
    }

    referenceMostRecentCompletedAction(reference: Reference, action: string): WorkflowRecordedAction {

        let mostrecentaction: WorkflowRecordedAction = null;

        if (reference.workflowRecordedActions != null && reference.workflowRecordedActions.length > 0) {
            const completed = reference.workflowRecordedActions.filter(a => a.completedWorkflowActionId == action && a.actionCompletedDate != null);

            if (completed != null && completed.length > 0) {
                const sorted = completed.sort(function (date1, date2) {
                    return date2.created.getTime() - date1.created.getTime();
                });

                mostrecentaction = sorted[0];
            }
        }

        // New actions are created with the uow repository factory so check here since new actions are not committed and accessible via the Reference navigation list
        const changedEntities = this._uow._emProvider.manager().getChanges();

        if (changedEntities != null) {

            changedEntities.forEach(e => {

                if (e.entityType.name.indexOf('WorkflowRecordedAction') > -1) {

                    if (e.entityAspect.entityState.isAdded()) {

                        if (e['completedWorkflowActionId'] == action && e['actionCompletedDate'] != null) {
                            if (mostrecentaction == null) {
                                mostrecentaction = e.entityAspect.entity as WorkflowRecordedAction;
                            } else if (e['created'] > mostrecentaction.created) {
                                mostrecentaction = e.entityAspect.entity as WorkflowRecordedAction;
                            }
                        }
                    }
                }
            });
        }

        return mostrecentaction;

    }

    cancelPendingWorkflowRecordedActionsForReference(reference: Reference, pendingAction: string, mostRecentOnly: boolean) {

        if (reference.workflowRecordedActions == null || reference.workflowRecordedActions.length == 0) {
            return;
        }

        const pending = reference.workflowRecordedActions.filter(a => a.completedWorkflowActionId == pendingAction && a.actionCompletedDate == null);

        if (pending == null || pending.length == 0) {
            return;
        }

        const currentUserName = this._userManager.currentUser.name;

        if (mostRecentOnly) {
            const sorted = _.sortBy(pending, p => p.created).reverse();
            sorted[0].actionCompletedDate = new Date();
            sorted[0].completedUser = currentUserName;
            sorted[0].completedWorkflowActionId = 'CANCELLED';
        } else {
            pending.forEach(p => {
                p['actionCompletedDate'] = new Date();
                p['completedUser'] = currentUserName;
                p['completedWorkflowActionId'] = 'CANCELLED';
            });
        }
    }

    deleteActionBeforeSave(reference: Reference, action: string) {

        if (reference.workflowRecordedActions == null || reference.workflowRecordedActions.length == 0) {
            return;
        }

        const todeletes = reference.workflowRecordedActions.filter(a => a.entityAspect.entityState.isAdded() && a.typeWorkflowActionId == action);

        if (todeletes == null || todeletes.length == 0) {
            return;
        }

        todeletes.forEach(d => {
            d.entityAspect.setDeleted();
        });
    }
}

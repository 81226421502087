import * as Raven from 'raven-js';
import { UserManager } from '../services/user-manager';
import { Settings } from './app-config';

export function configRavenLogging() {
    // Configure Sentry.io logging service, this needs to go before NgModule configuration to be able capture errors during the Angular bootstrap process
    if (Settings.enableLogging) {
        Raven
            .config(Settings.sentryIoDsn,
                {
                    environment: Settings.environment,
                    release: UserManager.versionNo
                })
            .install();
    }
}

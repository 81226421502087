import { Entity, EntityError, EntityType } from 'breeze-client';

import { StateMap, UnitOfWork } from '../services/common';
import { PageState } from '../controls/page-state';
import * as _ from 'lodash';
import { OnInit, Directive } from '@angular/core';

export interface IEditHost<T extends Entity> {
    canAdd?: () => boolean;
    onAdd?: () => void;
    onAddPromise?: () => Promise<void>;
    canDelete?: () => boolean;
    onDelete?: () => void;
    canSave?: () => boolean;
    onSave?: () => void;

    onApply?: () => boolean;
    onCancel?: () => void;
}

@Directive()
export class EditManager<T extends Entity> implements OnInit {

    public entities: T[];

    public editing: boolean;
    public errorMessages: string[];
    public femaRestricted = false;

    public pageState: PageState;
    public singleEntityOnly = false;
    _currentIx = 0;
    private _entityType: EntityType;
    status = {};

    constructor(protected _host: IEditHost<T>, public uow: UnitOfWork, public stateMap: StateMap, public pageName: string) {
        this.pageState = new PageState(pageName);
        this.errorMessages = [];
    }

    ngOnInit() {

    }

    set onlyEntity(value: T) {
        this.entities = [value];
        this.singleEntityOnly = true;
    }

    get currentEntity() {
        const entities = this.entities;
        if ((!entities) || entities.length == 0) {
            return null;
        } else if (this._currentIx < 0) {
            this._currentIx = 0;
        } else if (this._currentIx >= entities.length) {
            this._currentIx = entities.length - 1;
        }
        return entities[this._currentIx];
    }

    set currentEntity(e: T) {
        const entities = this.entities;
        if (entities) {
            this._currentIx = entities.indexOf(e);
        }
    }

    movePrev() {
        this._currentIx--;
        return this.currentEntity;
    }

    moveNext() {
        this._currentIx++;
        return this.currentEntity;
    }

    isStaff() {
        if (this.stateMap.currentUser.isStaff == false) {
            return false;
        }

        if (this.femaRestricted != null && this.femaRestricted == true) {
            return !this.stateMap.currentUser.isInRole('fema'); // FEMA staff can't edit any Material data
        }

        return this.stateMap.currentUser.isStaff;
    }

    hasEntities() {
        return !!(this.entities && this.entities.length > 0);
    }

    hasEdits() {
        return this.uow.hasChanges();
    }

    hasErrors() {
        return this.errorMessages.length > 0;
    }

    addError(message: string) {
        // only allow one copy of each error.
        if (this.errorMessages.indexOf(message) >= 0) {
            return;
        }
        this.errorMessages.push(message);
    }

    clearErrors() {
        this.errorMessages.length = 0;
        this.status = null;
    }

    onSelect(e: T) {
        if (!this.isStaff()) {
            return;
        }
        this.currentEntity = e;
        this.editing = true;
    }

    onEdit() {
        this.editing = !this.editing;
    }

    canAdd() {
        if (this._host.canAdd) {
            return this._host.canAdd();
        } else {
            return this.canAddCore();
        }
    }

    canAddCore() {
        if (this.editing) {
            return !this.currentEntity.entityAspect.hasValidationErrors;
        } else {
            return true;
        }
    }

    onAdd() {
        let p: Promise<void>;
        if (this._host.onAddPromise) {
            p = this._host.onAddPromise();
        } else {
            p = Promise.resolve(this._host.onAdd());
        }
        p.then(() => {
            if (!this.currentEntity.entityAspect.entityState.isAdded()) {
                this.setStatus({ message: 'Add failed - validation error on current entity', classes: 'label-danger', isTemp: true });
            }
        });
    }

    canDelete() {
        return this._host.canDelete ? this._host.canDelete() : this.canDeleteCore();
    }

    canDeleteCore() {
        return this.hasEntities();
    }

    onDelete() {
        this.captureEntityType();
        return this._host.onDelete ? this._host.onDelete() : this.onDeleteCore();
    }

    onDeleteCore() {
        const s = this.currentEntity;
        if (s.entityAspect.entityState.isAdded()) {
            s.entityAspect.rejectChanges();
        } else {
            s.entityAspect.setDeleted();
        }
        _.remove(this.entities, <any>this.currentEntity);
        this.updatePageState();
        this.editing = false;
    }

    canApply() {
        const ea = this.currentEntity.entityAspect;
        return (!ea.entityState.isUnchanged()) && (!ea.hasValidationErrors);
    }

    onApply(): boolean {
        return this._host.onApply ? this._host.onApply() : this.onApplyCore();
    }

    onApplyCore(): boolean {
        if (!this.validateCurrent()) {
            return false;
        }

        this.editing = false;
        this.updatePageState();
        return true;
    }

    canCancel() {
        if (this.editing) {
            return true;
        } else {
            return this.hasEdits();
        }
    }

    canShowBack() {
        return this.editing && this.currentEntity.entityAspect.entityState.isUnchanged();
    }

    onBack() {
        this.editing = false;
    }

    onCancel() {
        return this._host.onCancel ? this._host.onCancel() : this.onCancelCore();
    }

    onCancelCore() {
        if (this.entities && this.entities.length) {
            if (this.editing) {
                const ea = this.currentEntity.entityAspect;
                const es = ea.entityState;
                if (es.isUnchanged()) {
                    this.editing = false;
                } else if (es.isModified()) {
                    ea.rejectChanges();
                } else if (es.isDetached()) {
                    this.editing = false;
                } else {
                    ea.rejectChanges();
                    this.editing = false;
                }
            } else {
                this.restoreDeleted();
                this.uow.rollback();

            }
            // remove any added then rolled back items.
            _.remove(this.entities, fs => fs.entityAspect.entityState.isDetached());
        }
        if (this.uow.hasChanges() && !this.editing) {
            this.restoreDeleted();
            this.uow.rollback();
        } else {
            this.editing = false;
        }
        this.updatePageState();
        this.clearErrors();
    }

    captureEntityType() {
        if (this._entityType) {
            return;
        }
        this._entityType = this.currentEntity.entityType;
    }

    restoreDeleted() {
        if (!this._entityType) {
            return;
        }
        const deletedEntities = <T[]>this.uow.getChanges(this._entityType).filter(e => e.entityAspect.entityState.isDeleted());
        this.entities.push(...deletedEntities);
    }

    canSave() {
        return this._host.canSave ? this._host.canSave() : this.canSaveCore();
    }

    canSaveCore() {
        // TODO: think about requiring validation... ( may be expensive)
        return this.hasEdits();
    }

    onSave() {
        return this._host.onSave ? this._host.onSave() : this.onSaveCore();
    }

    onSaveCore() {
        if (!this.validateBeforeSave()) {
            return Promise.resolve();
        }
        this.pageState.isLoading = true;
        return this.commitSave().then(() => {
            this.pageState.isLoaded = true;
        }).catch(() => {
            this.pageState.isLoaded = false;
        });
    }

    updatePageState() {
        if (this.pageState) {
            this.pageState.isLoaded = this.entities;
        }
    }

    validateCurrent() {
        if (this.editing) {
            return this.currentEntity.entityAspect.validateEntity();
        } else {
            return true;
        }
    }

    validateBeforeSave() {
        const areAllValid = this.entities.filter(e => !e.entityAspect.entityState.isUnchanged())
            .every(s => {
                const ok = s.entityAspect.validateEntity();
                if (!ok) {
                    this.onSelect(s);
                }
                return ok;
            });

        return areAllValid;
    }

    commitSave() {
        return this.uow.commit().then(() => {
            this.setSavedStatus();
        }).catch(e => {
            this.handleSaveError(e);
        });
    }

    handleSaveError(e: any) {
        if (e.entityErrors) {
            // TODO: type EntityErrors in d.ts
            e.entityErrors.forEach((err: EntityError) => this.addError(
                err.errorMessage));
        }

        if (e.message && e.message.startsWith('The DELETE statement')) {
            this.setSaveFailedStatus('Save failed: Cannot perform delete because of existing relations / references.');
            throw e;
        } else {
            this.setSaveFailedStatus();
            throw e;
        }
    }

    setSavedStatus(message?: string) {
        this.setStatus({ message: message || 'Saved', classes: 'label-success', isTemp: true });
    }

    setSaveFailedStatus(message?: string) {
        this.setStatus({ message: message || 'Save failed', classes: 'label-danger', isTemp: false });
    }

    setStatus(status: { message: string, classes: string, isTemp: boolean }) {
        status.classes = 'label ' + status.classes;
        this.status = status;
        if (status.isTemp) {
            setTimeout(() => {
                this.status = null;
            }, 2000);
        }
    }

}


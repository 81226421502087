import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import * as _ from 'lodash';

import { StateMap, UnitOfWork, UserManager } from '../../services/common';
import { EditManager, IEditHost, LocationFns } from '../../utils/common';

import { MATERIAL_ROUTES } from './material.routes';
import { StatusNtpComponent } from './status-ntp.component';

import { NationalToxicologyProgram } from '../../entities/EntityModels';

@Component({
    selector: 'status-ntps',
    templateUrl: './status-ntps.html',
})
export class StatusNtpsComponent implements IEditHost<NationalToxicologyProgram>, OnInit {
    @Input() materialId: number;
    @Input() statusType: string;

    @ViewChild(StatusNtpComponent) editingComponent: StatusNtpComponent;

    _edm: EditManager<NationalToxicologyProgram>;

    constructor(public _uow: UnitOfWork, public _userManager: UserManager, public _stateMap: StateMap, public _location: Location) {

    }

    ngOnInit() {
        LocationFns.setTab(this._location, '3');
        this._stateMap.currentRouteName = MATERIAL_ROUTES.Status.name;

        this._edm = new EditManager<NationalToxicologyProgram>(this, this._uow, this._stateMap, 'National Toxicology Program');

        this._edm.pageState.isLoading = true;
        const p = {materialId: this.materialId};
        this._uow.nationalToxicologyProgramRepository.whereWithParams(p).then(r => {
            this._edm.entities = r;
            this._edm.pageState.isLoaded = r;
        });
    }

    canDeactivate() {
        return !this._edm.hasEdits();
    }

    onAdd() {
        if (!this._edm.validateCurrent()) {
            return;
        }

        const e = this._uow.nationalToxicologyProgramFactory.create();
        e.materialId = this.materialId;
        e.modified = new Date();
        e.lastReviewDate = new Date();

        this._edm.entities.push(e);
        this._edm.onSelect(e);
    }

    get sortedNTPEntities(): NationalToxicologyProgram[] {
        if (this._edm.entities != null) {
            return _.sortBy(this._edm.entities, n => n.lastReviewDate).reverse();
        } else {
            return null;
        }
    }
}

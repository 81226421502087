import { EntityBase } from './EntityBase';

import { Validator } from 'breeze-client';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

export class WorkflowPendingAction extends EntityBase {

   /// <code> Place custom code between <code> tags
   public abbreviatedTitle() {
        if (this.title != null && this.title.length > 51) {
            return (this.title.substring(0, 50) + '...');
        }

        return this.title;
    }
   /// </code>

   // Generated code. Do not place code below this line.
   workflowRecordedActionId: number;
   typeWorkflowActionId: string;
   completedWorkflowActionId: string;
   toCompleteDescription: string;
   actionCompletedDate: Date;
   completedUser: string;
   sentToContactId: number;
   workflowContactFirstName: string;
   workflowContactEmailAddress: string;
   createdWorkflowContactId: number;
   createdContactFirstName: string;
   createdContactEmailAddress: string;
   referenceId: number;
   briefReference: string;
   title: string;
   precedence: number;
   rIFMArticle: boolean;
   fEMAArticle: boolean;
   articleType: string;
   rIFMAsAuthor: boolean;
   restrictedDistribution: boolean;
   created: Date;
   createdUser: string;
   holdReason: string;
   onHoldStatus: string;
}


import { Component, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import * as _ from 'lodash';

import { StateMap, UnitOfWork } from '../../services/common';
import { IBrowser, UtilFns } from '../../utils/common';
import { PageState } from '../../controls/common';

import { MATERIAL_ROUTES } from './material.routes';
import { SynopsisReportComponent } from './synopsis-report.component';
import { ExperimentalMaterial, Material, TypeStudy } from '../../entities/EntityModels';

interface TypeStudySelector {
    typeStudy: TypeStudy;
    selected: boolean;
}

@Component({
    selector: 'synopsis',
    templateUrl: './synopsis.html',
})
export class SynopsisComponent implements OnInit {
    @ViewChild(SynopsisReportComponent) _synopsisReportComponent: SynopsisReportComponent;

    _material: Material;
    _pageState = new PageState('Synopsis');
    _typeStudies: TypeStudySelector[];
    _typeStudyIds: string[];
    _canShowReport = false;
    _expMats: ExperimentalMaterial[];
    _browser: IBrowser;
    _largeResultMessage: string;
    _rifmAsAuthorOnly = false;

    constructor(public _uow: UnitOfWork, public _stateMap: StateMap, public _location: Location) {

    }

    ngOnInit() {
        this._stateMap.currentRouteName = MATERIAL_ROUTES.Synopsis.name;
        this._material = this._stateMap.currentMaterial;
        let studyIds: string[];
        const pEm = {materialId: this._material.materialId};
        this._pageState.isLoading = true;
        this._uow.fetchTyped('Materials/FullExperimentalMaterials', ExperimentalMaterial, pEm).then(r => {
            this._expMats = _.sortBy(r, x => x.biologicalData.typeStudy.studyLong);
            studyIds = _.uniq(this._expMats.map(em => em.typeStudyId()));
            this.checkIfTooLarge();
            return this._uow.typeStudyRepository.all();
        }).then((r) => {
            r = r.filter(ts => studyIds.indexOf(ts.typeStudyId) != -1);
            r = _.sortBy(r, x => x.studyLong);
            this._typeStudies = r.map(item => <TypeStudySelector>{
                typeStudy: item,
                selected: true
            });
            this._pageState.isLoaded = true;
        }).catch(() => {
            this._pageState.isLoaded = false;
        });
    }

    checkIfTooLarge() {
        if (this._expMats.length < 500) {
            return;
        }
        this._browser = UtilFns.detectBrowser();
        if (this._browser.isEdge || this._browser.isChrome) {
            // chrome and edge can handle larger sizes than the other browsers.
            if (this._expMats.length < 1000) {
                return;
            }
        }
        this._largeResultMessage = `Generating this report for all Study Types may take awhile. Please consider selecting just a subset of the existing study types`;

    }

    selectAll(value: boolean) {
        this._typeStudies && this._typeStudies.forEach(ts => ts.selected = value);
    }

    generateReport(rifmAsAuthorOnly: boolean) {
        this._pageState.isLoading = true;
        this._rifmAsAuthorOnly = rifmAsAuthorOnly;
        setTimeout(() => {
            const filteredStudies = this._typeStudies.filter(x => x.selected);
            if (filteredStudies.length == this._typeStudies.length) {
                this._typeStudyIds = null;
            } else {
                this._typeStudyIds = filteredStudies.map(x => x.typeStudy.typeStudyId);
            }
            this._canShowReport = true;
            setTimeout(() => {
                const ele = $('#SynopsisReport');
                (<any>ele).modal('show');
                ele.on('hidden.bs.modal', () => {
                    this._pageState.isLoading = false;
                    this._canShowReport = false;
                });
            }, 0);
        });
    }
}

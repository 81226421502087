import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';

import { UnitOfWork, StateMap } from '../../services/common';
import { HOME_ROUTES} from './home.routes';

@Component({
    selector: 'ifra-survey-use',
    templateUrl: './ifra-survey-use.html',
})

export class IFRASurveyUseComponent implements OnInit {

    _canLoad = false;

    constructor(public _uow: UnitOfWork, public _stateMap: StateMap, public _location: Location) {

    }

    ngOnInit() {
        this._stateMap.currentRouteName = HOME_ROUTES.IFRASurveyUse.name;
    }

    canDeactivate() {
        return (!this._uow.hasChanges());
    }
}



<div class="row btn-bar indent-top-sm">
    <button *ngIf="!isEditing" type="button" class="btn btn-sm btn-primary" [disabled]="!canAdd()" (click)="onAdd()">Add</button>
    <button *ngIf="isEditing" type="button" class="btn btn-sm btn-primary" [disabled]="!canDelete()" (click)="onDelete(pe)">Delete</button>
    <button type="button" class="btn btn-sm btn-primary" [disabled]="!canSave()" (click)="onSave()">Save</button>
    <button type="button" class="btn btn-sm btn-primary" [disabled]="!canCancel()" (click)="onCancel()">Cancel</button>
    <span *ngIf="hasEdits()" class="label label-warning">
        You cannot leave the Assessment Categories tab until you either 'Save' or 'Cancel'
    </span>
    <span *ngIf="_status" [ngClass]="_status.classes">{{_status.message}}</span>
</div>

<div class="form-horizontal">
    
    <div *ngIf="isEditing && !canDelete()">
        <p class="info">Safety assessment categories can only be deleted after all SAs have been unassigned.</p>
    </div>

    <div class="form-group" *ngIf="isEditing">
        <edit-prop isChild label="Safety Assessment category" prop="name" szs="1,10">
            <input class="form-control" type="text" [disabled]="isDeleted()" [(ngModel)]="entity.name">
        </edit-prop>
    </div>

    <div class="form-group">
        <div *ngIf="!isEditing" class="col-md-4">
            <default-table>
                <thead>
                    <tr>
                        <td>Name</td>
                        <td>SA Count</td>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let c of assessmentCategories" (click)="onCategorySelected(c)">
                        <td>{{c.name}}</td>
                        <td>{{getAssessmentCount(c.assessmentCategoryId)}}</td>
                    </tr>
                </tbody>
            </default-table>
        </div>
    </div>

</div>
import { EntityBase } from './EntityBase';
import { MaterialUNTransport } from './MaterialUNTransport';
import { TypeHazardousSymbol } from './TypeHazardousSymbol';

import { Validator } from 'breeze-client';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

export class MaterialUNTransportCode extends EntityBase {

   /// <code> Place custom code between <code> tags
   
   /// </code>

   // Generated code. Do not place code below this line.
   materialId: number;
   hazardCodeId: string;
   created: Date;
   createdUser: string;
   modified: Date;
   modifyUser: string;
   rowVersion: number;
   materialUnTransport: MaterialUNTransport;
   typeHazardousSymbol: TypeHazardousSymbol;
}


<h4>View Online</h4>
<div style="margin-top: 8px;">
    <div class="display-block">
        <button type="button" class="btn btn-summary-report" (click)="onShowMostRecentSurveyReport()">Show Most Recent Survey for Material Report</button>
    </div>
</div>

<div style="margin-top: 50px;">
    <h4>Available for Download</h4>
    <div style="margin-top: 8px;">

        <div class="display-block subtitle-margin-top" style="margin-bottom: 20px;">
            <button type="button" class="btn btn-threshold-report" (click)="onExportMaterialThresholdReport()">Material Threshold Report</button>
        </div>

        <div *ngIf="_rifmReports">
            <div *ngFor="let r of _rifmReports">

                <div class="display-block subtitle-margin-top" style="margin-bottom: 22px;">
                    <button type="button" class="btn btn-survey-excel-report" (click)="onShowRIFMReport(r)">{{r.reportTitle}}</button>
                </div>

            </div>
        </div>

    </div>
</div>

<exposure-survey-last-report *ngIf="_exposureSurveyMostRecents" [reportItems]="_exposureSurveyMostRecents"></exposure-survey-last-report>

import { Component, OnInit, Input} from '@angular/core';
import { Location } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';

import { UnitOfWork, StateMap } from '../../services/common';
import { UtilFns, LocationFns } from '../../utils/common';
import { PageState, ColumnSorter } from '../../controls/common';
import { PaginationService, IPaginationInstance} from '../../controls/ng2-pagination/ng2-pagination';

import { ROUTES } from '../routes';
import { ReferenceAuthor } from '../../entities/EntityModels';

class PageStateExt extends PageState {
    operatorName: string;
    searchTerm: string;
}

@Component({
    templateUrl: './search-authors.html',
    providers: [PaginationService]
})
export class SearchAuthorsComponent implements OnInit  {

    _referenceAuthors: ReferenceAuthor[];

    _operators = [
        { name: 'startsWith', title: 'StartingWith' },
        { name: 'contains', title: 'Containing' }
    ];

    _pagination: IPaginationInstance;
    _colSorter: ColumnSorter;

    _pageState = new PageStateExt('Author References');

    constructor(public _uow: UnitOfWork, public _stateMap: StateMap,
        public _location: Location, public _router: Router ) {
    }

    ngOnInit() {

        this._pagination = this._pageState.getPaginationInfo();
        this._colSorter = new ColumnSorter(this, {
                'Author':  'author.authorName',
                'Initials':  'author.initials' ,
                'Publication Date':  'reference.yearOfPublication' ,
                'Title':  'reference.title'
            }, 'Author', (sortColumn) => this._pageState.sortColumn = sortColumn );

        this._pageState.operatorName = this._operators[0].name;

        // setTimeout needed because of Angular RC5 bug where Location is not set correctly until
        // after timeout resolves.
        setTimeout(() => {
            LocationFns.updatePageState(this._location, this._stateMap, this._pageState, (state: PageState) => {
                this._colSorter.sortColumn = state.sortColumn;
                this.search();
            });
        }, 0);
    }

    opChange(event: any) {
        // avoid thrashing
        const opName = event.target.value;
        if (this._pageState.operatorName == opName) { return; }
        this._pageState.operatorName = opName;
        this.newSearch();
    }

    // called by columnSorter.
    sortWith(colSorter: ColumnSorter) {
        this._pageState.pageNumber = 1;
        this.search();
    }

    newSearch() {
        this._pageState.resetPaging();
        this._colSorter.reset();
        return this.search();
    }

    clear() {
        this._referenceAuthors = null;
        this._pageState.searchTerm = '';
        this._pageState.resetPaging();
        this._colSorter.reset();

    }

    fetchPage(pageNumber: number) {
        this._pageState.pageNumber = pageNumber;
        return this.search();
    }

    search() {
        this._referenceAuthors = null;
        if (!this._pageState.searchTerm) { return; }
        const opValue = { };
        opValue[this._pageState.operatorName] = this._pageState.searchTerm;
        const pred = { 'author.authorName': opValue };
        // var p = new Predicate("author.authorName", this._operator, this._searchTerm)
        const orderBy = this._colSorter.getOrderBy();
        this._pageState.isLoading = true;
        const execQ = this._uow.referenceAuthorRepository
            .wherePaged(pred, orderBy, this._pageState.pageNumber, this._pageState.pageSize);

        return execQ.then( r => {
            this._pagination.currentPage = this._pageState.pageNumber;
            this._pagination.totalItems = this._pageState.updateEstimate(r.length);
            this._referenceAuthors = r;
            this._pageState.isLoaded = r;

            return r;
        }).catch(e => {
            this._pageState.isLoaded = false;
        });

    }

    selectReference(ra: ReferenceAuthor ) {
        this._router.navigate(UtilFns.asRouterParts(ROUTES.Reference, ra.reference.referenceId));
    }





}

import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';

import { UnitOfWork, StateMap } from '../../services/common';
import { PageState } from '../../controls/common';

@Component({
    templateUrl: './intro.html'
})
export class IntroComponent implements OnInit   {

    _pageState = new PageState("Introduction");
    
    constructor(public _uow: UnitOfWork, public _location: Location, public _stateMap: StateMap) {
    }
    
    ngOnInit() {
    }
}

import { EntityBase } from './EntityBase';
import { ClusterPath } from './ClusterPath';
import { SuperCluster } from './SuperCluster';

import { Validator } from 'breeze-client';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

export class SuperClusterPath extends EntityBase {

   /// <code> Place custom code between <code> tags
   
   /// </code>

   // Generated code. Do not place code below this line.
   superClusterPathId: number;
   superClusterId: number;
   clusterPathId: number;
   notes: string;
   created: Date;
   createdUser: string;
   modified: Date;
   modifyUser: string;
   rowVersion: number;
   clusterPath: ClusterPath;
   superCluster: SuperCluster;
}


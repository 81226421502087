<div class="form-horizontal">

    <div class="col-md-7">
        <edit-prop label="Synonym" prop="synonymWord" szs="2,9" >
            <input class="form-control" type="text" (focus)="originalValue=synonym.synonymWord" (change)="onSynonymWordChange(originalValue)" [(ngModel)]="synonym.synonymWord">
        </edit-prop>
        <div class="form-group" *ngIf="_synonymCollisionWarning">
            <span class="col-md-offset-2 alert-warning bold">{{_synonymCollisionWarning}}</span>
        </div>
        <edit-prop label="Alphabetic version" prop="rootWord" szs="2,9" >
            <input class="form-control" type="text" [(ngModel)]="synonym.rootWord">
        </edit-prop>
        <edit-prop label="Principal" prop="principal" szs="2,2" > <!--[disabled]="synonym.principal"-->
            <input class="form-control" type="checkbox" [(ngModel)]="synonym.principal" (change)="setPrincipalName()" />
        </edit-prop>
    </div>

    <div class="col-md-5">
        <div *ngFor="let tsu of allTsus, let ix=index">
            <div class="row">
                <strong class="col-md-2 text-right">{{tsu.synonymUseLong}}:</strong>
                <input type="checkbox" (click)="onSetModified()" [(ngModel)]="tsuChecks[ix]">
            </div>
        </div>
    </div>
</div>

<div class="indent-top-sm"></div>
<div class="panel panel-default" style="margin-right: 30px;" *ngIf="_bd">
    <div class="panel-body">
        <div class="row">
            <div class="col-md-3">
                <button type="button" style="margin-left: 0px; margin-right: 5px" (click)="expandProtocol = !expandProtocol" class="btn btn-default btn-xs">
                    <span *ngIf="expandProtocol" class="fa fa-minus"></span>
                    <span *ngIf="!expandProtocol" class="fa fa-plus"></span>
                </button>
                <label style="font-size: 16px">Protocol: </label>
                <label> Sub-Reference:</label>
                <span>{{_bd.subReference}}</span>
            </div>
            <div class="col-md-3">
                <label>Study:</label>
                <span>{{_bd.typeStudy?.studyLong}}</span>
            </div>
            <div class="col-md-3">
                <label>Route:</label>
                <span [htmlGreek]="_bd.typeRoute?.routeLong"></span>
            </div>
            <div class="col-md-2">
                <label>Species:</label>
                <span [htmlGreek]="_bd.typeSpecies?.speciesLong"></span>
            </div>
        </div>
        <div *ngIf="expandProtocol" class="row">
            <div class="col-md-11">
                <label>Protocol:</label>
                <span>{{_bd.protocol || '-'}}</span>
            </div>
        </div>

    </div>
</div>


<div class="panel panel-default" style="margin-right: 30px;">
    <div class="panel-body">
        <div class="row">
            <div class="col-md-3">
                <button type="button" style="margin-left: 0px; margin-right: 5px" (click)="expandExpMaterial = !expandExpMaterial" class="btn btn-default btn-xs">
                    <span *ngIf="expandExpMaterial" class="fa fa-minus"></span>
                    <span *ngIf="!expandExpMaterial" class="fa fa-plus"></span>
                </button>
                <label style="font-size: 16px">Experimental Material: </label>
            </div>
            <div class="col-md-2 ">
                <button type="button" class="btn btn-sm btn-success common-spacer" (click)="onSelectMaterial()">Select Material</button>
            </div>
            <div class="col-md-7">
                <material-summary class="" [material]="expMaterial.material"></material-summary>
            </div>
        </div>
        <div class="form-horizontal" *ngIf="expandExpMaterial">
            <div class="form-group">
                <edit-prop *ngIf="!_isAddingVehicle" isChild label="Vehicle" prop="vehicle" szs="2,5">
                    <select class="form-control" [ngModel]="expMaterial.typeExperimentVehicleId" (change)="expMaterial.typeExperimentVehicleId=parseVehicle($event.target.value)">
                        <option *ngFor="let item of _typeExperimentVehicles" [value]="item.typeExperimentVehicleId">{{item.vehicle}}</option>
                    </select>
                </edit-prop>
                <div *ngIf="!_isAddingVehicle" class="col-md-1 ">
                    <button type="button" class="btn btn-sm btn-success" (click)="onAddVehicle()">Add a Vehicle</button>
                </div>
                <edit-prop *ngIf="_isAddingVehicle" isChild label="Vehicle Code/Description" prop="" szs="2,1">
                    <input class="form-control" type="text" [(ngModel)]="_addedVehicleCode">
                </edit-prop>
                <edit-prop *ngIf="_isAddingVehicle" isChild prop="" szs="0,5">
                    <input class="form-control" type="text" [(ngModel)]="_addedVehicleDescription">
                </edit-prop>
                <div *ngIf="_isAddingVehicle" class="col-md-3 ">
                    <button type="button" class="btn btn-sm btn-success" [disabled]="!canAcceptVehicle()" (click)="onAcceptVehicle()">Accept New Vehicle</button>
                    <button type="button" class="btn btn-sm btn-success" (click)="onCancelVehicle()">Cancel New Vehicle</button>
                </div>
            </div>

            <edit-prop label="Details" prop="experimentalDetails" szs="2,6">
                <input class="form-control" type="text" [(ngModel)]="expMaterial.experimentalDetails">
            </edit-prop>

            <edit-prop label="Sample" prop="sampleText" szs="2,10">
                <textarea class="form-control" type="text" [(ngModel)]="expMaterial.sampleText"></textarea>
            </edit-prop>

            <div class="form-group">
                <edit-prop isChild label="Calculated Result Type" prop="typeToxicEffectId" szs="2,3">
                    <select class="form-control" [ngModel]="expMaterial.typeToxicEffectId" (change)="onSetTypeToxicEffectId($event.target.value)">
                        <option selected value>-- None --</option>
                        <option *ngFor="let item of _calculatedToxicEffects" [value]="item.typeToxicEffectId">{{item.toxicEffectDescription}}</option>
                    </select>
                </edit-prop>

                <edit-prop label="Calculated Result Value" prop="calculatedResult" szs="2,4">
                    <textarea class="form-control" type="text" [(ngModel)]="expMaterial.calculatedResult" (change)="onSetCalculatedResult($event.target.value)"></textarea>
                </edit-prop>
            </div>

            <div class="form-group">
                <edit-prop isChild label="Contains Mixture" prop="typeStudyMixtureId" szs="2,6">
                    <select class="form-control" [ngModel]="expMaterial.typeStudyMixtureId" (change)="expMaterial.typeStudyMixtureId=$event.target.value">
                        <option disabled selected value>-- None --</option>
                        <option *ngFor="let item of _typeStudyMixtures" [value]="item.typeStudyMixtureId">{{item.description}}</option>
                    </select>
                </edit-prop>
            </div>

            <div class="form-group">
                <edit-prop isChild label="Summary" prop="experimentalSummary" szs="2,6">
                    <textarea class="form-control" rows="3" [(ngModel)]="expMaterial.experimentalSummary"></textarea>
                </edit-prop>
            </div>

            <div class="form-group">
                <edit-prop isChild label="Last Modified" szs="2,3">
                    <!--<label class="control-label">{{expMaterial.modified | date}}</label>                    -->
                    <label class="control-label">{{ _stateMap.utilFns.formatDate(expMaterial.modified) }}</label>
                </edit-prop>
            </div>
        </div>
    </div>
</div>

<div class="panel panel-default" style="margin-right: 30px;">
    <div class="panel-body">
        <div class="row">
            <div class="col-md-3">
                <button type="button" style="margin-left: 0px; margin-right: 5px" (click)="expandExpResults = !expandExpResults" class="btn btn-default btn-xs">
                    <span *ngIf="expandExpResults" class="fa fa-minus"></span>
                    <span *ngIf="!expandExpResults" class="fa fa-plus"></span>
                </button>
                <label style="font-size: 16px">Experimental Results: </label>
                <label> ({{expMaterial.experimentalResults?.length}})</label>
            </div>
            <div class="col-md-2">
                <button type="button" class="btn btn-sm btn-success" (click)="onAddExperimentalResult()">Add Experimental Result</button>
            </div>
        </div>

        <div class="form-horizontal" *ngIf="expandExpResults && expMaterial.experimentalResults">
            <div *ngFor="let er of expMaterial.experimentalResults;let ix=index" class="indent-top-sm">
                <div class="panel panel-default" style="border-color: #949494;">
                    <div class="panel-body">
                        <div class="form-group">
                            <edit-prop isChild label="Dose" prop="compOp" [id]="ix" szs="1,2">
                                <select class="form-control" [ngModel]="er.compOp" (change)="er.compOp=$event.target.value">
                                    <option disabled selected value>-- None --</option>
                                    <option *ngFor="let op of compOps" [value]="op.compOp">{{op.desc}}</option>
                                </select>
                            </edit-prop>
                            <edit-prop isChild prop="dose" [id]="ix" szs="0,2">
                                <input class="form-control" type="number" [(ngModel)]="er.dose">
                            </edit-prop>
                            <edit-prop isChild label="Unit" prop="typeUnitId" [id]="ix" szs="1,3">
                                <select class="form-control" [ngModel]="er.typeUnitId" (change)="er.typeUnitId=$event.target.value">
                                    <option *ngFor="let item of _typeUnits" [value]="item.typeUnitId">{{item.unitDescription}}</option>
                                </select>
                            </edit-prop>
                            <div class="col-md-3">
                                <button type="button" class="btn btn-sm btn-success" (click)="onDeleteExperimentalResult(er)">Delete Experimental Result</button>
                            </div>
                        </div>
                        <div class="form-group">
                            <edit-prop isChild label="DPM/Node" prop="dPMperLymphNode" [id]="ix" szs="1,3">
                                <input class="form-control" type="text" [(ngModel)]="er.dPMperLymphNode">
                            </edit-prop>
                            <edit-prop isChild label="Stimulation Index" prop="stimulationIndex" [id]="ix" szs="2,3">
                                <input class="form-control" type="text" [(ngModel)]="er.stimulationIndex">
                            </edit-prop>
                        </div>

                        <div class="form-group">
                            <edit-prop isChild label="Vehicle" prop="vehicle" [id]="ix" szs="1,3">
                                <select class="form-control" [(ngModel)]="er.typeExperimentVehicleId" (change)="er.typeExperimentVehicleId=$event.target.value">
                                    <option *ngFor="let item of _typeExperimentVehicles" [value]="item.typeExperimentVehicleId">{{item.vehicle}}</option>
                                </select>
                            </edit-prop>
                        </div>

                        <div class="form-group">
                            <edit-prop isChild label="Add Toxic Effect" szs="1,4">
                                <select class="form-control" [(ngModel)]="_typeToxicEffectIdCandidate" (change)="onAddExperimentalToxicEffect(er, $event.target.value)">
                                    <option disabled selected value>-- None --</option>
                                    <option *ngFor="let item of _typeToxicEffects" [value]="item.typeToxicEffectId">{{item.toxicEffectDescription}}</option>
                                </select>
                            </edit-prop>
                            <edit-prop isChild szs="0,6">
                                <span *ngFor="let ete of er.experimentalToxicEffects">
                                    <label class="inline" style="margin-right: 10px;">
                                        {{ete.typeToxicEffect.toxicEffectDescription}}
                                        <a (click)="onDeleteExperimentalToxicEffect(ete)">(Delete)</a>
                                    </label>
                                </span>
                            </edit-prop>
                        </div>

                        <edit-prop label="Results" prop="resultNote" [id]="ix" szs="1,11">
                            <textarea class="form-control" rows=3 type="text" [(ngModel)]="er.resultNote"></textarea>
                        </edit-prop>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<material-selector></material-selector>

<div class="row topic-title">
    <div *ngIf="!material">
        <div *ngIf="title" class="h1-sm bold inline">{{title}}</div>
    </div>
    <div *ngIf="material">
        <div *ngIf="title" class="h1-sm bold inline">{{title}}</div>
        <div class="default-principal-name" [style.font-size.px]="22 * material.principalNameSizeFactor()" [htmlGreek]="material.principalSynonym()?.synonymWordOrWebVersion() || ''"></div>
        <div class="inline vertical-line">
            <div>CAS No:</div>
            <div class="bold">{{material.realCASNumber || '-'}}</div>
        </div>
        <div class="inline vertical-line material-header-text" *ngIf="material.formattedRIFMId">
            <div>RIFM ID:</div>
            <a id="rifmId" *ngIf="hasRIFMTooltip()" class="material-tooltip"
                (mouseenter)="toggleTooltip()" (mouseleave)="toggleTooltip()">
                <div class="bold">{{ material.formattedRIFMId}} </div>
            </a>

            <dx-tooltip style="border: 1px solid #67b168" target="#rifmId" *ngIf="hasRIFMTooltip()" [(visible)]="showTooltip">
                <div *dxTemplate="let data = data of 'content'" [innerHTML]="formatRIFMTooltip()">
                </div>
            </dx-tooltip>

            <div *ngIf="!hasRIFMTooltip()" class="bold">{{material.formattedRIFMId}} </div>
        </div>
        <div class="inline vertical-line material-header-text" *ngIf="material.eCB">
            <div>ECB:</div>
            <div class="bold">{{material.eCB}}</div>
        </div>
        <div class="inline vertical-line material-header-text" *ngIf="material.fEMANumber">
            <div class="foo">FEMA&nbsp;No:</div>
            <div class="bold">{{material.fEMANumber}}</div>
        </div>
        <div class="inline vertical-line material-header-text" *ngIf="material.eCInventoryEINECSELINCSNumbers()">
            <div class="foo">EC No./EINECS/ELINCS:</div>
            <div class="bold">{{material.eCInventoryEINECSELINCSNumbers()}}</div>
        </div>
        <div class="inline vertical-line material-header-text" *ngIf="material.mETIENCS()">
            <div class="foo">METI/ENCS:</div>
            <div class="bold">{{material.mETIENCS()}}</div>
        </div>
        <div class="inline vertical-line material-header-text" *ngIf="material.eCL">
            <div class="foo">ECL:</div>
            <div class="bold">{{material.eCL}}</div>
        </div>
        <div class="inline vertical-line material-header-text" *ngIf="material.hasRegistration()">
            <div class="foo">Registration:</div>
            <div class="bold">{{ getRegistration() }}</div>
        </div>
        <div class="inline vertical-line material-header-text" *ngIf="material.alternateCASNumber && material.eINECSRegistration">
            <div class="foo">Alternative CAS No:</div>
            <div class="bold">{{material.alternateCASNumber}}</div>
        </div>
    </div>
</div>
<div *ngIf="material && material.getCaptiveText()" class="row topic-title">
    <div class="inline indent-left-sm captive-text">
        {{material.getCaptiveText()}}
    </div>
</div>

// exports a single instance of the class not the class itself
class HtmlGreekTranslator {
    _greekUnicodeMap: { [name: string]: string };
    // type is to allow possible filtering later - not yet used.
    _htmlTranslations:  { tag: string, encTag: string, type: string} [] = [];

    constructor() {
        this.buildMaps();
    }

    safeEncode(str: any) {
        if (typeof(str) != "string") return str;
        let r=this.greekToUnicode(str);
        // idea is to encode all of the 'good' html sequences into something that escaping wont touch
        this._htmlTranslations.forEach(t => r = this.replaceAll(r, t.tag, t.encTag));
        // then to escape the remaining string
        r = this.escapeHtml(r);
        // and then revert the encoded 'good' html sequences back to html.
        this._htmlTranslations.forEach(t => r = this.replaceAll(r, t.encTag, t.tag)); 
        return r;
    }
    
    greekToUnicode(greek: string) {
        var r = greek;
        var map = this._greekUnicodeMap;
        for (let key in map) {
            r = this.replaceAll(r, key, map[key]);
        }
        return r;            
    }

    escapeHtml(str: string) {
        return str
            .replace(/&/g, "&amp;")
            .replace(/</g, "&lt;")
            .replace(/>/g, "&gt;")
            .replace(/"/g, "&quot;")
            .replace(/'/g, "&#039;")
            .replace(/\//g, "&#x2F;")
    }
    
    replaceAll(str: string, search: string, repl: string) {
        if (!str) return '';
        return str.split(search).join(repl);
    }
    
    

    buildMaps() {
        // char sequences that are likely to be unique and will not be affected by html escaping. 
        var subst = {
            "</": '@[@~',
            '<': '![!',
            '>': '~@]@',
            '/>': '!]!'
        };

        // type is to allow possible filtering later - not yet used.
        var allowedHtmlTags: any[] = [
            { key: 'b', type: 'emphasis' },
            { key: 'u', type: 'emphasis' },
            { key: 'i', type: 'emphasis' },
            { key: 'sup', type: 'size' },
            { key: 'sub', type: 'size' },
            { key: 'font size="-2"', type: 'size', endKey: 'font'  },      
            { key: "font face='symbol'", type: 'mod', endKey: ''  },   
            { key: "br", type: 'solo', endKey: '' }               
        ];
        
        allowedHtmlTags.forEach(t => {
            var cfg: { tag: string, encTag: string, type: string}
            if (t.type == 'solo') {
                cfg = {
                    tag: "<" + t.key + "/>", 
                    encTag: subst['<'] + this.replaceAll(this.replaceAll(t.key, '"','%'), "'",'^') + subst['/>'],
                    type: t.type
                }
            } else {
                cfg = {    
                    tag: "<" + t.key + ">", 
                    encTag: subst['<'] + this.replaceAll(this.replaceAll(t.key, '"','%'), "'",'^') + subst['>'],
                    type: t.type
                };
            }
            this._htmlTranslations.push( cfg);
            if (t.endKey !== '') {
                this._htmlTranslations.push( {
                    tag:  "</" + (t.endKey || t.key) + ">",
                    encTag: subst['</'] + (t.endKey || t.key) + subst['>'],
                    type: t.type    
                });
            }
        });
        
        this._greekUnicodeMap = {
            "&alpha;": "\u03B1",
            "&beta;": "\u03B2",
            "&gamma;": "\u03B3",
            "&delta;": "\u03B4",
            "&epsilon;": "\u03B5",
            "&zeta;": "\u03B6",
            "&eta;": "\u03B7",
            "&theta;": "\u03B8",
            "&iota;": "\u03B9",
            "&kappa;": "\u03BA",
            "&lambda;": "\u03BB",
            "&mu;": "\u03BC",
            "&nu;": "\u03BD",
            "&xi;": "\u03BE",
            "&omicron;": "\u03BF",
            "&pi;": "\u03C0",
            "&rho;": "\u03C1",
            "&sigmaf;": "\u03C2",
            "&sigma;": "\u03C3",
            "&tau;": "\u03C4",
            "&upsilon;": "\u03C5",
            "&phi;": "\u03C6",
            "&chi;": "\u03C7",
            "&psi;": "\u03C8",
            "&omega;": "\u03C9"
        };
    }
}

var htmlGreekTranslator = new HtmlGreekTranslator();

export default htmlGreekTranslator;
  
//   
<style>
    td.truncate {
        max-width: 200px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow:ellipsis;
    }
    td.comment {
        max-width: 500px;
        white-space: pre-wrap;
    }
</style>
<div class="indent-left-md">

    <div class="home-page-title">Fragrance Safety Assessments</div>

    <div *ngIf="!isStaff()" class="row">
        <i *ngIf="_isLoading" class="fa fa-circle-o-notch fa-spin" style="font-size:38px; color: #337ab7; margin-left: 24px;"></i>
    </div>

    <p class="alert-warning bold">{{_validationMessage}}</p>

    <div>
        <page-status [pageState]="_pageState" *ngIf="isStaff()"></page-status>
        <div class="bold alert alert-warning" *ngIf="isStaff() && _countSafetyAssessments.drafts > 0">
            There are {{ _countSafetyAssessments.drafts }} Fragrance Safety Assessment Drafts in progress.
        </div>
    </div>

    <div class="float-container" style="margin: 0px 0px 18px 0px; max-width: 95%;">
        <div class="float-left" style="width: 40%;"></div>
        <div class="float-right" style="width: 55%; margin-right: 18px;">
            <div class="flex-container" style="background-color: transparent; max-width: 98%; width: auto !important;">
                <div class="flex-item" style="margin: 0px;">
                    <span class="bold" style="color: #EE7600">**</span>
                </div>
                <div class="flex-item" style="margin: 0px;" >
                    <span class="overflow-wrap: break-word;">Please note that the citation dates used for studies sourced from the European Chemicals Agency (ECHA) website are the dates the dossiers were first posted, not the dates that the studies were conducted.</span>
                </div>
            </div>
        </div>
    </div>
    <p class="bold" style="text-align: right; width: 100%; margin-right: 10px;"></p>
    <div class="row" *ngIf="_materials && _pageState.itemCount != 0">
        <!--<div *ngIf="_pageState.hasMoreThanOnePage" class="col-md-12">
            <pagination-controls class="no-padding" (pageChange)="fetchPage($event)"></pagination-controls>
        </div>-->

        <default-table class="col-md-12">
            <thead>
                <tr style="white-space: nowrap">
                    <td>Safety Assessment</td>
                    <td><a [colSorter]="_colSorter">CAS No. </a></td>
                    <td><a [colSorter]="_colSorter">Synonym </a></td>
                    <td *ngIf="isStaff()"><a [colSorter]="_colSorter">Publish Date </a></td>
                    <td># Materials</td>
                    <td style="width: 8%">Additional Materials</td>
                    <td>Categories</td>
                    <td style="width: 24%">Comments</td>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let e of _materials | paginate : _pagination">
                    <td (click)="selectSafetyAssessment(e)" class="truncate"><a title="{{e.assessmentReportName}}">{{e.assessmentReportName}}</a></td>
                    <td (click)="selectCAS(e)"><a>{{e.realCASNumber || '(No CAS#)'}}</a></td>
                    <td [htmlGreek]="e.principalSynonymText()"></td>
                    <!--<td *ngIf="isStaff()">{{e.assessmentReportDate | date }}</td>-->
                    <td *ngIf="isStaff()">{{ _stateMap.utilFns.formatDate(e.assessmentReportDate) }}</td>
                    <td align="center">{{1 + e.safetyAssessmentMaterials.length}}</td>
                    <td>
                        <span *ngFor="let am of e.addlMaterials; let ix=index">
                            <span *ngIf="ix != 0">, </span>
                            <a (click)="selectCAS(am)">{{am.realCASNumber}}</a>
                        </span>
                    </td>
                    <td>{{getAssessmentCategories(e)}}</td>
                    <td class="comment">{{e.sACategoryComment}}</td>
                </tr>
            </tbody>
        </default-table>

        <!--<div *ngIf="_pageState.hasMoreThanOnePage" class="col-md-12">
            <pagination-controls class="no-padding" (pageChange)="fetchPage($event)"></pagination-controls>
        </div>-->
    </div>

</div>

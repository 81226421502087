import { EntityBase } from './EntityBase';
import { Material } from './Material';
import { ReportingOrganization } from './ReportingOrganization';
import { Survey } from './Survey';
import { SurveyAndCompany } from './SurveyAndCompany';


import { Validator } from 'breeze-client';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

export class SurveyReportedUse extends EntityBase {

   /// <code> Place custom code between <code> tags

   /// </code>

   // Generated code. Do not place code below this line.
   surveyReportedUseId: number;
   surveyAndCompanyId: number;
   materialId: number;
   reportedCASNumber: string;
   submitter: string;
   kilogramsConsumed: number;
   unmapped: boolean;
   unmappedReason: string;
   mappingNotes: string;
   notes: string;
   surveyId: number;
   reportingOrganizationId: number;
   created: Date;
   createdUser: string;
   modified: Date;
   modifyUser: string;
   rowVersion: number;
   material: Material;
   reportingOrganization: ReportingOrganization;
   survey: Survey;
   surveyAndCompany: SurveyAndCompany;
}


import { Component, OnInit, Input } from '@angular/core';
import { Location} from '@angular/common';

import { UnitOfWork, provideParent, StateMap } from '../../services/common';
import { PageState, EditPropParent } from '../../controls/common';
import { RIFMProduct, TypeLinkTarget } from  '../../entities/EntityModels';

@Component({
    selector: 'tools-and-model',
    templateUrl: './tools-and-model.html',
    providers: [provideParent(ToolsAndModelComponent, EditPropParent)]
})
export class ToolsAndModelComponent implements OnInit {

    @Input() entity: RIFMProduct;
    _typeLinkTargets: TypeLinkTarget[];
    _pageState = new PageState('Tools and Models Editor');


    constructor(public _uow: UnitOfWork, public _stateMap: StateMap, public _location: Location) {

    }

    ngOnInit() {
        this._uow.typeLinkTargetRepository.all().then( r => {
            this._typeLinkTargets = r;
        });

    }

    getError(propName: string) {
        return this.entity.getErrorFor(propName);
    }
}

import { Component, OnInit, Input, Inject } from '@angular/core';
import { Location } from '@angular/common';

import { LocationFns } from '../../utils/common';
import { UnitOfWork, StateMap  } from '../../services/common';

import { Material, Prediction, TypePrediction, TypePredictionClass } from '../../entities/EntityModels';

@Component({
    selector: 'prediction-hh-cramer',
    templateUrl: './prediction-hh-cramer.html',
})
export class PredictionHHCramerComponent implements OnInit {
    @Input() materialId: number;
    material: Material;

    _predictions: Prediction[];
    _cramerClassification: string;
    _cramerComment: string;
    _isEditEnabled = false;

    constructor(public _uow: UnitOfWork, public _stateMap: StateMap,
        public _location: Location) {

    }

    isStaff() {
        return ((this._stateMap.currentUser.isStaff) && (this._stateMap.currentUser.isInRole('fema') == false));
    }

    ngOnInit() {
        this.material = this._stateMap.currentMaterial;
        LocationFns.setTab(this._location, '0.1');
        const params = { materialId: this.materialId };
        this._uow.predictionRepository.whereWithParams(params).then(r => {
            this._predictions = r.filter(p => {
                if (this.isStaff()) { return true; }
                if (!p.enabled) { return false; }
                const predictionName = p.typePrediction.predictionName;
                return (!predictionName.startsWith('Tox')) && (!predictionName.startsWith('QSAR'));
            });
            const primaryJudgements = this._predictions.filter(p => p.typePrediction.primaryJudgment && p.enabled);
            if (primaryJudgements.length > 0) {
                const primaryJudgement = primaryJudgements[0];
                if (primaryJudgement.predictionClass) { // nullable field
                    this._cramerClassification = `Class ${primaryJudgement.predictionClass}, ${primaryJudgement.typePredictionClass.predictionClassDescription}`;
                }
                this._cramerComment = primaryJudgement.comment;
            }
        });
    }
}

import { HOME_ROUTES } from './home/home.routes';
import { HomeComponent } from './home/home.component';

import { MATERIAL_ROUTES } from './material/material.routes';
import { MaterialComponent } from './material/material.component';

import { REFERENCE_ROUTES } from './reference/reference.routes';
import { ReferenceComponent } from './reference/reference.component';

import { STAFF_ROUTES } from './staff/staff.routes';
import { StaffEditingComponent } from './staff/staff-editing.component';

import { EXPOSURE_ROUTES } from './exposure/exposure.routes';
import { ExposureComponent } from './exposure/exposure.component';

import { DOCUMENTMANAGEMENT_ROUTES } from './docmanagement/document-management.routes';
import { DocumentManagementComponent } from './docmanagement/document-management.component';

export const ROUTES = {
    Home: { name: 'Home', path: 'home', component: HomeComponent, childRoutes: HOME_ROUTES },
    Material: { name: 'Material', path: 'material/:id', component: MaterialComponent, childRoutes: MATERIAL_ROUTES },
    Reference: { name: 'Reference', path: 'reference/:id', component: ReferenceComponent, childRoutes: REFERENCE_ROUTES },
    Staff: { name: 'Staff', path: 'staff', component: StaffEditingComponent, childRoutes: STAFF_ROUTES },
    Exposure: { name: 'Exposure', path: 'exposure', component: ExposureComponent, childRoutes: EXPOSURE_ROUTES },
    DocumentManagement: {name: 'Document Management', path: 'docmanagement', component: DocumentManagementComponent, childRoutes: DOCUMENTMANAGEMENT_ROUTES }
};

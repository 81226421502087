import { Component, Input, OnInit } from '@angular/core';
import { HouseStatus } from '../../entities/HouseStatus';

@Component({
    selector: 'house-status',
    templateUrl: './house-status.html'
})
export class HouseStatusComponent implements OnInit {
    @Input() entity: HouseStatus;

    constructor() {
    }

    ngOnInit() {
    }

    hasChanges() {
        return (!this.entity.entityAspect.entityState.isUnchanged());
    }

    apply() {
    }
}

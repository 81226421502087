import { EntityBase } from './EntityBase';
import { Material } from './Material';
import { Survey } from './Survey';

import { Validator } from 'breeze-client';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

export class MaterialConsumption extends EntityBase {

   /// <code> Place custom code between <code> tags
   get geographicalArea() {
       var type = this.survey && this.survey.typeGeographicalArea;
       return type ? type.geographicalArea : '-';
   }
   
   get surveyYear() {
       return this.survey ? this.survey.surveyYear : '-';
   }
   /// </code>

   // Generated code. Do not place code below this line.
   materialConsumptionId: number;
   materialId: number;
   surveyId: number;
   kilogramsConsumed: number;
   subType: string;
   pecPnecRatio: number;
   created: Date;
   createdUser: string;
   modified: Date;
   modifyUser: string;
   rowVersion: number;
   material: Material;
   survey: Survey;
}


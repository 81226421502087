<div id="ShowAndDownloadPDF" class="modal fade bs-example-modal-lg" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header custom-header">
                <span class="modal-title" id="myModalLabel">Reference Document</span>
                <span class="no-print">
                    <button type="button" class="btn btn-default pull-right text-uppercase text-bold" data-dismiss="modal"><span class="fa fa-times"></span> Close</button>
                    <button (click)="onExportFile()" type="button" class="btn btn-default pull-right text-uppercase text-bold">Download File</button>
                </span>
            </div>
            <div class="modal-body custom-body">
                <div class="row">
                    <i *ngIf="_isLoading" class="fa fa-circle-o-notch fa-spin" style="font-size:38px; color: #337ab7;"></i>
                    <span class="inline">{{_validationMessage}}</span>
                </div>
                <div class="row" style="margin-top: 8px;">
                    <pdf-editor [serviceUrl]="_stateMap.documentManagementUrl" (stateChange)="pdfEditorEventHandler($event)" [ngStyle]="{'display': _pdfEditorDisplay}"></pdf-editor>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer border-zero no-print">
    </div>
</div>

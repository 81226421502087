import { Location } from '@angular/common';
import { Component, ViewChild, OnInit,  } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UnitOfWork, StateMap, UserManager, ErrorLogger } from '../../services/common';
import { UtilFns, IEditHost, EditManager } from '../../utils/common';
import {HttpClient, HttpHeaders, HTTP_INTERCEPTORS} from '@angular/common/http';
import {Observable} from 'rxjs';
import { environment } from '../../../environments/environment';
import * as _ from 'lodash';

import { MissingReferenceDocument, RIFMDocument, RIFMDocumentFolder, RIFMDocumentArchive, TypeRIFMDocument } from '../../entities/EntityModels';
import { DOCUMENTMANAGEMENT_ROUTES } from './document-management.routes';

export class ReferenceDocumentReport {
    id: number;
    name: string;
    description: string;
}

@Component({
    selector: 'document-management-reports',
    templateUrl: '../docmanagement/document-management-reports.html',
})

export class DocumentManagementReportsComponent implements OnInit {
    public _missingReferenceDocuments: MissingReferenceDocument[] = [];
    public _referenceDocumentReports: ReferenceDocumentReport[] = [];
    public _selectedReportIndex = 0;
    public _isLoading  = false;

    public _documentManagementReportUrl: string;

    public _validationMessage: string;


    constructor(private _httpClient: HttpClient, private _uow: UnitOfWork, private _stateMap: StateMap, private _userManager: UserManager, private _router: Router) {
    }

    ngOnInit() {

        this._stateMap.currentRouteName = DOCUMENTMANAGEMENT_ROUTES.DocumentManagementReports.name;

        this._referenceDocumentReports.push({id: 0, name: 'None', description: ''});
        this._referenceDocumentReports.push({id: 1, name: 'Missing Reference Documents', description: 'References that do not have a file assigned yet.'});

        this._documentManagementReportUrl = this._stateMap.documentManagementBaseUrl + '/Report';
    }

    // **************************************************************************************
    // load data
    // **************************************************************************************
    fetchMissingDocuments() {

        this._isLoading = true;

        this._missingReferenceDocuments = [];

        this._uow.fetch('DocumentManagement/MissingReferenceDocuments', { }).then(r => {
            if (r != null && r.length > 0) {
                this._missingReferenceDocuments = r;
            }
            this._isLoading = false;
        });
    }

    clearData() {
        this._missingReferenceDocuments = [];
    }

    // ************************************************************************
    //
    // ************************************************************************
    canDeactivate() {
        return !this._uow.hasChanges();
    }

    isStaff() {
        return this._stateMap.currentUser.isStaff;
    }

    get utilFns() {
        return UtilFns;
    }

    // ************************************************************************
    // interactions
    // ************************************************************************
    onSelectReport(idx: string) {
        switch (idx) {
            case '0': {
                this.clearData();
                break;
            }
            case '1': {
                this.fetchMissingDocuments();
                break;
            }
            default: {
                this.clearData();
                this._selectedReportIndex = 0;
                break;
            }
        }
    }

    get selectedReportDescription(): string {
        return this._referenceDocumentReports[this._selectedReportIndex].description;
    }

    rowColor(idx: number) {
        return ((idx % 2) > 0) ? '#F0F8FF' : '#FFFFFF';
    }

    // ************************************************************************
    // web api calls
    // ************************************************************************
    getMissingDocumentReportURL(): Observable<any> {
        const url = this._documentManagementReportUrl + '/GetMissingDocumentReportFile';
        const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');
        return this._httpClient.get<any>(url, { headers, responseType: 'text' as 'json'});
    }

    public exportMissingDocumentCSVFile() {
        this._isLoading = true;
        this._validationMessage = '';
        this.getMissingDocumentReportURL()
            .subscribe(
                (response: any) => {
                    const data = response;

                    const a = document.createElement('a');
                    a.href = 'data:attachment/csv;charset=utf-8,' + encodeURI(data);
                    a.target = '_blank';
                    a.download = 'MissingDocumentReport.csv';
                    document.body.appendChild(a);
                    a.click();

                    this._validationMessage = 'File download successful.';

                },
                (error) => {
                    console.error('Request failed with error: ' + error.message);
                    this._validationMessage = error.message;
                    this._isLoading = false;
                },
                () => {
                    this._isLoading = false;
                });
    }

    // ************************************************************************
    // document management data
    // ************************************************************************
    public getDocumentManagementReportUrl(): Promise<any> {
        return this._uow.fetch('DocumentManagement/DocumentManagementURL', {}).then(dm => {
            return dm[0];
        });
    }
}

<div class="indent-left-md">

    <div class="home-page-title">System Requirements and Tips for Navigating the New Database</div>

    <div class="panel panel-default" style="margin-right: 30px">
        <div class="panel-body">
            <h3>System Requirements:</h3>
            <div> Subscribers should be using the latest versions of Internet browsers released in 2016. Recommended browsers are
                Google Chrome, Internet Explorer 11, Microsoft Edge, and Mozilla Firefox for optimal performance. Please
                work with your IT representative to update your browser or you can easily download any of these browsers
                by doing an Internet search. Internet Explorer 10 is being supported for those users who have difficulty
                switching to other recommended browsers.</div>
        </div>
    </div>

    <div class="panel panel-default" style="margin-right: 30px">
        <div class="panel-body">
            <h3>Tips:</h3>
            <div>
                <b>Watch the Webinar</b> in the "Introduction to the New Database and Demo" link on the Home Page, this will
                help you find the basic reference and material information.
            </div>
            <br>
            <div>
                <b>To report an issue</b>, please send an email to help@rifm.org. Anytime you encounter errors, you should
                see the dialog below: <br /><br />
                <img style="margin-left: 45px" src="assets/images/system-requirements/RIFMWebErrorDialog.png" />
                <br /><br /> Please take a screen shot/capture of this dialog box, including the error message and email
                it to help@rifm.org. It would be further helpful if you can enlist the actions you took prior to the error
                being generated. To resume working, you can click on either of the two buttons. Clicking on the button Reload
                the current page, will allow you to stay on the page, and resume working. Clicking on the button Return to
                the Home screen, will lead you to the Home page of RIFM Database.
            </div>
            <br>
            <div>
                <b>To print,</b> click on the Print button provided in the header. Do not use the Print functionality built
                into browsers.
            </div>
            <br>
            <div>
                <b>Print any report in PDF format</b> directly from the browser. Listed below are steps to print them in
                each of the supported browsers.
            </div>
            <br>
            <div style="margin-left: 30px">
                <b>On Google Chrome: </b><br /> (1) Click the Print button seen on Reports or in the Header to see a dialog
                pop-up that allows printing.<br /><br /> (2) Set the Destination printer to Microsoft Print as PDF and then
                click on the Print button.<br /><br />
                <img style="margin-left: 45px" src="assets/images/system-requirements/ChromeSaveAsPDF.png" />
                <br /><br /> (3) A dialog called Save Print Output As should pop-up. On this dialog, specify a location and
                filename for the PDF document, and click on Save to generate a PDF document.<br /><br />
                <img style="margin-left: 45px" src="assets/images/system-requirements/ChromeSavePrintOutputAs.png" />
            </div>
            <br>
            <div style="margin-left: 30px">
                <b>On Microsoft Edge: </b><br /> (1) Click the Print button seen on Reports or in the Header to see a dialog
                pop-up that allows printing.<br /><br /> (2) Set the Printer to Microsoft Print to PDF and then click on
                the Print button. <br /><br />
                <img style="margin-left: 45px" src="assets/images/system-requirements/EdgeSaveAsPDF.png" />
                <br /><br /> (3) A dialog called Save Print Output As should pop-up. On this dialog, specify a location and
                filename for the PDF document, and click on Save to generate a PDF document.<br /><br />
                <img style="margin-left: 45px" src="assets/images/system-requirements/EdgeSavePrintOutputAs.png" />
            </div>
            <br>
            <div style="margin-left: 30px">
                <b>On Firefox: </b><br /> (1) Click the Print button seen on Reports or in the Header to see a dialog pop-up
                that allows printing.<br /><br /> (2) Set the Printer Name to Microsoft Print to PDF and then click OK. <br
                /><br />
                <img style="margin-left: 45px" src="assets/images/system-requirements/FirefoxSaveAsPDF.png" />
                <br /><br /> (3) A dialog called Save Print Output As should pop-up. On this dialog, specify a location and
                filename for the PDF document, and click on Save to generate a PDF document.<br /><br />
                <img style="margin-left: 45px" src="assets/images/system-requirements/FirefoxSavePrintOutputAs.png" />
            </div>
            <br>
            <div style="margin-left: 30px">
                <b>On Internet Explorer 11: </b><br /> (1) Click the Print button seen on Reports or in the Header to see
                a dialog pop-up that allows printing.<br /><br /> (2) In the Select printer section, check the checkbox for
                Microsoft Print to PDF and then click on the Print button. <br /><br />
                <img style="margin-left: 45px" src="assets/images/system-requirements/IE11SaveAsPDF.png" />
                <br /><br /> (3) A dialog called Save Print Output As should pop-up. On this dialog, specify a location and
                filename for the PDF document, and click on Save to generate a PDF document.<br /><br />
                <img style="margin-left: 45px" src="assets/images/system-requirements/IE11SavePrintOutputAs.png" />
            </div>
            <br>
            <div style="margin-left: 30px">
                <b>On Internet Explorer 10: </b><br /> (1) Click the Print button seen on Reports or in the Header to see
                a dialog pop-up that allows printing.<br /><br /> (2) In the Select printer section, select item Send to
                OneNote 2007 and then click on the Print button. <br /><br />
                <img style="margin-left: 45px" src="assets/images/system-requirements/IE10SaveAsOneNote.png" />
                <br /><br /> (3) This should launch OneNote. Click on this, and go to the File menu. Here onwards, at least
                two options are available to convert the document to PDF format. The first option involves installing a OneNote
                plug-in that allows publishing the document as a PDF. See below:<br /> <br />
                <img style="margin-left: 45px" src="assets/images/system-requirements/IE10PublishAsPDF.png" />
                <br /><br /> (4) The second option would be to save the document as a Microsoft Word document, and then use
                any available plug-in to convert from Microsoft Word to PDF. Saving the document using any of these options
                should generate a PDF. <br /><br />
                <img style="margin-left: 45px" src="assets/images/system-requirements/IE10SaveAsWordToPDF.png" />
                <br /><br />
            </div>
        </div>
    </div>


</div>

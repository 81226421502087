import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';

import { StateMap, UnitOfWork } from '../../services/common';
import { EditManager, IEditHost, LocationFns } from '../../utils/common';
import { NaturalOccurrenceComponent } from './natural-occurrence.component';
import { NaturalOccurrenceDescriptor } from '../../entities/EntityModels';

@Component({
    selector: 'natural-occurrences',
    templateUrl: './natural-occurrences.html',
})
export class NaturalOccurrencesComponent implements IEditHost<NaturalOccurrenceDescriptor>, OnInit {
    @Input() materialId: number;
    @ViewChild(NaturalOccurrenceComponent) _editingComponent: NaturalOccurrenceComponent;

    _edm: EditManager<NaturalOccurrenceDescriptor>;

    constructor(public _uow: UnitOfWork, public _stateMap: StateMap, public _location: Location) {

    }

    ngOnInit() {
        LocationFns.setTab(this._location, 1);
        this._edm = new EditManager<NaturalOccurrenceDescriptor>(this, this._uow, this._stateMap, 'Natural Occurrence Descriptors');

        this._edm.pageState.isLoading = true;
        const p = {materialId: this.materialId};
        this._uow.naturalOccurrenceDescriptorRepository
            .whereWithParams(p)
            .then(r => {
                this._edm.entities = r;
                this._edm.pageState.isLoaded = r;
            });

    }

    canDeactivate() {
        return !this._edm.hasEdits();
    }


    onAdd() {
        if (!this._edm.validateCurrent()) {
            return;
        }

        // TODO: add a unique validation for materialId, product pair.
        const config = {
            materialId: this.materialId,
            product: '-'
        };
        const entity = this._uow.naturalOccurrenceDescriptorFactory.create(config);
        entity.product = '';
        this._edm.entities.push(entity);
        this._edm.onSelect(entity);
    }


}

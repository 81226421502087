import { Component, OnInit, Input, Inject } from '@angular/core';
import { Location } from '@angular/common';

import { LocationFns } from '../../utils/common';
import { UnitOfWork, StateMap  } from '../../services/common';

import { Material  } from '../../entities/EntityModels';

@Component({
    selector: 'prediction-hh-ttc',
    templateUrl: './prediction-hh-ttc.html'
})
export class PredictionHHTTCComponent implements OnInit {
    @Input() materialId: number;
    material: Material;

    
    constructor(public _uow: UnitOfWork, public _stateMap: StateMap,
        public _location: Location) {

    }

    ngOnInit() {
        this.material = this._stateMap.currentMaterial;
        LocationFns.setTab(this._location, '0.2');
    }



}

<div class="modal" id="confirm-finish-override-modal" role="dialog">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header" style="background-color:#337ab7 !important;">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                <h4 class="modal-title" style="color: #FFFFFF !important;">{{_title}}</h4>
            </div>
            <div class="modal-body" >
                <h5 *ngIf="_reasons && _reasons.length > 0" class="bold">Reasons why the Reference is not finished:</h5>
                <div class="row confirm-finish-container">
                    <ul *ngIf="_reasons && _reasons.length > 0">
                        <li *ngFor="let r of _reasons;">
                            <span class="small">{{r}}</span>
                        </li>
                    </ul>
                </div>
                <h5 class="bold">{{_message}}</h5>
            </div>
            <div *ngIf="_buttonNames" class="modal-footer">
                <button (click)="select(true)" type="button" class="btn btn-default">{{_buttonNames[0]}}</button>
                <button *ngIf="_buttonNames.length > 1" (click)="select(false)" type="button" class="btn btn-primary">{{_buttonNames[1]}}</button>
            </div>
        </div>
    </div>
</div>
<div class="modal" id="license-selector-dialog" role="dialog" xmlns="http://www.w3.org/1999/html">
    <div class="modal-dialog  modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                <h3>Annual Renewal Agreement for the RIFM Database</h3>
                <div class="nav navbar-left navbar-form">
                    <button class="btn btn-primary navbar-btn" type="button" (click)="onAccept(true)">Accept</button>
                    <button class="btn btn-primary navbar-btn" type="button" (click)="onCancel(false)">Cancel</button>
                </div>
            </div>
            <div class="modal-body">
                <h4 class="user-acknowledgement-title">
                    PLEASE SCROLL DOWN AND READ THE SUBSCRIPTION AGREEMENT. ACCESS TO THE RIFM DATABASE WILL BE GRANTED ONLY UPON AGREEMENT BY THE SUBSCRIBER TO THE TERMS AND CONDITIONS OF
                    THE ONLINE DATABASE SUBSCRIPTION AGREEMENT BELOW.
                </h4>
                <h4 class="user-acknowledgement-centered user-acknowledgement-title-blue">ONLINE DATABASE SUBSCRIPTION AGREEMENT</h4>
                <br/>
                <div class="user-acknowledgement-text">
                    The Research Institute for Fragrance Materials, Inc. (“RIFM”), a New York corporation, has compiled a database of toxicology data, literature and general information on fragrance
                    and flavor raw materials (the “RIFM Database”).  The RIFM Database is available online under the terms and conditions set forth in this Online Database Subscription Agreement (“Agreement”)
                    between RIFM and the undersigned subscriber (“Subscriber”).
                </div>
                <br/>
                <div class="user-acknowledgement-text">
                    <strong>1. Scope of License Grant. </strong>
                    RIFM hereby grants Subscriber, through its authorized users (“Authorized Users”), a non-exclusive, non-transferable license to access and search the RIFM Database,
                    download search results and selectively copy portions of RIFM Database in connection with such search results pursuant to the terms and conditions of this Agreement
                    (hereinafter “Subscription Services”).&nbsp;
                    Authorized Users with access to Subscription Services must be employees of Subscriber.&nbsp;
                    Subscriber agrees that (i) the number of Authorized Users shall not exceed the number set forth in Appendix A;
                    (ii) the RIFM Database shall be used solely for Subscriber’s internal purposes; (iii) Subscriber shall not allow online access to the RIFM Database to anyone other than Authorized Users of the Subscriber, whether by data link or Internet connection or by permitting
                    access through terminals or computers of Authorized Users, or by other similar means or arrangements; (iv) Subscriber shall use the RIFM Database solely for lawful purposes
                    and in accordance with this Agreement; (v) Subscriber shall not directly or indirectly download or allow others to download the RIFM Database in a systematic fashion; (vi)
                    Subscriber shall not recompile or incorporate all or any portion of the RIFM Database in any database, website or other medium, absent RIFM’s prior written approval; (viii)
                    Subscriber shall not republish, resell, modify, create derivative works of, systematically reproduce, permit bulk reproduction or distribution, store in a searchable,
                    machine-readable form, time-share, or provide fee-for-service use of the RIFM Database; and (ix) Subscriber shall not remove, obscure or modify any copyright, proprietary or
                    other notice included in the RIFM Database or use the database in any manner that would infringe the copyright or proprietary rights therein.

                    RIFM reserves the rights, and Subscriber has no rights, to reproduce and/or otherwise use the RIFM Database in any manner for purposes of training and/or populating
                    artificial intelligence technologies, unless Subscriber obtains RIFM’s specific and express written permission to do so. Nor does Subscriber have the right to sublicense or
                    allow others to reproduce and/or otherwise use the RIFM Database in any manner for purposes of training and/or populating artificial intelligence technologies without
                    Subscriber’s specific and express written permission.
                </div>
                <br />
                <div class="user-acknowledgement-text">
                    <strong>2. Term. </strong>
                    The Term of this Agreement shall be one (1) year.  This Agreement shall renew for successive one (1) year periods so long as Subscriber pays the annual subscription
                    fees for Subscription Services, subject to any new terms and/or conditions required by RIFM for which Subscriber has received notification prior to renewal.&nbsp;
                    Subscriber will be required to acknowledge and consent to this Agreement each year prior to renewal.
                </div>
                <br />
                <div class="user-acknowledgement-text">
                    <strong>3. Fees. </strong>
                    The annual fees for Subscription Services shall be the current fees established by RIFM from time to time, as set forth in subscription renewal forms sent to
                    Subscriber.&nbsp;
                    Subscriber warrants that all information provided by Subscriber is accurate and agrees to report to RIFM any changes that might  affect
                    Subscriber’s classification and associated subscription fees.&nbsp;
                    The annual subscription fee may be raised by written or electronic notice to Subscriber one (1) month prior to the renewal date.
                </div>
                <br />
                <div class="user-acknowledgement-text">
                    <strong>4. Support. </strong>
                    RIFM will provide Subscriber with email and/or telephone support in RIFM’s sole discretion during RIFM’s normal business hours (Monday – Friday,&nbsp;
                    9:00 a.m. – 5:00 p.m., Eastern Time).
                </div>
                <br />
                <div class="user-acknowledgement-text">
                    <strong>5. Copyright. </strong>
                    Subscriber acknowledges that the RIFM Database contains proprietary material owned by RIFM and/or contributors to the RIFM Database and is protected by
                    United States copyright laws and international treaties.&nbsp;
                    Subscriber acknowledges that it has no claim of ownership to the RIFM Database by reason of
                    Subscriber’s use of or access to the database.&nbsp;
                    Subscriber further acknowledges that RIFM owns all intellectual property rights to the RIFM Database,
                    including the compilation, selection, coordination and arrangement of the content in the RIFM Database, as well as the RIFM Database website.
                </div>
                <br />
                <div class="user-acknowledgement-text">
                    <strong>6. Suspension or Termination of Subscription Service. </strong>
                    RIFM may, in its sole discretion, terminate or suspend Subscriber’s ability to access all or any part of the Subscription Services or the RIFM Database,
                    with or without notice, for any reason or no reason, including breach of this Agreement.&nbsp;
                    Any use of the RIFM Database beyond the scope of this Agreement or any fraudulent, abusive or otherwise illegal activities may be grounds for termination.&nbsp;
                    In addition, activity that may burden RIFM’s server(s), such as computer programs that automatically download content, commonly known as robots, spiders,
                    crawlers, wanderers or accelerators, is grounds for termination.
                </div>
                <br />
                <div class="user-acknowledgement-text">
                    <strong>7. Disclaimer of Warranties. </strong>
                    THE RIFM DATABASE IS PROVIDED AS IS, WITHOUT ANY WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO WARRANTIES OF
                    (i) MERCHANTABILITY, (ii) FITNESS FOR A PARTICULAR PURPOSE, (iii) ACCURACY, (iv) DESIGN, (v) USAGE, (vi) QUALITY, (vii) PERFORMANCE, (viii) COMPATIBILITY,
                    OR (ix) TITLE.  RIFM DOES NOT WARRANT THAT THE SUBSCRIBER’S USE OF THE RIFM DATABASE WILL BE UNINTERRUPTED, ERROR FREE, OR THAT THE RESULTS OBTAINED WILL
                    BE ACCURATE OR USEFUL OR WILL SATISFY SUBSCRIBER’S REQUIREMENTS.&nbsp;
                    FURTHERMORE, SUBSCRIBER AGREES THAT ALL USE OF THE RIFM DATABASE IS AT SUBSCRIBER’S SOLE
                    RISK AND THAT RIFM WILL HAVE NO LIABILITY TO ANY PERSON FOR ANY LOSS OR DAMAGE ARISING OUT OF THE USE OF OR INABILITY TO USE THE RIFM DATABASE, OR THE
                    CONTENT OR LACK OF CONTENT OF THE RIFM DATABASE.
                </div>
                <br />
                <div  class="user-acknowledgement-text">
                    <strong>8. Limitation of Liability. </strong>
                    UNDER NO CIRCUMSTANCES SHALL RIFM, INCLUDING ITS OFFICERS, DIRECTORS AND EMPLOYEES, AS WELL AS RIFM’S THIRD-PARTY CONSULTANTS AND CONTRACTORS,
                    BE LIABLE TO SUBSCRIBER OR ANY AUTHORIZED USERS, FOR SPECIAL, INCIDENTAL, CONSEQUENTIAL, INDIRECT, PUNITIVE OR EXEMPLARY DAMAGES OF ANY KIND,
                    INCLUDING LOST PROFITS, LOST SAVINGS OR LOST REVENUES, WHETHER OR NOT CHARACTERIZED IN NEGLIGENCE, TORT, CONTRACT OR OTHER THEORY OF LIABILITY,
                    EVEN IF RIFM HAS BEEN ADVISED OF THE POSSIBLITY OF OR COULD HAVE FORESEEN SUCH DAMAGES AND IRRESPECTIVE OF ANY FAILURE OF ESSENTIAL PURPOSE OF A
                    LIMITED REMEDY.&nbsp;
                    RIFM’S MAXIMUM LIABILITY UNDER THIS AGREEMENT SHALL BE THE REFUND OF SUBSCRIPTION FEES PAID BY SUBSCRIBER FOR THE ONE (1) YEAR
                    PERIOD PRIOR TO THE EVENT GIVING RISE TO SUCH LIABILITY.&nbsp;
                    IF ANY APPLICABLE AUTHORITY HOLDS ANY PORTION OF THIS SECTION TO BE UNEFORCEABLE,
                    THEN RIFM’S LIABILITY WILL BE LIMITED TO THE FULLEST POSSSIBLE EXTENT PERMITTED BY APPLICABLE LAW.
                </div>
                <br />
                <div class="user-acknowledgement-text">
                    <strong>9. Indemnification. </strong>
                    Subscriber shall indemnify RIFM and hold it harmless from any loss, claim or damage, including attorneys’ fees and expenses,
                    arising out of Subscriber’s breach of this Agreement or its use or misuse of the RIFM Database.
                </div>
                <br />
                <div class="user-acknowledgement-text">
                    <strong>10. Reserved Rights. </strong>
                    RIFM reserves the right in its sole discretion (i) to monitor and review Subscriber’s use of the RIFM Database, and may use any
                    information relating to Subscriber’s access to, communication with and use of the RIFM Database; and (ii) to change, suspend or
                    discontinue any aspect of the RIFM Database at any time, including the availability of any feature or content, and to impose limits on
                    certain features and services or restrict Subscriber’s access to all or part of the RIFM Database without notice or liability.
                </div>
                <br />
                <div class="user-acknowledgement-text">
                    <strong>11. General. </strong>
                    This Agreement contains the final and entire agreement regarding Subscriber’s use of the Subscription Services and supersedes all previous and
                    contemporaneous oral or written agreements with respect to the subject matter contained herein.  This Agreement is personal to Subscriber, which
                    means that Subscriber may not assign its rights or obligations under this Agreement to anyone.  No third party is a beneficiary of this Agreement
                    for Subscriber.  Subscriber agrees that this Agreement, as well as any and all claims arising from this Agreement, will be governed by and construed
                    in accordance with the laws of the State of New York, without regard to any conflict or choice of law principles. The sole jurisdiction and venue for
                    any litigation arising out of this Agreement will be an appropriate federal or state court located in New York. This Agreement will not be governed
                    by the United Nations Convention on Contracts for the International Sale of Goods.  Except as provided herein, no waiver or modification of this Agreement
                    shall be effective unless it is in writing and signed by the parties hereto..&nbsp;
                    Should any provision of this Agreement be deemed void or unenforceable, the remaining provisions shall remain in full force and effect.
                </div>
                <br />
                <div class="user-acknowledgement-text">
                    Subscriber certifies that it has read, understood and agrees to abide by this Agreement and that the signatory below is authorized to sign this Agreement on behalf of Subscriber.
                </div>
                <div class="user-acknowledgement-text" style="margin-top: 20px;">
                    Revised January 2024
                </div>
            </div>
            <div class="modal-footer">
                <div class="nav navbar-left navbar-form">
                    <button class="btn btn-primary navbar-btn" type="button" (click)="onAccept(true)">Accept</button>
                </div>
            </div>
        </div>
    </div>
</div>

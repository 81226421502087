<div *ngIf="entity" class="form-horizontal">
    <div class="col-md-7">
        <div class="form-group">
            <edit-prop isChild label="Prediction Class Name" prop="comment" szs="2,5">
                <input class="form-control" type="text" [(ngModel)]="predictionClassKey">
            </edit-prop>
        </div>

        <div class="form-group">
            <edit-prop isChild label="Description" prop="enabled" szs="2,5">
                <input class="form-control" type="text" [(ngModel)]="entity.predictionClassDescription">
            </edit-prop>
        </div>
    </div>
</div>

<div id="ClusterHelpVideo" class="modal fade bs-example-modal-lg" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel">
    <div class="modal-dialog modal-extra-lg">
        <div class="modal-content clustervideocontainer">
            <div class="modal-header custom-header">
                <span class="modal-title" id="myModalLabel">Cluster Graph Tutorial Videos</span>
            </div>
            <div class="modal-body">
                <div style=width:100%;>
                    <button class="btn btn-primary navbar-btn" type="button" style="margin-left: 840px;" (click)="onClose()">Close</button>
                </div>
                <div style="overflow-y: auto; overflow-x: hidden; height: 676px; width: 100%; margin-top: -12px;">

                    <div>
                        <div class="row cluster-video-link-container">
                            <p class="bold cluster-video-label" style="margin-left: 18px;">(1) Cluster Path Selection Tutorial</p>
                        </div>
                        <video width="450" height="450" class="cluster-help-video" controls poster="assets/images/transparent-1x1.png">
                            <source src="assets/images/RIFMClusterGraphTutorial.mp4#t=0.1" type="video/mp4">
                            Your browser doesn't support HTML5 video tag.
                        </video>
                    </div>
                    <div style="margin-top: 32px;">
                        <div class="row cluster-video-link-container">
                            <p class="bold cluster-video-label" style="margin-left: 10px;">(2) Cluster Graph Navigation Tutorial</p>
                        </div>
                        <video width="450" height="450" class="cluster-help-video" controls poster="assets/images/transparent-1x1.png">
                            <source src="assets/images/RIFMClusterGraphNavigation.mp4#t=0.1" type="video/mp4">
                            Your browser doesn't support HTML5 video tag.
                        </video>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer border-zero no-print">
    </div>
</div>

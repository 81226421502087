import {Injectable, Component } from '@angular/core';
import { Location } from '@angular/common';

import { Journal} from '../entities/Journal';
import { Material} from '../entities/Material';
import { Reference} from '../entities/Reference';
import { YesNoComponent } from '../components/main/yes-no.component';
import { ExceptionDialogComponent } from '../components/main/exception-dialog.component';
import { UserManager} from './user-manager';
import { UtilFns } from '../utils/util-fns';


@Injectable()
export class StateMap {
    _currentJournal: Journal;
    _currentMaterial: Material;
    _currentReference: Reference;
    _currentRouteName: string;
    _journalDataMap: {} = {};
    _hasMaterialDataMap: {} = {};
    _hasReferenceDataMap: {} = {};

    _map: Object = {};

    _retMap: Object = {};
    errors: any[] = [];
    _nextRetId = 1;
    _firstRetId = 1;

    constructor(public _userManager: UserManager) {
    }

   get versionNo() {
        return (UserManager.versionNo);
    }

    get shortVersionNo() {
        return (UserManager.versionNo.split('.').slice(0, -1).join('.'));
    }

    get utilFns() {
        return UtilFns;
    }

    get currentJournal() {
        return this._currentJournal;
    }
    set currentJournal(value: Journal) {
        this._currentJournal = value;
        this._journalDataMap = {};
    }

    get currentMaterial() {
        return this._currentMaterial;
    }
    set currentMaterial(value: Material) {
        this._currentMaterial = value;
        this._hasMaterialDataMap = {};
    }

    public documentManagementArchiveUrl: string;
    public documentManagementUrl: string;
    public documentManagementBaseUrl: string;
    public documentManagementReferenceId: number;

    public staffJournalId: number;
    public staffMaterialId: number;
    public staffReferenceId: number;

    get currentReference() {
        return this._currentReference;
    }
    set currentReference(value: Reference) {
        this._currentReference = value;
        this._hasReferenceDataMap = {};
    }

    public hasJournalDataFor(key: string) {
        const r = this._journalDataMap[key];
        return (r != undefined);
    }

    public hasMaterialDataFor(key: string) {
        const r = this._hasMaterialDataMap[key];
        if (r == undefined) {
            return true;
        } else {
            return r;
        }
    }

    public hasReferenceDataFor(key: string) {
        const r = this._hasReferenceDataMap[key];
        if (r == undefined) {
            return true;
        } else {
            return r;
        }
    }

    public getJournalDataFor(key: string) {
        return this._journalDataMap[key];
    }

    public setJournalDataFor(key: string, value: any) {
        this._journalDataMap[key] = value;
    }

    public setHasMaterialDataFor(key: string, value: boolean) {
        this._hasMaterialDataMap[key] = value;
    }

    public setHasReferenceDataFor(key: string, value: boolean) {
        this._hasReferenceDataMap[key] = value;
    }

    get currentUser()  {
        return this._userManager && this._userManager.currentUser;
    }

    get(key: any) {
        const skey = this.keyToString(key);
        return this._map[skey];
    }

    set(key: Object, value: Object, altKey?: string) {
        const skey = this.keyToString(key);
        this._map[skey] = value;
        if (altKey) {
            this._map[this.keyToString(altKey)] = value;
        }
    }

    getLastRetId() {
        return this._nextRetId - 1;
    }

    keyToString(key: Object): string {
        let skey: string;
        if (typeof(key) == 'string') {
            skey = <string> key;
        } else if (typeof(key) == 'object') {
            skey = JSON.stringify(key);
        } else {
            // can't get here...
            // skey = key.toString();
        }
        return skey;
    }

    public storeRetState(obj: any, id?: number): number {
        if (id) {
            this._retMap[id] = obj;
            return id;
        } else {
            this.checkRetMapSize();
            this._retMap[this._nextRetId] = obj;
            this._nextRetId++;
            return this._nextRetId - 1;
        }
    }

    checkRetMapSize() {
        if ((this._nextRetId - this._firstRetId) > 20) {
            delete this._retMap[this._firstRetId];
            this._firstRetId++;
        }
    }

    public getRetState(key: number): {} {
        return this._retMap[key];
    }


    public get currentRouteName() {
        return this._currentRouteName;
    }
    public set currentRouteName(r) {
        setTimeout( () => this._currentRouteName = r, 0);
    }
    public yesNoComponent: YesNoComponent;
    public exceptionDialog: ExceptionDialogComponent;


}


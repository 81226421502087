import { EntityBase } from './EntityBase';
import { TypeChangeReportFrequency } from './TypeChangeReportFrequency';
import { UserChangeReportAuthor } from './UserChangeReportAuthor';
import { UserChangeReportMaterial } from './UserChangeReportMaterial';
import { UserChangeReportStudy } from './UserChangeReportStudy';
import { UserChangeReportTopic } from './UserChangeReportTopic';

import { Validator } from 'breeze-client';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

export class UserChangeReport extends EntityBase {

   /// <code> Place custom code between <code> tags
   
   /// </code>

   // Generated code. Do not place code below this line.
   userChangeReportId: number;
   userId: string;
   typeChangeReportFrequencyId: string;
   allFlavors: boolean;
   allFragrances: boolean;
   allNaturals: boolean;
   referenceAuthors: boolean;
   staffReport: boolean;
   lastReportDate: Date;
   enabled: boolean;
   created: Date;
   createdUser: string;
   modified: Date;
   modifyUser: string;
   rowVersion: number;
   typeChangeReportFrequency: TypeChangeReportFrequency;
   userChangeReportAuthors: UserChangeReportAuthor[];
   userChangeReportMaterials: UserChangeReportMaterial[];
   userChangeReportStudies: UserChangeReportStudy[];
   userChangeReportTopics: UserChangeReportTopic[];
}


<div style="margin-bottom: 10px">
    <div *ngIf="_reference" class="inline" style="font-size: 18px;">Editing Protocols</div>
    <span *ngIf="canSelectReference()">
        <button type="button" class="inline btn btn-sm btn-primary" (click)="onSelectReference()">{{ "Select " + (_reference ? "Another " : "a ") + "Reference" }}</button>
    </span>
</div>

<p class="alert-message">{{_errorMessage}}</p>

<div *ngIf="_reference">
    <editor-header [customButtons]="true" [edm]="_edm">
        <span customButtons>
            <button *ngIf="canShowSave()" type="button" class="btn btn-sm btn-primary" [disabled]="!canSave()" (click)="onSave()">Save</button>
            <button *ngIf="canShowApply()" type="button" class="btn btn-sm btn-primary" [disabled]="!canApply()" (click)="onApply()">Apply</button>
            <button *ngIf="canShowBack()" type="button" class="btn btn-sm btn-primary" (click)="onBack()">Back</button>
            <button *ngIf="canShowCancel()" type="button" class="btn btn-sm btn-primary" [disabled]="!canShowCancel()" (click)="onCancel()">Cancel</button>
            <span *ngIf="referenceFinishedStatesHaveChanged()" class="workflow-automatic-finish-label">{{_finishedMessage}}</span>
        </span>
    </editor-header>
    <div [attr.data-hidden]="_edm.editing || _edmExpMat.editing || _copyingExperimentalMaterial">
        <div class="col-md-5">
            <div class="form-group">
                <span style="font-size: 20px;">Protocols</span>
                <button type="button" class="btn btn-sm btn-primary" (click)="onAddBioData()">Add</button>
                <span><b><i>Select a row to display its Experimental Materials</i></b></span>
            </div>
            <default-table *ngIf="_edm.hasEntities()">
                <thead>
                    <tr>
                        <td>Sub-reference</td>
                        <td>Study</td>
                        <td>Route</td>
                        <td>Species</td>
                        <td></td>
                        <td></td>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let bd of _edm.entities" [class.selected]="bd == _currentBd" (focus)="onSelect(bd)" (dblclick)="onEditBiologicalData(bd)" (click)="onSelect(bd)" tabIndex="0" (keydown)="onKeyDown($event)">
                        <td>{{bd.subReference}}</td>
                        <td>{{bd.typeStudy?.studyLong}}</td>
                        <td><span [htmlGreek]="bd.typeRoute?.routeLong"></span></td>
                        <td><span [htmlGreek]="bd.typeSpecies?.speciesLong"></span></td>
                        <td><a (click)="onEditBiologicalData(bd)">Edit</a></td>
                        <td><a (click)="onDeleteBioData(bd)">Delete</a></td>
                    </tr>
                </tbody>

            </default-table>
        </div>
        <div class="col-md-7" *ngIf="_currentBd">
            <div class="form-group">
                <span style="font-size: 20px;">Experimental Materials</span>
                <button type="button" class="btn btn-sm btn-primary" (click)="onAddExpMat()">Add</button>
            </div>
            <default-table>
                <thead>
                    <tr>
                        <td>Material</td>
                        <td>CAS No</td>
                        <td>RIFM ID</td>
                        <td>FEMA No.</td>
                        <td class="text-center">Use as Template</td>
                        <td></td>
                        <td></td>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let em of _currentBd.experimentalMaterials" (click)="_edmExpMat.onSelect(em)">
                        <td><span [htmlGreek]="em?.material?.principalSynonym()?.synonymWordOrWebVersion()"></span></td>
                        <td>{{em?.material?.realCASNumber}}</td>
                        <td>{{em?.material?.formattedRIFMId}}</td>
                        <td>{{em?.material?.fEMANumber}}</td>
                        <td class="text-center">
                            <a *ngIf="!hasChanges()" (click)="onCopyExpMat(em); $event.stopPropagation()">Copy</a>
                        </td>
                        <td><a (click)="_edmExpMat.onSelect(em)">Edit</a></td>
                        <td><a (click)="onDeleteExpMat(em)">Delete</a></td>
                    </tr>
                </tbody>

            </default-table>
        </div>
    </div>
    <div *ngIf="_edm.editing">
        <tab-container #tabContainerProtocol>
            <ng-template tab-pane title="Protocol">
                <protocol-editor-biodata #self [entity]="_edm.currentEntity"></protocol-editor-biodata>
            </ng-template>
            <ng-template tab-pane title="Sub Studies">
                <protocol-editor-substudies #self [entity]="_edm.currentEntity"></protocol-editor-substudies>
            </ng-template>
        </tab-container>
    </div>
    <div *ngIf="_edmExpMat.editing">
        <exp-material-editor #self [expMaterial]="_edmExpMat.currentEntity"></exp-material-editor>
    </div>
</div>

<div *ngIf="_copyingExperimentalMaterial">
    <exp-material-from-template (stateChange) ="copyExperimentalMaterialEventHandler($event)" [expMaterial]="_templateExperimentalMaterial" [referenceId]="_reference.referenceId"></exp-material-from-template>
</div>

<reference-selector></reference-selector>


import { Component, forwardRef, Inject, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import * as _ from 'lodash';

import { provideParent, ReferenceState, StateMap, UnitOfWork, WorkflowEmailService } from '../../services/common';
import { LocationFns, UtilFns } from '../../utils/common';
import { EditPropParent } from '../../controls/common';


import { Author, Reference, TypeKlimisch, TypePublication, TypeSearchService, TypeWorkflowContact } from '../../entities/EntityModels';
import { IBriefReferenceParts } from '../../entities/Reference';
import { ReferenceListItem } from '../../entities/projections/ReferenceListItem';

import { ReferenceSelectorComponent } from '../reference/reference-selector.component';
import { JournalSelectorComponent } from '../staff/journal-selector.component';
import { ReferenceResultsComponent } from '../reference/reference-results.component';
import { ConfirmFinishOverrideComponent } from './confirm-finish-override.component';
import { WorkflowHistoryComponent } from './workflow-history.component';
import { ReferenceEditorComponent } from './reference-editor.component';
import {ROUTES} from '../routes';

interface AuthorSelector {
    author: Author;
    selected: boolean;
}

export class NotifyContact {
    public _contactId: number;
    public _contactName: string;
    public _selected: boolean;

    constructor(contactId?: number, contactName?: string, selected?: boolean) {
        this._contactId = contactId || null;
        this._contactName = contactName || null;
        this._selected = selected || false;
    }
}

@Component({
    selector: 'reference-editor-base',
    templateUrl: './reference-editor-base.html',
    providers: [provideParent(ReferenceEditorBaseComponent, EditPropParent)]
})
export class ReferenceEditorBaseComponent implements OnInit, EditPropParent {
    @ViewChild(ReferenceSelectorComponent, { static: true }) _referenceSelectorComponent: ReferenceSelectorComponent;
    @ViewChild(ReferenceResultsComponent) _referenceResultsComponent: ReferenceResultsComponent;
    @ViewChild(JournalSelectorComponent, { static: true }) _journalSelectorComponent: JournalSelectorComponent;
    @ViewChild(ConfirmFinishOverrideComponent, { static: true }) _confirmFinishOverrideComponent: ConfirmFinishOverrideComponent;
    @ViewChild(WorkflowHistoryComponent) _workflowHistoryComponent: WorkflowHistoryComponent;

    _data: {
        entity: Reference,
        adding: boolean,
        // maxReferenceId: number,
        inSubEditor: boolean,
        onCancelMap: { [key: string]: () => void };
        otherErrorMap: { [key: string]: string }

        origRefId?: number,
        // testRefId?: number,
        isEditingRef?: boolean
        briefRefKey?: string;
        briefRefMatches?: ReferenceListItem[];
        showBriefRefMatches?: boolean;
    };

    private _allContacts: TypeWorkflowContact[] = [];
    private _sendToContacts: TypeWorkflowContact[];
    private _selectedContactId = 0;
    private _canSaveHold = false;
    private _isOnHold = false;
    private _holdReason: string;
    private _showHoldReasonInput = false;
    public _notifyContacts: NotifyContact[] = [];
    public _loadingAuthors: boolean;

    _typePublications: TypePublication[];
    _typeKlimischs: TypeKlimisch[];
    _typeSearchServices: TypeSearchService[];

    _matchingAuthors: AuthorSelector[];
    _showAuthorMatches: boolean;

    constructor(@Inject(forwardRef(() => ReferenceEditorComponent)) _parent: ReferenceEditorComponent,
                public _uow: UnitOfWork, public _stateMap: StateMap, public _location: Location,
                private _referenceState: ReferenceState, private _workflowEmailService: WorkflowEmailService) {
        this._data = _parent.data;
        this._data.onCancelMap['base'] = this.onCancel.bind(this);

    }

    ngOnInit() {
        LocationFns.setTab(this._location, 0);

        this._uow.typePublicationRepository.all().then(tps => {
            this._typePublications = tps;
        });
        this._uow.typeKlimischRepository.all().then(tks => {
            this._typeKlimischs = tks;
        });

        this._uow.typeSearchServiceRepository.all().then(ss => {
            this._typeSearchServices = ss;
        });

        this._uow.typeWorkflowContactRepository.all().then(c => {
            this._allContacts = c;

            const sendTos = c.filter(w => w.workflowStaff == true);
            this._sendToContacts = _.sortBy(sendTos, s => s.firstName);

            const notifies = c.filter(w => w.notifyOnAddReference == true).map(function (contact) {
                const notify = new NotifyContact(contact.workflowContactId, contact.firstName + ' ' + contact.lastName, false);
                return notify;
            });

            this._notifyContacts = _.sortBy(notifies, n => n._contactName);
        });

        const holdData  = this._referenceState.getHoldAction(this._data.entity);
        this.holdReason = holdData.holdReason;
        this.isOnHold   = holdData.isOnHold;
    }

    get reference() {
        return this._data.entity;
    }

    getError(propName: string) {
        const otherError = this._data.otherErrorMap[propName];
        const r = otherError || this._data.entity.getErrorFor(propName);
        return r;
    }

    onCaptivePaperChange(captivePaper: boolean) {
        if (!captivePaper) {
            this.reference.captiveCompany = null;
        }
    }

    onRestrictedDistributionChange(restrictedDistribution: boolean) {
        if (!restrictedDistribution) {
            this.reference.sponsoringCompany = null;
        }
    }

    onCancel() {
        if (!this._data) {
            return;
        }
        this._data.otherErrorMap = {};
        this._data.isEditingRef = false;

        this.resetSelections();

        this.refreshWorkflowHistory();
    }

    onEditRefId() {
        const data = this._data;
        data.origRefId = data.entity.referenceId;
        // data.testRefId = data.entity.referenceId;
        data.isEditingRef = true;
    }

    onShowHideMatches() {
        const data = this._data;
        data.showBriefRefMatches = !data.showBriefRefMatches;
        if (data.showBriefRefMatches) {
            return this.getMatchingBriefRefs(this._data.entity.briefReference);
        }
    }

    onSuggestNext(): void {
        const data = this._data;
        this.getMatchingBriefRefs(data.entity.briefReference).then(r => {
            const newBr = this.getNextBriefRef(r);
            if (newBr) {
                data.entity.briefReference = newBr;
                this.onCheckBriefRef();
            }
        });
    }

    onSelectJournal() {
        UtilFns.showModal(this._journalSelectorComponent, this, null).then(journal => {
            if (journal == null) {
                return;
            }
            // will also assign 'journalId'
            this._data.entity.journal = journal;
        });
    }

    // code to wrap binding to reference.abstract.text because the abstract record
    // may not exist.
    get _abstractText() {
        const ref = this._data.entity;
        if (ref.abstract == null) {
            return '';
        }
        return ref.abstract.text;
    }

    set _abstractText(value: string) {
        if (this.reference.abstract == null) {
            // exit if no abstract and no value
            if (!(value && value.trim().length)) {
                return;
            }
            const abstract = this._uow.abstractFactory.create({
                referenceId: this._data.entity.referenceId
            });
            this._data.entity.abstract = abstract;
        } else {
            // because abstract.text is required if the abstract record exists.
            if (!(value && value.trim().length)) {
                value = '-';
            }
        }
        this.reference.abstract.text = value;
    }

    onInputBriefRef() {
        setTimeout(() => {
            this._data.otherErrorMap['briefReference'] = 'BriefReference will be validated when you leave this field';
        });

        if (this._data.adding) {
            // Reset reference author data created from the Brief Reference when it is changed
            this._data.showBriefRefMatches = false;
            this._matchingAuthors = null;
            this._showAuthorMatches = false;

            this.onDetachNewReferenceAuthors();
        }
    }

    onCheckBriefRef() {
        const e = this.reference;
        e.setYearOfPublicationFromBriefReference();
        this.getMatchingBriefRefs(this._data.entity.briefReference).then((r) => {
            this._data.otherErrorMap['briefReference'] = '';
            if (!r) {
                return;
            }
            const collision = r.some(x => x.briefReference == e.briefReference && x.referenceId != e.referenceId);
            if (collision) {
                setTimeout(() => {
                    this._data.otherErrorMap['briefReference'] = 'This BriefReference has already been used.';
                }, 0);
                return;
            }
        });
    }

    getNextBriefRef(matches: ReferenceListItem[]) {
        if (matches == null || matches.length == 0) {
            return null;
        }
        const lastMatch = matches[matches.length - 1];
        const parts = Reference.extractBriefRefParts(lastMatch.briefReference);
        if (parts.suffix == null) {
            return null;
        }
        let suffix: string;
        if (parts.suffix.length == 0) {
            suffix = 'a';
        } else {
            suffix = parts.suffix;
            const ascii = suffix[suffix.length - 1].charCodeAt(0) + 1;
            if ('z'.charCodeAt(0) >= ascii) {
                suffix = suffix.substr(0, suffix.length - 1) + String.fromCharCode(ascii);
            } else {
                suffix = suffix.substr(0, suffix.length - 1);
                if (suffix.length == 0) {
                    suffix = 'aa';
                } else {
                    const asciiData = suffix[suffix.length - 1].charCodeAt(0) + 1;
                    if ('z'.charCodeAt(0) >= asciiData) {
                        suffix = String.fromCharCode(asciiData) + 'a';
                    } else {
                        // past zz ... pick a simpler method
                        suffix = suffix.substr(0, suffix.length - 1) + 'aa';
                    }
                }
            }
        }
        parts.suffix = suffix;
        const newBr = this.toBriefRef(parts);
        return newBr;
    }

    getMatchingBriefRefs(briefRef: string): Promise<ReferenceListItem[]> {
        const data = this._data;
        briefRef = briefRef.trim();
        if (!briefRef) {
            data.briefRefMatches    = null;
            data.briefRefKey        = null;
            return Promise.resolve(null);
        }

        if (briefRef == data.briefRefKey) {
            return Promise.resolve(data.briefRefMatches);
        }

        const parts = Reference.extractBriefRefParts(briefRef);
        return this._uow.fetch('References/ReferencesByRootBriefReference', { briefReference: UtilFns.encodeHackForAnd(parts.search) }).then(r => {
            const brMatches = <ReferenceListItem[]><any>r;
            data.briefRefMatches = _.sortBy(brMatches, m => {
                const briefParts = Reference.extractBriefRefParts(m.briefReference);
                const result = (briefParts.author ? briefParts.author.toLocaleUpperCase() : '')
                    + (briefParts.year || '')
                    + (briefParts.suffix ? briefParts.suffix.length.toString() + briefParts.suffix : '');
                return result;
            });
            data.briefRefKey = briefRef;

            return data.briefRefMatches;
        });
    }

    toBriefRef(parts: IBriefReferenceParts) {
        return parts.author + ',' + parts.year + parts.suffix;
    }

    hasBriefReferenceMatches(): boolean {
        return (this._data.briefRefMatches != null && this._data.briefRefMatches.length > 0);
    }

    // Authors
    canShowAuthors(): boolean {

        if (!this._data.adding) {
            return false;
        }

        if (!this._data.entity.briefReference) {
            return false;
        }

        const parts = Reference.extractBriefRefParts(this._data.entity.briefReference);
        return (parts.author != null);
    }

    hasAuthorMatches(): boolean {
        return ((this._matchingAuthors != null && this._matchingAuthors.length > 0) ||
            (this._data.entity.referenceAuthors != null && this._data.entity.referenceAuthors.length > 0));
    }

    onShowHideAuthorMatches() {
        if (!this._data.adding) {
            return false;
        }

        this._showAuthorMatches = !this._showAuthorMatches;
        if (this._showAuthorMatches && this._matchingAuthors == null) {
            this.getMatchingAuthors();
        }
    }

    getMatchingAuthors() {
        this._matchingAuthors = null;

        if (!this._data.entity.briefReference) {
            return;
        }

        const parts = Reference.extractBriefRefParts(this._data.entity.briefReference);
        if (parts.author) {
            this._loadingAuthors = true;
            const params = { authorName: parts.author };
            this._uow.fetchTyped('Misc/AuthorsByNameAndBriefReference', Author, params).then(r => {
                if (r != null && r.length > 0) {

                    r = _.sortBy(r, a => a.authorName);
                    this._matchingAuthors = r.map(item => <AuthorSelector>{
                        author: item,
                        selected: false
                    });
                }
                this._loadingAuthors = false;
            });
        }
    }

    onAttachSelectedAuthor(author: Author) {

        if (this._data.entity.referenceAuthors == null) {
            this._data.entity.referenceAuthors = [];
        }

        const maxCounter = _.max(this._data.entity.referenceAuthors.map(x => x.counter)) || 0;

        const refauthdata = {
            referenceId: this._data.entity.referenceId,
            authorId: author.authorId,
            counter: maxCounter + 1
        };

        const ra = this._uow.referenceAuthorFactory.create(refauthdata);
        this._data.entity.referenceAuthors.push(ra);

    }

    onChangeAuthorSelection(selectedAuthor: AuthorSelector) {
        if (selectedAuthor.selected) {
            this.onAttachSelectedAuthor(selectedAuthor.author);
        } else {
            this.onDetachReferenceAuthors(selectedAuthor.author);
        }
    }

    onDetachNewReferenceAuthors() {
        if (this._data.entity.referenceAuthors) {
            this._data.entity.referenceAuthors.forEach(ra => {
                if (ra.author.entityAspect.entityState.isAdded()) {
                    ra.author.entityAspect.rejectChanges();
                }
                if (ra.entityAspect.entityState.isAdded()) {
                    ra.entityAspect.rejectChanges();
                }
            });
        }
    }

    onDetachReferenceAuthors(author: Author) {
        if (this._data.entity.referenceAuthors) {

            const ra = this._data.entity.referenceAuthors.filter(a => a.author.authorName == author.authorName);

            if (ra != null && ra.length > 0) {

                if (ra[0].author.entityAspect.entityState.isAdded()) {
                    ra[0].author.entityAspect.rejectChanges();
                }
                if (ra[0].entityAspect.entityState.isAdded()) {
                    ra[0].entityAspect.rejectChanges();
                }
            }
        }
    }

    onCreateAuthor() {
        const parts = Reference.extractBriefRefParts(this._data.entity.briefReference);
        let author: Author;

        if (parts.author) {
            author = this._uow.authorFactory.create();
            author.authorName = parts.author;
        } else {
            return;
        }

        if (this._data.entity.referenceAuthors == null) {
            this._data.entity.referenceAuthors = [];
        }

        const maxCounter = _.max(this._data.entity.referenceAuthors.map(x => x.counter)) || 0;

        const refAuthorData = {
            referenceId: this._data.entity.referenceId,
            authorId: author == null ? null : author.authorId,
            counter: maxCounter + 1
        };

        const ra = this._uow.referenceAuthorFactory.create(refAuthorData);
        this._data.entity.referenceAuthors.push(ra);

    }

    canShowAuthorMatches(): boolean {
        if (!this._data.adding) {
            return false;
        }

        return (this._showAuthorMatches);
    }

    // Workflow Related
    public isProofable(): boolean {
        if (this._data.entity.abstract == null || this._data.entity.abstract.text == '-' || this._data.entity.abstract.entityAspect.entityState.isAdded()) {
            return false;
        }

        return !this._data.entity.complete;
    }

    private abstractProofed() {
        return this._data.entity.complete;
    }

    private proofAbstract() {
        this._data.entity.complete = true;
    }

    // Workflow On Hold
    public get holdReason(): string {
        return this._holdReason;
    }

    public set holdReason(value: string) {
        this._holdReason = value;

        this.canSaveHold = (this._holdReason && this._holdReason.length > 0) ? true : false;
    }

    public get isOnHold(): boolean {
        return this._isOnHold;
    }

    public set isOnHold(value: boolean) {
        this._isOnHold = value;
    }

    public get showHoldReasonInput(): boolean {
        return this._showHoldReasonInput;
    }

    public set showHoldReasonInput(value: boolean) {
        this._showHoldReasonInput = value;
    }

    public get canSaveHold(): boolean {
        return this._canSaveHold;
    }

    public set canSaveHold(value: boolean) {
        this._canSaveHold = value;
    }

    public addHold() {
        this.showHoldReasonInput = true;
    }

    public cancelAddHold() {
        this.showHoldReasonInput = false;
        this.holdReason = '';
        this.isOnHold = false;
    }

    public saveHold() {
        this._referenceState.recordAddHold(this._data.entity, this.holdReason);
        this.isOnHold = true;
        this.showHoldReasonInput = false;
    }

    public removeHold() {
        this._referenceState.recordRemoveHold(this._data.entity);
        this.holdReason = '';
        this.isOnHold = false;
    }

    // The "Finished" state is normally derived from the state of the data and workflow history but it can be overridden by staff here.
    finishReference() {
        this.confirmFinishOverride();
    }

    private confirmFinishOverride() {

        this._referenceState.enumerateMissingData(this._data.entity).then(c => {
            if (c == 0) { // Number of missing data elements = 0
                this._data.entity.finished = true;
                this._referenceState.finishOverride(this._data.entity);
            }


            this._confirmFinishOverrideComponent.showModal('Attempt to Finish a Reference with Missing Data',
                'Do you still want to Finish this reference?', this._referenceState.unfinishedReasons).then(ok => {
                if (ok) {
                    this._data.entity.finished = true;
                    this._referenceState.finishOverride(this._data.entity);
                }
            });
        });
    }

    // SendTo Workflow
    public sendToStaff(r: number) {
        if (this._selectedContactId != r) {
            if (this._selectedContactId != 0) {
                this._workflowEmailService.removeEmailFromSendToList(this._selectedContactId, this._data.entity);
            }

            this._selectedContactId = r;

            if (this._selectedContactId != 0) {

                this.recordSendTo(this._selectedContactId);
            }
        }
    }

    private recordSendTo(contactId: number) {

        const contact = this._sendToContacts.filter(c => c.workflowContactId == this._selectedContactId);

        if (contact == null || contact.length == 0) {
            return;
        }

        const resend = this._workflowEmailService.isResend(this._data.entity);

        if (resend) {
            this._workflowEmailService.reassignPreviousSentTo(this._data.entity).then(w => {
                this._workflowEmailService.addEmailToSendToList(contact[0], this._data.entity, resend);
            });
        } else {
            this._workflowEmailService.addEmailToSendToList(contact[0], this._data.entity, resend);
        }
    }

    public notifyContact(notifyContact: NotifyContact) {
        const workflowContact = this._allContacts.filter(a => a.workflowContactId == notifyContact._contactId);

        if (workflowContact == null || workflowContact.length == 0) {
            return;
        }

        if (notifyContact._selected) {
            this._workflowEmailService.addEmailToNotifyList(workflowContact[0], this._data.entity);
        } else {
            this._workflowEmailService.removeEmailFromNotifyList(workflowContact[0].workflowContactId, this._data.entity);
        }
    }

    // Reset Send To and Notify selections and refresh On Hold values
    resetSelections() {
        this._selectedContactId = 0;

        if (this._notifyContacts != null && this._notifyContacts.length > 0) {
            this._notifyContacts.forEach(n => n._selected = false);
        }

        const holdData = this._referenceState.getHoldAction(this._data.entity);
        this.holdReason = holdData.holdReason;
        this.isOnHold = holdData.isOnHold;
    }

    // Government Publication logic
    onSetPublicationType(val: number) {

        this.reference.publicationTypeId = val;

        if (this.reference.publicationTypeId != 7) {
            this.reference.govDocNumbers = null;
            this.reference.govSponsors = null;
        }
    }

    isGovernmentDocument(): boolean {
        return (this.reference.publicationTypeId == 7);
    }

    refreshWorkflowHistory() {
        if (this._workflowHistoryComponent) {
            if (this._workflowHistoryComponent.currentReferenceId == null || this._workflowHistoryComponent.currentReferenceId != this._data.entity.referenceId) {
                this._workflowHistoryComponent.onReferenceChange(this._data.entity);
            } else {
                this._workflowHistoryComponent.loadData();
            }
        }
    }
}

import { Location } from '@angular/common';
import {Component, forwardRef, Inject, Input, OnInit, AfterViewInit, ViewChild} from '@angular/core';

import {UnitOfWork, StateMap, provideParent, WorkflowEntityState, ReferenceState} from '../../services/common';
import { PageState, EditPropParent } from '../../controls/common';
import {ReferenceDocumentEditData, RIFMDocumentsComponent} from '../docmanagement/rifm-documents.component';
import { Reference } from '../../entities/EntityModels';

import * as _ from 'lodash';
import {UtilFns} from '../../utils/util-fns';
import {Observable} from 'rxjs';
import {HttpClient, HttpHeaders} from '@angular/common/http';

@Component({
    selector: 'replace-reference-document',
    templateUrl: './replace-reference-document.html'
})

export class ReplaceReferenceDocumentComponent implements OnInit, AfterViewInit {
    @Input() documentManagementUrl: string;

    public _data: ReferenceDocumentEditData = new ReferenceDocumentEditData();
    public _workflowReference: Reference;

    public _loading = false;
    public _pageState = new PageState('Add Reference Document');

    // add new reference document variables
    public _filedata: any;
    public _fileInputElement: any;

    public _replacedSuccessfully 	= false;
    public _validationMessage 		= '';

    constructor(public _uow: UnitOfWork, public _stateMap: StateMap, public _location: Location, private _httpClient: HttpClient, private _referenceState: ReferenceState,
                @Inject(forwardRef(() => RIFMDocumentsComponent)) public _parent: RIFMDocumentsComponent) {

        this._data = _parent._data;
    }

    ngOnInit() {
    }

    ngAfterViewInit() {
        this._fileInputElement = $('#fileInputElement')[0] as HTMLInputElement;
    }

    public cancelReplace() {
        this._filedata = null;
    }

    public get fileData(): any {
        return this._filedata;
    }

    public set fileData(val: any) {
        this._filedata = val;
    }

    get utilFns() {
        return UtilFns;
    }

    // ******************************************************************
    // Handle initial file upload
    // ******************************************************************
    onFileInput(files: FileList) {
        this._filedata = files.item(0);
    }

    public canCancel() {
        if (this._replacedSuccessfully) {
            return false;
        }
        return (this.fileData != null);
    }

    public canSave() {
        return (this.fileData != null);
    }

    public canShowBack() {
        if (this._replacedSuccessfully) {
            return true;
        }

        return (this.fileData == null);
    }

    public onCancel() {
        this._parent.replaceCompleted('Reference Document file replace cancelled.');
        if (this._fileInputElement != null) {
            this._fileInputElement.value = null;
        }
    }

    public onBack() {
        this._parent.replaceCompleted('');
    }

    public onSave() {
        this.replaceReferenceDocumentFile();
    }

    // ************************************************************************
    // web api calls
    // ************************************************************************
    getReplaceDocumentURL(): Observable<any> {
        const url = this.documentManagementUrl + '/ReplaceRIFMDocument';
        const headers = new HttpHeaders();
        headers.append('Accept', 'application/json');

        const formData: FormData = new FormData();
        formData.append('referenceId', this._data.referenceId + '');
        formData.append('rifmDocumentId', this._data.rIFMDocumentId + '');
        formData.append('fileName', this._data.referenceId + '.pdf');
        formData.append('file', this.fileData);

        return this._httpClient.post<any>(url, formData);
    }

    public replaceReferenceDocumentFile() {
        this._replacedSuccessfully = false;
        this._loading = true;
        this._validationMessage = '';
        this.getReplaceDocumentURL()
            .subscribe(
                (response) => {
                    const data = response;

                    if (data.Success == false) {
                        this._validationMessage = 'Error occurred, unable to replace the Reference Document file: ' + data.ExceptionMessage;
                        return;
                    }

                    this._replacedSuccessfully = true;
                    this._parent.replaceCompleted('Reference Document file replaced successfully.');
                },
                (error) => {
                    console.error('Request failed with error: ' + error.message);
                    this._validationMessage = error.message;
                    this._loading = false;
                },
                () => {
                    this._loading = false;
                    if (this._fileInputElement != null) {
                        this._fileInputElement.value = null;
                    }
                });
    }
}

// *******************************************************************************
// Component to reconcile pending and completed tasks with deleted data.
// *******************************************************************************

import { Injectable } from '@angular/core';
import * as _ from 'lodash';

import { AnalyticalResult, ExperimentalMaterial, ExperimentalResult, Reference, WorkflowRecordedAction } from '../entities/EntityModels';
import { WorkflowActivityCompletedItem } from '../entities/projections/WorkflowActivityCompletedItem';
import { UnitOfWork } from './unit-of-work';
import { WorkflowService } from './workflow-service';
import { ReferenceFinishedService } from './reference-finished-service';
import { WorkflowEntityState, WorkflowState } from './workflow-state';

@Injectable()
export class WorkflowDeletionService {

    constructor(private _uow: UnitOfWork, private _workflowService: WorkflowService, private _referenceFinishedService: ReferenceFinishedService) {
    }

    // *******************************************************************************
    // Reference Linked Analytical Results Deletion Logic
    // *******************************************************************************
    public reconcileLinkedAnalyticalResultDeletesWithPendingActions(reference: Reference, analyticalResults?: AnalyticalResult[]) {

        if (this.arrayIsEmpty(reference.workflowRecordedActions)) {
            return;
        }

        if (this.arrayIsEmpty(analyticalResults)) {
            this._workflowService.cancelPendingWorkflowRecordedActionsForReference(reference, 'CHECK PHYSICAL PROPERTY', false);
            return;
        }

        const completed = this._workflowService.referenceMostRecentCompletedAction(reference, 'CHECK PHYSICAL PROPERTY');

        if (completed) {
            const lastmat = analyticalResults.sort(function (date1, date2) {
                return date2.created.getTime() - date1.created.getTime();
            });

            // Cancel actions relating to deleted analytical results records
            if (completed.created.getTime() > lastmat[0].created.getTime()) {
                // Then the remaining open 'CHECK PHYSICAL PROPERTY pending actions can be cancelled
                this._workflowService.cancelPendingWorkflowRecordedActionsForReference(reference, 'CHECK PHYSICAL PROPERTY', false);
            }
        }

        return;
    }

    private arrayIsEmpty(anyArray?: any[]): boolean {

        return (anyArray == null || anyArray.length == 0);

    }

    // *******************************************************************************
    // Protocol and Tox Data Deletion Logic
    // *******************************************************************************
    public hasBioDataDeletionsToProcess(reference: Reference): boolean {

        const deletes: string[] = ['MATERIAL UNLINKED', 'PROTOCOL DELETED', 'SPECIAL LINK DELETED', 'TOX DATA DELETED'];

        if (reference.workflowRecordedActions.some(d =>
            _.some(deletes, function (deleteaction) {
                return deleteaction === d.typeWorkflowActionId;
            }) && d.entityAspect.entityState.isAdded()
        )) {
            return true;
        }

        return false;
    }

    // Close any pending actions that are dependent upon the deleted action.
    // Invoke a method that will add back in missing required actions to replace deleted action.
    public reconcileProtocolDeletesWithPendingActions(reference: Reference, expmats?: ExperimentalMaterial[]) {

        // PROTOCOL ADDED => MATERIAL LINKED => TOX DATA ADDED
        const missingMaterialLink = this.protocolsMissingMaterialLinks(reference);
        if (missingMaterialLink == false) {
            this._workflowService.cancelPendingWorkflowRecordedActionsForReference(reference, 'MATERIAL LINKED', false);
        }

        if (expmats == null || expmats.length == 0) {
            this._workflowService.cancelPendingWorkflowRecordedActionsForReference(reference, 'TOX DATA ADDED', false);
            this.reconcileToxDataDeletesWithPendingActions(reference, null);
            this.addBackRequiredActions(reference, 0);
            return;
        }

        this._workflowService.cancelPendingWorkflowRecordedActionsForReference(reference, 'TOX DATA ADDED', true);

        // Now loop through all the ExperimentalMaterials to gather Experimental Results
        const experimentalResults: ExperimentalResult[] = [];
        expmats.forEach(em => {
            if (em.experimentalResults && em.experimentalResults.length > 0) {
                em.experimentalResults.forEach(er => {
                    experimentalResults.push(er);
                });
            }
        });

        this.reconcileToxDataDeletesWithPendingActions(reference, experimentalResults);

        this.addBackRequiredActions(reference, expmats.length);

        return;
    }

    public protocolsMissingMaterialLinks(reference: Reference): boolean {
        if (reference.biologicalDatas != null || reference.biologicalDatas.length > 0) {
            for (let idx = 0; idx < reference.biologicalDatas.length; idx++) {
                if (reference.biologicalDatas[idx].experimentalMaterials == null || reference.biologicalDatas[idx].experimentalMaterials.length == 0) {
                    return true;
                }
            }
        }
        return false;
    }

    public reconcileToxDataDeletesWithPendingActions(reference: Reference, expresults?: ExperimentalResult[]) {

        if (expresults == null || expresults.length == 0) {
            this._workflowService.cancelPendingWorkflowRecordedActionsForReference(reference, 'AUDIT TOX DATA', false);
            return;
        }

        // TOX DATA ADDED => AUDIT TOX DATA
        const completed = this._workflowService.referenceMostRecentCompletedAction(reference, 'AUDIT TOX DATA');

        if (completed == null) {
            return;
        }

        const lastresult = expresults.sort(function (date1, date2) {
            return date2.created.getTime() - date1.created.getTime();
        });

        // Invalidate actions relating to deleted experimental results records
        if (completed.created.getTime() > lastresult[0].created.getTime()) {
            // Then the remaining open TOX DATA ADDED pending actions can be cancelled
            this._workflowService.cancelPendingWorkflowRecordedActionsForReference(reference, 'AUDIT TOX DATA', false);
        }

        return;
    }

    public addBackRequiredActions(reference: Reference, expMatsCount: number) {

        if (reference.biologicalDatas == null || reference.biologicalDatas.length == 0) {
            return;
        }

        const dataCheck = this._referenceFinishedService.hasIncompleteProtocolData(reference);

        // If the reference is missing experimental material data and there's no longer a pending task for this, insert one
        if (dataCheck.missingExperimentalMaterials) {
            if (this._workflowService.referenceMostRecentPendingAction(reference, 'MATERIAL LINKED') == null) {
                const workflow = new WorkflowState();
                workflow.workflowReferenceId = reference.referenceId;
                workflow.workflowEntityName = 'BiologicalData';
                workflow.workflowEntityState = WorkflowEntityState.Added;
                workflow.workflowPropertyName = 'addback';
                this._workflowService.addWorkflowEvent(workflow, reference);
            }
        }

        if (expMatsCount == 0) {
            return;
        }

        if (dataCheck.missingExperimentalResults) {
            if (this._workflowService.referenceMostRecentPendingAction(reference, 'TOX DATA ADDED') == null) {
                const workflow = new WorkflowState();
                workflow.workflowReferenceId = reference.referenceId;
                workflow.workflowEntityName = 'ExperimentalMaterial';
                workflow.workflowEntityState = WorkflowEntityState.Added;
                workflow.workflowPropertyName = 'addback';
                this._workflowService.addWorkflowEvent(workflow, reference);
            }
        }
    }

    // ****************************************************************************************************
    // Workflow Actions removed/undone by Staff.
    // ****************************************************************************************************
    // The workflow pattern (with exceptions) is as follows:
    //  A record with the preceding ACTION and a next ACTION to be Completed.

    //  When data is added, the change is recorded twice, once as a completed
    //  next step, and once as a preceding Action along with a new next step action
    //  triggered by the completed action.

    //  Both actions need to be reset when a staff member wants to undo an
    //  action they took.

    //  Only actions that are NOT generated by the state of the data can be handled this way.

    //  There is other logic that handles actions generated by logic based on the state
    //  of the reference or tox data.
    // ****************************************************************************************************
    public processDeleteWorkflowAction(reference: Reference, action: WorkflowActivityCompletedItem) {

        if (action.completedOnlyType) {
            this.deleteCompletedOnlyAction(reference, action.workflowRecordedActionId);
        } else {
            // Remove record by id
            this.deleteWorkflowAction(reference, action.workflowRecordedActionId);

            // Undo Action Completed Date
            // Exception: When a Reference is SENT multiple times, the completed action for previous assignments is marked as REASSIGN
            // and the action recorded is SENT AGAIN. However the required 'SENT TO' associated with the MADE LINKABLE
            // action still needs to be undone.
            const completedActionId = (action.typeWorkflowActionId == 'SENT AGAIN') ? 'SENT TO' : action.typeWorkflowActionId;
            const item: WorkflowRecordedAction = this._workflowService.referenceMostRecentCompletedAction(reference, completedActionId);
            if (item) {
                item.actionCompletedDate = null;
                item.completedUser = null;
            }

            if (action.typeWorkflowActionId == 'FINISHED OVERRIDE') {
                reference.finished = false;
            }

        }
    }

    public deleteWorkflowAction(reference: Reference, id: number): boolean {

        if (reference.workflowRecordedActions != null && reference.workflowRecordedActions.length > 0) {
            const actions = reference.workflowRecordedActions.filter(a => a.workflowRecordedActionId == id);

            if (actions != null && actions.length > 0) {
                actions[0].entityAspect.setDeleted();
                return true;
            }
        }
        return false;
    }

    public deleteCompletedOnlyAction(reference: Reference, id: number): boolean {
        if (reference.workflowRecordedActions != null && reference.workflowRecordedActions.length > 0) {
            const actions = reference.workflowRecordedActions.filter(a => a.workflowRecordedActionId == id);

            if (actions != null && actions.length > 0) {
                actions[0].actionCompletedDate = null;
                actions[0].completedUser = null;
                actions[0].completedWorkflowContactId = null;

                // When a Reference Abstract is Proofed by Staff, the Reference Completed Field is marked to true, this needs to be undone as well.
                if (actions[0].completedWorkflowActionId == 'PROOF ABSTRACT') {
                    reference.complete = false;
                }
                return true;
            }
        }
        return false;
    }

}

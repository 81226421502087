import {Configuration} from './configuration';
import {ErrorLogger} from './error-logger';

import {EntityManagerProvider} from './entity-manager-provider';
import {UnitOfWork, StateMap} from './common';
import {SearchService} from './search';
import {UserManager} from './user-manager';
import {MolImageService} from './mol-image.service';
import {EditorService} from './editor.service';
import {ReferenceState} from './reference-state';
import {ReferenceFinishedService} from './reference-finished-service';
import {WorkflowService} from './workflow-service';
import {WorkflowEmailService} from './workflow-email-service';
import { BusyService } from './busy.service';
import { ReferenceLoader } from '../components/staff/services/reference-loader';
import { MaterialLoader } from '../components/staff/services/material-loader';

export var SERVICE_PROVIDERS = [Configuration, ErrorLogger,
    EntityManagerProvider, UnitOfWork, SearchService, StateMap, UserManager,
    MolImageService, EditorService, ReferenceState, ReferenceFinishedService, WorkflowService, WorkflowEmailService,
    BusyService, ReferenceLoader, MaterialLoader ];
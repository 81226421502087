<div *ngIf="prediction" class="form-horizontal">
    <div class="col-md-7">

        <div class="form-group">
            <edit-prop isChild label="Prediction Type" prop="typePredictionId" szs="2,5">
                <select class="form-control" [ngModel]="prediction.typePredictionId" (change)="prediction.typePredictionId=checkIfNull($event.target.value)">
                    <option selected value>-- None --</option>
                    <option *ngFor="let item of _typePredictions" [value]="item.typePredictionId">{{item.predictionName}}</option>
                </select>
            </edit-prop>
        </div>

        <div class="form-group">
            <edit-prop isChild label="Prediction Class" prop="predictionClass" szs="2,5">
                <select class="form-control" [ngModel]="prediction.predictionClass" (change)="prediction.predictionClass=checkIfNull($event.target.value)">
                    <option selected value>-- None --</option>
                    <option *ngFor="let item of _typePredictionClasses" [value]="item.predictionClass">{{item.predictionClass + ' ' + item.predictionClassDescription}}</option>
                </select>
            </edit-prop>
        </div>

        <div class="form-group">
            <edit-prop isChild label="Comment" prop="comment" szs="2,5">
                <input class="form-control" type="text" [(ngModel)]="prediction.comment">
            </edit-prop>
        </div>

        <div class="form-group">
            <edit-prop isChild label="Enabled" prop="enabled" szs="2,5">
                <input class="form-control" type="checkbox" [(ngModel)]="prediction.enabled">
            </edit-prop>
        </div>

    </div>
</div>

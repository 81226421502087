import { Component, Input, OnInit, ElementRef, ViewChild, ContentChild } from '@angular/core';
import { NgStyle } from '@angular/common';
import { UnitOfWork } from '../../services/common';
import { UtilFns } from '../../utils/common';

import { ModalDialog, PageState } from '../../controls/common';
import { SearchService } from '../../services/common';

import { SuperCluster, SuperClusterPath, Material, ClusterGroup, ClusterPart, ClusterPath, MaterialCluster, ClusterNodeAndEdge } from '../../entities/EntityModels';
import { SelectableEntity } from '../../entities/projections/SelectableEntity';

import { MaterialListItem } from '../../entities/projections/MaterialListItem';

import * as _ from 'lodash';

@Component({
    selector: 'clusterpath-filter',
    templateUrl: './clusterpath-filter.html',
})
export class ClusterPathFilterComponent extends ModalDialog<boolean> {

    _isLoading = false;

    _selectedMaterial: Material;
    _materialCluster: MaterialCluster;

    _searchClusterTerm: string;
    _clusterPartSearchTerms: string[] = [];

    _clusterGroups: ClusterGroup[];
    _selectedClusterGroup: ClusterGroup;
    _selectedClusterGroupId = -1;

    _clusterPaths: ClusterPath[]                        = [];
    _clusterPathsEx: SelectableEntity<ClusterPath>[]    = [];
    _selectedClusterPathIds: number[]                   = [];

    _userNotifications      = '';

    constructor(elementRef: ElementRef, public _uow: UnitOfWork) {
        super(elementRef);
    }

    get utilFns() {
        return UtilFns;
    }

    // ***************************************************************************************************
    // Load Data
    // ***************************************************************************************************
    loadMaterialClusterPathData() {
        this._isLoading = true;
        this._materialCluster = null;

        const params = { materialId: this._selectedMaterial.materialId };
        this._uow.fetch('MaterialClusters/MaterialClusterByMaterialId', params).then(m => {
            if (m != null && m.length > 0) {
                this._materialCluster = m[0];
            }
            this._isLoading = false;
        });
    }

    loadMatchingClusterGroups() {
        this._isLoading = true;

        this.clearSelections();

        const param = {
            partSearchTerms: this._clusterPartSearchTerms
        };
        this._uow.fetchTyped('MaterialClusters/FetchClusterGroupsByClusterPartLabels', ClusterGroup, param).then(g => {
            this._clusterGroups = g;

            if (this._clusterGroups != null && this._clusterGroups.length > 0) {
                this.onSelectClusterGroup(this._clusterGroups[0].clusterGroupId);
            } else {
                this._userNotifications = 'No Cluster Paths match your search terms.';
            }
            this._isLoading = false;
        });
    }

    loadMatchingClusterPathData() {
        this._isLoading = true;

        this._clusterPaths              = null;
        this._clusterPathsEx            = null;
        this._selectedClusterPathIds    = [];

        const params = {
            partSearchTerms: this._clusterPartSearchTerms,
            clusterGroupId: this._selectedClusterGroup.clusterGroupId
        };

        this._uow.fetchTyped('MaterialClusters/FetchClusterPathByClusterLabel', ClusterPath, params).then(p => {
            this._clusterPaths = p;

            this.formatSelectableEntities();

            this._isLoading = false;
        });
    }

    formatSelectableEntities() {
        this._clusterPathsEx = [];
        if (this._clusterPaths == null || this._clusterPaths.length < 1) { return; }

        _.clone(this._clusterPaths).forEach(element => {
            this._clusterPathsEx.push(new SelectableEntity<ClusterPath>(element, '#FFFFFF'));
        });
    }

    // ***************************************************************************************************
    // Interaction
    // ***************************************************************************************************
    public get SelectedClusterPathIds(): number[] {
        return this._selectedClusterPathIds;
    }

    findClusterGroupById(clusterGroupId: number): ClusterGroup {
        const clusterGroups = this._clusterGroups.filter(g => g.clusterGroupId == clusterGroupId);
        return clusterGroups[0];
    }

    public get SelectedClusterGroupId(): number {
        return this._selectedClusterGroup.clusterGroupId;
    }

    clearSelections() {
        this._clusterGroups             = null;
        this._selectedClusterGroup      = null;
        this._selectedClusterGroupId    = -1;
        this._clusterPaths              = null;
        this._clusterPathsEx            = null;
    }

    // ***************************************************************************************************
    // Close Modal
    // ***************************************************************************************************
    onCancel() {
        UtilFns.returnModal(this, false);
    }

    onSave() {
        UtilFns.returnModal(this, true);
    }

    canSave(): boolean {

        if (this._isLoading == true) {
            return false;
        }

        return (this._selectedClusterPathIds.length > 0);
    }

    canAddSearchTerm(): boolean {
        if (!this._searchClusterTerm || this._searchClusterTerm.length == 0) {
            return false;
        }

        return (this._searchClusterTerm.trim().length > 0);
    }

    canSearch(): boolean {
        return (this._clusterPartSearchTerms.length > 0);
    }

    showUserMessage() {
        return (this._clusterGroups != null && this._clusterGroups.length > 1);
    }

    // ***************************************************************************************************
    // Filter by a cluster part label
    // ***************************************************************************************************
    onAddSearchTerm() {
        const term = this._searchClusterTerm.trim();

        if (!term || term.length == 0) {
            return;
        }

        if (this._clusterPartSearchTerms.length == 0) {
            this._clusterPartSearchTerms.push(term);
        } else {
            const idx = this._clusterPartSearchTerms.indexOf(term);
            if (idx < 0) {
                this._clusterPartSearchTerms.push(term);
                // Search criteria has changed so reset the data
                this.clearSelections();
            }
        }
        this._searchClusterTerm = '';

        this.searchClusterParts();
    }

    onRemoveSearchTerm(term: string) {
        this._userNotifications = '';

        if (this._clusterPartSearchTerms.length == 0) {
            return;
        }

        const terms = _.clone(this._clusterPartSearchTerms);

        const idx = terms.indexOf(term);

        if (idx > -1) {
            if (this._clusterPartSearchTerms.length == 1) {
                this._clusterPartSearchTerms = [];
            } else {
                this._clusterPartSearchTerms = this._clusterPartSearchTerms.filter(s => s !== term);
            }

            // Search criteria has changed so reset the data
            this.clearSelections();
        }

        if (this._clusterPartSearchTerms.length > 0) {
            this.searchClusterParts();
        }
    }

    onSelectClusterGroup(id: number) {
        this._selectedClusterGroup      = this.findClusterGroupById(id);
        this._selectedClusterGroupId    = this._selectedClusterGroup.clusterGroupId;

        this.loadMatchingClusterPathData();
    }

    searchClusterParts() {

        this._userNotifications = '';
        this.loadMatchingClusterGroups();
    }

    onSelectClusterPath(event: any, clusterPathId: number) {
        const currentpaths = _.clone(this._selectedClusterPathIds);

        const selected    = event.target.checked;
        const idx         = currentpaths.indexOf(clusterPathId);

        if (idx < 0) {
            if (selected) {
                this._selectedClusterPathIds.push(clusterPathId);
            }
        } else {
            if (selected == false) {
                this._selectedClusterPathIds = currentpaths.splice(idx, 1);
            }
        }
    }

    get addClusterTermLabel() {
        return (this._clusterPartSearchTerms.length == 0) ? 'Search Term' : 'Add Term to Search';
    }
}

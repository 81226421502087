<div style="margin-left: 8px;">
    <p class="bold">{{userMessage}}</p>

    <div>
        <div>
            <p class="bold" *ngIf="!hasReservesData()">No reserves for the selected journal.</p>
        </div>

        <div *ngIf="hasReservesData()">
            <div class="subtitle-margin-top" style="width: 950px;">
                <div>
                    <div class="scrollableTableHeader">
                        <div class="scrollableTableColumn text-center"><span class="inline" style="width: 100px">Year</span></div>
                        <div class="scrollableTableColumn text-center"><span class="inline" style="width: 100px">Volume</span></div>
                        <div class="scrollableTableColumn text-center"><span class="inline" style="width: 280px">Issue</span></div>
                        <div class="scrollableTableColumn text-left"><span class="inline" style="width: 380px">Notes</span></div>
                    </div>
                    <div style="height: 240px; overflow-y:auto;overflow-x: hidden;">
                        <div class="scrollableTableRow" *ngFor="let h of _reserves">
                            <div class="scrollableTableColumn text-center"><span class="inline" style="width: 100px">{{h.journalYear}}</span></div>
                            <div class="scrollableTableColumn text-center"><span class="inline" style="width: 100px">{{h.volume}}</span></div>
                            <div class="scrollableTableColumn text-left"><span class="inline" style="width: 280px">{{h.issue}}</span></div>
                            <div class="scrollableTableColumn text-left"><span class="inline" style="width: 380px">{{h.notes}}</span></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>

</div>

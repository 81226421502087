import {Component, forwardRef, Inject, OnInit} from '@angular/core';
import { provideParent, StateMap, UserManager } from '../../services/common';
import {AppComponent} from './app.component';
import { DOCUMENT } from '@angular/common';
import {environment} from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

declare var ga: Function; // Google analytics

@Component({
    selector: 'login',
    templateUrl: './login.html'
})
export class LoginComponent implements OnInit {
    _email: string;
    _oauthRedirectURL: string;

    constructor(public _userManager: UserManager, private _stateMap: StateMap, public _http: HttpClient,
                @Inject(forwardRef(() => AppComponent)) public _parent: AppComponent, @Inject(DOCUMENT) private document: Document) {
    }

    ngOnInit() {
    }


    // TODO: Ugly - replace with real Ng2 component when we get time.
    public async tryLogin(): Promise<any> {
        if (!this.isValidString(this._oauthRedirectURL)) {
            await this.getOAuthRedirectURL().then(url => {
                if (this.isValidString(url)) {
                    this._oauthRedirectURL = url;
                }
            });
        }

        // OAUTH login URL
        window.location.href = this._oauthRedirectURL;

        // this.document.location.href = this._oauthRedirectURL;
        return Promise.resolve(false);
    }

    public getOAuthRedirectURL(): Promise<string> {
        return this._http.get(`${environment.appUrl}/breeze/oAuthRedirectURL`, {})
            .toPromise()
            .then((url: any) => {
                return url;
            });
    }

    get userMessage(): string {
        const loc = window.location.href;

        return (loc.toLowerCase().indexOf('logout') > -1) ? 'You are logged out of the RIFM Database.' : 'Unable to authenticate your credentials.';
    }

    isValidString(teststring: string) {
        if (!teststring) { return false; }

        return (teststring.trim().length > 0);
    }
}


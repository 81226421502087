import { EntityBase } from './EntityBase';

import { Validator } from 'breeze-client';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

export class UserSessionToken extends EntityBase {

   /// <code> Place custom code between <code> tags
   
   /// </code>

   // Generated code. Do not place code below this line.
   userSessionTokenId: number;
   userEmail: string;
   userLoginToken: string;
   active: boolean;
   rIFMStaff: boolean;
   databaseSubscriber: boolean;
   fEMAMember: boolean;
   rIFMMember: boolean;
   organizationName: string;
   authorizedDownloadUser: boolean;
   fEMAStaff: boolean;
   rIFMConsultant: boolean;
   rIFMDBAdmin: boolean;
   iFRAStaff: boolean;
   created: Date;
   createdUser: string;
   modified: Date;
   modifyUser: string;
   rowVersion: number;
}


import { ElementRef } from '@angular/core';

export interface IModalDialog<T> {
    elementRef: ElementRef; // top level element in this component
    modalParent: any;  // a component
    modalResult: T;
    validationFn?: (x: T) => string;
    validationMessage?: string;
}

export abstract class ModalDialog<T> implements IModalDialog<T> {
    protected constructor(public elementRef: ElementRef) {
    }

    modalParent: any;  // a component
    modalResult: T;
    validationFn: (x: T) => string;
    validationMessage: string;
}

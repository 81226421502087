<div class="topic-page"  >
    <div class="flex-container background-transparent">
        <div class="flex-item" >
            <reference-header [reference]="reference"></reference-header>
        </div>
        <div class="flex-item" *ngIf="_hasReferenceDocumentFile">
            <button type="button" class="inline btn btn-view-reference-document" style="width: 205px; font-size: 12px;"  *ngIf="canDownloadReferenceDocument()" (click)="showReferenceDocument()">View or Download Document</button>
        </div>
    </div>
    <div class="row tab-container-vert">
        <div class="col-md-1-8 tab-titles no-print">
            <ul *ngIf="reference" class="nav nav-tabs nav-stacked">
                <li *ngFor="let r of _routes; let ix = index" role="presentation" [class.active]="isActiveTab(r)" 
                   [class.has-no-data]="!_stateMap.hasReferenceDataFor(r.path)"  >    
                   <a  *ngIf="r.path" [routerLink]="[ '/reference/' + reference.referenceId + '/' + r.path ]" [queryParams]="{}" >
                        <span >{{r.title}}</span>
                    </a>                               
                    <!--<a *ngIf="r.path" [routerLink]="[ r.path ]" [queryParams]="{}">{{r.title}}</a>-->
                    <a *ngIf="!r.path">{{r.title}}</a>
                </li>
            </ul>
        </div>
        <div class="col-md-7-8 tab-content">
            <router-outlet></router-outlet>
        </div>
        <div>
            <!--<modal-dialog></modal-dialog>-->
        </div>
    </div>
</div>

<h4 class="indent-left-md" *ngIf="_referenceNotFound">This reference no longer exists in the database</h4>

<show-and-download-pdf [showUVSFile]="false"></show-and-download-pdf>

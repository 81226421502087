import { Entity, EntityManager, EntityQuery, EntityType, FetchStrategy } from 'breeze-client';
import { EntityManagerProvider } from './entity-manager-provider';
import { ErrorLogger } from './error-logger';

export interface IRepository<T extends Entity> {
    withId(key: any): Promise<T>;

    where(predicate: any, orderBy?: string): Promise<T[]>;

    wherePaged(predicate: any, orderBy: string, pageNumber: number, pageSize: number): Promise<T[]>;

    whereWithParams(params: {}): Promise<T[]>;

    whereInCache(predicate: any): T[];

    all(): Promise<T[]>;
}

export class Repository<T extends Entity> implements IRepository<T> {

    protected _defaultFetchStrategy: FetchStrategy;
    _cachedManager: EntityManager;
    protected _errorLogger: ErrorLogger;

    constructor(
        protected _emProvider: EntityManagerProvider,
        protected _entityTypeName: string,
        protected _expand: string,
        protected _resourceName: string,
        protected _isCachedBundle: boolean = false) {

        if (!this._entityTypeName) {
            throw new Error('Repository must be created with an entity name specified');
        }
        this._errorLogger = _emProvider.errorLogger;
        this._defaultFetchStrategy = _isCachedBundle ? FetchStrategy.FromLocalCache : FetchStrategy.FromServer;
    }


    protected get manager(): EntityManager {
        const entityManager = this._emProvider.manager();
        if (entityManager == this._cachedManager) {
            return entityManager;
        }
        this._cachedManager = entityManager;
        const metadataStore = entityManager.metadataStore;

        const entityType = <EntityType>metadataStore.getEntityType(this._entityTypeName);
        if (entityType) {
            entityType.setProperties({defaultResourceName: this.localResourceName});
            metadataStore.setEntityTypeForResourceName(this.localResourceName, entityType);
        } else {
            throw new Error(this._entityTypeName + ' does not exist! Repository must be created for an existing entity type!');
        }

        return entityManager;
    }

    protected get localResourceName() {
        return this._isCachedBundle ? this._entityTypeName : this._resourceName;
    }

    withId(key: any, fromCache: boolean = false): Promise<any> {
        const p = <Promise<any>><any>this.manager.fetchEntityByKey(this._entityTypeName, key, fromCache);
        return p.then(data => {
            if (!data.entity) {
                return null;
            }
            return data.entity;
        });
    }

    // TODO: need to fix Predicate typing to accept json object.
    where(predicate: any, orderBy?: string): Promise<T[]> {
        let query = this.baseQueryWithExpand()
            .where(predicate);
        if (orderBy) {
            query = query.orderBy(orderBy);
        }

        return this.executeQuery(query);
    }

    wherePaged(predicate: any, orderBy: string, pageNumber: number, pageSize: number): Promise<T[]> {
        const query = this.baseQueryWithExpand()
            .where(predicate)
            .orderBy(orderBy)
            .skip((pageNumber - 1) * pageSize)
            .take(pageSize);

        return this.executeQuery(query);
    }

    whereWithParams(queryParameters: {}): Promise<T[]> {
        const query = this.baseQuery()
            .withParameters(queryParameters);

        return this.executeQuery(query);
    }

    whereInCache(predicate: any): T[] {
        const query = this.baseQuery()
            .where(predicate);

        return <T[]>this.executeCacheQuery(query);
    }

    all(): Promise<T[]> {
        const query = this.baseQueryWithExpand();

        return this.executeQuery(query).then(r => {
            if (!this._isCachedBundle || r.length > 0) {
                return r;
            }
            // go back to server if local cache is empty.
            const remoteQuery = query.from(this._resourceName);
            return this.executeQuery(remoteQuery, FetchStrategy.FromServer)
                .then(() => {
                    // repeat the initial query now that the cache is populated.
                    return this.executeQuery(query);
                });
        });

    }

    protected baseQuery(): EntityQuery {
        return EntityQuery.from(this.localResourceName);
    }

    protected baseQueryWithExpand(): EntityQuery {
        let q = this.baseQuery();
        if (this._expand && this._expand.length) {
            q = q.expand(this._expand);
        }
        return q;
    }

    protected executeQuery(query: any, fetchStrategy?: FetchStrategy): Promise<any> {
        const q = query.using(fetchStrategy || this._defaultFetchStrategy);
        const p = <Promise<any>><any>this.manager.executeQuery(q);
        return p.then(data => {
            return data.results;
        }).catch(e => {
            this._errorLogger.log(e);
            throw e;
        });
    }

    protected executeCacheQuery(query: any) {
        return this.manager.executeQueryLocally(query);
    }
}

<div>
    <page-status [pageState]="_pageState"></page-status>
</div>

<div *ngIf="_pageState.isLoaded" style="margin-right: 30px">
    <div class="panel-body">
        <div *ngIf="_summaries && _summaries.length">
            <div *ngFor="let s of _summaries">
                <div class="row publication-margin">
                    <label class="bold col-md-3 publication-title">Expert Panel Summary:</label>
                    <div class="bold col-md-5 publication-title"><a (click)="linkToSummary(s)">{{s.narrativeTitle || s.narrativeLink}}</a></div>
                </div>
            </div>
        </div>

        <div *ngIf="_fmr" class="row publication-margin">
            <label class="bold col-md-3 publication-title">Fragrance Material Review:</label>
            <div class="bold col-md-5 publication-title"><a (click)="linkToFmr()"><span [htmlGreek]="_material.principalSynonym().synonymWordOrWebVersion()"></span></a></div>
        </div>

        <div *ngIf="_hasMonograph" class="row publication-margin">
            <label class="bold col-md-3 publication-title">RIFM Monograph:</label>
            <div class="bold col-md-5 publication-title"><a (click)="linkToMonograph()">{{_material.monographReference}} </a></div>
        </div>
    </div>
</div>
import { Location } from '@angular/common';
import { Component, ViewChild, OnInit,  } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import * as _ from 'lodash';
import { UnitOfWork, StateMap, UserManager, ErrorLogger } from '../../services/common';
import { UtilFns, IEditHost, EditManager } from '../../utils/common';
import {
    Material,
    MaterialConsumption, ReportingOrganization, Survey,
    SurveyReportedUse,
    SurveyUsesByCompanyAndMaterialAndYear,
} from '../../entities/EntityModels';
import { GeographicalAreaAndSurveyYear, SurveyFilters } from '../../entities/projections/SurveyProjections';
import { SelectableEntity } from '../../entities/projections/SelectableEntity';
import { MaterialSelectorComponent } from '../material/material-selector.component';

import { ROUTES } from '../routes';
import { STAFF_ROUTES } from './staff.routes';
import {MaterialReportedUseEditorComponent} from './material-reported-use-editor.component';
import {MaterialWorkflowHistoryComponent} from './material-workflow-history.component';
import {ReportingOrganizationEditorComponent} from './reporting-organization-editor.component';
import { MaterialWorkflowService } from '../../services/material-workflow-service';

export enum MaterialReportedUseEditState {
    None,
    Add = 1,
    Edit,
    Delete,
    AddReportingOrganization,
    EditReportingOrganization
}

export class MaterialReportedUseEditData {
    materialReportedUseEditState: MaterialReportedUseEditState = MaterialReportedUseEditState.None;
    selectedMaterial: Material;
    selectedReportingOrganization: ReportingOrganization;
    selectedSurveyYear: string;
    edm: EditManager<SurveyReportedUse>;
    edmMaterialConsumption: EditManager<MaterialConsumption>;
    edmReportingOrganization: EditManager<ReportingOrganization>;
    reportingOrganizations: ReportingOrganization[];
    surveys: Survey[];
    userName: string;
}

@Component({
    selector: 'material-reported-uses',
    templateUrl: './material-reported-uses.html',
    providers: [MaterialWorkflowService],
})

export class MaterialReportedUsesComponent implements OnInit, IEditHost<SurveyReportedUse> {
    @ViewChild(MaterialReportedUseEditorComponent) _materialReportedUseEditorComponent: MaterialReportedUseEditorComponent;
    @ViewChild(MaterialSelectorComponent, { static: true }) _materialSelectorComponent: MaterialSelectorComponent;
    @ViewChild(MaterialWorkflowHistoryComponent) _materialWorkflowHistoryComponent: MaterialWorkflowHistoryComponent;
    @ViewChild(ReportingOrganizationEditorComponent) _reportingOrganizationEditorComponent: ReportingOrganizationEditorComponent;

    public _data: MaterialReportedUseEditData;

    public _surveyReportedUsesByCompanyAndMaterialAndYear: SurveyUsesByCompanyAndMaterialAndYear[];
    public _selectedSurveyUsesByCompanyAndMaterialAndYear: SurveyUsesByCompanyAndMaterialAndYear;
    public _surveyReportedUsesByCompanyAndMaterialAndYearEx: SelectableEntity<SurveyUsesByCompanyAndMaterialAndYear>[] = [];

    public _unreportedMaterial = false;
    public _geographicalAreaAndSurveyYears: GeographicalAreaAndSurveyYear[];
    public _organizationNames: string[];
    public _surveyYears: string[];

    public _filterOrganizationName: string;
    public _filterSurveyYear: string;

    public _selectedCASDisplay: string;
    private _selectedYears: string[];

    public _canShowWorkflowModal = false;
    public _isLoading = false;
    public _materialWasSelected = false; // used to determine how to refresh the display after saving changes
    public _refreshReportingOrganizations = false;
    public _reportTitle: string;
    public _saveMessage: string;
    public _validationMessage: string;
    public _workflowActivityType = 'reporteduse';

    private colorSelected   = '#ddd';
    private colorUnselected = '#FFFFFF';

    constructor(private _uow: UnitOfWork, private _stateMap: StateMap, private _userManager: UserManager, private _router: Router,
                private _route: ActivatedRoute, public _location: Location, public _errorLogger: ErrorLogger) {
    }

    ngOnInit() {
        this._data = new MaterialReportedUseEditData();

        this._stateMap.currentRouteName = STAFF_ROUTES.MaterialReportedUses.name;

        this._data.edm = new EditManager<SurveyReportedUse>(this, this._uow, this._stateMap, 'Survey Reported Uses');
        this._data.edm.entities = [];
        this._data.edm.editing = false;
        this._data.edm.pageState.canShowMessage = false;

        this._data.edmMaterialConsumption = new EditManager<MaterialConsumption>(this, this._uow, this._stateMap, 'Material Consumptions');
        this._data.edmMaterialConsumption.editing = false;
        this._data.edmMaterialConsumption.pageState.canShowMessage = false;

        this._data.edmReportingOrganization = new EditManager<ReportingOrganization>(this, this._uow, this._stateMap, 'Reporting Organization');
        this._data.edmReportingOrganization.editing = false;
        this._data.edmReportingOrganization.pageState.canShowMessage = false;

        this._data.userName = this._userManager.currentUser.name;

        this.fetchFilterData();
    }

    // #############################################################################
    // load data
    // #############################################################################
    private fetchFilterData() {
        this._isLoading = true;

        this._uow.fetchTyped('MaterialReportedUses/ReportingOrganizations', ReportingOrganization, { }).then(r => {

            this._data.reportingOrganizations = r;

            const names = this._data.reportingOrganizations.map(n => n.organizationName);

            this._organizationNames = _.sortBy(names);
            this._organizationNames.unshift('All');
            this._filterOrganizationName = 'All';

            this._uow.fetchTyped('MaterialReportedUses/SurveysByMinYear', Survey, { minYear: 2015 }).then(s => {

                this._data.surveys = _.sortBy(s, y => y.surveyYear).reverse();

                this._surveyYears = this._data.surveys.map(sy => sy.surveyYear).filter((value, index, self) => self.indexOf(value) === index);

                this._filterSurveyYear = this._surveyYears[0];

                this._isLoading = false;
            });
        });
    }

    private fetchMaterialReportedUses() { // fetch projections for grid display
        let params = {};

        if (this._data.selectedMaterial != null) {
            params = {
                companyName: this._filterOrganizationName,
                year: this._filterSurveyYear,
                materialId: this._data.selectedMaterial.materialId
            };
        } else {
            params = {
                companyName: this._filterOrganizationName,
                year: this._filterSurveyYear
            };
        }

        this._isLoading = true;
        this._reportTitle = 'Loading data...';

        this._uow.fetch('MaterialReportedUses/FetchMaterialSurveyReportedUse', params).then(r => {
            if (r != null && r.length > 0) {
                this._surveyReportedUsesByCompanyAndMaterialAndYear = r;
                this.formatSelectableEntities();
                this._reportTitle = this._surveyReportedUsesByCompanyAndMaterialAndYear.length + ' Matches found';

                this._unreportedMaterial = false;

            } else {
                this._reportTitle = 'No VoU reported data found for the selected filters.';
                this._surveyReportedUsesByCompanyAndMaterialAndYear     = [];
                this._surveyReportedUsesByCompanyAndMaterialAndYearEx   = [];

                this._unreportedMaterial = true;
            }

            this._isLoading = false;
        });
    }

    // #############################################################################
    // format and filter data
    // #############################################################################
    formatSelectableEntities() {
        this._surveyReportedUsesByCompanyAndMaterialAndYearEx = [];

        if (this._surveyReportedUsesByCompanyAndMaterialAndYear == null || this._surveyReportedUsesByCompanyAndMaterialAndYear.length < 1) { return; }

        const data: SelectableEntity<SurveyUsesByCompanyAndMaterialAndYear>[] = [];

        _.clone(this._surveyReportedUsesByCompanyAndMaterialAndYear).forEach(element => {
            data.push(new SelectableEntity<SurveyUsesByCompanyAndMaterialAndYear>(element, '#FFFFFF'));
        });

        this._surveyReportedUsesByCompanyAndMaterialAndYearEx = _.orderBy(data, ['companyName', 'surveyYear', 'realCASNumber', 'rIFMId'], ['asc', 'desc', 'asc', 'asc']);
    }

    filterToMaterialSurveyUseById(id: number): SurveyUsesByCompanyAndMaterialAndYear {
        if (this._surveyReportedUsesByCompanyAndMaterialAndYear == null || this._surveyReportedUsesByCompanyAndMaterialAndYear.length < 1) {
            return null;
        }

        const materialUses = this._surveyReportedUsesByCompanyAndMaterialAndYear.filter(u => u.id == id);
        if (materialUses != null && materialUses.length > 0) {
            return materialUses[0];
        }
    }

    public findReportingOrganizationByName(organizationName: string): ReportingOrganization {
        const orgIdx = this._data.reportingOrganizations.findIndex(o => o.organizationName == organizationName);
        return this._data.reportingOrganizations[orgIdx];
    }

    // #############################################################################
    // interactions
    // ############################################################################
    applyFilter() {
        this.fetchMaterialReportedUses();
    }

    clearSelectedMaterial() {
        this._materialWasSelected           = false;
        this._selectedCASDisplay            = '';
        this._data.selectedMaterial         = null;

        this.resetMaterialWorkflowModal();

        this._reportTitle = '';
        this._surveyReportedUsesByCompanyAndMaterialAndYear     = [];
        this._surveyReportedUsesByCompanyAndMaterialAndYearEx   = [];
        this._unreportedMaterial = false;
    }

    canApplyFilter(): boolean {
        return (this._data.selectedMaterial != null || this._filterOrganizationName != 'All');
    }

    canDeactivate() {
        return (this._data.materialReportedUseEditState == MaterialReportedUseEditState.None && !this._uow.hasChanges());
    }

    canShowNotes() {
        return (this._data.selectedMaterial != null);
    }

    hasEdits() {
        return this._uow.hasChanges();
    }

    get utilFns() {
        return UtilFns;
    }

    onSelectMaterial() {
        UtilFns.showModal(this._materialSelectorComponent, this).then(mli => {
            if (mli == null) {
                return;
            }
            this._isLoading = true;
            return this._uow.materialRepository.withId(mli.materialId);
        }).then(m => {
            if (m) {
                this._materialWasSelected   = true;
                this._selectedCASDisplay    = (m.realCASNumber || '') + ' RIFM Id: ' + m.formattedRIFMId;
                this._data.selectedMaterial = m;

                this.resetMaterialWorkflowModal();

                this._reportTitle = '';
                this._surveyReportedUsesByCompanyAndMaterialAndYear     = [];
                this._surveyReportedUsesByCompanyAndMaterialAndYearEx   = [];
                this._unreportedMaterial = false;   // this is not known until resported uses are queried
            }
            this._isLoading = false;
        });
    }

    onSelectMaterialFromList(msux: SelectableEntity<SurveyUsesByCompanyAndMaterialAndYear>) {

        if (msux == null) { return; }

        this._uow.materialRepository.withId(msux.data.materialId).then(m => {
            if (m) {
                this._materialWasSelected   = true;
                this._selectedCASDisplay    = (m.realCASNumber || '') + ' RIFM Id: ' + m.formattedRIFMId;
                this._data.selectedMaterial = m;

                this._reportTitle = '';
                this._surveyReportedUsesByCompanyAndMaterialAndYear     = [];
                this._surveyReportedUsesByCompanyAndMaterialAndYearEx   = [];
                this._unreportedMaterial = false;   // this is not known until resported uses are queried

                this.fetchMaterialReportedUses();
            }
        });
    }

    onShowNotes() {

        if (this._materialWorkflowHistoryComponent == null || this._data.selectedMaterial == null) {
            return;
        }

        UtilFns.showModal(this._materialWorkflowHistoryComponent, this).then(mrun => {
            this.resetMaterialWorkflowModal();
            return;
        });

    }

    resetMaterialWorkflowModal() {
        this._canShowWorkflowModal = false;

        setTimeout(() => {
            this._canShowWorkflowModal = true;
        }, 1);
    }

    resetBackgroundColor(materialSurveyUses: SelectableEntity<SurveyUsesByCompanyAndMaterialAndYear>[]) {
        materialSurveyUses.forEach((element, index) => {
            element.backgroundColor = this.colorUnselected;
        });
    }

    isValidString(teststring: string) {
        if (!teststring) { return false; }
        return (teststring.trim().length > 0);
    }

    // #############################################################################
    // edit manager overrides
    // #############################################################################
    canAddUnreportedMaterial() {
        return (this._data.selectedMaterial != null && this._unreportedMaterial && !this._uow.hasChanges());
    }

    canAddReportingOrganization() {
        return (this._data.materialReportedUseEditState == MaterialReportedUseEditState.None && !this._uow.hasChanges());
    }

    canEditReportingOrganization() {
        return (this._data.materialReportedUseEditState == MaterialReportedUseEditState.None && !this._uow.hasChanges() && this._filterOrganizationName != 'All');
    }

    canApply() {

        if (this._data.edm.editing) {
            if (this._uow.hasChanges()) {
                return true;
            }

            if (this._materialReportedUseEditorComponent != null && this._materialReportedUseEditorComponent.hasUserNotes()) {
                return true;
            }
        }


        if (this._data.edmReportingOrganization.editing) {
            if (this._uow.hasChanges()) {
                return true;
            }

        }

        return false;
    }

    canCancel() {
        return (this._uow.hasChanges());
    }

    canEdit() {
        return (this._data.materialReportedUseEditState == MaterialReportedUseEditState.Add || this._data.materialReportedUseEditState == MaterialReportedUseEditState.Edit
            || this._data.materialReportedUseEditState == MaterialReportedUseEditState.Delete);
    }

    canSave() {
        if (this._data.edm.editing || this._data.edmReportingOrganization.editing) { return false; }
        if (!this._data.edm.hasEdits()) { return false; }
        if (!this._data.edm.validateBeforeSave()) { return false; }

        return true;
    }

    canShowApply(): boolean {
        return (this._data.edm.editing || this._data.edmReportingOrganization.editing);
    }

    canShowBack() {
        if (!this._data.edm.editing && !this._data.edmReportingOrganization.editing) { return false; }
        return (!this._uow.hasChanges());
    }

    canShowConfirmDelete() {
        return (this._data.materialReportedUseEditState == MaterialReportedUseEditState.Delete);
    }

    canShowEditButtons() {
        return (this._data.materialReportedUseEditState != MaterialReportedUseEditState.None || this._uow.hasChanges());
    }

    canShowEditReportingOrganizationComponent(): boolean {
        // tslint:disable-next-line:max-line-length
        return (this._data.materialReportedUseEditState == MaterialReportedUseEditState.AddReportingOrganization || this._data.materialReportedUseEditState == MaterialReportedUseEditState.EditReportingOrganization);
    }

    canFilterAndView() {
        return (this._data.materialReportedUseEditState ==  MaterialReportedUseEditState.None && this.hasEdits() == false);
    }

    hasUseData() {
        return (this._surveyReportedUsesByCompanyAndMaterialAndYearEx != null && this._surveyReportedUsesByCompanyAndMaterialAndYearEx.length > 0);
    }

    onAdd() {
        if (this._filterOrganizationName != 'All') {
            this._data.selectedReportingOrganization    = this.findReportingOrganizationByName(this._filterOrganizationName);
        }

        this._data.selectedSurveyYear               = this._filterSurveyYear;
        this._data.edm.entities                     = [];

        const mcparams = {
            materialId: this._data.selectedMaterial.materialId,
            surveyYear: this._data.selectedSurveyYear
        };

        // there may be records in the tblMaterialConsumption table for that survey and material as a result of different organization reporting
        this._uow.fetch('MaterialReportedUses/FetchMaterialConsumptionByMaterialIdAndSurveyYear', mcparams).then(m => {
            if (m != null && m.length > 0) {
                this._data.edmMaterialConsumption.entities = m;
            } else {
                this._data.edmMaterialConsumption.entities = [];
            }

            this._data.edm.editing  = true;
            this._data.materialReportedUseEditState = MaterialReportedUseEditState.Add;

            this._isLoading = false;

        });
    }

    onAddReportingOrganization() {
        const ro = this._uow.reportingOrganizationFactory.create();

        this._data.edmReportingOrganization.onlyEntity = ro;
        this._data.edmReportingOrganization.editing    = true;

        this._data.materialReportedUseEditState = MaterialReportedUseEditState.AddReportingOrganization;
    }

    onEditReportingOrganization() {

        this._data.edmReportingOrganization.onlyEntity = this.findReportingOrganizationByName(this._filterOrganizationName);
        this._data.edmReportingOrganization.editing = true;

        this._data.materialReportedUseEditState = MaterialReportedUseEditState.EditReportingOrganization;
    }

    onApply() {
        this._reportTitle = '';
        this._saveMessage = '';
        this._validationMessage = '';

        if (this.canEdit() && this._data.edm.editing) {

            // Validate
            if (!this._materialReportedUseEditorComponent.validateSurveyReportedUseData()) {
                return;
            }

            this._materialReportedUseEditorComponent.processEdits();
            this._data.edm.onApplyCore();

            this._data.materialReportedUseEditState = MaterialReportedUseEditState.None;

            this._saveMessage = 'Save Survey Reported Use Changes for Material: ' + this._data.selectedMaterial.realCASNumber + ', ' + this._data.selectedMaterial.principalSynonymText();

        } else if (this.canShowEditReportingOrganizationComponent() && this._data.edmReportingOrganization.editing) {
            // Validate
            if (!this._reportingOrganizationEditorComponent.validateReportingOrganization()) {
                return;
            }

            // check for similar name
            this._data.edmReportingOrganization.onApplyCore();

            this._reportingOrganizationEditorComponent.recordReportingOrganizationWorkflowAction();

            this._data.materialReportedUseEditState = MaterialReportedUseEditState.None;

            this._refreshReportingOrganizations = true;

            this._saveMessage = 'Save Reporting Organization changes.';
        }
        return true;
    }

    onBack() {
        this._data.materialReportedUseEditState = MaterialReportedUseEditState.None;
        this._data.edm.editing   = false;
    }

    onCancel() {
        this._saveMessage = '';
        this._validationMessage = '';

        // survey reported uses
        this._data.edm.onCancelCore();
        this._data.edm.entities = [];
        this._data.edm.clearErrors();
        this._data.edm.editing = false;

        // material consumption
        this._data.edmMaterialConsumption.onCancelCore();
        this._data.edmMaterialConsumption.entities = [];
        this._data.edmMaterialConsumption.clearErrors();
        this._data.edmMaterialConsumption.editing = false;

        // reporting organizaiton
        this._data.edmReportingOrganization.onCancelCore();
        this._data.edmReportingOrganization.clearErrors();
        this._data.edmReportingOrganization.editing = false;
        this._refreshReportingOrganizations = false;

        this._data.materialReportedUseEditState = MaterialReportedUseEditState.None;

        // this.fetch();
    }

    onConfirmDelete() {
        this._materialReportedUseEditorComponent.processRemoveAll();

        this._data.edm.onApplyCore();
        this.onSave();

        this._reportTitle       = 'Records removed.';
    }

    onSave() {
        this._saveMessage = '';
        this._validationMessage = '';

        if (!this._data.edm.hasEdits()) {
            return false;
        }

        this._isLoading         = true;
        this._canShowWorkflowModal = false;

        this._data.edm.onSaveCore().then(() => {
            this._isLoading = false;

            // clear survey reported uses edm
            this._data.edm.entities = [];
            this._data.edm.clearErrors();
            this._data.edm.editing = false;

            // clear material consumption edm
            this._data.edmMaterialConsumption.entities = [];
            this._data.edmMaterialConsumption.clearErrors();
            this._data.edmMaterialConsumption.editing = false;

            // clear previous search results
            this._reportTitle = '';
            this._surveyReportedUsesByCompanyAndMaterialAndYear     = [];
            this._surveyReportedUsesByCompanyAndMaterialAndYearEx   = [];
            this._unreportedMaterial = false;

            // reset filters
            if (this._materialWasSelected == false) { // must have been a search by organization without selecting for a specific material
                this._data.selectedMaterial = null; // reset filters to state before a row was selected for edit
                this._selectedCASDisplay    = '';
            } else {
                // change request to show all organizations after saving edits for a selected material
                this._filterOrganizationName = 'All';
                this._data.selectedReportingOrganization = null;
            }

            if (this._refreshReportingOrganizations) {
                this._isLoading = true;

                this._uow.fetchTyped('MaterialReportedUses/ReportingOrganizations', ReportingOrganization, { }).then(r => {

                    this._data.reportingOrganizations = r;

                    const names = this._data.reportingOrganizations.map(n => n.organizationName);

                    this._organizationNames = names.sort();
                    this._organizationNames.unshift('All');

                    this._filterOrganizationName = 'All';
                    this._data.selectedReportingOrganization = null;

                    // clear reporting organization edm
                    this._data.edmReportingOrganization.onCancelCore();
                    this._data.edmReportingOrganization.clearErrors();
                    this._data.edmReportingOrganization.editing = false;

                    this._refreshReportingOrganizations = false;

                    if (this.canApplyFilter()) {
                        this.fetchMaterialReportedUses();
                    }

                    this._isLoading = false;

                });
            } else {
                if (this.canApplyFilter()) {
                    this.fetchMaterialReportedUses();
                }
            }

            this._canShowWorkflowModal = true;

        }).catch(e => {
            this._validationMessage = 'Save failed. Please contact support. Error: ' + e.toString();
            this._isLoading = false;
        });

        this._data.materialReportedUseEditState = MaterialReportedUseEditState.None;
    }

    onEdit(msux: SelectableEntity<SurveyUsesByCompanyAndMaterialAndYear>) {
        if (msux == null) { return; }

        if (this._data.selectedMaterial == null || this._data.selectedMaterial.materialId != msux.data.materialId) {
            this._uow.materialRepository.withId(msux.data.materialId).then(m => {
                if (m) {
                    this._selectedCASDisplay    = (m.realCASNumber || '') + ' RIFM Id: ' + m.formattedRIFMId;
                    this._data.selectedMaterial = m;

                    this.editReportedUses(msux);
                }
            });
        } else {
            this.editReportedUses(msux);
        }
    }

    // Both the data for the material in the tblSurveyReportedUses and tblMaterialConsumption table need to be retrieved and kept in sync
    // Two tables store similar information for legacy reasons.
    editReportedUses(msux: SelectableEntity<SurveyUsesByCompanyAndMaterialAndYear>) {

        this._selectedSurveyUsesByCompanyAndMaterialAndYear = this.filterToMaterialSurveyUseById(msux.data.id);
        this._data.selectedReportingOrganization            = this.findReportingOrganizationByName(this._selectedSurveyUsesByCompanyAndMaterialAndYear.organizationName);
        this._data.selectedSurveyYear	                    = this._selectedSurveyUsesByCompanyAndMaterialAndYear.surveyYear;

        const params = {
            materialId: this._data.selectedMaterial.materialId,
            companyName: this._data.selectedReportingOrganization.organizationName,
            surveyYear: this._data.selectedSurveyYear
        };

        this._isLoading = true;

        this._uow.fetch('MaterialReportedUses/FetchSurveyReportedUsesByYearAndMaterialId', params).then(r => {
            if (r != null && r.length > 0) {
                this._data.edm.entities = r;

                const mcparams = {
                    materialId: this._data.selectedMaterial.materialId,
                    surveyYear: this._data.selectedSurveyYear
                };

                this._uow.fetch('MaterialReportedUses/FetchMaterialConsumptionByMaterialIdAndSurveyYear', mcparams).then(m => {
                    if (m != null && m.length > 0) {
                        this._data.edmMaterialConsumption.entities = m;
                    } else {
                        this._data.edmMaterialConsumption.entities = [];
                    }

                    this._isLoading = false;

                    this._data.materialReportedUseEditState = MaterialReportedUseEditState.Edit;
                    this._data.edm.editing = true;
                });
            } else {
                this._data.edm.entities = [];
                this._validationMessage = 'An error occurred retrieving Survey Reported Use data. Please contact support.';
                this._isLoading = false;
                return;
            }
        });
    }

    // remove all survey reported use records for the selected material, company and survey year
    onRemove(msux: SelectableEntity<SurveyUsesByCompanyAndMaterialAndYear>) {
        if (msux == null) { return; }

        if (this._data.selectedMaterial == null || this._data.selectedMaterial.materialId != msux.data.materialId) {
            this._uow.materialRepository.withId(msux.data.materialId).then(m => {
                if (m) {
                    this._selectedCASDisplay    = (m.realCASNumber || '') + ' RIFM Id: ' + m.formattedRIFMId;
                    this._data.selectedMaterial = m;

                    this.removeAll(msux);
                }
            });
        } else {
            this.removeAll(msux);
        }
    }

    removeAll(msux: SelectableEntity<SurveyUsesByCompanyAndMaterialAndYear>) {
        this._selectedSurveyUsesByCompanyAndMaterialAndYear = this.filterToMaterialSurveyUseById(msux.data.id);
        this._data.selectedReportingOrganization            = this.findReportingOrganizationByName(this._selectedSurveyUsesByCompanyAndMaterialAndYear.organizationName);
        this._data.selectedSurveyYear	                    = this._selectedSurveyUsesByCompanyAndMaterialAndYear.surveyYear;

        const params = {
            materialId: this._data.selectedMaterial.materialId,
            companyName: this._data.selectedReportingOrganization.organizationName,
            surveyYear: this._data.selectedSurveyYear
        };

        this._isLoading = true;

        // fetch survey reported uses
        this._uow.fetch('MaterialReportedUses/FetchSurveyReportedUsesByYearAndMaterialId', params).then(r => {
            if (r != null && r.length > 0) {
                this._data.edm.entities = r;

                const mcparams = {
                    materialId: this._data.selectedMaterial.materialId,
                    surveyYear: this._data.selectedSurveyYear
                };

                // fetch material consumption data that should be kept in sync
                this._uow.fetch('MaterialReportedUses/FetchMaterialConsumptionByMaterialIdAndSurveyYear', mcparams).then(m => {
                    if (m == null && m.length == 0) {
                        this._validationMessage = 'An error occurred retrieving Material Consumption data. Please contact support.';
                        this._isLoading = false;
                        return;
                    }
                    this._data.edmMaterialConsumption.entities = m;
                });

                this._isLoading = false;

            } else {
                this._validationMessage = 'An error occurred retrieving Survey Reported Use data. Please contact support.';
                this._isLoading = false;
                return;
            }});

        this._data.materialReportedUseEditState = MaterialReportedUseEditState.Delete;
        this._data.edm.editing = true;
    }

    public resetFiltersAndData() {
        this._filterSurveyYear      = 'All';
        this._selectedYears[0]      = this._data.surveys[0].surveyYear;

        this._data.selectedMaterial     = null;
        this._data.selectedReportingOrganization = null;
        this._data.selectedMaterial     = null;
        this._data.selectedSurveyYear   = '';
        this._data.selectedSurveyYear   = '';
        this._data.edm.entities         = [];
        this._data.edmMaterialConsumption.entities = [];

        this._reportTitle = '';
        this._surveyReportedUsesByCompanyAndMaterialAndYear     = [];
        this._surveyReportedUsesByCompanyAndMaterialAndYearEx   = [];

        this._data.materialReportedUseEditState = MaterialReportedUseEditState.None;

    }
}

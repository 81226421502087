import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as _ from 'lodash';

import { provideParent, StateMap, UnitOfWork, UserManager } from '../../services/common';
import { EditPropParent } from '../../controls/common';
import { UtilFns } from '../../utils/common';
import { Reference, SpecialLink, TypePublication } from '../../entities/EntityModels';
import { ROUTES } from '../routes';
import { REFERENCE_ROUTES } from './reference.routes';

@Component({
    selector: 'reference-details',
    templateUrl: './reference-details.html',
    providers: [provideParent(ReferenceDetailsComponent, EditPropParent)]
})
export class ReferenceDetailsComponent implements EditPropParent, OnInit {
    reference: Reference;

    _typePublications: TypePublication[];
    _groupedLinks: {};

    constructor(public _uow: UnitOfWork, public _stateMap: StateMap, public _router: Router, public _userManager: UserManager) {
    }

    ngOnInit() {
        this.reference = this._stateMap.currentReference;
        this._stateMap.currentRouteName = REFERENCE_ROUTES.ReferenceDetails.name;
        this._uow.typePublicationRepository.all().then(tps => {
            this._typePublications = tps;
        });
        this._uow.projectRepository.all().then(r => {
            // just need them brought in
        });
        this.fetchSpecialLinks(this.reference.referenceId);
    }

    isStaff() {
        return this._stateMap.currentUser.isStaff;
    }

    getAbstract() {
        return this.reference.abstractText.replace(/\n/g, '<br/>');
    }

    canDeactivate() {
        return !this._uow.hasChanges();
    }

    getError(propName: string) {
        return this.reference.getErrorFor(propName);
    }

    isProofed() {
        return this.reference.complete;
    }

    isGovernmentDocument(): boolean {
        return (this.reference.publicationTypeId == 7);
    }

    fetchSpecialLinks(referenceId: number) {
        const p = {referenceId: referenceId};

        return this._uow.specialLinkReferenceRepository.whereWithParams(p).then(r => {
            const groups = _.groupBy(r, x => x.explanation() + ':::' + x.description());

            const groupedLinks = _.keys(groups).map(k => {
                const groupLinks = _.sortBy(groups[k], (sl: SpecialLink) => sl.material.principalSynonym().synonymWordOrWebVersion());
                const parts = k.split(':::');
                return {keyExpl: parts[0], keyDescr: parts[1], specialLinks: groupLinks};
            });
            this._groupedLinks = _.sortBy(groupedLinks, g => g.keyExpl);
        });
    }

    onCasSelect(specialLink: SpecialLink) {
        this._router.navigate(UtilFns.asRouterParts(ROUTES.Material, specialLink.material.materialId));
    }
}

<div class="modal" role="dialog">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                <div class="nav navbar-left navbar-form">
                    <label class="bold input-lg">Reference Relations Type Search</label>
                    <div class="form-group">
                        <input class="form-control" type="text" [(ngModel)]="_searchTerm" (keyup)="search()">
                    </div>
                    <button class="btn btn-primary navbar-btn" type="button" (click)="search()" [disabled]="!canSearch()">Search</button>
                    <button class="btn btn-primary navbar-btn" type="button" (click)="onSelect(null)">Cancel</button>
                </div>
            </div>
            <div class="modal-body">
                <div>
                    <page-status [pageState]="_pageState"></page-status>
                </div>
                <div class="" *ngIf="_entities && _entities.length">
                    <div>Now select a row from below or click 'Cancel'</div>
                </div>
                <div class="" *ngIf="validationMessage">
                    <p class="alert-danger">{{validationMessage}}</p>
                </div>
                <default-table>
                    <thead>
                        <tr>
                            <td>Id</td>
                            <td>Label</td>
                            <td>Description</td>
                            <td>Inverse Id</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let e of _entities" (click)="onSelect(e)">
                            <td>{{e.typeReferenceRelationId}}</td>
                            <td>{{e.label}}</td>
                            <td>{{e.description}}</td>
                            <td>{{e.inverseTypeReferenceRelationId}}</td>
                        </tr>
                    </tbody>
                </default-table>
            </div>
            <div class="modal-footer">
                <button (click)="onSelect(null)" type="button" class="btn btn-primary">Cancel</button>
            </div>
        </div>
    </div>
</div>
import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';

import { UserManager, UnitOfWork, StateMap, SearchService } from '../../services/common';
import { UtilFns, LocationFns } from '../../utils/common';
import { PageState, SortColumn, ColumnSorter } from '../../controls/common';
import { PaginationService, IPaginationInstance } from '../../controls/ng2-pagination/ng2-pagination';

import { ROUTES } from '../routes';
import {Material, AssessmentCategory, RIFMDownloadableFile} from '../../entities/EntityModels';
import {Observable, Subject} from 'rxjs';
import {HttpClient, HttpHeaders} from '@angular/common/http';

import * as _ from 'lodash';

@Component({
    templateUrl: './safety-assessments.html',
    providers: [PaginationService]
})
export class SafetyAssessmentsComponent implements OnInit {

    _materials: Material[];
    _pagination: IPaginationInstance;
    _colSorter: ColumnSorter;
    _countSafetyAssessments = {drafts: 0, reports: 0, matWith: 0};
    _assessmentCategories: AssessmentCategory[] = [];
    _documentManagementUrl: string;
    _isLoading = false;
    _validationMessage: string;

    _pageState = new PageState('Safety Assessments');

    constructor(public _uow: UnitOfWork, public _stateMap: StateMap, public _searchService: SearchService,
                public _userManager: UserManager, public _location: Location, public _router: Router, private _httpClient: HttpClient) {

    }

    ngOnInit() {

        this._pagination = this._pageState.getPaginationInfo();

        // default to publish date descending
        this._colSorter = new ColumnSorter(this, {
                'Publish Date': (m: Material) => (m['assessmentReportDate'] || new Date(0)).toISOString(),
                'CAS No.': (m: Material) => m.realCASNumber,
                'Synonym': (m: Material) => m.principalSynonymText()
            },
            new SortColumn('Publish Date', false),
            (sortColumn) => this._pageState.sortColumn = sortColumn);

        // setTimeout needed because of Angular RC5 bug where Location is not set correctly until
        // after timeout resolves.
        setTimeout(() => {
            const isReturning = LocationFns.updatePageState(this._location, this._stateMap, this._pageState, (state: PageState) => {
                this._colSorter.sortColumn = state.sortColumn;
            });
            this.search(isReturning);
        }, 0);

        this._uow.fetch('Materials/CountSafetyAssessments', {}).then(ar => {
            const r = ar[0];
            this._countSafetyAssessments.drafts = r.drafts;
            this._countSafetyAssessments.reports = r.reports;
            this._countSafetyAssessments.matWith = r.matWith;
        });

        this._uow.assessmentCategoryRepository.all().then(r => {
            for (const ac of r) {
                this._assessmentCategories[ac.assessmentCategoryId] = ac;
            }
        });

        // needed for file downloads
        this._documentManagementUrl = this._stateMap.documentManagementBaseUrl  + '/RIFMDownloadableFile';

    }

    sortWith(colSorter: ColumnSorter) {
        colSorter.sort(this._materials);
    }

    selectSafetyAssessment(m: Material) {
        this.downloadSAFile(m.assessmentReportName);
    }

    selectCAS(m: Material) {
        if (this.isStaff()) {
            this._router.navigate(UtilFns.asRouterParts(ROUTES.Material, m.materialId, ROUTES.Material.childRoutes.SafetyAssessment));
        } else {
            this._router.navigate(UtilFns.asRouterParts(ROUTES.Material, m.materialId));
        }
    }

    search(isReturning: boolean): Promise<Material[]> {
        this._materials = null;
        setTimeout(() => this._pageState.isLoading = true, 0);
        const sortColumn = this._colSorter.sortColumn;

        return this._uow.fetchTyped('fetchSAMaterialsPage', Material, {})
            .then(r => {
                if (!this.isStaff()) {
                    r = r.filter(m => m.assessmentReportName != null && m.assessmentReportName.trim() != '');
                }

                const addlmap = {};
                r.forEach(m => {
                    m['assessmentReportDate'] = m.assessmentReportNameModified && m.assessmentReportNameModified;
                    const addlMaterials = m.safetyAssessmentMaterials.map(sam => sam.additionalMaterial);
                    addlMaterials.forEach(a => addlmap[a.cASNumber] = m.assessmentReportName);
                    m['addlMaterials'] = addlMaterials;
                });

                // filter materials that are listed as additional in the SA, issue #550
                const mats: Material[] = [];
                let materialCount = 0;
                r.forEach(m => {
                    // if this material is not listed as an 'additional material' for the sa, then put it in the list
                    if (!(addlmap[m.cASNumber] == m.assessmentReportName)) {
                        mats.push(m);
                        materialCount++;
                        materialCount += m.safetyAssessmentMaterials.length;
                    }
                });
                this._materials = mats;

                this._pagination.currentPage = this._pageState.pageNumber;
                this._pagination.totalItems = this._pageState.updateEstimate(r.length);
                this._pageState.isLoaded = r;
                if (isReturning) {
                    this._colSorter.sort(this._materials);
                }

                const sa = this._countSafetyAssessments;
                // this._pageState.loadStatusMsg = "There are " + sa.reports + " Safety Assessments covering " + sa.matWith + " materials.";
                this._pageState.loadStatusMsg = 'There are ' + sa.reports + ' Safety Assessments covering ' + materialCount + ' materials.';

                return r;
            });

    }

    isStaff() {
        return this._stateMap.currentUser.isStaff;
    }

    getAssessmentCategories(e: Material) {
        return e.materialAssessmentCategories.map(ac => {
            return this._assessmentCategories[ac.assessmentCategoryId].name;
        }).join(', ');
    }

    // ***********************************************
    // *sa document downloads
    // ***********************************************
    public getDocumentManagementUrl(): Promise<any> {
        return this._uow.fetch('DocumentManagement/DocumentManagementURL', {}).then(dm => {
            return dm[0];
        });
    }

    getDownloadFileURL(fileName: string): Observable<any> {
        let url: string;
        url = this._documentManagementUrl + '/GetFileBySubFolderAndName?fileName=' + fileName + '&subFolder=' + 'SafetyAssessments' ;

        return this._httpClient.get<any>(url, { responseType: 'blob' as 'json'});
    }

    public downloadSAFile(fileName: string) {
        this._isLoading = true;
        this._validationMessage = '';
        this.getDownloadFileURL(fileName)
            .subscribe(
                (response: Blob) => {
                    const data = response;

                    const file = new Blob([data], { type: 'application/pdf' });
                    const fileURL = URL.createObjectURL(file);

                    // window.open(fileURL);
                    const a         = document.createElement('a');
                    a.href        = fileURL;
                    a.target      = '_blank';
                    a.download    = fileName;
                    document.body.appendChild(a);
                    a.click();

                    this._validationMessage = '';

                },
                (error) => {
                    console.error('Request failed with error: ' + error.message);
                    this._validationMessage = error.message;
                    this._isLoading = false;
                },
                () => {
                    this._isLoading = false;
                });
    }
}

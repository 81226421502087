import {Component, ElementRef, Input} from '@angular/core';
import {UnitOfWork} from '../../services/common';
import {UtilFns} from '../../utils/common';

import {ModalDialog} from '../../controls/common';

import {Material} from '../../entities/EntityModels';

import {MaterialIdentifiersItem} from '../../entities/projections/MaterialIdentifiersItem';
import {ProtocolExperimentalMaterialItem} from '../../entities/projections/ProtocolExperimentalMaterialItem';
import {SelectableEntity} from '../../entities/projections/SelectableEntity';

import * as _ from 'lodash';

@Component({
    selector: 'protocol-material-selector',
    templateUrl: './protocol-material-selector.html',
})
export class ProtocolMaterialSelectorComponent extends ModalDialog<boolean> {

    _protocolExperimentalMaterialItems: ProtocolExperimentalMaterialItem[] = [];
    _protocolExperimentalMaterialsEx: SelectableEntity<ProtocolExperimentalMaterialItem>[] = [];

    _expMaterialIdsForSortOrder: number[] = [];

    _isLoading = false;

    _biologicalDataId: number;
    _subReference: string;

    _selectedMaterialIdentifiersItems: MaterialIdentifiersItem[] = [];
    _userNotifications = '';

    constructor(elementRef: ElementRef, public _uow: UnitOfWork) {
        super(elementRef);
    }

    get utilFns() {
        return UtilFns;
    }

    captiveLabel(confidential: boolean): string {
        return (confidential) ? 'captive' : '';
    }

    // ***************************************************************************************************
    // Close Modal
    // ***************************************************************************************************
    onCancel() {
        UtilFns.returnModal(this, false);
    }

    onSave() {
        UtilFns.returnModal(this, true);
    }

    canSave(): boolean {

        if (this._isLoading == true) {
            return false;
        }

        return (this._selectedMaterialIdentifiersItems.length > 0);
    }

    public get SelectedMaterials(): MaterialIdentifiersItem[] {
        return this._selectedMaterialIdentifiersItems;
    }

    // ***************************************************************************************************
    // Interactions
    // ***************************************************************************************************
    onSelectExperimentalMaterial(event: any, mex: SelectableEntity<ProtocolExperimentalMaterialItem>) {
        const currentSelectedMaterials = _.clone(this._selectedMaterialIdentifiersItems);

        const selected    = event.target.checked;
        const idx         = currentSelectedMaterials.findIndex(s => s.materialId == mex.data.materialId);

        if (idx < 0) {
            if (selected) {
                const item = this.convertMaterialToMaterialIdentifiersItem(mex);
                this._selectedMaterialIdentifiersItems.push(item);
            }
        } else {
            if (selected == false) {
                this._selectedMaterialIdentifiersItems.splice(idx, 1);
            }
        }

        if (this._selectedMaterialIdentifiersItems.length > 0) {
            this._userNotifications = 'There are ' + this._selectedMaterialIdentifiersItems.length + ' Materials selected.';
        } else {
            this._userNotifications = '';
        }
    }

    onUnselectAll() {
        if (this._protocolExperimentalMaterialsEx != null && this._protocolExperimentalMaterialsEx.length > 0) {
            this._protocolExperimentalMaterialsEx.map(function(s) {
                s.selected = false;
                return s;
            });
            this._selectedMaterialIdentifiersItems = [];
            this._userNotifications = '';
        }
    }

    // ***************************************************************************************************
    // Load Data
    // ***************************************************************************************************
    loadExperimentalMaterials(biologicalDataId: number, subReference: string, excludedMaterialIds: number[], expMaterialIdsForSortOrder: number[]) {
        this._isLoading = true;
        this._userNotifications = '';

        this._biologicalDataId              = biologicalDataId;
        this._expMaterialIdsForSortOrder    = expMaterialIdsForSortOrder;
        this._subReference                  = subReference;

        this._protocolExperimentalMaterialItems = [];

        const params = { biologicalDataId: biologicalDataId, excludedMaterialIds: excludedMaterialIds };
        this._uow.fetch('BiologicalDatas/GetExperimentalMaterialsByBiologicalDataId', params).then(em => {
            if (em != null && em.length > 0) {
                this._protocolExperimentalMaterialItems = _.sortBy(em, e => e.realCASNumber);
                this.formatSelectableEntities();
            } else {
                this._userNotifications = 'No other Experimental Materials found that are assigned to this Protocol.';
            }
            this._isLoading = false;
        });
    }

    formatSelectableEntities() {
        this._protocolExperimentalMaterialsEx = [];
        if (this._protocolExperimentalMaterialItems == null || this._protocolExperimentalMaterialItems.length < 1) { return; }

        const data: SelectableEntity<ProtocolExperimentalMaterialItem>[] = [];

        _.clone(this._protocolExperimentalMaterialItems).forEach(element => {

            // workaround to establish sort order from order retrieved via navQuery in the protocol editor component.
            const idx     = this._expMaterialIdsForSortOrder.indexOf(element.materialId);
            element.sortOrder = idx;

            const pem = new SelectableEntity<ProtocolExperimentalMaterialItem>(element, '#FFFFFF');
            if (this.isSelected(element.materialId)) { // maintain previously selected items
                pem.selected = true;
            }
            data.push(pem);
        });

        this._protocolExperimentalMaterialsEx = _.sortBy(data, s => s.data.sortOrder);
    }

    // convert Material to MaterialListItem
    convertMaterialToMaterialIdentifiersItem(mex: SelectableEntity<ProtocolExperimentalMaterialItem>): MaterialIdentifiersItem {
        return {
            materialId:                 mex.data.materialId,
            realCASNumber:              mex.data.realCASNumber,
            fEMANumber:                 mex.data.fEMANumber,
            monographNumber:            mex.data.monographNumber,
            confidential:               mex.data.confidential,
            synonymWordOrWebVersion:    mex.data.synonymWordOrWebVersion,
            typeEssentialOilId:         mex.data.typeEssentialOilId,
            typeBotanicalSubdivisionId: mex.data.typeBotanicalSubdivisionId,
            formattedRifmId:            mex.data.formattedRIFMId,
            experimentalMaterialId:     mex.data.experimentalMaterialId,
            sortOrder:                  mex.data.sortOrder
        };
    }

    getselectedMaterialIdentifiersItems() {
        return _.sortBy(this._selectedMaterialIdentifiersItems, pem => pem.sortOrder);
    }

    isSelected(materialId: number) {
        if (this._selectedMaterialIdentifiersItems.some(m => m.materialId == materialId)) {
            return true;
        }
        return false;
    }

    removeFromSelectedList(materialId: number) {
        if (this._selectedMaterialIdentifiersItems == null || this._selectedMaterialIdentifiersItems.length == 0) {
            return;
        }

        const items = _.clone(this._selectedMaterialIdentifiersItems);
        const idx = items.findIndex(m => m.materialId == materialId);

        this._selectedMaterialIdentifiersItems.splice(idx, 1);
     }
}

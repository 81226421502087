import { Component, forwardRef, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as _ from 'lodash';

import { provideParent, ReferenceState, StateMap, UnitOfWork, UserManager } from '../../services/common';
import { AnalyticalResult, Material, Reference } from '../../entities/EntityModels';
import { UtilFns } from '../../utils/common';
import { EditPropParent } from '../../controls/common';
import { ReferenceEditorComponent } from './reference-editor.component';
import { ROUTES } from '../routes';


@Component({
    selector: 'reference-analytical-results',
    templateUrl: './reference-analytical-results.html',
    providers: [provideParent(ReferenceAnalyticalResultsComponent, EditPropParent)]
})
export class ReferenceAnalyticalResultsComponent implements OnInit, EditPropParent {


    private _linkedAnalyticalResults: AnalyticalResult[];
    _data: {
        entity: Reference,
        inSubEditor: boolean,
        onCancelMap: {},
    };

    constructor(@Inject(forwardRef(() => ReferenceEditorComponent)) public _parent: ReferenceEditorComponent,
                private _uow: UnitOfWork, private _stateMap: StateMap, private _userManager: UserManager, private _referenceState: ReferenceState, public _router: Router) {
    }

    ngOnInit() {
        this._data = this._parent.data;
        this._data.onCancelMap['special'] = this.onCancel.bind(this);

        if (this.reference.analyticalResults != null && this.reference.analyticalResults.length > 0) {
            // material.... was fetched in Reference.GetForEdit on the reference-editor.component.
            this._linkedAnalyticalResults = _.sortBy(this.reference.analyticalResults, a => <string> _.get(a, 'material.principalSynonym().synonymWordOrWebVersion()', ''));
        }
    }

    public get reference() {
        return this._data.entity;
    }


    get linkedAnalyticalResults() {
        return this._linkedAnalyticalResults;
    }

    get utilFns() {
        return UtilFns;
    }

    isStaff() {
        return this._stateMap.currentUser.isStaff;
    }

    downloadReport(fileName: string) {
        const url = this._userManager.getUVUrl(fileName.trim());
        window.open(url, '_self');
        return false;
    }

    getError(propName: string) {
        return this._parent.data.entity.getErrorFor(propName);
    }

    goToMaterialAnalyticalResults(mat: Material) {
        this._router.navigate(UtilFns.asRouterParts(ROUTES.Material, mat.materialId, ROUTES.Material.childRoutes.AnalyticalResults));
    }

    onCancel() {
    }

    // Workflow related
    public hasCheckedSpecialLinks() {
        return (this._referenceState.hasCheckedAnalyticalResults(this._parent.data.entity));
    }

    public hasUncheckedSpecialLinks() {
        return (this._referenceState.hasUncheckedAnalyticalResults(this._parent.data.entity));
    }

    public onLinkedAnalyticalResultsChecked() {
        this._referenceState.recordAnalyticalResultsChecked(this._parent.data.entity);
    }
}

import { Component, Input, OnInit, ViewChild } from '@angular/core';
import * as _ from 'lodash';

import { provideParent, UnitOfWork } from '../../services/common';
import { EditPropParent } from '../../controls/common';
import { UtilFns } from '../../utils/common';

import {
    BiologicalData,
    TypeComplianceGuideline,
    TypeRoute,
    TypeSpecies,
    TypeStudy,
    TypeStudyDesignation,
    TypeTimeUnit,
    TypeUsefulness
} from '../../entities/EntityModels';
import { ReferenceListItem } from '../../entities/projections/ReferenceListItem';
import { ReferenceSelectorComponent } from '../reference/reference-selector.component';
import { ComplianceSelectorComponent } from './compliance-selector.component';

@Component({
    selector: 'protocol-editor-biodata',
    templateUrl: './protocol-editor-biodata.html',
    providers: [provideParent(ProtocolEditorBiodataComponent, EditPropParent)]
})
export class ProtocolEditorBiodataComponent implements OnInit, EditPropParent {
    @ViewChild(ReferenceSelectorComponent, { static: true }) _referenceSelectorComponent: ReferenceSelectorComponent;
    @ViewChild(ComplianceSelectorComponent, { static: true }) _complianceSelectorComponent: ComplianceSelectorComponent;

    @Input() entity: BiologicalData;

    _typeStudies: TypeStudy[];
    _typeSpecies: TypeSpecies[];
    _typeRoutes: TypeRoute[];
    _typeTimeUnits: TypeTimeUnit[];
    _typeUsefulnesses: TypeUsefulness[];
    _typeStudyDesignations: TypeStudyDesignation[] = [];


    constructor(public _uow: UnitOfWork) {

    }

    ngOnInit() {
        // HACK: setTimeout is only needed in IE11 and Edge
        // reason seems to be that synchronous promises do not take a tick in IE and Edge
        // so this fix is needed to avoid 'Expression has changed after it was checked message.
        setTimeout(() => {
            this._uow.typeStudyDesignationRepository.all()
                .then(r => {
                this._typeStudyDesignations = r;
                this._uow.typeStudyRepository.all().then(x => this._typeStudies = _.sortBy(x, y => y.studyLong));
                this._uow.typeSpeciesRepository.all().then(x => this._typeSpecies = _.sortBy(x, y => y.speciesLong));
            });
            this._uow.typeRouteRepository.all().then(r => this._typeRoutes = _.sortBy(r, x => x.routeLong));
            this._uow.typeTimeUnitRepository.all().then(r => this._typeTimeUnits = _.sortBy(r, x => x.typeTimeUnitId));
            this._uow.typeUsefulnessRepository.all().then(r => this._typeUsefulnesses = _.sortBy(r, x => x.description));

        }, 0);

    }

    getError(propName: string) {
        return this.entity.getErrorFor(propName);
    }

    updateStudy(typeStudyId: string) {
        this.updateHHorEnv(typeStudyId, this.entity.typeSpeciesId);
        return typeStudyId;
    }

    updateSpecies(typeSpeciesId: string) {
        this.updateHHorEnv(this.entity.typeStudyId, typeSpeciesId);
        return typeSpeciesId;
    }

    updateHHorEnv(typeStudyId: string, typeSpeciesId: string) {
        const tsd = _.find(this._typeStudyDesignations, x =>
            (x.typeStudyId == typeStudyId || x.typeStudyId == null) && (x.typeSpeciesId == typeSpeciesId || x.typeSpeciesId == null));

        const designation = tsd ? tsd.designation : '';
        this.entity.humanHealthData = (designation == 'HH');
        this.entity.environmentalData = (designation == 'Env');
    }

    onAddBriefRef() {
        UtilFns.showModal(this._referenceSelectorComponent, this, this.validateReference)
            .then(rli => {
                if (rli == null) {
                    return;
                }
                const briefRefs = this.entity.getMethodologyBriefRefs();
                briefRefs.push(rli.briefReference);
                this.refreshBriefRefs(briefRefs);
            });

    }

    onDeleteBriefRef(briefRef: string) {
        const briefRefs = this.entity.getMethodologyBriefRefs();
        _.remove(briefRefs, (br) => br == briefRef);
        this.refreshBriefRefs(briefRefs);

    }

    validateReference(rli: ReferenceListItem): string {
        return null;
        // TODO: might want to check for dups here - but not critical
        // if (alreadyExists) {
        //     return  "Reference: " + rli.referenceId + " has already been referenced."
        // }
    }

    refreshBriefRefs(briefRefs: string[]) {
        this.entity.methodologyReference = briefRefs.join(';');
    }

    onSelectCompliance() {
        UtilFns.showModal(this._complianceSelectorComponent, this).then((r: TypeComplianceGuideline) => {
            if (r) {
                this.entity.compliance = r.guideline + ': ' + r.explanation;
            }
        });
    }
}

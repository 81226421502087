import { Component, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { EntityState } from 'breeze-client';
import * as _ from 'lodash';

import { EditorService, ErrorLogger, MolImageService, provideParent, StateMap, UnitOfWork } from '../../services/common';
import { EditManager, LocationFns, UtilFns } from '../../utils/common';
import { EditPropParent, PageState, } from '../../controls/common';

import { FSAGSelectorComponent } from './fsag-selector.component';
import { MaterialSelectorComponent } from '../material/material-selector.component';

import { FSAG, MaterialFSAG } from '../../entities/EntityModels';
import { MaterialListItem } from '../../entities/projections/MaterialListItem';

import { STAFF_ROUTES } from './staff.routes';
import * as pluralize from 'pluralize';

class PageStateExt extends PageState {
    sAGID: number;
}

@Component({
    selector: 'fsag-editor',
    templateUrl: './fsag-editor.html',
    providers: [provideParent(FSAGEditorComponent, EditPropParent)]
})
export class FSAGEditorComponent implements OnInit, EditPropParent {
    @ViewChild(FSAGSelectorComponent) _fsagSelectorComponent: FSAGSelectorComponent;
    @ViewChild(MaterialSelectorComponent, { static: true }) _materialSelectorComponent: MaterialSelectorComponent;

    _FSAGs: FSAG[];
    _maxId: number;
    _materialFSAGs: MaterialFSAG[];
    _edm: EditManager<FSAG>;
    _adding: boolean;
    // this is not the same pageState that the _edm is using.
    _pageState = new PageStateExt('FSAG editor');


    constructor(public _uow: UnitOfWork, public _stateMap: StateMap, public _editorService: EditorService,
                public _molImageService: MolImageService, public _location: Location,
                public _errorLogger: ErrorLogger) {

    }

    ngOnInit() {
        this._stateMap.currentRouteName = STAFF_ROUTES.FSAG.name;
        this._edm = this._editorService.createEditManager<FSAG>(this, 'FSAG');
        this._edm.pageState.canShowMessage = false; // no page state messages.
        this._maxId = 0;
        this._pageState.isLoading = true;
        this._uow.fSAGRepository.all().then(r => {
            r.forEach(f => {
                this._maxId = Math.max(this._maxId, f.sAGID);
            });
            this._FSAGs = r;
            this.sortFSAGs();
            this._pageState.isLoaded = true;

            const returned = LocationFns.updatePageState(this._location, this._stateMap, this._pageState, (state: PageStateExt) => {
                this.fetchFSAG(state.sAGID);
            });

            if (!returned) {
                this._edm.editing = false;
            }
        });


    }

    canDeactivate() {
        return !this._edm.hasEdits();
    }

    get entity() {
        return this._edm.currentEntity;
    }

    // Not really needed but in case this editor is ever used by non-staff.
    isStaff() {
        return this._stateMap.currentUser.isStaff;
    }

    getDescription(sAGID: number) {
        const fsag = this.findFSAG(sAGID);
        return fsag ? fsag.note : '';
    }

    findFSAG(sAGID: number) {
        if (sAGID == null) {
            return null;
        }
        return this._FSAGs.find(fsag => fsag.sAGID == sAGID);
    }

    onNoteChange() {
        const parts = this.entity.note.split('/');
        this.entity.sAG = parts[parts.length - 1];
    }

    onSelectParent() {
        UtilFns.showModal(this._fsagSelectorComponent, this, this.validateParent).then((fsag: FSAG) => {
            if (!fsag) {
                return;
            }
            const prevParent = this.findFSAG(this.entity.parent);
            this.entity.parent = fsag.sAGID;
            if (fsag.child == null) {
                fsag.child = this.entity.sAGID;
            }
            if (prevParent.child == this.entity.sAGID) {
                prevParent.child = null;
            }

        });
    }

    validateParent(fsag: FSAG): string {
        if (this.entity.sAGID == fsag.sAGID) {
            return `FSAG cannot have itself as a parent`;
        }
        if (this.entity.child == fsag.parent) {
            return `'Cannot select this FSAG's child as a parent`;
        }
    }

    onEdit() {
        UtilFns.showModal(this._fsagSelectorComponent, this, null).then((fsag: FSAG) => {
            if (!fsag) {
                return;
            }
            this.editFSAG(fsag);

        });
    }

    onAttachMaterial() {
        UtilFns.showModal(this._materialSelectorComponent, this, this.validateMaterial).then(mli => {
            if (mli == null) {
                return;
            }
            const config = {
                materialId: mli.materialId,
                sAGID: this.entity.sAGID
            };

            const fsag = this._uow.materialFSAGFactory.create(config);
            // fetch the material so that it can appear in table
            this._uow.materialRepository.withId(mli.materialId).then(m => {
                if (this.entity.entityAspect.entityState.isUnchanged()) {
                    this.entity.entityAspect.setEntityState(EntityState.Modified);
                }
                this._materialFSAGs.push(fsag);
            });

        });
    }

    onDetachMaterial(mf: MaterialFSAG) {
        if (mf.entityAspect.entityState.isAdded()) {
            mf.entityAspect.rejectChanges();
        } else {
            this.entity.entityAspect.setEntityState(EntityState.Modified);
            mf.entityAspect.setDeleted();
        }
        // this._materialFSAGs = this._materialFSAGs.filter(item => item != mf);
        _.remove(this._materialFSAGs, item => item == mf);
    }

    validateMaterial(mli: MaterialListItem): string {
        if (this._materialFSAGs.some(m => m.materialId == mli.materialId)) {
            return `Material: ${mli.realCASNumber} has already been referenced`;
        }
    }

    fetchFSAG(sAGID: number) {
        const fsag = this._FSAGs.find(f => f.sAGID == sAGID);
        if (fsag) {
            this.editFSAG(fsag);
        }
    }


    editFSAG(fsag: FSAG) {
        if (fsag == null) {
            return;
        }
        this._adding = false;
        this._edm.onlyEntity = fsag;
        this._pageState.sAGID = fsag.sAGID;
        this._edm.editing = true;
        this._materialFSAGs = null;
        const p = { materialId: <number>null, sagId: fsag.sAGID };
        this._uow.materialFSAGRepository.whereWithParams(p).then(r => {
            this._materialFSAGs = r;
        });
        return fsag;
    }

    createFSAG() {
        this._maxId = this._maxId + 1;
        const p = {
            sAGID: this._maxId
        };
        const fsag = this._edm.uow.fSAGFactory.create(p);
        return fsag;
    }

    getMaterialsCountText() {
        const l = this._materialFSAGs.length;
        return `${l} ${pluralize('material', l)} found`;
    }

    getError(propName: string) {
        const r = this.entity.getErrorFor(propName);
        return r;
    }

    getMaterialImageUrl(m: MaterialFSAG) {
        return this._molImageService.getImageUrlForMaterial(m.material.materialId);
    }


    // EditManager overrides

    onAdd() {
        this._adding = true;
        const e = this.createFSAG();
        this._edm.onlyEntity = e;
        this._edm.editing = true;
        this._materialFSAGs = [];

    }

    onDelete() {
        return this._stateMap.yesNoComponent.showModal('Delete entire FSAG',
            'Are you sure?').then(ok => {
            if (ok) {
                this.deleteFSAG();
            }
        });
    }

    onCancel() {
        const wasAdding = this.entity.entityAspect.entityState.isAdded();
        this._uow.rollback();
        if (wasAdding) {
            this._edm.editing = false;
        } else {
            this.editFSAG(this.entity);
        }

    }

    canSave() {
        if (!this._edm.hasEdits()) {
            return false;
        }
        // this._edm.canSaveCore does not include next line.
        if (!this._edm.validateBeforeSave()) {
            return false;
        }
        return true;
    }

    onSave() {
        const wasAdded = this.entity.entityAspect.entityState.isAdded();
        this._edm.onSaveCore().then(() => {
            this._pageState.sAGID = this._edm.currentEntity.sAGID;
            if (wasAdded) {
                this._FSAGs.push(this.entity);
                this._fsagSelectorComponent.reset();
                this.sortFSAGs();
            }
        });
    }

    sortFSAGs() {
        UtilFns.sort(this._FSAGs, true, f => (f.note || '').toLowerCase());
    }

    deleteFSAG() {
        if (this.entity.entityAspect.entityState.isAdded()) {
            this._uow.rollback();
            this._edm.editing = false;
            return;
        }
        _.remove(this._FSAGs, this.entity);
        this._fsagSelectorComponent.reset();
        this._materialFSAGs.forEach(mf => {
            if (mf.entityAspect.entityState.isAdded()) {
                mf.entityAspect.rejectChanges();
            } else {
                mf.entityAspect.setDeleted();
            }
        });
        this._edm.onDeleteCore();
        return this._uow.commit().then(() => {
            this._edm.setSavedStatus('Deleted');
            // Next line is needed because the onDeleteCore above will have modified all
            // entities in cache that ref'd this material.
            this._uow.rollback();
            this._edm.editing = false;
        }).catch((e) => {
            this._edm.setSaveFailedStatus('Delete failed: ' + e);
        });

    }

}

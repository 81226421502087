<default-table *ngIf="_entities">
    <thead>
        <tr>
            <td>Reference No.</td>
            <td>Brief Reference</td>
            <td>Title</td>
            <td>Relation Type</td>
            <!--removed per https://github.com/rifm/RIFMWeb/issues/279-->
            <!--<td>Year of Publication</td>
            <td>Abstract Only</td>
            <td>Priority</td>
            <td>Linkable</td>-->
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let e of _entities" >
            <td><a (click)="navToRef(e.relatedReference)">{{e.relatedReference?.referenceId}}</a></td>
            <td>{{ e.relatedReference?.briefRef(isStaff()) }}</td>
            <td>{{e.relatedReference?.title}}</td>
            <td>{{e.typeReferenceRelation?.label}}</td>
            <!--<td>{{e.relatedReference?.yearOfPublication}}</td>
            <td>
                <input type="checkbox" disabled [checked]="e.relatedReference?.abstractOnly">
            </td>
            <td>{{e.relatedReference?.precedence}}</td>
            <td>
                <input type="checkbox" disabled [checked]="e.relatedReference?.linkable">
            </td>-->
        </tr>
    </tbody>
</default-table>

<div>
    <span *ngIf="_edm.editing" class="inline">
        <span class="inline" style="font-size: 18px;">{{_adding ? "Adding an External Material" : "Editing an Exposure Material" }}</span>
        <img style="margin-left: 10px; height: 36px; width: 36px;" class="inline" src="assets/images/RIFMFragranceBottle.png" />
    </span>
    <span *ngIf="(!_edm.editing)">
        <button type="button" class="inline btn btn-survey-external-material" style="width: 170px;" (click)="onAdd()">Add External Material</button>
    </span>

    <editor-header [edm]="_edm" [customButtons]="true">
        <span customButtons class="inline" style="margin-left: 10px; margin-top: 6px;">
            <button *ngIf="!_edm.editing" type="button" class="btn btn-sm btn-primary" [disabled]="!canSave()" (click)="onSave()">Save</button>
            <button *ngIf="_edm.editing" type="button" class="btn btn-sm btn-primary" [disabled]="!canApply()" (click)="onApply()">Apply</button>
            <button *ngIf="canShowBack()" type="button" class="btn btn-sm btn-primary" (click)="onBack()">Back</button>
            <button *ngIf="canCancel()" type="button" class="btn btn-sm btn-primary" (click)="onCancel()">Cancel</button>
        </span>
    </editor-header>
</div>
<div class="" *ngIf="_validationMessage">
    <p class="alert-danger">{{_validationMessage}}</p>
</div>
<br />
<div class="exposure-survey-container" [attr.data-hidden]="_edm.editing">
    <div *ngIf="_exposureAdditionalMaterialsEx && _exposureAdditionalMaterialsEx.length > 0" style="border-bottom: 1px solid #424242; width: 1121px;">
        <div>
            <div class="scrollableTableHeader">
                <div class="scrollableTableColumn text-center"><span class="inline" style="width: 55px">Edit</span></div>
                <div class="scrollableTableColumn text-center"><span class="inline" style="width: 75px">Remove</span></div>
                <div class="scrollableTableColumn text-center"><span class="inline" style="width: 90px">CAS No.</span></div>
                <div class="scrollableTableColumn text-center"><span class="inline" style="width: 85px">MaterialId</span></div>
                <div class="scrollableTableColumn text-left"><span class="inline" style="width: 260px">Material Name</span></div>
                <div class="scrollableTableColumn text-center"><span class="inline" style="width: 160px">Member Company</span></div>
                <div class="scrollableTableColumn text-left"><span class="inline" style="width: 240px">Notes</span></div>
            </div>
            <div style="max-height: 240px; overflow-y:auto;overflow-x: hidden;">
                <div class="scrollableTableRow" *ngFor="let am of _exposureAdditionalMaterialsEx"
                     [ngStyle]="{ 'background-color': am.backgroundColor }">
                    <div class="scrollableTableColumn text-center"><a class="inline" style="width: 55px" (click)="onEdit(am)">Edit</a></div>
                    <div class="scrollableTableColumn text-center"><a class="inline" style="width: 75px" (click)="onRemove(am)">Remove</a></div>
                    <div class="scrollableTableColumn text-center"><span class="inline" style="width: 90px">{{am.data.cASNumber}}</span></div>
                    <div class="scrollableTableColumn text-center"><span class="inline" style="width: 85px">{{am.data.exposureAdditionalMaterialId}}</span></div>
                    <div class="scrollableTableColumn text-left"><span class="inline" style="width: 260px">{{am.data.materialName}}</span></div>
                    <div class="scrollableTableColumn text-center"><span class="inline" style="width: 160px">{{am.data.memberCompany}}</span></div>
                    <div class="scrollableTableColumn text-left"><span class="inline" style="width: 240px">{{am.data.notes}}</span></div>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="_edm.editing">
    <exposure-additional-material-editor #self [entity]="_edm.currentEntity"></exposure-additional-material-editor>
</div>

import { Component, forwardRef, Inject, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import * as _ from 'lodash';

import { provideParent, StateMap, UnitOfWork } from '../../services/common';
import { LocationFns, UtilFns } from '../../utils/common';
import { EditPropParent } from '../../controls/common';
import { Author, Reference, ReferenceAuthor } from '../../entities/EntityModels';
import { ReferenceEditorComponent } from './reference-editor.component';
import { AuthorSelectorComponent } from '../reference/author-selector.component';
import { AuthorEditorComponent } from './author-editor.component';

@Component({
    selector: 'reference-editor-author',
    templateUrl: './reference-editor-author.html',
    providers: [provideParent(ReferenceEditorAuthorComponent, EditPropParent)]
})
export class ReferenceEditorAuthorComponent implements OnInit, EditPropParent {
    @ViewChild(AuthorSelectorComponent, { static: true }) _authorSelectorComponent: AuthorSelectorComponent;
    @ViewChild(AuthorEditorComponent) _authorEditorComponent: AuthorEditorComponent;

    _data: {
        entity: Reference,
        adding: boolean,
        inSubEditor: boolean,

        editAuthor?: Author;

    };
    _etAlEnabled: boolean;
    _newEtAlAuthor: Author;
    _etAlRefAuthor: ReferenceAuthor;

    constructor(@Inject(forwardRef(() => ReferenceEditorComponent)) public _parent: ReferenceEditorComponent,
                public _uow: UnitOfWork, public _stateMap: StateMap, public _location: Location) {

        LocationFns.setTab(this._location, 1);
    }

    ngOnInit() {
        this._data = this._parent.data;
        this._etAlRefAuthor = this.getEtAlRefAuthor();
        this._etAlEnabled = !!this._etAlRefAuthor;
        if (!this._etAlEnabled) {
            this._uow.fetchTyped('Misc/AuthorsByName', Author, { name: 'et al.' }).then(r => {
                if (r.length > 0) {
                    this._newEtAlAuthor = r[0];
                    this._etAlEnabled = true;
                }

            });
        }
    }

    getEtAlRefAuthor() {
        return this._entity.referenceAuthors.find(ra => ra.author && ra.author.authorName === 'et al.');
    }

    get _entity() {
        return this._data.entity;
    }

    referenceAuthors() {
        if (this._entity) {
            return _.sortBy(this._entity.referenceAuthors, a => a.counter);
        } else {
            return [];
        }
    }

    getError(propName: string) {
        return this._data.entity.getErrorFor(propName);
    }

    onEtAlChange(event: any) {
        const etAlRefAuthor = this.getEtAlRefAuthor();
        if (etAlRefAuthor) {
            this.onDetachAuthor(etAlRefAuthor);
        } else {
            // this._etAlRefAuthor may already be deleted to we need to throw away the deletion
            // instead of creating a new ReferenceAuthor.
            if (this._etAlRefAuthor && !this._etAlRefAuthor.entityAspect.entityState.isDetached()) {
                this._etAlRefAuthor.entityAspect.rejectChanges();
                const maxCounter = _.max(this._entity.referenceAuthors.map(ra => ra.counter)) || 0;
                this._etAlRefAuthor.counter = maxCounter;
            } else {
                // force cast below is deliberate because we only need 3 fields above.
                this._etAlRefAuthor = this.addReferenceAuthorFor(this._newEtAlAuthor);
            }
        }
    }

    onAttachAuthor() {
        UtilFns.showModal(this._authorSelectorComponent, this, this.validateAuthor).then(a => {
            if (!a) {
                return;
            }
            const author = <Author>a;
            this.addReferenceAuthorFor(author);
        });
    }

    onDetachAuthor(ra: ReferenceAuthor) {
        if (ra.entityAspect.entityState.isAdded()) {
            ra.author.entityAspect.rejectChanges();
            ra.entityAspect.rejectChanges();
        } else {
            ra.entityAspect.setDeleted();
        }

    }

    onCreateAuthor() {
        const data = this._data;
        data.editAuthor = this._uow.authorFactory.create();
        data.inSubEditor = true;
    }

    onEditAuthor(ra: ReferenceAuthor) {
        const data = this._data;
        data.editAuthor = ra.author;
        data.inSubEditor = true;
    }

    onApplyAuthor(author: Author) {
        const data = this._data;
        if (author && !this._entity.referenceAuthors.some(ra => ra.author == author)) {
            this.addReferenceAuthorFor(author);
        }
        data.editAuthor = null;
        data.inSubEditor = false;
    }


    addReferenceAuthorFor(author: Author) {
        const maxCounter = _.max(this._entity.referenceAuthors.map(ra => ra.counter)) || 0;
        const config = {
            referenceId: this._data.entity.referenceId,
            authorId: author.authorId,
            counter: maxCounter + 1
        };
        // this will also add it to the referenceAuthors collection
        return this._uow.referenceAuthorFactory.create(config);
    }


    validateAuthor(author: Author): string {
        if (this._data.entity.referenceAuthors.some(a => a.authorId == author.authorId)) {
            return `Author: ${author.authorName} has already been referenced`;
        }
    }


}

import { Location } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';

import { UnitOfWork, StateMap, provideParent } from '../../services/common';
import { PageState, EditPropParent } from '../../controls/common';
import { ExposureSurvey, TypeExposureSurveyStatus, TypeCremeVersion } from '../../entities/EntityModels';
import * as _ from 'lodash';

@Component({
    selector: 'exposure-survey-editor',
    templateUrl: './exposure-survey-editor.html',
    providers: [provideParent(ExposureSurveyEditorComponent, EditPropParent)]
})

export class ExposureSurveyEditorComponent implements OnInit {

    _pageState = new PageState('Exposure Survey Editor');
    _typeExposureSurveyStatuses: TypeExposureSurveyStatus[];
    _typeCremeVersions: TypeCremeVersion[];

    _selectedMonthIndex: number;
    _selectedYearIndex: number;

    public _monthNames = [{ month: 'January' }, { month: 'February' }, { month: 'March' },
        { month: 'April' }, { month: 'May' }, { month: 'June' },
        { month: 'July' }, { month: 'August' }, { month: 'September' },
        { month: 'October' }, { month: 'November' }, { month: 'December' }];

    public _years: { year: number }[] = [];

    @Input() entity: ExposureSurvey;

    constructor(public _uow: UnitOfWork, public _stateMap: StateMap, public _location: Location) {

    }

    ngOnInit() {
        this._uow.typeExposureSurveyStatusRepository.all().then(r => {
            this._typeExposureSurveyStatuses = r;
        });

        this._uow.typeCremeVersionRepository.all().then(c => {
            this._typeCremeVersions = _.sortBy(c, t => t.cremeVersion).reverse();
        });

        this._years = this.createYearSequence(2001, this.entity.exposureSurveyDate.getUTCFullYear(), 10);
        this._years = _.sortBy(this._years, y => y.year).reverse();

        this._selectedMonthIndex    = this.entity.exposureSurveyDate.getUTCMonth();
        this._selectedYearIndex     = this._years.findIndex(y => y.year == this.entity.exposureSurveyDate.getUTCFullYear());
    }

    createYearSequence(minYear: number, currentYear: number, additionalYears: number): {year: number}[] {
        const cnt     = minYear + (currentYear - minYear) + additionalYears;
        const years = _.range(2001, cnt, 1);

        return years.map(function (y) {
            return { year: y };
        });
    }

    isAdded() {
        return this.entity.entityAspect.entityState.isAdded();
    }

    onSelectMonth(monthidx: number) {
        monthidx++;
        this.entity.exposureSurveyDate = this.formatExposureSurveyDate(monthidx, this.entity.exposureSurveyDate.getFullYear());
    }

    onSelectYear(yearidx: number) {
        let monthidx: number = this.entity.exposureSurveyDate.getMonth();
        monthidx++;
        this.entity.exposureSurveyDate = this.formatExposureSurveyDate(monthidx, this._years[yearidx].year);
    }

    formatExposureSurveyDate(month: number, year: number): Date {
        const s = '';
        const surveydate = s.concat('' + month, '/', '01', '/', '' + year);
        return new Date(surveydate);
    }
}

import {Location} from '@angular/common';
import {Component, forwardRef, Inject, OnInit} from '@angular/core';

import {provideParent, StateMap, UnitOfWork, WorkflowEntityState} from '../../services/common';
import {EditPropParent, PageState} from '../../controls/common';
import {ReportingOrganization} from '../../entities/EntityModels';
import {StringDistance} from '../../entities/projections/StringDistance';

import {MaterialReportedUseEditData, MaterialReportedUseEditState, MaterialReportedUsesComponent} from './material-reported-uses.component';

import {MaterialWorkflowService} from '../../services/material-workflow-service';
import {UtilFns} from '../../utils/util-fns';

@Component({
    selector: 'reporting-organization-editor',
    templateUrl: './reporting-organization-editor.html',
    providers: [provideParent(ReportingOrganizationEditorComponent, EditPropParent)]
})

export class ReportingOrganizationEditorComponent implements OnInit {

    public _data: MaterialReportedUseEditData = new MaterialReportedUseEditData();
    public _pageState = new PageState('Material Reported Use Edit Data');

    public _saveOrganizationName: string;
    public _searchedOrganizationName: string;
    public _organizationNameChanged = false;
    public _filterSurveyYear: string;

    public _reportingOrganizations: ReportingOrganization[];
    public _organizationNameStringDistances: StringDistance[];

    public _materialWorkflowService: MaterialWorkflowService;
    public _isLoading = false;
    public _validationMessage: string;

    constructor(public _uow: UnitOfWork, public _stateMap: StateMap, public _location: Location, materialWorkflowService: MaterialWorkflowService,
                @Inject(forwardRef(() => MaterialReportedUsesComponent)) public _parent: MaterialReportedUsesComponent) {

        this._data = _parent._data;
        this._materialWorkflowService = materialWorkflowService;
    }

    ngOnInit() {

        this._uow.fetchTyped('MaterialReportedUses/ReportingOrganizations', ReportingOrganization, { }).then(r => {
            this._isLoading = true;

            this._reportingOrganizations = r;

            this._isLoading = false;
        });

        if (this._data.edmReportingOrganization.currentEntity != null) {
            this._searchedOrganizationName = this._data.edmReportingOrganization.currentEntity.organizationName;
        }

        if (this._data.materialReportedUseEditState == MaterialReportedUseEditState.EditReportingOrganization) {
            this._saveOrganizationName = this.entity.organizationName;
        }
    }

    // interactions
    public pageTitle(): string {
        if (this._data.materialReportedUseEditState == MaterialReportedUseEditState.AddReportingOrganization) {
            return 'Adding Reporting Organization';
        }
    }

    isAdding(): boolean {
        return (this._data.materialReportedUseEditState == MaterialReportedUseEditState.AddReportingOrganization);
    }

    isEditing(): boolean {
        return (this._data.materialReportedUseEditState == MaterialReportedUseEditState.EditReportingOrganization);
    }

    onChangeOrganizationName(name: string) {

        if (!this.isValidString(name)) {
            return;
        }

        if (!this._organizationNameChanged) {
            return;
        }

        if (this._searchedOrganizationName == name) {
            return;
        }

        this._organizationNameChanged = false;
        this._searchedOrganizationName = name;

        this._isLoading = true;

        if (this._data.materialReportedUseEditState == MaterialReportedUseEditState.AddReportingOrganization) {
            // tslint:disable-next-line:max-line-length
            this._uow.fetch('MaterialReportedUses/FetchReportingOrganizationNameStringDistances', { organizationName: this.entity.organizationName, distThreshold: 4,  pctThreshold: 50.00 }).then(r => {
                this._organizationNameStringDistances = r;
                this._isLoading = false;
            });
        } else { // in edit mode, exclude the name of the reporting organization being edited
            // tslint:disable-next-line:max-line-length
            this._uow.fetch('MaterialReportedUses/FetchReportingOrganizationNameStringDistancesExcludeById', { organizationName: this.entity.organizationName, distThreshold: 4,  pctThreshold: 50.00, reportingOrganizationId: this.entity.reportingOrganizationId }).then(r => {
                this._organizationNameStringDistances = r;
                this._isLoading = false;
            });
        }
    }

    get entity(): ReportingOrganization {
        return this._data.edmReportingOrganization.currentEntity;
    }

    get utilFns() {
        return UtilFns;
    }

    public validateReportingOrganization(): boolean {
        if (this.entity == null || !this.isValidString(this.entity.organizationName)) {
            this._validationMessage = 'Please enter a valid organization name.';
            return false;
        }

        // check to see if the organization name already exists
        if (this.hasCalculatedDistances()) {
            if (this._organizationNameStringDistances.findIndex(o => o.distance == 0) > -1) {
                this._validationMessage = 'An organization with this name already exists.';
                return false;
            }
        }

        return true;
    }

    hasCalculatedDistances(): boolean {
        return (this._organizationNameStringDistances != null && this._organizationNameStringDistances.length > 0);
    }

    isValidString(teststring: string) {

        if (!teststring) { return false; }

        return (teststring.trim().length > 0);
    }

    // workflow
    public recordReportingOrganizationWorkflowAction() {
        let notes: string;

        if (this._data.materialReportedUseEditState == MaterialReportedUseEditState.AddReportingOrganization) {
            notes = 'New Reporting Organization name: ' + this.entity.organizationName;
            this._materialWorkflowService.recordNewReportingOrganization(WorkflowEntityState.Added, notes, this._data.userName);
        } else {
            if (this.entity.organizationName != this._saveOrganizationName) {
                notes = 'Reporting organization name changed from ' + this._saveOrganizationName + ' to ' + this.entity.organizationName;
                this._materialWorkflowService.recordChangedReportingOrganizationName(WorkflowEntityState.Modified, notes, this._data.userName);
            }
        }
    }
}

import { Component, ElementRef, OnInit } from '@angular/core';
import * as _ from 'lodash';

import { StateMap, UnitOfWork } from '../../services/common';
import { UtilFns } from '../../utils/common';
import { ModalDialog, PageState } from '../../controls/common';

import { Announcement } from '../../entities/EntityModels';

@Component({
    selector: 'whats-new-selector',
    templateUrl: './whats-new-selector.html'
})
export class WhatsNewSelectorComponent extends ModalDialog<Announcement> implements OnInit {
    _searchTerm: string;
    _entities: Announcement[] = [];
    _pageState = new PageState('What\'s New items');

    // stateMap is used within the html
    constructor(elementRef: ElementRef, public _uow: UnitOfWork, public _stateMap: StateMap) {
        super(elementRef);
    }

    ngOnInit() {
        this.refresh();
    }

    refresh() {
        this._pageState.isLoading = true;
        this._uow.announcementRepository.all().then(r => {
            this._pageState.isLoaded = r;
            this._entities = _.orderBy(r, a => a.created, 'desc');
        });
    }

    onSelect(announcement: Announcement) {
        UtilFns.returnModal(this, announcement);
    }


}


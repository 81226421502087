import { EntityBase } from './EntityBase';
import { Reference } from './Reference';
import { TypeWorkflowAction } from './TypeWorkflowAction';
import { TypeWorkflowContact } from './TypeWorkflowContact';

import { Validator } from 'breeze-client';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

export class WorkflowRecordedAction extends EntityBase {

   /// <code> Place custom code between <code> tags
   
   /// </code>

   // Generated code. Do not place code below this line.
   workflowRecordedActionId: number;
   typeWorkflowActionId: string;
   entityName: string;
   propertyName: string;
   referenceId: number;
   materialId: number;
   actionObjectId: string;
   completedWorkflowActionId: string;
   actionCompletedDate: Date;
   completedUser: string;
   sentToContactId: number;
   notes: string;
   createdWorkflowContactId: number;
   completedWorkflowContactId: number;
   holdReason: string;
   sequenceNumber: number;
   created: Date;
   createdUser: string;
   modified: Date;
   modifyUser: string;
   rowVersion: number;
   completedTypeWorkflowAction: TypeWorkflowAction;
   completedTypeWorkflowContact: TypeWorkflowContact;
   createdTypeWorkflowContact: TypeWorkflowContact;
   reference: Reference;
   sentToTypeWorkflowContact: TypeWorkflowContact;
   typeWorkflowAction: TypeWorkflowAction;
}


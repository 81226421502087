import { Component, OnDestroy, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import * as _ from 'lodash';

import { IRouteDefinition, SearchService, StateMap, UnitOfWork } from '../../services/common';
import { STAFF_ROUTES } from './staff.routes';
import { environment } from '../../../environments/environment';


interface IRouteInfo {
    definition: IRouteDefinition;
    canShow?: (state: StateMap) => boolean;
    getPath?: (state: StateMap) => string;
}

@Component({
    selector: 'staff-editing',
    templateUrl: './staff-editing.html',
})
export class StaffEditingComponent implements OnInit, OnDestroy {
    routes: IRouteInfo[];

    constructor(public _uow: UnitOfWork, public _stateMap: StateMap, public _searchService: SearchService,
                public _location: Location, public _http: HttpClient) {

        this.routes = [
            { definition: STAFF_ROUTES.Material, canShow: x => x.currentMaterial == null },
            {
                definition: STAFF_ROUTES.MaterialById,
                canShow: x => x.currentMaterial != null,
                getPath: x => `material/${x.currentMaterial.materialId}/info`
            },

            { definition: STAFF_ROUTES.Reference, canShow: x => x.currentReference == null },
            {
                definition: STAFF_ROUTES.ReferenceEditor,
                canShow: x => x.currentReference != null,
                getPath: x => `reference/${x.currentReference.referenceId}/info`
            },

            { definition: STAFF_ROUTES.ReferenceAudit, canShow: x => x.currentReference == null },
            {
                definition: STAFF_ROUTES.ReferenceAuditById,
                canShow: x => x.currentReference != null,
                getPath: x => `reference/${x.currentReference.referenceId}/audit`
            },

            { definition: STAFF_ROUTES.Protocol, canShow: x => x.currentReference == null },
            {
                definition: STAFF_ROUTES.ProtocolById,
                canShow: x => x.currentReference != null,
                getPath: x => `reference/${x.currentReference.referenceId}/protocol`
            },

            { definition: STAFF_ROUTES.Journals },
            { definition: STAFF_ROUTES.TypePredictionEditorBaseComponent },
            { definition: STAFF_ROUTES.Project },
            { definition: STAFF_ROUTES.FSAG },
            { definition: STAFF_ROUTES.TypeReferenceRelation },
            { definition: STAFF_ROUTES.WhatsNew },
            { definition: STAFF_ROUTES.RefWorkflowStats },
            { definition: STAFF_ROUTES.MaterialReportedUses },
            { definition: STAFF_ROUTES.SurveyReportedUse },
            { definition: STAFF_ROUTES.AssessmentCategories }
        ];
    }

    getPath(routeInfo: IRouteInfo) {
        if (routeInfo.getPath) {
            return routeInfo.getPath(this._stateMap);
        }
        return routeInfo.definition.path;
    }

    canShow(routeInfo: IRouteInfo) {

        if (routeInfo.canShow && !routeInfo.canShow(this._stateMap)) {
            return false;
        }

        const routeDef = routeInfo.definition;

        if (routeDef.name == 'MaterialReportedUses') {
            if (!this._stateMap.currentUser.isAdmin) {
                return false;
            }
        }

        if (this._stateMap.currentUser.isStaff) {
            // Exception to prevent consultant staff from seeing otherwise staff viewable content.
            if (routeDef.rolesExcluded) {
                const roles = routeDef.rolesExcluded.split(',').map(item => item.trim());

                if (roles != null && roles.length > 0) {
                    return (!this.userRoleFound(roles));
                }
            }
            return true;
        }

        return !routeDef.staffOnly;

    }

    userRoleFound(roles: string[]): boolean {
        for (let idx = 0; idx < roles.length; idx++) {
            if (this._stateMap.currentUser.isInRole(roles[idx])) {
                return true;
            }
        }
        return false;
    }

    ngOnInit() {
    }

    ngOnDestroy() {
    }

    currentRoute() {
        const route = _.find(this.routes, r => r.definition.name == this.routeName);
        return route == null ? null : route.definition;
    }

    get routeName() {
        return this._stateMap.currentRouteName;
    }

    canUseMaterialHeader() {
        return this.routeName == STAFF_ROUTES.Material.name;
    }

    canUseReferenceHeader() {
        return (this.routeName == STAFF_ROUTES.Reference.name
            || this.routeName == STAFF_ROUTES.Protocol.name
            || this.routeName == STAFF_ROUTES.ReferenceAudit.name);
    }

    canUseDefaultHeader() {
        return !(this.canUseMaterialHeader() || this.canUseReferenceHeader());
    }

    getPageTitle() {
        return 'Staff/' + (this.currentRoute() ? this.currentRoute().title : '') + ': ';
    }

    canDeactivate() {
        if (this._uow.hasChanges()) {
            return this._stateMap.yesNoComponent.showModal(
                'Changes will be lost', 'Do you want to continue?').then(ok => {
                if (ok) {
                    this._uow.rollback();
                }
                return ok;
            });
        } else {
            return Promise.resolve(true);
        }
    }

    refreshLookups() {
        return this._stateMap.yesNoComponent.showModal('Clear Lookup Cache', 'Clear Cache for Lookup Tables and Reload ?')
            .then(ok => {
                if (ok) {
                    this._http.post(`${environment.appUrl}/breeze/lookupReset`, null)
                        .toPromise()
                        .then(() => {
                            document.location.reload();
                        });
                }
                return ok;
            });
    }
}

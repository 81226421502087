import { Component, ElementRef } from '@angular/core';

import { UnitOfWork } from '../../services/common';
import { UtilFns } from '../../utils/common';
import { ModalDialog, PageState } from '../../controls/common';

import { Author } from '../../entities/EntityModels';

@Component({
    selector: 'author-selector',
    templateUrl: './author-selector.html',

})
export class AuthorSelectorComponent extends ModalDialog<Author> {
    _searchTerm: string;
    _entities: Author[] = [];
    _pageState = new PageState('Authors');

    constructor(elementRef: ElementRef, public _uow: UnitOfWork) {
        super(elementRef);
    }

    canSearch() {
        return this._searchTerm && this._searchTerm.length > 1;
    }

    search() {
        this.validationMessage = null;
        this._pageState.isLoading = true;
        this._entities = null;
        this._uow.fetchTyped('Misc/AuthorsByName', Author, {name: UtilFns.encodeHackForAnd(this._searchTerm)}).then(r => {
            this._pageState.isLoaded = r;
            this._entities = r;
        });
    }

    onSelect(author: Author) {
        UtilFns.returnModal(this, author);
    }


}


import { Injectable} from '@angular/core';

export class ErrorEntry {
    constructor(public error: any, public errorLevel: string) {
        
    }
    
    formatError() {
        this.error.message || this.error.toString(); 
    }
}

@Injectable()
export class ErrorLogger {
    constructor( ) {
        
    }
    
    _errors: ErrorEntry[] = [];
    
    log(e: string | any, errorLevel?: string ) {
        console.log(e);
        var ee = new ErrorEntry(e, errorLevel);
        this._errors.push(ee);            
    }
    
    getErrors() {
        return this._errors;
    }
} 
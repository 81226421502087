<div style="margin-bottom: 10px">
    <div class="inline" style="font-size: 18px;" *ngIf="_edm.editing">{{_adding ? "Adding an FSAG" : "Editing an FSAG" }}</div>
    <span *ngIf="(!_edm.editing) || _edm.canShowBack()">
        <button [disabled]="!_FSAGs" type="button" class="inline btn btn-sm btn-primary"  (click)="_edm.onAdd()">Add an FSAG</button>            
        <button [disabled]="!_FSAGs" type="button" class="inline btn btn-sm btn-primary"  (click)="onEdit()"> {{ "Edit " + (_edm.editing ? "Another " : "an ") + "FSAG" }}</button>
    </span>
</div>
<editor-header [edm]="_edm" [customButtons]="true">
    <span customButtons>
        <span *ngIf="_edm.editing">
            <button type="button" class="btn btn-sm btn-primary" [disabled]="!_edm.canSave()" (click)="_edm.onSave()">Save</button>
            <button *ngIf="!_adding" type="button" class="btn btn-sm btn-primary" [disabled]="!_edm.canDelete()" (click)="_edm.onDelete()">Delete</button>
            <button *ngIf="_edm.canShowBack()" type="button" class="btn btn-sm btn-primary"  (click)="_edm.onBack()">Back</button>
            <button *ngIf="!_edm.canShowBack()" type="button" class="btn btn-sm btn-primary" [disabled]="!_edm.canCancel()" (click)="_edm.onCancel()">Cancel</button>
        </span>
    </span>
</editor-header>

<page-status [pageState]="_pageState"></page-status>

<div *ngIf="_edm.editing" class="form-horizontal">
    <edit-prop label="Note" prop="note" szs="1,9">
        <input type="text" class="form-control" [(ngModel)]="entity.note" (change)="onNoteChange()">
    </edit-prop>
    <edit-prop label="SAG" prop="sAG" szs="1,9">
        <label class="form-control" type="string">{{ entity.sAG }}</label>        
    </edit-prop>
    <edit-prop label="Priority" prop="priority" szs="1,9">
        <input type="text" class="form-control" [(ngModel)]="entity.priority">
    </edit-prop>
    <div class="form-group">
        <span class="col-md-1">
            <button type="button" class="btn btn-sm btn-success" (click)="onSelectParent()">Parent</button>
        </span>
        <edit-prop isChild prop="parent" szs="1,8">
            <label class="form-control" type="string">{{ getDescription(entity.parent)}}</label>
        </edit-prop>
    </div>

    <edit-prop label="Child" prop="child" szs="1,9">
        <label class="form-control" type="string">{{ getDescription(entity.child)}}</label>
    </edit-prop>
    <edit-prop label="Sibling" prop="sibling" szs="1,9">
        <label class="form-control" type="string">{{ getDescription(entity.sibling)}}</label>
    </edit-prop>

    <div *ngIf="_materialFSAGs" style="margin-right: 15px;">
        <div>
            <span class="page-section">{{getMaterialsCountText()}}</span>
            <button type="button" class="btn btn-sm btn-primary" (click)="onAttachMaterial()">Attach Material</button>
        </div>
        <div>            
            <input type="checkbox"  [(ngModel)]="_canShowImages">
            <span>Show images</span>
        </div>                        
        <default-table>
            <thead>
                <tr>
                    <td>CAS #</td>
                    <td>RIFM ID</td>
                    <td>Principal Name</td>
                    <td *ngIf="_canShowImages">Structure</td>
                    <td></td>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let e of _materialFSAGs ">
                    <td (click)="onCasSelect(e) ">
                        <a>{{e.material.realCASNumber}}</a>
                    </td>
                    <td>{{e.material.formattedRIFMId}}</td>
                    <td>{{e.material.principalSynonym().synonymWordOrWebVersion()}}</td>
                    <td *ngIf="_canShowImages"><img [src]="getMaterialImageUrl(e) " /></td>
                    <td><a (click)="onDetachMaterial(e)">Detach</a></td>
                </tr>
            </tbody>
        </default-table>
    </div>
</div>

<fsag-selector *ngIf="_FSAGs" [FSAGs]="_FSAGs"></fsag-selector>
<material-selector></material-selector>
import { EntityBase } from './EntityBase';

import { Validator } from 'breeze-client';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

export class TypeAlertRule extends EntityBase {

   /// <code> Place custom code between <code> tags
   
   /// </code>

   // Generated code. Do not place code below this line.
   rule: number;
   description: string;
   gIF: string;
   topicalEffects: number;
   systemicEffects: number;
   carcinogenicEffects: number;
   rowVersion: number;
}


import { ExposureSurveysComponent } from './exposure-surveys.component';
import { ExposureSurveyListsComponent } from './exposure-survey-lists.component';
import { ExposureAdditionalMaterialsComponent } from './exposure-additional-materials.component';
import { RecordExposureSurveyMeasuresComponent } from './record-exposure-survey-measures.component';
import { ExposureSurveyReportsComponent } from './exposure-survey-reports.component';

export const EXPOSURE_ROUTES = {
    ExposureSurveys: { name: 'ExposureSurveys', title: 'Manage Surveys', path: 'exposure-surveys', component: ExposureSurveysComponent, rolesExcluded: 'consultant,FEMA' },
    ExposureSurveyLists: { name: 'ExposureSurveyListsComponent', title: 'Manage Material Lists', path: 'exposure-survey-lists', component: ExposureSurveyListsComponent, rolesExcluded: 'consultant,FEMA' },
    RecordExposureSurveyMeasuresComponent: { name: 'RecordExposureSurveyMeasures', title: 'Record Exposure Survey Measures', path: 'record-exposure-survey-measures', component: RecordExposureSurveyMeasuresComponent, rolesExcluded: 'consultant,FEMA' },
    ExposureAdditionalMaterialsComponent: { name: 'ExposureAdditionalMaterials', title: 'Manage External Materials', path: 'exposure-additional-materials', component: ExposureAdditionalMaterialsComponent, rolesExcluded: 'consultant,FEMA' },
    ExposureSurveyReportsComponent: { name: 'ExposureSurveyReports', title: 'Reports', path: 'exposure-survey-reports', component: ExposureSurveyReportsComponent, rolesExcluded: 'consultant,FEMA' }
};

<div class="indent-left-md" style="margin-bottom: 10px;" >
    <div class="home-page-title">
        <span class="home-page-title">Material Results</span>
        <span *ngIf="isStaff() && _materialSearchResult && _materialSearchResult.searchDescription" style="font-size: 14px !important"> - Search by {{ _materialSearchResult.searchDescription }}</span>
    </div>
    <material-results></material-results>
    
    <div class="home-page-title">
        <span class="home-page-title">Reference Results</span>
        <span *ngIf="isStaff() && _referenceSearchResult && _referenceSearchResult.searchDescription" style="font-size: 14px !important"> - Search by {{ _referenceSearchResult.searchDescription}}</span>
    </div>
    <reference-results [showJournal]="true"></reference-results>
</div>            

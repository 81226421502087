import { Component, Input, OnInit } from '@angular/core';
import * as _ from 'lodash';

import { provideParent, UnitOfWork } from '../../services/common';
import { EditPropParent } from '../../controls/common';

import { BioDataStudySubType, BiologicalData, TypeStudy, TypeStudySubType } from '../../entities/EntityModels';

@Component({
    selector: 'protocol-editor-substudies',
    templateUrl: './protocol-editor-substudies.html',
    providers: [provideParent(ProtocolEditorSubStudiesComponent, EditPropParent)]
})
export class ProtocolEditorSubStudiesComponent implements OnInit, EditPropParent {
    @Input() entity: BiologicalData;
    _typeStudies: TypeStudy[];
    _typeStudySubTypes: TypeStudySubType[];
    _filteredSubTypes: TypeStudySubType[];
    _status: string;

    _typeStudyId = '';
    set typeStudyId(val: string) {
        if (this._typeStudyId != val) {
            this.typeStudySubTypeId = null;
        }
        this._typeStudyId = val;
        this._filteredSubTypes = this.getStudySubTypes(val);
    }

    get typeStudyId() {
        return this._typeStudyId;
    }

    typeStudySubTypeId = '';

    constructor(public _uow: UnitOfWork) {
    }

    ngOnInit() {
        this._uow.bioDataStudySubTypeRepository
            .whereWithParams({ biologicalDataId: this.entity.biologicalDataId });

        this._uow.typeStudySubTypeRepository.all()
            .then(r => {
                this._typeStudySubTypes = _.sortBy(r, x => x.subTypeDescription);
                this._uow.typeStudyRepository.all()
                    .then(data => {
                        this._typeStudies = _.sortBy(data, x => x.studyLong)
                            .filter(x => this.getStudySubTypes(x.typeStudyId).length > 0);
                    });
            });
    }

    canAdd() {
        return !!this.typeStudySubTypeId;
    }

    addSubStudy() {
        try {
            if (!this.canAdd()) {
                return;
            }

            const config = {
                biologicalDataId: this.entity.biologicalDataId,
                typeStudyId: this.typeStudyId,
                studySubTypeId: this.typeStudySubTypeId
            };
            this._uow.bioDataStudySubTypeFactory.create(config);
        } catch (e) {
            this.setStatus('This sub study was already added or deleted.');
        }
    }

    getError(propName: string) {
        return this.entity.getErrorFor(propName);
    }

    onDeleteSubStudy(subStudy: BioDataStudySubType) {
        subStudy.entityAspect.setDeleted();
    }

    getStudySubTypes(studyId: string) {
        if (this._typeStudySubTypes) {
            return this._typeStudySubTypes.filter(x => x.typeStudyId == studyId);
        }
    }

    setStatus(message: string) {
        this._status = message;
        setTimeout(() => {
            this._status = null;
        }, 3000);
    }
}

import { EntityBase } from './EntityBase';
import { ExperimentalMaterial } from './ExperimentalMaterial';
import { BioDataStudySubType } from './BioDataStudySubType';
import { Reference } from './Reference';
import { TypeRoute } from './TypeRoute';
import { TypeSpecies } from './TypeSpecies';
import { TypeStudy } from './TypeStudy';
import { TypeTimeUnit } from './TypeTimeUnit';
import { TypeUsefulness } from './TypeUsefulness';

import { Validator } from 'breeze-client';
/// <code-import> Place custom imports between <code-import> tags
import { EntityPropertyAnnotation, EntityTypeAnnotation } from './EntityTypeAnnotation';
import * as _ from 'lodash';

/// </code-import>

export class BiologicalData extends EntityBase {

    /// <code> Place custom code between <code> tags
    // non persisted client side only temporary subReference
    // for when an actual subreference does not exist;
    _tempSubReference: number;

    static getEntityTypeAnnotation(): EntityTypeAnnotation {
        const propAnnotations = [
            new EntityPropertyAnnotation('typeStudyId', {
                displayName: 'Study',
                validators: [Validator.required()]
            }),
            new EntityPropertyAnnotation('typeSpeciesId', {
                displayName: 'Species',
                validators: []
            }),
            new EntityPropertyAnnotation('typeRouteId', {
                displayName: 'Route',
                validators: [Validator.required()]
            }),
            new EntityPropertyAnnotation('typeTimeUnitId', {
                displayName: 'Time Unit',
                validators: []
            }),
            new EntityPropertyAnnotation('typeUsefulnessId', {
                displayName: 'Usefulness',
                validators: []
            }),
        ];

        return new EntityTypeAnnotation({
            validators: [],
            propertyAnnotations: propAnnotations
        });
    }


    public anySubjects() {
        return (this.subjectsMale > 0) ||
            (this.subjectsFemale > 0) ||
            (this.subjectsUnspecifiedSex > 0);
    }

    public get subReference() {
        return (this.fileReference && this.fileReference.trim().length > 0) ?
            this.fileReference :
            (this._tempSubReference || '').toString();
    }

    public studySubTypeDescriptions() {
        return this.bioDataStudySubTypes
            .map(x => x.typeStudySubType.subTypeDescription);
    }

    public getMethodologyBriefRefs() {
        if (this.methodologyReference == null || this.methodologyReference.length == 0) {
            return [];
        }
        // split along ';'
        const briefRefs = this.methodologyReference.split(';');
        const normalizedRefs: string[] = [];
        briefRefs.forEach(br => {
            const brParts = Reference.extractBriefRefParts(br.trim());
            // some refs are written as 'a and b,1965'
            if (brParts.author) {
                const authors = brParts.author.split(' and ');
                if (authors.length > 1) {
                    authors.forEach(author => {
                        const tempBrParts = _.cloneDeep(brParts);
                        tempBrParts.author = author.trim();
                        const tempBr = Reference.partsToBriefRef(tempBrParts);
                        normalizedRefs.push(tempBr);
                    });
                } else {
                    normalizedRefs.push(Reference.partsToBriefRef(brParts));
                }
            } else {
                normalizedRefs.push(br);
            }
        });
        return normalizedRefs;
    }

    cascadeDelete() {
        // need to keep a copy of related entities and cascade delete them after
        // deleting the parent. Otherwise the deletion of the parent sets the fk of the
        // children to 0 which causes problems when the server when tries to delete them.
        const expMats = this.experimentalMaterials.map(x => x);
        this.cascadeDeleteCore();
        expMats.forEach(ed => ed.cascadeDelete());
    }

    /// </code>

    // Generated code. Do not place code below this line.
    biologicalDataId: number;
    referenceId: number;
    fileReference: string;
    typeStudyId: string;
    typeRouteId: string;
    typeSpeciesId: string;
    confidential: boolean;
    methodologyReference: string;
    humanHealthData: boolean;
    environmentalData: boolean;
    subjectsMale: number;
    subjectsFemale: number;
    subjectsUnspecifiedSex: number;
    studyLength: number;
    typeTimeUnitId: string;
    typeUsefulnessId: string;
    gLP: boolean;
    protocol: string;
    comment: string;
    compliance: string;
    safetyAssessmentSummary: string;
    created: Date;
    createdUser: string;
    modified: Date;
    modifyUser: string;
    rowVersion: number;
    bioDataStudySubTypes: BioDataStudySubType[];
    experimentalMaterials: ExperimentalMaterial[];
    reference: Reference;
    typeRoute: TypeRoute;
    typeSpecies: TypeSpecies;
    typeStudy: TypeStudy;
    typeTimeUnit: TypeTimeUnit;
    typeUsefulness: TypeUsefulness;
}


import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { provideParent } from '../../services/common';
import { EditPropParent } from '../../controls/common';

import { Author } from '../../entities/EntityModels';


@Component({
    selector: 'author-editor',
    templateUrl: './author-editor.html',
    providers: [provideParent(AuthorEditorComponent, EditPropParent)]
})
export class AuthorEditorComponent implements OnInit, EditPropParent {
    @Input() entity: Author;
    @Output() apply = new EventEmitter<Author>();

    ngOnInit() {

    }

    getError(propName: string) {
        return this.entity.getErrorFor(propName);
    }

    canApplyAuthor() {
        return !this.entity.entityAspect.hasValidationErrors;
    }

    onApplyAuthor(author: Author) {
        if (!author) {
            this.entity.entityAspect.rejectChanges();
        }
        this.apply.emit(author);
    }

    isAdded() {
        return this.entity && this.entity.entityAspect.entityState.isAdded();
    }

}

<div style="width: 100%; display: inline-block;">
    <div class="float-left" style="width: 55%;">
        <div *ngIf="_exposureSurveys && _exposureSurveys.length">
            <span class="inline text-bold text-left publication-title">List of Materials for the Exposure Survey: </span>
            <a class="inline page-title bold left-indent" (click)="onShowExposureSurvey()">{{_selectedExposureSurvey.exposureSurveyMonthYearNumber}}</a>
            <br />
            <div  class="inline vertical-middle">
                <span class="bold">Select Survey: </span>
                <select [disabled]="!canDeactivate()" class="dropdown-toggle list-background-white" [ngModel]="_selectedExposureSurveyIndex" (change)="filterExposureSurveyByIndex($event.target.value)">
                    <option *ngFor="let es of _exposureSurveys; let ix = index" class="dropdown" [value]="ix">{{es.exposureSurveyMonthYearNumber}}</option>
                </select>
            </div>
            <span class="inline text-bold text-left page-subtitle left-indent" style="margin-top: 4px; margin-left: 8px;">Survey Status: </span>
            <span class="inline text-bold text-left page-subtitle" style="background-color: #AFE7FF; margin-left: 2px; padding-left: 2px; padding-right: 2px;">{{_selectedExposureSurvey.typeExposureSurveyStatusId}}</span>
        </div>
    </div>
    <div class="float-right" style="width: 40%;">
        <button type="button" class="inline btn btn-creme-report" style="width:160px; margin-left:8px;" (click)="onExportCremeReport()">Export Creme Report</button>
        <button type="button" class="inline btn btn-survey-report" style="width:190px; margin-left:12px;" (click)="onExportFullSurveyReport()">Export Full Survey Report</button>
    </div>
</div>

<div  *ngIf="_selectedExposureSurvey" class="row" style="margin-top: 12px; width: 96%; margin-left: 14px;">
    <tab-container>
        <ng-template tab-pane [canDeactivate]="canDeactivate()" title="RIFM Materials">
            <div style="margin-left: 12px;margin-bottom:12px;">
                <material-exposure-surveys #self [exposureSurvey]="_selectedExposureSurvey"></material-exposure-surveys>
            </div>
        </ng-template>
        <ng-template tab-pane [canDeactivate]="canDeactivate()" title="External Materials">
            <div style="margin-left: 12px;margin-bottom:12px;">
                <exposure-additional-material-surveys #self [exposureSurvey]="_selectedExposureSurvey"></exposure-additional-material-surveys>
            </div>
        </ng-template>
    </tab-container>
</div>

import { Component, Input, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';

import { StateMap, UnitOfWork, UserManager } from '../../services/common';
import { UtilFns } from '../../utils/common';
import { PageState } from '../../controls/common';
import { ROUTES } from '../routes';
import { Material, MaterialComposition } from '../../entities/EntityModels';


@Component({
    selector: 'material-partof-compounds',
    templateUrl: './material-partof-compounds.html',
})
export class MaterialPartOfCompoundsComponent implements OnInit {

    @Input() materialId: number;
    @Input() principalName: string;

    _pageState = new PageState('Natural Materials');

    _materialCompositions: MaterialComposition[];

    constructor(private _uow: UnitOfWork, private _stateMap: StateMap, private _userManager: UserManager, private _router: Router, public _location: Location) {
    }

    ngOnInit() {
        const pMatId = {componentMaterialId: this.materialId};

        this._pageState.isLoading = true;

        this._uow.fetch('Materials/MaterialCompositionsByComponentMaterial', pMatId)
            .then(r => {
                if (r != null && r.length > 0) {
                    this._materialCompositions = r;
                    this._pageState.isLoaded = true;
                } else {
                    this._pageState.isLoaded = false;
                }
            });
    }

    goToSynonyms(m: Material) {
        if (m != null) {
            this._router.navigate(UtilFns.asRouterParts(ROUTES.Material, m.materialId));
        }
    }

    public get utilFns() {
        return UtilFns;
    }
}

import { Injectable } from '@angular/core';
import { Entity, EntityManager, EntityQuery, FetchStrategy, SaveOptions } from 'breeze-client';
import { IRepository, Repository } from './repository';
import { UserManager } from './user-manager';
import { EntityManagerProvider } from './entity-manager-provider';
import { ErrorLogger } from './error-logger';
import { ReferenceRepository } from './reference-repository';
import { extend } from 'lodash';

import {
    Material, Reference, BioDataStudySubType, BiologicalData, ReferenceAuthor, Synonym, ExperimentalMaterial, AnalyticalResult,
    FSAG, MaterialFSAG, SpecialLink, RegulatoryStatus, FoodStatus, MaterialUses, MaterialConsumption,
    ReferenceRelation, NaturalOccurrenceDescriptor, MaterialAlertRule, Prediction, EPISuite, NationalToxicologyProgram,
    MaterialRelation, Project, REACHRegistration, HouseStatus, MaterialExpertPanelSummary, UserChangeReport, UserChangeReportMaterial,
    UserChangeReportTopic, UserChangeReportAuthor, RIFMProduct, RIFMProductLicense, Announcement, Author,
    Abstract, ExperimentalResult, ExperimentalToxicEffect, Journal,
    TypeSearch, TypeSynonymUse, TypePublication, TypeAnalyticalProcedure, TypeRegulatoryStatus,
    TypeFoodCategory, TypeGeographicalArea, TypeTSCAClass, TypeAlertRule, TypePrediction, TypePredictionClass,
    TypeEPISuiteParameter, TypeEPISuiteCategory, TypeNTPStatus, TypeStudy, TypeSpecies, TypeRoute,
    TypeMaterialRelation, TypeREACHTonnageBand, TypeChangeReportFrequency, TypeChangeReportTopic,
    TypeLinkTarget, TypeBotanicalSubdivision, TypeEssentialOil, TypeNaturalOccurrence, TypeKeyword,
    TypeKlimisch, TypeTimeUnit, TypeUnit, TypeToxicEffect, TypeExperimentVehicle, TypeUsefulness, TypeStudySubType,
    TypeWorkflowAction, TypeWorkflowActionTransition, TypeWorkflowContact, WorkflowRecordedAction, TypeComplianceGuideline,
    TypeReferenceRelation, TypeStudyDesignation, TypeStudyMixture, Survey, SurveyAndCompany, SurveyReportedUse, SurveyUsesByMaterialAndYear, SurveyWhoUsedMostMaterial,
    TypeSearchService, UserAcknowledgement, MaterialAdditionalIdentifier, TypeMaterialIdentifier, MaterialComponent, MaterialComposition, TypeMaterialComponentClass,
    UserChangeReportStudy, LabelingManualPublication, AssessmentCategory, MaterialAssessmentCategory,
    ExposureAdditionalMaterial, ExposureAdditionalMaterialSurvey, ExposureAdditionalMaterialSurveyMeasure, ExposureSurvey,
    MaterialExposureSurvey, MaterialExposureSurveyIsomer, MaterialExposureSurveyMeasure,
    TypeExposureMeasure, TypeExposureMeasureValue, TypeExposureSurveyResponse, TypeExposureSurveyStatus, ExposureSurveyMaterialMeasureCount,
    ExposureSurveyMaterialMostRecent, TypeCremeVersion, RIFMReport, ClusterGroup, ClusterNodeAndEdge, ClusterPart, ClusterPath, MaterialCluster,
    SuperCluster, SuperClusterPath, ClusterPartWithParent, DocUniqReference, JournalReserve, JournalSubscription, TypeJournalSubscription, RIFMProductRoleAuthorization,
    RIFMDocumentFolder, RIFMDocument, RIFMDocumentArchive, TypeRIFMDocument, TypeWorkflowMaterialAction, WorkflowMaterialRecordedAction, TypeWorkflowMaterialActionTransition,
    ReportingOrganization, ECOSARToxicEffect, TypeMaterialUnit
} from '../entities/EntityModels';


export interface IEntityFactory<T extends Entity> {
    create(...params: any[]): T;
}

export class EntityFactory<T extends Entity> implements IEntityFactory<T> {

    constructor(public entityTypeName: string, public _emProvider: EntityManagerProvider) {
    }

    create(config?: {}): T {
        const inst = <T>this._emProvider.manager().createEntity(this.entityTypeName, config);
        // OLD version - did not allow config.
        // var inst = new this.type();
        // this.entityManagerProvider.manager().addEntity(inst);
        return inst;
    }
}

@Injectable()
export class UnitOfWork {
    assessmentCategoryRepository: IRepository<AssessmentCategory>;
    analyticalResultRepository: IRepository<AnalyticalResult>;
    announcementRepository: IRepository<Announcement>;
    bioDataStudySubTypeRepository: IRepository<BioDataStudySubType>;
    biologicalDataRepository: IRepository<BiologicalData>;
    clusterGroupRepository: IRepository<ClusterGroup>;
    clusterNodeAndEdgeRepository: IRepository<ClusterNodeAndEdge>;
    clusterPartRepository: IRepository<ClusterPart>;
    clusterPartWithParentRepository: IRepository<ClusterPartWithParent>;
    clusterPathRepository: IRepository<ClusterPath>;
    docUniqReferenceRepository: IRepository<DocUniqReference>;
    ecoSARToxicEffectRepository: IRepository<ECOSARToxicEffect>;
    epiSuiteRepository: IRepository<EPISuite>;
    experimentalMaterialRepository: IRepository<ExperimentalMaterial>;
    exposureAdditionalMaterialRepository: IRepository<ExposureAdditionalMaterial>;
    exposureAdditionalMaterialSurveyRepository: IRepository<ExposureAdditionalMaterialSurvey>;
    exposureAdditionalMaterialSurveyMeasureRepository: IRepository<ExposureAdditionalMaterialSurveyMeasure>;
    exposureSurveyRepository: IRepository<ExposureSurvey>;
    exposureSurveyMaterialMeasureCountRepository: IRepository<ExposureSurveyMaterialMeasureCount>;
    exposureSurveyMaterialMostRecentRepository: IRepository<ExposureSurveyMaterialMostRecent>;
    fSAGRepository: IRepository<FSAG>;
    foodStatusRepository: IRepository<FoodStatus>;
    houseStatusRepository: IRepository<HouseStatus>;
    journalRepository: IRepository<Journal>;
    journalReserveRepository: IRepository<JournalReserve>;
    journalSubscriptionRepository: IRepository<JournalSubscription>;
    labelingManualPublicationRepository: IRepository<LabelingManualPublication>;
    materialAdditionalIdentifierRepository: IRepository<MaterialAdditionalIdentifier>;
    materialAlertRuleRepository: IRepository<MaterialAlertRule>;
    materialClusterRepository: IRepository<MaterialCluster>;
    materialComponentRepository: IRepository<MaterialComponent>;
    materialCompositionRepository: IRepository<MaterialComposition>;
    materialConsumptionRepository: IRepository<MaterialConsumption>;
    materialExpertPanelSummaryRepository: IRepository<MaterialExpertPanelSummary>;
    materialExposureSurveyRepository: IRepository<MaterialExposureSurvey>;
    materialExposureSurveyIsomerRepository: IRepository<MaterialExposureSurveyIsomer>;
    materialExposureSurveyMeasureRepository: IRepository<MaterialExposureSurveyMeasure>;
    materialFSAGRepository: IRepository<MaterialFSAG>;
    materialRelationRepository: IRepository<MaterialRelation>;
    materialReciprocalRelationRepository: IRepository<MaterialRelation>;
    materialRepository: IRepository<Material>;
    materialUsesRepository: IRepository<MaterialUses>;
    materialAssessmentCategoriesRepository: IRepository<MaterialAssessmentCategory>;
    nationalToxicologyProgramRepository: IRepository<NationalToxicologyProgram>;
    naturalOccurrenceDescriptorRepository: IRepository<NaturalOccurrenceDescriptor>;
    predictionRepository: IRepository<Prediction>;
    projectRepository: IRepository<Project>;
    rEACHRegistrationRepository: IRepository<REACHRegistration>;
    reportingOrganizationRepository: IRepository<ReportingOrganization>;
    rifmDocumentRepository: IRepository<RIFMDocument>;
    rifmDocumentArchiveRepository: IRepository<RIFMDocumentArchive>;
    rifmDocumentFolderRepository: IRepository<RIFMDocumentFolder>;
    rIFMProductLicenseRepository: IRepository<RIFMProductLicense>;
    rIFMProductRepository: IRepository<RIFMProduct>;
    rIFMReportRepository: IRepository<RIFMReport>;
    referenceAuthorRepository: IRepository<ReferenceAuthor>;
    referenceRelationRepository: IRepository<ReferenceRelation>;
    referenceRepository: ReferenceRepository;
    regulatoryStatusRepository: IRepository<RegulatoryStatus>;
    specialLinkMaterialRepository: IRepository<SpecialLink>;
    specialLinkReferenceRepository: IRepository<SpecialLink>;
    superClusterPathRepository: IRepository<SuperClusterPath>;
    superClusterRepository: IRepository<SuperCluster>;
    surveyRepository: IRepository<Survey>;
    surveyAndCompanyRepository: IRepository<SurveyAndCompany>;
    surveyReportedUseRepository: IRepository<SurveyReportedUse>;
    surveyUsesByMaterialAndYearRepository: IRepository<SurveyUsesByMaterialAndYear>;
    surveyWhoUsedMostRepository: IRepository<SurveyWhoUsedMostMaterial>;
    synonymRepository: IRepository<Synonym>;
    userChangeReportRepository: IRepository<UserChangeReport>;
    userAcknowledgementRepository: IRepository<UserAcknowledgement>;
    workflowRecordedActionRepository: IRepository<WorkflowRecordedAction>;
    workflowMaterialRecordedActionRepository: IRepository<WorkflowMaterialRecordedAction>;

    typeAlertRuleRepository: IRepository<TypeAlertRule>;
    typeAnalyticalProcedureRepository: IRepository<TypeAnalyticalProcedure>;
    typeBotanicalSubdivisionRepository: IRepository<TypeBotanicalSubdivision>;
    typeChangeReportFrequencyRespository: IRepository<TypeChangeReportFrequency>;
    typeChangeReportTopicRepository: IRepository<TypeChangeReportTopic>;
    typeComplianceGuidelineRepository: IRepository<TypeComplianceGuideline>;
    typeCremeVersionRepository: IRepository<TypeCremeVersion>;
    typeEPISuiteCategoryRepository: IRepository<TypeEPISuiteCategory>;
    typeEPISuiteParameterRepository: IRepository<TypeEPISuiteParameter>;
    typeEssentialOilRepository: IRepository<TypeEssentialOil>;
    typeExposureMeasureRepository: IRepository<TypeExposureMeasure>;
    typeExposureMeasureValueRepository: IRepository<TypeExposureMeasureValue>;
    typeExposureSurveyResponseRepository: IRepository<TypeExposureSurveyResponse>;
    typeExposureSurveyStatusRepository: IRepository<TypeExposureSurveyStatus>;
    typeExperimentVehicleRepository: IRepository<TypeExperimentVehicle>;
    typeFoodCategoryRepository: IRepository<TypeFoodCategory>;
    typeGeographicalAreaRepository: IRepository<TypeGeographicalArea>;
    typeJournalSubscriptionRepository: IRepository<TypeJournalSubscription>;
    typeKeywordRepository: IRepository<TypeKeyword>;
    typeKlimischRepository: IRepository<TypeKlimisch>;
    typeLinkTargetRepository: IRepository<TypeLinkTarget>;
    typeMaterialComponentClassRepository: IRepository<TypeMaterialComponentClass>;
    typeMaterialRelationRepository: IRepository<TypeMaterialRelation>;
    typeMaterialIdentifierRepository: IRepository<TypeMaterialIdentifier>;
    typeMaterialUnitRepository: IRepository<TypeMaterialUnit>;
    typeNaturalOccurrenceRepository: IRepository<TypeNaturalOccurrence>;
    typeNTPStatusRepository: IRepository<TypeNTPStatus>;
    typePredictionClassRepository: IRepository<TypePredictionClass>;
    typePredictionRepository: IRepository<TypePrediction>;
    typePublicationRepository: IRepository<TypePublication>;
    typeREACHTonnageBandRepository: IRepository<TypeREACHTonnageBand>;
    typeReferenceRelationRepository: IRepository<TypeReferenceRelation>;
    typeRegulatoryStatusRepository: IRepository<TypeRegulatoryStatus>;
    typeRIFMDocumentRepository: IRepository<TypeRIFMDocument>;
    typeRouteRepository: IRepository<TypeRoute>;
    typeSearchRepository: IRepository<TypeSearch>;
    typeSearchServiceRepository: IRepository<TypeSearchService>;
    typeSpeciesRepository: IRepository<TypeSpecies>;
    typeStudyRepository: IRepository<TypeStudy>;
    typeStudyMixtureRepository: IRepository<TypeStudyMixture>;
    typeStudyDesignationRepository: IRepository<TypeStudyDesignation>;
    typeStudyMixture: IRepository<TypeStudyMixture>;
    typeStudySubTypeRepository: IRepository<TypeStudySubType>;
    typeSynonymUseRepository: IRepository<TypeSynonymUse>;
    typeTimeUnitRepository: IRepository<TypeTimeUnit>;
    typeToxicEffectRepository: IRepository<TypeToxicEffect>;
    typeTSCAClassRepository: IRepository<TypeTSCAClass>;
    typeUnitRepository: IRepository<TypeUnit>;
    typeUsefulnessRepository: IRepository<TypeUsefulness>;
    typeWorkflowActionRepository: IRepository<TypeWorkflowAction>;
    typeWorkflowActionTransitionRepository: IRepository<TypeWorkflowActionTransition>;
    typeWorkflowContactRepository: IRepository<TypeWorkflowContact>;
    typeWorkflowMaterialActionRepository: IRepository<TypeWorkflowMaterialAction>;
    typeWorkflowMaterialActionTransitionRepository: IRepository<TypeWorkflowMaterialActionTransition>;

    abstractFactory: IEntityFactory<Abstract>;
    analyticalResultFactory: IEntityFactory<AnalyticalResult>;
    announcementFactory: IEntityFactory<Announcement>;
    assessmentCategoryFactory: IEntityFactory<AssessmentCategory>;
    authorFactory: IEntityFactory<Author>;
    bioDataStudySubTypeFactory: IEntityFactory<BioDataStudySubType>;
    biologicalDataFactory: IEntityFactory<BiologicalData>;
    clusterGroupFactory: IEntityFactory<ClusterGroup>;
    clusterPartFactory: IEntityFactory<ClusterPart>;
    clusterPathFactory: IEntityFactory<ClusterPath>;
    experimentalMaterialFactory: IEntityFactory<ExperimentalMaterial>;
    experimentalResultFactory: IEntityFactory<ExperimentalResult>;
    experimentalToxicEffectFactory: IEntityFactory<ExperimentalToxicEffect>;
    exposureAdditionalMaterialFactory: IEntityFactory<ExposureAdditionalMaterial>;
    exposureAdditionalMaterialSurveyFactory: IEntityFactory<ExposureAdditionalMaterialSurvey>;
    exposureAdditionalMaterialSurveyMeasureFactory: IEntityFactory<ExposureAdditionalMaterialSurveyMeasure>;
    exposureSurveyFactory: IEntityFactory<ExposureSurvey>;
    foodStatusFactory: IEntityFactory<FoodStatus>;
    fSAGFactory: IEntityFactory<FSAG>;
    houseStatusFactory: IEntityFactory<HouseStatus>;
    journalFactory: IEntityFactory<Journal>;
    journalReserveFactory: IEntityFactory<JournalReserve>;
    journalSubscriptionFactory: IEntityFactory<JournalSubscription>;
    materialAdditionalIdentifierFactory: IEntityFactory<MaterialAdditionalIdentifier>;
    materialComponentFactory: IEntityFactory<MaterialComponent>;
    materialCompositionFactory: IEntityFactory<MaterialComposition>;
    materialConsumptionFactory: IEntityFactory<MaterialConsumption>;
    materialExposureSurveyFactory: IEntityFactory<MaterialExposureSurvey>;
    materialExposureSurveyIsomerFactory: IEntityFactory<MaterialExposureSurveyIsomer>;
    materialExposureSurveyMeasureFactory: IEntityFactory<MaterialExposureSurveyMeasure>;
    materialFSAGFactory: IEntityFactory<MaterialFSAG>;
    materialFactory: IEntityFactory<Material>;
    materialRelationFactory: IEntityFactory<MaterialRelation>;
    materialUsesFactory: IEntityFactory<MaterialUses>;
    nationalToxicologyProgramFactory: IEntityFactory<NationalToxicologyProgram>;
    naturalOccurrenceDescriptorFactory: IEntityFactory<NaturalOccurrenceDescriptor>;
    predictionFactory: IEntityFactory<Prediction>;
    projectFactory: IEntityFactory<Project>;
    rEACHRegistrationFactory: IEntityFactory<REACHRegistration>;
    referenceFactory: IEntityFactory<Reference>;
    referenceAuthorFactory: IEntityFactory<ReferenceAuthor>;
    referenceRelationFactory: IEntityFactory<ReferenceRelation>;
    regulatoryStatusFactory: IEntityFactory<RegulatoryStatus>;
    reportingOrganizationFactory: IEntityFactory<ReportingOrganization>;
    rifmDocumentFactory: IEntityFactory<RIFMDocument>;
    rifmDocumentFolderFactory: IEntityFactory<RIFMDocumentFolder>;
    rIFMProductFactory: IEntityFactory<RIFMProduct>;
    rIFMProductLicenseFactory: IEntityFactory<RIFMProductLicense>;
    rIFMReportFactory: IEntityFactory<RIFMReport>;
    specialLinkFactory: IEntityFactory<SpecialLink>;
    surveyReportedUseFactory: IEntityFactory<SurveyReportedUse>;
    synonymFactory: IEntityFactory<Synonym>;
    typeExperimentVehicleFactory: IEntityFactory<TypeExperimentVehicle>;
    typePredictionFactory: IEntityFactory<TypePrediction>;
    typePredictionClassFactory: IEntityFactory<TypePredictionClass>;
    typeReferenceRelationFactory: IEntityFactory<TypeReferenceRelation>;
    userAcknowledgementFactory: IEntityFactory<UserAcknowledgement>;
    userChangeReportAuthorFactory: IEntityFactory<UserChangeReportAuthor>;
    userChangeReportFactory: IEntityFactory<UserChangeReport>;
    userChangeReportMaterialFactory: IEntityFactory<UserChangeReportMaterial>;
    userChangeReportStudyFactory: IEntityFactory<UserChangeReportStudy>;
    userChangeReportTopicFactory: IEntityFactory<UserChangeReportTopic>;
    workflowRecordedActionFactory: IEntityFactory<WorkflowRecordedAction>;
    workflowMaterialRecordedActionFactory: IEntityFactory<WorkflowMaterialRecordedAction>;

    constructor(public _emProvider: EntityManagerProvider, public _userManager: UserManager, public _errorLogger: ErrorLogger) {
        this.analyticalResultRepository = this.createRepository<AnalyticalResult>('AnalyticalResult', 'Materials/AnalyticalResults');
        this.assessmentCategoryRepository = this.createRepository<AssessmentCategory>('AssessmentCategory', 'Misc/AssessmentCategories');
        this.announcementRepository = this.createRepository<Announcement>('Announcement', 'Misc/Announcements');
        this.bioDataStudySubTypeRepository = this.createRepository<BioDataStudySubType>('BioDataStudySubType', 'BiologicalDatas/GetBioDataStudySubTypes' );
        this.biologicalDataRepository = this.createRepository<BiologicalData>('BiologicalData', 'BiologicalDatas/get');
        this.clusterGroupRepository = this.createRepository<ClusterGroup>('ClusterGroup', 'MaterialClusters/ClusterGroups');
        this.clusterPathRepository = this.createRepository<ClusterPath>('ClusterPath', 'MaterialClusters/ClusterPaths');
        this.docUniqReferenceRepository = this.createRepository<DocUniqReference>('DocUniqReference', 'References/DocUniqReferences');
        this.ecoSARToxicEffectRepository = this.createRepository<ECOSARToxicEffect>('ECOSARToxicEffect', 'Materials/ECOSARToxicEffects');
        this.epiSuiteRepository = this.createRepository<EPISuite>('EPISuite', 'Materials/EPISuites');
        this.experimentalMaterialRepository = this.createRepository<ExperimentalMaterial>('ExperimentalMaterial', 'Materials/ExperimentalMaterials');
        this.exposureAdditionalMaterialRepository = this.createRepository<ExposureAdditionalMaterial>('ExposureAdditionalMaterial', 'ExposureSurveys/ExposureAdditionalMaterials');
        this.exposureSurveyRepository = this.createRepository<ExposureSurvey>('ExposureSurvey', 'ExposureSurveys/ExposureSurveys');
        this.exposureSurveyMaterialMostRecentRepository = this.createRepository<ExposureSurveyMaterialMostRecent>('ExposureSurveyMaterialMostRecent',
            'ExposureSurveys/ExposureSurveyMaterialMostRecents');
        this.fSAGRepository = this.createRepository<FSAG>('FSAG', 'Materials/FSAGs');
        this.foodStatusRepository = this.createRepository<FoodStatus>('FoodStatus', 'Materials/FoodStatuses');
        this.houseStatusRepository = this.createRepository<HouseStatus>('HouseStatus', 'Materials/HouseStatuses');
        this.labelingManualPublicationRepository = this.createRepository<LabelingManualPublication>('LabelingManualPublication', 'Misc/LabelingManualPublications');
        this.materialAdditionalIdentifierRepository = this.createRepository<MaterialAdditionalIdentifier>('MaterialAdditionalIdentifier', 'Materials/MaterialAdditionalIdentifier');
        this.materialAlertRuleRepository = this.createRepository<MaterialAlertRule>('MaterialAlertRule', 'Materials/MaterialAlertRules');
        this.materialComponentRepository = this.createRepository<MaterialComponent>('MaterialComponent', 'Materials/MaterialComponents');
        this.materialCompositionRepository = this.createRepository<MaterialComposition>('MaterialComposition', 'Materials/MaterialCompositions');
        this.materialConsumptionRepository = this.createRepository<MaterialConsumption>('MaterialConsumption', 'Materials/MaterialConsumptions');
        this.materialExpertPanelSummaryRepository = this.createRepository<MaterialExpertPanelSummary>('MaterialExpertPanelSummary', 'Materials/MaterialExpertPanelSummaries');
        this.materialExposureSurveyRepository = this.createRepository<MaterialExposureSurvey>('MaterialExposureSurvey', 'ExposureSurveys/MaterialExposureSurveys');
        this.materialExposureSurveyIsomerRepository = this.createRepository<MaterialExposureSurveyIsomer>('MaterialExposureSurveyIsomer', 'ExposureSurveys/MaterialExposureSurveyIsomersByMaterialId');
        this.materialExposureSurveyMeasureRepository = this.createRepository<MaterialExposureSurveyMeasure>('MaterialExposureSurveyMeasure', 'ExposureSurveys/MaterialExposureSurveyMeasures');
        this.materialFSAGRepository = this.createRepository<MaterialFSAG>('MaterialFSAG', 'Materials/MaterialFSAGs');
        this.materialRelationRepository = this.createRepository<MaterialRelation>('MaterialRelation', 'Materials/MaterialRelations');
        this.materialReciprocalRelationRepository = this.createRepository<MaterialRelation>('MaterialRelation', 'Materials/ReciprocalMaterialRelations');
        this.materialRepository = this.createRepository<Material>('Material', 'Materials/Materials');
        this.materialUsesRepository = this.createRepository<MaterialUses>('MaterialUses', 'Materials/MaterialUseses');
        this.materialAssessmentCategoriesRepository = this.createRepository<MaterialAssessmentCategory>('MaterialAssessmentCategory', 'Material/MaterialAssessmentCategories');
        this.nationalToxicologyProgramRepository = this.createRepository<NationalToxicologyProgram>('NationalToxicologyProgram', 'Materials/NationalToxicologyPrograms');
        this.naturalOccurrenceDescriptorRepository = this.createRepository<NaturalOccurrenceDescriptor>('NaturalOccurrenceDescriptor', 'Materials/NaturalOccurrenceDescriptors');
        this.predictionRepository = this.createRepository<Prediction>('Prediction', 'Materials/Predictions');
        this.projectRepository = this.createRepository<Project>('Project', 'References/Projects');
        this.rEACHRegistrationRepository = this.createRepository<REACHRegistration>('REACHRegistration', 'Materials/REACHRegistrations');
        this.reportingOrganizationRepository = this.createRepository<ReportingOrganization>('ReportingOrganization', 'MaterialReportedUses/ReportingOrganizations');
        this.rIFMProductLicenseRepository = this.createRepository<RIFMProductLicense>('RIFMProductLicense', 'Misc/RIFMProductLicenses');
        this.rIFMProductRepository = this.createRepository<RIFMProduct>('RIFMProduct', 'Misc/RIFMProducts');
        this.rIFMReportRepository = this.createRepository<RIFMReport>('RIFMReport', 'Misc/RIFMReports');
        this.referenceAuthorRepository = this.createRepository<ReferenceAuthor>('ReferenceAuthor', 'References/ReferenceAuthors', false, 'author,reference');
        this.referenceRelationRepository = this.createRepository<ReferenceRelation>('ReferenceRelation', 'References/ReferenceRelations');
        this.referenceRepository = new ReferenceRepository(this._emProvider, 'Reference', '', 'References/get');
        this.regulatoryStatusRepository = this.createRepository<RegulatoryStatus>('RegulatoryStatus', 'Materials/RegulatoryStatuses');
        this.rifmDocumentRepository = this.createRepository<RIFMDocument>('RIFMDocument', 'DocumentManagement/RIFMDocuments');
        this.rifmDocumentFolderRepository = this.createRepository<RIFMDocumentFolder>('RIFMDocumentFolder', 'DocumentManagement/RIFMDocumentFolders');
        this.specialLinkMaterialRepository = this.createRepository<SpecialLink>('SpecialLink', 'Materials/SpecialLinks');
        this.specialLinkReferenceRepository = this.createRepository<SpecialLink>('SpecialLink', 'References/SpecialLinks');
        this.surveyRepository = this.createRepository<Survey>('Survey', 'Misc/Surveys');
        this.surveyAndCompanyRepository = this.createRepository<SurveyAndCompany>('Survey', 'MaterialReportedUses/SurveyAndCompanies');
        this.surveyReportedUseRepository = this.createRepository<SurveyReportedUse>('SurveyReportedUse', 'Misc/SurveyReportedUses');
        this.surveyUsesByMaterialAndYearRepository = this.createRepository<SurveyUsesByMaterialAndYear>('SurveyUsesByMaterialAndYear', 'Misc/SurveyUsesByMaterialAndYear');
        this.surveyWhoUsedMostRepository = this.createRepository<SurveyWhoUsedMostMaterial>('SurveyWhoUsedMostMaterial', 'MaterialReportedUses/SurveyWhoUsedMostMaterials');
        this.synonymRepository = this.createRepository<Synonym>('Synonym', 'Materials/Synonyms');
        this.userChangeReportRepository = this.createRepository<UserChangeReport>('UserChangeReport', 'Misc/UserChangeReports');
        this.userAcknowledgementRepository = this.createRepository<UserAcknowledgement>('UserAcknowledgement', 'Misc/UserAcknowledgement');
        this.workflowRecordedActionRepository = this.createRepository<WorkflowRecordedAction>('WorkflowRecordedAction', 'Misc/WorkflowRecordedAction');
        this.workflowMaterialRecordedActionRepository = this.createRepository<WorkflowMaterialRecordedAction>('WorkflowMaterialRecordedAction', 'Misc/WorkflowMaterialRecordedAction');

        // Not all lookup tables have ( or need ) a Repository
        this.typeAlertRuleRepository = this.createRepository<TypeAlertRule>('TypeAlertRule', 'Lookups', true);
        this.typeAnalyticalProcedureRepository = this.createRepository<TypeAnalyticalProcedure>('TypeAnalyticalProcedure', 'Lookups', true);
        this.typeBotanicalSubdivisionRepository = this.createRepository<TypeBotanicalSubdivision>('TypeBotanicalSubdivision', 'Lookups', true);
        this.typeChangeReportFrequencyRespository = this.createRepository<TypeChangeReportFrequency>('TypeChangeReportFrequency', 'Lookups', true);
        this.typeChangeReportTopicRepository = this.createRepository<TypeChangeReportTopic>('TypeChangeReportTopic', 'Lookups', true);
        this.typeComplianceGuidelineRepository = this.createRepository<TypeComplianceGuideline>('TypeComplianceGuideline', 'Misc/TypeComplianceGuidelines');
        this.typeCremeVersionRepository = this.createRepository<TypeCremeVersion>('TypeCremeVersion', 'Lookups', true);
        this.typeEPISuiteCategoryRepository = this.createRepository<TypeEPISuiteCategory>('TypeEPISuiteCategory', 'Lookups', true);
        this.typeEPISuiteParameterRepository = this.createRepository<TypeEPISuiteParameter>('TypeEPISuiteParameter', 'Lookups', true);
        this.typeEssentialOilRepository = this.createRepository<TypeEssentialOil>('TypeEssentialOil', 'Lookups', true);
        this.typeExposureMeasureRepository = this.createRepository<TypeExposureMeasure>('TypeExposureMeasure', 'Lookups', true);
        this.typeExposureMeasureValueRepository = this.createRepository<TypeExposureMeasureValue>('TypeExposureMeasureValue', 'Lookups', true);
        this.typeExposureSurveyResponseRepository = this.createRepository<TypeExposureSurveyResponse>('TypeExposureSurveyResponse', 'Lookups', true);
        this.typeExperimentVehicleRepository = this.createRepository<TypeExperimentVehicle>('TypeExperimentVehicle', 'Lookups', true);
        this.typeExposureSurveyStatusRepository = this.createRepository<TypeExposureSurveyStatus>('TypeExposureSurveyStatus', 'Lookups', true);
        this.typeFoodCategoryRepository = this.createRepository<TypeFoodCategory>('TypeFoodCategory', 'Lookups', true);
        this.typeGeographicalAreaRepository = this.createRepository<TypeGeographicalArea>('TypeGeographicalArea', 'Lookups', true);
        this.typeJournalSubscriptionRepository = this.createRepository<TypeJournalSubscription>('TypeJournalSubscription', 'Misc/TypeJournalSubscriptions');
        this.typeKeywordRepository = this.createRepository<TypeKeyword>('TypeKeyword', 'Lookups', true);
        this.typeKlimischRepository = this.createRepository<TypeKlimisch>('TypeKlimisch', 'Lookups', true);
        this.typeLinkTargetRepository = this.createRepository<TypeLinkTarget>('TypeLinkTarget', 'Lookups', true);
        this.typeMaterialComponentClassRepository = this.createRepository<TypeMaterialComponentClass>('TypeMaterialComponentClass', 'Lookups', true);
        this.typeMaterialIdentifierRepository = this.createRepository<TypeMaterialIdentifier>('TypeMaterialIdentifier', 'Lookups', true);
        this.typeMaterialRelationRepository = this.createRepository<TypeMaterialRelation>('TypeMaterialRelation', 'Lookups', true);
        this.typeMaterialUnitRepository = this.createRepository<TypeMaterialUnit>('TypeMaterialUnit', 'Misc/TypeMaterialUnits');
        this.typeNTPStatusRepository = this.createRepository<TypeNTPStatus>('TypeNTPStatus', 'Lookups', true);
        this.typeNaturalOccurrenceRepository = this.createRepository<TypeNaturalOccurrence>('TypeNaturalOccurrence', 'Lookups', true);
        this.typePredictionClassRepository = this.createRepository<TypePredictionClass>('TypePredictionClass', 'Lookups', true);
        this.typePredictionRepository = this.createRepository<TypePrediction>('TypePrediction', 'Lookups', true);
        this.typePublicationRepository = this.createRepository<TypePublication>('TypePublication', 'Lookups', true);
        this.typeREACHTonnageBandRepository = this.createRepository<TypeREACHTonnageBand>('TypeREACHTonnageBand', 'Lookups', true);
        this.typeReferenceRelationRepository = this.createRepository<TypeReferenceRelation>('TypeReferenceRelation', 'Misc/TypeReferenceRelations');
        this.typeRegulatoryStatusRepository = this.createRepository<TypeRegulatoryStatus>('TypeRegulatoryStatus', 'Lookups', true);
        this.typeRIFMDocumentRepository = this.createRepository<TypeRIFMDocument>('TypeRIFMDocument', 'Lookups', true);
        this.typeRouteRepository = this.createRepository<TypeRoute>('TypeRoute', 'Lookups', true);
        this.typeSearchRepository = this.createRepository<TypeSearch>('TypeSearch', 'Lookups', true);
        this.typeSearchServiceRepository = this.createRepository<TypeSearchService>('TypeSearchService', 'Lookups', true);
        this.typeSpeciesRepository = this.createRepository<TypeSpecies>('TypeSpecies', 'Lookups', true);
        this.typeStudyRepository = this.createRepository<TypeStudy>('TypeStudy', 'Lookups', true);
        this.typeStudyMixtureRepository = this.createRepository<TypeStudyMixture>('TypeStudyMixture', 'Lookups', true);
        this.typeStudyDesignationRepository = this.createRepository<TypeStudyDesignation>('TypeStudyDesignation', 'Misc/TypeStudyDesignations');
        this.typeStudySubTypeRepository = this.createRepository<TypeStudySubType>('TypeStudySubType', 'Lookups', true);
        this.typeSynonymUseRepository = this.createRepository<TypeSynonymUse>('TypeSynonymUse', 'Lookups', true);
        this.typeTimeUnitRepository = this.createRepository<TypeTimeUnit>('TypeTimeUnit', 'Lookups', true);
        this.typeToxicEffectRepository = this.createRepository<TypeToxicEffect>('TypeToxicEffect', 'Lookups', true);
        this.typeTSCAClassRepository = this.createRepository<TypeTSCAClass>('TypeTSCAClass', 'Lookups', true);
        this.typeUnitRepository = this.createRepository<TypeUnit>('TypeUnit', 'Lookups', true);
        this.typeUsefulnessRepository = this.createRepository<TypeUsefulness>('TypeUsefulness', 'Lookups', true);
        this.typeWorkflowActionRepository = this.createRepository<TypeWorkflowAction>('TypeWorkflowAction', 'Lookups', true);
        this.typeWorkflowActionTransitionRepository = this.createRepository<TypeWorkflowActionTransition>('TypeWorkflowActionTransition', 'Lookups', true);
        this.typeWorkflowContactRepository = this.createRepository<TypeWorkflowContact>('TypeWorkflowContact', 'Lookups', true);
        this.typeWorkflowMaterialActionRepository = this.createRepository<TypeWorkflowMaterialAction>('TypeWorkflowMaterialAction', 'Lookups', true);
        this.typeWorkflowMaterialActionTransitionRepository = this.createRepository<TypeWorkflowMaterialActionTransition>('TypeWorkflowMaterialActionTransition', 'Lookups', true);

        this.abstractFactory = this.createFactory<Abstract>('Abstract');
        this.analyticalResultFactory = this.createFactory<AnalyticalResult>('AnalyticalResult');
        this.announcementFactory = this.createFactory<Announcement>('Announcement');
        this.assessmentCategoryFactory = this.createFactory<AssessmentCategory>('AssessmentCategory');
        this.authorFactory = this.createFactory<Author>('Author');
        this.bioDataStudySubTypeFactory = this.createFactory('BioDataStudySubType');
        this.biologicalDataFactory = this.createFactory('BiologicalData');
        this.experimentalMaterialFactory = this.createFactory<ExperimentalMaterial>('ExperimentalMaterial');
        this.experimentalResultFactory = this.createFactory<ExperimentalResult>('ExperimentalResult');
        this.experimentalToxicEffectFactory = this.createFactory<ExperimentalToxicEffect>('ExperimentalToxicEffect');
        this.exposureAdditionalMaterialFactory = this.createFactory<ExposureAdditionalMaterial>('ExposureAdditionalMaterial');
        this.exposureAdditionalMaterialSurveyFactory = this.createFactory<ExposureAdditionalMaterialSurvey>('ExposureAdditionalMaterialSurvey');
        this.exposureAdditionalMaterialSurveyMeasureFactory = this.createFactory<ExposureAdditionalMaterialSurveyMeasure>('ExposureAdditionalMaterialSurveyMeasure');
        this.exposureSurveyFactory = this.createFactory<ExposureSurvey>('ExposureSurvey');
        this.foodStatusFactory = this.createFactory<FoodStatus>('FoodStatus');
        this.fSAGFactory = this.createFactory<FSAG>('FSAG');
        this.houseStatusFactory = this.createFactory<HouseStatus>('HouseStatus');
        this.journalFactory = this.createFactory<Journal>('Journal');
        this.journalReserveFactory = this.createFactory<JournalReserve>('JournalReserve');
        this.journalSubscriptionFactory = this.createFactory<JournalSubscription>('JournalSubscription');
        this.materialAdditionalIdentifierFactory = this.createFactory<MaterialAdditionalIdentifier>('MaterialAdditionalIdentifier');
        this.materialFSAGFactory = this.createFactory<MaterialFSAG>('MaterialFSAG');
        this.materialFactory = this.createFactory<Material>('Material');
        this.materialComponentFactory = this.createFactory<MaterialComponent>('MaterialComponent');
        this.materialCompositionFactory = this.createFactory<MaterialComposition>('MaterialComposition');
        this.materialConsumptionFactory = this.createFactory<MaterialConsumption>('MaterialConsumption');
        this.materialRelationFactory = this.createFactory<MaterialRelation>('MaterialRelation');

        this.materialExposureSurveyFactory = this.createFactory<MaterialExposureSurvey>('MaterialExposureSurvey');
        this.materialExposureSurveyIsomerFactory = this.createFactory<MaterialExposureSurveyIsomer>('MaterialExposureSurveyIsomer');
        this.materialExposureSurveyMeasureFactory = this.createFactory<MaterialExposureSurveyMeasure>('MaterialExposureSurveyMeasure');
        this.materialUsesFactory = this.createFactory<MaterialUses>('MaterialUses');
        this.nationalToxicologyProgramFactory = this.createFactory<NationalToxicologyProgram>('NationalToxicologyProgram');
        this.naturalOccurrenceDescriptorFactory = this.createFactory<NaturalOccurrenceDescriptor>('NaturalOccurrenceDescriptor');
        this.predictionFactory = this.createFactory<Prediction>('Prediction');
        this.projectFactory = this.createFactory<Project>('Project');
        this.rEACHRegistrationFactory = this.createFactory<REACHRegistration>('REACHRegistration');
        this.referenceAuthorFactory = this.createFactory<ReferenceAuthor>('ReferenceAuthor');
        this.reportingOrganizationFactory = this.createFactory<ReportingOrganization>('ReportingOrganization');
        this.rifmDocumentFactory = this.createFactory<RIFMDocument>('RIFMDocument');
        this.rifmDocumentFolderFactory = this.createFactory<RIFMDocumentFolder>('RIFMDocumentFolder');
        this.rIFMProductFactory = this.createFactory<RIFMProduct>('RIFMProduct');
        this.rIFMProductLicenseFactory = this.createFactory<RIFMProductLicense>('RIFMProductLicense');
        this.referenceFactory = this.createFactory<Reference>('Reference');
        this.referenceRelationFactory = this.createFactory<ReferenceRelation>('ReferenceRelation');
        this.regulatoryStatusFactory = this.createFactory<RegulatoryStatus>('RegulatoryStatus');
        this.specialLinkFactory = this.createFactory<SpecialLink>('SpecialLink');
        this.surveyReportedUseFactory = this.createFactory<SurveyReportedUse>('SurveyReportedUse');
        this.synonymFactory = this.createFactory<Synonym>('Synonym');
        this.typeExperimentVehicleFactory = this.createFactory<TypeExperimentVehicle>('TypeExperimentVehicle');
        this.typePredictionFactory = this.createFactory<TypePrediction>('TypePrediction');
        this.typePredictionClassFactory = this.createFactory<TypePredictionClass>('TypePredictionClass');
        this.typeReferenceRelationFactory = this.createFactory<TypeReferenceRelation>('TypeReferenceRelation');
        this.userAcknowledgementFactory = this.createFactory<UserAcknowledgement>('UserAcknowledgement');
        this.userChangeReportAuthorFactory = this.createFactory<UserChangeReportAuthor>('UserChangeReportAuthor');
        this.userChangeReportFactory = this.createFactory<UserChangeReport>('UserChangeReport');
        this.userChangeReportMaterialFactory = this.createFactory<UserChangeReportMaterial>('UserChangeReportMaterial');
        this.userChangeReportTopicFactory = this.createFactory<UserChangeReportTopic>('UserChangeReportTopic');
        this.userChangeReportStudyFactory = this.createFactory<UserChangeReportStudy>('UserChangeReportStudy');
        this.workflowMaterialRecordedActionFactory = this.createFactory<WorkflowMaterialRecordedAction>('WorkflowMaterialRecordedAction');
        this.workflowRecordedActionFactory = this.createFactory<WorkflowRecordedAction>('WorkflowRecordedAction');

    }

    createRepository<T extends Entity>(entityTypeName: string, resourceName: string, isCached: boolean = false, expand: string = '') {
        return new Repository<T>(this._emProvider, entityTypeName, expand, resourceName, isCached);
    }

    createFactory<T extends Entity>(entityTypeName: string) {
        return new EntityFactory<T>(entityTypeName, this._emProvider);
    }

    clearEntityManager() {
        this._emProvider.newManager();
    }

    fetch(resourceName: string, params: {}): Promise<any> {
        const manager = this._emProvider.manager();
        const query = EntityQuery
            .from(resourceName)
            .withParameters(params);
        const p = <Promise<any>><any>manager.executeQuery(query.using(FetchStrategy.FromServer));
        return p.then(data => {
            return data.results;
        }).catch(e => {
            this._errorLogger.log(e, 'E');
            throw e;
        });
    }

    fetchTyped<T>(resourceName: string, type: { new(): T; }, params: {}): Promise<T[]> {
        return this.fetch(resourceName, params).then(r => {
            return <T[]><any>r;
        });
    }

    hasChanges(): boolean {
        return this._emProvider.manager().hasChanges();
    }

    getChanges(args?: any) {
        return this._emProvider.manager().getChanges(args);
    }

    commit(): Promise<any> {
        const saveOptions = new SaveOptions({ resourceName: 'savechanges' });

        this.processAuditFields();
        const p = <Promise<any>><any>this._emProvider.manager().saveChanges(null, saveOptions);
        return p.then((saveResult) => {
            return saveResult;
        }).catch(e => {
            this._errorLogger.log(e, 'E');
            // rethrow...
            throw e;
        });
    }

    commitSelected(entities: Entity[]) {
        const saveOptions = new SaveOptions({ resourceName: 'savechanges' });

        this.processAuditFields(entities);

        return this._emProvider.manager().saveChanges(entities, saveOptions)
            .then((saveResult) => {

            }).catch(e => {
                this._errorLogger.log(e, 'E');
                // rethrow...
                throw e;
            });
    }

    processAuditFields(entities?: Entity[]) {
        const currentUserName = this._userManager.currentUser.name;
        const changedEntities = entities || this._emProvider.manager().getChanges();
        changedEntities.forEach(e => {
            const entityState = e.entityAspect.entityState;
            if (entityState.isAdded()) {
                e['created'] = new Date();
                e['createdUser'] = currentUserName;
            }
            if (entityState.isModified() || entityState.isAdded()) {
                e['modified'] = new Date();
                e['modifyUser'] = currentUserName;
            }
            // var rv = e['rowVersion'];
            // e['rowVersion'] = rv != null ? rv+1 : 0;
        });
    }

    rollback(): void {
        this._emProvider.manager().rejectChanges();
    }
}

export class TypedQuery<T extends Entity> {
    private _query: EntityQuery;
    private _manager: EntityManager;

    constructor(
        protected _type: { new(): T; } = null,
        protected _resourceName: string = null,
        protected _uow: UnitOfWork = null
    ) {
        // for clone
        if (_type == null && _resourceName == null) {
            return;
        }
        this._manager = _uow._emProvider.manager();
        if (_type != null) {
            // const entityTypeName = _type.name;
            // const entityType = <EntityType>this._manager.metadataStore.getEntityType(entityTypeName);
            const entityType = _type.prototype.entityType;
            if (!entityType) {
                throw new Error(_type.name + ' does not exist! Query must be created for an existing entity type!');
            }
            if (!this._resourceName) {
                this._resourceName = entityType.defaultResourceName;
            }
        }
        this._query = EntityQuery.from(this._resourceName);
    }

    where(predicate: any): TypedQuery<T> {
        const q = this.clone();
        q._query = q._query.where(predicate);
        return q;
    }

    withParameters(params: any): TypedQuery<T> {
        const q = this.clone();
        params = extend(q._query.parameters, params);
        q._query = q._query.withParameters(params);
        return q;
    }

    expand(propertyPaths: any) {
        const q = this.clone();
        q._query = q._query.expand(propertyPaths);
        return q;
    }

    using(x: any) {
        const q = this.clone();
        q._query = q._query.using(x);
        return q;
    }

    executeRaw(): Promise<T[]> {
        const p = <Promise<any>><any>this._manager.executeQuery(this._query);
        return p.then(data => {
            return data;
        });
    }

    execute(): Promise<T[]> {
        const p = <Promise<any>><any>this._manager.executeQuery(this._query);
        return p.then(data => {
            return data.results;
        });
    }

    /** execute query to return only the count of entities */
    executeCount(): Promise<number> {
        const cq = this._query.take(0).inlineCount(true);
        const p = <Promise<any>><any>this._manager.executeQuery(cq);
        return p.then(data => {
            return data.inlineCount;
        });
    }

    skip(count: number) {
        const q = this.clone();
        q._query = q._query.skip(count);
        return q;
    }

    take(count: number) {
        const q = this.clone();
        q._query = q._query.take(count);
        return q;
    }

    inlineCount(enabled: boolean) {
        const q = this.clone();
        q._query = q._query.inlineCount(enabled);
        return q;
    }

    orderBy(prop: string, isDescending = false) {
        const q = this.clone();
        q._query = q._query.orderBy(prop, isDescending);
        return q;
    }

    orderByDesc(prop: string) {
        return this.orderBy(prop, true);
    }

    noTracking() {
        const q = this.clone();
        q._query = q._query.noTracking();
        return q;
    }

    private clone() {
        const q = new TypedQuery<T>();
        q._query = this._query;
        q._manager = this._manager;
        q._type = this._type;
        q._resourceName = this._resourceName;
        q._uow = this._uow;
        return q;
    }
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';

import { SearchService, StateMap, UnitOfWork, UserManager } from '../../services/common';
import { LocationFns, UtilFns } from '../../utils/common';
import { ColumnSorter, PageState, SortColumn, SortMap } from '../../controls/common';

import { RelationsMaterialComponent } from './relations-material.component';
import { Material, MaterialRelation, TypeMaterialRelation } from '../../entities/EntityModels';
import { MaterialRelationListItem } from '../../entities/projections/MaterialRelationListItem';
import { ROUTES } from '../routes';

class PageStateExt extends PageState {
    sortColumn1: SortColumn;
}

@Component({
    selector: 'relations-materials',
    templateUrl: './relations-materials.html',
})
export class RelationsMaterialsComponent implements OnInit {
    @ViewChild(RelationsMaterialComponent) _editComponent: RelationsMaterialComponent;

    // Can't use editManager here because MaterialRelationListItem is not an Entity.
    _material: Material;
    _items: MaterialRelationListItem[];
    _currentItem: MaterialRelationListItem;
    _addedMaterialRelation: MaterialRelation;
    _materialRelations: MaterialRelation[]; // only resolved if staff.
    _reciprocalMaterialRelations: MaterialRelation[];
    _containsType: TypeMaterialRelation;
    _editing = false;
    _deleting = false;
    _status: {};
    _colSorter1: ColumnSorter;
    _isReciprocalRelation: boolean;

    _pageState = new PageStateExt('Related materials');

    constructor(public _uow: UnitOfWork, public _userManager: UserManager, public _stateMap: StateMap,
                public _searchService: SearchService, public _router: Router, public _route: ActivatedRoute, public _location: Location) {
    }

    ngOnInit() {
        this._material = this._stateMap.currentMaterial;

        LocationFns.setTab(this._location, 0);

        this._uow.typeMaterialRelationRepository.all().then(r => {
            this._containsType = r.find(tmr => tmr.relationType == 'contains');
        });
        this.getMaterialRelations()
            .then(r => {
                // note: canAdd is waiting for this.
            });

        const sortConfig: SortMap = {
            'Relation Type': (e: MaterialRelationListItem) => e.relationType,
            'CAS No.': (e: MaterialRelationListItem) => e.realCASNumber,
            'RIFM ID': (e: MaterialRelationListItem) => e.formattedRifmId,
            'FEMA No.': (e: MaterialRelationListItem) => (e.fEMANumber || '').toString(),
            'EINECS': (e: MaterialRelationListItem) => e.eINECSNumber,
            'Principal Name': (e: MaterialRelationListItem) => e.synonymWordOrWebVersion,
        };
        this._colSorter1 = new ColumnSorter(this, sortConfig, 'Principal Name',
            (sortColumn) => this._pageState.sortColumn1 = sortColumn);

        // setTimeout needed because of Angular RC5 bug where Location is not set correctly until
        // after timeout resolves.
        setTimeout(() => {
            const returned = LocationFns.updatePageState(this._location, this._stateMap, this._pageState, (state: PageState) => {
                this._colSorter1.sortColumn = (<PageStateExt>state).sortColumn1;
            });

            this.search();
        }, 0);

    }

    search() {
        const materialId = this._material.materialId;
        this._pageState.isLoading = true;
        const p = {materialId: materialId};
        this._uow.fetch('Materials/MaterialRelationsWithReciprocals', p).then(r => {
            if (r == null || r.length == 0) {
                this._pageState.isLoaded = [];
                return;
            }
            this._items = r;
            this.sortWith(this._colSorter1);
            if (this._items.length) {
                this._pageState.isLoaded = true;
            } else {
                this._pageState.isLoadedWithNoData = true;
            }
        });

    }

    public getMaterialRelations(): Promise<MaterialRelation[]> {
        if (this._materialRelations) {
            return Promise.resolve(this._materialRelations);
        }
        const materialId = this._material.materialId;

        this._uow.materialReciprocalRelationRepository.whereWithParams({relatedMaterialId: materialId}).then(r => {
            this._reciprocalMaterialRelations = r;
            return r;
        });
        return this._uow.materialRelationRepository.whereWithParams({materialId: materialId}).then(r => {
            this._materialRelations = r;
            return r;
        });
    }

    isStaff() {
        return ((this._stateMap.currentUser.isStaff) && (this._stateMap.currentUser.isInRole('fema') == false));
    }

    goToSynonyms(mrli: MaterialRelationListItem) {
        if (this._material.materialId == mrli.materialId) {
            this._router.navigate(UtilFns.asRouterParts(ROUTES.Material, mrli.relatedMaterialId));
        } else {
            this._router.navigate(UtilFns.asRouterParts(ROUTES.Material, mrli.materialId));
        }
    }

    canDeactivate() {
        return !this.hasEdits();
    }


    sortWith(colSorter: ColumnSorter) {
        if (colSorter == this._colSorter1) {
            colSorter.sort(this._items);
        }
    }


    onSelect(e: MaterialRelationListItem) {
        if (!this.isStaff()) {
            return;
        }
        if (!this.isValid()) {
            return;
        }
        this._isReciprocalRelation = false;
        let filteredItems = this._materialRelations.filter(mr => (mr.materialId == e.materialId)
            && (mr.relatedMaterialId == e.relatedMaterialId)
            && (mr.typeMaterialRelation && (mr.typeMaterialRelation.reciprocalRelationType == e.relationType || mr.typeMaterialRelation.relationType == e.relationType)));

        if (filteredItems.length == 0) {
            filteredItems = this._reciprocalMaterialRelations.filter(mr => (mr.materialId == e.materialId)
                && (mr.relatedMaterialId == e.relatedMaterialId)
                && (mr.typeMaterialRelation && (mr.typeMaterialRelation.reciprocalRelationType == e.relationType || mr.typeMaterialRelation.relationType == e.relationType)));
            this._isReciprocalRelation = true;
        }
        if (filteredItems.length == 0) {
            return;
        }
        this._addedMaterialRelation = filteredItems[0];
        this._editing = true;
    }

    hasEditableRelation(e: MaterialRelationListItem) {
        if (!this._materialRelations || !this._reciprocalMaterialRelations) {
            return false;
        }
        let filteredItems = this._materialRelations.filter(mr => (mr.materialId == e.materialId)
            && (mr.relatedMaterialId == e.relatedMaterialId)
            && (mr.typeMaterialRelation && (mr.typeMaterialRelation.reciprocalRelationType == e.relationType || mr.typeMaterialRelation.relationType == e.relationType)));

        if (filteredItems.length == 0) {
            filteredItems = this._reciprocalMaterialRelations.filter(mr => (mr.materialId == e.materialId)
                && (mr.relatedMaterialId == e.relatedMaterialId)
                && (mr.typeMaterialRelation && (mr.typeMaterialRelation.reciprocalRelationType == e.relationType || mr.typeMaterialRelation.relationType == e.relationType)));
        }
        return filteredItems.length > 0;
    }

    canAdd() {
        // need to wait until materialRelations have been fetched
        return this._materialRelations != null;
    }

    canSave() {
        return this.hasEdits() && this.isValid(this._deleting);
        // if (!this._edm.hasEdits()) return false;
        // // this._edm.canSaveCore does not include next line.
        // if (!this._edm.validateBeforeSave()) return false;
        // return true;
    }

    hasEdits() {
        return this._uow.hasChanges();
    }


    isValid(deleting: boolean = false) {
        if (this._editing) {
            return this._editComponent && this._editComponent.isValid(deleting);
        } else {
            return true;
        }

    }

    canDelete() {
        return this._addedMaterialRelation != null && !this._addedMaterialRelation.entityAspect.entityState.isAdded();
    }

    onAdd() {
        if (!this.isValid()) {
            return;
        }
        this._isReciprocalRelation = false;
        const config = {
            materialId: this._material.materialId,
            typeMaterialRelationId: this._containsType ? this._containsType.typeMaterialRelationId : 0,
            relatedMaterialId: 0 // needed for create to succeed.
        };
        const entity = this._uow.materialRelationFactory.create(config);
        entity.relatedMaterialId = null;
        this._addedMaterialRelation = entity;
        this._editing = true;

    }

    onDelete() {
        this._deleting = true;
        this._editComponent.rejectChanges();
    }

    canCancel() {
        if (this._editing) {
            return true;
        } else {
            return this.hasEdits();
        }
    }

    onCancel() {
        if (this._editing) {
            const e = this._addedMaterialRelation;
            e.entityAspect.rejectChanges();
            this._editing = false;
            this._deleting = false;
        } else {
            this._uow.rollback();
            this.search();
        }
    }

    onSave() {
        if (!this.isValid(this._deleting)) {
            return;
        }
        this._editing = false;
        this._deleting = false;

        const amr = this._addedMaterialRelation;
        if (amr.entityAspect.entityState.isModified()) {
            // typeMaterialRelationId has changed, and it's part of the key, so we need to delete & add
            const config = {
                materialId: amr.materialId,
                relatedMaterialId: amr.relatedMaterialId,
                typeMaterialRelationId: amr.typeMaterialRelationId,
            };
            // delete entity in the old state
            amr.entityAspect.rejectChanges();
            if (amr.materialId != config.materialId ||
                amr.relatedMaterialId != config.relatedMaterialId ||
                amr.typeMaterialRelationId != config.typeMaterialRelationId) {
                amr.entityAspect.setDeleted();
                // create entity for the new state
                const entity = this._uow.materialRelationFactory.create(config);
            }
        }

        this._uow.commit().then(() => {
            this._materialRelations = null;
            this.getMaterialRelations();
            this.search();
            this.setStatus({message: 'Saved', classes: 'label-success', isTemp: true});
        }).catch(e => {
            alert('save failed: ' + e);
        });
    }

    setStatus(status: { message: string, classes: string, isTemp: boolean }) {
        status.classes = 'label ' + status.classes;
        this._status = status;
        if (status.isTemp) {
            setTimeout(() => {
                this._status = null;
            }, 2000);
        }
    }


}

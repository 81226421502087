import { EntityBase } from './EntityBase';
import { ClusterPath } from './ClusterPath';

import { Validator } from 'breeze-client';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

export class ClusterPart extends EntityBase {

   /// <code> Place custom code between <code> tags
   
   /// </code>

   // Generated code. Do not place code below this line.
   clusterPartId: number;
   clusterPartName: string;
   clusterPartLabel: string;
   clusterPathId: number;
   description: string;
   precedence: number;
   created: Date;
   createdUser: string;
   modified: Date;
   modifyUser: string;
   rowVersion: number;
   clusterPath: ClusterPath;
}


<h3 style="margin-left: 15px;">Prediction Types and Classes</h3>

<div class="row" style="margin-top: 12px; width: 96%; margin-left: 14px;">
    <tab-container>
        <ng-template tab-pane [canDeactivate]="canDeactivate()" title="Prediction Types">
            <div style="margin-left: 12px;margin-bottom:12px;">
                <type-predictions #self></type-predictions>
            </div>
        </ng-template>
        <ng-template tab-pane [canDeactivate]="canDeactivate()" title="Prediction Classes">
            <div style="margin-left: 12px;margin-bottom:12px;">
                <type-prediction-classes #self></type-prediction-classes>
            </div>
        </ng-template>
    </tab-container>
</div>

import { EntityBase } from './EntityBase';
import { Material } from './Material';
import { MaterialComponent } from './MaterialComponent';

import { Validator } from 'breeze-client';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

export class MaterialComposition extends EntityBase {

   /// <code> Place custom code between <code> tags
   
   /// </code>

   // Generated code. Do not place code below this line.
   materialCompositionId: number;
   materialId: number;
   materialComponentId: number;
   componentAmount: number;
   componentValue: string;
   created: Date;
   createdUser: string;
   modified: Date;
   modifyUser: string;
   rowVersion: number;
   material: Material;
   materialComponent: MaterialComponent;
}


import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';

import { SearchService } from '../../services/common';
import { UtilFns } from '../../utils/common';
import { ModalDialog } from '../../controls/common';

import { MaterialListItem } from '../../entities/projections/MaterialListItem';
import { IMaterialResultsParent, MaterialResultsComponent } from './material-results.component';

@Component({
    selector: 'material-selector',
    templateUrl: './material-selector.html',
})
export class MaterialSelectorComponent extends ModalDialog<MaterialListItem> implements IMaterialResultsParent, OnInit {
    @ViewChild(MaterialResultsComponent, { static: true }) _materialResultsComponent: MaterialResultsComponent;

    _searchTerm: string;
    _entities: MaterialListItem[];

    constructor(public elementRef: ElementRef, public _searchService: SearchService) {
        super(elementRef);
    }

    ngOnInit() {
    }

    canSearch() {
        return this._searchTerm && this._searchTerm.length > 1;
    }

    onSearch() {
        if (!this.canSearch()) {
            return;
        }
        const params = {
            searchTerm: this._searchTerm,
            searchType: 'searchall',
            pageNumber: 0,
            pageSize: 50
        };
        this.validationMessage = null;
        this._materialResultsComponent.loading(true);
        this._entities = null;
        this._searchService.fetchMaterialsPage(params).then(r => {
            this._entities = r.materials;
            this._materialResultsComponent.update(this, r);
        }).catch(e => {
            this._materialResultsComponent.loading(false);
        });
    }

    // called from MaterialResultsComponent
    selectMaterial(mli: MaterialListItem) {
        UtilFns.returnModal(this, mli);
    }


}


<div class="indent-left-md">

    <div class="home-page-title">Database Subscription Information and Application Forms</div>
    <h5>Please download the application and send the completed form to the email address provided on the application document.</h5>

    <p class="alert-warning bold">{{_validationMessage}}</p>
    <div class="row">
        <i *ngIf="_isLoading" class="fa fa-circle-o-notch fa-spin" style="font-size:38px; color: #337ab7; margin-left: 24px;"></i>
    </div>

    <div class="col-md-10 database-subscription-container" style="margin-right: 30px">
        <div class="panel-body">

            <div *ngIf="_databaseApplicationFiles != null && _databaseApplicationFiles.length > 0">
                <div *ngFor="let a of _databaseApplicationFiles">
                    <div class="row">
                        <label class="bold col-md-2 database-subscription-title">Application:</label>
                        <div class="col-md-6 bold database-subscription-link"><a (click)="downloadFile(a)">{{a.fileLabel}}</a></div>
                    </div>
                </div>
            </div>

            <div class="row database-subscription-container">
                <span class="bold col-md-2 database-subscription-title">{{_yearLabel}}</span>
                <div class="col-md-6 bold page-title">

                    <div *ngIf="_databaseApplicationFiles != null && _databaseApplicationFiles.length > 0">
                        <ul class="database-subscription-list">
                            <div *ngFor="let p of _databasePricingFiles">
                                <li class="bold database-subscription-link"><a (click)="downloadFile(p)">{{p.fileLabel}}</a></li>
                            </div>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>

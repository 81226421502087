import { Component, Input, OnInit, ViewChild } from '@angular/core';
import * as _ from 'lodash';

import { provideParent, StateMap, UnitOfWork } from '../../services/common';
import { UtilFns } from '../../utils/common';
import { EditPropParent } from '../../controls/common';

import { Material, MaterialRelation, TypeMaterialRelation } from '../../entities/EntityModels';
import { MaterialListItem } from '../../entities/projections/MaterialListItem';
import { MaterialSelectorComponent } from './material-selector.component';

@Component({
    selector: 'relations-material',
    templateUrl: './relations-material.html',
    providers: [provideParent(RelationsMaterialComponent, EditPropParent)]
})
export class RelationsMaterialComponent implements OnInit {
    @Input() entity: MaterialRelation;
    @ViewChild(MaterialSelectorComponent, { static: true }) _materialSelectorComponent: MaterialSelectorComponent;
    @Input() reciprocalRelation: boolean;
    // next two vars are used to hold temporarily invalid values.
    _material: Material;
    private _typeMaterialRelationId: number;
    private _reciprocalTypeMaterialRelationId: number;
    _relatedMaterial: MaterialListItem;
    _isEditing: boolean;
    _isNew: boolean;
    _types: TypeMaterialRelation[];
    _reciprocalTypes: TypeMaterialRelation[];
    _editErrorMessage: string;

    constructor(public _uow: UnitOfWork, public _stateMap: StateMap) {

    }

    ngOnInit() {
        this._material = this._stateMap.currentMaterial;
        if (!this.reciprocalRelation) {
            this.reciprocalRelation = false;
        }
        this._isNew = this.entity.entityAspect.entityState.isAdded();

        if (!this.reciprocalRelation) {
            this.typeMaterialRelationId = this.entity.typeMaterialRelationId;
        } else {
            this.reciprocalTypeMaterialRelationId = this.entity.typeMaterialRelationId;
        }

        this._uow.typeMaterialRelationRepository.all().then(r => {
            this._types = _.sortBy(r, x => x.relationType);
            this._reciprocalTypes = this._types;
        });
        if (this.entity) {

            if (!this.reciprocalRelation) {
                this._uow.fetch('Materials/MaterialListItems', {materialIds: this.entity.relatedMaterialId}).then(r => {
                    this._relatedMaterial = r[0];
                    this._isEditing = true;
                });
            } else {
                this._uow.fetch('Materials/MaterialListItems', {materialIds: this.entity.materialId}).then(r => {
                    this._relatedMaterial = r[0];
                    this._isEditing = true;
                });
            }
        }
    }

    get typeMaterialRelationId(): number {
        return this._typeMaterialRelationId;
    }

    set typeMaterialRelationId(val: number) {
        this._typeMaterialRelationId = val;
        if (val > 0) {
            this.reciprocalTypeMaterialRelationId = -1;
        }
    }


    get reciprocalTypeMaterialRelationId(): number {
        return this._reciprocalTypeMaterialRelationId;
    }

    set reciprocalTypeMaterialRelationId(val: number) {
        this._reciprocalTypeMaterialRelationId = val;
        if (val > 0) {
            this.typeMaterialRelationId = -1;
        }
    }

    hasChanges() {
        return (!this.entity.entityAspect.entityState.isUnchanged());
    }

    isAdded() {
        return this.entity.entityAspect.entityState.isAdded();
    }

    isDeleted() {
        return this.entity.entityAspect.entityState.isDeleted();
    }

    rejectChanges() {
        this.entity.entityAspect.rejectChanges();
        this.entity.entityAspect.setDeleted();
        if (this.entity.materialId == this._material.materialId) {
            this.typeMaterialRelationId = this.entity.typeMaterialRelationId;
        } else {
            this.reciprocalTypeMaterialRelationId = this.entity.typeMaterialRelationId;
        }
    }

    onSetRelationId(val: number) {
        try {
            this.typeMaterialRelationId = val;
            if (val <= 0) {
                return;
            }
            this.reciprocalRelation = false;
            this.entity.materialId = 0;
            this.entity.relatedMaterialId = 0;
            this.entity.typeMaterialRelationId = val;
            this.entity.materialId = this._material.materialId;
            this.entity.relatedMaterialId = this._relatedMaterial ? this._relatedMaterial.materialId : null;
            // next two lines are needed in case a collision occured earlier - in that case we need to set back
            // the relatedmaterialId after setting the relationId
            // if (this._relatedMaterial && this._relatedMaterial.materialId != this.entity.relatedMaterialId) {
            //    this.entity.relatedMaterialId = this._relatedMaterial.materialId;
            // }
            this._editErrorMessage = null;
        } catch (e) {
            this._editErrorMessage = 'This material and relation type combination has already been added.';
        }
    }

    onSetReciprocalRelationId(val: number) {
        try {
            this.reciprocalTypeMaterialRelationId = val;
            if (val <= 0) {
                return;
            }
            this.reciprocalRelation = true;
            // for reciprocal relations reverse materialId with relatedMaterialId
            this.entity.materialId = 0;
            this.entity.relatedMaterialId = 0;
            this.entity.typeMaterialRelationId = val;
            this.entity.relatedMaterialId = this._material.materialId;
            this.entity.materialId = this._relatedMaterial ? this._relatedMaterial.materialId : null;
            // next two lines are needed in case a collision occured earlier - in that case we need to set back
            // the relatedmaterialId after setting the relationId
            // if (this._relatedMaterial && this._material.materialId != this.entity.relatedMaterialId) {
            //    this.entity.relatedMaterialId = this._material.materialId;
            // }

            this._editErrorMessage = null;
        } catch (e) {
            this._editErrorMessage = 'This material and relation type combination has already been added.';
        }
    }

    onSelectingMaterial() {
        // BUG: synonym display not changing when material is changed but the rest of the relatedMaterial does change. ... sometimes...
        UtilFns.showModal(this._materialSelectorComponent, this, null).then(mli => {
            if (mli == null) {
                return;
            }
            // this._relatedMaterial = null;
            this._relatedMaterial = mli;
            // Prevent exception thrown when a user inadvertently tries to set a relationship on the same material.
            if (this._material.materialId == mli.materialId) {
                this._editErrorMessage = 'You cannot set a relationship on a material to itself; please select a different material.';
                return;
            }
            try {
                if (!this.reciprocalRelation) {
                    this.entity.materialId = this._material.materialId;
                    this.entity.relatedMaterialId = mli.materialId;
                    if (this.entity.typeMaterialRelationId != this._typeMaterialRelationId) {
                        this.entity.typeMaterialRelationId = this._typeMaterialRelationId;
                    }
                } else {
                    this.entity.materialId = mli.materialId;
                    this.entity.relatedMaterialId = this._material.materialId;
                    if (this.entity.typeMaterialRelationId != this._reciprocalTypeMaterialRelationId) {
                        this.entity.typeMaterialRelationId = this._reciprocalTypeMaterialRelationId;
                    }
                }
                this._editErrorMessage = null;
            } catch (e) {
                this._editErrorMessage = 'This material and relation type combination has already been added.';
            }

        });
    }

    isValid(deleting: boolean) {
        const ok = this.entity.entityAspect.validateEntity();
        return ok && this._editErrorMessage == null && this.validateTypeRelationSelection(deleting) == null;
    }

    getError(propName: string) {
        return this._editErrorMessage || this.validateTypeRelationSelection(false) || this.entity.getErrorFor(propName);
    }

    validateTypeRelationSelection(deleting: boolean) {
        if (this.typeMaterialRelationId < 0 && this.reciprocalTypeMaterialRelationId < 0) {
            return 'Either \'Relation type\' or \'Reciprocal relation type\' must be selected';
        }
        if (!deleting && this.entity.materialId == this.entity.relatedMaterialId) {
            return 'Cannot create relation between material and self.';
        }
        return null;
    }

    getRelationTypeText() {
        if (this.typeMaterialRelationId > 0) {
            return _.find(this._types, x => x.typeMaterialRelationId == this.typeMaterialRelationId).relationType;
        }

        if (this.reciprocalTypeMaterialRelationId > 0) {
            const mat = _.find(this._types, x => x.typeMaterialRelationId == this.reciprocalTypeMaterialRelationId);
            return mat.reciprocalRelationType != null ? mat.reciprocalRelationType : mat.relationType;
        }

        return ' - ';
    }

}

import { Component, Input, OnInit } from '@angular/core';

import { provideParent, UnitOfWork } from '../../services/common';
import { EditPropParent } from '../../controls/common';
import { FSAG, MaterialFSAG } from '../../entities/EntityModels';

@Component({
    selector: 'relations-group',
    templateUrl: './relations-group.html',
    providers: [provideParent(RelationsGroupComponent, EditPropParent)]
})
export class RelationsGroupComponent implements OnInit {
    @Input() entity: MaterialFSAG;
    _FSAGs: FSAG[];

    constructor(public _uow: UnitOfWork) {

    }

    ngOnInit() {
        this._uow.fSAGRepository.all().then(r => {
            // insert a blank line at the top of the select box.
            // r.unshift(null);
            this._FSAGs = r;
        });
    }

    getError(propName: string) {
        return this.entity.getErrorFor(propName);
    }


}

<div>
    <div class="inline">
        <span class="inline text-bold text-left page-title">{{_data.adding ? "Adding a Subscription" : "Editing a Subscription" }}</span>
        &nbsp;
        <span class="inline text-bold text-left page-title">for Journal</span>
        &nbsp;
        <span class="inline text-bold text-left page-title highlighted-text-blue" style="padding: 6px; border: 1px solid lightgray;">{{_data.entity.journalName}}</span>
    </div>

    <editor-header [edm]="_edmSubscription" [customButtons]="true">
        <span customButtons class="inline" style="margin-left: 10px; margin-top: 6px;">
            <button *ngIf="canSave()" type="button" class="btn btn-sm btn-primary" [disabled]="!canSave()" (click)="onSave()">Save</button>
            <button *ngIf="canDelete()" type="button" class="btn btn-sm btn-primary" (click)="onDelete()">Confirm Delete</button>
            <button *ngIf="canApply()" type="button" class="btn btn-sm btn-primary" [disabled]="!canApply()" (click)="onApply()">Apply</button>
            <button *ngIf="canShowBack()" type="button" class="btn btn-sm btn-primary" (click)="onBack()">Back</button>
            <button *ngIf="canCancel()" type="button" class="btn btn-sm btn-primary" (click)="onCancel()">Cancel</button>
        </span>
    </editor-header>
</div>

<p class="alert-danger">{{_validationMessage}}</p>

<div class="form-horizontal indent-top-sm" style="padding-bottom: 60px;">

    <div  *ngIf="journalSubscription != null" class="form-horizontal">

        <p class="light-gray-text display-block bold">Publisher Data</p>

        <edit-prop label="Publisher" prop="publisher" szs="2,5">
            <input class="form-control" type="text"  [(ngModel)]="journalSubscription.publisher">
        </edit-prop>

        <edit-prop label="Contact" prop="subscriberContact" szs="2,8">
            <textarea class="form-control" rows=3 [(ngModel)]="journalSubscription.subscriptionContact"></textarea>
        </edit-prop>

        <p class="light-gray-text display-block bold">Subscription Data</p>

        <div class="inline" style="margin-left: -12px; padding-bottom: 4px;">
            <edit-prop isChild label="Subscription Start Year" szs="2,2">
                <input #startdate type="text" [ngModel] ="_subscriptionStartYear" (change)="onChangeJournalSubscriptionStartYear($event.target.value)"/>
                <span class="italic left-indent">YYYY</span>
            </edit-prop>
            <edit-prop isChild label="Subscription Start Month" szs="2,2">
                <select *ngIf="journalSubscription.subscriptionStartYear != null" class="form-control" [ngModel]="_selectedSubscriptionStartMonthIndex" (change)="onSelectSubscriptionStartMonth($event.target.value)">
                    <option *ngFor="let m of _monthNames; let mix = index" [value]="mix">{{m.month}}</option>
                </select>
            </edit-prop>
        </div>

        <div class="inline" style="margin-left: -12px; padding-bottom: 4px;">
            <edit-prop isChild label="Subscription End Year" szs="2,2">
                <input #enddate type="text" [ngModel] ="_subscriptionEndYear" (change)="onChangeJournalSubscriptionEndYear($event.target.value)"/>
                <span class="italic left-indent">YYYY</span>
            </edit-prop>
            <edit-prop isChild label="Subscription End Month" szs="2,2">
                <select *ngIf="journalSubscription.subscriptionEndYear != null" class="form-control" [ngModel]="_selectedSubscriptionEndMonthIndex" (change)="onSelectSubscriptionEndMonth($event.target.value)">
                    <option *ngFor="let m of _monthNames; let mix = index" [value]="mix">{{m.month}}</option>
                </select>
            </edit-prop>
        </div>

        <div class="form-group">
            <edit-prop isChild label="Subscription Type" szs="2,4">
                <select class="form-control" [ngModel]="journalSubscription.typeJournalSubscriptionId" (change)="journalSubscription.typeJournalSubscriptionId=$event.target.value">
                    <option *ngFor="let ts of _typeJournalSubscriptions" [value]="ts.typeJournalSubscriptionId">{{ts.description}}</option>
                </select>
            </edit-prop>
        </div>

        <edit-prop label="Issues Per Year" prop="issuesPerYear" szs="2,4">
            <input type="text" class="form-control" [(ngModel)]="journalSubscription.issuesPerYear">
        </edit-prop>

        <edit-prop label="Membership Subscription" prop="membershipSubscription" szs="2,8">
            <textarea class="form-control" rows=3 [(ngModel)]="journalSubscription.membershipSubscription"></textarea>
        </edit-prop>

        <p class="light-gray-text display-block bold">Payment Data</p>

        <edit-prop label="Subscription Payer" prop="subscriptionPayer" szs="2,8">
            <input class="form-control" type="text"  [(ngModel)]="journalSubscription.subscriptionPayer">
        </edit-prop>

        <edit-prop label="Payment Activity" prop="paymentActivity" szs="2,8">
            <textarea class="form-control" rows=3 [(ngModel)]="journalSubscription.paymentActivity"></textarea>
        </edit-prop>

        <edit-prop label="Subscription Account Numbers" prop="subscriptionAccountNumbers" szs="2,8">
            <input class="form-control" type="text"  [(ngModel)]="journalSubscription.subscriptionAccountNumbers">
        </edit-prop>

        <edit-prop label="Invoice/Order Number" prop="orderNumber" szs="2,5">
            <input class="form-control" type="text"  [(ngModel)]="journalSubscription.orderNumber">
        </edit-prop>

        <p class="light-gray-text display-block bold">Additional Data</p>

        <edit-prop label="Department" prop="department" szs="2,5">
            <input class="form-control" type="text"  [(ngModel)]="journalSubscription.department">
        </edit-prop>

        <edit-prop label="Notes" prop="notes" szs="2,8">
            <textarea class="form-control" rows=8 [(ngModel)]="journalSubscription.notes"></textarea>
        </edit-prop>

    </div>

</div>

<div id="FullReport" class="modal fade bs-example-modal-lg" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel">
    <div class="modal-dialog modal-lg reference-full-report">
        <div class="modal-content">
            <div class="modal-header custom-header">
                <span *ngIf="selectedExpMaterial == null" class="modal-title" id="myModalLabel">Reference Data Report</span>
                <span *ngIf="selectedExpMaterial != null" class="modal-title" id="myModalLabel">Reference Data Single Material Report</span>
                <span class="no-print">
                    <button type="button" class="btn btn-default pull-right text-uppercase text-bold" data-dismiss="modal"><span class="fa fa-times"></span> Close</button>
                    <button (click)="printFullReport()" type="button" class="btn btn-default pull-right text-uppercase text-bold"><span class="fa fa-print"></span> Print</button>
                    <button (click)="showPrintToPdfDoc()" type="button" class="btn btn-default pull-right text-uppercase text-bold"><span class="fa"></span> How to print to PDF</button>
                </span>
            </div>
            <div class="modal-body custom-body">
                <div class="row common-spacer">
                    <div class="col-md-2 col-sm-4">
                        <span class="report-title">Reference: </span><span class="report-value">{{bd.reference?.referenceId}}</span>
                    </div>
                    <div class="col-md-3 col-sm-4">
                        <span class="report-title">Sub-Reference: </span><span class="report-value">{{bd.subReference}}</span>
                    </div>
                    <div class="col-md-3 col-sm-4">
                        <span class="report-title">Study: </span><span class="report-value">{{bd.typeStudy?.studyLong || '-'}}</span>
                        <span class="small" *ngFor="let stDescr of bd?.studySubTypeDescriptions()">
                            <br>&nbsp;&nbsp;&nbsp;{{stDescr}}
                        </span>
                    </div>
                    <div class="col-md-2 col-sm-4 col-xs-6">
                        <span class="report-title">Route: </span><span class="report-value" [htmlGreek]="bd.typeRoute?.routeLong || '-'"></span>
                    </div>
                    <div class="col-md-2 col-sm-4 col-xs-6">
                        <span class="report-title">Species: </span><span class="report-value" [htmlGreek]="bd.typeSpecies?.speciesLong || '-'"></span>
                    </div>
                </div>
                <div class="row common-spacer gray">
                    <div class="col-md-12 common-padding">
                        <div class="row" *ngIf="bd.studyLength != 0 || bd.typeTimeUnitId.toLowerCase() != 'none'">
                            <div class="col-md-3 col-sm-4 text-bold">Study Length:</div>
                            <div class="col-md-9 col-sm-8">
                                <span *ngIf="bd.studyLength != 0" class="inline">{{bd.studyLength}}</span>
                                <span *ngIf="bd.typeTimeUnitId.toLowerCase() != 'none'" class="inline">{{bd.typeTimeUnitId}}</span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-3 col-sm-4 text-bold">GLP: </div>
                            <div class="col-md-9 col-sm-8">
                                <input type="checkbox" disabled="true" [checked]="bd.gLP" />
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-3 col-sm-4 text-bold">Protocol: </div>
                            <div class="col-md-9 col-sm-8">{{bd.protocol}}</div>
                        </div>
                        <div class="row">
                            <div class="col-md-3 col-sm-4 text-bold">Comment: </div>
                            <div class="col-md-9 col-sm-8">{{bd.comment || '-'}}</div>
                        </div>
                        <div class="row">
                            <div class="col-md-3 col-sm-4 text-bold">Compliance: </div>
                            <div class="col-md-9 col-sm-8">{{bd.compliance || '-'}}</div>
                        </div>
                    </div>
                </div>
                <div class="row common-spacer">
                    <div class="list-wrapper">
                        <ul class="report-details">
                            <!-- lists starts here -->
                            <li *ngFor="let expMaterial of getSelectedExpMaterials(bd); let exIx=index" class="col-md-12 common-padding">
                                <div class="row">
                                    <div class="col-md-6 col-sm-6">
                                        <div class="report-title">Experimental Material</div>
                                        <div class="row">
                                            <div class="col-md-6 text-bold">Material Name: </div>
                                            <div class="col-md-6 break-word" [htmlGreek]="expMaterial?.material.principalSynonym()?.synonymWordOrWebVersion()"> </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-6 text-bold">CAS Number: </div>
                                            <div class="col-md-6"> {{expMaterial?.material.realCASNumber || '(No CAS#)'}}</div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-6 text-bold">RIFM ID: </div>
                                            <div class="col-md-6">{{expMaterial?.material.monographNumber}}</div>
                                        </div>
                                    </div>
                                    <div class="col-md-6 col-sm-6">
                                        <div class="report-title">Experimental Description</div>
                                        <div class="row">
                                            <div class="col-md-6 text-bold">Calculated: </div>
                                            <div class="col-md-6">{{expMaterial?.calculatedResultTypeAndValue}}</div>
                                        </div>
                                        <div *ngIf="expMaterial != null && expMaterial.showExperimentalMaterialVehicle()" class="row">
                                            <div class="col-md-6 text-bold">Vehicle: </div>
                                            <div class="col-md-6">{{expMaterial?.typeExperimentVehicle.vehicle}}</div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-6 text-bold">Summary: </div>
                                            <div class="col-md-6">{{expMaterial?.experimentalSummary}}</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12">

                                        <div class="report-title">Experimental Results</div>
                                        <div class="table-responsive">
                                            <table class="table table-striped custom-table">
                                                <thead>
                                                    <tr>
                                                        <th class="col-md-2">Dose/Unit</th>
                                                        <th class="col-md-2">Effects</th>
                                                        <th class="col-md-4">Results</th>
                                                        <th class="col-md-2"></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let er of expMaterial.experimentalResults">
                                                        <td>{{er.formattedDose()}}</td>
                                                        <td>{{er.toxicEffects() || '-'}}</td>
                                                        <td>{{er.resultNote}}</td>
                                                        <td class="bold">{{(er.typeExperimentVehicleId != 'NONE') ? 'Vehicle: ' + er.typeExperimentVehicle.vehicle : ''}}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>

                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="modal-footer border-zero no-print">
                <button (click)="printFullReport()" type="button" class="btn btn-default text-uppercase text-bold"><span class="fa fa-print"></span> Print</button>
                <button type="button" class="btn btn-default text-uppercase text-bold" data-dismiss="modal"><span class="fa fa-times"></span> Close</button>
            </div>
        </div>
    </div>
</div>

<print-to-pdf-doc></print-to-pdf-doc>
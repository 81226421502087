<div *ngIf="isStaff()" class="row btn-bar indent-top-sm">
    <button *ngIf="!_editing" type="button" class="btn btn-sm btn-primary" [disabled]="!canAdd()" (click)="onAdd()">Add</button>
    <button *ngIf="_editing" type="button" class="btn btn-sm btn-primary" [disabled]="!canDelete()" (click)="onDelete(pe)">Delete</button>
    <button type="button" class="btn btn-sm btn-primary" [disabled]="!canSave()" (click)="onSave()">Save</button>
    <button type="button" class="btn btn-sm btn-primary" [disabled]="!canCancel()" (click)="onCancel()">Cancel</button>
    <span *ngIf="hasEdits()" class="label label-warning">
            You cannot leave the Relations tab until you either 'Save' or 'Cancel'
    </span>
    <span *ngIf="_status" [ngClass]="_status.classes">{{_status.message}}</span>
</div>

<div>
    <page-status [attr.data-hidden]='_editing' [pageState]="_pageState"></page-status>
</div>

<default-table *ngIf="_items && _items.length > 0" [attr.data-hidden]="_editing">
    <h4 header>Related Materials</h4>
    <thead>
        <tr>
            <td><a [colSorter]="_colSorter1">Relation Type </a></td>
            <td><a [colSorter]="_colSorter1">CAS No. </a></td>
            <td><a [colSorter]="_colSorter1">RIFM ID </a></td>
            <td><a [colSorter]="_colSorter1">FEMA No. </a></td>
            <td><a [colSorter]="_colSorter1">EINECS </a></td>
            <td><a [colSorter]="_colSorter1">Principal Name </a></td>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let e of _items" [class.selected]="e == _currentItem" [class.text-muted] ="isStaff() && !hasEditableRelation(e)" (click)="onSelect(e)">
            <td>{{e.relationType}}</td>
            <td><a (click)="goToSynonyms(e)">{{e.realCASNumber || '(No CAS#)' }}</a></td>
            <td>{{e.formattedRifmId}}</td>
            <td>{{e.fEMANumber}}</td>
            <td>{{e.eINECSNumber}}</td>
            <td><span [htmlGreek]="e.synonymWordOrWebVersion"></span></td>
            <!-- <td>{{s.entityAspect.entityState}}</td> -->
        </tr>
    </tbody>
</default-table>

<div *ngIf="_editing">
    <relations-material #self [entity]="_addedMaterialRelation" [reciprocalRelation] ="_isReciprocalRelation"></relations-material>
</div>

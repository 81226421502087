import { Location } from '@angular/common';
import { Component, ViewChild, OnInit,  } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import * as _ from 'lodash';
import { UnitOfWork, StateMap, UserManager, ErrorLogger } from '../../services/common';
import { UtilFns, IEditHost, EditManager } from '../../utils/common';
import { ExposureSurvey } from '../../entities/EntityModels';
import { ExposureSurveyStatistics } from '../../entities/projections/ExposureSurveyStatistics';
import { SelectableEntity } from '../../entities/projections/SelectableEntity';
import { ConfirmFinishOverrideComponent } from '../staff/confirm-finish-override.component';
import { EXPOSURE_ROUTES } from './exposure.routes';

@Component({
    selector: 'exposure-surveys',
    templateUrl: './exposure-surveys.html',
})

export class ExposureSurveysComponent implements OnInit, IEditHost<ExposureSurvey> {
    @ViewChild(ConfirmFinishOverrideComponent, { static: true }) _confirmFinishOverrideComponent: ConfirmFinishOverrideComponent;

    // Default type ids
    private _typeCremeVersionId = 0;
    private _typeExposureSurveyStatusId: string = null;

    public _edm: EditManager<ExposureSurvey>;
    public _adding = false;
    public _showExposureSurveyMaterialList = false;
    private _lastNumber: number;

    private _isLoading = false;
    public _selectedExposureSurvey: ExposureSurvey;
    public _exposureSurveyStatistics: ExposureSurveyStatistics;
    public _exposureSurveysEx: SelectableEntity<ExposureSurvey>[] = [];

    public _validationMessage: string;

    private colorSelected   = '#ddd';
    private colorUnselected = '#FFFFFF';

    constructor(private _uow: UnitOfWork, private _stateMap: StateMap, private _userManager: UserManager, private _router: Router,
                private _route: ActivatedRoute, public _location: Location, public _errorLogger: ErrorLogger) {
    }

    ngOnInit() {

        this._edm = new EditManager<ExposureSurvey>(this, this._uow, this._stateMap, 'Exposure Surveys');

        this._stateMap.currentRouteName = EXPOSURE_ROUTES.ExposureSurveys.name;

        // var _sub = this._route.params.subscribe(params => {
        //    this._navparam = +params['id']; //exposure survey id
        // });

        this.getDefaultMeasureTypeIds();

        this.fetch();
    }

    fetch() {

        this._exposureSurveysEx = [];

        this._isLoading = true;

        this._uow.fetch('ExposureSurveys/ExposureSurveys', {}).then(e => {
            this._edm.entities = e;

            if (this._edm.entities != null && this._edm.entities.length > 0) {
                this._lastNumber = this._edm.entities[0].exposureSurveyNumber;
            }

            this.formatSelectableEntities();

            this._isLoading = false;
        });
    }

    formatSelectableEntities() {
        this._exposureSurveysEx = [];
        if (this._edm.entities == null || this._edm.entities.length < 1) { return; }

        _.clone(this._edm.entities).forEach(element => {
            this._exposureSurveysEx.push(new SelectableEntity<ExposureSurvey>(element, '#FFFFFF'));
        });

        this._exposureSurveysEx = _.sortBy(this._exposureSurveysEx, e => e.data.exposureSurveyDate).reverse();
    }

    filterToExposureSurveyById(exposureSurveyId: number): ExposureSurvey {
        if (this._edm.entities == null || this._edm.entities.length < 1) {
            return null;
        }

        const expSurvey = this._edm.entities.filter(e => e.exposureSurveyId == exposureSurveyId);
        if (expSurvey != null && expSurvey.length > 0) {
            return expSurvey[0];
        }
    }

    canDeactivate() {
        return !this.hasEdits();
    }

    hasEdits() {
        return this._uow.hasChanges();
    }

    isStaff() {
        return this._stateMap.currentUser.isStaff;
    }

    get utilFns() {
        return UtilFns;
    }

    showExposureSurveyStatistics(esx: SelectableEntity<ExposureSurvey>) {
        if (esx == null) { return; }

        this._selectedExposureSurvey = this.filterToExposureSurveyById(esx.data.exposureSurveyId);

        const params = { exposureSurveyId: esx.data.exposureSurveyId };

        if (this._selectedExposureSurvey) {
            this.resetBackgroundColor(this._exposureSurveysEx);
            esx.backgroundColor = this.colorSelected;
            this._uow.fetch('ExposureSurveys/FetchExposureSurveyStatistics', params).then(es => {
                if (es != null && es.length > 0) {
                    this._exposureSurveyStatistics = es[0];
                } else { this._exposureSurveyStatistics = null; }
            });
        }
    }

    resetBackgroundColor(exposureSurveys: SelectableEntity<ExposureSurvey>[]) {
        exposureSurveys.forEach((element, index) => {
            element.backgroundColor = this.colorUnselected;
        });
    }

    // edit manager overrides
    canApply() {
        if (this._edm.editing && this._uow.hasChanges()) {
            if (this._edm.currentEntity != null) {
                return (!this._edm.currentEntity.entityAspect.hasValidationErrors);
            }
            return true;
        }
        return false;
    }

    canCancel() {
        return (this._uow.hasChanges());
    }

    canSave() {
        if (this._edm.editing) { return false; }
        if (!this._edm.hasEdits()) { return false; }
        if (!this._edm.validateBeforeSave()) { return false; }

        return true;
    }

    canShowBack() {
        if (!this._edm.editing) { return false; }
        return (!this._uow.hasChanges());
    }

    onAdd() {
        if (!this._edm.validateCurrent()) { return; }

        this._adding                    = true;
        const e                           = this._uow.exposureSurveyFactory.create();
        e.typeExposureSurveyStatusId    = 'Not Set';

        // Survey Date specified by Month and Year only, set default to current month and year for the first day of the month.
        // tslint:disable-next-line:prefer-const
        const today = new Date();
        const s = '';
        const m = today.getMonth() + 1;
        const surveydate          = s.concat(m.toString(), '/', '01', '/', today.getFullYear().toString());
        e.exposureSurveyDate    = new Date(surveydate);
        e.exposureSurveyNumber  = this._lastNumber + 1;
        e.typeCremeVersionId    = this._typeCremeVersionId;

        if (this._typeExposureSurveyStatusId != null) { // If it is null, the value with default to the defined default value in the database
            e.typeExposureSurveyStatusId = this._typeExposureSurveyStatusId;
        }

        this._edm.entities.push(e);
        this._edm.onSelect(e);
    }

    onApply() {
        this._validationMessage = '';

        if (this._edm.editing) {

            if (this._edm.currentEntity.typeCremeVersion.cremeVersion.toLowerCase() == 'none') {
                this._validationMessage = 'Please select a Creme Version other than None.';
                return;
            }

            if (this.isDuplicateSurveyEntry(this._edm.currentEntity.exposureSurveyMonthYearNumber)) {
                this._validationMessage = 'An exposure survey with the same date and number already exists.';
                return;
            }
            this._edm.onApplyCore();
            this._adding = false;

            this.formatSelectableEntities();
        }

        return true;
    }

    onBack() {
        this._adding        = false;
        this._edm.editing   = false;
    }

    onCancel() {
        this._validationMessage = '';

        this._adding = false;
        this._edm.onCancelCore();
        this._edm.entities = null;

        this.fetch();
    }

    onEdit(esx: SelectableEntity<ExposureSurvey>) {

        if (esx == null) { return; }

        this._selectedExposureSurvey = this.filterToExposureSurveyById(esx.data.exposureSurveyId);

        if (this._selectedExposureSurvey == null) { return; }

        this._edm.onSelect(this._selectedExposureSurvey);
    }

    onRemove(esx: SelectableEntity<ExposureSurvey>) {

        if (esx == null) { return; }

        this._selectedExposureSurvey = this.filterToExposureSurveyById(esx.data.exposureSurveyId);

        if (this._selectedExposureSurvey == null) {
            this._validationMessage = 'Error occurred, could not remove the selected exposure survey from the list.';
            return;
        }

        const messages: string[] = [];
        this._confirmFinishOverrideComponent.showModal('Confirm Deletion of Exposure Survey',
            'If you delete the survey, all related data will be removed. Do you still want to delete this entire Exposure Survey?', messages).then(ok => {
            if (ok) {
                if (this._selectedExposureSurvey.entityAspect.entityState.isAdded()) {
                    this._selectedExposureSurvey.entityAspect.rejectChanges();
                } else {
                    this._selectedExposureSurvey.entityAspect.setDeleted();
                }

                _.remove(this._edm.entities, this._selectedExposureSurvey);

                this._exposureSurveyStatistics = null;

                this.formatSelectableEntities();
            }
        });
    }

    onSave() {
        if (this._edm.hasEdits()) {
            this._edm.onSaveCore().then(() => {

                this._adding = false;
                this._edm.editing = false;

                // this.fetch();
            });
        }
    }

    onShowMaterialList(esx: SelectableEntity<ExposureSurvey>) {

        if (esx == null) { return; }

        this._selectedExposureSurvey = this.filterToExposureSurveyById(esx.data.exposureSurveyId);

        if (this._selectedExposureSurvey == null) { return; }

        this._showExposureSurveyMaterialList = true;

        this._router.navigate(['../exposure-survey-lists', { id: this._selectedExposureSurvey.exposureSurveyId }], { relativeTo: this._route });
    }

    isDuplicateSurveyEntry(dateandnumber: string): boolean {
        if (this._edm.entities == null) { return false; }

        const expsurvey = this._edm.entities.filter(e => e.exposureSurveyMonthYearNumber == dateandnumber);
        return expsurvey != null && expsurvey.length > 1;
    }

    getDefaultMeasureTypeIds() {
        this._uow.typeCremeVersionRepository.all().then(c => {
            if (c != null && c.length > 0) {
                const types = c.filter(t => t.cremeVersion.toLowerCase() == 'none');
                if (types != null && types.length > 0) {
                    this._typeCremeVersionId = types[0].typeCremeVersionId;
                }
            }
        });

        this._uow.typeExposureSurveyStatusRepository.all().then(e => {
            if (e != null && e.length > 0) {
                const types = e.filter(t => t.typeExposureSurveyStatusId == 'Open for Materials');
                if (types != null && types.length > 0) {
                    this._typeExposureSurveyStatusId = types[0].typeExposureSurveyStatusId;
                }
            }
        });
    }
}

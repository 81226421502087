<span class="row topic-title">
    <div *ngIf="title" class="h1-sm bold inline">{{title}}</div>
    <span class="h1-sm bold">
        {{ reference?.description(isStaff()) }}
    </span>
    <span style="margin-left: 15px" *ngIf="reference?.restrictedDistribution" class="captive-text">
        Restricted Distribution
    </span>
    <span style="margin-left: 15px" *ngIf="reference?.captiveFinishedText()" class="captive-text">
        {{reference?.captiveFinishedText()}}
    </span>
</span>

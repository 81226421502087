import { Location } from '@angular/common';
import { Component, forwardRef, Inject, OnInit } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';

import { ErrorLogger, provideParent, StateMap, UnitOfWork, UserManager } from '../../services/common';
import { EditManager, IEditHost, UtilFns } from '../../utils/common';
import { EditPropParent } from '../../controls/common';
import {
    ExposureAdditionalMaterial,
    ExposureAdditionalMaterialSurvey,
    ExposureAdditionalMaterialSurveyMeasure,
    ExposureSurvey
} from '../../entities/EntityModels';
import { MeasureEditData, RecordExposureSurveyMeasuresComponent } from './record-exposure-survey-measures.component';
import { SelectableEntity } from '../../entities/projections/SelectableEntity';
import * as _ from 'lodash';

@Component({
    selector: 'exposure-additional-material-survey-measures',
    templateUrl: './exposure-additional-material-survey-measures.html',
    providers: [provideParent(ExposureAdditionalMaterialSurveyMeasuresComponent, EditPropParent)]
})
export class ExposureAdditionalMaterialSurveyMeasuresComponent implements IEditHost<ExposureAdditionalMaterialSurveyMeasure>, OnInit {



    private _adding: boolean;

    // Default type ids
    private _typeCremeVersionId = 0;
    private _typeExposureMeasureId = 0;
    private _typeExposureMeasureValueId = 0;

    private _exposureAdditionalMaterials: ExposureAdditionalMaterial[] = [];
    private _selectedExposureAdditionalMaterial: ExposureAdditionalMaterial;
    private _selectedExposureAdditionalMaterialIndex: number;

    private _selectedExposureSurveyIndex: number;
    private _selectedExposureSurvey: ExposureSurvey;

    private _currentExposureAdditionalMaterialSurvey: ExposureAdditionalMaterialSurvey;
    private _exposureAdditionalMaterialSurveys: ExposureAdditionalMaterialSurvey[] = [];

    private _selectedExposureAdditionalMaterialSurveyMeasure: ExposureAdditionalMaterialSurveyMeasure;
    public _exposureAdditionalMaterialSurveyMeasuresEx: SelectableEntity<ExposureAdditionalMaterialSurveyMeasure>[] = [];

    private _data: MeasureEditData;

    _edm: EditManager<ExposureAdditionalMaterialSurveyMeasure>;
    _validationMessage: string;
    _unsurveyedMessage: string;
    _isLoading = false;

    constructor(@Inject(forwardRef(() => RecordExposureSurveyMeasuresComponent)) public _parent: RecordExposureSurveyMeasuresComponent,
                private _uow: UnitOfWork, private _stateMap: StateMap, private _userManager: UserManager, private _router: Router,
                public _route: ActivatedRoute, public _location: Location, public _errorLogger: ErrorLogger) {

        this._data = _parent._data;
    }

    ngOnInit() {

        this._edm = new EditManager<ExposureAdditionalMaterialSurveyMeasure>(this, this._uow, this._stateMap, 'Exposure Additional Material Survey Measures');

        this._uow.exposureAdditionalMaterialRepository.all().then(m => {
            this._exposureAdditionalMaterials = m;

            // Filter to input parameter, if set.
            if (this._data.exposureAdditionalMaterialId > 0 && this._exposureAdditionalMaterials != null) {

                this._selectedExposureAdditionalMaterial = this.filterToAdditionalMaterialById(this._data.exposureAdditionalMaterialId);

                if (this._selectedExposureAdditionalMaterial) {
                    this._selectedExposureAdditionalMaterialIndex = this._exposureAdditionalMaterials
                        .findIndex(a => a.exposureAdditionalMaterialId == this._selectedExposureAdditionalMaterial.exposureAdditionalMaterialId);
                }

                this.fetchExposureAdditionalMaterialSurveys();
            }
        });

        this.getDefaultMeasureTypeIds();
    }

    fetchExposureAdditionalMaterialSurveys() {

        let filterId = 0;

        this._edm.entities = null;
        this._exposureAdditionalMaterialSurveys = [];
        this._unsurveyedMessage = null;

        const params = { exposureAdditionalMaterialId: this._selectedExposureAdditionalMaterial.exposureAdditionalMaterialId };

        this._isLoading = true;

        this._uow.fetch('ExposureSurveys/ExposureAdditionalMaterialSurveyByAdditionalMaterialId', params).then(m => {
            this._exposureAdditionalMaterialSurveys = m;
            if (this._exposureAdditionalMaterialSurveys != null && this._exposureAdditionalMaterialSurveys.length > 0) {

                if (this._data.additionalExposureSurveyId > 0) {
                    const expsurveyid = this._exposureAdditionalMaterialSurveys.findIndex(e => e.exposureSurveyId == this._data.additionalExposureSurveyId);
                    filterId = (expsurveyid < 0) ? 0 : expsurveyid;
                }
                this._selectedExposureSurveyIndex = filterId;
                this.filterExposureSurveyByIndex(this._selectedExposureSurveyIndex); // Set the selected exposure survey which will call the fetchMeasures method
            } else {
                this._currentExposureAdditionalMaterialSurvey = null;
                this._unsurveyedMessage = 'This Exposure Additional Material was not included in any Exposure Survey.';
            }
            this._isLoading = false;
        });
    }

    fetchMeasures() {

        if (this._selectedExposureAdditionalMaterial == null || this._selectedExposureSurvey == null) {
            return;
        }

        const params = {
            exposureAdditionalMaterialId: this._selectedExposureAdditionalMaterial.exposureAdditionalMaterialId,
            exposureSurveyId: this._selectedExposureSurvey.exposureSurveyId
        };

        this._isLoading = true;
        this._uow.fetch('ExposureSurveys/FetchExposureAdditionalMaterialSurveyMeasures', params).then(m => {
            this._edm.entities = m;
            this._edm.updatePageState();

            this.formatSelectableEntities();

            this._isLoading = false;

        });
    }

    formatSelectableEntities() {
        this._exposureAdditionalMaterialSurveyMeasuresEx = [];
        if (this._edm.entities == null || this._edm.entities.length < 1) {
            return;
        }

        _.clone(this._edm.entities).forEach(element => {
            const isMultiple = this.isDuplicateMeasureEntry(element.exposureAdditionalMaterialId, element.typeExposureMeasureId);
            this._exposureAdditionalMaterialSurveyMeasuresEx.push(new SelectableEntity<ExposureAdditionalMaterialSurveyMeasure>(element, '#FFFFFF', '', isMultiple));
        });
    }

    get isLoading() {
        return this._isLoading;
    }

    set isLoading(value) {
        this._isLoading = value;
    }

    isStaff() {
        return this._stateMap.currentUser.isStaff;
    }

    get utilFns() {
        return UtilFns;
    }

    canAdd() {
        return ((!this._edm.editing) && this._currentExposureAdditionalMaterialSurvey != null);
    }

    // edit manager overrides
    canApply() {
        if (this._edm.editing) {
            return (this._uow.hasChanges() && !this._edm.currentEntity.entityAspect.hasValidationErrors);
        }

        return false;
    }

    canCancel() {
        return (this._uow.hasChanges());
    }

    canDeactivate() {
        return !this.hasEdits();
    }

    hasEdits() {
        return this._uow.hasChanges();
    }

    canSave() {
        if (this._edm.editing) {
            return false;
        }
        if (!this._edm.hasEdits()) {
            return false;
        }
        if (!this._edm.validateBeforeSave()) {
            return false;
        }
        return true;
    }

    canShowBack() {
        if (!this._edm.editing) {
            return false;
        }
        return !this._uow.hasChanges();
    }

    onAdd() {
        if (this._edm.entities == null) {
            this._edm.entities = [];
        }

        this._adding = true;
        const m = this._uow.exposureAdditionalMaterialSurveyMeasureFactory.create();

        m.exposureAdditionalMaterialId  = this._selectedExposureAdditionalMaterial.exposureAdditionalMaterialId;
        m.exposureSurveyId              = this._selectedExposureSurvey.exposureSurveyId;
        m.typeExposureMeasureId         = this._typeExposureMeasureId; //The default value is set on ngInit
        m.typeExposureMeasureValueId    = this._typeExposureMeasureValueId;
        //03/07/2019: Use Creme Version of the Exposure Survey as the default
        m.typeCremeVersionId            = this._selectedExposureSurvey.typeCremeVersionId;

        this._edm.entities.push(m);
        this._edm.onSelect(m);
    }

    onApply() {

        this._validationMessage = '';


        if (this._edm.currentEntity.typeExposureMeasure == null) {
            this._validationMessage = 'Please select a Measure type other than None.';
            return;
        }

        if (this._edm.currentEntity.typeExposureMeasure.measureDescription.toLowerCase() == 'none') {
            this._validationMessage = 'Please select a Measure type other than None.';
            return;
        }

        if (this._edm.currentEntity.measureQualitativeResult == null && this._edm.currentEntity.measureQuantitativeResult == null) {
            this._validationMessage = 'Please enter a measure value.';
            return;
        }

        if (this._edm.currentEntity.typeCremeVersion.cremeVersion.toLowerCase() == 'none') {
            this._validationMessage = 'Please select a Creme Version other than None.';
            return;
        }

        // 01/26/2019: Measure data for the same measure type can be recorded for a material/survey more than once.
        // if (this.isDuplicateMeasureEntry(this._edm.currentEntity.exposureAdditionalMaterialId, this._edm.currentEntity.typeExposureMeasureId)) {
        //    this._validationMessage = 'This measure type has already been assigned to this exposure additional material for the selected exposure survey.';
        //    return;
        // }

        this._edm.onApplyCore();
        this._adding = false;

        this.formatSelectableEntities();

        return true;
    }

    onBack() {
        this._adding = false;
        this._edm.editing = false;
    }

    onCancel() {
        this._validationMessage = '';

        this._adding = false;
        this._edm.onCancelCore();
        this._edm.entities = null;

        this._exposureAdditionalMaterialSurveyMeasuresEx = [];

        this.fetchMeasures();
    }

    onEdit(mx: SelectableEntity<ExposureAdditionalMaterialSurveyMeasure>) {
        if (mx == null) {
            return;
        }

        this._selectedExposureAdditionalMaterialSurveyMeasure = this.filterToExposureAdditionalMaterialSurveyMeasureById(mx.data.exposureAdditionalMaterialSurveyMeasureId);

        if (this._selectedExposureAdditionalMaterialSurveyMeasure == null) {
            return;
        }

        this._edm.onSelect(this._selectedExposureAdditionalMaterialSurveyMeasure);
    }

    onRemove(mx: SelectableEntity<ExposureAdditionalMaterialSurveyMeasure>) {

        if (mx == null) {
            return;
        }
        this._selectedExposureAdditionalMaterialSurveyMeasure = this.filterToExposureAdditionalMaterialSurveyMeasureById(mx.data.exposureAdditionalMaterialSurveyMeasureId);

        if (this._selectedExposureAdditionalMaterialSurveyMeasure == null) {
            this._validationMessage = 'Error occurred, could not remove the selected measure from the list.';
            return;
        }

        if (this._selectedExposureAdditionalMaterialSurveyMeasure.entityAspect.entityState.isAdded()) {
            this._selectedExposureAdditionalMaterialSurveyMeasure.entityAspect.rejectChanges();
        } else {
            this._selectedExposureAdditionalMaterialSurveyMeasure.entityAspect.setDeleted();
        }

        _.remove(this._edm.entities, this._selectedExposureAdditionalMaterialSurveyMeasure);

        this.formatSelectableEntities();
    }

    onSave() {
        if (this._edm.hasEdits()) {
            this._edm.onSaveCore().then(() => {

                this._adding = false;
                this._edm.editing = false;

                this.fetchMeasures();
            });
        }
    }

    onShowMaterialSurveys() {
        if (this._selectedExposureSurvey == null) {
            return;
        }

        this._router.navigate(['../exposure-survey-lists', { id: this._selectedExposureSurvey.exposureSurveyId }], { relativeTo: this._route });
    }

    isDuplicateMeasureEntry(exposureAdditionalMaterialId: number, typeExposureMeasureId: number): boolean {
        if (this._edm.entities == null) {
            return false;
        }

        const measure = this._edm.entities.filter(m => m.exposureAdditionalMaterialId == exposureAdditionalMaterialId && m.typeExposureMeasureId == typeExposureMeasureId);
        if (measure != null && measure.length > 1) {
            return true;
        }

        return false;
    }

    // Filters
    filterToAdditionalMaterialById(exposureAdditionalMaterialId: number): ExposureAdditionalMaterial {
        if (this._exposureAdditionalMaterials == null || this._exposureAdditionalMaterials.length < 1) {
            return null;
        }

        const additionalmaterial = this._exposureAdditionalMaterials.filter(a => a.exposureAdditionalMaterialId == exposureAdditionalMaterialId);
        if (additionalmaterial != null && additionalmaterial.length > 0) {
            return additionalmaterial[0];
        }

        return null;
    }

    filterToExposureAdditionalMaterialSurveyMeasureById(exposureAdditionalMaterialSurveyMeasureId: number): ExposureAdditionalMaterialSurveyMeasure {
        if (this._edm.entities == null || this._edm.entities.length < 1) {
            return null;
        }

        const measure = this._edm.entities.filter(m => m.exposureAdditionalMaterialSurveyMeasureId == exposureAdditionalMaterialSurveyMeasureId);
        if (measure != null && measure.length > 0) {
            return measure[0];
        }

        return null;
    }

    filterExposureAdditionalMaterialByIndex(ix: number) {
        this._selectedExposureAdditionalMaterialIndex = ix;
        this._selectedExposureAdditionalMaterial = this._exposureAdditionalMaterials[ix];
        this._data.exposureAdditionalMaterialId = this._selectedExposureAdditionalMaterial.exposureAdditionalMaterialId;
        this._data.additionalExposureSurveyId = null;

        this.fetchExposureAdditionalMaterialSurveys();
    }

    public filterExposureSurveyByIndex(ix: number) {

        this._selectedExposureSurveyIndex = ix;

        this._selectedExposureSurvey = this._exposureAdditionalMaterialSurveys[ix].exposureSurvey;
        this._data.additionalExposureSurveyId = this._selectedExposureSurvey.exposureSurveyId;
        this._currentExposureAdditionalMaterialSurvey = this._exposureAdditionalMaterialSurveys[ix];

        this.fetchMeasures();

        return true;
    }

    getDefaultMeasureTypeIds() {
        this._uow.typeExposureMeasureRepository.all().then(m => {
            if (m != null && m.length > 0) {
                const types = m.filter(t => t.measureName.toLowerCase() == 'none');
                if (types != null && types.length > 0) {
                    this._typeExposureMeasureId = types[0].typeExposureMeasureId;
                    this._typeExposureMeasureValueId = types[0].typeExposureMeasureValueId;
                }
            }
        });

        this._uow.typeCremeVersionRepository.all().then(c => {
            if (c != null && c.length > 0) {
                const types = c.filter(t => t.cremeVersion.toLowerCase() == 'none');
                if (types != null && types.length > 0) {
                    this._typeCremeVersionId = types[0].typeCremeVersionId;
                }
            }
        });
    }
}

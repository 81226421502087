import {Component, OnInit, OnDestroy, Input, Output, EventEmitter, ElementRef, OnChanges, AfterViewInit, Inject, forwardRef, ViewChild, ViewEncapsulation, ChangeDetectorRef} from '@angular/core';
import { Location } from '@angular/common';
import {HttpClient, HttpHeaders, HTTP_INTERCEPTORS} from '@angular/common/http';

import { UnitOfWork, UserManager, StateMap } from '../../services/common';
import { UtilFns, LocationFns, } from '../../utils/common';
import { PageState } from '../../controls/common';
import * as _ from 'lodash';

import {
    PdfViewerComponent, LinkAnnotationService, BookmarkViewService, MagnificationService, ThumbnailViewService,
    ToolbarService, NavigationService, TextSearchService, TextSelectionService, PrintService, AnnotationService, FormFieldsService,
    LoadEventArgs, AnnotationAddEventArgs, AjaxRequestFailureEventArgs, ExportFailureEventArgs, AjaxRequestSuccessEventArgs,
    AnnotationRemoveEventArgs, AnnotationResizeEventArgs, BeforeAddFreeTextEventArgs, CommentEventArgs, FormFieldAddArgs,
    AnnotationPropertiesChangeEventArgs, PageChangeEventArgs, AjaxRequestInitiateEventArgs, DownloadEndEventArgs, DownloadStartEventArgs, LoadFailedEventArgs, ToolbarItem
} from '@syncfusion/ej2-angular-pdfviewer';
import { ColorPickerModule } from '@syncfusion/ej2-angular-inputs';
import { ToolbarModule } from '@syncfusion/ej2-angular-navigations';

@Component({
    selector: 'pdf-editor',
    templateUrl: './pdf-editor.html',
    encapsulation: ViewEncapsulation.None,
    // tslint:disable-next-line:max-line-length
    providers: [LinkAnnotationService, BookmarkViewService, MagnificationService, ThumbnailViewService, ToolbarService, NavigationService, TextSearchService, TextSelectionService, PrintService, AnnotationService, FormFieldsService]
})
export class PDFEditorComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild(PdfViewerComponent, { static: true }) _pdfViewerComponent: PdfViewerComponent;

    @Input() serviceUrl: string;
    @Output() stateChange = new EventEmitter<string>();

    // JAYT: added canShowHeader and changeDetectorRef logic to handle 'Expression has been changed...' issue.
    public _canShowHeader = false;
    public _canShowPDF     = false;
    public _documentPath: string;
    public _enableToolbar  = false;
    public _hasChanges     = false;
    public _processingMessage: string;
    public _rifmDocumentSource:  string;
    public _showPDFTronDocument = true;
    public _zoommode = 'FitToPage';

    // tslint:disable-next-line:max-line-length
    public _toolbarSettingsEdit = { toolbarItem: 'AnnotationEditTool, DownloadOption, MagnificationTool, OpenOption, PageNavigationTool, PrintOption, SearchOption, SelectionTool, UndoRedoTool, CommentTool' };
    // tslint:disable-next-line:max-line-length
    public _toolbarSettingsView = { toolbarItem: 'DownloadOption, MagnificationTool, PageNavigationTool, PrintOption, SearchOption, SelectionTool' };

    public _toolbarSettings = this._toolbarSettingsView;

    public _printMode = 'printMode.NewWindow';

    public _colorPicker: any;

    constructor(public _stateMap: StateMap, public _uow: UnitOfWork, private changeDetectorRef: ChangeDetectorRef) {
    }

    ngOnInit() {
    }

    ngAfterViewInit() {

      if (this._pdfViewerComponent != null) {
          this._pdfViewerComponent.freeTextSettings.fontSize = 48;
      }
      this._canShowHeader = true;
      this.changeDetectorRef.detectChanges();



        // this._colorPicker = $('#colorpicker')[0] as HTMLInputElement;
    }

    ngOnDestroy() {
        if (this._pdfViewerComponent != null) {
            this.unloadPDF();
        }

        // if (this._colorPicker != null) {
        //     console.log('remove colorpicker');
        //     this._colorPicker.remove();
        // }
    }

    public destroyData() {
        this._pdfViewerComponent.destroy();
    }

    public loadPDF(fileName: string) {
        this.documentPath                   = fileName;
        this._pdfViewerComponent.fileName   = fileName;

        this._pdfViewerComponent.load(fileName, '');

    }

    public savePDF() {
        this._processingMessage = 'Saving PDF File.';
        this._pdfViewerComponent.download();
    }

    public unloadPDF() {
        this._pdfViewerComponent.unload();
        this.documentPath                   = '';
        this._pdfViewerComponent.fileName   = '';
    }

    public refreshPDF() {
        const fileName = this._pdfViewerComponent.fileName;

        this.documentPath = fileName;
        this.loadPDF(fileName);
    }

    // **************************************************************************
    // bindable properties
    // **************************************************************************
    public ajaxSetting = {
        ajaxHeaders: [
            {
                headerName: 'Authorization',

                headerValue: this._stateMap.currentUser.authenticationToken
            }
        ],

        withCredentials: false
    };

    public get rifmDocumentSource(): string {
        return this._rifmDocumentSource;
    }

    public set rifmDocumentSource(value: string) {
        this._rifmDocumentSource = value;
        this._pdfViewerComponent.load(this._rifmDocumentSource, null);
        // this.documentPath = 'data:application/pdf;base64,' + value;
    }

    public get downloadFileName() {
        return (this._pdfViewerComponent != null) ? this._pdfViewerComponent.documentPath : '';
    }

    public get canShowPDF() {
        return this._canShowPDF;
    }

    public set canShowPDF(value) {
        this._canShowPDF = value;
    }

    public get documentPath() {
        return this._documentPath;
    }

    public set documentPath(value: string) {
        this._documentPath = value;
    }

    public get enableToolbar(): boolean {
        return this._enableToolbar;
    }

    public set enableToolbar(value: boolean) {
        this._enableToolbar = value;
        this._pdfViewerComponent.toolbar.showToolbar(value);
        this._pdfViewerComponent.toolbar.showAnnotationToolbar(value);
    }

    public get hasChanges(): boolean {
        return this._hasChanges;
    }

    public set hasChanges(value) {
        this._hasChanges = value;

        if (this._hasChanges) {
            this.stateChange.emit('documentchanged');
        }
    }

    public get pdfPageCount() {
        if (this._pdfViewerComponent != null && this._pdfViewerComponent.documentPath != '') {
            return this._pdfViewerComponent.pageCount + '';
        }

        return '';
    }

    public get showPDFTronDocument() {
        return this._showPDFTronDocument;
    }

    public set showPDFTronDocument(value) {
        this._showPDFTronDocument = value;
    }

    public get toolbarSettings() {
        return this._toolbarSettings;
    }

    public set toolbarSettings(value) {
        this._toolbarSettings = value;
    }

    get utilFns() {
        return UtilFns;
    }

    // **************************************************************************
    // public methods
    // **************************************************************************
    public setEditMode() {
        this.enableToolbar = true;
        this.toolbarSettings = this._toolbarSettingsEdit;

        if (this._pdfViewerComponent != null && this._pdfViewerComponent.toolbar != null) {
            this._pdfViewerComponent.toolbar.showToolbar(true);
        }
    }

    public setViewMode() {
        this.enableToolbar = false;
        this.toolbarSettings = this._toolbarSettingsView;

        if (this._pdfViewerComponent != null && this._pdfViewerComponent.toolbar != null) {
            this._pdfViewerComponent.toolbar.showToolbar(false);
        }
    }

    // **************************************************************************
    // pdf viewer ajax events
    // **************************************************************************
    public ajaxRequestInitiate(e: AjaxRequestInitiateEventArgs): void {
        // console.log(e);
        // this.stateChange.emit('ajaxrequeststarted');
    }

    public ajaxRequestSuccess(e: AjaxRequestSuccessEventArgs): void {
        this._processingMessage = '';
        this.stateChange.emit('success');
    }

    public ajaxRequestFailed(e: AjaxRequestFailureEventArgs): void {
        this._processingMessage = '';
        this.stateChange.emit('error');
    }

// download actually saves the file
    public downloadStart(e: DownloadStartEventArgs): void {
        this.stateChange.emit('downloadstart');
    }

    public downloadEnd(e: DownloadEndEventArgs): void {
        this.hasChanges = false;
        this._processingMessage = '';
        this.stateChange.emit('downloadend');
    }

    // **************************************************************************
    // pdf viewer editing events
    // **************************************************************************
    public annotationAdded(e: AnnotationAddEventArgs): void {
        this.hasChanges = true;
    }

    public annotationRemoved(e: AnnotationRemoveEventArgs): void {
        this.hasChanges = true;
    }

    public annotationResized(e: AnnotationResizeEventArgs): void {
        this.hasChanges = true;
    }

    public beforeAddFreeText(e: BeforeAddFreeTextEventArgs): void {
        this.hasChanges = true;
    }

    public commentAdded(e: CommentEventArgs): void {
        this.hasChanges = true;
    }

    public commentDeleted(e: CommentEventArgs): void {
        this.hasChanges = true;
    }

    public commentEdited(e: CommentEventArgs): void {
        this.hasChanges = true;
    }

    public documentLoad(e: LoadEventArgs): void {
        this.hasChanges = false;
        this.stateChange.emit('documentloaded');

    }

    public documentLoadFailed(e: LoadFailedEventArgs) {
        this._processingMessage = '';
        this.stateChange.emit('loadfailederror');
    }

    public exportFailure(e: ExportFailureEventArgs): void {
        alert(e.errorDetails);
    }

    public formFieldAdd(e: FormFieldAddArgs): void {
        this.hasChanges = true;
    }


    public annotationPropertiesChange (e: AnnotationPropertiesChangeEventArgs): void {
        this.hasChanges = true;
    }


    public pageChange (e: PageChangeEventArgs): void {
        this.hasChanges = true;
    }

}

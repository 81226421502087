import { Component, forwardRef, Inject, OnInit } from '@angular/core';
import * as _ from 'lodash';

import { provideParent, ReferenceState, StateMap, UnitOfWork } from '../../services/common';
import { EditPropParent } from '../../controls/common';

import { Reference, SpecialLink, TypeKeyword } from '../../entities/EntityModels';
import { ReferenceEditorComponent } from './reference-editor.component';

@Component({
    selector: 'reference-editor-special',
    templateUrl: './reference-editor-special.html',
    providers: [provideParent(ReferenceEditorSpecialComponent, EditPropParent)]
})
export class ReferenceEditorSpecialComponent implements OnInit, EditPropParent {

    private _defaultProps: { keyword: string, reason: string };
    private _linksDeleted = false;

    _data: {
        entity: Reference,
        inSubEditor: boolean,
        onCancelMap: {},
        newSpecialLink?: SpecialLink;

    };
    _groupedLinks: {};
    _typeKeywords: TypeKeyword[];

    constructor(@Inject(forwardRef(() => ReferenceEditorComponent)) public _parent: ReferenceEditorComponent,
                public _uow: UnitOfWork, public _stateMap: StateMap, private _referenceState: ReferenceState) {

    }

    ngOnInit() {
        this._data = this._parent.data;
        this._data.onCancelMap['special'] = this.onCancel.bind(this);
        this.groupSpecialLinks(this._entity);


    }

    get _entity() {
        return this._data.entity;
    }

    getError(propName: string) {
        return this._data.entity.getErrorFor(propName);
    }

    onCreateLink() {
        const data = this._data;

        this.getPreviousLinkProperties(); // In most cases, the keyword and reason are the same for every material special linked to a reference.

        const p = {
            keyword: 'ZZZ',
            referenceId: this._data.entity.referenceId,
            materialId: 0
        };
        const newSpecialLink = this._uow.specialLinkFactory.create(p);

        newSpecialLink.keyword = this._defaultProps.keyword;
        newSpecialLink.reason = this._defaultProps.reason;

        data.newSpecialLink = newSpecialLink;
        data.inSubEditor = true;
    }

    onApplyLink(specialLink: SpecialLink) {
        const data = this._data;
        if (specialLink != null) {
            this.groupSpecialLinks(this._entity);
        }
        data.newSpecialLink = null;
        data.inSubEditor = false;
    }

    onDetachLink(sl: SpecialLink) {

        if (sl.entityAspect.entityState.isAdded() == false && this._linksDeleted == false) {
            this._linksDeleted = true;
            this._referenceState.recordSpecialLinkDelete(this._data.entity);
        }

        sl.entityAspect.setDeleted();
        this.groupSpecialLinks(this._entity);
    }

    groupSpecialLinks(reference: Reference) {
        const r = reference.specialLinks;
        const groups = _.groupBy(r, x => x.explanation());
        const groupedLinks = _.keys(groups).map(k => {
            // var groupLinks = _.sortBy(groups[k], (sl: SpecialLink) => sl.material.principalSynonym().synonymWordOrWebVersion())
            const groupLinks = _.sortBy(groups[k], (sl: SpecialLink) => {
                return _.get(sl, 'material.principalSynonym().synonymWordOrWebVersion()', '');
            });
            return { key: k, specialLinks: groupLinks };
        });
        this._groupedLinks = _.sortBy(groupedLinks, g => g.key);
    }

    private getPreviousLinkProperties() {
        if (this._data.entity.specialLinks == null || this._data.entity.specialLinks.length < 1) {
            this._defaultProps = { keyword: '', reason: null };
        } else {
            const lastlink = this._data.entity.specialLinks[this._data.entity.specialLinks.length - 1];
            this._defaultProps = { keyword: lastlink.keyword, reason: lastlink.reason };
        }
        return;
    }

    onCancel() {
    }

}

import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import * as _ from 'lodash';

import { provideParent, UnitOfWork } from '../../services/common';
import { UtilFns } from '../../utils/common';
import { EditPropParent } from '../../controls/common';

import { Material, SpecialLink, TypeKeyword } from '../../entities/EntityModels';

import { MaterialSelectorComponent } from '../material/material-selector.component';

@Component({
    selector: 'special-link-editor',

    templateUrl: './special-link-editor.html',
    providers: [provideParent(SpecialLinkEditorComponent, EditPropParent)]
})
export class SpecialLinkEditorComponent implements OnInit, EditPropParent {
    @Input() entity: SpecialLink;
    @Output() apply = new EventEmitter<SpecialLink>();
    @ViewChild(MaterialSelectorComponent, { static: true }) _materialSelectorComponent: MaterialSelectorComponent;

    _typeKeywords: TypeKeyword[];
    // We can't allow direct entry into the 'new' SpecialLink entity
    // because breeze will throw an error if the entity collides with an
    // existing one.  So we need to check before we add the entity.

    _keyword: string;
    _keywordErrorMessage: string;
    _material: Material;
    _materialErrorMessage: string;

    // constructor( @Inject(forwardRef(() => ReferenceEditorSpecialComponent)) _parent: ReferenceEditorSpecialComponent) {

    // }

    constructor(public _uow: UnitOfWork) {

    }

    ngOnInit() {
        this._uow.typeKeywordRepository.all().then(r => {
            this._typeKeywords = _.sortBy(r, x => x.keyword);
        });

        if (this.entity && this.entity.keyword) {
            this._keyword = this.entity.keyword;
        }
    }


    getError(propName: string) {
        if (propName == '_keyword') {
            return this._keywordErrorMessage;
        }
        if (propName == '_material') {
            return this._materialErrorMessage;
        }
        return this.entity.getErrorFor(propName);
    }

    canApply() {
        this._keywordErrorMessage = this._keyword ? '' : 'A Category must be selected.';
        this._materialErrorMessage = this._material ? '' : 'A Material must be selected';
        if (this._keywordErrorMessage || this._materialErrorMessage) {
            return false;
        }
        if (!this.entity.entityAspect.hasValidationErrors) {
            return false;
        }

        const specialLinks = this.entity.reference.specialLinks;
        if (specialLinks.some(sl => sl.materialId == this._material.materialId
            && sl.keyword == this._keyword)) {
            this._keywordErrorMessage = 'A special link for this Category and Material already exists.';
            this._materialErrorMessage = this._keywordErrorMessage;
            return false;
        }
        return true;
    }

    onApply(specialLink: SpecialLink) {
        if (specialLink == null) {
            this.entity.entityAspect.rejectChanges();
        } else {
            this.entity.keyword = this._keyword;
            this.entity.materialId = this._material.materialId;
        }
        this.apply.emit(specialLink);
    }

    onSelectMaterial() {
        UtilFns.showModal(this._materialSelectorComponent, this).then(mli => {
            if (mli == null) {
                return;
            }
            return this._uow.materialRepository.withId(mli.materialId);
        }).then(m => {
            this._material = m;
        });
    }

}

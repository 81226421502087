<div *ngIf="entity" class="row" style="margin-left: 14px;">
    <div style="background-color: lightgray;">
        <h4 class="inline" style="margin-left: 4px;">Workflow Status and History</h4>
        <h6 *ngIf="_isDataLoading" class="bold inline italic">(Still Loading...)</h6>
    </div>

    <div style="margin-left: 14px;">
        <div class="row" style="margin-left: 8px;">
            <h6 class="bold">Pending Actions</h6>

            <table class="table table-striped table-bordered table-condensed small" style="width:auto;" *ngIf="hasPendingActions()">
                <thead>
                    <tr>
                        <td>Action/Missing Data</td>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let r of _unfinishedReasons">
                        <td>{{r}}</td>
                    </tr>
                </tbody>
            </table>
            <h6 *ngIf="hasPendingActions() == false && _isDataLoading == false" class="italic" style="margin-left: 8px;">No Remaining Tasks</h6>
        </div>

        <div class="row" style="margin-left: 8px;">
            <h6 class="bold">Completed Actions</h6>
            <table class="table table-striped table-bordered table-condensed small" style="width:auto;" *ngIf="hasCompletedActions()">
                <thead>
                    <tr>
                        <td>Date</td>
                        <td>Completed Action</td>
                        <td>Completed By</td>
                        <td>Sent To/Notified</td>
                        <td *ngIf="editable">Undo</td>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let c of _workflowActivityCompletedItems">
                        <td>{{utilFns.formatDate(c.created) }}</td>
                        <td>{{c.workflowActionDescription}}</td>
                        <td>{{c.staffFirstName || ''}}</td>
                        <td>{{c.actionData}}</td>
                        <td *ngIf="editable"><a *ngIf="c.canDeleteFromWorkflowHistory" (click)="removeAction(c)">Remove</a></td>
                    </tr>
                </tbody>
            </table>

        </div>
    </div>
</div>


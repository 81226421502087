import { Directive, ElementRef, Input, OnChanges } from '@angular/core';
import HtmlGreekTranslator from './html-greek-translator';

// Example:
// <div class="h1-sm bold bg-info" [htmlGreek]="material.principalSynonym()?.synonymWordOrWebVersion" ></div>
@Directive({
    selector: '[htmlGreek]',
})
export class HtmlGreekDirective implements OnChanges {
    @Input() htmlGreek: string;

    constructor(public _el: ElementRef) {
    }

    ngOnChanges() {
        const newVal = HtmlGreekTranslator.safeEncode(this.htmlGreek);
        this._el.nativeElement.innerHTML = newVal;

    }
}

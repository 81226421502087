import { EntityBase } from './EntityBase';
import { Material } from './Material';
import { TypeMaterialComponentClass } from './TypeMaterialComponentClass';

import { Validator } from 'breeze-client';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

export class MaterialComponent extends EntityBase {

   /// <code> Place custom code between <code> tags
   public isMaterial(): boolean {
        return (this.materialId != null);
    }
   /// </code>

   // Generated code. Do not place code below this line.
   materialComponentId: number;
   materialId: number;
   cASNumber: string;
   componentName: string;
   componentNameFromLiterature: string;
   typeMaterialComponentClassId: number;
   dB1: string;
   dB5: string;
   wax20M: string;
   cECode: string;
   created: Date;
   createdUser: string;
   modified: Date;
   modifyUser: string;
   rowVersion: number;
   material: Material;
   typeMaterialComponentClass: TypeMaterialComponentClass;
}


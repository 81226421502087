<tab-container>
    <ng-template tab-pane title="Human Health" >
        <human-health [materialIds]="_materialIds" [toxType]="'H'" [tabPath]="'0'" [shouldFetchMaterials]="false"></human-health>
    </ng-template>
    <ng-template tab-pane title="Environment">
        <human-health [materialIds]="_materialIds" [toxType]="'E'" [tabPath]="'1'" [shouldFetchMaterials]="false"></human-health>
    </ng-template>
    <ng-template tab-pane title="Non-tox References">
        <tox-data-other [materialIds]="_materialIds" [tabPath]="'2'"></tox-data-other>
    </ng-template>
</tab-container>

import { Component, OnInit, ViewChild } from '@angular/core';

import { EditorService, ErrorLogger, provideParent, StateMap, UnitOfWork } from '../../services/common';
import { EditManager, UtilFns } from '../../utils/common';
import { EditPropParent, PageState } from '../../controls/common';

import { WhatsNewSelectorComponent } from './whats-new-selector.component';

import { Announcement } from '../../entities/EntityModels';
import { STAFF_ROUTES } from './staff.routes';


@Component({
    selector: 'whats-new-editor',
    templateUrl: './whats-new-editor.html',
    providers: [provideParent(WhatsNewEditorComponent, EditPropParent)]
})
export class WhatsNewEditorComponent implements OnInit, EditPropParent {
    @ViewChild(WhatsNewSelectorComponent, { static: true }) _whatsNewSelectorComponent: WhatsNewSelectorComponent;


    _maxProjectId: number;
    _edm: EditManager<Announcement>;
    _adding: boolean;
    // this is not the same pageState that the _edm is using.
    _pageState = new PageState(`What's New editor`);

    constructor(public _uow: UnitOfWork, public _stateMap: StateMap, public _editorService: EditorService,
                public _errorLogger: ErrorLogger) {

    }

    ngOnInit() {
        this._stateMap.currentRouteName = STAFF_ROUTES.WhatsNew.name;
        this._edm = this._editorService.createEditManager<Announcement>(this, 'Announcement');
        this._edm.pageState.canShowMessage = false; // no page state messages.
        this._edm.editing = false;

    }


    canDeactivate() {
        return !this._edm.hasEdits();
    }

    get entity() {
        return this._edm.currentEntity;
    }

    // Not really needed but in case this editor is ever used by non-staff.
    isStaff() {
        return this._stateMap.currentUser.isStaff;
    }


    onEdit() {
        UtilFns.showModal(this._whatsNewSelectorComponent, this, null).then((ann: Announcement) => {
            if (!ann) {
                return;
            }
            this.editAnnouncement(ann);
        });
    }

    editAnnouncement(ann: Announcement) {
        if (ann == null) {
            return;
        }
        this._adding = false;
        this._edm.onlyEntity = ann;

        this._edm.editing = true;

        return ann;
    }

    getError(propName: string) {
        const r = this.entity.getErrorFor(propName);
        return r;
    }


    // EditManager overrides

    onAdd() {
        const ann = this._edm.uow.announcementFactory.create();
        this._adding = true;
        this._edm.onlyEntity = ann;
        this._edm.editing = true;

    }

    onDelete() {
        return this._stateMap.yesNoComponent.showModal('Delete entire Announcement',
            'Are you sure?').then(ok => {
            if (ok) {
                this.deleteAnnouncement();
            }
        });
    }

    canSave() {
        if (!this._edm.hasEdits()) {
            return false;
        }
        // this._edm.canSaveCore does not include next line.
        if (!this._edm.validateBeforeSave()) {
            return false;
        }
        return true;
    }

    onSave() {
        this._edm.onSaveCore().then(() => {
            // refreshes the list of projects.
            this._whatsNewSelectorComponent.refresh();
            this._adding = false;
        });
    }

    deleteAnnouncement() {
        // delete children
        this.entity.announcementDetails.map(ad => ad).forEach(ad => {
            if (ad.entityAspect.entityState.isAdded()) {
                ad.entityAspect.rejectChanges();
            } else {
                ad.entityAspect.setDeleted();
            }
        });
        this._edm.onDeleteCore();
        return this._uow.commit().then(() => {
        }).then(() => {
            this._edm.setSavedStatus('Deleted');
            // Next line is needed because the onDeleteCore above will have modified all
            // entities in cache that ref'd this material.
            this._uow.rollback();
            this._whatsNewSelectorComponent.refresh();
        }).catch((e) => {
            this._uow.rollback();
            this._edm.setSaveFailedStatus('Delete failed: ' + e);
        });

    }

}

import {Component, forwardRef, Inject, Input, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import { Router } from '@angular/router';
import * as _ from 'lodash';

import { EditorService, provideParent, StateMap, UnitOfWork } from '../../services/common';
import { EditManager, UtilFns } from '../../utils/common';
import { EditPropParent } from '../../controls/edit-prop.component';

import {
    MaterialAdditionalIdentifier,
    TypeBotanicalSubdivision,
    TypeEssentialOil,
    TypeNaturalOccurrence,
    TypeTSCAClass
} from '../../entities/EntityModels';
import { MaterialListItem } from '../../entities/projections/MaterialListItem';

import { MaterialEditData, MaterialEditorComponent } from './material-editor.component';
import { MaterialResultsComponent } from '../material/material-results.component';

export enum MaterialAdditionalIdentifierMode { NotSet, Adding, Editing }

@Component({
    selector: 'material-editor-base',
    templateUrl: './material-editor-base.html',
    providers: [provideParent(MaterialEditorBaseComponent, EditPropParent)]
})
export class MaterialEditorBaseComponent implements OnInit, EditPropParent {
    @ViewChildren(MaterialResultsComponent) _materialResultsComponents: QueryList<MaterialResultsComponent>;

    _data: MaterialEditData;

    _edmIds: EditManager<MaterialAdditionalIdentifier>;
    _CASChanged: boolean;
    _CASCollisionWarning: string;
    _synonymChanged: boolean;
    _synonymCollisionWarning: string;
    _synonymError: string;
    _synonymName: string;
    _typeTSCAClasses: TypeTSCAClass[];
    _typeBotanicalSubdivisions: TypeBotanicalSubdivision[];
    _typeEssentialOils: TypeEssentialOil[];
    _typeNaturalOccurrences: TypeNaturalOccurrence[];

    _monographMaterials: MaterialListItem[];
    _fEMAMaterials: MaterialListItem[];
    _materialAdditionalIdentifiersHasChanges = false;
    _materialAdditionalIdentifierMode: MaterialAdditionalIdentifierMode = MaterialAdditionalIdentifierMode.NotSet;
    editMaterialAdditionalIdentifer: MaterialAdditionalIdentifier;

    editingAdditionalIdentifiers = false;
    missingAllAdditionalIdentifierFields = false;

    _noFEMANumberMatchesMsg         = '';
    _noMonographNumberMatchesMsg    = '';

    readonly _tabNumber: number = 0;

    constructor(@Inject(forwardRef(() => MaterialEditorComponent)) _parent: MaterialEditorComponent,
                public _uow: UnitOfWork, public _stateMap: StateMap, public _editorService: EditorService,
                public _router: Router) {

        this._data = _parent._data;
        this._data.onCancelMap['base'] = this.onCancel.bind(this);
        this._data.onSaveMap['base'] = this.onSave.bind(this);

        this._data.inSubEditor = false;
    }

    ngOnInit() {
        this._edmIds = this._editorService.createEditManager<MaterialAdditionalIdentifier>(this, 'MaterialAdditionalIdentifier');
        this._edmIds.entities = [];

        this._uow.typeTSCAClassRepository.all().then(r => {
            this._typeTSCAClasses = r;
        });
        this._uow.typeBotanicalSubdivisionRepository.all().then(r => {
            this._typeBotanicalSubdivisions = r;
        });
        this._uow.typeEssentialOilRepository.all().then(r => {
            this._typeEssentialOils = r;
        });
        this._uow.typeNaturalOccurrenceRepository.all().then(r => {
            this._typeNaturalOccurrences = r;
        });

        if (this._data.entity != null) {
            this._edmIds.entities = this._data.entity.materialAdditionalIdentifiers.slice(0);
        }
        this._edmIds.editing = false;

        this._data.currentTab = this._tabNumber;

    }

    checkIfNull(value: string) {
        if (value == '') {
            return null;
        } else {
            return value;
        }
    }

    onAdd() {
        this._synonymName = '';
        this.onSynonymChange();

        this._edmIds.entities = [];
        this._edmIds.editing = false;
    }

    onCancel() {
        if (this._data.inSubEditor == false) {
            this._fEMAMaterials = null;
            this._monographMaterials = null;
            this._CASCollisionWarning = '';
            this._synonymCollisionWarning = '';

            // Material Additional Identifiers
            this._edmIds.clearErrors();
            this._edmIds.editing = false;
            this.editingAdditionalIdentifiers = false;
            this.missingAllAdditionalIdentifierFields = false;
            this._materialAdditionalIdentifierMode = MaterialAdditionalIdentifierMode.NotSet;
            this._edmIds.entities = this._data.entity.materialAdditionalIdentifiers.slice(0);
        }
    }

    onEdit() {

        if (this._data.entity != null) {
            this._edmIds.entities = this._data.entity.materialAdditionalIdentifiers.slice(0);
        }
        this._edmIds.editing = false;
    }

    onSave() {
        this.refreshData();
    }

    getError(propName: string) {

        if (propName == '_synonymName' && this._data.adding) {
            if (this._synonymName && this._synonymName.trim().length > 0) {
                return this._synonymError;
            } else {
                return 'A principal synonym is required.';
            }
        }

        const r = this._data.entity.getErrorFor(propName);
        return r;
    }

    get material() {
        return this._data.entity;
    }

    onChangeCAS() {
        if (!this._CASChanged) {
            return;
        }

        this._CASChanged = false;

        this._data.entity.cASNumber = this._data.entity.realCASNumber;
        if (this._data.entity.cASNumber) {
            const numericalCASNumber = parseFloat(this._data.entity.cASNumber.replace(/-/g, ''));
            this._data.entity.numericalCASNumber = numericalCASNumber;
        } else {
            this._data.entity.numericalCASNumber = null;
        }

        if (this._data.entity.cASNumber) {
            this._uow.fetch('Materials/MaterialsByCASNumber', { realCASNumber: this._data.entity.cASNumber }).then(r => {
                const mlis = <MaterialListItem[]>r;
                // if no match
                if (mlis.length == 0 || (mlis.length == 1 && mlis[0].materialId == this._data.entity.materialId)) {
                    this._CASCollisionWarning = null;
                    return;
                }
                this._CASCollisionWarning = 'Just a warning but this CAS Number has already been used elsewhere.';

                if (mlis.some(mli => mli.materialId == this._data.entity.materialId)) {
                    return;
                }

                const maxMli = _.maxBy(mlis, mli => mli.numericalCASNumber);
                const maxNumber = maxMli.numericalCASNumber;
                let maxSuffix = maxNumber % 1;

                if (maxSuffix >= .9) {
                    maxSuffix = (1 + Math.round(maxSuffix * 100)) / 100;
                } else {
                    maxSuffix = (1 + Math.round(maxSuffix * 10)) / 10;
                }
                this._data.entity.numericalCASNumber = Math.floor(maxNumber) + maxSuffix;
            });
        }
    }

    onShowMongraphMaterials() {
        if (this._noMonographNumberMatchesMsg != '') {
            this._noMonographNumberMatchesMsg = '';
            return;
        }

        if (this._monographMaterials) {
            this._monographMaterials = null;
            return;
        }

        const num = this._data.entity.monographNumber;
        if (num == null || isNaN(num)) {
            if (isNaN(num)) {
                this._data.entity.monographNumber = this._data.maxMonographNumber + 1;
                this._noMonographNumberMatchesMsg = 'The non-numeric Monograph Number has been changed to the next available.';
            } else {
                this._noMonographNumberMatchesMsg = 'No Monograph Number has been assigned.';
            }
            this._monographMaterials = null;
            return;
        }

        if (num == this._data.maxMonographNumber + 1) {
            this._noMonographNumberMatchesMsg = 'This is a new Monograph Number.';
            this._monographMaterials = null;
            return;
        }

        const params = {
            monographNumber: num,
            materialId: this._data.entity.materialId
        };
        this._uow.fetch('Materials/FetchMaterialIdentifiersByMonographNumber', params).then(r => {
            if (r != null && r.length > 0) {
                this._monographMaterials = r;
            } else {
                this._noMonographNumberMatchesMsg = 'No matching RIFM IDs found.';
            }
        });
    }

    onShowFEMAMaterials() {
        if (this._noFEMANumberMatchesMsg != '') {
            this._noFEMANumberMatchesMsg = '';
            return;
        }

        if (this._fEMAMaterials) {
            this._fEMAMaterials = null;
            return;
        }

        const num = this._data.entity.fEMANumber;
        if (num == null || isNaN(num)) {
            if (isNaN(num)) {
                this._data.entity.fEMANumber = this._data.maxFEMANumber + 1;
                this._noFEMANumberMatchesMsg = 'The non-numeric FEMA Number has been changed to the next available.';
            } else {
                this._noFEMANumberMatchesMsg = 'No FEMA Number has been assigned.';
            }
            this._fEMAMaterials = null;
            return;
        }

        if (num == this._data.maxFEMANumber + 1) {
            this._noFEMANumberMatchesMsg = 'This is a new FEMA Number.';
            this._fEMAMaterials = null;
            return;
        }

        const params = {
            femaNumber: num,
            materialId: this._data.entity.materialId
        };
        this._uow.fetch('Materials/FetchMaterialIdentifiersByFEMANumber', params).then(r => {
            if (r != null && r.length > 0) {
                this._fEMAMaterials = r;
            } else {
                this._noFEMANumberMatchesMsg = 'No matching FEMA Numbers found.';
            }
        });
    }

    onSynonymChange() {
        if (!this._synonymChanged) {
            return;
        }

        this._synonymChanged = false;
        this._synonymError = '';
        this._synonymCollisionWarning = '';

        if (this._synonymName == null || this._synonymName.length < 3) {
            this._synonymError = 'You must enter a Principal synonym name with 3 or more characters.';
            return;
        }

        const synonymWord = this._synonymName;
        this._uow.fetch('Materials/MaterialsBySynonym', { synonymWord: UtilFns.encodeHackForAnd(synonymWord) }).then(r => {
            const mlis = <MaterialListItem[]>r;
            // if no match
            if (mlis.length == 0 || (mlis.length == 1 && mlis[0].materialId == this._data.entity.materialId)) {
                this._synonymCollisionWarning = null;
                return;
            }
            this._synonymCollisionWarning = 'Just a warning but this Synonym has already been used elsewhere.';
        });
    }

    checkConfidential(confidential: boolean) {
        if (!confidential) {
            this._data.entity.captiveCompany = null;
        }
    }

    hasFEMAMatchResponse() {
        return (this._noFEMANumberMatchesMsg != '' || (this._fEMAMaterials != null && this._fEMAMaterials.length > 0));
    }

    hasMonographMatchResponse() {
        return (this._noMonographNumberMatchesMsg != '' || (this._monographMaterials != null && this._monographMaterials.length > 0));
    }

    materialHasChanges(): boolean {
        // Make sure unit of work has material changes and not just materialadditionalidentifier changes
        return (!this._data.entity.entityAspect.entityState.isUnchanged());
    }

    materialAdditionalIdentifiersHasChanges(): boolean {
        return (this._edmIds.hasEdits());
    }

    materialHasValidationErrors(): boolean {
        if (!this._data.edm.validateBeforeSave()) {
            return true;
        }

        if (this.getError('_synonymName')) {
            return true;
        }

        if (this.editingAdditionalIdentifiers) {
            if (!this.hasAtLeastOneAdditionalIdentifierField(this.editMaterialAdditionalIdentifer)) {
                return true;
            }
        }
        return false;
    }

    refreshData() {
        this.missingAllAdditionalIdentifierFields = false;
        if (this._data.entity) {
            this._edmIds.entities = this._data.entity.materialAdditionalIdentifiers.slice(0);
        }
    }

    // EditManager overrides
    canSave(): boolean {
        if (this.editingAdditionalIdentifiers) {
            return false;
        }

        if (this.materialHasChanges() || this.materialAdditionalIdentifiersHasChanges()) {
            return (!this.materialHasValidationErrors());
        }
        return false;
    }

    canShowBack(): boolean {
        if (!this._data.edm.editing) {
            return false;
        }

        return (this.materialHasChanges() == false && this.materialAdditionalIdentifiersHasChanges() == false);
    }

    // Material Additional Identifiers
    confirmMaterialAdditionalIdChanges() {
        if (this._materialAdditionalIdentifierMode == MaterialAdditionalIdentifierMode.Adding) {
            this.addMaterialAdditionalIdentifier();
        } else if (this._materialAdditionalIdentifierMode == MaterialAdditionalIdentifierMode.Editing) {
            this.editMaterialAdditionalIdentifier();
        }
    }

    addMaterialAdditionalIdentifier() {
        if (!this.hasAtLeastOneAdditionalIdentifierField(this.editMaterialAdditionalIdentifer)) {
            this.missingAllAdditionalIdentifierFields = true;
            return;
        }

        this.applyMaterialAdditionalIdentifierChanges(this._edmIds.currentEntity, this.editMaterialAdditionalIdentifer);

        this.missingAllAdditionalIdentifierFields = false;
        this._materialAdditionalIdentifiersHasChanges = true;
        this.editingAdditionalIdentifiers = false;
        this._materialAdditionalIdentifierMode = MaterialAdditionalIdentifierMode.NotSet;
    }

    editMaterialAdditionalIdentifier() {

        if (this.editMaterialAdditionalIdentifer.hasChanges(this._edmIds.currentEntity)) { // compare versions
            if (!this.hasAtLeastOneAdditionalIdentifierField(this.editMaterialAdditionalIdentifer)) {
                this.missingAllAdditionalIdentifierFields = true;
                return;
            }

            this.applyMaterialAdditionalIdentifierChanges(this._edmIds.currentEntity, this.editMaterialAdditionalIdentifer);

            this.missingAllAdditionalIdentifierFields = false;
            this._materialAdditionalIdentifiersHasChanges = true;
        }
        this.editingAdditionalIdentifiers = false;
        this._materialAdditionalIdentifierMode = MaterialAdditionalIdentifierMode.NotSet;
    }

    cancelMaterialAdditionalIdChanges() {

        if (this._materialAdditionalIdentifierMode == MaterialAdditionalIdentifierMode.Adding) {
            if (this._edmIds.currentEntity) {
                const matId = this._edmIds.currentEntity;
                if (matId.entityAspect.entityState.isAdded()) {
                    matId.entityAspect.rejectChanges();
                } else {
                    matId.entityAspect.setDeleted();
                }
                _.remove(this._edmIds.entities, this._edmIds.currentEntity);
            }
        }

        this.editingAdditionalIdentifiers = false;
        this._materialAdditionalIdentifierMode = MaterialAdditionalIdentifierMode.NotSet;

    }

    hasAtLeastOneAdditionalIdentifierField(matAdditionalId: MaterialAdditionalIdentifier) {
        // 01/23/2019: ECNNumber no longer displayed so one of the remaining 4 values needs to be present
        return (matAdditionalId.cASNumber || matAdditionalId.eCL || matAdditionalId.eINECSNumber || matAdditionalId.eCInventoryNumber);
    }

    applyMaterialAdditionalIdentifierChanges(target: MaterialAdditionalIdentifier, source: MaterialAdditionalIdentifier) {
        // Remove empty strings that result from the user clearing the field data
        if (this.isStringNullOrEmpty(source.cASNumber)) {
            source.cASNumber = null;
        }
        if (target.cASNumber != source.cASNumber) {
            target.cASNumber = source.cASNumber;
        }

        if (this.isStringNullOrEmpty(source.eCL)) {
            source.eCL = null;
        }
        if (target.eCL != source.eCL) {
            target.eCL = source.eCL;
        }

        if (this.isStringNullOrEmpty(source.eINECSNumber)) {
            source.eINECSNumber = null;
        }
        if (target.eINECSNumber != source.eINECSNumber) {
            target.eINECSNumber = source.eINECSNumber;
        }

        if (this.isStringNullOrEmpty(source.eCNNumber)) {
            source.eCNNumber = null;
        }
        if (target.eCNNumber != source.eCNNumber) {
            target.eCNNumber = source.eCNNumber;
        }

        if (this.isStringNullOrEmpty(source.eCInventoryNumber)) {
            source.eCInventoryNumber = null;
        }
        if (target.eCInventoryNumber != source.eCInventoryNumber) {
            target.eCInventoryNumber = source.eCInventoryNumber;
        }

        if (target.dSLRegistration != source.dSLRegistration) {
            target.dSLRegistration = source.dSLRegistration;
        }

        if (target.eCNRegistration != source.eCNRegistration) {
            target.eCNRegistration = source.eCNRegistration;
        }

        if (target.eINECSRegistration != source.eINECSRegistration) {
            target.eINECSRegistration = source.eINECSRegistration;
        }

        if (target.nDSLRegistration != source.nDSLRegistration) {
            target.nDSLRegistration = source.nDSLRegistration;
        }

        if (target.reachRegistration != source.reachRegistration) {
            target.reachRegistration = source.reachRegistration;
        }

        if (target.tSCARegistration != source.tSCARegistration) {
            target.tSCARegistration = source.tSCARegistration;
        }

        if (target.typeMaterialIdentifierId != source.typeMaterialIdentifierId) {
            target.typeMaterialIdentifierId = source.typeMaterialIdentifierId;
        }

        return;
    }

    onAddMaterialAdditionalIdentifiers() {
        this._materialAdditionalIdentifierMode = MaterialAdditionalIdentifierMode.Adding;

        const entity = this._edmIds.uow.materialAdditionalIdentifierFactory.create();
        entity.materialId = this._data.entity.materialId;
        entity.typeMaterialIdentifierId = 'ALTERNATIVECAS';

        this._edmIds.entities.push(entity);
        this._edmIds.onSelect(entity);

        this.editMaterialAdditionalIdentifer = new MaterialAdditionalIdentifier(this._edmIds.currentEntity);
        this.editingAdditionalIdentifiers = true;
    }

    onDeleteAdditionalIdentifier(matid: MaterialAdditionalIdentifier) {

        if (matid.entityAspect.entityState.isAdded()) {
            matid.entityAspect.rejectChanges();
        } else {
            matid.entityAspect.setDeleted();
        }
        _.remove(this._edmIds.entities, matid);

    }

    onEditAdditionalIdentifier(matid: MaterialAdditionalIdentifier) {
        this._materialAdditionalIdentifierMode = MaterialAdditionalIdentifierMode.Editing;

        this._edmIds.onSelect(matid);
        this.editMaterialAdditionalIdentifer = new MaterialAdditionalIdentifier(matid);
        this.editingAdditionalIdentifiers = true;
    }

    get changeModeDescription() {
        return (this._materialAdditionalIdentifierMode == MaterialAdditionalIdentifierMode.Adding) ? 'Add Alternative Identifier' : 'Edit Alternative Identifer';
    }

    getCaptiveText(confidential: boolean): string {

        return confidential ? 'Captive' : '';
    }

    isStringNullOrEmpty(s: string): boolean {
        return (s == null || s.trim() === '');
    }
}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { StateMap, UnitOfWork, UserManager } from '../../services/common';
import { UtilFns } from '../../utils/common';
import { PageState } from '../../controls/common';

import { ROUTES } from '../routes';
import { REFERENCE_ROUTES } from './reference.routes';

import { Reference, ReferenceRelation } from '../../entities/EntityModels';

@Component({
    selector: 'synonyms',
    templateUrl: './reference-relations.html',
})
export class ReferenceRelationsComponent implements OnInit {

    _referenceId: number;
    _entities: ReferenceRelation[];
    _pageState = new PageState('Reference Relations');

    constructor(public _uow: UnitOfWork, public _userManager: UserManager, public _stateMap: StateMap,
                public _router: Router) {

    }

    ngOnInit() {
        this._stateMap.currentRouteName = REFERENCE_ROUTES.ReferenceRelations.name;
        this._referenceId = this._stateMap.currentReference.referenceId;
        this._uow.typeReferenceRelationRepository.all().then(r => {
            // this._typeReferenceRelations = r;
        });

        this.fetchEntities();
    }

    isStaff() {
        return this._userManager.currentUser.isStaff;
    }

    fetchEntities() {
        this._pageState.isLoading = true;

        const q = { referenceId: this._referenceId };
        this._uow.referenceRelationRepository
            .whereWithParams(q)
            .then(r => {
                this._entities = r;
                this._pageState.isLoaded = r;
            })
            .catch(e => {
                this._pageState.isLoaded = false;
            });
    }


    navToRef(r: Reference) {
        this._router.navigate(UtilFns.asRouterParts(ROUTES.Reference, r.referenceId));
    }


}

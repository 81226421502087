import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable()
export class MolImageService {

    constructor(public _http: HttpClient) {
    }


    public getMaterialImage(materialId: number): Promise<any> {
        const url = this.getImageUrlForMaterial(materialId);
        return this._http.get<string>(url)
            .toPromise()
            .catch(() => {
            });
    }

    public getBase32SmileUrl(materialId: number): Promise<any> {
        const url = `${environment.appUrl}/breeze/MolImage/Base32SmileByMaterial?id=${materialId}`;
        return this._http.get<string>(url)
            .toPromise()
            .then(r => {
                if (r) {
                    return `${environment.appUrl}/cache/${r}.png`;
                }
                return null;
            })
            .catch(() => {
            });
    }

    public getImageUrlForMaterial(materialId: number) {
        return `${environment.appUrl}/breeze/MolImage/ByMaterial?id=${materialId}`;
    }

    public getImageUrlForSmile(smile: string) {
        return `${environment.appUrl}/breeze/MolImage/BySmile?smile=${smile}`;
    }

    public getMoleculeFromMaterial(materialId: number): Promise<any> {
        const url = `${environment.appUrl}/breeze/MolImage/MoleculeFromMaterial?materialId=${materialId}`;
        return this._http.get<string>(url)
            .toPromise()
            .catch(() => {
            });
    }

    public getBase32SmileUrlWithSize(materialId: number, size: string): Promise<any> {
        const url = `${environment.appUrl}/breeze/MolImage/Base32SmileByMaterial?id=` + materialId + '&size=' + size;
        return this._http.get<string>(url)
            .toPromise()
            .then(r => {
                if (r) {
                    return `${environment.appUrl}/cache/${r}.png`;
                }
                return null;
            }).catch(er => { });
    }

    public getImageUrlForMaterialWithSize(materialId: number, imageSize: string) {
        return 'breeze/MolImage/ByMaterial?id=' + materialId + '&size=' + imageSize;
    }

}

import { Component, OnInit, ViewChild, ElementRef} from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';

import { UnitOfWork, StateMap, MolImageService } from '../../services/common';
import { UtilFns, LocationFns } from '../../utils/common';
import { PageState, TabContainer } from '../../controls/common';

import { ROUTES } from '../routes';
import { FSAG, MaterialFSAG } from '../../entities/EntityModels';

// TODO: need to support 'sub'-sorting by columns 'FullRefencned 
@Component({
    templateUrl: './material-groups.html',
})
export class MaterialGroupsComponent implements OnInit {
    @ViewChild(TabContainer, { static: true }) _tabContainer: TabContainer;
    
    _FSAGs: FSAG[];
    _materialFSAGs: MaterialFSAG[];
    _materialIds: number[];
    _canShowMaterials: boolean;
    _pageState: PageState = new PageState("Material Groups");
    _image: any;
    _tab: number;
    _fsagIx: number;
  
    constructor(public _uow: UnitOfWork, public _stateMap: StateMap, public _molImageService: MolImageService, 
        public _location: Location, public _router: Router, public _elementRef: ElementRef ) {   
           
    }
    
    ngOnInit() {
        this._tab = LocationFns.getTab(this._location) ||  0;
        this._pageState.isLoading = true;
        this._uow.fSAGRepository.all().then(r => {
            // insert a blank line at the top of the select box.
            r.unshift(null);
            this._FSAGs = r;
        
            var fsagIx = LocationFns.getParamValue(this._location, 'fsagIx');
            if (fsagIx) {
                this.selectFSAGIndex(parseInt(fsagIx));
            }
            this._pageState.isLoaded = true;
        }).catch( () => {
            this._pageState.isLoadedWithNoData = true;
        })
    }
       
    selectEntireTable() {
        var eles = this._elementRef.nativeElement.getElementsByTagName('table');
        if (eles.length)
        var range= UtilFns.selectDOMElement(eles[0]);
        // range.execCommand("Copy");
    }
    
    selectFSAG(event: any) {
        // on a filterChange we do NOT need to get a new list of filters.
        var ix = event.currentTarget.selectedIndex;
        this.selectFSAGIndex(ix);
    }
    
    selectFSAGIndex(fsagIx: number) {
        this._fsagIx = fsagIx;
        LocationFns.setParamValue(this._location, 'fsagIx', fsagIx.toString());
        // if the blank line at the top of the select is picked.
        if (fsagIx == 0) {
            this._canShowMaterials = false;
            return;
        }
        var fsag = this._FSAGs[fsagIx];

        this._canShowMaterials = false;
        var p =  { materialId: <number> null, sagId: fsag.sAGID };
        this._uow.materialFSAGRepository.whereWithParams(p).then(r => {
            this._canShowMaterials = true;
            this._materialFSAGs = UtilFns.sort(r, true, this.getName);
            this._materialIds = this._materialFSAGs.map(fsag => {
                this._molImageService.getBase32SmileUrl(fsag.material.materialId).then( (url) => {
                    fsag.material.base32SmileUrl = url;
                });   
                return fsag.materialId;
            });
            // _tabContainer in an ngIf that won't be available until next tic - after _canShowMaterial is true.
            // setTimeout(() => this._tabContainer.selectTab(this._tab), 0);
            this._tabContainer.selectTab(this._tab);            
        })
    }
    
    onCasSelect(materialFSAG: MaterialFSAG) {
        this._router.navigate(UtilFns.asRouterParts(ROUTES.Material, materialFSAG.material.materialId ));
        
    }

    getName(materialFSAG: MaterialFSAG) {
        return materialFSAG.material.principalSynonym().synonymWordOrWebVersion();
    }
    
    onTabChange(paneNumber: number) {
        if (paneNumber == 0) {
            LocationFns.setTab(this._location, '0');
        }
    }

}

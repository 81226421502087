import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';

import { StateMap } from '../../services/common';
import { LocationFns } from '../../utils/common';
import { TabContainer } from '../../controls/common';

import { MATERIAL_ROUTES } from './material.routes';
import { Material } from '../../entities/Material';

@Component({
    selector: 'toxic-data',
    templateUrl: './toxic-data.html',
})
export class ToxicDataComponent implements OnInit, AfterViewInit {
    @ViewChild(TabContainer, { static: true }) _tabContainer: TabContainer;

    material: Material;
    _materialIds: number[];
    _tab: number;

    constructor(public _stateMap: StateMap, public _location: Location) {
    }

    ngOnInit() {
        this.material = this._stateMap.currentMaterial;
        this._materialIds = [this._stateMap.currentMaterial.materialId];
        this._stateMap.currentRouteName = MATERIAL_ROUTES.ToxicData.name;
        this._tab = LocationFns.getTab(this._location) || 0;
    }

    ngAfterViewInit() {
        setTimeout(() => {
            this._tabContainer.selectTab(this._tab);
        }, 1);
    }
}

import {Component, Input, Output, ViewChild, OnInit, EventEmitter} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import * as _ from 'lodash';
import { UnitOfWork, StateMap, UserManager, ErrorLogger } from '../../services/common';
import { UtilFns, IEditHost, EditManager } from '../../utils/common';
import { RIFMDocument, RIFMDocumentFolder, RIFMDocumentArchive, TypeRIFMDocument } from '../../entities/EntityModels';
import { DocumentFoldersListItem } from '../../entities/projections/DocumentFoldersListItem';
import { ArchivedReferenceDocumentListItem } from '../../entities/projections/ArchivedReferenceDocumentListItem';
import { SelectableEntity } from '../../entities/projections/SelectableEntity';
import { DOCUMENTMANAGEMENT_ROUTES } from './document-management.routes';

export enum SearchType { REFERENCEID, RECENTLYARCHIVED }

@Component({
    selector: 'archived-document-selector',
    templateUrl: './archived-document-selector.html'
})
export class ArchivedDocumentSelectorComponent implements OnInit {
    @Input() showSelectors: boolean;
    @Output() stateChange = new EventEmitter<string>();

    private _isLoading: boolean;

    // Default type ids
    public _typeRIFMDocumentId: string;
    public _selectedRIFMDocumentArchive: ArchivedReferenceDocumentListItem;

    public colorUnselected = '#FCFCFC';
    public colorSelected   = '#C6FAE8';

    public _searchType: SearchType  = SearchType.REFERENCEID;

    public _searchTerm: string;
    public _searchErrorMessage: string;
    public _searchTypeMessage   = 'Enter ReferenceIds separated by a comma.';

    public _rifmDocumentArchivesEx: SelectableEntity<ArchivedReferenceDocumentListItem>[] = [];
    public _recentRIFMDocumentArchivesEx: SelectableEntity<ArchivedReferenceDocumentListItem>[] = [];

    _isDataLoading: boolean;

    constructor(public _stateMap: StateMap, private _uow: UnitOfWork) {
    }

    ngOnInit() {
        // clear out chrome autofill of search term input
        this._searchTerm = '';
    }

    get utilFns() {
        return UtilFns;
    }

    // **************************************************************************************
    // load archived documents data
    // **************************************************************************************
    fetchRIFMDocumentArchivesByReferenceIds(referenceIds: number[]) {
        this._rifmDocumentArchivesEx = [];

        this._uow.fetch('DocumentManagement/FetchRIFMDocumentArchivesByReferenceId', { referenceIds: referenceIds }).then ( f => {
            if (f != null && f.length > 0) {
                f.forEach((element: ArchivedReferenceDocumentListItem) => {
                    this._rifmDocumentArchivesEx.push(new SelectableEntity<ArchivedReferenceDocumentListItem>(element, '#FFFFFF'));
                });
            } else {
                this._searchErrorMessage = 'No Archived Reference Documents found';
            }
        });
    }

    fetchRecentlyArchivedRIFMDocuments() {
        this._recentRIFMDocumentArchivesEx = [];

        this._uow.fetch('DocumentManagement/FetchRecentlyArchivedRIFMDocuments', { takeCount: 25 }).then ( f => {
            if (f != null && f.length > 0) {
                f.forEach((element: ArchivedReferenceDocumentListItem) => {
                    this._recentRIFMDocumentArchivesEx.push(new SelectableEntity<ArchivedReferenceDocumentListItem>(element, '#FFFFFF'));
                });
            } else {
                this._searchErrorMessage = 'No Reference Documents found';
            }
        });
    }

    // **************************************************************************************
    // search by referenceid(s)
    // **************************************************************************************
    search() {
        let hasErrors = false;

        this._searchErrorMessage = '';

        if (this.searchType == SearchType.REFERENCEID) { // search by ReferenceIds
            const referenceIds = this._searchTerm.split(',').map(function (item) {
                const num = Number(item.trim());
                if (isNaN(num)) {
                    hasErrors = true;
                } else {
                    return num;
                }
            });
            if (hasErrors) {
                this._searchErrorMessage = 'Hmmm, looks like some of the ReferenceIds are not numbers.';
                return;
            }
            return this.fetchRIFMDocumentArchivesByReferenceIds(referenceIds);
        }
    }

    // filter interactions
    canSearch() {
        return (this.searchType == SearchType.REFERENCEID && this._searchTerm && this._searchTerm.trim().length > 0);
    }

    showSearchElements() : boolean {
        return (this.searchType != SearchType.RECENTLYARCHIVED);
    }

    public filterButtonColor(filtertype: string) {
        switch (filtertype) {
            case 'reference': {
                return (this.searchType == SearchType.REFERENCEID) ? this.colorSelected : this.colorUnselected;
                break;
            }
            case 'recentlymodified': {
                return (this.searchType == SearchType.RECENTLYARCHIVED) ? this.colorSelected : this.colorUnselected;
                break;
            }
            default: {
                return this.colorUnselected;
                break;
            }
        }
    }

    // toggle button
    onSelectFilterType(type: string) {
        this._searchErrorMessage = '';

        if (type == 'reference') {
            this.searchType = SearchType.REFERENCEID;
        } else {
            this.searchType = SearchType.RECENTLYARCHIVED;
            // no filters are required so run the search immediately
            this.fetchRecentlyArchivedRIFMDocuments();
        }
    }

    public refreshWithRecentlyArchived() {
        this.onSelectFilterType('recentlyarchived');
    }

    public onView(dx: SelectableEntity<ArchivedReferenceDocumentListItem>) {
        if (!dx) {return; }

        this._selectedRIFMDocumentArchive = dx.data;
        this.stateChange.emit('view');

        if (this.searchType == SearchType.REFERENCEID) {
            const items = _.clone(this._rifmDocumentArchivesEx);
            this._rifmDocumentArchivesEx = this.resetBackgroundColor(items);
        } else {
            const items = _.clone(this._recentRIFMDocumentArchivesEx);
            this._recentRIFMDocumentArchivesEx = this.resetBackgroundColor(items);
        }

        dx.backgroundColor = this.colorSelected;

    }

    // link click events
    public onEdit(dx: SelectableEntity<ArchivedReferenceDocumentListItem>) {
        if (!dx) {return; }

        this._selectedRIFMDocumentArchive = dx.data;
        this.stateChange.emit('edit');
    }

    public onRestore(dx: SelectableEntity<ArchivedReferenceDocumentListItem>) {
        if (!dx) {return; }

        this._selectedRIFMDocumentArchive = dx.data;
        this.stateChange.emit('restore');
    }

    resetBackgroundColor(archives: SelectableEntity<ArchivedReferenceDocumentListItem>[]) {

        archives.forEach((element, index) => {
            element.backgroundColor = this.colorUnselected;
        });

        return archives;
    }

    // *****************************************************************************
    // bindable properties
    // *****************************************************************************
    public get filterSearchType(): typeof SearchType {
        return SearchType;
    }

    public get searchType(): SearchType {
        return this._searchType;
    }

    public set searchType(value) {
        this._searchType = value;
    }

    public get searchTypeMessage() {
        return this._searchTypeMessage;
    }

    public set searchTypeMessage(value) {
        this._searchTypeMessage = value;
    }

    public get selectedFileName(): string {
        return (this._selectedRIFMDocumentArchive != null) ? this._selectedRIFMDocumentArchive.fileName : null;
    }

    public get selectedOriginalFileName(): string {
        return (this._selectedRIFMDocumentArchive != null) ? this._selectedRIFMDocumentArchive.originalFileName : null;
    }

    public get selectedReferenceId(): number {
        return (this._selectedRIFMDocumentArchive != null) ? this._selectedRIFMDocumentArchive.referenceId : null;
    }

    public get selectedRIFMDocumentArchive(): ArchivedReferenceDocumentListItem {
        return this._selectedRIFMDocumentArchive;
    }

    public set selectedRIFMDocumentArchive(val: ArchivedReferenceDocumentListItem) {
        this._selectedRIFMDocumentArchive = val;
    }
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';

import { StateMap, UnitOfWork } from '../../services/common';
import { EditManager, IEditHost } from '../../utils/common';

import { MATERIAL_ROUTES } from './material.routes';
import { HouseStatusComponent } from './house-status.component';
import { HouseStatus } from '../../entities/HouseStatus';

@Component({
    selector: 'house-statuses',
    templateUrl: './house-statuses.html',
})
export class HouseStatusesComponent implements IEditHost<HouseStatus>, OnInit {
    @ViewChild(HouseStatusComponent) editingComponent: HouseStatusComponent;

    _materialId: number;
    _edm: EditManager<HouseStatus>;

    constructor(public _uow: UnitOfWork, public _stateMap: StateMap, public _location: Location) {

    }

    ngOnInit() {
        this._stateMap.currentRouteName = MATERIAL_ROUTES.RIFMStatus.name;
        this._edm = new EditManager<HouseStatus>(this, this._uow, this._stateMap, 'House Status');

        const material = this._stateMap.currentMaterial;
        this._materialId = material.materialId;
        this._edm.pageState.isLoading = true;
        const p = {materialId: this._materialId};
        this._uow.houseStatusRepository.whereWithParams(p).then(r => {
            this._edm.entities = r;
            this._edm.pageState.isLoaded = r;
        }).catch(e => {
            this._edm.pageState.isLoaded = [];
        });

    }

    canDeactivate() {
        return !this._edm.hasEdits();
    }

    onAdd() {
        if (this._edm.editing && this.editingComponent) {
            this.editingComponent.apply();
            if (!this._edm.validateCurrent()) {
                return;
            }
        }

        // TODO: add a unique validation for materialId, product pair.
        const config = {
            materialId: this._materialId,
            topic: '-'
        };
        const entity = this._uow.houseStatusFactory.create(config);
        entity.topic = '';
        this._edm.entities.push(entity);
        this._edm.onSelect(entity);
    }


}

import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { MaterialAdditionalIdentifier } from '../../entities/EntityModels';
import { UnitOfWork } from '../../services/common';
import { UtilFns } from '../../utils/common';
import { trigger, state, style, animate, transition } from '@angular/animations';

@Component({
    selector: 'material-additional-identifiers',
    templateUrl: './material-additional-identifiers.html',
    animations: [
        trigger('visibility', [
            state('shown', style({
                opacity: 1
            })),
            state('hidden', style({
                opacity: 0
            })),
            transition('* => *', animate('.5s'))
        ])
    ],
})

export class MaterialAdditionalIdentifiersComponent implements OnInit, OnChanges {

    _additionalMaterialIds: MaterialAdditionalIdentifier[];

    visibility = 'hidden';

    @Input() materialId: number;
    @Input() defaultVisibility: string;

    constructor(private _uow: UnitOfWork) {
    }

    ngOnInit() {
        this.visibility = (this.defaultVisibility != null) ? this.defaultVisibility : 'hidden';
    }

    ngOnChanges() {
        this.getMaterialAdditionalIdentifiers();
    }

    getMaterialAdditionalIdentifiers() {
        if (this.materialId) {
            const params = {materialId: this.materialId};
            return this._uow.fetchTyped('Materials/MaterialAdditionalIdentifierForMaterial', MaterialAdditionalIdentifier, params)
                .then(r => {
                    this._additionalMaterialIds = r;
                });
        }
    }

    get utilFns() {
        return UtilFns;
    }

    toggleVisibility() {
        this.visibility = this.visibility == 'shown' ? 'hidden' : 'shown';
    }
}

<editor-header [edm]="_edm" [femaRestricted]="true"></editor-header>

<div *ngIf="_principalNameWarning" >
    <span class="alert-warning bold">{{_principalNameWarning}}</span>
</div>

<default-table *ngIf="_edm.hasEntities()" [attr.data-hidden]="_edm.editing">
    <thead>
        <tr>
            <td>Synonym</td>
            <!--<td>Principal</td>-->
            <td>Used By</td>
            <td *ngIf="_edm.isStaff()">Principal</td>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let s of _edm.entities" (click)="_edm.onSelect(s)">
            <td><span [htmlGreek]="s.synonymWordOrWebVersion()"></span></td>
            <!--<td><input type="checkbox" disabled [checked]="s.principal"></td>-->
            <td><span class="bold" *ngIf="s.principal">Principal{{s.useList ? ' - ' : ''}}</span>{{s.useList}}</td>
            <td *ngIf="_edm.isStaff()"><input type="checkbox" disabled="disabled" [(ngModel)]="s.principal"></td>
            <!--<td>{{s.synonymWord}}</td>-->
            <!-- <td>{{s.entityAspect.entityState}}</td> -->
        </tr>
    </tbody>
    
</default-table>

<div *ngIf="_edm.editing">
    <synonym #self [synonym]="_edm.currentEntity" [entities]="_edm.entities"></synonym>
</div>

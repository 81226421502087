<page-status [pageState]="_pageState"></page-status>
<div *ngIf="showDetailsToggle()" class="row">
    <div class="inline reference-toggle-container">
        <span class="reference-toggle-text inline">Show Study Details</span>&nbsp;
        <label class="toggle">
            <input type="checkbox" (change)="toggleDetailsDisplay($event)">
            <span class="toggle-container round"></span>
        </label>
    </div>
</div>

<div class="edit-page" *ngIf="reference">
    <div *ngFor="let bd of biologicalDatas; let ix=index">
        <div class="panel panel-default small" style="margin-right: 30px;">
            <div class="panel-body">
                <div class="row">
                    <div class="col-md-2">
                        <button type="button" style="margin-left: 0px; margin-right: 5px" (click)="showHide('expMaterial', ix)" class="btn btn-default btn-xs">
                            <span *ngIf="isVisible('expMaterial', ix)" class="fa fa-minus"></span>
                            <span *ngIf="!isVisible('expMaterial', ix)" class="fa fa-plus"></span>
                        </button>
                        <label>Sub-Reference:</label>
                        <span>{{bd.subReference}}</span>
                    </div>
                    <div class="col-md-2">
                        <label class="ref-label">Study:</label>
                        <span>{{bd.typeStudy?.studyLong}}</span>
                        <span *ngFor="let stDescr of bd?.studySubTypeDescriptions()">
                            <br><span style="margin-left: 55px;" class="bold small">{{stDescr}}</span>
                        </span>
                    </div>
                    <div class="col-md-2">
                        <label class="ref-label">Route:</label>
                        <span [htmlGreek]="bd.typeRoute?.routeLong"></span>
                    </div>
                    <div class="col-md-2">
                        <label class="ref-label">Species:</label>
                        <span [htmlGreek]="bd.typeSpecies?.speciesLong"></span>
                    </div>
                    <div class="col-md-3">
                        <p><span class="bold">Compliance: </span>{{bd.compliance || '-'}}</p>
                    </div>
                    <div class="col-md-1">
                        <a class="pull-right" (click)="showHide('bioDetail', ix)">{{getShowHideText('bioDetail', ix)}}</a>
                    </div>
                </div>
                <div class="row">
                    <div *ngIf="isVisible('bioDetail', ix)" style="margin-left: 30px; margin-right:-30px;" class="col-md-11">
                        <p><span class="bold">Protocol: </span>{{bd.protocol || '-'}}</p>
                    </div>
                    <div *ngIf="!isVisible('bioDetail', ix)" style="margin-left: 30px; margin-right:-30px;" class="col-md-11">
                        <!--<span>{{abbreviate(bd.protocol, 500)}}</span>-->
                        <div class="ellipsis-4" type="text"><span class="bold">Protocol: </span>{{bd.protocol}}</div>
                    </div>
                    <div class="col-md-1">
                        <button type="button" (click)="doReport(bd, null)" class="btn btn-no-margin btn-xs btn-primary pull-right">Full Report</button>
                    </div>
                </div>
                <div *ngIf="isVisible('bioDetail', ix)">
                    <div class="row indent-top-sm">
                        <div class="col-md-3 shift-left-md">
                            <label class="ref-label">Methodology:</label>
                            <!--<span>{{bd.methodologyReference}}</span>-->
                            <span *ngFor="let briefRef of bd?.getMethodologyBriefRefs()">
                                <a *ngIf="isClickableBriefRef(briefRef)" (click)="goToBriefRef(briefRef)">{{briefRef}}</a>
                                <span *ngIf="!(isClickableBriefRef(briefRef))">{{briefRef}}</span>
                                <span>;&nbsp;</span>
                            </span>
                        </div>
                        <div class="col-md-3">
                            <label>Subject:</label>
                            <label style="margin-left: 5px">M:</label>
                            <span>{{bd.subjectsMale}}</span>
                            <label style="margin-left: 5px">F:</label>
                            <span>{{bd.subjectsFemale}}</span>
                            <label style="margin-left: 5px">Uns:</label>
                            <span>{{bd.subjectsUnspecifiedSex}}</span>
                        </div>
                        <div class="col-md-6">
                            <span *ngIf="bd.studyLength != 0 || bd.typeTimeUnitId.toLowerCase() != 'none'" class="inline"><strong>Study Length:</strong></span>
                            <span *ngIf="bd.studyLength != 0" class="inline">{{bd.studyLength}}</span>
                            <span *ngIf="bd.typeTimeUnitId.toLowerCase() != 'none'" class="inline">{{bd.typeTimeUnitId}}</span>
                            <label style="margin-left: 5px">GLP:</label>
                            <input type="checkbox" disabled [checked]="bd.gLP">
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12 shift-left-md">
                            <label class="ref-label">Comment:</label>
                            <span>{{bd.comment}}</span>
                        </div>
                    </div>

                    <div class="row indent-top-sm" *ngIf="isStaff()">
                        <div class="col-md-12 shift-left-md">
                            <label class="ref-label">Fragrance Safety Assessment Summary:</label>
                            <span>{{bd.safetyAssessmentSummary}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="isVisible('expMaterial', ix)" class="panel panel-default small" style="margin-left: 30px; margin-right: 30px;">
            <div *ngFor="let expMaterial of bd.experimentalMaterials; let exIx=index">
                <div class="panel-body">
                    <div class="row">
                        <div class="col-md-4">
                            <button style="margin-left: 0px; margin-right: 5px" type="button" (click)="showHide('expResults', ix, exIx)" class="btn btn-default btn-xs">
                                <span *ngIf="isVisible('expResults', ix, exIx)" class="fa fa-minus"></span>
                                <span *ngIf="!isVisible('expResults', ix, exIx)" class="fa fa-plus"></span>
                            </button>
                            <label>Material name:</label>
                            <a [htmlGreek]="expMaterial?.material.principalSynonym()?.synonymWordOrWebVersion()" (click)="goToSynonyms(expMaterial)"></a>
                        </div>
                        <div class="col-md-2">
                            <label>CAS No.:</label>
                            <span> {{ expMaterial?.material.realCASNumber || '(No CAS#)' }}</span>
                            <span *ngIf="expMaterial?.material.isCaptive()" class="captive-text"> Captive</span>
                        </div>
                        <div class="col-md-2">
                            <label>RIFM ID:</label>
                            <span>{{expMaterial?.material.monographNumber}}</span>
                        </div>
                        <div class="col-md-2">
                            <label>FEMA No.:</label>
                            <span>{{expMaterial?.material.fEMANumber}}</span>
                        </div>
                        <div class="col-md-1">
                            <a (click)="showHide('expMaterialDetails', ix,exIx)">{{getShowHideText('expMaterialDetails', ix, exIx)}}</a>
                        </div>
                        <div class="col-md-1">
                            <button type="button" (click)="doReport(bd, expMaterial)" class="btn btn-no-margin btn-primary btn-xs pull-right">Full Material Report</button>
                        </div>
                    </div>
                    <div *ngIf="isVisible('expMaterialDetails', ix, exIx)">
                        <div>
                            <div class="row ref-row">
                                <div *ngIf="expMaterial != null && expMaterial.showExperimentalMaterialVehicle()" class="col-md-6 shift-left-md">
                                    <label class="ref-label">Vehicles:</label>
                                    <span>{{expMaterial?.typeExperimentVehicle.vehicle || '-'}}</span>
                                </div>
                                <div class="col-md-6">
                                    <label class="ref-label">Details:</label>
                                    <span>{{expMaterial?.experimentalDetails || '-'}}</span>
                                </div>
                            </div>
                            <div class="row ref-row">
                                <div class="col-md-6 shift-left-md">
                                    <label class="ref-label">Sample:</label>
                                    <span>{{expMaterial?.sampleText || '-'}}</span>
                                </div>
                            </div>
                            <div class="row ref-row">
                                <div class="col-md-6 shift-left-md">
                                    <label class="ref-label">Result Name:</label>
                                    <span>{{expMaterial?.typeToxicEffectId || '-'}}</span>
                                </div>
                                <div class="col-md-6">
                                    <label class="ref-label">Calculated Result:</label>
                                    <span>{{expMaterial?.calculatedResultTypeAndValue}}</span>
                                </div>
                            </div>
                            <div class="row ref-row" *ngIf="isStaff()">
                                <div class="col-md-6 shift-left-md">
                                    <label class="ref-label">Contains Mixture:</label>
                                    <span>{{expMaterial?.typeStudyMixture.description}}</span>
                                </div>
                            </div>
                            <div class="row ref-row">
                                <div class="col-md-6 shift-left-md">
                                    <label class="ref-label">Summary:</label>
                                    <span>{{expMaterial?.experimentalSummary}}</span>
                                </div>
                                <div class="col-md-6">
                                    <label class="ref-label">FFIDS Statement:</label>
                                    <span>{{expMaterial?.fFDSStatement || '-'}}</span>
                                </div>
                            </div>
                            <div class="row">

                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="isVisible('expResults', ix, exIx)">
                    <div *ngFor="let er of expMaterial.experimentalResults" style="margin-left: 50px;">
                        <div class="row">
                            <div class="col-md-11">
                                <label class="col-md-1">Dose/Unit:</label>
                                <span class="col-md-2">{{er.formattedDose()}}</span>
                                <label class="col-md-1">Effects:</label>
                                <span class="col-md-6">{{er.toxicEffects()}}</span>
                            </div>
                        </div>
                        <div class="row" *ngIf="isNotZero(er.dPMperLymphNode)">
                            <div class="col-md-11">
                                <label class="col-md-1">DPM/Node:</label>
                                <div class="col-md-11">{{er.dPMperLymphNode}}</div>
                            </div>
                        </div>
                        <div class="row" *ngIf="isNotZero(er.stimulationIndex)">
                            <div class="col-md-11">
                                <label class="col-md-1">Stimulation Index:</label>
                                <div class="col-md-11">{{er.stimulationIndex}}</div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-11">
                                <label class="col-md-1">Results:</label>
                                <div class="col-md-11">{{er.resultNote || '-'}}</div>
                            </div>
                        </div>
                        <div class="row">
                            <div *ngIf="er.typeExperimentVehicleId != 'NONE'" class="col-md-11">
                                <label class="col-md-1">Vehicle:</label>
                                <div class="col-md-11">{{er.typeExperimentVehicle.vehicle}}</div>
                            </div>
                        </div>
                        <div>
                            <p></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<report *ngIf="_printBd" [bd]="_printBd" [selectedExpMaterial]="_printExpMaterial"> </report>

import { Component, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';

import { EditorService, StateMap, UnitOfWork } from '../../services/common';
import { EditManager, IEditHost } from '../../utils/common';

import { MATERIAL_ROUTES } from './material.routes';
import { SynonymComponent } from './synonym.component';
import { Material, Synonym } from '../../entities/EntityModels';

@Component({
    selector: 'synonyms',
    templateUrl: './synonyms.html',
})
export class SynonymsComponent implements IEditHost<Synonym>, OnInit {
    @ViewChild(SynonymComponent) synonymComponent: SynonymComponent;

    _principalNameWarning: string;
    _material: Material;
    _edm: EditManager<Synonym>;

    constructor(public _uow: UnitOfWork, public _stateMap: StateMap, public _location: Location, public _editorService: EditorService) {
    }

    ngOnInit() {
        this._stateMap.currentRouteName = MATERIAL_ROUTES.Synonyms.name;
        this._material = this._stateMap.currentMaterial;

        this._edm = this._editorService.createEditManager<Synonym>(this, 'Synonyms');
        this._edm.entities = this._material.synonyms.slice(0);
        this._edm.entities = this._edm.entities.sort((a, b) => {
            return a.synonymWordOrWebVersion().localeCompare(b.synonymWordOrWebVersion());
        });

    }

    canDeactivate() {
        return !this._edm.hasEdits();
    }

    onAdd() {
        if (this._edm.editing && this.synonymComponent) {
            this.synonymComponent.apply();
            if (!this._edm.validateCurrent()) {
                return;
            }
        }

        const sy = this._uow.synonymFactory.create();
        sy.materialId = this._material.materialId;
        sy.synonymWord = '';
        this._edm.entities.push(sy);
        this._edm.onSelect(sy);
    }

    onDelete() {
        this._edm.currentEntity.synonymNamers.slice(0).forEach(sn => {
            if (sn.entityAspect.entityState.isAdded()) {
                sn.entityAspect.rejectChanges();
            } else {
                sn.entityAspect.setDeleted();
            }
        });
        this._edm.onDeleteCore();
    }

    onApply() {
        this.synonymComponent.apply();
        if (!this._edm.onApplyCore()) {
            return;
        }

        return true;
    }

    onSave() {
        if (!this._edm.validateBeforeSave()) {
            return;
        }
        const hasEntities = this._edm.entities.length > 0;
        if (!hasEntities) {
            this._stateMap.yesNoComponent.showModal('Cannot save',
                'You must have at least one synonym per material.', ['Ok']);
            return;
        }
        this._principalNameWarning = '';
        const principalNameCount = this._edm.entities.filter(s => s.principal).length;
        if (principalNameCount == 0) {
            this._principalNameWarning = 'At least 1 synonym should be designated as the Principal Name';
            return;
        } else if (principalNameCount > 1) {
            this._principalNameWarning = 'Only 1 synonym can be designated as the Principal Name';
            return;
        }

        return this._edm.commitSave();
    }

    stopProp(event: Event) {
        event.stopPropagation();
    }
}

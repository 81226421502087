<p class="inline text-bold text-left page-title">List of Materials for the Exposure Survey: </p>

<div class="row" style="margin-top: 38px; width: 96%; margin-left: 14px;">
    <tab-container>
        <ng-template tab-pane [canDeactivate]="canDeactivate()" title="RIFM Material Measures">
            <div style="margin-bottom:12px; border: 2px solid #B0C4DE; padding-bottom: 40px;">
                <material-exposure-survey-measures #self></material-exposure-survey-measures>
            </div>
        </ng-template>
        <ng-template tab-pane [canDeactivate]="canDeactivate()" title="External Material Measures">
            <div style="margin-bottom:12px; border: 2px solid #B0C4DE; padding-bottom: 40px;">
                <exposure-additional-material-survey-measures #self></exposure-additional-material-survey-measures>
            </div>
        </ng-template>
        <ng-template tab-pane [canDeactivate]="canDeactivate()" title="Isomer Measures">
            <div style="margin-bottom:12px; border: 2px solid #B0C4DE; padding-bottom: 40px;">
                <material-exposure-survey-isomer-measures #self></material-exposure-survey-isomer-measures>
            </div>
        </ng-template>
    </tab-container>
</div>

import { EntityBase } from './EntityBase';

import { Validator } from 'breeze-client';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

export class TypeNarrativeSummary extends EntityBase {

   /// <code> Place custom code between <code> tags
   
   /// </code>

   // Generated code. Do not place code below this line.
   typeNarrativeSummaryId: string;
   narrativeTitle: string;
   narrativeLink: string;
   sponsor: string;
   rowVersion: number;
}



<div *ngIf="_data.adding || _data.edm.editing" class="form-horizontal material-editor-base-container">

    <div style="margin-top: 12px;">
        <div class="material-editor-left">
            <div class="material-edit-box">
                <edit-prop isChild label="CAS No." prop="realCASNumber" szs="135,220">
                    <input type="text" class="form-control" (change)="_CASChanged=true" (blur)="onChangeCAS($event.target.value)" [(ngModel)]="material.realCASNumber">
                </edit-prop>
                <div class="material-edit-box" style="margin-top:2px; margin-bottom: 2px;" *ngIf="_CASCollisionWarning">
                    <span class="alert-warning bold">{{_CASCollisionWarning}}</span>
                </div>
                <div class="col-md-3">
                    <input type="text" class="form-control" disabled [ngModel]="material.materialId">
                </div>
            </div>
            <div class="material-edit-box margin-fix-additional-identifiers">
                <edit-prop isChild label="RIFM ID" prop="monographNumber" szs="135,220">
                    <input class="form-control" type="number" (input)="_monographMaterials=null" [(ngModel)]="material.monographNumber">
                </edit-prop>
                <div>
                    <button type="button" class="btn btn-sm btn-success btn-no-margin" (click)="onShowMongraphMaterials()">
                        {{ hasMonographMatchResponse() ? 'Hide Matches' : 'Show Matches'}}
                    </button>
                    <div class="inline" style="margin-left: 150px; margin-top: 8px;">
                        <table class="table table-striped table-bordered table-condensed small" style="width:auto;"
                               *ngIf="_monographMaterials && (_monographMaterials.length > 0)">
                            <thead>
                            <tr>
                                <td>MaterialId</td>
                                <td>CAS No.</td>
                                <td>Captive</td>
                                <td>RIFM ID</td>
                                <td>FEMA No.</td>
                            </tr>
                            </thead>
                            <tbody>
                            <tr *ngFor="let m of _monographMaterials">
                                <td>{{m.materialId}}</td>
                                <td>{{m.realCASNumber}}</td>
                                <td><span class="captive-text">{{ getCaptiveText(m.confidential) }}</span></td>
                                <td>{{m.formattedRifmId}}</td>
                                <td>{{m.fEMANumber}}</td>
                            </tr>
                            </tbody>
                        </table>
                        <div class="inline" style="margin-left: 206px;">
                            <span>{{_noMonographNumberMatchesMsg}}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="material-edit-box margin-fix-additional-identifiers">
                <edit-prop isChild label="FEMA Number" prop="fEMANumber" szs="135,220">
                    <input class="form-control" type="number" (input)="_fEMAMaterials=null" [(ngModel)]="material.fEMANumber">
                </edit-prop>
                <div class="inline">
                    <button type="button" class="btn btn-sm btn-success btn-no-margin" (click)="onShowFEMAMaterials()">
                        {{ hasFEMAMatchResponse() ? 'Hide Matches' : 'Show Matches'}}
                    </button>
                </div>
                <div class="inline" style="margin-left: 150px; margin-top: 8px;">
                    <table class="table table-striped table-bordered table-condensed small" style="width:auto;"
                           *ngIf="_fEMAMaterials && (_fEMAMaterials.length > 0)">
                        <thead>
                        <tr>
                            <td>MaterialId</td>
                            <td>CAS No.</td>
                            <td>Captive</td>
                            <td>FEMA No.</td>
                            <td>RIFM ID</td>
                        </tr>
                        </thead>
                        <tbody>
                        <tr *ngFor="let fm of _fEMAMaterials">
                            <td>{{fm.materialId}}</td>
                            <td>{{fm.realCASNumber}}</td>
                            <td><span class="captive-text">{{ getCaptiveText(fm.confidential) }}</span></td>
                            <td>{{fm.fEMANumber}}</td>
                            <td>{{fm.formattedRifmId}}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div class="inline" style="margin-top: 8px;">
                    <span>{{_noFEMANumberMatchesMsg}}</span>
                </div>
            </div>
        </div>

        <div class="material-editor-right">
            <div class="material-edit-box">
                <edit-prop isChild label="Num. CAS No." prop='N/A' szs="160,220">
                    <input type="text" class="form-control" disabled [ngModel]="material.numericalCASNumber">
                </edit-prop>
            </div>

            <div class="material-edit-box">
                <edit-prop isChild label="Natural Occurrence" prop="typeNaturalOccurrenceId" szs="160,360">
                    <select class="form-control" [ngModel]="material.typeNaturalOccurrenceId" (change)="material.typeNaturalOccurrenceId=$event.target.value">
                        <option disabled selected value>-- None --</option>
                        <option *ngFor="let tno of _typeNaturalOccurrences" [value]="tno.typeNaturalOccurrenceId">{{tno.occurrenceCode + ' - ' + tno.occurrenceDetail}}</option>
                    </select>
                </edit-prop>
            </div>

            <div class="material-edit-box">
                <edit-prop isChild label="Generic Class" prop="typeTSCAClassId" szs="160,360">
                    <select class="form-control" [ngModel]="material.typeTSCAClassId" (change)="material.typeTSCAClassId=checkIfNull($event.target.value)">
                        <option selected value>-- None --</option>
                        <option *ngFor="let ttc of _typeTSCAClasses" [value]="ttc.typeTSCAClassId">{{ttc.genericClass}}</option>
                    </select>
                </edit-prop>
            </div>

        </div>
    </div>

    <div class="clear material-edit-box principal-name-add-container">
        <edit-prop *ngIf="_data.adding" label="Principal Name" prop="_synonymName" szs="135,320">
            <input class="form-control required" type="text" (change)="_synonymChanged=true" (blur)="onSynonymChange()" [(ngModel)]="_synonymName" />
        </edit-prop>
        <div class="material-edit-box" *ngIf="_synonymCollisionWarning">
            <span class="alert-warning bold">{{_synonymCollisionWarning}}</span>
        </div>
    </div>

    <div class="clear" style="top: 4px;">
        <div class="material-editor-left">
            <div class="material-editor-highlight" style="margin-top: 2px;">
                <div>
                    <label class="col-md-180 text-right">If this is a captive material</label>
                </div>
                <div class="material-edit-box">
                    <edit-prop isChild label="Captive" prop="confidential" szs="135,80">
                        <input class="form-control" type="checkbox" (change)="checkConfidential($event.target.checked)" [(ngModel)]="material.confidential">
                    </edit-prop>
                </div>
                <div class="material-edit-box">
                    <edit-prop isChild label="Captive Company" prop="captiveCompany" szs="135,220">
                        <input class="form-control" [disabled]="!material.confidential" type="text" [(ngModel)]="material.captiveCompany">
                    </edit-prop>
                </div>
            </div>
        </div>

        <div class="material-editor-right">
            <div class="material-editor-highlight essential-oil-container">
                <div>
                    <label class="col-md-180 essential-oil-label">If this is an essential oil</label>
                </div>
                <div class="material-edit-box">
                    <edit-prop isChild label="Botanical part" prop="typeBotanicalSubdivisionId" szs="160,360">
                        <select class="form-control" [ngModel]="material.typeBotanicalSubdivisionId" (change)="material.typeBotanicalSubdivisionId=checkIfNull($event.target.value)">
                            <option selected value>-- None --</option>
                            <option *ngFor="let tbs of _typeBotanicalSubdivisions" [value]="tbs.typeBotanicalSubdivisionId">{{tbs.typeBotanicalSubdivisionId + ' - ' + tbs.botanicalSource}}</option>
                        </select>
                    </edit-prop>
                </div>
                <div class="material-edit-box">
                    <edit-prop isChild label="Extract type" prop="typeEssentialOilId" szs="160,360">
                        <select class="form-control" [ngModel]="material.typeEssentialOilId" (change)="material.typeEssentialOilId=checkIfNull($event.target.value)">
                            <option selected value>-- None --</option>
                            <option *ngFor="let teo of _typeEssentialOils" [value]="teo.typeEssentialOilId">{{teo.typeEssentialOilId + ' - ' + teo.essentialOilTerm}}</option>
                        </select>
                    </edit-prop>
                </div>
            </div>
        </div>

    </div>

    <div class="clear" style="top: 18px;">
        <div class="material-editor-left">
            <div class="material-input-margin">
                <edit-prop isChild label="Alt. CAS No." prop="alternateCASNumber" szs="135,280">
                    <input type="text" class="form-control" [(ngModel)]="material.alternateCASNumber">
                </edit-prop>
            </div>

            <div class="material-input-margin">
                <edit-prop isChild label="ECB" prop="eCB" szs="135,280">
                    <input type="text" class="form-control" [(ngModel)]="material.eCB">
                </edit-prop>
            </div>

            <div class="material-edit-box">
                <edit-prop isChild label="METI" prop="mETI" szs="135,280">
                    <input type="text" class="form-control" [(ngModel)]="material.mETI">
                </edit-prop>
            </div>
        </div>

        <div class="material-editor-right">

            <div class="material-edit-box">
                <edit-prop isChild label="ECL" prop="eCL" szs="160,280">
                    <input type="text" class="form-control" [(ngModel)]="material.eCL">
                </edit-prop>
            </div>

            <div class="material-input-margin">
                <edit-prop isChild label="EC Inventory" prop="eCInventoryNumber" szs="160,380">
                    <input type="text" class="form-control" [(ngModel)]="material.eCInventoryNumber">
                </edit-prop>
            </div>

            <div class="material-input-margin">
                <edit-prop isChild label="EINECS No." prop="eINECSNumber" szs="160,380">
                    <input type="text" class="form-control" [(ngModel)]="material.eINECSNumber">
                </edit-prop>
            </div>

            <div class="material-input-margin">
                <edit-prop isChild label="ECN No." prop="eCNNumber" szs="160,380">
                    <input type="text" class="form-control" [(ngModel)]="material.eCNNumber">
                </edit-prop>
            </div>

        </div>
    </div>

    <label class="material-edit-box registration-label-edit">Registration</label>

    <div class="clear">
        <edit-prop isChild label="EINECS" prop="eINECSRegistration" szs="1,1">
            <input class="form-control" type="checkbox" [(ngModel)]="material.eINECSRegistration">
        </edit-prop>
        <edit-prop isChild label="ECN" prop="eCNRegistration" szs="1,1">
            <input class="form-control" type="checkbox" [(ngModel)]="material.eCNRegistration">
        </edit-prop>
        <edit-prop isChild label="DSL" prop="dSLRegistration" szs="1,1">
            <input class="form-control" type="checkbox" [(ngModel)]="material.dSLRegistration">
        </edit-prop>
        <edit-prop isChild label="NDSL" prop="nDSLRegistration" szs="1,1">
            <input class="form-control" type="checkbox" [(ngModel)]="material.nDSLRegistration">
        </edit-prop>
        <edit-prop isChild label="REACH" prop="reachRegistration" szs="1,1">
            <input class="form-control" type="checkbox" [(ngModel)]="material.reachRegistration">
        </edit-prop>
        <edit-prop isChild label="TSCA" prop="tSCARegistration" szs="1,1">
            <input class="form-control" type="checkbox" [(ngModel)]="material.tSCARegistration">
        </edit-prop>
        <br />
    </div>

    <div *ngIf="!_data.adding && _data.edm.editing" class="material-edit-box clear" style="top: 18px;">
        <div>
            <label class="text-right alternative-identifier-label">Alternative Identifiers</label>
            <button id="add-alternate-identifier-button" *ngIf="!editingAdditionalIdentifiers" type="button" class="btn btn-sm btn-primary" (click)="onAddMaterialAdditionalIdentifiers()">Add Identifier</button>
        </div>
        <div *ngIf="_edmIds.entities && _edmIds.entities.length > 0" class="material-edit-box">
            <div class="alternate-identifiers-table-container">
                <default-table *ngIf="_edmIds.entities && _edmIds.entities.length > 0">
                    <thead>
                        <tr>
                            <td *ngIf="!editingAdditionalIdentifiers"></td>
                            <td *ngIf="!editingAdditionalIdentifiers"></td>
                            <td>Type</td>
                            <td>CAS No.</td>
                            <td>EC Inventory</td>
                            <td>ECL</td>
                            <td>EINECS</td>
                            <td>ECN</td>
                            <td>Registration</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let matid of _edmIds.entities">
                            <td *ngIf="!editingAdditionalIdentifiers"><a (click)="onDeleteAdditionalIdentifier(matid)">Delete</a></td>
                            <td *ngIf="!editingAdditionalIdentifiers"><a (click)="onEditAdditionalIdentifier(matid)">Edit</a></td>
                            <td><label class="additional-identifiers-text">{{matid.typeMaterialIdentifier?.description}} </label></td>
                            <td><label class="additional-identifiers-label">{{matid.cASNumber}}</label>
                            <td><label class="additional-identifiers-label">{{matid.eCInventoryNumber}}</label>
                            <td><label class="additional-identifiers-label">{{matid.eCL}}</label>
                            <td><label class="additional-identifiers-label">{{matid.eINECSNumber}}</label>
                            <td><label class="additional-identifiers-label">{{matid.eCNNumber}}</label>
                            <td>
                                <label class="additional-identifiers-text">Registration:</label>
                                <span class="additional-identifiers-label" *ngIf="matid.dSLRegistration">DSL</span>
                                <span class="additional-identifiers-label" *ngIf="matid.eCNRegistration">ECN</span>
                                <span class="additional-identifiers-label" *ngIf="matid.eINECSRegistration">EINECS</span>
                                <span class="additional-identifiers-label" *ngIf="matid.nDSLRegistration">NDSL</span>
                                <span class="additional-identifiers-label" *ngIf="matid.reachRegistration">REACH</span>
                                <span class="additional-identifiers-label" *ngIf="matid.tSCARegistration">TSCA</span>
                            </td>
                        </tr>
                    </tbody>

                </default-table>
            </div>
        </div>
    </div>

    <div class="material-editor-highlight" style="margin-bottom: 20px;">
        <div *ngIf="editingAdditionalIdentifiers" class="edit-additional-identifiers-container">
            <div class="alert-danger" *ngIf="missingAllAdditionalIdentifierFields">
                <span class="bold">At least on identifier must be entered.</span>
            </div>
            <div class="material-edit-box" style="margin-left: 8px; margin-top: 2px;">
                <label class="change-mode-description">{{changeModeDescription}}</label>
            </div>
            <div class="material-edit-box">
                <div class="material-editor-left">
                    <edit-prop label="Alt. CAS No." prop="cASNumber" szs="170,280">
                        <input class="form-control" type="text" [(ngModel)]="editMaterialAdditionalIdentifer.cASNumber">
                    </edit-prop>
                    <edit-prop label="ECL No." prop="eCL" szs="170,280">
                        <input class="form-control" type="text" [(ngModel)]="editMaterialAdditionalIdentifer.eCL">
                    </edit-prop>
                </div>
                <div class="material-editor-right">
                    <edit-prop label="EC Inventory" prop="eCL" szs="170,380">
                        <input class="form-control" type="text" [(ngModel)]="editMaterialAdditionalIdentifer.eCInventoryNumber">
                    </edit-prop>
                    <edit-prop label="EINECS No." prop="eINECSNumber" szs="170,380">
                        <input class="form-control" type="text" [(ngModel)]="editMaterialAdditionalIdentifer.eINECSNumber">
                    </edit-prop>
                    <edit-prop label="ECN No." prop="eCNNumber" szs="170,380">
                        <input class="form-control" type="text" [(ngModel)]="editMaterialAdditionalIdentifer.eCNNumber">
                    </edit-prop>
                </div>
            </div>
            <div class="material-edit-box clear" style="margin-bottom: 8px;">
                <label class="registration-label-add">Registration</label>
                <div class="material-edit-box">
                    <edit-prop isChild label="DSL" prop="dSLRegistration" szs="1,1">
                        <input class="form-control registration-boxes" type="checkbox" [(ngModel)]="editMaterialAdditionalIdentifer.dSLRegistration">
                    </edit-prop>
                    <edit-prop isChild label="ECN" prop="eCNRegistration" szs="1,1">
                        <input class="form-control registration-boxes" type="checkbox" [(ngModel)]="editMaterialAdditionalIdentifer.eCNRegistration">
                    </edit-prop>
                    <edit-prop isChild label="EINECS" prop="eINECSRegistration" szs="1,1">
                        <input class="form-control registration-boxes" type="checkbox" [(ngModel)]="editMaterialAdditionalIdentifer.eINECSRegistration">
                    </edit-prop>
                    <edit-prop isChild label="NDSL" prop="nDSLRegistration" szs="1,1">
                        <input class="form-control registration-boxes" type="checkbox" [(ngModel)]="editMaterialAdditionalIdentifer.nDSLRegistration">
                    </edit-prop>
                    <edit-prop isChild label="REACH" prop="reachRegistration" szs="1,1">
                        <input class="form-control registration-boxes" type="checkbox" [(ngModel)]="editMaterialAdditionalIdentifer.reachRegistration">
                    </edit-prop>
                    <edit-prop isChild label="TSCA" prop="tSCARegistration" szs="1,1">
                        <input class="form-control registration-boxes" type="checkbox" [(ngModel)]="editMaterialAdditionalIdentifer.tSCARegistration">
                    </edit-prop>
                </div>
            </div>

            <div class="material-edit-box material-edit-buttons">
                <button class="btn btn-primary btn-sm confirm-hold-button" type="button" (click)="confirmMaterialAdditionalIdChanges()">Apply Id Changes</button>
                <button class="btn btn-primary btn-sm btn-warning cancel-hold-button" type="button" (click)="cancelMaterialAdditionalIdChanges()">Cancel Changes</button>
            </div>

        </div>
    </div>
    <br />
</div>

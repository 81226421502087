<div class="indent-left-md">
    <div class="home-page-title">Report of Database Changes</div>

    <div class="row btn-bar">
        <button *ngIf="_userChangeReport" type="button" class="btn btn-sm btn-primary" [disabled]="!canSave()" (click)="onSave()">Save</button>
        <button *ngIf="_userChangeReport" type="button" class="btn btn-sm btn-primary" [disabled]="!canCancel()" (click)="onCancel()">Cancel</button>
        <span *ngIf="hasEdits()" class="label label-warning">
            You cannot leave this page you either 'Save' or 'Cancel'
        </span>
        <span *ngIf="_status" [ngClass]="_status.classes">{{_status.message}}</span>
    </div>

    <div *ngIf="dataReady() && !_userChangeReport" class="row">
        <div class="col-md-4">
            You are not setup to receive email reports of RIFM database changes yet. Please click the Create Settings button to specify
            your report preferences.
        </div>
        <div class="col-md-4">
            <button type="button" class="btn btn-primary" (click)="createSettings()">Create Settings</button>
        </div>
    </div>

    <div *ngIf="dataReady() && _userChangeReport" style="font-size: 18px; margin-left: 10px">

        <div class="row" style="margin-bottom: 15px">
            <div class="col-md-3 ">
                <div>
                    <label>Report Frequency: </label>
                    <select [ngModel]="_userChangeReport.typeChangeReportFrequencyId" (change)="_userChangeReport.typeChangeReportFrequencyId = $event.target.value">
                        <option *ngFor="let t of _typeChangeReportFrequencies" [value]="t.typeChangeReportFrequencyId">{{t.typeChangeReportFrequencyId}}</option>
                    </select>
                </div>
            </div>
        </div>

        <div class="row" style="margin-bottom: 15px">
            <div class="panel panel-default col-md-9" style="margin-right: 30px">
                <div class="panel-body">

                    <div>
                        <label>Selected Report Topics</label>
                    </div>
                    <div class="flex-container-change-report background-transparent indent-left-sm">
                        <div *ngFor="let topic of topicsWithoutStudies; let ix = index">
                            <input type="checkbox" (change)="onTopicChanged()" [(ngModel)]="_topicMap[topic.typeChangeReportTopicId]">
                            <span class="flex-item-change-report" style="width: 180px;">{{topic.changeReportTopicDesc}}</span>
                        </div>
                    </div>

                    <div class="flex-container-change-report background-transparent indent-left-sm">
                        <div *ngFor="let topic of topicsOnlyStudies; let ix = index">
                            <input type="checkbox" (change)="onTopicChanged()" [(ngModel)]="_topicMap[topic.typeChangeReportTopicId]">
                            <span class="flex-item-change-report" style="width: 180px; margin-top: 4px;">{{topic.changeReportTopicDesc}}</span>
                        </div>
                        <div *ngIf="hasSelectedStudyTopic()" id="study-filter-container" class="flex-item-change-report">
                            <button type="button" (click)="setStudyFilterMode('All')"
                                    [ngClass]="{'btn-select-active': isActiveStudyFilter('All'),  'btn-select': isNotActiveStudyFilter('All')}">
                                All Study Types
                            </button>

                            <button type="button" (click)="setStudyFilterMode('Specific')"
                                    [ngClass]="{'btn-select-active': isActiveStudyFilter('Specific'),  'btn-select': isNotActiveStudyFilter('Specific')}">
                                Selected Study Types
                            </button>
                        </div>
                        <select *ngIf="showStudyTypeFilter" [(ngModel)]="filterStudyType" (change)="filterStudyType = $event.target.value"
                                class="flex-item-change-report dropdown-toggle study-filter-select">
                            <option *ngFor="let t of _typeStudies" [value]="t.typeStudyId"
                                    [ngClass]="{'selected-font': isStudyTypeSelected(t),  'unselected-font': isStudyTypeUnselected(t)}">
                                {{t.studyLong}}
                            </option>
                        </select>
                    </div>

                    <div *ngIf="showStudyTypeFilter" class="flex-container-change-report background-transparent" style="margin-left: 14px;">
                        <span *ngFor="let s of _selectedStudyTypes; let ix = index" class="change-report-selected">
                            <label>
                                {{s.studyLong}}
                                <a (click)="onRemoveStudyType(s.typeStudyId)">(Remove)</a>
                            </label>
                        </span>
                    </div>

                </div>
            </div>
        </div>

        <div class="row">

            <div class="panel panel-default col-md-4" style="margin-right: 30px">
                <div class="panel-body">
                    <div>
                        <label>Selected Materials for this Report</label>
                    </div>
                    <div class="indent-left-sm">
                        <div>
                            <input type="checkbox" [(ngModel)]="_userChangeReport.allFlavors">
                            <span>All Flavors</span>
                        </div>
                        <div>
                            <input type="checkbox" [(ngModel)]="_userChangeReport.allFragrances">
                            <span>All Fragrances</span>
                        </div>
                        <div>
                            <input type="checkbox" [(ngModel)]="_userChangeReport.allNaturals ">
                            <span>All Naturals</span>
                        </div>
                        <div>
                            <span style="font-style: italic">Specific Materials of Interest</span>
                            <button type="button" class="btn btn-primary" (click)="onSelectingMaterial()">Choose Material</button>
                            <div *ngIf="_materialListItems.length == 0" class="small indent-left-sm">
                                (None selected)
                            </div>
                            <default-table *ngIf="_materialListItems.length">
                                <div header style="margin-top: 10px;"></div>
                                <thead>
                                    <tr>
                                        <td></td>
                                        <td>CAS No.</td>
                                        <td>RIFM ID</td>
                                        <td>Principal Synonym</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let m of _materialListItems">
                                        <td><a (click)="removeMaterial(m)">Remove</a></td>
                                        <td>{{m.realCASNumber}}</td>
                                        <td>{{m.formattedRifmId}}</td>
                                        <td [htmlGreek]="m.synonymWordOrWebVersion"></td>
                                    </tr>
                                </tbody>
                            </default-table>
                        </div>
                    </div>
                </div>
            </div>

            <div class="panel panel-default col-md-4" style="margin-right: 30px">
                <div class="panel-body">
                    <div>
                        <label>Additional Report Options</label>
                    </div>
                    <div class="indent-left-sm">
                        <div>
                            <span>New Publications</span>
                        </div>
                        <div>
                            <span style="font-style: italic">Specific Authors of Interest</span>
                            <button type="button" class="btn btn-primary" (click)="onSelectingAuthor()">Choose Author</button>
                        </div>
                        <div *ngIf="_authors.length == 0" class="small indent-left-sm">
                            (None selected)
                        </div>
                        <default-table *ngIf="_authors.length">
                            <div header style="margin-top: 10px;"></div>
                            <thead>
                                <tr>
                                    <td></td>
                                    <td>Name</td>
                                    <td>Initials</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let a of _authors">
                                    <td><a (click)="removeAuthor(a)">Remove</a></td>
                                    <td>{{a.authorName}}</td>
                                    <td>{{a.initials}}</td>
                                </tr>
                            </tbody>
                        </default-table>
                    </div>
                </div>
            </div>

        </div>

    </div>

</div>

<material-selector></material-selector>
<author-selector></author-selector>

<div class="modal" id="material-selector-dialog" role="dialog">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                <div class="nav navbar-left navbar-form">
                    <label class="bold input-lg">Material Search</label>
                    <input class="form-control" type="text" [(ngModel)]="_searchTerm" autofocus (keyup.enter)="onSearch()">
                    <button class="btn btn-primary navbar-btn" type="button" (click)="onSearch()" [disabled]="!canSearch()">Search</button>
                    <button class="btn btn-primary navbar-btn" type="button" (click)="selectMaterial(null)">Cancel</button>
                </div>

            </div>
            <div class="modal-body">
                <div class="" *ngIf="_entities && _entities.length">
                    <div>Now select a row from below or click 'Cancel'</div>
                </div>
                <div class="" *ngIf="validationMessage">
                    <p class="alert-danger">{{validationMessage}}</p>
                </div>
                <material-results></material-results>
            </div>
            <div class="modal-footer">
                <button (click)="selectMaterial(null)" type="button" class="btn btn-primary">Cancel</button>
            </div>
        </div>
    </div>
</div>
import { Location } from '@angular/common';
import { Component, Input, Inject, forwardRef, OnInit } from '@angular/core';

import { Router, ActivatedRoute } from '@angular/router';
import * as _ from 'lodash';
import { UnitOfWork, StateMap, UserManager, ErrorLogger } from '../../services/common';
import { UtilFns, IEditHost, EditManager } from '../../utils/common';

import { ExposureSurvey, MaterialExposureSurvey, MaterialExposureSurveyMeasure, MaterialExposureSurveyIsomer, Material } from '../../entities/EntityModels';
import { SelectableEntity } from '../../entities/projections/SelectableEntity';
import { ExposureSurveyListsComponent, ExposureEditData } from './exposure-survey-lists.component';
import { ROUTES } from '../routes';
import {EntityBase} from '../../entities/EntityBase';

@Component({
    selector: 'material-exposure-surveys',
    templateUrl: './material-exposure-surveys.html',
})

export class MaterialExposureSurveysComponent implements IEditHost<MaterialExposureSurvey>, OnInit {

    @Input() exposureSurvey: ExposureSurvey;

    public _isLoading = false;

    public _data: ExposureEditData;
    public _edm: EditManager<MaterialExposureSurvey>;
    public _edmMeasures: EditManager<MaterialExposureSurveyMeasure>;
    public _adding: boolean;

    public _selectedMaterialExposureSurvey: MaterialExposureSurvey;
    public _materialExposureSurveys: MaterialExposureSurvey[] = [];
    public _materialExposureSurveysEx: SelectableEntity<MaterialExposureSurvey>[] = [];

    public _materialExposureSurveyIsomers: MaterialExposureSurveyIsomer[] = [];

    public colorSelected   = '#ddd';
    public colorUnselected = '#FFFFFF';

    public _canClearMaterialFilter = false;
    public _materialFilter = '';
    public _validationMessage: string;

    constructor(@Inject(forwardRef(() => ExposureSurveyListsComponent)) public _parent: ExposureSurveyListsComponent,
                private _uow: UnitOfWork, private _stateMap: StateMap, private _userManager: UserManager, private _router: Router,
                public _route: ActivatedRoute, public _location: Location, public _errorLogger: ErrorLogger) {

        this._data = _parent._data;
    }

    ngOnInit() {

        this._edm = new EditManager<MaterialExposureSurvey>(this, this._uow, this._stateMap, 'Exposure Survey Materials');
        this._edmMeasures = new EditManager<MaterialExposureSurveyMeasure>(this, this._uow, this._stateMap, 'Exposure Survey Material Measures');

        this.fetch();
    }

    fetch() {
        this._canClearMaterialFilter            = false;
        this._materialExposureSurveys           = [];
        this._edm.entities                      = [];
        this._selectedMaterialExposureSurvey    = null;

        this._isLoading = true;

        const params = { exposureSurveyId: this.exposureSurvey.exposureSurveyId };
        this._uow.fetch('ExposureSurveys/MaterialExposureSurveyByExposureSurveyId', params).then(e => {
            this._materialExposureSurveys = e;
            this._edm.entities = _.clone(this._materialExposureSurveys);
            this._uow.fetch('ExposureSurveys/MaterialExposureSurveyIsomerByExposureSurveyId', params).then(i => {
                this._materialExposureSurveyIsomers = i;
                this.formatSelectableEntities();
                this._isLoading = false;
            });
        });
    }

    formatSelectableEntities() {
        this._materialExposureSurveysEx = [];

        if (this._edm.entities == null || this._edm.entities.length < 1) {
            return;
        }

        _.clone(this._edm.entities).forEach(element => {
            element.isomers = this.getIsomersForMaterial(element.materialId);
            this._materialExposureSurveysEx.push(new SelectableEntity<MaterialExposureSurvey>(element, '#FFFFFF'));
        });
    }

    getIsomersForMaterial(materialId: number): string {
        if (this._materialExposureSurveyIsomers == null || this._materialExposureSurveyIsomers.length < 1) {
            return '';
        }

        const isomers = this._materialExposureSurveyIsomers.filter(i => i.materialExposureSurvey.materialId == materialId);

        if (isomers == null || isomers.length < 1) {
            return '';
        }

        return isomers.map(i => {
            return this.formatIsomerIdentifiers(i.material);
        }).join('; ');
    }

    formatIsomerIdentifiers(material: Material): string {
        const rifmId = (material.formattedRIFMId == '-') ? '' : ' (' + material.formattedRIFMId + ')';
        return (material.realCASNumber || '') + rifmId;
    }

    // filter to the entity for editing purposes
    filterToMaterialExposureSurveyById(materialExposureSurveyId: number): MaterialExposureSurvey {
        if (this._materialExposureSurveys == null || this._materialExposureSurveys.length < 1) {
            return null;
        }

        const expsurveymaterial = this._materialExposureSurveys.filter(e => e.materialExposureSurveyId == materialExposureSurveyId);
        if (expsurveymaterial != null && expsurveymaterial.length > 0) {
            return expsurveymaterial[0];
        }
    }

    hasData() {
        if (this._edm.entities == null || this._edm.entities.length < 1) {
            return false;
        }

        if (this._materialExposureSurveysEx == null || this._materialExposureSurveysEx.length < 1) {
            return false;
        }
        return true;
    }

    get isLoading() {
        return this._isLoading;
    }

    set isLoading(value) {
        this._isLoading = value;
    }

    isStaff() {
        return this._stateMap.currentUser.isStaff;
    }

    get utilFns() {
        return UtilFns;
    }

    canAddToMaterialList() {
        if (this._edm.editing) {
            return false;
        }

        // Make sure the Exposure Survey status allows materials to be added to the list
        if (this.exposureSurvey == null) {
            return false;
        }

        return (this.exposureSurvey.typeExposureSurveyStatusId == 'Open for Materials');
    }

    // edit manager overrides
    canApply() {
        if (this._edm.editing) {
            return (this._uow.hasChanges() && !this._edm.currentEntity.entityAspect.hasValidationErrors);
        }
        return false;
    }

    canCancel() {
        return this._edm.hasEdits();
    }

    canDeactivate() {
        return !this.hasEdits();
    }

    hasEdits() {
        return this._uow.hasChanges();
    }

    canSave() {
        if (this._edm.editing) {
            return false;
        }
        if (!this._edm.hasEdits()) {
            return false;
        }
        if (!this._edm.validateBeforeSave()) {
            return false;
        }
        return true;
    }

    canShowBack() {
        if (!this._edm.editing) {
            return false;
        }
        return !this._uow.hasChanges();
    }

    onAdd() {
        if (!this._edm.validateCurrent()) {
            return;
        }

        this._adding = true;
        const e = this._uow.materialExposureSurveyFactory.create();

        e.exposureSurveyId = this.exposureSurvey.exposureSurveyId;
        e.typeExposureSurveyResponseId = 2;

        this._edm.entities.push(e);
        this._edm.onSelect(e);

        this._data.inSubEditor = true;
    }

    onApply() {
        this._validationMessage = '';
        if (this._edm.editing) {

            if (this._edm.currentEntity.materialId == null || this._edm.currentEntity.materialId == 0) {
                this._validationMessage = 'Please select a material.';
                return;
            }

            if (this.isDuplicateMaterialEntry(this._edm.currentEntity.materialId)) {
                this._validationMessage = 'This material has already been added to the exposure survey list.';
                return;
            }

            this._edm.onApplyCore();
            this._adding = false;


            this.formatSelectableEntities();
        }

        this._data.inSubEditor = false;

        return true;
    }

    onBack() {
        this._adding = false;
        this._edm.editing = false;
        this._data.inSubEditor = false;
    }

    onCancel() {
        this._validationMessage = '';

        this._adding = false;
        this._edm.onCancelCore();
        this._edm.entities = null;

        this._edmMeasures.onCancelCore();
        this._edmMeasures.entities = null;

        this._data.inSubEditor = false;

        this.fetch();
    }

    onEdit(mesx: SelectableEntity<MaterialExposureSurvey>) {

        if (mesx == null) {
            return;
        }

        this._selectedMaterialExposureSurvey = this.filterToMaterialExposureSurveyById(mesx.data.materialExposureSurveyId);

        if (this._selectedMaterialExposureSurvey == null) {
            return;
        }

        this._edm.onSelect(this._selectedMaterialExposureSurvey);

        this._data.inSubEditor = true;
    }

    onRemove(mesx: SelectableEntity<MaterialExposureSurvey>) {

        if (mesx == null) {
            return;
        }

        this._selectedMaterialExposureSurvey = this.filterToMaterialExposureSurveyById(mesx.data.materialExposureSurveyId);

        if (this._selectedMaterialExposureSurvey == null) {
            this._validationMessage = 'Error occurred, could not remove the selected material from the list.';
            return;
        }

        if (this._selectedMaterialExposureSurvey.entityAspect.entityState.isAdded()) {
            this._selectedMaterialExposureSurvey.entityAspect.rejectChanges();
        } else {
            this._selectedMaterialExposureSurvey.entityAspect.setDeleted();
            this.removeMaterialSurveyMeasures();
        }

        _.remove(this._edm.entities, this._selectedMaterialExposureSurvey);

        this.formatSelectableEntities();
    }

    removeMaterialSurveyMeasures() {
        // Delete the measures associated with removed material exposure survey record.
        const params = {
            materialId: this._selectedMaterialExposureSurvey.materialId,
            exposureSurveyId: this._selectedMaterialExposureSurvey.exposureSurveyId
        };

        this._uow.fetchTyped('ExposureSurveys/FetchExposureSurveyMaterialMeasures', MaterialExposureSurveyMeasure, params).then(m => {
            this._edmMeasures.entities = m;

            if (this._edmMeasures.entities != null && this._edmMeasures.entities.length > 0) {
                this._edmMeasures.entities.forEach(r => r.entityAspect.setDeleted());
            }
        });
    }

    isDeleted(e: EntityBase) {
        if (e == null) {
            return true;
        }
        return e.entityAspect.entityState.isDeleted();
    }

    onSave() {

        if (this._edm.hasEdits()) {
            this._edm.onSaveCore().then(() => {

                this._adding = false;
                this._edm.editing = false;

                this._edmMeasures.editing = false;

                this.fetch();
            });
        }
    }

    onShowMaterialExposureSurveyNotes(mesx: SelectableEntity<MaterialExposureSurvey>) {
        if (mesx == null) { return; }

        this._selectedMaterialExposureSurvey = this.filterToMaterialExposureSurveyById(mesx.data.materialExposureSurveyId);

        if (this._selectedMaterialExposureSurvey) {
            this.resetBackgroundColor(this._materialExposureSurveysEx);
            mesx.backgroundColor = this.colorSelected;
        }
    }

    resetBackgroundColor(materialExposureSurveys: SelectableEntity<MaterialExposureSurvey>[]) {
        materialExposureSurveys.forEach((element, index) => {
            element.backgroundColor = this.colorUnselected;
        });
    }

    onShowMeasures(mesx: SelectableEntity<MaterialExposureSurvey>) {
        if (mesx == null) {
            return;
        }

        this._selectedMaterialExposureSurvey = this.filterToMaterialExposureSurveyById(mesx.data.materialExposureSurveyId);

        if (this._selectedMaterialExposureSurvey == null) {
            return;
        }

        this._router.navigate(['../record-exposure-survey-measures',
                {
                    materialId: this._selectedMaterialExposureSurvey.materialId,
                    exposuresurveyid: this.exposureSurvey.exposureSurveyId
                }],
            { relativeTo: this._parent._route }
        );
    }

    onShowMaterial(mesx: SelectableEntity<MaterialExposureSurvey>) {
        if (mesx == null) {
            return;
        }

        this._router.navigate(UtilFns.asRouterParts(ROUTES.Material, mesx.data.materialId, ROUTES.Material.childRoutes.MaterialExposure));
    }

    onSurveyChange(es: ExposureSurvey) {
        this.exposureSurvey = es;
        this.fetch();
    }

    isDuplicateMaterialEntry(materialId: number): boolean {
        if (this._edm.entities == null) {
            return false;
        }

        const mat = this._edm.entities.filter(m => m.materialId == materialId);
        if (mat != null && mat.length > 1) {
            return true;
        }

        return false;
    }

    onClearFilter() {
        this._canClearMaterialFilter = false;
        this._materialFilter = '';
        this.formatSelectableEntities();
    }

    onEnterMaterialFilter() {
        this._validationMessage = '';
    }

    // filter the materials in the list to a single material
    onFilterDisplayedMaterials() {
        this._canClearMaterialFilter = true;

        let results: MaterialExposureSurvey[];
        const searchMaterial = this._materialFilter.trim();

        this._materialExposureSurveysEx = [];

        // if non-numeric search for a cas match.
        // if numeric, it could be a materialid or the first set of numbers in a cas number so look for both
        if (isNaN(Number(searchMaterial))) {
            results = this._materialExposureSurveys.filter(e => e.material.realCASNumber.startsWith(searchMaterial));
        } else {
            results = this._materialExposureSurveys.filter(e => e.materialId == Number(searchMaterial));

            if (results == null || results.length == 0) { // try cas substring
                results = this._materialExposureSurveys.filter(e => e.material.realCASNumber.startsWith(searchMaterial));
            }
        }

        if (results == null || results.length < 1) {
            this._validationMessage = 'Unable to locate the filter you entered in the list';
            this._canClearMaterialFilter = false;
            this.formatSelectableEntities();
            return;
        }

        // this._materialExposureSurveysEx.push(new SelectableEntity<MaterialExposureSurvey>(results[0], '#FFFFFF'));
        _.clone(results).forEach(element => {
            element.isomers = this.getIsomersForMaterial(element.materialId);
            this._materialExposureSurveysEx.push(new SelectableEntity<MaterialExposureSurvey>(element, '#FFFFFF'));
        });
    }

    canFilterMaterialList() {
        return this._materialFilter != null && this._materialFilter.trim().length > 0;
    }

}

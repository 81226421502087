<p class="page-title">Adding a New Reference Document</p>
<div class="row" style="margin-left: 10px;">
    <span class="alert-message item-message">{{_validationMessage}}</span>
</div>

<div class="row">
    <i *ngIf="_loading" class="fa fa-circle-o-notch fa-spin" style="font-size:38px; color: #337ab7;"></i>
</div>

<div style="margin-top: 24px;">
    <button *ngIf="_showAddButton" type="button" class="btn btn-sm btn-primary" (click)="onSaveAdd()">Save New Reference Document</button>
    <button type="button" class="btn btn-sm btn-primary"  style="font-size: 14px;" (click)="onCancel()">Close</button>
    <button *ngIf="canShowBackFromAddReferenceDocument()" type="button" class="inline btn btn-view-reference-document" style="width: 190px;" (click)="onBackFromAddReferenceDocument()">View Added Document</button>
</div>

<div class="add-reference-document-grid">
    <button type="button" class="btn-sm btn-success item-select-reference-button" style="margin-top: 2px; font-size: 14px;" (click)="onSelectReferenceForAdd()">Select Reference</button>

    <span class="bold item-select-reference-label">Selected ReferenceId:</span>
    <div class="item-selected-referenceid centered">
        <span class="bold text-center background-aliceblue">{{_selectedReferenceForAdd?.referenceId}}</span>
    </div>

    <label class="fileContainer" class="item-choose-file">
        <input id="fileInputElement" type="file" accept=".pdf" (change)="onFileInput($event.target.files)"/>
    </label>
</div>

<reference-selector></reference-selector>

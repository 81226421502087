import {Component, EventEmitter, forwardRef, Inject, Input, Output, OnInit, ViewChild} from '@angular/core';
import { Location } from '@angular/common';

import { Router } from '@angular/router';
import { Entity } from 'breeze-client';
import { EditorService, ErrorLogger, provideParent, StateMap, UnitOfWork } from '../../services/common';
import { EditPropParent, PageState } from '../../controls/common';

import {Journal, Reference} from '../../entities/EntityModels';

import { JournalEditData, JournalsComponent} from './journals.component';
import { JournalSelectorComponent } from './journal-selector.component';
import {UtilFns} from '../../utils/util-fns';
import {EditManager} from '../../utils/edit-manager';

import * as pluralize from 'pluralize';

// class PageStateExt extends PageState {
//     journalId: number;
// }

@Component({
    selector: 'journal-reassign',
    templateUrl: './journal-reassign.html',
    providers: [provideParent(JournalReassignComponent, EditPropParent)]
})
export class JournalReassignComponent implements OnInit, EditPropParent {
    @ViewChild(JournalSelectorComponent, { static: true }) _journalSelectorComponent: JournalSelectorComponent;

    @Output() stateChange = new EventEmitter<string>();

     _data: JournalEditData;
    _edm: EditManager<Journal>;
    _selectedReferences: Reference[] = [];

    _reassignJournal: Journal;
    _hasBeenReassigned = false;

    // _pageState = new PageStateExt('Journal editor');
    _validationMessage: string;

    constructor(@Inject(forwardRef(() => JournalsComponent)) public _parent: JournalsComponent,
                public _uow: UnitOfWork, public _stateMap: StateMap, public _editorService: EditorService,
                public _location: Location, public _errorLogger: ErrorLogger, public _router: Router) {

        this._data = _parent._data;
    }

    ngOnInit() {
        this._edm	= new EditManager<Journal>(this, this._uow, this._stateMap, 'Reassign Journal');

        if (this._data == null) {
            return;
        }

        this._edm.onlyEntity = this._data.entity;
        this._edm.editing    = true;

        const data = this._data;
        this._selectedReferences = this._edm.currentEntity.references.filter(function(ref) {
            return data.selectedReferenceIds.indexOf(ref.referenceId) !== -1;
        });

        if (this._selectedReferences == null || this._selectedReferences.length < 1) {
            this._validationMessage = 'Error, no References selected for reassignment.';
            return;
        }
    }

    get reassignJournalName(): string {
        if (this._reassignJournal == null ) { return ''; }

        return (this._reassignJournal.journalName);
    }

    onSearchForReassignJournal() {
        this._validationMessage = '';

        this._journalSelectorComponent.setShowJournalData(true);
        UtilFns.showModal(this._journalSelectorComponent, this, null).then((j: Journal) => {
            if (!j) {
                return;
            }
            this._reassignJournal = j;
            if (this._data.entity.journalId == this._reassignJournal.journalId) {
                this._validationMessage = 'Cannot reassign to the same journal.';
            }
        });
    }

    getReferenceCountText() {
        if (this._selectedReferences != null) {
            const l = this._selectedReferences.length;
            return `${l} ${pluralize('reference', l)} will be reassigned.`;
        }
    }

    // *******************************************************
    // edit interactions
    // *******************************************************
    canApply() {

        if (this._data == null || this._reassignJournal == null) {
            return false;
        }

        if (this._selectedReferences == null || this._selectedReferences.length < 1) {
            return false;
        }

        if (this._data.entity.journalId == this._reassignJournal.journalId) {
            return false;
        }

        return (this._edm.editing);
    }

    onApply() {
        this._validationMessage = '';

        if (this._edm.editing) {
            this.reassignJournalReferences();
            this._edm.onApplyCore();
        }

        return true;
    }

    canShowBack() {
        if (this._hasBeenReassigned) {
            return false;
        }

        return (!this._uow.hasChanges());
    }

    onBack() {
        this._edm.editing       = false;
        this._data.inSubEditor  = false;

        this.stateChange.emit('Back');
    }

    canCancel() {
        return (this._uow.hasChanges());
    }

    onCancel() {
        this._validationMessage = '';

        this._uow.rollback();

        this._edm.clearErrors();
        this._edm.editing = false;
        this._edm.pageState.loadStatusMsg = null;

        const data          = this._data;
        data.inSubEditor    = false;

        this.stateChange.emit('Cancel');
    }

    canReassign() {
        return (this._edm.currentEntity != null && this._reassignJournal != null);
    }

    reassignJournalReferences() {

        this._selectedReferences.forEach(r => {
            r.journalId = this._reassignJournal.journalId;
        });
    }

    canSave(): boolean {
        if (this._edm.editing) { return false; }
        if (!this._edm.hasEdits()) { return false; }
        if (!this._edm.validateBeforeSave()) { return false; }

        return true;
    }

    onSave() {
        // journal
        if (this._edm.hasEdits()) {
            this._edm.onSaveCore().then(() => {
                this._edm.editing = false;

                const data = this._data;
                data.inSubEditor = false;
                this.stateChange.emit('Save');
            });
        }
    }

    sameStringWithoutSpaces(compare1: string, compare2: string) {
        return (compare1.replace(/\s+/g, '').toLowerCase()	== compare2.replace(/\s+/g, '').toLowerCase());
    }

    getError(propName: string) {
        if (this._edm == null || this._edm.currentEntity == null) {
            return;
        }

        const r = this._edm.currentEntity.getErrorFor(propName);
        return r;
    }

    get pageTitle() {
        if (this._data == null || this._data.entity == null) {
            return '';
        }

        return this._data.entity.journalName;
    }
}

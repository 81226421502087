<p class="page-title">Editing PDF Pages</p>

<span class="inline">
    <span class="page-title">For Reference Id: </span>
    <span class="page-title left-indent bold" style="color: #337ab7;">{{referenceId}}</span>
</span>

<div class="flex-container" style="background-color: transparent;">
    <div class="flex-item">
        <button class="btn-select" style="width: 180px;" [ngStyle]="{ 'background-color': pageActionButtonColor('extract') }" (click)="onSelectPageActionType('extract')">Copy Pages From PDF File</button>
    </div>
    <div class="flex-item">
        <button class="btn-select" style="width: 180px;" [ngStyle]="{ 'background-color': pageActionButtonColor('add') }" (click)="onSelectPageActionType('add')">Add Pages to PDF File</button>
    </div>
    <div class="flex-item">
        <button class="btn-select-delete" style="width: 222px;" [ngStyle]="{ 'background-color': pageActionButtonColor('remove') }" (click)="onSelectPageActionType('remove')">Delete Pages from PDF File</button>
    </div>
</div>

<div style="margin-top: 24px;">
    <button type="button" class="btn btn-primary navbar-btn" (click)="onBack()">Close</button>
    <button type="button" class="inline btn btn-view-reference-document" style="width: 150px;" (click)="onBackAndView()">View Document</button>
    <button *ngIf="hasAddPagesEdits()" class="btn btn-primary navbar-btn" type="button" (click)="addPages()" [disabled]="!hasAddPagesEdits()">Save Added Pages</button>
    <button *ngIf="hasExtractPagesEdits()" class="inline btn btn-download" style="width: 205px;" type="button" (click)="extractPages()" [disabled]="!hasExtractPagesEdits()">Download Copied Pages</button>
    <button *ngIf="hasRemovePagesEdits()" class="btn btn-primary navbar-btn" type="button" (click)="removePages()" [disabled]="!hasRemovePagesEdits()">Confirm Page(s) Deletion</button>
</div>

<div class="row" style="margin-left: 10px; margin-top: 8px;">
    <span class="alert-message item-message">{{_validationMessage}}</span>
</div>

<div class="row">
    <i *ngIf="_loading" class="fa fa-circle-o-notch fa-spin" style="font-size:38px; color: #337ab7;"></i>
</div>

<div style="margin-top: 14px; margin-left: 14px;">
    <div id="extract-grid" *ngIf="selectedPageActionType == pdfPageActionType.EXTRACTPAGES" class="pdf-editpages-grid-container" style="margin-top: 10px;">

        <!-- Row 1 -->
        <span class="item-pdfedit-label">
    	<span class="bold" style="font-size: 18px;">Copy and Download Pages</span>
        <img style="margin-left: 10px; height: 64px; width: 64px;" class="inline" src="assets/images/ExtractPagesFromPDF.png" />
    </span>

        <!-- Row 2 -->
        <span  class="bold item-pdfedit-rangestartlabel">Range Start:</span>
        <input class="item-pdfedit-rangestartinput background-white" type="text" [(ngModel)]="_extractPageRangeStart"/>
        <span  class="bold item-pdfedit-rangeendlabel">Range End:</span>
        <input class="item-pdfedit-rangeendinput background-white" type="text" [(ngModel)]="_extractPageRangeEnd"/>

        <!-- Row 3 -->
        <span  class="bold item-pdfedit-numberstringlabel">Individual Pages:</span>
        <input class="item-pdfedit-numberstringinput background-white"
               autocomplete="extractnumberstring" type="text" [(ngModel)]="_extractPageNumberString"/>

        <!-- Row 4 -->
        <span  class="italic item-pdfedit-instructions">Please enter pages numbers separated by a comma.</span>
    </div>

    <div id="add-pages-grid" *ngIf="selectedPageActionType == pdfPageActionType.ADDPAGES" class="pdf-editpages-grid-container">

        <!-- Row 1 -->
        <span class="item-pdfedit-label">
	        <span class="bold" style="font-size: 18px;">Add Pages</span>
            <img style="margin-left: 10px; height: 64px; width: 64px;" class="inline" src="assets/images/AddPagesToPDF.png" />
        </span>

        <!-- Row 2 -->
        <label class="item-pdfedit-insertbypage bold background-aliceblue">
            <input type ="radio" name="insertType" ng-model="_selectedInsertionOptionPageIndex" (click)="onselectedInsertionOption(0)" checked/>
            Insert by Page Number
        </label>

        <label class="item-pdfedit-insertbeginning bold background-aliceblue">
            <input type ="radio" name="insertType" ng-model="_selectedInsertionOptionBeginning" (click)="onselectedInsertionOption(1)" />
            Insert at the Beginning
        </label>

        <label class="item-pdfedit-insertend bold background-aliceblue">
            <input type ="radio" name="insertType" ng-model="_selectedInsertionOptionEnd" (click)="onselectedInsertionOption(2)"/>
            Insert at the End
        </label>

        <!-- Row 3 -->
        <span *ngIf="_selectedInsertionOptionPageIndex" class="item-pdfedit-addpageindexlabelandinput">
            <span  class="bold item-pdfedit-addpageindexlabel inline">Insert Before Page:</span>
            <input class="item-pdfedit-addpageindexinput background-white inline left-indent"
                   autocomplete="addindexstring"
                   type="text" [(ngModel)]="_addPageIndex"/>
        </span>

        <div class="item-pdfedit-fileinput">
            <label class="fileContainer">
                <input id="fileInputElement" type="file" accept=".pdf" (change)="onFileInput($event.target.files)"/>
            </label>
        </div>
    </div>

    <div id="remove-pages-grid" *ngIf="selectedPageActionType == pdfPageActionType.REMOVEPAGES" class="pdf-editpages-grid-container">
        <!-- Row 1 -->
        <span class="item-pdfedit-label">
    	<span class="bold" style="font-size: 18px;">Delete Pages</span>
        <img style="margin-left: 10px; height: 64px; width: 64px;" class="inline" src="assets/images/DeletePagesFromPDF.png" />
    </span>

        <!-- Row 2 -->
        <span  class="bold item-pdfedit-rangestartlabel">Range Start:</span>
        <input class="item-pdfedit-rangestartinput background-white" type="text" [(ngModel)]="_removePageRangeStart"/>
        <span  class="bold item-pdfedit-rangeendlabel">Range End:</span>
        <input class="item-pdfedit-rangeendinput background-white"
               autocomplete="removenumberstring"
               type="text" [(ngModel)]="_removePageRangeEnd"/>

        <!-- Row 3 -->
        <span  class="bold item-pdfedit-numberstringlabel">Individual Pages:</span>
        <input class="item-pdfedit-numberstringinput background-white" type="text" [(ngModel)]="_removePageNumberString"/>

        <!-- Row 4 -->
        <span  class="italic item-pdfedit-instructions">Please enter pages numbers separated by a comma.</span>
    </div>
</div>

<!--<show-and-download-pdf></show-and-download-pdf>-->

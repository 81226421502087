import { Component, ViewChild, Inject, Input, forwardRef, OnInit } from '@angular/core';

import { UnitOfWork, provideParent } from '../../services/common';
import { UtilFns } from '../../utils/common';
import { ModalDialog, PageState } from '../../controls/common';

import { Journal, JournalReserve, JournalSubscription} from '../../entities/EntityModels';
import { JournalListItem } from '../../entities/projections/JournalListItem';
import { JournalReserveItem } from '../../entities/projections/JournalReserveItem';
import { JournalSubscriptionItem } from '../../entities/projections/JournalSubscriptionItem';
import { SelectableEntity } from '../../entities/projections/SelectableEntity';

import {JournalSelectorComponent, JournalSearchData } from './journal-selector.component';

import * as _ from 'lodash';

export enum SearchType { Journal, Publisher }

@Component({
    selector: 'journal-search',
    templateUrl: './journal-search.html',
    providers: [provideParent(JournalSearchComponent)]
})export class JournalSearchComponent implements OnInit {

    _pageState = new PageState('Journals');
    _userMessage: string;
    _validationMessage: string;

    _journalSearchResults: JournalListItem[];
    _selectedJournalListItem: JournalListItem;
    _selectOnlyMode = false;

    _journal: Journal;
    _journalsEx: SelectableEntity<JournalListItem>[] = [];

    _currentSearchTerm: string;
    _data: JournalSearchData;
    _searchType: SearchType = SearchType.Journal;

    private colorSelected   = '#B0C4DE'; // '#C6E7CE'; // '#e0eeee';
    private colorUnselected = '#FFFFFF';

    toggleShowJournalIdTip                      = false;
    toggleShowJournalNameTip                    = false;
    toggleShowJournalReservesTip                = false;
    toggleShowJournalSubscriptionsTip           = false;
    toggleShowJournalSubscriptionTypeTip        = false;
    toggleShowJournalNotesTip                   = false;
    toggleShowJournalRelatedNamesTip            = false;
    toggleShowJournalFoundInTip                 = false;
    toggleShowJournalSubscriptionPublishersTip  = false;

    constructor(@Inject(forwardRef(() => JournalSelectorComponent)) public _parent: JournalSelectorComponent, public _uow: UnitOfWork) {
        this._data = _parent._data;
        // this._data.onResetMap['base']  = this.onReset.bind(this);
    }

    ngOnInit() {
    }

    canSelect() {
        return this._journal != null;
    }

    // load data
    search(searchTerm: string, hasJournalReservesInLibrary: boolean, startsWith: boolean, activeSubscriptionsOnly: boolean, subscriptionType: string) {

        this.clearDisplayData();

        this._currentSearchTerm = searchTerm;
        this._searchType = SearchType.Journal;

        this._pageState.isLoading   = true;

        const searchTermParam = (searchTerm === null || searchTerm == undefined) ? '' : searchTerm;

        // tslint:disable-next-line:max-line-length
        const params = { searchText: UtilFns.encodeHackForAnd(searchTermParam), hasJournalReservesInLibrary: hasJournalReservesInLibrary, startsWith: startsWith, activeSubscriptionsOnly: activeSubscriptionsOnly, subscriptionType: UtilFns.encodeHackForAnd(subscriptionType)};
        return this._uow.fetch('Misc/FetchFilteredJournals', params).then(r => {

            if (r && r.length > 0) {
                this._journalSearchResults = r;

                this.formatSelectableEntities();

            } else {
                this._userMessage = 'No matches found.';
            }

            this._pageState.isLoading = false;
        });
    }

    searchPublications(searchTerm: string) {

        this.clearDisplayData();

        this._currentSearchTerm = searchTerm;
        this._searchType = SearchType.Publisher;

        this._pageState.isLoading   = true;
        const searchTermParam = (searchTerm === null || searchTerm == undefined) ? '' : searchTerm;
        const params = { searchText: UtilFns.encodeHackForAnd(searchTermParam) };
        return this._uow.fetch('Misc/FetchJournalsBySubscriptionPublisher', params).then(r => {

            if (r && r.length > 0) {
                this._journalSearchResults = r;

                this.formatSelectableEntities();

            } else {
                this._userMessage = 'No matches found.';
            }

            this._pageState.isLoading = false;
        });
    }

    formatSelectableEntities() {
        this._journalsEx = [];
        if (this._journalSearchResults == null || this._journalSearchResults.length < 1) { return; }

        _.clone(this._journalSearchResults).forEach(element => {
            this._journalsEx.push(new SelectableEntity<JournalListItem>(_.clone(element), '#FFFFFF'));
        });

        this._journalsEx = this._journalsEx.sort(SelectableEntity.compareJournals);
    }


    clearDisplayData() {
        this._userMessage               = null;
        this._validationMessage         = null;

        this._journalSearchResults      = null;
        this._journalsEx                = [];
        this._selectedJournalListItem   = null;

        this._currentSearchTerm         = null;
    }

    onSelect(selectedEntity: SelectableEntity<JournalListItem>) {
        this._selectedJournalListItem = selectedEntity.data;

        if (this._selectedJournalListItem) {
            this.resetBackgroundColor(this._journalsEx);
            selectedEntity.backgroundColor = this.colorSelected;
            this._parent.selectedJournalId(this._selectedJournalListItem.journalId);
        }
    }

    resetBackgroundColor(journals: SelectableEntity<JournalListItem>[]) {
        journals.forEach((element, index) => {
            element.backgroundColor = this.colorUnselected;
        });
    }

    onReset() {
        this.clearDisplayData();
    }

    get utilFns() {
        return UtilFns;
    }

    get resultsCount() {
        return (this._journalsEx == null) ? 0 : this._journalsEx.length;
    }

    get userMessage() {
        return this._userMessage;
    }

    showFoundIn() {
        return (this._searchType == SearchType.Journal);
    }

    showPublishers() {
        return (this._searchType == SearchType.Publisher);
    }

    // ******************************************************
    // *Tooltip Display                                     *
    // ******************************************************
    toggleTooltip(whichTip: number) {
        switch (whichTip) {
            case 1: {
                this.toggleShowJournalIdTip = !this.toggleShowJournalIdTip;
                break;
            }
            case 2: {
                this.toggleShowJournalNameTip = !this.toggleShowJournalNameTip;
                break;
            }
            case 3: {
                this.toggleShowJournalReservesTip = !this.toggleShowJournalReservesTip;
                break;
            }
            case 4: {
                this.toggleShowJournalSubscriptionsTip = !this.toggleShowJournalSubscriptionsTip;
                break;
            }
            case 5: {
                this.toggleShowJournalSubscriptionTypeTip = !this.toggleShowJournalSubscriptionTypeTip;
                break;
            }
            case 6: {
                this.toggleShowJournalNotesTip = !this.toggleShowJournalNotesTip;
                break;
            }
            case 7: {
                this.toggleShowJournalRelatedNamesTip = !this.toggleShowJournalRelatedNamesTip;
                break;
            }
            case 8: {
                this.toggleShowJournalFoundInTip = !this.toggleShowJournalFoundInTip;
                break;
            }
            case 9: {
                this.toggleShowJournalSubscriptionPublishersTip = !this.toggleShowJournalSubscriptionPublishersTip;
                break;
            }
            default: {
                break;
            }
        }
    }

    formatTooltipContent(whichTip: number) {

        let hcode = '';

        switch (whichTip) {
            case 1: {
                // tslint:disable-next-line:max-line-length
                hcode = '<div style="background-color: #F0F8FF !important; font-weight: bold; width: 200px;"><b>Searchable, primary Journal Identifier.</b></div>';
                break;
            }
            case 2: {
                // tslint:disable-next-line:max-line-length
                hcode = '<div style="background-color: #F0F8FF !important; font-weight: bold; width: 200px;"><b>Journal Name associated with the displayed Journal Id.</b></div>';
                break;
            }
            case 3: {
                // tslint:disable-next-line:max-line-length
                hcode = '<div style="background-color: #F0F8FF !important; font-weight: bold; width: 200px;"><b>A "Yes" in this column indicates that physical copies of a Journal are available in the RIFM Library.</b></div>';
                break;
            }
            case 4: {
                // tslint:disable-next-line:max-line-length
                hcode = '<div style="background-color: #F0F8FF !important; font-weight: bold; width: 200px;"><b>Journals with Subscriptions that do not have an end date are marked as "Active". Subscriptions that have an end date are marked as "Inactive".</b></div>';
                break;
            }
            case 5: {
                // tslint:disable-next-line:max-line-length
                hcode = '<div style="background-color: #F0F8FF !important; font-weight: bold; width: 200px;"><b>If the Journal has an active or inactive Subscription, the type is displayed.</b></div>';
                break;
            }
            case 6: {
                // tslint:disable-next-line:max-line-length
                hcode = '<div style="background-color: #F0F8FF !important; font-weight: bold; width: 200px;"><b>Notes writen by Staff for the Journal.</b></div>';
                break;
            }
            case 7: {
                // tslint:disable-next-line:max-line-length
                hcode = '<div style="background-color: #F0F8FF !important; font-weight: bold; width: 200px;"><b>The list of former Journal names and any Journal name changes.</b></div>';
                break;
            }
            case 8: {
                // tslint:disable-next-line:max-line-length
                hcode = '<div style="background-color: #F0F8FF !important; font-weight: bold; width: 200px;"><b>The Journal data that matched the search term and filters.</b></div>';
                break;
            }
            case 9: {
                // tslint:disable-next-line:max-line-length
                hcode = '<div style="background-color: #F0F8FF !important; font-weight: bold; width: 200px;"><b>The Publisher data that matched the search term.</b></div>';
                break;
            }
            default: {
                break;
            }
        }
        return hcode;
    }
}


<div class="col-md-7">
    <div class="row form-group">
        <label class="col-md-2 text-right">Topic:</label>
        <input class="col-md-3" type="text" [(ngModel)]="entity.topic">
        <div class="alert-danger">{{entity.getErrorFor("topic")}}</div>
    </div>

    <div class="row form-group">
        <label class="col-md-2 text-right">Determination:</label>
        <input class="col-md-3" type="text" [(ngModel)]="entity.determination">
        <div class="alert-danger">{{entity.getErrorFor("determination")}}</div>
    </div>
</div>    
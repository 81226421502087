import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import * as _ from 'lodash';

import { StateMap, UnitOfWork } from '../../services/common';
import { UtilFns } from '../../utils/common';
import { PageState } from '../../controls/common';

import { BiologicalData, ExperimentalMaterial, Material, Reference } from '../../entities/EntityModels';
import { ReferenceListItem } from '../../entities/projections/ReferenceListItem';

import { ROUTES } from '../routes';
import { REFERENCE_ROUTES } from './reference.routes';

@Component({
    selector: 'reference-data',
    templateUrl: './reference-data.html',
})
export class ReferenceDataComponent implements OnInit, OnDestroy {
    @Input() toxaudit: boolean;
    @Input() expanded: boolean;
    @Input() reference: Reference;
    @Input() initTitle = true;

    _queryParamsSub: any;
    _emptyQueryParams: { queryParams: {} };


    _casContext: string;
    _bioIdContext: number;

    visibleIxs = {};

    material: Material;
    biologicalDatas: BiologicalData[];
    _sub: any;
    _pageState = new PageState('Reference');
    _printBd: BiologicalData;
    _printExpMaterial: ExperimentalMaterial;
    _briefRefsNotFound: string[] = [];

    constructor(public _uow: UnitOfWork, public _stateMap: StateMap,
                public _router: Router, public _route: ActivatedRoute, public _location: Location) {
        this.reference = this._stateMap.currentReference;
    }

    ngOnInit() {
        // this._queryParamsSub = this._router.routerState.queryParams.subscribe(params => {
        // this._queryParamsSub = this._router.routerState.root.queryParams.subscribe(params => {
        if (this.initTitle) {
            this._stateMap.currentRouteName = REFERENCE_ROUTES.ReferenceData.name;
        }

        this._queryParamsSub = this._route.queryParams.subscribe(params => {
            this._casContext = params['cas'];
            this._bioIdContext = +params['bioid'];
            this.fetchBioData();
        });
    }

    ngOnDestroy() {
        this._queryParamsSub.unsubscribe();
    }

    private fetchBioData() {
        if (this.reference.biologicalDatas == null || this.reference.biologicalDatas.length == 0 || this._bioIdContext) {
            this._pageState.isLoading = true;
            // basically doing this + some includes.
            // this.reference.entityAspect.loadNavigationProperty("biologicalDatas")
            const that = this;
            this._uow.referenceRepository.populateBiologicalData(this.reference).then(() => {
                this._pageState.isLoaded = true;
                that.initializeLocals();
            })
                .catch();
        } else {
            this.initializeLocals();
        }
    }

    doReport(bd: BiologicalData, expMaterial: ExperimentalMaterial) {
        this._printBd = bd;
        this._printExpMaterial = expMaterial;
        setTimeout(() => {
            const ele = $('#FullReport');
            (<any>ele).modal('show');
        }, 0);
    }

    abbreviate(s: string, length: number) {
        if (s == null || s.trim().length === 0) {
            return '-';
        }
        if (s.length <= length) {
            return s;
        }
        return s.trim().substr(0, length) + ' . . .';
    }


    initializeLocals() {
        this.biologicalDatas = this.reference.biologicalDatas;
        // sort biologicalData by fileReference with biologicalDatas matching this._bioIdContext to the top;
        if (this.biologicalDatas.length == 0) {
            this._pageState.isLoadedWithNoData = true;
            return;
        }
        // assign tempSubRef #s to blank fileRefs from oldest to newest.
        let tempIx = 1;
        _.sortBy(this.biologicalDatas, bd => bd.created).forEach(bd => {
            // if fileRef is null or blank ( or 0)
            if (!bd.fileReference) {
                bd._tempSubReference = tempIx++;
            }
        });
        UtilFns.sort(this.biologicalDatas, true, (bd) => {
            let prefix = '';
            if (this._bioIdContext) {
                prefix = bd.biologicalDataId == this._bioIdContext ? 'a_' : 'z_';
            }
            return prefix + bd.subReference || '';
        });
        // sort each experimentalMaterial by casNumber with casNumbers matching this._casContext to the top
        this.biologicalDatas.forEach(bd => {
            UtilFns.sort(bd.experimentalMaterials, true, (expMat) => {
                let prefix = '';
                if (this._casContext != null) {
                    prefix = expMat.material.realCASNumber == this._casContext ? 'a_' : 'z_';
                }
                return prefix + expMat.material.realCASNumber;
            });

            bd.experimentalMaterials.forEach(em => {
                UtilFns.sortNumeric(em.experimentalResults, true, (expRes) => {
                    return expRes.dose;
                });
            });
        });
        this._pageState.isLoaded = true;

    }

    isStaff() {
        return this._stateMap.currentUser.isStaff;
    }

    isNotZero(s: number) {
        return s != 0;
    }


    showHide(op: string, ix: number, jx: number) {
        const key = op + ':' + ix + ':' + jx;
        this.visibleIxs[key] = !this.visibleIxs[key];
    }

    isVisible(op: string, ix: number, jx: number) {
        const key = op + ':' + ix + ':' + jx;
        if (this.visibleIxs[key] == undefined) {
            if (this.expanded || op == 'expMaterial') {
                this.visibleIxs[key] = true;
            }
        }

        return !!this.visibleIxs[key];
    }

    getShowHideText(op: string, ix: number, jx: number) {
        const show = this.isVisible(op, ix, jx);
        return show ? 'Less details...' : 'More Details...';
    }

    isClickableBriefRef(briefRef: string) {
        if (this._briefRefsNotFound.indexOf(briefRef) >= 0) {
            return false;
        }
        return briefRef != null && briefRef.indexOf(',') >= 0;
    }

    goToBriefRef(briefRef: string) {
        return this._uow.fetch('References/ReferencesByBriefReference', { briefReference: briefRef }).then(r => {
            const brMatches = <ReferenceListItem[]><any>r;
            if (brMatches.length == 0) {
                this._briefRefsNotFound.push(briefRef);
                return;
            }

            this._router.navigate(UtilFns.asRouterParts(ROUTES.Reference, brMatches[0].referenceId), this._emptyQueryParams);
        });
    }

    goToSynonyms(expmaterial: ExperimentalMaterial) {
        this._router.navigate(UtilFns.asRouterParts(ROUTES.Material, expmaterial.materialId));
    }

    showDetailsToggle() {
        // All reference data is shown by default when displayed in the Staff Editing Reference Audit view
        if (this.toxaudit && this.toxaudit == true) {
            return false;
        }
        return (this.biologicalDatas && this.biologicalDatas.length > 0);
    }

    toggleDetailsDisplay(event: any) {
        this.expanded = event.target.checked;
        for (const key in this.visibleIxs) {
            if (this.visibleIxs.hasOwnProperty(key)) {
                this.visibleIxs[key] = event.target.checked;
            }
        }

    }
}

import { Location } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import * as _ from 'lodash';

import { StateMap, UnitOfWork, UserManager } from '../../services/common';
import {ReportingOrganization, Survey, SurveyUsesByCompanyAndMaterialAndYear, SurveyUsesByMaterialAndYear} from '../../entities/EntityModels';
import { CompanyAndSurveyYear, GeographicalAreaAndSurveyYear, SurveyFilters, SurveyReportedUseListItem } from '../../entities/projections/SurveyProjections';
import { MaterialSelectorComponent } from '../material/material-selector.component';
import { SurveyWhoUsedMostComponent } from './survey-whousedmost.component';
import { UtilFns } from '../../utils/common';
import { STAFF_ROUTES } from './staff.routes';

@Component({
    selector: 'survey-reported-use',
    templateUrl: './survey-reported-use.html',
})

export class SurveyReportedUseComponent implements OnInit {
    @ViewChild(MaterialSelectorComponent, { static: true }) _materialSelectorComponent: MaterialSelectorComponent;
    @ViewChild(SurveyWhoUsedMostComponent, { static: true }) _surveyWhoUsedMostComponent: SurveyWhoUsedMostComponent;

    public _hasLinks = false;
    public _isLoading = true;
    public _pageSize = 100;
    public _paginationTotalItems = 0;
    public _paginationCurrentPage = 0;
    public _pages: number[] = [];
    public _searchParams: { pageNumber: number, pageSize: number, sortField: string, sortDescending: boolean } = {
        pageNumber: 1,
        pageSize: 100,
        sortField: '',
        sortDescending: false
    };

    public _reportTitle: string;
    public _organizationNames: string[];
    public _reportingOrganizations: ReportingOrganization[];
    public _surveys: Survey[];
    public _surveyYears: string[];

    public _filterOrganizationName: string;
    public _filterSurveyYear: string;

    public _unitOfMeasure: string;

    private _selectedYears: string[] = [];
    public _selectedCASDisplay: string;
    private _selectedMaterialId: number;

    public _surveyReportedUseByMaterialAndYears: SurveyUsesByMaterialAndYear[];
    public _surveyReportedUseByCompany: SurveyUsesByCompanyAndMaterialAndYear[];

    constructor(private _uow: UnitOfWork, private _stateMap: StateMap, private _userManager: UserManager, private _router: Router, public _location: Location) {
    }

    ngOnInit() {

        this._stateMap.currentRouteName = STAFF_ROUTES.SurveyReportedUse.name;

        this._unitOfMeasure = 'Kilograms';

        this._isLoading = true;

        this._uow.fetchTyped('MaterialReportedUses/ReportingOrganizations', ReportingOrganization, { }).then(r => {

            this._reportingOrganizations = r;

            const names = this._reportingOrganizations.map(n => n.organizationName);

            this._organizationNames = names.sort();
            this._organizationNames.unshift('All');

            this._filterOrganizationName = 'All';

            this._uow.fetchTyped('MaterialReportedUses/SurveysByMinYear', Survey, { minYear: 2011 }).then(s => {

                this._surveys = _.sortBy(s, y => y.surveyYear).reverse();

                this._surveyYears = this._surveys.map(sy => sy.surveyYear).filter((value, index, self) => self.indexOf(value) === index);

                this._selectedYears[0] = this._surveyYears[0];

                this._filterSurveyYear = this._surveyYears[0];

                this.generateReport();

                this._isLoading = false;
            });
        });
    }

    // load data
    public generateReport() {

        this.clearReportData();

        this._isLoading = true;

        if (this._filterOrganizationName != null && this._filterOrganizationName != 'All') {
            this.generateReportByCompany();
        } else {
            this.generateReportByMaterialAndYear();
        }
    }

    // This method cam be invoked when the only filter selected is the Survey Year.
    // In this case the _selectedMaterialId is set to null.
    // The web api has the materialId as an optional parameter.
    private generateReportByMaterialAndYear() {
        this._reportTitle = (this._selectedMaterialId != null) ? 'Report By Year and Material for Each Reported Geographical Area' : 'Report By Year for Each Reported Geographical Area';
        this._pages = [];

        this._searchParams.pageSize = 100;
        this._searchParams.pageNumber = 1;
        this._searchParams.sortField = 'DisplayCAS';
        this._searchParams.sortDescending = false;

        // Get counts for pagination first
        const paramsCount = {
            years: this._selectedYears,
            casNumber: '',
            materialId: this._selectedMaterialId // This param may be null
        };

        this._uow.fetch('MaterialReportedUses/FetchSurveyReportedUsesCount', paramsCount).then(c => {

            if (c != null && c.length > 0) {
                console.log('paginationTotalItems: ' + c[0]);
                this._paginationTotalItems = c[0];
            } else { console.log('No pagination items.'); }

            const paramsReport = {
                years: this._selectedYears,
                casNumber: '', // Search is by material id not cas number
                pageNumber: this._searchParams.pageNumber,
                pageSize: this._searchParams.pageSize,
                sortField: this._searchParams.sortField,
                sortDescending: this._searchParams.sortDescending,
                materialId: this._selectedMaterialId // This param may be null
            };

            this._uow.fetch('MaterialReportedUses/FetchSurveyReportedUses', paramsReport).then(r => {
                if (r != null && r.length > 0) {
                    this._searchParams.pageNumber = 1;
                    this._surveyReportedUseByMaterialAndYears = r;
                    this.hasLinks = true;
                } else {
                    this._reportTitle = 'No data found';
                    this.hasLinks = false;
                }

                if (this.hasPages()) {
                    let count = this._paginationTotalItems / this._searchParams.pageSize;
                    if ((this._paginationTotalItems % this._searchParams.pageSize) > 0) {
                        count = count + 1;
                    }

                    this._pages = _.range(1, count);
                }

                this._isLoading = false;

            });

        });
    }

    private generateReportByCompany() {

        this._reportTitle = 'Reported Use for ' + this._filterOrganizationName;
        this.hasLinks = false;

        this._pages = [];

        this._searchParams.pageSize = 100;
        this._searchParams.pageNumber = 1;
        this._searchParams.sortField = 'DisplayCAS';
        this._searchParams.sortDescending = false;

        // Get counts for pagination first
        const paramsCount = {
            years: this._selectedYears,
            companyName: this._filterOrganizationName,
            casNumber: '', // Search is by material id not cas number
            materialId: this._selectedMaterialId
        };

        this._uow.fetch('MaterialReportedUses/FetchSurveyReportedUsesByCompanyAndMaterialAndYearCount', paramsCount).then(c => {

            if (c != null && c.length > 0) {
                console.log('paginationTotalItems: ' + c[0]);
                this._paginationTotalItems = c[0];
            } else { console.log('No pagination items.'); }

            const paramsReport = {
                years: this._selectedYears,
                companyName: this._filterOrganizationName,
                casNumber: '', // Search is by material id not cas number
                pageNumber: this._searchParams.pageNumber,
                pageSize: this._searchParams.pageSize,
                sortField: this._searchParams.sortField,
                sortDescending: this._searchParams.sortDescending,
                materialId: this._selectedMaterialId // This param may be null
            };

            this._uow.fetch('MaterialReportedUses/FetchSurveyReportedUsesByCompany', paramsReport).then(r => {
                if (r != null && r.length > 0) {
                    this._searchParams.pageNumber = 1;
                    this._surveyReportedUseByCompany = r;
                } else {
                    this._reportTitle = 'No data found';
                }

                if (this.hasPages()) {
                    let count = this._paginationTotalItems / this._searchParams.pageSize;
                    if ((this._paginationTotalItems % this._searchParams.pageSize) > 0) {
                        count = count + 1;
                    }

                    this._pages = _.range(1, count);
                }

                this._isLoading = false;

            });
        });
    }

    private clearReportData() {
        this._paginationTotalItems = 0;
        this._surveyReportedUseByMaterialAndYears = [];
        this._surveyReportedUseByCompany = [];

        this._reportTitle = '';
        this._hasLinks = false;
    }

    public fetchNextPage(pageNumber: number) {
        if (this._surveyReportedUseByMaterialAndYears != null && this._surveyReportedUseByMaterialAndYears.length > 1) {
            return this.fetchSurveyUsesPage(pageNumber);
        } else {
            return this.fetchSurveyUsesByCompanyPage(pageNumber);
        }
    }

    public fetchSurveyUsesPage(pageNumber: number): Promise<any> {

        this._isLoading = true;

        this._searchParams.pageNumber = pageNumber;

        const paramsReport = {
            years: this._selectedYears,
            casNumber: '',
            pageNumber: this._searchParams.pageNumber,
            pageSize: this._searchParams.pageSize,
            sortField: this._searchParams.sortField,
            sortDescending: this._searchParams.sortDescending,
            materialId: this._selectedMaterialId
        };

        this._surveyReportedUseByMaterialAndYears = [];
        return this._uow.fetch('MaterialReportedUses/FetchSurveyReportedUses', paramsReport).then(r => {
            if (r != null && r.length > 0) {
                this._surveyReportedUseByMaterialAndYears = r;
                this._searchParams.pageNumber = pageNumber;
            }
            this._isLoading = false;
        });
    }

    public fetchSurveyUsesByCompanyPage(pageNumber: number): Promise<any> {

        this._isLoading = true;

        this._searchParams.pageNumber = pageNumber;

        const paramsReport = {
            years: this._selectedYears,
            companyName: this._filterOrganizationName,
            casNumber: '', // Search is by material id not cas number
            pageNumber: this._searchParams.pageNumber,
            pageSize: this._searchParams.pageSize,
            sortField: this._searchParams.sortField,
            sortDescending: this._searchParams.sortDescending,
            materialId: this._selectedMaterialId // This param may be null
        };

        this._surveyReportedUseByCompany = [];
        return this._uow.fetch('MaterialReportedUses/FetchSurveyReportedUsesByCompany', paramsReport).then(r => {
            if (r != null && r.length > 0) {
                this._surveyReportedUseByCompany = r;
                this._searchParams.pageNumber = pageNumber;
            }
            this._isLoading = false;
        });
    }

    public get hasLinks(): boolean {
        return this._hasLinks;
    }

    public set hasLinks(value: boolean) {
        this._hasLinks = value;
    }

    public hasPages(): boolean {
        return (this._paginationTotalItems > 1);
    }

    public isActive(index: number): boolean {
        return (index == this._searchParams.pageNumber);
    }

    public isInactive(index: number): boolean {
        return (!this.isActive(index));
    }

    public get utilFns() {
        return UtilFns;
    }

    public clearSelectedMaterial() {
        this._selectedCASDisplay = '';
        this._selectedMaterialId = null;

        this.generateReport();

    }

    // Different organizations report each survey year so update the list with each year change.
    public filterSurveyYearChange(event: any) {
        const ix = event.currentTarget.selectedIndex;

        this._selectedYears = [];
        this._selectedYears[0] = this._filterSurveyYear;

        this.generateReport();
    }

    public filterOrganizationChange(event: any) {
        this.generateReport();
    }

    public setUnitOfMeasure(uom: string) {
        this._unitOfMeasure = uom;
    }

    public onSelectMaterial() {
        UtilFns.showModal(this._materialSelectorComponent, this).then(mli => {
            if (mli == null) {
                return;
            }
            return this._uow.materialRepository.withId(mli.materialId);
        }).then(m => {
            if (m) {
                this._selectedCASDisplay = (m.realCASNumber || '') + ' RIFM Id: ' + m.formattedRIFMId;
                this._selectedMaterialId = m.materialId;

                this.generateReport();
            }
        });
    }

    public onShowWhoUsedMost(use: SurveyReportedUseListItem) {
        this._surveyWhoUsedMostComponent.displayCAS = use.displayCAS;
        this._surveyWhoUsedMostComponent.materialId = use.materialId;
        this._surveyWhoUsedMostComponent.formattedRIFMId = use.formattedRifmId;

        this._surveyWhoUsedMostComponent.loadData(this._selectedYears[0]);

        UtilFns.showModal(this._surveyWhoUsedMostComponent, this).then(wum => {
            return;
        });
    }
}


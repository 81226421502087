import { Component, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import * as _ from 'lodash';

import { EditorService, ErrorLogger, provideParent, StateMap, UnitOfWork } from '../../services/common';
import { EditManager, LocationFns, UtilFns } from '../../utils/common';
import { EditPropParent, PageState } from '../../controls/common';

import { ProjectSelectorComponent } from './project-selector.component';

import { Project } from '../../entities/EntityModels';

import { STAFF_ROUTES } from './staff.routes';

class PageStateExt extends PageState {
    projectId: number;
}

@Component({
    selector: 'project-editor',
    templateUrl: './project-editor.html',
    providers: [provideParent(ProjectEditorComponent, EditPropParent)]
})
export class ProjectEditorComponent implements OnInit, EditPropParent {
    @ViewChild(ProjectSelectorComponent, { static: true }) _projectSelectorComponent: ProjectSelectorComponent;

    _projects: Project[];
    _maxProjectId: number;
    _edm: EditManager<Project>;
    _adding: boolean;
    // this is not the same pageState that the _edm is using.
    _pageState = new PageStateExt('Project editor');

    constructor(public _uow: UnitOfWork, public _stateMap: StateMap, public _editorService: EditorService,
                public _location: Location, public _errorLogger: ErrorLogger, public _router: Router) {

    }

    ngOnInit() {
        this._stateMap.currentRouteName = STAFF_ROUTES.Project.name;
        this._edm = this._editorService.createEditManager<Project>(this, 'Project');
        this._edm.pageState.canShowMessage = false; // no page state messages.

        // setTimeout needed because of Angular RC5 bug where Location is not set correctly until
        // after timeout resolves.
        setTimeout(() => {
            const returned = LocationFns.updatePageState(this._location, this._stateMap, this._pageState, (state: PageStateExt) => {
                this._pageState.isLoading = true;
                this.getProjects().then((projects) => {
                    const project = projects.find(p => p.projectId == state.projectId);
                    if (project) {
                        this.editProject(project);
                    }
                });
            });
            if (!returned) {
                this._edm.editing = false;
            }
        }, 0);
    }

    getProjects() {
        if (this._projects) {
            return Promise.resolve(this._projects);
        }
        this._pageState.isLoading = true;
        return this._uow.projectRepository.all().then(r => {
            this._pageState.isLoaded = r;
            this._projects = r;
            this._maxProjectId = _.maxBy(r, item => item.projectId).projectId;
            return r;
        });
    }

    canDeactivate() {
        return !this._edm.hasEdits();
    }

    get entity() {
        return this._edm.currentEntity;
    }

    // Not really needed but in case this editor is ever used by non-staff.
    isStaff() {
        return this._stateMap.currentUser.isStaff;
    }


    onEdit() {
        UtilFns.showModal(this._projectSelectorComponent, this, null).then((p: Project) => {
            if (!p) {
                return;
            }
            this.editProject(p);
        });
    }

    editProject(p: Project) {
        if (p == null) {
            return;
        }
        this._adding = false;
        this._edm.onlyEntity = p;
        this._pageState.projectId = p.projectId;
        this._edm.editing = true;

        return p;
    }

    getError(propName: string) {
        const r = this.entity.getErrorFor(propName);
        return r;
    }


    // EditManager overrides

    onAddPromise() {

        return this.getProjects().then(() => {
            this._maxProjectId++;
            const project = this._edm.uow.projectFactory.create({ projectId: this._maxProjectId });
            this._adding = true;
            this._edm.onlyEntity = project;
            this._edm.editing = true;
        });
    }

    onDelete() {
        return this._stateMap.yesNoComponent.showModal('Delete entire Project',
            'Are you sure?').then(ok => {
            if (ok) {
                this.deleteProject();
            }
        });
    }

    canSave() {
        if (!this._edm.hasEdits()) {
            return false;
        }
        // this._edm.canSaveCore does not include next line.
        if (!this._edm.validateBeforeSave()) {
            return false;
        }

        return true;
    }

    onSave() {
        this._edm.onSaveCore().then(() => {
            this._pageState.projectId = this._edm.currentEntity.projectId;
            // refreshes the list of projects.
            this._projectSelectorComponent.refresh();
            this._adding = false;
        });
    }

    deleteProject() {
        const entitiesToSave = [this.entity];
        this._edm.onDeleteCore();

        return this._uow.commitSelected(entitiesToSave)
            .then(() => {
                this._edm.setSavedStatus('Deleted');
                // Next line is needed because the onDeleteCore above will have modified all
                // entities in cache that ref'd this material.
                this._uow.rollback();
                this._projectSelectorComponent.refresh();
            })
            .catch((e) => {
                this._edm.setSaveFailedStatus('Delete failed: ' + e);
                this._uow.rollback();
            });

    }

}

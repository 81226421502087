<div class="subtitle-margin-top">
    <div *ngIf="_edm.editing" class="inline">
        <p class="inline bold">{{_adding ? "Adding a Material to the Exposure Survey List" : "Editing a Material from the Exposure Survey List" }}</p>
        <img style="margin-left: 10px; height: 42px; width: 26px;" class="inline" src="assets/images/ExposureSurveyMaterial.png" />
    </div>
</div>

<div class="row">
    <i *ngIf="isLoading" class="fa fa-circle-o-notch fa-spin" style="font-size:38px; color: #337ab7;"></i>
</div>

<div style="margin-top: -2px;">
    <editor-header [edm]="_edm" [customButtons]="true">
        <span customButtons>
            <button *ngIf="!_edm.editing" type="button" class="btn btn-sm btn-primary" [disabled]="!canSave()" (click)="onSave()">Save</button>
            <button *ngIf="_edm.editing" type="button" class="btn btn-sm btn-primary" [disabled]="!canApply()" (click)="onApply()">Apply</button>
            <button *ngIf="canCancel()" type="button" class="btn btn-sm btn-primary" (click)="onCancel()">Cancel</button>
            <button *ngIf="canShowBack()" type="button" class="btn btn-sm btn-primary" (click)="onBack()">Back</button>
            <button type="button" *ngIf="canAddToMaterialList()" class="inline btn btn-survey-material" style="width:150px;" (click)="onAdd()">Add Survey Material</button>
            <span *ngIf="_validationMessage" class="alert-danger">{{_validationMessage}}</span>
        </span>
    </editor-header>
</div>

<div *ngIf="!_edm.editing">
    <div style="width: 970px; margin-top: 12px; padding-bottom: 18px;">
        <div *ngIf="_materialExposureSurveysEx == null || _materialExposureSurveysEx.length < 1">
            <h5>No RIFM Materials have been added yet.</h5>
        </div>

        <div *ngIf="hasData()" style="margin-top: -6px;">
            <div class="flex-container" style="width: 1158px; background-color: #E7E7E7">
                <div class="flex-item vertical-middle" style="width: 500px;">
                    <p class="bold">RIFM Materials Added to the Material List</p>
                </div>
                <div class="flex-item vertical-top" style="width: 580px;">
                    <div style="margin-top: -4px;">
                        <span class="block italic gray-text bold left-indent" autocomplete="filterformaterial" style="font-size: 11px; ">Filter list by CAS No. or MaterialId:</span>

                        <input type="text" class="form-control inline" style="width:120px; margin-left: 10px; padding-top: 3px; padding-bottom: 3px;"
                               [(ngModel)]="_materialFilter"
                               id="materialFilter"
                               name="materialFilter"
                               autocomplete="materialfilter" type="search" (input)="onEnterMaterialFilter()"
                        />
                        <button
                                class="btn btn-sm btn-primary left-indent vertical-middle"
                                style="border-radius: 5px; height: 35px; margin-left: 10px;"
                                [disabled]="!canFilterMaterialList()"
                                (click)="onFilterDisplayedMaterials()">
                            Filter the List
                        </button>
                        <button
                                type="button"
                                class="btn btn-clear-material-filter left-indent vertical-middle"
                                style="border-radius: 5px; margin-left: 10px;"
                                [disabled]="!_canClearMaterialFilter"
                                (click)="onClearFilter()">
                            Clear Filter
                        </button>
                    </div>
                </div>
            </div>
        </div>

            <div style="width: 1465px; margin-top: -4px;">
                <div class="scrollableTableHeader">
                    <div class="scrollableTableColumn text-center"><span class="inline" style="width: 45px">Edit</span></div>
                    <div class="scrollableTableColumn text-center"><span class="inline" style="width: 55px">Remove</span></div>
                    <div class="scrollableTableColumn text-center"><span class="inline" style="width: 95px">CAS No.</span></div>
                    <div class="scrollableTableColumn text-center"><span class="inline" style="width: 85px">RIFM Id</span></div>
                    <div class="scrollableTableColumn text-center"><span class="inline" style="width: 75px">MaterialId</span></div>
                    <div class="scrollableTableColumn text-left"><span class="inline" style="width: 260px">Synonym</span></div>
                    <div class="scrollableTableColumn text-left"><span class="inline" style="width: 150px">Isomers</span></div>
                    <div class="scrollableTableColumn text-center"><span class="inline" style="width: 105px">Response Type</span></div>
                    <div class="scrollableTableColumn text-center"><span class="inline" style="width: 90px">Add Measures</span></div>
                </div>
                <div style="max-height: 195px; width: 1170px; overflow-y:auto; overflow-x:hidden; border: 1px solid gray;">
                    <div class="scrollableTableRow" *ngFor="let mes of _materialExposureSurveysEx"
                         (click)="onShowMaterialExposureSurveyNotes(mes)"
                         [ngStyle]="{ 'background-color': mes.backgroundColor }">
                        <div class="scrollableTableColumn text-center"><a class="inline" style="width: 43px" (click)="onEdit(mes)">Edit</a></div>
                        <div class="scrollableTableColumn text-center"><a class="inline" style="width: 55px" (click)="onRemove(mes)">Remove</a></div>
                        <div class="scrollableTableColumn text-center"><a class="inline" style="width: 95px" (click)="onShowMaterial(mes)">{{mes.data.material?.realCASNumber || '(No CAS#)'}}</a></div>
                        <div class="scrollableTableColumn text-center"><span class="inline" style="width: 85px">{{mes.data.material?.formattedRIFMId}}</span></div>
                        <div class="scrollableTableColumn text-center"><span class="inline" style="width: 75px">{{mes.data.material?.materialId}}</span></div>
                        <div class="scrollableTableColumn text-left"><span class="inline" style="width: 260px" [htmlGreek]="mes.data.material?.principalSynonym().synonymWordOrWebVersion()"></span></div>
                        <div class="scrollableTableColumn text-center"><span class="inline" style="width:150px;">{{mes.data.isomers}}</span></div>
                        <div class="scrollableTableColumn text-center"><span class="inline" style="width: 105px;">{{mes.data.typeExposureSurveyResponse.responseType}}</span></div>
                        <div class="scrollableTableColumn text-center"><a class="inline" style="width: 90px" (click)="onShowMeasures(mes)">Measures</a></div>
                    </div>
                </div>
            </div>

            <div style="margin-top: 8px; border: 1px solid #e7e7e7;" *ngIf="_selectedMaterialExposureSurvey != null && isDeleted(_selectedMaterialExposureSurvey) == false">
                <div style="background: #F0F8FF; border: 1px solid #e7e7e7; margin-top: 6px; margin-bottom: 2px;">
                    <div>
                        <span class="inline text-bold text-left page-subtitle">Notes for Material: </span>
                        <span class="inline text-bold text-left page-subtitle">{{_selectedMaterialExposureSurvey.material?.realCASNumber}}</span>
                        <span class="inline text-bold text-left page-subtitle left-indent">{{'  RIFM ID: ' + _selectedMaterialExposureSurvey.material?.formattedRIFMId}}</span>
                    </div>
                </div>

                <div class="row">
                    <label class="bold col-md-3">Notes:</label>
                    <div class="col-md-5"><p>{{_selectedMaterialExposureSurvey.notes}}</p></div>
                </div>

                <div class="row">
                    <label class="bold col-md-3">Expected SA Completion:</label>
                    <div class="col-md-5"><span>{{_selectedMaterialExposureSurvey.expectedSACompletion}}</span></div>
                </div>

            </div>

    </div>

</div>

<div *ngIf="_edm.editing">
    <material-exposure-survey-editor #self [entity]="_edm.currentEntity"></material-exposure-survey-editor>
</div>

import { EntityBase } from './EntityBase';
import { Reference } from './Reference';
import { JournalReserve } from './JournalReserve';
import { JournalSubscription } from './JournalSubscription';

import {JournalReserveItem} from './projections/JournalReserveItem';
import {JournalSubscriptionItem} from './projections/JournalSubscriptionItem';

/// <code-import> Place custom imports between <code-import> tags


/// </code-import>

const _monthFullNames = [
   'January', 'February', 'March',
   'April', 'May', 'June', 'July',
   'August', 'September', 'October',
   'November', 'December'
];

export class Journal extends EntityBase {

   /// <code> Place custom code between <code> tags

   _reserves: JournalReserveItem[]           = [];
   _subscriptions: JournalSubscriptionItem[] = [];

   public get journalReserveItems(): JournalReserveItem[] {
      return this._reserves;
   }

   public get journalSubscriptionItems(): JournalSubscriptionItem[] {
      return this._subscriptions;
   }

   public get journalNameStartYearAndMonth(): string {
      if (this.journalNameStartYear == null) {
         return '';
      }

      const month = (this.journalNameStartMonth == null) ? '' : ', ' + _monthFullNames[this.journalNameStartMonth - 1];

      return (this.journalNameStartYear + month);
   }

   public get journalNameEndYearAndMonth(): string {
      if (this.journalNameEndYear == null) {
         return '';
      }

      const month = (this.journalNameEndMonth == null) ? '' : ', ' + _monthFullNames[this.journalNameEndMonth - 1];

      return (this.journalNameEndYear + month);
   }

   public get journalNameStartDateForSort(): Date {
      if (this.journalNameStartYear == null) {
         return null;
      }

      const m = (this.journalNameStartMonth != null) ? this.journalNameStartMonth : 1;

      return (this.formatDate(m, this.journalNameStartYear));
   }

   public get journalNameEndDateForSort(): Date {
      if (this.journalNameEndYear == null) {
         return null;
      }

      const m = (this.journalNameEndMonth != null) ? this.journalNameEndMonth : 1;

      return (this.formatDate(m, this.journalNameEndYear));
   }

   formatDate(month: number, year: number): Date {
      const s = '';
      const d = s.concat('' + month, '/', '01', '/', '' + year);
      return new Date(d);
   }

   prepareJournalData() {
      this._reserves       = [];
      this._subscriptions  = [];

      if (this.journalReserves != null) {
         this.journalReserves.forEach(reserve => {
            this._reserves.push(new JournalReserveItem(reserve.journalReserveId, this.journalName, reserve.journalYear, reserve.volume, reserve.issue, reserve.pages, reserve.notes));
         });
      }

      if (this.journalSubscriptions != null) {
         this.journalSubscriptions .forEach(sub => {
            // tslint:disable-next-line:max-line-length
            this._subscriptions.push(new JournalSubscriptionItem(sub.journalSubscriptionId, this.journalName, sub.typeJournalSubscription.description, sub.subscriptionStartMonth, sub.subscriptionStartYear, sub.subscriptionEndMonth, sub.subscriptionEndYear, sub.issuesPerYear, sub.department, sub.publisher, sub.notes));
         });
      }
   }

   public static compareJournalNames = function (a: Journal, b: Journal) {

      if (a.journalNameStartDateForSort != null && b.journalNameStartDateForSort != null) {
         if ( a.journalNameStartDateForSort > b.journalNameStartDateForSort) {
            return -1;
         } else if ( a.journalNameStartDateForSort < b.journalNameStartDateForSort) {
            return 1;
         }
      }

      if (a.journalName < b.journalName) {
         return -1;
      }
      if (a.journalName > b.journalName) {
         return 1;
      }
      return 0;
   };

   /// </code>

   // Generated code. Do not place code below this line.
   journalId: number;
   journalName: string;
   inHouse: boolean;
   notes: string;
   journalNameStartMonth: number;
   journalNameStartYear: number;
   journalNameEndMonth: number;
   journalNameEndYear: number;
   rootJournalId: number;
   created: Date;
   createdUser: string;
   modified: Date;
   modifyUser: string;
   rowVersion: number;
   references: Reference[];
   journalReserves: JournalReserve[];
   journalSubscriptions: JournalSubscription[];
}


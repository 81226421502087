import {Component, ElementRef, EventEmitter, forwardRef, Inject, Input, Output, OnInit, ViewChild} from '@angular/core';
import { Location } from '@angular/common';

import { Router } from '@angular/router';
import { Entity } from 'breeze-client';
import { EditorService, ErrorLogger, provideParent, StateMap, UnitOfWork } from '../../services/common';
import { EditPropParent, PageState } from '../../controls/common';

import {Journal} from '../../entities/EntityModels';

import { JournalEditData, JournalsComponent} from './journals.component';
import { JournalSelectorComponent } from './journal-selector.component';
import {UtilFns} from '../../utils/util-fns';
import {EditManager} from '../../utils/edit-manager';

import * as pluralize from 'pluralize';
import * as _ from 'lodash';

class PageStateExt extends PageState {
    journalId: number;
}

@Component({
    selector: 'journal-editor',
    templateUrl: './journal-editor.html',
    providers: [provideParent(JournalEditorComponent, EditPropParent)]
})
export class JournalEditorComponent implements OnInit, EditPropParent {
    @ViewChild(JournalSelectorComponent, { static: true }) _journalSelectorComponent: JournalSelectorComponent;

    @Output() stateChange = new EventEmitter<string>();

    _data: JournalEditData;
    _edm: EditManager<Journal>;
    _edmNextJournalName: EditManager<Journal>;  // only used if journal is deleted

    _previousJournal: Journal;
    _nextJournal: Journal;

    _pageState = new PageStateExt('Journal editor');
    _validationMessage: string;

    _hasBeenDeleted = false;
    _hasJournalNameCollision = false;
    _journalNameCollisionDesc: string;

    _journalNameStartYear: string;
    _journalNameEndYear: string;
    _selectedNameStartMonthIndex    = 0;
    _selectedNameEndMonthIndex      = 0;

    _dateErrMsg = 'The Journal Name Start Date is equal to or later than the Journal Name End Date.';

    public _monthNames = [{ month: 'None' }, { month: 'January' }, { month: 'February' }, { month: 'March' },
        { month: 'April' }, { month: 'May' }, { month: 'June' },
        { month: 'July' }, { month: 'August' }, { month: 'September' },
        { month: 'October' }, { month: 'November' }, { month: 'December' }];

    constructor(@Inject(forwardRef(() => JournalsComponent)) public _parent: JournalsComponent,
                public _uow: UnitOfWork, public _stateMap: StateMap, public _editorService: EditorService,
                public _location: Location, public _errorLogger: ErrorLogger, public _router: Router) {

        this._data = _parent._data;
    }

    ngOnInit() {
        this._edm                   = new EditManager<Journal>(this, this._uow, this._stateMap, 'Journal');
        this._edmNextJournalName    = new EditManager<Journal>(this, this._uow, this._stateMap, 'Journal');

        if (this._data == null) {
            return;
        }

        if (this._data.adding) {
            const j = this._edm.uow.journalFactory.create();
            this._data.entity = j;
            this._edm.onlyEntity = this._data.entity;
            this._edm.editing    = true;

        } else {
            this._edm.onlyEntity = this._data.entity;
            this._edm.editing    = true;

            this._journalNameStartYear          = (this._edm.currentEntity.journalNameStartYear != null) ? this._edm.currentEntity.journalNameStartYear.toString() : null;
            this._journalNameEndYear            = (this._edm.currentEntity.journalNameEndYear != null) ? this._edm.currentEntity.journalNameEndYear.toString() : null;
            this._selectedNameStartMonthIndex   = (this._edm.currentEntity.journalNameStartMonth != null) ? this._edm.currentEntity.journalNameStartMonth : 0;
            this._selectedNameEndMonthIndex     = (this._edm.currentEntity.journalNameEndMonth != null) ? this._edm.currentEntity.journalNameEndMonth : 0;

            return this.fetchJournalWithPreviousName().then(r => {
                // if the journal is to be deleted, remove references to the journal in the rootjournalid of the subsequent journal name
                if (this._data.deleting) {
                    return this.fetchJournalNextName().then( n => {
                        if (this._nextJournal != null) {
                            this._edmNextJournalName.onlyEntity = this._nextJournal;
                            this._edmNextJournalName.editing    = true;
                            this._edmNextJournalName.currentEntity.rootJournalId = null;
                        }
                    });
                }
                return;
            });
        }
    }

    get journal() {
        return this._edm.currentEntity;
    }

    fetchJournalNextName() {

        this._nextJournal = null;

        const params = {
            journalId: this._data.entity.journalId,
        };

        return this._uow.fetch('Misc/FetchJournalWithNextName', params).then(r => {
            if (r != null && r.length > 0) {
                this._nextJournal = r[0];
            }
        });
    }

    fetchJournalWithPreviousName() {

        this._previousJournal = null;

        if (this._data.entity.rootJournalId == null) {
            return Promise.resolve(null);
        }

        const params = {
            journalId: this._data.entity.journalId,
            rootJournalId: this._data.entity.rootJournalId
        };

        return this._uow.fetch('Misc/FetchJournalWithPreviousName', params).then(r => {
            if (r != null && r.length > 0) {
                this._previousJournal = r[0];
            }
        });
    }

    onSearchForRelatedJournal() {
        const invalidFormerJournal = 'The selected former journal is the same as the current journal. Please select another.';

        this._previousJournal = null;
        if (this._validationMessage) {
            if (this._validationMessage == invalidFormerJournal || this._validationMessage.indexOf('is already marked as the former journal name of') != -1) {
                this._validationMessage = '';
            }
        }

        this._journalSelectorComponent.setShowJournalData(true);
        UtilFns.showModal(this._journalSelectorComponent, this, null).then((j: Journal) => {
            if (!j) {
                return;
            }

            if (this._data.entity.journalId == j.journalId) {
                this._validationMessage = invalidFormerJournal;
            } else if (this._data.entity.journalId == j.rootJournalId) {
                this._validationMessage = 'The journal ' + this._data.entity.journalName + ' is already marked as the former journal name of ' + j.journalName;
            }
            else {
                this._previousJournal = j;
                this._data.entity.rootJournalId = this._previousJournal.journalId;
            }
        });
    }

    removeRelatedJournal() {
        if (this._data.entity == null) {
            return;
        }
        this._data.entity.rootJournalId = null;
        this._previousJournal = null;
    }

    // Not really needed but in case this editor is ever used by non-staff.
    isStaff() {
        return this._stateMap.currentUser.isStaff;
    }

    // dates
    onChangeJournalNameStartYear(yearString: string) {
        this._validationMessage = '';

        if (this.isStringNullOrEmpty(yearString)) {
            this._edm.currentEntity.journalNameStartYear    = null;
            this._edm.currentEntity.journalNameStartMonth   = null; // a year value is required for a month value
            this._journalNameStartYear = null;
            return;
        }

        if (this.isValidYear(yearString)) {
            this._edm.currentEntity.journalNameStartYear = parseInt(yearString, 10);
            this._journalNameStartYear = parseInt(yearString, 10).toString();
        } else {
            this._validationMessage = 'An incorrect Year has been entered in the Journal Name Start Year field.';
        }
    }

    onChangeJournalNameEndYear(yearString: string) {
        this._validationMessage = '';

        if (this.isStringNullOrEmpty(yearString)) {
            this._edm.currentEntity.journalNameEndYear  = null;
            this._edm.currentEntity.journalNameEndMonth = null;  // a year value is required for a month value
            this._journalNameEndYear = null;
            return;
        }

        if (this.isValidYear(yearString)) {
            this._edm.currentEntity.journalNameEndYear = parseInt(yearString, 10);
            this._journalNameEndYear = parseInt(yearString, 10).toString();
        } else {
            this._validationMessage = 'An incorrect Year has been entered in the Journal Name End Year field.';
        }
    }

    checkDate(dateString: string): boolean {
        const d = Date.parse(dateString);

        return (!isNaN(d));
    }

    onSelectNameStartMonth(monthidx: number) {
        this._selectedNameStartMonthIndex = monthidx;
        this._edm.currentEntity.journalNameStartMonth = (monthidx > 0) ? monthidx : null;

        if (this._validationMessage == this._dateErrMsg) {
            this._validationMessage = '';
        }
        this.hasValidJournalNameDates();
    }

    onSelectNameEndMonth(monthidx: number) {
        this._selectedNameEndMonthIndex = monthidx;
        this._edm.currentEntity.journalNameEndMonth = (monthidx > 0) ? monthidx : null;

        if (this._validationMessage == this._dateErrMsg) {
            this._validationMessage = '';
        }
        this.hasValidJournalNameDates();
    }

    isStringNullOrEmpty(s: string): boolean {
        return (s == null || s.trim() === '');
    }

    isValidYear(yearString: string) {
        const year = parseInt(yearString, 10);
        return !isNaN(year) && year > 1900 && year < 2100;
    }

    pageTitle() {
        return (this._data.adding ? 'Adding a Journal' : this._data.deleting ? 'Deleting Journal' : 'Editing Journal');
    }

    // *******************************************************
    // edit interactions
    // *******************************************************
    canApply() {

        if (this._data == null) {
            return false;
        }

        if (this._data.deleting) {
            return false;
        }

        if (!this.hasValidJournalNameDates()) {
            return false;
        }

        if (this._edm.editing && this._uow.hasChanges()) {
            if (this._edm.currentEntity != null) {
                return (!this._edm.currentEntity.entityAspect.hasValidationErrors);
            }
            return true;
        }
        return false;
    }

    onApply() {
        this._validationMessage = '';

        this.isDuplicateJournalName().then( r => {
            if (!this._hasJournalNameCollision) {
                if (this._edm.editing) {
                    this._edm.onApplyCore();
                }
            }
        });
        return true;
    }

    canShowBack() {
        if (this._data.deleting) {
            return false;
        }

        return (!this._uow.hasChanges());
    }

    onBack() {
        this._data.adding       = false;
        this._edm.editing       = false;
        this._data.inSubEditor  = false;

        this.stateChange.emit('Back');
    }

    canCancel() {
        if (this._data.deleting) {
            return true;
        }

        return (this._uow.hasChanges());
    }

    onCancel() {
        this._validationMessage = '';

        this._uow.rollback();

        this._edm.clearErrors();
        this._edm.editing = false;
        this._edm.pageState.loadStatusMsg = null;

        this._edmNextJournalName.clearErrors();
        this._edmNextJournalName.editing = false;

        const data          = this._data;
        data.inSubEditor    = false;

        this.stateChange.emit('Cancel');
    }

    canDelete() {

        if (this._hasBeenDeleted) {
            return false;
        }

        if (this._data == null || this._edm == null) {
            return false;
        }

        return (this._data.deleting && this._edm.currentEntity != null);
    }

    onDelete() {
        return this._stateMap.yesNoComponent.showModal('Delete entire Journal',
            'Are you sure you want to delete this Journal?').then(ok => {
            if (ok) {

                if (this._data.entity.entityAspect.entityState.isAdded()) {
                    this._data.entity.entityAspect.rejectChanges();
                } else {
                    this._data.entity.entityAspect.setDeleted();
                }

                this._edmNextJournalName.onApplyCore();

                if (this._edmNextJournalName.currentEntity != null) {
                    this._edmNextJournalName.onApplyCore();
                }

                this._hasBeenDeleted = true;
            }
        });
    }

    canSave(): boolean {
        if (this._hasBeenDeleted) { return true; }
        if (this._edm.editing) { return false; }
        if (!this._edm.hasEdits()) { return false; }
        if (!this._edm.validateBeforeSave()) { return false; }

        return true;
    }

    onSave() {
        // journal
        if (this._edm.hasEdits()) {
            this._edm.onSaveCore().then(() => {
                this._edm.editing   = false;

                const data          = this._data;
                data.inSubEditor    = false;
                if (data.adding) {
                    data.entity         = this._edm.currentEntity;
                    data.journalId      = this._edm.currentEntity.journalId;
                }

                if (this._hasBeenDeleted) {
                    this._edmNextJournalName.editing = false;
                    this.stateChange.emit('Delete');
                } else {
                    this.stateChange.emit('Save');
                }
            });
        }
    }

    hasRelatedJournalName() {
        if (this.journal == null) {
            return false;
        }

        return (this.journal.rootJournalId != null && this.journal.rootJournalId != this.journal.journalId);
    }

    isDuplicateJournalName(): Promise<boolean> {
        if (this.journal == null) {
            return Promise.resolve(false);
        }
        this._hasJournalNameCollision = false;
        this._journalNameCollisionDesc = '';

        const params = { name: UtilFns.encodeHackForAnd(this.journal.journalName), journalId: this.journal.journalId };
        return this._uow.fetchTyped('Misc/JournalMatchByRootName', Journal, params).then(r => {
            if (r && r.length > 0) {
                this._hasJournalNameCollision = true;
                if (this.journal.journalName == r[0].journalName) {
                    this._journalNameCollisionDesc = 'A journal with the same name already exists.';
                } else {
                    this._journalNameCollisionDesc = 'A journal name that differs only by spaces and case exists.';
                }
            }
            return this._hasJournalNameCollision;
        });
    }

    getError(propName: string) {
        if (this._edm == null || this._edm.currentEntity == null) {
            return;
        }

        const r = this._edm.currentEntity.getErrorFor(propName);
        return r;
    }

    hasValidJournalNameDates() {
        if (this._edm.currentEntity.journalNameStartYear == null || this._edm.currentEntity.journalNameEndYear == null) {
            return true;
        }

        if (this._edm.currentEntity.journalNameStartDateForSort >= this._edm.currentEntity.journalNameEndDateForSort) {
            this._validationMessage = 'The Journal Name Start Date is equal to or later than the Journal Name End Date.';
            return false;
        }

        return true;
    }
}

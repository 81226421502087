import { Component, Input, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import * as _ from 'lodash';

import { StateMap, UnitOfWork } from '../../services/common';
import { LocationFns, UtilFns } from '../../utils/common';
import { PageState } from '../../controls/common';

import { SpecialLink } from '../../entities/SpecialLink';

import { ROUTES } from '../routes';

@Component({
    selector: 'tox-data-other',
    templateUrl: './tox-data-other.html',
})
export class ToxDataOtherReferencesComponent implements OnInit {
    @Input() materialIds: number[];
    @Input() tabPath: string;
    _specialLinks: SpecialLink[];
    _groupedLinks: { key: string, specialLinks: SpecialLink[] } [];

    _pageState = new PageState('Non-tox references');

    constructor(public _uow: UnitOfWork, public _stateMap: StateMap, public _location: Location,
                public _router: Router) {

    }

    ngOnInit() {
        LocationFns.setTab(this._location, this.tabPath);

        const p = {materialIds: this.materialIds};
        this._pageState.isLoading = true;
        this._uow.specialLinkMaterialRepository.whereWithParams(p).then(r => {
            this._specialLinks = r;
            const groups = _.groupBy(r, x => x.explanation());
            const groupedLinks = _.keys(groups).map(k => {
                return {key: k, specialLinks: groups[k]};
            });
            this._groupedLinks = _.sortBy(groupedLinks, g => g.key);
            this._pageState.isLoaded = r;
        }).catch(() => {
            this._pageState.isLoadedWithNoData = true;
        });

    }

    isStaff() {
        return this._stateMap.currentUser.isStaff;
    }

    onReferenceSelect(specialLink: SpecialLink) {
        this._router.navigate(UtilFns.asRouterParts(ROUTES.Reference, specialLink.referenceId));
    }


}

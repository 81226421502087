<div id="SynopsisReport" class="modal fade bs-example-modal-lg" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel">
    <div class="modal-dialog modal-extra-lg">
        <div class="modal-content">
            <div class="modal-header custom-header">
                <span class="modal-title" id="myModalLabel">Synopis Report</span>
                <!--<span style="align: right"><i>Generated on: {{ _reportDate | date }} </i></span>-->
                <span style="align: right"><i>Generated on: {{ _stateMap.utilFns.formatDate(_reportDate) }} </i></span>
                <span class="no-print">
                    <button type="button" class="btn btn-default pull-right text-uppercase text-bold" data-dismiss="modal"><span class="fa fa-times"></span>                Close</button>
                    <button (click)="printReport()" type="button" class="btn btn-default pull-right text-uppercase text-bold"><span class="fa fa-print"></span> Print</button>
                    <button (click)="showPrintToPdfDoc()" type="button" class="btn btn-default pull-right text-uppercase text-bold"><span class="fa"></span> How to print to PDF</button>
                </span>
            </div>
            <div *ngIf="_material" class="modal-body custom-body indent-left-sm">

                <h1 [htmlGreek]="_material.principalSynonym()?.synonymWordOrWebVersion()"></h1>

                <table class="table table-grid">
                    <tr >
                        <th>Synonyms</th>
                        <th>Use</th>
                    </tr>
                    <tr *ngFor="let s of _material.synonyms" >
                        <td [htmlGreek]="s.synonymWordOrWebVersion()"></td>
                        <td>{{s.useList}}</td>
                    </tr>
                </table>

                
                <table class="table table-grid">                  
                    <tr>
                        <th class="bold">CAS No.</th>
                        <th class="bold">RIFM ID</th>
                        <th class="bold">FEMA</th>
                        <th class="bold">EC No./EINECS/ELINCS</th>
                        <th>METI/ENCS</th>
                        <th>ECL</th>
                        <th class="bold">Registration</th>
                    </tr>
                    <tr>
                        <td>{{_material.realCASNumber || '(No CAS#)'}}</td>
                        <td>{{_material.formattedRIFMId}}</td>
                        <td>{{_material.fEMANumber}}</td>
                        <td>{{_material.eCInventoryEINECSELINCSNumbers()}}</td>
                        <td>{{_material.mETIENCS()}}</td>
                        <td>{{_material.eCL}}</td>
                        <td>{{_material.registrationText()}}</td>
                    </tr>
                </table>

                <div *ngIf="_matIds && _matIds.length > 0">
                    <span class="synopsis-section-title">Alternative Identifiers</span>
                    <table class="table table-grid">
                        <tbody>
                            <tr>
                                <th class="bold">Alt. CAS No.</th>
                                <th class="bold">EC No.</th>
                                <th class="bold">ECL</th>
                                <th class="bold">EINECS</th>
                                <th class="bold">Registration</th>
                            </tr>
                            <tr *ngFor="let m of _matIds">
                                <td><span>{{m.cASNumber}}</span>
                                <td><span>{{m.eCInventoryNumber}}</span>
                                <td><span>{{m.eCL}}</span>
                                <td><span>{{m.eINECSNumber}}</span>
                                <td>
                                    <span *ngIf="m.dSLRegistration">DSL</span>
                                    <span *ngIf="m.eCNRegistration">ECN</span>
                                    <span *ngIf="m.eINECSRegistration">EINECS</span>
                                    <span *ngIf="m.nDSLRegistration">NDSL</span>
                                    <span *ngIf="m.reachRegistration">REACH</span>
                                    <span *ngIf="m.tSCARegistration">TSCA</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div>
                    <span><strong>RIFM Monograph:</strong></span>
                    <span>{{_material.monographReference}}</span>
                </div>

                <div>
                    <img class="img-structure" [src]="getSmileUrl(_material)" alt="[Structure Image not available]" onerror="this.style.display='none'"/>
                </div>

                <table>
                    <tr>
                        <td><strong>Formula:</strong></td>
                        <td [chemFormula]='_material.formula'></td>
                        <!--<td>{{_material.formula}}</td>-->
                    </tr>
                    <tr>
                        <td><strong>Structure:</strong></td>
                        <td>{{_material.structure}}</td>
                    </tr>
                    <tr>
                        <td><strong>Weight:</strong></td>
                        <td>{{_material.molecularWeightWithUnit}}</td>
                    </tr>
                    <tr>
                        <td><strong>Smiles:</strong></td>
                        <td>{{_material.smiles}}</td>
                    </tr>
                    <tr>
                        <td><strong>Generic Class:</strong></td>
                        <td>{{_material.typeTscaClass?.genericClass}}</td>
                    </tr>
                    <tr>
                        <td><strong>Description:</strong></td>
                        <td>{{_material.materialDescription}}</td>
                    </tr>
                </table>

                <h2>Physical Data</h2>

                <table class="table table-grid">                  
                    <tr>
                        <th class="vertical-top">Procedure/Method</th>
                        <th class="vertical-top">Observed Result</th>
                        <th class="vertical-top">Source</th>
                        <th class="vertical-top">Sample</th>
                    </tr>
                    <tr *ngFor="let a of _material.analyticalResults">
                        <td class="vertical-top" [htmlGreek]="a.typeAnalyticalProcedure?.analyticalProcedure"></td>
                        <td class="vertical-top" [htmlGreek]="a.observedResult"></td>
                        <td class="vertical-top">{{a.analyticalSource}}</td>
                        <td class="break-word vertical-top" style="max-width: 440px;">{{a.analyticalSample}}</td>
                    </tr>
                </table>

                <div *ngIf="_flavorConsumptions && _flavorConsumptions.length">

                    <h2>Flavor Consumption (in kg)</h2>
                    <table class="table table-grid">
                        <tr>
                            <th>Year</th>
                            <th>Geography</th>
                            <th>Consumed</th>
                        </tr>
                        <tr *ngFor="let fc of _flavorConsumptions">
                            <td>{{fc.survey?.surveyYear}}</td>
                            <td>{{fc.survey?.typeGeographicalArea?.geographicalArea}}</td>
                            <td>{{fc.kilogramsConsumed}}</td>
                        </tr>
                    </table>
                </div>

                <div *ngIf="_uses && _uses.length">
                    <h2>Uses</h2>
                    <table class="table table-grid">
                        <tr>
                            <th>Product</th>
                            <th>AverageUsual (ppm)</th>
                            <th>AverageMaximum (ppm)</th>
                            <th>MeanDailyConsumption (gms)</th>
                            <th>Updated</th>
                        </tr>
                        <tr *ngFor="let u of _uses">
                            <td>{{u.typeFoodCategory?.foodDescription}}</td>
                            <td>{{u.averageUsual}}</td>
                            <td>{{u.averageMaximum}}</td>
                            <td>{{u.typeFoodCategory?.meanDailyConsumption}}</td>
                            <!--<td>{{u.modified | date}}</td>-->
                            <td>{{ _stateMap.utilFns.formatDate(u.modified) }}</td>
                        </tr>
                    </table>
                </div>

                <div *ngIf="_flavorStatuses && _flavorStatuses.length">
                    <h2>Flavor Status</h2>
                    <div *ngFor="let st of _flavorStatuses" class="block">
                        <span><strong>{{st.agencyTitle()}}: </strong></span>
                        <span>{{st.prefixedDetermination()}} {{st.linkDisplay}}</span>
                    </div>
                </div>

                <div *ngIf="_ifraStatuses && _ifraStatuses.length">
                    <h2>IFRA Status</h2>
                    <div *ngFor="let st of _ifraStatuses" class="div-horizontal">
                        <span><strong>{{st.agencyTitle()}}: </strong></span>
                        <span>{{st.prefixedDetermination()}} {{st.linkDisplay}}</span>
                    </div>
                </div>

                <div *ngIf="_occupationalStatuses && _occupationalStatuses.length">
                    <h2>Occupational Status</h2>
                    <div *ngFor="let st of _occupationalStatuses" class="div-horizontal">
                        <span><strong>{{st.agencyTitle()}}: </strong></span>
                        <span>{{st.prefixedDetermination()}} {{st.linkDisplay}}</span>
                    </div>
                </div>

                <div *ngIf="_hhExpMats && _hhExpMats.length > 0">
                    <h2>Human Health Data</h2>
                    <div *ngFor="let em of _hhExpMats">
                        <h3>{{em.studyLong()}}</h3>
                        <div class="block">
                            <span style="display:block"><strong>Study:</strong> {{em.studyLong()}}</span>
                            <span class="small bold" *ngFor="let stDescr of em?.studySubTypeDescriptions()">
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{stDescr}}<br>
                            </span>
                            <span style="display:block"><strong>Route:</strong> <span [htmlGreek]="em.routeLong()"></span></span>
                            <span style="display:block"><strong>Species:</strong> <span [htmlGreek]="em.speciesLong()"></span></span>
                            <span style="display:block"><strong>Protocol:</strong> {{em.biologicalData?.protocol || '-'}}</span>
                            <span *ngIf="em.biologicalData?.comment && em.biologicalData?.comment.trim().length > 0 " style="display:block"><strong>Summary:</strong> {{em.biologicalData?.comment}}</span>
                            <span *ngIf="em.biologicalData?.anySubjects()" style="display:block">
                                <strong>Subjects:</strong>
                                <label>M:</label>
                                <span>{{em.biologicalData?.subjectsMale}}</span>
                                <label>F:</label>
                                <span>{{em.biologicalData?.subjectsFemale}}</span>
                                <label>Uns:</label>
                                <span>{{em.biologicalData?.subjectsUnspecifiedSex}}</span>
                            </span>
                            <div>
                                <span *ngIf="em.biologicalData?.studyLength != 0 || em.biologicalData?.typeTimeUnitId.toLowerCase() != 'none'" class="inline"><strong>Study Length:</strong></span>
                                <span *ngIf="em.biologicalData?.studyLength != 0" class="inline">{{em.biologicalData?.studyLength}}</span>
                                <span *ngIf="em.biologicalData?.typeTimeUnitId.toLowerCase() != 'none'" class="inline">{{em.biologicalData?.typeTimeUnitId}}</span>
                            </div>
                            <span *ngIf="em.biologicalData?.compliance" style="display:block"><strong>Compliance:</strong> {{em.biologicalData?.compliance}}</span>
                            <span *ngIf="em.experimentalDetails" style="display:block"><strong>Details:</strong> {{em.experimentalDetails}}</span>
                            <span *ngIf="em.fFDSStatement" style="display:block"><strong>FFDS Statement:</strong> {{em.fFDSStatement}}</span>
                            <span *ngIf="em.sampleText" style="display:block"><strong>Sample:</strong> {{em.sampleText}}</span>
                            <span *ngIf="em != null && em.showExperimentalMaterialVehicle()" style="display:block"><strong>Vehicle:</strong> {{em.typeExperimentVehicle?.vehicle}}</span>
                            <span *ngIf="em.typeToxicEffectId || em.calculatedResult" style="display:block"><strong>Result Type:</strong> {{em.calculatedResultTypeAndValue}}</span>
                            <span *ngIf="isStaff() && em.typeStudyMixture" style="display:block"><strong>Contains Mixture:</strong> {{em.typeStudyMixture?.description}}</span>
                            <br />
                            <div *ngFor="let er of em.experimentalResults">
                                <div class="panel panel-default" style="margin-right: 30px">
                                    <div class="panel-body">
                                        <span style="display:block"><strong>Dose:</strong> {{er.formattedDose()}} <strong>Effects:</strong> {{er.toxicEffects()}}</span>
                                        <span *ngIf="er.resultNote" style="display:block"><strong>Results:</strong> {{er.resultNote}}</span>
                                        <span *ngIf="er.typeExperimentVehicleId != 'NONE'" style="display:block"><strong>Vehicle:</strong> {{er.typeExperimentVehicle?.vehicle}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style="display:inline;">
                            <span><strong>Reference:</strong> {{em.biologicalData?.reference?.description(isStaff())}}</span>
                            <span *ngIf="em.biologicalData?.subReference"><strong>Sub-Reference:</strong>  {{em.biologicalData.subReference}}</span>
                            <span class="in-progress-text"> {{em.biologicalData?.reference?.finishedText()}}</span>
                        </div>
                    </div>
                </div>

                <div *ngIf="_envExpMats && _envExpMats.length > 0">
                    <h2>Environmental Data</h2>
                    <div *ngFor="let em of _envExpMats">
                        <h3>{{em.studyLong()}}</h3>
                        <div class="block">
                            <span style="display:block"><strong>Study:</strong> {{em.studyLong()}}</span>
                            <span style="display:block"><strong>Route:</strong> <span [htmlGreek]="em.routeLong()"></span></span>
                            <span style="display:block"><strong>Species:</strong> <span [htmlGreek]="em.speciesLong()"></span></span>
                            <span style="display:block"><strong>Protocol:</strong> {{em.biologicalData?.protocol || '-'}}</span>
                            <span *ngIf="em.biologicalData?.comment && em.biologicalData?.comment.trim().length > 0 " style="display:block"><strong>Summary:</strong> {{em.biologicalData?.comment}}</span>
                            <span *ngIf="em.biologicalData?.anySubjects()" style="display:block">
                                <strong>Subjects:</strong>
                                <label>M:</label>
                                <span>{{em.biologicalData?.subjectsMale}}</span>
                                <label>F:</label>
                                <span>{{em.biologicalData?.subjectsFemale}}</span>
                                <label>Uns:</label>
                                <span>{{em.biologicalData?.subjectsUnspecifiedSex}}</span>
                            </span>
                            <div>
                                <span *ngIf="em.biologicalData?.studyLength != 0 || em.biologicalData?.typeTimeUnitId.toLowerCase() != 'none'" class="inline"><strong>Study Length:</strong></span>
                                <span *ngIf="em.biologicalData?.studyLength != 0" class="inline">{{em.biologicalData?.studyLength}}</span>
                                <span *ngIf="em.biologicalData?.typeTimeUnitId.toLowerCase() != 'none'" class="inline">{{em.biologicalData?.typeTimeUnitId}}</span>
                            </div>
                            <span *ngIf="em.biologicalData?.compliance" style="display:block"><strong>Compliance:</strong> {{em.biologicalData?.compliance}}</span>
                            <span *ngIf="em.experimentalDetails" style="display:block"><strong>Details:</strong> {{em.experimentalDetails}}</span>
                            <span *ngIf="em.fFDSStatement" style="display:block"><strong>FFDS Statement:</strong> {{em.fFDSStatement}}</span>
                            <span *ngIf="em.sampleText" style="display:block"><strong>Sample:</strong> {{em.sampleText}}</span>
                            <span *ngIf="em != null && em.showExperimentalMaterialVehicle()" style="display:block"><strong>Vehicle:</strong> {{em.typeExperimentVehicle?.vehicle}}</span>
                            <span *ngIf="em.typeToxicEffectId || em.calculatedResult" style="display:block"><strong>Result Type:</strong> {{em.calculatedResultTypeAndValue}}</span>
                            <span *ngIf="isStaff() && em.typeStudyMixture" style="display:block"><strong>Contains Mixture:</strong> {{em.typeStudyMixture?.description}}</span>
                            <br />
                            <div *ngFor="let er of em.experimentalResults">
                                <div class="panel panel-default" style="margin-right: 30px">
                                    <div class="panel-body">
                                        <span style="display:block"><strong>Dose:</strong> {{er.formattedDose()}} <strong>Effects:</strong> {{er.toxicEffects()}}</span>
                                        <span *ngIf="er.resultNote" style="display:block"><strong>Results:</strong> {{er.resultNote}}</span>
                                        <span *ngIf="er.typeExperimentVehicleId != 'NONE'" style="display:block"><strong>Vehicle:</strong> {{er.typeExperimentVehicle?.vehicle}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style="display:inline;">
                            <span><strong>Reference:</strong> {{em.biologicalData?.reference?.description(isStaff())}}</span>
                            <span *ngIf="em.biologicalData?.subReference"><strong>Sub-Reference:</strong>  {{em.biologicalData.subReference}}</span>
                            <span class="in-progress-text"> {{em.biologicalData?.reference?.finishedText()}}</span>
                        </div>
                    </div>
                </div>

                <div *ngIf="_refCitations && _refCitations.length">
                    <h2>References</h2>
                    <div *ngFor="let c of _refCitations" style="margin-bottom: 10px">
                        <span [innerHTML]="c"></span>
                    </div>
                </div>

            </div>
        </div>
        <div class="modal-footer border-zero no-print">
            <button (click)="printReport()" type="button" class="btn btn-default text-uppercase text-bold"><span class="fa fa-print"></span> Print</button>
            <button type="button" class="btn btn-default text-uppercase text-bold" data-dismiss="modal"><span class="fa fa-times"></span> Close</button>
        </div>
    </div>
</div>

<print-to-pdf-doc></print-to-pdf-doc>

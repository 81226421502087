<script type="text/javascript">
    $("img").one("error", function () {
        $(this).attr("src", "app/images/NoStructureAvailable.png");
    });
</script>
<style>
    #cy {
        width: 100%;
        height: 1000px;
        position: absolute;
        top: 0px;
        left: 0px;
        border: 1px solid #808080;
    }
</style>

<div id="graph-container">
    <div id="cy"></div>
</div>



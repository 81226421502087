import { Component } from '@angular/core';

@Component({
    selector: 'print-to-pdf-doc',
    templateUrl: './print-to-pdf-doc.html',

})
export class PrintToPdfDocComponent {

    showModal(): Promise<boolean> {
        const ele = PrintToPdfDocComponent.getModalEle();
        const p = new Promise((resolve) => {
            ele.modal({backdrop: 'static', keyboard: false}).on('hidden.bs.modal', () => {
                resolve(true);
            });
        });
        return <any>p;
    }

    static getModalEle(): any {
        return $('#dialog-root');
    }
}

import { Component, ElementRef, Input, OnInit } from '@angular/core';

import { UnitOfWork } from '../../services/common';
import { UtilFns } from '../../utils/common';
import { ModalDialog, PageState } from '../../controls/common';

import { TypeReferenceRelation } from '../../entities/EntityModels';

@Component({
    selector: 'type-reference-relation-selector',
    templateUrl: './type-reference-relation-selector.html',
})
export class TypeReferenceRelationSelectorComponent extends ModalDialog<TypeReferenceRelation> implements OnInit {
    @Input() typeReferenceRelations: TypeReferenceRelation[] = [];
    _searchTerm: string;
    _entities: TypeReferenceRelation[] = [];
    _pageState = new PageState('Reference Relation Types');

    constructor(elementRef: ElementRef, public _uow: UnitOfWork) {
        super(elementRef);
    }

    ngOnInit() {
        this._entities = this.typeReferenceRelations;
    }

    canSearch() {
        return true;
    }

    search() {
        this.validationMessage = null;

        this._entities = null;
        const term = (this._searchTerm || '').toLowerCase();
        this._entities = this.typeReferenceRelations.filter(f => f.label.toLowerCase().indexOf(term) > -1);
    }


    onSelect(typeReferenceRelation: TypeReferenceRelation) {
        UtilFns.returnModal(this, typeReferenceRelation);
    }


}


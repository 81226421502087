import { EntityBase } from './EntityBase';
import { Material } from './Material';
import { TypeRegulatoryStatus } from './TypeRegulatoryStatus';

import { Validator } from 'breeze-client';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

export class RegulatoryStatus extends EntityBase {

   /// <code> Place custom code between <code> tags
   // These methods allow RegulatoryStatus and FoodStatus to share the same interface.
    evaluatingAgency() {
        return (this.typeRegulatoryStatus != null) ? this.typeRegulatoryStatus.evaluatingAgencyLabel : null;
    }

    agencyTitle() {
        return this.typeRegulatoryStatus && this.typeRegulatoryStatus.agencyTitle;
    }

    regulatoryStatement() {
        return this.typeRegulatoryStatus && this.typeRegulatoryStatus.regulatoryStatement;
    }

    prefixedDetermination() {
        return this.regulatoryStatement() ? this.regulatoryStatement() + ' ' + this.determination : this.determination;
    }

    webReferenceExt() {
        return this.webReference;
    }

    linkDisplayExt() {
        return (this.linkDisplay);
    }

    static getEvaluatingAgenciesFor(statusType: string) {
        statusType = statusType.toLowerCase();
        if (statusType == "general") {
            return ["ATSDR", "CERHR", "CIR", "CITES", "CMR", "CSCA", "DHC", "EC", "ECB", "EPA", "HPV", "IARC", "ISO", "IUCLID",
                "MAK", "NTP", "OECD", "PROP65", "REXPAN", "SCCP", "SCCS", "SCHER", "SCTEE", "UNTCC", "VKM", "WHO"];
        } else if (statusType == "occupational") {
            return ["OSHA", "ACGIH", "ISHL", "UNTC"];
        } else if (statusType == "ifra") {
            return ["IFRA", "IFRAIL"]
        } else if (statusType == "flavor") {
            return ["COE", "FDA", "FEMA", "GRAS", "GRASA", "GRASQ", "JECFA", "VKM"]
        } else {
            throw new Error("Unable to locate a statusType of: " + statusType);
        }
    }
   /// </code>

   // Generated code. Do not place code below this line.
   regulatoryStatusId: number;
   materialId: number;
   evaluatingAgencyId: string;
   evaluatorId: string;
   reference: string;
   determination: string;
   webReference: string;
   linkDisplay: string;
   note: string;
   created: Date;
   createdUser: string;
   modified: Date;
   modifyUser: string;
   rowVersion: number;
   material: Material;
   typeRegulatoryStatus: TypeRegulatoryStatus;
}


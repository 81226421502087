import { Directive } from '@angular/core';

// This is a NOOP directive - it is there simply to insure that Angular does not throw an
// error when it discovers an 'unknown' tag 'datepicker'.  This is a HACK.

// Example:
// <datepicker></datepicker>
@Directive({
    selector: 'datepicker',
})
export class DatePickerDirective  {
}

import { Component, forwardRef, Inject, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import * as _ from 'lodash';

import { provideParent, StateMap, UnitOfWork } from '../../services/common';
import { LocationFns, UtilFns } from '../../utils/common';
import { EditPropParent } from '../../controls/common';

import { Reference, ReferenceRelation, TypeReferenceRelation } from '../../entities/EntityModels';
import { ReferenceListItem } from '../../entities/projections/ReferenceListItem';

import { ROUTES } from '../routes';

import { ReferenceEditorComponent } from './reference-editor.component';
import { ReferenceSelectorComponent } from '../reference/reference-selector.component';

@Component({
    selector: 'reference-editor-relations',

    templateUrl: './reference-editor-relations.html',
    providers: [provideParent(ReferenceEditorRelationsComponent, EditPropParent)]
})
export class ReferenceEditorRelationsComponent implements OnInit, EditPropParent {
    @ViewChild(ReferenceSelectorComponent, { static: true }) _referenceSelectorComponent: ReferenceSelectorComponent;

    _data: {
        entity: Reference,
        inSubEditor: boolean,
        onCancelMap: {},
    };
    _inverseRelations: ReferenceRelation[] = [];
    _typeReferenceRelations: TypeReferenceRelation[];
    _typeReferenceRelation: TypeReferenceRelation = null;

    constructor(@Inject(forwardRef(() => ReferenceEditorComponent)) public _parent: ReferenceEditorComponent,
                public _uow: UnitOfWork, public _stateMap: StateMap, public _router: Router, public _location: Location) {

    }

    ngOnInit() {
        LocationFns.setTab(this._location, 2);

        this._uow.typeReferenceRelationRepository.all().then(r => {
            this._typeReferenceRelations = r;
            this._typeReferenceRelation = r[0];
        });

        this._data = this._parent.data;
        this._data.onCancelMap['special'] = this.onCancel.bind(this);
        const params = { referenceId: this._entity.referenceId };
        if (this._entity.referenceRelations == null || this._entity.referenceRelations.length == 0) {
            // fetch if not already populated.
            this._uow.referenceRelationRepository.whereWithParams(params);
        }

    }

    get _entity() {
        return this._data.entity;
    }

    getError(propName: string) {
        return this._data.entity.getErrorFor(propName);
    }

    setTypeReferenceRelation(typeReferenceRelationId: string) {
        this._typeReferenceRelation = this.getTypeReferenceRelation(typeReferenceRelationId);
    }

    getTypeReferenceRelation(typeReferenceRelationId: string) {
        return this._typeReferenceRelations.find(trr => trr.typeReferenceRelationId == typeReferenceRelationId);
    }

    navToRef(r: Reference) {
        this._router.navigate(UtilFns.asRouterParts(ROUTES.Reference, r.referenceId));
    }

    onAttachReference() {
        UtilFns.showModal(this._referenceSelectorComponent, this, this.validateReference).then(rli => {
            if (rli == null) {
                return;
            }
            this.attachRelatedReference(rli.referenceId, this._typeReferenceRelation);
        });

    }

    onDetachReference(referenceRelation: ReferenceRelation) {
        const relatedReferenceId = referenceRelation.relatedReferenceId;
        const ea = referenceRelation.entityAspect;
        if (ea.entityState.isAdded()) {
            ea.rejectChanges();
            const rr = _.find(this._inverseRelations, x => x.referenceId == relatedReferenceId);
            if (rr != null) {
                rr.entityAspect.rejectChanges();
            }
        } else {
            ea.setDeleted();
            // now delete inverse.
            const params = { referenceId: relatedReferenceId };
            this._uow.referenceRelationRepository.whereWithParams(params)
                .then(rrs => {
                    const rr = _.find(rrs, x => x.relatedReferenceId == this._entity.referenceId);
                    if (rr != null) {
                        rr.entityAspect.setDeleted();
                    }
                });
        }

    }

    attachRelatedReference(relatedReferenceId: number, typeReferenceRelation: TypeReferenceRelation) {
        return this._uow.referenceRepository.withId(relatedReferenceId).then(r => {
            let config = {
                referenceId: this._entity.referenceId,
                relatedReference: r,
                typeReferenceRelationId: typeReferenceRelation.typeReferenceRelationId
            };
            const rr1 = this._uow.referenceRelationFactory.create(config);
            // now create inverse
            const invTypeReferenceRelation = this.getTypeReferenceRelation(typeReferenceRelation.inverseTypeReferenceRelationId);
            config = {
                referenceId: r.referenceId,
                relatedReference: this._entity,
                typeReferenceRelationId: invTypeReferenceRelation.typeReferenceRelationId
            };
            const rr2 = this._uow.referenceRelationFactory.create(config);
            this._inverseRelations.push(rr2);
        });
    }


    validateReference(rli: ReferenceListItem) {
        const alreadyExists = this._entity.referenceRelations.some(rr => rr.relatedReferenceId == rli.referenceId);
        if (alreadyExists) {
            return 'Reference: ' + rli.referenceId + ' has already been referenced.';
        }
    }


    onCancel() {

    }


}

import { EntityBase } from './EntityBase';
import { Material } from './Material';
import { BiologicalData } from './BiologicalData';
import { ExperimentalResult } from './ExperimentalResult';
import { TypeToxicEffect } from './TypeToxicEffect';
import { TypeExperimentVehicle } from './TypeExperimentVehicle';
import { TypeStudyMixture } from './TypeStudyMixture';

import { Entity, Validator } from 'breeze-client';

/// <code-import> Place custom imports between <code-import> tags
import { BioDataStudySubType } from './BioDataStudySubType';

const nullBiologicalData = {
    typeStudy: {studyLong: '-'},
    typeSpecies: {speciesLong: '-'},
    typeRoute: {routeLong: '-'},
    reference: {
        briefReference: '-',
        // briefReferenceTrimmed: '-',
        briefRef: (isStaff: boolean) => '-',
        restrictedDistribution: '-',
        rIFMAsAuthor: '-',
        sponsoringCompany: '-'
    },
    subReference: '-',
    typeStudyId: '-',
    typeRouteId: '-',
    typeSpeciesId: '-',
    referenceId: -999,
    bioDataStudySubTypes: <BioDataStudySubType[]>[]
};

function isNullOrEmpty(inputString: string): boolean {
    if (inputString === null || inputString.length === 0) {
        return true;
    } else {
        return false;
    }
}

const _monthNames = [
    'January', 'February', 'March',
    'April', 'May', 'June', 'July',
    'August', 'September', 'October',
    'November', 'December'
].map(n => n.substr(0, 3));

// formats a date in dd-MMM-yyyy format
function formatDate(date: Date) {
    const day = date.getDate();
    const monthIndex = date.getMonth();
    const year = date.getFullYear().toString().substr(2);
    return day + '-' + _monthNames[monthIndex] + '-' + year;
}

/// </code-import>

export class ExperimentalMaterial extends EntityBase {

    /// <code> Place custom code between <code> tags
    public getBiologicalData() {
        return this.biologicalData || nullBiologicalData;
    }

    public typeStudyId() {
        return this.getBiologicalData().typeStudyId;
    }

    public typeRouteId() {
        return this.getBiologicalData().typeRouteId;
    }

    public typeSpeciesId() {
        return this.getBiologicalData().typeSpeciesId;
    }

    // was Location
    public referenceId() {
        return this.getBiologicalData().referenceId;
    }

    // was Study
    public studyLong() {
        return this.getBiologicalData().typeStudy.studyLong;
    }

    // was Route
    public routeLong() {
        return this.getBiologicalData().typeRoute.routeLong;
    }

    // was Species
    public speciesLong() {
        return this.getBiologicalData().typeSpecies.speciesLong;
    }

    public studySubTypeDescriptions() {
        return this.getBiologicalData().bioDataStudySubTypes
            .map(x => x.typeStudySubType.subTypeDescription);
    }

    public studySubTypeIds() {
        return this.getBiologicalData().bioDataStudySubTypes
            .map(x => x.studySubTypeId);
    }

    // was File
    public get subReference() {
        return this.getBiologicalData().subReference;
    }

    // was Reference
    public briefReference() {
        return this.getBiologicalData().reference.briefReference;
    }

    public briefRef(isStaff: boolean) {
        return this.getBiologicalData().reference.briefRef(isStaff);
    }

    // was Date
    public modifiedOn() {
        return formatDate(this.modified);
    }

    public modifiedAsISO() {
        return this.modified.toISOString();
    }

    // was Sponsor
    public sponsoringCompany() {
        return this.getBiologicalData().reference.sponsoringCompany;
    }

    public M() {
        return this.getBiologicalData().reference.restrictedDistribution ? 'M' : '';
    }

    public R() {
        return this.getBiologicalData().reference.rIFMAsAuthor ? 'R' : '';
    }

    public fFDS() {
        if ((!isNullOrEmpty(this.fFDSShortVersion)) && this.fFDSShortVersion.substring(0, 1) == 'x') {
            return 'X';
        }
        if ((isNullOrEmpty(this.fFDSStatement)) && isNullOrEmpty(this.fFDSShortVersion)) {
            return null;
        }
        const ffdsString = this.material.materialHazardCodes.length > 0 ? 'Y ' : 'N ';
        return ffdsString + (this.fFDSModified != null ? formatDate(this.fFDSModified) : '');
    }

    public activateTheLink() {
        if ((!isNullOrEmpty(this.fFDSShortVersion)) && this.fFDSShortVersion.substr(0, 1) == 'x') {
            return false;
        }
        if (isNullOrEmpty(this.fFDSStatement) && isNullOrEmpty(this.fFDSShortVersion)) {
            return false;
        }
        return true;
    }

    public unfinishedReference() {
        return this.biologicalData.reference.finished ? 'Finished' : 'Tox Date Entry In Progress';
    }

    cascadeDelete() {
        if (this.experimentalResults) {
            this.experimentalResults.map(er => er).forEach(er => er.cascadeDelete());
        }
        this.cascadeDeleteCore();
    }

    _deleted: Entity[] = [];

    addDeletedChild(e: Entity) {
        if (e.entityAspect.entityState.isAdded()) {
            return;
        }
        this._deleted.push(e);
    }

    getDeletedChildren() {
        return this._deleted;
    }

    clearDeletedChildren() {
        this._deleted.length = 0;
    }

    // Only show Experimental Material Vehicle if there are no Vehicles set for Experimental Results
    public showExperimentalMaterialVehicle(): boolean {

        if (this.experimentalResults == null) {
            return true;
        }

        const vehicles = this.experimentalResults.filter(v => v.typeExperimentVehicleId != 'NONE');

        if (vehicles != null && vehicles.length > 0) {
            return false;
        }

        return true;
    }

    public get calculatedResultTypeAndValue(): string {
        const typeToxicEffect = (this.typeToxicEffect) ? this.typeToxicEffect.toxicEffectDescription : '-';
        return (typeToxicEffect) + ' ' + (this.calculatedResult || '');
    }

    /// </code>

    // Generated code. Do not place code below this line.
    experimentalMaterialId: number;
    biologicalDataId: number;
    materialId: number;
    sampleText: string;
    typeToxicEffectId: string;
    calculatedResult: string;
    fFDSShortVersion: string;
    fFDSSequenceNo: number;
    fFDSModified: Date;
    experimentalDetails: string;
    experimentalSummary: string;
    fFDSStatement: string;
    typeStudyMixtureId: string;
    typeExperimentVehicleId: string;
    created: Date;
    createdUser: string;
    modified: Date;
    modifyUser: string;
    rowVersion: number;
    biologicalData: BiologicalData;
    experimentalResults: ExperimentalResult[];
    material: Material;
    typeExperimentVehicle: TypeExperimentVehicle;
    typeStudyMixture: TypeStudyMixture;
    typeToxicEffect: TypeToxicEffect;
}


<h4 class="bold">Adding a Special Link</h4>
<span>
    <button type="button" class="btn btn-sm btn-primary" [disabled]="!canApply()"  (click)="onApply(entity)">Apply</button>            
    <button type="button" class="btn btn-sm btn-primary"  (click)="onApply(null)">Cancel</button>
</span>

<div class="form-horizontal">
    <edit-prop label="Category" prop="_keyword" szs="2,6" >
        <select class="form-control" *ngIf="_typeKeywords" [ngModel]="_keyword" (change)="_keyword=$event.target.value">
            <option *ngFor="let t of _typeKeywords" [value]="t.keyword">{{t.keyword + ": " + t.explanation}}</option>
        </select>
    </edit-prop>
    <div class="form-group">
        <span class="col-md-2">
            <button type="button" class="pull-right btn btn-sm btn-success" (click)="onSelectMaterial()">Select Material</button>
        </span>
        <edit-prop isChild prop="_material" szs="2,8">
            <label class="form-control" type="string" [htmlGreek]="_material?.principalSynonym()?.synonymWordOrWebVersion() || ''" ></label>
        </edit-prop>
    </div>

    <edit-prop label="Description" prop="reason" szs="2,6">
        <input class="form-control" type="text" [(ngModel)]="entity.reason">
    </edit-prop>
    <edit-prop label="Human Health Data" prop="humanHealthData" szs="2,4">
        <input class="form-control" type="checkbox" [(ngModel)]="entity.humanHealthData">
    </edit-prop>
    <edit-prop label="Environmental Data" prop="environmentalData" szs="2,4">
        <input class="form-control" type="checkbox" [(ngModel)]="entity.environmentalData">
    </edit-prop>

</div>

<material-selector></material-selector>
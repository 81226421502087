<div style="margin-left: 8px;">
    <p class="bold">{{userMessage}}</p>

    <div>

        <p *ngIf="!hasSubscriptionsData()" class="bold left-indent">No subscriptions for the selected journal.</p>

        <div *ngIf="hasSubscriptionsData()">
            <div class="subtitle-margin-top">
                <div class="scrollableTableHeader">
                    <div class="scrollableTableColumn text-center"><span class="inline" style="width: 120px">Subscription Type</span></div>
                    <div class="scrollableTableColumn text-center"><span class="inline" style="width: 90px">Start Date</span></div>
                    <div class="scrollableTableColumn text-center"><span class="inline" style="width: 90px">End Date</span></div>
                    <div class="scrollableTableColumn text-center"><span class="inline" style="width: 90px">Issues per Year</span></div>
                    <div class="scrollableTableColumn text-left"><span class="inline" style="width: 220px">Publisher</span></div>
                    <div class="scrollableTableColumn text-left"><span class="inline" style="width: 380px">Notes</span></div>
                </div>
                <div style="height: 240px; overflow-y:auto;overflow-x: hidden;">
                    <div class="scrollableTableRow" *ngFor="let s of _subscriptions">
                        <div class="scrollableTableColumn text-center"><span class="inline" style="width: 120px">{{s.subscriptionDescription}}</span></div>
                        <div class="scrollableTableColumn text-center"><span class="inline" style="width: 90px">{{s.subscriptionStartYearAndMonth}}</span></div>
                        <div class="scrollableTableColumn text-center"><span class="inline" style="width: 90px">{{s.subscriptionEndYearAndMonth}}</span></div>
                        <div class="scrollableTableColumn text-center"><span class="inline" style="width: 90px">{{s.issuesPerYear}}</span></div>
                        <div class="scrollableTableColumn text-left"><span class="inline" style="width: 220px">{{s.publisher}}</span></div>
                        <div class="scrollableTableColumn text-left"><span class="inline" style="width: 380px">{{s.notes}}</span></div>
                    </div>
                </div>
            </div>
        </div>

    </div>

</div>

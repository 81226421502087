import { Component, Input } from '@angular/core';
import { Material } from '../../entities/Material';

@Component({
    selector: 'material-header',
    templateUrl: './material-header.html',
})
export class MaterialHeaderComponent {

    showTooltip = false;
    @Input() material: Material;
    @Input() title: string;

    hasRIFMTooltip() {
        return !!(this.material.typeBotanicalSubdivision || this.material.typeEssentialOil);
    }

    toggleTooltip() {
        this.showTooltip = !this.showTooltip;
    }

    formatRIFMTooltip() {
        const bs = this.material.typeBotanicalSubdivision;
        const bsText = bs ? `<b>${bs.typeBotanicalSubdivisionId}</b>:  ${bs.botanicalSource}` : '';
        const te = this.material.typeEssentialOil;
        const teText = te ? `<b>${te.typeEssentialOilId}</b>:  ${te.essentialOilTerm}` : '';
        const result = `
            <div style="text-align: left; margin-left:10px;">
                <div><b>Botanical Definition</b></div>
                <div style="margin-left: 20px;" >${bsText}</div>
                <br/>
                <div><b>Processing Method</b></div>
                <div style="margin-left: 20px;">${teText}</div>
            </div>
            `;
        return result;
    }

    getRegistration() {
        const registrations: string[] = [];
        if (this.material.dSLRegistration) {
            registrations.push('DSL');
        }
        if (this.material.eCNRegistration) {
            registrations.push('ECN');
        }
        if (this.material.eINECSRegistration) {
            registrations.push('EINECS');
        }
        if (this.material.nDSLRegistration) {
            registrations.push('NDSL');
        }
        if (this.material.reachRegistration) {
            registrations.push('REACH');
        }
        if (this.material.tSCARegistration) {
            registrations.push('TSCA');
        }

        return registrations.join(' ');
    }
}

import { Location } from '@angular/common';
import {Component, EventEmitter, forwardRef, Inject, Input, Output, OnInit, ViewChild} from '@angular/core';

import { UnitOfWork, StateMap, provideParent } from '../../services/common';
import { PageState, EditPropParent } from '../../controls/common';
import { Journal, JournalReserve } from '../../entities/EntityModels';
import * as _ from 'lodash';

import { JournalsComponent, JournalEditData, JournalEditState } from './journals.component';
import {EditManager} from '../../utils/edit-manager';

@Component({
    selector: 'journal-reserve-editor',
    templateUrl: './journal-reserve-editor.html',
    providers: [provideParent(JournalReserveEditorComponent, EditPropParent)]
})

export class JournalReserveEditorComponent implements OnInit {
    @Output() stateChange = new EventEmitter<string>();

    _data: JournalEditData;
    _edmReserve: EditManager<JournalReserve>;

    _hasBeenDeleted = false;
    _pageState = new PageState('Journal Reserve Editor');
    _validationMessage: string;

    constructor( @Inject(forwardRef(() => JournalsComponent)) public _parent: JournalsComponent,
                 private _uow: UnitOfWork, private _stateMap: StateMap, public _location: Location) {

        this._data  = _parent._data;
    }

    ngOnInit() {

        this._edmReserve = new EditManager<JournalReserve>(this, this._uow, this._stateMap, 'JournalReserve');

        if (this._data == null) {
            return;
        }

        if (this._data.adding) {
            const h = this.createJournalReserve();
            this._edmReserve.onlyEntity = h;
        } else {
            if (this._data.journalReserveId == null) {
                return;
            }
            this.fetchThenEditJournalReserve();
        }

        this._edmReserve.editing    = true;

    }

    get journalReserve() {
        return this._edmReserve.currentEntity;
    }

    createJournalReserve() {
        const journalReserve        = this._uow.journalReserveFactory.create();
        journalReserve.journalId    = this._data.entity.journalId;

        // provide a default year
        const today                 = new Date();
        journalReserve.journalYear  = today.getFullYear();

        return journalReserve;
    }

    fetchThenEditJournalReserve() {
        this._pageState.isLoading   = true;

        return this._uow.fetchTyped('Misc/JournalReserveById', JournalReserve, { journalReserveId: this._data.journalReserveId }).then(r => {
            const h = r && (r.length > 0) && r[0];
            if (h) {
                this._edmReserve.onlyEntity = h;
            }
            this._pageState.isLoading   = false;
        }).catch(() => {
            this._pageState.isLoading   = false;
        });
    }

    getError(propName: string) {

        return;

        if (this._edmReserve == null || this._edmReserve.currentEntity == null) {
            return;
        }

        const r = this._edmReserve.currentEntity.getErrorFor(propName);
        return r;
    }

    // *******************************************************
    // edit interactions
    // *******************************************************
    canApply() {

        if (this._data == null) {
            return false;
        }

        if (this._data.deleting) {
            return false;
        }

        if (this._edmReserve.editing && this._uow.hasChanges()) {
            if (this._edmReserve.currentEntity != null) {
                return (!this._edmReserve.currentEntity.entityAspect.hasValidationErrors);
            }
            return true;
        }
        return false;
    }

    onApply() {
        this._validationMessage = '';

        if (this.isValidJournalReserve() == false) {
            return false;
        }

        // journals
        if (this._edmReserve.editing) {
            this._edmReserve.onApplyCore();
        }

        this._data.adding   = false;

        return true;
    }

    canShowBack() {
        if (this._data.deleting) {
            return false;
        }

        return (!this._uow.hasChanges());
    }

    onBack() {
        this._data.adding               = false;
        this._edmReserve.editing        = false;
        this._data.inSubEditor          = false;

        this.stateChange.emit('Back');

    }

    canCancel() {
        if (this._data.deleting) {
            return true;
        }

        return (this._uow.hasChanges());
    }

    onCancel() {
        this._validationMessage = '';

        this._uow.rollback();

        this._edmReserve.clearErrors();
        this._edmReserve.editing = false;
        this._edmReserve.pageState.loadStatusMsg = null;

        const data          = this._data;
        data.inSubEditor    = false;

        this.stateChange.emit('Cancel');
    }

    canDelete() {
        if (this._hasBeenDeleted) {
            return false;
        }

        if (this._data == null || this._edmReserve == null) {
            return false;
        }

        return (this._data.deleting && this._edmReserve.currentEntity != null && this._hasBeenDeleted == false);
    }

    onDelete() {
        const reserve = this._edmReserve.currentEntity;

        if (reserve.entityAspect.entityState.isAdded()) {
            reserve.entityAspect.rejectChanges();
        } else {
            reserve.entityAspect.setDeleted();
        }

        // this is a confirmed delete so apply changes
        this.onApply();
        this._hasBeenDeleted = true;
    }

    canSave(): boolean {

        if (this._edmReserve.editing) { return false; }
        if (!this._edmReserve.hasEdits()) { return false; }
        if (!this._edmReserve.validateBeforeSave()) { return false; }

        return true;
    }

    onSave() {
        if (this._edmReserve.hasEdits()) {
            this._edmReserve.onSaveCore().then(() => {
                this._edmReserve.editing = false;
            });
        }

        this.stateChange.emit('Save');
    }

    isValidJournalReserve() {
        if (this.journalReserve.journalYear == null || this.journalReserve.journalYear == 0) {
            this._validationMessage = 'Please enter a valid Year.';
            return false;
        }

        return true;
    }
}

import { Component, Input, OnInit, ElementRef, ViewChild, ContentChild } from '@angular/core';
import { UnitOfWork } from '../../services/common';
import { UtilFns } from '../../utils/common';
import { ModalDialog, PageState } from '../../controls/common';

import * as _ from 'lodash';

@Component({
    selector: 'cluster-help-video',
    templateUrl: './cluster-help-video.html',
})
export class ClusterHelpVideoComponent extends ModalDialog<boolean> {

    constructor(elementRef: ElementRef, public _uow: UnitOfWork) {
        super(elementRef);
    }

    get utilFns() {
        return UtilFns;
    }

    // ***************************************************************************************************
    // Close Modal
    // ***************************************************************************************************
    onClose() {
        UtilFns.returnModal(this, false);
    }
}

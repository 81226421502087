import { AfterViewInit, Component, ViewChild, Inject, forwardRef, OnInit, ElementRef } from '@angular/core';

import {UnitOfWork, provideParent, UserManager} from '../../services/common';
import { UtilFns } from '../../utils/common';
import {ModalDialog, PageState, TabContainer} from '../../controls/common';

import {Journal, JournalReserve, JournalSubscription, TypeJournalSubscription} from '../../entities/EntityModels';
import { JournalSearchComponent } from '../staff/journal-search.component';
import * as _ from 'lodash';

export class JournalSearchData {
    entity: Journal;
    currentTab: number;
    onResetMap: { [key: string]: () => void } = {};
}

@Component({
    selector: 'journal-selector',
    templateUrl: './journal-selector.html',
})export class JournalSelectorComponent extends ModalDialog<Journal> implements AfterViewInit, OnInit {
    @ViewChild(JournalSearchComponent, { static: true }) _journalSearchComponent: JournalSearchComponent;
    @ViewChild(TabContainer) _tabContainer: TabContainer;

    _pageState = new PageState('Journals');
    _showJournalData = true;
    _tab = 2;
    _userMessage: string;

    _journal: Journal;
    _searchTerm: string;

    _activeSubscriptionsOnly        = false;
    _hasJournalReservesInLibrary    = false;
    _publishersOnly                 = false;
    _startsWith                     = false;

    _data: JournalSearchData    = new JournalSearchData();
    _journalDataIsPrepared      = false;

    _typeJournalSubscriptions: TypeJournalSubscription[] = [];
    _selectedJournalSubscriptionType = 'Any';

    constructor(elementRef: ElementRef, public _uow: UnitOfWork, public _userManager: UserManager) {
        super(elementRef);
    }

    ngOnInit() {
        return this._uow.typeJournalSubscriptionRepository.all().then(types => {
            if (types != null) {
                this._typeJournalSubscriptions = _.sortBy(types, t => t.sortOrder);
            }
        });
    }

    ngAfterViewInit() {
        // this.setTab(0);
    }

    canSearch() {

        if (this._activeSubscriptionsOnly || this._hasJournalReservesInLibrary || this.selectedJournalSubscriptionType.toLowerCase() != 'any') {
            return true;
        }

        return this._searchTerm && this._searchTerm.length > 0 && !this._publishersOnly;
    }

    canSearchPublications() {
        return this._searchTerm && this._searchTerm.length > 0 && this._publishersOnly;
    }

    canDownloadPublisherSearchResults() {
        if (!this.canSearchPublications() || this._journalSearchComponent === null) { return false; }

        return (this._journalSearchComponent._journalsEx != null && this._journalSearchComponent._journalsEx.length > 0);
    }

    canSelect() {
        return this._journal != null;
    }

    // event handlers
    onOk() {
        UtilFns.returnModal(this, this._journal);
    }

    onCancel() {
        UtilFns.returnModal(this, null);
    }

    search() {
        this.clearDisplayData();

        if (this._journalSearchComponent != null && this._journalSearchComponent != undefined) {
            this._journalSearchComponent._selectOnlyMode = !this._showJournalData;
            const subType = (this.selectedJournalSubscriptionType != null && this.selectedJournalSubscriptionType.toLowerCase() != 'any') ? this.selectedJournalSubscriptionType : '';
            this._journalSearchComponent.search(this._searchTerm, this._hasJournalReservesInLibrary, this._startsWith, this._activeSubscriptionsOnly, subType);
        }
    }

    searchPublications() {
        this.clearDisplayData();

        if (this._journalSearchComponent != null && this._journalSearchComponent != undefined) {
            this._journalSearchComponent._selectOnlyMode = !this._showJournalData;
            this._journalSearchComponent.searchPublications(this._searchTerm);
        }
    }

    // Set by child component: JournalSearchComponent
    selectedJournalId(journalId: number) {
        this.resetJournalDataDisplay();
        this.fetchJournal(journalId);
    }

    fetchJournal(journalId: number) {

        this._pageState.isLoading   = true;

        return this._uow.fetchTyped('Misc/JournalById', Journal, { journalId: journalId }).then(r => {
            const j = r && (r.length > 0) && r[0];
            if (j) {
                this._journal       = j;

                if (this._showJournalData) {
                    this.prepareData();
                    this.setTab(this._tab);
                }
            }
            this._pageState.isLoading   = false;
        }).catch(() => {
            this._pageState.isLoading   = false;
        });
    }

    prepareData() {

        this._data.entity = _.clone(this._journal);

        this._data.entity.prepareJournalData();

        this._journalDataIsPrepared = true;

    }

    clearDisplayData() {
        this._userMessage                   = null;
        this._journal                       = null;
        this._data.entity                   = null;

        this.resetJournalDataDisplay();
    }

    resetJournalDataDisplay() {
        const data = this._data;

        // tslint:disable-next-line:forin
        for (const k in data.onResetMap) {
            data.onResetMap[k]();
        }

        this._journalDataIsPrepared = false;

    }

    clearSearchResults() {
        if (this._journalSearchComponent != null) {
            this._journalSearchComponent.clearDisplayData();
        }
    }

    setShowJournalData(show: boolean) {
        this._showJournalData = show;
    }

    setTab(tabNumber: number) {
        setTimeout(() => {
            this._tabContainer.selectTab(tabNumber);
        }, 1);
    }

    onRestrictSearchToPublishersChange(restrictToPublishers: boolean) {
        if (!restrictToPublishers) {
        }
    }

    downloadPublisherSearchResults() {
        if (this._journalSearchComponent == null) { return; }

        const params: { key: string, value: string }[] = [];

        params.push({ key: 'reporttype', value: 'journalpublisher' });
        params.push({ key: 'publisherfilter', value: this._searchTerm });

        const url = this._userManager.getReportUrl(params);
        window.open(url, '_self');
        return false;
    }

    public get selectedJournalSubscriptionType(): string {
        return this._selectedJournalSubscriptionType;
    }

    public set selectedJournalSubscriptionType(subTypeId: string) {
        this._selectedJournalSubscriptionType = (this.isValidString(subTypeId)) ? subTypeId : 'Any';
    }

    isValidString(teststring: string) {
        if (!teststring) { return false; }
        return (teststring.trim().length > 0);
    }
}


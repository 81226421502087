import { Component, OnInit } from '@angular/core';

import { IMaterialSearchResult, SearchService, StateMap } from '../../services/common';
import { PageState } from '../../controls/common';

import { MaterialListItem } from '../../entities/projections/MaterialListItem';
import DataSource from 'devextreme/data/data_source';
import CustomStore from 'devextreme/data/custom_store';

export interface IMaterialResultsParent {
    selectMaterial(mli: MaterialListItem): void;
}

@Component({
    selector: 'material-results',
    templateUrl: './material-results.html'
})
export class MaterialResultsComponent implements OnInit {
    _parentComponent: any;
    _searchResult: IMaterialSearchResult;

    _pageState = new PageState('Material Results');

    dataSource: DataSource;

    constructor(public _searchService: SearchService, public _stateMap: StateMap) {
    }

    ngOnInit() {
        this.dataSource = new DataSource({
            store: new CustomStore({
                load: (loadOptions: any) => {
                    if (!this._searchResult) {
                        this._pageState.isLoading = false;
                        this._pageState.itemCount = 0;
                        this._pageState.isLoadedWithNoData = true;
                        return Promise.resolve({ data: [], totalCount: 0 });
                    }

                    let sort = { name: 'relevance', isDesc: false };
                    if (loadOptions.sort) {
                        sort = {
                            name: loadOptions.sort[0].selector,
                            isDesc: !!loadOptions.sort[0].desc
                        };
                    }

                    const skip = loadOptions.skip ? loadOptions.skip / loadOptions.take : 0;
                    const params = {
                        searchTerm: this._searchResult.searchTerm,
                        searchType: this._searchResult.searchType,
                        orderByProp: 'relevance',
                        pageNumber: skip,
                        pageSize: loadOptions.take || 20,
                        clientOrderBy: sort.name + (sort.isDesc ? ' desc' : '')
                    };

                    this._pageState.isLoading = true;
                    return this._searchService.fetchMaterialsPage(params)
                        .then(data => {
                            this._pageState.isLoading = false;
                            this._pageState.itemCount = data.totalCount;
                            this._pageState.isLoadedWithNoData = data.totalCount == 0;
                            return {
                                data: data.materials,
                                totalCount: data.totalCount
                            };
                        });
                }
            })
        });
    }

    onSelectionChanged(evt: any) {
        const material: MaterialListItem = evt.selectedRowsData[0];
        this.selectMaterial(material);
    }

    loading(value: boolean) {
        setTimeout(() => {
            this._pageState.isLoading = value;
        });
    }

    public update(parentComponent: IMaterialResultsParent, searchResult: IMaterialSearchResult) {
        this._parentComponent = parentComponent;
        this._searchResult = searchResult;

        const promise = this.dataSource.reload();

        promise
            .then((data: any) => {
                console.log(data);
            })
            .catch((err: any) => {
                this.dataSource.reload();
            });
    }

    isStaff() {
        return this._stateMap.currentUser.isStaff;
    }

    getCaptiveText(confidential: boolean): string {

        return confidential ? 'Captive' : '';
    }

    selectMaterial(material: MaterialListItem) {
        if (this._parentComponent) {
            this._parentComponent.selectMaterial(material);
        }
    }

    hasData() {
        const items = this.dataSource.items();
        return items && items.length > 0;
    }
}



import {
    AfterContentInit,
    Component,
    ContentChild,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    TemplateRef,
    ViewContainerRef
} from '@angular/core';
import { Subscription } from 'rxjs';
import { PaginationService } from './pagination-service';

export interface IPage {
    label: string;
    value: any;
}

const DEFAULT_TEMPLATE = `
    <ul class="pagination pagination-sm" role="navigation" aria-label="Pagination" *ngIf="displayDefaultTemplate()">

        <li class="pagination-previous" [class.disabled]="isFirstPage()" *ngIf="directionLinks">
            <a *ngIf="1 < getCurrent()" (click)="setCurrent(getCurrent() - 1)" aria-label="Next page">
                Previous <span class="sr-only">page</span>
            </a>
            <span *ngIf="isFirstPage()">Previous <span class="sr-only">page</span></span>
        </li>

        <li [class.current]="getCurrent() === page.value" *ngFor="let page of pages">
            <a (click)="setCurrent(page.value)" *ngIf="getCurrent() !== page.value">
                <span class="sr-only">Page</span>
                <span>{{ page.label }}</span>
            </a>
            <span *ngIf="getCurrent() === page.value">
                <span class="sr-only">You're on page</span>
                <span class="label label-info">{{ page.label }}</span>
            </span>
        </li>

        <li class="pagination-next" [class.disabled]="isLastPage()" *ngIf="directionLinks">
            <a *ngIf="!isLastPage()" (click)="setCurrent(getCurrent() + 1)" aria-label="Next page">
                Next <span class="sr-only">page</span>
            </a>
            <span *ngIf="isLastPage()">Next <span class="sr-only">page</span></span>
        </li>
    </ul>
    `;

@Component({
    selector: 'pagination-controls',
    template: DEFAULT_TEMPLATE
})
export class PaginationControlsComponent implements OnInit, AfterContentInit, OnChanges, OnDestroy {

    @Input() id: string;
    @Input() maxSize = 7;
    @Input() directionLinks = true;
    @Input() autoHide = false;

    @Output() pageChange = new EventEmitter<number>();

    @ContentChild(TemplateRef) customTemplate: any;

    changeSub: Subscription;
    maxPage = 0;
    pages: IPage[] = [];

    constructor(public service: PaginationService, public viewContainer: ViewContainerRef) {
        this.changeSub = this.service.change
            .subscribe((id: any) => {
                if (this.id === id) {
                    this.updatePages();
                }
            });
    }

    updatePages() {
        const inst = this.service.getInstance(this.id);
        this.pages = this.createPageArray(inst.currentPage, inst.itemsPerPage, inst.totalItems, this.maxSize);
    }

    displayDefaultTemplate(): boolean {
        if (this.customTemplate != null) {
            return false;
        }
        return !this.autoHide || 1 < this.pages.length;
    }

    /**
     * Set up the subscription to the PaginationService.change observable.
     */
    ngOnInit() {
        if (this.id === undefined) {
            this.id = this.service.defaultId;
        }
    }

    ngAfterContentInit() {
        if (this.customTemplate != null) {
            this.viewContainer.createEmbeddedView(this.customTemplate);
        }
    }

    ngOnChanges() {
        this.updatePages();
    }

    ngOnDestroy() {
        // TODO: do i need to manually clean these up??? What's the difference between unsubscribe() and remove()
        this.changeSub.unsubscribe();
    }

    /**
     * Set the current page number.
     */
    public setCurrent(page: number) {
        this.service.setCurrentPage(this.id, page);
        this.pageChange.emit(this.service.getCurrentPage(this.id));
    }

    /**
     * Get the current page number.
     */
    public getCurrent(): number {
        return this.service.getCurrentPage(this.id);
    }

    public isFirstPage(): boolean {
        return this.getCurrent() === 1;
    }

    public isLastPage(): boolean {
        const inst = this.service.getInstance(this.id);
        return Math.ceil(inst.totalItems / inst.itemsPerPage) === inst.currentPage;
    }

    /**
     * Returns an array of IPage objects to use in the pagination controls.
     */
    createPageArray(currentPage: number, itemsPerPage: number, totalItems: number, paginationRange: number): IPage[] {
        // paginationRange could be a string if passed from attribute, so cast to number.
        paginationRange = +paginationRange;
        const pages: IPage[] = [];
        // const totalPages = Math.ceil(totalItems / itemsPerPage);

        let totalPages: number;
        if (totalItems == Number.MAX_VALUE) {
            this.maxPage = Math.max(currentPage + 1, this.maxPage);
            totalPages = this.maxPage;
        } else {
            totalPages = Math.ceil(totalItems / itemsPerPage);
        }
        const halfWay = Math.ceil(paginationRange / 2);

        const isStart = currentPage <= halfWay;
        const isEnd = totalPages - halfWay < currentPage;
        const isMiddle = !isStart && !isEnd;

        const ellipsesNeeded = (paginationRange < totalPages) || (totalItems == Number.MAX_VALUE);
        let i = 1;

        while (i <= totalPages && i <= paginationRange) {
            let label: string;
            const pageNumber = this.calculatePageNumber(i, currentPage, paginationRange, totalPages);
            const openingEllipsesNeeded = (i === 2 && (isMiddle || isEnd) && totalPages != 3);
            const closingEllipsesNeeded = ((totalItems == Number.MAX_VALUE) && (pageNumber === totalPages)) || (i === paginationRange - 1 && (isMiddle || isStart));
            if (ellipsesNeeded && (openingEllipsesNeeded || closingEllipsesNeeded)) {
                label = '...';
            } else {
                label = pageNumber.toString();
            }
            pages.push({
                label: label,
                value: pageNumber
            });
            i++;
        }
        return pages;
    }

    /**
     * Given the position in the sequence of pagination links [i],
     * figure out what page number corresponds to that position.
     */
    calculatePageNumber(i: number, currentPage: number, paginationRange: number, totalPages: number) {
        const halfWay = Math.ceil(paginationRange / 2);
        if (i === paginationRange) {
            return totalPages;
        } else if (i === 1) {
            return i;
        } else if (paginationRange < totalPages) {
            if (totalPages - halfWay < currentPage) {
                return totalPages - paginationRange + i;
            } else if (halfWay < currentPage) {
                return currentPage - halfWay + i;
            } else {
                return i;
            }
        } else {
            return i;
        }
    }
}

import { Component, OnInit } from '@angular/core';
import {StateMap, UnitOfWork, UserManager} from '../../services/common';
import { RIFMDownloadableFile } from  '../../entities/EntityModels';
import {HttpClient, HttpHeaders, HTTP_INTERCEPTORS} from '@angular/common/http';
import {Observable} from 'rxjs';

@Component({
    templateUrl: './database-subscription.html',
})
export class DatabaseSubscriptionComponent implements OnInit {

    // DatabaseApplication
    // DatabasePricing

    _databaseApplicationFiles: RIFMDownloadableFile[] = [];
    _databasePricingFiles: RIFMDownloadableFile[] = [];
    _documentManagementUrl: string;

    _isLoading = false;
    _validationMessage: string;
    _yearLabel: string;

    constructor(public _stateMap: StateMap, private _httpClient: HttpClient, public _uow: UnitOfWork, public _userManager: UserManager) { }

    ngOnInit() {
        this._documentManagementUrl = this._stateMap.documentManagementBaseUrl + '/RIFMDownloadableFile';

        this.loadData();
    }

    loadData() {
        this._isLoading = true;

        const params = { category: 'DatabaseSubscriptionInformation' };

        return this._uow.fetch('DocumentManagement/FetchRIFMDownloadableFilesByFileCategory', params).then(r => {
            if (r) {
                const files = <RIFMDownloadableFile[]>r;
                this._databaseApplicationFiles = files.filter(f => f.fileIdentifier == 'DatabaseApplication');

                this._databasePricingFiles = files.filter(f => f.fileIdentifier == 'DatabasePricing');
                this.setYearLabel();
            }

            this._isLoading = false;
        });
    }

    public setYearLabel() {
        this._yearLabel = (this._databasePricingFiles == null || this._databasePricingFiles.length == 0) ? '' : this._databasePricingFiles[0].fileDescription;
    }

    public getDocumentManagementUrl(): Promise<any> {
        return this._uow.fetch('DocumentManagement/DocumentManagementURL', {}).then(dm => {
            return dm[0];
        });
    }

    downloadFile(dfs: RIFMDownloadableFile) {
        if (dfs == null) {
            return ;
        }

        this.exportSubscriptionFile(dfs.fileName, dfs.fileSubFolder);
    }

    // ************************************************************************
    // document management web api url
    // ************************************************************************
    getDownloadFileURL(fileName: string, subFolder: string): Observable<any> {
        let url: string;
        url = this._documentManagementUrl + '/GetFileBySubFolderAndName?fileName=' + fileName + '&subFolder=' + subFolder ;

        return this._httpClient.get<any>(url, { responseType: 'blob' as 'json'});
    }

    public exportSubscriptionFile(fileName: string, subFolder: string) {
        this._isLoading = true;
        this._validationMessage = '';
        this.getDownloadFileURL(fileName, subFolder)
            .subscribe(
                (response: Blob) => {
                    const data = response;

                    const file = new Blob([data], { type: 'application/pdf' });
                    const fileURL = URL.createObjectURL(file);

                    // window.open(fileURL);
                    const a         = document.createElement('a');
                    a.href        = fileURL;
                    a.target      = '_blank';
                    a.download    = fileName;
                    document.body.appendChild(a);
                    a.click();

                    this._validationMessage = 'File download successful.';

                },
                (error) => {
                    console.error('Request failed with error: ' + error.message);
                    this._validationMessage = error.message;
                    this._isLoading = false;
                },
                () => {
                    this._isLoading = false;
                });
    }
}

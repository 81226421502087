<div *ngIf="reference" class="form-horizontal">
    <div *ngIf="isStaff()" class="flex-container background-transparent workflow-ref-status" style="max-width: 852px;">
        <div class="flex-item" >
				<span *ngIf="reference.finished" class="workflow-status-text">
					Finished &nbsp;
				</span>
        </div>
        <div class="flex-item">
				<span *ngIf="!reference.finished" class="workflow-status-text">
					Unfinished &nbsp;
				</span>
        </div>
        <div class="flex-item">
				<span class="workflow-status-text" *ngIf="isProofed()">
					Reference Proofed
				</span>
        </div>
        <div class="flex-item">
				<span class="workflow-status-text" *ngIf="!isProofed()">
					Reference Unproofed
				</span>
        </div>
    </div>
    <edit-prop label="Ref No." szs="1,10">
        <p class="form-control-static">{{reference.referenceId}}</p>
    </edit-prop>
    <edit-prop label="Title" szs="1,10">
        <p class="form-control-static">{{reference.title}}</p>
    </edit-prop>
    <edit-prop *ngIf="isStaff()" label="Project" szs="1,10">
        <p class="form-control-static">{{reference.project?.projectName}}</p>
    </edit-prop>
    <edit-prop label="Authors" szs="1,10">
        <p class="form-control-static">{{reference.authorDisplay()}}</p>
    </edit-prop>
    <edit-prop label="Journal" szs="1,10">
        <p class="form-control-static">{{reference.journal?.journalName || '-'}}; {{reference.journalDetail || ''}}</p>
    </edit-prop>
    <edit-prop label="Klimisch Score" szs="1,10">
        <p class="form-control-static">{{reference.typeKlimischId || '-'}}</p>
    </edit-prop>
    <edit-prop *ngIf="isStaff()" label="Online Publications" szs="1,10">
        <p class="form-control-static">{{reference.publicationWebReference || '-'}}</p>
    </edit-prop>
    <edit-prop label="Abstract" szs="1,10">
        <p class="form-control-static" [htmlGreek]="getAbstract()"></p>
    </edit-prop>
    <edit-prop label="Abstract Number" szs="1,10">
        <p class="form-control-static">{{reference.abstractNumber}}</p>
    </edit-prop>
    <edit-prop label="Meeting Details" szs="1,10">
        <p class="form-control-static">{{reference.meetingDetails}}</p>
    </edit-prop>
    <edit-prop label="Poster Attached" szs="1,10">
        <input class="form-control-static" type="checkbox" disabled [checked]="reference.posterAttached">
    </edit-prop>
    <edit-prop label="Contractor ID" szs="1,10">
        <p class="form-control-static">{{reference.contractorId || '-'}}</p>
    </edit-prop>
    <edit-prop label="In Life Portion" szs="1,10">
        <span class="form-control-static; overflow-wrap: break-word; inline" style="width: 75%;">{{reference.inLifePortion || '-'}}</span>
    </edit-prop>
    <div *ngIf="isGovernmentDocument()">
        <edit-prop label="Govt. Sponsors" szs="1,7">
            <p class="form-control-static">{{reference.govSponsors || '-'}}</p>
        </edit-prop>
        <edit-prop label="Govt. Document No." szs="1,7">
            <p class="form-control-static">{{reference.govDocNumbers || '-'}}</p>
        </edit-prop>
    </div>
    <span *ngIf="isStaff()">
        <div class="form-group">
            <edit-prop isChild label="Staff Comments" szs="1,7">
                <p class="form-control-static">{{reference.staffComments || '-'}}</p>
            </edit-prop>
            <edit-prop isChild label="Priority" szs="1,3">
                <p class="form-control-static">{{reference.precedence || '-'}}</p>
            </edit-prop>
        </div>
        <div class="form-group">
            <edit-prop isChild label="Sponsoring Company" szs="1,7">
                <p class="form-control-static">{{reference.sponsoringCompany || '-'}}</p>
            </edit-prop>
            <edit-prop isChild label="Reference Type" szs="1,3">
                <p class="form-control-static">{{reference.typePublication.publicationType}}</p>
            </edit-prop>
        </div>

        <div class="form-group">
            <edit-prop isChild label="Linkable" szs="1,3">
                <input class="form-control-static" type="checkbox" disabled [checked]="reference.linkable">
            </edit-prop>
            <edit-prop isChild label="FEMA Article" szs="1,3">
                <input class="form-control-static" type="checkbox" disabled [checked]="reference.fEMAArticle">
            </edit-prop>
            <edit-prop isChild label="Restricted Distribution" szs="1,3">
                <input class="form-control-static" type="checkbox" disabled [checked]="reference.restrictedDistribution">
            </edit-prop>
        </div>

        <div class="form-group">
            <edit-prop isChild label="Literature Search" szs="1,3">
                <p class="form-control-static">{{reference.typeSearchServiceId}}</p>
            </edit-prop>
            <edit-prop isChild label="Abstract Only" szs="1,3">
                <input class="form-control-static" type="checkbox" disabled [checked]="reference.abstractOnly">
            </edit-prop>
            <edit-prop isChild label="Captive Paper" szs="1,3">
                <input class="form-control-static" type="checkbox" disabled [checked]="reference.captivePaper">
            </edit-prop>
        </div>

        <div class="form-group">
            <edit-prop isChild label="RIFM Article" szs="1,3">
                <input class="form-control-static" type="checkbox" disabled [checked]="reference.rIFMArticle">
            </edit-prop>
            <edit-prop isChild label="RIFM as Author" szs="1,3">
                <input class="form-control-static" type="checkbox" disabled [checked]="reference.rIFMAsAuthor">
            </edit-prop>
        </div>
    </span>

    <div class="col-md-12" *ngIf="_groupedLinks && _groupedLinks.length > 0">
        <div class="page-section">Material Links</div>
        <div *ngFor="let group of _groupedLinks">
            <default-table *ngIf="group">
                <h5 header class="bold">{{group.keyExpl}}</h5>
                <h6 header style="margin-right: 30px">{{group.keyDescr}}</h6>
                <thead>
                    <tr>
                        <td>CAS No.</td>
                        <td>RIFM ID</td>
                        <td>Synonym</td>
                        <td>FEMA No.</td>
                        <td>Human Health</td>
                        <td>Environmental</td>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let sl of group.specialLinks">
                        <td><a (click)="onCasSelect(sl)">{{sl.material.realCASNumber || '(No CAS#)'}}</a></td>
                        <td>{{sl.material.formattedRIFMId}}</td>
                        <td [htmlGreek]="sl.material?.principalSynonym()?.synonymWordOrWebVersion()"></td>
                        <td>{{sl.material.fEMANumber}}</td>
                        <td><input type="checkbox" disabled [checked]="sl.humanHealthData"></td>
                        <td><input type="checkbox" disabled [checked]="sl.environmentalData"></td>
                    </tr>
                </tbody>
            </default-table>
        </div>
    </div>


    <div *ngIf="isStaff()" class="row clear">
        <workflow-history [entity]="reference" [editable]="false"></workflow-history>
    </div>

</div>

    

import { ColumnSorter, SortColumn, SortMap } from './column-sorter';
import * as pluralize from 'pluralize';

export enum PageLoadStatus {
    IsLoading,
    IsLoaded,
    IsLoadedWithNoData
}

export class PageState {
    sortColumn: SortColumn;
    loadStatus: PageLoadStatus;
    loadStatusMsg: string;
    pageNumber: number;
    pageSize: number;
    itemCount: number;
    tabNumber: number;
    canShowMessage: boolean;

    _maxItemCount: number;

    constructor(public displayName: string) {
        this.pageNumber = 1;
        this.pageSize = 200;
        this.canShowMessage = true;
    }


    get isLoading() {
        return this.loadStatus == PageLoadStatus.IsLoading;
    }
    set isLoading(value: boolean) {
        if (value) {
            this.loadStatus = PageLoadStatus.IsLoading;
        } else {
            this.loadStatus = PageLoadStatus.IsLoaded;
        }
    }

    get isLoaded() {
        return this.loadStatus == PageLoadStatus.IsLoaded;
    }
    set isLoaded(value: boolean | Array<any>) {
        if (value == null) {
            this.loadStatus = PageLoadStatus.IsLoadedWithNoData;
        } else if (typeof (value) == 'boolean') {
            if (value) {
                this.loadStatus = PageLoadStatus.IsLoaded;
            } else {
                this.loadStatus = PageLoadStatus.IsLoadedWithNoData;
            }
        } else {
            this.loadStatus = ((<Array<any>>value).length > 0) ? PageLoadStatus.IsLoaded : PageLoadStatus.IsLoadedWithNoData;
        }
        this.updateLoadStatusMsg();
    }

    get isLoadedWithNoData() {
        return this.loadStatus == PageLoadStatus.IsLoadedWithNoData;
    }
    set isLoadedWithNoData(value: boolean) {
        if (value) {
            this.loadStatus = PageLoadStatus.IsLoadedWithNoData;
        } else {
            this.loadStatus = PageLoadStatus.IsLoaded;
        }
        this.updateLoadStatusMsg();
    }

    get hasMessage() {
        const result = this.canShowMessage && (this.isLoadedWithNoData || (this.isLoaded && this.itemCount != null));
        return result;
    }

    loadStatusClass() {
        return 'bold';
    }

    getPaginationInfo() {
        return {
            currentPage: this.pageNumber,
            itemsPerPage: this.pageSize,
            totalItems: this.itemCountEstimate
        };
    }

    resetPaging() {
        this.pageNumber = 1;
        this.itemCount = null;
        this._maxItemCount = null;
    }

    get hasMoreThanOnePage() {
        return this.itemCount == null || this.itemCount > this.pageSize;
    }

    // best guess as to the total number of items
    get itemCountEstimate(): number {
        return this.itemCount != null ? this.itemCount : Number.MAX_VALUE;
    }

    updateEstimate(currentPageLength: number) {
        if (!this.itemCount) {
            if (currentPageLength < this.pageSize) {
                if ((currentPageLength == 0) && (this.pageNumber > 1)) {
                    this._maxItemCount = ((this.pageNumber - 1) * this.pageSize);
                } else {
                    this.itemCount = ((this.pageNumber - 1) * this.pageSize) + currentPageLength;
                }
            } else {
                this.itemCount = ((this.pageNumber - 1) * this.pageSize) + currentPageLength;
                this._maxItemCount = Math.max(this._maxItemCount, (this.pageNumber + 1) * this.pageSize);
            }
        }

        return this.itemCountEstimate;
    }

    updateLoadStatusMsg() {
        let r: string;
        if (this.loadStatus == PageLoadStatus.IsLoading) {
            r = 'Loading . . .';
        } else if (this.loadStatus == PageLoadStatus.IsLoadedWithNoData) {
            r = 'No matching ' + this.displayName + ' data found.';
        } else {
            const count = this.itemCount;
            if (count) {
                const wasWere = count > 1 ? ' were ' : ' was ';
                r = 'There ' + wasWere + this.itemCount + '  matching ' + pluralize(this.displayName, count) + ' found.';
            } else {
                r = '';
            }
        }
        this.loadStatusMsg = r;
    }

}





<h4>Reach Registrations</h4>

<editor-header [edm]="_edm" [femaRestricted]="true"></editor-header>

<default-table *ngIf="_edm.hasEntities()" [attr.data-hidden]="_edm.editing">
    <thead>
        <tr>
            <td>Type</td>
            <td [colSorter]="_colSorter">CAS No.</td>
            <td [colSorter]="_colSorter">Name</td>
            <td [colSorter]="_colSorter">Registration Type</td>
            <td>Total Tonnage Band</td>
            <td>Link to Dossier</td>
            <td>Source</td>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let e of _edm.entities" (click)="_edm.onSelect(e)">
            <td>Registered under REACH</td>
            <td>{{e.registrationCASNumber}}</td>
            <td><span [htmlGreek]="e.registrationName"></span></td>
            <td>{{e.registrationType}}</td>
            <td>{{e.typeREACHTonnageBand?.totalTonnageBand || '-'}}
            <td><a (click)="linkToDossier(e)">Dossier</a>
                <td><span>European Chemicals Agency, July 2014 - <a href="http://echa.europa.eu"  target="_blank">http://echa.europa.eu</a></span></td>
                <!-- <td>{{s.entityAspect.entityState}}</td> -->
        </tr>
    </tbody>
</default-table>

<div *ngIf="_edm.editing">
    <reach-registration #self [entity]="_edm.currentEntity"></reach-registration>
</div>
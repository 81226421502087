import {Component, OnInit} from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';

import { ErrorLogger, StateMap, UnitOfWork, UserManager } from '../../services/common';
import { UtilFns } from '../../utils/common';
import { EntityManagerProvider } from '../../services/entity-manager-provider';
import { ROUTES } from '../routes';
import { TypeSearch } from '../../entities/TypeSearch';
import * as _ from 'lodash';

@Component({
    selector: 'rifm-toolbar',
    templateUrl: './toolbar.html'
})
// tslint:disable-next-line:component-class-suffix
export class ToolBar implements OnInit {
    _searchTerm: string;
    _filters: TypeSearch[] = [];
    _filter: string = null;
    _defaultFilter: string = null;
    _noFilterData = true;
    _errorLogger: ErrorLogger;
    _nullSearchParts = UtilFns.asRouterParts(ROUTES.Home, ROUTES.Home.childRoutes.SearchResults);
    _typeSearches: TypeSearch[] = [];

    constructor(public _emProvider: EntityManagerProvider, public _userManager: UserManager,
                public _uow: UnitOfWork, public _stateMap: StateMap,
                public _router: Router, public _route: ActivatedRoute, public _location: Location
    ) {
        this._errorLogger = _emProvider.errorLogger;
    }

    ngOnInit() {
        return this._emProvider
            .prepare()
            .then(() => this._uow.typeSearchRepository.all())
            .then(data => {
                this._typeSearches = data;
                // at this point the user may not have logged in yet and the staff setting may not be known. The data are filtered in the get function.
                this.searchFilters = data;
                const defaults = this.searchFilters.filter(f => f.defaultOption);
                this._filter = this._defaultFilter = (defaults.length > 0) ? defaults[0].radioOptionId : this.searchFilters[0].radioOptionId;
                this._noFilterData = false;
                return data;
            });
    }

    refreshSearchOptions() {
        if (this.isStaff()) {
            this._filters = this._typeSearches; //
        }
    }

    logout() {
        return this._userManager.logout().then(() => {
            this._location.replaceState('/home?logout');
            document.location.reload();
        });
    }

    printBody() {
        const ele = document.querySelector('#main-body');
        UtilFns.printElement(ele);
    }

    isOnHomePage() {
        return _.endsWith(this._location.path(), 'home-page');
    }

    canToggleStaff() {
        return this._stateMap.currentUser.isReallyStaff;
    }

    isStaffOnlyPage() {
        return this._location.path().indexOf('staff/') >= 0;
    }

    toggleStaff() {
        this._stateMap.currentUser.toggleRoles();

        const parts = UtilFns.parseUrl(this._location.path());
        parts.paramHash['isStaff'] = this.isStaff().toString();
        this._stateMap.set('Search Results', null); // to insure that old results aren't reused.
        if (parts.base.indexOf('SearchResults') >= 0) {
            this._router.navigate(this._nullSearchParts);
            this.search();
            return;
        }

        this._location.go(UtilFns.formatUrl(parts));
        this._location.back();

    }

    isStaff() {
        return this._stateMap.currentUser.isStaff;
    }

    isAuthorizedDownloadUser() {
        return this._stateMap.currentUser.isAuthorizedDownloadUser;
    }

    goHome() {
        this._router.navigate(UtilFns.asRouterParts(ROUTES.Home));
    }

    goStaffEditing() {
        const parts = UtilFns.parseUrl(this._location.path());

        if (this._stateMap.currentUser.isInRole('fema') == true) { // FEMA staff can only edit Reference Protocol data
            const routeParts = [ `/${ROUTES.Staff.path}`, 'reference', 'info' ];
            this._router.navigate(routeParts);
        } else if (parts.base.indexOf('/material/') >= 0) {
            if (this._stateMap.currentMaterial != null) {
                const routeParts = [`/${ROUTES.Staff.path}`, 'material', this._stateMap.currentMaterial.materialId, 'info'];
                this._router.navigate(routeParts);
            } else {
                this._router.navigate(UtilFns.asRouterParts(ROUTES.Staff, ROUTES.Staff.childRoutes.Material));
            }
        } else if (parts.base.indexOf('/reference/') >= 0) {
            if (this._stateMap.currentReference != null) {
                const routeParts = [`/${ROUTES.Staff.path}`, 'reference', this._stateMap.currentReference.referenceId, 'info'];
                this._router.navigate(routeParts);
            } else {
                this._router.navigate(UtilFns.asRouterParts(ROUTES.Staff, ROUTES.Staff.childRoutes.Reference));
            }
        } else {
            this._router.navigate(UtilFns.asRouterParts(ROUTES.Staff));
        }
    }

    goDocManagement() {
        this._router.navigate(UtilFns.asRouterParts(ROUTES.DocumentManagement, ROUTES.DocumentManagement.childRoutes.RIFMDocuments));
    }

    goExposureAdmin() {
        this._router.navigate(UtilFns.asRouterParts(ROUTES.Exposure, ROUTES.Exposure.childRoutes.ExposureSurveys));
    }

    goMaterial() {
        if (this._stateMap.currentMaterial) {
            this._router.navigate(UtilFns.asRouterParts(ROUTES.Material, this._stateMap.currentMaterial.materialId));
        }
    }

    goReference() {
        if (this._stateMap.currentReference) {
            this._router.navigate(UtilFns.asRouterParts(ROUTES.Reference, this._stateMap.currentReference.referenceId));
        }
    }

    goSearchResults() {
        this._router.navigate(this._nullSearchParts);
    }

    canSearch() {
        return (this.isValidString(this._searchTerm));
    }

    search() {
        if (this._filter && this._searchTerm) {
            const parts = UtilFns.asRouterParts(ROUTES.Home, ROUTES.Home.childRoutes.SearchResults,
                this._filter.trim(),
                UtilFns.encodeRouterPart(this._searchTerm.trim()));
            this._router.navigate(parts);
        }
    }

    searchIfEnter(event: any) {
        if (event.keyCode == 13) {
            this.search();
        }
    }

    clear() {
        this._searchTerm = '';
        this._filter = this._defaultFilter;
    }

    hasErrors() {
        return this._stateMap.errors.length > 0;
    }

    canShowDocManagement() {

        if (this._stateMap.currentUser.isStaff) {
            if (this._stateMap.currentUser.isInRole('consultant') || this._stateMap.currentUser.isInRole('FEMA')) {
                return false;
            } else {
                return true;
            }
        }

        return false;
    }

    canShowExposureAdmin() {

        if (this._stateMap.currentUser.isStaff) {
            if (this._stateMap.currentUser.isInRole('consultant') || this._stateMap.currentUser.isInRole('FEMA')) {
                return false;
            } else {
                return true;
            }
        }

        return false;
    }

    get searchFilters() {
        if (this._filters == null || this._filters.length == 0) { return this._filters; }

        if (this._stateMap.currentUser.isNullUser || !this._stateMap.currentUser.isStaff) {
            return this._filters.filter(s => s.staffOnlyOption == false);
        } else {
            return this._filters;
        }
    }

    set searchFilters(value: TypeSearch[]) {
        this._filters = value;
    }

    get userReady(): boolean {
        return (this._stateMap.currentUser && !this._stateMap.currentUser.isNullUser);
    }
    // showErrors() {
    //     this._stateMap.exceptionDialog.showModal();
    // }

    isValidString(teststring: string) {
        if (!teststring) { return false; }

        return (teststring.trim().length > 0);
    }
}

import { Component, Input, OnInit, ElementRef, ViewChild, ContentChild, AfterViewInit } from '@angular/core';
import {provideParent, StateMap, UnitOfWork} from '../../services/common';
import {EditManager, IEditHost, UtilFns} from '../../utils/common';
import {EditPropParent, ModalDialog, PageState} from '../../controls/common';
import { Material, WorkflowMaterialRecordedAction } from '../../entities/EntityModels';

import * as _ from 'lodash';

@Component({
    selector: 'material-workflow-history',
    templateUrl: './material-workflow-history.html',
    providers: [MaterialWorkflowHistoryComponent, provideParent(MaterialWorkflowHistoryComponent, EditPropParent)]
})
export class MaterialWorkflowHistoryComponent extends ModalDialog<boolean> implements OnInit, AfterViewInit, IEditHost<WorkflowMaterialRecordedAction> {
    @Input() material: Material;
    @Input() activityType: string;

    public _workflowMaterialRecordedActions: WorkflowMaterialRecordedAction[];

    public _edm: EditManager<WorkflowMaterialRecordedAction>;
    public _isEditing = false;
    public _validationMessage = '';

    public _loading = false;

    constructor(elementRef: ElementRef, public _uow: UnitOfWork, private _stateMap: StateMap) {
        super(elementRef);
    }

    ngOnInit() {
        this._edm = new EditManager<WorkflowMaterialRecordedAction>(this, this._uow, this._stateMap, 'Workflow Material History');

        if (this.material != null) {
            if (this.activityType == 'reporteduse') {
                this.loadReportedUseData();
            } else {
                this.loadAllData();
            }
        }
    }

    ngAfterViewInit() {
    }

    public loadAllData(): Promise<any> {

        this._workflowMaterialRecordedActions = [];

        if (this.material == null) {
            return Promise.resolve();
        }

        this._loading = true;

        const params = { materialId: this.material.materialId };

        return this._uow.fetch('Workflow/FetchWorkflowMaterialRecordedActionsByMaterialId', params).then(r => {
            if (r != null) {
                this._workflowMaterialRecordedActions = r;
            }
            this._loading = false;
        });
    }

    public loadReportedUseData(): Promise<any> {

        this._workflowMaterialRecordedActions = [];

        if (this.material == null) {
            return Promise.resolve();
        }

        this._loading = true;

        const params = { materialId: this.material.materialId };

        return this._uow.fetch('Workflow/FetchWorkflowMaterialRecordedActionsForMaterialReportedUse', params).then(r => {
            if (r != null) {
                this._workflowMaterialRecordedActions = r;
            }
            this._loading = false;
        });
    }

    public get utilFns() {
        return UtilFns;
    }

    rowColor(idx: number) {
        return ((idx % 2) > 0) ? '#F0F8FF' : '#FFFFFF';
    }

    materialWorkflowChangeValue(action: WorkflowMaterialRecordedAction) {
          if (action.changeValueNumber == null && action.changeValueString == null) {
              return '';
          }

          if (action.changeValueNumber != null) {
              return (action.typeWorkflowMaterialAction.formatDecimals) ?  action.changeValueNumber.toFixed(4) : action.changeValueNumber;
          }

          return (action.changeValueString);
    }

    // ***************************************************************************************************
    // edit manager overrides
    // ***************************************************************************************************
    canApply() {
        if (this._edm.editing && this._uow.hasChanges()) {
            if (this._edm.currentEntity != null) {
                return (!this._edm.currentEntity.entityAspect.hasValidationErrors);
            }
            return true;
        }
        return false;
    }

    canCancel() {
        return (this._uow.hasChanges());
    }

    canClose() {
        return (!this._isEditing);
    }

    canSave() {
        if (this._edm.editing) { return false; }
        if (!this._edm.hasEdits()) { return false; }
        if (!this._edm.validateBeforeSave()) { return false; }

        return true;
    }

    canShowBack() {
        if (!this._edm.editing) { return false; }
        return (!this._uow.hasChanges());
    }

    canShowEditSection() {
        return (this._edm.currentEntity != null);
    }

    onApply() {
        this._validationMessage = '';

        if (this._edm.editing) {
            if (this._edm.currentEntity.userNotes.trim() == '') { this._edm.currentEntity.userNotes = null; } // replace empty strings with null
            this._edm.onApplyCore();
        }
        return true;
    }

    onBack() {
        this._edm.editing  = false;
        this._edm.currentEntity = null;
        this._isEditing = false;
    }

    onCancel() {
        this._validationMessage = '';

        this._edm.onCancelCore();
        this._edm.currentEntity = null;
        this._isEditing = false;
    }

    onEdit(wmra: WorkflowMaterialRecordedAction) {
        this._edm.onlyEntity = wmra;
        this._edm.editing = true;
        this._isEditing = true;
    }

    onSave() {
        if (this._edm.hasEdits()) {
            this._edm.onSaveCore().then(() => {

                this._edm.editing = false;
                this._isEditing = false;

                if (this.activityType == 'reporteduse') {
                    this.loadReportedUseData();
                } else {
                    this.loadAllData();
                }
            });
        }
    }

    // ***************************************************************************************************
    // Close Modal
    // ***************************************************************************************************
    onClose() {
        if (!this.canClose()) { return; }

        UtilFns.returnModal(this, false);
    }
}

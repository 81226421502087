<div class="flex-container background-transparent">
    <div class="flex-item" style="width: 10%;">
        <a class="bold item-selector-toggle" (click)="onSetSelectorVisibility()">{{rifmDocumentSelectorNextAction}}</a>
    </div>

    <div class="flex-item">
        <i *ngIf="_isLoading" class="fa fa-circle-o-notch fa-spin" style="font-size:38px; color: #337ab7;"></i>
    </div>
</div>

<div class="flex-container background-transparent" style="margin-top: 5px; margin-left: -15px; width: 100%;">

    <div *ngIf="showRIFMDocumentSelector" class="flex-item" [ngStyle]="{ 'width': _selectorWidth, 'display': _selectorDisplay}"
         style="border-right: 1px solid #6E7B8B; height:auto;">
        <div class="custom-body" style="width: 98%;">
            <span class="inline">
                <span class="bold">Search and Restore Archived Reference Documents.</span>
                <img style="margin-left: 10px;" class="inline" src="assets/images/Search.png" height="24px" />
            </span>
            <p class="alert-message">{{_validationMessage}}</p>
            <archived-document-selector [showSelectors]="true" (stateChange) ="archivedDocumentSelectorEventHandler($event)"></archived-document-selector>
        </div>
    </div>

    <div class="flex-item" [ngStyle]="{ 'width': _editorWidth}">
        <div class="custom-body" style="width: 98%; margin-top: -10px;">
            <span class="inline">
                <span class="bold">View Archived Reference Documents.</span>
                <img style="margin-left: 10px;" class="inline" src="assets/images/ArchivedPDFDocument.png" height="48px" />
            </span>
        </div>

        <pdf-editor></pdf-editor>
    </div>
</div>

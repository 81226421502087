<div class="inline">
    <p class="inline bold publication-title">Reassign Journal References from </p>
    <p class="inline bold publication-title highlighted-text-blue">{{pageTitle}}</p>
    <p class="inline subtitle-margin-top publication-title bold" style="margin-left: 12px;">{{"( " + getReferenceCountText() + ")"}}</p>
</div>

<div class="row">
    <editor-header [edm]="_edm" [customButtons]="true">
        <span customButtons class="inline" style="margin-left: 16px; margin-top: 6px;">
            <button *ngIf="canSave()" type="button" class="btn btn-sm btn-primary btn-image-height" (click)="onSave()">Save</button>
            <button *ngIf="canShowBack()" type="button" class="btn btn-sm btn-primary btn-image-height" (click)="onBack()">Back</button>
            <button *ngIf="canApply()" type="button" class="inline btn btn-confirm-reassign-references" style="width: 154px;" [disabled]="!canApply()" (click)="onApply()">Confirm Reassign</button>
            <button *ngIf="canCancel()" type="button" class="btn btn-sm btn-primary btn-image-height" (click)="onCancel()">Cancel</button>
        </span>
    </editor-header>
</div>

<p class="alert-danger" style="width: 50%;">{{_validationMessage}}</p>

<div class="flex-container background-transparent" style="width: 100% !important; margin-top: 18px;">

    <div class="flex-item">
        <div>
            <p class="page-subtitle bold">References Selected for Reassignment</p>
            <default-table *ngIf="_selectedReferences != null && _selectedReferences.length > 0">
                <div header style="margin-top: 10px;"></div>
                <thead style="background-color: #337ab7;">
                    <tr>
                        <td>ReferenceId</td>
                        <td>Brief Reference</td>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let r of _selectedReferences">
                        <td>{{r.referenceId}}</td>
                        <td>{{r.briefReference}}</td>
                    </tr>
                </tbody>
            </default-table>

        </div>
    </div>

    <div class="flex-item">
        <img class="" style="width: 110px; height: 40px;" src="assets/images/ReassignJournalArrow.png" />
    </div>

    <div class="flex-item">
        <p class="bold display-block publication-title highlighted-text-green" style="margin-top: 6px; vertical-align: bottom;">{{reassignJournalName}}</p>
    </div>

    <div class="flex-item">
        <button type="button display-block" class="btn btn-md btn-success inline" style="margin-left: 32px; vertical-align: middle;" (click)="onSearchForReassignJournal()">Search Journal for Assignment</button>
    </div>

</div>


<journal-selector></journal-selector>

<div class="row">
    <i *ngIf="_isLoading" class="fa fa-circle-o-notch fa-spin" style="font-size:38px; color: #337ab7; margin-left: 24px;"></i>
</div>
<p *ngIf="_savingUnfinishedReferenceDuringCopy" class="bold alert-message">'Please wait while the Reference is being updated'</p>
<p class="bold alert-message">{{_informationalMessage}}</p>
<div>
    <div style="margin-top: 24px; padding: 4px; width: 960px;">
        <p class="page-title bold" style="margin-left: 4px; margin-bottom: 4px;">You chose this material as the Experimental Material template:</p>
        <div class="background-aliceblue" style="padding: 4px;">
            <div class="flex-container"  style="background-color: transparent; margin-left: 18px;">
                <div class="flex-item">
                    <span class="bold background-aliceblue" style="margin-left: 2px;">CAS No:</span>
                    <span class="left-indent">{{expMaterial.material?.realCASNumber}}</span>
                </div>
                <div class="flex-item">
                    <span class="bold background-aliceblue">RIFM ID:</span>
                    <span class="left-indent">{{expMaterial.material?.formattedRIFMId}}</span>
                </div>
                <div class="flex-item">
                    <span class="bold background-aliceblue">Name:</span>
                    <span class="left-indent">{{expMaterial.material?.principalSynonym().synonymWordOrWebVersion()}}</span>
                </div>
            </div>

            <div style="clear: left; margin-left: 24px;">
                <span class="left-indent bold page-subtitle">{{'There are (' + experimentalResultsTemplateCount() + ') Experimental Results assigned to this Experimental Material.'}}</span>
            </div>
        </div>
    </div>

    <div *ngIf="showStep1() || showStep2()" style="margin-top: 40px; width: 100%; clear: left;">
        <div>
            <img  *ngIf="showStep1()" height="44px" width="44px" style="vertical-align:middle" src="assets/images/CircledNumbers1.png" alt="Step (1)" alt="Step (1)">
            <img  *ngIf="showStep2()" height="44px" width="44px" style="vertical-align:middle" src="assets/images/CircledNumbers2.png" alt="Step (2)" alt="Step (2)">
            <span class="bold left-indent">{{_userMessage}}</span>
        </div>
        <div class="choose-materials-for-toxcopy-grid" style="background-color: transparent; margin-top: 10px;">
            <div class="item-select-materials-to-copy-buttons">
                <button type="button" class="btn btn-primary" style="margin: 0 10px" (click)="onSelectingMaterial()" [disabled]="!canSelectMaterials()">Select Material</button>
                <button type="button" class="btn btn-primary" style="margin-left: 10px;" (click)="onSelectingExperimentalMaterial()" [disabled]="!canSelectMaterials()">Select Experimental Materials</button>
            </div>

            <div class="item-selected-materials-to-copy-table" style="margin-left: 12px;">
                <div style="margin-bottom: 10px;">
                    <p class="bold">Selected Materials for New Experimental Materials </p>
                    <p class="bold alert-message">{{_materialWarning}}</p>

                    <default-table *ngIf="_selectedMaterialIdentifiersItems != null && _selectedMaterialIdentifiersItems.length > 0">
                        <div header style="margin-top: 10px;"></div>
                        <thead>
                        <tr>
                            <td *ngIf="canRemoveSelectedMaterials()"></td>
                            <td class="text-center">CAS No.</td>
                            <td class="text-center">RIFM ID</td>
                            <td class="text-left">Principal Synonym</td>
                        </tr>
                        </thead>
                        <tbody>
                        <tr *ngFor="let m of _selectedMaterialIdentifiersItems">
                            <td class="text-center" *ngIf="canRemoveSelectedMaterials()"><a (click)="removeMaterial(m)">Remove</a></td>
                            <td class="text-center">{{m.realCASNumber}}</td>
                            <td class="text-center">{{m.formattedRifmId}}</td>
                            <td class="text-left" [htmlGreek]="m.synonymWordOrWebVersion"></td>
                        </tr>
                        </tbody>
                    </default-table>
                </div>


                <div>
                    <p class="bold page-subtitle">Selected Experimental Materials to be Updated</p>

                    <p class="bold alert-message">{{_expMaterialWarning1}}</p>
                    <p class="bold alert-message">{{_expMaterialWarning2}}</p>

                    <default-table *ngIf="viewableExperimentalMaterials != null && viewableExperimentalMaterials.length > 0">
                        <div header style="margin-top: 10px;"></div>
                        <thead>
                        <tr>
                            <td *ngIf="canRemoveSelectedMaterials()"></td>
                            <td class="text-center">CAS No.</td>
                            <td class="text-center">RIFM ID</td>
                            <td class="text-left">Principal Synonym</td>
                        </tr>
                        </thead>
                        <tbody>
                        <tr *ngFor="let em of viewableExperimentalMaterials">
                            <td class="text-center" *ngIf="canRemoveSelectedMaterials()"><a (click)="removeExperimentalMaterial(em)">Remove</a></td>
                            <td class="text-center">{{em.material?.realCASNumber}}</td>
                            <td class="text-center">{{em.material?.formattedRIFMId}}</td>
                            <td class="text-left break-word" [htmlGreek]="em.material?.principalSynonymText()"></td>
                        </tr>
                        </tbody>
                    </default-table>

                </div>
            </div>

            <div class="item-create-expmaterials-button" style="margin-top: 14px;">
                <button *ngIf="canCreateExperimentalMaterials()"
                        type="button"
                        class="btn btn-create-experimental-materials vertical-middle"
                        style="border-radius: 5px; margin-left: 0px; width: 320px;"
                        (click)="createExperimentalMaterialsFromTemplate()">
                    Create or Update Experimental Materials
                </button>
                <span *ngIf="canSaveOrCancelExperimentalMaterials()" class="inline" style="border: 4px solid #C1FFC1; padding: 8px;">
                    <button type="button" class="btn btn-sm btn-primary" (click)="onSaveExperimentalMaterials()">Save Experimental Materials</button>
                    <button type="button" class="btn btn-sm btn-primary" (click)="onCancelExperimentalMaterials()" style="background-color: #FFA500;">Cancel</button>
                </span>
            </div>
        </div>
    </div>

    <div *ngIf="showStep3()" style="margin-top: 20px; clear: left;">
        <div>
            <img height="44px" width="44px" style="vertical-align:middle" src="assets/images/CircledNumbers3.png" alt="Step (3)">
            <span class="bold left-indent">{{_userMessage}}</span>
        </div>
        <div class="flex-container" style="background-color: transparent; margin-top: 10px;">
            <div class="flex-item">
                <default-table *ngIf="_edmExpMat.entities != null && _edmExpMat.entities.length > 0">
                    <div header style="margin-top: 10px;"></div>
                    <thead>
                    <tr>
                        <td class="text-center">CAS No.</td>
                        <td class="text-center">RIFM ID</td>
                        <td class="text-left">Principal Synonym</td>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let em of _edmExpMat.entities">
                        <td class="text-center">{{em.material?.realCASNumber}}</td>
                        <td class="text-center">{{em.material?.formattedRIFMId}}</td>
                        <td class="text-left break-word" [htmlGreek]="em.material?.principalSynonymText()"></td>
                    </tr>
                    </tbody>
                </default-table>
            </div>

            <div class="flex-item">
                <button *ngIf="canCreateExperimentalResults()"
                        type="button"
                        class="btn btn-create-experimental-results vertical-middle"
                        style="border-radius: 5px; margin-left: 0px; margin-top: 10px; width: 240px;"
                        (click)="createExperimentalResultsFromTemplate()">
                    Create Experimental Results
                </button>
                <span *ngIf="canSaveOrCancelExperimentalResults()" class="inline" style="border: 4px solid #C1FFC1; padding: 8px;">
                    <button type="button" class="btn btn-sm btn-primary" (click)="onSaveExperimentalResults()">Save Experimental Results</button>
                    <button type="button" class="btn btn-sm btn-primary" (click)="onCancelExperimentalResults()" style="background-color: #FFA500;">Cancel</button>
                </span>
            </div>
        </div>
    </div>

    <div *ngIf="showStep4()" style="margin-top: 20px; clear: left;">
        <ul class="list-without-bullets">
            <li>
                <div>
                    <img height="48px" width="48px" style="vertical-align:middle" src="assets/images/CopyDone.png" alt="Step (4)">
                    <span class="bold left-indent">{{_userMessage}}</span>
                </div>
            </li>
            <li>
                    <span class="inline" style="margin-left: 52px;">
                    		<span class="workflow-asterisk inline vertical-top">*</span>
							<span class="bold" style="margin-left: 8px;">{{_finalEMCountMessage}}</span>
                    </span>
            </li>
            <li>
                    <span class="inline" style="margin-left: 52px;">
                    		<span class="workflow-asterisk inline vertical-top">*</span>
                            <span class="bold" style="margin-left: 8px;">{{_finalERCountMessage}}</span>
                    </span>
            </li>
        </ul>
    </div>
</div>

<material-selector></material-selector>
<protocol-material-selector></protocol-material-selector>

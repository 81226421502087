import { Validator } from 'breeze-client';

export class EntityTypeAnnotation {
    validators: Validator[];
    propertyAnnotations: EntityPropertyAnnotation[];
    
    constructor(arg: { validators: Validator[], propertyAnnotations: EntityPropertyAnnotation[] }) {
        this.validators = arg.validators
        this.propertyAnnotations = arg.propertyAnnotations;
    }    
}

export class EntityPropertyAnnotation {
    displayName: string;
    validators: Validator[];
    constructor(public propertyName: string, config: {
        displayName: string,
        validators: Validator[],  
    }) {
        this.displayName = config.displayName;
        this.validators = config.validators;
    } 

}
<div class="row no-print">
    <rifm-toolbar></rifm-toolbar>

    <div class="row no-print">
        <div id="main-body" >
            <router-outlet></router-outlet>
        </div>
    </div>

    <login></login>

    <yes-no-dialog></yes-no-dialog>   
    <exception-dialog></exception-dialog> 
</div>    

import { Component, OnInit,ViewChild, Inject, forwardRef } from '@angular/core';
import * as _ from 'lodash';

import { UnitOfWork, StateMap, EditorService } from '../../services/common';
import { EditManager } from '../../utils/common';
import { EditPropParent } from '../../controls/edit-prop.component';

import { Prediction } from '../../entities/EntityModels';
import { MaterialEditorComponent, MaterialEditData} from './material-editor.component';
import { PredictionCramerEditorComponent} from './prediction-cramer-editor.component';

@Component({
    selector: 'predictions-cramer-editor',
    templateUrl: './predictions-cramer-editor.html',
})
export class PredictionsCramerEditorComponent implements OnInit, EditPropParent {
    @ViewChild(PredictionCramerEditorComponent) _editingComponent: PredictionCramerEditorComponent;

    _adding: boolean = false;
    _data: MaterialEditData;
    _edmPredictions: EditManager<Prediction>;
    readonly _tabNumber: number = 1;

    constructor( @Inject(forwardRef(() => MaterialEditorComponent)) public _parent: MaterialEditorComponent,
        public _uow: UnitOfWork, public _stateMap: StateMap, public _editorService: EditorService) {

        this._data = _parent._data;
        this._data.onCancelMap['base']  = this.onCancel.bind(this);
        this._data.onSaveMap['base']    = this.onSave.bind(this);
    }

    ngOnInit() {

        this._edmPredictions = this._editorService.createEditManager<Prediction>(this, "Prediction");

        if (this._data.entity) {
            this._edmPredictions.entities = this._data.entity.predictions.slice(0);
        }

        this._edmPredictions.editing    = false;
        this._data.inSubEditor          = false;

        //this._data.currentTab = this._tabNumber;
    }

    getError(propName: string) {
        var r = this._data.entity.getErrorFor(propName);
        return r;
    }

    isStaff() {
        return this._stateMap.currentUser.isStaff;
    }

    //Events
    canApply() {

        if (this._edmPredictions.editing) {
            return (this._uow.hasChanges() && !this._edmPredictions.currentEntity.entityAspect.hasValidationErrors);
        }

        return false;
    }

    canCancel() {
        return (this._uow.hasChanges());
    }

    canShowBack() {
        return !this._uow.hasChanges();
    }

    onAdd() {
        this._adding            = true;
        this._data.inSubEditor  = true;

        let prediction              = this._uow.predictionFactory.create();
        prediction.materialId       = this._data.entity.materialId;
        prediction.typePredictionId = 0;
        prediction.enabled          = true;

        this._edmPredictions.entities.push(prediction);
        this._edmPredictions.onSelect(prediction);
    }

    onApply() {
        if (this._edmPredictions.editing) {
            this._edmPredictions.onApplyCore();

            this._adding            = false;
            this._data.inSubEditor  = false;
        }
    }

    onBack() {
        this._adding                    = false;
        this._edmPredictions.editing    = false;
        this._data.inSubEditor          = false;
    }

    onCancel() {
        this._edmPredictions.clearErrors();

        this._adding                    = false;
        this._edmPredictions.editing    = false;
        this._data.inSubEditor          = false;
        this._edmPredictions.entities   = this._data.entity.predictions.slice(0);
    }

    onDeletePrediction(prediction: Prediction) {

        if (prediction.entityAspect.entityState.isAdded()) {
            prediction.entityAspect.rejectChanges();
        } else {
            prediction.entityAspect.setDeleted();
        }
        _.remove(this._edmPredictions.entities, prediction);

    }

    onEdit() {
        if (this._data.entity) {
            this._edmPredictions.entities = this._data.entity.predictions.slice(0);
        }

        this._edmPredictions.editing    = false;
        this._data.inSubEditor          = false;
    }

    onEditPrediction(prediction: Prediction) {
        this._edmPredictions.onSelect(prediction);
        this._data.inSubEditor          = true;
        this._edmPredictions.editing    = true;
    }

    onSave() {
        this._adding                    = false;
        this._data.inSubEditor          = false;
        this._edmPredictions.editing    = false;
        this._edmPredictions.entities   = this._data.entity.predictions.slice(0);
    }
}

<editor-header [edm]="_edm" [femaRestricted]="true"></editor-header>

<div class="row">
    <i *ngIf="_isLoading" class="fa fa-circle-o-notch fa-spin" style="font-size:38px; color: #337ab7; margin-left: 24px;"></i>
</div>

<p class="alert-warning bold">{{_validationMessage}}</p>

<div *ngIf="showUVFileMessage()">
    <span class="bold page-subtitle inline" style="color: #2E6DA4">There are pending UV file changes that will be processed when the Save button is clicked.</span>
</div>

<div  *ngIf="referenceFinishedStatesHaveChanged()" style="margin-left: -8px; margin-bottom: 4px;">
    <span class="workflow-automatic-finish-label">{{_finishedMessage}}</span>
    <span class="bold page-subtitle">{{_uvsFileSavedMessage}}</span>
</div>

<default-table *ngIf="_edm.hasEntities()" [attr.data-hidden]="_edm.editing">
    <thead>
        <tr>
            <td>Procedure/Method</td>
            <td>Result</td>
            <td>Source</td>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let ent of analyticalResults" (click)="selectAnalyticalResult(ent)">
            <td><span [htmlGreek]="ent.typeAnalyticalProcedure?.analyticalProcedure || '-'"></span></td>
            <!--<td><div [outerHTML]="ent.observedResult | formatUVFiles"></div></td>            -->
            <td *ngIf="ent.typeAnalyticalProcedureID != 'UVS'"><span [htmlGreek]="ent.observedResult"></span></td>
            <td *ngIf="ent.typeAnalyticalProcedureID == 'UVS'">
                <span *ngFor="let uvs of ent.uvsFileList; let ix=index">
                    <a (click)="downloadUVSFile(uvs);$event.stopPropagation()">{{'UVS' + (ix+1) + ' '}}</a>
                </span>
            </td>
            <td *ngIf="ent.reference"><a (click)="navToRef(ent);stopProp($event)">{{formatAnalyticalSource(ent)}}</a></td>
            <td *ngIf="!ent.reference">{{ent.analyticalSource}}</td>
            <!-- <td>{{s.entityAspect.entityState}}</td> -->
            
        </tr>
    </tbody>
</default-table>

<div *ngIf="_edm.editing">
    <analytical-result #self [entity]="_edm.currentEntity"></analytical-result>
</div>

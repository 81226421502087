import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { ColumnSorter } from './column-sorter';
import * as _ from 'lodash';

// Example:
// <td> <a [colSorter]="_colSorter">Author <a></td>
@Directive({
    selector: '[colSorter]',
    // tslint:disable-next-line:use-host-property-decorator
    host: {
        '(click)': 'clickColumnHeader()',
    }

})
export class ColumnSorterDirective implements OnInit {
    @Input() colSorter: ColumnSorter;

    _colName: string;
    _iconChild: HTMLElement;

    constructor(public _el: ElementRef) {

    }

    ngOnInit() {
        const ne = this._el.nativeElement;
        if (ne.className.indexOf('sort-column') == -1) {
            ne.className = ne.className + ' sort-column';
        }
        this._colName = ne.textContent.trim();
        const spans = ne.getElementsByTagName('span');
        if (spans.length == 0) {
            this._iconChild = document.createElement('span');
            ne.appendChild(this._iconChild);
        } else {
            this._iconChild = spans[0];
        }
        this.colSorter.iconMap[this._colName] = this._iconChild;
        this.updateClasses();
    }

    clickColumnHeader() {
        this.colSorter.applySort(this._colName);
        this.updateClasses();
    }

    updateClasses() {
        _.forEach(this.colSorter.iconMap, (iconChild, colName) => {
            (<any>iconChild).className = this.colSorter.getClasses(colName);
        });
    }
}

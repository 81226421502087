<div class="subtitle-margin-top">
    <div *ngIf="_edm.editing" class="inline">
        <p class="inline bold">{{_adding ? "Adding an External Material to the Exposure Survey List" : "Editing an External Material from the Exposure Survey List" }}</p>
        <img style="margin-left: 10px; height: 32px; width: 30px;" class="inline" src="assets/images/RIFMFragranceBottle.png" />
    </div>
</div>
<br />

<div class="row">
    <i *ngIf="_isLoading" class="fa fa-circle-o-notch fa-spin" style="font-size:38px; color: #337ab7;"></i>
</div>

<h4 *ngIf="canAddToMaterialList()">Add an External Material to the Survey List</h4>

<div *ngIf="_validationMessage">
    <p class="alert-danger">{{_validationMessage}}</p>
</div>

<div style="margin-top: 18px;">
    <editor-header [edm]="_edm" [customButtons]="true">
        <span customButtons>
            <button *ngIf="!_edm.editing" type="button" class="btn btn-sm btn-primary" [disabled]="!canSave()" (click)="onSave()">Save</button>
            <button *ngIf="_edm.editing" type="button" class="btn btn-sm btn-primary" [disabled]="!canApply()" (click)="onApply()">Apply</button>
            <button *ngIf="canCancel()" type="button" class="btn btn-sm btn-primary" (click)="onCancel()">Cancel</button>
            <button *ngIf="canShowBack()" type="button" class="btn btn-sm btn-primary" (click)="onBack()">Back</button>
        </span>
    </editor-header>
</div>

<div *ngIf="!_edm.editing">
    <div style="margin-top: 6px; margin-left: -10px; width: 86%;">
        <button type="button" *ngIf="canAddToMaterialList()" class="inline btn btn-survey-external-material" style="width:165px;" (click)="onAdd()">Add External Material</button>
    </div>

    <div style="width: 990px; margin-top: 2px;">
        <div *ngIf="_exposureAdditionalMaterialSurveysEx == null || _exposureAdditionalMaterialSurveysEx.length < 1">
            <h5 class="clear text-left">No External Materials have been added yet.</h5>
        </div>
        <div *ngIf="hasData()">
            <p class="bold">External Materials Added to the Material List</p>

            <div style="width: 1205px;">
                <div class="scrollableTableHeader">
                    <div class="scrollableTableColumn text-center"><span class="inline" style="width: 60px">Edit</span></div>
                    <div class="scrollableTableColumn text-center"><span class="inline" style="width: 55px">Remove</span></div>
                    <div class="scrollableTableColumn text-center"><span class="inline" style="width: 85px">CAS No.</span></div>
                    <div class="scrollableTableColumn text-center"><span class="inline" style="width: 75px">MaterialId</span></div>
                    <div class="scrollableTableColumn text-left"><span class="inline" style="width: 300px">Name</span></div>
                    <div class="scrollableTableColumn text-left"><span class="inline" style="width: 150px">Notes</span></div>
                    <div class="scrollableTableColumn text-center"><span class="inline" style="width: 105px">Response Type</span></div>
                    <div class="scrollableTableColumn text-center"><span class="inline" style="width: 60px">Banned Material</span></div>
                    <div class="scrollableTableColumn text-center"><span class="inline" style="width: 75px">Add Measures</span></div>
                </div>
                <div style="max-height: 300px; width: 1195px; overflow-y:auto; overflow-x:hidden;">
                    <div class="scrollableTableRow" *ngFor="let eam of _exposureAdditionalMaterialSurveysEx"
                         [ngStyle]="{ 'background-color': eam.backgroundColor }">
                        <div class="scrollableTableColumn text-center"><a class="inline" style="width: 60px" (click)="onEdit(eam)">Edit</a></div>
                        <div class="scrollableTableColumn text-center"><a class="inline" style="width: 55px" (click)="onRemove(eam)">Remove</a></div>
                        <div class="scrollableTableColumn text-center"><span class="inline" style="width:85px;">{{eam.data.exposureAdditionalMaterial?.cASNumber}}</span></div>
                        <div class="scrollableTableColumn text-center"><span class="inline" style="width:75px;">{{eam.data.exposureAdditionalMaterial?.exposureAdditionalMaterialId}}</span></div>
                        <div class="scrollableTableColumn text-center"><span class="inline" style="width: 300px">{{eam.data.exposureAdditionalMaterial?.materialName}}</span></div>
                        <div class="scrollableTableColumn text-center"><span class="inline" style="width:150px;">{{eam.data.notes}}</span></div>
                        <div class="scrollableTableColumn text-center"><span class="inline" style="width:105px;">{{eam.data.typeExposureSurveyResponse?.responseType}}</span></div>
                        <div class="scrollableTableColumn text-left"><span class="inline" style="width:60px;">{{eam.data.bannedMaterialCaption}}</span></div>
                        <div class="scrollableTableColumn text-center"><a class="inline" style="width: 75px" (click)="onShowAdditionalMaterialMeasures(eam)">Measures</a></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="_edm.editing">
    <exposure-additional-material-survey-editor #self [entity]="_edm.currentEntity"></exposure-additional-material-survey-editor>
</div>

import { EntityBase } from './EntityBase';
import { TypeLinkTarget } from './TypeLinkTarget';
import { RIFMProductLicense } from './RIFMProductLicense';

import { Validator } from 'breeze-client';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

export class RIFMProduct extends EntityBase {

   /// <code> Place custom code between <code> tags
   
   /// </code>

   // Generated code. Do not place code below this line.
   productId: number;
   productName: string;
   productDescription: string;
   productVersion: string;
   productLink: string;
   linkTargetType: string;
   enabled: boolean;
   contentType: string;
   created: Date;
   createdUser: string;
   modified: Date;
   modifyUser: string;
   rowVersion: number;
   rIFMProductLicenses: RIFMProductLicense[];
   typeLinkTarget: TypeLinkTarget;
}


<div>
    <div class="workflow-stats-filters-container">
        <div class="flex-container">
            <div class="flex-item">
                <span class="bold">Assigned To: </span>
                <select class="dropdown-toggle list-background-white" [ngModel]="filterName" (change)="filterContactChange($event)">
                    <option *ngFor="let contact of _typeWorkflowContacts; let ix = index" class="dropdown" [value]="contact.firstName">{{contact.firstName}}</option>
                </select>
            </div>

            <div class="flex-item">
                <span class="bold">Created By:</span>
                <select class="dropdown-toggle list-background-white" [ngModel]="filterCompletedByName" (change)="filterCompletedByContactChange($event)">
                    <option *ngFor="let contact of _typeCompletedByContacts; let ix = index" class="dropdown" [value]="contact.firstName">{{contact.firstName}}</option>
                </select>
            </div>

            <div class="flex-item">
                <span class="bold">Created Date:</span>
            </div>

            <div class="flex-item">
                <datepicker>
                    <input type="Date" [(ngModel)]="fromDate" />
                </datepicker>
                <br />
                <span class="small bold italic">From date</span>
            </div>

            <div class="flex-item">
                <datepicker>
                    <input type="Date" [(ngModel)]="toDate" />
                </datepicker>
                <br />
                <span class="small bold italic">To date</span>
            </div>
        </div>

        <div class="flex-container">
            <div class="flex-item">
                <button type="button" class="btn btn-sm btn-primary" (click)="filterResults()">Apply Filter</button>
                <button type="button" class="btn btn-sm btn-primary" (click)="filterClear()">Clear Filter</button>
                <button *ngIf="canViewStaffReport()" type="button" class="btn green-button" (click)="showWorkflowActivityReport()">Show Activity Report</button>
                <button *ngIf="canViewStaffReport()" type="button" class="btn green-button" (click)="showSearchServiceReport()">Download Search Service Report</button>
            </div>
            <div class="flex-item">
                <span class="alert-danger">{{_filterErrorMsg}}</span>
            </div>
        </div>
    </div>

    <div class="row">
        <i *ngIf="_isLoading" class="fa fa-3x fa-circle-o-notch fa-spin" style="font-size:28px;"></i>
        <div *ngIf="_isLoading" class="bold alert alert-warning workflow-alert">
            {{stillLoadingMsg}}
        </div>

        <div class="workflow-stats-left" style="border-right: 1px solid #D3CCBF;">
             <h5 class="bold" *ngIf="filterName">
                {{'Summary Counts for ' + filterName}}
            </h5>
                       
            <div style="font-size: 12px;">
                <div *ngFor="let lc of _pendingTaskCounts; let ix = index" class="indent-left-sm">
                    <div class="label-unselected">
                        <input style="margin-top: 3px;" type="checkbox" (change)="onDisplayAction(ix)" [(ngModel)]="lc.selected">
                        <span style="padding: 4px;">{{lc.label + " (" + lc.count + ")"}}</span>
                    </div>
                </div>
            </div>
        </div>

        <div class="workflow-stats-right">
            <div *ngIf="_hasWorkflowData">
                <h5 class="bold" *ngIf="filterName">
                    {{'Pending Task Details for ' + filterName}}
                </h5>
                <div class="workflow-stats-button-container">
                    <button type="button" class="btn btn-primary btn-xs vertical-top" (click)="expandAll(true)">Expand All</button>
                    <button type="button" class="btn btn-primary btn-xs vertical-top" (click)="expandAll(false)">Collapse All</button>
                    <span class="inline">
                    		<span class="workflow-asterisk inline vertical-top">*</span>
	                    	<span class="inline bold vertical-middle" style="margin-top: 1px;">indicates a reference with tox data that is still unproofed.</span>
                    		<span style="margin-top: -6px; margin-left: 2px;" class="workflow-asterisk inline vertical-top">H</span>
	                    	<span class="inline bold vertical-middle" style="margin-top: 1px;">indicates a reference that is marked as On Hold.</span>
                    </span>
                </div>
                <div class="workflow-stats-pending" *ngFor="let group of _workflowGroups; let ix=index">
                    <div class="panel panel-default">
                        <div class="panel-body" style="padding-top: 0px;">
                            <div class="row text-center bold thead">
                                <div class="col-sm-1">
                                    <a (click)="workflowGroupCollapse(ix)">
                                        <span *ngIf="group.collapsed" class="fa fa-caret-down" style="color: #ffffff"></span>
                                        <span *ngIf="!group.collapsed" class="fa fa-caret-up" style="color: #ffffff"></span>
                                    </a>
                                </div>
                                {{group.name}} {{'(' + group.pendingWorkflowRecordedActions.length + ')'}}
                            </div>
                            <table class="pending-workflow-table small table-striped" [attr.data-hidden]="group.collapsed">
                                <thead class="workflow-thead">
                                    <tr>
                                        <td>Ref Id</td>
                                        <td></td>
                                        <td></td>
                                        <td>Brief Reference</td>
                                        <td>Type</td>
                                        <td>RIFM/FEMA</td>
                                        <td>Priority</td>
                                        <td>Created By</td>
                                        <td>Title</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let action of group.pendingWorkflowRecordedActions">
                                        <td class="vertical-top"><a class="bold" (click)="navToRef(action)">{{action.referenceId}}</a></td>
                                        <td class="nopadding vertical-top"><span *ngIf="action.incompleteWithToxData" class="workflow-asterisk">*</span></td>
                                        <td class="nopadding vertical-top"><span *ngIf="action.onHold" class="on-hold-text">H</span></td>
                                        <td class="vertical-top">{{action.briefReference}}</td>
                                        <td class="vertical-top"><span *ngIf="action.rifmAsAuthor" class="rifm-article">R</span> <span *ngIf="action.restrictedDistribution" class="member-article">M</span></td>
                                        <td class="vertical-top"><span class="text-center">{{action.articleType}}</span></td>
                                        <td class="vertical-top">{{action.precedence}}</td>
                                        <td class="vertical-top" width="70px">{{action.createdContactFirstName}}</td>
                                        <td class="vertical-top">
                                            <span class="ref-title-wrap">{{action.title}}</span>
                                            <span *ngIf="action.holdReason" class="ref-title-wrap">{{"Hold Reason: " + action.holdReason}}</span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<workflow-activity-report *ngIf="_reportItems" [reportItems]="_reportItems"> </workflow-activity-report>


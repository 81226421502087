import { Component, ElementRef } from '@angular/core';

import { UnitOfWork } from '../../services/common';
import { UtilFns } from '../../utils/common';
import { ModalDialog, PageState } from '../../controls/common';

import { SurveyWhoUsedMostMaterial } from '../../entities/EntityModels';

@Component({
    selector: 'survey-whousedmost',
    templateUrl: './survey-whousedmost.html',
})

export class SurveyWhoUsedMostComponent extends ModalDialog<SurveyWhoUsedMostMaterial> {
    public _searchTerm: string;
    public _entities: SurveyWhoUsedMostMaterial[] = [];
    public _pageState = new PageState('SurveyWhoUsedMostMaterial');
    public _userMessage = '';

    private _displayCAS: string;
    private _materialId: number;
    private _formattedRIFMId: string;

    constructor(elementRef: ElementRef, public _uow: UnitOfWork) {
        super(elementRef);
    }

    public loadData(year: string) {
        this._userMessage = '';
        this.validationMessage = null;
        this._pageState.isLoading = true;
        this._entities = null;

        this._uow.fetchTyped('MaterialReportedUses/SurveyWhoUsedMostMaterials', SurveyWhoUsedMostMaterial, {
            displayCAS: this._displayCAS,
            surveyYear: year,
            materialId: this._materialId
        }).then(r => {
            this._pageState.isLoaded = true;
            this._entities = r;

            if (this._entities == null || this._entities.length == 0) {
                this._userMessage = 'No organizations reported a use greater than zero for this material and survey year.';
            }
        });
    }

    onSelect(surveyWhoUsedMost: SurveyWhoUsedMostMaterial) {
        UtilFns.returnModal(this, surveyWhoUsedMost);
    }

    public set displayCAS(cas: string) {
        this._displayCAS = cas;
    }

    public get displayCAS(): string {
        return this._displayCAS;
    }

    public set materialId(id: number) {
        this._materialId = id;
    }

    public get materialId(): number {
        return this._materialId;
    }

    public set formattedRIFMId(rifmId: string) {
        this._formattedRIFMId = rifmId;
    }

    public get formattedRIFMId(): string {
        return this._formattedRIFMId;
    }

    public hasData(): boolean {
        return (this._entities != null && this._entities.length > 0);
    }

    public get utilFns() {
        return UtilFns;
    }
}

<div class="modal" id="login-modal" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-sm">
        <div class="modal-content">

            <div class="modal-header" style="background-color: #B0C4DE !important; border-bottom: #00088D;">
                <h4 class="modal-title text-center" id="myModalLabel" style="color: #000000;">RIFM Database</h4>
            </div>

            <div class="modal-body" style="height: 160px;">

                <div style="width: 100%;">
                    <span class="bold page-subtitle" style="display: flow; text-align: center;">{{userMessage}}</span>
                    <button (click)="tryLogin()" class="btn btn-primary center-horizontal" style="margin-top: 20px; margin-left: 80px;">Retry Log In</button>
                </div>

            </div>
        </div>
    </div>
</div>

<div>
    <page-status [pageState]="_pageState"></page-status>
</div>

<div *ngIf="this._pageState.isLoaded" class="indent-left-sm">

    <div class="material-partof-compounds-header">
        <span class="composition-title">Materials that contain</span>
        <span class="composition-title left-indent" [htmlGreek]="principalName"></span>
        <span class="composition-title left-indent">as a constituent:</span>
        <br />
    </div>

    <div style="width: 65%">
        <dx-data-grid [dataSource]="_materialCompositions"
                [showBorders]="true"
                [allowColumnReordering]="true"
                [allowColumnResizing]="true">
            <dxo-paging [enabled]="false"></dxo-paging>
            <dxo-sorting mode="multiple"></dxo-sorting>
            <dxo-state-storing [enabled]="true" type="localStorage" storageKey="material-partof-compounds"></dxo-state-storing>

            <dxi-column dataField="material.realCASNumber" caption="CAS No." cellTemplate="casNumberTemplate"></dxi-column>
            <div *dxTemplate="let cell of 'casNumberTemplate'">
                <a (click)="goToSynonyms(cell.data.material)">{{cell.data.material.realCASNumber}}</a>
            </div>
            <dxi-column dataField="material.formattedRIFMId" caption="RIFM ID"></dxi-column>
            <dxi-column dataField="material.fEMANumber" caption="FEMA No."></dxi-column>
            <dxi-column dataField="c.material" caption="Principal Name" cellTemplate="principalTemplate"></dxi-column>
            <div *dxTemplate="let cell of 'principalTemplate'">
                <span [htmlGreek]="cell.data.material?.principalSynonym()?.synonymWordOrWebVersion()"></span>
            </div>
            <dxi-column dataField="componentAmount" caption="Percentage" cellTemplate="percentageTemplate"></dxi-column>
            <div *dxTemplate="let cell of 'percentageTemplate'">
                {{ utilFns.formatNumber(cell.data.componentAmount, 6, 4) }}
            </div>
        </dx-data-grid>
    </div>
</div>

<div style="margin-top: 15px">
    <div class="row">
        <i *ngIf="_isLoading" class="fa fa-circle-o-notch fa-spin" style="font-size:38px; color: #337ab7; margin-left: 24px;"></i>
    </div>
    <div class="form-group">
        <div class="col-md-3">
            <span class="bold">{{"Unit of Measure: " + _unitOfMeasure}}</span>
        </div>
        <div class="form-group">
            <button class="btn btn-sm btn-primary" type="button" (click)="setUnitOfMeasure('Kilograms')">Kilograms</button>
            <button class="btn btn-sm btn-primary" type="button" (click)="setUnitOfMeasure('Metric Tons')">Metric Tons</button>
        </div>
    </div>

    <div class="form-group">
        <button type="button" class="btn btn-primary btn-xs expander-button" (click)="expandAll(true)">Expand All</button>
        <button type="button" class="btn btn-primary btn-xs expander-button" (click)="expandAll(false)">Collapse All</button>
    </div>

    <div class="col-md-5">
        <div class="form-group" *ngFor="let group of _consumptionsYearGroups; let ix=index" style="width: 240px;">
            <div class="panel-body">
                <div class="row text-center bold thead">
                    <div class="col-sm-1">
                        <a (click)="yearGroupCollapse(ix)">
                            <span *ngIf="group.collapsed" class="fa fa-caret-down" style="color: #ffffff"></span>
                            <span *ngIf="!group.collapsed" class="fa fa-caret-up" style="color: #ffffff"></span>
                        </a>
                    </div>
                    {{group.year}}
                </div>
                <table [attr.data-hidden]="group.collapsed" class="table table-striped table-hover table-bordered table-condensed" style="width: auto;">
                    <tbody>
                        <tr *ngFor="let c of group.consumptions">
                            <td>{{c.geographicalArea}}</td>
                            <!--<td> {{ utilFns.formatNumber(c.kilogramsConsumed, 12, 3) }}</td>-->
                            <td *ngIf="_unitOfMeasure == 'Kilograms'">{{ utilFns.formatNumber(c.kilogramsConsumed, 12, 3) }}</td>
                            <td *ngIf="_unitOfMeasure == 'Metric Tons'">{{ utilFns.scaleNumber(c.kilogramsConsumed, 1000, 10, 4) }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>

</div>

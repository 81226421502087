import { EntityBase } from './EntityBase';
import { ExposureAdditionalMaterialSurveyMeasure } from './ExposureAdditionalMaterialSurveyMeasure';
import { MaterialExposureSurveyMeasure } from './MaterialExposureSurveyMeasure';
import { TypeCremeVersion } from './TypeCremeVersion';
import { MaterialExposureSurvey } from './MaterialExposureSurvey';
import { TypeExposureSurveyStatus } from './TypeExposureSurveyStatus';

import { Validator } from 'breeze-client';

/// <code-import> Place custom imports between <code-import> tags
var _monthFullNames = [
    "January", "February", "March",
    "April", "May", "June", "July",
    "August", "September", "October",
    "November", "December"
];
/// </code-import>

export class ExposureSurvey extends EntityBase {

   /// <code> Place custom code between <code> tags
   public get exposureSurveyMonthYear() {

        if (this.exposureSurveyDate == null) {
            return '';
        }
        var month = _monthFullNames[this.exposureSurveyDate.getUTCMonth()];
        var year = this.exposureSurveyDate.getUTCFullYear().toString();

        return month + ', ' + year;
    }

    public get exposureSurveyMonthYearNumber() {

        if (this.exposureSurveyDate == null) {
            return '';
        }
 
        return this.exposureSurveyMonthYear + ': ' + this.exposureSurveyNumber;
    }
   /// </code>

   // Generated code. Do not place code below this line.
   exposureSurveyId: number;
   exposureSurveyDate: Date;
   exposureSurveyNumber: number;
   description: string;
   typeExposureSurveyStatusId: string;
   typeCremeVersionId: number;
   created: Date;
   createdUser: string;
   modified: Date;
   modifyUser: string;
   rowVersion: number;
   exposureAdditionalMaterialSurveyMeasures: ExposureAdditionalMaterialSurveyMeasure[];
   materialExposureSurveyMeasures: MaterialExposureSurveyMeasure[];
   materialExposureSurveys: MaterialExposureSurvey[];
   typeCremeVersion: TypeCremeVersion;
   typeExposureSurveyStatus: TypeExposureSurveyStatus;
}


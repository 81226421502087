<h4 *ngIf="isAdded()" class="bold">Adding new Author</h4>
<h4 *ngIf="!isAdded()" class="bold">Editing existing Author</h4>
<span>
    <button type="button" class="btn btn-sm btn-primary" [disabled]="!canApplyAuthor()"  (click)="onApplyAuthor(entity)">Apply</button>            
    <button type="button" class="btn btn-sm btn-primary"  (click)="onApplyAuthor(null)">Cancel</button>
</span>

<div class="form-horizontal">

    <edit-prop label="Author Last Name or Company Name" prop="authorName" szs="3,4">
        <input class="form-control" type="text" [(ngModel)]="entity.authorName">
    </edit-prop>
    <edit-prop label="Initials" prop="initials" szs="3,4">
        <input class="form-control" type="text" [(ngModel)]="entity.initials">
    </edit-prop>

</div>
import { Location } from '@angular/common';
import {Component, forwardRef, Inject, Input, OnInit, AfterViewInit, ViewChild} from '@angular/core';

import {UnitOfWork, StateMap, provideParent, WorkflowEntityState, ReferenceState} from '../../services/common';
import {EditPropParent, PageState} from '../../controls/common';
import { Reference } from '../../entities/EntityModels';
import { ReferenceSelectorComponent } from '../reference/reference-selector.component';
import {ReferenceDocumentEditData, RIFMDocumentsComponent} from '../docmanagement/rifm-documents.component';
// import { ShowAndDownloadPDFComponent } from '../docmanagement/show-and-download-pdf.component';

import * as _ from 'lodash';
import {UtilFns} from '../../utils/util-fns';
import {Observable} from 'rxjs';
import {HttpClient, HttpHeaders} from '@angular/common/http';

export enum PageActionType { EXTRACTPAGES, ADDPAGES, REMOVEPAGES }
export enum InsertType { BYINDEX, BEGINNING, END }

@Component({
    selector: 'edit-pdf-pages',
    templateUrl: './edit-pdf-pages.html',
    providers: [provideParent(EditPDFPagesComponent, EditPropParent)]
})

export class EditPDFPagesComponent implements OnInit, AfterViewInit {
    // @ViewChild(ShowAndDownloadPDFComponent) _showAndDownloadPDFComponent: ShowAndDownloadPDFComponent;

    @Input() referenceId: number;
    @Input() rifmDocumentId: number;

    public _documentManagementUrl: string;

    public _loading = false;
    public _pageState = new PageState('Edit PDF Pages');

    public _filedata: any;
    public _fileInputElement: any;

    public _addPageIndex = '';

    public _extractPageRangeStart = '';
    public _extractPageRangeEnd = '';
    public _extractPageNumberString: string;

    public _removePageRangeStart = '';
    public _removePageRangeEnd = '';
    public _removePageNumberString: string;

    public _addedPagesSuccessfully = false;
    public _extractPagesSuccessfully = false;
    public _removePagesSuccessfully = false;

    public _validationMessage = '';

    public _selectedPageActionType = PageActionType.EXTRACTPAGES;

    public _selectedInsertionOptionPageIndex    = true;
    public _selectedInsertionOptionBeginning    = false;
    public _selectedInsertionOptionEnd          = false;

    public colorUnselected = '#C1CDC1';
    public colorSelected = '#C1FFC1';

    public _isLoading = false;

    constructor(public _uow: UnitOfWork, public _stateMap: StateMap, public _location: Location, private _httpClient: HttpClient,
                @Inject(forwardRef(() => RIFMDocumentsComponent)) public _parent: RIFMDocumentsComponent) {
    }

    ngOnInit() {
        this._documentManagementUrl = this._stateMap.documentManagementBaseUrl  + '/PDFEditing';

        this._extractPageNumberString = '';
        this._removePageNumberString = '';
    }

    ngAfterViewInit() {
        this._fileInputElement = $('#fileInputElement')[0] as HTMLInputElement;
    }

    // ************************************************************************
    // Page interaction
    // ************************************************************************
    hasAddPagesEdits(): boolean {
        if (this.selectedPageActionType == PageActionType.ADDPAGES) {
            return ((this._filedata != null) || this.isValidString(this._addPageIndex));
        }
        return false;
    }

    hasExtractPagesEdits(): boolean {
        if (this.selectedPageActionType == PageActionType.EXTRACTPAGES) {
            return (this.isValidString(this._extractPageRangeStart) || this.isValidString(this._extractPageRangeEnd) || this.isValidString(this._extractPageNumberString));
        }
        return false;
    }

    hasRemovePagesEdits(): boolean {
        if (this.selectedPageActionType == PageActionType.REMOVEPAGES) {
            return (this.isValidString(this._removePageRangeStart) || this.isValidString(this._removePageRangeEnd) || this.isValidString(this._removePageNumberString));
        }
        return false;
    }

    public onBack() {
        if (this._fileInputElement != null) {
            this._fileInputElement.value = null;
        }

        this._parent.editPagesCompleted('');
    }

    public onBackAndView() {
        if (this._fileInputElement != null) {
            this._fileInputElement.value = null;
        }

        this._parent.editPagesCompleted('viewPDF');
    }

    resetDisplay() {
        this.clearParameterValues();
        this._validationMessage = '';
    }

    clearParameterValues() {

        this._extractPageRangeStart = '';
        this._extractPageRangeEnd = '';
        this._extractPageNumberString = '';

        this._removePageRangeStart = '';
        this._removePageRangeEnd = '';
        this._removePageNumberString = '';

        this._addedPagesSuccessfully = false;
        this._extractPagesSuccessfully = false;
        this._removePagesSuccessfully = false;

        if (this._fileInputElement != null) {
            this._fileInputElement.value = null;
        }
    }

    // ************************************************************************
    // Parameter Validation
    // ************************************************************************
    public isValidExtract() {
        this._validationMessage = '';

        if (this.canConvertToPageNumber(this._extractPageRangeStart) && !this.canConvertToPageNumber(this._extractPageRangeEnd)) {
            this._validationMessage = 'If you enter a range of page numbers, both start and end page numbers must be entered.';
            return false;
        }

        if (!this.canConvertToPageNumber(this._extractPageRangeStart) && this.canConvertToPageNumber(this._extractPageRangeEnd)) {
            this._validationMessage = 'If you enter a range of page numbers, both start and end page numbers must be entered.';
            return false;
        }

        if (this.isValidString(this._extractPageNumberString)) {
            // this method will set an error flag if there are invalid entries
            const nums = this.extractAndValidateStringOfNumbers(this._extractPageNumberString);
            if (nums == '') {
                this._validationMessage = 'Some of the page numbers you entered are invalid numbers.';
                return false;
            }
        }

        if (!this.canConvertToPageNumber(this._extractPageRangeStart) && !this.canConvertToPageNumber(this._extractPageRangeEnd) && !this.isValidString(this._extractPageNumberString)) {
            return false;
        }

        return true;
    }

    public isValidAddPages() {
        this._validationMessage = '';

        if (this._selectedInsertionOptionPageIndex) {
            if (!this.canConvertToPageNumber(this._addPageIndex)) {
                this._validationMessage = 'The page number you entered to add pages is not numeric.';
                return false;
            }
        }

        if (this.fileData == null) {
            this._validationMessage = 'Please choose a file with the pages to be added.';
            return false;
        }

        return true;
    }

    public isValidRemovePages() {
        this._validationMessage = '';

        if (this.canConvertToPageNumber(this._removePageRangeStart) && !this.canConvertToPageNumber(this._removePageRangeEnd)) {
            this._validationMessage = 'If you enter a range of page numbers, both start and end page numbers must be entered.';
            return false;
        }

        if (!this.canConvertToPageNumber(this._removePageRangeStart) && this.canConvertToPageNumber(this._removePageRangeEnd)) {
            this._validationMessage = 'If you enter a range of page numbers, both start and end page numbers must be entered.';
            return false;
        }

        if (this.isValidString(this._removePageNumberString)) {
            // this method will set an error flag if there are invalid entries
            const nums = this.extractAndValidateStringOfNumbers(this._removePageNumberString);
            if (nums == '') {
                this._validationMessage = 'Some of the page numbers you entered are invalid numbers.';
                return false;
            }
        }

        if (!this.canConvertToPageNumber(this._removePageRangeStart) && !this.canConvertToPageNumber(this._removePageRangeEnd) && !this.isValidString(this._removePageNumberString)) {
            return false;
        }

        return true;
    }

    canConvertToPageNumber(teststring: string) {
        if (!this.isValidString(teststring)) {
            return false;
        }

        const testnum = parseInt(teststring, 10);
        return !isNaN(testnum) && testnum > -1;
    }

    isValidString(teststring: string): boolean {
        if (!teststring) {
            return false;
        }
        return (teststring.trim().length > 0);
    }

    private extractAndValidateStringOfNumbers(stringOfNumbers: string): string {
        let hasParameterErrors = false;
        const pageNumbers = stringOfNumbers.split(',').map(function (item) {
            const num = Number(item.trim());
            if (isNaN(num)) {
                hasParameterErrors = true;
            } else {
                return num;
            }
        });

        if (hasParameterErrors) {
            return '';
        }

        const pagesWithBaseZero = this.substractValueFromNumberArray(pageNumbers, 1);
        return pagesWithBaseZero.join(',');
    }

    private substractValueFromNumberArray(numbers: number[], decrementValue: number): number[] {
        if (numbers == null || numbers.length == 0) {
            return new Array<number>();
        }

        const newNumberValues = numbers.map(function (value) {
                return value - decrementValue;
            }
        );

        return newNumberValues;
    }

    // ************************************************************************
    // click events
    // ************************************************************************
    public extractPages() {
        this._validationMessage = '';
        if (!this.isValidExtract()) {
            return false;
        }

        this.extractPDFPagesIntoFile();
    }

    public addPages() {
        this._validationMessage = '';

        if (!this.isValidAddPages()) {
            return false;
        }

        this.addPagesToPDF();
    }

    public removePages() {
        this._validationMessage = '';

        if (!this.isValidRemovePages()) {
            return false;
        }

        this.removePagesFromPDFFile();
    }

    public onselectedInsertionOption(type: number) {
        if (type == InsertType.BYINDEX) {
            this._selectedInsertionOptionPageIndex    = true;
            this._selectedInsertionOptionBeginning    = false;
            this._selectedInsertionOptionEnd          = false;
        } else if (type == InsertType.BEGINNING) {
            this._selectedInsertionOptionPageIndex    = false;
            this._selectedInsertionOptionBeginning    = true;
            this._selectedInsertionOptionEnd          = false;
        } else if (type == InsertType.END) {
            this._selectedInsertionOptionPageIndex    = false;
            this._selectedInsertionOptionBeginning    = false;
            this._selectedInsertionOptionEnd          = true;
        }
    }

    // ******************************************************************
    // Handle initial file upload
    // ******************************************************************
    onFileInput(files: FileList) {
        this._filedata = files.item(0);
    }

    // *****************************************************************************
    // bindable properties
    // *****************************************************************************
    public get fileData(): any {
        return this._filedata;
    }

    public set fileData(value: any) {
        this._filedata = value;
    }

    get utilFns() {
        return UtilFns;
    }

    public get pdfPageActionType(): typeof PageActionType {
        return PageActionType;
    }

    public get selectedPageActionType(): PageActionType {
        return this._selectedPageActionType;
    }

    public set selectedPageActionType(value) {
        this._selectedPageActionType = value;
    }

    public pageActionButtonColor(desc: string) {
        switch (desc) {
            case 'extract': {
                return (this.selectedPageActionType == PageActionType.EXTRACTPAGES) ? this.colorSelected : this.colorUnselected;
                break;
            }
            case 'add': {
                return (this.selectedPageActionType == PageActionType.ADDPAGES) ? this.colorSelected : this.colorUnselected;
                break;
            }
            case 'remove': {
                return (this.selectedPageActionType == PageActionType.REMOVEPAGES) ? this.colorSelected : this.colorUnselected;
                break;
            }
            default: {
                return this.colorUnselected;
                break;
            }
        }
    }

    onSelectPageActionType(type: string) {
        this._validationMessage = '';

        if (type == 'extract') {
            this.selectedPageActionType = PageActionType.EXTRACTPAGES;
        } else if (type == 'add') {
            this.selectedPageActionType = PageActionType.ADDPAGES;
            this.onselectedInsertionOption(InsertType.BYINDEX);
        } else {
            this.selectedPageActionType = PageActionType.REMOVEPAGES;
        }
    }

    // ************************************************************************
    // web api calls
    // ************************************************************************
    getAddPagesURL(): Observable<any> {

        let insertAtEnd         = 'false';
        let shiftedAddPageIndex = -1;

        if (this._selectedInsertionOptionBeginning) {
            shiftedAddPageIndex = 0;
        } else if (this._selectedInsertionOptionEnd) {
            insertAtEnd = 'true';
        } else {
            const num = parseInt(this._addPageIndex, 10);
            shiftedAddPageIndex = num - 1; // syncfusion pdf api assign the first page numbers as 0
        }

        const url = this._documentManagementUrl + '/AddPages';
        const headers = new HttpHeaders();
        headers.append('Accept', 'application/json');
        const formData: FormData = new FormData();
        formData.append('referenceId', this.referenceId + '');
        formData.append('rifmDocumentId', this.rifmDocumentId + '');
        formData.append('fileName', this.referenceId + '.pdf');
        formData.append('insertPageIndex', shiftedAddPageIndex + '');
        formData.append('insertAtEnd', insertAtEnd);
        formData.append('file', this.fileData);

        return this._httpClient.post<any>(url, formData);
    }

    getExtractPagesURL(): Observable<any> {

        let pageString = '';
        if (this.isValidString(this._extractPageNumberString)) {
            pageString = this.extractAndValidateStringOfNumbers(this._extractPageNumberString);
        }

        // syncfusion pdf api assign the first page numbers as 0 so decrement range by 1
        let pageRangeStart = -1;
        if (this.isValidString(this._extractPageRangeStart)) {
            const num = parseInt(this._extractPageRangeStart, 10);
            pageRangeStart = num - 1;
        }

        let pageRangeEnd = -1;
        if (this.isValidString(this._extractPageRangeEnd)) {
            const num = parseInt(this._extractPageRangeEnd, 10);
            pageRangeEnd = num - 1;
        }

        // tslint:disable-next-line:max-line-length
        const url = this._documentManagementUrl + '/ExtractPages?rifmDocumentId=' + this.rifmDocumentId + '&pageNumberEntries=' + pageString + '&pageRangeStart=' + pageRangeStart + '&pageRangeEnd=' + pageRangeEnd;
        return this._httpClient.get<any>(url, {responseType: 'blob' as 'json'});
    }

    getRemovePDFPagesURL(): Observable<any> {
        const fileName = this.referenceId + '.pdf';

        let pageString = '';
        if (this.isValidString(this._removePageNumberString)) {
            pageString = this.extractAndValidateStringOfNumbers(this._removePageNumberString);
        }

        // syncfusion pdf api assign the first page numbers as 0 so decrement range by 1
        let pageRangeStart = -1;
        if (this.isValidString(this._removePageRangeStart)) {
            const num = parseInt(this._removePageRangeStart, 10);
            pageRangeStart = num - 1;
        }

        let pageRangeEnd = -1;
        if (this.isValidString(this._removePageRangeEnd)) {
            const num = parseInt(this._removePageRangeEnd, 10);
            pageRangeEnd = num - 1;
        }

        // tslint:disable-next-line:max-line-length
        const url = this._documentManagementUrl + '/RemovePages?rifmDocumentId=' + this.rifmDocumentId + '&fileName=' + fileName + '&pageNumberEntries=' + pageString + '&pageRangeStart=' + pageRangeStart + '&pageRangeEnd=' + pageRangeEnd;
        const headers = new HttpHeaders();
        headers.append('Accept', 'application/json');
        return this._httpClient.get<any>(url, {responseType: 'text' as 'json'});
    }

    public addPagesToPDF() {
        this._addedPagesSuccessfully = false;
        this._loading = true;
        this._validationMessage = '';
        this.getAddPagesURL()
            .subscribe(
                (response) => {
                    const data = response;

                    if (data.Success == false) {
                        this._validationMessage = 'Error occurred, unable to add the new pages: ' + data.ExceptionMessage;
                        return;
                    }

                    this._addedPagesSuccessfully = true;

                    this._validationMessage = 'Pages added to the PDF successfully.';
                },
                (error) => {
                    console.error('Request failed with error: ' + error.message);
                    this._validationMessage = error.message;
                    this._loading = false;
                },
                () => {
                    this._loading = false;
                });
    }

    public extractPDFPagesIntoFile() {
        this._isLoading = true;
        this._validationMessage = '';
        const extractFileName = 'Extract_' + this.referenceId + '.pdf';
        this.getExtractPagesURL()
            .subscribe(
                (response: Blob) => {
                    const data = response;

                    const file = new Blob([data], {type: 'application/pdf'});
                    const fileURL = URL.createObjectURL(file);

                    // window.open(fileURL);
                    const a = document.createElement('a');
                    a.href = fileURL;
                    a.target = '_blank';
                    a.download = extractFileName;
                    document.body.appendChild(a);
                    a.click();

                    this._validationMessage = 'Pages copied to file and downloaded successfully.';

                    this.clearParameterValues();
                },
                (error: any) => {
                    console.error('Request failed with error: ' + error.message);
                    this._validationMessage = error.message;
                    this._isLoading = false;
                },
                () => {
                    this._isLoading = false;
                });
    }

    public removePagesFromPDFFile() {
        this._isLoading = true;
        this._validationMessage = '';
        this.getRemovePDFPagesURL()
            .subscribe(
                (response) => {
                    const data = response;

                    if (data.Success == false) {
                        this._validationMessage = 'Error occurred, unable to delete pages from the file: ' + data.ExceptionMessage;
                        return;
                    }

                    this._validationMessage = 'Pages deleted successfully from PDF file.';

                },
                (error) => {
                    console.error('Request failed with error: ' + error.message);
                    this._validationMessage = error.message;
                    this._isLoading = false;
                },
                () => {
                    console.error('Request completed');
                    this._isLoading = false;
                });
    }

    // ************************************************************************
    // document management data
    // ************************************************************************
    public getDocumentManagementPDFEditingUrl(): Promise<any> {
        return this._uow.fetch('DocumentManagement/DocumentManagementURL', {}).then(dm => {
            return dm[0];
        });
    }

    // ************************************************************************
    // Display pdf in a modal dialogue
    // ************************************************************************
    /*    showReferenceDocument() {
            this._showAndDownloadPDFComponent.fileName = this.referenceId + '.pdf';
            UtilFns.showModal(this._showAndDownloadPDFComponent, this).then(m => {
                return;
            });
        }*/
}

<div id="SelectProtocolMaterials" class="modal fade bs-example-modal-lg" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel">
    <div class="modal-dialog modal-lg">
        <div class="modal-content clusterfiltercontainer">
            <div class="modal-header custom-header">
                <span class="modal-title" id="myModalLabel">Select From Experimental Materials</span>
            </div>
            <div class="modal-body" style="height: 800px;">
                <div class="row">

                    <span class="alert bold text-left" style="width: auto; margin-left: 16px;">{{_userNotifications}}</span>

                    <div style="margin-top: 8px;">
                        <div class="float-left">
                            <button type="button" class="btn-link" style="margin-left: 32px;" (click)="onUnselectAll()">Unselect All</button>
                        </div>
                        <div class="float-right" style="width: 50%;">
                            <button class="btn btn-primary" type="button" (click)="onSave()" [disabled]="!canSave()">Save Selected Materials to the Copy List</button>
                            <button class="btn btn-primary" type="button" (click)="onCancel()">Cancel</button>
                        </div>
                    </div>
                </div>

                <div classs="row">
                    <div *ngIf="_protocolExperimentalMaterialsEx != null && _protocolExperimentalMaterialsEx.length > 0" style="margin-left: 16px; margin-top: 6px; max-height: 500px; overflow-y: auto; overflow-x: hidden;">
                        <p class="bold">{{'Materials assigned to Protocol: ' + _subReference}}</p>
                        <default-table>
                            <thead>
                            <tr>
                                <td class="text-center">Select</td>
                                <td class="text-center">Protocol Sub-reference</td>
                                <td class="text-center">MaterialId</td>
                                <td class="text-center">CAS No.</td>
                                <td class="text-center">Captive</td>
                                <td class="text-center">RIFM ID</td>
                                <td class="text-center">FEMA No.</td>
                                <td class="text-left">Principal Synonym</td>
                            </tr>
                            </thead>
                            <tbody>
                            <tr *ngFor="let m of _protocolExperimentalMaterialsEx">
                                <td class="text-center"><input type="checkbox" class="mediumcheckbox" [(ngModel)]="m.selected" (change)="onSelectExperimentalMaterial($event, m)" /></td>
                                <td class="text-center"><span class="text-left">{{m.data.fileReference}}</span></td>
                                <td class="text-center"><span class="text-left">{{m.data.materialId}}</span></td>
                                <td class="text-center"><span class="text-left">{{m.data.realCASNumber}}</span></td>
                                <td class="text-center"><span class="captive-text">{{captiveLabel(m.data.confidential)}}</span></td>
                                <td class="text-center"><span class="text-left">{{m.data.formattedRIFMId}}</span></td>
                                <td class="text-center"><span class="text-left">{{m.data.fEMANumber}}</span></td>
                                <td class="text-left"><span class="text-left" [htmlGreek]="m.data.synonymWordOrWebVersion">}</span></td>
                            </tr>
                            </tbody>
                        </default-table>
                    </div>

                </div>

            </div>
        </div>
    </div>
</div>
<div class="modal-footer border-zero no-print">
</div>


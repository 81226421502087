import {ChangeDetectorRef, AfterViewInit, Component, OnInit} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import {MolImageService, StateMap, UserManager} from '../../services/common';
import { UtilFns, } from '../../utils/common';
import { PageState } from '../../controls/common';

import { ROUTES } from '../routes';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import {EntityManagerProvider} from '../../services/entity-manager-provider';

interface SmileItem {
    MaterialId: number;
    RealCASNumber: string;
    FEMANumber: number;
    MonographNumber: number;
    SynonymWordOrWebVersion: string;
    TypeEssentialOilId: string;
    TypeBotanicalSubdivisionId: string;
    Smiles: string;
    Base32SmileUrl: string;
    FormattedRIFMId: string;
    Confidential: boolean;
    Score: number;
}


@Component({
    templateUrl: './marvin-search.html',
})
export class MarvinSearchComponent implements OnInit, AfterViewInit {

    _materials: SmileItem[];
    _sketcherInstance: any;

    _isError            = false;
    _isShowingSearch    = true;
    _isSearching        = false;
    _isStructureError   = false;

    _pageState = new PageState('Materials');
    _sub: any;

    _similarityThreshold    = '80';
    _validationMessage: string;

    _sketchSmiles: string;
    _inputSmiles: string;

    _maintainOriginalSMILES = true; // this is set to true only the first time the component is loaded so the SMILES matches the source.
    _originalSMILES: any;

    constructor(public _molImageService: MolImageService, private http: HttpClient, public _router: Router,
                public _stateMap: StateMap, public _route: ActivatedRoute, public _userManager: UserManager,
                public _cd: ChangeDetectorRef) {
    }

    ngOnInit() {
        this._isShowingSearch = true;
    }

    ngAfterViewInit() {
        let materialId;

        this._sub = this._route.params.subscribe(params => {
            if (params['id']) {
                materialId = +params['id'];
            }

            if (params['smiles']) {
                this._originalSMILES = params['smiles'];
            }
        });

        if (materialId == null || Number.isNaN(materialId)) {
            this.getSketcherInstance().then((si: any) => {  // Get sketcher reference so event handlers will be bound
                return;
            });
        }

        let molData: any;
        this._molImageService.getMoleculeFromMaterial(materialId).then((r) => {
            molData = r;
            this.getSketcherInstance().then((si: any) => {
                return si.importStructure('mol', molData);
            });
        });
    }

    isStaff() {
        return this._userManager.currentUser.isStaff;
    }

    getSketcherInstance() {
        try {
            if (this._sketcherInstance) {
                return Promise.resolve(this._sketcherInstance);
            } else {
                return (<any>window).MarvinJSUtil.getEditor('#sketch').then((si: any) => {
                    this._sketcherInstance = si;

                    this._sketcherInstance.on('molchange', () => {
                        this.onMolChangeEvent();
                    });

                    return si;
                });
            }
        } catch (e) {
            alert('Cannot retrieve sketcher instance from iframe:' + e);
        }
    }

    search() {
        this._isSearching = true;
        this._materials = null;
        this._isError = false;

        if (this.validateSimilarityThreshold() == false) { return; }

        this._pageState.isLoading = true;

        const threshold = parseFloat(this._similarityThreshold) / 100;

        this.getSketcherInstance()
            .then((si: any) => {
                return si.exportStructure('mrv');
            })
            .then((mrv: any) => {
                return this.http
                    .post(`${environment.appUrl}/breeze/MolImage/SearchByStructure`,  { marvin: mrv, similarityThreshold: threshold })
                    .toPromise();
            })
            .then((r: SmileItem[]) => {
                this._materials = r;
                this._materials.forEach(m => {
                    this._molImageService.getBase32SmileUrl(m.MaterialId).then((url) => {
                        m.Base32SmileUrl = url;
                    });
                });
                this._isShowingSearch = false;
                this._isSearching = false;
                this._pageState.itemCount = r.length;
                this._pageState.isLoaded = r;
            })
            .catch((e: any) => {
                this._pageState.isLoaded = false;
                this._isSearching = false;
                console.log(e);
                this._isError = true;
            });
    }

    getMaterialImageUrl(m: SmileItem) {
        return this._molImageService.getImageUrlForMaterial(m.MaterialId);
    }

    onCasSelect(m: SmileItem) {
        return this._router.navigate(UtilFns.asRouterParts(ROUTES.Material, m.MaterialId));
    }

    canDrawSMILES(): boolean {
        return this.isValidString(this._inputSmiles);
    }

    onMolChangeEvent() {

        this.getSketcherInstance()
            .then((si: any) => {
                return si.exportStructure('mrv');
            })
            .then((mrv: any) => {
                if (mrv == null || mrv == undefined ) {
                    this.smiles = '';
                    this._cd.detectChanges();
                    return;
                }

                if (this._maintainOriginalSMILES == true && this._originalSMILES != null) { // only the first time when the component loads so the SMILES structure matches the query param exactly.
                    this.smiles = this._originalSMILES;
                    this._maintainOriginalSMILES = false;
                    return;
                }

                this.getSMILESFromStructure(mrv).then( r => {
                    if (r != null) {
                        const s = JSON.stringify(r);
                        this.smiles = s.replace(/\"/g, '');
                        this._cd.detectChanges();
                        return;
                    }
                });
            });
     }

     getSMILESFromStructure(marvin: any) {
         return this.http
             .post(`${environment.appUrl}/breeze/MolImage/SmilesFromStructure`,  { marvin: marvin, similarityThreshold: 0.0 })
             .toPromise();
     }

    onChangeSMILES() {
        this._isStructureError = false;
    }

    onDrawSMILES() {
        let molData: any;
        this._isStructureError = false;

        this.http
            .post(`${environment.appUrl}/breeze/MolImage/MoleculeFromSmiles`,  { smiles: this._inputSmiles })
            .toPromise()
            .then((m) => {
                molData = m;
                if (molData == null || molData == undefined) {
                    this._isStructureError = true;
                }
                this.getSketcherInstance().then((si: any) => {
                    si.importStructure('mol', molData);
                });
            });
    }

    isValidString(teststring: string) {

        if (!teststring) { return false; }

        return (teststring.trim().length > 0);
    }

    validateSimilarityThreshold(): boolean {
        this._validationMessage = '';
        const msg = 'The Similarity Threshold should be a number between 1 and 99. The default is 80.';

        if (this._similarityThreshold == null || this._similarityThreshold.trim().length < 1) {
            this._validationMessage = msg;
            return false;
        }
        const regex = /^(\d?[1-9]|[1-9]0)$/;

        if (!regex.test(this._similarityThreshold)) {
            event.preventDefault();
            this._validationMessage = msg;
            return false;
        }

        return true;
    }

    public hasSearchResults(): boolean {
        return (this._materials != null && this._materials.length > 0);
    }

    public get searchResultsStatus(): string {
        if (this._pageState.isLoading) {return ''; }

        if (this._materials == null || this._materials.length == 0) {
            return 'No materials matched the drawn structure with a similarity score of ' + this._similarityThreshold + ' or higher.';
        }

        return 'There are ' + this._materials.length + ' materials that are at least ' + this._similarityThreshold + '% similar to the drawn structure.';
    }

    public get smiles(): string {
        return this._sketchSmiles;
    }

    public set smiles(s: string) {
        this._sketchSmiles = s;
    }

    public get utilFns() {
        return UtilFns;
    }

    onCopyCASToClipboard() {
        const casNumbers = this._materials.filter(m => m.RealCASNumber).map(m => m.RealCASNumber).join(', ');

        const selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = casNumbers;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
    }
}

import { EntityBase } from './EntityBase';
import { Material } from './Material';
import { Reference } from './Reference';
import { TypeKeyword } from './TypeKeyword';

import { Validator } from 'breeze-client';
/// <code-import> Place custom imports between <code-import> tags
import { EntityPropertyAnnotation, EntityTypeAnnotation } from './EntityTypeAnnotation';
import * as _ from 'lodash';
/// </code-import>

export class SpecialLink extends EntityBase {

    /// <code> Place custom code between <code> tags
    static getEntityTypeAnnotation(): EntityTypeAnnotation {
        const nonZeroCodeValidator = new Validator(
            'NonZeroCodeValidator',
            (value: any) => value != 0,
            {messageTemplate: 'You must specify a %displayName%.  '});

        const sentinalStringValidator = new Validator(
            'SentinalStringValidator',
            (value: string) => value != 'ZZZ',
            {messageTemplate: 'You must specify a %displayName%.  '});

        const propAnnotations = [
            new EntityPropertyAnnotation('materialId', {
                displayName: 'Material',
                validators: [nonZeroCodeValidator]
            }),
            new EntityPropertyAnnotation('keyword', {
                displayName: 'Category',
                validators: [sentinalStringValidator]
            }),
        ];

        return new EntityTypeAnnotation({
            validators: [],
            propertyAnnotations: propAnnotations
        });
    }

    description() {
        //   return (this.reason && this.reason.length > 0) ? this.reason : this.reference.title;
        return (this.reason && this.reason.length > 0) ? this.reason : _.get(this, 'reference.title', '');
    }

    explanation() {
        const r = this.typeKeywords && this.typeKeywords.explanation;
        return (r != null) ? r : '';
    }

    /// </code>

    // Generated code. Do not place code below this line.
    keyword: string;
    referenceId: number;
    materialId: number;
    reason: string;
    humanHealthData: boolean;
    environmentalData: boolean;
    created: Date;
    createdUser: string;
    modified: Date;
    modifyUser: string;
    rowVersion: number;
    material: Material;
    reference: Reference;
    typeKeywords: TypeKeyword;
}


import { Component, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import * as _ from 'lodash';
import { MolImageService, StateMap, UnitOfWork } from '../../services/common';
import { EditManager, IEditHost, LocationFns, UtilFns } from '../../utils/common';
import { ColumnSorter, TabContainer } from '../../controls/common';

import { Material, MaterialFSAG } from '../../entities/EntityModels';
import { ROUTES } from '../routes';

@Component({
    selector: 'relations-groups',
    templateUrl: './relations-groups.html',
})
export class RelationsGroupsComponent implements IEditHost<MaterialFSAG>, OnInit {

    @ViewChild(TabContainer, { static: true }) _tabContainer: TabContainer;
    _material: Material;
    _materialIds: number[];
    _edm: EditManager<MaterialFSAG>;
    _fsagNote: string;
    _tab: number;
    _colSorter: ColumnSorter;

    constructor(public _uow: UnitOfWork, public _stateMap: StateMap, public _molImageService: MolImageService,
        public _router: Router, public _route: ActivatedRoute, public _location: Location) {

    }

    ngOnInit() {
        this._material = this._stateMap.currentMaterial;
        this._edm = new EditManager<MaterialFSAG>(this, this._uow, this._stateMap, 'Related Groups');
        this._edm.femaRestricted = true;    // FEMA can't edit this data
        // var returned = LocationFns.updatePageState(this._location, this._stateMap, this._pageState, (state: PageState) => {
        //     this._colSorter1.sortColumn = (<PageStateExt> state).sortColumn1;
        //     this._colSorter2.sortColumn = (<PageStateExt> state).sortColumn2;
        // });

        // The ',1' below is because this is a nested tab
        this._tab = LocationFns.getTab(this._location, 1) || 0;

        this.search();

    }

    canDeactivate() {
        return !this._edm.hasEdits();
    }

    onTabChange(paneNumber: number) {
        if (paneNumber == 0) {
            LocationFns.setTab(this._location, '1.0');
        }
    }

    onCasSelect(materialFSAG: MaterialFSAG) {
        this._router.navigate(UtilFns.asRouterParts(ROUTES.Material, materialFSAG.material.materialId));
    }

    search() {
        const materialId = this._material.materialId;
        this._edm.pageState.isLoading = true;
        return this._uow.materialFSAGRepository.whereWithParams({ materialId: materialId, sagId: null }).then(r => {
            if (r.length == 0) {
                return Promise.resolve(r);
            } else {
                const baseMaterialFSAG = r[0];
                this._fsagNote = baseMaterialFSAG.fSAG.note;
                return this._uow.materialFSAGRepository.whereWithParams({ materialId: null, sagId: baseMaterialFSAG.sAGID });
            }
        }).then(r => {
            this._edm.entities = _.sortBy(r, x => x.material.principalSynonym().synonymWordOrWebVersion());
            this._materialIds = this._edm.entities.map(fsag => fsag.materialId);
            this._tabContainer.selectTab(this._tab);
            this._edm.pageState.isLoaded = r;
        });
    }

    canSave() {
        if (!this._edm.hasEdits()) { return false; }
        if (!this._edm.validateBeforeSave()) { return false; }
        return true;
    }

    getMaterialImageUrl(m: MaterialFSAG) {
        return this._molImageService.getImageUrlForMaterial(m.material.materialId);
    }

    sortWith(colSorter: ColumnSorter) {

    }

    // EditManager overrides

    canAdd() {
        return this._edm.entities && (this._edm.entities.length == 0);
    }

    onAdd() {
        if (!this._edm.validateCurrent()) { return; }
        const config = {
            materialId: this._material.materialId,
            sAGID: 0
        };

        const e = this._uow.materialFSAGFactory.create(config);
        e.sAGID = null;
        this._edm.entities.push(e);
        this._edm.onSelect(e);
    }

    onDelete() {
        const ents = this._edm.entities.filter(e => e.materialId == this._material.materialId);
        if (ents.length == 0) { return; }
        this._edm.entities = [];
        ents[0].entityAspect.setDeleted();
        this._edm.commitSave().then(() => {
            this.search();
        }).catch(e => {
            this.search();
        });
    }

    onSave() {
        return this._edm.onSaveCore().then(() => {
            this._edm.editing = false;
            this.search();
        }).catch((e) => {
            // leave in _editing mode to see error.
            this._edm.errorMessages.push(e.toString());
        });
    }




}

import { EntityBase } from './EntityBase';
import { ExperimentalResult } from './ExperimentalResult';
import { TypeToxicEffect } from './TypeToxicEffect';

import { Validator } from 'breeze-client';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

export class ExperimentalToxicEffect extends EntityBase {

   /// <code> Place custom code between <code> tags
   
   /// </code>

   // Generated code. Do not place code below this line.
   experimentalResultId: number;
   typeToxicEffectId: string;
   created: Date;
   createdUser: string;
   modified: Date;
   modifyUser: string;
   rowVersion: number;
   experimentalResult: ExperimentalResult;
   typeToxicEffect: TypeToxicEffect;
}


<div>
    <div *ngIf="_canShowHeader" style="width: 98%; margin-top: -10px; margin-left: 8px;">
                <span class="inline">
                    <span class="bold page-title">Currently Displayed File Name:</span>
                    <span class="bold page-title" style="color: #337AB7; margin-left: 4px;">{{downloadFileName}}</span>
                    <span class="bold page-title" style="margin-left: 8px;">Page Count:</span>
                    <span class="bold page-title" style="color: #337AB7; margin-left: 4px;">{{pdfPageCount}}</span>
                </span>
    </div>

    <p class="notice-message" style="margin-left: 8px;">{{_processingMessage}}</p>

    <ejs-pdfviewer id="pdfViewer" [serviceUrl]="serviceUrl"
                   [documentPath]='documentPath'
                   [enableDownload]='true'
                   [enableTextSelection]='true'
                   [enablePrint]='true'
                   [printMode]="_printMode"

                   [ajaxRequestSettings]='ajaxSetting'
                   (ajaxRequestFailed)='ajaxRequestFailed($event)'
                   (ajaxRequestInitiate)='ajaxRequestInitiate($event)'
                   (ajaxRequestSuccess)='ajaxRequestSuccess($event)'

                   (downloadEnd)='downloadEnd($event)'
                   (downloadStart)='downloadStart($event)'

                   (documentLoad)='documentLoad($event)'
                   (documentLoadFailed)='documentLoadFailed($event)'

                   (annotationAdd)='annotationAdded($event)'
                   (annotationRemove)='annotationRemoved($event)'
                   (annotationResize)='annotationResized($event)'
                   (beforeAddFreeText)='beforeAddFreeText($event)'
                   (commentAdd)='commentAdded($event)'
                   (commentDelete)='commentDeleted($event)'
                   (commentEdit)='commentEdited($event)'
                   (exportFailed)='exportFailure($event)'
                   style="height:1000px;display:block">
        
    </ejs-pdfviewer>
    <input ejs-colorpicker type="color" id="colorpicker" />
</div>

import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';

import { UnitOfWork, StateMap} from '../../services/common';
import { UtilFns, LocationFns } from '../../utils/common';
import { PageState,  ColumnSorter } from '../../controls/common';
import { PaginationService, IPaginationInstance} from '../../controls/ng2-pagination/ng2-pagination';

import { ROUTES } from '../routes';
import { Synonym } from '../../entities/Synonym';

class PageStateExt extends PageState {
    searchTerm: string;
    operatorName: string;
}

@Component({
    templateUrl: './search-synonyms.html',
    providers: [PaginationService]
})
export class SearchSynonymsComponent implements OnInit {

    _synonyms: Synonym[];

    _operators = [
        { name: 'startsWith', title: 'StartingWith' },
        { name: 'contains', title: 'Containing' }
    ];

    _pagination: IPaginationInstance;
    _colSorter: ColumnSorter;
    _pageState = new PageStateExt('Synonyms');

    constructor(public _uow: UnitOfWork, public _stateMap: StateMap,
        public _location: Location, public _router: Router ) {
    }

    ngOnInit() {

        this._pagination = this._pageState.getPaginationInfo();
        this._colSorter = new ColumnSorter(this, {
            'CAS No.':  'material.realCASNumber',
            // can't sort on RIFM ID because 'formattedRIFMId' is not expressible as a sql expr
            // "RIFM ID":  "rifm",
            'FEMA No.': 'material.fEMANumber' ,
            'Synonym':  'synonymWord'
        }, 'Synonym', (sortColumn) => this._pageState.sortColumn = sortColumn);
        this._pageState.operatorName = this._operators[0].name;

        // setTimeout needed because of Angular RC5 bug where Location is not set correctly until
        // after timeout resolves.
        setTimeout(() => {
            LocationFns.updatePageState(this._location, this._stateMap, this._pageState, (state: PageState) => {
                this._colSorter.sortColumn = state.sortColumn;
                this.search();
            });
        }, 0);
    }

    opChange(event: any) {
        // avoid thrashing
        const opName = event.target.value;
        if (this._pageState.operatorName == opName) { return; }
        this._pageState.operatorName = opName;
        this.newSearch();
    }

    // called by columnSorter.
    sortWith(colSorter: ColumnSorter) {
        this._pageState.pageNumber = 1;
        this.search();
    }

    clear() {
        this._synonyms = null;
        this._pageState.searchTerm = '';
        this._pageState.resetPaging();
        this._colSorter.reset();

    }

    fetchPage(pageNumber: number) {
        this._pageState.pageNumber = pageNumber;
        return this.search();
    }

     newSearch() {
        this._pageState.resetPaging();
        this._colSorter.reset();
        return this.search();
    }

    search() {
        this._synonyms = null;
        if (!this._pageState.searchTerm) { return; }

        const opValue = { };
        opValue[this._pageState.operatorName] = this._pageState.searchTerm;
        const pred = { 'synonymWord': opValue };
        const orderBy = this._colSorter.getOrderBy();
        this._pageState.isLoading = true;
        return this._uow.synonymRepository.wherePaged(pred, orderBy,
            this._pageState.pageNumber, this._pageState.pageSize).then( r => {
            this._pagination.currentPage = this._pageState.pageNumber;
            this._pagination.totalItems = this._pageState.updateEstimate(r.length);
            this._synonyms = r;
            this._pageState.isLoaded = r;
            return r;
        }).catch(e => {
            this._pageState.isLoaded = false;
        });

    }



    selectMaterial(s: Synonym ) {
        this._router.navigate(UtilFns.asRouterParts(ROUTES.Material, s.material.materialId ));
    }





}

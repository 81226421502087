<div *ngIf="_isInitialized" class="row indent-top-sm" id="main-wrapper">
	<div class="col-md-5" style="vertical-align: top">
		<ul class="nav nav-stacked home-topic">
			<li>
				<a *ngIf="!isAuthorizedDownloadUser()" class="" (click)="goIntro()">Introduction to the New Database and Demo</a>
				<a *ngIf="!isAuthorizedDownloadUser()" class="" (click)="goDatabaseSubscription()">Database Subscription Information and Application</a>
				<a *ngIf="!isAuthorizedDownloadUser()" class="" (click)="goSafetyAssessments()">Available Fragrance Safety Assessments</a>
				<a *ngIf="!isAuthorizedDownloadUser()" class="" (click)="goIFRAAttachments()">IFRA/IOFI Labeling Manual</a>
				<a *ngIf="!isAuthorizedDownloadUser()" class="" (click)="downloadIFRANCSDocument()">Naturals Category List</a>
				<a *ngIf="!isAuthorizedDownloadUser()" class="" (click)="goEnvironmentalData()">Environmental Data</a>
				<a *ngIf="!isAuthorizedDownloadUser()" class="" (click)="goMaterialGroups()">Material Groups</a>
				<a *ngIf="!isAuthorizedDownloadUser()" class="" (click)="goSearchAuthors()">Search by Authors</a>
				<a *ngIf="!isAuthorizedDownloadUser()" class="" (click)="goSearchStructure()">Search by Structure</a>
				<a *ngIf="!isAuthorizedDownloadUser()" class="" (click)="goSearchSynonyms()">Search by Synonyms</a>
				<a *ngIf="!isAuthorizedDownloadUser()" class="" (click)="downloadRequestForm()">Study Request Form</a>
				<a *ngIf="!isAuthorizedDownloadUser()" class="" (click)="goSystemRequirements()">System Requirements and Tips for Navigation</a>
				<a class="" (click)="goToolsAndModels()"> Tools and Models</a>
				<a *ngIf="!isAuthorizedDownloadUser()" class="" (click)="goReportDatabaseChanges()">Report of Database Changes</a>
				<a *ngIf="isStaff()" class="" (click)="goStaffEditing()">Staff Editing</a>
				<a *ngIf="isIFRA()" class="" (click)="goIFRASurveyUses()">Survey Uses</a>
				<a *ngIf="!isAuthorizedDownloadUser()" class="" (click)="goMaterialClusters()">Material Clusters</a>
				<a *ngIf="canShowExposureAdmin()" class="" (click)="goExposureAdmin()">Exposure Admin</a>
				<a *ngIf="canShowDocManagementAdmin()" class="" (click)="goDocManagement()">Document Management</a>
			</li>
		</ul>
	</div>
	<div class="col-md-2 full-height">
		<div *ngIf="isStaff()"  id="fern-image-container">
		</div>
	</div>
	<div class="col-md-5">
		<ul class="nav nav-stacked home-topic">
			<li>
				<!--<span class="home-new">Browser Update</span>-->
				<a class="home-new">What's New in the RIFM Database</a>
			</li>
			<li>
				<whats-new></whats-new>
			</li>
		</ul>
	</div>
</div>
<div class="row">
	<i *ngIf="_isLoading" class="fa fa-circle-o-notch fa-spin" style="font-size:38px; color: #337ab7; margin-left: 24px;"></i>
</div>
<div class="row home-footer">
	<div class="col-md-offset-1 col-md-11 bold" style="text-align: center;">
		Version {{ _stateMap.shortVersionNo }}
		<br> © Copyright Research Institute for Fragrance Materials, Inc. (RIFM) All Rights Reserved. No part of the RIFM Fragrance
		and Flavor Database may be reproduced or transmitted in any form or by any means, electronic or mechanical, including
		photocopy, recording or any information storage and retrieval system, except with written permission from RIFM or
		as authorized by license or subscription agreement.
	</div>
</div>
<user-acknowledgement></user-acknowledgement>


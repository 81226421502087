<div class="workflow-audit-container" *ngIf="_canLoad">
    <div class="workflow-audit-left" *ngIf="hasChanges() || _toxPendingAudit">
        <editor-header [customButtons]="true" [edm]="_edm">
            <span customButtons>
                <button *ngIf="canShowSave()" type="button" class="btn btn-sm btn-primary" [disabled]="!canSave()" (click)="onSave()">Save</button>
                <button *ngIf="canShowCancel()" type="button" class="btn btn-sm btn-primary" [disabled]="!canShowCancel()" (click)="onCancel()">Cancel</button>
                <span *ngIf="referenceFinishedStatesHaveChanged()" class="workflow-automatic-finish-label">{{_finishedMessage}}</span>
            </span>
        </editor-header>
    </div>
    <div class="workflow-audit-right">
        <button *ngIf="_toxPendingAudit" type="button" class="btn btn-workflow reference-audit-button" (click)="onAuditComplete()">Complete Tox Data Audit</button>
    </div>
</div>
<br />
<div *ngIf="_canLoad" style="margin-top: 20px;">
    <reference-data [expanded]="true" [toxaudit]="true" [reference]="reference" [initTitle]="false"></reference-data>
</div>
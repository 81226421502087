import { MeasuresByCategory } from './MeasuresByCategory';

export class MeasureSurveyCategoryGroup {
    id: string;
    measuresByCategory: MeasuresByCategory[];
    isomers: string;
    notes: string;
    collapsed?: boolean;
    exposureSurveyDate: Date;
    exposureSurveyNumber: number;
    backgroundColor: string;

    // tslint:disable-next-line:max-line-length
    constructor(id: string, measuresByCategory: MeasuresByCategory[], isomers: string = '', notes: string = '', exposureSurveyDate: Date, exposureSurveyNumber: number, backgroundColor: string, collapsed: boolean = true) {
        this.id                     = id;
        this.measuresByCategory     = measuresByCategory;
        this.isomers                = isomers;
        this.notes                  = notes;
        this.collapsed              = collapsed;
        this.exposureSurveyDate     = exposureSurveyDate;
        this.backgroundColor        = backgroundColor;
        this.exposureSurveyNumber   = exposureSurveyNumber;
    }

    public get fontColor(): string {
        if (this.backgroundColor == null) {
            return '#000000';
        }

        return (this.backgroundColor == '#4CAF50' ? '#FFFFFF' : '#000000');
    }

}

<div>
    <div *ngIf="!_data.inSubEditor" class="background-transparent" style="width: 100% !important;">
        <div class="float-container" style="margin-top: 10px;">
            <div class="float-left" style="width:36%; margin-bottom: 4px;">
                <div class="flex-container" style="width: 400px !important;">
                    <div class="flex-item">
                        <span class="inline text-bold text-left page-title" style="width: 180px;">Find a Journal</span>
                    </div>
                    <div class="flex-item vertical-top">
                        <button type="button" class="inline btn btn-sm btn-primary btn-image-height" (click)="onSearchForJournal()">Search for a Journal</button>
                    </div>
                </div>
            </div>
            <div class="float-left" style="width:60%; margin-top: -28px;">
                <fieldset style="border: 2px solid lightgray !important; padding: 6px !important; width: 890px;">
                    <legend style="text-decoration: none !important; width: 90px; margin-bottom: 4px;">Reports</legend>
                    <button type="button" class="inline vertical-top btn btn-download-subscription-report" style="width: 165px;" (click)="onDownloadJournalSubscriptionReport()">Subscription Report</button>
                    <button type="button" class="inline vertical-top btn btn-download-active-subscription-report" style="width: 200px;" (click)="onDownloadJournalActiveSubscriptionReport()">Active Subscription Report</button>
                    <button type="button" class="inline vertical-top btn btn-download-inactive-subscription-report" style="width: 210px;" (click)="onDownloadJournalInactiveSubscriptionReport()">Inactive Subscription Report</button>
                    <button type="button" class="inline vertical-top btn btn-download-journal-reserve-report" style="width: 184px;" (click)="onDownloadJournalReserveReport()">Journal Reserve Report</button>
                </fieldset>
            </div>
        </div>
    </div>

    <hr style="background-color: lightgray; height: 2px; width: 100%;"/>
    <div class="row">
        <i *ngIf="_isLoading" class="fa fa-circle-o-notch fa-spin inline" style="font-size:38px; color: #337ab7;"></i>
    </div>

    <p class="alert-danger">{{_validationMessage}}</p>

    <div class="flex-container background-transparent" style="width: 100%;">
        <div *ngIf="!_data.inSubEditor" class="flex-item">
            <span class="inline text-bold text-left page-title" style="width: 180px;">Journal Details</span>
        </div>
        <div *ngIf="!_data.inSubEditor" class="flex-item">
            <button type="button" class="inline btn btn-add-journal" style="width: 128px;"  (click)="onAddJournal()">Add a Journal</button>
        </div>
        <div *ngIf="!_data.inSubEditor && journal != null" class="flex-item">
            <button type="button" class="inline btn btn-sm btn-primary btn-image-height" (click)="onEditJournal()">Edit Journal</button>
        </div>
        <div *ngIf="canDelete()" class="flex-item">
            <button type="button" class="inline btn btn-delete-journal" style="width: 136px;" (click)="onDeleteJournal()">Delete Journal</button>
        </div>
        <div *ngIf="!_data.inSubEditor && journal != null" class="flex-item">
            <button type="button" class="inline btn btn-add-reserve" style="width: 170px;" (click)="onAddJournalReserve()">Add Journal Reserve</button>
        </div>
        <div *ngIf="!_data.inSubEditor && journal != null" class="flex-item">
            <button type="button" class="inline btn btn-add-subscription" style="width: 190px;" (click)="onAddJournalSubscription()">Add Journal Subscription</button>
        </div>
        <div *ngIf="!_data.inSubEditor && journal != null && _hasActiveSubscription" class="flex-item" style="background-color: #C9FFC9;">
            <div class="center-element" style="padding: 2px 6px 2px 6px;">
                <p class="inline bold">Active Subscription</p>
                <img style="margin-left: 10px; height: 32px; width: 30px;" class="inline" src="assets/images/ActiveSubscription.png" />
            </div>
        </div>
        <div *ngIf="!_data.inSubEditor && journal != null && hasReservesData()" class="flex-item" style="background-color: #C9FFC9;">
            <div class="center-element" style="padding: 2px 6px 2px 6px;">
                <p class="inline bold">Has Journal Reserves</p>
                <img style="margin-left: 10px; height: 32px; width: 30px;" class="inline" src="assets/images/JournalReserves.png" />
            </div>
        </div>
    </div>
</div>
<div *ngIf="_userMessage"  class="alert alert-info" role="alert" style="width: 50%;">
    <p class="page-subtitle bold">{{_userMessage}}</p>
</div>

<div *ngIf="!_data.inSubEditor && journal != null" class="inline" style="margin-left: 8px; margin-top: 6px;">

    <div style="margin-top: 18px; margin-left: 12px;">
        <div class="row form-group">
            <label class="col-xs-2 text-left">Journal Id:</label>
            <span class="col-xs-5 break-word">{{journal.journalId}}</span>
        </div>

        <div class="row form-group">
            <label class="col-xs-2 text-left">Journal Name:</label>
            <span class="col-xs-5 break-word">{{journal.journalName}}</span>
        </div>

        <div class="row form-group">
            <label class="col-xs-2 text-left">Journal Name Start Date:</label>
            <span class="col-xs-5 break-word">{{journal.journalNameStartYearAndMonth}}</span>
        </div>

        <div class="row form-group">
            <label class="col-xs-2 text-left">Journal Name End Date:</label>
            <span class="col-xs-5 break-word">{{journal.journalNameEndYearAndMonth}}</span>
        </div>

        <div *ngIf="hasFormerJournalName()" class="row form-group">
            <label class="col-xs-2 text-left">Former Journal Name:</label>
            <span class="col-xs-5">{{formerJournalName()}}</span>
        </div>

        <div class="row form-group">
            <label class="col-xs-2 text-left">Journal Notes:</label>
            <span class="col-xs-5 break-word">{{journal.notes}}</span>
        </div>

    </div>

    <div class="inline" style="padding: 6px; width: auto;">
        <p class="bold inline journal-section-divider" >
            Journal Data
        </p>

        <div class="journal-data-container center-element" style="margin-top: 18px; margin-bottom: 32px;">
            <tab-container (tabChange)="onTabChange($event)">
                <ng-template tab-pane title="Related Journal Name History">
                    <div class="journal-tab">
                        <p class="bold left-indent journal-subtitle-spacer" *ngIf="!hasRelatedJournals()">No related names for the selected journal.</p>

                        <div style="margin-left: 12px; margin-top: 6px;">
                            <div *ngIf="hasRelatedJournals()">
                                <div class="subtitle-margin-top">
                                    <default-table>
                                        <thead class="journal-thead" style="background-color: #4CAF50; color: white; font-size: 14px !important; font-weight: normal;">
                                        <tr>
                                            <td class="text-left" style="width: 90px;">Journal Id</td>
                                            <td class="text-left" style="width: 340px;">Journal Name</td>
                                            <td class="text-center" style="width: 140px;">Journal Name Start Date</td>
                                            <td class="text-center" style="width: 140px;">Journal Name End Date</td>
                                            <td class="text-left" style="width: 320px;">Relationship</td>
                                            <td class="text-center" style="width: 80px;">Switch To</td>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr *ngFor="let n of _relatedJournals">
                                            <td class="text-left" style="width: 90px;">{{n.journalId}}</td>
                                            <td class="text-left" style="width: 340px;">{{n.journalName}}</td>
                                            <td class="text-center" style="width: 140px;">{{n.displayStartDate}}</td>
                                            <td class="text-center" style="width: 140px;">{{n.displayEndDate}}</td>
                                            <td class="text-left" style="width: 320px;">{{n.relationship}}</td>
                                            <td class="text-center"><a class="inline" style="width: 80px" (click)="onNavigateToJournal(n)">Switch</a></td>
                                        </tr>
                                        </tbody>
                                    </default-table>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>

                <ng-template tab-pane title="Journal Reserves">
                    <div class="journal-tab">
                        <p class="bold left-indent journal-subtitle-spacer" *ngIf="!hasReservesData()">No reserves for journal.</p>

                        <div style="margin-left: 12px; margin-top: 6px;">
                            <div *ngIf="hasReservesData()">
                                <div class="subtitle-margin-top" style="width: 1120px;">
                                    <div>
                                        <div class="scrollableTableHeader">
                                            <div class="scrollableTableColumn text-center"><span class="inline" style="width: 60px">Edit</span></div>
                                            <div class="scrollableTableColumn text-center"><span class="inline" style="width: 60px">Delete</span></div>
                                            <div class="scrollableTableColumn text-center"><span class="inline" style="width: 100px">Year</span></div>
                                            <div class="scrollableTableColumn text-center"><span class="inline" style="width: 100px">Volume</span></div>
                                            <div class="scrollableTableColumn text-center"><span class="inline" style="width: 280px">Issue</span></div>
                                            <div class="scrollableTableColumn text-left"><span class="inline" style="width: 360px">Notes</span></div>
                                        </div>
                                        <div style="height: 400px; overflow-y:auto;overflow-x: hidden;">
                                            <div class="scrollableTableRow" *ngFor="let h of _reserves">
                                                <div class="scrollableTableColumn text-center"><a class="inline" style="width: 60px" (click)="onEditJournalReserve(h)">Edit</a></div>
                                                <div class="scrollableTableColumn text-center"><a class="inline" style="width: 60px" (click)="onDeleteJournalReserve(h)">Delete</a></div>
                                                <div class="scrollableTableColumn text-center"><span class="inline" style="width: 100px">{{h.journalYear}}</span></div>
                                                <div class="scrollableTableColumn text-center"><span class="inline" style="width: 100px">{{h.volume}}</span></div>
                                                <div class="scrollableTableColumn text-left"><span class="inline" style="width: 280px">{{h.issue}}</span></div>
                                                <div class="scrollableTableColumn text-left"><span class="inline" style="width: 360px">{{h.notes}}</span></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>

                <ng-template tab-pane title="Journal Subscriptions">
                    <div class="journal-tab">
                        <p class="bold left-indent journal-subtitle-spacer" *ngIf="!hasSubscriptionsData()">No subscriptions for the selected journal.</p>

                        <div style="margin-left: 12px; margin-top: 6px;">

                            <div *ngIf="hasSubscriptionsData()">
                                <div class="subtitle-margin-top" style="overflow-x: auto;">
                                    <div class="subtitle-margin-top">
                                        <div class="scrollableTableHeader">
                                            <div class="scrollableTableColumn text-center"><span class="inline" style="width: 70px">View / Edit</span></div>
                                            <div class="scrollableTableColumn text-center"><span class="inline" style="width: 50px">Delete</span></div>
                                            <div class="scrollableTableColumn text-center"><span class="inline" style="width: 150px">Subscription Type</span></div>
                                            <div class="scrollableTableColumn text-center"><span class="inline" style="width: 80px">Start Date</span></div>
                                            <div class="scrollableTableColumn text-center"><span class="inline" style="width: 80px">End Date</span></div>
                                            <div class="scrollableTableColumn text-center"><span class="inline" style="width: 70px">Issues per Year</span></div>
                                            <div class="scrollableTableColumn text-left"><span class="inline" style="width: 200px">Publisher</span></div>
                                            <div class="scrollableTableColumn text-left"><span class="inline" style="width: 300px">Notes</span></div>
                                        </div>
                                        <div style="height: 400px; overflow-y:auto;overflow-x: hidden;">
                                            <div class="scrollableTableRow" *ngFor="let s of _subscriptions">
                                                <div class="scrollableTableColumn text-center"><a class="inline" style="width: 70px" (click)="onEditJournalSubscription(s)">View / Edit</a></div>
                                                <div class="scrollableTableColumn text-center"><a class="inline" style="width: 50px" (click)="onDeleteJournalSubscription(s)">Delete</a></div>
                                                <div class="scrollableTableColumn text-center"><span class="inline" style="width: 150px">{{s.typeJournalSubscription.description}}</span></div>
                                                <div class="scrollableTableColumn text-center"><span class="inline" style="width: 80px">{{formatDate(s.subscriptionStartYear, s.subscriptionStartMonth)}}</span></div>
                                                <div class="scrollableTableColumn text-center"><span class="inline" style="width: 80px">{{formatDate(s.subscriptionEndYear, s.subscriptionEndMonth)}}</span></div>
                                                <div class="scrollableTableColumn text-center"><span class="inline" style="width: 70px">{{s.issuesPerYear}}</span></div>
                                                <div class="scrollableTableColumn text-left"><span class="inline" style="width: 200px">{{s.publisher}}</span></div>
                                                <div class="scrollableTableColumn text-left"><span class="inline" style="width: 300px">{{s.notes}}</span></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>

                <ng-template tab-pane title={{referenceTabTitle}}>
                    <div class="journal-tab" style="margin-left: 12px; margin-top: 6px;">
                        <p class="bold left-indent journal-subtitle-spacer" *ngIf="!hasAssignedReferences()">No assigned reference for journal.</p>

                        <div class="flex-container background-transparent">

                            <div class="flex-item">
                                <div *ngIf="hasAssignedReferences()">
                                    <h4>{{getReferenceCountText()}} - Select to reassign</h4>
                                    <span class="inline" style="margin-bottom: 8px;">
                                        <button type="button" class="btn-link bold" (click)="onSelectReferences(true)">Select All</button>
                                        <button type="button" class="btn-link bold" style='margin-left: 18px;' (click)="onSelectReferences(false)">Unselect All</button>
                                    </span>
                                    <default-table style="width: 90%">
                                        <thead>
                                        <tr>
                                            <td class="text-center">Edit Reference</td>
                                            <td class="text-center">Select</td>
                                            <td class="text-center">Reference No.</td>
                                            <td class="text-center">Brief Reference</td>
                                            <td class="text-left">Title</td>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr *ngFor="let r of _referencesEx">
                                            <td class="text-center"><a class="text-center" (click)="selectReference(r.data)">Edit</a></td>
                                            <td><input type="checkbox" class="mediumcheckbox" [(ngModel)]="r.selected" /></td>
                                            <td>{{r.data.referenceId}}</td>
                                            <td>{{r.data.briefRef(isStaff()) }}</td>
                                            <td class="ref-title-wrap">{{r.data.title}}</td>
                                        </tr>
                                        </tbody>
                                    </default-table>
                                </div>

                            </div>

                            <div *ngIf="canReassignReferences()" class="flex-item" style="margin-top: 49px; margin-left: 49px;">
                                <button type="button" class="inline btn btn-reassign-references" style="width: 174px;" (click)="onReassignReference()">Reassign References</button>
                            </div>

                        </div>
                    </div>
                </ng-template>

            </tab-container>
        </div>
    </div>

</div>

<div class="row" style="width: 96%; margin-left: 14px;">
    <div *ngIf="_data.inSubEditor && _data.journalEditState == JournalEditState.Journal">
        <journal-editor (stateChange) ="refreshData($event)"></journal-editor>
    </div>
</div>

<div class="row" style="width: 96%; margin-left: 14px;">
    <div *ngIf="_data.inSubEditor && _data.journalEditState == JournalEditState.Reserve">
        <journal-reserve-editor (stateChange) ="refreshData($event)"></journal-reserve-editor>
    </div>
</div>

<div class="row" style="width: 96%; margin-left: 14px;">
    <div *ngIf="_data.inSubEditor && _data.journalEditState == JournalEditState.Subscription">
        <journal-subscription-editor (stateChange) ="refreshData($event)"></journal-subscription-editor>
    </div>
</div>

<div class="row" style="width: 96%; margin-left: 14px;">
    <div *ngIf="_data.inSubEditor && _data.journalEditState == JournalEditState.Reassign">
        <journal-reassign (stateChange) ="refreshData($event)"></journal-reassign>
    </div>
</div>

<journal-selector></journal-selector>

<div>
    <span *ngIf="_edm.editing" class="inline">
        <span class="inline" style="font-size: 18px;">{{_adding ? "Adding a Prediction Class" : "Editing a Prediction Class" }}</span>
    </span>

    <editor-header [edm]="_edm" [customButtons]="true">
        <span customButtons class="inline" style="margin-left: 10px; margin-top: 6px;">
            <button *ngIf="!_edm.editing" type="button" class="btn btn-sm btn-primary" [disabled]="!canSave()" (click)="onSave()">Save</button>
            <button *ngIf="_edm.editing" type="button" class="btn btn-sm btn-primary" [disabled]="!canApply()" (click)="onApply()">Apply</button>
            <button *ngIf="canShowBack()" type="button" class="btn btn-sm btn-primary" (click)="onBack()">Back</button>
            <button *ngIf="canCancel()" type="button" class="btn btn-sm btn-primary" (click)="onCancel()">Cancel</button>
        </span>
    </editor-header>
    <span *ngIf="(!_edm.editing)">
        <button type="button" class="inline btn-sm btn-primary" style="width: 170px;" (click)="onAdd()">Add Prediction Class</button>
    </span>
</div>
<div class="" *ngIf="_validationMessage">
    <p class="alert-danger">{{_validationMessage}}</p>
</div>
<br />
<div [attr.data-hidden]="_edm.editing">
    <div *ngIf="_typePredictionClassesEx && _typePredictionClassesEx.length">
        <div>
            <div class="scrollableTableHeader">
                <div class="scrollableTableColumn text-center"><span class="inline" style="width: 55px">Edit</span></div>
                <div class="scrollableTableColumn text-center"><span class="inline" style="width: 75px">Remove</span></div>
                <div class="scrollableTableColumn text-center"><span class="inline" style="width: 140px">Prediction Class</span></div>
                <div class="scrollableTableColumn text-center"><span class="inline" style="width: 280px">Description</span></div>
            </div>
            <div style="height: 240px; overflow-y:auto;overflow-x: hidden;">
                <div class="scrollableTableRow" *ngFor="let p of _typePredictionClassesEx"
                     [ngStyle]="{ 'background-color': p.backgroundColor }">
                    <div class="scrollableTableColumn text-center"><a class="inline" style="width: 55px" (click)="onEdit(p)">Edit</a></div>
                    <div class="scrollableTableColumn text-center"><a class="inline" style="width: 75px" (click)="onRemove(p)">Remove</a></div>
                    <div class="scrollableTableColumn text-center"><span class="inline" style="width: 140px">{{p.data.predictionClass}}</span></div>
                    <div class="scrollableTableColumn text-center"><span class="inline" style="width: 280px">{{p.data.predictionClassDescription}}</span></div>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="_edm.editing">
    <type-prediction-class-editor #self [entity]="_edm.currentEntity"></type-prediction-class-editor>
</div>
<confirm-finish-override-dialog></confirm-finish-override-dialog>


import {Component, OnInit} from '@angular/core';
import * as _ from 'lodash';
import { ActivatedRoute } from '@angular/router';
import { StateMap, IRouteDefinition } from '../../services/common';
import { EXPOSURE_ROUTES } from './exposure.routes';

@Component({
    selector: 'exposure',
    templateUrl: './exposure.html',
})
export class ExposureComponent implements OnInit {

    _routes = <IRouteDefinition[]>_.values(EXPOSURE_ROUTES);

    constructor(public _stateMap: StateMap, public _route: ActivatedRoute) {

    }

    ngOnInit() {

    }

    canShow(routeDef: IRouteDefinition) {

        if (this._stateMap.currentUser.isStaff) {
            // Exception to prevent consultant staff from seeing otherwise staff viewable content.
            if (routeDef.rolesExcluded) {
                const roles = routeDef.rolesExcluded.split(',').map(function (item) {
                    return item.trim();
                });

                if (roles != null && roles.length > 0) {
                    return (!this.userRoleFound(roles));
                }
            }
            return true;
        }

        if (routeDef.staffOnly) { return false; }
        return true;
    }

    currentRoute() {
        return _.find(this._routes, r => r.name == this.routeName);
    }

    getPageTitle() {
        return (this.currentRoute() ? this.currentRoute().title : '') + ': ';
    }

    get routeName() {
        return this._stateMap.currentRouteName;
    }

    isActiveTab(r: IRouteDefinition) {
        const currentRouteName = this.routeName || this._routes[0].name;
        return r.name == currentRouteName;
    }

    userRoleFound(roles: string[]): boolean {
        for (let idx = 0; idx < roles.length; idx++) {
            if (this._stateMap.currentUser.isInRole(roles[idx])) {
                return true;
            }
        }

        return false;
    }
}

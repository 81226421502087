import { EntityBase } from './EntityBase';
import { ClusterGroup } from './ClusterGroup';

import { Validator } from 'breeze-client';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

export class SuperCluster extends EntityBase {

   /// <code> Place custom code between <code> tags
   
   /// </code>

   // Generated code. Do not place code below this line.
   superClusterId: number;
   superClusterName: string;
   clusterGroupId: number;
   notes: string;
   colorCode: string;
   created: Date;
   createdUser: string;
   modified: Date;
   modifyUser: string;
   rowVersion: number;
   clusterGroup: ClusterGroup;
}


import { Component, Input, OnInit } from '@angular/core';

import { provideParent, UnitOfWork } from '../../services/common';
import { EditPropParent } from '../../controls/common';

import { TypePrediction } from '../../entities/EntityModels';

@Component({
    selector: 'type-prediction-editor',
    templateUrl: './type-prediction-editor.html',
    providers: [provideParent(TypePredictionEditorComponent, EditPropParent)]
})
export class TypePredictionEditorComponent implements OnInit, EditPropParent {
    @Input() entity: TypePrediction;

    constructor(public uow: UnitOfWork) {

    }

    ngOnInit() {
    }

    checkIfNull(value: string) {
        if (value == '') {
            return null;
        } else {
            return value;
        }
    }

    getError(propName: string) {
        if (this.entity == null) {
            return;
        }

        const err = this.entity.getErrorFor(propName);
        if (err) {
            return err;
        }
        return null;
    }
}

<div class="modal" id="journal-selector-dialog" role="dialog" style="transform: translateY(-2%);">
    <div class="modal-dialog" style="width: 1500px; min-height: 600px;">
        <div class="modal-content">
            <div class="modal-header">

                <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>

                <p class="bold input-lg inline">Journal Search</p>

                <div class="inline-block" style="width: 100%; clear:both; margin-top: -8px;">
                    <div class="float-left" style="width: 60%;"> </div>
                    <div class="float-right" style="width: 30%; margin-right: 8px;">
                        <button class="inline btn btn-select-journal" style="width: 190px;" type="button" (click)="onOk()" [disabled]="!canSelect()">Choose Selected Journal</button>
                        <button class="btn btn-primary navbar-btn btn-image-height" type="button" (click)="onCancel()">Cancel</button>
                    </div>
                </div>

            </div>

            <div class="modal-body">

                <p class="bold">{{_userMessage}}</p>

                <div style="max-height: 1200px; overflow-y: auto; overflow-x: hidden; min-height: 120px; border-bottom: 2px solid lightgray;">
                    <div class="inline full-width">
                        <div class="inline float-left" style="width: 32% !important; margin-left: 14px;">
                            <span class="bold inline">Journal Search Term:</span>
                            <input class="inline" style="margin-left: 12px; width: 300px;" type="text" [(ngModel)]="_searchTerm">
                        </div>
                        <div class="float-left" style="width: 57% !important;">
                            <div class="grid-container inline">
                                <div>
                                    <button *ngIf="!_publishersOnly" class="btn btn-primary navbar-btn" type="button" (click)="search()" [disabled]="!canSearch()">Search</button>
                                    <button *ngIf="_publishersOnly" type="button" class="inline btn btn-search-publishers" style="width: 170px;" (click)="searchPublications()" [disabled]="!canSearchPublications()">Search Publishers</button>
                                </div>
                                <div *ngIf="!_publishersOnly" >
                                    <span class="bold inline text-right" style="color: #2e6da4; width: 200px;">Starts With:</span>
                                    <input type="checkbox" class="largecheckbox inline vertical-middle" style="margin-left: 16px;" [(ngModel)]="_startsWith">
                                </div>
                                <div  *ngIf="!_publishersOnly" >
                                    <span class="bold inline text-right" style="color: #2e6da4; width: 260px;">Has Journal Reserves:</span>
                                    <input type="checkbox" class="largecheckbox inline vertical-middle" style="margin-left: 16px;" [(ngModel)]="_hasJournalReservesInLibrary">
                                </div>
                                <div>
                                    <button *ngIf="_publishersOnly" type="button" class="inline btn btn-download-search-publishers-results" style="width: 200px;" (click)="downloadPublisherSearchResults()" [disabled]="!canDownloadPublisherSearchResults()">Download Search Results</button>
                                </div>
                                <div>
                                    <span class="bold inline text-right" style="color: #2e6da4; width: 200px;">Restrict to Publishers:</span>
                                    <input type="checkbox" class="largecheckbox inline vertical-middle" style="margin-left: 16px;" (change)="onRestrictSearchToPublishersChange($event.target.checked)" [(ngModel)]="_publishersOnly">
                                </div>
                                <div  *ngIf="!_publishersOnly" >
                                    <span class="bold inline text-right" style="color: #2e6da4; width: 260px;">Active Subscriptions:</span>
                                    <input type="checkbox" class="largecheckbox inline vertical-middle" style="margin-left: 16px;" [(ngModel)]="_activeSubscriptionsOnly">
                                </div>
                            </div>
                            <div  *ngIf="!_publishersOnly" style="margin-top: 18px;">
                                <span class="bold inline text-right" style="color: #2e6da4; margin-left: 268px;">Subscription Type:</span>
                                <select id="sub-type-select" class="inline form-control list-background-white"
                                        style="width: 320px; border: 1px solid #2e6da4; margin-left: 12px;"
                                        (change)="selectedJournalSubscriptionType = $event.target.value">
                                    <option selected value>Any</option>
                                    <option *ngFor="let t of _typeJournalSubscriptions" [value]="t.typeJournalSubscriptionId">{{t.description}}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <i *ngIf="_pageState.isLoading" class="fa fa-circle-o-notch fa-spin inline" style="font-size:38px; color: #337ab7; margin-left: 24px;"></i>
                </div>
                <div style="margin-left: 12px; margin-bottom: 6px;">
                    <journal-search></journal-search>
                </div>

                <div *ngIf="_showJournalData" class="row journal-data-tab-container">
                    <div *ngIf="_journal != null" class="inline"
                         style="background-color: #337ab7; padding: 6px; width: 98%; border: 2px solid #2e6da4; border-radius: 3px;">
                        <span class="bold inline" style="font-size: 16px; color: white;">{{'Data for Journal: ' + _journal.journalName}}</span>
                    </div>

                    <div *ngIf="_journalDataIsPrepared" class="left-indent" style="padding-bottom: 20px; margin-top: 18px; width: 98%;">
                        <tab-container>
                            <ng-template tab-pane title="Related Journal Names">
                                <div class="journal-data-container" style="width: 100%;">
                                    <journal-names></journal-names>
                                </div>
                            </ng-template>
                            <ng-template tab-pane title="Journal Reserves"  style="width: 100%;">
                                <div class="journal-data-container">
                                    <journal-reserves></journal-reserves>
                                </div>
                            </ng-template>

                            <ng-template tab-pane title="Journal Subscriptions"  style="width: 100%;">
                                <div class="journal-data-container">
                                    <journal-subscriptions></journal-subscriptions>
                                </div>
                            </ng-template>
                        </tab-container>
                    </div>
                </div>

                </div>

        </div>

    </div>

</div>


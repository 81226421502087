import { Injectable } from '@angular/core';
import { UserManager } from './user-manager';
import { Reference, TypeWorkflowContact } from '../entities/EntityModels';
import { WorkflowEntityState, WorkflowState } from './workflow-state';
import { UnitOfWork } from './unit-of-work';
import { WorkflowService } from './workflow-service';

@Injectable()
export class WorkflowEmailService {

    private _workflowSendToEmails: Array<WorkflowState> = new Array<WorkflowState>();
    private _workflowNotifyEmails: Array<WorkflowState> = new Array<WorkflowState>();

    constructor(private _uow: UnitOfWork, private _workflowService: WorkflowService, private _userManager: UserManager) {
    }

    // ----------------------------------------------------------------------------------------------------------------------------------
    // Email Notifications for Sent To and Notify
    // ----------------------------------------------------------------------------------------------------------------------------------
    public addEmailToSendToList(contact: TypeWorkflowContact, reference: Reference, resend: boolean) {

        const workflow = new WorkflowState();

        workflow.workflowReferenceId = reference.referenceId;
        workflow.workflowEntityName = reference.entityType.shortName;

        if (resend) {
            workflow.workflowPropertyName = 'sentAgain';
        } else {
            workflow.workflowPropertyName = 'sentTo';
        }

        if (reference.finished) {
            workflow.workflowPropertyName = workflow.workflowPropertyName + 'Finished'; // Won't include the to complete task labeled 'Finish Data' if already finished
        }
        workflow.workflowEntityState = WorkflowEntityState.Modified;
        workflow.workflowContactId = contact.workflowContactId;
        workflow.workflowContactEmail = contact.emailAddress;

        this._workflowSendToEmails.push(workflow);
        this._workflowService.addWorkflowEvent(workflow, reference);

    }

    // Sent To is used to assign a Reference to a Staff member.
    // Only one Staff member should be assigned to a Reference at a time.
    public reassignPreviousSentTo(reference: Reference): Promise<any> {

        const currentUserName = this._userManager.currentUser.name;
        let workflowContactId = 0;

        return this._uow.typeWorkflowContactRepository.all().then(r => {
            const contact = r.filter(c => c.emailAddress.toUpperCase() == this._userManager.currentUser.name.toUpperCase());

            if (contact != null && contact.length > 0) {
                workflowContactId = contact[0].workflowContactId;
            }

            if (reference.workflowRecordedActions != null && reference.workflowRecordedActions.length > 0) {

                const sentto = reference.workflowRecordedActions
                    .filter(s => (s.typeWorkflowActionId == 'SENT TO' || s.typeWorkflowActionId == 'SENT AGAIN') && s.actionCompletedDate == null);

                if (sentto != null && sentto.length > 0) {
                    sentto.forEach(s => {
                        s['completedWorkflowActionId'] = 'REASSIGN';
                        s['actionCompletedDate'] = new Date();
                        s['completedUser'] = currentUserName;

                        if (workflowContactId > 0) {
                            s['completedWorkflowContactId'] = workflowContactId;
                        }
                    });
                }
            }

            return Promise.resolve(true);
        });
    }

    public removeEmailFromSendToList(contactId: number, reference: Reference) {

        if (this._workflowSendToEmails == null || this._workflowSendToEmails.length == 0) {
            return;
        }

        // Discard requested notifications
        const reject = this._workflowSendToEmails.filter(e => e.workflowContactId == contactId);
        if (reject != null) {

            reject.forEach(w => {
                this._workflowService.rejectWorkflowRecordedNotification(w, reference);
            });
        }

        // Save remaining notifications if they exists
        const save = this._workflowSendToEmails.filter(e => e.workflowContactId != contactId);
        this._workflowSendToEmails = save;

    }

    public addEmailToNotifyList(contact: TypeWorkflowContact, reference: Reference) {

        const workflow = new WorkflowState();

        workflow.workflowReferenceId = reference.referenceId;
        workflow.workflowEntityName = reference.entityType.shortName;
        workflow.workflowPropertyName = 'notify';
        workflow.workflowEntityState = WorkflowEntityState.Modified;
        workflow.workflowContactId = contact.workflowContactId;
        workflow.workflowContactEmail = contact.emailAddress;

        this._workflowNotifyEmails.push(workflow);
        this._workflowService.addWorkflowEvent(workflow, reference);

    }

    public removeEmailFromNotifyList(contactId: number, reference: Reference) {

        if (this._workflowNotifyEmails == null || this._workflowNotifyEmails.length == 0) {
            return;
        }

        // Discard requested notifications
        const reject = this._workflowNotifyEmails.filter(e => e.workflowContactId == contactId);
        if (reject != null) {
            reject.forEach(w => {
                this._workflowService.rejectWorkflowRecordedNotification(w, reference);
            });
        }

        // Save remaining notifications if they exists
        const save = this._workflowNotifyEmails.filter(e => e.workflowContactId != contactId);
        this._workflowNotifyEmails = save;

    }

    public sendEmails(reference: Reference) {

        // Ensure pending "Sent To" tasks are marked complete for the Reference
        // The action invoked from the settings below should be a "finish only" type of action
        if (this._workflowSendToEmails.length > 0) {
            const workflow = new WorkflowState();
            workflow.workflowReferenceId = reference.referenceId;
            workflow.workflowEntityName = reference.entityType.shortName;
            workflow.workflowPropertyName = 'sentTo=finished';
            workflow.workflowEntityState = WorkflowEntityState.Modified;
            this._workflowService.addWorkflowEvent(workflow, reference);
        }

        while (this._workflowSendToEmails.length) {

            const email = this._workflowSendToEmails.pop();

            this.sendSendToAssigmentEmail(reference, email.workflowContactEmail);
        }

        while (this._workflowNotifyEmails.length) {

            const email = this._workflowNotifyEmails.pop();

            this.sendNotifyEmail(reference, email.workflowContactEmail);
        }
    }

    public sendNotifyEmail(reference: Reference, emailAddress: string) {

        const params = {
            title: reference.title,
            briefReference: reference.briefReference,
            referenceId: reference.referenceId,
            emailAddress: emailAddress
        };

        this._uow.fetch('Workflow/NotifyStaff', params);
        return;
    }

    public sendSendToAssigmentEmail(reference: Reference, emailAddress: string) {
        const params = {
            title: reference.title,
            briefReference: reference.briefReference,
            referenceId: reference.referenceId,
            emailAddress: emailAddress
        };

        this._uow.fetch('Workflow/SendToStaff', params);
        return;
    }

    public isResend(reference: Reference) {
        if (reference.workflowRecordedActions == null || reference.workflowRecordedActions.length == 0) {
            return false;
        }

        const pending = reference.workflowRecordedActions
            .filter(a => (a.typeWorkflowActionId == 'SENT TO' || a.typeWorkflowActionId == 'SENT AGAIN') && a.actionCompletedDate == null);

        if (pending == null || pending.length == 0) {
            return false;
        }

        return true;
    }
}

import { Location } from '@angular/common';
import { Component, ViewChild, Inject, forwardRef, OnInit } from '@angular/core';

import { Router, ActivatedRoute } from '@angular/router';

import { UnitOfWork, StateMap, ErrorLogger, provideParent, UserManager } from '../../services/common';
import { EditManager, IEditHost, UtilFns } from '../../utils/common';
import { EditPropParent } from '../../controls/common';
import { Material, ExposureSurvey, MaterialExposureSurvey, MaterialExposureSurveyMeasure, MaterialExposureSurveyIsomer } from '../../entities/EntityModels';

import { MaterialSelectorComponent } from '../material/material-selector.component';
import { RecordExposureSurveyMeasuresComponent, MeasureEditData } from './record-exposure-survey-measures.component';

import { SelectableEntity } from '../../entities/projections/SelectableEntity';

import { ROUTES } from '../routes';
import * as _ from 'lodash';

@Component({
    selector: 'material-exposure-survey-measures',
    templateUrl: './material-exposure-survey-measures.html',
    providers: [provideParent(MaterialExposureSurveyMeasuresComponent, EditPropParent)]
})
export class MaterialExposureSurveyMeasuresComponent implements IEditHost<MaterialExposureSurveyMeasure>, OnInit {

    @ViewChild(MaterialSelectorComponent, { static: true }) _materialSelectorComponent: MaterialSelectorComponent;

    private _isLoading = false;

    private _adding: boolean;
    _unsurveyedMessage: string;
    _validationMessage: string;

    // Default type ids
    private _typeCremeVersionId = 0;
    private _typeExposureMeasureId = 0;
    private _typeExposureMeasureValueId = 0;

    private _selectedExposureSurveyIndex: number;
    private _selectedExposureSurvey: ExposureSurvey;

    private _currentMaterialExposureSurvey: MaterialExposureSurvey;
    private _materialExposureSurveys: MaterialExposureSurvey[] = [];

    private _selectedMaterialExposureSurveyMeasure: MaterialExposureSurveyMeasure;
    private _materialExposureSurveyMeasures: MaterialExposureSurveyMeasure[] = [];

    public _materialExposureSurveyIsomers: string;

    private _selectedMaterial: Material;
    _edm: EditManager<MaterialExposureSurveyMeasure>;
    public _materialExposureSurveyMeasuresEx: SelectableEntity<MaterialExposureSurveyMeasure>[] = [];

    private _data: MeasureEditData;

    constructor( @Inject(forwardRef(() => RecordExposureSurveyMeasuresComponent)) public _parent: RecordExposureSurveyMeasuresComponent,
        private _uow: UnitOfWork, private _stateMap: StateMap, private _userManager: UserManager, private _router: Router,
        public _route: ActivatedRoute, public _location: Location, public _errorLogger: ErrorLogger) {

        this._data = _parent._data;
    }

    ngOnInit() {
        this._edm = new EditManager<MaterialExposureSurveyMeasure>(this, this._uow, this._stateMap, 'Exposure Survey Material Measures');

        this._edm.pageState.canShowMessage = false;

        if (this._data.materialId > 0) {
            this.fetchMaterial(this._data.materialId);
        }

        this.getDefaultMeasureTypeIds();
    }

    fetchMaterial(materialId: number) {
        this._edm.entities = null;
        this._materialExposureSurveys = [];

        this._isLoading = true;
        this._uow.materialRepository.withId(materialId).then(m => {
            if (m) {
                this._selectedMaterial = m;
                this.fetchMaterialExposureSurveys();
            }
            this._isLoading = false;
        });
    }

    fetchMaterialExposureSurveys() {

        let filterId = 0;

        this._materialExposureSurveys = [];

        const params = { materialId: this._selectedMaterial.materialId };

        this._isLoading = true;

        this._uow.fetch('ExposureSurveys/MaterialExposureSurveyByMaterialId', params).then(m => {
            this._materialExposureSurveys = m;
            if (this._materialExposureSurveys != null && this._materialExposureSurveys.length > 0) {
                if (this._data.exposureSurveyId > 0) {
                    const expsurveyid = this._materialExposureSurveys.findIndex(e => e.exposureSurveyId == this._data.exposureSurveyId);
                    filterId = (expsurveyid < 0) ? 0 : expsurveyid;
                }
                this._selectedExposureSurveyIndex = filterId;
                this.filterExposureSurveyByIndex(this._selectedExposureSurveyIndex); // Set the selected exposure survey which will call the fetchMeasures method
            } else {
                this._currentMaterialExposureSurvey = null;
                this._unsurveyedMessage = 'This Material was not included in any Exposure Survey.';
            }
            this._isLoading = false;
        });
    }

    fetchIsomers() {
        this._materialExposureSurveyIsomers = '';

        const params = {materialExposureSurveyId: this._currentMaterialExposureSurvey.materialExposureSurveyId};
        this._uow.fetch('ExposureSurveys/MaterialExposureSurveyIsomerByMaterialExposureSurveyId', params).then(i => {
            if (i != null && i.length > 0) {
                this._materialExposureSurveyIsomers = i.map((m: { material: Material; }) => {
                    return this.formatIsomerIdentifiers(m.material);
                }).join('; ');
            }
        });
    }

    formatIsomerIdentifiers(material: Material) {
        const rifmId = (material.formattedRIFMId == '-') ? '' : ' (' + material.formattedRIFMId + ')';
        return material.realCASNumber + rifmId;
    }

    fetchMeasures() {

        if (this._selectedMaterial == null || this._selectedExposureSurvey == null) { return; }

        const params = { materialId: this._selectedMaterial.materialId, exposureSurveyId: this._selectedExposureSurvey.exposureSurveyId };

        this._isLoading = true;
        this._uow.fetch('ExposureSurveys/FetchExposureSurveyMaterialMeasures', params).then(m => {
            this._edm.entities = m;
            this._edm.updatePageState();

            this.formatSelectableEntities();

            this._isLoading = false;
        });
    }

    formatSelectableEntities() {
        this._materialExposureSurveyMeasuresEx = [];
        if (this._edm.entities == null || this._edm.entities.length < 1) { return; }

        _.clone(this._edm.entities).forEach(element => {
            const isMultiple = this.isDuplicateMeasureEntry(element.materialId, element.typeExposureMeasureId);
            this._materialExposureSurveyMeasuresEx.push(new SelectableEntity<MaterialExposureSurveyMeasure>(element, '#FFFFFF', '', isMultiple));
        });
    }

    get isLoading() {
        return this._isLoading;
    }

    set isLoading(value) {
        this._isLoading = value;
    }

    isStaff() {
        return this._stateMap.currentUser.isStaff;
    }

    get utilFns() {
        return UtilFns;
    }

    canAdd() {
        return ((!this._edm.editing) && this._currentMaterialExposureSurvey != null);
    }

    // edit manager overrides
    canApply() {
        if (this._edm.editing) {
            return (this._uow.hasChanges() && !this._edm.currentEntity.entityAspect.hasValidationErrors);
        }

        return false;
    }

    canCancel() {
        return (this._uow.hasChanges());
    }

    canDeactivate() {
        return !this.hasEdits();
    }

    hasEdits() {
        return this._uow.hasChanges();
    }

    canSave() {
        if (this._edm.editing) { return false; }
        if (!this._edm.hasEdits()) { return false; }
        if (!this._edm.validateBeforeSave()) { return false; }
        return true;
    }

    canShowBack() {
        if (!this._edm.editing) { return false; }
        return !this._uow.hasChanges();
    }

    onAdd() {
        if (this._edm.entities == null) {
            this._edm.entities = [];
        }

        this._adding = true;
        const m = this._uow.materialExposureSurveyMeasureFactory.create();

        m.materialId                    = this._selectedMaterial.materialId;
        m.exposureSurveyId              = this._selectedExposureSurvey.exposureSurveyId;
        m.typeExposureMeasureId         = this._typeExposureMeasureId;
        m.typeExposureMeasureValueId    = this._typeExposureMeasureValueId;
        // 03/07/2019: Use Creme Version of the Exposure Survey as the default
        m.typeCremeVersionId            = this._selectedExposureSurvey.typeCremeVersionId;
        m.created                       = new Date(); // the date is displayed in the table after apply so a valid date is needed here.

        this._edm.entities.push(m);
        this._edm.onSelect(m);
    }

    onApply() {

        this._validationMessage = '';

        if (this._edm.currentEntity.typeExposureMeasure == null) {
            this._validationMessage = 'Please select a Measure type other than None.';
            return;
        }

        if (this._edm.currentEntity.typeExposureMeasure.measureDescription.toLowerCase() == 'none') {
            this._validationMessage = 'Please select a Measure type other than None.';
            return;
        }

        if (this._edm.currentEntity.measureQualitativeResult == null && this._edm.currentEntity.measureQuantitativeResult == null) {
            this._validationMessage = 'Please enter a measure value.';
            return;
        }

        if (this._edm.currentEntity.typeCremeVersion.cremeVersion.toLowerCase() == 'none') {
            this._validationMessage = 'Please select a Creme Version other than None.';
            return;
        }

        // 01/26/2019: Measure data for the same measure type can be recorded for a material/survey more than once.
        // if (this.isDuplicateMeasureEntry(this._edm.currentEntity.materialId, this._edm.currentEntity.typeExposureMeasureId)) {
        //    this._validationMessage = 'This measure type has already been assigned to this material for the selected exposure survey.';
        //    return;
        // }

        this._edm.onApplyCore();
        this._adding = false;

        this.formatSelectableEntities();

        return true;
    }

    onBack() {
        this._adding = false;
        this._edm.editing = false;
    }

    onCancel() {
        this._validationMessage = '';

        this._adding = false;
        this._edm.onCancelCore();
        this._edm.entities = null;

        this._materialExposureSurveyMeasuresEx = [];

        this.fetchMeasures();
    }

    onEdit(mx: SelectableEntity<MaterialExposureSurveyMeasure>) {

        if (mx == null) { return; }

        this._selectedMaterialExposureSurveyMeasure = this.filterToMaterialExposureSurveyMeasureById(mx.data.materialExposureSurveyMeasureId);

        if (this._selectedMaterialExposureSurveyMeasure == null) { return; }

        this._edm.onSelect(this._selectedMaterialExposureSurveyMeasure);
    }

    onRemove(mx: SelectableEntity<MaterialExposureSurveyMeasure>) {

        if (mx == null) { return; }
        this._selectedMaterialExposureSurveyMeasure = this.filterToMaterialExposureSurveyMeasureById(mx.data.materialExposureSurveyMeasureId);

        if (this._selectedMaterialExposureSurveyMeasure == null) {
            this._validationMessage = 'Error occurred, could not remove the selected measure from the list.';
            return;
        }

        if (this._selectedMaterialExposureSurveyMeasure.entityAspect.entityState.isAdded()) {
            this._selectedMaterialExposureSurveyMeasure.entityAspect.rejectChanges();
        } else {
            this._selectedMaterialExposureSurveyMeasure.entityAspect.setDeleted();
        }

        _.remove(this._edm.entities, this._selectedMaterialExposureSurveyMeasure);

        this.formatSelectableEntities();
    }

    onSave() {
        if (this._edm.hasEdits()) {
            this._edm.onSaveCore().then(() => {

                this._adding = false;
                this._edm.editing = false;

                this.fetchMeasures();
            });
        }
    }

    onShowMaterial(mat: Material) {
        if (mat == null) { return; }

        this._router.navigate(UtilFns.asRouterParts(ROUTES.Material, mat.materialId, ROUTES.Material.childRoutes.MaterialExposure));
    }


    onShowMaterialSurveys() {
        if (this._selectedExposureSurvey == null) {
            return;
        }

        this._router.navigate(['../exposure-survey-lists', { id: this._selectedExposureSurvey.exposureSurveyId }], { relativeTo: this._route });
    }

    isDuplicateMeasureEntry(materialId: number, typeExposureMeasureId: number): boolean {
        if (this._edm.entities == null) { return false; }

        const measure = this._edm.entities.filter(m => m.materialId == materialId && m.typeExposureMeasureId == typeExposureMeasureId);
        if (measure != null && measure.length > 1) { return true; }

        return false;
    }

    // Filters
    public filterExposureSurveyByIndex(ix: number) {

        this._selectedExposureSurveyIndex = ix;

        this._selectedExposureSurvey        = this._materialExposureSurveys[ix].exposureSurvey;
        this._data.exposureSurveyId         = this._selectedExposureSurvey.exposureSurveyId;
        this._currentMaterialExposureSurvey = this._materialExposureSurveys[ix];

        this.fetchMeasures();
        this.fetchIsomers();
        return true;
    }

    filterToMaterialExposureSurveyMeasureById(materialExposureSurveyMeasureId: number): MaterialExposureSurveyMeasure {
        if (this._edm.entities == null || this._edm.entities.length < 1) {
            return null;
        }

        const measure = this._edm.entities.filter(m => m.materialExposureSurveyMeasureId == materialExposureSurveyMeasureId);
        if (measure != null && measure.length > 0) {
            return measure[0];
        }

        return null;
    }

    selectMaterial() {
        UtilFns.showModal(this._materialSelectorComponent, this).then(mli => {
            if (mli == null) { return; }
            return this._uow.materialRepository.withId(mli.materialId);
        }).then(m => {
            if (m) {
                this._selectedMaterial      = m;
                this._data.materialId       = m.materialId;
                this._data.exposureSurveyId = null;

                this.fetchMaterialExposureSurveys();
            }
        });
    }

    getDefaultMeasureTypeIds() {
        this._uow.typeExposureMeasureRepository.all().then(m => {
            if (m != null && m.length > 0) {
                const types = m.filter(t => t.measureName.toLowerCase() == 'none');
                if (types != null && types.length > 0) {
                    this._typeExposureMeasureId = types[0].typeExposureMeasureId;
                    this._typeExposureMeasureValueId = types[0].typeExposureMeasureValueId;
                }
            }
        });

        this._uow.typeCremeVersionRepository.all().then(c => {
            if (c != null && c.length > 0) {
                const types = c.filter(t => t.cremeVersion.toLowerCase() == 'none');
                if (types != null && types.length > 0) {
                    this._typeCremeVersionId = types[0].typeCremeVersionId;
                }
            }
        });
    }
}

import { EntityBase } from './EntityBase';
import { TypeStudy } from './TypeStudy';
import { UserChangeReport } from './UserChangeReport';

import { Validator } from 'breeze-client';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

export class UserChangeReportStudy extends EntityBase {

   /// <code> Place custom code between <code> tags
   
   /// </code>

   // Generated code. Do not place code below this line.
   userChangeReportStudyId: number;
   userChangeReportId: number;
   typeStudyId: string;
   created: Date;
   createdUser: string;
   modified: Date;
   modifyUser: string;
   rowVersion: number;
   typeStudy: TypeStudy;
   userChangeReport: UserChangeReport;
}


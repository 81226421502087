<div style="margin-top: 12px;">
    <div class="row">
        <i *ngIf="isLoading" class="fa fa-circle-o-notch fa-spin" style="font-size:38px; color: #337ab7;"></i>
    </div>

    <div class="flex-container background-transparent" style="width: 100% !important;">
        <div class="flex-item">
            <span class="inline bold">Select a Material Surveyed as an Isomer: </span>

            <select *ngIf="_uniqueIsomerMaterials != null && _uniqueIsomerMaterials.length > 0"
                    class="dropdown-toggle list-background-white left-indent" style="margin-left: 4px;"
                    [ngModel]="_selectedIsomerMaterialIndex" style="width:180px;"
                    (change)="filterIsomerMaterialByIndex($event.target.value)">
                <option *ngFor="let im of _uniqueIsomerMaterials; let ix = index" class="dropdown" [value]="ix">{{im.textId}}</option>
            </select>
        </div>

        <div class="flex-item">
            <div *ngIf="_selectedIsomerMaterial" class="left-indent inline" style="border: 1px solid gray; background-color: aliceblue; margin-top: 4px; padding: 4px;">
                <span class="notify-text bold inline">Selected Isomer CAS No.:</span>
                <a class="inline notify-text bold left-indent" (click)="onShowMaterial(_selectedIsomerMaterial)">{{_selectedIsomerMaterial.realCASNumber}}</a>

                <span class="notify-text bold inline some-space">RIFM ID:</span>
                <span class="notify-text form-control-static left-indent">{{_selectedIsomerMaterial.formattedRIFMId}}</span>

                <span class="notify-text bold inline some-space">Principal Synonym:</span>
                <span class="notify-text form-control-static left-indent">{{_selectedIsomerMaterial.principalSynonym().synonymWordOrWebVersion()}}</span>
            </div>
        </div>
    </div>
    <div class="flex-container background-transparent" style="margin-top: 8px; width: 80%;">
        <div class="flex-item">
            <div *ngIf="_uniqueExposureSurveys != null && _uniqueExposureSurveys.length > 0" class="inline">
                <span class="bold inline" style="margin-top: 18px; padding-right: 4px;">Available Exposure Surveys for Isomer: </span>
                <select class="dropdown-toggle list-background-white left-indent" [ngModel]="_selectedExposureSurveyIndex"
                        (change)="filterExposureSurveyByIndex($event.target.value)">
                    <option *ngFor="let es of _uniqueExposureSurveys; let ix = index" class="dropdown" [value]="ix">{{es.textId}}</option>
                </select>
            </div>
        </div>

        <div class="flex-item">
            <div *ngIf="_materialExposureSurveys != null && _materialExposureSurveys.length > 0" class="inline">
                <span class="bold inline" style="margin-top: 18px; padding-right: 4px;">Primary Survey Material: </span>
                <select class="dropdown-toggle left-indent list-background-white" style="background-color: aliceblue !important;"
                        [ngModel]="_selectedMaterialExposureSurveyIndex" (change)="filterMaterialExposureSurveyByIndex($event.target.value)">
                        <option *ngFor="let mes of _materialExposureSurveys; let ix = index" class="dropdown" [value]="ix">{{mes.material?.realCASNumber + ' (' + mes.material?.formattedRIFMId + ')'}}</option>
                </select>
            </div>
        </div>
    </div>

    <div *ngIf="_selectedMaterialExposureSurvey != null">
        <div style="margin-left: 10px; margin-top: 8px;">
            <h4 *ngIf="(_materialExposureSurveyMeasuresEx == null || _materialExposureSurveyMeasuresEx.length < 1)">There is no Measure data for this material/isomer for the selected survey.</h4>
            <span *ngIf="_materialExposureSurveyMeasuresEx != null && _materialExposureSurveyMeasuresEx.length > 0" class="inline">
                <span class="bold inline subtitle-margin-top" header>Exposure Survey Measure Data</span>
                <img style="margin-left: 10px;" class="inline multiple-measure-comment-image" src="assets/images/MultipleExposureMeasures.png" />
                <span class="inline italic notify-text">Indicates multiple runs of the same measure within an Exposure Survey.</span>
            </span>
            <default-table *ngIf="_materialExposureSurveyMeasuresEx != null && _materialExposureSurveyMeasuresEx.length > 0">
                <thead>
                <tr>
                    <td>Multiple Runs</td>
                    <td>Measure</td>
                    <td>Value</td>
                    <td>Date Entered</td>
                    <td>Notes</td>
                    <td>Creme Version</td>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let mx of _materialExposureSurveyMeasuresEx">
                    <td><img *ngIf="mx.isMultiple" class="multiple-measure-image" src="assets/images/MultipleExposureMeasures.png" /></td>
                    <td>{{mx.data.typeExposureMeasure.measureDescription}}</td>
                    <td>{{mx.data.measureQualitativeResult || mx.data.measureQuantitativeResult}}</td>
                    <td>{{utilFns.formatDate(mx.data.created)}}</td>
                    <td class="text-left">{{mx.data.measureNotes}}</td>
                    <td class="text-center">{{mx.data.typeCremeVersion.cremeVersion}}</td>
                </tr>
                </tbody>
            </default-table>
        </div>
</div>

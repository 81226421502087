<div style="margin-top: 15px"></div>
<div *ngIf="_entity && !_data.inSubEditor" class="form-horizontal">
    <div class="form-group">
        <div class="col-md-7">
            <button type="button " class="btn btn-sm btn-success btn-no-margin" (click)="onAttachAuthor() ">Assign Author</button>
            <button type="button " class="btn btn-sm btn-success btn-no-margin left-indent" (click)="onCreateAuthor() ">Create Author</button>
            <label class="left-indent">
                <input class="left-indent" type="checkbox" title="et al." (click)="onEtAlChange($event)" [disabled]='!_etAlEnabled' [checked]="!!getEtAlRefAuthor()"> et. al.
            </label>
        </div>
    </div>
    <div class="form-group" style="margin-bottom: 0px;">
        <div class="col-md-6 ">
            <default-table>
                <thead>
                    <tr>
                        <td>Author</td>
                        <td>Initials</td>
                        <td>Author Id</td>
                        <td>Edit</td>
                        <td>Unassign</td>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let e of referenceAuthors() ">
                        <td>{{e.author?.authorName}}</td>
                        <td>{{e.author?.initials}}</td>
                        <td>{{e.author?.authorId}}</td>
                        <td><a (click)="onEditAuthor(e)">Edit</a></td>
                        <td><a (click)="onDetachAuthor(e)">Unassign Author</a></td>
                    </tr>
                </tbody>
            </default-table>
        </div>
    </div>
</div>

<author-selector></author-selector>
<author-editor *ngIf="_data.editAuthor" (apply)="onApplyAuthor($event)" [entity]="_data.editAuthor"></author-editor>

import { SynonymsComponent } from './synonyms.component';
import { StructureIdentityComponent } from './structure-identity.component';
import { AnalyticalResultsComponent } from './analytical-results.component';
import { ToxicDataComponent } from './toxic-data.component';
import { FlavorComponent } from './flavor.component';
import { SynopsisComponent } from './synopsis.component';
import { NaturalsComponent } from './naturals.component';
import { StatusRootComponent } from './status-root.component';
import { FragranceUseComponent } from './fragrance-use.component';
import { PredictionsComponent } from './predictions.component';
import { RelationsComponent } from './relations.component';
import { REACHRegistrationsComponent } from './reach-registrations.component';
import { HouseStatusesComponent } from './house-statuses.component';
import { PublicationsComponent } from './publications.component';
// import { LabelingComponent} from './labeling.component';
import { SafetyAssessmentComponent } from './safety-assessment.component';
import { MaterialConsumptionsComponent } from './material-consumptions.component';
import { MaterialExposureComponent } from './material-exposure.component';

export const MATERIAL_ROUTES = {
    Synonyms: { name: 'Synonyms', title: 'Synonyms', path: 'synonyms', component: SynonymsComponent },
    StructureIdentity: {
        name: 'StructureIdentity',
        title: 'Structure / Identity',
        path: 'structure-identity',
        component: StructureIdentityComponent
    },
    Relations: { name: 'Relations', title: 'Relations', path: 'relations', component: RelationsComponent },
    AnalyticalResults: { name: 'AnalyticalResults', title: 'Physical Data', path: 'physical-data', component: AnalyticalResultsComponent },
    ToxicData: { name: 'ToxicData', title: 'Toxicological Data', path: 'toxic-data', component: ToxicDataComponent },
    Flavor: { name: 'Flavor', title: 'Flavor', path: 'flavor', component: FlavorComponent },
    Synopsis: { name: 'Synopsis', title: 'Synopsis', path: 'synopsis', component: SynopsisComponent },
    Status: { name: 'Status', title: 'Status', path: 'status', component: StatusRootComponent },
    FragranceUse: {
        name: 'FragranceUse',
        title: 'Fragrance Use',
        path: 'fragrance-use',
        component: FragranceUseComponent,
        staffOnly: true,
        rolesPermitted: 'ifra',
        rolesExcluded: 'consultant'
    },
    // Labeling: { name: 'Labeling', title: 'Labeling', path: 'labeling', component: LabelingComponent },
    Naturals: {
        name: 'Naturals',
        title: 'Naturals',
        path: 'naturals',
        component: NaturalsComponent,
        staffOnly: true,
        rolesPermitted: 'ifra'
    },
    Predictions: { name: 'Predictions', title: 'Predictions', path: 'predictions', component: PredictionsComponent },
    Publications: { name: 'Publications', title: 'Publications', path: 'publications', component: PublicationsComponent },
    Reach: { name: 'Reach', title: 'REACH', path: 'reach', component: REACHRegistrationsComponent },
    SafetyAssessment: {
        name: 'SafetyAssessment',
        title: 'Safety Assessment',
        path: 'safety-assessment',
        component: SafetyAssessmentComponent
    },
    RIFMStatus: { name: 'RIFMStatus', title: 'RIFM Status', path: 'rifm-status', component: HouseStatusesComponent, staffOnly: true },
    MaterialConsumptions: {
        name: 'MaterialConsumptions',
        title: 'Material Consumption',
        path: 'material-consumptions',
        component: MaterialConsumptionsComponent,
        staffOnly: true,
        rolesPermitted: 'ifra',
        rolesExcluded: 'consultant'
    },
    MaterialExposure: {
        name: 'MaterialExposure',
        title: 'Material Exposure',
        path: 'material-exposure',
        component: MaterialExposureComponent,
        staffOnly: true,
        rolesExcluded: 'consultant'
    }
};



import { Component, forwardRef, Inject, OnInit, ViewChild } from '@angular/core';
import * as _ from 'lodash';

import { provideParent, StateMap, UnitOfWork } from '../../services/common';
import { EditPropParent } from '../../controls/edit-prop.component';
import { EditManager } from '../../utils/common';
import { TypePredictionClass } from '../../entities/EntityModels';

import { PredictionEditData, TypePredictionEditorBaseComponent } from './type-prediction-editor-base.component';
import { TypePredictionClassEditorComponent } from './type-prediction-class-editor.component';
import { ConfirmFinishOverrideComponent } from './confirm-finish-override.component';
import { SelectableEntity } from '../../entities/projections/SelectableEntity';

@Component({
    selector: 'type-prediction-classes',
    templateUrl: './type-prediction-classes.html',
    providers: [provideParent(TypePredictionClassesComponent, EditPropParent)]
})
export class TypePredictionClassesComponent implements OnInit, EditPropParent {
    @ViewChild(ConfirmFinishOverrideComponent, { static: true }) _confirmFinishOverrideComponent: ConfirmFinishOverrideComponent;
    @ViewChild(TypePredictionClassEditorComponent) _typePredictionClassEditorComponent: TypePredictionClassEditorComponent;

    private _data: PredictionEditData;

    public _typePredictionClassesEx: SelectableEntity<TypePredictionClass>[] = [];

    _edm: EditManager<TypePredictionClass>;
    private _adding = false;

    private _isLoading = false;
    private _selectedTypePredictionClass: TypePredictionClass;

    _validationMessage: string;

    constructor(@Inject(forwardRef(() => TypePredictionEditorBaseComponent)) public _parent: TypePredictionEditorBaseComponent,
                private _uow: UnitOfWork, private _stateMap: StateMap) {

        this._data = _parent._data;
    }

    ngOnInit() {

        this._edm = new EditManager<TypePredictionClass>(this, this._uow, this._stateMap, 'Type Prediction Classes');
        this._edm.pageState.canShowMessage = false;

        this.loadData();
    }

    loadData() {
        this._isLoading = true;

        this._uow.typePredictionClassRepository.all().then(c => {
            this._edm.entities = c;

            this.formatSelectableEntities();

            this._isLoading = false;
        });
    }

    refresh() {
        this._isLoading = true;
        return this._uow.typePredictionClassRepository.all().then(c => {
            this._edm.entities = c;

            this.formatSelectableEntities();

            this._isLoading = false;
        });
    }

    formatSelectableEntities() {
        this._typePredictionClassesEx = [];
        if (this._edm.entities == null || this._edm.entities.length < 1) {
            return;
        }

        _.clone(this._edm.entities).forEach(element => {
            this._typePredictionClassesEx.push(new SelectableEntity<TypePredictionClass>(element, '#FFFFFF'));
        });

        this._typePredictionClassesEx = _.sortBy(this._typePredictionClassesEx, e => e.data.predictionClass);
    }

    checkIfNull(value: string) {
        if (value == '') {
            return null;
        } else {
            return value;
        }
    }

    getError(propName: string) {
        if (this._selectedTypePredictionClass == null) {
            return;
        }

        const err = this._selectedTypePredictionClass.getErrorFor(propName);
        if (err) {
            return err;
        }
        return null;
    }

    // EditManager overrides
    canApply() {
        if (this._edm.editing && this._uow.hasChanges()) {
            if (this._edm.currentEntity != null) {
                return (!this._edm.currentEntity.entityAspect.hasValidationErrors);
            }
            return true;
        }
        return false;
    }

    canCancel() {
        return (this._uow.hasChanges());
    }

    canSave() {
        if (!this._edm.hasEdits()) {
            return false;
        }
        if (!this._edm.validateBeforeSave()) {
            return false;
        }
        return true;
    }

    canShowBack() {
        if (!this._edm.editing) {
            return false;
        }
        return (!this._uow.hasChanges());
    }

    onAdd() {
        this._adding = true;
        this._data.inSubEditor = true;

        const params = {
            predictionClass: 'None'
        };
        const p = this._uow.typePredictionClassFactory.create(params);
        this._edm.entities.push(p);
        this._edm.onSelect(p);
    }

    onApply() {
        this._validationMessage = '';

        if (this._edm.editing) {
            if (this._typePredictionClassEditorComponent.predictionClassKey == null) {
                this._validationMessage = 'Please enter a class name.';
                return;
            }

            if (this._typePredictionClassEditorComponent.predictionClassKey == 'None') {
                this._validationMessage = 'Please enter a class name other than None.';
                return;
            }

            const cnt = this.matchTypePredictionClassEntry(this._typePredictionClassEditorComponent.predictionClassKey);

            if (this._adding && cnt > 0) {
                this._validationMessage = 'A Prediction Class with the same name already exists.';
                return;
            }

            if (this._adding == false && cnt > 1) {
                this._validationMessage = 'A Prediction Class with the same name already exists.';
                return;
            }

            // Work-around for the type of primary key
            this._edm.currentEntity.predictionClass = this._typePredictionClassEditorComponent.predictionClassKey;

            this._edm.onApplyCore();
            this._adding = false;
            this._data.inSubEditor = false;

            this.formatSelectableEntities();
        }

        return true;
    }

    onBack() {
        this._adding = false;
        this._edm.editing = false;
    }

    onCancel() {
        this._validationMessage = '';

        this._adding = false;
        this._data.inSubEditor = false;

        this._edm.onCancelCore();
        this._edm.entities = null;

        this.loadData();
    }

    onEdit(px: SelectableEntity<TypePredictionClass>) {

        if (px == null) {
            return;
        }

        this._selectedTypePredictionClass = this.filterToTypePredictionClass(px.data.predictionClass);

        if (this._selectedTypePredictionClass == null) {
            return;
        }

        this._edm.onSelect(this._selectedTypePredictionClass);

        this._data.inSubEditor = true;
    }

    onRemove(pcx: SelectableEntity<TypePredictionClass>) {

        if (pcx == null) {
            return;
        }

        this._selectedTypePredictionClass = this.filterToTypePredictionClass(pcx.data.predictionClass);

        if (this._selectedTypePredictionClass == null) {
            this._validationMessage = 'Error occurred, could not remove the selected prediction class.';
            return;
        }

        const messages: string[] = []; // Only needed to satisfy a parameter
        this._confirmFinishOverrideComponent.showModal('Confirm Deletion of the Prediction Class',
            'There may be materials assigned to this prediction class that will prevent its removal.', messages).then(ok => {
            if (ok) {
                if (this._selectedTypePredictionClass.entityAspect.entityState.isAdded()) {
                    this._selectedTypePredictionClass.entityAspect.rejectChanges();
                } else {
                    this._selectedTypePredictionClass.entityAspect.setDeleted();
                }

                _.remove(this._edm.entities, this._selectedTypePredictionClass);

                this.refresh();

                this.formatSelectableEntities();

                this._data.inSubEditor = true; // Has unsaved changes
            }
        });
    }

    onSave() {
        if (this._edm.hasEdits()) {
            this._edm.onSaveCore()
                .then(() => {

                    this._adding = false;
                    this._edm.editing = false;
                    this._data.inSubEditor = false;

                    return this.refresh();
                })
                .catch(err => {
                    this._edm.setSaveFailedStatus(err);
                });
        }
    }

    filterToTypePredictionClass(predictionClass: string): TypePredictionClass {
        if (this._edm.entities == null || this._edm.entities.length < 1) {
            return null;
        }

        const pclass = this._edm.entities.filter(e => e.predictionClass == predictionClass);
        if (pclass != null && pclass.length > 0) {
            return pclass[0];
        }
    }

    matchTypePredictionClassEntry(predictionClass: string): number {
        if (this._edm.entities == null) {
            return 0;
        }

        const pclass = this._edm.entities.filter(e => e.predictionClass == predictionClass);
        if (pclass == null) {
            return 0;
        }

        return pclass.length;
    }
}

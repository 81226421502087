import { Component, ElementRef } from '@angular/core';
import { UtilFns } from '../../utils/common';
import { ModalDialog } from '../../controls/common';

@Component({
    selector: 'user-acknowledgement',
    templateUrl: './user-acknowledgement.html',

})
export class UserAcknowledgementComponent extends ModalDialog<boolean> {
    constructor(public elementRef: ElementRef) {
        super(elementRef);
    }

    onAccept(accept: boolean) {
        UtilFns.returnModal(this, accept);
    }

    onCancel(cancel: boolean) {
        UtilFns.returnModal(this, cancel);
    }

}

import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';

import { StateMap, UnitOfWork } from '../../services/common';
import { EditManager, IEditHost, LocationFns, UtilFns } from '../../utils/common';
import { ColumnSorter, PageState, SortColumn } from '../../controls/common';

import { MATERIAL_ROUTES } from './material.routes';
import { FlavorUsesItemComponent } from './flavor-uses-item.component';
import { MaterialUses } from '../../entities/EntityModels';

@Component({
    selector: 'flavor-uses',
    templateUrl: './flavor-uses.html',
})
export class FlavorUsesComponent implements IEditHost<MaterialUses>, OnInit {
    @Input() materialId: number;
    @ViewChild(FlavorUsesItemComponent) _editingComponent: FlavorUsesItemComponent;

    _PADI: number;
    _edm: EditManager<MaterialUses>;
    _pageState = new PageState('Flavor Uses');
    _colSorter: ColumnSorter;

    constructor(public _uow: UnitOfWork, public _stateMap: StateMap, public _location: Location) {

    }

    ngOnInit() {
        LocationFns.setTab(this._location, 1);
        this._stateMap.currentRouteName = MATERIAL_ROUTES.Flavor.name;
        const material = this._stateMap.currentMaterial;

        this._edm = new EditManager<MaterialUses>(this, this._uow, this._stateMap, 'Material Uses');

        this._colSorter = new ColumnSorter(this, {
                'Average Usual (ppm)': (mu: MaterialUses) => this.format(mu.averageUsual),
                'Average Maximum (ppm)': (mu: MaterialUses) => this.format(mu.averageMaximum),
                'Mean Daily Consumption (gms)': (mu: MaterialUses) => mu.typeFoodCategory ? this.format(mu.typeFoodCategory.meanDailyConsumption) : '',
                'Food Description': (mu: MaterialUses) => mu.typeFoodCategory && mu.typeFoodCategory.foodDescription
            },
            new SortColumn('Food Description', true),
            (sortColumn) => this._pageState.sortColumn = sortColumn);

        // setTimeout needed because of Angular RC5 bug where Location is not set correctly until
        // after timeout resolves.
        setTimeout(() => {
            const isReturning = LocationFns.updatePageState(this._location, this._stateMap, this._pageState, (state: PageState) => {
                this._colSorter.sortColumn = state.sortColumn;
            });
            this.search(isReturning);
        }, 0);
    }

    canDeactivate() {
        return !this._edm.hasEdits();
    }

    search(isReturning: boolean) {
        this._edm.pageState.isLoading = true;
        const p = {materialId: this.materialId};
        this._uow.materialUsesRepository.whereWithParams(p).then(r => {
            // this._edm.entities = _.sortBy(r, r => r.typeFoodCategory.foodDescription);
            this._edm.entities = r;
            this._edm.pageState.isLoaded = r;
            this.calcPADI();
            this._colSorter.sort(this._edm.entities);
        });
    }

    onSelectionChanged(evt: any) {
        this._edm.onSelect(evt.selectedRowsData[0]);
    }

    format(val: number) {
        if (!val) {
            return '';
        }
        return UtilFns.formatNumber(val, 10);
    }

    sortWith(colSorter: ColumnSorter) {
        colSorter.sort(this._edm.entities);
    }

    calcPADI() {
        if (this._edm.hasEntities()) {
            this._PADI = this._edm.entities.map(e => (e.averageUsual * e.typeFoodCategory.meanDailyConsumption) / 1000).reduce((a, b) => a + b);
        }
    }

    // EditManager overrides

    onAdd() {
        // don't allow add unless current synonym is valid.
        if (!this._edm.validateCurrent()) {
            return;
        }

        const rs = this._uow.materialUsesFactory.create();
        rs.materialId = this.materialId;
        rs.typeFoodCategoryId = null; // otherwise will default to 0 - which will not cause validation error.
        this._edm.entities.push(rs);
        this._edm.onSelect(rs);
    }

    onDelete() {
        this._edm.onDeleteCore();
        this.calcPADI();
    }

    onApply() {
        if (!this._edm.onApplyCore()) {
            return false;
        }
        this.calcPADI();
        return true;
    }

    onCancel() {
        this._edm.onCancelCore();
        this.calcPADI();
    }


}

<div class="col-md-12 indent-top-sm">
    <div class="row" style="margin-left: 4px;" *ngIf="_predictions && _predictions.length == 0">No Cramer classifications found</div>
    <div class="row page-title form-group" *ngIf="_cramerClassification">
        <strong class="">Cramer Classification:  </strong>
        <span class="">{{ _cramerClassification}}</span>
    </div>
    <div *ngIf="!_isEditEnabled">
        <div class="row">
            <div *ngFor="let p of _predictions">
                <div class="panel panel-default col-md-2 " style="margin-right: 10px;">
                    <div class="panel-body" [class.alert-warning]="!p.enabled">
                        <div class="row text-center bold thead">{{p.typePrediction.predictionName}}</div>
                        <div class="row text-center">{{p.predictionClass}}</div>
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="_cramerComment && isStaff()" class="row">
            <div class="panel panel-default col-md-6 " style="margin-right: 30px;">
                <div class="panel-body">
                    <div class="form-group">
                        <strong class="">Comment:  </strong>
                        <span class="">{{ _cramerComment}}</span>
                    </div>
                </div>
            </div>

        </div>
    </div>

    <default-table *ngIf="_isEditEnabled">
        <h4 header>Predictions</h4>
        <thead>
            <tr>
                <td>Prediction type</td>
                <td>Prediction class</td>
                <td>Comment</td>
                <td>Enabled</td>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let e of _predictions" (click)="onSelect(e)">
                <td>{{e.typePrediction?.predictionName}}</td>
                <td>{{e.predictionClass}}</td>
                <td>{{e.comment}}</td>
                <td>{{e.enabled}}</td>
            </tr>
        </tbody>
    </default-table>


</div>
import { Injectable } from '@angular/core';
import * as _ from 'lodash';

import { UnitOfWork } from './unit-of-work';
import { UserManager } from './user-manager';
import { UtilFns } from '../utils/common';

import { MaterialListItem } from '../entities/projections/MaterialListItem';
import { ReferenceListItem } from '../entities/projections/ReferenceListItem';

export interface IMaterialSearchResult {
    searchType: string;
    searchTerm: string;
    searchDescription: string;
    materials: MaterialListItem[];
    relatedMonographNumbers: number[];
    totalCount: number;
    materialSearchCode: string;
    pageSize: number;
    pageNumber: number;
}

export interface IReferenceSearchResult {
    searchType: string;
    searchTerm: string;
    searchDescription: string;
    references: ReferenceListItem[];
    totalCount: number;
    referenceSearchCode: string;
    pageSize: number;
    pageNumber: number;
}

interface IMaterialSearchParams {
    searchTerm: string;
    searchType: string;
    pageNumber: number;
    pageSize: number;
}

interface IReferenceSearchParams {
    searchTerm: string;
    searchType: string;
    pageNumber: number;
    pageSize: number;
}

enum FoundInCode {
    FEMA,
    RIFM_ID,
    Internal_Material_Notes,
    CAS,
    Matching_RIFM_ID,
    Alternate_CAS,
    EINECS,
    ECN,
    Synonym,
    Regulatory_Agency,
    Alternative_Identifier,
    ECNo,
    MaterialId
}

enum FoundInReferenceCode {
    Abstracts,
    Author,
    Brief_Reference,
    Compliance,
    Contractor_Id,
    Govt_Doc_Number,
    Govt_Sponsor,
    Journal,
    Journal_Detail,
    Protocols,
    Title,
    ReferenceId,
    SearchAll,
    Sponsoring_Company,
    Staff_Comments
}

@Injectable()
export class SearchService {

    _materialSearchCodeMap: {} = {
        SearchFEMAorMonograph: 'FEMA/Base RIFM ID',
        SearchRIFMId: 'RIFM ID',
        SearchCAS: 'CAS/EINECS/ECN/EC No. and associated materials with same RIFM ID',
        SearchSynonymsPlus: 'Synonym or Regulatory Agency',
        SearchSafetyAssessments: 'Safety Assessments',
        SearchFFIDS: 'FFIDS',
        SearchSynonyms: 'Synonym',
        SearchRegulatory: 'Regulatory Agency',
        SearchMaterialId: 'MaterialId',
        SearchInternalMaterialNotes: 'Internal Material Notes'
    };

    _referenceSearchCodeMap: {} = {
        SearchAbstracts: 'Abstracts',
        SearchAuthors: 'Authors',
        SearchBriefReferences: 'Brief Reference',
        SearchCompliance: 'Compliance',
        SearchContractorId: 'Contractor Id',
        SearchGovDocNumber: 'Govt. Document #',
        SearchGovSponsor: 'Govt. Sponsor',
        SearchJournalDetail: 'Journal Detail',
        SearchJournals: 'Journals',
        SearchProtocols: 'Protocols',
        SearchSponsoringCompany: 'Sponsoring Company',
        SearchStaffComments: 'Staff Comments',
        SearchTitles: 'Title',
        SearchAll: 'All'
    };

    constructor(public _uow: UnitOfWork, public _userManager: UserManager) {

    }

    search(searchTerm: string, searchType: string, pageSize: number): Promise<any> {
        const params = { searchTerm: UtilFns.encodeHackForAnd(searchTerm), searchType: searchType, pageSize: pageSize };
        return this._uow.fetch('search', params)
            .then(r => {
                const sr = r[0];
                sr.materialSearchResult = this.resolveMaterialSearchResult(sr.materialSearchResult);
                sr.referenceSearchResult = this.resolveReferenceSearchResult(sr.referenceSearchResult);
                return sr;
            });
    }

    fetchMaterialsPage(params: IMaterialSearchParams): Promise<IMaterialSearchResult> {
        if (params.searchTerm) {
            params.searchTerm = UtilFns.encodeHackForAnd(params.searchTerm);
        }
        return this._uow.fetch('fetchMaterialsPage', params).then(r => {
            return this.resolveMaterialSearchResult(r[0]);
        });
    }

    fetchReferencesPage(params: IReferenceSearchParams): Promise<IReferenceSearchResult> {
        if (params.searchTerm) {
            params.searchTerm = UtilFns.encodeHackForAnd(params.searchTerm);
        }
        return this._uow.fetch('fetchReferencesPage', params).then(r => {
            return this.resolveReferenceSearchResult(r[0]);
        });
    }

    // Try 136-G2-30  ->  uses SearchRIFMId
    //  or 34902-57-3 ( uses SearchCAS and returns a matching RIFM_ID )
    resolveMaterialSearchResult(sr: IMaterialSearchResult) {
        const user = this._userManager.currentUser;
        sr.searchDescription = this._materialSearchCodeMap[sr.materialSearchCode];

        const searchTerm = sr.searchTerm;
        const monographNumbers = sr.relatedMonographNumbers;
        sr.materials.forEach(m => {

            const locs: FoundInCode[] = [];
            if (sr.materialSearchCode == 'SearchFEMAorMonograph') {
                if ((m.fEMANumber || '').toString() == searchTerm) {
                    locs.push(FoundInCode.FEMA);
                    m.foundText = m.fEMANumber.toString();
                } else if ((m.monographNumber || '').toString() == searchTerm) {
                    locs.push(FoundInCode.RIFM_ID);
                    m.foundText = m.monographNumber.toString();
                } else if (locs.length == 0) {
                    locs.push(FoundInCode.Internal_Material_Notes);
                    m.foundText = '{not retrieved}';
                }
            } else if (sr.materialSearchCode == 'SearchCAS') {
                if ((m.realCASNumber || '').indexOf(searchTerm) >= 0) {
                    locs.push(FoundInCode.CAS);
                    m.foundText = m.realCASNumber;
                } else if ((m.alternateCASNumber || '').indexOf(searchTerm) != -1) {
                    locs.push(FoundInCode.Alternate_CAS);
                    m.foundText = m.alternateCASNumber;
                } else if ((m.eINECSNumber || '').indexOf(searchTerm) != -1) {
                    locs.push(FoundInCode.EINECS);
                    m.foundText = m.eINECSNumber;
                } else if ((m.eCNNumber || '').indexOf(searchTerm) != -1) {
                    locs.push(FoundInCode.ECN);
                    m.foundText = m.eCNNumber;
                } else if ((m.eCInventoryNumber || '').indexOf(searchTerm) != -1) {
                    locs.push(FoundInCode.ECNo);
                    m.foundText = m.eCInventoryNumber;
                } else if (m.alternativeIdFound) {
                    locs.push(FoundInCode.Alternative_Identifier);
                    m.foundText = searchTerm;
                } else if (user.isStaff && (m.internalMaterialNotes != null)) {
                    if (m.internalMaterialNotes.toLowerCase().indexOf(searchTerm.toLowerCase()) != -1) {
                        locs.push(FoundInCode.Internal_Material_Notes);
                        m.foundText = '{not retrieved}';
                    }
                } else if (locs.length == 0 && monographNumbers != null && monographNumbers.indexOf(m.monographNumber) != -1) {
                    locs.push(FoundInCode.Matching_RIFM_ID);
                    m.foundText = m.monographNumber.toString();
                }
            } else if (sr.materialSearchCode == 'SearchRIFMId') {
                if ((m.formattedRifmId || '').toLowerCase().indexOf(searchTerm.toLowerCase()) != -1) {
                    locs.push(FoundInCode.RIFM_ID);
                    m.foundText = m.formattedRifmId;
                } else if ((m.synonymFound || '').toLowerCase().indexOf(searchTerm.toLowerCase()) != -1) {
                    locs.push(FoundInCode.Synonym);
                    m.foundText = m.synonymFound;
                }
            } else if (sr.materialSearchCode == 'SearchSynonymsPlus') {
                if ((m.synonymFound || '').toLowerCase().indexOf(searchTerm.toLowerCase()) != -1) {
                    locs.push(FoundInCode.Synonym);
                    m.foundText = m.synonymFound;
                } else if (user.isStaff && (m.internalMaterialNotes != null) && (m.internalMaterialNotes.toLowerCase().indexOf(searchTerm.toLowerCase()) != -1)) {
                    locs.push(FoundInCode.Internal_Material_Notes);
                    m.foundText = '{not retrieved}';
                } else {
                    locs.push(FoundInCode.Regulatory_Agency);
                    m.foundText = '{not retrieved}';
                }
            } else if (sr.materialSearchCode == 'SearchSynonyms') {
                if ((m.synonymFound || '').toLowerCase().indexOf(searchTerm.toLowerCase()) != -1) {
                    locs.push(FoundInCode.Synonym);
                    m.foundText = m.synonymFound;
                }
            } else if (sr.materialSearchCode == 'SearchRegulatory') {
                locs.push(FoundInCode.Regulatory_Agency);
                m.foundText = '{not retrieved}';
            } else if (sr.materialSearchCode == 'SearchMaterialId') {
                locs.push(FoundInCode.MaterialId);
                m.foundText = m.materialId.toString();
            } else if (sr.materialSearchCode == 'SearchInternalMaterialNotes') {
                if (user.isStaff && (m.internalMaterialNotes != null)) {
                    if (m.internalMaterialNotes.toLowerCase().indexOf(searchTerm.toLowerCase()) != -1) {
                        locs.push(FoundInCode.Internal_Material_Notes);
                        m.foundText = '{not retrieved}';
                    }
                }
            }
            const tmps = locs.map(l => _.replace(FoundInCode[l], new RegExp('_', 'g'), ' '));
            m.searchResolution = tmps.join(', ');
        });
        return sr;
    }

    public getMaterialSearchDescription() {
        // undefined is ok - means there is no relevant material search

    }

    resolveReferenceSearchResult(sr: IReferenceSearchResult) {
        const searchTerm        = sr.searchTerm.toLowerCase();
        sr.searchDescription    = this._referenceSearchCodeMap[sr.referenceSearchCode];
        const user              = this._userManager.currentUser;

        sr.references.forEach(r => {
            const locs: FoundInReferenceCode[] = [];

            if (sr.referenceSearchCode == 'SearchAbstracts') {
                locs.push(FoundInReferenceCode.Abstracts);
            } else if (sr.referenceSearchCode == 'SearchAuthors') {
                locs.push(FoundInReferenceCode.Author);
            } else if (sr.referenceSearchCode == 'SearchBriefReferences') {
                locs.push(FoundInReferenceCode.Brief_Reference);
            } else if (sr.referenceSearchCode == 'SearchCompliance') {
                locs.push(FoundInReferenceCode.Compliance);
            } else if (sr.referenceSearchCode == 'SearchJournals') {
                locs.push(FoundInReferenceCode.Journal);
            } else if (sr.referenceSearchCode == 'SearchProtocols') {
                locs.push(FoundInReferenceCode.Protocols);
            } else if (sr.referenceSearchCode == 'SearchTitles') {
                locs.push(FoundInReferenceCode.Title);
            } else if (sr.referenceSearchCode == 'SearchReferenceId') {
                if (r.referenceId.toString() == searchTerm) {
                    locs.push(FoundInReferenceCode.ReferenceId);
                } else if (r.contractorId != null && r.contractorId.toLowerCase().indexOf(searchTerm) != -1) {
                    locs.push(FoundInReferenceCode.Contractor_Id);
                }
            } else if (sr.referenceSearchCode == 'SearchAll') {
                if (r.briefReference.toLowerCase().indexOf(searchTerm) != -1) {
                    locs.push(FoundInReferenceCode.Brief_Reference);
                } else if (r.journal.toLowerCase().indexOf(searchTerm) != -1) {
                    locs.push(FoundInReferenceCode.Journal);
                } else if (r.title.toLowerCase().indexOf(searchTerm) != -1) {
                    locs.push(FoundInReferenceCode.Title);
                } else if (r.contractorId != null && r.contractorId.toLowerCase().indexOf(searchTerm) != -1) {
                    locs.push(FoundInReferenceCode.Contractor_Id);
                } else if (user.isStaff && r.staffComments != null && r.staffComments.toLowerCase().indexOf(searchTerm.toLowerCase()) != -1) {
                    locs.push(FoundInReferenceCode.Staff_Comments);
                } else if (r.allAuthorNames != null && r.allAuthorNames.toLowerCase().indexOf(searchTerm.toLowerCase()) != -1) {
                    locs.push(FoundInReferenceCode.Author);
                }
            } else if (sr.referenceSearchCode == 'SearchJournalDetail') {
                    locs.push(FoundInReferenceCode.Journal_Detail);
            } else if (sr.referenceSearchCode == 'SearchGovDocNumber') {
                    locs.push(FoundInReferenceCode.Govt_Doc_Number);
            } else if (sr.referenceSearchCode == 'SearchGovSponsor') {
                locs.push(FoundInReferenceCode.Govt_Sponsor);
            } else if (sr.referenceSearchCode == 'SearchContractorId') {
                locs.push(FoundInReferenceCode.Contractor_Id);
            } else if (sr.referenceSearchCode == 'SearchSponsoringCompany') {
                locs.push(FoundInReferenceCode.Sponsoring_Company);
            } else if (sr.referenceSearchCode == 'SearchStaffComments') {
                locs.push(FoundInReferenceCode.Staff_Comments);
            }

            const tmps = locs.map(l => _.replace(FoundInReferenceCode[l], new RegExp('_', 'g'), ' '));
            r.searchResolution = tmps.join(', ');
        });

        return sr;
    }
}

<div class="row">
    <i *ngIf="_loading" class="fa fa-circle-o-notch fa-spin" style="font-size:38px; color: #337ab7;"></i>
</div>

<p *ngIf="canShowFileInformationalMessage()" class="alert-warning">At least one file needs to be assigned to a UVS record.</p>

<div class="form-horizontal">

    <edit-prop label="Property" prop="typeAnalyticalProcedureID" szs="2,6">
        <select [disabled]='disablePropertyTypeSelection()' class="form-control" [ngModel]="entity.typeAnalyticalProcedureID" (change)="entity.typeAnalyticalProcedureID=$event.target.value"
                (change)="onPropertyTypeChange()">
            <option disabled selected value>-- None --</option>
            <option *ngFor="let tap of _typeAnalyticalProcedures" [value]="tap.typeAnalyticalProcedureId" [htmlGreek]="tap && tap.analyticalProcedure"></option>
        </select>
    </edit-prop>

    <edit-prop *ngIf="!isUVS()" label="Observed Result" prop="observedResult" szs="2,5">
        <textarea class="form-control" rows=3 [(ngModel)]="entity.observedResult"></textarea>
    </edit-prop>

    <div *ngIf="isUVS()" class="form-group" style="margin-left: 286px;">
        <p class="bold page-subtitle">Manage UVA and UVS Files</p>

        <div>
            <button style="display:block;width:120px; height:30px; background-color: #337AB7; color: #FFFFFF;"
                    onclick="document.getElementById('fileInputElement').click()">Add UVS File</button>
            <input type="file" id="fileInputElement" style="display:none" accept=".pdf" (change)="onAddUVSFile($event.target.files)">
        </div>

        <div *ngIf="hasUVFileData()" class="inline">
            <table class="table table-striped table-bordered table-condensed small" style="width:auto;">
                <thead>
                <tr>
                    <td style="width: 240px;">File Name</td>
                    <td>Show File</td>
                    <td>Action</td>
                    <td style="width: 160px;">Remove</td>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let u of entityUVFiles()">
                    <td style="width: 240px;">{{u.fileName}}</td>
                    <td><a *ngIf="!isUVAdd(u.key)" class="inline" style="width: 55px" (click)="onShowUVSFile(u.key)">Show</a></td>
                    <td>{{translateUVSFileActionToLabel(u.key)}}</td>
                    <td><a class="inline" style="width: 160px" (click)="onRemoveUVSFile(u.key)">{{translateUVSFileCancelRemoveToLabel(u.key)}}</a></td>
                </tr>
                </tbody>
            </table>
        </div>

    </div>

    <edit-prop label="Observed Maximum" prop="observedMaximum" szs="2,5">
        <input class="form-control" type="text" [(ngModel)]="entity.observedMaximum">
    </edit-prop>

    <div class="form-group">
        <edit-prop isChild label="Reference No." prop="referenceId" szs="2,4">
            <input disabled class="form-control" type="text" [(ngModel)]="entity.referenceId">
        </edit-prop>
        <div class="col-md-1 ">
            <button type="button" class="btn btn-sm btn-success" (click)="onSelectReference()">Select Reference</button>
        </div>
    </div>

    <edit-prop label="Source" prop="analyticalSource" szs="2,4">
        <input class="form-control" type="text" (change)="onSourceChange($event.target.checked)" [(ngModel)]="entity.analyticalSource">
    </edit-prop>

    <edit-prop label="Test Method" prop="testMethod" szs="2,4">
        <input class="form-control" type="text" [(ngModel)]="entity.testMethod">
    </edit-prop>

    <edit-prop label="Factor" prop="analyticalFactor" szs="2,4">
        <input class="form-control" type="text" [(ngModel)]="entity.analyticalFactor">
    </edit-prop>

    <edit-prop label="Sample" prop="analyticalSample" szs="2,6">
        <textarea class="form-control" type="text" rows=6 [(ngModel)]="entity.analyticalSample"></textarea>
    </edit-prop>

</div>

<reference-selector></reference-selector>
<show-and-download-pdf [showUVSFile]="true"></show-and-download-pdf>

import { Component, forwardRef, Inject, OnInit, ViewChild } from '@angular/core';
import * as _ from 'lodash';

import { provideParent, StateMap, UnitOfWork } from '../../services/common';
import { EditPropParent } from '../../controls/edit-prop.component';
import { EditManager } from '../../utils/common';
import { TypePrediction } from '../../entities/EntityModels';

import { PredictionEditData, TypePredictionEditorBaseComponent } from './type-prediction-editor-base.component';
import { ConfirmFinishOverrideComponent } from './confirm-finish-override.component';
import { SelectableEntity } from '../../entities/projections/SelectableEntity';

@Component({
    selector: 'type-predictions',

    templateUrl: './type-predictions.html',
    providers: [provideParent(TypePredictionsComponent, EditPropParent)]
})
export class TypePredictionsComponent implements OnInit, EditPropParent {
    @ViewChild(ConfirmFinishOverrideComponent, { static: true }) _confirmFinishOverrideComponent: ConfirmFinishOverrideComponent;

    private _data: PredictionEditData;

    public _typePredictionsEx: SelectableEntity<TypePrediction>[] = [];

    _edm: EditManager<TypePrediction>;
    private _adding = false;

    private _isLoading = false;
    private _selectedTypePrediction: TypePrediction;

    _validationMessage: string;

    constructor(@Inject(forwardRef(() => TypePredictionEditorBaseComponent)) public _parent: TypePredictionEditorBaseComponent, private _uow: UnitOfWork,
                private _stateMap: StateMap) {

        this._data = _parent._data;
    }

    ngOnInit() {
        this._edm = new EditManager<TypePrediction>(this, this._uow, this._stateMap, 'Type Predictions');

        this.loadData();
    }

    loadData() {
        this._isLoading = true;

        this._uow.typePredictionRepository.all().then(p => {
            this._edm.entities = p;

            this.formatSelectableEntities();

            this._isLoading = false;
        });
    }

    refresh() {
        this._isLoading = true;
        return this._uow.typePredictionRepository.all().then(p => {
            this._edm.entities = p;
            this.formatSelectableEntities();

            this._isLoading = false;
        });
    }

    formatSelectableEntities() {
        this._typePredictionsEx = [];
        if (this._edm.entities == null || this._edm.entities.length < 1) {
            return;
        }

        _.clone(this._edm.entities).forEach(element => {
            this._typePredictionsEx.push(new SelectableEntity<TypePrediction>(element, '#FFFFFF'));
        });

        this._typePredictionsEx = _.sortBy(this._typePredictionsEx, e => e.data.predictionName);
    }

    checkIfNull(value: string) {
        if (value == '') {
            return null;
        } else {
            return value;
        }
    }

    getError(propName: string) {
        if (this._selectedTypePrediction == null) {
            return;
        }

        const err = this._selectedTypePrediction.getErrorFor(propName);
        if (err) {
            return err;
        }
        return null;
    }

    // EditManager overrides
    canApply() {
        if (this._edm.editing && this._uow.hasChanges()) {
            if (this._edm.currentEntity != null) {
                return (!this._edm.currentEntity.entityAspect.hasValidationErrors);
            }
            return true;
        }
        return false;
    }

    canCancel() {
        return (this._uow.hasChanges());
    }

    canSave() {
        if (!this._edm.hasEdits()) {
            return false;
        }
        if (!this._edm.validateBeforeSave()) {
            return false;
        }
        return true;
    }

    canShowBack() {
        if (!this._edm.editing) {
            return false;
        }
        return (!this._uow.hasChanges());
    }

    onAdd() {
        this._adding = true;
        this._data.inSubEditor = true;

        const p = this._uow.typePredictionFactory.create();
        this._edm.entities.push(p);
        this._edm.onSelect(p);
    }

    onApply() {
        this._validationMessage = '';

        if (this._edm.editing) {
            if (this.isDuplicateTypePredictionEntry(this._edm.currentEntity.predictionName)) {
                this._validationMessage = 'A Prediction Type with the same name already exists.';
                return;
            }

            const cnt = this.numberOfPrimaryJudgements();

            if (cnt > 1) {
                this._validationMessage = 'Only one prediction can be marked as a Primary Judgement.';
                return;
            }

            if (cnt == 0) {
                this._validationMessage = 'Warning: there are no Prediction Types marked as the Primary Judgement.';
            }

            this._edm.onApplyCore();
            this._adding = false;

            this.formatSelectableEntities();
        }

        return true;
    }

    onBack() {
        this._adding = false;
        this._edm.editing = false;
    }

    onCancel() {
        this._validationMessage = '';

        this._adding = false;
        this._data.inSubEditor = false;

        this._edm.onCancelCore();
        this._edm.entities = null;

        this.loadData();
    }

    onEdit(px: SelectableEntity<TypePrediction>) {

        if (px == null) {
            return;
        }

        this._selectedTypePrediction = this.filterToTypePredictionById(px.data.typePredictionId);

        if (this._selectedTypePrediction == null) {
            return;
        }

        this._edm.onSelect(this._selectedTypePrediction);

        this._data.inSubEditor = true;
    }

    onRemove(px: SelectableEntity<TypePrediction>) {

        if (px == null) {
            return;
        }

        this._selectedTypePrediction = this.filterToTypePredictionById(px.data.typePredictionId);

        if (this._selectedTypePrediction == null) {
            this._validationMessage = 'Error occurred, could not remove the selected prediction type.';
            return;
        }

        const messages: string[] = []; // Only needed to satisfy a parameter
        this._confirmFinishOverrideComponent.showModal('Confirm Deletion of the Prediction Type',
            'There may be materials assigned to this prediction type that will prevent its removal.', messages).then(ok => {
            if (ok) {
                if (this._selectedTypePrediction.entityAspect.entityState.isAdded()) {
                    this._selectedTypePrediction.entityAspect.rejectChanges();
                } else {
                    this._selectedTypePrediction.entityAspect.setDeleted();
                }

                _.remove(this._edm.entities, this._selectedTypePrediction);

                this.refresh();

                this.formatSelectableEntities();

                this._data.inSubEditor = true; // Has unsaved changes

            }
        });
    }

    onSave() {
        if (this._edm.hasEdits()) {
            this._edm.onSaveCore().then(() => {

                this._adding = false;
                this._edm.editing = false;

                this.refresh();
            });
        }

        this._data.inSubEditor = false;

    }

    filterToTypePredictionById(typePredictionId: number): TypePrediction {
        if (this._edm.entities == null || this._edm.entities.length < 1) {
            return null;
        }

        const prediction = this._edm.entities.filter(e => e.typePredictionId == typePredictionId);
        if (prediction != null && prediction.length > 0) {
            return prediction[0];
        }
    }

    isDuplicateTypePredictionEntry(predictionName: string): boolean {
        if (this._edm.entities == null) {
            return false;
        }

        const pred = this._edm.entities.filter(e => e.predictionName == predictionName);
        if (pred != null && pred.length > 1) {
            return true;
        }

        return false;
    }

    numberOfPrimaryJudgements(): number {
        if (this._edm.entities == null) {
            return 0;
        }

        const primaries = this._edm.entities.filter(e => e.primaryJudgment);

        if (primaries == null) {
            return 0;
        }

        return primaries.length;
    }
}

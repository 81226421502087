import { EntityBase } from './EntityBase';
import { Validator } from 'breeze-client';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

export class RIFMDownloadableFile extends EntityBase {

    /// <code> Place custom code between <code> tags
    /// </code>

    // Generated code. Do not place code below this line.
    rifmDownloadableFileId: number;
    fileCategory: string;
    fileIdentifier: string;
    fileSubFolder: string;
    fileName: string;
    fileLabel: string;
    fileDescription: string;
    notes: string;
    enabled: boolean;
    sortOrder: number;
    created: Date;
    createdUser: string;
    modified: Date;
    modifyUser: string;
    rowVersion: number;
}

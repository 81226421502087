<div class="additional-identifiers-container background-transparent" 
     *ngIf="_additionalMaterialIds && _additionalMaterialIds.length > 0">
    <div>
        <button type="button" (click)="toggleVisibility()" class="additional-identifiers-button">
            <span *ngIf="visibility =='shown'" class="fa fa-minus-circle minus-symbol"></span>
            <span *ngIf="visibility =='hidden'" class="fa fa-plus-circle plus-symbol"></span>
        </button>
        <span class="bold" style="color: #2e6da4;">Alternative Identifiers</span>
    </div>
    <div *ngIf="visibility =='shown'" class="additional-identifiers-content">
        <table class="additional-identifiers-table" *ngIf="_additionalMaterialIds && _additionalMaterialIds.length > 0">
            <tbody>
                <tr *ngFor="let matid of _additionalMaterialIds">
                    <td><label class="additional-identifiers-text">{{matid.typeMaterialIdentifier.description + ": "}} </label></td>
                    <td><label class="additional-identifiers-label">{{matid.cASNumber}}</label>
                    <td><label class="additional-identifiers-text">EC No.: </label>
                    <td><label class="additional-identifiers-label">{{matid.eCInventoryNumber}}</label>
                    <td><label class="additional-identifiers-text">ECL: </label>
                    <td><label class="additional-identifiers-label">{{matid.eCL}}</label>
                    <td><label class="additional-identifiers-text">EINECS: </label>
                    <td><label class="additional-identifiers-label">{{matid.eINECSNumber}}</label>

                    <td>
                        <label class="additional-identifiers-text">Registration:</label>
                        <span class="additional-identifiers-label" *ngIf="matid.dSLRegistration">DSL</span>
                        <span class="additional-identifiers-label" *ngIf="matid.eCNRegistration">ECN</span>
                        <span class="additional-identifiers-label" *ngIf="matid.eINECSRegistration">EINECS</span>
                        <span class="additional-identifiers-label" *ngIf="matid.nDSLRegistration">NDSL</span>
                        <span class="additional-identifiers-label" *ngIf="matid.reachRegistration">REACH</span>
                        <span class="additional-identifiers-label" *ngIf="matid.tSCARegistration">TSCA</span>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>

import { EntityBase } from './EntityBase';
import { ExposureAdditionalMaterial } from './ExposureAdditionalMaterial';
import { ExposureSurvey } from './ExposureSurvey';
import { TypeCremeVersion } from './TypeCremeVersion';
import { TypeExposureMeasure } from './TypeExposureMeasure';
import { TypeExposureMeasureValue } from './TypeExposureMeasureValue';

import { Validator } from 'breeze-client';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

export class ExposureAdditionalMaterialSurveyMeasure extends EntityBase {

   /// <code> Place custom code between <code> tags
   
   /// </code>

   // Generated code. Do not place code below this line.
   exposureAdditionalMaterialSurveyMeasureId: number;
   exposureAdditionalMaterialId: number;
   exposureSurveyId: number;
   typeExposureMeasureId: number;
   typeExposureMeasureValueId: number;
   measureQualitativeResult: string;
   measureQuantitativeResult: number;
   measureNotes: string;
   originalSurveyId: number;
   typeCremeVersionId: number;
   created: Date;
   createdUser: string;
   modified: Date;
   modifyUser: string;
   rowVersion: number;
   exposureAdditionalMaterial: ExposureAdditionalMaterial;
   exposureSurvey: ExposureSurvey;
   typeCremeVersion: TypeCremeVersion;
   typeExposureMeasure: TypeExposureMeasure;
   typeExposureMeasureValue: TypeExposureMeasureValue;
}


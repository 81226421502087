import { EntityBase } from './EntityBase';
import { AnalyticalResult } from './AnalyticalResult';
import { BiologicalData } from './BiologicalData';
import { Abstract } from './Abstract';
import { Journal } from './Journal';
import { Project } from './Project';
import { ReferenceAuthor } from './ReferenceAuthor';
import { ReferenceRelation } from './ReferenceRelation';
import { SpecialLink } from './SpecialLink';
import { TypeKlimisch } from './TypeKlimisch';
import { TypePublication } from './TypePublication';
import { TypeSearchService } from './TypeSearchService';
import { WorkflowRecordedAction } from './WorkflowRecordedAction';

import { Validator } from 'breeze-client';
/// <code-import> Place custom imports between <code-import> tags
import { EntityPropertyAnnotation, EntityTypeAnnotation } from './EntityTypeAnnotation';
import * as _ from 'lodash';

export interface IBriefReferenceParts {
    author?: string;
    year?: string;
    suffix?: string;
    br: string;
    search: string;
}

/// </code-import>

export class Reference extends EntityBase {

    /// <code> Place custom code between <code> tags
    static getEntityTypeAnnotation(): EntityTypeAnnotation {
        const nonZeroCodeValidator = new Validator(
            'NonZeroCodeValidator',
            (value: any) => value != 0,
            {messageTemplate: 'You must specify a %displayName%.  '});

        const briefReferenceValidator = new Validator(
            'BriefRefValidator',
            (value: any) => {
                const parts = Reference.extractBriefRefParts(value);
                if (!parts.year) {
                    return false;
                }
                const year = parseInt(parts.year);
                return !isNaN(year);
            },
            {messageTemplate: 'A Brief Reference must contain a valid year component.'}
        );

        const propAnnotations = [
            new EntityPropertyAnnotation('journalId', {
                displayName: 'Journal',
                validators: [nonZeroCodeValidator]
            }),
            new EntityPropertyAnnotation('briefReference', {
                displayName: 'Brief Reference',
                validators: [briefReferenceValidator]
            })
        ];

        return new EntityTypeAnnotation({
            validators: [],
            propertyAnnotations: propAnnotations
        });
    }

    // // a property instead of a method because it is also exposed as a property under Null experimentalMaterial.
    // public get briefReferenceTrimmed() {
    //     var parts = Reference.extractBriefRefParts(this.briefReference)
    //     return parts.author ? parts.author + "," + parts.year : '';
    // }

    public briefRef(isStaff: boolean) {
        return Reference.formatBriefReference(this.briefReference, isStaff);
    }

    public static formatBriefReference(br: string, isStaff: boolean) {
        if (isStaff) {
            return br || '';
        }
        const parts = Reference.extractBriefRefParts(br);
        return parts.author ? parts.author + ',' + parts.year : '';
    }

    public description(isStaff: boolean) {
        return this.briefRef(isStaff) + ' - Reference No. ' + (this.referenceId || '');
    }

    public captiveText() {
        return this.captivePaper ? 'Captive' : '';
    }

    public finishedText() {
        return this.finished ? '' : 'Tox Data Entry In Progress';
    }

    public captiveFinishedText() {
        return this.captiveText() + ((this.captivePaper && this.finished == false) ? ' - ' : '') + this.finishedText();
    }

    public authorDisplay() {
        if (!this.referenceAuthors) {
            return '-';
        }
        // We used to sort by 'initials' - removed per issue #404
        // var authors = _.sortBy( this.referenceAuthors.map(ra => ra.author), a => a.initials);
        // now sorted by counter.
        const authors = _.sortBy(this.referenceAuthors, a => a.counter).map(a => a.author);
        return authors.map(a => {
            const inits = (a.initials != null) ? a.initials + ' ' : '';
            return inits + a.authorName;
        }).join('; ');
    }

    public get abstractText() {
        return (this.abstract) ? this.abstract.text : '';
    }

    public set abstractText(value: string) {
        this.abstract.text = value;
    }

    // public get rIFMPublications() {
    //     return (this.typePublication) ? this.typePublication.publicationType : '';
    // }

    // assumes 'referenceAuthors' property has been fetched;
    public citationText(isStaff: boolean) {
        const authors = _.sortBy(this.referenceAuthors, a => a.counter).map(a => a.author);
        const authorNames = authors.map(a => {
            const initials = a.initials && a.initials.trim();
            return initials ? `${a.authorName.trim()} ${initials}` : a.authorName.trim();
        });
        const authorList = authorNames.join('; ');
        const brParts = Reference.extractBriefRefParts(this.briefReference);
        const annotatedYear = brParts.year ? brParts.year + (isStaff ? brParts.suffix : '') : '';
        let citation: string;
        if (this.rIFMAsAuthor) {
            const refPart1 = `Research Institute for Fragrance Materials ${this.yearOfPublication} ${this.title}`;
            let refPart2: string;
            if (this.restrictedDistribution) {
                refPart2 = `RIFM report number ${this.referenceId}`;
            } else {
                refPart2 = `Unpublished report ${this.referenceId} from ${authorList}`;
            }
            citation = `${Reference.formatBriefReference(this.briefReference, isStaff)} ${refPart1} ${refPart2}`;
        } else {
            citation = `${authorList} ${annotatedYear} ${this.title} <i>${this.journal.journalName}</i> ${this.journalDetail} Ref# ${this.referenceId}`;
        }
        return citation;
    }

    public setYearOfPublicationFromBriefReference() {
        const parts = Reference.extractBriefRefParts(this.briefReference);
        if (!parts.year) {
            this.yearOfPublication = 0;
        } else {
            this.yearOfPublication = parseInt(parts.year);
        }
    }


    public static extractBriefRefParts(br: string): IBriefReferenceParts {

        const rx = /(.*),\s*(\d*)(.*)/;
        const parts = rx.exec(br);
        if (!parts) {
            return {br: br, search: br};
        } else {
            return {
                author: parts[1],
                year: parts[2],
                suffix: parts[3] ? parts[3].trim().toLocaleLowerCase() : '',
                br: br,
                search: parts[1] + ',' + parts[2]
            };
        }
    }

    public static partsToBriefRef(parts: IBriefReferenceParts) {
        if (!parts.author) {
            return parts.br;
        } else {
            return parts.author + ',' + parts.year + parts.suffix;
        }
    }

    /// </code>

    // Generated code. Do not place code below this line.
    referenceId: number;
    journalId: number;
    publicationTypeId: number;
    briefReference: string;
    title: string;
    yearOfPublication: number;
    abstractOnly: boolean;
    precedence: number;
    linkable: boolean;
    complete: boolean;
    rIFMArticle: boolean;
    fEMAArticle: boolean;
    journalDetail: string;
    privateCommunication: boolean;
    restrictedDistribution: boolean;
    sponsoringCompany: string;
    finished: boolean;
    rIFMAsAuthor: boolean;
    projectId: number;
    contractorId: string;
    litSearch: boolean;
    captivePaper: boolean;
    testingInProgress: boolean;
    typeKlimischId: string;
    klimNote: string;
    govSponsors: string;
    govDocNumbers: string;
    captiveCompany: string;
    staffComments: string;
    typeSearchServiceId: string;
    abstractNumber: string;
    publicationWebReference: string;
    meetingDetails: string;
    posterAttached: boolean;
    created: Date;
    createdUser: string;
    modified: Date;
    modifyUser: string;
    rowVersion: number;
    abstract: Abstract;
    analyticalResults: AnalyticalResult[];
    biologicalDatas: BiologicalData[];
    journal: Journal;
    project: Project;
    referenceAuthors: ReferenceAuthor[];
    referenceRelations: ReferenceRelation[];
    specialLinks: SpecialLink[];
    typeKlimisch: TypeKlimisch;
    typePublication: TypePublication;
    typeSearchService: TypeSearchService;
    inLifePortion: string;
    workflowRecordedActions: WorkflowRecordedAction[];
}


<div class="form-horizontal indent-top-sm">


    <div class="form-group">
        <edit-prop isChild label="Study" prop="typeStudyId" szs="1,3">
            <select class="form-control" [ngModel]="typeStudyId" (change)="typeStudyId=$event.target.value">
                <option disabled selected="selected" value="">-- None --</option>
                <option *ngFor="let item of _typeStudies" [value]="item.typeStudyId">{{item.typeStudyId}} - {{item.studyLong}}</option>
            </select>
        </edit-prop>

        <edit-prop isChild label="Sub-Study" prop="typeStudySubTypeId" szs="1,3">
            <select class="form-control" [ngModel]="typeStudySubTypeId" (change)="typeStudySubTypeId=$event.target.value">
                <option disabled selected="selected" value="">-- None --</option>
                <option *ngFor="let item of _filteredSubTypes" [value]="item.studySubTypeId">{{item.studySubTypeId}} - {{item.subTypeDescription}}</option>
            </select>
        </edit-prop>


    </div>

    <div class="form-group">
        <div class="col-md-7">
            <button [disabled]="!canAdd()" class="btn btn-sm btn-success btn-no-margin" type="button" (click)="addSubStudy()">Add study sub type</button>
            <span *ngIf="_status" class="alert-danger">{{_status}}</span>
        </div>
    </div>

    <div class="col-md-5">
        <div class="form-group">
            <default-table *ngIf="entity.bioDataStudySubTypes?.length > 0">
                <thead>
                    <tr>
                        <td>Study</td>
                        <td>Sub Study</td>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let st of entity.bioDataStudySubTypes">
                        <td>{{st.typeStudySubType.typeStudyId}}</td>
                        <td>{{st.typeStudySubType.studySubTypeId}} - {{st.typeStudySubType.subTypeDescription}}</td>
                        <td><a (click)="onDeleteSubStudy(st)">Delete</a></td>
                    </tr>
                </tbody>

            </default-table>
        </div>
    </div>

</div>

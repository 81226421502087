import { EntityBase } from './EntityBase';
import { RIFMDocument } from './RIFMDocument';
import { TypeLinkTarget } from './TypeLinkTarget';

import { Validator } from 'breeze-client';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

export class RIFMDocumentFolder extends EntityBase {

    /// <code> Place custom code between <code> tags

    /// </code>

    // Generated code. Do not place code below this line.
    rIFMDocumentFolderId: number;
    folderName: string;
    archiveFolder: boolean;
    notes: string;
    startReferenceId: number;
    endReferenceId: number;
    created: Date;
    createdUser: string;
    modified: Date;
    modifyUser: string;
    rowVersion: number;
    rIFMDocuments: RIFMDocument[];
}

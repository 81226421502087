import { Component, ElementRef } from '@angular/core';
import { UtilFns } from '../../utils/common';
import { ModalDialog } from '../../controls/common';

@Component({
    selector: 'tools-and-models-license',
    templateUrl: './tools-and-models-license.html',
})
export class ToolsAndModelsLicenseComponent extends ModalDialog<boolean> {
    constructor(public elementRef: ElementRef) {
        super(elementRef);
    }

    onAccept(accept: boolean) {
        UtilFns.returnModal(this, accept);
    }

}


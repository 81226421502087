import { Component, Input, OnInit } from '@angular/core';
import { ValidationError } from 'breeze-client';
import * as _ from 'lodash';
import { provideParent, UnitOfWork } from '../../services/common';
import { EditPropParent } from '../../controls/common';

import { RegulatoryStatus, TypeRegulatoryStatus } from '../../entities/EntityModels';

@Component({
    selector: 'regulatory-status',
    templateUrl: './regulatory-status.html',
    providers: [provideParent(RegulatoryStatusComponent, EditPropParent)]
})
export class RegulatoryStatusComponent implements OnInit, EditPropParent {
    @Input() entity: RegulatoryStatus;
    @Input() statusType: string;
    _typeRegulatoryStatuses: TypeRegulatoryStatus[];
    _valError: ValidationError;

    constructor(public uow: UnitOfWork) {

    }

    ngOnInit() {
        const agencies = RegulatoryStatus.getEvaluatingAgenciesFor(this.statusType);
        this.uow.typeRegulatoryStatusRepository.all()
            .then(r => {
                this._typeRegulatoryStatuses = r.filter(trs => {
                    return _.includes(agencies, trs.evaluatingAgencyId);
                });
            });
    }

    getError(propName: string) {
        const err = this.entity.getErrorFor(propName);
        if (err) {
            return err;
        }
        if (propName == 'webReference' || propName == 'linkDisplay') {
            return this.validate();
        }
        return null;
    }

    validate() {
        const wr = (this.entity.webReference || '').trim();
        const ld = (this.entity.linkDisplay || '').trim();
        let errText: string;
        if (wr.length > 0 && ld.length == 0) {
            errText = 'A "Link display" must be set if a "Web Reference" is specified';
        } else if (wr.length == 0 && ld.length > 0) {
            errText = 'A "Web Reference" must be set if a "Link display" is specified';
        }

        if (this._valError) {
            this.entity.entityAspect.removeValidationError(this._valError);
        }
        if (errText) {
            this._valError = new ValidationError(null, null, errText, 'web-link');
            this.entity.entityAspect.addValidationError(this._valError);
        }
        return errText;
    }

}

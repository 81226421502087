<div class="indent-left-md">

    <div class="home-page-title">Environmental Data Summaries</div>

    <p class="alert-warning bold">{{_validationMessage}}</p>
    <div class="row">
        <i *ngIf="_isLoading" class="fa fa-circle-o-notch fa-spin" style="font-size:38px; color: #337ab7; margin-left: 24px;"></i>
    </div>

    <div class="col-md-10 database-subscription-container" style="margin-right: 30px">
        <div class="panel-body">
            <div *ngIf="_envDataFiles != null && _envDataFiles.length > 0">
                <div *ngFor="let e of _envDataFiles">
                    <div class="row" style="margin-bottom: 8px;">
                        <label class="bold col-md-2 database-subscription-title">{{e.fileDescription}}</label>
                        <div class="col-md-6 bold database-subscription-link"><a (click)="downloadFile(e)">{{e.fileLabel}}</a></div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>

import { MaterialExposureSurveyMeasureItem } from './MaterialExposureSurveyMeasureItem';

export class MeasuresByCategory {
    categoryId: string;
    measureValues: MaterialExposureSurveyMeasureItem[];

    constructor(categoryId: string, measureValues: MaterialExposureSurveyMeasureItem[]) {
        this.categoryId = categoryId;
        this.measureValues = measureValues;
    }
}

import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import * as _ from 'lodash';
import { StateMap, UnitOfWork, UserManager } from '../../services/common';
import { PageState } from '../../controls/common';

import { MATERIAL_ROUTES } from './material.routes';
import { ExpertPanelSummary, Material, MaterialExpertPanelSummary } from '../../entities/EntityModels';

import {Observable, Subject} from 'rxjs';
import {HttpClient, HttpHeaders} from '@angular/common/http';

@Component({
    selector: 'publications',
    templateUrl: './publications.html',
})
export class PublicationsComponent implements OnInit {

    _material: Material;
    _pageState = new PageState('Publications');
    _fmr: MaterialExpertPanelSummary;
    _summaries: ExpertPanelSummary[];
    _hasMonograph: boolean;

    // file download links
    _expertPanelSummarySubFolder = 'Expert Panel Summaries';
    _documentManagementUrl: string;
    _isLoading = false;
    _validationMessage = '';


    constructor(public _uow: UnitOfWork, public _userManager: UserManager, public _stateMap: StateMap,
        public _location: Location, private _httpClient: HttpClient) {

    }

    ngOnInit() {
        this._stateMap.currentRouteName = MATERIAL_ROUTES.Publications.name;
        this._material = this._stateMap.currentMaterial;
        this._material.principalSynonym().synonymWordOrWebVersion();

        this._pageState.isLoading = true;
        this._uow.materialExpertPanelSummaryRepository.whereWithParams({materialId: this._material.materialId}).then(r => {
            this._fmr = _.find(r, m => m.review != null);
            const summaries = r.map(item => item.expertPanelSummary);
            this._summaries = summaries.filter(s => s.narrativeLink != null && s.narrativeLink.trim() != '');
            this._hasMonograph = (this._material.monographReference != null) && (this._material.monographReference.trim() != '' && this._material.monographNumber != null);
            this._pageState.isLoaded = (summaries.length > 0) || (this._fmr != null) || this._hasMonograph;
        });

        this._documentManagementUrl = this._stateMap.documentManagementBaseUrl + '/RIFMDownloadableFile';
        this._stateMap.documentManagementUrl = this._documentManagementUrl;
    }

    linkToSummary(summary: ExpertPanelSummary) {
        this.downloadPublicationFile(summary.narrativeLink, 'Expert Panel Summaries');
    }

    linkToFmr() {
        this.downloadPublicationFile(this._fmr.review, 'Expert Panel Summaries');
    }

    linkToMonograph() {
        this.downloadPublicationFile(this._material.monographNumber + '.pdf', 'Monographs');
    }

    // ***********************************************
    // *material publication file downloads
    // ***********************************************
    getDownloadFileURL(fileName: string, subFolder: string): Observable<any> {
        let url: string;
        url = this._documentManagementUrl + '/GetFileBySubFolderAndName?fileName=' + fileName + '&subFolder=' + subFolder ;

        return this._httpClient.get<any>(url, { responseType: 'blob' as 'json'});
    }

    public downloadPublicationFile(fileName: string, subFolder: string) {
        this._isLoading = true;
        this._validationMessage = '';
        this.getDownloadFileURL(fileName, subFolder)
            .subscribe(
                (response: Blob) => {
                    const data = response;

                    const file = new Blob([data], { type: 'application/pdf' });
                    const fileURL = URL.createObjectURL(file);

                    // window.open(fileURL);
                    const a         = document.createElement('a');
                    a.href        = fileURL;
                    a.target      = '_blank';
                    a.download    = fileName;
                    document.body.appendChild(a);
                    a.click();

                    this._validationMessage = '';

                },
                (error) => {
                    console.error('Request failed with error: ' + error.message);
                    this._validationMessage = error.message;
                    this._isLoading = false;
                },
                () => {
                    this._isLoading = false;
                });
    }
}

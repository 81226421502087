import { Location } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';

import { provideParent, StateMap, UnitOfWork } from '../../services/common';
import { UtilFns } from '../../utils/common';
import { EditPropParent } from '../../controls/common';
import { ExposureAdditionalMaterial } from '../../entities/EntityModels';

@Component({
    selector: 'exposure-additional-material-editor',
    templateUrl: './exposure-additional-material-editor.html',
    providers: [provideParent(ExposureAdditionalMaterialEditorComponent, EditPropParent)]
})
export class ExposureAdditionalMaterialEditorComponent implements OnInit {

    @Input() entity: ExposureAdditionalMaterial;

    constructor(public _uow: UnitOfWork, public _stateMap: StateMap, public _location: Location) {
    }

    ngOnInit() {
    }

    get utilFns() {
        return UtilFns;
    }
}

import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import * as _ from 'lodash';
import { StateMap, UnitOfWork } from '../../services/common';
import { LocationFns } from '../../utils/common';
import { ColumnSorter, PageState } from '../../controls/common';

import { EPISuite, Material, ECOSARToxicEffect } from '../../entities/EntityModels';
import { MATERIAL_ROUTES } from './material.routes';

@Component({
    selector: 'prediction-environmental',
    templateUrl: './prediction-environmental.html',
})
export class PredictionEnvironmentalComponent implements OnInit {
    material: Material;

    _epiSuites: EPISuite[];
    _epiGroups: { name: string, epiSuites: EPISuite[], collapsed?: boolean } [] = [];
    _ecoSARToxicEffects: ECOSARToxicEffect[];
    _colSorter: ColumnSorter;
    _pageState = new PageState('Environmental Predictions');

    _biowinCollapsed = false;
    _ecoSARCollapsed = false;

    constructor(public _uow: UnitOfWork, public _stateMap: StateMap, public _location: Location) {

    }

    ngOnInit() {
        this.material = this._stateMap.currentMaterial;
        this._stateMap.currentRouteName = MATERIAL_ROUTES.Predictions.name;
        LocationFns.setTab(this._location, 1);

        this._colSorter = new ColumnSorter(this, {
            'Parameter': (e: EPISuite) => e.typeEpiSuiteParameter.ePISuiteParameter,
            'Value': (e: EPISuite) => e.ePIValue
        }, 'Parameter', (sortColumn) => this._pageState.sortColumn = sortColumn);

        // setTimeout needed because of Angular RC5 bug where Location is not set correctly until
        // after timeout resolves.
        setTimeout(() => {
            const returned = LocationFns.updatePageState(this._location, this._stateMap, this._pageState, (state: PageState) => {
                this._colSorter.sortColumn = state.sortColumn;
            });

            this.fetchAndGroup();
        }, 0);
    }

    fetchAndGroup() {
        this._pageState.isLoading = true;

        // fetch epiSuite data
        const params = {materialId: this.material.materialId};
        this._uow.epiSuiteRepository.whereWithParams(params).then(r => {
            this._epiSuites = _.sortBy(r, e => e.typeEpiSuiteParameter.ePISuiteSortOrder);

            const groups = _.groupBy(this._epiSuites, (epiSuite) => epiSuite.typeEpiSuiteParameter.typeEpiSuiteCategory.categoryName);
            this._epiGroups = _.map(groups, (v, k) => {
                return {name: k, epiSuites: v, isCollapsed: false};
            });
            // this._epiGroups = _.sortBy(this._epiGroups, g => g.name);
            this._pageState.isLoaded = r;
            this.sortWith(this._colSorter);
        });

        // fetch ecoSARToxicEffect data
        this._uow.ecoSARToxicEffectRepository.whereWithParams(params).then(e => {
            if (e) {
                this._ecoSARToxicEffects = _.sortBy(e, o => o.fish_96hr_LC50_Class);
            } else {
                this._ecoSARToxicEffects = null;
            }
        });
    }

    collapseGroup(ix: number) {
        this._epiGroups[ix].collapsed = !this._epiGroups[ix].collapsed;
    }

    sortWith(colSorter: ColumnSorter) {
        this._epiGroups.forEach(g => {
            colSorter.sort(g.epiSuites);
        });
    }

    setBIOWINCollapse() {
        this._biowinCollapsed = !this._biowinCollapsed;
    }

    setECOSARCollapse() {
        this._ecoSARCollapsed = !this._ecoSARCollapsed;
    }

}

import { ErrorHandler, Injector } from '@angular/core';
import { Location } from '@angular/common';

import { StateMap } from './state-map';
import * as Raven from 'raven-js';

declare var ga: Function;

class CustomExceptionHandler {
    constructor(private stateMap: StateMap, private injector: Injector) {
    }

    call(exception: any, stackTrace: any = null, reason: string = null) {
        Raven.captureException(exception.originalError || exception);
        console.log(exception);
        console.log(stackTrace);
        this.stateMap.errors.push({ exception: exception, stackTrace: stackTrace });

        if (!(this.stateMap.exceptionDialog && this.stateMap.exceptionDialog.showModal)) {
            alert(exception); // use plain ol' javascript alert;
            return;
        }
        this.stateMap.exceptionDialog.showModal().then((result) => {
            const location = <Location>this.injector.get(Location);
            ga('send', 'exception', {
                'exDescription': exception && exception.toString(),
                'page': location.path()
            });

            // var parts = UtilFns.parseUrl(location.path());
            if (!result) {
                location.replaceState('/home');
            }
            document.location.reload();

        });
    }

    handleError(exception: any, stackTrace: any = null, reason: string = null) {
        this.call(exception, stackTrace, reason);
    }
}

export function CustomExceptionHandlerFactory(stateMap: StateMap, injector: Injector) {
    return new CustomExceptionHandler(stateMap, injector);
}

export const customExceptionHandlerProvider = {
    provide: ErrorHandler,
    useFactory: CustomExceptionHandlerFactory,
    deps: [StateMap, Injector]
};

<div *ngIf="_edm.editing" class="inline">
    <p class="inline bold" style="margin-left: 8px;">{{_adding ? "Adding an Exposure Survey Measure" : "Editing an Exposure Survey Measure" }}</p>
    <img style="margin-left: 10px; height: 38px; width: 38px;margin-top:4px;" class="inline" src="assets/images/MeasureDataValue_Blue.png" />
</div>

<div class="row">
    <i *ngIf="isLoading" class="fa fa-circle-o-notch fa-spin" style="font-size:38px; color: #337ab7;"></i>
</div>

<div *ngIf="_validationMessage">
    <p class="alert-danger">{{_validationMessage}}</p>
</div>

<div *ngIf="!_edm.editing">
    <div style="margin-top: 12px; margin-left: 6px;">
        <div class="row inline">
            <button type="button" class="inline btn btn-choose-material" style="margin-left: 19px; margin-top: -2px;" (click)="selectMaterial()">Choose Material</button>
            <div *ngIf="_selectedMaterial" class="left-indent inline">
                <span class="bold inline">Selected CAS No.: </span>
                <a class="inline bold some-space" (click)="onShowMaterial(_selectedMaterial)">{{_selectedMaterial.realCASNumber || '(No CAS#)'}}</a>

                <span class="bold inline left-indent">RIFM Id: </span>
                <span class="form-control-static some-space">{{_selectedMaterial.formattedRIFMId + ','}}</span>

                <span class="bold inline some-space">Principal Synonym: </span>
                <span class="form-control-static some-space" [htmlGreek]="_selectedMaterial.principalSynonym().synonymWordOrWebVersion()"></span>
            </div>
        </div>
    </div>

    <div class="row exposure-survey-details-container">
        <div class="float-left" style="width: 44%; margin-left: 8px;">
            <span *ngIf="_materialExposureSurveys != null && _materialExposureSurveys.length > 0" class="inline">
                <span class="bold inline" style="margin-top: 18px; padding-right: 4px;">Available Exposure Surveys: </span>
                <select id="exposure-survey-list" class="dropdown-toggle" [ngModel]="_selectedExposureSurveyIndex" (change)="filterExposureSurveyByIndex($event.target.value)">
                    <option *ngFor="let mes of _materialExposureSurveys; let ix = index" class="dropdown" [value]="ix">{{mes.exposureSurvey?.exposureSurveyMonthYearNumber}}</option>
                </select>
            </span>
        </div>
        <div *ngIf="_currentMaterialExposureSurvey" class="float-right" style="width: 55%; margin-top: 8px; ">
            <div class="inline" style="border: 1px solid #D3CCBF; background-color: aliceblue; padding: 4px;">
                <span class="inline text-bold text-left page-subtitle right-indent">For the Selected Exposure Survey:</span>
                <span class="inline text-bold text-left page-subtitle left-indent">{{_selectedExposureSurvey.exposureSurveyMonthYear}}</span>
                <span class="inline text-bold text-left page-subtitle left-indent"> with Number: </span>
                <span class="inline text-bold text-left page-subtitle left-indent">{{_selectedExposureSurvey.exposureSurveyNumber}}</span>
                <br />
                <span class="inline text-bold text-left page-subtitle">The Response Status was: </span>
                <span class="inline text-bold text-left page-subtitle left-indent" style="background-color: #AFE7FF;">{{_currentMaterialExposureSurvey.typeExposureSurveyResponse.responseType}}</span>
                <br />
                <a class="inline" (click)="onShowMaterialSurveys()"><strong>(Back to Material List)</strong></a>
            </div>
        </div>
    </div>

</div>

<div *ngIf="_unsurveyedMessage">
    <p class="alert alert-info">{{_unsurveyedMessage}}</p>
</div>

<div class="row" style="margin-left: 10px; margin-top: -4px;">
    <editor-header [edm]="_edm" [customButtons]="true" style="margin-left: -4px !important;">
        <span customButtons>
            <button *ngIf="!_edm.editing" type="button" class="btn btn-sm btn-primary" [disabled]="!canSave()" (click)="onSave()">Save</button>
            <button *ngIf="_edm.editing" type="button" class="btn btn-sm btn-primary" [disabled]="!canApply()" (click)="onApply()">Apply</button>
            <button *ngIf="canShowBack()" type="button" class="btn btn-sm btn-primary" (click)="onBack()">Back</button>
            <button *ngIf="canCancel()" type="button" class="btn btn-sm btn-primary" (click)="onCancel()">Cancel</button>
        </span>
    </editor-header>
    <button *ngIf="(canAdd())" type="button" class="inline btn btn-survey-measure" style="width:130px; margin-left: 0px;" (click)="onAdd()">Add Measure</button>
</div>

<div *ngIf="!_edm.editing">
    <div style="margin-left: 10px;">
        <h4 *ngIf="(_materialExposureSurveyMeasuresEx == null || _materialExposureSurveyMeasuresEx.length < 1) && _currentMaterialExposureSurvey != null">
                There is no Measure data for this material for the selected survey.
        </h4>
        <span *ngIf="_materialExposureSurveyMeasuresEx != null && _materialExposureSurveyMeasuresEx.length > 0" class="inline">
            <span class="bold inline subtitle-margin-top" header>Exposure Survey Measure Data</span>
            <img style="margin-left: 10px;" class="inline multiple-measure-comment-image" src="assets/images/MultipleExposureMeasures.png" />
            <span class="inline italic notify-text left-indent">Indicates multiple runs of the same measure within an Exposure Survey.</span>
        </span>
        <default-table *ngIf="_materialExposureSurveyMeasuresEx != null && _materialExposureSurveyMeasuresEx.length > 0">
            <thead>
                <tr>
                    <td>Edit</td>
                    <td>Remove</td>
                    <td>Multiple Runs</td>
                    <td>Measure</td>
                    <td>Value</td>
                    <td>Date Entered</td>
                    <td>Notes</td>
                    <td>Creme Version</td>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let mx of _materialExposureSurveyMeasuresEx">
                    <td><a (click)="onEdit(mx)">Edit</a></td>
                    <td><a (click)="onRemove(mx)">Remove</a></td>
                    <td><img *ngIf="mx.isMultiple" class="multiple-measure-image" src="assets/images/MultipleExposureMeasures.png" /></td>
                    <td>{{mx.data.typeExposureMeasure.measureDescription}}</td>
                    <td>{{mx.data.measureQualitativeResult || utilFns.formatNumber(mx.data.measureQuantitativeResult, 12, 4)}}</td>
                    <td>{{utilFns.formatDate(mx.data.created)}}</td>
                    <td class="text-left">{{mx.data.measureNotes}}</td>
                    <td class="text-center">{{mx.data.typeCremeVersion.cremeVersion}}</td>
                </tr>
            </tbody>
        </default-table>
    </div>
    <span class="inline" style="margin-left: 10px;" *ngIf="_materialExposureSurveyIsomers != null &&  _materialExposureSurveyIsomers.length > 0 && !_edm.editing">
        <span class="bold inline">Isomers surveyed with this material: </span>
        <span class="inline left-indent" style="max-width: 700px; overflow-wrap: break-word;">{{_materialExposureSurveyIsomers}}</span>
    </span>
</div>

<material-selector></material-selector>

<div *ngIf="_edm.editing" style="margin-bottom: 10px;">
    <material-exposure-survey-measure-editor #self [entity]="_edm.currentEntity"></material-exposure-survey-measure-editor>
</div>

export class  JournalReserveItem {
    journalReserveId: number;
    journalName: string;
    journalYear: number;
    volume: string;
    issue: string;
    pages: string;
    notes: string;


    constructor(id: number, name: string, year: number, vol: string, issue: string, pages: string, notes: string) {
        this.journalReserveId	= id;
        this.journalName		= name;
        this.journalYear		= year;
        this.volume 			= vol;
        this.issue				= issue;
        this.pages				= pages;
        this.notes				= notes;
    }
}

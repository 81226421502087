import { Location } from '@angular/common';
import {Component, EventEmitter, forwardRef, Inject, Input, Output, OnInit, ViewChild} from '@angular/core';

import { UnitOfWork, StateMap, provideParent } from '../../services/common';
import { PageState, EditPropParent } from '../../controls/common';
import {Journal, JournalSubscription, TypeJournalSubscription} from '../../entities/EntityModels';
import * as _ from 'lodash';

import { JournalsComponent, JournalEditData, JournalEditState } from './journals.component';
import {EditManager} from '../../utils/edit-manager';

@Component({
    selector: 'journal-subscription-editor',
    templateUrl: './journal-subscription-editor.html',
    providers: [provideParent(JournalSubscriptionEditorComponent, EditPropParent)]
})

export class JournalSubscriptionEditorComponent implements OnInit {
    @Output() stateChange = new EventEmitter<string>();

    _data: JournalEditData;

    _edmSubscription: EditManager<JournalSubscription>;
    _typeJournalSubscriptions: TypeJournalSubscription[];
    _journalSubscription: JournalSubscription;

    _pageState = new PageState('Journal Subscription Editor');
    _validationMessage: string;

    _hasBeenDeleted = false;

    _subscriptionStartYear: string;
    _subscriptionEndYear: string;
    _selectedSubscriptionStartMonthIndex    = 0;
    _selectedSubscriptionEndMonthIndex      = 0;

    _dateErrMsg = 'The Subscription Start Date is equal to or later than the Subscription End Date.';

    public _monthNames = [{ month: 'None' }, { month: 'January' }, { month: 'February' }, { month: 'March' },
        { month: 'April' }, { month: 'May' }, { month: 'June' },
        { month: 'July' }, { month: 'August' }, { month: 'September' },
        { month: 'October' }, { month: 'November' }, { month: 'December' }];

    constructor( @Inject(forwardRef(() => JournalsComponent)) public _parent: JournalsComponent,
                 private _uow: UnitOfWork, private _stateMap: StateMap, public _location: Location) {

        this._data  = _parent._data;
    }

    ngOnInit() {
        this._edmSubscription = new EditManager<JournalSubscription>(this, this._uow, this._stateMap, 'JournalSubscription');

        if (this._data == null) {
            return;
        }

        this._uow.typeJournalSubscriptionRepository.all().then(types => {
            if (types != null) {
                this._typeJournalSubscriptions = _.sortBy(types, t => t.sortOrder);
            }
        });

        if (this._data.adding) {
            const s = this.createJournalSubscription();
            this._journalSubscription = s;
            this._journalSubscription.typeJournalSubscriptionId = 'NONE';
            this._edmSubscription.onlyEntity = this._journalSubscription;
            this._edmSubscription.editing = true;

        } else {
            if (this._data.journalSubscriptionId == null) {
                return;
            }
            this.fetchThenEditJournalSubscription();
        }
    }

    getError(propName: string) {

        return;

        if (this._edmSubscription.editing == false) {
            return;
        }

        if (this._journalSubscription == null) {
            return;
        }

        const r = this._journalSubscription.getErrorFor(propName);
        return r;
    }

    get journalSubscription() {
        return this._edmSubscription.currentEntity;
    }

    createJournalSubscription() {
        const journalSubscription       = this._uow.journalSubscriptionFactory.create();
        journalSubscription.journalId   = this._data.entity.journalId;

        return journalSubscription;
    }

    fetchThenEditJournalSubscription() {
        return this._uow.fetchTyped('Misc/JournalSubscriptionById', JournalSubscription, { journalSubscriptionId: this._data.journalSubscriptionId }).then(r => {
            const s = r && (r.length > 0) && r[0];
            if (s) {
                this._journalSubscription = s;
                this._edmSubscription.onlyEntity = this._journalSubscription;
                this._edmSubscription.editing = true;

                // tslint:disable-next-line:max-line-length
                this._subscriptionStartYear                 = (this._edmSubscription.currentEntity.subscriptionStartYear != null) ? this._edmSubscription.currentEntity.subscriptionStartYear.toString() : null;
                // tslint:disable-next-line:max-line-length
                this._subscriptionEndYear                   = (this._edmSubscription.currentEntity.subscriptionEndYear != null) ? this._edmSubscription.currentEntity.subscriptionEndYear.toString() : null;
                this._selectedSubscriptionStartMonthIndex   = (this._edmSubscription.currentEntity.subscriptionStartMonth != null) ? this._edmSubscription.currentEntity.subscriptionStartMonth : 0;
                this._selectedSubscriptionEndMonthIndex   	= (this._edmSubscription.currentEntity.subscriptionEndMonth != null) ? this._edmSubscription.currentEntity.subscriptionEndMonth : 0;
            }
            this._pageState.isLoading   = false;
        }).catch(() => {
            this._pageState.isLoading   = false;
        });
    }

    // *******************************************************
    // edit interactions
    // *******************************************************
    canApply() {

        if (this._edmSubscription.currentEntity == null) {
            return false;
        }

        if (this._data == null) {
            return false;
        }

        if (this._data.deleting) {
            return false;
        }

        if (!this.hasValidJournalSubscriptionDates()) { return false; }

        if (this._edmSubscription.editing && this._uow.hasChanges()) {
            if (this._edmSubscription.currentEntity != null) {
                return (!this._edmSubscription.currentEntity.entityAspect.hasValidationErrors);
            }
            return true;
        }
        return false;
    }

    onApply() {
        this._validationMessage = '';

        if (!this.isValidSubscription()) { return false; }

        // journals
        if (this._edmSubscription.editing) {
            this._edmSubscription.onApplyCore();
            this._data.adding   = false;
            return true;
        }

        return false;
    }

    canShowBack() {
        if (this._data.deleting) {
            return false;
        }

        return (!this._uow.hasChanges());
    }

    onBack() {
        this._data.adding               = false;
        this._edmSubscription.editing   = false;
        this._data.inSubEditor          = false;

        this.stateChange.emit('Back');
    }

    canCancel() {
        if (this._data.deleting) {
            return true;
        }

        return (this._uow.hasChanges());
    }

    onCancel() {
        this._validationMessage = '';

        this._uow.rollback();

        this._edmSubscription.clearErrors();
        this._edmSubscription.editing = false;
        this._edmSubscription.pageState.loadStatusMsg = null;

        const data          = this._data;
        data.inSubEditor    = false;

        this.stateChange.emit('Cancel');
    }

    canDelete() {
        if (this._hasBeenDeleted) {
            return false;
        }

        if (this._data == null || this._edmSubscription == null) {
            return false;
        }

        return (this._data.deleting && this._edmSubscription.currentEntity != null);
    }

    onDelete() {
        const subscription = this._edmSubscription.currentEntity;

        if (subscription.entityAspect.entityState.isAdded()) {
            subscription.entityAspect.rejectChanges();
        } else {
            subscription.entityAspect.setDeleted();
        }

        // this is a confirmed delete so apply changes
        this.onApply();

        this._hasBeenDeleted = true;
    }

    canSave(): boolean {

        if (this._edmSubscription.editing) { return false; }
        if (!this._edmSubscription.hasEdits()) { return false; }
        if (!this._edmSubscription.validateBeforeSave()) { return false; }

        return true;
    }

    onSave() {
        if (this._edmSubscription.hasEdits()) {
            this._edmSubscription.onSaveCore().then(() => {
                this._edmSubscription.editing = false;
            });
        }
        this.stateChange.emit('Save');
    }

    isValidSubscription(): boolean {
        if (!this.isValidString(this.journalSubscription.publisher)) {
            this._validationMessage = 'Please enter a Publisher';
            return false;
        }

        if (this.journalSubscription.typeJournalSubscriptionId.toLowerCase() == 'none') {
            this._validationMessage = 'Please select a Subscription Type';
            return false;
        }

        return true;
    }

    isValidString(teststring: string) {
        if (!teststring) { return false; }
        return (teststring.trim().length > 0);
    }

    // dates
    onChangeJournalSubscriptionStartYear(yearString: string) {
        this._validationMessage = '';

        if (this.isStringNullOrEmpty(yearString)) {
            this._edmSubscription.currentEntity.subscriptionStartYear    = null;
            this._edmSubscription.currentEntity.subscriptionStartMonth   = null; // a year value is required for a month value
            this._subscriptionStartYear = null;
            return;
        }

        if (this.isValidYear(yearString)) {
            this._edmSubscription.currentEntity.subscriptionStartYear = parseInt(yearString, 10);
            this._subscriptionStartYear = parseInt(yearString, 10).toString();
        } else {
            this._validationMessage = 'An incorrect Year has been entered in the Subscription Start Year field.';
        }
    }

    onChangeJournalSubscriptionEndYear(yearString: string) {
        this._validationMessage = '';

        if (this.isStringNullOrEmpty(yearString)) {
            this._edmSubscription.currentEntity.subscriptionEndYear  = null;
            this._edmSubscription.currentEntity.subscriptionEndMonth = null;  // a year value is required for a month value
            this._subscriptionEndYear = null;
            return;
        }

        if (this.isValidYear(yearString)) {
            this._edmSubscription.currentEntity.subscriptionEndYear = parseInt(yearString, 10);
            this._subscriptionEndYear = parseInt(yearString, 10).toString();
        } else {
            this._validationMessage = 'An incorrect Year has been entered in the Subscription End Year field.';
        }
    }

    onSelectSubscriptionStartMonth(monthidx: number) {
        this._selectedSubscriptionStartMonthIndex = monthidx;
        this._edmSubscription.currentEntity.subscriptionStartMonth = (monthidx > 0) ? monthidx : null;

        if (this._validationMessage == this._dateErrMsg) {
            this._validationMessage = '';
        }
        this.hasValidJournalSubscriptionDates();
    }

    onSelectSubscriptionEndMonth(monthidx: number) {
        this._selectedSubscriptionEndMonthIndex = monthidx;
        this._edmSubscription.currentEntity.subscriptionEndMonth = (monthidx > 0) ? monthidx : null;

        if (this._validationMessage == this._dateErrMsg) {
            this._validationMessage = '';
        }
        this.hasValidJournalSubscriptionDates();
    }

    isStringNullOrEmpty(s: string): boolean {
        return (s == null || s.trim() === '');
    }

    isValidYear(yearString: string) {
        const year = parseInt(yearString, 10);
        return !isNaN(year) && year > 1900 && year < 2100;
    }

    checkDate(dateString: string): boolean {
        const d = Date.parse(dateString);

        return (!isNaN(d));
    }

    formatDate(month: number, year: number): Date {
        const s = '';
        const d = s.concat('' + month, '/', '01', '/', '' + year);
        return new Date(d);
    }

    hasValidJournalSubscriptionDates() {
        if (this._edmSubscription.currentEntity.subscriptionStartYear == null || this._edmSubscription.currentEntity.subscriptionEndYear == null) {
            return true;
        }

        const startMonth    = (this._edmSubscription.currentEntity.subscriptionStartMonth != null) ? this._edmSubscription.currentEntity.subscriptionStartMonth : 1;
        const endMonth      = (this._edmSubscription.currentEntity.subscriptionEndMonth != null) ? this._edmSubscription.currentEntity.subscriptionEndMonth : 1;

        const startDate     = new Date(this._edmSubscription.currentEntity.subscriptionStartYear,startMonth,1);
        const endDate       = new Date(this._edmSubscription.currentEntity.subscriptionEndYear,endMonth,1);

        if (startDate >= endDate) {
            this._validationMessage = this._dateErrMsg;
            return false;
        }
        return true;
    }

}

import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { NavigationEnd, Router } from '@angular/router';

import { YesNoComponent } from './yes-no.component';
import { ExceptionDialogComponent } from './exception-dialog.component';

import { StateMap, UserManager } from '../../services/common';
import { EntityManagerProvider } from '../../services/entity-manager-provider';
import {ToolBar} from './toolbar.component';

declare var ga: Function;

@Component({
    selector: 'my-app',
    templateUrl: './app.html',
})
export class AppComponent implements OnInit, AfterViewInit {
    @ViewChild(YesNoComponent, { static: true }) yesNoComponent: YesNoComponent;
    @ViewChild(ExceptionDialogComponent, { static: true }) exceptionDialog: ExceptionDialogComponent;
    @ViewChild(ToolBar, { static: true }) toolBar: ToolBar;
    private _currentRoute: string;

    constructor(public _stateMap: StateMap, private _emProvider: EntityManagerProvider, private _userManager: UserManager,
                private _router: Router, private _location: Location) {
        _router.events.subscribe((event: any) => {
            // Send GA tracking on NavigationEnd event.
            if (event instanceof NavigationEnd) {
                // When the route is '/', location.path actually returns ''.
                const newRoute = this._location.path() || '/';
                // If the route has changed, send the new route to analytics.
                if (this._currentRoute != newRoute) {
                    // we want this page set for any events that come after - not just the pageview.
                    ga('set', 'page', newRoute);
                    ga('send', 'pageview');
                    // ga('send', 'pageview', newRoute + '/alt');
                    this._currentRoute = newRoute;
                }
            }
        });
    }

    ngOnInit() {
        this._emProvider.prepare().catch(console.log.bind(console));
    }

    ngAfterViewInit() {
        this._stateMap.yesNoComponent = this.yesNoComponent;
        this._stateMap.exceptionDialog = this.exceptionDialog;

        // Scrollbar modal dialog fix
        // If you have a modal on your page that exceeds the browser height,
        // then you can't scroll in it when closing an second modal.
        // from: http://stackoverflow.com/questions/19305821/multiple-modals-overlay
        $(document).on('hidden.bs.modal', '.modal', function () {
            if ($('.modal:visible').length) {
                $(document.body).addClass('modal-open');
            }
        });
    }

    successfulLogin() {
        if (this.toolBar != null) {
            this.toolBar.refreshSearchOptions();
        }
    }
}

import { EntityBase } from './EntityBase';
import { Material } from './Material';
import { Reference } from './Reference';
import { TypeAnalyticalProcedure } from './TypeAnalyticalProcedure';

import { Validator } from 'breeze-client';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

export class AnalyticalResult extends EntityBase {

   /// <code> Place custom code between <code> tags
   public get uvsFileList(): string[] {

        const data: string[] = [];

        if (this.typeAnalyticalProcedureID != 'UVS') {
            return data;
        }

        if (this.observedResult == null || this.observedResult.length == 0) {
            return data;
        }

        if (this.containsFileName(this.observedResult) == false) {
            data.push(this.observedResult);
            return data;
        }

        return (this.splitUVFileList(this.observedResult));
    }

    private containsFileName(uvObservedResult: string): boolean {
        return (uvObservedResult.indexOf('.gif') > -1 || uvObservedResult.indexOf('.pdf') > -1 || uvObservedResult.indexOf('.tif') > -1);
    }

    private splitUVFileList(uvFiles: string): string[] {
        return (uvFiles.split(','));
    }
   /// </code>

   // Generated code. Do not place code below this line.
   analyticalResultId: number;
   materialID: number;
   typeAnalyticalProcedureID: string;
   referenceId: number;
   analyticalSource: string;
   observedResult: string;
   observedMaximum: string;
   testMethod: string;
   analyticalFactor: number;
   analyticalSample: string;
   created: Date;
   createdUser: string;
   modified: Date;
   modifyUser: string;
   rowVersion: number;
   material: Material;
   reference: Reference;
   typeAnalyticalProcedure: TypeAnalyticalProcedure;
}


import { Component, OnInit, AfterViewInit, OnDestroy, Input, Output, EventEmitter, ElementRef, ViewChild } from '@angular/core';
import * as _ from 'lodash';
import { Location } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';

import { UnitOfWork, UserManager, StateMap, SearchService, provideParent } from '../../services/common';
import { UtilFns, LocationFns, } from '../../utils/common';
import { PageState, TabContainer } from '../../controls/common';

import { MaterialListItem } from '../../entities/projections/MaterialListItem';
import { SelectableEntity } from '../../entities/projections/SelectableEntity';
import { SuperCluster, SuperClusterPath, ClusterGroup, ClusterPart, ClusterPath, MaterialCluster, ClusterNodeAndEdge } from '../../entities/EntityModels';

import { ClusterHelpVideoComponent } from './cluster-help-video.component';
import { ClusterPathFilterComponent } from './clusterpath-filter.component';
import { ClusterPathSelectorComponent } from './clusterpath-selector.component';

import { CytoscapeGraphComponent } from './cytoscape-graph.component';

import { MaterialSelectorComponent } from '../material/material-selector.component';

import { HOME_ROUTES } from './home.routes';
import { ROUTES } from '../routes';

@Component({
    selector: 'material-clusters',
    templateUrl: './material-clusters.html',
    providers: [provideParent(MaterialClustersComponent)],
    styles: [`
      cytoscape-graph {
        height: 100vh;
        float: left;
        width: 100%;
        position: relative;
    }`],
})
export class MaterialClustersComponent implements OnInit, AfterViewInit, OnDestroy {
        @ViewChild(ClusterHelpVideoComponent, { static: true }) _clusterHelpVideoComponent: ClusterHelpVideoComponent;
        @ViewChild(ClusterPathFilterComponent, { static: true }) _clusterPathFilterComponent: ClusterPathFilterComponent;
        @ViewChild(ClusterPathSelectorComponent, { static: true }) _clusterPathSelectorComponent: ClusterPathSelectorComponent;
        @ViewChild(CytoscapeGraphComponent) _cytoscapeGraphComponent: CytoscapeGraphComponent;
        @ViewChild(TabContainer, { static: true }) _tabContainer: TabContainer;
        @ViewChild(MaterialSelectorComponent, { static: true }) _materialSelectorComponent: MaterialSelectorComponent;

    _clusterGroups: ClusterGroup[];
    _selectedClusterGroup: ClusterGroup;
    _selectedClusterGroupId = -1;

    _superClusterPaths: SuperClusterPath[];

    _clusterObjectMap: { [key: number]: ClusterNodeAndEdge[] } = {};
    _selected_node_name: string;
    _savedGraphData: any;

    _selectedClusterPathIds: number[] = [];

    _materialIds: number[];
    _sub: any;

    _isClusterGraphDisplayed            = true;
    _currentTabNumber                   = 0;
    _isLoading                          = false;
    _nodeLevel                          = 99999;
    _quickSearchCASNumber               = '';
    _userNotifications                  = '';

    toggleShowSelectClusterGroupPaths   = false;
    toggleShowTopLevelNodes             = false;
    toggleShowClusterFilterModal        = false;
    toggleShowHelpVideo                 = false;
    toggleClearGraph                    = false;
    toggleCenterGraph                   = false;
    toggleExportClusterImage            = false;
    toggleExportClusterFile             = false;
    toggleMaterialLookup                = false;

    public _superClusterPathsEx: SelectableEntity<SuperClusterPath>[] = [];

    constructor(public _stateMap: StateMap, public _uow: UnitOfWork,
                public _userManager: UserManager, public _elementRef: ElementRef,
                public _route: ActivatedRoute, public _router: Router) {

    }

    ngOnInit() {
        this._stateMap.currentRouteName = HOME_ROUTES.MaterialClusters.name;

        this.loadClusterGroups();
        this.setTab(0);

        this._userNotifications = '';
        this._quickSearchCASNumber   = ' ';
    }

    ngAfterViewInit() {
        this._sub = this._route.params.subscribe(params => {
            const materialId = +params['id'];

            if (materialId != null && materialId > 0) {
                this.navigateToMaterialClusterPath(materialId);
            }
        });
    }

    ngOnDestroy() {
        this._sub.unsubscribe();
    }

    canDeactivate() {
        return (!this._uow.hasChanges());
    }

    navigateToMaterialClusterPath(materialId: number) {
        const params = { materialId: materialId };
        this._uow.fetch('MaterialClusters/MaterialClusterByMaterialId', params).then(m => {
            if (m != null && m.length > 0) {
                this.setSelectedClusterGroup(m[0].clusterPath.clusterGroup).then(g => {
                    this._selectedClusterPathIds.push(m[0].clusterPath.clusterPathId);
                    this.loadClusterData().then(d => {
                        if (this._clusterObjectMap != null) {
                            this._cytoscapeGraphComponent.nodeLevelMin = 0;
                            this._cytoscapeGraphComponent.nodeLevelMax = 99999;
                            this.expandClusterPath(m[0].clusterPath.clusterPathId);
                            this.alignGraph();
                        }
                    });
                });
            } else {
                this.onClearGraph();
                this._userNotifications = 'The searched Material is not yet assigned to a cluster.';
}
});
    }

    // ********************************************************************
    // Load data                                                          *
    // ********************************************************************
    loadClusterGroups() {
        this._isLoading = true;

        this._uow.fetchTyped('MaterialClusters/ClusterGroups', ClusterGroup, {}).then(g => {
            this._clusterGroups = g;
            this._isLoading     = false;
        });
    }

    loadClusterPathData(): Promise<any> {
        this._isLoading = true;

        const params = { clusterGroupId: this._selectedClusterGroup.clusterGroupId };
        return this._uow.fetchTyped('MaterialClusters/SuperClusterPathsByClusterGroupId', SuperClusterPath, params).then(p => {
            this._superClusterPaths = p;

            this.formatSuperSelectableEntities();

            this._isLoading = false;
        });
    }

    loadClusterData(): Promise<any> {
        this._isLoading = true;

        const params = { clusterGroupId: this._selectedClusterGroup.clusterGroupId, isStaff: this.isStaff() };
        return this._uow.fetch('MaterialClusters/FetchClusterDataByGroupId', params).then(g => {
            if (g != null && g.length > 0) {
                this._clusterObjectMap = g[0];

                this._isLoading = false;
            }
        });
    }

    formatSuperSelectableEntities() {
        this._superClusterPathsEx = [];
        if (this._superClusterPaths == null || this._superClusterPaths.length < 1) { return; }

        _.clone(this._superClusterPaths).forEach(element => {
            this._superClusterPathsEx.push(new SelectableEntity<SuperClusterPath>(element, '#FFFFFF'));
        });
    }

    setSelectedClusterGroup(clusterGroup: ClusterGroup): Promise<any> {

        if (this._selectedClusterGroup == null || this._selectedClusterGroup.clusterGroupId != clusterGroup.clusterGroupId) {
            this._selectedClusterGroup      = clusterGroup;
            this._selectedClusterGroupId    = this._selectedClusterGroup.clusterGroupId;
            this._superClusterPaths         = [];
            this._clusterObjectMap          = {};

            this.resetGraph();
        }

        if (this._superClusterPaths.length < 1) {
            return this.loadClusterPathData();
        } else {
            return Promise.resolve(true);
        }
    }

    isDictionaryEmpty(dict: object): boolean {
        return Object.keys(dict).length === 0;
    }

    hasNodeData(): boolean {
        return !this.isDictionaryEmpty(this._clusterObjectMap);
    }

    hasSelectedClusterGroup(): boolean {
        return (this._selectedClusterGroup != null);
    }

    // **************************************************************************************
    // Study display title                                                                  *
    // **************************************************************************************
    get pageTitle(): string {
        return (this._selectedClusterGroup) ? 'Material Clusters for ' + this._selectedClusterGroup.clusterGroupName : 'Material Clusters';
    }

    formatNodeId(nodeId: string): string {
        const reg = /(_|-)/gi;
        return nodeId.replace(reg, ' ');
    }

    // ******************************************************
    // Show Study Data Event                                *
    // ******************************************************
    // Show the study data displayed in the tab control
    public showStudyData(nodeIds: string[], parentNodeId: string) {
        this.saveGraphData(); // This data will be used to redraw the graph when the user switches back to the tab with the graph canvas

        this._materialIds = [];

        // A single material node was clicked
        if (nodeIds == null || nodeIds.length < 1) {
            const clusterNode = this.findClusterNode(parentNodeId);
            if (clusterNode != null && clusterNode.isMaterial) {
                this._selected_node_name = 'CAS No.: ' + clusterNode.clusterPartLabel;
                this._materialIds = [];
                this._materialIds.push(Number(parentNodeId));
            }
        } else {  // A parent node was clicked
            this._selected_node_name = this.formatNodeId(parentNodeId) + ' Materials';

            nodeIds.forEach(n => {
                const clusterNode = this.findClusterNode(n);
                if (clusterNode != null && clusterNode.isMaterial) {
                    if (this._materialIds == null) {
                        this._materialIds = [];
                    }
                    this._materialIds.push(Number(n));
                }
            });
        }

        if (this._materialIds.length > 0) {
            this.setTab(1);
        }
    }

    findClusterNode(nodeId: string): ClusterNodeAndEdge {
        const clusters = _.clone(this._clusterObjectMap);
        // tslint:disable-next-line:forin
         for (const key in clusters) {
            const data = this._clusterObjectMap[key];
            const node = data.find(n => n.clusterPartNode == nodeId);
            if (typeof node != 'undefined') {
                return (node);
            }
         }

        return null;
    }

    // ******************************************************
    // *Graph Reset                                         *
    // ******************************************************
    onClearGraph() {
        this._selectedClusterGroup      = null;
        this._selectedClusterGroupId    = -1;
        this._superClusterPaths         = [];
        this._clusterObjectMap          = {};

        this.resetGraph();
    }

    resetGraph() {
        this._userNotifications     = '';

        // Clear materials
        this._materialIds               = [];
        this._selectedClusterPathIds    = [];

        this._clusterObjectMap          = {};
        this._savedGraphData            = null;

        if (this._cytoscapeGraphComponent != null && this._cytoscapeGraphComponent.hasData()) {
            this._cytoscapeGraphComponent.clearGraph();
        }
    }

    onRedrawGraphWithCurrentData() {

        if (typeof this._savedGraphData == undefined || this._savedGraphData == null) {
            return;
        }

        this._cytoscapeGraphComponent.highlightTopNode = true;
        this._cytoscapeGraphComponent.renderClusterJSON(this._savedGraphData);
        return;
    }
    saveGraphData() { // Save graph data when switching tabs
        this._savedGraphData = this._cytoscapeGraphComponent.getGraphData();
    }

    alignGraph() {
        this._cytoscapeGraphComponent.centerAndFitGraph();

        if (this._selectedClusterPathIds.length < 3) {
            this._cytoscapeGraphComponent.moveGraphToTop(-100);
        }
    }

    // ******************************************************
    // *Tab Events                                          *
    // ******************************************************
    setTab(tabNumber: number) {
        this._currentTabNumber = tabNumber;
         setTimeout(() => {this._tabContainer.selectTab(tabNumber);
        }, 1);
    }

    onTabChange(tab: number) {
        if (tab == 0) {
            setTimeout(() => {
                this.onRedrawGraphWithCurrentData();
            }, 0);
        }

        this._isClusterGraphDisplayed = (tab == 0);
    }

    // ******************************************************
    // *Interactive Node Display                            *
    // ******************************************************
    onSelectClusterGroup(id: number) {
        const group = this.findClusterGroupById(id);
        return this.setSelectedClusterGroup(group).then(s => {
            this.onShowTopLevelNodes();
            this._quickSearchCASNumber = '';
        });
    }

    onShowTopLevelNodes() {
        this.resetGraph();
        this.loadClusterData().then(g => {
            this.graphTopLevelNodesForClusterGroup();
            this._cytoscapeGraphComponent.zoomToOrigin();
        });
    }

    graphTopLevelNodesForClusterGroup() {
        this._nodeLevel = 2;
        this._cytoscapeGraphComponent.nodeLevelMin  = 0;
        this._cytoscapeGraphComponent.nodeLevelMax  = this._nodeLevel;

        const clusters = _.clone(this._clusterObjectMap);
        // tslint:disable-next-line:forin
        for (const key in clusters) {
            const data = this._clusterObjectMap[key];
            if (this._cytoscapeGraphComponent) {
                if (this._cytoscapeGraphComponent.hasData()) {
                    this._cytoscapeGraphComponent.addClusterDataToGraph(data);
                } else {
                    this._cytoscapeGraphComponent.renderClusterData(data);
                }
            }
        }
    }

    // ******************************************************
    // *Show Modal to Choose Paths to Display               *
    // ******************************************************
    onShowSelectClusterGroupPaths() {
        if (this._clusterPathSelectorComponent == null) {
            return;
        }

        const previouslySelectedClusterPaths = _.clone(this._selectedClusterPathIds);

        this._clusterPathSelectorComponent.SuperClusterPaths = this._superClusterPathsEx;

        // Pass previously selected clusterPathIds
        this._clusterPathSelectorComponent.selectedClusterPaths(this._selectedClusterPathIds);

        UtilFns.showModal(this._clusterPathSelectorComponent, this).then(r => {
            if (r == false) {
                return;
            }

            if (this.isDictionaryEmpty(this._clusterObjectMap)) {
                this.loadClusterData().then(g => {
                    this.processClusterPathSelections(previouslySelectedClusterPaths, this._clusterPathSelectorComponent.SelectedClusterPathIds);
                });
            } else {
                this.processClusterPathSelections(previouslySelectedClusterPaths, this._clusterPathSelectorComponent.SelectedClusterPathIds);
            }
        });
    }

    processClusterPathSelections(currentPathIds: number[], newPathIds: number[]) {

        const addedIds    = newPathIds.filter(a => currentPathIds.indexOf(a) < 0);
        const removedIds  = currentPathIds.filter(r => newPathIds.indexOf(r) < 0);

        this._selectedClusterPathIds = newPathIds;

        if (newPathIds.length == 0) {
            this._cytoscapeGraphComponent.clearGraph();
            return;
        }

        if (currentPathIds.length < 1) {   // Load all the selected paths from the modal dialogue
            this.displaySelectedClusterPaths();
            this.alignGraph();
            return;
        }

        // Isolate the node and edge data to only those associated with a selected cluster path
        const graphablePaths: { [key: number]: ClusterNodeAndEdge[] } = {};
        newPathIds.forEach(key => {
            graphablePaths[key] = this._clusterObjectMap[key];
        });

        // Remove unselected paths node by node, starting at the end and finishing when a node is shared with a selected node path
        if (typeof removedIds != undefined && removedIds != null && removedIds.length > 0) {
            removedIds.forEach(i => {
                this.removePath(i, graphablePaths);
            });
        }

        // Add newly selected cluster paths to the graph
        if (typeof addedIds != undefined && addedIds != null && addedIds.length > 0) {
            this._cytoscapeGraphComponent.nodeLevelMin = 0;
            this._cytoscapeGraphComponent.nodeLevelMax = 99999;

            addedIds.forEach(i => {
                this.expandClusterPath(i);
            });
        }

        this.alignGraph();
    }

    displaySelectedClusterPaths() {
        this._userNotifications = '';

        this._cytoscapeGraphComponent.nodeLevelMin = 0;
        this._cytoscapeGraphComponent.nodeLevelMax = 99999;

        this._selectedClusterPathIds.forEach(p => {
            this.expandClusterPath(p);
        });
    }

    removePath(clusterPathId: number, graphableData: { [key: number]: ClusterNodeAndEdge[] }) {
        const clusterparts = this._clusterObjectMap[clusterPathId];

        const sortedparts = _.sortBy(clusterparts, p => p.precedence).reverse();

        sortedparts.forEach(p => {
            if (this.isSharedNode(p.clusterPartNode, graphableData)) {
                // done
                return;
            } else {
                this._cytoscapeGraphComponent.removeNode(p.clusterPartNode);
            }
        });
    }

    // ***************************************************************************************************
    // Material Lookup
    // ***************************************************************************************************
    public onSelectMaterial() {
        UtilFns.showModal(this._materialSelectorComponent, this).then(mli => {
            if (mli == null) { return; }
            this.navigateToMaterialClusterPath(mli.materialId);
            return;
        });
    }

    // **************************************************************************
    // *Show Dialog for Users to Filter and Search for Cluster Components       *
    // **************************************************************************
    public onShowClusterFilterModal() {
        if (this._clusterPathFilterComponent == null) {
            return;
        }

        UtilFns.showModal(this._clusterPathFilterComponent, this).then(r => {
            if (r == false) { return; }

            this._cytoscapeGraphComponent.nodeLevelMin = 0;
            this._cytoscapeGraphComponent.nodeLevelMax = 99999;

            const clusterGroupId  = this._clusterPathFilterComponent.SelectedClusterGroupId;
            const clusterGroup    = this.findClusterGroupById(clusterGroupId);

            this.setSelectedClusterGroup(clusterGroup).then(g => {
                this.loadClusterData().then(g => {
                    this._selectedClusterPathIds = this._clusterPathFilterComponent.SelectedClusterPathIds;
                    this.displaySelectedClusterPaths();
                    this.alignGraph();
                });
            });
        });
    }

    findClusterGroupById(clusterGroupId: number): ClusterGroup {
        const clusterGroups = this._clusterGroups.filter(g => g.clusterGroupId == clusterGroupId);
        return clusterGroups[0];
    }

    // **************************************************************************
    // *Quick Search by CAS Number                                              *
    // **************************************************************************

    canQuickSearch() {
        return this._quickSearchCASNumber != null && this._quickSearchCASNumber.trim().length > 0;
    }

    onQuickSearch() {
       const searchMaterial = this._quickSearchCASNumber.trim();

       if (isNaN(Number(searchMaterial))) {
           this._uow.fetch('Materials/MaterialsByCASNumber', { realCASNumber: searchMaterial }).then(r => {
               const mats = <MaterialListItem[]> r;

               if (mats == null || mats.length < 1) {
                   this.onClearGraph();
                   this._userNotifications = 'No materials matched the entered CAS Number.';

                   return;
               }

               if (mats.length == 1) {
                   this.navigateToMaterialClusterPath(mats[0].materialId);
               } else {
                   this.onClearGraph();
                   this._userNotifications = 'Multiple materials matched the entered CAS number. Use the advanced search or enter a MaterialId.';
               }
           });
       } else {
           this._uow.materialRepository.withId(Number(searchMaterial)).then(m => {
               if (m == null) {
                   this.onClearGraph();
                   this._userNotifications = 'No materials matched the entered MaterialId.';

                   return;
               }
               this.navigateToMaterialClusterPath(m.materialId);
           });
       }
    }

    onQuickSearchCASNumber() {
        this._userNotifications = '';
    }

    // *******************************************************************************
    // Cluster Data Export
    // *******************************************************************************
    onExportClusterImage() {
        this._cytoscapeGraphComponent.exportGraphToImageFile();
    }

    onExportClusterFile() {
        const params: { key: string, value: string }[] = [];

        if (this.isStaff()) {
            params.push({ key: 'reporttype', value: 'materialclusterreport' });
        } else {
            params.push({ key: 'reporttype', value: 'materialclusterreportsubscribers' });
        }

        const url = this._userManager.getReportUrl(params);
        window.open(url, '_self');
        return false;
    }

    canExportGraphData(): boolean {
        if (this._cytoscapeGraphComponent == null) {
            return false;
        }

        return (this._cytoscapeGraphComponent.hasData());
    }

    // *******************************************************************************
    // Copy data to the clipboard
    // *******************************************************************************
    copyPathToClipboard(node: any) {
        const pathnames = this.prepareVisibleClusterPathDataForClipboard(node);

        const selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = pathnames;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
    }

    // Find any cluster path associated with a node. A node is displayed once but can be found in multiple cluster paths.
    prepareVisibleClusterPathDataForClipboard(node: any): string {
        let copytext = '';

        if (this._selectedClusterPathIds == null || this._selectedClusterPathIds.length < 1) {
            return copytext;
        }

        this._selectedClusterPathIds.forEach(p => {
            const data    = this._clusterObjectMap[p];
            const obj     = data.find(n => n.clusterPartNode == node.id());

            if (typeof obj != 'undefined' && obj != null) {
                const pathname    = this.getFullPathName(+p);
                const materials   = MaterialClustersComponent.getPathMaterials(data);

                if (copytext == '') {
                    copytext = pathname + '//' + materials;
                } else {
                    copytext = copytext + '\n' + pathname + '//' + materials;
                }
            }
        });
        return copytext;
    }

    // *******************************************************************************
    // Routing                                                                       *
    // *******************************************************************************
    private navigateLocal(path: string) {
        this._router.navigate(['../' + path], { relativeTo: this._route });
    }

    navigateToStructureSearch(node: any) {
        const materialId    = node.id();
        const parts         = UtilFns.asRouterParts(ROUTES.Home, ROUTES.Home.childRoutes.SearchStructure, materialId);
        this._router.navigate(parts);
    }

    // *******************************************************************************
    // Path Data Queries                                                             *
    // *******************************************************************************
    // Check if a node has multiple edges
    isSharedNode(nodeId: string, graphableData: { [key: number]: ClusterNodeAndEdge[] }): boolean {
        // tslint:disable-next-line:forin
        for (const key in graphableData) {
            const data = graphableData[key];
            const obj = data.find(n => n.clusterPartNode == nodeId);

            if (typeof obj != 'undefined' && obj != null) {
                return true;
            }
        }
        return false;
    }

    getFullPathName(clusterPathId: number): string {
        let path = '';
        if (this._superClusterPaths != null) {
            const paths = this._superClusterPaths.filter(p => p.clusterPathId == clusterPathId);

            if (paths != undefined && paths.length > 0) {
                path = paths[0].clusterPath.clusterPathName;
            }
        }
        return path;
    }

    static getPathMaterials(data: ClusterNodeAndEdge[]): string {
        let materials = '';

        const mats = data.filter(m => m.isMaterial == true);

        if (mats != undefined && mats.length > 0) {
            materials = $.map(mats, function (obj) {
                return obj.clusterPartLabel;
            }).join('; ');
        }

        return materials;
    }

    isClusterPathAlreadySelected(clusterPathId: number): boolean {
        const idx = this._selectedClusterPathIds.indexOf(clusterPathId);
        return (idx > -1);
    }

    isSelectedClusterGroup(id: number) {
        if (this._selectedClusterGroup == null) {
            return false;
        }

        return (this._selectedClusterGroup.clusterGroupId == id);
    }

    // ******************************************************
    // *Expand Path Display by Node                         *
    // ******************************************************
    expandClusterPath(clusterPathId: number) {
        const data = this._clusterObjectMap[clusterPathId];

        if (this._cytoscapeGraphComponent.hasData()) {
            this._cytoscapeGraphComponent.addClusterDataToGraph(data);
        } else {
            this._cytoscapeGraphComponent.renderClusterData(data);  // Will happen during redraw when flipping back to the cluster tab
        }
    }

    expandNodeNextLevel(node: any) {
        if (node == null) {
            return;
        }

        const precedence                            = node.data('precedence');
        this._cytoscapeGraphComponent.nodeLevelMin  = 0;
        this._cytoscapeGraphComponent.nodeLevelMax  = precedence + 1;

        this.findAndGraphExpandNodes(node);
    }

    expandAllNodes(node: any) {
        this._isLoading             = true;
        this._userNotifications     = '';

        if (node.isNode() == false) {
            this._userNotifications = 'Please click a node instead of an edge to expand the cluster path.';
            return;
        }

        const params = {
            clusterGroupId: this._selectedClusterGroupId,
            clusterPartName: node.id(),
            precedence: node.data('precedence')
        };

        return this._uow.fetch('MaterialClusters/GetClusterNodeCountByPartAsync', params).then(c => {
            const cnt = (c != null) ? c[0] : 0;
            if (cnt > 50) { // Too many, just display the next level
                this._userNotifications = 'The clicked node has more than 50 child nodes so just one level is being added to the graph. Expand again to see the materials.';
                this._cytoscapeGraphComponent.nodeLevelMin  = 0;
                this._cytoscapeGraphComponent.nodeLevelMax  = node.data('precedence') + 1;
            } else {
                this._cytoscapeGraphComponent.resetNodeLevelsToAll();    // Expand the full cluster path, set defaults to maximum display levels
            }

            this.findAndGraphExpandNodes(node);

            this._isLoading = false;
        });
    }

    findAndGraphExpandNodes(node: any) {
        const clusters = _.clone(this._clusterObjectMap);

        // tslint:disable-next-line:forin
        for (const key in clusters) {
            const data  = this._clusterObjectMap[key];
            const obj   = data.find(n => n.clusterPartNode == node.id());
            if (typeof obj != 'undefined' && obj != null) {
                if (this.isClusterPathAlreadySelected(+key) == false) {
                    this._selectedClusterPathIds.push(+key);
                }
                this._cytoscapeGraphComponent.addClusterDataToGraph(data, false);
            }
        }
        this._cytoscapeGraphComponent.zoomToNode(node);
    }

    isStaff() {
        return this._userManager.currentUser.isStaff;
    }

    // ******************************************************
    // *Show Modal with Help Video                          *
    // ******************************************************
    onShowHelpVideoModal() {
        UtilFns.showModal(this._clusterHelpVideoComponent, this).then(r => {
        });
    }

    // ******************************************************
    // *Tooltip Display                                     *
    // ******************************************************
    toggleTooltip(whichButton: number) {
        switch (whichButton) {
            case 1: {
                this.toggleShowSelectClusterGroupPaths = !this.toggleShowSelectClusterGroupPaths;
                break;
            }
            case 2: {
                this.toggleShowTopLevelNodes =  !this.toggleShowTopLevelNodes;
                break;
            }
            case 3: {
                this.toggleShowClusterFilterModal =  !this.toggleShowClusterFilterModal;
                break;
            }
            case 4: {
                this.toggleShowHelpVideo =  !this.toggleShowHelpVideo;
                break;
            }
            case 5: {
                this.toggleClearGraph =  !this.toggleClearGraph;
                break;
            }
            case 6: {
                this.toggleCenterGraph =  !this.toggleCenterGraph;
                break;
            }
            case 7: {
                this.toggleExportClusterImage =  !this.toggleExportClusterImage;
                break;
            }
            case 8: {
                this.toggleExportClusterFile =  !this.toggleExportClusterFile;
                break;
            }
            case 9: {
                this.toggleMaterialLookup = !this.toggleMaterialLookup;
                break;
            }
            default: {
                break;
            }
        }
    }
}

import {Component, OnInit} from '@angular/core';
import * as _ from 'lodash';
import { ActivatedRoute } from '@angular/router';
import {StateMap, IRouteDefinition, UnitOfWork} from '../../services/common';
import { DOCUMENTMANAGEMENT_ROUTES } from './document-management.routes';
import {UtilFns} from '../../utils/util-fns';

@Component({
    selector: 'document-management',
    templateUrl: './document-management.html',
})
export class DocumentManagementComponent implements OnInit {

    _routes = <IRouteDefinition[]>_.values(DOCUMENTMANAGEMENT_ROUTES);

    public _referenceId = 0;

    constructor(public _stateMap: StateMap, public _route: ActivatedRoute, private _uow: UnitOfWork) {

    }

    ngOnInit() {
        this._stateMap.documentManagementArchiveUrl  = this._stateMap.documentManagementBaseUrl + '/RIFMDocumentArchive';
        this._stateMap.documentManagementUrl         = this._stateMap.documentManagementBaseUrl + '/RIFMDocument';

        this._route.paramMap
            .subscribe(params => {
                this._referenceId = +params.get('id');
                }
            );
    }

    canShow(routeDef: IRouteDefinition) {

        if (this._stateMap.currentUser.isStaff) {
            // Exception to prevent consultant staff from seeing otherwise staff viewable content.
            if (routeDef.rolesExcluded) {
                const roles = routeDef.rolesExcluded.split(',').map(function (item) {
                    return item.trim();
                });

                if (roles != null && roles.length > 0) {
                    return (!this.userRoleFound(roles));
                }
            }
            return true;
        }

        if (routeDef.staffOnly) { return false; }
        return true;
    }

    currentRoute() {
        return _.find(this._routes, r => r.name == this.routeName);
    }

    getPageTitle() {
        return (this.currentRoute() ? this.currentRoute().title : '') + ': ';
    }

    get routeName() {
        return this._stateMap.currentRouteName;
    }

    isActiveTab(r: IRouteDefinition) {
        const currentRouteName = this.routeName || this._routes[0].name;
        return r.name == currentRouteName;
    }

    userRoleFound(roles: string[]): boolean {
        for (let idx = 0; idx < roles.length; idx++) {
            if (this._stateMap.currentUser.isInRole(roles[idx])) {
                return true;
            }
        }

        return false;
    }

    // ************************************************************************
    // document management web api url
    // ************************************************************************
    public getDocumentManagementUrl(): Promise<any> {
        return this._uow.fetch('DocumentManagement/DocumentManagementURL', {}).then(dm => {
            return dm[0];
        });
    }

    public isValidString(testString: string): boolean {
        return (testString && testString.length > 0);
    }
}

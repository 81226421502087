<div class="form-horizontal">

    <div class="col-md-5">
        <edit-prop label="Product" prop="product" szs="3,8" >
            <input class="form-control" type="text" [(ngModel)]="entity.product">
        </edit-prop>
        
        <edit-prop label="Product CAS" prop="productCAS" szs="3,5">
            <input class="form-control" type="text" [(ngModel)]="entity.productCAS">
        </edit-prop>               

        <edit-prop label="Unit" prop="unit" szs="3,3" >
            <input class="form-control" type="text" [(ngModel)]="entity.unit">
        </edit-prop>               
        
        <edit-prop label="Comment" prop="comment" szs="3,3" >
            <input class="form-control" type="text" [(ngModel)]="entity.comment">
        </edit-prop>               
    </div>
    
    <div class="col-md-6">
        <edit-prop label="Range Min" prop="rangeMin" szs="3,3" >
            <input class="form-control" type="number" [(ngModel)]="entity.rangeMin">
        </edit-prop>               

        <edit-prop label="Range max" prop="rangeMax" szs="3,3" >
            <input class="form-control" type="number" [(ngModel)]="entity.rangeMax">
        </edit-prop>               
        
        <edit-prop label="Category" prop="category" szs="3,2" >
            <input class="form-control" type="checkbox" [(ngModel)]="entity.category">
        </edit-prop>
        
        <edit-prop label="Less Than" prop="lessThan" szs="3,2" >
            <input class="form-control" type="checkbox" [(ngModel)]="entity.lessThan">
        </edit-prop>               
        
        <edit-prop label="Trace" prop="trace" szs="3,2" >
            <input class="form-control" type="checkbox" [(ngModel)]="entity.trace">
        </edit-prop>               
        
        <edit-prop label="Qualitative" prop="qualitative" szs="3,2" >
            <input class="form-control" type="checkbox" [(ngModel)]="entity.qualitative">
        </edit-prop>
    </div>                                             
    
<div>
<div style="margin-bottom: 10px">
    <div class="inline" style="font-size: 18px;" *ngIf="_edm.editing">{{_adding ? "Adding an Reference Relation Type" : "Editing an Reference Relation Type" }}</div>
    <span *ngIf="(!_edm.editing) || _edm.canShowBack()">
        <button [disabled]="!_entities" type="button" class="inline btn btn-sm btn-primary"  (click)="_edm.onAdd()">Add an Reference Relation Type</button>            
        <button [disabled]="!_entities" type="button" class="inline btn btn-sm btn-primary"  (click)="onEdit()"> {{ "Edit " + (_edm.editing ? "Another " : "an ") + "Reference Relation Type" }}</button>
    </span>
</div>
<editor-header [edm]="_edm" [customButtons]="true">
    <span customButtons>
        <span *ngIf="_edm.editing">
            <button type="button" class="btn btn-sm btn-primary" [disabled]="!_edm.canSave()" (click)="_edm.onSave()">Save</button>
            <button *ngIf="!_adding" type="button" class="btn btn-sm btn-primary" [disabled]="!_edm.canDelete()" (click)="_edm.onDelete()">Delete</button>
            <button *ngIf="_edm.canShowBack()" type="button" class="btn btn-sm btn-primary"  (click)="_edm.onBack()">Back</button>
            <button *ngIf="!_edm.canShowBack()" type="button" class="btn btn-sm btn-primary" [disabled]="!_edm.canCancel()" (click)="_edm.onCancel()">Cancel</button>
        </span>
    </span>
</editor-header>

<!--<page-status [pageState]="_pageState"></page-status>-->

<div *ngIf="_edm.editing" class="form-horizontal">
    <edit-prop label="Id" prop="typeReferenceRelationId" szs="1,9">
        <input type="text" class="form-control" [disabled]="!_adding" [ngModel]="entity.typeReferenceRelationId" (change)="setId($event.target.value)" >
    </edit-prop>

    <edit-prop label="Label" prop="label" szs="1,9">
        <input type="text" class="form-control" [(ngModel)]="entity.label">
    </edit-prop>

    <edit-prop label="Description" prop="description" szs="1,9">
        <input type="text" class="form-control" [(ngModel)]="entity.description">
    </edit-prop>

    
    <edit-prop label="Inverse Id" prop="_inverseId" szs="1,9">
        <input type="text" class="form-control" [disabled]="true" [(ngModel)]="_inverseId"  >
    </edit-prop>

    <edit-prop label="Inverse label" prop="_inverseLabel" szs="1,9" >
        <input type="text" class="form-control" [(ngModel)]="_inverseLabel" (change)="updateInv()" >
    </edit-prop>

    <edit-prop label="Inverse description" prop="_inverseDescription" szs="1,9">
        <input type="text" class="form-control" [(ngModel)]="_inverseDescription" (change)="updateInv()" >
    </edit-prop>
    
</div>

<type-reference-relation-selector *ngIf="_entities" [typeReferenceRelations]="_entities"></type-reference-relation-selector>

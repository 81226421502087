import { Injectable } from '@angular/core';
import { BiologicalData, ExperimentalMaterial, ExperimentalResult, Reference } from '../entities/EntityModels';
import { UnitOfWork } from './unit-of-work';

export enum ReferenceFinishState { MissingData, DataComplete, FinishOverride }

@Injectable()
export class ReferenceFinishedService {

    private _unfinishedReasons: Array<string> = new Array<string>();

    constructor(private _uow: UnitOfWork) {
    }

    // ----------------------------------------------------------------------------------------------------------------------------------
    // Evaluate Reference to determine if it is in a Finished state
    // ----------------------------------------------------------------------------------------------------------------------------------
    public enumerateMissingData(reference: Reference): Promise<number> {
        // Returns the number of ways in which the reference is missing data normally required to be considered finished
        return this._uow.referenceRepository.populateBiologicalData(reference).then(() => {

            this._unfinishedReasons = [];
            if (this.isReferenceAbstracted(reference) == false) {
                this._unfinishedReasons.push('Missing Abstract');
            }

            if (this.isReferenceProofed(reference) == false) {
                this._unfinishedReasons.push('Unproofed');
            }

            if (reference.rIFMArticle == false && reference.fEMAArticle == false) {
                this._unfinishedReasons.push('Has not been designated as a RIFM or FEMA article');
            }

            // Look for unfinished tasks recorded in the workflow table
            const incompletes = this.distinctPendingWorkflowActions(reference);

            if (incompletes != null && incompletes.length > 0) {
                incompletes.forEach(i => {
                    this._unfinishedReasons.push(i);
                });
            }

            // ToDo: Check for undefined linkable state when ready
            if (reference.linkable) {
                if (this.hasLinks(reference) == false) {
                    this._unfinishedReasons.push('Linkable with incomplete or missing linked data');
                }
            }

            const datacheck = (this.hasIncompleteProtocolData(reference));

            if (reference.precedence > 2 && reference.linkable && datacheck.missingBiologicalData) {
                this._unfinishedReasons.push('P3 Reference missing Study, Route, Species (Protocol) data');
            }

            if (datacheck.missingBiologicalData == false) { // Means there are Bio Data records created so now we check for linked materials and tox data (Exp Materials and Exp Results)
                if (datacheck.missingExperimentalMaterials) {
                    this._unfinishedReasons.push('Protocol data without Linked Materials');
                }

                if (datacheck.missingExperimentalResults) {
                    this._unfinishedReasons.push('Linked materials without Tox Data');
                }
            }

            return this._unfinishedReasons.length;

        });
    }

    public isReferenceFinished(reference: Reference): Promise<ReferenceFinishState> {

        return this._uow.referenceRepository.populateToxData(reference).then(() => {

            if (this.isFinishedByOverride(reference)) {
                return ReferenceFinishState.FinishOverride;
            }

            const hasLinks = false;

            if (this.isReferenceProofed(reference) == false) {
                return ReferenceFinishState.MissingData;
            }

            if (reference.rIFMArticle == false && reference.fEMAArticle == false) {
                return ReferenceFinishState.MissingData;
            }

            const datacheck = (this.hasIncompleteProtocolData(reference));

            // If a reference has the precedence (priority) set to a value 3 or higher then it can be marked as finished if it has been abstracted and proofed with BioData.
            if (reference.precedence > 2) {
                if (reference.linkable) {
                    if (datacheck.missingBiologicalData == false) {
                        return ReferenceFinishState.DataComplete;
                    } else {
                        return ReferenceFinishState.MissingData;
                    }
                } else {
                    return ReferenceFinishState.DataComplete;
                }
            }

            // Look for unfinished tasks recorded in the workflow table
            if (this.allWorkflowActionsCompleted(reference) == false) {
                return ReferenceFinishState.MissingData;
            }

            // ToDo: Check for undefined linkable state when ready
            if (reference.linkable == false) {
                return ReferenceFinishState.DataComplete;
            }

            if (this.hasLinks(reference)) {
                if (datacheck.missingBiologicalData == true) {
                    return ReferenceFinishState.DataComplete; // missingBiologicalData is true when there are no Bio Data records, in this case, there can't be missing linked materials or tox data.
                }
            } else {
                return ReferenceFinishState.MissingData;  // Incomplete when linkable without linked data
            }


            if (datacheck.missingExperimentalMaterials == false && datacheck.missingExperimentalResults == false) {
                return ReferenceFinishState.DataComplete;
            }

            return ReferenceFinishState.MissingData;
        });
    }

    public get unfinishedReasons(): string[] {
        return this._unfinishedReasons.sort();
    }

    private isReferenceAbstracted(reference: Reference): boolean {
        const abstracted = reference.workflowRecordedActions.filter(a => a.typeWorkflowActionId == 'ABSTRACTED');

        return (abstracted != null && abstracted.length > 0);
    }

    private isReferenceProofed(reference: Reference) {
        return reference.complete;
    }

    // Linkable References should have AnalyticalResults or Special Links or Materials Linked in order to be considered Finished.
    private hasLinks(reference: Reference) {

        if (this.hasAnalyticalResultLinks(reference)) {
            return true;
        }

        if (this.hasSpecialLinks(reference)) {
            return true;
        }

        if (this.hasMaterialLinks(reference)) {
            return true;
        }

        return false;
    }

    private hasAnalyticalResultLinks(reference: Reference) {
        return (reference.analyticalResults != null && reference.analyticalResults.length > 0);
    }

    private hasMaterialLinks(reference: Reference) {

        const bddata = reference.biologicalDatas.filter(b => b.experimentalMaterials.length > 0);

        if (bddata != null && bddata.length > 0) {
            return true;
        }

        return false;
    }

    private hasSpecialLinks(reference: Reference) {
        return (reference.specialLinks != null && reference.specialLinks.length > 0);
    }

    private allWorkflowActionsCompleted(reference: Reference) {

        const pending = reference.workflowRecordedActions.filter(a => a.completedWorkflowActionId != null && a.completedWorkflowActionId != 'FINISH DATA' && a.actionCompletedDate == null);

        if (pending == null || pending.length == 0) {
            return true;
        }

        return false;
    }

    private distinctPendingWorkflowActions(reference: Reference): string[] {

        const incompletes: Array<string> = new Array<string>();
        const pending = reference.workflowRecordedActions.filter(a => a.completedWorkflowActionId != null && a.completedWorkflowActionId != 'FINISH DATA'
            && a.actionCompletedDate == null && a.completedTypeWorkflowAction.includeInStaffReport
            && a.completedTypeWorkflowAction.coreData == false);

        if (pending == null || pending.length == 0) {
            return incompletes;
        }

        pending.forEach(p => {
            const exists = incompletes.filter(a => a == p.completedTypeWorkflowAction.toCompleteDescription);

            if (exists == null || exists.length == 0) {
                incompletes.push(p.completedTypeWorkflowAction.toCompleteDescription);
            }
        });

        return incompletes;
    }

    private isFinishedByOverride(reference: Reference) {

        const actions = reference.workflowRecordedActions.filter(a => a.typeWorkflowActionId == 'FINISHED OVERRIDE' && a.entityAspect.entityState.isAdded());
        return (actions != null && actions.length > 0);

    }

    public hasIncompleteProtocolData(reference: Reference) {

        let missingBioData = false;
        let missingExpMats = false;
        let missingExpResults = false;

        if (reference.biologicalDatas != null && reference.biologicalDatas.length > 0) {
            reference.biologicalDatas.forEach(bd => {
                if (this.missingExperimentalMaterial(bd)) {
                    missingExpMats = true;
                }

                bd.experimentalMaterials.forEach(em => {
                    if (this.missingExperimentalResults(em)) {
                        missingExpResults = true;
                    }
                });
            });
        } else {
            missingBioData = true;
            missingExpMats = true;
            missingExpResults = true;
        }

        return {
            missingBiologicalData: missingBioData,
            missingExperimentalMaterials: missingExpMats,
            missingExperimentalResults: missingExpResults
        };
    }

    public missingExperimentalMaterial(bd: BiologicalData): boolean {
        const hasexisting = (bd.experimentalMaterials != null && bd.experimentalMaterials.length > 0);
        let hasnew = false;

        // New actions are created with the uow repository factory so check here since new actions are not committed and accessible via the Reference navigation list
        const changedEntities = this._uow._emProvider.manager().getChanges();
        if (changedEntities != null) {

            changedEntities.forEach(e => {

                if (e.entityType.shortName == 'ExperimentalMaterial') {

                    if (e.entityAspect.entityState.isAdded()) {
                        if (e['biologicalDataId'] == bd.biologicalDataId) {
                            hasnew = true;
                        }
                    }
                }
            });
        }

        return (hasexisting == false && hasnew == false);
    }

    public missingExperimentalResults(em: ExperimentalMaterial): boolean {
        const hasexisting = (em.experimentalResults != null && em.experimentalResults.length > 0);
        let hasnew = false;

        // New actions are created with the uow repository factory so check here since new actions are not committed and accessible via the Reference navigation list
        const changedEntities = this._uow._emProvider.manager().getChanges();
        if (changedEntities != null) {

            changedEntities.forEach(e => {

                if (e.entityType.shortName == 'ExperimentalResult') {

                    if (e.entityAspect.entityState.isAdded()) {
                        if (e['experimentalMaterialId'] == em.experimentalMaterialId) {
                            hasnew = true;
                        }
                    }
                }
            });
        }

        return (hasexisting == false && hasnew == false);
    }
}

<div class="indent-top-sm"></div>
<editor-header [edm]="_edm" [femaRestricted]="true"></editor-header>

<default-table *ngIf="_edm.hasEntities()" [attr.data-hidden]="_edm.editing">
    <thead>
        <tr>
            <td>Product</td>
            <td>Category</td>
            <td>Product CAS</td>
            <td>Less Than</td>
            <td>Trace</td>
            <td>Qualitative</td>
            <td>Range Min</td>
            <td>Range Max</td>
            <td>Unit</td>
            <td>Comment</td>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let e of _edm.entities" (click)="_edm.onSelect(e)">
            <td>{{e.product}}</td>
            <td>
                <input type="checkbox" disabled [checked]="e.category">
            </td>
            <td>{{e.productCAS}}</td>
            <td>
                <input type="checkbox" disabled [checked]="e.lessThan">
            </td>
            <td>
                <input type="checkbox" disabled [checked]="e.trace">
            </td>
            <td>
                <input type="checkbox" disabled [checked]="e.qualitative">
            </td>
            <td>{{e.rangeMin}}</td>
            <td>{{e.rangeMax}}</td>
            <td>{{e.unit}}</td>
            <td>{{e.comment}}</td>
            <!-- <td>{{s.entityAspect.entityState}}</td> -->
        </tr>
    </tbody>
</default-table>

<div *ngIf="_edm.editing">
    <natural-occurrence #self [entity]="_edm.currentEntity"></natural-occurrence>
</div>
import { Component, Input, OnInit } from '@angular/core';
import * as _ from 'lodash';

import { EditManager, UtilFns } from '../../utils/common';
import { provideParent, StateMap, UnitOfWork } from '../../services/common';
import { EditPropParent } from '../../controls/common';

import { NationalToxicologyProgram, TypeNTPStatus, TypeRoute, TypeSpecies, TypeStudy } from '../../entities/EntityModels';

@Component({
    selector: 'status-ntp',
    templateUrl: './status-ntp.html',
    providers: [provideParent(StatusNtpComponent, EditPropParent)]
})
export class StatusNtpComponent implements OnInit, EditPropParent {

    @Input() edm: EditManager<NationalToxicologyProgram>;
    @Input() entity: NationalToxicologyProgram;

    _typeStudies: TypeStudy[] = [];
    _typeSpecies: TypeSpecies[] = [];
    _typeRoutes: TypeRoute[] = [];
    _typeNTPStatuses: TypeNTPStatus[] = [];
    _lastReviewDateAlertWarning: string;
    _typeNTPStatusIdAlertWarning: string;

    constructor(public _uow: UnitOfWork, public _stateMap: StateMap) {

    }

    ngOnInit() {
        this._uow.typeStudyRepository.all().then(r => this._typeStudies = _.sortBy(r, x => x.studyLong));
        this._uow.typeSpeciesRepository.all().then(r => this._typeSpecies = _.sortBy(r, x => x.speciesLong));
        this._uow.typeRouteRepository.all().then(r => this._typeRoutes = _.sortBy(r, x => x.routeLong));
        this._uow.typeNTPStatusRepository.all().then(r => this._typeNTPStatuses = _.sortBy(r, x => x.description));
    }

    getError(propName: string) {
        return this.entity.getErrorFor(propName);
    }

    get utilFns() {
        return UtilFns;
    }

    checkNull(value: string) {
        if (value) {
            return value;
        } else {
            return null;
        }
    }

    markAsReviewed() {
        this.entity.lastReviewDate = new Date();
        this._lastReviewDateAlertWarning = null;
    }

    removeReviewDate() {
        this.entity.lastReviewDate = null;
        this._lastReviewDateAlertWarning = 'The Last Review Date is a required field. Please use the "Mark as Reviewed" button to update this field.';
    }

    isStaff() {
        return this._stateMap.currentUser.isStaff;
    }

    onSetNTPStatusTypeId(val: string) {
        this.entity.typeNTPStatusId = val;
        if (val) {
            this._typeNTPStatusIdAlertWarning = null;
        }
    }

    // Editing Overrides
    canApply() {
        if (this.edm.editing) {
            return (this._uow.hasChanges() && !this.edm.currentEntity.entityAspect.hasValidationErrors);
        }

        return false;
    }

    canCancel() {
        return (this._uow.hasChanges());
    }

    canShowBack(): boolean {
        return (!this._uow.hasChanges());
    }

    onApply() {
        this._lastReviewDateAlertWarning = null;
        this._typeNTPStatusIdAlertWarning = null;

        if (!this.entity.lastReviewDate) {
            this._lastReviewDateAlertWarning = 'The Last Review Date is a required field. Please use the "Mark as Reviewed" button to update this field.';
            return false;
        }

        if (!this.entity.typeNTPStatusId) {
            this._typeNTPStatusIdAlertWarning = 'The Description is a required field. Please select one from the list.';
            return false;
        }

        this.edm.onApplyCore();
    }
}

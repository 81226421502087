import { Component, Input, OnInit } from '@angular/core';

import { provideParent } from '../../services/common';
import { EditPropParent } from '../../controls/common';

import { NaturalOccurrenceDescriptor } from '../../entities/NaturalOccurrenceDescriptor';

@Component({
    selector: 'natural-occurrence',
    templateUrl: './natural-occurrence.html',
    providers: [provideParent(NaturalOccurrenceComponent, EditPropParent)]
})
export class NaturalOccurrenceComponent implements OnInit, EditPropParent {
    @Input() entity: NaturalOccurrenceDescriptor;

    constructor() {
    }

    ngOnInit() {
    }

    getError(propName: string) {
        return this.entity.getErrorFor(propName);
    }
}

<default-table *ngIf="material">
    <thead>
        <tr>
            <td>Material</td>
            <td>CAS No</td>
            <td>RIFM ID</td>
            <td>FEMA No.</td>
        </tr>
    </thead>
    <tbody>
        <tr >
            <td><span [htmlGreek]="material.principalSynonym()?.synonymWordOrWebVersion()"></span></td>
            <td>{{material.realCASNumber}}</td>
            <td>{{material.monographNumber}}</td>
            <td>{{material.fEMANumber}}</td>
        </tr>
    </tbody>
</default-table>
import { Component, Input, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import * as _ from 'lodash';
import { StateMap, UnitOfWork, UserManager } from '../../services/common';
import { LocationFns, UtilFns } from '../../utils/common';
import { ColumnSorter, PageState } from '../../controls/common';

import { MATERIAL_ROUTES } from './material.routes';
import { MaterialConsumption } from '../../entities/EntityModels';

@Component({
    selector: 'flavor-volumes',
    templateUrl: './flavor-volumes.html',
})
export class FlavorVolumesComponent implements OnInit {
    @Input() materialId: number;

    _entities: MaterialConsumption[];
    _groups: { name: string, entities: MaterialConsumption[], collapsed?: boolean }[] = [];
    _pageState = new PageState('Flavor volumes');
    _colSorter: ColumnSorter;
    _editing = false;

    constructor(public _uow: UnitOfWork, public _userManager: UserManager, public _stateMap: StateMap,
                public _location: Location) {

    }

    ngOnInit() {
        LocationFns.setTab(this._location, 2);

        this._colSorter = new ColumnSorter(this, {
            'Kilograms consumed': (e: MaterialConsumption) => UtilFns.formatNumber(e.kilogramsConsumed, 8),
            'Area': (e: MaterialConsumption) => this.getGeoArea(e)
        }, 'Kilograms consumed', (sortColumn) => this._pageState.sortColumn = sortColumn);

        this._stateMap.currentRouteName = MATERIAL_ROUTES.Flavor.name;

        const p = {materialId: this.materialId, materialType: 'flavor'};
        this._pageState.isLoading = true;
        this._uow.materialConsumptionRepository.whereWithParams(p).then(r => {
            this._entities = _.sortBy(r, e => e.surveyYear);
            const groups = _.groupBy(this._entities, (e) => e.surveyYear);
            this._groups = _.map(groups, (v, k) => {
                return {name: k, entities: v, isCollapsed: false};
            });

            this._pageState.isLoaded = r;
        });
    }

    collapseGroup(ix: number) {
        this._groups[ix].collapsed = !this._groups[ix].collapsed;
    }

    getGeoArea(mc: MaterialConsumption) {
        const survey = mc.survey;
        if (!survey) {
            return '';
        }
        const t1 = survey.typeGeographicalArea;
        if (!t1) {
            return '';
        }
        return t1.geographicalArea;
    }

    sortWith(colSorter: ColumnSorter) {
        this._groups.forEach(g => {
            colSorter.sort(g.entities);
        });
    }


}

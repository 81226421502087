import {Injectable, Component } from '@angular/core';

export enum WorkflowEntityState {
    Added = 1,
    Modified,
    Deleted,
    Undefined
}

export class WorkflowState {
    workflowReferenceId: number;
    workflowMaterialId: number;
    workflowObjectId: string;
    workflowEntityName: string;
    workflowPropertyName: string;
    workflowEntityState: WorkflowEntityState;
    workflowContactId: number;
    workflowContactEmail: string;
    workflowNotes: string;
    workflowHoldReason: string;
    workflowChangeValueNumber: number;
    workflowChangeValueString: string;
    workflowActionObjectId: number;
    workflowActionPropertyName: string;
    workflowUserNotes: string;

    // tslint:disable-next-line:max-line-length
    constructor(referenceId?: number, materialId?: number, entityName?: string, objectId?: string, propertyName?: string, entityState?: WorkflowEntityState, contactId?: number, contactEmail?: string, workflowHoldReason?: string) {

        this.workflowReferenceId        = referenceId || null;
        this.workflowMaterialId         = materialId || null;
        this.workflowObjectId           = objectId || null;
        this.workflowEntityName         = (entityName == null) ? null : entityName;
        this.workflowPropertyName       = propertyName || null;
        this.workflowEntityState        = entityState || WorkflowEntityState.Undefined;
        this.workflowContactId          = contactId || null;
        this.workflowContactEmail       = contactEmail || null;
        this.workflowHoldReason         = workflowHoldReason || null;
    }

    public parsedEntityName(): string {
        return ((this.workflowEntityName == null) ? null : this.parseEntityName(this.workflowEntityName));
    }

    private parseEntityName(entityName: string): string {
        const idx = entityName.indexOf(':');
        if (idx > 0) {
            return entityName.substring(0, (idx));
        }

        return (entityName);
    }
}

<page-status [pageState]="_pageState"></page-status>

<div *ngIf="_groupedLinks && _groupedLinks.length > 0">
    <div class="" style="margin-bottom: 10px;">
        <h4 class="bold">Non-tox References</h4>
    </div>
    <div *ngFor="let group of _groupedLinks">
        <default-table *ngIf="group">
            <h5 header class="bold">{{group.key}}</h5>
            <thead>
                <tr>
                    <td>Brief Reference</td>
                    <td>Description</td>
                    <td>Reference Id</td>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let e of group.specialLinks">
                    <td>{{ e.reference?.briefRef(isStaff()) }}</td>
                    <td>{{e.description()}}</td>
                    <td (click)="onReferenceSelect(e)">
                        <a>{{e.referenceId}}</a>
                    </td>
                </tr>
            </tbody>
        </default-table>
    </div>
</div>
import {QuickDocumentUploadComponent} from './quick-document-upload.component';
import {RIFMArchivedDocumentsComponent } from './rifm-archived-documents.component';
import {RIFMDocumentsComponent} from './rifm-documents.component';
import {DocumentManagementReportsComponent} from './document-management-reports.component';

export const DOCUMENTMANAGEMENT_ROUTES = {
    RIFMDocuments: { name: 'RIFMDocuments', title: 'Reference Documents', path: 'rifm-documents', component: RIFMDocumentsComponent, rolesExcluded: 'consultant,FEMA' },
    QuickDocumentUpload: { name: 'QuickDocumentUpload', title: 'Quick Upload', path: 'quick-document-upload', component: QuickDocumentUploadComponent, rolesExcluded: 'consultant,FEMA' },
    RIFMArchivedDocuments: { name: 'RIFMArchivedDocuments', title: 'Archived Documents', path: 'rifm-archived-documents', component: RIFMArchivedDocumentsComponent, rolesExcluded: 'consultant,FEMA' },
    // tslint:disable-next-line:max-line-length
    DocumentManagementReports: { name: 'DocumentManagementReports', title: 'Reports', path: 'document-management-reports', component: DocumentManagementReportsComponent, rolesExcluded: 'consultant,FEMA' },
};

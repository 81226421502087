const _monthFullNames = [
    'January', 'February', 'March',
    'April', 'May', 'June', 'July',
    'August', 'September', 'October',
    'November', 'December'
];

export class  JournalSubscriptionItem {
    journalSubscriptionId: number;
    journalName: string;
    subscriptionDescription: string;
    subscriptionStartMonth: number;
    subscriptionStartYear: number;
    subscriptionEndMonth: number;
    subscriptionEndYear: number;
    issuesPerYear: string;
    department: string;
    notes: string;
    publisher: string;

    // tslint:disable-next-line:max-line-length
    constructor(id: number, name: string, subDesc: string, subStartMonth: number, subStartYear: number, subEndMonth: number, subEndYear: number, issuesPerYear: string, dept: string, publisher: string, notes: string) {
        this.journalSubscriptionId      = id;
        this.journalName                = name;
        this.subscriptionDescription    = subDesc;
        this.subscriptionStartMonth     = subStartMonth;
        this.subscriptionStartYear      = subStartYear;
        this.subscriptionEndMonth       = subEndMonth;
        this.subscriptionEndYear        = subEndYear;
        this.issuesPerYear              = issuesPerYear;
        this.department                 = dept;
        this.publisher                  = publisher;
        this.notes                      = notes;
    }

    public get subscriptionStartYearAndMonth(): string {
        if (this.subscriptionStartYear == null) {
            return '';
        }

        const month = (this.subscriptionStartMonth == null) ? '' : ', ' + _monthFullNames[this.subscriptionStartMonth - 1];

        return (this.subscriptionStartYear + month);
    }

    public get subscriptionEndYearAndMonth(): string {
        if (this.subscriptionEndYear == null) {
            return '';
        }

        const month = (this.subscriptionEndMonth == null) ? '' : ', ' + _monthFullNames[this.subscriptionEndMonth - 1];

        return (this.subscriptionEndYear + month);
    }

    public get subscriptionStartDateForSort(): Date {
        if (this.subscriptionStartYear == null) {
            return null;
        }

        const m = (this.subscriptionStartMonth != null) ? this.subscriptionStartMonth : 1;

        return (this.formatDate(m, this.subscriptionStartYear));
    }

    public get journalSubscriptionEndDateForSort(): Date {
        if (this.subscriptionEndYear == null) {
            return null;
        }

        const m = (this.subscriptionEndMonth != null) ? this.subscriptionEndMonth : 1;

        return (this.formatDate(m, this.subscriptionEndYear));
    }

    formatDate(month: number, year: number): Date {
        const s = '';
        const d = s.concat('' + month, '/', '01', '/', '' + year);
        return new Date(d);
    }

    public static compareJournalSubscriptionItems = function (a: JournalSubscriptionItem, b: JournalSubscriptionItem) {

        if (a.subscriptionStartDateForSort != null && b.subscriptionStartDateForSort != null) {
            if ( a.subscriptionStartDateForSort > b.subscriptionStartDateForSort) {
                return -1;
            } else if ( a.subscriptionStartDateForSort < b.subscriptionStartDateForSort) {
                return 1;
            }
        }
        return 0;
    };
}

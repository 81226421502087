<div style="margin-left: 4px; margin-top: 12px;">

    <div style="background-color: transparent !important; width: 100%;">
        <div class="float-left" style="width:63%;">
            <div id="cluster-title-container" class="flex-container background-transparent">
                <div class="flex-item">
                    <span class="inline cluster-group-name bold">{{pageTitle}}</span>
                </div>
                <div class="flex-item">
                    <p class="alert-danger text-left">{{_userNotifications}}</p>
                </div>
            </div>
        </div>
        <div class="float-right" style="width:35%;">
            <div id="cluster-reports-container" class="flex-container background-transparent" style="margin-left: 4px; margin-top: -2px;">
                <div class="flex-item" *ngIf="_isClusterGraphDisplayed">
                    <button
                            id="btnExportClusterImage"
                            type="button"
                            class="btn btn-export-cluster-image"
                            [disabled]="!hasNodeData()"
                            (click)="onExportClusterImage()"
                            (mouseenter)="toggleTooltip(7)" (mouseleave)="toggleTooltip(7)">
                        Export Displayed Graph to Image File
                    </button>
                    <dx-tooltip
                            style="border: 1px solid #67b168;"
                            target="#btnExportClusterImage"
                            [(visible)]="toggleExportClusterImage"
                            [closeOnOutsideClick]="false">
                        <div *dxTemplate="let data = data of 'content'">
                            Create an image file that shows the displayed graph.
                        </div>
                    </dx-tooltip>
                </div>
                <div class="flex-item">
                    <button
                            id="btnExportClusterFile"
                            type="button"
                            class="btn btn-export-cluster-file"
                            (click)="onExportClusterFile()"
                            (mouseenter)="toggleTooltip(8)" (mouseleave)="toggleTooltip(8)">
                        Export All Cluster Data to File
                    </button>
                    <dx-tooltip
                            style="border: 1px solid #67b168;"
                            target="#btnExportClusterFile"
                            [(visible)]="toggleExportClusterFile"
                            [closeOnOutsideClick]="false">
                        <div *dxTemplate="let data = data of 'content'">
                            Download a *.csv file with data for all cluster assignments.
                        </div>
                    </dx-tooltip>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="_isLoading"  class="float-left clear" style="font-size:38px; margin-left: 12px;">
            <i class="fa fa-circle-o-notch fa-spin" style="color: #337ab7;"></i>
            <span class="inline publication-title bold">Preparing cluster data...</span>
        </div>

    <div style="width: 100%; margin-bottom: 2px;">
            <div id="cluster-button-container" class="flex-container background-transparent" style="width: 100%; overflow-x: auto;">
                <div *ngIf="_clusterGroups" class="flex-item subtitle-margin-top">
                    <span class="inline bold" style="margin-left: 22px;">Select a Cluster Group:</span>
                    <select class="form-control inline" *ngIf="_clusterGroups != null && _clusterGroups.length > 0" style="width: 360px; margin-left: 6px;"
                            [ngModel]="_selectedClusterGroupId"
                            (change)="onSelectClusterGroup($event.target.value)">
                        <option *ngFor="let g of _clusterGroups" [value]="g.clusterGroupId">{{g.clusterGroupName}}</option>
                    </select>
                </div>
                <div class="flex-item" *ngIf="_isClusterGraphDisplayed">
                    <button id="btnShowSelectClusterGroupPaths" type="button"
                            class="btn btn-sm btn-primary bold" style="height: 35px;margin-left: 18px;" [disabled]="!hasSelectedClusterGroup()"
                            (click)="onShowSelectClusterGroupPaths()"
                            (mouseenter)="toggleTooltip(1)" (mouseleave)="toggleTooltip(1)">
                        Select Cluster Paths
                    </button>

                    <dx-tooltip
                            style="border: 1px solid #67b168; font-weight: bold;"
                            target="#btnShowSelectClusterGroupPaths"
                            [(visible)]="toggleShowSelectClusterGroupPaths"
                            [closeOnOutsideClick]="false">
                        <div *dxTemplate="let data = data of 'content'">
                            Select one or more Cluster Paths to display.
                        </div>
                    </dx-tooltip>
                </div>

                <div class="flex-item" *ngIf="_isClusterGraphDisplayed">
                    <button id="btnShowMaterialLookup" type="button"
                            class="btn btn-search-image"
                            style="width: 150px;"
                            (click)="onSelectMaterial()"
                            (mouseenter)="toggleTooltip(9)" (mouseleave)="toggleTooltip(9)">
                        Search by Material
                    </button>
                    <dx-tooltip
                            style="border: 1px solid #67b168;"
                            target="#btnShowMaterialLookup"
                            [(visible)]="toggleMaterialLookup"
                            [closeOnOutsideClick]="false">
                        <div *dxTemplate="let data = data of 'content'">
                            Search for the Cluster Path assigned to a Material.
                        </div>
                    </dx-tooltip>
                </div>

                <div class="flex-item" *ngIf="_isClusterGraphDisplayed">
                    <button id="btnShowClusterFilterModal" type="button"
                            class="btn btn-search-image"
                            (click)="onShowClusterFilterModal()"
                            (mouseenter)="toggleTooltip(3)" (mouseleave)="toggleTooltip(3)">
                        Search by Cluster Term
                    </button>
                    <dx-tooltip
                            style="border: 1px solid #67b168;"
                            target="#btnShowClusterFilterModal"
                            [(visible)]="toggleShowClusterFilterModal"
                            [closeOnOutsideClick]="false">
                        <div *dxTemplate="let data = data of 'content'">
                            Search for Cluster Paths by a chemical structure term.
                        </div>
                    </dx-tooltip>
                </div>
                <div class="flex-item" *ngIf="_isClusterGraphDisplayed">
                    <div class="inline" style="width: 420px; margin-bottom: 2px;">
                        <span class="block italic gray-text bold left-indent" autocomplete="searchformaterial" style="font-size: 11px; ">Quick Search by CAS No. or MaterialId:</span>

                        <input type="text" class="form-control inline" style="width:120px; margin-left: 10px; padding-top: 3px; padding-bottom: 3px;"
                               [(ngModel)]="_quickSearchCASNumber"
                               id="quickSearchCASNumber"
                               name="quickSearchCASNumber"
                               autocomplete="clustersearch" type="search" (input)="onQuickSearchCASNumber()"
                        />
                        <button
                                class="btn-no-margin btn-primary left-indent"
                                style="border-radius: 5px; margin-left: 10px;"
                                [disabled]="!canQuickSearch()"
                                (click)="onQuickSearch()">
                            Find
                        </button>
                    </div>
                </div>
            </div>
        </div>

</div>

<div style="width: 100%;">
    <div class="float-left" style="width: 92% !important;">
        <div id="cluster-tab-container">
            <tab-container (tabChange)="onTabChange($event)">
                <ng-template tab-pane title="Cluster Display">
                    <div class="cluster-path-container" style="position: relative; top: 0px; border-left-width: 0px; height: 1800px;">
                        <div style="height: 100%; width: 100%; overflow: auto; overflow-y: auto; display:block;">
                            <cytoscape-graph [layout]="layout" [highlightTopNode]="true"></cytoscape-graph>
                        </div>
                    </div>
                </ng-template>
                <ng-template *ngIf="_materialIds && _materialIds.length " tab-pane title="Human Health Studies ">
                    <h4>{{_selected_node_name}}</h4>
                    <human-health [materialIds]="_materialIds " [toxType]=" 'H' " [tabPath]=" '1' " [shouldFetchMaterials]="true"></human-health>
                </ng-template>
                <ng-template *ngIf="_materialIds && _materialIds.length " tab-pane title="Environmental Studies ">
                    <h4>{{_selected_node_name}}</h4>
                    <human-health [materialIds]="_materialIds " [toxType]=" 'E' " [tabPath]=" '2' " [shouldFetchMaterials]="true"></human-health>
                </ng-template>
                <ng-template *ngIf="_materialIds && _materialIds.length " tab-pane title="Non-tox References ">
                    <h4>{{_selected_node_name}}</h4>
                    <tox-data-other [materialIds]="_materialIds " [tabPath]=" '3' "></tox-data-other>
                </ng-template>
            </tab-container>
        </div>
    </div>
    <div class="float-right" style="width: 8% !important; margin-top: 70px;">
        <div class="float-left display-block text-center" *ngIf="_isClusterGraphDisplayed">
            <button id="btnCenterGraph" type="button"
                    class="btn btn-center-graph btn-side-toolbar"
                    [disabled]="!hasNodeData()"
                    (click)="alignGraph()"
                    (mouseenter)="toggleTooltip(6)" (mouseleave)="toggleTooltip(6)">
            </button>
            <dx-tooltip
                    style="border: 1px solid #67b168;"
                    target="#btnCenterGraph"
                    [(visible)]="toggleCenterGraph"
                    [closeOnOutsideClick]="false">
                <div *dxTemplate="let data = data of 'content'">
                    Position the graph in the center of the display area.
                </div>
            </dx-tooltip>
        </div>
        <div class="float-left display-block text-center" style="margin-top: 24px;">
            <button id="btnShowHelpVideo" type="button"
                    class="btn btn-help btn-side-toolbar"
                    (click)="onShowHelpVideoModal()"
                    (mouseenter)="toggleTooltip(4)" (mouseleave)="toggleTooltip(4)">
            </button>
            <dx-tooltip
                    style="border: 1px solid #67b168;"
                    target="#btnShowHelpVideo"
                    [(visible)]="toggleShowHelpVideo"
                    [closeOnOutsideClick]="false">
                <div *dxTemplate="let data = data of 'content'">
                    Show Tutorial Videos on Cluster Graph Interaction.
                </div>
            </dx-tooltip>
        </div>
        <div class="float-left display-block text-center" style="margin-top: 48px;" *ngIf="_isClusterGraphDisplayed">
            <button id="btnClearGraph" type="button"
                    class="btn btn-clear btn-side-toolbar"
                    [disabled]="!hasNodeData()"
                    (click)="onClearGraph()"
                    (mouseenter)="toggleTooltip(5)" (mouseleave)="toggleTooltip(5)">
            </button>
            <dx-tooltip
                    style="border: 1px solid #67b168;"
                    target="#btnClearGraph"
                    [(visible)]="toggleClearGraph"
                    [closeOnOutsideClick]="false">
                <div *dxTemplate="let data = data of 'content'">
                    Clear all selections and displayed graph nodes.
                </div>
            </dx-tooltip>
        </div>
    </div>
</div>


<clusterpath-filter></clusterpath-filter>

<clusterpath-selector></clusterpath-selector>

<cluster-help-video></cluster-help-video>

<material-selector></material-selector>

import { EntityBase } from './EntityBase';
import { Validator } from 'breeze-client';
import { Journal } from './Journal';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

export class JournalReserve extends EntityBase {

    /// <code> Place custom code between <code> tags

    /// </code>

    // Generated code. Do not place code below this line.
    journalReserveId: number;
    journalId: number;
    journalYear: number;
    volume: string;
    issue: string;
    pages: string;
    notes: string;
    created: Date;
    createdUser: string;
    modified: Date;
    modifyUser: string;
    rowVersion: number;
    journal: Journal;
}


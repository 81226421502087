import { EntityBase } from './EntityBase';
import { Material } from './Material';
import { TypeMaterialRelation } from './TypeMaterialRelation';

import { Validator } from 'breeze-client';

/// <code-import> Place custom imports between <code-import> tags
import { EntityTypeAnnotation, EntityPropertyAnnotation} from './EntityTypeAnnotation'
/// </code-import>

export class MaterialRelation extends EntityBase {

   /// <code> Place custom code between <code> tags
   static getEntityTypeAnnotation(): EntityTypeAnnotation {
        var propAnnotations = [
            new EntityPropertyAnnotation('relatedMaterialId', {
                displayName: 'Related Material',
                validators: [Validator.required()]
            }),
        ];

        return new EntityTypeAnnotation({
            validators: [],
            propertyAnnotations: propAnnotations
        })
    }
   /// </code>

   // Generated code. Do not place code below this line.
   materialId: number;
   relatedMaterialId: number;
   typeMaterialRelationId: number;
   created: Date;
   createdUser: string;
   modified: Date;
   modifyUser: string;
   rowVersion: number;
   material: Material;
   relatedMaterial: Material;
   typeMaterialRelation: TypeMaterialRelation;
}


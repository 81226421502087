import { Component, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';

import { StateMap, UnitOfWork } from '../../services/common';
import { EditManager, IEditHost } from '../../utils/common';
import { ColumnSorter } from '../../controls/common';

import { MATERIAL_ROUTES } from './material.routes';
import { REACHRegistration } from '../../entities/EntityModels';
import { REACHRegistrationComponent } from './reach-registration.component';

@Component({
    selector: 'reach-registrations',
    templateUrl: './reach-registrations.html',
})
export class REACHRegistrationsComponent implements IEditHost<REACHRegistration>, OnInit {

    @ViewChild(REACHRegistrationComponent) _editingComponent: REACHRegistrationComponent;

    _materialId: number;
    _edm: EditManager<REACHRegistration>;
    _colSorter: ColumnSorter;

    constructor(protected _uow: UnitOfWork, protected _stateMap: StateMap, protected _location: Location) {

    }

    ngOnInit() {
        this._stateMap.currentRouteName = MATERIAL_ROUTES.Reach.name;
        const material = this._stateMap.currentMaterial;
        this._edm = new EditManager<REACHRegistration>(this, this._uow, this._stateMap, 'Reach Registrations');
        this._materialId = material.materialId;

        this._colSorter = new ColumnSorter(this, {
            'Type': (r: REACHRegistration) => r.registrationType,
            'CAS No.': (r: REACHRegistration) => r.registrationCASNumber,
            'Name': (r: REACHRegistration) => r.registrationName
        }, 'CAS No');

        this._edm.pageState.isLoading = true;
        this._uow.rEACHRegistrationRepository.whereWithParams({materialId: material.materialId}).then(r => {
            this._edm.entities = r;
            this._edm.pageState.isLoaded = r;
        }).catch(e => {
            this._edm.pageState.isLoaded = false;
        });

    }

    canDeactivate() {
        return !this._edm.hasEdits();
    }

    onAdd() {
        if (!this._edm.validateCurrent()) {
            return;
        }
        const e = this._uow.rEACHRegistrationFactory.create();
        e.materialId = this._materialId;
        e.typeREACHTonnageBandId = null;
        this._edm.entities.push(e);
        this._edm.onSelect(e);
    }

    sortWith(colSorter: ColumnSorter) {
        colSorter.sort(this._edm.entities);
    }

    linkToDossier(e: REACHRegistration) {
        const url = e.dossierWebLink;
        window.open(url);
    }

}

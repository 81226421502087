import { EntityBase } from './EntityBase';
import { Material } from './Material';

import { Validator } from 'breeze-client';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

export class NaturalOccurrenceDescriptor extends EntityBase {

   /// <code> Place custom code between <code> tags
   
   /// </code>

   // Generated code. Do not place code below this line.
   materialId: number;
   product: string;
   category: boolean;
   productCAS: string;
   lessThan: boolean;
   trace: boolean;
   qualitative: boolean;
   rangeMin: number;
   rangeMax: number;
   unit: string;
   comment: string;
   created: Date;
   createdUser: string;
   modified: Date;
   modifyUser: string;
   rowVersion: number;
   material: Material;
}


<div style="margin-bottom: 10px">
    <div class="inline" style="font-size: 18px;" *ngIf="_edm.editing">{{_adding ? "Adding a What's New item'" : "Editing a What's New item" }}</div>
    <span *ngIf="(!_edm.editing) || _edm.canShowBack()">
        <button type="button" class="inline btn btn-sm btn-primary"  (click)="_edm.onAdd()">Add a What's New item</button>            
        <button type="button" class="inline btn btn-sm btn-primary"  (click)="onEdit()"> {{ "Edit " + (_edm.editing ? "Another " : "a ") + "What's New item" }}</button>
    </span>
</div>
<editor-header [edm]="_edm" [customButtons]="true">
    <span customButtons>
        <span *ngIf="_edm.editing">
            <button type="button" class="btn btn-sm btn-primary" [disabled]="!_edm.canSave()" (click)="_edm.onSave()">Save</button>
            <button *ngIf="!_adding" type="button" class="btn btn-sm btn-primary" [disabled]="!_edm.canDelete()" (click)="_edm.onDelete()">Delete</button>
            <button *ngIf="_edm.canShowBack()" type="button" class="btn btn-sm btn-primary"  (click)="_edm.onBack()">Back</button>
            <button *ngIf="!_edm.canShowBack()" type="button" class="btn btn-sm btn-primary" [disabled]="!_edm.canCancel()" (click)="_edm.onCancel()">Cancel</button>
        </span>
    </span>
</editor-header>

<div *ngIf="_edm.editing" class="form-horizontal">
    <edit-prop label="Title" prop="title" szs="2,9">
        <input type="text" class="form-control" [(ngModel)]="entity.title">
    </edit-prop>
    <edit-prop label="Content" prop="title" szs="2,9">
        <textarea [(ngModel)]="entity.content" rows="4" class="form-control"></textarea>
    </edit-prop>
    <edit-prop label="Image Link" prop="imageLink" szs="2,9">
        <input type="text" class="form-control" [(ngModel)]="entity.imageLink">
    </edit-prop>
    <edit-prop label="Web Link" prop="webLink" szs="2,9">
        <input type="text" class="form-control" [(ngModel)]="entity.webLink">
    </edit-prop>
    <edit-prop label="Web Link Description" prop="webLinkDescription" szs="2,9">
        <input type="text" class="form-control" [(ngModel)]="entity.webLinkDescription">
    </edit-prop>
    <edit-prop label="Enabled" prop="webLink" szs="2,9">
        <input type="checkbox" [(ngModel)]="entity.enabled">
    </edit-prop>
</div>

<whats-new-selector></whats-new-selector>
<div style="margin-left: 8px;">
    <p class="bold">{{userMessage}}</p>

    <div>
        <div>
            <p *ngIf="!hasNamesData()" class="bold">No related names for the selected journal.</p>
        </div>

        <div *ngIf="hasNamesData()">

            <div *ngIf="_relatedJournals != null && _relatedJournals.length > 0" style="margin-top: 10px;">
                <p class="bold left-indent">Journal Name History</p>
                <default-table>
                    <thead class="journal-thead">
                    <tr>
                        <td class="default-font text-left" style="width: 90px;">Journal Id</td>
                        <td class="default-font text-left" style="width: 320px;">Journal Name</td>
                        <td class="default-font text-center" style="width: 110px;">Journal Name Start Date</td>
                        <td class="default-font text-center" style="width: 110px;">Journal Name End Date</td>
                        <td class="default-font text-center" style="width: 320px;">Relationship</td>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let n of _relatedJournals">
                        <td class="default-font text-left" style="width: 90px;">{{n.journalId}}</td>
                        <td class="default-font text-left" style="width: 320px;">{{n.journalName}}</td>
                        <td class="default-font text-center" style="width: 110px;">{{n.displayStartDate}}</td>
                        <td class="default-font text-center" style="width: 110px;">{{n.displayEndDate}}</td>
                        <td class="default-font text-left" style="width: 320px;">{{n.relationship}}</td>
                    </tr>
                    </tbody>
                </default-table>
            </div>
        </div>
    </div>
</div>

<div id="ClusterPathFilter" class="modal fade bs-example-modal-lg" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel">
    <div class="modal-dialog modal-lg">
        <div class="modal-content clusterfiltercontainer">
            <div class="modal-header custom-header">
                <span class="modal-title" id="myModalLabel">Search for Cluster Paths by Chemical Terms</span>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="inline subtitle-margin-top" style="margin-left: 16px;">
                        <span class="bold inline">Cluster Search Term:</span>
                        <input type="search" autocomplete="searchterm" class="form-control inline" style="margin-left: 14px; width: 280px;" [(ngModel)]="_searchClusterTerm">
                        <button class="btn btn-primary inline" type="button" style="margin-left: 18px;" (click)="onAddSearchTerm()" [disabled]="!canAddSearchTerm()">{{addClusterTermLabel}}</button>
                    </div>

                    <div class="flex-container-change-report background-transparent" style="margin-left: 12px; margin-bottom: 12px;"
                         *ngIf="_clusterPartSearchTerms != null && _clusterPartSearchTerms.length > 0">
                            <span *ngFor="let s of _clusterPartSearchTerms; let ix = index" class="change-report-selected">
                                <label>
                                    {{s}}
                                    <a (click)="onRemoveSearchTerm(s)">(Remove)</a>
                                </label>
                            </span>
                    </div>

                    <hr style="height: 2px; border: none; color: #000000; width: 100%;" />

                    <p class="alert-danger text-left" style="width: 96%; margin-left: 16px;">{{_userNotifications}}</p>

                    <div style="height: 80px; width: 96%;border-top: 1px solid #AAAAAA; border-bottom: 1px solid #AAAAAA; background-color: #F0F8FF; padding: 4px; margin-left: 16px;">
                        <div class="float-left" style="width: 65%;">
                            <div *ngIf="showUserMessage()">
                                <p  class="bold">The Cluster Terms were found in Cluster Paths in Multiple Groups.</p>
                                <p  class="bold">Select a Cluster Group to see each set of Cluster Paths that match your query.</p>
                            </div>
                        </div>
                        <div class="float-right" style="width: 34%;">
                            <button class="btn btn-primary" type="button" (click)="onSave()" [disabled]="!canSave()">Display Selected Paths</button>
                            <button class="btn btn-primary" type="button" (click)="onCancel()">Cancel</button>
                        </div>
                    </div>

                    <div classs="row">
                        <div *ngIf="_clusterGroups != null && _clusterGroups.length > 0" class="subtitle-margin-top" style="margin-left: 16px;">
                            <span class="inline bold">Cluster Group:</span>
                            <select class="form-control inline" style="width: 420px; margin-left: 6px;"
                                    [ngModel]="_selectedClusterGroupId"
                                    (change)="onSelectClusterGroup($event.target.value)">
                                <option *ngFor="let g of _clusterGroups" [value]="g.clusterGroupId">{{g.clusterGroupName}}</option>
                            </select>
                        </div>

                        <div *ngIf="_clusterPathsEx != null && _clusterPathsEx.length > 0" style="margin-left: 16px; margin-top: 6px; max-height: 400px; overflow-y: auto; overflow-x: hidden;">
                            <p class="bold">The Cluster Term(s) were found in the following paths.</p>
                            <default-table>
                                <thead>
                                <tr>
                                    <td>Select</td>
                                    <td>Cluster Group Name</td>
                                    <td>Cluster Path</td>
                                </tr>
                                </thead>
                                <tbody>
                                <tr *ngFor="let p of _clusterPathsEx">
                                    <td><input type="checkbox" class="mediumcheckbox" [(ngModel)]="p.selected" (change)="onSelectClusterPath($event, p.data.clusterPathId)" /></td>
                                    <td><span class="text-left">{{p.data.clusterGroup.clusterGroupName}}</span></td>
                                    <td><span class="text-left">{{p.data.clusterPathName}}</span></td>
                                </tr>
                                </tbody>
                            </default-table>
                        </div>

                    </div>

                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer border-zero no-print">
    </div>
</div>

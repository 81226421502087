import { EntityBase } from './EntityBase';
import { Material } from './Material';
import { ExposureSurvey } from './ExposureSurvey';
import { TypeExposureSurveyResponse } from './TypeExposureSurveyResponse';

import { Validator } from 'breeze-client';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

export class MaterialExposureSurvey extends EntityBase {

   /// <code> Place custom code between <code> tags
   isomers: string;

   public get bannedMaterialCaption(): string {
        return (this.bannedMaterial) ? 'Banned' : '';
    }

   /// </code>

   // Generated code. Do not place code below this line.
   materialExposureSurveyId: number;
   exposureSurveyId: number;
   materialId: number;
   notes: string;
   expectedSACompletion: string;
   bannedMaterial: boolean;
   typeExposureSurveyResponseId: number;
   originalSurveyId: number;
   created: Date;
   createdUser: string;
   modified: Date;
   modifyUser: string;
   rowVersion: number;
   exposureSurvey: ExposureSurvey;
   material: Material;
   typeExposureSurveyResponse: TypeExposureSurveyResponse;
}


<div style="margin-top: 15px"></div>
<div *ngIf="_entity && !_data.inSubEditor" class="form-horizontal">

    <div class="form-group">
        <div class="col-md-7">
            <button type="button " class="btn btn-sm btn-success btn-no-margin" (click)="onCreateLink() ">Attach a link</button>
        </div>
    </div>
    <div class="form-group" style="margin-bottom: 0px;">
        <div *ngIf="_groupedLinks && _groupedLinks.length > 0">
            <div class="col-md-11" *ngFor="let group of _groupedLinks">
                <default-table *ngIf="group">
                    <h5 header class="bold">{{group.key}}</h5>
                    <thead>
                        <tr>
                            <td>Material</td>
                            <td>Description</td>
                            <td>Human Health</td>
                            <td>Environmental</td>
                            <td>Linked</td>
                            <td></td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let sl of group.specialLinks">
                            <td [htmlGreek]="sl.material?.principalSynonym()?.synonymWordOrWebVersion()"></td>
                            <td>{{sl.description()}}</td>
                            <td><input type="checkbox" disabled [checked]="sl.humanHealthData"></td>
                            <td><input type="checkbox" disabled [checked]="sl.environmentalData"></td>
                            <!--<td>{{sl.created | date}}</td>-->
                            <td>{{ _stateMap.utilFns.formatDate(sl.created) }}</td>
                            <td><a (click)="onDetachLink(sl)">Detach</a></td>
                        </tr>
                    </tbody>
                </default-table>
            </div>
        </div>
    </div>

</div>
<special-link-editor *ngIf="_data.newSpecialLink" (apply)="onApplyLink($event)" [entity]="_data.newSpecialLink"></special-link-editor>
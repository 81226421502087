import { EntityBase } from './EntityBase';
import { ExperimentalMaterial } from './ExperimentalMaterial';
import { ExperimentalToxicEffect } from './ExperimentalToxicEffect';
import { TypeExperimentVehicle } from './TypeExperimentVehicle';
import { TypeUnit } from './TypeUnit';

import { Validator } from 'breeze-client';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

export class ExperimentalResult extends EntityBase {

   /// <code> Place custom code between <code> tags
   public formattedDose() {
       var op = (this.compOp == '0') ? '' : this.compOp;
       return op + this.dose + ' ' + this.getTypeUnitDescription()
   }

   public formattedDoseAndEffects() {
       return this.formattedDose() + ' ' + this.toxicEffects();
   }
   
   public toxicEffects() {
       if (this.experimentalToxicEffects==null || this.experimentalToxicEffects.length == 0) return ''; 
       return this.experimentalToxicEffects.map(x => x.typeToxicEffect.toxicEffectDescription).join("; ");
   }
   
   getTypeUnitDescription() {
       if (!this.typeUnit) return '';
       return this.typeUnit.unitDescription;
   }
   
    cascadeDelete() {
        if (this.experimentalToxicEffects) {
            this.experimentalToxicEffects.map(et =>et).forEach(et => et.cascadeDelete());
        }
        this.cascadeDeleteCore();            
    }
   /// </code>

   // Generated code. Do not place code below this line.
   experimentalResultId: number;
   experimentalMaterialId: number;
   typeUnitId: string;
   compOp: string;
   dose: number;
   dPMperLymphNode: number;
   stimulationIndex: number;
   resultNote: string;
   typeExperimentVehicleId: string;
   created: Date;
   createdUser: string;
   modified: Date;
   modifyUser: string;
   rowVersion: number;
   experimentalMaterial: ExperimentalMaterial;
   experimentalToxicEffects: ExperimentalToxicEffect[];
   typeExperimentVehicle: TypeExperimentVehicle;
   typeUnit: TypeUnit;
}


import { EntityBase } from './EntityBase';
import { Reference } from './Reference';

import { Validator } from 'breeze-client';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

export class ExpertPanelSummary extends EntityBase {

   /// <code> Place custom code between <code> tags
   
   /// </code>

   // Generated code. Do not place code below this line.
   expertPanelID: number;
   narrativeTitle: string;
   narrativeLink: string;
   publication: string;
   referenceId: number;
   created: Date;
   createdUser: string;
   modified: Date;
   modifyUser: string;
   rowVersion: number;
   reference: Reference;
}


<page-status [pageState]="_pageState" ></page-status>

<div *ngIf="_expMatGroups && _expMatGroups.length" class="outermost table-responsive">
    <div class="collapse navbar-collapse" >
        
        <ul class="nav navbar-nav navbar-form  ">
            <li>
                <span class="bold">{{_studiesText}}&nbsp;&nbsp;&nbsp;</span>
                <span>Group By</span>
                <select class="btn btn-primary dropdown-toggle" [ngModel]="groupBy" (change)="groupBy=$event.target.value" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" >
                    <option *ngFor="let groupBy of _groupByKeys" class="dropdown" [value]="groupBy" > {{groupBy}}</option>
                </select>
            </li>
            <li>
                <span>Filter By</span>
                <select class="btn btn-primary dropdown-toggle" (change)="filterChange($event)" [(ngModel)]="_pageState.filter" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" > 
                    <option *ngFor="let filter of _filters; let ix = index"  class="dropdown" [value]="filter" [htmlGreek]="filter" ></option>
                </select>
            </li>
            <li>
                <button type="button" class="btn btn-primary btn-xs" (click)="expandAll(true)" >Expand All</button>
            </li>
            <li>
                <button type="button" class="btn btn-primary btn-xs" (click)="expandAll(false)">Collapse All</button>
            </li>
                
                
        </ul>
    </div>
    
    <!--<div *ngIf="isVeryLarge()" class="indent-left-sm">
        <div class="page-title alert-warning bold">
              Result set is too large to be displayed all at once. Please expand groups as you need them.
        </div>
        <div *ngIf="_browser.isIE" class="alert-warning">
            Note: Using a newer browser such as Google Chrome or Microsoft Edge will improve the performance of this app dramatically. 
        </div>
    </div>        -->
    <div  class="small">M=Member Company Report, R=RIFM Sponsored Report</div>
    <!--Cant use <default-table> here because of ngFor in <tbody>-->

    <!--
        <div style="width: 80%">
        <dx-data-grid [dataSource]="_allExpMats"
                      [showBorders]="true"
                      [allowColumnReordering]="true"
                      [allowColumnResizing]="true">
            <dxo-paging [enabled]="false"></dxo-paging>
            <dxo-sorting mode="multiple"></dxo-sorting>
            <dxo-state-storing [enabled]="true" type="localStorage" storageKey="_allExpMats"></dxo-state-storing>

            <dxi-column caption="Study" dataField="studyLong" [groupIndex]="0"></dxi-column>
            <div *dxTemplate="let cell of 'studyLong'">
                <span [htmlGreek]="cell.data.studyLong"></span>
            </div>

            <dxi-column caption="Route" dataField="routeLong" cellTemplate="routeLong"></dxi-column>
            <div *dxTemplate="let cell of 'routeLong'">
                <span [htmlGreek]="cell.data.routeLong()"></span>
            </div>

            <dxi-column caption="Species" dataField="speciesLong" cellTemplate="speciesLong"></dxi-column>
            <div *dxTemplate="let cell of 'speciesLong'">
                <span [htmlGreek]="cell.data.speciesLong()"></span>
            </div>

            <dxi-column caption="Reference No." dataField="referenceId" cellTemplate="referenceId"></dxi-column>
            <div *dxTemplate="let cell of 'referenceId'">
                <span [htmlGreek]="cell.data.referenceId()"></span>
            </div>

            <dxi-column caption="M" dataField="M" cellTemplate="mTemplate" [calculateSortValue]="calculateM"></dxi-column>
            <div *dxTemplate="let cell of 'mTemplate'">
                <span [htmlGreek]="cell.data.M()"></span>
            </div>

            <dxi-column caption="R" dataField="R" cellTemplate="rTemplate" [calculateSortValue]="calculateR"></dxi-column>
            <div *dxTemplate="let cell of 'rTemplate'">
                <span [htmlGreek]="cell.data.R()"></span>
            </div>

            <dxi-column caption="Sub-Reference" dataField="subReference"></dxi-column>

            <dxi-column caption="FFIDS" dataField="subReference" [visible]="isStaff()" cellTemplate="ffids"></dxi-column>
            <div *dxTemplate="let cell of 'ffids'">
                {{ cell.data.fFDS() }}
            </div>

            <dxo-grouping [autoExpandAll]="true"></dxo-grouping>
            <dxo-summary>
                <dxi-group-item
                        column="studyLong"
                        summaryType="count"
                        displayFormat="{0} studies">
                </dxi-group-item>
            </dxo-summary>
        </dx-data-grid>
    </div>

    -->

    <table class="table table-striped table-hover table-bordered table-condensed" style="width: auto;">
        <thead>
            <tr>
                <td></td>
                <td>Study</td>
                <td>Route</td>
                <td>Species</td>
                <td>Reference No.</td>
                <td><a [colSorter]="_colSorter">M </a></td>
                <td><a [colSorter]="_colSorter">R </a></td>
                <td><a [colSorter]="_colSorter">Sub-Reference </a></td>
                <td *ngIf="isStaff()">FFIDS</td>
                <td><a [colSorter]="_colSorter">Brief Reference </a></td>
                <td *ngIf="isStaff()"><a [colSorter]="_colSorter">Date </a></td>
                <td *ngIf="isStaff()"><a [colSorter]="_colSorter">Sponsor </a></td>
                <td *ngIf="isStaff()">Contains Mixture</td>
                <td *ngIf="shouldFetchMaterials">CAS No.</td>
            </tr>
        </thead>
        <tbody *ngFor="let group of _expMatGroups; let ix=index" >
            <tr class="header bold">
                <td>
                    <a (click)="expCollapse(ix)">
                        <span *ngIf="group.collapsed" class="fa fa-caret-down"></span>
                        <span *ngIf="!group.collapsed" class="fa fa-caret-up"></span>
                    </a>
                </td>
                <td colspan="9">
                    <span [htmlGreek]="group.name"></span>
                    <span>{{getGroupDescription(group)}}</span>
                </td>                    
            </tr>
            <ng-template [ngIf]="!group.collapsed">
                <tr class="body" *ngFor="let expMat of group.experimentalMaterials">
                    <td></td>
                    <td>
                        <a (click)="navToRefData(expMat)">{{expMat?.studyLong()}}</a>
                        <span class="small bold" *ngFor="let stDescr of expMat?.studySubTypeDescriptions()">
                            <br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{stDescr}}
                        </span>
                    </td>
                    <td [htmlGreek]="expMat?.routeLong()"></td>
                    <td [htmlGreek]="expMat?.speciesLong()"></td>
                    <td><a (click)="navToRef(expMat)">{{expMat?.referenceId()}}</a></td>
                    <td>{{expMat?.M()}}</td>
                    <td>{{expMat?.R()}}</td>
                    <td>{{expMat?.subReference}}</td>
                    <td *ngIf="isStaff()"><a>{{expMat?.fFDS()}}</a></td>
                    <td>{{ expMat?.briefRef(isStaff()) }}</td>
                    <td *ngIf="isStaff()">{{expMat?.modifiedOn()}}</td>
                    <td *ngIf="isStaff()">{{expMat?.sponsoringCompany()}}</td>
                    <td *ngIf="isStaff()">{{expMat?.typeStudyMixtureId}}</td>
                    <td *ngIf="shouldFetchMaterials" (click)="selectCAS(expMat)"><a>{{expMat?.material?.realCASNumber || '(No CAS#)'}}</a></td>

                </tr>
            </ng-template>
        </tbody>
    </table>
    
</div>

import { EntityBase } from './EntityBase';
import { Material } from './Material';
import { Reference } from './Reference';
import { JECFAMonograph } from './JECFAMonograph';
import { TypeFoodStatus } from './TypeFoodStatus';

import { Validator } from 'breeze-client';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

export class FoodStatus extends EntityBase {

   /// <code> Place custom code between <code> tags
   // These methods allow RegulatoryStatus and FoodStatus to share the same interface.

   evaluatingAgency() {
       return this.typeFoodStatus.foodAgencyLabel;
   }

   agencyTitle() {
       return this.typeFoodStatus.foodStatusTitle;
   }

   regulatoryStatement() {
       return this.typeFoodStatus.foodStatusStmnt ;
   }

   prefixedDetermination() {
      return this.regulatoryStatement() ? this.regulatoryStatement() + ' ' + this.determination : this.determination;
   }

   webReferenceExt() {
       if (this.monographID != null) {
           return this.jECFAMonograph.monographAddress;
       } else {
           return this.webReference;
       }
   }

   linkDisplayExt() {
        if (this.webReference == null) {
            return null;
        }

       return (this.webReference.indexOf('http') == 0) ? 'Link here' : 'Download here';
   }

    regulatoryTypeForCompare() {
        return ((this.regulatoryType != null) ? this.regulatoryType : '');
    }

    public get numberFlavis_JECFA(): string {
        if (this.foodAgencyId.toLowerCase() == 'flavis') {
            return this.fL_No;
        } else if (this.foodAgencyId.toLowerCase() == 'jecfa') {
            return this.jECFA_No;
        }
        return '';
    }

   /// </code>


   // Generated code. Do not place code below this line.
   foodStatusId: number;
   materialId: number;
   monographID: number;
   foodAgencyId: string;
   evaluatorId: string;
   referenceID: number;
   regulatoryType: string;
   determination: string;
   webReference: string;
   note: string;
   fL_No: string;
   jECFA_No: string;
   created: Date;
   createdUser: string;
   modified: Date;
   modifyUser: string;
   rowVersion: number;
   jECFAMonograph: JECFAMonograph;
   material: Material;
   reference: Reference;
   typeFoodStatus: TypeFoodStatus;
}


import { EntityBase } from './EntityBase';
import { Material } from './Material';
import { TypeRoute } from './TypeRoute';
import { TypeSpecies } from './TypeSpecies';
import { TypeStudy } from './TypeStudy';
import { TypeNTPStatus } from './TypeNTPStatus';

import { Validator } from 'breeze-client';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

export class NationalToxicologyProgram extends EntityBase {

   /// <code> Place custom code between <code> tags
   
   /// </code>

   // Generated code. Do not place code below this line.
   materialNTPId: number;
   materialId: number;
   sponsor: string;
   contractor: string;
   typeStudyId: string;
   typeRouteId: string;
   typeSpeciesId: string;
   typeNTPStatusId: string;
   comments: string;
   protocol: string;
   results: string;
   reference: string;
   lastReviewDate: Date;
   created: Date;
   createdUser: string;
   modified: Date;
   modifyUser: string;
   rowVersion: number;
   material: Material;
   typeNTPStatus: TypeNTPStatus;
   typeRoute: TypeRoute;
   typeSpecies: TypeSpecies;
   typeStudy: TypeStudy;
}


<div style="margin-top: 15px">
    <div class="col-md-8">
        <div class="form-group">
            <default-table *ngIf="linkedAnalyticalResults">
                <thead>
                    <tr>
                        <td class="casnumber">CAS No.</td>
                        <td class="principalname">Principal Name</td>                        
                        <td>Procedure</td>
                        <td>Observed Result</td>
                        <td>Sample</td>
                        <td>Test Method</td>
                        <td>Updated</td>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let ar of linkedAnalyticalResults">
                        <td><a (click)="goToMaterialAnalyticalResults(ar.material)">{{ar.material.realCASNumber}}</a></td>
                        <td><span [htmlGreek]="ar.material.principalSynonym()?.synonymWordOrWebVersion()"></span></td>
                        <td><span [htmlGreek]="ar.typeAnalyticalProcedure?.analyticalProcedure || '-'"></span></td>
                        <!--<td><div [outerHTML]="ar.observedResult | formatUVFiles"></div></td>-->
                        <td><span [htmlGreek]="ar.observedResult"></span></td>
                        <td class="break-word" style="max-width: 440px;">{{ar.analyticalSample}}</td>
                        <td>{{ar.testMethod}}</td>
                        <td>{{utilFns.formatDate(ar.modified) }}</td>
                    </tr>
                </tbody>
            </default-table>
        </div>
    </div>

    <div class="col-md-4">
        <div class="form-group">
            <button *ngIf="hasUncheckedSpecialLinks()" type="button" class="btn-workflow check-results-button" (click)="onLinkedAnalyticalResultsChecked()">Check Linked Physical Properties</button>
            <p *ngIf="hasCheckedSpecialLinks()" class="workflow-status-text">Linked Physical Properties Checked </p>
        </div>
    </div>
</div>

import { EntityBase } from './EntityBase';

import { Validator } from 'breeze-client';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

export class WorkflowReferenceAssignment extends EntityBase {

   /// <code> Place custom code between <code> tags
   
   /// </code>

   // Generated code. Do not place code below this line.
   referenceId: number;
   briefReference: string;
   title: string;
   precedence: number;
   articleType: string;
   rIFMAsAuthor: boolean;
   restrictedDistribution: boolean;
   assignedContactId: number;
   assignedContactFirstName: string;
}


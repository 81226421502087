import {Component, Input, OnInit} from '@angular/core';
import * as _ from 'lodash';

import {provideParent, UnitOfWork} from '../../services/common';
import {EditPropParent} from '../../controls/common';
import {MaterialExposureSurveyMeasure, TypeCremeVersion, TypeExposureMeasure, TypeExposureMeasureValue} from '../../entities/EntityModels';

@Component({
    selector: 'material-exposure-survey-measure-editor',
    templateUrl: './material-exposure-survey-measure-editor.html',
    providers: [provideParent(MaterialExposureSurveyMeasureEditorComponent, EditPropParent)]
})
export class MaterialExposureSurveyMeasureEditorComponent implements OnInit, EditPropParent {
    @Input() entity: MaterialExposureSurveyMeasure;

    _currentTypeExposureMeasure: TypeExposureMeasure;
    _typeExposureMeasures: TypeExposureMeasure[];
    _typeExposureMeasureValues: TypeExposureMeasureValue[];

    _typeCremeVersions: TypeCremeVersion[];

    constructor(public uow: UnitOfWork) {
    }

    ngOnInit() {
        this.uow.typeExposureMeasureRepository.all().then(m => {
            this._typeExposureMeasures = m;

            if (m == null || m.length < 1) { return; }

            if (!this.entity.entityAspect.entityState.isAdded()) {
                this.setCurrentMeasureType(this.entity.typeExposureMeasure.typeExposureMeasureValueId);
            }
        });

        this.uow.typeExposureMeasureValueRepository.all().then(v => {
            this._typeExposureMeasureValues = v;
        });

        this.uow.typeCremeVersionRepository.all().then(c => {
            this._typeCremeVersions = _.sortBy(c, t => t.cremeVersion).reverse();
        });
    }

    checkIfNull(value: string) {
        if (value == '') { return null; } else { return value; }
    }

    getError(propName: string) {
        if (this.entity == null) {
            return;
        }

        return this.entity.getErrorFor(propName);
    }

    hasQualitativeValue(): boolean {
        if (this._currentTypeExposureMeasure == null || this._currentTypeExposureMeasure.typeExposureMeasureValue == null) { return false; }

        return (this._currentTypeExposureMeasure.typeExposureMeasureValue.measureValueType == 'Qualitative');
    }

    hasQuantitativeValue(): boolean {
        if (this._currentTypeExposureMeasure == null || this._currentTypeExposureMeasure.typeExposureMeasureValue == null) { return false; }

        return (this._currentTypeExposureMeasure.typeExposureMeasureValue.measureValueType == 'Quantitative' || this._currentTypeExposureMeasure.typeExposureMeasureValue.measureValueType == 'Percentage');
    }

    isAdded() {
        return this.entity.entityAspect.entityState.isAdded();
    }

    onSelectMeasure(ix: number) {
        this.setCurrentMeasureType(ix);

        this.entity.typeExposureMeasureId       = this._currentTypeExposureMeasure.typeExposureMeasureId;
        this.entity.typeExposureMeasureValueId  = this._currentTypeExposureMeasure.typeExposureMeasureValueId;
    }

    setCurrentMeasureType(ix: number) {
        const x = this._typeExposureMeasures.filter(t => t.typeExposureMeasureId == ix);
        if (x != null && x.length > 0) {
            this._currentTypeExposureMeasure = x[0];
        }
    }
}

<div class="form-horizontal indent-top-sm">

    <div class="form-group">
        <edit-prop isChild label="Sub-reference" prop="fileReference" szs="1,4">
            <input class="form-control" type="text" [(ngModel)]="entity.fileReference">
        </edit-prop>
        <edit-prop isChild label="Study" prop="typeStudyId" szs="1,4">
            <select class="form-control" [ngModel]="entity.typeStudyId" (change)="entity.typeStudyId=updateStudy($event.target.value)">
                <option disabled selected value>-- None --</option>
                <option *ngFor="let item of _typeStudies" [value]="item.typeStudyId">{{item.studyLong}}</option>
            </select>
        </edit-prop>
    </div>

    <div class="form-group">
        <edit-prop isChild label="Route" prop="typeRouteId" szs="1,4">
            <select class="form-control" [ngModel]="entity.typeRouteId" (change)="entity.typeRouteId=$event.target.value">
                <option disabled selected value>-- None --</option>
                <option *ngFor="let item of _typeRoutes" [value]="item.typeRouteId" [htmlGreek]="item.routeLong"></option>
            </select>
        </edit-prop>
        <edit-prop isChild label="Species" prop="typeSpeciesId" szs="1,4">
            <select class="form-control" [ngModel]="entity.typeSpeciesId" (change)="entity.typeSpeciesId=updateSpecies($event.target.value)">
                <option disabled selected value>-- None --</option>
                <option *ngFor="let item of _typeSpecies" [value]="item.typeSpeciesId" [htmlGreek]="item.speciesLong"></option>
            </select>
        </edit-prop>
    </div>
    
    <div class="form-group">
        <edit-prop isChild label="Compliance" prop="compliance" szs="1,10">
            <input class="form-control" type="text" [(ngModel)]="entity.compliance">
        </edit-prop>
        <button type="button" class="pull-left btn btn-sm btn-success" (click)="onSelectCompliance()">Select</button>
    </div>         
        
    
    <edit-prop label="Protocol" prop="protocol" szs="1,11">
        <textarea class="form-control" rows=9 type="text" [(ngModel)]="entity.protocol"></textarea>
    </edit-prop>
    <edit-prop label="Comment" prop="comment" szs="1,11">
        <textarea class="form-control" rows=1 type="text" [(ngModel)]="entity.comment"></textarea>
    </edit-prop>

    <!--<edit-prop label="Methodology" prop="methodologyReference" szs="1,11">
        <input class="form-control" type="text" [(ngModel)]="entity.methodologyReference">
    </edit-prop>-->

    <edit-prop label="Methodology" prop="methodologyReference" szs="1,11">
        <span *ngFor="let briefRef of entity.getMethodologyBriefRefs()">
                {{briefRef}}
                <a (click)="onDeleteBriefRef(briefRef)">(delete)</a>
                <span>;&nbsp;</span>
        </span>
        <button type="button" class="pull-right btn btn-sm btn-success" (click)="onAddBriefRef()">Add Reference</button>
    </edit-prop>

    <edit-prop label="SA Summary" prop="safetyAssessmentSummary" szs="1,11">
        <textarea class="form-control" rows=3 type="text" [(ngModel)]="entity.safetyAssessmentSummary"></textarea>
    </edit-prop>

    <div class="form-group">
        <edit-prop isChild label="Subjects" szs="1,0">
            <span></span>
        </edit-prop>
        <edit-prop isChild label="Male" prop="subjectsMale" szs="1,2">
            <input class="form-control" type="number" [(ngModel)]="entity.subjectsMale">
        </edit-prop>
        <edit-prop isChild label="Female" prop="subjectsFemale" szs="1,2">
            <input class="form-control" type="number" [(ngModel)]="entity.subjectsFemale">
        </edit-prop>
        <edit-prop isChild label="Unspecified" prop="subjectsUnspecifiedSex" szs="1,2">
            <input class="form-control" type="number" [(ngModel)]="entity.subjectsUnspecifiedSex">
        </edit-prop>
    </div>

    <div class="form-group">
        <edit-prop isChild label="Usefulness" prop="typeUsefulnessId" szs="1,3">
            <select class="form-control" [ngModel]="entity.typeUsefulnessId" (change)="entity.typeUsefulnessId=$event.target.value">
                <option disabled selected value>-- None --</option>
                <option *ngFor="let item of _typeUsefulnesses" [value]="item.typeUsefulnessId">{{item.typeUsefulnessId + ' - ' + item.description}}</option>
            </select>
        </edit-prop>
        <edit-prop isChild label="Study Length" prop="studyLength" szs="1,2">
            <input class="form-control" type="number" [(ngModel)]="entity.studyLength">
        </edit-prop>
        <edit-prop isChild label="Time Unit" prop="typeTimeUnitId" szs="1,2">
            <select class="form-control" [ngModel]="entity.typeTimeUnitId" (change)="entity.typeTimeUnitId=$event.target.value">
                <option disabled selected value>-- None --</option>
                <option *ngFor="let item of _typeTimeUnits" [value]="item.typeTimeUnitId">{{item.typeTimeUnitId}}</option>
            </select>
        </edit-prop>
    </div>

    <div class="form-group">
        <edit-prop isChild label="GLP" prop="gLP" szs="1,1">
            <input class="form-control" type="checkbox" [(ngModel)]="entity.gLP">
        </edit-prop>
        <edit-prop isChild label="Human Health Data" prop="humanHealthData" szs="2,1">
            <input class="form-control" type="checkbox" [(ngModel)]="entity.humanHealthData">
        </edit-prop>
        <edit-prop isChild label="Environmental Data" prop="environmentalData" szs="2,1">
            <input class="form-control" type="checkbox" [(ngModel)]="entity.environmentalData">
        </edit-prop>
    </div>
</div>

<reference-selector></reference-selector>
<compliance-selector></compliance-selector>

import { Component, Input, OnInit } from '@angular/core';
import { REACHRegistration, TypeREACHTonnageBand } from '../../entities/EntityModels';
import { provideParent, UnitOfWork } from '../../services/common';
import { EditPropParent } from '../../controls/common';

@Component({
    selector: 'reach-registration',
    templateUrl: './reach-registration.html',
    providers: [provideParent(REACHRegistrationComponent, EditPropParent)]
})
export class REACHRegistrationComponent implements OnInit {

    @Input() entity: REACHRegistration;
    _types: TypeREACHTonnageBand[];

    constructor(public _uow: UnitOfWork) {
    }

    ngOnInit() {
        this._uow.typeREACHTonnageBandRepository.all().then(r => {
            this._types = r;
        });
    }

    getError(propName: string) {
        return this.entity.getErrorFor(propName);
    }
}

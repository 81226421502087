import { Component, OnInit, Input} from '@angular/core';
import { Location } from '@angular/common';

import { UnitOfWork, UserManager, StateMap, SearchService } from '../../services/common';
import { UtilFns, LocationFns, } from '../../utils/common';
import { PageState } from '../../controls/common';

import { RIFMDownloadableFile } from  '../../entities/EntityModels';
import {HttpClient, HttpHeaders, HTTP_INTERCEPTORS} from '@angular/common/http';
import { HOME_ROUTES} from './home.routes';
import {Observable} from 'rxjs';

@Component({
    selector: 'environmental-data',
    templateUrl: './environmental-data.html',
})
export class EnvironmentalDataComponent implements OnInit {

    _documentManagementUrl: string;
    _envDataFiles: RIFMDownloadableFile[] = [];

    _isLoading = false;
    _validationMessage: string;

    constructor(public _stateMap: StateMap, private _httpClient: HttpClient, public _uow: UnitOfWork, public _userManager: UserManager) {

    }

    ngOnInit() {
        this._stateMap.currentRouteName = HOME_ROUTES.EnvironmentalData.name;

        this._documentManagementUrl = this._stateMap.documentManagementBaseUrl  + '/RIFMDownloadableFile';

        this.loadData();
    }

    canDeactivate() {
        return (!this._uow.hasChanges());
    }

    public getDocumentManagementUrl(): Promise<any> {
        return this._uow.fetch('DocumentManagement/DocumentManagementURL', {}).then(dm => {
            return dm[0];
        });
    }

    loadData() {
        this._isLoading = true;

        const params = { category: 'EnvironmentalData'};

        this._envDataFiles = [];

        return this._uow.fetch('DocumentManagement/FetchRIFMDownloadableFilesByFileCategory', params).then(r => {
            if (r) {
                this._envDataFiles = <RIFMDownloadableFile[]>r;
            }
            this._isLoading = false;
        });
    }

    downloadFile(dfs: RIFMDownloadableFile) {
        if (dfs == null) {
            return ;
        }

        this.exportEnvironmentalFile(dfs.fileName, dfs.fileSubFolder);
    }

    // ************************************************************************
    // document management web api url
    // ************************************************************************
    getDownloadFileURL(fileName: string, subFolder: string): Observable<any> {
        let url: string;
        url = this._documentManagementUrl + '/GetFileBySubFolderAndName?fileName=' + fileName + '&subFolder=' + subFolder ;

        return this._httpClient.get<any>(url, { responseType: 'blob' as 'json'});
    }

    public exportEnvironmentalFile(fileName: string, subFolder: string) {
        this._isLoading = true;
        this._validationMessage = '';
        this.getDownloadFileURL(fileName, subFolder)
            .subscribe(
                (response: Blob) => {
                    const data = response;

                    const file = new Blob([data], { type: 'application/pdf' });
                    const fileURL = URL.createObjectURL(file);

                    // window.open(fileURL);
                    const a         = document.createElement('a');
                    a.href        = fileURL;
                    a.target      = '_blank';
                    a.download    = fileName;
                    document.body.appendChild(a);
                    a.click();

                    this._validationMessage = 'File download successful.';

                },
                (error) => {
                    console.error('Request failed with error: ' + error.message);
                    this._validationMessage = error.message;
                    this._isLoading = false;
                },
                () => {
                    this._isLoading = false;
                });
    }
}

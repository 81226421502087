import { EntityBase } from './EntityBase';
import { Material } from './Material';
import { TypeEPISuiteParameter } from './TypeEPISuiteParameter';

import { Validator } from 'breeze-client';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

export class EPISuite extends EntityBase {

   /// <code> Place custom code between <code> tags
   
   /// </code>

   // Generated code. Do not place code below this line.
   ePISuiteId: number;
   materialId: number;
   typeColumnLabelId: string;
   ePIValue: string;
   rowVersion: number;
   material: Material;
   typeEpiSuiteParameter: TypeEPISuiteParameter;
}


<div class="modal" id="workflow-reference-assignments-dialog" role="dialog">
    <div class="modal-dialog modal-lg workflow-reference-assignments-modal">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                <div class="nav navbar-left navbar-form">
                    <label class="bold input-lg" style="padding-left: 8px;">Workflow Reference Assignments</label>
                    <button class="btn btn-primary navbar-btn" style="margin-left: 12px;" type="button" (click)="refreshData()">Refresh Data</button>
                    <button class="btn btn-primary navbar-btn" type="button" (click)="onSelectReferenceAssignment(null)">Close</button>
                </div>
            </div>
            <div class="reference-assignments-filters-container">
                <div style="margin-left: 32px;">
                    <h4 *ngIf="_isTypeDataLoading" class="bold inline italic">(Still Loading Filter Settings...)</h4>
                </div>
                <div class="flex-container" style="background-color: transparent;">
                    <div class="flex-item">
                        <span class="bold">Pending Action:</span>
                        <select class="dropdown-toggle list-background-white workflow-list" [ngModel]="_filterToCompleteDescription" (change)="filterResults($event)">
                            <option *ngFor="let action of _workflowPendingActionTypes; let ix = index" class="dropdown" [value]="action">{{action}}</option>
                        </select>
                    </div>
                </div>
            </div>

            <div class="modal-body">

                <div class="row workflow-reference-assignments-container">
                    <div style="margin-left: 32px;">
                        <h4 *ngIf="_isPendingActionDataLoading" class="bold inline italic">(Still Loading Reference Assignments for Selected Pending Action...)</h4>
                    </div>
                    <div style="margin-left: 32px;">
                        <h4>{{_filterSettingsDescription}}</h4>
                    </div>

                    <div *ngIf="hasData()">
                        <table class="table table-striped table-bordered table-condensed small center-element" style="width:94.5%;" [attr.data-hidden]="_isCollapsed">
                            <thead>
                                <tr>
                                    <td>Reference</td>
                                    <td>Brief Reference</td>
                                    <td>Type</td>
                                    <td>RIFM/FEMA</td>
                                    <td>Priority</td>
                                    <td>Sent To</td>
                                    <td>Title</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let ref of _workflowPendingActions">
                                    <td class="vertical-top"><a class="bold" (click)="onSelectReferenceAssignment(ref)">{{ref.referenceId}}</a></td>
                                    <td class="vertical-top">{{ref.briefReference}}</td>
                                    <td class="vertical-top"> <span *ngIf="ref.rIFMAsAuthor" class="rifm-article">R</span> <span *ngIf="ref.restrictedDistribution" class="member-article">M</span></td>
                                    <td class="vertical-top"><span class="text-center">{{ref.articleType}}</span></td>
                                    <td class="vertical-top">{{ref.precedence}}</td>
                                    <td class="vertical-top" width="70px">{{ref.workflowContactFirstName}}</td>
                                    <td class="vertical-top">
                                        <span class="ref-title-wrap">{{ref.title}}</span>
                                        <span *ngIf="ref.holdReason" class="ref-title-wrap">{{"Hold Reason: " + ref.holdReason}}</span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="row" style="margin-left:42px; margin-bottom: 20px;" *ngIf="hasPages()">
                        <button *ngIf="_hasNextPages" type="button" class="btn btn-sm btn-primary" (click)="nextPage()">Next Page</button>
                        <button *ngIf="_hasPreviousPages" type="button" class="btn btn-sm btn-primary" (click)="previousPage()">Previous Page</button>
                        <span class="bold inline">{{"Page " + _searchParams.pageNumber + " of " + _pages.length}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div  style="width:100%;">
    <div class="document-search-grid-container">
        <!-- Row 1 -->
        <span class="bold item-search-title">
            <span class="bold">Search Reference Documents</span>
            <img style="margin-left: 10px;" src="assets/images/Search.png" height="24px"/>
            <span class="bold" style="margin-left: 8px;">Or</span>
        </span>
        <button class="btn-select item-recentlymodified-button" style="margin-top: -4px; width: 160px;" [ngStyle]="{ 'background-color': filterButtonColor('recentlymodified') }" (click)="onSelectFilterType('recentlymodified')">Show Recently Modified</button>

        <!-- Row 2 -->
        <span class="bold document-selector-toggle-text document-filter-text-padding inline item-find-by">Filter By:</span>
        <button class="btn-select item-referenceid-button" [ngStyle]="{ 'background-color': filterButtonColor('reference') }" (click)="onSelectFilterType('reference')">Reference</button>
        <button class="btn-select item-foldername-button" [ngStyle]="{ 'background-color': filterButtonColor('folder') }" (click)="onSelectFilterType('folder')">Folder</button>

        <!-- Row 3 -->
        <span *ngIf="showSearchElements" class="bold document-selector-toggle-text display-block document-filter-text-padding item-searchterm-label">Search Terms:</span>
        <input *ngIf="showSearchElements" class="item-search-input background-white" type="text" [(ngModel)]="_searchTerm">
        <button *ngIf="showSearchElements" class="btn btn-primary navbar-btn item-search-button" type="button" (click)="search()" [disabled]="!canSearch()">Search</button>

        <!-- Row 4 -->
        <span class="item-search-message italic left-indent">{{searchTypeMessage}}</span>

        <!-- Row 5 -->
        <span class="alert-message item-error-message">{{_searchErrorMessage}}</span>

    </div>

    <div class="inline">
        <span class="bold page-subtitle">Search Results</span>
    </div>

    <div *ngIf="searchType == filterSearchType.REFERENCEID">
        <div *ngIf="_rifmDocumentsEx != null && _rifmDocumentsEx.length > 0">
            <table class="table table-striped table-hover table-bordered table-condensed" style="width: auto;">
                <thead>
                <tr>
                    <td class="centered" style="width: 65px;">Ref Id</td>
                    <td class="text-left" style="width: 160px;">File Name</td>
                    <td class="centered" style="width: 90px;">Brief Reference</td>
                    <td class="text-left" style="width: 220px;">Folder Name</td>
                    <td class="centered" style="width: 70px;">View</td>
                    <td class="centered" style="width: 70px;">Download</td>
                    <td class="centered" style="width: 70px;">Edit</td>
                    <td  class="centered"style="width: 140px;">Copy/Add/Delete Pages</td>
                    <td  class="centered"style="width: 120px;">Delete File</td>
                    <td  class="centered"style="width: 70px;">Replace File</td>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let rd of _rifmDocumentsEx">
                    <td class="centered"><a class="bold" (click)="onNavToRef(rd)">{{rd.data.referenceId}}</a></td>
                    <td>{{rd.data.fileName}}</td>
                    <td>{{rd.data.briefReference}}</td>
                    <td class="break-word">{{rd.data.folderName}}</td>
                    <td>
                        <a *ngIf="rd.data.fileName" (click)="onView(rd)">
                            <img class="centered-image" src="assets/images/ViewPDF.png" width="24"/>
                        </a>
                    </td>
                    <td>
                        <a *ngIf="rd.data.fileName" (click)="onExport(rd)">
                            <img class="centered-image" src="assets/images/ArrowVerticalDown.png" width="18"/>
                        </a>
                    </td>
                    <td class="centered"><a *ngIf="rd.data.fileName" (click)="onEdit(rd)">Edit PDF</a></td>
                    <td class="centered">
                        <a *ngIf="rd.data.fileName" style="color: #B0171F;" (click)="onEditPDFPages(rd)">Copy/Add/Delete Pages</a></td>
                    <td>
                        <a *ngIf="rd.data.fileName" (click)="onDeleteFile(rd)">
                            <img class="centered-image" src="assets/images/DeletePDF.png" width="24"/>
                        </a>
                    </td>
                    <td>
                        <a (click)="onReplace(rd)">
                            <img *ngIf="rd.data.fileName != null && rd.data.fileName.length > 0" class="centered-image" src="assets/images/ReplacePDF.png" width="24"/>
                            <img *ngIf="rd.data.fileName == null || rd.data.fileName.length == 0" class="centered-image" src="assets/images/AddPDF.png" width="24"/>
                        </a>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>

    </div>

   <div *ngIf="searchType == filterSearchType.FOLDER" class="item-document-search-results">
        <div *ngIf="_foldersAndFiles != null && _foldersAndFiles.length > 0">

            <div *ngFor="let folder of _foldersAndFiles; let ix=index">
                <div class="panel-body">
                    <div class="text-center bold reference-document-folder-container">
                        <div class="flex-container" style="width: auto;">
                            <div class="flex-item">
                                <img style="height: 24px; width: 24px;" class="inline" src="../assets/images/folder.png" />
                            </div>
                            <div class="flex-item">
                                <span class="bold">{{folder.folderName}}</span>
                            </div>
                            <div class="flex-item">
                                <span> File Count: </span>
                                <span>{{folder.referenceDocumentsEx.length}}</span>
                            </div>
                            <div class="flex-item">
                                <a (click)="foldersAndFilesCollapse(ix)">
                                    <span *ngIf="folder.collapsed" class="fa fa-caret-down" style="color: #000000"></span>
                                    <span *ngIf="!folder.collapsed" class="fa fa-caret-up" style="color: #000000"></span>
                                </a>
                            </div>
                        </div>
                    </div>
                    <table [attr.data-hidden]="folder.collapsed" class="table table-striped table-hover table-bordered table-condensed background-white" style="width: auto;">
                        <tbody>
                        <tr *ngFor="let d of folder.referenceDocumentsEx">
                            <td class="centered bold"><a (click)="onNavToRef(d)">{{d.data.referenceId}}</a></td>
                            <td class="text-center" style="width: 140px;">{{d.data.fileName}}</td>
                            <td>{{d.data.briefReference}}</td>
                            <td class="break-word">{{d.data.folderName}}</td>
                            <td>
                                <a *ngIf="d.data.fileName" (click)="onView(d)">
                                    <img class="centered-image" src="assets/images/ViewPDF.png" width="24"/>
                                </a>
                            </td>
                            <td>
                                <a *ngIf="d.data.fileName" (click)="onExport(d)">
                                    <img class="centered-image" src="assets/images/ArrowVerticalDown.png" width="18"/>
                                </a>
                            </td>
                            <td class="centered"><a *ngIf="d.data.fileName" (click)="onEdit(d)">Edit PDF</a></td>
                            <td class="centered"><a *ngIf="d.data.fileName" style="color: #B0171F;" (click)="onEditPDFPages(d)">Copy/Add/Delete Pages</a></td>
                            <td>
                                <a *ngIf="d.data.fileName" (click)="onDeleteFile(d)">
                                    <img class="centered-image" src="assets/images/DeletePDF.png" width="24"/>
                                </a>
                            </td>
                            <td>
                                <a (click)="onReplace(d)">
                                    <img *ngIf="d.data.fileName != null && d.data.fileName.length > 0" class="centered-image" src="assets/images/ReplacePDF.png" width="24"/>
                                    <img *ngIf="d.data.fileName == null || d.data.fileName.length == 0" class="centered-image" src="assets/images/AddPDF.png" width="24"/>
                                </a>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="searchType == filterSearchType.RECENTLYMODIFIED">
        <div *ngIf="_recentRIFMDocumentsEx != null && _recentRIFMDocumentsEx.length > 0" class="background-white">
            <table class="table table-striped table-hover table-bordered table-condensed" style="width: auto;">
                <thead>
                <tr>
                    <td class="centered" style="width: 65px;">Ref Id</td>
                    <td class="text-left" style="width: 160px;">File Name</td>
                    <td class="centered" style="width: 110px;">Last Change Date</td>
                    <td class="centered" style="width: 160px;">Changed By</td>
                    <td class="centered" style="width: 70px;">View</td>
                    <td class="centered" style="width: 70px;">Download</td>
                    <td class="centered" style="width: 70px;">Edit</td>
                    <td  class="centered"style="width: 120px;">Copy/Add/Delete Pages</td>
                    <td  class="centered"style="width: 120px;">Delete File</td>
                    <td  class="centered"style="width: 70px;">Replace File</td>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let rd of _recentRIFMDocumentsEx">
                    <td class="centered"><a class="centered" (click)="onNavToRef(rd)">{{rd.data.referenceId}}</a></td>
                    <td class="text-left">{{rd.data.fileName}}</td>
                    <td class="centered">{{_stateMap.utilFns.formatDate(rd.data.modified)}}</td>
                    <td class="text-left">{{rd.data.modifyUser}}</td>
                    <td>
                        <a *ngIf="rd.data.fileName" (click)="onView(rd)">
                            <img class="centered-image" src="assets/images/ViewPDF.png" width="24"/>
                        </a>
                    </td>
                    <td>
                        <a *ngIf="rd.data.fileName" (click)="onExport(rd)">
                            <img class="centered-image" src="assets/images/ArrowVerticalDown.png" width="18"/>
                        </a>
                    </td>
                    <td class="centered"><a *ngIf="rd.data.fileName" (click)="onEdit(rd)">Edit PDF</a></td>
                    <td class="centered"><a *ngIf="rd.data.fileName" style="color: #B0171F;" (click)="onEditPDFPages(rd)">Copy/Add/Delete Pages</a></td>
                    <td>
                        <a *ngIf="rd.data.fileName" (click)="onDeleteFile(rd)">
                            <img class="centered-image" src="assets/images/DeletePDF.png" width="24"/>
                        </a>
                    </td>
                    <td>
                        <a (click)="onReplace(rd)">
                            <img *ngIf="rd.data.fileName != null && rd.data.fileName.length > 0" class="centered-image" src="assets/images/ReplacePDF.png" width="24"/>
                            <img *ngIf="rd.data.fileName == null || rd.data.fileName.length == 0" class="centered-image" src="assets/images/AddPDF.png" width="24"/>
                        </a>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>

    </div>

</div>

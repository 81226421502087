import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import * as _ from 'lodash';

import {EditorService, ReferenceFinishState, ReferenceState, StateMap, UnitOfWork, UserManager, WorkflowEmailService} from '../../services/common';
import { EditManager, LocationFns, UtilFns } from '../../utils/common';
import { PageState, TabContainer } from '../../controls/common';

import {DocUniqReference, Project, Reference, ReferenceRelation} from '../../entities/EntityModels';
import { ShowAndDownloadPDFComponent } from '../docmanagement/show-and-download-pdf.component';

import { ROUTES } from '../routes';
import { STAFF_ROUTES } from './staff.routes';

import { ReferenceSelectorComponent } from '../reference/reference-selector.component';
import { ReferenceResultsComponent } from '../reference/reference-results.component';
import { ReferenceEditorBaseComponent } from './reference-editor-base.component';
import { WorkflowReferenceAssignmentsComponent } from './workflow-reference-assignments.component';

export class ReferenceEditData {
    edm: EditManager<Reference>;
    entity: Reference;
    adding: boolean;
    inSubEditor: boolean;
    otherErrorMap: { [key: string]: string } = {};
    onCancelMap: { [key: string]: () => void } = {};
    projects: Project[];
}

class PageStateExt extends PageState {
    // referenceId: number;
}

@Component({
    selector: 'reference-editor',
    templateUrl: './reference-editor.html',
})
export class ReferenceEditorComponent implements OnInit, AfterViewInit {
    @ViewChild(TabContainer) _tabContainer: TabContainer;
    @ViewChild(ReferenceSelectorComponent, { static: true }) _referenceSelectorComponent: ReferenceSelectorComponent;
    @ViewChild(ReferenceResultsComponent) _referenceResultsComponent: ReferenceResultsComponent;
    @ViewChild(ReferenceEditorBaseComponent) _referenceEditorBaseComponent: ReferenceEditorBaseComponent;
    @ViewChild(WorkflowReferenceAssignmentsComponent) _workflowReferenceAssignmentsComponent: WorkflowReferenceAssignmentsComponent;
    @ViewChild(ShowAndDownloadPDFComponent, { static: true }) _showAndDownloadPDFComponent: ShowAndDownloadPDFComponent;

    data: ReferenceEditData = new ReferenceEditData();
    _referenceObservations: {
        minReferenceId: number,
        maxReferenceId: number;
    };

    _tab: number;
    _edm: EditManager<Reference>;

    _hasReferenceDocumentFile = false;
    _referenceFileName = '';

    // this is not the same pageState that the _edm is using.
    _pageState = new PageStateExt('Reference editor');

    _finishedMessage: string;

    constructor(public _uow: UnitOfWork, public _stateMap: StateMap, public _editorService: EditorService,
                private _location: Location, private _router: Router,
                private _referenceState: ReferenceState, private _workflowEmailService: WorkflowEmailService,
                private route: ActivatedRoute) {
    }

    ngOnInit() {
        this._stateMap.currentRouteName = STAFF_ROUTES.Reference.name;
        this._tab = LocationFns.getTab(this._location) || 0;

        this._edm = this._editorService.createEditManager<Reference>(this, 'Reference');
        this.data.edm = this._edm;

        // this.fetchMaxReferenceId();

        this.setReferenceMinAndMax();

        this._uow.projectRepository.all().then(r => {
            this.data.projects = _.sortBy(r, p => p.projectName);
        });

        this.route.params
            .subscribe(params => {
                if (params['referenceId']) {
                    return this.fetchThenEditReference(params['referenceId'])
                        .then(() => {
                            this.fetchReferenceDocumentFileStatus();
                            if (this._referenceEditorBaseComponent) {
                                this.resetPageDisplay();
                            }
                        });
                } else {
                    this.data.edm.editing = false;
                }
            });
    }

    canDeactivateEditor() {
        return this.data.inSubEditor != true;
    }

    ngAfterViewInit() {
        this.focusInitialTab();
    }

    focusInitialTab() {
        setTimeout(() => {
            if (this._tabContainer) {
                this._tabContainer.selectTab(this._tab);
            }
        }, 1);
    }

    canDeactivate() {
        return !this.data.edm.hasEdits();
    }

    canShowBack() {
        return !this.data.edm.hasEdits();
    }

    onSelectReference() {
        UtilFns.showModal(this._referenceSelectorComponent, this, null).then(rli => {
            if (rli == null) {
                return;
            }
            return this._router.navigate(['/staff/reference/', rli.referenceId, 'info']);
        });
    }

    onSelectAssignedReference() {
        UtilFns.showModal(this._workflowReferenceAssignmentsComponent, this, null).then(ra => {
            if (ra == null) {
                return;
            }
            return this._router.navigate(['/staff/reference/', ra.referenceId, 'info']);
        });
    }

    onMore() {
        this._router.navigate(UtilFns.asRouterParts(ROUTES.Reference, this.data.entity.referenceId));
    }

    onNext() {
        const params = { referenceId: this.data.entity.referenceId };
        this._uow.fetch('References/NextReferenceId', params)
            .then(r => {
                if (r && r > 0) {
                    const refId = r[0];
                    return this._router.navigate(['/staff/reference/', refId, 'info']);
                }
            });
    }

    onPrev() {
        const params = { referenceId: this.data.entity.referenceId };
        this._uow.fetch('References/PreviousReferenceId', params)
            .then(r => {
                if (r && r > 0) {
                    const refId = r[0];
                    return this._router.navigate(['/staff/reference/', refId, 'info']);
                }
            });
    }

    canShowPrev(): boolean {
        if (this.data.edm.hasEdits()) {
            return false;
        }
        return ((this._referenceObservations) && (this.data.entity.referenceId > this._referenceObservations.minReferenceId));
    }

    canShowNext(): boolean {
        if (this.data.edm.hasEdits()) {
            return false;
        }
        return ((this._referenceObservations) && (this.data.entity.referenceId < this._referenceObservations.maxReferenceId));
    }

    isFEMA() {
        // FEMA role members can only edit Protocol data
        return (this._stateMap.currentUser.isInRole('fema'));
    }

    isReady() {
        return (this.data.projects != null);
        // && (this.data.maxReferenceId != null);
    }

    fetchReferenceDocumentFileStatus() {
        this._hasReferenceDocumentFile = false;

        if (this.data.entity == null) {
            return false;
        }

        return this._uow.fetch('DocumentManagement/RIFMDocumentByReferenceId', {referenceId: this.data.entity.referenceId}).then(rd => {
            const doc = (rd && rd.length) ? rd[0] : null;
            if (doc != null) {
                this._referenceFileName         = doc.fileName;
                this._hasReferenceDocumentFile  =  (doc.fileName != null && doc.fileName.length > 0);
            }
        });
    }

    fetchThenEditReference(referenceId: number) {
        let reference: Reference;
        this._pageState.isLoading = true;

        return this._uow.fetch('References/GetForEdit', { referenceId: referenceId }).then(refs => {
            reference = (refs && refs.length) ? refs[0] : null;
            if (reference) {
                this.editReference(reference);
                this._stateMap.currentReference = reference;
            }
            this._pageState.isLoaded = true;
            return reference;
        });
    }

    editReference(r: Reference) {
        if (r == null) {
            return;
        }

        const data = this.data;
        data.adding = false;
        data.entity = r;
        data.edm.onlyEntity = r;
        data.edm.editing = true;
        this.focusInitialTab();
        return r;
    }

    createReference() {
        const params = {};

        const reference = this._uow.referenceFactory.create(params);
        reference.typeKlimischId = 'None';
        reference.precedence = 1; // Default changed September, 2017
        reference.publicationTypeId = 0; // None
        reference.typeSearchServiceId = 'Not Sent';
        return reference;
    }

    // EditManager overrides

    canAdd() {
        return ((this._stateMap.currentUser.isStaff) && (this._stateMap.currentUser.isInRole('fema') == false));
    }

    onAdd() {
        this._finishedMessage = '';

        const data = this.data;
        data.adding = true;
        data.entity = this.createReference();
        this._stateMap.currentReference = null;

        // next line is only really needed when a reference is added immediately after another
        // reference is saved because this error would normally have been triggered by
        // loading the 'base' editor which will have already occurred.
        // this.data.otherErrorMap['abstract'] = "An Abstract is required.";
        data.edm.onlyEntity = data.entity;
        data.edm.editing = true;

        if (this._referenceEditorBaseComponent) {
            this._referenceEditorBaseComponent.cancelAddHold();
        }

        this.focusInitialTab();
    }

    canSave() {
        const data = this.data;
        if (!data.edm.hasEdits()) {
            return false;
        }
        if (!data.edm.validateBeforeSave()) {
            return false;
        }
        const errMap = data.otherErrorMap;
        for (const key in errMap) {
            if (errMap[key]) {
                return false;
            }
        }
        return true;
    }

    onSave() {
        if (!this.canSave()) {
            return Promise.resolve();
        }

        this._finishedMessage = '';
        this.recordWorkflowChanges();

        // Check if the modifications change the Reference state to Finished
        // If the finished state has changed, record this workflow action
        // The workflow transition mappings won't match the finished property change event so the action won't be recorded twice.
        return this._referenceState.isReferenceFinished(this.data.entity).then(f => {
            const finished: boolean = (f != ReferenceFinishState.MissingData);
            if (finished != this.data.entity.finished) {
                this._finishedMessage = (finished) ?
                    'The Reference is now marked complete, you may need to Save again to confirm.' :
                    'The Reference has been Unfinished as a result of these edits.';
                this.data.entity.finished = finished;
                this._referenceState.recordReferenceFinishedStateChange(this.data.entity);
            }

            return this.data.edm.commitSave()
                .then(() => {
                    if (this.data.adding) {
                        return this._router.navigate(['/staff/reference/', this.data.entity.referenceId, 'info']);
                    }

                    const reference = this.data.entity;
                    this.editReference(reference);
                    if (this._referenceEditorBaseComponent) {
                        this._referenceEditorBaseComponent.resetSelections();
                        this._referenceEditorBaseComponent.refreshWorkflowHistory();
                    }

                    if (this.data.entity.referenceId > this._referenceObservations.maxReferenceId) {
                        this.setReferenceMinAndMax();
                    }
                });

        });
    }

    onDelete() {
        return this._stateMap.yesNoComponent.showModal('Delete entire reference',
            'Are you sure?').then(ok => {
            if (ok) {
                this.deleteReference();
            }
        });
    }

    onCancel() {
        const data = this.data;
        const hadEdits = data.edm.hasEdits();
        this._uow.rollback();
        data.edm.clearErrors();
        data.edm.pageState.loadStatusMsg = null;

        // tslint:disable-next-line:forin
        for (const k in data.onCancelMap) {
            data.onCancelMap[k]();
        }

        // if in edit mode (not adding and already some edits then cancel will just rollback the edits)
        if (data.adding || !hadEdits) {
            data.edm.editing = false;
        }
    }

    deleteReference() {
        // TODO: check that all entries in related tables are deleted.
        // this is done on the db with a cascade delete.
        const data = this.data;

        const params = { referenceId: data.entity.referenceId };
        return this._uow.fetch('References/AllReferenceRelations', params).then(r => {
            const rrs: ReferenceRelation[] = r;
            rrs.forEach(rr => rr.entityAspect.setDeleted());
            return this._uow.commitSelected(rrs);
        }).then(() => {
            data.edm.onDeleteCore();
            return this._uow.commitSelected([data.entity]);
        }).then(() => {
            data.edm.setSavedStatus('Deleted');
            // Next line is needed because the onDeleteCore above will have modified all
            // entities in cache that ref'd this material.
            this._uow.rollback();
        }).catch((e) => {
            data.edm.setSaveFailedStatus('Delete failed: ' + e);
            throw e;
        });
    }

    resetPageDisplay() {
        this._referenceEditorBaseComponent.resetSelections();
        this._referenceEditorBaseComponent.refreshWorkflowHistory();
        this._finishedMessage = '';
    }

    setReferenceMinAndMax() {
        return this._uow.fetch('References/ReferenceIdMinAndMax', {}).then(r => {
            this._referenceObservations = r[0];
        });
    }

    // Workflow related logic
    private recordWorkflowChanges() {
        const reference = this.data.entity;

        // First record specific changes tracked for the Reference then for the related tables of interest
        if (reference.entityAspect.entityState.isAdded() || reference.entityAspect.entityState.isModified()) {
            this._referenceState.recordEntityChange(reference, reference);
        }

        // Abstract
        const abstract = reference.abstract;
        if (abstract != null && abstract.entityAspect.entityState.isAdded()) {
            this._referenceState.recordEntityChange(abstract, reference, String(abstract.referenceId)); // Not every table has a primary key defined as an int
        }

        // Special Link
        if (reference.specialLinks != null && reference.specialLinks.length > 0) {
            const addedlinks = reference.specialLinks.filter(s => s.entityAspect.entityState.isAdded());
            if (addedlinks != null && addedlinks.length > 0 && abstract != null) {
                this._referenceState.recordEntityChange(addedlinks[0], reference, String(abstract.referenceId)); // Only needs to be recorded once, not for every added special link
            }
        }

        // SentTo, Notify
        this._workflowEmailService.sendEmails(reference);  // If any staff notifications are in the list they will be sent now that the user has saved their changes.

    }

    public referenceFinishedStatesHaveChanged(): boolean {
        return (this._finishedMessage && this._finishedMessage.length > 0);
    }

    canDownloadReferenceDocument(): boolean {

        if (this._stateMap.currentUser.isStaff || this._stateMap.currentUser.isInRole('fema')) {
            return true;
        }

        if (this.data.entity) {
            if (this._stateMap.currentUser.isInRole('iff') && (this.matchDocumentToRole('iff') || this.matchDocumentToRole('bba'))) {
                return true;
            }
        }

        return false;
    }

    canEditReferenceDocument() {
        if (this._stateMap.currentUser.isStaff) {
            if (this._stateMap.currentUser.isInRole('consultant')) {
                return false;
            } else {
                return true;
            }
        }
        return false;
    }

    matchDocumentToRole(role: string) {
        if (this.data.entity.briefReference.length < role.length) {
            return false;
        }

        return (this.data.entity.briefReference.substring(0, role.length).toUpperCase() == role.toUpperCase());
    }

    showReferenceDocument() {
        this._showAndDownloadPDFComponent.fileName = this._referenceFileName;
        UtilFns.showModal(this._showAndDownloadPDFComponent, this).then(m => {
            return;
        });
    }

    onNavToReferenceDocument() {
        this._stateMap.documentManagementReferenceId = this.data.entity.referenceId;
        this._router.navigate(UtilFns.asRouterParts(ROUTES.DocumentManagement, ROUTES.DocumentManagement.childRoutes.RIFMDocuments));
    }
}

<div class="indent-left-md">

    <div class="home-page-title">Introduction to the Fragrance and Flavors Database
        <span style="margin-right: 30px; font-size: 12px"> - Version {{ _stateMap.versionNo }}</span>
    </div>

    <div>
        <!--<video style="width:100%; max-width:750px; height:auto; margin-right: 30px" controls poster="app/images/screen-capture.png">-->
        <video width="500" height="500" class="intro-video" controls poster="assets/images/transparent-1x1.png">
            <!--Not using RIFM-Intro-original because it doesn't play on chrome - this version was processed from the original 
                'Handbrake' to create a web optimized mp4 that works in all browsers. -->
            <source src="assets/images/RIFM-Intro.mp4" type="video/mp4">
            <!--<source src="https://archive.org/download/WebmVp8Vorbis/webmvp8_512kb.mp4" type="video/mp4">-->
            <!--<source src="https://archive.org/download/WebmVp8Vorbis/webmvp8.ogv" type="video/ogg">
            <source src="https://archive.org/download/WebmVp8Vorbis/webmvp8.webm" type="video/webm">-->
            Your browser doesn't support HTML5 video tag.
        </video>

        <div class="panel panel-default" style="margin-right: 30px">
            <div class="panel-body">
                <div class="bold">
                    Welcome to the new RIFM Database! If you were unable to attend one of the webinars that RIFM offered, please take a few minutes
                    to view the recorded Database demonstration to get familiar with navigating the new Database.
                </div>
                <br/>
                <div> The RIFM Database is the most comprehensive, worldwide source of toxicology data, literature and general
                    information on fragrance and flavor raw materials. It currently contains over 65,000 references, including
                    over 100,000 individual human health and environmental studies. The Database contains basic material
                    information on over 5,000 flavor and fragrance materials such as: CAS numbers, synonyms, structures,
                    molecular formulas and weights, physical properties and material relationships such as isomers and metabolites.
                    Regulatory information such as EINECS, TSCA and DSL registrations, C/M/R classifications, IARC status,
                    FDA GRAS status and California/EPA listings are also included for many materials. IFRA Standards, UV
                    spectra, RIFM published monographs and group summaries can also be downloaded from the material pages
                    in the Database.</div>

                <br/>
                <div> The Database can be searched in a variety of ways; by individual materials, or by material groups such as
                    structure activity groups, e.g. 'Alcohols Terpene Cyclic'. Individual reports or published papers can
                    also be found by searching an author's name. Subscribers can also download a material synopsis on individual
                    materials in the database that summarizes physical data, labeling, and toxicological data for each material.</div>

                <br/>
                <div> Sources of data include RIFM sponsored studies, member company sponsored studies, government reports, and
                    current literature. The amount and type of data that are available for each material depends on the number
                    of studies that have been conducted for each material and the number of papers that have been published
                    for each material. RIFM staff monitors the literature, indexes and abstracts and summarizes the data,
                    which are added to the Database. The Database is updated and maintained on a daily basis by RIFM staff.</div>

                <br/>
                <div> If you have any questions on Database content for fragrances, please contact Christen Sachse-Vasquez, csachse-vasquez@rifm.org.
                    For Database content for flavors please contact Ms. Christie Harman, charman@vertosolutions.net.</div>
            </div>
        </div>


    </div>
</div>

import { Directive, ElementRef, Input, OnChanges } from '@angular/core';

// Example:
// <div class="h1-sm bold bg-info" [chemFormula]="" ></div>
@Directive({
    selector: '[chemFormula]',
})
export class ChemFormulaDirective implements OnChanges {
    @Input() chemFormula: string;

    constructor(public _el: ElementRef) {
    }

    ngOnChanges() {
        const newVal = this.translateToUseSubscripts(this.chemFormula);
        this._el.nativeElement.innerHTML = newVal;

    }

    translateToUseSubscripts(chemFormula: string) {
        if (chemFormula == null || chemFormula.length === 0) {
            return chemFormula;
        }
        const parts = chemFormula.split(/(\d*)/);
        const tparts = parts.map(p => {
            p.startsWith('');
            if (p.match(/\d/)) {
                return '<sub>' + p + '</sub>';
            } else {
                return p;
            }
        });
        return tparts.join('');
    }
}

/* tslint:disable:max-line-length */
import { MaterialEditorComponent } from './material-editor.component';
import { ReferenceEditorComponent } from './reference-editor.component';
import { ProtocolEditorComponent } from './protocol-editor.component';
import { JournalsComponent } from './journals.component';
import { ProjectEditorComponent } from './project-editor.component';
import { FSAGEditorComponent } from './fsag-editor.component';
import { TypeReferenceRelationEditorComponent } from './type-reference-relation-editor.component';
import { WhatsNewEditorComponent } from './whats-new-editor.component';
import { ReferenceWorkflowStatsComponent } from './reference-workflow-stats.component';
import { ReferenceAuditComponent } from './reference-audit.component';
import { SurveyReportedUseComponent } from './survey-reported-use.component';
import { SafetyAssessmentCategoryEditorComponent } from './safety-assessment-category-editor.component';
import { TypePredictionEditorBaseComponent } from './type-prediction-editor-base.component';
import {MaterialReportedUsesComponent} from './material-reported-uses.component';

export const STAFF_ROUTES = {
    Material: { name: 'MaterialEditor', title: 'Material', path: 'material', component: MaterialEditorComponent, rolesExcluded: 'FEMA' },
    MaterialById: {
        name: 'MaterialEditorById',
        title: 'Material',
        path: 'material/:materialId/info',
        component: MaterialEditorComponent,
        rolesExcluded: 'FEMA'
    },

    Reference: { name: 'ReferenceEditor', title: 'Reference', path: 'reference/info', component: ReferenceEditorComponent },
    ReferenceAudit: { name: 'ReferenceAudit', title: 'Reference Audit', path: 'reference/audit', component: ReferenceAuditComponent },
    Protocol: { name: 'ProtocolEditor', title: 'Protocol', path: 'reference/protocol', component: ProtocolEditorComponent },

    ReferenceEditor: {
        name: 'ReferenceEditorById',
        title: 'Reference',
        path: 'reference/:referenceId/info',
        component: ReferenceEditorComponent
    },
    ReferenceAuditById: {
        name: 'ReferenceAudit',
        title: 'Reference Audit',
        path: 'reference/:referenceId/audit',
        component: ReferenceAuditComponent
    },
    ProtocolById: {
        name: 'ReferenceProtocolEditor',
        title: 'Protocol',
        path: 'reference/:referenceId/protocol',
        component: ProtocolEditorComponent
    },


    Journals: { name: 'Journals', title: 'Journals', path: 'journals', component: JournalsComponent, rolesExcluded: 'FEMA' },
    Project: { name: 'ProjectEditor', title: 'Project', path: 'project', component: ProjectEditorComponent, rolesExcluded: 'FEMA' },
    FSAG: { name: 'FSAGEditor', title: 'FSAG', path: 'fsag', component: FSAGEditorComponent, rolesExcluded: 'FEMA' },
    TypeReferenceRelation: {
        name: 'TypeReferenceRelationEditor',
        title: 'Reference Relation Types',
        path: 'typeReferenceRelation',
        component: TypeReferenceRelationEditorComponent,
        rolesExcluded: 'FEMA'
    },
    WhatsNew: {
        name: 'WhatsNewEditor',
        title: 'What\'s New',
        path: 'whats-new',
        component: WhatsNewEditorComponent,
        rolesExcluded: 'FEMA'
    },
    RefWorkflowStats: {
        name: 'ReferenceWorkflowStats',
        title: 'Workflow',
        path: 'ref-workflow',
        component: ReferenceWorkflowStatsComponent,
        rolesExcluded: 'FEMA'
    },
    MaterialReportedUses: {
        name: 'MaterialReportedUses',
        title: 'Edit Survey Uses',
        path: 'material-reported-uses',
        component: MaterialReportedUsesComponent,
        rolesExcluded: 'consultant,FEMA'
    },
    SurveyReportedUse: {
        name: 'SurveyReportedUse',
        title: 'Query Survey Uses',
        path: 'survey-reported-use',
        component: SurveyReportedUseComponent,
        rolesExcluded: 'consultant,FEMA'
    },
    AssessmentCategories: {
        name: 'AssessmentCategories',
        title: 'SA Categories',
        path: 'safety-assessment-categories',
        component: SafetyAssessmentCategoryEditorComponent,
        rolesExcluded: 'FEMA'
    },
    TypePredictionEditorBaseComponent: {
        name: 'TypePredictionEditorBase',
        title: 'Prediction Types',
        path: 'type-prediction-editor-base',
        component: TypePredictionEditorBaseComponent,
        rolesExcluded: 'FEMA'
    }
};

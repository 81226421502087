import {Component, OnInit, ViewChild} from '@angular/core';
import { Location } from '@angular/common';
import * as _ from 'lodash';

import { UnitOfWork, UserManager, StateMap, ErrorLogger } from '../../services/common';
import { UtilFns, IEditHost, EditManager } from '../../utils/common';

import { RIFMProduct, RIFMProductLicense } from '../../entities/EntityModels';
import { ToolsAndModelComponent } from './tools-and-model.component';
import { ToolsAndModelsLicenseComponent } from './tools-and-models-license.component';
import { environment } from '../../../environments/environment';

@Component({
    templateUrl: './tools-and-models.html'
})
export class ToolsAndModelsComponent implements OnInit, IEditHost<RIFMProduct> {
    @ViewChild(ToolsAndModelComponent) _editingComponent: ToolsAndModelComponent;
    @ViewChild(ToolsAndModelsLicenseComponent, { static: true }) _licenseComponent: ToolsAndModelsLicenseComponent;

    _edm: EditManager<RIFMProduct>;
    _licenses: RIFMProductLicense[];
    _licenseProduct: RIFMProduct;

    constructor(public _uow: UnitOfWork, public _stateMap: StateMap, public _location: Location,
                public _userManager: UserManager, public _errorLogger: ErrorLogger) {

    }

    ngOnInit() {
        this._edm = new EditManager<RIFMProduct>(this, this._uow, this._stateMap, 'Tools and Models');

        if (this.isAuthorizedDownloadUser()) {
          this.fetchAuthorizedDownloads('AuthorizedDownloadUser');
        } else {
            this.fetch();
        }
    }

    isAuthorizedDownloadUser() {
        return this._stateMap.currentUser.isAuthorizedDownloadUser;
    }

    fetch() {
        this._uow.rIFMProductRepository.all()
            .then(r => {
                r = this._edm.isStaff() ? r : r.filter(e => e.enabled);
                this._edm.entities = _.sortBy(r, e => e.productName);
                const params = {userId: this._stateMap.currentUser.name};
                return this._uow.rIFMProductLicenseRepository
                    .whereWithParams(params)
                    .then(result => {
                        // bringing the licenses in will cause breeze to automatically add licenses to each of the
                        // RIFMProducts if applicable.  We can use this to determine if a Product is already licensed
                        // for this user.
                        this._licenses = result;
                    });
            });
    }

    fetchAuthorizedDownloads(roleName: string) {
        const paramsRole = { roleName: roleName };
        this._uow.fetch('ContentAuthorization/FetchAuthorizedRIFMProducts', paramsRole).then(r => {
            this._edm.entities = _.sortBy(r, e => e.productName);
            const params = {userId: this._stateMap.currentUser.name};
            return this._uow.rIFMProductLicenseRepository
                .whereWithParams(params)
                .then(result => {
                    this._licenses = result;
                });
        });
    }

    hasLicense(e: RIFMProduct) {
        if (this._licenses == null) { return false; }
        return this._licenses.some(l => l.productId == e.productId && l.licenseAcceptDate != null);
        // product will have licenses populated as a result of breeze hookup logic.
        // return e.rIFMProductLicenses.some(l => l.licenseAcceptDate != null);
    }

    getDownloadText(e: RIFMProduct) {
        if (e.linkTargetType == 'File') {
            return 'Click to Download Setup File';
        } else {
            return 'Click to Open Online Tool';
        }
    }

    getImage(e: RIFMProduct) {
        if (this.hasLicense(e)) {
            return environment.assetsUrl + 'assets/images/install.png';
        } else {
            return environment.assetsUrl + 'assets/images/license.png';
        }
    }

    formatProductName(name: string) {
        return name.replace('Model', 'Model ');
    }

    showLicense(e: RIFMProduct) {
        if (e.entityAspect.entityState.isAdded()) {
            this._stateMap.yesNoComponent.showModal(
                'Cannot accept this license',
                'Cannot accept a license on this product until the product is saved', ['Ok']);
            return;
        }
        this._licenseProduct = e;
        UtilFns.showModal(this._licenseComponent, this).then((ok) => this.onLicenseAccept(ok));

    }

    onLicenseAccept(accept: boolean) {
        if (accept == false) {
            this._licenseProduct = null;
            return;
        }
        const params = {
            productId: this._licenseProduct.productId,
            userId: this._stateMap.currentUser.name,
            licenseAcceptDate: new Date()
        };
        const license = this._uow.rIFMProductLicenseFactory.create(params);

        // HACK - because we don't want to combine this with 'Staff' editing.
        this._uow.commitSelected([license]).then(r => {
            // refresh license data
            const licenseparams = {userId: this._stateMap.currentUser.name};
            return this._uow.rIFMProductLicenseRepository
                .whereWithParams(licenseparams)
                .then(result => {
                    this._licenses = result;
                });
        }).catch(e => {
            const err = license.entityAspect.getValidationErrors().map(ve => ve.errorMessage).join('; ');
            this._errorLogger.log(e + ':' + err);
        });
    }

    onDownload(e: RIFMProduct) {
        if (e.linkTargetType == 'RIFMSecureProduct') {
            this.linkToSecuredProduct(e.productLink, e.contentType);
        } else {
            let url: string;
            const token = this._stateMap.currentUser.authenticationToken;

            if (e.linkTargetType == 'File') {
                url = this._userManager.getFileServiceFilePathUrl(e.productLink);
            } else if (e.linkTargetType == 'RIFMProduct') {
                if (e.productLink.indexOf('?') >= 0) {
                    url = `${e.productLink}&token=${token}`;
                } else {
                    url = `${e.productLink}//?token=${token}`;
                }
            }

            window.open(url, '_self');
            return false;
        }
    }

    linkToSecuredProduct(link: string, type: string) {

        let url: string = link;

        const params = {token: this._stateMap.currentUser.authenticationToken, contentType: type};
        this._uow.fetch('ToolsAndModels/GetApplicationToken', params).then(r => {
            if (r != null && r.length > 0) {
                if (link.indexOf('<TOKEN>') >= 0) {
                    url = url.replace('<TOKEN>', r[0]);
                }
                if (link.indexOf('<USERNAME>') >= 0) {
                    const uname = this._userManager.currentUser.name.replace('@', '_');
                    url = url.replace('<USERNAME>', uname);
                }
            }

            window.open(url);
        });
    }


    // EditManager overrides.

    onAdd() {
        if (!this._edm.validateCurrent()) {
            return;
        }
        const e = this._uow.rIFMProductFactory.create();
        e.contentType = 'None';
        this._edm.entities.push(e);
        this._edm.onSelect(e);
    }

    onCancel() {
        this._edm.onCancelCore();
        this._edm.entities = null;
        this.fetch();
    }

    onDelete() {
        // need to delete all of the licences associated with this RIFMProduct.
        const params = {productId: this._edm.currentEntity.productId};
        return this._uow.fetchTyped('Misc/RIFMProductLicensesForProduct', RIFMProductLicense, params).then(r => {
            r.forEach(l => l.entityAspect.setDeleted());
            this._edm.onDeleteCore();
        });
    }
}

<div class="inline">
    <span class="inline text-bold text-left page-title">{{pageTitle()}}</span>
    &nbsp;
    <span class="inline text-bold text-left page-title highlighted-text-blue" style="padding: 6px; border: 1px solid lightgray;">{{journal.journalName}}</span>
</div>
<div class="row btn-bar">
        <span customButtons class="inline" style="margin-left: 10px; margin-top: 6px;">
            <button *ngIf="canSave()" type="button" class="btn btn-sm btn-primary" (click)="onSave()">Save</button>
            <button *ngIf="canDelete()" type="button" class="btn btn-sm btn-primary" (click)="onDelete()">Confirm Delete</button>
            <button *ngIf="canApply()" type="button" class="btn btn-sm btn-primary" (click)="onApply()">Apply</button>
            <button *ngIf="canShowBack()" type="button" class="btn btn-sm btn-primary" (click)="onBack()">Back</button>
            <button *ngIf="canCancel()" type="button" class="btn btn-sm btn-primary" (click)="onCancel()">Cancel</button>
        </span>
</div>

<p class="alert-danger">{{_validationMessage}}</p>
<p *ngIf="_hasJournalNameCollision" class="alert-danger">{{_journalNameCollisionDesc}}</p>

<div *ngIf="_hasBeenDeleted == false && _data.entity != null" class="form-horizontal">

    <edit-prop label="Journal Name" prop="journalName" szs="2,8">
        <input type="text" class="form-control" (change)="isDuplicateJournalName()" [(ngModel)]="journal.journalName">
    </edit-prop>

    <edit-prop label="Journal Id" prop="journalId" szs="2,2">
        <input class="form-control" type="text" disabled [(ngModel)]="journal.journalId">
    </edit-prop>

    <div class="inline" style="margin-left: -12px; padding-bottom: 4px;">
        <edit-prop isChild label="Journal Name Start Year" szs="2,1">
            <input #startdate type="text" [ngModel] ="_journalNameStartYear" (change)="onChangeJournalNameStartYear($event.target.value)"/>
            <span class="italic left-indent">YYYY</span>
        </edit-prop>
        <edit-prop isChild label="Journal Name Start Month" szs="2,2">
            <select *ngIf="journal.journalNameStartYear != null" class="form-control" [ngModel]="_selectedNameStartMonthIndex" (change)="onSelectNameStartMonth($event.target.value)">
                <option *ngFor="let m of _monthNames; let mix = index" [value]="mix">{{m.month}}</option>
            </select>
        </edit-prop>
    </div>

    <div class="inline" style="margin-left: -12px; padding-bottom: 4px;">
        <edit-prop isChild label="Journal Name End Year" szs="2,1">
            <input #enddate type="text" [ngModel] ="_journalNameEndYear" (change)="onChangeJournalNameEndYear($event.target.value)"/>
            <span class="italic left-indent">YYYY</span>
        </edit-prop>
        <edit-prop isChild label="Journal Name End Month" szs="2,2">
            <select *ngIf="journal.journalNameEndYear != null" class="form-control" [ngModel]="_selectedNameEndMonthIndex" (change)="onSelectNameEndMonth($event.target.value)">
                <option *ngFor="let m of _monthNames; let mix = index" [value]="mix">{{m.month}}</option>
            </select>
        </edit-prop>
    </div>

    <div class="form-group inline" style="width: 100%;">
        <span class="edit-label inline col-md-2 control-label bold">Former Journal Name:</span>
        <input *ngIf="_previousJournal != null" disabled type="text" class="inline col-md-5" style="margin-left: 20px; border: 1px solid #4cae4c;" [(ngModel)]="_previousJournal.journalName">
        <button *ngIf="hasRelatedJournalName()"  type="button" class="btn btn-sm btn-success inline" (click)="removeRelatedJournal()">Remove</button>
        <button type="button" class="btn btn-sm btn-success inline" (click)="onSearchForRelatedJournal()">Search Former Journal Name</button>
    </div>

    <edit-prop label="Notes" prop="notes" szs="2,8">
        <textarea class="form-control" rows=3 [(ngModel)]="journal.notes"></textarea>
    </edit-prop>

</div>

<journal-selector></journal-selector>




import { Component, OnInit, Input, Output, EventEmitter, ElementRef, OnChanges, AfterViewInit, Inject, forwardRef } from '@angular/core';
import { Location } from '@angular/common';

import { UnitOfWork, UserManager, StateMap, SearchService, MolImageService } from '../../services/common';
import { UtilFns, LocationFns, } from '../../utils/common';
import { PageState } from '../../controls/common';
import * as _ from 'lodash';

import { MaterialClustersComponent } from './material-clusters.component';

import { ClusterGroup, ClusterPart, ClusterPath, MaterialCluster, ClusterNodeAndEdge } from '../../entities/EntityModels';

declare var require: any;

import { CytoscapeNode } from '../../entities/projections/CytoscapeNode';
import { CytoscapeEdge } from '../../entities/projections/CytoscapeEdge';
import { CytoscapeElements } from '../../entities/projections/CytoscapeElements';

import * as cytoscape from 'cytoscape';
const cydagre = require('cytoscape-dagre');
cydagre(cytoscape);

export class NodePosition {
    x: number;
    y: number;
}

const contextMenus = require('../../../../node_modules/cytoscape-context-menus');
contextMenus(cytoscape, jQuery);

@Component({
    selector: 'cytoscape-graph',
    templateUrl: './cytoscape-graph.html',
})
export class CytoscapeGraphComponent implements OnChanges, OnInit, AfterViewInit {

    public cy: any;

    private _canvasCenter   = 0;
    private _canvasTop      = 0;

    private _hasData       = false;
    private _nodeLevelMin   = 0;
    private _nodeLevelMax = 99999;

    private _layout     = 'dagre'; // 'dagre' 'cola', 'spread', 'cose-bilkent';
    private _rankDir    = 'LR';
    // tslint:disable-next-line:max-line-length
    private _layoutOptions = { name: this._layout, nodeSep: 20, edgeSep: 6, padding: 10, avoidOverlap: true, rankSep: 8, rankDir: this._rankDir, spacingFactor: 3, nodeDimensionsIncludeLabels: true};

    @Output() apply = new EventEmitter<string>();

    @Input() public zoom: any;
    @Input() public layout: any;
    @Input() public highlightTopNode: boolean;

    constructor(public _stateMap: StateMap, public _molImageService: MolImageService,
                public _uow: UnitOfWork, public _userManager: UserManager, public _elementRef: ElementRef,
                @Inject(forwardRef(() => MaterialClustersComponent)) public _parent: MaterialClustersComponent) {
    }

    ngOnInit() {
        this._hasData = false;

        this.layout = this.layout || {
            name: this._layout, // dagre, cola, cose-bilkent, 'spread'
            directed: true,
            padding: 0
        };

        this.zoom = this.zoom || {
            min: 0.5,
            max: 1.2
        };

    }

    public ngOnChanges(): any {
    }

    public ngAfterViewInit() {

    }

    canDeactivate() {
        return (!this._uow.hasChanges());
    }

    // **************************************************************
    // Public Properties                                            *
    // **************************************************************
    public hasData(): boolean {
        return this._hasData;
    }

    public get nodeLevelMin(): number {
        return this._nodeLevelMin;
    }

    public set nodeLevelMin(level) {
        this._nodeLevelMin = level;
    }

    public get nodeLevelMax(): number {
        return this._nodeLevelMax;
    }

    public set nodeLevelMax(level: number) {
        this._nodeLevelMax = level;
    }

    public get layoutType(): string {
        return this._layout;
    }

    public set layoutType(type: string) {
        this._layout = type;
    }

    public resetNodeLevelsToAll() {
        this._nodeLevelMin = 0;
        this._nodeLevelMax = 99999;
    }

    // **************************************************************
    // Display Interaction                                          *
    // **************************************************************
    public centerAndFitGraph(graphObject?: any) {
        const test = this.cy.elements().length;

        if (graphObject === undefined || graphObject == null) {
            this.cy.fit();
            // this.cy.center();
        } else {
            this.cy.fit(graphObject);
            // this.cy.center(graphObject);
        }
    }

    public centerGraph(graphObject?: any) {
        if (graphObject === undefined || graphObject == null) {
            this.cy.center();
        } else {
            this.cy.center(graphObject);
        }
    }

    centerGraphToOrigin() {
        if (this.cy !== null && this.hasData()) {

            const n = this.cy.nodes().filter('[precedence = 1]');

            if (n) {
                this.centerGraph(n);
            }
        }
    }

    public moveGraphToTop(yvalue: number) {
        this.cy.elements().shift('y', yvalue); // Positioning at center and 0 not working so forcing graph to the top of the canvas
    }

    public clearGraph() {

        this._hasData = false;

        if (typeof this.cy === undefined) {
            return;
        }

        if (this.cy.elements()) {
            this.cy.elements().remove();
        }

        const layout = this.cy.makeLayout(this._layoutOptions);
    }

    // *************************************************************************************
    // Process and display node data                                                       *
    // *************************************************************************************
    public renderClusterData(clusterData: ClusterNodeAndEdge[]) {

        const graphdata: CytoscapeElements = this.buildCytoscapeGraphFromClusterData(clusterData);

        this.render(graphdata);

        const materialNodes = graphdata.nodes.filter(m => m.data.ismaterial);

        this.updateMaterialNodesWithImage(materialNodes);

        if (this.highlightTopNode) {
            this.highlightTopLevelNode();
        }

        this._hasData = true;

    }

    public renderClusterJSON(graphData: any) {

        this.render(graphData);

        const materialNodes = graphData.filter((m: CytoscapeNode) => m.data.ismaterial);

        this.updateMaterialNodesWithImage(materialNodes);

        if (this.highlightTopNode) {
            this.highlightTopLevelNode();
        }

        this._hasData = true;
    }

    public render(graphData: any) {

        const self = this;

        // @ts-ignore
        this.cy = cytoscape({
            container: document.getElementById('cy'),
            elements: graphData,
            wheelSensitivity: .2,
            style: [
                {
                    selector: 'node',
                    style:  {
                        'background-color': 'data(colorcode)',
                        'label': 'data(name)',
                        'font-family': 'Arial',
                        'font-size': 11,
                        'font-weight': 'bold'
                    }
                },
                {
                    selector: 'edge',
                    style: {
                        'width': 'data(strength)',
                        'target-arrow-shape': 'triangle',
                        'line-color': '#9dbaea',
                        'target-arrow-color': '#9dbaea',
                        'curve-style': 'bezier'
                    }
                },
                {
                    selector: ':parent',
                    style: {
                        'background-opacity': 0.333
                    }
                },
                {
                    selector: 'edge.meta',
                    style: {
                        'width': 2,
                        'line-color': 'red'
                    }
                },
                {
                    selector: ':selected',
                    style: {
                        'background-color': '#4682B4',
                        'line-color': '#00FF00',
                        'target-arrow-color': '#32CD32',
                        'source-arrow-color': 'black',
                    }
                },
                {
                    selector: 'edge:selected',
                    style: {
                        'width': 16
                    }
                },
                {
                    selector: 'node[type="rectangle"]',
                    style: {
                        'shape': 'rectangle',
                        'background-color': '#FFA500'
                    }
                }
            ],
            layout: this._layoutOptions
        });

        this.cy.on('click', 'node', (evt: any) => {
            this.onGraphNodeClicked(evt.target);
        });

        this.cy.contextMenus({
            menuItems: [
                {
                    id: 'collapse',
                    content: 'Collapse Cluster Path',
                    tooltipText: 'Hide the children nodes of the clicked node',
                    image: { src: 'assets/images/ClusterCollapse.png', width: 24, height: 24, x: 6, y: 4 },
                    selector: 'node, edge',
                    onClickFunction: function (event: { target: any; }) {
                        self.onCollapseNode(event.target);
                    },
                    hasTrailingDivider: true
                },
                {
                    id: 'expandall',
                    content: 'Expand All Cluster Path Nodes',
                    tooltipText: 'Expand the cluster path to show all defined nodes',
                    image: { src: 'assets/images/ClusterExpand.png', width: 24, height: 24, x: 6, y: 4 },
                    selector: 'node, edge',
                    onClickFunction: function (event: { target: any; }) {
                        self.onExpandNodeAll(event.target);
                    },
                    hasTrailingDivider: true
                },
                {
                    id: 'expand',
                    content: 'Expand Cluster Path One Level',
                    tooltipText: 'Expand the cluster path to show the next node in the hierarchy',
                    image: { src: 'assets/images/ClusterExpand.png', width: 24, height: 24, x: 6, y: 4 },
                    selector: 'node, edge',
                    onClickFunction: function (event: { target: any; }) {
                        self.onExpandNode(event.target);
                    },
                    hasTrailingDivider: true
                },
                {
                    id: 'clipboard',
                    content: 'Copy Selected Path to Clipboard',
                    tooltipText: 'Copy the text version of the selected path to the clipboard',
                    image: { src: 'assets/images/Clipboard.png', width: 24, height: 24, x: 6, y: 4 },
                    selector: 'node, edge',
                    hasTrailingDivider: true,
                    onClickFunction: function (event: { target: any; }) {
                        self.onCopyPathToClipboard(event.target);
                    }
                }
                ,
                {
                    id: 'searchstructure',
                    content: 'Search by Structure',
                    tooltipText: 'Navigate to the Search by Structure page and look for other materials similar to this structure',
                    image: { src: 'assets/images/studies.png', width: 24, height: 24, x: 6, y: 4 },
                    selector: 'node, edge',
                    hasTrailingDivider: true,
                    onClickFunction: function (event: { target: any; }) {
                        self.onNavigateToStructureSearch(event.target);
                    }
                }
            ],
            menuItemClasses: [
                'text-center'
            ]
        });
    }

    public addClusterDataToGraph(clusterData: ClusterNodeAndEdge[], skipLayout?: boolean) {

        const graphdata: CytoscapeElements = this.buildCytoscapeGraphFromClusterData(clusterData);
        this._hasData = true;

        const eles = this.cy.add(graphdata);

        if (skipLayout == undefined || skipLayout == false) {
            const layout = this.cy.makeLayout(this._layoutOptions);
            layout.run();
        }

        const materialNodes = graphdata.nodes.filter(m => m.data.ismaterial);

        this.updateMaterialNodesWithImage(materialNodes);

        if (this.highlightTopNode) {
            this.highlightTopLevelNode();
        }
    }

    public removeNode(nodeId: string) {
        const n = this.cy.$('#' + nodeId);

        if (n) {
            this.cy.remove(n);
        }
    }

    buildCytoscapeGraphFromClusterData(clusterData: ClusterNodeAndEdge[]): CytoscapeElements {

        let _cytoscapeElements: CytoscapeElements;
        const nodes: CytoscapeNode[] = [];
        const edges: CytoscapeEdge[] = [];

        clusterData.forEach(el => {
            if (el.precedence > this._nodeLevelMin && el.precedence <= this._nodeLevelMax) {

                if (this.alreadyGraphed(el.clusterPartNode) == false) {
                    nodes.push(
                        {
                            data:
                                {
                                    id: el.clusterPartNode,
                                    name: el.clusterPartLabel,
                                    degree_layout: 1,
                                    colorcode: (el.colorCode == null) ? '#6495ED' : el.colorCode,
                                    type: el.shapeType,
                                    selected: false,
                                    ismaterial: el.isMaterial,
                                    relevance: el.clusterRelevance,
                                    precedence: el.precedence
                                }
                        });
                }

                if (el.parentNode != null) {
                    const edgeId = el.parentNode + '_to_' + el.clusterPartNode;
                    if (this.alreadyGraphed(edgeId) == false) {
                        edges.push(
                            {
                                data:
                                    {
                                        source: el.parentNode,
                                        target: el.clusterPartNode,
                                        interaction: 'pc',
                                        colorcode: '#68838B',
                                        strength: el.clusterRelevance,
                                        selected: false,
                                        id: edgeId,
                                        name: edgeId
                                    }
                            });
                    }
                }
            }
        });

        _cytoscapeElements = { nodes: nodes, edges: edges };

        return (_cytoscapeElements);
    }

    alreadyGraphed(id: string): boolean {
        if (this.cy == null) {
            return false;
        }

        const graphObject = this.cy.$('#' + id);
        return (graphObject != null && graphObject.length > 0);
    }

    highlightTopLevelNode() {
        if (this.cy !== null && this.hasData()) {

            const n = this.cy.nodes().filter('[precedence = 1]');

            // let n = this.cy.nodes()[0];

            if (n) {
                n.css('height', '60px');
                n.css('width', '60px');
                n.css('background-color', '#FFA500');
            }
        }
    }

    // Test
    showOriginPosition() {
        const n = this.cy.$('#Oxygen-containing');

        const p = n.position();
        alert('left: ' + p.left + ' top: ' + p.top);
    }

    collapseNodePath(node: any) {
        node.successors().targets().remove();
    }

    zoomToNode(node: any) {
        const n = this.cy.$('#' + node.id());
        this.cy.center(n);

        this.cy.zoom({
            level: 1.1,
            position: n.position()
        });
    }

    zoomToOrigin() {
        if (this.cy !== null && this.hasData()) {

            const n = this.cy.nodes().filter('[precedence = 1]');

            if (n) {
                this.zoomToNode(n);
            }
        }
    }

    getGraphData() {
        return this.cy.elements().jsons();
    }

    getGraphImageData(): any {
        if (this.cy == null) {
            return null;
        }

        return this.cy.png();
    }

    getNodePosition(node: any): NodePosition {
        const position = node.position();

        const np = new NodePosition();
        np.x = position.x;
        np.y = position.y;

        return (np);
    }

    // *************************************************************************************
    // Material Nodes                                                                      *
    // *************************************************************************************
    updateMaterialNodesWithImage(materialNodes: CytoscapeNode[]) {

        if (materialNodes == null || materialNodes.length < 1) {
            return;
        }

        try {
            materialNodes.forEach(m => {

                const materialId = parseInt(m.data.id, 10);
                this._molImageService.getBase32SmileUrlWithSize(materialId, 'S').then((url) => {
                    const hasImage = (url != undefined && true && url.indexOf('null.png') < 0);
                    const n = this.cy.$('#' + m.data.id);

                    if (n) {
                        n.css('height', '105px');
                        n.css('width', '105px');
                        n.css('background-color', '#FFFFFF');
                        n.css('border-width', '2px');
                        n.css('border-style', 'solid');
                        n.css('border-color', m.data.colorcode); // '#104E8B'
                        n.css('text-valign', 'top');
                        n.css('text-align', 'center');
                        n.css('text-wrap', 'wrap');
                        n.css('text-max-width', '400');

                        if (hasImage) {
                            n.css('background-image', url);
                            n.css('background-repeat', 'no-repeat');
                            n.css('background-height', '100%');
                            n.css('background-width', '100%');
                        }
                    }
                });
            });
        } catch (exc) {
            alert(exc.code);
            return;
        }
    }

    // *************************************************************************************
    // Graph User Interaction                                                              *
    // *************************************************************************************
    onGraphNodeClicked(node: any) {

        const selectedNodeIds: string[] = [];
        node.successors().targets().forEach(function (c: any) {
            selectedNodeIds.push(c.id());
        });
        this._parent.showStudyData(selectedNodeIds, node.id());
    }

    // **************************************************************
    // Menu Item Actions                                            *
    // **************************************************************
    onCollapseNode(node: any) {

        const precedence = node.data('precedence');
        const isOriginNode: boolean = (precedence == 1);

        const edges = node.connectedEdges();
        if (isOriginNode) {
            if (edges.length > 0) {
                this.collapseNodePath(node);
            }
        } else {
            if (edges.length > 1) {    // Always at least 1 edge connecting to the parent node
                this.collapseNodePath(node);
            }
        }
    }

    onExpandNode(node: any) {
        this._parent.expandNodeNextLevel(node);
    }

    onExpandNodeAll(node: any) {
        this._parent.expandAllNodes(node);

/*
        const precedence              = node.data('precedence');
        const isOriginNode: boolean   = (precedence == 1);

        if (isOriginNode) {
            const edges = node.connectedEdges();

            if (edges.length == 0) {
                this._parent.expandNodeNextLevel(node); // Don't expand the entire graph from the origin of the cluster group
            }
        } else {
            this._parent.expandAllNodes(node);
        }
*/
    }

    onCopyPathToClipboard(node: any) {
        this._parent.copyPathToClipboard(node);
    }

    onShowStudiesMenuItemClick(node: any) {
        const selectedNodeIds: string[] = [];
        node.successors().targets().forEach(function (c: any) {
            selectedNodeIds.push(c.id());
        });

        this._parent.showStudyData(selectedNodeIds, node.id());
    }

    onNavigateToStructureSearch(node: any) {
        this._parent.navigateToStructureSearch(node);
    }


    // *************************************************************************
    // Print Graph Image to File
    // *************************************************************************
    exportGraphToImageFile() {
        const filename = 'rifmclustergraph.png';
        const args = {
            bg: 'white',
            full: true
        };
        const png64 = this.cy.png(args);

        this.downloadFile(png64, filename);
    }

    downloadFile(data: any, filename: string) {
        const ele = document.createElement('a');
        ele.setAttribute('id', 'clusterimagedownload');
        ele.setAttribute('href', data);
        ele.setAttribute('download', filename);

        if (document.createEvent) {
            const event = document.createEvent('MouseEvents');
            event.initEvent('click', true, true);
            ele.dispatchEvent(event);
        } else {
            ele.click();
        }

        if (document.contains(document.getElementById('clusterimagedownload'))) {
            document.getElementById('clusterimagedownload').remove();
        }
    }
}

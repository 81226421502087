<div style="margin-top: 15px"></div>
<editor-header [edm]="edm" [femaRestricted]="true" [customButtons]="true">
    <span customButtons>
        <button type="button" class="btn btn-sm btn-primary" [disabled]="!edm.canDelete()" (click)="edm.onDelete()">Delete</button>
        <button type="button" class="btn btn-sm btn-primary" [disabled]="!canApply()" (click)="onApply()">Apply</button>
        <button *ngIf="canShowBack()" type="button" class="btn btn-sm btn-primary" (click)="edm.onBack()">Back</button>
        <button *ngIf="canCancel()" type="button" class="btn btn-sm btn-primary" (click)="edm.onCancel()">Cancel</button>
    </span>
</editor-header>
<div class="form-horizontal">
    <div class="form-group" *ngIf="_lastReviewDateAlertWarning">
        <span class="col-md-offset-2 alert-warning bold">{{_lastReviewDateAlertWarning}}</span>
    </div>
    <div class="form-group" *ngIf="_typeNTPStatusIdAlertWarning">
        <span class="col-md-offset-2 alert-warning bold">{{_typeNTPStatusIdAlertWarning}}</span>
    </div>
    <edit-prop label="Description" prop="typeNTPStatusId" szs="2,3">
        <select class="form-control" [ngModel]="entity.typeNTPStatusId" (change)="onSetNTPStatusTypeId($event.target.value)">
            <option *ngFor="let tstatus of _typeNTPStatuses" [value]="tstatus.typeNTPStatusId">{{tstatus.description}}</option>
        </select>
    </edit-prop>
    <edit-prop label="Study" prop="typeStudyId" szs="2,3">
        <select class="form-control" [ngModel]="entity.typeStudyId" (change)="entity.typeStudyId=checkNull($event.target.value)">
            <option selected value>-- None --</option>
            <option *ngFor="let tstudy of _typeStudies" [value]="tstudy.typeStudyId">{{tstudy.studyLong}}</option>
        </select>
    </edit-prop>
    <edit-prop label="Route" prop="typeRouteId" szs="2,3">
        <select class="form-control" [ngModel]="entity.typeRouteId" (change)="entity.typeRouteId=checkNull($event.target.value)">
            <option selected value>-- None --</option>
            <option *ngFor="let troute of _typeRoutes" [value]="troute.typeRouteId">{{troute.routeLong}}</option>
        </select>
    </edit-prop>
    <edit-prop label="Species" prop="typeSpeciesId" szs="2,3">
        <select class="form-control" [ngModel]="entity.typeSpeciesId" (change)="entity.typeSpeciesId=checkNull($event.target.value)">
            <option selected value>-- None --</option>
            <option *ngFor="let tspecies of _typeSpecies" [value]="tspecies.typeSpeciesId">{{tspecies.speciesLong}}</option>
        </select>
    </edit-prop>
    <edit-prop label="Protocol" prop="protocol" szs="2,3">
        <input class="form-control" type="text" [(ngModel)]="entity.protocol">
    </edit-prop>
    <edit-prop label="Reference" prop="reference" szs="2,3">
        <input class="form-control" type="text" [(ngModel)]="entity.reference">
    </edit-prop>
    <edit-prop label="Comments" prop="comments" szs="2,5">
        <textarea class="form-control" rows=5 [(ngModel)]="entity.comments"></textarea>
    </edit-prop>
    <edit-prop label="" prop="comments" szs="2,5">
    </edit-prop>
    <div class="form-group">
        <span class="control-label col-md-2 green-text bold">Last Review Date:</span>
        <span class="col-md-3 inline control-label text-left striped-gray-background">{{utilFns.formatDate(entity.lastReviewDate)}}</span>
        <button class="btn btn-primary" type="button" (click)="markAsReviewed()">Mark as Reviewed</button>
        <a *ngIf="entity.lastReviewDate" class="inline" (click)="removeReviewDate()">Remove Review Date</a>
    </div>
</div>

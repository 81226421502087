import { Component, ViewChild, Inject, Input, forwardRef, OnInit } from '@angular/core';

import { UnitOfWork, provideParent } from '../../services/common';
import { UtilFns } from '../../utils/common';

import { Journal } from '../../entities/EntityModels';
import { JournalReserveItem } from '../../entities/projections/JournalReserveItem';

import {JournalSelectorComponent, JournalSearchData } from './journal-selector.component';

import * as _ from 'lodash';

@Component({
    selector: 'journal-reserves',
    templateUrl: './journal-reserves.html',
})export class JournalReservesComponent implements OnInit {
    _userMessage: string;

    _data: JournalSearchData;
    _reserves: JournalReserveItem[];

    constructor(@Inject(forwardRef(() => JournalSelectorComponent)) public _parent: JournalSelectorComponent, public _uow: UnitOfWork) {
        this._data = _parent._data;
    }

    ngOnInit() {
        this._data.onResetMap['base']  = this.onReset.bind(this);

        if (this._data != null && this._data.entity != null) {
            this.prepareReservesData();
        }
    }

    // load data
    prepareReservesData() {
        this.onReset();

        if (this._data.entity.journalReserveItems != null && this._data.entity.journalReserveItems.length > 0) {

            this._reserves = _.orderBy(this._data.entity.journalReserveItems, ['journalYear', 'volume'], ['desc', 'desc']);
        }
    }

    get utilFns() {
        return UtilFns;
    }

    get userMessage() {
        return this._userMessage;
    }

    hasReservesData() {
        return (this._reserves != null && this._reserves.length > 0);
    }

    onReset() {
        this._userMessage       = null;
        this._reserves          = null;
    }
}


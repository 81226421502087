import { HomePageComponent} from './home-page.component';
import { IntroComponent} from './intro.component';
import { SearchResultsComponent} from './search-results.component';
import { SearchAuthorsComponent} from './search-authors.component';
import { SearchSynonymsComponent} from './search-synonyms.component';
import { MarvinSearchComponent} from './marvin-search.component';
import { MaterialGroupsComponent} from './material-groups.component';
import { SafetyAssessmentsComponent } from './safety-assessments.component';
import { IFRAAttachmentsComponent } from './ifra-attachments.component';
import { ToolsAndModelsComponent } from './tools-and-models.component';
import { ReportDatabaseChangesComponent } from './report-database-changes.component';
import { SystemRequirementsComponent  } from './system-requirements.component';
import { IFRASurveyUseComponent } from './ifra-survey-use.component';
import { DatabaseSubscriptionComponent } from './database-subscription.component';
import { MaterialClustersComponent } from './material-clusters.component';
import { EnvironmentalDataComponent } from './environmental-data.component';

export const HOME_ROUTES = {
    HomePage:    { path: 'home-page',  name: 'HomePage', component: HomePageComponent },
    AfterLogin:    { path: 'after-login:code/info',  name: 'AfterLogin', component: HomePageComponent, rolesExcluded: 'authorizeddownloaduser' },
    Introduction: { path: 'intro',  name: 'Introduction', component: IntroComponent, rolesExcluded: 'authorizeddownloaduser' },
    SearchResults: { path: 'search-results/:filter/:term', name: 'SearchResults', component: SearchResultsComponent, rolesExcluded: 'authorizeddownloaduser' },
    _SearchResults: { path: 'search-results', name: 'SearchResults', component: SearchResultsComponent, rolesExcluded: 'authorizeddownloaduser' },
    SearchAuthors: { path: 'search-authors', name: 'SearchAuthors', component: SearchAuthorsComponent, rolesExcluded: 'authorizeddownloaduser' },
    SearchSynonyms: { path: 'search-synonyms', name: 'SearchSynonyms', component: SearchSynonymsComponent, rolesExcluded: 'authorizeddownloaduser' },
    SearchStructure: { path: 'search-structure/:id', name: 'SearchStructure', component: MarvinSearchComponent, rolesExcluded: 'authorizeddownloaduser' },
    _SearchStructure: { path: 'search-structure/:id/:smiles', name: 'SearchStructure', component: MarvinSearchComponent, rolesExcluded: 'authorizeddownloaduser' },
    MaterialGroups: { path: 'material-groups', name: 'MaterialGroups', component: MaterialGroupsComponent, rolesExcluded: 'authorizeddownloaduser' },
    SafetyAssessments: { path: 'safety-assessments', name: 'SafetyAssessments', component: SafetyAssessmentsComponent, rolesExcluded: 'authorizeddownloaduser' },
    IFRAAttachments: { path: 'ifra-attachments', name: 'IFRAAttachments', component: IFRAAttachmentsComponent, rolesExcluded: 'authorizeddownloaduser' },
    ToolsAndModels: { path: 'tools-and-models', name: 'ToolsAndModels', component: ToolsAndModelsComponent },
    ReportDatabaseChanges: { path: 'report-database-changes', name: 'ReportDatabaseChanges', component: ReportDatabaseChangesComponent, rolesExcluded: 'authorizeddownloaduser' },
    SystemRequirements: { path: 'system-requirements', name: 'SystemRequirements', component: SystemRequirementsComponent, rolesExcluded: 'authorizeddownloaduser' },
    IFRASurveyUse: { name: 'IFRASurveyUse', title: 'Survey Uses', path: 'ifraSurveyUse', component: IFRASurveyUseComponent, rolesPermitted: 'ifra', rolesExcluded: 'staff,subscriber,authorizeddownloaduser' },
    DatabaseSubscription: { path: 'database-subscription', name: 'DatabaseSubscription', component: DatabaseSubscriptionComponent, rolesExcluded: 'authorizeddownloaduser' },
    MaterialClusters: { path: 'material-clusters/:id', name: 'MaterialClusters', component: MaterialClustersComponent, rolesExcluded: 'authorizeddownloaduser' },
    EnvironmentalData: { path: 'environmental-data', name: 'EnvironmentalData', component: EnvironmentalDataComponent, rolesExcluded: 'authorizeddownloaduser' }
};

import { Location } from '@angular/common';
import { Component } from '@angular/core';

import { UnitOfWork, StateMap, ErrorLogger } from '../../services/common';
import { UtilFns, IEditHost, EditManager } from '../../utils/common';
import { ExposureAdditionalMaterial } from '../../entities/EntityModels';
import { SelectableEntity } from '../../entities/projections/SelectableEntity';
import { EXPOSURE_ROUTES } from './exposure.routes'
import * as _ from 'lodash';

@Component({
    selector: 'exposure-additional-materials',
    templateUrl: './exposure-additional-materials.html',
})
export class ExposureAdditionalMaterialsComponent implements IEditHost<ExposureAdditionalMaterial> {

    _isLoading: boolean = false;

    _selectedExposureAdditionalMaterialIndex: number;
    _selectedExposureAdditionalMaterial: ExposureAdditionalMaterial;
    _exposureAdditionalMaterials: ExposureAdditionalMaterial[] = [];
    _exposureAdditionalMaterialsEx: SelectableEntity<ExposureAdditionalMaterial>[] = [];

    _edm: EditManager<ExposureAdditionalMaterial>;
    _adding: boolean = false;

    _validationMessage: string;

    colorSelected: string = '#ddd';
    colorUnselected: string = '#FFFFFF';

    constructor(private _uow: UnitOfWork, private _stateMap: StateMap,
        public _location: Location, public _errorLogger: ErrorLogger) {
    }

    ngOnInit() {

        this._edm = new EditManager<ExposureAdditionalMaterial>(this, this._uow, this._stateMap, "Exposure Additional Materials");

        this._stateMap.currentRouteName = EXPOSURE_ROUTES.ExposureAdditionalMaterialsComponent.name;

        this.fetch();
    }

    fetch() {

        this._isLoading = true;

        this._uow.fetch("ExposureSurveys/ExposureAdditionalMaterials", {}).then(e => {
            this._edm.entities = e;

            this.formatSelectableEntities();

            this._isLoading = false;
        });
    }

    getError(propName: string) {
        if (this._selectedExposureAdditionalMaterial == null) {
            return;
        }

        return this._selectedExposureAdditionalMaterial.getErrorFor(propName);
    }

    isAdded() {
        return this._selectedExposureAdditionalMaterial.entityAspect.entityState.isAdded();
    }

    formatSelectableEntities() {
        this._exposureAdditionalMaterialsEx = [];
        if (this._edm.entities == null || this._edm.entities.length < 1) return;

        _.clone(this._edm.entities).forEach(element => {
            this._exposureAdditionalMaterialsEx.push(new SelectableEntity<ExposureAdditionalMaterial>(element, '#FFFFFF'));
            this._exposureAdditionalMaterialsEx = _.sortBy(this._exposureAdditionalMaterialsEx, a => a.data.cASNumber);
        });
    }

    canDeactivate() {
        return !this.hasEdits();
    }

    hasEdits() {
        return this._uow.hasChanges();
    }

    isStaff() {
        return this._stateMap.currentUser.isStaff;
    }

    get utilFns() {
        return UtilFns;
    }

    //edit manager overrides
    canApply() {
        if (this._edm.editing) {
            return (this._uow.hasChanges() && !this._edm.currentEntity.entityAspect.hasValidationErrors);
        }
        return false;
    }

    canCancel() {
        return (this._uow.hasChanges());
    }

    canSave() {

        if (this._edm.editing) return false;

        if (!this._edm.hasEdits()) return false;

        if (!this._edm.validateBeforeSave()) return false;

        return true;
    }

    canShowBack() {
        if (!this._edm.editing) return false;
        return (!this._uow.hasChanges());
    }

    onAdd() {

        this._adding = true;

        if (this._edm.entities == null) {
            this._edm.entities = [];
        }

        let e = this._uow.exposureAdditionalMaterialFactory.create();

        this._edm.entities.push(e);
        this._edm.onSelect(e);
    }

    onApply() {
        this._validationMessage = '';

        if (this._edm.editing) {
            if (this.isDuplicateAdditionalMaterialEntry(this._edm.currentEntity.exposureAdditionalMaterialId, this._edm.currentEntity.cASNumber)) {
                this._validationMessage = 'This external material has already been created.';
                return;
            }
            this._edm.onApplyCore();
            this._adding = false;

            this.formatSelectableEntities();
        }

        return true;
    }

    onBack() {
        this._adding = false;
        this._edm.editing = false;
    }

    onCancel() {
        this._validationMessage = '';

        this._adding = false;
        this._edm.onCancelCore();
        this._edm.entities = null;

        this.fetch();
    }

    onEdit(amx: SelectableEntity<ExposureAdditionalMaterial>) {

        if (amx == null) { return; }

        this._selectedExposureAdditionalMaterial = this.filterToExposureAdditionalMaterialById(amx.data.exposureAdditionalMaterialId);

        if (this._selectedExposureAdditionalMaterial == null) { return; }

        this._edm.onSelect(this._selectedExposureAdditionalMaterial);
    }

    onRemove(amx: SelectableEntity<ExposureAdditionalMaterial>) {

        if (amx == null) { return; }

        this._selectedExposureAdditionalMaterial = this.filterToExposureAdditionalMaterialById(amx.data.exposureAdditionalMaterialId);

        if (this._selectedExposureAdditionalMaterial == null) {
            this._validationMessage = 'Error occurred, could not remove the selected external material from the list.';
            return;
        }

        if (this._selectedExposureAdditionalMaterial.entityAspect.entityState.isAdded()) {
            this._selectedExposureAdditionalMaterial.entityAspect.rejectChanges();
        } else {
            this._selectedExposureAdditionalMaterial.entityAspect.setDeleted();
        }

        _.remove(this._edm.entities, this._selectedExposureAdditionalMaterial);

        this.formatSelectableEntities();
    }

    onSave() {
        if (this._edm.hasEdits()) {
            this._edm.onSaveCore().then(() => {

                this._adding = false;
                this._edm.editing = false;
            });
        }
    }

    //Filters
    filterToExposureAdditionalMaterialById(exposureAdditionalMaterialId: number): ExposureAdditionalMaterial {
        if (this._edm.entities == null || this._edm.entities.length < 1) {
            return null;
        }

        const expAdditionalMaterial = this._edm.entities.filter(a => a.exposureAdditionalMaterialId == exposureAdditionalMaterialId);
        if (expAdditionalMaterial != null && expAdditionalMaterial.length > 0) {
            return expAdditionalMaterial[0];
        }

        return null;
    }

    //Validation
    isDuplicateAdditionalMaterialEntry(exposureAdditionalMaterialId: number, casNumber: string): boolean {
        if (this._edm.entities == null) return false;

        const mat = this._edm.entities.filter(m => m.exposureAdditionalMaterialId == exposureAdditionalMaterialId);
        if (mat != null && mat.length > 1) return true;

        const casMat = this._edm.entities.filter(m => m.cASNumber == casNumber);
        if (casMat != null && casMat.length > 1) {
            return true;
        }

        return false;
    }

    isValidString(teststring: string) {
        if (!teststring) { return false; }
        return (teststring.trim().length > 0);
    }
}

import { Component, Input } from '@angular/core';
import { PageState } from './page-state';

@Component({
    selector: 'page-status',
    template: `
        <i *ngIf="(pageState == null) || pageState.isLoading" class="fa fa-3x fa-circle-o-notch fa-spin" style="margin-top: 5px;margin-bottom: 5px;"></i>
        <div *ngIf="pageState && pageState.hasMessage && pageState.loadStatusMsg" class="bold alert alert-warning">
            {{pageState.loadStatusMsg}}
        </div>
        `
})
export class PageStatusComponent {
    @Input() pageState: PageState;
}

import { Component, ViewChild, Inject, Input, forwardRef, OnInit } from '@angular/core';

import { UnitOfWork, provideParent } from '../../services/common';
import { UtilFns } from '../../utils/common';
import { ModalDialog, PageState } from '../../controls/common';

import { Journal } from '../../entities/EntityModels';
import { JournalSubscriptionItem } from '../../entities/projections/JournalSubscriptionItem';

import {JournalSelectorComponent, JournalSearchData } from './journal-selector.component';

import * as _ from 'lodash';

@Component({
    selector: 'journal-subscriptions',
    templateUrl: './journal-subscriptions.html',
})export class JournalSubscriptionsComponent implements OnInit {
    _userMessage: string;

    // For groupings
    _subscriptions: JournalSubscriptionItem[];

    _data: JournalSearchData;

    constructor(@Inject(forwardRef(() => JournalSelectorComponent)) public _parent: JournalSelectorComponent, public _uow: UnitOfWork) {
        this._data = _parent._data;
    }

    ngOnInit() {
        this._data.onResetMap['base']  = this.onReset.bind(this);

        if (this._data != null && this._data.entity != null) {
            this.prepareSubscriptionsData();
        }
    }

    // load data
    prepareSubscriptionsData() {
        this.onReset();

        if (this._data.entity.journalSubscriptionItems != null && this._data.entity.journalSubscriptionItems.length > 0) {
            this._subscriptions = (this._data.entity.journalSubscriptionItems.sort(JournalSubscriptionItem.compareJournalSubscriptionItems));
        }
    }

    get utilFns() {
        return UtilFns;
    }

    get userMessage() {
        return this._userMessage;
    }

    hasSubscriptionsData() {
        return (this._subscriptions != null && this._subscriptions.length > 0);
    }

    onReset() {
        this._userMessage                   = null;
        this._subscriptions                 = null;
    }

}


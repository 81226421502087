import { EntityBase } from './EntityBase';
import { TypeGeographicalArea } from './TypeGeographicalArea';
import { TypeSponsor } from './TypeSponsor';

import { Validator } from 'breeze-client';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

export class Survey extends EntityBase {

   /// <code> Place custom code between <code> tags
   
   /// </code>

   // Generated code. Do not place code below this line.
   surveyId: number;
   surveyYear: string;
   typeSponsorId: number;
   flavor: boolean;
   typeGeographicalAreaId: number;
   numericalSurveyYear: number;
   created: Date;
   createdUser: string;
   modified: Date;
   modifyUser: string;
   rowVersion: number;
   typeGeographicalArea: TypeGeographicalArea;
   typeSponsor: TypeSponsor;
}


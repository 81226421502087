import {Location} from '@angular/common';
import {Component, forwardRef, Inject, OnInit} from '@angular/core';

import {provideParent, StateMap, UnitOfWork} from '../../services/common';
import {EditPropParent, PageState} from '../../controls/common';
import {MaterialConsumption, ReportingOrganization, Survey, SurveyReportedUse, TypeGeographicalArea} from '../../entities/EntityModels';
import {MaterialReportedUseEditData, MaterialReportedUseEditState, MaterialReportedUsesComponent} from './material-reported-uses.component';

import { MaterialWorkflowService } from '../../services/material-workflow-service';
import { WorkflowEntityState, WorkflowState } from '../../services/workflow-state';

import * as _ from 'lodash';

@Component({
    selector: 'material-reported-use-editor',
    templateUrl: './material-reported-use-editor.html',
    providers: [provideParent(MaterialReportedUseEditorComponent, EditPropParent)]
})

export class MaterialReportedUseEditorComponent implements OnInit {

    public _data: MaterialReportedUseEditData = new MaterialReportedUseEditData();
    public _pageState = new PageState('Material Reported Use Edit Data');

    public _filterOrganizationName: string;
    public _filterSurveyYear: string;

    public _surveyYears: string[];
    public _typeGeographicalAreas: TypeGeographicalArea[];
    public _unreportedTypeGeographicalAreas: TypeGeographicalArea[];

    public _originalKgs: { surveyReportedUseId: number, surveyId: number, kilogramsConsumed: number}[];

    public _materialWorkflowService: MaterialWorkflowService;
    public _isLoading = false;
    public _userMessage: string;
    public _validationMessage: string;

    public _dictNotes: { [key: number]: string } = {};

    constructor(public _uow: UnitOfWork, public _stateMap: StateMap, public _location: Location, materialWorkflowService: MaterialWorkflowService,
                @Inject(forwardRef(() => MaterialReportedUsesComponent)) public _parent: MaterialReportedUsesComponent) {

        this._data = _parent._data;
        this._materialWorkflowService = materialWorkflowService;
    }

    ngOnInit() {
        this._uow.fetch('MaterialReportedUses/FetchTypeGeographicalAreasByYear', { surveyYear: this._data.selectedSurveyYear }).then(r => {
            this._typeGeographicalAreas = r;
            this.setUnreportedGeographicAreas();

            this.createNotesDictionary(); // notes for each reported use by region
        });

        if (this._data.edm.entities != []) {
            this._originalKgs = this._data.edm.entities.map(obj => ({
                surveyReportedUseId: obj.surveyReportedUseId,
                surveyId: obj.surveyId,
                kilogramsConsumed: obj.kilogramsConsumed
            }));
        }

        if (this._data.materialReportedUseEditState == MaterialReportedUseEditState.Add) {
            this._filterSurveyYear  = this._data.selectedSurveyYear;

            if (this._data.selectedReportingOrganization != null) {
                this._filterOrganizationName = this._data.selectedReportingOrganization.organizationName;

            } else {
                this._filterOrganizationName = 'All';
                this._userMessage = 'Please select a Reporting Organization';
            }
        }

        this._surveyYears = this._data.surveys.map(s => s.surveyYear).filter((value, index, self) => self.indexOf(value) === index);
    }

    // load data
    public setUnreportedGeographicAreas() {
        if (this._data.edm.entities == []) {
            this._unreportedTypeGeographicalAreas = _.clone(this._typeGeographicalAreas);
            return;
        }

        const currentTypeGeoAreas = this._data.edm.entities.filter(e => e.survey != null).map(t => t.survey.typeGeographicalAreaId);

        this._unreportedTypeGeographicalAreas = this._typeGeographicalAreas.filter(function(ga) {
            return currentTypeGeoAreas.findIndex(t => t == ga.typeGeographicalAreaId) == -1;
        });
    }

    createNotesDictionary() {
        this._dictNotes = {};

        this._typeGeographicalAreas.forEach(g => {
            this._dictNotes[g.typeGeographicalAreaId] = '';
        });
    }

    // style binding
    public rowColor(ix: number): string {
        const rem =  ix % 2;
        if (rem == 0) {
            return '#D1E5EE';
        }
        return '#FFFFFF';
    }

    public backgroundGeo(ix: number): string {
        const rem =  ix % 2;
        if (rem == 0) {
            return '#E3F7F6';
        }
        return '#E3F7F6';
    }

    // interactions
    public addSurveyReportedUse(typeGeographicalAreaId: number) {

        const survey = this.findSurvey(this._data.selectedSurveyYear, typeGeographicalAreaId);

        const newUse: SurveyReportedUse = this._uow.surveyReportedUseFactory.create();

        newUse.materialId 			    = this._data.selectedMaterial.materialId;
        newUse.reportedCASNumber        = this._data.selectedMaterial.realCASNumber;
        newUse.submitter 			    = this._data.userName;
        newUse.unmapped 			    = false;

        newUse.reportingOrganizationId = this._data.selectedReportingOrganization.reportingOrganizationId;
        newUse.surveyId = survey.surveyId;

        this._data.edm.entities.push(newUse);

        this.setUnreportedGeographicAreas();
    }

    public removeSurveyReportedUse(sru: SurveyReportedUse) {

        const materialUseIdx = this._data.edm.entities.findIndex(u => u.surveyReportedUseId == sru.surveyReportedUseId);

        if (materialUseIdx < 0) {
            this._validationMessage = 'Unexpected error trying to remove a survey reported use.';
            return;
        }

        if (this._data.edm.entities[materialUseIdx].entityAspect.entityState.isAdded()) {
            this._data.edm.entities[materialUseIdx].entityAspect.rejectChanges();
            this._data.edm.entities.splice(materialUseIdx, 1);
            this.setUnreportedGeographicAreas();
        } else {
            this._data.edm.entities[materialUseIdx].entityAspect.setDeleted();
        }
    }

    public pageTitle(): string {
        if (this._data.materialReportedUseEditState == MaterialReportedUseEditState.Edit) {
            return 'Editing Survey Reported Use';
        } if (this._data.materialReportedUseEditState == MaterialReportedUseEditState.Add) {
            return 'Adding New Survey Reported Use';
        } else {
            return 'Please confirm removal of all reported use data for this Company, Survey Year and Material:';
        }
    }

    // sync tblMaterialConsumptions
    public processEdits(): boolean {
        let diffKgs     = 0;
        let notes       = '';
        let userNote: string;
        let originalSurveyReportedUsesIdx: number;
        let survey: Survey;

        this._data.edm.entities.forEach(e => {

            survey = this.findSurveyById(e.surveyId);

            userNote = null;
            if (survey.typeGeographicalAreaId in this._dictNotes) {
                userNote = this._dictNotes[survey.typeGeographicalAreaId];

                if (!this.isValidString(userNote)) { // replace empty strings with null
                    userNote = null;
                }
            }

            if (e.entityAspect.entityState.isAdded()) {
                diffKgs = _.round(e.kilogramsConsumed, 4);
                notes = this.buildWorkflowNotes(survey);

                // tslint:disable-next-line:max-line-length
                this._materialWorkflowService.recordMaterialSurveyReportedUseChange(this._data.selectedMaterial.materialId, WorkflowEntityState.Added, diffKgs, e.surveyId, notes, userNote, this._data.userName);

            } else if (e.entityAspect.entityState.isDeleted()) {
                diffKgs = _.round(e.kilogramsConsumed * -1, 4);
                notes = this.buildWorkflowNotes(survey);

                // tslint:disable-next-line:max-line-length
                this._materialWorkflowService.recordMaterialSurveyReportedUseChange(this._data.selectedMaterial.materialId, WorkflowEntityState.Deleted, diffKgs, e.surveyId, notes, userNote, this._data.userName);

            } else if (e.entityAspect.entityState.isModified()) {
                originalSurveyReportedUsesIdx	= this._originalKgs.findIndex(o => o.surveyReportedUseId == e.surveyReportedUseId);

                diffKgs =  _.round(e.kilogramsConsumed - this._originalKgs[originalSurveyReportedUsesIdx].kilogramsConsumed, 4);

                notes = this.buildWorkflowNotes(survey);

                // tslint:disable-next-line:max-line-length
                this._materialWorkflowService.recordMaterialSurveyReportedUseChange(this._data.selectedMaterial.materialId, WorkflowEntityState.Modified, diffKgs, e.surveyId, notes, userNote, this._data.userName);

            } else { // only the notes have been modified
                if (this.isValidString(userNote)) {
                    notes = this.buildWorkflowNotes(survey);
                    // tslint:disable-next-line:max-line-length
                    this._materialWorkflowService.recordMaterialSurveyReportedUseUserNotesOnlyChange(this._data.selectedMaterial.materialId, WorkflowEntityState.Modified, e.surveyId, notes, userNote, this._data.userName);
                }

                diffKgs = 0;
            }

            if (diffKgs != 0) {
                this.updateMaterialConsumptions(survey.surveyId, diffKgs);
            }
        });

        return true;
    }

    public findReportingOrganizationByName(organizationName: string): ReportingOrganization {
        const orgIdx = this._data.reportingOrganizations.findIndex(o => o.organizationName == organizationName);
        return this._data.reportingOrganizations[orgIdx];
    }

    public findSurvey(surveyYear: string, typeGeographicalAreaId: number): Survey {
        const surveyIdx = this._data.surveys.findIndex(s => s.surveyYear == surveyYear && s.typeGeographicalAreaId == typeGeographicalAreaId);
       return this._data.surveys[surveyIdx];
    }

    public findSurveyById(surveyId: number): Survey {
        const surveyIdx = this._data.surveys.findIndex(s => s.surveyId == surveyId);
        return this._data.surveys[surveyIdx];
    }

    public buildWorkflowNotes(survey: Survey): string {
        // tslint:disable-next-line:max-line-length
        return 'Survey Year: ' + this._data.selectedSurveyYear + ', Organization Name: ' + this._data.selectedReportingOrganization.organizationName + ', Geographical Area: ' + survey.typeGeographicalArea.geographicalArea;
    }

    public processRemoveAll(): boolean {
        let diffKgs = 0;
        let notes: string;
        let userNote: string;
        let survey: Survey;

        this._data.edm.entities.forEach(e => {

            survey = this.findSurveyById(e.surveyId);

            userNote = null;
            if (survey.typeGeographicalAreaId in this._dictNotes) {
                userNote = this._dictNotes[survey.typeGeographicalAreaId];

                if (!this.isValidString(userNote)) { // replace empty strings with null
                    userNote = null;
                }
            }

            diffKgs = e.kilogramsConsumed * -1;

            e.entityAspect.setDeleted();

            if (diffKgs != 0) {
                this.updateMaterialConsumptions(survey.surveyId, diffKgs);
            }

            notes = this.buildWorkflowNotes(survey);

            // tslint:disable-next-line:max-line-length
            this._materialWorkflowService.recordMaterialSurveyReportedUseChange(this._data.selectedMaterial.materialId, WorkflowEntityState.Deleted, diffKgs, e.surveyId, notes, userNote, this._data.userName);
        });

        return true;
    }

    public validateSurveyReportedUseData(): boolean {
        if (this._filterOrganizationName == '' || this._data.selectedReportingOrganization == null) {
            this._validationMessage = 'Please select a Reporting Organization from the list.';
            return false;
        }

        let isValid = true;
        this._data.edm.entities.forEach(e => {
            if (e.kilogramsConsumed == null) {
                this._validationMessage = 'Please enter a number into all the Kilograms Consumed fields.';
                isValid = false;
            }
        });

        return isValid;
    }

    updateMaterialConsumptions(surveyId: number, diffKgs: number) {
        let add = false;
        let materialConsumptionIdx = -1;

        if (this._data.edmMaterialConsumption.entities == []) {
            add = true;
        } else {
            materialConsumptionIdx = this._data.edmMaterialConsumption.entities.findIndex(mc => mc.surveyId == surveyId); // surveys are related to a particular geographic area (region)
            if (materialConsumptionIdx == -1) { add = true; }
        }

        // console.log('diffKgs');
        // console.log(diffKgs);

        if (add) {
                this.addMaterialConsumptions(this._data.selectedMaterial.materialId, surveyId, diffKgs);
        } else {
            // console.log('mc kgs before');
            // console.log(this._data.edmMaterialConsumption.entities[materialConsumptionIdx].kilogramsConsumed);

            const newKgs = _.round(this._data.edmMaterialConsumption.entities[materialConsumptionIdx].kilogramsConsumed + diffKgs, 4);

            this._data.edmMaterialConsumption.entities[materialConsumptionIdx].kilogramsConsumed =  newKgs;

            // console.log('mc kgs after');
            // console.log(this._data.edmMaterialConsumption.entities[materialConsumptionIdx].kilogramsConsumed);
        }
    }

    addMaterialConsumptions(materialId: number, surveyId: number, newKgs: number) {
        const newMaterialConsumption: MaterialConsumption = this._uow.materialConsumptionFactory.create();

        newMaterialConsumption.materialId           = materialId;
        newMaterialConsumption.surveyId             = surveyId;
        newMaterialConsumption.kilogramsConsumed    = newKgs;

        this._data.edmMaterialConsumption.entities.push(newMaterialConsumption);
    }

    // #############################################################################
    // interactions
    // #############################################################################
    public filterOrganizationChange(event: any) {
        this._userMessage = '';
        this._data.selectedReportingOrganization = this.findReportingOrganizationByName(this._filterOrganizationName);
    }

    filterSurveyYearChange(event: any) {
        this._data.selectedSurveyYear = this._filterSurveyYear;

        // reset records created for different year
        if (this._data.materialReportedUseEditState == MaterialReportedUseEditState.Add) {
            this._data.edm.entities = [];
            this._data.edm.clearErrors();
        }
    }

    canAddNewGeoRegion(): boolean {
        if (this._data.materialReportedUseEditState != MaterialReportedUseEditState.Add) {
            return true;
        }

        return (this._filterOrganizationName != 'All' && this._filterSurveyYear != 'All');
    }

    isAdding(): boolean {
        return (this._data.materialReportedUseEditState == MaterialReportedUseEditState.Add);
    }

    isEditing(): boolean {
        return (this._data.materialReportedUseEditState == MaterialReportedUseEditState.Edit);
    }

    isRemoving(): boolean {
        return (this._data.materialReportedUseEditState == MaterialReportedUseEditState.Delete);
    }

    isValidString(teststring: string) {
        if (!teststring) { return false; }

        return (teststring.trim().length > 0);
    }

    hasUserNotes() {
        if (Object.keys(this._dictNotes).length == 0) { return false; }

        const userNotes = Object.keys(this._dictNotes).map(s => this._dictNotes[s]).filter(n => this.isValidString(n));

        return (userNotes != null && userNotes.length > 0);
    }
}

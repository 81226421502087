<div class="indent-top-sm"></div>
<editor-header [edm]="_edm" [femaRestricted]="true"></editor-header>

<default-table *ngIf="_edm.hasEntities()" [attr.data-hidden]="_edm.editing">
    <h4 header>Regulatory Status</h4>
    <thead>
        <tr>
            <td>Agency</td>
            <td>Agency Title</td>
            <td>Reference</td>
            <td>Determination</td>
            <td>Web or File Link</td>
            <td *ngIf="_edm.isStaff()">Web Reference</td>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let e of _edm.entities" (click)="_edm.onSelect(e)">
            <td>{{e.evaluatingAgency()}}</td>
            <td>{{e.agencyTitle()}}</td>
            <td>{{e.evaluatorId}}</td>
            <td>{{e.prefixedDetermination()}}</td>
            <td><a (click)="selectWebRef(e);$event.stopPropagation()">{{e.linkDisplay}}</a></td>
            <td *ngIf="_edm.isStaff()">{{e.webReferenceExt()}}</td>
            <!-- <td>{{s.entityAspect.entityState}}</td> -->
        </tr>
    </tbody>
</default-table>

<div *ngIf="_edm.editing">
    <regulatory-status #self [entity]="_edm.currentEntity" [statusType]="statusType"></regulatory-status>
</div>
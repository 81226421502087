<div *ngIf="_materialIds && _materialIds.length" class="indent-top-sm">
    <h4>Related By Fragrance Structure Activity Group: {{_fsagNote}} - {{_materialIds.length}} material(s)</h4>
</div>

<tab-container (tabChange)="onTabChange($event)">
    <ng-template tab-pane title="Groups">
        <div *ngIf="_edm.isStaff()" class="row btn-bar indent-top-sm">
            <button *ngIf="!_edm.editing" type="button" class="btn btn-sm btn-primary" [disabled]="!_edm.canAdd()" (click)="_edm.onAdd()">Attach to a group</button>
            <button *ngIf="!_edm.editing" type="button" class="btn btn-sm btn-primary" [disabled]="!_edm.canDelete()" (click)="_edm.onDelete()">Remove from this group</button>
            <button *ngIf="_edm.editing" type="button" class="btn btn-sm btn-primary" [disabled]="!_edm.canSave()" (click)="_edm.onSave()">Save</button>
            <button *ngIf="_edm.editing" type="button" class="btn btn-sm btn-primary" [disabled]="!_edm.canCancel()" (click)="_edm.onCancel()">Cancel</button>
            <span *ngIf="_edm.hasEdits()" class="label label-warning">
            You cannot leave the Relations tab until you either 'Save' or 'Cancel'
            </span>
            <span *ngIf="_edm.status" [ngClass]="_edm.status.classes">{{_edm.status.message}}</span>
        </div>

        <div *ngIf="_edm.hasErrors()">
            <div *ngFor="let errorMessage of _edm.errorMessages" >
                <div class="row col-md-offset-1 alert-danger">{{ errorMessage }}</div>
            </div>
        </div>            

        <div>
            <page-status [attr.data-hidden]='_edm.editing' [pageState]="_edm.pageState"></page-status>
        </div>

        <default-table *ngIf="_edm.hasEntities()" [attr.data-hidden]="_edm.editing">
            <thead>
                <tr>
                    <td>CAS #</td>
                    <td>RIFM ID</td>
                    <td>Principal Name</td>
                    <td>Structure</td>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let e of _edm.entities">
                    <td (click)="onCasSelect(e)">
                        <a>{{e.material.realCASNumber || '(No CAS#)'}}</a>
                    </td>
                    <td>{{e.material.formattedRIFMId}}</td>
                    <td><span [htmlGreek]="e.material.principalSynonym().synonymWordOrWebVersion()"></span></td>
                    <td><img [src]="getMaterialImageUrl(e)" /></td>
                </tr>
            </tbody>
        </default-table>

        <div *ngIf="_edm.editing">
            <relations-group #self [entity]="_edm.currentEntity"></relations-group>
        </div>
    </ng-template>
    <ng-template *ngIf="_materialIds && _materialIds.length" tab-pane title="Human Health Studies">
        <human-health [materialIds]="_materialIds" [toxType]="'H'" [tabPath]="'1.1'" [shouldFetchMaterials]="true"></human-health>
    </ng-template>
    <ng-template *ngIf="_materialIds && _materialIds.length" tab-pane title="Environmental Studies">
        <human-health [materialIds]="_materialIds" [toxType]="'E'" [tabPath]="'1.2'" [shouldFetchMaterials]="true"></human-health>
    </ng-template>
    <ng-template *ngIf="_materialIds && _materialIds.length" tab-pane title="Non-tox References">
        <tox-data-other [materialIds]="_materialIds" [tabPath]="'1.3'"></tox-data-other>
    </ng-template>
</tab-container>

<div class="form-horizontal indent-top-sm">

    <div class="inline" style="margin-left: -12px; padding-bottom: 4px;">
        <edit-prop isChild label="Exposure Survey Month" szs="2,2">
            <select class="form-control" [ngModel]="_selectedMonthIndex" (change)="onSelectMonth($event.target.value)">
                <option *ngFor="let m of _monthNames; let mix = index" [value]="mix">{{m.month}}</option>
            </select>
        </edit-prop>
        <edit-prop isChild label="Exposure Survey Year" szs="2,2">
            <select class="form-control" [ngModel]="_selectedYearIndex" (change)="onSelectYear($event.target.value)">
                <option *ngFor="let y of _years; let ix = index" [value]="ix">{{y.year}}</option>
            </select>
        </edit-prop>
    </div>

    <edit-prop label="Survey Number" prop="exposureSurveyNumber" szs="2,2">
        <input class="form-control" type="text" [(ngModel)]="entity.exposureSurveyNumber">
    </edit-prop>

    <edit-prop label="Survey Description" prop="description" szs="2,6">
        <textarea class="form-control" rows=3 [(ngModel)]="entity.description"></textarea>
    </edit-prop>

    <div class="form-group">
        <edit-prop isChild label="Creme Version" szs="2,2">
            <select class="form-control" *ngIf="_typeCremeVersions" [ngModel]="entity.typeCremeVersionId" (change)="entity.typeCremeVersionId=$event.target.value">
                <option *ngFor="let v of _typeCremeVersions" [value]="v.typeCremeVersionId">{{v.cremeVersion}}</option>
            </select>
        </edit-prop>
    </div>

    <div class="form-group">
        <edit-prop label="Survey Status" prop="typeExposureSurveyStatusId" szs="2,3">
            <select class="form-control" *ngIf="_typeExposureSurveyStatuses" [ngModel]="entity.typeExposureSurveyStatusId" (change)="entity.typeExposureSurveyStatusId=$event.target.value">
                <option *ngFor="let t of _typeExposureSurveyStatuses" [value]="t.typeExposureSurveyStatusId">{{t.typeExposureSurveyStatusId}}</option>
            </select>
        </edit-prop>
    </div>

</div>

import { Component, OnInit } from '@angular/core';

import { StateMap, UnitOfWork, UserManager } from '../../services/common';
import * as _ from 'lodash';

import { ExposureSurveyMaterialMostRecent, RIFMReport } from '../../entities/EntityModels';
import { EXPOSURE_ROUTES } from './exposure.routes';

@Component({
    templateUrl: './exposure-survey-reports.html',
})
export class ExposureSurveyReportsComponent implements OnInit {
    private _isLoading = false;
    public _exposureSurveyMostRecents: ExposureSurveyMaterialMostRecent[] = [];
    public _rifmReports: RIFMReport[] = [];

    constructor(public _stateMap: StateMap, public _uow: UnitOfWork, public _userManager: UserManager) {

    }

    ngOnInit() {
        this._stateMap.currentRouteName = EXPOSURE_ROUTES.ExposureSurveyReportsComponent.name;

        this.fetch();
    }

    // ***** RIFM Reports *****
    fetch() {
        const params = { reportTopic: 'ExposureSurveyReports' };
        return this._uow.fetch('Misc/RIFMReportsByReportTopic', params).then(r => {
            if (r) {
                this._rifmReports = _.sortBy(r, x => x.reportTitle);
            } else {
                this._rifmReports = r;
            }
        });
    }

    onShowRIFMReport(r: RIFMReport) {
        if (r.reportWebLink != null) {
            window.open(r.reportWebLink);
        } else if (r.reportFileName != null) {
            const url = this._userManager.getFileServiceFilePathUrl(r.reportFileName);
            window.open(url, '_self');
            return false;
        }
        return;
    }

    onShowMostRecentSurveyReport() {
        this._isLoading = true;

        return this._uow.fetch('ExposureSurveys/ExposureSurveyMaterialMostRecents', {}).then(r => {
            this._exposureSurveyMostRecents = r;
            if (this._exposureSurveyMostRecents != null && this._exposureSurveyMostRecents.length > 0) {
                this._isLoading = false;
                setTimeout(() => {
                    const ele = $('#ExposureSurveyLastReport');
                    (<any>ele).modal('show');
                }, 0);
                return;
            }
        });
    }

    onExportMaterialThresholdReport() {
        const params: { key: string, value: string }[] = [];

        params.push({ key: 'reporttype', value: 'materialthreshold' });

        const url = this._userManager.getReportUrl(params);
        window.open(url, '_self');
        return false;
    }
}

import { Location } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { UnitOfWork, StateMap, UserManager, ErrorLogger, EditorService } from '../../services/common';
import { ColumnSorter, TabContainer } from '../../controls/common';
import { EditPropParent } from '../../controls/edit-prop.component';
import { UtilFns, IEditHost, EditManager, LocationFns } from '../../utils/common';
import { PageState } from '../../controls/common';

import { ExposureSurvey } from '../../entities/EntityModels';
import { ExposureAdditionalMaterialSurveyMeasuresComponent } from './exposure-additional-material-survey-measures.component';
import { MaterialExposureSurveyMeasuresComponent } from './material-exposure-survey-measures.component';

import { ROUTES } from '../routes';
import { EXPOSURE_ROUTES } from './exposure.routes';

export class MeasureEditData {
    inSubEditor: boolean;
    currentTab: number;
    materialId: number;
    exposureSurveyId: number;
    exposureAdditionalMaterialId: number;
    additionalExposureSurveyId: number
}

@Component({
    selector: 'record-exposure-survey-measures',
    templateUrl: './record-exposure-survey-measures.html',
})

export class RecordExposureSurveyMeasuresComponent implements OnInit {
    @ViewChild(ExposureAdditionalMaterialSurveyMeasuresComponent) _exposureAdditionalMaterialSurveyMeasuresComponent: ExposureAdditionalMaterialSurveyMeasuresComponent;
    @ViewChild(MaterialExposureSurveyMeasuresComponent) _materialExposureSurveyMeasuresComponent: MaterialExposureSurveyMeasuresComponent;

    @ViewChild(TabContainer, { static: true }) _tabContainer: TabContainer;
    _tab: number;

    public _data: MeasureEditData = new MeasureEditData();
    private _isLoading: boolean;

    constructor(private _uow: UnitOfWork, private _stateMap: StateMap, private _userManager: UserManager, private _router: Router,
        public _route: ActivatedRoute, public _location: Location, public _errorLogger: ErrorLogger) {

    }

    ngOnInit() {

        this._stateMap.currentRouteName = EXPOSURE_ROUTES.RecordExposureSurveyMeasuresComponent.name;

        const _sub = this._route.params.subscribe(params => {
            this._data.materialId                   = +params['materialId'];
            this._data.exposureAdditionalMaterialId = +params['exposureAdditionalMaterialId'];
            this._data.exposureSurveyId             = +params['exposuresurveyid'];
            this._data.additionalExposureSurveyId   = +params['exposuresurveyid'];
        });

        if (this._data.materialId > 0) {
            this.setTab(0);
        } else if (this._data.exposureAdditionalMaterialId > 0) {
            this.setTab(1);
        }
    }

    setTab(tabNumber: number) {
        setTimeout(() => {
            this._tabContainer.selectTab(tabNumber);
        }, 1)
    }

    canDeactivate() {
        return !this._data.inSubEditor && !this._uow.hasChanges();
    }

    get isLoading() {
        return this._isLoading;
    }

    set isLoading(value) {
        this._isLoading = value;
    }

    isStaff() {
        return this._stateMap.currentUser.isStaff;
    }

    get utilFns() {
        return UtilFns;
    }

    //Reports

    //Navigation
}

<div class="form-horizontal">

    <edit-prop label="Use Category" prop="typeFoodCategoryId" szs="2,3" >
        <select class="form-control" [ngModel]="entity.typeFoodCategoryId" (change)="entity.typeFoodCategoryId=$event.target.value">
            <option selected value>-- None --</option>
            <option *ngFor="let item of _typeFoodCategories" [value]="item.typeFoodCategoryId">{{item.foodDescription}}</option>
        </select>
    </edit-prop>
    
    <edit-prop label="Average Usual" prop="averageUsual" szs="2,4" >
        <input class="form-control" type="text" [(ngModel)]="entity.averageUsual">
    </edit-prop>
    
    <edit-prop label="Average Maximum" prop="averageMaximum" szs="2,4" >
        <input class="form-control" type="text" [(ngModel)]="entity.averageMaximum">
    </edit-prop>
    
</div>

import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';

import { StateMap, UnitOfWork } from '../../services/common';
import { LocationFns } from '../../utils/common';
import { TabContainer } from '../../controls/tab-container';

import { Material } from '../../entities/Material';
import { MATERIAL_ROUTES } from './material.routes';

@Component({
    templateUrl: './flavor.html',
})
export class FlavorComponent implements OnInit, AfterViewInit {
    material: Material;
    @ViewChild(TabContainer, { static: true }) _tabContainer: TabContainer;
    _tab: number;

    constructor(public _uow: UnitOfWork, public _stateMap: StateMap, public _location: Location) {
    }


    ngOnInit() {
        this.material = this._stateMap.currentMaterial;
        this._stateMap.currentRouteName = MATERIAL_ROUTES.Flavor.name;

        this._tab = LocationFns.getTab(this._location) || 0;
    }

    ngAfterViewInit() {
        if (this._tab != 0) {
            setTimeout(() => this._tabContainer.selectTab(this._tab), 0);
        }
    }

    canDeactivate() {
        return !this._uow.hasChanges();
    }

}

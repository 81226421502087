<div style="margin-top: 15px"></div>

<div>
    <div class="inline" style="font-size: 18px;" *ngIf="_data.inSubEditor">{{_adding ? "Adding a Cramer Class" : "Editing a Cramer Class" }}</div>
    <span *ngIf="(!_data.inSubEditor)">
        <button type="button" class="inline btn btn-sm btn-primary" (click)="onAdd()">Add Class</button>
    </span>

    <editor-header *ngIf="_data.inSubEditor" [edm]="_edmPredictions" [customButtons]="true">
        <span customButtons>
            <button type="button" class="btn btn-sm btn-primary" [disabled]="!canApply()" (click)="onApply()">Apply</button>
            <button *ngIf="canShowBack()" type="button" class="btn btn-sm btn-primary" (click)="onBack()">Back</button>
            <button *ngIf="canCancel()" type="button" class="btn btn-sm btn-primary" (click)="_data.edm.onCancel()">Cancel</button>
        </span>
    </editor-header>

</div>

<div *ngIf="_data.entity && !_data.inSubEditor" class="form-horizontal" style="margin-left: 10px;">

    <default-table *ngIf="_edmPredictions.entities && _edmPredictions.entities.length > 0">
        <h4 header>Cramer Classes</h4>
        <thead>
            <tr>
                <td></td>
                <td></td>
                <td>Prediction Type</td>
                <td>Prediction Class</td>
                <td>Comment</td>
                <td>Enabled</td>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let p of _edmPredictions.entities">
                <td><a (click)="onDeletePrediction(p)">Delete</a></td>
                <td><a (click)="onEditPrediction(p)">Edit</a></td>
                <td>{{p.typePrediction?.predictionName}}</td>
                <td>{{p.predictionClass}}</td>
                <td>{{p.comment}}</td>
                <td>{{p.enabled}}</td>
            </tr>
        </tbody>
    </default-table>
</div>

<div *ngIf="_adding || _edmPredictions.editing">
    <h4>{{subTitle}}</h4>
    <prediction-cramer-editor #self [prediction]="_edmPredictions.currentEntity"></prediction-cramer-editor>
</div>

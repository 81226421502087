<nav class="toolbar navbar navbar-default" style="margin-bottom: 0px;">

    <div style="margin-top: 10px; margin-bottom: 10px" >
        <div class="navbar-center bold toolbar-title" style="font-size: 30px" >RIFM Fragrance and Flavor Database</div>
        <div class="navbar-collapse collapse">
            <div class="nav navbar-left">
                <img src="assets/images/RIFM_Horizontal_Full_Name_Full_CLR_Logo_RGB Registered.png" style="height: 50px" alt="RIFM" />
            </div>
            <div class="nav navbar-right">
                <img src="assets/images/iofi-transparent.png" style="height: 50px; margin-top: 0px; margin-right: 15px" />
                <!--<img src="assets/images/FEMA97x32.png" style="height: 32px; margin-top: 9px; margin-right: 10px" />-->
            </div>
        </div>
        <!--<div *ngIf="isOnHomePage()" class="navbar-center bold" style="font-size: 10px" >Version: {{ _stateMap.versionNo }}</div>-->
    </div>
    <div >
        <!-- Collect the nav links, forms, and other content for toggling -->
        <div class="collapse navbar-collapse" style="margin-bottom: 10px" >
            <ul class="nav navbar-nav">
                <li>
                    <button type="button" class="btn btn-primary" style="margin-left: 0px" (click)="goHome()">Home</button>
                </li>
                <li *ngIf="_stateMap.currentMaterial && !isAuthorizedDownloadUser()">
                    <button type="button" class="btn btn-primary" (click)="goMaterial()">Material</button>
                </li>
                <li *ngIf="_stateMap.currentReference && !isAuthorizedDownloadUser()">
                    <button type="button" class="btn btn-primary" (click)="goReference()">Reference</button>
                </li>
                <li *ngIf="!isAuthorizedDownloadUser()">
                    <button type="button" class="btn btn-primary" (click)="goSearchResults()">Search Results</button>
                </li>
                <li *ngIf="isStaff()">
                    <button type="button" class="btn btn-primary" (click)="goStaffEditing()">Staff Editing</button>
                </li>
                <li *ngIf="canShowExposureAdmin()">
                    <button type="button" class="btn btn-primary" (click)="goExposureAdmin()">Exposure Admin</button>
                </li>
                <li *ngIf="canShowDocManagement()">
                    <button type="button" class="btn btn-primary" (click)="goDocManagement()">Document Management</button>
                </li>
                <li>
                    <button type="button" class="btn btn-primary" (click)="printBody()">Print</button>
                </li>
                <li>
                    <button type="button" class="btn btn-primary" (click)="logout()">Logout</button>
                </li>
                <!--<li *ngIf="hasErrors()">
        <button type="button" class="btn btn-xs alert-danger" (click)="showErrors()">Errors</button>
    </li>-->
            </ul>
            <div *ngIf="userReady">
                <ul *ngIf="searchFilters && searchFilters.length > 0 && !isAuthorizedDownloadUser()" class="nav navbar-nav navbar-right" style="margin-right: 10px;" >
                    <li>
                        <select class="btn btn-primary dropdown-toggle" [ngModel]="_filter" (change)="_filter=$event.target.value" [disabled]="!_filter"
                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <option *ngFor="let f of searchFilters" class="dropdown" [value]="f.radioOptionId"> {{f.radioOptionText}}</option>
                        </select>
                    </li>
                    <li>
                        <input type="text" class="form-control" [(ngModel)]="_searchTerm" (keyup.enter)="search()" id="searchTerm" name="searchTerm" autocomplete="off" />
                    </li>
                    <li>
                        <button type="button" class="btn btn-primary" (click)="search()" [disabled]="!canSearch() || !_filter">Search</button>
                    </li>
                    <li>
                        <button type="button" class="btn btn-primary" (click)="clear()" [disabled]="!_searchTerm && filter == _defaultFilter">Clear</button>
                    </li>
                    <li *ngIf="isStaffOnlyPage()">
                        <p class="bold">Staff ONLY</p>
                    </li>
                </ul>
            </div>
        </div>
        <!-- /.navbar-collapse -->
    </div>
    <!-- /.container-fluid -->
</nav>

import { Component, ElementRef, OnInit } from '@angular/core';
import * as _ from 'lodash';

import { UnitOfWork } from '../../services/common';
import { UtilFns } from '../../utils/common';
import { ModalDialog, PageState } from '../../controls/common';

import { TypeComplianceGuideline } from '../../entities/EntityModels';

@Component({
    selector: 'compliance-selector',
    templateUrl: './compliance-selector.html',
})
export class ComplianceSelectorComponent extends ModalDialog<TypeComplianceGuideline> implements OnInit {
    _searchTerm: string;
    _allEntities: TypeComplianceGuideline[] = [];
    _entities: TypeComplianceGuideline[] = [];
    _pageState = new PageState('Complaince Guidelines');

    constructor(elementRef: ElementRef, public _uow: UnitOfWork) {
        super(elementRef);
    }

    ngOnInit() {
        this._pageState.isLoading = true;
        this._uow.typeComplianceGuidelineRepository.all().then(r => {
            this._pageState.isLoaded = r;
            this._allEntities = _.sortBy(r, x => x.guideline);
            this.search();
        });
    }

    hasSearchTerm() {
        return this._searchTerm && this._searchTerm.length > 0;
    }

    search() {
        this.validationMessage = null;

        if (this.hasSearchTerm()) {
            const searchTerm = this._searchTerm.toLowerCase();
            this._entities = this._allEntities.filter(f => (f.guideline || '').toLowerCase().indexOf(searchTerm) > -1
                || (f.explanation || '').toLowerCase().indexOf(searchTerm) > -1);
        } else {
            this._entities = this._allEntities;
        }

    }

    onSelect(type: TypeComplianceGuideline) {
        UtilFns.returnModal(this, type);
    }


}


<div>
    <div class="inline">
        <span class="inline text-bold text-left page-title">{{_data.adding ? "Adding a Reserve" : "Editing a Reserve" }}</span>
        &nbsp;
        <span class="inline text-bold text-left page-title">for Journal</span>
        &nbsp;
        <span class="inline text-bold text-left page-title highlighted-text-blue" style="padding: 6px; border: 1px solid lightgray;">{{_data.entity.journalName}}</span>
    </div>

    <editor-header [edm]="_edmReserve" [customButtons]="true">
        <span customButtons class="inline" style="margin-left: 10px; margin-top: 6px;">
            <button *ngIf="canSave()" type="button" class="btn btn-sm btn-primary" (click)="onSave()">Save</button>
            <button *ngIf="canDelete()" type="button" class="btn btn-sm btn-primary" (click)="onDelete()">Confirm Delete</button>
            <button *ngIf="canApply()" type="button" class="btn btn-sm btn-primary" [disabled]="!canApply()" (click)="onApply()">Apply</button>
            <button *ngIf="canShowBack()" type="button" class="btn btn-sm btn-primary" (click)="onBack()">Back</button>
            <button *ngIf="canCancel()" type="button" class="btn btn-sm btn-primary" (click)="onCancel()">Cancel</button>
        </span>
    </editor-header>
</div>

<p class="alert-danger">{{_validationMessage}}</p>

<div class="form-horizontal indent-top-sm">

    <div *ngIf="journalReserve != null" class="form-horizontal">
        <edit-prop label="Journal Year" prop="journalYear" szs="2,8">
            <input type="text" class="form-control" [(ngModel)]="journalReserve.journalYear">
        </edit-prop>

        <edit-prop label="Volume" prop="volume" szs="2,5">
            <input class="form-control" type="text"  [(ngModel)]="journalReserve.volume">
        </edit-prop>

        <edit-prop label="Issue" prop="issue" szs="2,5">
            <datepicker>
                <input class="form-control" type="text" [(ngModel)]="journalReserve.issue" />
            </datepicker>
        </edit-prop>

        <edit-prop label="Notes" prop="notes" szs="2,8">
            <textarea class="form-control" rows=3 [(ngModel)]="journalReserve.notes"></textarea>
        </edit-prop>

    </div>

</div>

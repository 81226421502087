<tab-container>
    <ng-template tab-pane [canDeactivate]="canDeactivate()" title="General" [hasData]="_stateMap.hasMaterialDataFor('regulatory-statuses-general')" active="true">
        <regulatory-statuses [materialId]="material.materialId" [statusType]="'general'" ></regulatory-statuses>
    </ng-template>
    <ng-template tab-pane [canDeactivate]="canDeactivate()" title="IFRA" [hasData]="_stateMap.hasMaterialDataFor('regulatory-statuses-ifra')" >
        <regulatory-statuses [materialId]="material.materialId" [statusType]="'ifra'" ></regulatory-statuses>
    </ng-template>
    <ng-template tab-pane [canDeactivate]="canDeactivate()" title="Occupational" [hasData]="_stateMap.hasMaterialDataFor('regulatory-statuses-occupational')" >
        <regulatory-statuses [materialId]="material.materialId" [statusType]="'occupational'" ></regulatory-statuses>
    </ng-template>
    <ng-template tab-pane [canDeactivate]="canDeactivate()" title="NTP" [hasData]="_stateMap.hasMaterialDataFor('status-ntps')" >
        <status-ntps [materialId]="material.materialId"  ></status-ntps>
    </ng-template>
</tab-container>

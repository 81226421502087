import { EntityBase } from './EntityBase';
import { BiologicalData } from './BiologicalData';
import { TypeStudySubType } from './TypeStudySubType';

import { Validator } from 'breeze-client';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

export class BioDataStudySubType extends EntityBase {

   /// <code> Place custom code between <code> tags
   
   /// </code>

   // Generated code. Do not place code below this line.
   biologicalDataId: number;
   typeStudyId: string;
   studySubTypeId: string;
   rowVersion: number;
   biologicalData: BiologicalData;
   typeStudySubType: TypeStudySubType;
}


import { EntityBase } from './EntityBase';
import { Material } from './Material';
import { ClusterPath } from './ClusterPath';

import { Validator } from 'breeze-client';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

export class MaterialCluster extends EntityBase {

   /// <code> Place custom code between <code> tags
   
   /// </code>

   // Generated code. Do not place code below this line.
   materialId: number;
   clusterPathId: number;
   molecularFormula: string;
   clusterRelevance: number;
   notes: string;
   created: Date;
   createdUser: string;
   modified: Date;
   modifyUser: string;
   rowVersion: number;
   clusterPath: ClusterPath;
   material: Material;
}


<!--<div class="topic-page" *ngIf="material">   -->
<div class="topic-page" >    
    <material-header [material]="material"></material-header>
    <material-additional-identifiers [materialId]="currentMaterialId" [defaultVisibility]="additionalIdsVisibility"></material-additional-identifiers>
   
    <div class="row tab-container-vert">
        <div class="col-md-1-8 tab-titles no-print">
            <ul *ngIf="material" class="nav nav-tabs nav-stacked" style="padding-bottom: 12px;">
                <li *ngFor="let r of _routes; let ix = index" role="presentation" [class.active]="isActiveTab(r)" 
                   [class.has-no-data]="!_stateMap.hasMaterialDataFor(r.path)"  >                 
                    <a  *ngIf="r.path && canShow(r)" [routerLink]="[ '/material/' + material.materialId + '/' + r.path ]">
                        <span >{{r.title}}</span>
                    </a>
                    <!--<a  *ngIf="r.path && canShow(r)" [routerLink]="[ r.path ]"><span >{{r.title}}</span></a>-->
                    <a *ngIf="canShow(r) && (!r.path)"><span>{{r.title}}</span></a>
                </li>
            </ul>
        </div>
        <div class="col-md-7-8 tab-content">
            <router-outlet></router-outlet>
        </div>
        <div>
            <!--<modal-dialog></modal-dialog>-->
        </div>
    </div>
</div>

<h4 class="indent-left-md" *ngIf="_materialNotFound">This material no longer exists in the database</h4>
    
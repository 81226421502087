<div class="indent-left-md">
    <div class="navbar-header">
        <p class="home-page-title">Synonym</p>
    </div>

    <div class="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
        <ul class="nav navbar-nav navbar-form">
            <li>
                <select class="btn btn-primary dropdown-toggle" [ngModel]="_pageState.operatorName" (change)="opChange($event)" data-toggle="dropdown"
                aria-haspopup="true" aria-expanded="false">
                    <option *ngFor="let op of _operators" class="dropdown" [value]="op.name"> {{op.title}}</option>
                </select>
            </li>
            <li>
                <input type="text" class="form-control" [(ngModel)]="_pageState.searchTerm" (keyup.enter)="newSearch()" autocomplete="off" />
            </li>
            <li>
                <button type="button" class="btn btn-primary" (click)="newSearch()" [disabled]="!_pageState.searchTerm">Search</button>
            </li>
            <li>
                <button type="button" class="btn btn-primary" (click)="clear()" [disabled]="!_pageState.searchTerm">Clear</button>
            </li>
        </ul>
    </div>

    <page-status [pageState]="_pageState"></page-status>

    <div class="row" *ngIf="_synonyms && _pageState.itemCount != 0">

        <div *ngIf="_pageState.hasMoreThanOnePage" class="col-md-12">
            <pagination-controls class="no-padding" (pageChange)="fetchPage($event)"></pagination-controls>
        </div>

        <default-table class="col-md-12">
            <thead>
                <tr>
                    <td><a [colSorter]="_colSorter">CAS No. </a></td>
                    <!--can't sort on RIFM ID because can't compose sql orderby-->
                    <td>RIFM ID</td>
                    <td><a [colSorter]="_colSorter">FEMA No. </a></td>
                    <td><a [colSorter]="_colSorter">Synonym </a></td>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let e of _synonyms | paginate : _pagination" (click)="selectMaterial(e)">
                    <td><a>{{e.material.realCASNumber || '(No CAS#)'}}</a></td>
                    <td>{{e.material.formattedRIFMId}}</td>
                    <td>{{e.material.fEMANumber}}</td>
                    <td [htmlGreek]="e.synonymWord"></td>
                </tr>
            </tbody>
        </default-table>


        <div *ngIf="_pageState.hasMoreThanOnePage" class="col-md-12">
            <pagination-controls class="no-padding" (pageChange)="fetchPage($event)"></pagination-controls>
        </div>

    </div>

</div>
import { Location } from '@angular/common';
import * as _ from 'lodash';

import { StateMap } from '../services/common';
import { UtilFns } from './util-fns';
import { PageState } from '../controls/common';

export class LocationFns {

    static getTab(location: Location, partIx: number = 0) {
        const tab = this.getParamValue(location, 'tab');
        if (tab) {
            const tabs = tab.split('.');
            const tabNum = parseInt(tabs[partIx]);
            if (!isNaN(tabNum)) {
                return tabNum;
            }
        }
        return null;
    }

    static setTab(location: Location, tab: number | string) {
        setTimeout(() => {
            this.setParamValue(location, 'tab', tab.toString(), true);
        });
    }

    static getParamValue(location: Location, key: string) {
        const parts = UtilFns.parseUrl(location.path());
        const value = parts.paramHash[key];
        return value || null;
    }

    static setParamValue(location: Location, key: string, value: string, canReplace?: boolean) {
        const parts = UtilFns.parseUrl(location.path());
        if (parts.paramHash[key] == value) {
            return;
        }
        let shouldReplace: boolean;
        if (canReplace) {
            shouldReplace = parts.paramHash[key] == null;
        } else {
            shouldReplace = false;
        }

        parts.paramHash[key] = value;
        if (shouldReplace) {
            location.replaceState(UtilFns.formatUrl(parts));
        } else {
            location.go(UtilFns.formatUrl(parts));
        }
    }

    static updatePageState(location: Location, stateMap: StateMap, currentState: PageState, updateFn?: (savedState: PageState) => void) {

        return LocationFns.updateState(location, stateMap, currentState, (savedState: PageState) => {
            _.forEach(savedState, (v, k) => {
                const pd = Object.getOwnPropertyDescriptor(currentState, k);
                // if a normal property or a simple writable property or has a setter then go for it.
                if ((!pd) || ((pd.value !== undefined) && pd.writable) || pd.set) {
                    currentState[k] = v;
                }
            });
            if (updateFn) {
                updateFn(savedState);
            }
        });

    }

    // returns true if retrieved an existing state
    static updateState(location: Location, stateMap: StateMap, currentState: {}, updateFn: (savedState: {}) => void) {

        const parts = UtilFns.parseUrl(location.path());
        const retKey = parts.paramHash['ret'];
        let retIx: number;
        if (retKey) {
            retIx = parseInt(retKey);
            const savedState = stateMap.getRetState(retIx);
            // if a savedState AND a matching savedState.
            if (savedState && savedState['displayName'] == currentState['displayName']) {
                updateFn(savedState);
                stateMap.storeRetState(currentState, retIx);
                return true;
            }
        }
        retIx = stateMap.storeRetState(currentState);
        // move 'ret' to the end.
        delete parts.paramHash['ret'];
        parts.paramHash['ret'] = retIx.toString();

        location.replaceState(UtilFns.formatUrl(parts));
        return false;
    }


}

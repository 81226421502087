<div>
    <span *ngIf="_edm.editing" class="inline">
        <span class="inline" style="font-size: 18px;">{{_adding ? "Adding an Exposure Survey" : "Editing an Exposure Survey" }}</span>
        <img style="margin-left: 10px; height: 40px; width: 42px;" class="inline" src="assets/images/ExposureSurvey_WhiteBackground.png" />
    </span>

    <editor-header [edm]="_edm" [customButtons]="true">
        <span customButtons class="inline" style="margin-left: 10px; margin-top: 6px;">
            <button *ngIf="!_edm.editing" type="button" class="btn btn-sm btn-primary" [disabled]="!canSave()" (click)="onSave()">Save</button>
            <button *ngIf="_edm.editing" type="button" class="btn btn-sm btn-primary" [disabled]="!canApply()" (click)="onApply()">Apply</button>
            <button *ngIf="canShowBack()" type="button" class="btn btn-sm btn-primary" (click)="onBack()">Back</button>
            <button *ngIf="canCancel()" type="button" class="btn btn-sm btn-primary" (click)="onCancel()">Cancel</button>
        </span>
    </editor-header>
    <span *ngIf="(!_edm.editing)">
        <button type="button" class="inline btn btn-survey" style="width: 170px;" (click)="onAdd()">Add Exposure Survey</button>
    </span>
</div>
<div class="" *ngIf="_validationMessage">
    <p class="alert-danger">{{_validationMessage}}</p>
</div>
<br />
<div class="exposure-survey-container" [attr.data-hidden]="_edm.editing">
    <div class="float-right" style="margin-left: 4px;">
        <p class="bold">Click on a row to view summary statistics.</p>
    </div>
    <div *ngIf="_exposureSurveysEx && _exposureSurveysEx.length" style="border-bottom: 1px solid #424242; width: 1072px;">
        <div>
            <div class="scrollableTableHeader">
                <div class="scrollableTableColumn text-center"><span class="inline" style="width: 55px">Edit</span></div>
                <div class="scrollableTableColumn text-center"><span class="inline" style="width: 75px">Remove</span></div>
                <div class="scrollableTableColumn text-center"><span class="inline" style="width: 120px">Survey Date</span></div>
                <div class="scrollableTableColumn text-center"><span class="inline" style="width: 60px">Survey Number</span></div>
                <div class="scrollableTableColumn text-left"><span class="inline" style="width: 130px">Survey Status</span></div>
                <div class="scrollableTableColumn text-left"><span class="inline" style="width: 280px">Description</span></div>
                <div class="scrollableTableColumn text-center"><span class="inline" style="width: 60px">Creme Version</span></div>
                <div class="scrollableTableColumn text-center"><span class="inline" style="width: 113px">Survey Materials</span></div>
            </div>
            <div style="height: 240px; overflow-y:auto;overflow-x: hidden;">
                <div class="scrollableTableRow" *ngFor="let es of _exposureSurveysEx" (click)="showExposureSurveyStatistics(es)"
                     [ngStyle]="{ 'background-color': es.backgroundColor }">
                    <div class="scrollableTableColumn text-center"><a class="inline" style="width: 55px" (click)="onEdit(es)">Edit</a></div>
                    <div class="scrollableTableColumn text-center"><a class="inline" style="width: 75px" (click)="onRemove(es)">Remove</a></div>
                    <div class="scrollableTableColumn text-center"><span class="inline" style="width: 120px">{{es.data.exposureSurveyMonthYear}}</span></div>
                    <div class="scrollableTableColumn text-center"><span class="inline" style="width: 60px">{{es.data.exposureSurveyNumber}}</span></div>
                    <div class="scrollableTableColumn text-left"><span class="inline" style="width: 130px">{{es.data.typeExposureSurveyStatusId}}</span></div>
                    <div class="scrollableTableColumn text-left"><span class="inline" style="width: 280px">{{es.data.description}}</span></div>
                    <div class="scrollableTableColumn text-center"><span class="inline" style="width: 60px">{{es.data.typeCremeVersion.cremeVersion}}</span></div>
                    <div class="scrollableTableColumn text-center"><a class="inline" style="width: 90px" (click)="onShowMaterialList(es)">Material List</a></div>
                </div>
            </div>
        </div>
    </div>

    <div style="margin-top: 36px; border: 1px solid #e7e7e7;" *ngIf="_exposureSurveyStatistics">
        <div style="background: #ddd; border: 1px solid #e7e7e7; width: 913px;">
            <div>
                <span class="inline text-bold text-left page-subtitle">Survey Date: </span>
                <span class="inline text-bold text-left page-subtitle">{{_selectedExposureSurvey.exposureSurveyMonthYear}}</span>
                <br />
                <span class="inline text-bold text-left page-subtitle">Survey Number: </span>
                <span class="inline text-bold text-left page-subtitle">{{_selectedExposureSurvey.exposureSurveyNumber}}</span>
            </div>
        </div>
        <div class="row col-xs-12" style="margin-top: 8px;">
            <div class="float-left" style="width: 30%;">
                <label class="text-left" style="width: 200px;">Number of Materials:</label>
                <span class="text-left" style="width: 80px;">{{_exposureSurveyStatistics.numberOfMaterials}}</span>
            </div>
            <div class="float-right" style="width: 70%;">
                <label class="text-left" style="width: 340px;">Materials with Status Change of Has Data:</label>
                <span class="text-left" style="width: 80px;">{{_exposureSurveyStatistics.numberOfMaterialsWithStatusChangeHasData}}</span>
            </div>
        </div>

        <div class="row col-xs-12" style="margin-top: 8px;">
            <div class="float-left" style="width: 30%;">
                <label class="text-left" style="width: 200px;">Materials with Data:</label>
                <span class="text-left" style="width: 80px;">{{_exposureSurveyStatistics.numberOfMaterialsWithExposureData}}</span>
            </div>
            <div class="float-right" style="width: 70%;">
                <label class="text-left" style="width: 340px;">Materials with Status Change of Has No Data:</label>
                <span class="text-left" style="width: 80px;">{{_exposureSurveyStatistics.numberOfMaterialsWithStatusChangeNoData}}</span>
            </div>
        </div>

        <div class="row col-xs-12" style="margin-top: 8px;">
            <div class="float-left" style="width: 30%;">
                <label class="text-left" style="width: 200px;">Materials without Data:</label>
                <span class="text-left" style="width: 80px;">{{_exposureSurveyStatistics.numberOfMaterialsWithoutExposureData}}</span>
            </div>
        </div>
    </div>

</div>

<div *ngIf="_edm.editing">
    <exposure-survey-editor #self [entity]="_edm.currentEntity"></exposure-survey-editor>
</div>

<confirm-finish-override-dialog></confirm-finish-override-dialog>

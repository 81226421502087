<div class="form-horizontal indent-top-sm">

    <edit-prop label="Product Name" prop="productName" szs="2,7">
        <input class="form-control" type="text" [(ngModel)]="entity.productName">
    </edit-prop>

    <edit-prop label="Product Description" prop="productDescription" szs="2,9">
        <textarea class="form-control" rows=3 [(ngModel)]="entity.productDescription"></textarea>
    </edit-prop>

    <edit-prop label="Version" prop="productVersion" szs="2,2">
        <input class="form-control" type="text" [(ngModel)]="entity.productVersion">
    </edit-prop>

    <edit-prop label="Link" prop="productLink" szs="2,3">
        <input class="form-control" type="text" [(ngModel)]="entity.productLink">
    </edit-prop>

    <edit-prop label="Link Type" prop="linkTargetType" szs="2,3">
        <select class="form-control" *ngIf="_typeLinkTargets" [ngModel]="entity.linkTargetType" (change)="entity.linkTargetType=$event.target.value">
            <option disabled selected value>-- None --</option>
            <option *ngFor="let t of _typeLinkTargets" [value]="t.linkTargetType">{{t.linkTargetType}}</option>
        </select>
    </edit-prop>

    <edit-prop label="Content Type" prop="contentType" szs="2,3">
        <input class="form-control" type="text" [(ngModel)]="entity.contentType">
    </edit-prop>

    <edit-prop label="Enabled" prop="enabled" szs="2,2">
        <input class="form-control" type="checkbox" [(ngModel)]="entity.enabled">
    </edit-prop>

</div>
import { Component, ElementRef, OnInit } from '@angular/core';
import * as _ from 'lodash';

import { UnitOfWork } from '../../services/common';
import { UtilFns } from '../../utils/common';
import { ModalDialog, PageState } from '../../controls/common';

import { Project } from '../../entities/EntityModels';

@Component({
    selector: 'project-selector',
    templateUrl: './project-selector.html',
})
export class ProjectSelectorComponent extends ModalDialog<Project> implements OnInit {
    _searchTerm: string;
    _entities: Project[] = [];
    _pageState = new PageState('Journals');

    constructor(elementRef: ElementRef, public _uow: UnitOfWork) {
        super(elementRef);
    }

    ngOnInit() {
        this.refresh();
    }

    refresh() {
        this._pageState.isLoading = true;
        this._uow.projectRepository.all().then(r => {
            this._pageState.isLoaded = r;
            this._entities = _.sortBy(r, p => p.projectName.toLocaleLowerCase());
        });
    }

    onSelect(project: Project) {
        UtilFns.returnModal(this, project);
    }
}


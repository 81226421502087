import { Component } from '@angular/core';


@Component({
    selector: 'default-table',
    template: `
    <div class="row indent-left-row">
        <ng-content select="[header]"></ng-content>
        <div class="table-responsive">
            <table class="table table-striped table-hover table-bordered table-condensed small" style="width: auto;margin-right: 30px">
                <ng-content select="thead"></ng-content>
                <ng-content select="tbody"></ng-content>
            </table>
        </div>
        <ng-content select="[footer]"></ng-content>
    </div>
    `
})
export class DefaultTableComponent {
}

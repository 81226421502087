import { EntityBase } from './EntityBase';
import { Material } from './Material';
import { ExpertPanelSummary } from './ExpertPanelSummary';

import { Validator } from 'breeze-client';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

export class MaterialExpertPanelSummary extends EntityBase {

   /// <code> Place custom code between <code> tags
   
   /// </code>

   // Generated code. Do not place code below this line.
   materialId: number;
   expertPanelID: number;
   review: string;
   created: Date;
   createdUser: string;
   modified: Date;
   modifyUser: string;
   rowVersion: number;
   expertPanelSummary: ExpertPanelSummary;
   material: Material;
}


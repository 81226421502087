import { Component, ElementRef, Input, OnInit } from '@angular/core';

import { UnitOfWork } from '../../services/common';
import { UtilFns } from '../../utils/common';
import { ModalDialog, PageState } from '../../controls/common';

import { FSAG } from '../../entities/EntityModels';

@Component({
    selector: 'fsag-selector',
    templateUrl: './fsag-selector.html',
})
export class FSAGSelectorComponent extends ModalDialog<FSAG> implements OnInit {
    @Input() FSAGs: FSAG[];
    _searchTerm: string;
    _entities: FSAG[] = [];
    _pageState = new PageState('Fragrance Structure Activity Groups');

    constructor(elementRef: ElementRef, public _uow: UnitOfWork) {
        super(elementRef);
    }

    ngOnInit() {
        this._entities = this.FSAGs;
    }

    canSearch() {
        // return this._searchTerm && this._searchTerm.length > 1;
        return true;
    }

    search() {
        this.validationMessage = null;

        this._entities = null;
        const term = (this._searchTerm || '').toLowerCase();
        this._entities = this.FSAGs.filter(f => f.normNote.indexOf(term) > -1);
    }

    reset() {
        this._entities = [];
        this._searchTerm = '';
    }

    onSelect(fsag: FSAG) {
        UtilFns.returnModal(this, fsag);
    }


}


import { EntityBase } from './EntityBase';
import { Material } from './Material';
import { TypeSafetyEU } from './TypeSafetyEU';

import { Validator } from 'breeze-client';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

export class SafetyEUComment extends EntityBase {

   /// <code> Place custom code between <code> tags
   
   /// </code>

   // Generated code. Do not place code below this line.
   safetyEUCommentId: number;
   materialId: number;
   typeSafetyEUId: string;
   author: string;
   comment: string;
   approved: boolean;
   qualifiers: string;
   deletedDate: Date;
   deletedBy: string;
   lMApprovedDate: Date;
   lMApprovedBy: string;
   deleteNextLM: boolean;
   created: Date;
   createdUser: string;
   modified: Date;
   modifyUser: string;
   rowVersion: number;
   material: Material;
   typeSafetyEu: TypeSafetyEU;
}


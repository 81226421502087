<p class="page-title">Replacing a Reference Document File</p>
<div style="margin-top: 24px;">
    <button type="button" class="btn btn-sm btn-primary" [disabled]="!canSave()" (click)="onSave()">Replace RIFM Document File</button>
    <button *ngIf="canCancel()" type="button" class="btn btn-sm btn-primary" (click)="onCancel()">Cancel</button>
    <button *ngIf="canShowBack()" type="button" class="btn btn-sm btn-primary" (click)="onBack()">Back</button>
</div>

<div class="replace-reference-document-file-grid">
    <!-- Row 1 -->
    <span class="bold item-replace-for-referenceid-label">Replace file for ReferenceId:</span>
    <div class="item-replace-for-referenceid centered background-aliceblue">
        <span class="bold text-center">{{_data.referenceId}}</span>
    </div>

    <!-- Row 2 -->

    <label class="fileContainer" class="item-choose-file-for-replace">
        <input type="file" accept=".pdf" id="fileInputElement" (change)="onFileInput($event.target.files)"/>
    </label>

    <span class="alert-message item-replace-file-message">{{_validationMessage}}</span>
</div>


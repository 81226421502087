<div class="form-horizontal">
    
    <edit-prop label="Agency Id" prop="evaluatingAgencyId" szs="2,5" >
        <select class="form-control" [ngModel]="entity.evaluatingAgencyId" (change)="entity.evaluatingAgencyId=$event.target.value" >
            <option disabled selected value>-- None --</option>
            <option *ngFor="let trs of _typeRegulatoryStatuses" [value]="trs.evaluatingAgencyId">{{trs.evaluatingAgencyId + ' - ' + trs.agencyTitle}}</option>
        </select>
    </edit-prop>        

    <edit-prop label="Evaluator Id" prop="evalutatorId" szs="2,3" >
        <input class="form-control" type="text" [(ngModel)]="entity.evaluatorId">
    </edit-prop>

    <edit-prop label="Reference" prop="reference" szs="2,3" >
        <input class="form-control" type="text" [(ngModel)]="entity.reference">
    </edit-prop>

    <edit-prop label="Determination" prop="determination" szs="2,3" >
        <input class="form-control" type="text" [(ngModel)]="entity.determination">
    </edit-prop>

    <edit-prop label="Web Reference" prop="webReference" szs="2,3" >
        <input class="form-control" type="text" [(ngModel)]="entity.webReference">
    </edit-prop>

    <edit-prop label="Link Display" prop="linkDisplay" szs="2,4" >
        <input class="form-control" type="text" [(ngModel)]="entity.linkDisplay">
    </edit-prop>

    <edit-prop label="Note" prop="note" szs="2,3" >
        <input class="form-control" type="text" [(ngModel)]="entity.note">
    </edit-prop>


</div>
import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'confirm-finish-override-dialog',
    templateUrl: './confirm-finish-override.html'
})
export class ConfirmFinishOverrideComponent implements OnInit {
    _title: string;
    _message: string;
    _reasons: string[];
    _buttonNames: string[];
    _result: boolean;

    ngOnInit() {

    }

    select(isYes: boolean) {
        this._result = isYes;
        this.getModalEle().modal('hide');
    }

    showModal(title: string, message: string, reasons: string[], buttonNames?: string[]): Promise<boolean> {
        this._title = title;
        this._message = message;
        this._reasons = reasons;
        this._buttonNames = buttonNames || ['Yes', 'No'];
        const ele = this.getModalEle();
        const p = new Promise((resolve, reject) => {
            ele.modal({ backdrop: 'static', keyboard: false }).on('hidden.bs.modal', () => {
                resolve(this._result);
            });
        });
        return <any>p;
    }

    getModalEle(): any {
        return jQuery('#confirm-finish-override-modal');
    }
}


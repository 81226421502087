<div>
    <page-status [attr.data-hidden]='_editing' [pageState]="_pageState"></page-status>
</div>

<p class="alert-warning bold">{{_validationMessage}}</p>

<div *ngIf="hasAssessment()" style="margin-bottom: 20px">
    <div class="page-title" style="margin-bottom: 15px">Fragrance Safety Assessment Report</div>
    <button type="button" class="btn btn-sm btn-primary" (click)="downloadReport()">{{ _material.assessmentReportName }}</button>
</div>

<div *ngIf="hasElsevierAssessment()" style="margin-bottom: 20px">
    <div class="page-title" style="margin-bottom: 15px">Elsevier Fragrance Safety Assessment Report</div>
    <button type="button" class="btn btn-sm btn-primary" (click)="downloadElsevierReport()">{{ _material.elsevierReportName }}</button>
</div>

<div *ngIf="_assessmentCategories && _assessmentCategories.length" class="row" style="margin-left: 5px">
    <div class="page-title">Fragrance Safety Assessment Categories</div>
    <default-table style="margin-bottom: 5px">
        <thead>
            <tr>
                <td>Category</td>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let ac of _assessmentCategories">
                <td>{{ac.name}}</td>
            </tr>
        </tbody>
    </default-table>
</div>

<div class="row form-group panel col-xs-6" *ngIf="!!_material.sACategoryComment">
    <label class="col-xs-2 text-left">Comment</label>
    <div class="col-xs-8 break-word">{{_material.sACategoryComment}}</div>   
</div>

<div *ngIf="_materialListItems && _materialListItems.length" class="row" style="margin-left: 5px">
    <div class="page-title">Additional Related Materials</div>
    <default-table style="margin-bottom: 5px">
        <thead>
            <tr>
                <td>CAS No.</td>
                <td>RIFM ID</td>
                <td>FEMA No.</td>
                <td>EINECS</td>
                <td>ECN</td>
                <td>Principal Synonym</td>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let m of _materialListItems" (click)="selectMaterial(m)">
                <td>{{m.realCASNumber}}</td>
                <td>{{m.formattedRifmId}}</td>
                <td>{{m.fEMANumber}}</td>
                <td>{{m.eINECSNumber}}</td>
                <td>{{m.eCNNumber}}</td>
                <td [htmlGreek]="m.synonymWordOrWebVersion"></td>
            </tr>
        </tbody>
    </default-table>
</div>

<div class="col-xs-12">
    <h4 class="bold">Structure Alerts Components</h4>
    <div class="row" style="margin-left: 4px;" *ngIf="_scoreNotFound == true">None found</div>

    <div class="row" *ngIf="_score">
        <div class="panel panel-default col-xs-8 " style="margin-left: 15px; margin-right: 30px;">
            <div class="panel-body">
                <div class="row">
                    <div class="col-xs-6">
                        <strong class="col-xs-8">Carcinogenic Effects:</strong>
                        <div class="col-xs-2">{{ _score.carcinogenicEffects}}</div>
                    </div>
                    <div class="col-xs-6">
                        <strong class="col-xs-8">Concentration Level Score:</strong>
                        <div class="col-xs-2">{{ _score.concentrationLevelScore}}</div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xs-6">
                        <strong class="col-xs-8">Systemic Effects:</strong>
                        <div class="col-xs-2">{{ _score.systemicEffects}}</div>
                    </div>
                    <div class="col-xs-6">
                        <strong class="col-xs-8">Volume Score:</strong>
                        <div class="col-xs-2">{{ _score.volumeScore}}</div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xs-6">
                        <strong class="col-xs-8">Topical Effects:</strong>
                        <div class="col-xs-2">{{ _score.topicalEffects}}</div>
                    </div>
                    <div class="col-xs-6">
                        <strong class="col-xs-8">Evaluation Priority:</strong>
                        <div class="col-xs-2">{{ _score.evaluationPriority}}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="_materialAlertRules && (_materialAlertRules.length > 0)" class="row">
        <default-table class="col-xs-3">
            <thead>
                <tr>
                    <td><a [colSorter]="_colSorter">Rule</a></td>
                    <td>Partial Structure</td>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let e of _materialAlertRules">
                    <td>{{e.rule}}</td>
                    <td *ngIf="hasStockImage(e)"><img [src]="getStockImage(e)" [alt]="" /></td>
                    <td *ngIf="hasStockImage(e) == false">{{e.typeAlertRule.description}}</td>                     
                </tr>
            </tbody>
        </default-table>
        <default-table *ngIf="material.smiles" class="col-xs-3">
            <thead>
                <tr>
                    <td>Material Structure</td>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td><img [src]="getSmileUrl(material)" /></td>
                </tr>
            </tbody>
        </default-table>
    </div>

    <div class="row" style="margin-left: 4px;">
        <h5 class="bold">Reference</h5>
        <div>
            <a (click)="linkTo('Ford, 2000.pdf')">Ford R.A., Domeyer B., Easterday O., Maier K. and Middleton J. (2000)</a>
            <span class="inline">Criteria for development of a database for safety evaluation of fragrance ingredients. Regulatory Toxicology and Pharmacology, 31, 166-181</span>
        </div>

    </div>
</div>
<div *ngIf="(!_data.inPDFSubEditor && !_data.adding && !_data.replacing && !_data.editingPDFPages)" class="flex-container background-transparent">
    <div class="flex-item" style="width: 15%;">
        <a class="bold item-selector-toggle" (click)="onSetSelectorVisibility()">{{rifmDocumentSelectorNextAction}}</a>
    </div>

    <div class="flex-item" style="width: 45%;">
        <span class="alert-message inline">{{_validationMessage}}</span>
    </div>
    <div class="flex-item">
        <span>
            <button type="button" class="inline btn btn-add-rifmdocument" style="width: 220px;" (click)="onAdd()">Add Reference Document</button>
        </span>
    </div>
</div>

<div class="row">
    <i *ngIf="_isLoading || _isSavingPDFFile" class="fa fa-circle-o-notch fa-spin" style="font-size:38px; color: #337ab7;"></i>
    <span class="alert-message" style="margin-left: 10px;">{{_pdfProcessingMessage}}</span>
</div>

<!-- Document Selector Component -->
<div *ngIf="showRIFMDocumentSelector" style="border-right: 1px solid #6E7B8B; height:auto; width:80%;" [ngStyle]="{ 'display': _selectorDisplay}">
    <div style="width: 98%;">
        <div style="display: inline-block; margin-top: 18px;">
            <document-selector [showSelectors]="true" [searchTerm]="_paramSearchTerm" (stateChange)="documentSelectorEventHandler($event)"></document-selector>
        </div>
    </div>
</div>

<div [ngStyle]="{ 'width': _editorWidth}" style="margin-top: 30px;">

    <!-- Editing Buttons for PDF  -->
    <div>
        <span *ngIf="_data.editingPDF" class="inline">
            <span class="inline" style="font-size: 18px;">Editing a Reference PDF Document File</span>
            <button *ngIf="canSave()" type="button" class="btn btn-sm btn-primary" [disabled]="!canSave()" (click)="onSave()">Save PDF Edits</button>
            <button *ngIf="canCancel()" type="button" class="btn btn-sm btn-primary" (click)="onCancel()">Cancel PDF Edits</button>
            <span class="alert-message inline" style="margin-left: 18px;">{{_pdfEditorMessage}}</span>
            <button *ngIf="canShowBack()" type="button" class="btn btn-sm btn-primary" (click)="onBack()">Close PDF Editor</button>
        </span>
    </div>

    <!-- PDF Editor and Viewer Component -->
    <div style="margin-top: 24px;">
        <pdf-editor [serviceUrl]="_stateMap.documentManagementUrl" (stateChange)="pdfEditorEventHandler($event)" [ngStyle]="{'display': pdfEditorDisplayElement}"></pdf-editor>
    </div>

</div>

<!-- Add Reference Document Component -->
<div *ngIf="_data.adding" style="position: absolute; top: 0px;">
    <add-reference-document [documentManagementUrl]="_stateMap.documentManagementUrl"></add-reference-document>
</div>

<!-- Replace Reference Document File Component -->
<div *ngIf="_data.replacing" style="position: absolute; top: 0px;">
    <replace-reference-document [documentManagementUrl]="_stateMap.documentManagementUrl"></replace-reference-document>
</div>

<div *ngIf="_data.editingPDFPages && _selectedRIFMDocument != null" style="position: absolute; top: 0px;">
    <edit-pdf-pages [referenceId]="this._selectedRIFMDocument.referenceId" [rifmDocumentId]="this._selectedRIFMDocument.rIFMDocumentId"></edit-pdf-pages>
</div>

<confirm-finish-override-dialog></confirm-finish-override-dialog>



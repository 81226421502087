import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';

import { StateMap, UnitOfWork } from '../../services/common';
import { LocationFns } from '../../utils/common';
import { TabContainer } from '../../controls/common';
import { STAFF_ROUTES } from './staff.routes';

export class PredictionEditData {
    inSubEditor: boolean;
    currentTab: number;
}

@Component({
    selector: 'type-prediction-editor-base',
    templateUrl: './type-prediction-editor-base.html',
})
export class TypePredictionEditorBaseComponent implements OnInit, AfterViewInit {
    @ViewChild(TabContainer, { static: true }) _tabContainer: TabContainer;

    _data: PredictionEditData = new PredictionEditData();

    _tab: number;

    constructor(private _uow: UnitOfWork, private _stateMap: StateMap, public _location: Location) {
        this._data.inSubEditor = false;
    }

    ngOnInit() {

        this._stateMap.currentRouteName = STAFF_ROUTES.TypePredictionEditorBaseComponent.name;

        this._tab = LocationFns.getTab(this._location) || 0;
    }

    ngAfterViewInit() {
        this.focusInitialTab();
    }

    canDeactivate() {
        return !this._data.inSubEditor && !this._uow.hasChanges();
    }

    focusInitialTab() {
        setTimeout(() => {
            if (this._tabContainer) {
                this._tabContainer.selectTab(this._tab);
            }
        }, 1);
    }
}

<div>
    <span class="page-title">{{pageTitle()}}</span>
</div>

<div class="row" style="margin-left: 10px;">
    <span class="alert-message item-message">{{_validationMessage}}</span>
</div>

<div class="row">
    <i *ngIf="_isLoading" class="fa fa-circle-o-notch fa-spin" style="font-size:38px; color: #337ab7;"></i>
</div>


<div *ngIf="isAdding() || isEditing()">
    <div class="float-left">
        <edit-prop label="Organization Name" prop="organizationName" szs="2,5">
            <input class="form-control" type="text" (change)="_organizationNameChanged=true" (blur)="onChangeOrganizationName($event.target.value)" [(ngModel)]="entity.organizationName">
        </edit-prop>
    </div>

    <div class="float-left">
        <edit-prop label="Notes" prop="notes" szs="2,5">
            <textarea class="form-control" rows=3 [(ngModel)]="entity.notes"></textarea>
        </edit-prop>
    </div>

    <div class="float-left" style="margin-top: 38px;">
        <div *ngIf="hasCalculatedDistances()">
            <h6 class="page-subtitle bold">These Organization Names are similar to the one you entered.</h6>
            <table class="table table-striped table-bordered table-condensed small" style="width:auto;">
                <thead style="background-color: #C1FFC1; color: #000000;">
                <tr>
                    <td>Organization Name</td>
                    <td>Difference Score</td>
                    <td>Percent Difference</td>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let dist of _organizationNameStringDistances">
                    <td>{{dist.comparedString}}</td>
                    <td>{{dist.distance}}</td>
                    <td>{{utilFns.formatNumber(dist.percentDifference, 3,  2)}}</td>
                </tr>
                </tbody>
            </table>
        </div>

    </div>

</div>

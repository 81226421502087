<div class="outermost">
    <div>
        <page-status [pageState]="_pageState"></page-status>
    </div>

    <default-table *ngIf="_crossTab && (_crossTab.length > 0)">
        <h4 header>Kgs/Year</h4>
        <thead>
            <tr>
                <td>Year</td>
                <td>China</td>
                <td>Japan</td>
                <td>Asia Pacific</td>
                <td>Europe</td>
                <td>North America</td>
                <td>South America</td>
                <td>Other</td>
                <td>Total</td>

            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let ent of _crossTab" (click)="onSelect(ent)">
                <td class='bold'>{{ent.year}}</td>
                <!--<td align="right">{{ent['China'] | number:'1.3-3'}}</td> -->
                
                <td align="right">{{ _stateMap.utilFns.formatNumber(ent['China'], 9, 3) }}</td>
                <td align="right">{{ _stateMap.utilFns.formatNumber(ent['Japan'], 9, 3) }}</td>
                <td align="right">{{ _stateMap.utilFns.formatNumber(ent['Asia-Pacific'], 9, 3) }}</td>
                <td align="right">{{ _stateMap.utilFns.formatNumber(ent['Europe'], 9, 3) }}</td>
                <td align="right">{{ _stateMap.utilFns.formatNumber(ent['North America'], 9, 3) }}</td>
                <td align="right">{{ _stateMap.utilFns.formatNumber(ent['South America'], 9, 3) }}</td>
                <td align="right">{{ _stateMap.utilFns.formatNumber(ent['Other'], 9, 3) }}</td>
                <td align="right" class='bold'>{{ _stateMap.utilFns.formatNumber(ent.total, 9, 3) }}</td>
            </tr>
            <tr>
                <td class='bold'>Totals</td>
                <td align="right">{{ _stateMap.utilFns.formatNumber(_totals['China'], 9, 3) }}</td>
                <td align="right">{{ _stateMap.utilFns.formatNumber(_totals['Japan'], 9, 3) }}</td>
                <td align="right">{{ _stateMap.utilFns.formatNumber(_totals['Asia-Pacific'], 9, 3) }}</td>
                <td align="right">{{ _stateMap.utilFns.formatNumber(_totals['Europe'], 9, 3) }}</td>
                <td align="right">{{ _stateMap.utilFns.formatNumber(_totals['North America'], 9, 3) }}</td>
                <td align="right">{{ _stateMap.utilFns.formatNumber(_totals['South America'], 9, 3) }}</td>
                <td align="right">{{ _stateMap.utilFns.formatNumber(_totals['Other'], 9, 3) }}</td>
                <td align="right" class='bold'>{{ _stateMap.utilFns.formatNumber(_totals.total, 9 , 3) }}</td>
            </tr>
        </tbody>

        <div footer>Trace amounts (&lt; 1 mg) are recorded as 0.001 kgs.</div>
    </default-table>
</div>
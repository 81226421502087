<div id="WorkflowActivityReport" class="modal fade bs-example-modal-lg" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header custom-header">
                <span class="modal-title" id="myModalLabel">Workflow Activity Report</span>
                <span class="no-print">
                    <button type="button" class="btn btn-default pull-right text-uppercase text-bold" data-dismiss="modal"><span class="fa fa-times"></span> Close</button>
                    <button (click)="printWorkflowActivityReport()" type="button" class="btn btn-default pull-right text-uppercase text-bold"><span class="fa fa-print"></span> Print</button>
                </span>
            </div>
            <div class="modal-body custom-body">
                <div class="row">
                    <div class="col-sm-6">
                        <table class="table table-striped table-hover table-bordered table-condensed" style="width: auto;">
                            <thead>
                                <tr>
                                    <td></td>
                                    <td>Action</td>
                                    <td class="text-center">Completed Count</td>
                                    <td class="text-center">Both</td>
                                    <td class="text-center">RIFM</td>
                                    <td class="text-center">FEMA</td>

                                </tr>
                            </thead>
                            <tbody *ngFor="let group of _activityGroups; let ix=index">
                                <tr class="header bold">
                                    <td>
                                        <a (click)="expCollapse(ix)">
                                            <span *ngIf="group.collapsed" class="fa fa-caret-down"></span>
                                            <span *ngIf="!group.collapsed" class="fa fa-caret-up"></span>
                                        </a>
                                    </td>
                                    <td colspan="6" style="font-size: 12px;" class="bold">
                                        {{group.name}}
                                    </td>
                                </tr>
                                <ng-template [ngIf]="!group.collapsed">
                                    <tr class="body" *ngFor="let actions of group.workflowCompletedActions" style="font-size: 11px;">
                                        <td></td>
                                        <td>{{actions.typeWorkflowActionId}}</td>
                                        <td class="text-center">{{actions.count}}</td>
                                        <td class="text-center">{{actions.countBoth}}</td>
                                        <td class="text-center">{{actions.countRIFM}}</td>
                                        <td class="text-center">{{actions.countFEMA}}</td>
                                    </tr>
                                </ng-template>
                            </tbody>
                        </table>
                    </div>
                    <div class="col-sm-4">
                        <div>
                            <button type="button" class="btn btn-primary btn-xs" (click)="expandAll(true)">Expand All</button>
                            <button type="button" class="btn btn-primary btn-xs" (click)="expandAll(false)">Collapse All</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer border-zero no-print">
    </div>
</div>

<print-to-pdf-doc></print-to-pdf-doc>

<div class="form-horizontal">

    <edit-prop label="Relation Type" prop="typeMaterialRelationId" szs="2,4">
        <select class="form-control" [disabled]="isDeleted()" [ngModel]="typeMaterialRelationId" (change)="onSetRelationId($event.target.value)">
            <option selected value="-1">-- Not selected --</option>
            <option *ngFor="let type of _types" [value]="type.typeMaterialRelationId">{{ type.relationType}}</option>
        </select>
    </edit-prop>

    <div class="form-group">
        <label class="control-label col-md-2">- or -</label>
    </div>

    <edit-prop label="Reciprocal Relation Type" prop="reciprocalTypeMaterialRelationId" szs="2,4">
        <select class="form-control" [disabled]="isDeleted()" [ngModel]="reciprocalTypeMaterialRelationId" (change)="onSetReciprocalRelationId($event.target.value)">
            <option selected value="-1">-- Not selected --</option>
            <option *ngFor="let type of _reciprocalTypes" [value]="type.typeMaterialRelationId">{{ type.reciprocalRelationType != null ? type.reciprocalRelationType : type.relationType }}</option>
        </select>
    </edit-prop>


    <div class="form-group">
        <button [disabled]="!_isNew || isDeleted()" type="button" class="col-md-2 btn btn-sm btn-primary" (click)="onSelectingMaterial()">Select related material</button>
        <div *ngIf="_relatedMaterial" class="col-md-4">
            <default-table>
                <thead>
                    <tr>
                        <td>CAS No.</td>
                        <td>RIFM ID</td>
                        <td>Principal Synonym</td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{{_relatedMaterial.realCASNumber}}</td>
                        <td>{{_relatedMaterial.formattedRifmId}}</td>
                        <td [htmlGreek]="_relatedMaterial.synonymWordOrWebVersion"></td>
                    </tr>
                </tbody>
            </default-table>
        </div>
        <span class="alert-danger">{{ _relatedMaterial == null ? 'A related material is required' : '' }}</span>
        <span class="alert-danger">{{ _editErrorMessage }}</span>
    </div>

    <div class="form-group has-success" *ngIf="_relatedMaterial != null && _material != null">
        <label class="control-label col-md-2">Result:</label>
        <div class="col-md-6" style="margin-top: 7px;">
            <span [htmlGreek]="_material.principalSynonymText()"></span>
            <span class="text-bold"> {{getRelationTypeText()}}</span>&nbsp;
            <span [htmlGreek]="_relatedMaterial.synonymWordOrWebVersion"></span>
        </div>
    </div>

</div>

<material-selector></material-selector>

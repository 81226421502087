import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { enableProdMode } from '@angular/core';
import { AppModule } from './app/app.module';
import { configRavenLogging } from './app/utils/logging';
import * as Raven from 'raven-js';
import { environment } from './environments/environment';

configRavenLogging();

if (environment.production) {
    enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
    .catch((reason: any) => {
        Raven.captureException(reason, { tags: { 'location': 'bootstrap' } });
        console.error(reason);
    });


import { Component, OnInit } from '@angular/core';
import { provideParent, UnitOfWork } from '../../services/common';
import { EditManager, HashMap } from '../../utils/common';
import { EditPropParent } from '../../controls/common';
import { AssessmentCategory } from '../../entities/AssessmentCategory';

export interface IAssessmentCategoryCount {
    assessmentCategoryId: number;
    assessmentsCount: number;
}

@Component({
    selector: 'safety-assessment-category-editor',
    templateUrl: './safety-assessment-category-editor.html',
    providers: [provideParent(SafetyAssessmentCategoryEditorComponent, EditPropParent)]
})
export class SafetyAssessmentCategoryEditorComponent implements OnInit, EditPropParent {
    assessmentCategories: AssessmentCategory[];
    assessmentCounts: HashMap<number> = {};
    entity: AssessmentCategory;
    edm: EditManager<AssessmentCategory>;
    _status: any;

    constructor(public _uow: UnitOfWork) {
    }

    get isEditing(): boolean {
        return !!this.entity;
    }

    ngOnInit() {
        this.load();
    }

    load() {
        return this._uow.assessmentCategoryRepository.all()
            .then(r => {
                return this._uow.fetch('Misc/AssessmentCategoryCounts', {}).then(c => {
                    this.assessmentCategories = r;
                    for (const count of <IAssessmentCategoryCount[]>c) {
                        this.assessmentCounts[count.assessmentCategoryId] = count.assessmentsCount;
                    }
                });
            });
    }

    canAdd() {
        return !this.isEditing;
    }

    onAdd() {
        this.entity = this._uow.assessmentCategoryFactory.create({ name: '' });
    }

    canSave() {
        return this.isEditing && !this.entity.entityAspect.entityState.isUnchanged() && this.isValid();
    }

    onSave() {
        this._uow.commit()
            .then(() => {
                this.entity = null;
                return this.load();
            });
    }

    canDelete() {
        return this.isEditing
            && this.entity.entityAspect.entityState.isUnchangedOrModified()
            && this.getAssessmentCount(this.entity.assessmentCategoryId) === 0;
    }

    onDelete() {
        this.entity.entityAspect.setDeleted();
    }

    canCancel() {
        return true;
    }

    onCancel() {
        this.entity.entityAspect.rejectChanges();
        this.entity = null;
    }

    isAdded() {
        return this.entity.entityAspect.entityState.isAdded();
    }

    isDeleted() {
        return this.entity.entityAspect.entityState.isDeleted();
    }

    isValid() {
        const ok = this.entity.entityAspect.validateEntity();
        return ok && !this.hasUniqueError;
    }

    getError(propName: string) {
        if (propName == 'name') {
            this.hasUniqueError = false;
            for (const ac of this.assessmentCategories) {
                if (ac !== this.entity && ac.name.trim() === this.entity.name.trim()) {
                    this.hasUniqueError = true;
                    return 'Assessment category Name needs to be unique';

                }
            }
        }
        return this.entity.getErrorFor(propName);
    }

    hasEdits() {
        return this.isEditing && this.entity.entityAspect.entityState.isAddedModifiedOrDeleted();
    }

    onCategorySelected(c: AssessmentCategory) {
        this.entity = c;
    }

    getAssessmentCount(assessmentCategoryId: number) {
        return this.assessmentCounts[assessmentCategoryId];
    }

    hasUniqueError: boolean;
}

import { Component, Input } from '@angular/core';
import { Material } from '../../entities/Material';

@Component({
    selector: 'material-summary',
    templateUrl: './material-summary.html',
})
export class MaterialSummaryComponent {
    @Input() material: Material;
}

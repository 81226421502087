<div class="indent-left-md">
    <div class="home-page-title">RIFM Tools and Models</div>
    
    <editor-header [edm]="_edm"></editor-header>

    <div *ngIf="_edm.hasEntities()" [attr.data-hidden]="_edm.editing">

        <div *ngFor="let e of _edm.entities" class="row equal">
            <div *ngIf="_edm.isStaff() || e.enabled" class="col-md-8">
                <div class="panel panel-default col-md-3" style="margin-right: 30px;">
                    <div class="panel-body" [class.alert-warning]="!e.enabled">
                        <div>
                            <img style="height: 35px; width: auto" [src]="getImage(e)" />
                            <span *ngIf="_edm.isStaff() && !e.enabled" class="bold" style="color: red">Disabled</span>
                            <button *ngIf="_edm.isStaff()" class="btn btn-sm btn-primary" (click)="_edm.onSelect(e)">Edit</button>
                        </div>
                        <div>
                            <label>{{formatProductName(e.productName)}}</label>
                        </div>
                        <div class="small">Version: {{e.productVersion}}</div>
                        <div *ngIf="!hasLicense(e)" style="margin-top: 10px"><a (click)="showLicense(e)">View and Accept License Agreement</a> </div>
                        <div *ngIf="hasLicense(e)" style="margin-top: 10px"><a (click)="onDownload(e)">{{getDownloadText(e)}}</a> </div>

                    </div>
                </div>
                <div class="panel panel-default col-md-4" style="margin-right: 30px;">
                    <div class="panel-body ">
                        <div>{{e.productDescription}}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="_edm.editing">
        <tools-and-model #self [entity]="_edm.currentEntity"></tools-and-model>
    </div>
</div>



<tools-and-models-license></tools-and-models-license>
<div id="MaterialWorkflowHistory" class="modal fade bs-example-modal-lg" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" style="height: 1360px;">
    <div class="modal-dialog modal-extra-lg" style="width: 1440px;">
        <div class="modal-content">
            <div class="modal-header custom-header">
                <span class="modal-title" id="myModalLabel">Material Reported Use Notes</span>
            </div>
            <div class="modal-body">
                <div style=width:100%;>
                    <button class="btn btn-primary navbar-btn" type="button" style="margin-left: 1300px;" (click)="onClose()">Close</button>
                </div>

                <div style="overflow-y: auto; overflow-x: hidden; height: auto; width: 100%; margin-top: -12px;">
                    <div class="row" style="margin-left: 80px;">
                        <i *ngIf="_loading" class="fa fa-circle-o-notch fa-spin" style="font-size:38px; color: #337ab7;"></i>
                    </div>

                    <div *ngIf="material != null" class="edit-material-reported-uses-grid-container" style="margin-left: 2px; background-color: #EAEAEA; padding: 8px; width: 75%; border: 1px solid lightgray;">
                        <!--row 2-->
                        <span class="bold item-edit-material-uses-materialId-label text-left">MaterialId:</span>
                        <span class="item-edit-material-uses-materialId text-left bold" style="color: #337ab7;">{{material.materialId}}</span>

                        <span class="bold item-edit-material-uses-cas-label text-left">CAS #:</span>
                        <span class="item-edit-material-uses-cas text-left">{{material.realCASNumber}}</span>

                        <span class="bold item-edit-material-uses-rifmId-label text-left">RIFM ID:</span>
                        <span class="item-edit-material-uses-rifmId text-left">{{material.formattedRIFMId}}</span>

                        <!--row 3-->
                        <span class="bold item-edit-material-uses-principal-name-label text-left">Principal Name:</span>
                        <span class="item-edit-material-uses-principal-name text-left">{{material.principalSynonymText()}}</span>

                    </div>
                </div>

                <div style="overflow-y: auto; overflow-x: hidden; height: 360px; width: 100%; margin-top: 12px;">
                    <div *ngIf="!_loading && (_workflowMaterialRecordedActions == null || _workflowMaterialRecordedActions.length == 0)">
                        <p *ngIf="activityType == 'all'" class="page-subtitle bold">
                            There is no Workflow History for this Material.
                        </p>
                        <p *ngIf="activityType == 'reporteduse'" class="page-subtitle bold">
                            There is no history of Reported Use changes for this Material.
                        </p>
                    </div>

                    <div *ngIf="_workflowMaterialRecordedActions != null && _workflowMaterialRecordedActions.length > 0" style="margin-left: 2px; width: 98%;">
                        <span class="inline">
                            <span class="bold page-subtitle" style="color: #EE7600;">***</span>
                            <span class="bold page-subtitle">Double-click a row to edit the notes data.</span>
                        </span>
                        <div>
                            <div class="scrollableTableHeader">
                                <div class="scrollableTableColumn text-center"><span class="inline" style="width: 75px">Date</span></div>
                                <div class="scrollableTableColumn text-center"><span class="inline" style="width: 90px">Completed By</span></div>
                                <div class="scrollableTableColumn text-left"><span class="inline" style="width: 180px">Action</span></div>
                                <div class="scrollableTableColumn text-left"><span class="inline" style="width: 320px">Action Details</span></div>
                                <div class="scrollableTableColumn text-left"><span class="inline" style="width: 320px">User Notes</span></div>
                                <div class="scrollableTableColumn text-center"><span class="inline" style="width: 130px">Field Affected</span></div>
                                <div class="scrollableTableColumn text-right"><span class="inline" style="width: 95px">Change Value</span></div>

                            </div>
                            <div style="height: 240px; overflow-y:auto;overflow-x: hidden;">
                                <div class="scrollableTableRow" *ngFor="let w of _workflowMaterialRecordedActions; let ix=index;" (dblclick)="onEdit(w)"
                                     [ngStyle]="{ 'background-color': rowColor(ix) }">

                                    <div class="scrollableTableColumn text-center"><span class="inline" style="width: 75px">{{utilFns.formatDate(w.created)}}</span></div>
                                    <div class="scrollableTableColumn text-center"><span class="inline" style="width: 90px">{{w.createdTypeWorkflowMaterialContact?.firstName || ''}}</span></div>
                                    <div class="scrollableTableColumn text-left"><span class="inline" style="width: 180px">{{w.typeWorkflowMaterialAction?.description}}</span></div>
                                    <div class="scrollableTableColumn text-left"><span class="inline" style="width: 320px">{{w.notes}}</span></div>
                                    <div class="scrollableTableColumn text-left"><span class="inline" style="width: 320px">{{w.userNotes}}</span></div>
                                    <div class="scrollableTableColumn text-center"><span class="inline" style="width: 130px">{{w.propertyName}}</span></div>
                                    <div class="scrollableTableColumn text-right"><span class="inline" style="width: 95px">{{materialWorkflowChangeValue(w)}}</span></div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>

                <div *ngIf="_isEditing" style="margin-top: 12px;">
                    <editor-header [edm]="_edm" [customButtons]="true">
                    <span customButtons class="inline" style="margin-left: 10px; margin-top: 6px;">
                        <button *ngIf="!_edm.editing" type="button" class="btn btn-sm btn-primary" [disabled]="!canSave()" (click)="onSave()">Save</button>
                        <button type="button" class="btn btn-sm btn-primary" [disabled]="!canApply()" (click)="onApply()">Apply</button>
                        <button *ngIf="canShowBack()" type="button" class="btn btn-sm btn-primary" (click)="onBack()">Close Edit Section</button>
                        <button *ngIf="canCancel()" type="button" class="btn btn-sm btn-primary" (click)="onCancel()">Cancel</button>
                    </span>
                    </editor-header>

                    <div class="row" style="margin-left: 1px;">
                        <edit-prop label="User Notes" prop="notes" szs="1,5">
                            <textarea class="form-control" rows=3 [(ngModel)]="_edm.currentEntity.userNotes"></textarea>
                        </edit-prop>
                    </div>
                </div>

            </div>
        </div>
    </div>

    <div class="modal-footer border-zero no-print">
    </div>

</div>

import { Component, ElementRef, OnInit } from '@angular/core';
import { UnitOfWork, UserManager } from '../../services/common';
import { UtilFns } from '../../utils/common';
import { TypeWorkflowContact, WorkflowPendingAction, WorkflowReferenceAssignment } from '../../entities/EntityModels';
import { ModalDialog } from '../../controls/common';
import * as _ from 'lodash';

@Component({
    selector: 'workflow-reference-assignments',
    templateUrl: './workflow-reference-assignments.html'
})

export class WorkflowReferenceAssignmentsComponent extends ModalDialog<WorkflowReferenceAssignment> implements OnInit {
    _currentWorkflowContact: TypeWorkflowContact = null;
    _isCollapsed = false;
    _hasNextPages = false;
    _hasPreviousPages = false;
    _isTypeDataLoading: boolean;
    _isPendingActionDataLoading: boolean;
    _filterPrecedence = '0';
    _filterToCompleteDescription: string = null;
    _filterSettingsDescription: string;

    _workflowPendingActions: WorkflowPendingAction[] = [];
    _workflowPendingActionTypes: string[] = [];

    public _pages: number[] = [];
    public _paginationTotalItems = 0;
    public _searchParams: { pageNumber: number, pageSize: number } = { pageNumber: 1, pageSize: 20 };

    constructor(public elementRef: ElementRef, private _uow: UnitOfWork, private _userManager: UserManager) {
        super(elementRef);
    }

    ngOnInit() {
        this._uow.typeWorkflowContactRepository.all().then(w => {
            const contacts = w.filter(c => c.emailAddress.toUpperCase() == this._userManager.currentUser.name.toUpperCase());

            if (contacts != null && contacts.length > 0) {
                this._currentWorkflowContact = contacts[0];
            }

            if (this._currentWorkflowContact == null) {
                return;
            }

            this.fetchTypesOfPendingWorkflowRecordedActions();

            return;
        });

    }

    public refreshData() {
        this.fetchTypesOfPendingWorkflowRecordedActions();
    }

    public fetchTypesOfPendingWorkflowRecordedActions() {

        //Clear paging and display data
        this._workflowPendingActionTypes = [];
        this._workflowPendingActions = [];
        this._filterToCompleteDescription = null;
        this._filterSettingsDescription = null;
        this._hasNextPages = false;
        this._hasPreviousPages = false;
        this._pages = [];
        this._paginationTotalItems = 0;
        this._searchParams.pageNumber = 1;
        //*

        this._isTypeDataLoading = true;

        let params: {} = null;
        if (this._currentWorkflowContact.consultant) {
            params = {
                workflowContactId: this._currentWorkflowContact.workflowContactId
            };
        } else {
            params = {};
        }

        this._uow.fetch('Workflow/FetchTypesOfPendingWorkflowRecordedActions', params)
            .then(r => {
                if (r == null || r.length < 1) {
                    this._isTypeDataLoading = false;
                    return;
                }

                this._workflowPendingActionTypes = <any>_.sortBy(r, a => (<any>a).toCompleteDescription);
                this._isTypeDataLoading = false;
                return;
            });
    }

    private getWorkflowReferenceAssignmentCounts(): Promise<any> {

        let params: {} = null;

        if (this._currentWorkflowContact.consultant) {
            params = {
                toCompleteDescription: this._filterToCompleteDescription,
                workflowContactId: this._currentWorkflowContact.workflowContactId
            };
        } else {
            params = {
                toCompleteDescription: this._filterToCompleteDescription
            };
        }

        return this._uow.fetch('Workflow/FetchPendingWorkflowActionsByToCompleteDescriptionCount', params);
    }

    public fetchAllWorkflowReferenceAssignments() {

        this._filterSettingsDescription = 'Pending Action "' + this._filterToCompleteDescription + '" displayed';
        this._workflowPendingActions = [];

        let params: {} = null;

        if (this._currentWorkflowContact.consultant) {
            params = {
                pageNumber: this._searchParams.pageNumber,
                pageSize: this._searchParams.pageSize,
                toCompleteDescription: this._filterToCompleteDescription,
                workflowContactId: this._currentWorkflowContact.workflowContactId
            };
        } else {
            params = {
                pageNumber: this._searchParams.pageNumber,
                pageSize: this._searchParams.pageSize,
                toCompleteDescription: this._filterToCompleteDescription,
            };
        }

        this._isPendingActionDataLoading = true;

        this._uow.fetch('Workflow/FetchPendingWorkflowActionsByToCompleteDescription', params).then(r => {
            this._workflowPendingActions = r;
            this._hasNextPages = this._searchParams.pageNumber < this._pages.length;
            this._hasPreviousPages = this._searchParams.pageNumber > 1;
            this._isPendingActionDataLoading = false;
            return;
        });
    }

    hasData(): boolean {
        return (this._workflowPendingActions != null && this._workflowPendingActions.length > 0);
    }

    public hasPages(): boolean {
        return (this._paginationTotalItems > 1);
    }

    public nextPage() {
        if (this._searchParams.pageNumber < this._pages.length) {
            this._searchParams.pageNumber = this._searchParams.pageNumber + 1;
            return this.fetchAllWorkflowReferenceAssignments();
        }
    }

    public previousPage() {
        if (this._searchParams.pageNumber > 1) {
            this._searchParams.pageNumber = this._searchParams.pageNumber - 1;
            return this.fetchAllWorkflowReferenceAssignments();
        }
    }

    public missingContactInformation(): boolean {
        return (this._currentWorkflowContact == null);
    }

    get utilFns() {
        return UtilFns;
    }

    public canFilter(): boolean {
        return (this._filterToCompleteDescription != null);
    }

    public filterResults(event: any) {

        this._searchParams.pageNumber = 1;

        const ix = event.currentTarget.selectedIndex;
        this._filterToCompleteDescription = this._workflowPendingActionTypes[ix];

        this.getWorkflowReferenceAssignmentCounts().then(c => {

            if (c != null && c.length > 0) {
                this._paginationTotalItems = c[0];
            }

            if (this._paginationTotalItems == 0) {
                return;
            }

            if (this.hasPages()) {
                let count = this._paginationTotalItems / this._searchParams.pageSize;
                if ((this._paginationTotalItems % this._searchParams.pageSize) > 0) {
                    count = count + 1;
                }

                this._pages = _.range(1, count);
            } else {
                this._pages = _.range(1, 1);
            }

            return this.fetchAllWorkflowReferenceAssignments();
        });
    }

    // Navigate to selected reference
    onSelectReferenceAssignment(ra: WorkflowReferenceAssignment) {
        UtilFns.returnModal(this, ra);
    }
}

<div class="modal" id="select-clusterpaths-dialog" role="dialog">
    <div class="modal-dialog modal-lg" style="overflow-y: initial !important">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                <div class="nav navbar-left navbar-form">
                    <label class="bold input-lg">Select the Cluster Paths you would like to view</label>
                    <button class="btn btn-primary navbar-btn" type="button" (click)="onSave()">Graph Selections</button>
                    <button class="btn btn-primary navbar-btn" type="button" (click)="onCancel()">Cancel</button>
                </div>
            </div>
            <div class="modal-body" style="height: 620px; overflow-y: auto;">
                <button type="button" class="btn-link" (click)="onUnselectAll()">Unselect All</button>
                <div style="overflow-y: visible !important;">
                    <div *ngIf="_superClusterPathGroups && _superClusterPathGroups.length > 0">
                        <table class="table table-striped table-hover table-bordered table-condensed" style="width: auto;">
                            <thead>
                                <tr>
                                    <td></td>
                                    <td>Show</td>
                                    <td>Cluster Path</td>
                                </tr>
                            </thead>
                            <tbody *ngFor="let group of _superClusterPathGroups; let ix=index">
                                <tr class="header bold">
                                    <td>
                                        <a (click)="superClusterPathCollapse(ix)">
                                            <span *ngIf="group.collapsed" class="fa fa-caret-down"></span>
                                            <span *ngIf="!group.collapsed" class="fa fa-caret-up"></span>
                                        </a>
                                    </td>
                                    <td colspan="9">
                                        <div style="background-color: aliceblue; height: 30px; width: 848px;">
                                            <input type="checkbox" class="largecheckbox" style="margin: 8px;" (change)="onSelectSuperCluster(group.id)" [(ngModel)]="group.selected">
                                            <span class="inline bold" style="color: #000000;">{{group.id}}</span>
                                        </div>
                                    </td>
                                </tr>
                                <ng-template [ngIf]="!group.collapsed">
                                    <tr class="body" *ngFor="let p of group.paths">
                                        <td></td>
                                        <td><input type="checkbox" class="mediumcheckbox" [(ngModel)]="p.selected" /></td>
                                        <td><span class="text-left">{{p.data.clusterPath.clusterPathName}}</span></td>
                                    </tr>
                                </ng-template>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
		</div>
    </div>
</div>

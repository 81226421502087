import { AnalyticalResult } from './AnalyticalResult';
import { Material } from './Material';
import { DermalExposure } from './DermalExposure';
import { ECOSARToxicEffect } from './ECOSARToxicEffect';
import { EPIData } from './EPIData';
import { EPISuite } from './EPISuite';
import { TypeEPISuiteParameter } from './TypeEPISuiteParameter';
import { TypeEPISuiteCategory } from './TypeEPISuiteCategory';
import { ExperimentalMaterial } from './ExperimentalMaterial';
import { BiologicalData } from './BiologicalData';
import { BioDataStudySubType } from './BioDataStudySubType';
import { TypeStudySubType } from './TypeStudySubType';
import { Reference } from './Reference';
import { Abstract } from './Abstract';
import { Journal } from './Journal';
import { Project } from './Project';
import { ReferenceAuthor } from './ReferenceAuthor';
import { Author } from './Author';
import { ReferenceRelation } from './ReferenceRelation';
import { TypeReferenceRelation } from './TypeReferenceRelation';
import { SpecialLink } from './SpecialLink';
import { TypeKeyword } from './TypeKeyword';
import { TypeKlimisch } from './TypeKlimisch';
import { TypePublication } from './TypePublication';
import { TypeSearchService } from './TypeSearchService';
import { WorkflowRecordedAction } from './WorkflowRecordedAction';
import { TypeWorkflowAction } from './TypeWorkflowAction';
import { TypeWorkflowContact } from './TypeWorkflowContact';
import { TypeRoute } from './TypeRoute';
import { TypeSpecies } from './TypeSpecies';
import { TypeStudy } from './TypeStudy';
import { TypeTitle } from './TypeTitle';
import { TypeTimeUnit } from './TypeTimeUnit';
import { TypeUsefulness } from './TypeUsefulness';
import { ExperimentalResult } from './ExperimentalResult';
import { ExperimentalToxicEffect } from './ExperimentalToxicEffect';
import { TypeToxicEffect } from './TypeToxicEffect';
import { TypeExperimentVehicle } from './TypeExperimentVehicle';
import { TypeUnit } from './TypeUnit';
import { TypeStudyMixture } from './TypeStudyMixture';
import { FoodStatus } from './FoodStatus';
import { JECFAMonograph } from './JECFAMonograph';
import { TypeFoodStatus } from './TypeFoodStatus';
import { HouseStatus } from './HouseStatus';
import { MaterialAdditionalIdentifier } from './MaterialAdditionalIdentifier';
import { TypeMaterialIdentifier } from './TypeMaterialIdentifier';
import { MaterialAlertRule } from './MaterialAlertRule';
import { TypeAlertRule } from './TypeAlertRule';
import { MaterialAssessmentCategory } from './MaterialAssessmentCategory';
import { AssessmentCategory } from './AssessmentCategory';
import { MaterialConsumption } from './MaterialConsumption';
import { Survey } from './Survey';
import { TypeGeographicalArea } from './TypeGeographicalArea';
import { TypeSponsor } from './TypeSponsor';
import { MaterialExpertPanelSummary } from './MaterialExpertPanelSummary';
import { ExpertPanelSummary } from './ExpertPanelSummary';
import { MaterialFSAG } from './MaterialFSAG';
import { FSAG } from './FSAG';
import { MaterialHazardCode } from './MaterialHazardCode';
import { TypeHazardCode } from './TypeHazardCode';
import { MaterialPrecautionCode } from './MaterialPrecautionCode';
import { TypePrecautionStatement } from './TypePrecautionStatement';
import { MaterialRelation } from './MaterialRelation';
import { TypeMaterialRelation } from './TypeMaterialRelation';
import { MaterialTransparencyList } from './MaterialTransparencyList';
import { MaterialUNTransport } from './MaterialUNTransport';
import { MaterialUNTransportCode } from './MaterialUNTransportCode';
import { TypeHazardousSymbol } from './TypeHazardousSymbol';
import { NaturalOccurrenceDescriptor } from './NaturalOccurrenceDescriptor';
import { Prediction } from './Prediction';
import { TypePrediction } from './TypePrediction';
import { TypePredictionClass } from './TypePredictionClass';
import { REACHRegistration } from './REACHRegistration';
import { TypeREACHTonnageBand } from './TypeREACHTonnageBand';
import { RegulatoryStatus } from './RegulatoryStatus';
import { TypeRegulatoryStatus } from './TypeRegulatoryStatus';
import { SafetyAssessmentMaterial } from './SafetyAssessmentMaterial';
import { SafetyComment } from './SafetyComment';
import { TypeSafety } from './TypeSafety';
import { SafetyEUComment } from './SafetyEUComment';
import { TypeSafetyEU } from './TypeSafetyEU';
import { Synonym } from './Synonym';
import { SynonymNamer } from './SynonymNamer';
import { TypeSynonymUse } from './TypeSynonymUse';
import { TypeBotanicalSubdivision } from './TypeBotanicalSubdivision';
import { TypeEssentialOil } from './TypeEssentialOil';
import { TypeNarrativeSummary } from './TypeNarrativeSummary';
import { TypeNaturalOccurrence } from './TypeNaturalOccurrence';
import { TypeTSCAClass } from './TypeTSCAClass';
import { MaterialUses } from './MaterialUses';
import { TypeFoodCategory } from './TypeFoodCategory';
import { TypeAnalyticalProcedure } from './TypeAnalyticalProcedure';
import { AnnouncementDetail } from './AnnouncementDetail';
import { TypeLinkTarget } from './TypeLinkTarget';
import { Announcement } from './Announcement';
import { ApplicationConfigurationSetting } from './ApplicationConfigurationSetting';
import { ClusterGroup } from './ClusterGroup';
import { ClusterNodeAndEdge } from './ClusterNodeAndEdge';
import { ClusterPart } from './ClusterPart';
import { ClusterPath } from './ClusterPath';
import { ClusterPartWithParent } from './ClusterPartWithParent';
import { DocUniqReference } from './DocUniqReference';
import { ExposureAdditionalMaterial } from './ExposureAdditionalMaterial';
import { ExposureAdditionalMaterialSurveyMeasure } from './ExposureAdditionalMaterialSurveyMeasure';
import { ExposureSurvey } from './ExposureSurvey';
import { MaterialExposureSurveyMeasure } from './MaterialExposureSurveyMeasure';
import { MissingReferenceDocument } from './MissingReferenceDocument';
import { TypeCremeVersion } from './TypeCremeVersion';
import { TypeExposureMeasure } from './TypeExposureMeasure';
import { TypeExposureMeasureValue } from './TypeExposureMeasureValue';
import { TypeHouseholdProduct } from './TypeHouseholdProduct';
import { MaterialExposureSurvey } from './MaterialExposureSurvey';
import { TypeExposureSurveyResponse } from './TypeExposureSurveyResponse';
import { TypeExposureSurveyStatus } from './TypeExposureSurveyStatus';
import { ExposureAdditionalMaterialSurvey } from './ExposureAdditionalMaterialSurvey';
import { ExposureSurveyMaterialMeasureCount } from './ExposureSurveyMaterialMeasureCount';
import { ExposureSurveyMaterialMostRecent } from './ExposureSurveyMaterialMostRecent';
import { LabelingManualPublication } from './LabelingManualPublication';
import { MaterialCluster } from './MaterialCluster';
import { MaterialComponent } from './MaterialComponent';
import { TypeMaterialComponentClass } from './TypeMaterialComponentClass';
import { MaterialComposition } from './MaterialComposition';
import { MaterialExposureSurveyIsomer } from './MaterialExposureSurveyIsomer';
import { NationalToxicologyProgram } from './NationalToxicologyProgram';
import { TypeNTPStatus } from './TypeNTPStatus';
import { RIFMProductLicense } from './RIFMProductLicense';
import { RIFMProduct } from './RIFMProduct';
import { RIFMReport } from './RIFMReport';
import { StaffWorkflowReport } from './StaffWorkflowReport';
import { SuperClusterPath } from './SuperClusterPath';
import { SuperCluster } from './SuperCluster';
import { SurveyAndCompany } from './SurveyAndCompany';
import { SurveyReportedUse } from './SurveyReportedUse';
import { SynonymWebVersion } from './SynonymWebVersion';
import { TypeAlertConcLevelScore } from './TypeAlertConcLevelScore';
import { TypeAlertVolumeScore } from './TypeAlertVolumeScore';
import { TypeChangeReportFrequency } from './TypeChangeReportFrequency';
import { TypeChangeReportTopic } from './TypeChangeReportTopic';
import { TypeComplianceGuideline } from './TypeComplianceGuideline';
import { TypeSearch } from './TypeSearch';
import { TypeStudyDesignation } from './TypeStudyDesignation';
import { TypeWorkflowActionTransition } from './TypeWorkflowActionTransition';
import { UserAcknowledgement } from './UserAcknowledgement';
import { UserChangeReportAuthor } from './UserChangeReportAuthor';
import { UserChangeReport } from './UserChangeReport';
import { UserChangeReportMaterial } from './UserChangeReportMaterial';
import { UserChangeReportStudy } from './UserChangeReportStudy';
import { UserChangeReportTopic } from './UserChangeReportTopic';
import { UserSessionToken } from './UserSessionToken';
import { SurveyUsesByMaterialAndYear } from './SurveyUsesByMaterialAndYear';
import { SurveyUsesByCompanyAndMaterialAndYear } from './SurveyUsesByCompanyAndMaterialAndYear';
import { SurveyWhoUsedMostMaterial } from './SurveyWhoUsedMostMaterial';
import { WorkflowPendingAction } from './WorkflowPendingAction';
import { WorkflowReferenceAssignment } from './WorkflowReferenceAssignment';
import { RIFMDocument } from './RIFMDocument';
import { RIFMDocumentArchive } from './RIFMDocumentArchive';
import { RIFMDocumentFolder } from './RIFMDocumentFolder';
import { TypeRIFMDocument } from './TypeRIFMDocument';

import { MetadataStore } from 'breeze-client';
import {RIFMDownloadableFile} from './RIFMDownloadableFile';

export class _RegistrationHelper {

    static register(metadataStore: MetadataStore) {
        metadataStore.registerEntityTypeCtor('AnalyticalResult', AnalyticalResult);
        metadataStore.registerEntityTypeCtor('Material', Material);
        metadataStore.registerEntityTypeCtor('DermalExposure', DermalExposure);
        metadataStore.registerEntityTypeCtor('EPIData', EPIData);
        metadataStore.registerEntityTypeCtor('EPISuite', EPISuite);
        metadataStore.registerEntityTypeCtor('TypeEPISuiteParameter', TypeEPISuiteParameter);
        metadataStore.registerEntityTypeCtor('TypeEPISuiteCategory', TypeEPISuiteCategory);
        metadataStore.registerEntityTypeCtor('ExperimentalMaterial', ExperimentalMaterial);
        metadataStore.registerEntityTypeCtor('BiologicalData', BiologicalData);
        metadataStore.registerEntityTypeCtor('BioDataStudySubType', BioDataStudySubType);
        metadataStore.registerEntityTypeCtor('TypeStudySubType', TypeStudySubType);
        metadataStore.registerEntityTypeCtor('Reference', Reference);
        metadataStore.registerEntityTypeCtor('Abstract', Abstract);
        metadataStore.registerEntityTypeCtor('Journal', Journal);
        metadataStore.registerEntityTypeCtor('Project', Project);
        metadataStore.registerEntityTypeCtor('ReferenceAuthor', ReferenceAuthor);
        metadataStore.registerEntityTypeCtor('Author', Author);
        metadataStore.registerEntityTypeCtor('ReferenceRelation', ReferenceRelation);
        metadataStore.registerEntityTypeCtor('TypeReferenceRelation', TypeReferenceRelation);
        metadataStore.registerEntityTypeCtor('SpecialLink', SpecialLink);
        metadataStore.registerEntityTypeCtor('TypeKeyword', TypeKeyword);
        metadataStore.registerEntityTypeCtor('TypeKlimisch', TypeKlimisch);
        metadataStore.registerEntityTypeCtor('TypePublication', TypePublication);
        metadataStore.registerEntityTypeCtor('TypeSearchService', TypeSearchService);
        metadataStore.registerEntityTypeCtor('WorkflowRecordedAction', WorkflowRecordedAction);
        metadataStore.registerEntityTypeCtor('TypeWorkflowAction', TypeWorkflowAction);
        metadataStore.registerEntityTypeCtor('TypeWorkflowContact', TypeWorkflowContact);
        metadataStore.registerEntityTypeCtor('TypeRoute', TypeRoute);
        metadataStore.registerEntityTypeCtor('TypeSpecies', TypeSpecies);
        metadataStore.registerEntityTypeCtor('TypeStudy', TypeStudy);
        metadataStore.registerEntityTypeCtor('TypeTitle', TypeTitle);
        metadataStore.registerEntityTypeCtor('TypeTimeUnit', TypeTimeUnit);
        metadataStore.registerEntityTypeCtor('TypeUsefulness', TypeUsefulness);
        metadataStore.registerEntityTypeCtor('ExperimentalResult', ExperimentalResult);
        metadataStore.registerEntityTypeCtor('ExperimentalToxicEffect', ExperimentalToxicEffect);
        metadataStore.registerEntityTypeCtor('TypeToxicEffect', TypeToxicEffect);
        metadataStore.registerEntityTypeCtor('TypeExperimentVehicle', TypeExperimentVehicle);
        metadataStore.registerEntityTypeCtor('TypeUnit', TypeUnit);
        metadataStore.registerEntityTypeCtor('TypeStudyMixture', TypeStudyMixture);
        metadataStore.registerEntityTypeCtor('FoodStatus', FoodStatus);
        metadataStore.registerEntityTypeCtor('JECFAMonograph', JECFAMonograph);
        metadataStore.registerEntityTypeCtor('TypeFoodStatus', TypeFoodStatus);
        metadataStore.registerEntityTypeCtor('HouseStatus', HouseStatus);
        metadataStore.registerEntityTypeCtor('MaterialAdditionalIdentifier', MaterialAdditionalIdentifier);
        metadataStore.registerEntityTypeCtor('TypeMaterialIdentifier', TypeMaterialIdentifier);
        metadataStore.registerEntityTypeCtor('MaterialAlertRule', MaterialAlertRule);
        metadataStore.registerEntityTypeCtor('TypeAlertRule', TypeAlertRule);
        metadataStore.registerEntityTypeCtor('MaterialAssessmentCategory', MaterialAssessmentCategory);
        metadataStore.registerEntityTypeCtor('AssessmentCategory', AssessmentCategory);
        metadataStore.registerEntityTypeCtor('MaterialConsumption', MaterialConsumption);
        metadataStore.registerEntityTypeCtor('Survey', Survey);
        metadataStore.registerEntityTypeCtor('TypeGeographicalArea', TypeGeographicalArea);
        metadataStore.registerEntityTypeCtor('TypeSponsor', TypeSponsor);
        metadataStore.registerEntityTypeCtor('MaterialExpertPanelSummary', MaterialExpertPanelSummary);
        metadataStore.registerEntityTypeCtor('ExpertPanelSummary', ExpertPanelSummary);
        metadataStore.registerEntityTypeCtor('MaterialFSAG', MaterialFSAG);
        metadataStore.registerEntityTypeCtor('FSAG', FSAG);
        metadataStore.registerEntityTypeCtor('MaterialHazardCode', MaterialHazardCode);
        metadataStore.registerEntityTypeCtor('TypeHazardCode', TypeHazardCode);
        metadataStore.registerEntityTypeCtor('MaterialPrecautionCode', MaterialPrecautionCode);
        metadataStore.registerEntityTypeCtor('TypePrecautionStatement', TypePrecautionStatement);
        metadataStore.registerEntityTypeCtor('MaterialRelation', MaterialRelation);
        metadataStore.registerEntityTypeCtor('TypeMaterialRelation', TypeMaterialRelation);
        metadataStore.registerEntityTypeCtor('MaterialTransparencyList', MaterialTransparencyList);
        metadataStore.registerEntityTypeCtor('MaterialUNTransport', MaterialUNTransport);
        metadataStore.registerEntityTypeCtor('MaterialUNTransportCode', MaterialUNTransportCode);
        metadataStore.registerEntityTypeCtor('TypeHazardousSymbol', TypeHazardousSymbol);
        metadataStore.registerEntityTypeCtor('NaturalOccurrenceDescriptor', NaturalOccurrenceDescriptor);
        metadataStore.registerEntityTypeCtor('Prediction', Prediction);
        metadataStore.registerEntityTypeCtor('TypePrediction', TypePrediction);
        metadataStore.registerEntityTypeCtor('TypePredictionClass', TypePredictionClass);
        metadataStore.registerEntityTypeCtor('REACHRegistration', REACHRegistration);
        metadataStore.registerEntityTypeCtor('TypeREACHTonnageBand', TypeREACHTonnageBand);
        metadataStore.registerEntityTypeCtor('RegulatoryStatus', RegulatoryStatus);
        metadataStore.registerEntityTypeCtor('TypeRegulatoryStatus', TypeRegulatoryStatus);
        metadataStore.registerEntityTypeCtor('SafetyAssessmentMaterial', SafetyAssessmentMaterial);
        metadataStore.registerEntityTypeCtor('SafetyComment', SafetyComment);
        metadataStore.registerEntityTypeCtor('TypeSafety', TypeSafety);
        metadataStore.registerEntityTypeCtor('SafetyEUComment', SafetyEUComment);
        metadataStore.registerEntityTypeCtor('TypeSafetyEU', TypeSafetyEU);
        metadataStore.registerEntityTypeCtor('Synonym', Synonym);
        metadataStore.registerEntityTypeCtor('SynonymNamer', SynonymNamer);
        metadataStore.registerEntityTypeCtor('TypeSynonymUse', TypeSynonymUse);
        metadataStore.registerEntityTypeCtor('TypeBotanicalSubdivision', TypeBotanicalSubdivision);
        metadataStore.registerEntityTypeCtor('TypeEssentialOil', TypeEssentialOil);
        metadataStore.registerEntityTypeCtor('TypeNarrativeSummary', TypeNarrativeSummary);
        metadataStore.registerEntityTypeCtor('TypeNaturalOccurrence', TypeNaturalOccurrence);
        metadataStore.registerEntityTypeCtor('TypeTSCAClass', TypeTSCAClass);
        metadataStore.registerEntityTypeCtor('MaterialUses', MaterialUses);
        metadataStore.registerEntityTypeCtor('TypeFoodCategory', TypeFoodCategory);
        metadataStore.registerEntityTypeCtor('TypeAnalyticalProcedure', TypeAnalyticalProcedure);
        metadataStore.registerEntityTypeCtor('AnnouncementDetail', AnnouncementDetail);
        metadataStore.registerEntityTypeCtor('TypeLinkTarget', TypeLinkTarget);
        metadataStore.registerEntityTypeCtor('Announcement', Announcement);
        metadataStore.registerEntityTypeCtor('ApplicationConfigurationSetting', ApplicationConfigurationSetting);
        metadataStore.registerEntityTypeCtor('ClusterGroup', ClusterGroup);
        metadataStore.registerEntityTypeCtor('ClusterNodeAndEdge', ClusterNodeAndEdge);
        metadataStore.registerEntityTypeCtor('ClusterPart', ClusterPart);
        metadataStore.registerEntityTypeCtor('ClusterPath', ClusterPath);
        metadataStore.registerEntityTypeCtor('ClusterPartWithParent', ClusterPartWithParent);
        metadataStore.registerEntityTypeCtor('ExposureAdditionalMaterial', ExposureAdditionalMaterial);
        metadataStore.registerEntityTypeCtor('ExposureAdditionalMaterialSurveyMeasure', ExposureAdditionalMaterialSurveyMeasure);
        metadataStore.registerEntityTypeCtor('ExposureSurvey', ExposureSurvey);
        metadataStore.registerEntityTypeCtor('MaterialExposureSurveyMeasure', MaterialExposureSurveyMeasure);
        metadataStore.registerEntityTypeCtor('TypeCremeVersion', TypeCremeVersion);
        metadataStore.registerEntityTypeCtor('TypeExposureMeasure', TypeExposureMeasure);
        metadataStore.registerEntityTypeCtor('TypeExposureMeasureValue', TypeExposureMeasureValue);
        metadataStore.registerEntityTypeCtor('TypeHouseholdProduct', TypeHouseholdProduct);
        metadataStore.registerEntityTypeCtor('MaterialExposureSurvey', MaterialExposureSurvey);
        metadataStore.registerEntityTypeCtor('TypeExposureSurveyResponse', TypeExposureSurveyResponse);
        metadataStore.registerEntityTypeCtor('TypeExposureSurveyStatus', TypeExposureSurveyStatus);
        metadataStore.registerEntityTypeCtor('ExposureAdditionalMaterialSurvey', ExposureAdditionalMaterialSurvey);
        metadataStore.registerEntityTypeCtor('ExposureSurveyMaterialMeasureCount', ExposureSurveyMaterialMeasureCount);
        metadataStore.registerEntityTypeCtor('ExposureSurveyMaterialMostRecent', ExposureSurveyMaterialMostRecent);
        metadataStore.registerEntityTypeCtor('LabelingManualPublication', LabelingManualPublication);
        metadataStore.registerEntityTypeCtor('MaterialCluster', MaterialCluster);
        metadataStore.registerEntityTypeCtor('MaterialComponent', MaterialComponent);
        metadataStore.registerEntityTypeCtor('TypeMaterialComponentClass', TypeMaterialComponentClass);
        metadataStore.registerEntityTypeCtor('MaterialComposition', MaterialComposition);
        metadataStore.registerEntityTypeCtor('MaterialExposureSurveyIsomer', MaterialExposureSurveyIsomer);
        metadataStore.registerEntityTypeCtor('NationalToxicologyProgram', NationalToxicologyProgram);
        metadataStore.registerEntityTypeCtor('TypeNTPStatus', TypeNTPStatus);
        metadataStore.registerEntityTypeCtor('RIFMProductLicense', RIFMProductLicense);
        metadataStore.registerEntityTypeCtor('RIFMProduct', RIFMProduct);
        metadataStore.registerEntityTypeCtor('RIFMReport', RIFMReport);
        metadataStore.registerEntityTypeCtor('StaffWorkflowReport', StaffWorkflowReport);
        metadataStore.registerEntityTypeCtor('SuperClusterPath', SuperClusterPath);
        metadataStore.registerEntityTypeCtor('SuperCluster', SuperCluster);
        metadataStore.registerEntityTypeCtor('SurveyAndCompany', SurveyAndCompany);
        metadataStore.registerEntityTypeCtor('SurveyReportedUse', SurveyReportedUse);
        metadataStore.registerEntityTypeCtor('SynonymWebVersion', SynonymWebVersion);
        metadataStore.registerEntityTypeCtor('TypeAlertConcLevelScore', TypeAlertConcLevelScore);
        metadataStore.registerEntityTypeCtor('TypeAlertVolumeScore', TypeAlertVolumeScore);
        metadataStore.registerEntityTypeCtor('TypeChangeReportFrequency', TypeChangeReportFrequency);
        metadataStore.registerEntityTypeCtor('TypeChangeReportTopic', TypeChangeReportTopic);
        metadataStore.registerEntityTypeCtor('TypeComplianceGuideline', TypeComplianceGuideline);
        metadataStore.registerEntityTypeCtor('TypeSearch', TypeSearch);
        metadataStore.registerEntityTypeCtor('TypeStudyDesignation', TypeStudyDesignation);
        metadataStore.registerEntityTypeCtor('TypeWorkflowActionTransition', TypeWorkflowActionTransition);
        metadataStore.registerEntityTypeCtor('UserAcknowledgement', UserAcknowledgement);
        metadataStore.registerEntityTypeCtor('UserChangeReportAuthor', UserChangeReportAuthor);
        metadataStore.registerEntityTypeCtor('UserChangeReport', UserChangeReport);
        metadataStore.registerEntityTypeCtor('UserChangeReportMaterial', UserChangeReportMaterial);
        metadataStore.registerEntityTypeCtor('UserChangeReportStudy', UserChangeReportStudy);
        metadataStore.registerEntityTypeCtor('UserChangeReportTopic', UserChangeReportTopic);
        metadataStore.registerEntityTypeCtor('UserSessionToken', UserSessionToken);
        metadataStore.registerEntityTypeCtor('SurveyUsesByMaterialAndYear', SurveyUsesByMaterialAndYear);
        metadataStore.registerEntityTypeCtor('SurveyUsesByCompanyAndMaterialAndYear', SurveyUsesByCompanyAndMaterialAndYear);
        metadataStore.registerEntityTypeCtor('SurveyWhoUsedMostMaterial', SurveyWhoUsedMostMaterial);
        metadataStore.registerEntityTypeCtor('WorkflowPendingAction', WorkflowPendingAction);
        metadataStore.registerEntityTypeCtor('WorkflowReferenceAssignment', WorkflowReferenceAssignment);
        metadataStore.registerEntityTypeCtor('RIFMDocument', RIFMDocument);
        metadataStore.registerEntityTypeCtor('RIFMDocumentArchive', RIFMDocumentArchive);
        metadataStore.registerEntityTypeCtor('RIFMDocumentFolder', RIFMDocumentFolder);
        metadataStore.registerEntityTypeCtor('TypeRIFMDocument', TypeRIFMDocument);
        metadataStore.registerEntityTypeCtor('RIFMDownloadableFile', RIFMDownloadableFile);
        metadataStore.registerEntityTypeCtor('ECOSARToxicEffect', ECOSARToxicEffect);
    }
}

import { Component, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import * as _ from 'lodash';

import { WorkflowActivityReportItem } from '../../entities/projections/WorkflowActivityReportItem';
import { UtilFns } from '../../utils/common';
import { PrintToPdfDocComponent } from '../main/print-to-pdf-doc.component';


@Component({
    selector: 'workflow-activity-report',
    templateUrl: './workflow-activity-report.html',
})
export class WorkflowActivityReportComponent implements OnInit, OnChanges {
    _groups: {};
    _activityGroups: { name: string, workflowCompletedActions: WorkflowActivityReportItem[], collapsed?: boolean }[] = [];

    @ViewChild(PrintToPdfDocComponent, { static: true }) _printToPdfDocComponent: PrintToPdfDocComponent;

    @Input() reportItems: WorkflowActivityReportItem[];

    ngOnInit() {
    }

    ngOnChanges() {
        this.groupData();
    }

    expandAll(expand: boolean) {
        setTimeout(() => {
            this._activityGroups.forEach(g => g.collapsed = !expand);
        }, 0);
    }

    expCollapse(ix: number) {
        this._activityGroups[ix].collapsed = !this._activityGroups[ix].collapsed;
    }

    groupData() {

        const groups = _.groupBy(this.reportItems, a => a.staffFirstName);

        this._activityGroups = _.map(groups, (v, k) => {
            return { name: k, workflowCompletedActions: v, isCollapsed: false };
        });

        this._activityGroups = _.sortBy(this._activityGroups, (g) => g.name);

    }

    hasReportData(): boolean {
        return (this.reportItems != null && this.reportItems.length > 0);
    }

    printWorkflowActivityReport() {
        const ele = document.querySelector('#WorkflowActivityReport .modal-content');
        UtilFns.printElement(ele);
    }

    get utilFns() {
        return UtilFns;
    }
}

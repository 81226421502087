import { Component, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import * as _ from 'lodash';

import { EditorService, provideParent, StateMap, UnitOfWork } from '../../services/common';
import { EditManager, LocationFns, UtilFns } from '../../utils/common';
import { EditPropParent, PageState } from '../../controls/common';

import { TypeReferenceRelationSelectorComponent } from './type-reference-relation-selector.component';

import { TypeReferenceRelation } from '../../entities/EntityModels';

import { STAFF_ROUTES } from './staff.routes';

class PageStateExt extends PageState {
    typeReferenceRelationId: string;
}

// TODO: Review this later - discuss removing foreign key check
// NOTE: This component is complicated by two issues.
//      1) The TypeReferenceRelations table has a inverse reference id that points to the same table that has a foreign key check on it.
//          This means that we cannot save a record and its inverse in a single transaction because the first will fail the fk check.
//          So instead we have to insert the first record with a null inv id and then in a 2nd trx update the inv id and create the
//          inverse entity.
//      2) The primary key is a user defined string - the problem is that we can't allow this field to be edited directly
//          like with other editors because we need to check if the key collides with other pks.

@Component({
    selector: 'type-reference-relation-editor',
    templateUrl: './type-reference-relation-editor.html',
    providers: [provideParent(TypeReferenceRelationEditorComponent, EditPropParent)]
})
export class TypeReferenceRelationEditorComponent implements OnInit, EditPropParent {
    @ViewChild(TypeReferenceRelationSelectorComponent) _selectorComponent: TypeReferenceRelationSelectorComponent;

    _entities: TypeReferenceRelation[];
    _adding = false;
    _edm: EditManager<TypeReferenceRelation>;
    _inverseId: string;
    _inverseLabel: string;
    _inverseDescription: string;


    _pageState = new PageStateExt('Reference Relation Type editor');

    // can't actually create an entity in this editor until both sides are known ( entity and invEntity)

    constructor(public _uow: UnitOfWork, public _stateMap: StateMap, public _location: Location,
                private _editorService: EditorService) {

    }

    ngOnInit() {
        this._stateMap.currentRouteName = STAFF_ROUTES.TypeReferenceRelation.name;
        this._edm = this._editorService.createEditManager<TypeReferenceRelation>(this, 'TypeReferenceRelation');
        this._pageState.isLoading = true;
        this.refresh()
            .then(() => {
                this._pageState.isLoaded = true;

                const returned = LocationFns.updatePageState(this._location, this._stateMap, this._pageState, (state: PageStateExt) => {
                    this.fetch(state.typeReferenceRelationId);
                });

                if (!returned) {
                    this._edm.editing = false;
                }
            });
    }

    canDeactivate() {
        return !this._edm.editing;
    }

    get entity() {
        return this._edm.currentEntity;
    }

    refresh() {
        return this._uow.typeReferenceRelationRepository.all().then(r => {
            this._entities = r;
            return r;
        });
    }


    // Not really needed but in case this editor is ever used by non-staff.
    isStaff() {
        return this._stateMap.currentUser.isStaff;
    }

    onEdit() {
        UtilFns.showModal(this._selectorComponent, this, null).then((entity: TypeReferenceRelation) => {
            this.editEntity(entity);

        });
    }

    findEntity(typeReferenceRelationId: string) {
        if (typeReferenceRelationId == null) {
            return null;
        }
        return this._entities.find(f => f.typeReferenceRelationId == typeReferenceRelationId);
    }


    fetch(typeReferenceRelationId: string) {
        const entity = this.findEntity(typeReferenceRelationId);
        if (entity) {
            this.editEntity(entity);
        }
    }

    editEntity(entity: TypeReferenceRelation) {
        if (entity == null) {
            return;
        }
        this._adding = false;
        this._edm.onlyEntity = entity;
        this._pageState.typeReferenceRelationId = entity.typeReferenceRelationId;
        let inv = this.entity.inverseTypeReferenceRelation;
        if (!inv) {
            inv = this.entity;
        } // if no inverse - make it its own inverse
        this._inverseId = inv.typeReferenceRelationId;
        this._inverseLabel = inv.label;
        this._inverseDescription = inv.description;
        this._edm.editing = true;
        return entity;
    }

    getError(propName: string) {
        let val: any;
        if (propName.substr(0, 1) == '_') {
            val = this[propName] && this[propName].trim();
        } else {
            val = this.entity[propName] && this.entity[propName].trim();
        }

        if (this._adding && (propName == 'typeReferenceRelationId' || propName == '_inverseId')) {
            if (this._entities.find(e => e.typeReferenceRelationId == val) != null) {
                return 'This id has already been used';
            }
        }

        if (propName == 'typeReferenceRelationId' || propName == '_inverseId' || propName == '_inverseLabel') {
            return (val && val.length) ? null : 'This is a required field';
        }
        const r = this.entity.getErrorFor(propName);

        return r;

    }

    setId(id: string) {
        try {
            id = this.entity.typeReferenceRelationId = id && id.trim().toUpperCase();
            this.entity.typeReferenceRelationId = id;
            if (id.indexOf('HAS') == 0) {
                this._inverseId = id.substr(3);
            } else {
                this._inverseId = 'HAS' + id;
            }
        } catch (e) {
            // will fail if this collides with an existing id;
            this.entity.typeReferenceRelationId = ' ';

        }
    }

    onAdd() {
        this._adding = true;
        const e = this.createEntity();
        this._edm.onlyEntity = e;
        this._edm.editing = true;
        this._inverseId = '';
        this._inverseLabel = '';
        this._inverseDescription = '';

    }


    onDelete() {
        const id = this.entity.typeReferenceRelationId;
        return this._uow.fetch('References/CountReferenceRelationsByType', { typeReferenceRelationId: id }).then(r => {
            if (r.length == 0 || r[0] != 0) {
                this._stateMap.yesNoComponent.showModal('Cannot delete',
                    `There are ${r.length && r[0]} ReferenceRelations that still reference this type, you must change these references before you can delete this type.`, ['Ok']);
                return;
            }
            return this._stateMap.yesNoComponent.showModal('Delete entire Reference Relation Type', 'Are you sure?');
        }).then(ok => {
            if (ok) {
                this.deleteEntity();
            }
        });
    }

    onCancel() {
        const wasAdding = this.entity.entityAspect.entityState.isAdded();
        this._uow.rollback();
        if (wasAdding) {
            this._edm.editing = false;
        } else {
            this.editEntity(this.entity);
        }

    }

    canSave() {
        if (!this._edm.hasEdits()) {
            return false;
        }
        // this._edm.canSaveCore does not include next line.
        if (!this._edm.validateBeforeSave()) {
            return false;
        }
        return true;
    }

    updateInv() {
        if (!this._adding) {
            const inv = this.entity.inverseTypeReferenceRelation;
            inv.label = this._inverseLabel;
            inv.description = this._inverseDescription;
        }
    }

    onSave() {
        const wasAdded = this.entity.entityAspect.entityState.isAdded();

        return this._uow.commit().then(() => {
            if (wasAdded) {
                this.entity.inverseTypeReferenceRelationId = this._inverseId;
                const p = {
                    typeReferenceRelationId: this.entity.inverseTypeReferenceRelationId,
                    label: this._inverseLabel,
                    description: this._inverseDescription,
                    inverseTypeReferenceRelationId: this.entity.typeReferenceRelationId
                };
                this._uow.typeReferenceRelationFactory.create(p);
                return this._uow.commit();
            }
        }).then(() => {
            this._edm.setSavedStatus();
            this._pageState.typeReferenceRelationId = this._edm.currentEntity.typeReferenceRelationId;

            if (wasAdded) {
                this._entities.push(this.entity);
                this._entities.push(this.entity.inverseTypeReferenceRelation);
                this.refresh();
                this.sortEntities();
                this._selectorComponent.search();
            }
            this._adding = false;
        }).catch(e => {
            this._edm.handleSaveError(e);
        });

    }

    createEntity() {
        const p1 = {
            typeReferenceRelationId: ' ' // blank
        };
        const entity = this._uow.typeReferenceRelationFactory.create(p1);
        return entity;
    }


    deleteEntity() {
        const entity = this.entity;
        if (entity.entityAspect.entityState.isAdded()) {
            this._uow.rollback();
            this._edm.editing = false;
            return;
        }
        const inv = entity.inverseTypeReferenceRelation;
        entity.inverseTypeReferenceRelationId = null;
        if (inv) {
            inv.inverseTypeReferenceRelationId = null;
        }
        return this._uow.commit().then(() => {
            entity.entityAspect.setDeleted();
            _.remove(this._entities, e => e == entity);
            if (inv) {
                inv.entityAspect.setDeleted();
                _.remove(this._entities, e => inv == e);
            }
            return this._uow.commit();
        }).then(() => {
            this._edm.setSavedStatus('Deleted');
            this._edm.editing = false;
            this.refresh();
            this._selectorComponent.search();
        }).catch((e) => {
            this._edm.setSaveFailedStatus('Delete failed: ' + e);
        });

    }

    sortEntities() {
        UtilFns.sort(this._entities, true, f => (f.label).toLowerCase());
    }

    toUpperCase(s: string) {
        if (!s) {
            return s;
        }
        return s.toUpperCase();
    }

}

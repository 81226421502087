import { UnitOfWork } from '../../../services/unit-of-work';
import { StateMap } from '../../../services/state-map';
import { BusyService } from '../../../services/busy.service';
import { Injectable } from '@angular/core';

@Injectable()
export class ReferenceLoader {
	constructor(private uow: UnitOfWork, private stateMap: StateMap, private busyService: BusyService) {
	}

	load(referenceId: number) {
		if (referenceId == null) {
			return Promise.resolve(null);
		}

		let shouldLoad = false;
		if (this.stateMap.currentReference == null) {
			shouldLoad = true;
		} else if (this.stateMap.currentReference.referenceId != referenceId) {
			shouldLoad = true;
		}

		if (!shouldLoad) {
			return Promise.resolve(this.stateMap.currentReference);
		}

		const promise = this.uow.referenceRepository
			.withId(referenceId)
			.then(r => {
				this.stateMap.currentReference = r;
				return r;
			});

		return this.busyService.busy(promise);
	}
}
<div class="modal" id="yes-no-modal" role="dialog">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
        <h4 class="modal-title">{{_title}}</h4>
      </div>
      <div class="modal-body">
        <p>{{_message}}</p>
      </div>
      <div *ngIf="_buttonNames" class="modal-footer">
        <button  (click)="select(true)" type="button" class="btn btn-default" >{{_buttonNames[0]}}</button>
        <button *ngIf="_buttonNames.length > 1" (click)="select(false)" type="button" class="btn btn-primary" >{{_buttonNames[1]}}</button>
      </div>
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div><!-- /.modal -->
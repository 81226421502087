import { EntityBase } from './EntityBase';
import { TypeSpecies } from './TypeSpecies';
import { TypeStudy } from './TypeStudy';

import { Validator } from 'breeze-client';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

export class TypeStudyDesignation extends EntityBase {

   /// <code> Place custom code between <code> tags
   
   /// </code>

   // Generated code. Do not place code below this line.
   typeStudyDesignationId: number;
   typeStudyId: string;
   typeSpeciesId: string;
   designation: string;
   typeSpecies: TypeSpecies;
   typeStudy: TypeStudy;
}


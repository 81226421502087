<div class="indent-top-sm"></div>
<editor-header *ngIf="!_edm.editing" [edm]="_edm" [femaRestricted]="true"></editor-header>
<default-table *ngIf="_edm.hasEntities()" [attr.data-hidden]="_edm.editing">
    <h4 header>National Toxicological Data</h4>
    <thead>
        <tr>
            <td>Last Updated</td>
            <td>Description</td>
            <td>Study</td>
            <td>Route</td>
            <td>Species</td>
            <td>Protocol</td>
            <td>Reference</td>
            <td>Comments</td>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let e of sortedNTPEntities" (click)="_edm.onSelect(e)">
            <td>{{ _stateMap.utilFns.formatDate(e.lastReviewDate) }}</td>
            <!--<td>{{e.modified | date }}</td>-->
            <td>{{e.typeNTPStatus?.description }}</td>
            <td>{{e.typeStudy?.studyLong}}</td>
            <td>{{e.typeRoute?.routeLong}}</td>
            <td>{{e.typeSpecies?.speciesLong}}</td>
            <td>{{e.protocol}}</td>
            <td>{{e.reference}}</td>
            <td>{{e.comments}}</td>
            <!-- <td>{{s.entityAspect.entityState}}</td> -->
        </tr>
    </tbody>
</default-table>

<div *ngIf="_edm.editing">
    <status-ntp #self [entity]="_edm.currentEntity" [edm]="_edm"></status-ntp>
</div>
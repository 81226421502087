<page-status [pageState]="_pageState"></page-status>

<div style="padding-right: 40px">
	<dx-data-grid *ngIf="hasData()"
			      [dataSource]="dataSource"
	              [columnAutoWidth]="false"
	              [showRowLines]="true"
	              [rowAlternationEnabled]="true"
	              [showBorders]="true"
	              [hoverStateEnabled]="true"
	              [allowColumnReordering]="true"
				  [allowColumnResizing]="true"
				  [wordWrapEnabled]="true"
	              (onSelectionChanged)="onSelectionChanged($event)">
		<dxo-paging [enabled]="true" [pageSize]="20"></dxo-paging>
		<dxo-pager [showNavigationButtons]="true" [visible]="true" infoText="Page #{0}. Total: {1} ({2} references)"></dxo-pager>
		<dxo-remote-operations [sorting]="true" [paging]="true"></dxo-remote-operations>
		<dxo-sorting mode="single"></dxo-sorting>
		<dxo-selection mode="single"></dxo-selection>

		<dxi-column caption="Reference No." dataField="referenceId" alignment="center" width="90"></dxi-column>

		<dxi-column caption="Brief Reference" dataField="briefReference" cellTemplate="briefReference" alignment="center" width="120"></dxi-column>
		<div *dxTemplate="let cell of 'briefReference'">
			{{ formatBriefReference(cell.data.briefReference) }}
		</div>

		<dxi-column caption="Title" dataField="title" cellTemplate="title"></dxi-column>
		<div *dxTemplate="let cell of 'title'">
			<span [htmlGreek]="cell.data.title" style="max-width: 350px; word-wrap: break-word;"></span>
		</div>

		<dxi-column caption="Journal" dataField="journal" [visible]="showJournal" alignment="left" width="300"></dxi-column>

		<dxi-column caption="Found In" dataField="searchResolution" [allowSorting]="false" alignment="center" width="140"></dxi-column>
	</dx-data-grid>
</div>

<div style="margin-top: 15px"></div>
<div *ngIf="_entity && !_data.inSubEditor" class="form-horizontal">
    <div class="form-group">
        <div class="col-md-7">
            <span>
                <button type="button " class="btn btn-sm btn-success btn-no-margin" (click)="onAttachReference() ">Attach a related reference</button>
                <span class="bold" style="margin-left: 15px">Of Type </span>
                <select *ngIf="_typeReferenceRelation" class="" [ngModel]="_typeReferenceRelation.typeReferenceRelationId" (change)="setTypeReferenceRelation($event.target.value)">
                    <option disabled selected value>-- None --</option> 
                    <option *ngFor="let trr of _typeReferenceRelations" [value]="trr.typeReferenceRelationId">{{trr.label}}</option>
                </select>
            </span>
        </div>
    </div>
</div>
<default-table>
    <thead>
        <tr>
            <td>Reference No.</td>
            <td>Brief Reference</td>
            <td>Title</td>
            <td>Year of Publication</td>
            <td>Abstract Only</td>
            <td>Priority</td>
            <td>Linkable</td>
            <td>Relation Type</td>
            <td></td>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let e of _entity.referenceRelations; let ix=index">
            <td><a (click)="navToRef(e.relatedReference)">{{e.relatedReference?.referenceId}}</a></td>
            <td>{{e.relatedReference?.briefReference}}</td>
            <td>{{e.relatedReference?.title}}</td>
            <td>{{e.relatedReference?.yearOfPublication}}</td>
            <td>
                <input type="checkbox" disabled [checked]="e.relatedReference?.abstractOnly">
            </td>
            <td>{{e.relatedReference?.precedence}}</td>
            <td>
                <input type="checkbox" disabled [checked]="e.relatedReference?.linkable">
            </td>
            <td>{{e.typeReferenceRelation?.label}}</td>
            <td><a (click)="onDetachReference(e)"><b>Detach</b></a></td>
        </tr>
    </tbody>
</default-table>

<reference-selector></reference-selector>
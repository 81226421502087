<div class="indent-left-md">

    <p class="alert-warning bold">{{_validationMessage}}</p>
    <div class="row">
        <i *ngIf="_isLoading" class="fa fa-circle-o-notch fa-spin" style="font-size:38px; color: #337ab7; margin-left: 24px;"></i>
    </div>

    <div class="home-page-title">{{"IFRA/IOFI Labeling Manual " + _labelingManualYear}}</div>

    <div *ngIf="_labelingManuals" class="col-md-10 database-subscription-container" style="margin-right: 30px">
        <div class="panel-body">
            <div *ngFor="let lm of _labelingManuals">
                <div class="row">
                    <label class="bold col-md-2 database-subscription-title">Labeling Manual:</label>
                    <div class="col-md-6 database-subscription-link"><a (click)="downloadFile(lm)" class="bold">{{lm.fileLabel}}</a></div>
                </div>
            </div>
        </div>
    </div>

</div>

import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';


import { EditorService, ReferenceFinishState, ReferenceState, StateMap, UnitOfWork } from '../../services/common';
import { EditManager, IEditHost } from '../../utils/common';
import { PageState } from '../../controls/common';
import { Reference, WorkflowRecordedAction } from '../../entities/EntityModels';

import { STAFF_ROUTES } from './staff.routes';
import { ActivatedRoute } from '@angular/router';
import { ReferenceLoader } from './services/reference-loader';

class PageStateExt extends PageState {
    referenceId: number;
}

@Component({
    selector: 'reference-audit',
    templateUrl: './reference-audit.html',
})
export class ReferenceAuditComponent implements OnInit, IEditHost<WorkflowRecordedAction> {
    _toxAlreadyAudited: boolean;
    _toxPendingAudit: boolean;

    _canLoad = false;
    _edm: EditManager<WorkflowRecordedAction>;
    _pageState = new PageStateExt('Reference Audit');
    _finishedMessage: string;
    reference: Reference;

    constructor(public _uow: UnitOfWork, private _stateMap: StateMap, public _location: Location, public _editorService: EditorService,
                private _referenceState: ReferenceState, private route: ActivatedRoute,
                private referenceLoader: ReferenceLoader) {
    }

    ngOnInit() {
        this._stateMap.currentRouteName = STAFF_ROUTES.ReferenceAudit.name;

        this.route.params
            .subscribe(params => {
                const referenceId: number = params['referenceId'];
                if (referenceId) {
                    // For Workflow
                    this._edm = this._editorService.createEditManager<WorkflowRecordedAction>(this, 'Workflow');
                    this._edm.pageState.canShowMessage = false;

                    this._edm.editing = this._toxPendingAudit;

                    this.referenceLoader.load(referenceId)
                        .then(r => {
                            this.reference = r;
                            if (r.workflowRecordedActions != null) {
                                this._toxAlreadyAudited = this.hasAuditedToxData();
                                this._toxPendingAudit = this.hasUnauditedToxData();
                                this._edm.entities = r.workflowRecordedActions;
                            }
                            this._canLoad = true;
                        });
                } else {
                    return;
                }
            });
    }

    canDeactivate() {
        return (!this._uow.hasChanges());
    }

    // Workflow Related
    hasChanges() {
        return this._uow.hasChanges();
    }

    isEditing() {
        return this._edm.editing;
    }

    canShowCancel() {
        return (this._uow.hasChanges());
    }

    canCancel() {
        return (this._uow.hasChanges());
    }

    onCancel() {
        this._uow.rollback();

        this._toxAlreadyAudited = this.hasAuditedToxData();
        this._toxPendingAudit = this.hasUnauditedToxData();
    }

    clearEdms() {
    }

    canShowSave() {
        return this._uow.hasChanges();
    }

    canSave() {
        return this._uow.hasChanges();
    }

    onSave() {
        this._referenceState.isReferenceFinished(this.reference).then(f => {
            const finished: boolean = (f != ReferenceFinishState.MissingData);

            if (finished != this.reference.finished) {
                this._finishedMessage = finished ?
                    'The Reference is now marked complete, you may need to Save again to confirm.' :
                    'The Reference has been Unfinished as a result of these edits.';

                this.reference.finished = finished;
                this._referenceState.recordReferenceFinishedStateChange(this.reference);
            }

            return (this._edm.commitSave());
        });
    }

    private hasAuditedToxData() {
        return (this._referenceState.hasAuditedToxData(this.reference));
    }

    private hasUnauditedToxData() {
        return (this._referenceState.hasUnauditedToxData(this.reference));
    }

    public onAuditComplete() {
        this._referenceState.recordToxAudit(this.reference);
        this._edm.entities = this.reference.workflowRecordedActions;
        this._toxPendingAudit = false;
        this._toxAlreadyAudited = true;
    }

    public referenceFinishedStatesHaveChanged(): boolean {
        return (this._finishedMessage && this._finishedMessage.length > 0);
    }

}

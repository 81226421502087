import { EntityBase } from './EntityBase';
import { Material } from './Material';
import { MaterialUNTransportCode } from './MaterialUNTransportCode';

import { Validator } from 'breeze-client';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

export class MaterialUNTransport extends EntityBase {

   /// <code> Place custom code between <code> tags
   
   /// </code>

   // Generated code. Do not place code below this line.
   materialId: number;
   labelApproved: boolean;
   labelAdded: Date;
   labelAddedBy: string;
   labelUpdated: Date;
   labelUpdatedBy: string;
   labelDeleted: Date;
   labelDeletedBy: string;
   classification: string;
   concentrationLimits: string;
   concLimChanged: Date;
   deleted: Date;
   deletedBy: string;
   rSComment: string;
   rSCommentChanged: Date;
   labelDeleteNextLM: boolean;
   created: Date;
   createdUser: string;
   modified: Date;
   modifyUser: string;
   rowVersion: number;
   material: Material;
   materialUNTransportCodes: MaterialUNTransportCode[];
}


import { Location } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { ErrorLogger, StateMap, UnitOfWork, UserManager } from '../../services/common';
import { TabContainer } from '../../controls/common';
import { LocationFns, UtilFns } from '../../utils/common';
import { ExposureSurvey } from '../../entities/EntityModels';
import { MaterialExposureSurveysComponent } from './material-exposure-surveys.component';
import { EXPOSURE_ROUTES } from './exposure.routes';

export class ExposureEditData {
    inSubEditor: boolean;
    currentTab: number;
}

@Component({
    selector: 'exposure-survey-lists',
    templateUrl: './exposure-survey-lists.html',
})

export class ExposureSurveyListsComponent implements OnInit {
    @ViewChild(MaterialExposureSurveysComponent) _materialExposureSurveysComponent: MaterialExposureSurveysComponent;
    @ViewChild(TabContainer) _tabContainer: TabContainer;
    _tab: number;

    public _data: ExposureEditData = new ExposureEditData();
    private _isLoading: boolean;
    private _navparam: number;

    public _selectedExposureSurveyIndex: number;
    public _selectedExposureSurvey: ExposureSurvey;
    public _exposureSurveys: ExposureSurvey[] = [];

    constructor(private _uow: UnitOfWork, private _stateMap: StateMap, private _userManager: UserManager, private _router: Router,
                public _route: ActivatedRoute, public _location: Location, public _errorLogger: ErrorLogger) {

    }

    ngOnInit() {

        this._stateMap.currentRouteName = EXPOSURE_ROUTES.ExposureSurveyLists.name;

        this._route.params.subscribe(params => {
            this._navparam = +params['id']; // exposure survey id
        });

        this.fetch();

        this._tab = LocationFns.getTab(this._location) || 0;
    }

    fetch() {

        let filterId = 0;

        this._exposureSurveys = [];

        this._isLoading = true;

        this._uow.fetch('ExposureSurveys/ExposureSurveys', {})
            .then(e => {
                this._exposureSurveys = e;
                if (this._exposureSurveys != null && this._exposureSurveys.length > 0) {
                    if (!isNaN(this._navparam)) {   // Filter to navigation param of exposuresurveyid instead of first in the array.
                        filterId = this._exposureSurveys.findIndex(x => x.exposureSurveyId == this._navparam);
                    }
                    this.filterExposureSurveyByIndex(filterId); // Set default Exposure Survey to the first in the list.
                }
                this._isLoading = false;
            });
    }

    // Filters
    filterExposureSurveyByIndex(ix: number) {
        this._selectedExposureSurveyIndex = ix;
        this._selectedExposureSurvey = this._exposureSurveys[ix];

        if (this._materialExposureSurveysComponent != null) {
            this._materialExposureSurveysComponent.onSurveyChange(this._selectedExposureSurvey);
        }

        setTimeout(() => {
            this._tabContainer.selectTab(0);
        }, 1);

    }

    canDeactivate() {
        return !this._data.inSubEditor && !this._uow.hasChanges();
    }

    get isLoading() {
        return this._isLoading;
    }

    set isLoading(value) {
        this._isLoading = value;
    }

    isStaff() {
        return this._stateMap.currentUser.isStaff;
    }

    get utilFns() {
        return UtilFns;
    }

    // Reports
    onExportCremeReport() {
        const params: { key: string, value: string }[] = [];

        params.push({ key: 'reporttype', value: 'creme' });
        params.push({ key: 'survey', value: '' + this._selectedExposureSurvey.exposureSurveyId });

        const url = this._userManager.getReportUrl(params);
        window.open(url, '_self');
        return false;
    }

    onExportFullSurveyReport() {
        const params: { key: string, value: string }[] = [];

        params.push({ key: 'reporttype', value: 'exposuresurveymaterials' });
        params.push({ key: 'survey', value: '' + this._selectedExposureSurvey.exposureSurveyId });

        const url = this._userManager.getReportUrl(params);
        window.open(url, '_self');
        return false;
    }

    // Navigation
    onShowExposureSurvey() {

        if (this._selectedExposureSurvey == null) {
            return;
        }

        this._router.navigate(['../exposure-surveys', { id: this._selectedExposureSurvey.exposureSurveyId }], { relativeTo: this._route });
    }
}

import { AfterViewInit, Component, OnDestroy, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { StateMap, SearchService, IMaterialSearchResult, IReferenceSearchResult } from '../../services/common';
import { PageState } from '../../controls/common';
import { UtilFns } from '../../utils/common';

import { MaterialResultsComponent } from '../material/material-results.component';
import { ReferenceResultsComponent } from '../reference/reference-results.component';

import { MaterialListItem} from '../../entities/projections/MaterialListItem';
import { ReferenceListItem} from '../../entities/projections/ReferenceListItem';
import { ROUTES } from '../routes';

@Component({
    selector: 'search-results',
    templateUrl: './search-results.html'
})
export class SearchResultsComponent implements AfterViewInit, OnDestroy {
    _materialSearchResult: IMaterialSearchResult;
    _referenceSearchResult: IReferenceSearchResult;
    _sub: any;
    _pageState = new PageState('Search Results');
    _key: ISearchKey;
    _pageSize = 20;
    @ViewChild(MaterialResultsComponent, { static: true }) _materialResultsComponent: MaterialResultsComponent;
    @ViewChild(ReferenceResultsComponent, { static: true }) _referenceResultsComponent: ReferenceResultsComponent;

    _firstSearchedPerformed = false;

    constructor(public _searchService: SearchService, public _stateMap: StateMap,
        public _router: Router, public _route: ActivatedRoute ) {
    }

    ngAfterViewInit() {
        this._sub = this._route.params.subscribe(params => {
            const filter = params['filter'];
            const searchTerm = UtilFns.decodeRouterPart(params['term']);
            const key = this.makeKey(searchTerm, filter);
            // this._materialResultsComponent._colSorter.reset();
            // this._referenceResultsComponent._colSorter.reset();
            if (!this.restoreState(key)) {
                return this.search(key);
            }
        });
    }

    ngOnDestroy() {
        this._sub.unsubscribe();
    }

    isStaff() {
        return this._stateMap.currentUser.isStaff;
    }

    makeKey(searchTerm: string, searchType: string): ISearchKey {
        if (searchTerm == null && searchType == null) {
            return null;
        } else {
            return {
                searchType: searchType,
                searchTerm: decodeURI(searchTerm) // decodeURI is needed if input has encoded chars from url
            };
        }
    }

    saveState(key: ISearchKey) {
        this._key = key;
        this._stateMap.set(this._pageState.displayName, {
            key: key,
            materialSearchResult: this._materialSearchResult,
            referenceSearchResult: this._referenceSearchResult
        });
    }

    restoreState(key: ISearchKey) {
        const val = this._stateMap.get(this._pageState.displayName);
        if (val == null) { return false; }

        if ((key == null) || (key.searchType == val.key.searchType && key.searchTerm == val.key.searchTerm)) {
            this._key = val.key;
            setTimeout( () => {
                this._materialSearchResult = val.materialSearchResult;
                this._referenceSearchResult = val.referenceSearchResult;
                this._materialResultsComponent.update(this, this._materialSearchResult);
                this._referenceResultsComponent.update(this, this._referenceSearchResult);
            });
            return true;
        } else {
            return false;
        }
    }

    search(key: ISearchKey): Promise<any> {
        if (key == null) { return null; }

        this._pageState.isLoading = true;
        this._materialResultsComponent.loading(true);
        this._referenceResultsComponent.loading(true);

        return this._searchService.search(key.searchTerm, key.searchType, this._pageSize).then(result => {
            this._materialSearchResult = result.materialSearchResult;
            this._referenceSearchResult = result.referenceSearchResult;

            // the variable storing the reference to the DxDataGridComponent from the ViewChild decorator was always null so refreshing the grid page and sort settings this way.
            if (this._firstSearchedPerformed) {
                this._materialResultsComponent.ngOnInit();
                this._referenceResultsComponent.ngOnInit();
            }

            this._materialResultsComponent.update(this, this._materialSearchResult);
            this._referenceResultsComponent.update(this, this._referenceSearchResult);
            this.saveState(key);

            this._firstSearchedPerformed = true;

        }).catch(() => {
            this._materialResultsComponent.loading(false);
            this._referenceResultsComponent.loading(false);
        });
    }

    selectMaterial(material: MaterialListItem) {
        this._router.navigate(UtilFns.asRouterParts(ROUTES.Material, material.materialId));
    }

    selectReference(reference: ReferenceListItem) {
        this._router.navigate(UtilFns.asRouterParts(ROUTES.Reference, reference.referenceId));
    }

}

interface ISearchKey {
    searchType: string;
    searchTerm: string;

}





<page-status [pageState]="_pageState"></page-status>

<div style="padding-right: 40px">
	<dx-data-grid *ngIf="hasData()"
			      [dataSource]="dataSource"
	              [columnAutoWidth]="true"
	              [showRowLines]="true"
	              [rowAlternationEnabled]="true"
	              [showBorders]="true"
	              [hoverStateEnabled]="true"
	              [allowColumnReordering]="true"
	              [allowColumnResizing]="true"
				  (onSelectionChanged)="onSelectionChanged($event)">
		<dxo-paging [enabled]="true" [pageSize]="20"></dxo-paging>
		<dxo-remote-operations [sorting]="true" [paging]="true"></dxo-remote-operations>
		<dxo-pager [showNavigationButtons]="true" [visible]="true" infoText="Page #{0}. Total: {1} ({2} materials)"></dxo-pager>
		<dxo-sorting mode="single"></dxo-sorting>
		<dxo-selection mode="single"></dxo-selection>

		<dxi-column caption="MaterialId" dataField="materialId" alignment="center"></dxi-column>
		<dxi-column caption="CAS No." dataField="realCASNumber"></dxi-column>

		<dxi-column *ngIf="isStaff()" caption="Captive" cellTemplate="captive" dataField="confidential"></dxi-column>
		<div *dxTemplate="let cell of 'captive'">
			<span class="captive-text">{{ getCaptiveText(cell.data.confidential) }}</span>
		</div>

		<dxi-column caption="RIFM ID" dataField="formattedRifmId"></dxi-column>
		<dxi-column caption="FEMA No." dataField="fEMANumber" alignment="left"></dxi-column>
		<dxi-column caption="EINECS" dataField="eINECSNumber"></dxi-column>
		<dxi-column caption="ECN" dataField="eCNNumber"></dxi-column>
		<dxi-column caption="EC No." dataField="eCInventoryNumber"></dxi-column>

		<dxi-column caption="Alt. CAS Nos." cellTemplate="altCasNos" dataField="alternateCASNumber"></dxi-column>
		<div *dxTemplate="let cell of 'altCasNos'">
			<span *ngIf="cell.data.alternativeCASNumbers && cell.data.alternativeCASNumbers.length > 0">{{cell.data.alternativeCASNumbers?.join('; ')}}</span>
		</div>

		<dxi-column caption="Principal Synonym" cellTemplate="principalSynonym" dataField="synonymWordOrWebVersion"></dxi-column>
		<div *dxTemplate="let cell of 'principalSynonym'">
			<span [htmlGreek]="cell.data.synonymWordOrWebVersion"></span>
		</div>

		<dxi-column caption="Found In" dataField="searchResolution" [allowSorting]="false"></dxi-column>
		<dxi-column caption="Match" dataField="foundText" [allowSorting]="false"></dxi-column>
	</dx-data-grid>
</div>

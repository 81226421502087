import { Location } from '@angular/common';
import { Component, ViewChild, Inject, OnInit } from '@angular/core';

import { Router, ActivatedRoute } from '@angular/router';

import { UnitOfWork, StateMap, ErrorLogger, provideParent, UserManager } from '../../services/common';
import { EditManager, IEditHost, UtilFns } from '../../utils/common';
import { EditPropParent } from '../../controls/common';
import { Material, ExposureSurvey, MaterialExposureSurvey, MaterialExposureSurveyMeasure, MaterialExposureSurveyIsomer } from '../../entities/EntityModels';

import { RecordExposureSurveyMeasuresComponent, MeasureEditData } from './record-exposure-survey-measures.component';

import { SelectableEntity } from '../../entities/projections/SelectableEntity';
import { SelectionItem } from '../../entities/projections/SelectionItem';

import { ROUTES } from '../routes';
import * as _ from 'lodash';

@Component({
    selector: 'material-exposure-survey-isomer-measures',
    templateUrl: './material-exposure-survey-isomer-measures.html',
})
export class MaterialExposureSurveyIsomerMeasuresComponent implements OnInit {

    public _isLoading = false;
    public _validationMessage: string;

    public _selectedExposureSurveyIndex = -1;
    public _selectedExposureSurvey: SelectionItem;
    public _uniqueExposureSurveys: SelectionItem[] = [];

    public _materialExposureSurveyIsomers: MaterialExposureSurveyIsomer[] = [];

    public _selectedIsomerMaterial: Material;
    public _selectedIsomerMaterialIndex = -1;
    public _uniqueIsomerMaterials: SelectionItem[] = [];

    public _selectedMaterialExposureSurveyIndex = -1;
    public _selectedMaterialExposureSurvey: MaterialExposureSurvey;
    public _materialExposureSurveys: MaterialExposureSurvey[] = [];

    public _materialExposureSurveyMeasures: MaterialExposureSurveyMeasure[] = [];
    public _materialExposureSurveyMeasuresEx: SelectableEntity<MaterialExposureSurveyMeasure>[] = [];

    constructor(private _uow: UnitOfWork, private _stateMap: StateMap, private _userManager: UserManager, private _router: Router,
                 public _route: ActivatedRoute, public _location: Location, public _errorLogger: ErrorLogger) {

    }

    ngOnInit() {
        this.fetchIsomers();
    }

    private clearFilteredData() {
        this._selectedIsomerMaterial            = null;
        this._selectedIsomerMaterialIndex       = -1;

        this._selectedExposureSurveyIndex       = -1;
        this._selectedExposureSurvey            = null;
        this._uniqueExposureSurveys             = [];

        this._selectedMaterialExposureSurvey    = null;
        this._materialExposureSurveys           = [];

        this._materialExposureSurveyMeasures    = [];

    }
    fetchIsomers() {
        this.clearFilteredData();

        this._materialExposureSurveyIsomers     = [];
        this._uniqueIsomerMaterials             = [];

        this._isLoading = true;

        this._uow.fetch('ExposureSurveys/MaterialExposureSurveyIsomers', {}).then(isomers => {
            if (isomers != null && isomers.length > 0) {
                this._materialExposureSurveyIsomers = isomers;

                this._materialExposureSurveyIsomers.map(i => {
                    if (this._uniqueIsomerMaterials.findIndex(u => u.id == i.materialId) < 0) {
                        const casandrifmid = MaterialExposureSurveyIsomerMeasuresComponent.formatIsomerIdentifiers(i.material);
                        const syn = i.material.principalSynonym().synonymWordOrWebVersion();
                        this._uniqueIsomerMaterials.push({ id: i.materialId, textId: casandrifmid, description: syn, selected: false });
                    }
                });
            }
            this._isLoading = false;
        });
    }

    static formatIsomerIdentifiers(material: Material) {
        const rifmId = (material.formattedRIFMId == '-') ? '' : ' (RIFM Id: ' + material.formattedRIFMId + ')';
        return material.realCASNumber + rifmId;
    }

    fetchMeasures() {

        if (this._selectedMaterialExposureSurvey == null) { return; }

        this._isLoading = true;

        const params = { materialId: this._selectedMaterialExposureSurvey.materialId, exposureSurveyId: this._selectedMaterialExposureSurvey.exposureSurveyId };
        this._uow.fetch('ExposureSurveys/FetchExposureSurveyMaterialMeasures', params).then(m => {
            this._materialExposureSurveyMeasures = m;
            this.formatSelectableEntities();
            this._isLoading = false;
        });
    }

    formatSelectableEntities() {
        this._materialExposureSurveyMeasuresEx = [];
        if (this._materialExposureSurveyMeasures == null || this._materialExposureSurveyMeasures.length < 1) { return; }

        _.clone(this._materialExposureSurveyMeasures).forEach(element => {
            const isMultiple = this.isDuplicateMeasureEntry(element.materialId, element.typeExposureMeasureId);
            this._materialExposureSurveyMeasuresEx.push(new SelectableEntity<MaterialExposureSurveyMeasure>(element, '#FFFFFF', '', isMultiple));
        });
    }

    isDuplicateMeasureEntry(materialId: number, typeExposureMeasureId: number): boolean {
        if (this._materialExposureSurveyMeasures == null) { return false; }

        const measure = this._materialExposureSurveyMeasures.filter(m => m.materialId == materialId && m.typeExposureMeasureId == typeExposureMeasureId);
        return measure != null && measure.length > 1;
    }

    get isLoading() {
        return this._isLoading;
    }

    set isLoading(value) {
        this._isLoading = value;
    }

    isStaff() {
        return this._stateMap.currentUser.isStaff;
    }

    get utilFns() {
        return UtilFns;
    }

    canDeactivate() {
        return !this._uow.hasChanges();
    }

    canShowBack() {
        return !this._uow.hasChanges();
    }

    // Filters
    filterIsomerMaterialByIndex(ix: number) {

        this.clearFilteredData();

        const surveyIsomers = this._materialExposureSurveyIsomers.filter(i => i.materialId == this._uniqueIsomerMaterials[ix].id);

        if (surveyIsomers == null || surveyIsomers.length < 1) { return; }

        this._selectedIsomerMaterial = surveyIsomers[0].material;

        const surveys = surveyIsomers.map(i => i.materialExposureSurvey.exposureSurvey);
        surveys.forEach(s => {
            if (this._uniqueExposureSurveys.findIndex(u => u.id == s.exposureSurveyId) < 0) {
                this._uniqueExposureSurveys.push({id: s.exposureSurveyId, textId: s.exposureSurveyMonthYearNumber, description: '', selected: false});
            }
        });
        this.filterExposureSurveyByIndex(0);
    }

    public filterExposureSurveyByIndex(ix: number) {
        if (this._materialExposureSurveyIsomers == null) { return; }

        this._selectedExposureSurveyIndex   = ix;
        this._selectedExposureSurvey        = this._uniqueExposureSurveys[ix];

        // A few isomers were surveyed for more than one material within a given survey.
        // This is why there is a selection list of survey primary materials.
        // tslint:disable-next-line:max-line-length
        const matsurveys = this._materialExposureSurveyIsomers.filter(m => m.materialId == this._selectedIsomerMaterial.materialId && m.materialExposureSurvey.exposureSurveyId == this._selectedExposureSurvey.id);

        this._materialExposureSurveys               = [];
        this._materialExposureSurveyMeasures        = [];
        _.clone(matsurveys).forEach(m => {
            this._materialExposureSurveys.push(m.materialExposureSurvey);
            }
        );
        this.filterMaterialExposureSurveyByIndex(0);
        return true;
    }

    filterMaterialExposureSurveyByIndex(ix: number) {
        this._selectedMaterialExposureSurveyIndex   = ix;
        this._selectedMaterialExposureSurvey = this._materialExposureSurveys[ix];
        this.fetchMeasures();
    }

    onShowMaterial(mat: Material) {
        if (mat == null) { return; }

        this._router.navigate(UtilFns.asRouterParts(ROUTES.Material, mat.materialId, ROUTES.Material.childRoutes.MaterialExposure));
    }
}

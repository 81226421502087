import { EntityBase } from './EntityBase';
import { Material } from './Material';
import { TypeREACHTonnageBand } from './TypeREACHTonnageBand';

import { Validator } from 'breeze-client';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

export class REACHRegistration extends EntityBase {

   /// <code> Place custom code between <code> tags
   
   /// </code>

   // Generated code. Do not place code below this line.
   rEACHRegistrationId: number;
   materialId: number;
   eCListNumber: string;
   registrationCASNumber: string;
   registrationName: string;
   registrationType: string;
   submissionType: string;
   typeREACHTonnageBandId: number;
   dossierId: string;
   dossierWebLink: string;
   registrationReportDate: Date;
   created: Date;
   createdUser: string;
   modified: Date;
   modifyUser: string;
   rowVersion: number;
   material: Material;
   typeREACHTonnageBand: TypeREACHTonnageBand;
}


<!--<div style="height: 400px; overflow-y: scroll; ">-->
<div style="height: 70vh; overflow-y: scroll; ">
    <div *ngFor="let e of _announcements">
        <div *ngIf="e.enabled || isStaff()" class="panel panel-default">
            <div class="panel-body" [class.alert-warning]="!e.enabled">
                <div class="announcement-title">{{e.title}}</div>
                <div class="announcement-content">{{e.content}}</div>
                <div style="margin-left: 10px; margin-top: 20px;">
                    <a *ngIf="e.webLink" (click)="linkTo(e)" style="font-size: 14px">{{e.webLinkDescription}}</a>
                    <img *ngIf="e.imageLink" [src]="e.imageLink" style="width:70%; margin-left:15%; margin-right: 15%" />
                </div>
            </div>
        </div>
    </div>
</div>
import { Component, ViewChild, Inject, Input, forwardRef, OnInit } from '@angular/core';

import { UnitOfWork, provideParent } from '../../services/common';
import { UtilFns } from '../../utils/common';

import { Journal } from '../../entities/EntityModels';
import { RelatedJournalNameItem } from '../../entities/projections/RelatedJournalNameItem';

import {JournalSelectorComponent, JournalSearchData } from './journal-selector.component';

import * as _ from 'lodash';

@Component({
    selector: 'journal-names',
    templateUrl: './journal-names.html',
    providers: [provideParent(JournalNamesComponent)]
})export class JournalNamesComponent implements OnInit {

    _userMessage: string;

    _relatedJournals: RelatedJournalNameItem[];

    _data: JournalSearchData;

    private colorSelected   = 'B0E0E6'; // '#C6E7CE'; // '#e0eeee';
    private colorUnselected = '#FFFFFF';

    constructor(@Inject(forwardRef(() => JournalSelectorComponent)) public _parent: JournalSelectorComponent, public _uow: UnitOfWork) {
        this._data = _parent._data;
        this._data.onResetMap['base']  = this.onReset.bind(this);
    }

    ngOnInit() {
        if (this._data != null && this._data.entity != null) {
            this.fetchJournalsRelatedByName();
        }
    }

    fetchJournalsRelatedByName() {
        this.onReset();

        const params = {
            journalId: this._data.entity.journalId,
            rootJournalId: this._data.entity.rootJournalId,
            journalName: UtilFns.encodeHackForAnd(this._data.entity.journalName)
        };

        return this._uow.fetch('Misc/FetchJournalsRelatedByName', params).then(r => {
            if (r != null && r.length > 0) {
                this._relatedJournals = r;
            }
        });
    }

    hasNamesData() {
        return (this._relatedJournals != null && this._relatedJournals.length > 0);
    }

    get userMessage() {
        return this._userMessage;
    }

    get utilFns() {
        return UtilFns;
    }

    onReset() {
        this._userMessage	    = null;
        this._relatedJournals   = null;
    }
}


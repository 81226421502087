<div class="indent-left-md indent-top-sm">
    <div class="home-page-title">Search by Structure</div>
    <ul class="nav nav-tabs">
        <li (click)="_isShowingSearch =true" role="presentation" [class.active]="_isShowingSearch">
            <a href="javascript:void(0)"><span>Search</span></a>
        </li>
        <li (click)="_isShowingSearch = false" role="presentation" [class.active]="!_isShowingSearch">
            <a href="javascript:void(0)"><span>Search Results</span></a>
        </li>
    </ul>

    <div [attr.data-hidden]="!_isShowingSearch">
        <div class="inline subtitle-margin-top">
            <span class="bold inline vertical-top" style="margin-left: 8px;">Draw a structure or enter a SMILES:</span>
            <textarea class="form-control inline vertical-top" rows=1 type="text" style="margin-left: 6px; width: 365px;" (input)="onChangeSMILES()" [(ngModel)]="_inputSmiles"></textarea>
            <span *ngIf="_isStructureError" class="bold alert-warning inline">Cannot draw this SMILES</span>
            <button class="btn btn-select-active inline vertical-top" style="margin-top: 2px;" type="button" (click)="onDrawSMILES()" [disabled]="!canDrawSMILES()">Draw SMILES</button>
            <button type="button" class="btn btn-primary btn-no-margin btn-structure-search vertical-top" (click)="search()">Search Structure</button>
        </div>
        <div class="indent-top-sm">
            <span *ngIf="_isError" class="bold alert alert-warning">Sorry: unable to run this search</span>
            <span class="inline italic" style="margin-left: 6px;">SMILES from Structure:</span>
            <span class="inline" style="margin-left: 4px; background-color: #F0F8FF;">{{smiles}}</span>
        </div>

        <div *ngIf="_pageState.isLoading">
            <i class="fa fa-3x fa-circle-o-notch fa-spin" style="margin-top: 5px;margin-bottom: 5px;"></i>
            <span>Searching through all the materials in the RIFM Database... </span>
        </div>
        <div class="flex-container background-transparent">
            <div class="flex-item">
                <div class="resizable indent-top-sm">
                    <iframe src="assets/marvin/MarvinEditor/editor.html?license={licenseUrl}" id="sketch" class="sketcher-frame" style="width: 500px; height: 600px "></iframe>
                </div>
            </div>
            <div class="flex-item">
                <div class="inline" style="margin-top: 6px;">
                    <span class="bold">Similarity Threshold: </span>
                    <input type="text" class="form-control" style="width: 65px;" [(ngModel)]="_similarityThreshold"/>
                </div>
            </div>
            <div class="flex-item" style="margin-left: 14px;">
                <div style="margin-top: 5px;">
                    <div *ngIf="_validationMessage">
                        <p class="alert-danger">{{_validationMessage}}</p>
                    </div>

                    <p class="italic" style="mix-width: 480px; background-color: aliceblue;">This value influences how closely the searched structure should match the drawn structure. Valid entries are a number between 1 and 99.
                        The higher the value indicates you want a greater level of similarity; this results in a more restrictive the set of matches. Lower values (< 50) will
                        return materials that are slightly similar to the drawn structure and typically increase the number of matches found.</p>
                    <div style="margin-top: 40px;">
                        <p style="text-decoration: underline;">Informational References</p>
                        <a href="http://ijssst.info/Vol-15/No-2/data/3251a116.pdf" target="_blank" style="display: block; margin-bottom: 24px;">Syuib, Mukhsin & Arif, Shereena & Malim, Nurul. (2013). "Comparison of Similarity Coefficients for Chemical Database Retrieval."</a>
                        <a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC4456712/" target="_blank">Bajusz D, Rácz A, Héberger K. "Why is Tanimoto index an appropriate choice for fingerprint-based similarity calculations?"</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="_materials && !_isShowingSearch">
        <div class="inline" style="margin-top: 4px; margin-bottom: 4px;">
            <span class="bold inline">{{searchResultsStatus}}</span>
            <button type="button" class="btn btn-copy-to-clipboard" [disabled]="!hasSearchResults()" (click)="onCopyCASToClipboard()">Copy CAS Numbers to Clipboard</button>
        </div>
        <default-table *ngIf="_materials.length > 0">
            <thead>
                <tr>
                    <td>CAS #</td>
                    <td>RIFM ID</td>
                    <td>Principal Name</td>
                    <td>Structure</td>
                    <td class="text-center">Similarity Score</td>
                    <td *ngIf="isStaff()">Captive</td>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let e of _materials " (click)="onCasSelect(e)" >
                    <td (click)="onCasSelect(e) ">
                        <a>{{e.RealCASNumber || '(No CAS#)'}}</a>
                    </td>
                    <td>{{e.FormattedRIFMId}}</td>
                    <td [htmlGreek]="e.SynonymWordOrWebVersion"></td>
                    <!-- old version ( doesn't support copy/paste) -->
                    <!--<td><img [src]="getMaterialImageUrl(e) " /></td>-->
                    <td><img *ngIf="e.Base32SmileUrl" [src]="e.Base32SmileUrl" /></td>
                    <td class="text-center">{{utilFns.formatNumber(e.Score, 3, 2)}}</td>
                    <td *ngIf="isStaff()">
                        <span *ngIf="e.Confidential" class="captive-text"> Captive</span>
                    </td>
                </tr>
            </tbody>
        </default-table>
    </div>


</div>

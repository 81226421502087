<div style="margin-bottom: 10px">
    <div class="inline" style="font-size: 18px;" *ngIf="_edm.editing">{{_adding ? "Adding a Project" : "Editing a Project" }}</div>
    <span *ngIf="(!_edm.editing) || _edm.canShowBack()">
        <button type="button" class="inline btn btn-sm btn-primary"  (click)="_edm.onAdd()">Add a Project</button>            
        <button type="button" class="inline btn btn-sm btn-primary"  (click)="onEdit()"> {{ "Edit " + (_edm.editing ? "Another " : "a ") + "Project" }}</button>
    </span>
</div>
<editor-header [edm]="_edm" [customButtons]="true">
    <span customButtons>
        <span *ngIf="_edm.editing">
            <button type="button" class="btn btn-sm btn-primary" [disabled]="!_edm.canSave()" (click)="_edm.onSave()">Save</button>
            <button *ngIf="!_adding" type="button" class="btn btn-sm btn-primary" [disabled]="!_edm.canDelete()" (click)="_edm.onDelete()">Delete</button>
            <button *ngIf="_edm.canShowBack()" type="button" class="btn btn-sm btn-primary"  (click)="_edm.onBack()">Back</button>
            <button *ngIf="!_edm.canShowBack()" type="button" class="btn btn-sm btn-primary" [disabled]="!_edm.canCancel()" (click)="_edm.onCancel()">Cancel</button>
        </span>
    </span>
</editor-header>

<div *ngIf="_edm.editing" class="form-horizontal">
    <edit-prop label="Project Name" prop="projectName" szs="2,9">
        <input type="text" class="form-control" [(ngModel)]="entity.projectName">
    </edit-prop>
</div>

<project-selector></project-selector>
import { EntityBase } from './EntityBase';
import { Material } from './Material';
import { ExposureSurvey } from './ExposureSurvey';
import { TypeCremeVersion } from './TypeCremeVersion';
import { TypeExposureMeasure } from './TypeExposureMeasure';
import { TypeExposureMeasureValue } from './TypeExposureMeasureValue';

import { Validator } from 'breeze-client';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

export class MaterialExposureSurveyMeasure extends EntityBase {

   /// <code> Place custom code between <code> tags
   public hasQualitativeValue(): boolean {
        return (this.typeExposureMeasureValue.measureValueType == 'Qualitative');
    }

    public hasQuantitativeValue(): boolean {
        return (this.typeExposureMeasureValue.measureValueType == 'Quantitative' || this.typeExposureMeasureValue.measureValueType == 'Percentage');
    }
   /// </code>

   // Generated code. Do not place code below this line.
   materialExposureSurveyMeasureId: number;
   materialId: number;
   exposureSurveyId: number;
   typeExposureMeasureId: number;
   typeExposureMeasureValueId: number;
   measureQualitativeResult: string;
   measureQuantitativeResult: number;
   measureNotes: string;
   originalSurveyId: number;
   typeCremeVersionId: number;
   created: Date;
   createdUser: string;
   modified: Date;
   modifyUser: string;
   rowVersion: number;
   exposureSurvey: ExposureSurvey;
   material: Material;
   typeCremeVersion: TypeCremeVersion;
   typeExposureMeasure: TypeExposureMeasure;
   typeExposureMeasureValue: TypeExposureMeasureValue;
}


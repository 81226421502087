<div>
    <div class="row left-indent">
        <i *ngIf="_pageState.isLoading" class="fa fa-circle-o-notch fa-spin" style="font-size:38px; color: #337ab7;"></i>
    </div>

    <p style="margin-top: 32px; margin-left: 13px;" *ngIf="_journalSearchResults == null || _journalSearchResults.length < 1" class="bold">{{userMessage}}</p>

    <div *ngIf="_journalSearchResults != null && _journalSearchResults.length > 0"  style="margin-left: -8px;">
		<span class="inline" style="margin-left: 10px;">
			<span  class="bold">{{'Number of matches: ' + resultsCount}}</span>
			<span *ngIf="!_selectOnlyMode" class="bold" style="margin-left: 16px; color: #337AB7;">Select a row to view Related Names, Reserves and Subscription data.</span>
		</span>
        <div style="width: 1100px; margin-left: 10px; margin-top: 12px; min-height: 180px; height: auto;">
            <div class="scrollableTableHeader">
                <div id="journal-id-header" class="scrollableTableColumn default-font text-center" (mouseenter)="toggleTooltip(1)" (mouseleave)="toggleTooltip(1)">
                    <span class="inline" style="width: 60px">JournalId</span>
                </div>
                <div id="journal-name-header"  class="scrollableTableColumn default-font text-left" (mouseenter)="toggleTooltip(2)" (mouseleave)="toggleTooltip(2)">
                    <span class="inline" style="width: 220px">Current Name</span>
                </div>
                <div id="journal-reserves-header" class="scrollableTableColumn default-font text-center" (mouseenter)="toggleTooltip(3)" (mouseleave)="toggleTooltip(3)">
                    <span class="inline" style="width: 80px">Journal Reserves in Library</span>
                </div>
                <div id="journal-subscriptions-header" class="scrollableTableColumn default-font text-center"  (mouseenter)="toggleTooltip(4)" (mouseleave)="toggleTooltip(4)">
                    <span class="inline" style="width: 80px">Subscription Status</span>
                </div>
                <div id="journal-subscription-type-header" class="scrollableTableColumn default-font text-center" (mouseenter)="toggleTooltip(5)" (mouseleave)="toggleTooltip(5)">
                    <span class="inline" style="width: 135px">Subscription Type</span>
                </div>
                <div id="journal-notes-header" class="scrollableTableColumn default-font text-center" (mouseenter)="toggleTooltip(6)" (mouseleave)="toggleTooltip(6)">
                    <span class="inline" style="width: 120px">Notes</span>
                </div>
                <div id="journal-related-names-header" class="scrollableTableColumn default-font text-left" (mouseenter)="toggleTooltip(7)" (mouseleave)="toggleTooltip(7)">
                    <span class="inline" style="width: 220px">Related Names</span>
                </div>
                <div id="journal-found-in-header" *ngIf="showFoundIn()" class="scrollableTableColumn default-font text-center" (mouseenter)="toggleTooltip(8)" (mouseleave)="toggleTooltip(8)">
                    <span class="inline" style="width: 170px">Found In</span>
                </div>
                <div id="journal-subscription-publishers-header" *ngIf="showPublishers()" class="scrollableTableColumn default-font text-center" (mouseenter)="toggleTooltip(9)" (mouseleave)="toggleTooltip(9)">
                    <span class="inline" style="width: 170px">Publishers</span>
                </div>
            </div>

            <div style="max-height: 270px; width: 1270px; overflow-y:auto; overflow-x: hidden; border-bottom: solid 1px lightgray;">
                <div class="scrollableTableRow" *ngFor="let j of _journalsEx" (click)="onSelect(j)" [ngStyle]="{ 'background-color': j.backgroundColor }">
                    <div class="scrollableTableColumn default-font text-center"><span class="inline" style="width: 60px">{{j.data.journalId}}</span></div>
                    <div class="scrollableTableColumn default-font text-left"><span class="inline" style="width: 220px">{{j.data.journalName}}</span></div>
                    <div class="scrollableTableColumn default-font text-center"><span class="inline" style="width: 80px">{{j.data.hasReserves?'Yes' : ''}}</span></div>
                    <div class="scrollableTableColumn default-font text-center"><span class="inline" style="width: 80px">{{j.data.subscriptionStatus}}</span></div>
                    <div class="scrollableTableColumn default-font text-center"><span class="inline" style="width: 135px">{{j.data.subscriptionTypeDescriptions?.join('; ')}}</span></div>
                    <div class="scrollableTableColumn default-font text-center"><span class="inline" style="width: 120px">{{j.data.notes}}</span></div>
                    <div class="scrollableTableColumn default-font text-left"><span class="inline ref-title-wrap" style="width: 220px">{{j.data.formerNames?.join('; ')}}</span></div>
                    <div *ngIf="showFoundIn()" class="scrollableTableColumn default-font text-center"><span class="inline" style="width: 170px">{{j.data.foundIn}}</span></div>
                    <div *ngIf="showPublishers()" class="scrollableTableColumn default-font text-center"><span class="inline" style="width: 170px">{{j.data.publishers?.join('; ')}}</span></div>
                </div>
            </div>

            <div id="tooltip-container" style="font-size: 14px !important;">
                <dx-tooltip style="border: 1px solid #67b168" target="#journal-id-header" [(visible)]="toggleShowJournalIdTip" [closeOnOutsideClick]="false">
                    <div *dxTemplate="let data = data of 'content'" [innerHTML]="formatTooltipContent(1)">
                    </div>
                </dx-tooltip>
                <dx-tooltip style="border: 1px solid #67b168" target="#journal-name-header" [(visible)]="toggleShowJournalNameTip" [closeOnOutsideClick]="false">
                    <div *dxTemplate="let data = data of 'content'" [innerHTML]="formatTooltipContent(2)">
                    </div>
                </dx-tooltip>
                <dx-tooltip style="border: 1px solid #67b168" target="#journal-reserves-header" [(visible)]="toggleShowJournalReservesTip" [closeOnOutsideClick]="false">
                    <div *dxTemplate="let data = data of 'content'" [innerHTML]="formatTooltipContent(3)">
                    </div>
                </dx-tooltip>
                <dx-tooltip style="border: 1px solid #67b168" target="#journal-subscriptions-header" [(visible)]="toggleShowJournalSubscriptionsTip" [closeOnOutsideClick]="false">
                    <div *dxTemplate="let data = data of 'content'" [innerHTML]="formatTooltipContent(4)">
                    </div>
                </dx-tooltip>
                <dx-tooltip style="border: 1px solid #67b168" target="#journal-subscription-type-header" [(visible)]="toggleShowJournalSubscriptionTypeTip" [closeOnOutsideClick]="false">
                    <div *dxTemplate="let data = data of 'content'" [innerHTML]="formatTooltipContent(5)">
                    </div>
                </dx-tooltip>
                <dx-tooltip style="border: 1px solid #67b168" target="#journal-notes-header" [(visible)]="toggleShowJournalNotesTip" [closeOnOutsideClick]="false">
                    <div *dxTemplate="let data = data of 'content'" [innerHTML]="formatTooltipContent(6)">
                    </div>
                </dx-tooltip>
                <dx-tooltip style="border: 1px solid #67b168" target="#journal-related-names-header" [(visible)]="toggleShowJournalRelatedNamesTip" [closeOnOutsideClick]="false">
                    <div *dxTemplate="let data = data of 'content'" [innerHTML]="formatTooltipContent(7)">
                    </div>
                </dx-tooltip>
                <dx-tooltip style="border: 1px solid #67b168" target="#journal-found-in-header" [(visible)]="toggleShowJournalFoundInTip" [closeOnOutsideClick]="false">
                    <div *dxTemplate="let data = data of 'content'" [innerHTML]="formatTooltipContent(8)">
                    </div>
                </dx-tooltip>
                <dx-tooltip style="border: 1px solid #67b168" target="#journal-subscription-publishers-header" [(visible)]="toggleShowJournalSubscriptionPublishersTip" [closeOnOutsideClick]="false">
                    <div *dxTemplate="let data = data of 'content'" [innerHTML]="formatTooltipContent(9)">
                    </div>
                </dx-tooltip>
            </div>
        </div>
    </div>
</div>

import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';

import { StateMap, UnitOfWork } from '../../services/common';
import { Material } from '../../entities/Material';

import { MATERIAL_ROUTES } from './material.routes';

@Component({
    selector: 'naturals',
    templateUrl: './naturals.html',
})
export class NaturalsComponent implements OnInit {

    material: Material;

    constructor(public _uow: UnitOfWork, public _stateMap: StateMap, public _location: Location) {
    }

    ngOnInit() {
        this.material = this._stateMap.currentMaterial;
        this._stateMap.currentRouteName = MATERIAL_ROUTES.Naturals.name;
    }

    canDeactivate() {
        return true;
    }
}

<div class="indent-left-sm">
    <h4 class="bold">Synopsis Report</h4>
    <div>
        <button type="button" (click)="generateReport(false)" class="btn btn-no-margin btn-primary">Full Report</button>
        <button type="button" (click)="generateReport(true)" class="btn btn-primary">RIFM Authored Research</button>
    </div>
    <div class="page-title" style="margin-top: 15px; margin-bottom: 10px">
        <span style="margin-right: 10px">Study Types </span>
        <button type="button" (click)="selectAll(true)" class="btn btn-no-margin btn-primary btn-xs">Select all</button>&nbsp;
        <button type="button" (click)="selectAll(false)" class="btn btn-no-margin btn-primary btn-xs">Deselect all</button>
    </div>
    <div>
        <page-status [pageState]="_pageState"></page-status>
    </div>
    <div *ngIf="_largeResultMessage" class="alert-warning">
        {{_largeResultMessage}}
    </div>

    <div class="col-md-3 panel panel-default">
        <div class="panel-body">
            <div *ngFor="let ts of _typeStudies, let ix=index">
                <div>
                    <input type="checkbox" [(ngModel)]="ts.selected">
                    <span class="bold">  {{ts.typeStudy.studyLong}}</span>
                </div>
            </div>
        </div>
    </div>
</div>

<synopsis-report *ngIf="_canShowReport" [_pageState]="_pageState" [_material]="_material" [_expMats]="_expMats" [_typeStudyIds]="_typeStudyIds" [_rifmAsAuthorOnly]="_rifmAsAuthorOnly"> </synopsis-report>

import { Location } from '@angular/common';
import {Component, forwardRef, Inject, Input, OnInit, AfterViewInit, ViewChild} from '@angular/core';

import {UnitOfWork, StateMap, provideParent, WorkflowEntityState, ReferenceState} from '../../services/common';
import { PageState, EditPropParent } from '../../controls/common';
import { Reference } from '../../entities/EntityModels';
import { ReferenceSelectorComponent } from '../reference/reference-selector.component';
import {ReferenceDocumentEditData, RIFMDocumentsComponent} from '../docmanagement/rifm-documents.component';

import * as _ from 'lodash';
import {UtilFns} from '../../utils/util-fns';
import {Observable, Subject} from 'rxjs';
import {HttpClient, HttpHeaders} from '@angular/common/http';

@Component({
    selector: 'add-reference-document',
    templateUrl: './add-reference-document.html',
    providers: [provideParent(AddReferenceDocumentComponent, EditPropParent)]
})

export class AddReferenceDocumentComponent implements OnInit, AfterViewInit {
    @ViewChild(ReferenceSelectorComponent, { static: true }) _referenceSelectorComponent: ReferenceSelectorComponent;

    @Input() documentManagementUrl: string;

    public _data: ReferenceDocumentEditData = new ReferenceDocumentEditData();

    public _loading = false;
    public _pageState = new PageState('Add Reference Document');

    // add new reference document variables
    public _selectedReferenceForAdd: Reference;
    public _filedata: any;
    public _fileInputElement: any;

    public _addedSuccessfully           = false;
    public _lastAddedReferenceId        = -1;
    public _lastAddedReferenceMessage   = '';
    public _showAddButton               = false;
    public _validationMessage           = '';

    constructor(public _uow: UnitOfWork, public _stateMap: StateMap, public _location: Location, private _httpClient: HttpClient, private _referenceState: ReferenceState,
                @Inject(forwardRef(() => RIFMDocumentsComponent)) public _parent: RIFMDocumentsComponent) {

        this._data = _parent._data;
    }

    ngOnInit() {
    }

    ngAfterViewInit() {
        this._fileInputElement = $('#fileInputElement')[0] as HTMLInputElement;
    }

    public cancelAdd() {
        this._filedata = null;
        if (this._fileInputElement != null) {
            this._fileInputElement.value = null;
        }

        this._lastAddedReferenceId      = -1;
        this._lastAddedReferenceMessage = '';
        this._selectedReferenceForAdd   = null;
    }

    onSelectReferenceForAdd() {

        this._validationMessage = '';

        UtilFns.showModal(this._referenceSelectorComponent, this, null).then(rli => {
            if (rli == null) {
                return;
            }
            return this._uow.referenceRepository.withId(rli.referenceId).then(r => {
                this._selectedReferenceForAdd = r;

                if (this.fileData != null && this._selectedReferenceForAdd != null) {
                    this.checkIfFileNameMatchReferenceId();
                    this._showAddButton = true;
                }
            });
        });
    }

    public get assignedReferenceId(): number {
        return (this._selectedReferenceForAdd != null) ? this._selectedReferenceForAdd.referenceId : -1;
    }

    public get fileData(): any {
        return this._filedata;
    }

    public set fileData(val: any) {
        this._filedata = val;

        if (this.fileData != null && this._selectedReferenceForAdd != null) {
            this.checkIfFileNameMatchReferenceId();
            this._showAddButton = true;
        }
    }

    get utilFns() {
        return UtilFns;
    }

    public checkIfFileNameMatchReferenceId() {

        const fileName = this._filedata.name.split('.')[0];

        this._validationMessage =  (this._selectedReferenceForAdd.referenceId + '' == fileName) ? '' : 'File name does not match Reference Id. This is can be ok, just checking...';
    }

    // ******************************************************************
    // Handle initial file upload
    // ******************************************************************
    onFileInput(files: FileList) {
        this._validationMessage = '';
        this.fileData = files.item(0);
    }

    // ************************************************************************
    // add reference document
    // ************************************************************************
    public canAddReferenceDocument(): boolean {
        return (this.fileData != null && this._selectedReferenceForAdd != null);
    }

    public canShowBackFromAddReferenceDocument() {
        if (this._addedSuccessfully) {
            return true;
        }
    }

    public onCancel() {
        if (this._fileInputElement != null) {
            this._fileInputElement.value = null;
        }
        this._parent.addCompleted(-1);
    }

    public onBackFromAddReferenceDocument() {
        this._parent.addCompleted(this._lastAddedReferenceId);
    }

    public onSaveAdd() {

        this.addReferenceDocument().subscribe((r) => {
            this._addedSuccessfully = r;

            this._showAddButton = false;

            if (this._addedSuccessfully) {
                this._lastAddedReferenceMessage     = 'Successfully added new document for ReferenceId: ' + this._selectedReferenceForAdd.referenceId;
                this._lastAddedReferenceId          = this._selectedReferenceForAdd.referenceId;

                this._fileInputElement.value        = null;
                this._selectedReferenceForAdd       = null;
            } else {
                this._lastAddedReferenceMessage     = 'Error adding new Reference Document.';
                this._lastAddedReferenceId          = -1;
            }
        });
    }

    // ************************************************************************
    // web api calls
    // ************************************************************************
    getAddDocumentURL(): Observable<any> {
        const url = this.documentManagementUrl + '/AddRIFMDocument';
        const headers = new HttpHeaders();
        headers.append('Accept', 'application/json');

        const formData: FormData = new FormData();
        formData.append('referenceId', this._selectedReferenceForAdd.referenceId + '');
        formData.append('fileName', this._selectedReferenceForAdd.referenceId + '.pdf');
        formData.append('file', this.fileData);

        return this._httpClient.post<any>(url, formData);
    }

    public addReferenceDocument(): Observable<boolean> {
        const subject = new Subject<boolean>();

        this._validationMessage = '';
        this.getAddDocumentURL()
            .subscribe(
                (response) => {
                    const data = response;

                    if (data.Success == false) {
                        this._validationMessage = 'Error occurred, unable to add the new Reference document: ' + data.ExceptionMessage;
                        subject.next(false);
                    } else {
                        this._validationMessage = 'Reference Document added successfully for Reference Id:' + this._selectedReferenceForAdd.referenceId;
                        subject.next(true);
                    }
                },
                (error) => {
                    this._validationMessage = error.message;
                    subject.next(false);
                });

            return subject.asObservable();
    }

}

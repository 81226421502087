import { Component, Input, OnInit } from '@angular/core';

import { IReferenceSearchResult, SearchService, StateMap } from '../../services/common';
import { PageState } from '../../controls/common';
import { PaginationService } from '../../controls/ng2-pagination/ng2-pagination';

import { Reference } from '../../entities/Reference';
import { ReferenceListItem } from '../../entities/projections/ReferenceListItem';

import DataSource from 'devextreme/data/data_source';
import CustomStore from 'devextreme/data/custom_store';
import { DxDataGridComponent } from 'devextreme-angular';
import * as moment from 'moment';

export interface IReferenceResultsParent {
    selectReference(rli: ReferenceListItem): void;
}

@Component({
    selector: 'reference-results',
    templateUrl: './reference-results.html',
    providers: [PaginationService],
})
export class ReferenceResultsComponent implements OnInit {

    @Input() showJournal: boolean;

    _parentComponent: any;
    _searchResult: IReferenceSearchResult;

    _pageState = new PageState('Reference Results');

    dataSource: DataSource;

    constructor(public _searchService: SearchService, public _stateMap: StateMap) {
    }

    ngOnInit() {
        this.dataSource = new DataSource({
            store: new CustomStore({
                load: (loadOptions: any) => {
                    if (!this._searchResult) {
                        this._pageState.isLoading = false;
                        this._pageState.itemCount = 0;
                        this._pageState.isLoadedWithNoData = true;
                        return Promise.resolve({ data: [], totalCount: 0 });
                    }

                    let sort = { name: 'relevance', isDesc: false };
                    if (loadOptions.sort) {
                        sort = {
                            name: loadOptions.sort[0].selector,
                            isDesc: !!loadOptions.sort[0].desc
                        };
                    }

                    const skip = loadOptions.skip ? loadOptions.skip / loadOptions.take : 0;
                    const params = {
                        searchTerm: this._searchResult.searchTerm,
                        searchType: this._searchResult.searchType,
                        orderByProp: 'relevance',
                        pageNumber: skip,
                        pageSize: loadOptions.take || 20,
                        clientOrderBy: sort.name + (sort.isDesc ? ' desc' : '')
                    };

                    this._pageState.isLoading = true;
                    return this._searchService.fetchReferencesPage(params)
                        .then(data => {
                            this._pageState.isLoading = false;
                            this._pageState.itemCount = data.totalCount;
                            this._pageState.isLoadedWithNoData = data.totalCount == 0;

                            return {
                                data: data.references,
                                totalCount: data.totalCount
                            };
                        });
                }
            })
        });
    }

    onSelectionChanged(evt: any) {
        const material: ReferenceListItem = evt.selectedRowsData[0];
        this.selectReference(material);
    }

    public loading(value: boolean) {
        setTimeout(() => this._pageState.isLoading = value);
    }

    // needed because ReferenceListItem only has the briefReference (not the trimmed briefReference).
    formatBriefReference(br: string) {
        return Reference.formatBriefReference(br, this.isStaff());
    }

    public update(parentComponent: IReferenceResultsParent, searchResult: IReferenceSearchResult) {
        this._parentComponent = parentComponent;
        this._searchResult = searchResult;

        const promise = this.dataSource.reload();

        promise
            .then((data: any) => {
                console.log(data);
            })
            .catch((err: any) => {
                this.dataSource.reload();
            });
    }

    isStaff() {
        return this._stateMap.currentUser.isStaff;
    }

    selectReference(reference: ReferenceListItem) {
        if (this._parentComponent && this._parentComponent.selectReference) {
            this._parentComponent.selectReference(reference);
        }
    }

    hasData() {
        const items = this.dataSource.items();
        return items && items.length > 0;
    }
}

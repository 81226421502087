<div *ngIf="_isLoading" class="row">
    <i class="fa fa-circle-o-notch fa-spin" style="font-size:38px; color: #337ab7;"></i>
    <span class="notice-message">Uploading PDF files...</span>
</div>
<div class="dragndrop-grid-container">

    <!-- Row 1 -->
    <div class="item-dragndrop-buttons">
        <button type="button" class="inline btn btn-prepare-rifm-documents" style="width: 124px;" [disabled]="!canPrepareFiles()" (click)="onPrepareFiles()">Validate Files</button>
        <button *ngIf="canCancel()" type="button" class="btn btn-sm btn-primary" (click)="onCancel()">Cancel</button>
        <span class="page-title bold" style="margin-left: 18px;">{{fileCount}}</span>
    </div>

    <span class="item-dragndrop-message alert-message bold">{{_validationMessage}}</span>

    <!-- Row 2 -->
    <span *ngIf="canDragFiles()" class="bold page-subtitle item-files-label centered">Drag-n-Drop Files</span>
<!--    <span class="bold page-subtitle item-folders-label centered">Drag-n-Drop Folders</span>-->

    <!-- Row 3 -->
    <div *ngIf="canDragFiles()"  class="item-files-image" (dragover)="onDragOver($event)" (dragleave)="onDragLeave($event)" (drop)="onDrop($event)">
        <img class="inline centered-image" src="assets/images/DragNDropFiles.png" />
    </div>

<!--    <div  class="item-folders-image" style="border: 4px solid lightgrey; margin-bottom: 20px; border-radius: 5px;">
        <img class="inline centered-image" src="assets/images/DragNDropFolders.png" />
    </div>-->

    <div *ngIf="_fileValidList != null && _fileValidList.length > 0" class="item-dragndrop-valid-results">
        <div class="inline">
            <span *ngIf="!_readyForMore" class="bold publication-title">Valid Files: Ready to Upload</span>
            <button *ngIf="canUploadAll()" type="button" class="btn btn-sm btn-primary" style="margin-left: 12px;" (click)="onUploadAll()">Upload All</button>
            <button *ngIf="_readyForMore" type="button" class="btn btn-sm btn-primary" style="margin-left: 12px;" (click)="onAddMore()">Add More Documents</button>
        </div>

        <div>
            <table class="table table-striped table-hover table-bordered table-condensed" style="width: auto;">
                <thead>
                <tr>
                    <td class="centered" style="width: 140px;">File Name</td>
                    <td class="centered" style="width: 75px;">Ref Id</td>
                    <td class="centered" style="width: 160px;">Add/Replace</td>
                    <td class="centered" style="width: 340px;">Description</td>
                    <td class="centered" style="width: 240px;">Remove from List</td>
                    <td class="centered" style="width: 240px;">Results</td>
                    <td class="centered" style="width: 180px;">Show</td>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let f of fileValidList; let ix=index">
                    <td class="centered">{{f.fileName}}</td>
                    <td class="centered">{{f.referenceId}}</td>
                    <td class="centered" >
                        <img *ngIf="f.hasRIFMDocumentRecord && f.results.length == 0" class="centered-image" (click)="onReplaceFile(ix)" src="assets/images/ReplacePDF.png" width="24"/>
                        <img *ngIf="!f.hasRIFMDocumentRecord && f.results.length == 0" class="centered-image" (click)="onAddDocument(ix)" src="assets/images/AddPDF.png" width="24"/>
                    </td>
                    <td class="text-left">
                        <span *ngIf="f.hasRIFMDocumentRecord">A record already exists, the file will be replaced.</span>
                    </td>
                    <td class="centered"><a class="inline" style="color: #4B0082;" (click)="onRemoveFileFromList(ix)">Remove</a></td>
                    <td class="centered bold" style="color: maroon;">{{f.results}}</td>
                    <td class="centered"><a *ngIf="f.successAdd || f.successReplace"  class="centered bold" (click)="onNavToRefDoc(ix)">Go to Document</a></td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>

    <!-- Row 4 -->
    <div *ngIf="canDragFiles()" class="item-files-input">
        <form class="centered">
            <input id="fileInputElement" type="file" multiple accept=".pdf" (change)="onFileInput($event.target.files)">
            <label class="button" for="fileInputElement">Or, choose file(s)</label>
        </form>
    </div>
    <!-- Row 5 -->

    <div *ngIf="_fileInvalidList != null && _fileInvalidList.length > 0" class="item-dragndrop-invalid-results">
        <span class="bold publication-title">Invalid Files</span>

        <div>
            <table class="table table-striped table-hover table-bordered table-condensed" style="width: auto;">
                <thead style="background-color: darkgray; color: #B0171F; font-weight: bold;">
                <tr>
                    <td class="centered" style="width: 160px;">File Name</td>
                    <td class="centered" style="width: 320px;">File Error</td>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let i of _fileInvalidList">
                    <td>{{i.fileName}}</td>
                    <td>{{i.errorMsg}}</td>
                </tr>
                </tbody>
            </table>
        </div>

    </div>

</div>


import { RouterModule, Routes } from '@angular/router';

import { AuthGuard, AuthGuardAllowAuthorized, CanDeactivateGuard, IRouteDefinition } from './services/common';
import { MaterialResolver } from './components/material/material.resolver';
import { ReferenceResolver } from './components/reference/reference.resolver';
import { ROUTES } from './components/routes';
import * as _ from 'lodash';

// calculate navPath for each RouteDef path.
function processPaths(routeDefObj: {}) {
    _.forOwn(routeDefObj, (value: IRouteDefinition) => {
        value.navPath = value.path.split('/')[0];
        const childRoutes = value['childRoutes'];
        if (childRoutes) {
            processPaths(childRoutes);
        }
    });
}

processPaths(ROUTES);

const homeConfig: Routes = [
    {
        path: ROUTES.Home.path,
        component: ROUTES.Home.component,
        canActivate: [AuthGuardAllowAuthorized],
        canDeactivate: [CanDeactivateGuard],
        children: [
            { path: '', redirectTo: 'home-page', pathMatch: 'full' },
            { path: ROUTES.Home.childRoutes.AfterLogin.path, component: ROUTES.Home.childRoutes.AfterLogin.component, canDeactivate: [CanDeactivateGuard] },
            { path: ROUTES.Home.childRoutes.HomePage.path, component: ROUTES.Home.childRoutes.HomePage.component, canDeactivate: [CanDeactivateGuard] },
            { path: ROUTES.Home.childRoutes.Introduction.path, component: ROUTES.Home.childRoutes.Introduction.component, canDeactivate: [CanDeactivateGuard], canActivate: [AuthGuard] },
            { path: ROUTES.Home.childRoutes.SearchResults.path, component: ROUTES.Home.childRoutes.SearchResults.component, canDeactivate: [CanDeactivateGuard], canActivate: [AuthGuard] },
            { path: ROUTES.Home.childRoutes._SearchResults.path, component: ROUTES.Home.childRoutes._SearchResults.component, canDeactivate: [CanDeactivateGuard], canActivate: [AuthGuard] },
            { path: ROUTES.Home.childRoutes.SearchAuthors.path, component: ROUTES.Home.childRoutes.SearchAuthors.component, canDeactivate: [CanDeactivateGuard], canActivate: [AuthGuard] },
            { path: ROUTES.Home.childRoutes.SearchSynonyms.path, component: ROUTES.Home.childRoutes.SearchSynonyms.component, canDeactivate: [CanDeactivateGuard], canActivate: [AuthGuard] },
            { path: ROUTES.Home.childRoutes.SearchStructure.path, component: ROUTES.Home.childRoutes.SearchStructure.component, canDeactivate: [CanDeactivateGuard], canActivate: [AuthGuard] },
            { path: ROUTES.Home.childRoutes._SearchStructure.path, component: ROUTES.Home.childRoutes.SearchStructure.component, canDeactivate: [CanDeactivateGuard], canActivate: [AuthGuard] },
            { path: ROUTES.Home.childRoutes.EnvironmentalData.path, component: ROUTES.Home.childRoutes.EnvironmentalData.component, canDeactivate: [CanDeactivateGuard], canActivate: [AuthGuard] },
            { path: ROUTES.Home.childRoutes.MaterialGroups.path, component: ROUTES.Home.childRoutes.MaterialGroups.component, canDeactivate: [CanDeactivateGuard], canActivate: [AuthGuard] },
            { path: ROUTES.Home.childRoutes.SafetyAssessments.path, component: ROUTES.Home.childRoutes.SafetyAssessments.component, canDeactivate: [CanDeactivateGuard], canActivate: [AuthGuard] },
            { path: ROUTES.Home.childRoutes.IFRAAttachments.path, component: ROUTES.Home.childRoutes.IFRAAttachments.component, canDeactivate: [CanDeactivateGuard], canActivate: [AuthGuard] },
            { path: ROUTES.Home.childRoutes.ToolsAndModels.path, component: ROUTES.Home.childRoutes.ToolsAndModels.component, canDeactivate: [CanDeactivateGuard] },
            // tslint:disable-next-line:max-line-length
            { path: ROUTES.Home.childRoutes.ReportDatabaseChanges.path, component: ROUTES.Home.childRoutes.ReportDatabaseChanges.component, canDeactivate: [CanDeactivateGuard], canActivate: [AuthGuard] },
            { path: ROUTES.Home.childRoutes.SystemRequirements.path, component: ROUTES.Home.childRoutes.SystemRequirements.component, canDeactivate: [CanDeactivateGuard], canActivate: [AuthGuard] },
            { path: ROUTES.Home.childRoutes.IFRASurveyUse.path, component: ROUTES.Home.childRoutes.IFRASurveyUse.component, canDeactivate: [CanDeactivateGuard], canActivate: [AuthGuard] },
            // tslint:disable-next-line:max-line-length
            { path: ROUTES.Home.childRoutes.DatabaseSubscription.path, component: ROUTES.Home.childRoutes.DatabaseSubscription.component, canDeactivate: [CanDeactivateGuard], canActivate: [AuthGuard] },
            { path: ROUTES.Home.childRoutes.MaterialClusters.path, component: ROUTES.Home.childRoutes.MaterialClusters.component, canDeactivate: [CanDeactivateGuard], canActivate: [AuthGuard] },
        ]
    }
];

const materialConfig: Routes = [
    {
        path: ROUTES.Material.path,
        component: ROUTES.Material.component,
        resolve: { material: MaterialResolver },
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard],
        children: [
            { path: '', redirectTo: 'synonyms', pathMatch: 'full' },
            { path: ROUTES.Material.childRoutes.Synonyms.path, component: ROUTES.Material.childRoutes.Synonyms.component, canDeactivate: [CanDeactivateGuard] },
            { path: ROUTES.Material.childRoutes.StructureIdentity.path, component: ROUTES.Material.childRoutes.StructureIdentity.component, canDeactivate: [CanDeactivateGuard] },
            { path: ROUTES.Material.childRoutes.Relations.path, component: ROUTES.Material.childRoutes.Relations.component, canDeactivate: [CanDeactivateGuard] },
            { path: ROUTES.Material.childRoutes.AnalyticalResults.path, component: ROUTES.Material.childRoutes.AnalyticalResults.component, canDeactivate: [CanDeactivateGuard] },
            { path: ROUTES.Material.childRoutes.ToxicData.path, component: ROUTES.Material.childRoutes.ToxicData.component, canDeactivate: [CanDeactivateGuard] },
            { path: ROUTES.Material.childRoutes.Flavor.path, component: ROUTES.Material.childRoutes.Flavor.component, canDeactivate: [CanDeactivateGuard] },
            { path: ROUTES.Material.childRoutes.Synopsis.path, component: ROUTES.Material.childRoutes.Synopsis.component, canDeactivate: [CanDeactivateGuard] },
            { path: ROUTES.Material.childRoutes.Status.path, component: ROUTES.Material.childRoutes.Status.component, canDeactivate: [CanDeactivateGuard] },
            { path: ROUTES.Material.childRoutes.FragranceUse.path, component: ROUTES.Material.childRoutes.FragranceUse.component, canDeactivate: [CanDeactivateGuard] },
            { path: ROUTES.Material.childRoutes.Naturals.path, component: ROUTES.Material.childRoutes.Naturals.component, canDeactivate: [CanDeactivateGuard] },
            { path: ROUTES.Material.childRoutes.Predictions.path, component: ROUTES.Material.childRoutes.Predictions.component, canDeactivate: [CanDeactivateGuard] },
            { path: ROUTES.Material.childRoutes.Publications.path, component: ROUTES.Material.childRoutes.Publications.component, canDeactivate: [CanDeactivateGuard] },
            { path: ROUTES.Material.childRoutes.Reach.path, component: ROUTES.Material.childRoutes.Reach.component, canDeactivate: [CanDeactivateGuard] },
            { path: ROUTES.Material.childRoutes.SafetyAssessment.path, component: ROUTES.Material.childRoutes.SafetyAssessment.component, canDeactivate: [CanDeactivateGuard] },
            { path: ROUTES.Material.childRoutes.RIFMStatus.path, component: ROUTES.Material.childRoutes.RIFMStatus.component, canDeactivate: [CanDeactivateGuard] },
            { path: ROUTES.Material.childRoutes.MaterialConsumptions.path, component: ROUTES.Material.childRoutes.MaterialConsumptions.component, canDeactivate: [CanDeactivateGuard] },
            { path: ROUTES.Material.childRoutes.MaterialExposure.path, component: ROUTES.Material.childRoutes.MaterialExposure.component, canDeactivate: [CanDeactivateGuard] }
        ]
    }
];

const referenceConfig: Routes = [
    {
        path: ROUTES.Reference.path,
        component: ROUTES.Reference.component,
        resolve: { reference: ReferenceResolver },
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard],
        children: [
            { path: '', redirectTo: 'reference-details', pathMatch: 'full' },
            { path: ROUTES.Reference.childRoutes.ReferenceDetails.path, component: ROUTES.Reference.childRoutes.ReferenceDetails.component, canDeactivate: [CanDeactivateGuard] },
            { path: ROUTES.Reference.childRoutes.ReferenceData.path, component: ROUTES.Reference.childRoutes.ReferenceData.component, canDeactivate: [CanDeactivateGuard] },
            { path: ROUTES.Reference.childRoutes.ReferenceRelations.path, component: ROUTES.Reference.childRoutes.ReferenceRelations.component, canDeactivate: [CanDeactivateGuard] }
        ]
    }
];

const staffConfig: Routes = [
    {
        path: ROUTES.Staff.path,
        component: ROUTES.Staff.component,
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard],
        children: [
            { path: '', redirectTo: 'material', pathMatch: 'full' },
            { path: ROUTES.Staff.childRoutes.Material.path, component: ROUTES.Staff.childRoutes.Material.component, canDeactivate: [CanDeactivateGuard] },
            { path: ROUTES.Staff.childRoutes.MaterialById.path, component: ROUTES.Staff.childRoutes.MaterialById.component, canDeactivate: [CanDeactivateGuard] },
            { path: ROUTES.Staff.childRoutes.Reference.path, component: ROUTES.Staff.childRoutes.Reference.component, canDeactivate: [CanDeactivateGuard] },
            { path: ROUTES.Staff.childRoutes.ReferenceAudit.path, component: ROUTES.Staff.childRoutes.ReferenceAudit.component, canDeactivate: [CanDeactivateGuard] },
            { path: ROUTES.Staff.childRoutes.Protocol.path, component: ROUTES.Staff.childRoutes.Protocol.component, canDeactivate: [CanDeactivateGuard] },
            { path: ROUTES.Staff.childRoutes.ReferenceEditor.path, component: ROUTES.Staff.childRoutes.ReferenceEditor.component, canDeactivate: [CanDeactivateGuard] },
            { path: ROUTES.Staff.childRoutes.ReferenceAuditById.path, component: ROUTES.Staff.childRoutes.ReferenceAuditById.component, canDeactivate: [CanDeactivateGuard] },
            { path: ROUTES.Staff.childRoutes.ProtocolById.path, component: ROUTES.Staff.childRoutes.ProtocolById.component, canDeactivate: [CanDeactivateGuard] },
            { path: ROUTES.Staff.childRoutes.Journals.path, component: ROUTES.Staff.childRoutes.Journals.component, canDeactivate: [CanDeactivateGuard] },
            { path: ROUTES.Staff.childRoutes.Project.path, component: ROUTES.Staff.childRoutes.Project.component, canDeactivate: [CanDeactivateGuard] },
            { path: ROUTES.Staff.childRoutes.FSAG.path, component: ROUTES.Staff.childRoutes.FSAG.component, canDeactivate: [CanDeactivateGuard] },
            { path: ROUTES.Staff.childRoutes.TypeReferenceRelation.path, component: ROUTES.Staff.childRoutes.TypeReferenceRelation.component, canDeactivate: [CanDeactivateGuard] },
            { path: ROUTES.Staff.childRoutes.WhatsNew.path, component: ROUTES.Staff.childRoutes.WhatsNew.component, canDeactivate: [CanDeactivateGuard] },
            { path: ROUTES.Staff.childRoutes.RefWorkflowStats.path, component: ROUTES.Staff.childRoutes.RefWorkflowStats.component, canDeactivate: [CanDeactivateGuard] },
            { path: ROUTES.Staff.childRoutes.MaterialReportedUses.path, component: ROUTES.Staff.childRoutes.MaterialReportedUses.component, canDeactivate: [CanDeactivateGuard] },
            { path: ROUTES.Staff.childRoutes.SurveyReportedUse.path, component: ROUTES.Staff.childRoutes.SurveyReportedUse.component, canDeactivate: [CanDeactivateGuard] },
            { path: ROUTES.Staff.childRoutes.AssessmentCategories.path, component: ROUTES.Staff.childRoutes.AssessmentCategories.component, canDeactivate: [CanDeactivateGuard] },
            {
                path: ROUTES.Staff.childRoutes.TypePredictionEditorBaseComponent.path,
                component: ROUTES.Staff.childRoutes.TypePredictionEditorBaseComponent.component,
                canDeactivate: [CanDeactivateGuard]
            }
            // ...createChildConfig(ROUTES.Staff.childRoutes)
        ]
    }
];

const exposureConfig: Routes = [
    {
        path: ROUTES.Exposure.path,
        component: ROUTES.Exposure.component,
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard],
        children: [
            { path: '', redirectTo: 'exposure-surveys', pathMatch: 'full' },
            { path: ROUTES.Exposure.childRoutes.ExposureSurveys.path, component: ROUTES.Exposure.childRoutes.ExposureSurveys.component, canDeactivate: [CanDeactivateGuard] },
            { path: ROUTES.Exposure.childRoutes.ExposureSurveyLists.path, component: ROUTES.Exposure.childRoutes.ExposureSurveyLists.component, canDeactivate: [CanDeactivateGuard] },
            {
                path: ROUTES.Exposure.childRoutes.RecordExposureSurveyMeasuresComponent.path,
                component: ROUTES.Exposure.childRoutes.RecordExposureSurveyMeasuresComponent.component,
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: ROUTES.Exposure.childRoutes.ExposureAdditionalMaterialsComponent.path,
                component: ROUTES.Exposure.childRoutes.ExposureAdditionalMaterialsComponent.component,
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: ROUTES.Exposure.childRoutes.ExposureSurveyReportsComponent.path,
                component: ROUTES.Exposure.childRoutes.ExposureSurveyReportsComponent.component,
                canDeactivate: [CanDeactivateGuard]
            }
            // ...createChildConfig(ROUTES.Exposure.childRoutes)
        ]
    }
];

const documentManagementConfig: Routes = [
    {
        path: ROUTES.DocumentManagement.path,
        component: ROUTES.DocumentManagement.component,
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard],
        children: [
            { path: '', redirectTo: 'rifm-documents', pathMatch: 'full' },
            { path: ROUTES.DocumentManagement.childRoutes.RIFMDocuments.path, component: ROUTES.DocumentManagement.childRoutes.RIFMDocuments.component, canDeactivate: [CanDeactivateGuard] },
            // tslint:disable-next-line:max-line-length
            { path: ROUTES.DocumentManagement.childRoutes.QuickDocumentUpload.path, component: ROUTES.DocumentManagement.childRoutes.QuickDocumentUpload.component, canDeactivate: [CanDeactivateGuard] },
            // tslint:disable-next-line:max-line-length
            { path: ROUTES.DocumentManagement.childRoutes.RIFMArchivedDocuments.path, component: ROUTES.DocumentManagement.childRoutes.RIFMArchivedDocuments.component, canDeactivate: [CanDeactivateGuard] },
            // tslint:disable-next-line:max-line-length
            { path: ROUTES.DocumentManagement.childRoutes.DocumentManagementReports.path, component: ROUTES.DocumentManagement.childRoutes.DocumentManagementReports.component, canDeactivate: [CanDeactivateGuard] }
        ]
    }
];

export const routes: Routes = [
    {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full'
    },
    ...homeConfig,
    ...materialConfig,
    ...referenceConfig,
    ...staffConfig,
    ...exposureConfig,
    ...documentManagementConfig,
    {
        path: '**',
        redirectTo: 'home'
    },
];

// export const appRouterProviders = [
//   provideRouter(routesConfig), AuthGuard, CanDeactivateGuard
// ];

export const appRouterProviders = [
    AuthGuard, AuthGuardAllowAuthorized, CanDeactivateGuard
];

export const routing = RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' });

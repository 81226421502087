import {AfterViewInit, Component, OnInit} from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';

import * as _ from 'lodash';

import { UtilFns } from '../../utils/common';
import { PageState } from '../../controls/common';

import { ROUTES } from '../routes';
import { MATERIAL_ROUTES } from './material.routes';

import { ExposureSurvey, Material, MaterialExposureSurvey, MaterialExposureSurveyIsomer } from '../../entities/EntityModels';
import { MaterialExposureSurveyMeasureItem } from '../../entities/projections/MaterialExposureSurveyMeasureItem';
import { MeasureSurveyCategoryGroup } from '../../entities/projections/MeasureSurveyCategoryGroup';
import { UnitOfWork } from '../../services/unit-of-work';
import { UserManager } from '../../services/user-manager';
import { StateMap } from '../../services/state-map';
import { MeasuresByCategory } from '../../entities/projections/MeasuresByCategory';

const compareMeasures = function (a: MaterialExposureSurveyMeasureItem, b: MaterialExposureSurveyMeasureItem) {
    if (a.sortOrder < b.sortOrder) {
        return -1;
    }
    if (a.sortOrder > b.sortOrder) {
        return 1;
    }
    // reverse sort order for created date
    if (a.created > b.created) {
        return -1;
    }
    if (a.created < b.created) {
        return 1;
    }
    return 0;
};

const compareSurveys = function (a: MeasureSurveyCategoryGroup, b: MeasureSurveyCategoryGroup) {
    if (a.exposureSurveyDate > b.exposureSurveyDate) {
        return -1;
    }
    if (a.exposureSurveyDate < b.exposureSurveyDate) {
        return 1;
    }
    if (a.exposureSurveyNumber > b.exposureSurveyNumber) {
        return -1;
    }
    if (a.exposureSurveyNumber < b.exposureSurveyNumber) {
        return 1;
    }
    return 0;
};

export interface  MeasureSurveyGroup { id: string; measures: MaterialExposureSurveyMeasureItem[]; collapsed?: boolean; }

@Component({
    selector: 'material-exposure',
    templateUrl: './material-exposure.html',
})
export class MaterialExposureComponent implements OnInit, AfterViewInit {

    _monthFullNames = [
        'January', 'February', 'March',
        'April', 'May', 'June', 'July',
        'August', 'September', 'October',
        'November', 'December'
    ];

    _material: Material;
    _materialExposureMeasures: MaterialExposureSurveyMeasureItem[] = [];
    _materialExposureSurveys: MaterialExposureSurvey[] = [];
    _measureSurveyGroups: MeasureSurveyGroup[] = [];
    _measureSurveyCategoryGroups: MeasureSurveyCategoryGroup[] = [];

    _exposureSurveys: ExposureSurvey[] = [];

    _isomerOfMaterialExposureSurveys: MaterialExposureSurveyIsomer[] = [];
    _pageState = new PageState('Exposure');

    constructor(public _uow: UnitOfWork, public _userManager: UserManager, public _stateMap: StateMap,
                public _location: Location, public _router: Router) {

    }

    ngOnInit() {
        this._stateMap.currentRouteName = MATERIAL_ROUTES.MaterialExposure.name;
        this._material = this._stateMap.currentMaterial;

        if (this._material == null) {
            return;
        }

        this._pageState.isLoading = true;

        const params = {materialId: this._material.materialId};
        this._uow.fetch('ExposureSurveys/MaterialExposureSurveyByMaterialId', params).then(m => {
            this._materialExposureSurveys = m;

            if (this._materialExposureSurveys == null || this._materialExposureSurveys.length < 1) {
                this._pageState.isLoaded = true;
                return;
            }

            // ************************************************************************************************************************
            // Change: 07/24/2020: Show Exp. Survey data for a material even if they only have notes and no measure data
            // Create objects for each survey the material is registered for and fill in measure and isomer data if available.
            // ************************************************************************************************************************
            this._materialExposureSurveys.forEach(e => {
                // tslint:disable-next-line:max-line-length
                this._measureSurveyCategoryGroups.push(new MeasureSurveyCategoryGroup(e.exposureSurveyId.toString(), [], null, e.notes, e.exposureSurvey.exposureSurveyDate, e.exposureSurvey.exposureSurveyNumber, '#AAAAAA', true));
            });

            // Fetch all measure data for the material across surveys. Group the data by survey and display category, then update the _measureSurveyCategoryGroups object
            this._uow.fetch('ExposureSurveys/FetchExposureSurveyMaterialMeasureItems', params).then(e => {
                if (e != null && e.length > 0) {
                    this._materialExposureMeasures = e;

                    // First group measures by Exposure Survey, then within each survey, group measure values by category.
                    const groups = _.groupBy(this._materialExposureMeasures, item => item.exposureSurveyId);
                    this._measureSurveyGroups = _.map(groups, (v, k) => {
                        return {id: k, measures: v, isCollapsed: false};
                    });

                    (async () => {
                        this._measureSurveyCategoryGroups = await this.forEachMeasureCategoryAsync();
                    })();
                }
                this._pageState.isLoaded = true;
            });


        });

        this.fetchMaterialAsIsomerSurveys();
    }

    ngAfterViewInit() {
        this.expandAll(false);
    }

    async forEachMeasureCategoryAsync(): Promise<MeasureSurveyCategoryGroup[]> {

        for (let idx = 0; idx < this._measureSurveyGroups.length; idx++) {
            const msg = this._measureSurveyGroups[idx];

            // Group measures by their display category
            const cg = _.groupBy(msg.measures, d => d.displayCategory);
            const categorygroups: MeasuresByCategory[] = _.map(cg, (v, k) => {
                return {categoryId: k, measureValues: v};
            });

            // Then sort each measure within each display category
            const sortedcategorygroups: MeasuresByCategory[] = [];
            _.clone(categorygroups).forEach(v => {
                const sortedmeasurevalues: MaterialExposureSurveyMeasureItem[] = v.measureValues.sort(compareMeasures);
                sortedcategorygroups.push(new MeasuresByCategory(v.categoryId, sortedmeasurevalues));
            });

            // Fetch any material isomers that were surveyed along with this material
            const i = await (this.fetchIsomers(this._material.materialId, Number(msg.id))); // msg.id = exposureSurveyId.ToString()

            // filter to the material exposure survey and update the measures and isomers fields
            const measureSurveyCategoryGroup = this.filterToMeasureSurveyCategoryGroup(msg.id);

            if (measureSurveyCategoryGroup != null) {
                measureSurveyCategoryGroup.measuresByCategory = sortedcategorygroups;
                measureSurveyCategoryGroup.isomers = i;
                measureSurveyCategoryGroup.backgroundColor = '#4CAF50';
            }
        }

        return (this._measureSurveyCategoryGroups.sort(compareSurveys));
    }

    // display isomer materials included in the survey.
    async fetchIsomers(materialId: number, exposureSurveyId: number): Promise<string> {
        let isomers = '';
        const params = {materialId: materialId, exposureSurveyId: exposureSurveyId};
        return this._uow.fetch('ExposureSurveys/MaterialExposureSurveyIsomerByMaterialAndSurvey', params).then(i => {
            if (i != null && i.length > 0) {
                isomers = i.map((m: { material: Material; }) => {
                    return this.formatIsomerIdentifiers(m.material);
                }).join('; ');
            }
            return isomers;
        });
    }

    formatIsomerIdentifiers(material: Material): string {
        const rifmId = (material.formattedRIFMId == '-') ? '' : ' (RIFM Id: ' + material.formattedRIFMId + ')';
        return material.realCASNumber + rifmId;
    }

    fetchMaterialAsIsomerSurveys() {
        this._isomerOfMaterialExposureSurveys = [];
        const params = {materialId: this._material.materialId};
        return this._uow.fetch('ExposureSurveys/MaterialExposureSurveyIsomersByMaterialId', params).then(i => {
            this._isomerOfMaterialExposureSurveys = i;
        });

    }

    hasData(): boolean {
        return (this._measureSurveyCategoryGroups !== null && this._measureSurveyCategoryGroups.length > 0);
    }

    private surveyGroupCollapse(ix: number) {
        this._measureSurveyGroups[ix].collapsed = !this._measureSurveyGroups[ix].collapsed;
    }

    private surveyGroupCollapseNew(ix: number) {
        this._measureSurveyCategoryGroups[ix].collapsed = !this._measureSurveyCategoryGroups[ix].collapsed;
    }

    public expandAll(expand: boolean) {
        setTimeout(() => {
            this._measureSurveyCategoryGroups.forEach(g => g.collapsed = !expand);
        }, 0);
    }

    public surveyDescription(grp: MeasureSurveyCategoryGroup) {

        if (grp == null) {
            return '';
        }

        const   month       = this._monthFullNames[grp.exposureSurveyDate.getUTCMonth()];
        const   year        = grp.exposureSurveyDate.getUTCFullYear().toString();
        let     msg         = '';

        if (grp.measuresByCategory == null || grp.measuresByCategory.length < 1) {
            msg = ' (The material was surveyed but no measure data has been entered)';
        }

        return month + ', ' + year + '      Number: ' + grp.exposureSurveyNumber + msg;

    }

    private filterToExposureSurveyById(exposureSurveyId: number): ExposureSurvey {
        if (this._exposureSurveys == null || this._exposureSurveys.length < 1) {
            return null;
        }

        const expsurvey = this._exposureSurveys.filter(e => e.exposureSurveyId == exposureSurveyId);
        if (expsurvey != null && expsurvey.length > 0) {
            return expsurvey[0];
        }
    }

    get utilFns() {
        return UtilFns;
    }

    onShowMaterial(isomer: MaterialExposureSurveyIsomer) {
        if (isomer == null || isomer.materialExposureSurvey == null || isomer.materialExposureSurvey.material == null) {
            return;
        }

        this._router.navigate(UtilFns.asRouterParts(ROUTES.Material, isomer.materialExposureSurvey.material.materialId));
        // this._router.navigate(UtilFns.asRouterParts(ROUTES.Material, isomer.materialExposureSurvey.material.materialId, ROUTES.Material.childRoutes.MaterialExposure));
    }

    // Filters
    filterToMeasureSurveyCategoryGroup(id: string): any {
        if (this._measureSurveyCategoryGroups == null || this._measureSurveyCategoryGroups.length < 1) {
            return (null);
        }

        const mscg = this._measureSurveyCategoryGroups.filter(c => c.id == id);
        return (mscg != null && mscg.length > 0) ? mscg[0] : null;
    }
}

import { Component, ElementRef, OnInit } from '@angular/core';

import { SearchService, StateMap } from '../../services/common';
import { UtilFns } from '../../utils/common';
import { ModalDialog, PageState } from '../../controls/common';

import { Reference } from '../../entities/EntityModels';
import { ReferenceListItem } from '../../entities/projections/ReferenceListItem';

@Component({
    selector: 'reference-selector',
    templateUrl: './reference-selector.html',
})
export class ReferenceSelectorComponent extends ModalDialog<ReferenceListItem> implements OnInit {

    _searchTerm: string;
    _entities: ReferenceListItem[] = [];
    _pageState = new PageState('References');

    constructor(elementRef: ElementRef, public _searchService: SearchService, public _stateMap: StateMap) {
        super(elementRef);
    }

    ngOnInit() {
    }

    search() {
        this.validationMessage = null;
        const params = {
            searchTerm: this._searchTerm,
            searchType: '',
            pageNumber: 0,
            pageSize: 200
        };

        this._pageState.isLoading = true;
        this._entities = null;
        return this._searchService.fetchReferencesPage(params).then(rsr => {
            this._pageState.isLoaded = rsr.references;
            this._entities = rsr.references;
        }).catch(e => {
            this._pageState.isLoaded = false;
        });
    }

    onSelect(rli: ReferenceListItem) {
        UtilFns.returnModal(this, rli);
    }

    // needed because ReferenceListItem only has the briefReference (not the trimmed briefReference).
    formatBriefReference(br: string) {
        return Reference.formatBriefReference(br, this.isStaff());
    }

    isStaff() {
        return this._stateMap.currentUser.isStaff;
    }


}


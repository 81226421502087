import { EntityBase } from './EntityBase';

import { Validator } from 'breeze-client';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

export class TypeWorkflowActionTransition extends EntityBase {

   /// <code> Place custom code between <code> tags
   
   /// </code>

   // Generated code. Do not place code below this line.
   typeWorkflowActionTransitionId: number;
   typeWorkflowActionId: string;
   entityName: string;
   propertyName: string;
   entityState: string;
   completedWorkflowActionId: string;
   markComplete: boolean;
   doNotComplete: boolean;
   rowVersion: number;
}


import { Component, Input, OnInit } from '@angular/core';
import { Entity } from 'breeze-client';

import { EditManager } from '../utils/edit-manager';

@Component({
    selector: 'editor-header',
    templateUrl: './editor-header.html',
})
export class EditorHeaderComponent<T extends Entity> implements OnInit  {
    @Input() edm: EditManager<T>;
    @Input() customButtons: boolean;
    @Input() femaRestricted: boolean;

    ngOnInit() {
        if (this.edm != null) {
            this.edm.femaRestricted = (this.femaRestricted != null) ? this.femaRestricted : false;
        }
    }
}


import { Component, OnInit, ViewChild} from '@angular/core';
import * as _ from 'lodash';
import { UnitOfWork, StateMap, SearchService } from '../../services/common';
import { UtilFns } from '../../utils/common';
import { PageState } from '../../controls/common';

import { UserChangeReport, TypeChangeReportFrequency, TypeChangeReportTopic, Author, TypeStudy} from  '../../entities/EntityModels';
import { MaterialListItem} from '../../entities/projections/MaterialListItem';
import { MaterialSelectorComponent} from '../material/material-selector.component';
import { AuthorSelectorComponent} from '../reference/author-selector.component';


@Component({
    templateUrl: './report-database-changes.html'
})
export class ReportDatabaseChangesComponent implements OnInit {
    @ViewChild(MaterialSelectorComponent, { static: true }) _materialSelectorComponent: MaterialSelectorComponent;
    @ViewChild(AuthorSelectorComponent, { static: true }) _authorSelectorComponent: AuthorSelectorComponent;

    _userChangeReport: UserChangeReport;
    _typeChangeReportFrequencies: TypeChangeReportFrequency[];
    _typeChangeReportTopics: TypeChangeReportTopic[];
    _typeStudies: TypeStudy[];
    _filterStudyType: string;
    _selectedStudyTypes: TypeStudy[] = [];
    _studyFilterMode: string = "All";

    _studyTypeMap: { [key: string]: boolean } = {};
    _topicMap: { [key: string]: boolean } = {};
    _materialListItems: MaterialListItem[];
    _authors: Author[];

    _hasPendingEdits: boolean = false;
    _pageState = new PageState('Report of Database Changes');
    _status = {};
    _errorMessage: string;

    constructor(public _uow: UnitOfWork, public _stateMap: StateMap, public _searchService: SearchService) {

    }

    ngOnInit() {

        this._uow.typeChangeReportFrequencyRespository.all().then(r => {
            this._typeChangeReportFrequencies = r;
        });

        this._uow.typeStudyRepository.all().then(r => {
            this._typeStudies = _.sortBy(r, t => t.studyLong);
        });

        this._uow.typeChangeReportTopicRepository.all().then(r => {
            this._typeChangeReportTopics = _.sortBy(r, t => t.topicDisplayOrder);
            this.fetch();
        });
    }


    fetch() {
        this._userChangeReport = null;
        this._materialListItems = null;
        this._authors = null;
        this._typeChangeReportTopics.forEach(topic => this._topicMap[topic.typeChangeReportTopicId] = false);

        var params = { userId: this._stateMap.currentUser.name }
        return this._uow.userChangeReportRepository.whereWithParams(params).then(r => {
            if (r.length == 0) {
                this._materialListItems = [];
                this._authors = [];
                return;
            }
            this._userChangeReport = r[0];

            this._userChangeReport.userChangeReportTopics.forEach(t => {
                this._topicMap[t.typeChangeReportTopicId] = true;
            });
            var materialIds = this._userChangeReport.userChangeReportMaterials.map(m => m.materialId);
            // return this._searchService.fetchMaterialListItems(materialIds);
            this._uow.fetch("Materials/MaterialListItems", { materialIds: materialIds }).then(r => {
                this._materialListItems = <MaterialListItem[]><any>r;
                if (this._materialListItems != null && this._materialListItems.length > 0) {
                    this._materialListItems = _.sortBy(this._materialListItems, m => m.synonymWordOrWebVersion);
                }
            })

            this._typeStudies.forEach(s => this._studyTypeMap[s.typeStudyId] = false);
            this._selectedStudyTypes = [];
            this._userChangeReport.userChangeReportStudies.forEach(s => {
                this._studyTypeMap[s.typeStudyId] = true;
                this._selectedStudyTypes.push(s.typeStudy);
            });
            if (this._selectedStudyTypes.length > 0) {
                this._studyFilterMode = "Specific";
                this._selectedStudyTypes = _.sortBy(this._selectedStudyTypes, s => s.studyLong);
            }

            var authorIds = this._userChangeReport.userChangeReportAuthors.map(a => a.authorId);
            return this._uow.fetchTyped("Misc/AuthorsByIds", Author, { authorIds: authorIds }).then(r => {
                this._authors = r;
                if (this._authors != null && this._authors.length > 0) {
                    this._authors = _.sortBy(this._authors, a => a.authorName);
                }
            });

        })
    }

    dataReady() {
        return this._materialListItems != null && this._authors != null;
    }

    createSettings() {
        var params = {
            userId: this._stateMap.currentUser.name,
            staffReport: this._stateMap.currentUser.isStaff,
            typeChangeReportFrequencyId: "Weekly",
            enabled: true
        }
        this._userChangeReport = this._uow.userChangeReportFactory.create(params);
        return this._uow.commit().then(() => {
            this.fetch();
        }).catch(e => {

        })
    }

    canDeactivate() {
        return !this.hasEdits();
    }

    hasEdits() {
        return this._uow.hasChanges() || this._hasPendingEdits;
    }

    canSave() {
        return this.hasEdits();
    }

    canCancel() {
        return this.hasEdits();
    }

    onSave() {
        // add/remove topics
        var topics = this._userChangeReport.userChangeReportTopics;
        // clone is so that we don't change the navProp while iterating over it.
        _.clone(topics).forEach(t => {
            if (this._topicMap[t.typeChangeReportTopicId] == false) {
                t.entityAspect.setDeleted();
            }
        });

        _.forEach(this._topicMap, (v, k) => {
            if (v && !_.find(topics, t => t.typeChangeReportTopicId == k)) {
                var params = {
                    userChangeReportId: this._userChangeReport.userChangeReportId,
                    typeChangeReportTopicId: k,
                    enabled: true
                }
                var userChangeReportTopic = this._uow.userChangeReportTopicFactory.create(params);
                topics.push(userChangeReportTopic);
            }
        });

        // add/remove materials
        var reportMaterials = this._userChangeReport.userChangeReportMaterials;
        // clone is so that we don't change the navProp while iterating over it.
        _.clone(reportMaterials).forEach(reportMaterial => {
            if (!this._materialListItems.find(m => m.materialId == reportMaterial.materialId)) {
                reportMaterial.entityAspect.setDeleted();
            }
        });

        _.forEach(this._materialListItems, mli => {
            if (!_.find(reportMaterials, rm => rm.materialId == mli.materialId)) {
                var params = {
                    userChangeReportId: this._userChangeReport.userChangeReportId,
                    materialId: mli.materialId
                }
                var userChangeReportMaterial = this._uow.userChangeReportMaterialFactory.create(params);
                reportMaterials.push(userChangeReportMaterial);
            }
        })

        // add/remove authors
        var reportAuthors = this._userChangeReport.userChangeReportAuthors;
        this._userChangeReport.referenceAuthors = reportAuthors.length > 0;
        // clone is so that we don't change the navProp while iterating over it.
        _.clone(reportAuthors).forEach(reportAuthor => {
            if (!this._authors.find(a => a.authorId == reportAuthor.authorId)) {
                reportAuthor.entityAspect.setDeleted();
            }
        });

        _.forEach(this._authors, author => {
            if (!_.find(reportAuthors, ra => ra.authorId == author.authorId)) {
                var params = {
                    userChangeReportId: this._userChangeReport.userChangeReportId,
                    authorId: author.authorId
                }
                var userChangeReportAuthor = this._uow.userChangeReportAuthorFactory.create(params);
                reportAuthors.push(userChangeReportAuthor);
            }
        })

        //add/remove study types
        var studies = this._userChangeReport.userChangeReportStudies;
        this._selectedStudyTypes.forEach(s => {
            if (!_.find(studies, r => r.typeStudyId == s.typeStudyId)) {
                var params = {
                    userChangeReportId: this._userChangeReport.userChangeReportId,
                    typeStudyId: s.typeStudyId,
                    enabled: true
                }
                var userChangeReportStudy = this._uow.userChangeReportStudyFactory.create(params);
                studies.push(userChangeReportStudy);
            }
        });

        //The default is All study types when either the health or environmental study topic is selected.
        //Only store specific study types if the user selects them.
        if (this._studyFilterMode == "All" || this.hasSelectedStudyTopic() == false) {
            _.clone(studies).forEach(s => {
                s.entityAspect.setDeleted();
            });
            this._typeStudies.forEach(s => this._studyTypeMap[s.typeStudyId] = false);
            this._selectedStudyTypes = [];
        }
        else {
            _.clone(studies).forEach(s => {
                if (this._studyTypeMap[s.typeStudyId] == false) {
                    s.entityAspect.setDeleted();
                }
            });
        }

        // and then save
        return this._uow.commit().then(() => {
            this.setStatus({ message: 'Saved', classes: "label-success", isTemp: true });
            this._hasPendingEdits = false;
        }).catch(e => {
            this.setStatus({ message: 'Save failed: ' + e.toString(), classes: "label-danger", isTemp: false });
        })

    }

    onCancel() {
        this._uow.rollback();
        this._hasPendingEdits = false;
        this.fetch();
    }

    onTopicChanged() {
        this._hasPendingEdits = true;
    }

    onSelectingMaterial() {
        UtilFns.showModal(this._materialSelectorComponent, this, this.validateMaterial).then(mli => {
            if (mli == null) return;
            this._hasPendingEdits = true;
            this._materialListItems.push(mli);
            this._materialListItems = _.sortBy(this._materialListItems, m => m.synonymWordOrWebVersion);
        });
    }

    validateMaterial(mli: MaterialListItem): string {
        if (this._materialListItems.some(m => m.materialId == mli.materialId)) {
            return `Material: ${mli.realCASNumber} has already been referenced`;
        }
    }

    removeMaterial(mli: MaterialListItem) {
        _.remove(this._materialListItems, mli);
        this._hasPendingEdits = true;
    }

    onSelectingAuthor() {
        UtilFns.showModal(this._authorSelectorComponent, this, this.validateAuthor).then(author => {
            if (!author) return;
            this._hasPendingEdits = true;
            this._authors.push(author);
            this._authors = _.sortBy(this._authors, a => a.authorName);
        })
    }

    onRemoveStudyType(id: string) {
        this._selectedStudyTypes = this._selectedStudyTypes.filter(function (obj) {
            return obj.typeStudyId !== id;
        });

        this._studyTypeMap[id] = false;
        this._hasPendingEdits = true;
    }

    get filterStudyType() {
        return this._filterStudyType;
    }

    set filterStudyType(id: string) {
        if (this._studyTypeMap[id] != true) {
            this._studyTypeMap[id] = true;
            this._hasPendingEdits = true;
            this._selectedStudyTypes.push(_.find(this._typeStudies, s => s.typeStudyId == id));
            this._selectedStudyTypes = _.sortBy(this._selectedStudyTypes, s => s.studyLong);
        }
    }

    get topicsWithoutStudies() {
        if (this._typeChangeReportTopics != null) {
            return this._typeChangeReportTopics.filter(t => t.changeReportTopicDesc.indexOf("Studies") < 0);
        }
    }

    get topicsOnlyStudies() {
        if (this._typeChangeReportTopics != null) {
            return this._typeChangeReportTopics.filter(t => t.changeReportTopicDesc.indexOf("Studies") > -1);
        }
    }

    //Set style based on selected status
    isStudyTypeSelected(obj: TypeStudy): boolean {
        return this._studyTypeMap[obj.typeStudyId];
    }

    isStudyTypeUnselected(obj: any): boolean {
        return (this._studyTypeMap[obj.typeStudyId] == false);
    }

    hasSelectedStudyTopic() {
        var studytopics = this._typeChangeReportTopics.filter(t => t.changeReportTopicDesc.indexOf("Studies") > -1);
        if (studytopics == null) {
            return false;
        }

        var result = false;
        studytopics.forEach(st => {
            if (this._topicMap[st.typeChangeReportTopicId] == true) {
                result = true;
            }
        });

        return result;
    }

    get showStudyTypeFilter() {
        return (this.hasSelectedStudyTopic() && this._studyFilterMode == "Specific");
    }

    setStudyFilterMode(mode: string) {
        this._studyFilterMode = mode;
        this._hasPendingEdits = true;
    }

    //Set style based on selection status
    isNotActiveStudyFilter(mode: string): boolean {
        return (this._studyFilterMode != mode);
    }

    isActiveStudyFilter(mode: string): boolean {
        return (this._studyFilterMode == mode);
    }

    validateAuthor(author: Author): string {
        if (this._authors.some(a => a.authorId == author.authorId)) {
            return `Author: ${author.authorName} has already been referenced`;
        }
    }

    removeAuthor(author: Author) {
        _.remove(this._authors, author);
        this._hasPendingEdits = true;
    }

    protected setStatus(status: { message: string, classes: string, isTemp: boolean }) {
        status.classes = "label " + status.classes;
        this._status = status;
        if (status.isTemp) {
            setTimeout(() => { this._status = null }, 2000)
        }
    }
}

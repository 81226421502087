import { Location } from '@angular/common';
import { Component, Input, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import {PageState, TabContainer} from '../../controls/common';
import { UnitOfWork, StateMap, UserManager, ErrorLogger, EditorService } from '../../services/common';
import { EditPropParent } from '../../controls/edit-prop.component';
import { UtilFns, IEditHost, EditManager, LocationFns } from '../../utils/common';

import { JournalSelectorComponent } from './journal-selector.component';

import {Journal, JournalReserve, JournalSubscription, Reference, TypeJournalSubscription} from '../../entities/EntityModels';
import { JournalEditorComponent } from './journal-editor.component';
import { JournalReserveEditorComponent } from './journal-reserve-editor.component';
import { JournalSubscriptionEditorComponent } from './journal-subscription-editor.component';

import { RelatedJournalNameItem } from '../../entities/projections/RelatedJournalNameItem';
import { JournalSubscriptionItem } from '../../entities/projections/JournalSubscriptionItem';
import { SelectableEntity } from '../../entities/projections/SelectableEntity';
import * as _ from 'lodash';

import { ROUTES } from '../routes';
import { STAFF_ROUTES } from './staff.routes';
import {Entity} from 'breeze-client';
import * as pluralize from 'pluralize';

export enum JournalEditState {
    None,
    Journal = 1,
    Reserve,
    Name,
    Subscription,
    Reassign
}

export class JournalEditData {
    journalEditState: JournalEditState = JournalEditState.None;
    inSubEditor: boolean;
    adding: boolean;
    deleting: boolean;
    entity: Journal;
    journalId: number;
    journalReserveId: number;
    journalSubscriptionId: number;
    selectedReferenceIds: number[] = [];
}

@Component({
    selector: 'journals',
    templateUrl: './journals.html',
})

export class JournalsComponent implements OnInit, AfterViewInit {
    @ViewChild(JournalEditorComponent) _journalEditComponent: JournalEditorComponent;
    @ViewChild(JournalSelectorComponent, { static: true }) _journalSelectorComponent: JournalSelectorComponent;
    @ViewChild(TabContainer) _tabContainer: TabContainer;

    JournalEditState = JournalEditState;

    _isLoading: boolean;
    _userMessage: string;

    _data: JournalEditData = new JournalEditData();

    _referencesEx: SelectableEntity<Reference>[] = [];
    _relatedJournals: RelatedJournalNameItem[];
    _reserves: JournalReserve[];
    _subscriptions: JournalSubscription[];

    _hasActiveSubscription = false;

    _sub: any;
    _tab = 2;
    _validationMessage: string;

    _monthFullNames = [
        'January', 'February', 'March',
        'April', 'May', 'June', 'July',
        'August', 'September', 'October',
        'November', 'December'
    ];

    constructor(private _uow: UnitOfWork, private _stateMap: StateMap, private _userManager: UserManager, private _router: Router,
                public _route: ActivatedRoute, public _location: Location, public _errorLogger: ErrorLogger) {

    }

    ngOnInit() {

        this._stateMap.currentRouteName = STAFF_ROUTES.Journals.name;

        this._data.inSubEditor = false;

        const tabnum = (this._stateMap.getJournalDataFor('tabNumber'));
        this._tab = (tabnum == undefined) ? 2 : tabnum;

        const id = this._stateMap.staffJournalId;
        if (id != null) {
            return this.fetchJournal((id)).then(r => {
                this.setTab(this._tab);
            });
        }
    }

    ngAfterViewInit() {
    }

    canDeactivate() {
        return !this._data.inSubEditor && !this._uow.hasChanges();
    }

    get isLoading() {
        return this._isLoading;
    }

    set isLoading(value) {
        this._isLoading = value;
    }

    isStaff() {
        return this._stateMap.currentUser.isStaff;
    }

    get journal() {
        return this._data.entity;
    }

    get utilFns() {
        return UtilFns;
    }

    onSearchForJournal() {
        this._journalSelectorComponent.setShowJournalData(false);
        UtilFns.showModal(this._journalSelectorComponent, this, null).then((j: Journal) => {
            if (!j) {
                return;
            }

            this._tab = 2;
            return this.fetchJournal((j.journalId)).then(r => {
                this.setTab(this._tab);
            });
        });
    }

    fetchJournal(journalId: number) {
        this._data.entity = null;
        this._referencesEx = [];

        this.isLoading = true;

        return this._uow.fetchTyped('Misc/JournalById', Journal, { journalId: journalId }).then(r => {
            const j = r && (r.length > 0) && r[0];
            if (j) {
                this._data.journalId            = j.journalId;
                this._data.entity               = j;
                this._stateMap.staffJournalId   = j.journalId;

                this.prepareData();
            } else {
                this.onReset();
            }
            this.isLoading = false;
        }).catch(() => {
            this._validationMessage =  'Unexpected error occurred. Please contact support.';
            this.isLoading = false;
        });
    }

    prepareData() {
        this.onReset();

        this.fetchJournalsRelatedByName();

        this.formatReferenceSelectableEntities();

        // reserves
        if (this._data.entity.journalReserves != null && this._data.entity.journalReserves.length > 0) {
            this._reserves = _.orderBy(this._data.entity.journalReserves, ['journalName', 'journalYear', 'volume'], ['asc', 'desc', 'desc']);
        }

        // subscriptions
        if (this._data.entity.journalSubscriptions != null && this._data.entity.journalSubscriptions.length > 0) {
            this._subscriptions = _.orderBy(this._data.entity.journalSubscriptions, ['subscriptionStartYear', 'subscriptionStartMonth'], ['desc', 'desc']);

            try {
                const activeSubs = this._subscriptions.filter(s => s.subscriptionEndYear === null || s.subscriptionEndYear === undefined);
                this._hasActiveSubscription = (activeSubs != null && activeSubs.length > 0);
            } catch (error) {
                alert(error);
            }

        }
    }

    fetchJournalsRelatedByName() {
        this.onReset();

        const params = {
            journalId: this._data.entity.journalId,
            rootJournalId: this._data.entity.rootJournalId,
            journalName: UtilFns.encodeHackForAnd(this._data.entity.journalName)
        };

        return this._uow.fetch('Misc/FetchJournalsRelatedByName', params).then(r => {
            if (r != null && r.length > 0) {
                this._relatedJournals = r;
            }
        });
    }

    refreshData(event: string) {
        const data = this._data;

        switch (data.journalEditState) {
            case JournalEditState.Reserve: {
                this._tab = 1;
                break;
            }
            case JournalEditState.Reassign: {
                this._tab = 0;
                this._userMessage = (event == 'Save') ? 'References reassigned successfully.' : '';
                break;
            }
            case JournalEditState.Subscription: {
                this._tab = 2;
                break;
            }
            default: {
                this._tab = 2;
                break;
            }
        }

        if (event == 'Save' || (event == 'Cancel' && !this._data.adding)) {
            if (this._data.entity != null) {
                this.fetchJournal((this._data.entity.journalId)).then (r => {
                    this.setTab(this._tab);
                });
            }
        } else if ((event == 'Cancel' && this._data.adding && this._data.journalEditState == JournalEditState.Journal) || (event == 'Delete')) {

            this.onReset();

            data.entity             = null;
            data.journalId          = null;
            data.journalEditState   = JournalEditState.None;

            if (event == 'Delete') {
                this._userMessage = 'Journal Deleted';
            }
        }

        if (event == 'Save' || event == 'Delete') {
            if (this._journalSelectorComponent != null) {
                this._journalSelectorComponent.clearSearchResults();
            }
        }

        data.adding         = false;
        data.deleting       = false;
        data.inSubEditor    = false;
        this.setTab(this._tab);
    }

    hasFormerJournalName() {
        if (this._data.entity == null || this._relatedJournals == null) {
            return false;
        }

        const journal = _.find(this._relatedJournals, j => j.journalId == this._data.entity.rootJournalId);

        return (journal != null ? true : false);
    }

    formerJournalName() {
        if (this._data.entity == null || this._relatedJournals == null) {
            return false;
        }

        const journal = _.find(this._relatedJournals, j => j.journalId == this._data.entity.rootJournalId);
        return (journal != null ? journal.journalName : '');
    }

    onReset() {
        this._userMessage       = null;
        this._validationMessage = '';

        this._relatedJournals   = null;
        this._reserves          = null;
        this._subscriptions     = null;

        this._hasActiveSubscription         = false;
        this._data.journalReserveId         = null;
        this._data.journalSubscriptionId    = null;
    }

    onAddJournal() {
        this._validationMessage = '';

        const data = this._data;

        data.journalEditState   = JournalEditState.Journal;
        data.adding             = true;
        data.inSubEditor        = true;

        this._stateMap.staffJournalId = null;

    }

    onDownloadJournalSubscriptionReport() {
        const params: { key: string, value: string }[] = [];

        params.push({ key: 'reporttype', value: 'journalsubscription' });

        const url = this._userManager.getReportUrl(params);
        window.open(url, '_self');
        return false;
    }

    onDownloadJournalActiveSubscriptionReport() {
        const params: { key: string, value: string }[] = [];

        params.push({ key: 'reporttype', value: 'journalsubscription' });
        params.push({ key: 'reportfilter', value: 'active' });

        const url = this._userManager.getReportUrl(params);
        window.open(url, '_self');
        return false;
    }

    onDownloadJournalInactiveSubscriptionReport() {
        const params: { key: string, value: string }[] = [];

        params.push({ key: 'reporttype', value: 'journalsubscription' });
        params.push({ key: 'reportfilter', value: 'inactive' });

        const url = this._userManager.getReportUrl(params);
        window.open(url, '_self');
        return false;
    }

    onDownloadJournalReserveReport() {
        const params: { key: string, value: string }[] = [];

        params.push({ key: 'reporttype', value: 'journalreserve' });

        const url = this._userManager.getReportUrl(params);
        window.open(url, '_self');
        return false;
    }

    onEditJournal() {
        if (this._data.entity == null) {
            return;
        }

        this._validationMessage = '';

        const data = this._data;

        data.adding             = false;
        data.deleting           = false;
        data.inSubEditor        = true;
        data.journalEditState   = JournalEditState.Journal;
    }

    canDelete() {
    // !_data.inSubEditor && journal != null
        if (this.journal == null) {
            return false;
        }

        if (this.hasAssignedReferences()) {
            return false;
        }

        return (!this._data.inSubEditor);
    }

    onDeleteJournal() {
        this._validationMessage = '';

        if (this._data.entity == null) {
            return;
        }

        if (this.hasRelatedJournalData()) {
            this._validationMessage = 'Please delete all Journal Reserves and Subscriptions before deleting this Journal.';
            return;
        }

        const data = this._data;

        data.adding             = false;
        data.deleting           = true;
        data.inSubEditor        = true;
        data.journalEditState   = JournalEditState.Journal;
    }

    // Journal Names
    hasRelatedJournals() {
        return (this._relatedJournals != null && this._relatedJournals.length > 0);
    }

    // Journal Reserves
    hasReservesData() {
        return (this._reserves != null && this._reserves.length > 0);
    }

    onAddJournalReserve() {
        this._validationMessage = '';

        const data = this._data;

        data.journalEditState   = JournalEditState.Reserve;
        data.adding             = true;
        data.inSubEditor        = true;
    }

    onEditJournalReserve(h: JournalReserve) {
        this._validationMessage = '';

        if (h == null) {
            return;
        }

        const data = this._data;

        this._data.journalReserveId = h.journalReserveId;

        data.journalEditState   = JournalEditState.Reserve;
        data.adding             = false;
        data.deleting           = false;
        data.inSubEditor        = true;
    }

    onDeleteJournalReserve(h: JournalReserve) {
        this._validationMessage = '';

        const data = this._data;

        data.journalReserveId   = h.journalReserveId;
        data.journalEditState   = JournalEditState.Reserve;

        data.adding             = false;
        data.deleting           = true;
        data.inSubEditor        = true;
    }

    // Journal Subscriptions
    hasSubscriptionsData() {
        return (this._subscriptions != null && this._subscriptions.length > 0);
    }

    onAddJournalSubscription() {
        this._validationMessage = '';

        const data = this._data;

        data.journalEditState   = JournalEditState.Subscription;
        data.adding             = true;
        data.inSubEditor        = true;
    }

    onEditJournalSubscription(s: JournalSubscription) {
        this._validationMessage = '';

        if (s == null) {
            return;
        }

        const data = this._data;

        this._data.journalSubscriptionId = s.journalSubscriptionId;

        data.adding             = false;
        data.deleting           = false;
        data.inSubEditor        = true;
        data.journalEditState   = JournalEditState.Subscription;
    }

    onDeleteJournalSubscription(s: JournalSubscription) {
        this._validationMessage = '';

        const data = this._data;

        data.journalSubscriptionId = s.journalSubscriptionId;
        data.journalEditState   = JournalEditState.Subscription;

        data.adding             = false;
        data.deleting           = true;
        data.inSubEditor        = true;
    }

    // interaction logic
    setTab(tabNumber: number) {
        setTimeout(() => {
            if (this._tabContainer != null) {
                this._tabContainer.selectTab(tabNumber);
            }
        }, 1);

        this._stateMap.setJournalDataFor('tabNumber', tabNumber);
    }

    onTabChange(tabNumber: number) {
        this._tab = tabNumber;
        this._stateMap.setJournalDataFor('tabNumber', tabNumber);
    }

    onNavigateToJournal(n: RelatedJournalNameItem) {
        if (n == null) {
            return;
        }

        this._tab = 2;
        return this.fetchJournal(n.journalId).then(r => {
            this.setTab(this._tab);
        });
    }

    hasRelatedJournalData() {
        if (this._data == null || this._data.entity == null) {
            return false;
        }

        if (this._data.entity.journalReserves != null && this._data.entity.journalReserves.length > 0 ) {
            return true;
        }

        if (this._data.entity.journalSubscriptions != null && this._data.entity.journalSubscriptions.length > 0 ) {
            return true;
        }
        return false;
    }

    // *******************************************************
    // related references
    // *******************************************************
    formatReferenceSelectableEntities() {
        this._referencesEx = [];
        if (this._data.entity.references == null || this._data.entity.references.length < 1) { return; }

        _.clone(this._data.entity.references).forEach(element => {
            this._referencesEx.push(new SelectableEntity<Reference>(element, '#FFFFFF'));
        });

        this._referencesEx = _.sortBy(this._referencesEx, e => e.data.referenceId);
    }

    onSelectReferences(check: boolean) {

        this._referencesEx.map(function(r) {
            r.selected = check;
            return r
        });
    }

    canReassignReferences() {
        if (this.hasAssignedReferences() == false) {
            return false;
        }

        const selected = this._referencesEx.filter(r => r.selected);
        return (selected != null && selected.length > 0);
    }

    hasAssignedReferences() {
        if (this._data == null || this._data.entity == null) {
            return false;
        }

        return (this._data.entity.references != null && this._data.entity.references.length > 0);
    }

    onReassignReference() {
        this._validationMessage = '';

        const selectedRefIds = this._referencesEx.filter(r => r.selected).map(s => s.data.referenceId);

        const data = this._data;

        data.journalEditState       = JournalEditState.Reassign;
        data.inSubEditor            = true;
        data.selectedReferenceIds   = selectedRefIds;
    }

    getReferenceCountText() {
        if (this._data.entity.references != null) {
            const l = this._data.entity.references.length;
            return `${l} ${pluralize('reference', l)} found`;
        }
    }

    get referenceTabTitle(): string {
        const title = 'Assigned References';
        let cnt = 0;

        if (this._data.entity.references != null) {
            cnt = this._data.entity.references.length;
        }
        return title + ' (' + cnt + ')';
    }

    selectReference(r: Reference) {
        this._stateMap.currentReference = r;
        this._router.navigate(['staff/reference', r.referenceId, 'info']);
    }

    formatDate(year: number, month: number): string {
        if (year == null) {
            return '';
        }

        const m = (month == null) ? '' : ', ' + this._monthFullNames[month - 1];

        return (year + m);
    }

}

import { Location } from '@angular/common';
import { Component, Input, OnInit, ViewChild, Inject, forwardRef, QueryList } from '@angular/core';
import { Router } from '@angular/router';

import { UnitOfWork, StateMap, EditorService, provideParent, UserManager } from '../../services/common';
import { EditManager, IEditHost, LocationFns, UtilFns } from '../../utils/common';
import { PageState, EditPropParent } from '../../controls/common';
import { ExposureAdditionalMaterial, ExposureSurvey, ExposureAdditionalMaterialSurvey, TypeExposureSurveyResponse } from '../../entities/EntityModels';
import { ROUTES } from '../routes';
import { EXPOSURE_ROUTES } from './exposure.routes'

export class ExposureAdditionalMaterialData {
    edmAdditionalMaterial: EditManager<ExposureAdditionalMaterial>;
}

@Component({
    selector: 'exposure-additional-material-survey-editor',
    templateUrl: './exposure-additional-material-survey-editor.html',
    providers: [provideParent(ExposureAdditionalMaterialSurveyEditorComponent, EditPropParent)]
})

export class ExposureAdditionalMaterialSurveyEditorComponent implements OnInit, EditPropParent, IEditHost<ExposureAdditionalMaterial> {
    _isLoading: boolean = false;

    _pageState = new PageState("Exposure Additional Material Survey Editor");
    _typeExposureSurveyResponses: TypeExposureSurveyResponse[];

    _selectedExposureAdditionalMaterialIndex: number;
    _selectedExposureAdditionalMaterial: ExposureAdditionalMaterial;
    _exposureAdditionalMaterials: ExposureAdditionalMaterial[] = [];

    public _data: ExposureAdditionalMaterialData = new ExposureAdditionalMaterialData();
   
    @Input() entity: ExposureAdditionalMaterialSurvey;

    constructor(public _uow: UnitOfWork, public _stateMap: StateMap, public _location: Location) {
    }

    onAdd() {
    }

    ngOnInit() {
        this._isLoading = true;

        this._data.edmAdditionalMaterial = new EditManager<ExposureAdditionalMaterial>(this, this._uow, this._stateMap, "Exposure Survey Additional Materials");

        this._uow.typeExposureSurveyResponseRepository.all().then(r => {
            this._typeExposureSurveyResponses = r;
            this._uow.exposureAdditionalMaterialRepository.all().then(m => {
                this._exposureAdditionalMaterials = m;
                this._data.edmAdditionalMaterial.entities = m;
                this._isLoading = false;
            });
        });
    }

    getError(propName: string) {
        if (this.entity == null) {
            return;
        }
        return this.entity.getErrorFor(propName);
    }

    isAdded() {
        return this.entity.entityAspect.entityState.isAdded();
    }

    filterExposureAdditionalMaterialByIndex(ix: number) {
        this._selectedExposureAdditionalMaterialIndex = ix;

        this._selectedExposureAdditionalMaterial = this._data.edmAdditionalMaterial.entities[ix];

        if (this.isAdded) {
            this.entity.exposureAdditionalMaterial = this._selectedExposureAdditionalMaterial;
        }
    }
}

import { Component, Input, OnInit, ElementRef } from '@angular/core';
import { NgStyle } from '@angular/common';
import { UnitOfWork } from '../../services/common';
import { UtilFns  } from '../../utils/common';
import { ModalDialog, PageState } from '../../controls/common';

import { SelectableEntity } from '../../entities/projections/SelectableEntity';
import { SuperCluster, SuperClusterPath, ClusterGroup, ClusterPath } from '../../entities/EntityModels';
import * as _ from 'lodash';

@Component({
    selector: 'clusterpath-selector',
    templateUrl: './clusterpath-selector.html',
})

export class ClusterPathSelectorComponent extends ModalDialog<boolean> {
    _superClusterPaths: SelectableEntity<SuperClusterPath>[] = [];
    _selectedClusterPathIds: number[] = [];
    _superClusterPathGroups: { id: string, paths: SelectableEntity<SuperClusterPath>[], selected: boolean, collapsed?: boolean }[] = [];
    _selectEntireGroup = false;
    _selectSuperCluster = false;

    constructor(elementRef: ElementRef, public _uow: UnitOfWork) {
        super(elementRef);
    }

    onSave() {
        this._selectedClusterPathIds = [];

        // tslint:disable-next-line:forin
        for (const key in this._superClusterPathGroups) {
            this._superClusterPathGroups[key].paths.forEach(p => {
                if (p.selected) {
                    this._selectedClusterPathIds.push(p.data.clusterPathId);
                }
            });
        }

        UtilFns.returnModal(this, true);
    }

    onCancel() {
        UtilFns.returnModal(this, false);
        // tslint:disable-next-line:indent
	}

    // tslint:disable-next-line:indent
	// properties
    public set SuperClusterPaths(data: SelectableEntity<SuperClusterPath>[]) {
        this._superClusterPaths = data;

        const groups = _.groupBy(this._superClusterPaths, s => s.data.superCluster.superClusterName);

        this._superClusterPathGroups = _.map(groups, (v, k) => {
            return { id: k, paths: v, selected: false, isCollapsed: false };
        });
    }

    public get SuperClusterPaths(): SelectableEntity<SuperClusterPath>[] {
        return this._superClusterPaths;
    }

    public get SelectedClusterPathIds(): number[] {
        return this._selectedClusterPathIds;
    }

    public selectedClusterPaths(clusterPathIds: number[]) {
        clusterPathIds.forEach(c => {
            for (const key in this._superClusterPathGroups) {
                this._superClusterPathGroups[key].paths.forEach(p => {
                    if (p.data.clusterPathId == c) {
                        p.selected = true;
                    }
                });
            }
        });
    }

    public get utilFns() {
        return UtilFns;
    }

    // selectClusterPath(cpx: SelectableEntity<SuperClusterPath>) {
    //    if (cpx.selected) {
    //        this._selectedClusterPathIds.push(cpx.data.clusterPathId);
    //    }
    //    else {
    //        var idx = this._selectedClusterPathIds.indexOf(cpx.data.clusterPathId);
    //        if (idx > -1) {
    //            this._selectedClusterPathIds = this._selectedClusterPathIds.splice(idx, 1);
    //        }
    //    }
    // }

    selectClusterPath(cpx: SelectableEntity<SuperClusterPath>) {
    }

    superClusterPathCollapse(ix: number) {
        this._superClusterPathGroups[ix].collapsed = !this._superClusterPathGroups[ix].collapsed;
    }

    // superClusterColorCode(group: SuperClusterGroup) {
    //    return (group.paths[0].data.superCluster.colorCode);
    // }

    onSelectGroup() {
        this._superClusterPathGroups.forEach(s => {
            s.selected = this._selectEntireGroup;
            this.onSelectSuperCluster(s.id);
        });
    }

    onSelectSuperCluster(groupId: string) {
        const data = this._superClusterPathGroups.filter(g => g.id == groupId);
        data.forEach(c => {
            const checked = c.selected;
            c.paths.forEach(p => {
                p.selected = checked;
            });
        });
    }

    onUnselectAll() {
        for (const key in this._superClusterPathGroups) {
            this._superClusterPathGroups[key].selected = false;
            this._superClusterPathGroups[key].paths.forEach(p => {
                p.selected = false;
            });
        }
    }
}

<div class="form-horizontal indent-top-sm">
    <div *ngIf="entity" class="form-horizontal indent-top-sm">
        <edit-prop label="Cas No." szs="2,4">
            <input class="form-control" [(ngModel)]="entity.cASNumber" type="text">
        </edit-prop>

        <edit-prop label="Material Name" szs="2,4">
            <input class="form-control" [(ngModel)]="entity.materialName" type="text">
        </edit-prop>

        <edit-prop label="Member Company" szs="2,4">
            <input class="form-control" [(ngModel)]="entity.memberCompany" type="text">
        </edit-prop>

        <edit-prop label="Notes" prop="notes" szs="2,4">
            <textarea class="form-control" rows=3 [(ngModel)]="entity.notes"></textarea>
        </edit-prop>
    </div>
</div>

import {Component, Input, Output, ViewChild, OnInit, AfterViewInit, EventEmitter} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import * as _ from 'lodash';
import { UnitOfWork, StateMap, UserManager, ErrorLogger } from '../../services/common';
import { UtilFns, IEditHost, EditManager } from '../../utils/common';
import { RIFMDocument, RIFMDocumentFolder, RIFMDocumentArchive, TypeRIFMDocument } from '../../entities/EntityModels';
import { DocumentFoldersListItem } from '../../entities/projections/DocumentFoldersListItem';
import { ReferenceDocumentListItem } from '../../entities/projections/ReferenceDocumentListItem';
import { SelectableEntity } from '../../entities/projections/SelectableEntity';
import { DOCUMENTMANAGEMENT_ROUTES } from './document-management.routes';

import { ROUTES } from '../routes';

export enum SearchType { REFERENCEID, FOLDER, RECENTLYMODIFIED }

@Component({
    selector: 'document-selector',
    templateUrl: './document-selector.html'
})

export class DocumentSelectorComponent implements OnInit, AfterViewInit {
    @Input() showSelectors: boolean;
    @Input() searchTerm: string;
    @Output() stateChange = new EventEmitter<string>();

    public _isLoading: boolean;

    public _selectedFileName: string;
    public _selectedReferenceId: number;
    public _selectedRIFMDocumentId: number;

    public colorUnselected         = '#C1CDC1';
    public colorSelected           = '#C1FFC1';
    public specialColorSelected    = '#A4D3EE';

    public _searchType: SearchType  = SearchType.REFERENCEID;

    public _searchTerm: string;
    public _searchErrorMessage: string;
    public _searchTypeMessage   = 'Enter ReferenceIds separated by a comma.';

    public _rifmDocumentFolders: RIFMDocumentFolder[] = [];
    public _rifmDocumentsEx: SelectableEntity<ReferenceDocumentListItem>[] = [];
    public _recentRIFMDocumentsEx: SelectableEntity<ReferenceDocumentListItem>[] = [];

    public _selectedRIFMDocumentFolderId = -1;
    public _selectedDocumentFolder: RIFMDocumentFolder;

    public _isDisabled = false;

    // grouped data
    public _foldersAndFiles: { folderId: number, folderName: string, referenceDocumentsEx: SelectableEntity<ReferenceDocumentListItem>[], collapsed?: boolean }[] = [];

    _isDataLoading: boolean;

    constructor(public _stateMap: StateMap, private _uow: UnitOfWork, public _router: Router) {
    }

    ngOnInit() {
        if (this.searchTerm != null && this.searchTerm != '') {
            this.searchAndLoadReferenceDocument(this.searchTerm);
        }
        // clear out initialization param after it has been used
        this.searchTerm = '';
        this._stateMap.documentManagementReferenceId = null;
    }

    ngAfterViewInit() {

    }

    get utilFns() {
        return UtilFns;
    }

    // **************************************************************************************
    // load data
    // **************************************************************************************
    fetchAllFolders() {

        this._rifmDocumentFolders = [];
        this._selectedRIFMDocumentFolderId = -1;
        this._selectedDocumentFolder = null;

        this._uow.fetch('DocumentManagement/RIFMDocumentFolders', { archiveFolder: false }).then(r => {
            if (r != null && r.length > 0) {
                this._rifmDocumentFolders = _.sortBy(r, n => n.folderName);
            }
            this._isLoading = true;
        });
    }

    fetchFoldersByFolderId(folderIds: number[]) {

        this._foldersAndFiles = [];

        this._uow.fetch('DocumentManagement/FetchRIFMDocumentFoldersDictionaryByFolderIds', { folderIds: folderIds }).then(r => {

            if (r != null && r.length > 0) {
                const docandfiles: DocumentFoldersListItem[] = r as DocumentFoldersListItem[];

                docandfiles.forEach((d: DocumentFoldersListItem) => {
                    const foldername = d.documents[0].folderName; // all items for each dictionary entry should have the same folder assignment
                    const files: SelectableEntity<ReferenceDocumentListItem>[] = [];
                    d.documents.forEach((element: ReferenceDocumentListItem) => {
                        files.push(new SelectableEntity<ReferenceDocumentListItem>(element, '#FFFFFF'));
                    });
                    this._foldersAndFiles.push({ folderId: d.folderId, folderName: foldername, referenceDocumentsEx: files, collapsed: true });
                });
                this._foldersAndFiles = _.sortBy(this._foldersAndFiles, n => n.folderName);
            }
            this._isLoading = true;
        });
    }

    fetchFoldersByReferenceId(referenceIds: number[]) {

        this._foldersAndFiles = [];

        this._uow.fetch('DocumentManagement/FetchRIFMDocumentFoldersDictionaryByReferenceIds', { referenceIds: referenceIds }).then(r => {

            if (r != null && r.length > 0) {
                const docandfiles: DocumentFoldersListItem[] = r as DocumentFoldersListItem[];

                docandfiles.forEach((d: DocumentFoldersListItem) => {
                    const foldername = d.documents[0].folderName; // all items for each dictionary entry should have the same folder assignment
                    const files: SelectableEntity<ReferenceDocumentListItem>[] = [];
                    d.documents.forEach((element: ReferenceDocumentListItem) => {
                        files.push(new SelectableEntity<ReferenceDocumentListItem>(element, '#FFFFFF'));
                    });
                    this._foldersAndFiles.push({ folderId: d.folderId, folderName: foldername, referenceDocumentsEx: files, collapsed: true });
                });
                this._foldersAndFiles = _.sortBy(this._foldersAndFiles, n => n.folderName);
            } else {
                this._searchErrorMessage = 'No Reference Documents found';
            }
            this._isLoading = true;
        });
    }

    fetchAndFormatFiles(ix: number) {
        this._foldersAndFiles[ix].referenceDocumentsEx = [];

        const folderIds: number[] = [];
        folderIds.push(this._foldersAndFiles[ix].folderId); // fetch function accepts multiple folderIds but here we only have one

        this._uow.fetch('DocumentManagement/FetchRIFMDocumentsByFolderId', { folderIds: folderIds }).then ( f => {
            if (f != null && f.length > 0) {
                f.forEach((element: ReferenceDocumentListItem) => {
                        this._foldersAndFiles[ix].referenceDocumentsEx.push(new SelectableEntity<ReferenceDocumentListItem>(element, '#FFFFFF'));
                });
            }
        });
    }

    fetchRIFMDocuments(referenceIds: number[]): Promise<any> {
        this._rifmDocumentsEx = [];

        return this._uow.fetch('DocumentManagement/FetchRIFMDocumentsByReferenceId', { referenceIds: referenceIds }).then ( f => {
            if (f != null && f.length > 0) {
                f.forEach((element: ReferenceDocumentListItem) => {
                    this._rifmDocumentsEx.push(new SelectableEntity<ReferenceDocumentListItem>(element, '#FFFFFF'));
                });
            } else {
                this._searchErrorMessage = 'No Reference Documents found';
            }
        });
    }

    fetchRecentlyModifiedRIFMDocuments() {
        this._recentRIFMDocumentsEx = [];

        this._uow.fetch('DocumentManagement/FetchRecentlyModifiedRIFMDocuments', { takeCount: 25 }).then ( f => {
            if (f != null && f.length > 0) {
                f.forEach((element: ReferenceDocumentListItem) => {
                    this._recentRIFMDocumentsEx.push(new SelectableEntity<ReferenceDocumentListItem>(element, '#FFFFFF'));
                });
            } else {
                this._searchErrorMessage = 'No Reference Documents found';
            }
        });
    }

    // **************************************************************************************
    // reset selection formatting
    // **************************************************************************************
    resetBackgroundColor(rifmDocuments: SelectableEntity<RIFMDocument>[]) {
        rifmDocuments.forEach((element, index) => {
            element.backgroundColor = this.colorUnselected;
        });
    }

    private foldersAndFilesCollapse(ix: number) {
        this._foldersAndFiles[ix].collapsed = !this._foldersAndFiles[ix].collapsed;
        if (!this._foldersAndFiles[ix].collapsed) {
            if (this._foldersAndFiles[ix].referenceDocumentsEx.length == 0) { // check to see if you need to load the file list for this folder
                this.fetchAndFormatFiles(ix);
            }
        }
    }

    public expandAll(expand: boolean) {
        setTimeout(() => {
            this._foldersAndFiles.forEach(g => g.collapsed = !expand);
        }, 0);
    }

    hasData(): boolean {
        return (this._foldersAndFiles != null && this._foldersAndFiles.length > 0);
    }

    // **************************************************************************************
    // search by folder
    // **************************************************************************************

    filterRIFMDocumentFolderByIndex(ix: number) {
        this._selectedDocumentFolder = this._rifmDocumentFolders[ix];
        this._selectedRIFMDocumentFolderId = this._selectedDocumentFolder.rIFMDocumentFolderId;

        const folderIds: number[] = [];
        folderIds.push(this._selectedRIFMDocumentFolderId);

        this.fetchFoldersByFolderId(folderIds);
    }

    // **************************************************************************************
    // search by referenceid
    // **************************************************************************************
    search() {
        let hasErrors = false;

        this._searchErrorMessage = '';

        if (this.searchType == SearchType.FOLDER) {
            const referenceIds = this._searchTerm.split(',').map(function (item) {
                const num = Number(item.trim());
                if (isNaN(num)) {
                    hasErrors = true;
                } else {
                    return num;
                }
            });
            if (hasErrors) {
                this._searchErrorMessage = 'Hmmm, looks like some of the ReferenceIds are not numbers.';
                return;
            }
            return this.fetchFoldersByReferenceId(referenceIds);

        } else if (this.searchType == SearchType.REFERENCEID) { // search by ReferenceIds
            const referenceIds = this._searchTerm.split(',').map(function (item) {
                const num = Number(item.trim());
                if (isNaN(num)) {
                    hasErrors = true;
                } else {
                    return num;
                }
            });
            if (hasErrors) {
                this._searchErrorMessage = 'Hmmm, looks like some of the ReferenceIds are not numbers.';
                return;
            }
            return this.fetchRIFMDocuments(referenceIds);
        }
    }

    // filter interactions
    canSearch() {
        return (this.searchType != SearchType.RECENTLYMODIFIED && this._searchTerm && this._searchTerm.trim().length > 0);
    }

    showSearchElements(): boolean {
        return (this.searchType != SearchType.RECENTLYMODIFIED);
    }

    public filterButtonColor(filtertype: string) {
        switch (filtertype) {
            case 'reference': {
                return (this.searchType == SearchType.REFERENCEID) ? this.colorSelected : this.colorUnselected;
                break;
            }
            case 'folder': {
                return (this.searchType == SearchType.FOLDER) ? this.colorSelected : this.colorUnselected;
                break;
            }
            case 'recentlymodified': {
                return (this.searchType == SearchType.RECENTLYMODIFIED) ? this.colorSelected : this.specialColorSelected;
                break;
            }
            default: {
                return this.colorUnselected;
                break;
            }
        }
    }

    // toggle button
    onSelectFilterType(type: string) {

        this._searchErrorMessage = '';

        if (type == 'reference') {
            this.searchType = SearchType.REFERENCEID;
        } else if (type == 'folder') {
            this.searchType = SearchType.FOLDER;

            if (this._rifmDocumentFolders == null || this._rifmDocumentFolders.length == 0) {
                this.fetchAllFolders();
            }
        } else {
            this.searchType = SearchType.RECENTLYMODIFIED;
            // no filters are required so run the search immediately
            this.fetchRecentlyModifiedRIFMDocuments();
        }
    }

    public refreshWithRecentlyModified() {
        this.onSelectFilterType('recentlymodified');
    }

    public onView(dx: SelectableEntity<ReferenceDocumentListItem>) {
        if (!dx) {return; }

        this._selectedReferenceId       = dx.data.referenceId;
        this._selectedRIFMDocumentId    = dx.data.rIFMDocumentId;
        this._selectedFileName          = dx.data.fileName;
        this.stateChange.emit('view');
    }

    // link click events
    public onDeleteFile(dx: SelectableEntity<ReferenceDocumentListItem>) {
        if (!dx) {return; }

        this._selectedReferenceId       = dx.data.referenceId;
        this._selectedRIFMDocumentId    = dx.data.rIFMDocumentId;
        this._selectedFileName          = dx.data.fileName;
        this.stateChange.emit('deletefile');
    }

    public onEdit(dx: SelectableEntity<ReferenceDocumentListItem>) {
        if (!dx) {return; }

        this._selectedReferenceId       = dx.data.referenceId;
        this._selectedRIFMDocumentId    = dx.data.rIFMDocumentId;
        this._selectedFileName          = dx.data.fileName;
        this.stateChange.emit('edit');
    }

    public onEditPDFPages(dx: SelectableEntity<ReferenceDocumentListItem>) {
        if (!dx) {return; }

        this._selectedReferenceId       = dx.data.referenceId;
        this._selectedRIFMDocumentId    = dx.data.rIFMDocumentId;
        this._selectedFileName          = dx.data.fileName;
        this.stateChange.emit('editPDFPages');
    }

    public onExport(dx: SelectableEntity<ReferenceDocumentListItem>) {
        if (!dx) {return; }

        this._selectedReferenceId       = dx.data.referenceId;
        this._selectedRIFMDocumentId    = dx.data.rIFMDocumentId;
        this._selectedFileName          = dx.data.fileName;
        this.stateChange.emit('export');
    }

    public onRemove(dx: SelectableEntity<ReferenceDocumentListItem>) {
        if (!dx) {return; }

        this._selectedReferenceId       = dx.data.referenceId;
        this._selectedRIFMDocumentId    = dx.data.rIFMDocumentId;
        this._selectedFileName          = dx.data.fileName;
        this.stateChange.emit('remove');
    }

    public onReplace(dx: SelectableEntity<ReferenceDocumentListItem>) {
        if (!dx) {return; }

        this._selectedReferenceId       = dx.data.referenceId;
        this._selectedRIFMDocumentId    = dx.data.rIFMDocumentId;
        this._selectedFileName          = dx.data.fileName;
        this.stateChange.emit('replace');
    }

    onNavToRef(dx: SelectableEntity<ReferenceDocumentListItem>) {
        if (!dx) {return; }

        if (dx.data.referenceId == null) {
            return;
        }

        this._router.navigate(UtilFns.asRouterParts(ROUTES.Reference, dx.data.referenceId));
    }

    // *****************************************************************************
    // bindable properties
    // *****************************************************************************
    public get filterSearchType(): typeof SearchType {
        return SearchType;
    }

    public get isDisabled() {
        return this._isDisabled;
    }

    public set isDisabled(value) {
        this._isDisabled = value;
    }

    public get searchType(): SearchType {
        return this._searchType;
    }

    public set searchType(value) {
        this._searchType = value;
    }

    public get selectedFileName(): string {
        return this._selectedFileName;
    }

    public set selectedFileName(val: string) {
        this._selectedFileName = val;
    }

    public get selectedReferenceId(): number {
        return this._selectedReferenceId;
    }

    public set selectedReferenceId(val: number) {
        this._selectedReferenceId = val;
    }

    public get searchTypeMessage() {
        return this._searchTypeMessage;
    }

    public set searchTypeMessage(value) {
        this._searchTypeMessage = value;
    }

    public get selectedRIFMDocumentId(): number {
        return this._selectedRIFMDocumentId;
    }

    public set selectedRIFMDocumentId(val: number) {
        this._selectedRIFMDocumentId = val;
    }

    // **************************************************************************************
    // pre-load  reference
    // **************************************************************************************
    searchAndLoadReferenceDocument(searchTerm: string) {
        if (this.canConvertToNumber(searchTerm)) {
            this.onSelectFilterType('reference');
            this._searchTerm = searchTerm;

            const referenceId = parseInt(searchTerm, 10);

            const params: number[] = new Array();
            params.push(referenceId);

            this.fetchRIFMDocuments(params).then(r => {
                const refDocEx = this.filterToReferenceDocumentByReferenceId(referenceId);

                if (refDocEx != null && this.isValidString(refDocEx.data.fileName)) {
                    this.onView(refDocEx);
                }
            });
        }
    }

    canConvertToNumber(teststring: string) {
        if (!this.isValidString(teststring)) {
            return false;
        }

        const testnum = parseInt(teststring, 10);
        return !isNaN(testnum) && testnum > -1;
    }

    filterToReferenceDocumentByReferenceId(referenceId: number): SelectableEntity<ReferenceDocumentListItem> {
        if (this._rifmDocumentsEx == null || this._rifmDocumentsEx.length < 1) {
            return null;
        }

        const docs = this._rifmDocumentsEx.filter(r => r.data.referenceId == referenceId);
        if (docs != null && docs.length > 0) {
            return docs[0];
        }
    }

    isValidString(teststring: string): boolean {
        if (!teststring) {
            return false;
        }
        return (teststring.trim().length > 0);
    }
}

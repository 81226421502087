<div class="indent-left-md indent-top-sm" >
    <tab-container>
        <ng-template tab-pane title="Structure">
            <prediction-hh-alert [materialId]="material.materialId"></prediction-hh-alert>
        </ng-template>
        <ng-template tab-pane title="Cramer Class" [hasData]="_stateMap.hasMaterialDataFor('prediction-hh-cramer')">
            <prediction-hh-cramer [materialId]="material.materialId"></prediction-hh-cramer>
        </ng-template>
        <ng-template tab-pane title="TTC" *ngIf="isStaff()">
            <prediction-hh-ttc [materialId]="material.materialId"></prediction-hh-ttc>
        </ng-template>
    </tab-container>
</div>

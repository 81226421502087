<page-status [pageState]="_pageState"></page-status>

<div style="margin-top: 15px">
    <h4>Reported Exposure Data by Survey</h4>

    <div *ngIf="hasData()" class="form-group">
        <button type="button" class="btn btn-primary btn-xs expander-button" (click)="expandAll(true)">Expand All</button>
        <button type="button" class="btn btn-primary btn-xs expander-button" (click)="expandAll(false)">Collapse All</button>
    </div>

    <div *ngIf="!hasData()">
        <p class="bold left-indent">There is no Exposure Survey data for this material.</p>
    </div>

    <div *ngIf="hasData()" class="col-md-5">
        <span class="inline">
            <img style="margin-left: 10px; margin-right: 4px;" class="inline multiple-measure-comment-image" src="assets/images/MultipleExposureMeasures.png" />
            <span class="inline italic">Indicates multiple runs of the same measure within an Exposure Survey.</span>
        </span>

        <div class="col-md-5">
            <div class="form-group" *ngFor="let group of _measureSurveyCategoryGroups; let ix=index" style="width: 800px;">
                <div class="panel-body" style="border: 1px solid lightgray;">
                    <div class="row text-center bold" style="height: 28px; padding: 4px;" [ngStyle]="{ 'background-color': group.backgroundColor, 'color': group.fontColor }">
                        <div class="col-sm-1">
                            <a (click)="surveyGroupCollapseNew(ix)">
                                <span *ngIf="group.collapsed" class="fa fa-caret-down" style="color: #ffffff; font-size: 32px; margin-top: -5px;"></span>
                                <span *ngIf="!group.collapsed" class="fa fa-caret-up" style="color: #ffffff; font-size: 32px; margin-top: -5px;"></span>
                            </a>
                        </div>
                        <span class="text-left inline">{{surveyDescription(group)}}</span>
                    </div>

                    <div class="row" [attr.data-hidden]="group.collapsed" *ngFor="let data of group.measuresByCategory; let ixx=index">
                        <p style="padding: 4px; background-color: #B9D3EE; width: 799px;" class="bold">{{data.categoryId}}</p>
                        <table class="table table-hover table-bordered table-condensed" style="width: auto;">
                            <tr style="background-color: lightgray;">
                                <th class="gray-text text-left" style="width:430px; text-align: left;">Measure</th>
                                <th class="gray-text text-center" style="width:85px; text-align: center;">Value</th>
                                <th class="gray-text text-center" style="width:110px; text-align: center;">Version</th>
                                <th class="gray-text text-left" style="width:230px; text-align: left;">Notes</th>
                                <th class="gray-text text-center" style="width:14px; text-align: center;">Multiple</th>
                            </tr>
                            <tbody>
                            <tr *ngFor="let m of data.measureValues">
                                <td *ngIf="m.index == 0" class="text-left">
                                    <span class="demi-bold-text">{{m.measureDescription}}</span>
                                </td>
                                <td *ngIf="m.index > 0"></td>
                                <td *ngIf="m.measureQualitativeResult != null" class="text-center">{{m.measureQualitativeResult}}</td>
                                <td *ngIf="m.measureQuantitativeResult != null" class="text-center">{{utilFns.formatNumber(m.measureQuantitativeResult, 12, 4)}}</td>
                                <td class="text-center">{{m.cremeVersion}}</td>
                                <td>
                                    <span *ngIf="m.measureNotes != null" class="text-left" style="font-size: 12px;">{{m.measureNotes}}</span>
                                </td>
                                <td>
                                    <img *ngIf="m.isMultiple" class="image-top multiple-measure-image" src="assets/images/MultipleExposureMeasures.png" />
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <div *ngIf="group.notes && group.notes.length > 0">
                            <span style="display: inline-block; width: 100%;">
                                <span class="bold" style="color: #4CAF50; font-size: 16px;">*</span>
                                <span class="bold inline">Notes:</span>
                                <span class="inline" style="margin-left: 2px;">{{group.notes}}</span>
                            </span>
                    </div>
                    <div *ngIf="group.isomers && group.isomers.length > 0">
                            <span style="display: inline-block; width: 100%;">
                                <span class="bold" style="color: #4CAF50; font-size: 16px;">*</span>
                                <span class="bold inline">Isomers included in this survey:</span>
                                <span class="inline" style="margin-left: 2px;">{{group.isomers}}</span>
                            </span>
                    </div>
                </div>
            </div>
        </div>

    </div>

    <div class="float-left" style="margin-left: 26px; margin-top: 8px;" *ngIf="_isomerOfMaterialExposureSurveys != null && _isomerOfMaterialExposureSurveys.length > 0">
        <span class="bold inline page-subtitle left-indent">This Material was surveyed as an isomer of the following materials and surveys</span>

        <div class="left-indent" style="margin-top: 8px;">
            <default-table>
                <thead>
                <tr>
                    <td>Survey Material CAS No.</td>
                    <td>RIFM Id</td>
                    <td>Survey Date</td>
                    <td>Survey Number</td>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let i of _isomerOfMaterialExposureSurveys">
                    <td> <a class="inline bold some-space" (click)="onShowMaterial(i)">{{i.materialExposureSurvey?.material?.realCASNumber}}</a></td>
                    <td>{{i.materialExposureSurvey?.material?.formattedRIFMId}}</td>
                    <td>{{i.materialExposureSurvey?.exposureSurvey?.exposureSurveyMonthYear}}</td>
                    <td>{{i.materialExposureSurvey?.exposureSurvey?.exposureSurveyNumber}}</td>
                </tr>
                </tbody>
            </default-table>
        </div>
    </div>

</div>

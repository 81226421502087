import { EntityBase } from './EntityBase';

import { Validator } from 'breeze-client';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

export class SurveyUsesByCompanyAndMaterialAndYear extends EntityBase {

   /// <code> Place custom code between <code> tags
   
   /// </code>

   // Generated code. Do not place code below this line.
   id: number;
   surveyYear: string;
   organizationName: string;
   materialId: number;
   realCASNumber: string;
   displayCAS: string;
   rIFMId: string;
   china: number;
   asiaPacific: number;
   eEC: number;
   europe: number;
   japan: number;
   northAmerica: number;
   other: number;
   southAmerica: number;
   uSA: number;
   world: number;
}


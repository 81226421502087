<div class="modal" id="exception-dialog" role="dialog">
    <div class="modal-dialog  modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                <h3>An error has occurred</h3>
                <h4 class="bold">Please send an email to help@rifm.org with the error message shown below. 
                    If possible, please include the steps you took just prior to the occurrence of this error. 
                    This will allow our developers to reproduce the error, and get to the cause faster.
                </h4>
                <div class="nav navbar-left navbar-form">
                    <button class="btn btn-primary navbar-btn" type="button" (click)="onRespond(true)">Reload the current page</button>
                    <button class="btn btn-primary navbar-btn" type="button" (click)="onRespond(false)">Return to the Home screen</button>
                    <!--<button class="btn btn-primary navbar-btn" type="button" (click)="onSendEmail()" >Send an email to help@rifm.org</button>-->
                </div>
            </div>
            <div class="modal-body">
                <div class="alert-danger">
                    <p id="errorMessages">{{ _errorMessages }}</p>
                    <br>
                    <p id="addlErrorText"></p>
                </div>
            </div>

            <div class="modal-footer">
                <div class="nav navbar-left navbar-form">
                    <button class="btn btn-primary navbar-btn" type="button" (click)="onRespond(true)">Reload the current page</button>
                    <button class="btn btn-primary navbar-btn" type="button" (click)="onRespond(false)">Return to the Home screen</button>
                </div>
            </div>
        </div>
    </div>
</div>
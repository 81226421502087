import { EntityBase } from './EntityBase';
import { TypeExposureMeasureValue } from './TypeExposureMeasureValue';
import { TypeHouseholdProduct } from './TypeHouseholdProduct';

import { Validator } from 'breeze-client';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

export class TypeExposureMeasure extends EntityBase {

   /// <code> Place custom code between <code> tags
   
   /// </code>

   // Generated code. Do not place code below this line.
   typeExposureMeasureId: number;
   measureName: string;
   measureDescription: string;
   typeHouseholdProductId: number;
   typeExposureMeasureValueId: number;
   sortOrder: number;
   displayCategory: string;
   rowVersion: number;
   typeExposureMeasureValue: TypeExposureMeasureValue;
   typeHouseholdProduct: TypeHouseholdProduct;
}


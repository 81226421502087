import { EntityBase } from './EntityBase';
import { Material } from './Material';

import { Validator } from 'breeze-client';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

export class EPIData extends EntityBase {

   /// <code> Place custom code between <code> tags
   
   /// </code>

   // Generated code. Do not place code below this line.
   materialId: number;
   logKow: number;
   bP: number;
   mP: number;
   vP: number;
   vPPa: number;
   waterSol: number;
   henrys: number;
   mW: number;
   bCF: number;
   bIOWIN1: number;
   bIOWIN2: number;
   bIOWIN3: number;
   bIOWIN3T: string;
   bIOWIN6: number;
   wATERNTFragWSEstimate: number;
   created: Date;
   createdUser: string;
   modified: Date;
   modifyUser: string;
   rowVersion: number;
   material: Material;
}


import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import * as pluralize from 'pluralize';
import * as _ from 'lodash';

import { StateMap, UnitOfWork } from '../../services/common';
import { IBrowser, LocationFns, UtilFns } from '../../utils/common';
import { ColumnSorter, PageState } from '../../controls/common';

import { ExperimentalMaterial, TypeStudyMixture } from '../../entities/EntityModels';
import { ROUTES } from '../routes';

class PageStateExt extends PageState {
    groupBy: string;
    filter: string;
}

interface ExpMatGroup {
    name: string;
    experimentalMaterials: ExperimentalMaterial[];
    collapsed?: boolean;
    count?: number;
}

// TODO: need to support 'sub'-sorting by columns 'FullReferenced
@Component({
    selector: 'human-health',
    templateUrl: './human-health.html',
})
export class HumanHealthComponent implements OnInit {

    @Input() materialIds: number[];
    @Input() toxType: string; // either 'H' or 'E'
    @Input() tabPath: string;
    @Input() shouldFetchMaterials: boolean;

    _allExpMats: ExperimentalMaterial[];
    _allGroups: ExpMatGroup[] = [];
    _expMatGroups: ExpMatGroup[] = [];
    _typeStudyMixtures: TypeStudyMixture[];
    _studiesText = '';
    _groupByMap: {};
    _groupByKeys: string[];
    _filters: string[];
    _colSorter: ColumnSorter;
    _pageState: PageStateExt;

    _browser: IBrowser;
    _maxExpMats: number;


    constructor(public _uow: UnitOfWork, public _stateMap: StateMap, public _location: Location, public _router: Router,
                public _cd: ChangeDetectorRef) {
        this._browser = UtilFns.detectBrowser();
        if (this._browser.isEdge || this._browser.isChrome) {
            this._maxExpMats = 1500;
        } else if (this._browser.isFirefox) {
            this._maxExpMats = 1000;
        } else { // usually IE but we want to allow others as well.
            this._maxExpMats = 1000;
        }
    }

    ngOnInit() {
        // TODO: Hack... should pass page
        if (this.tabPath.indexOf('.') > 0) {
            this._pageState = new PageStateExt(this.isHumanHealth() ? 'Related Human Health' : 'Related Environment');
        } else {
            this._pageState = new PageStateExt(this.isHumanHealth() ? 'Human Health' : 'Environment');
        }

        this._uow.typeStudyMixtureRepository.all().then(r => {
            this._typeStudyMixtures = r;
        });


        this._colSorter = new ColumnSorter(this, {
            'M': (em: ExperimentalMaterial) => em.M(),
            'R': (em: ExperimentalMaterial) => em.R(),
            'Sub-Reference': (em: ExperimentalMaterial) => em.subReference,
            'Brief Reference': (em: ExperimentalMaterial) => em.briefReference(),
            'Date': (em: ExperimentalMaterial) => em.modifiedAsISO(),
            'Sponsor': (em: ExperimentalMaterial) => em.sponsoringCompany()
        }, '', (sortColumn) => this._pageState.sortColumn = sortColumn);

        this._groupByMap = {
            'Study': 'studyLong',
            'Route': 'routeLong',
            'Species': 'speciesLong',
            // 'Reference': 'briefReference', // removed per #190
            'Reference No.': 'referenceId',
        };
        this._groupByKeys = Object.keys(this._groupByMap);

        this._pageState.groupBy = 'Study';
        this._pageState.filter = 'None';

        // setTimeout needed because of Angular RC5 bug where Location is not set correctly until
        // after timeout resolves.
        setTimeout(() => {
            const returned = LocationFns.updatePageState(this._location, this._stateMap, this._pageState, (state: PageState) => {
                this._colSorter.sortColumn = state.sortColumn;
                this.fetchAndGroupBy().then(() => {
                    this.filter();
                    this.sortWith(this._colSorter);
                });
            });

            LocationFns.setTab(this._location, this.tabPath);

            if (!returned) {
                this.fetchAndGroupBy();
            }
        }, 0);
    }

    calculateM(data: any) {
        return data.M();
    }

    calculateR(data: any) {
        return data.R();
    }

    expandAll(expand: boolean) {
        this._pageState.isLoading = true;
        setTimeout(() => {
            this._allGroups.forEach(g => g.collapsed = !expand);
            this._pageState.isLoading = false;
        }, 0);
    }


    isHumanHealth() {
        return this.toxType != 'E';
    }

    isStaff() {
        return this._stateMap.currentUser.isStaff;
    }

    navToRef(expMat: ExperimentalMaterial) {
        const parts = UtilFns.asRouterParts(ROUTES.Reference, expMat.referenceId());
        return this._router.navigate(UtilFns.asRouterParts(ROUTES.Reference, expMat.referenceId()));
    }

    navToRefData(expMat: ExperimentalMaterial) {
        this._router.navigate(UtilFns.asRouterParts(ROUTES.Reference, expMat.referenceId(),
            ROUTES.Reference.childRoutes.ReferenceData),
            { queryParams: { cas: expMat.material.realCASNumber, bioid: expMat.biologicalDataId } });
        // To use matrix params instead of queryParams ... ugh ... not yet documented in Angular2
        // this._router.navigate(UtilFns.asRouterParts(ROUTES.Reference, expMat.referenceId(),
        //      ROUTES.Reference.childRoutes.ReferenceData, { cas: expMat.material.realCASNumber, bioid: expMat.biologicalDataId }),

    }


    sortWith(colSorter: ColumnSorter) {
        this._expMatGroups.forEach(emg => {
            colSorter.sort(emg.experimentalMaterials);
        });
    }

    isVeryLarge() {
        return this._allExpMats && this._allExpMats.length > this._maxExpMats;
    }

    expCollapse(ix: number) {
        this._expMatGroups[ix].collapsed = !this._expMatGroups[ix].collapsed;
    }

    getGroupDescription(group: ExpMatGroup) {
        const count = group.count || group.experimentalMaterials.length;
        return ` - ${count} ${pluralize('study', count)}`;
    }

    filterChange(event: any) {
        // on a filterChange we do NOT need to get a new list of filters.
        const ix = event.currentTarget.selectedIndex;
        this._pageState.filter = this._filters[ix];
        this.filter();
    }

    filter() {
        if (this._pageState.filter == 'None') {
            this._expMatGroups = this._allGroups;
        } else {
            this._expMatGroups = this._allGroups.filter(g => g.name == this._pageState.filter);
            this._expMatGroups.forEach(g => g.collapsed = false);
        }
    }

    set groupBy(value) {
        this._pageState.groupBy = value;
        this._pageState.filter = 'None';
        this._colSorter.reset();
        this.doGroupBy();
    }

    get groupBy() {
        return this._pageState.groupBy;
    }

    fetchAndGroupBy() {
        this._pageState.isLoading = true;
        return this.fetch().then(r => {
            this.doGroupBy();
            this._pageState.isLoaded = r;
            return r;
        });
    }

    doGroupBy() {
        const propFnName = this._groupByMap[this._pageState.groupBy];
        const groups = _.groupBy(this._allExpMats, (expMat) => expMat[propFnName]());

        const expMatGroups = _.map(groups, (v, k) => {
            return { name: k, experimentalMaterials: v, isCollapsed: false };
        });
        if (this._pageState.groupBy == 'Reference No.') {
            this._allGroups = <any>_.sortBy(expMatGroups, (g) => parseInt(g.name));
        } else {
            this._allGroups = <any>_.sortBy(expMatGroups, (g) => g.name);
        }

        if (this.isVeryLarge()) {
            this._allGroups.forEach(g => g.collapsed = true);
        }
        this._filters = this._allGroups.map(g => g.name);
        this._filters.unshift('None');
        this._expMatGroups = this._allGroups;

    }

    fetch(): Promise<ExperimentalMaterial[]> {
        const p = { materialIds: this.materialIds, biologicalDataType: this.isHumanHealth() ? 0 : 1 };
        this._pageState.isLoading = true;
        if (this.shouldFetchMaterials) {
            // this can be done in parallel with query down below and is not required to finish before 2nd query
            // results will be lazily bound when they complete.
            this._uow.fetch('Materials/MaterialsByIds', { materialIds: this.materialIds });
        }
        return this._uow.experimentalMaterialRepository.whereWithParams(p).then(r => {
            this._allExpMats = r;
            const sText = pluralize('study', this._allExpMats.length);
            const mText = pluralize('material', this.materialIds.length);
            this._studiesText = `${this._allExpMats.length} ${sText} on ${this.materialIds.length} ${mText}`;
            return r;
        });
    }

    selectCAS(expMat: ExperimentalMaterial) {
        this._router.navigate(UtilFns.asRouterParts(ROUTES.Material, expMat.materialId));
    }

}

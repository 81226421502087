import { EntityBase } from './EntityBase';
import { TypeLinkTarget } from './TypeLinkTarget';
import { RIFMDocumentFolder } from './RIFMDocumentFolder';
import { TypeRIFMDocument } from './TypeRIFMDocument';
import { Reference } from './Reference';

import { Validator } from 'breeze-client';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

export class RIFMDocument extends EntityBase {

    /// <code> Place custom code between <code> tags
    public get assignedReference() {
        if (this.reference == null) { return ''; }
        return this.reference.referenceId + ', ' + this.reference.briefReference;
    }
    /// </code>

    // Generated code. Do not place code below this line.
    rIFMDocumentId: number;
    typeRIFMDocumentId: string;
    rIFMDocumentFolderId: number;
    referenceId: number;
    fileName: string;
    notes: string;
    changeHistory: string;
    enabled: boolean;
    created: Date;
    createdUser: string;
    modified: Date;
    modifyUser: string;
    rowVersion: number;
    rIFMDocumentFolder: RIFMDocumentFolder;
    typeRIFMDocument: TypeRIFMDocument;
    reference: Reference;
}

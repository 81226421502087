import { IModalDialog } from '../controls/modal-dialog';
import * as _ from 'lodash';
import * as moment from 'moment';

declare const $: any;
// declare var moment: any;

// for detectBrowser function
declare var opr: any;
declare var InstallTrigger: {};

export interface UrlParts {
    base: string;
    paramHash: IDictionary;
    queryHash: IDictionary;
}

interface IDictionary {
    [key: string]: string;
}

export interface IBrowser {
    isOpera: boolean;
    isFirefox: boolean;
    isSafari: boolean;
    isIE: boolean;
    isEdge: boolean;
    isChrome: boolean;
    isBlink: boolean;
}

export interface HashMap<T> {
    [key: string]: T;
}

export class UtilFns {

    static sort<T>(collection: T[], isAscending: boolean = true, fn: (a: T) => string) {
        return collection.sort((a, b) => {
            return (fn(a) || '').localeCompare(fn(b) || '') * (isAscending ? 1 : -1);

        });
    }

    static sortNumeric<T>(collection: T[], isAscending: boolean = true, fn: (a: T) => number) {
        return collection.sort((a, b) => {
            return (fn(a) - fn(b)) * (isAscending ? 1 : -1);

        });
    }

    static detectBrowser() {
        let r: IBrowser;
        const wnd: any = window;
        const doc: any = document;
        r = <IBrowser>{};
        // Opera 8.0+
        // r.isOpera = (!!wnd.opr && !!opr.addons) || !!wnd.opera || navigator.userAgent.indexOf(' OPR/') >= 0;
        r.isOpera = (!!wnd.opr && !!opr.addons) || !!wnd.opera || navigator.userAgent.indexOf(' OPR/') >= 0;
        // Firefox 1.0+
        r.isFirefox = typeof InstallTrigger !== 'undefined';
        // At least Safari 3+: "[object HTMLElementConstructor]"
        r.isSafari = Object.prototype.toString.call(wnd.HTMLElement).indexOf('Constructor') > 0;
        // Internet Explorer 6-11
        r.isIE = /*@cc_on!@*/!!doc.documentMode;
        // Edge 20+
        r.isEdge = !r.isIE && !!wnd.StyleMedia;
        // Chrome 1+
        r.isChrome = !!wnd.chrome && !!wnd.chrome.webstore;
        // Blink engine detection
        r.isBlink = (r.isChrome || r.isOpera) && !!wnd.CSS;
        return r;
    }

    static selectDOMElement(el: any) {
        const body = document.body;
        let range: any;
        let sel: any;
        if (document.createRange && window.getSelection) {
            range = document.createRange();
            sel = window.getSelection();
            sel.removeAllRanges();
            try {
                range.selectNodeContents(el);
                sel.addRange(range);
            } catch (e) {
                range.selectNode(el);
                sel.addRange(range);
            }
        } else if ((<any>body).createTextRange) {
            range = (<any>body).createTextRange();
            range.moveToElementText(el);
            range.select();
        }
        return range;

    }


    static formatDate(dt: Date, format: string = 'L') {
        if (!dt) {
            return '';
        }
        return moment(dt).format(format);
    }

    static formatNumber(num: number, width: number = 0, decimalDigits = 2) {
        if (num == null) {
            return '';
        }
        const parts = num.toFixed(decimalDigits).split('.');
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ','); // ',' is the thousands sep.
        const r = parts.join('.');
        if (width) {
            return _.padStart(r, width);
        } else {
            return r;
        }
    }

    static extend(obj1: Object, obj2: Object) {
        if (obj2 == null) {
            return obj1;
        }
        const obj3 = {};
        // tslint:disable-next-line:forin
        for (const attrname in obj1) {
            obj3[attrname] = obj1[attrname];
        }
        // tslint:disable-next-line:forin
        for (const attrname in obj2) {
            obj3[attrname] = obj2[attrname];
        }
        return obj3;
    }

    static scaleNumber(num: number, divisor: number, width: number = 0, decimalDigits = 2): string {
        if (num == null || divisor == null || divisor == 0) {
            return '';
        }

        const scaledNumber: number = (num / divisor);

        return UtilFns.formatNumber(scaledNumber, width, decimalDigits);
    }

    static getClassName(instance: {}) {
        const funcNameRegex = /function (.{1,})\(/;
        const results = (funcNameRegex).exec((<any>instance).constructor.toString());
        return (results && results.length > 1) ? results[1] : '';
    }

    static parseUrl(url: string): UrlParts {
        const parts = url.split('?');
        let base: string;
        let paramHash: IDictionary;
        const ix = parts[0].indexOf(';');
        if (ix == -1) {
            base = parts[0];
            paramHash = <IDictionary>{};
        } else {
            base = parts[0].substr(0, ix);
            paramHash = this.stringToHash(parts[0].substr(ix + 1), ';');
        }

        const queryHash = (parts.length > 1) ? this.stringToHash(parts[1], '&') : <IDictionary>{};
        return {
            base: base,
            paramHash: paramHash,
            queryHash: queryHash
        };
    }

    static formatUrl(urlParts: UrlParts) {
        let paramString = '';
        let queryString = '';
        if (!_.isEmpty(urlParts.paramHash)) {
            _.map(urlParts.paramHash);
            paramString = ';' + _.map(urlParts.paramHash, (v: any, k: any) => k + '=' + v).join(';');
        }
        if (!_.isEmpty(urlParts.queryHash)) {
            _.map(urlParts.queryHash);
            queryString = '?' + _.map(urlParts.queryHash, (v: any, k: any) => k + '=' + v).join('&');
        }

        return urlParts.base + paramString + queryString;
    }


    static stringToHash(qs: string, delimiter: string): IDictionary {
        if (!qs) {
            return {};
        }
        const parts = qs.split(delimiter);
        if (parts.length == 0) {
            return {};
        }
        const r: IDictionary = {};
        for (let i = 0; i < parts.length; ++i) {
            const p = parts[i].split('=', 2);
            if (p.length == 1) {
                r[p[0]] = '';
            } else {
                r[p[0]] = decodeURIComponent(p[1].replace(/\+/g, ' '));
            }
        }
        return r;
    }

    static asRouterParts(...parts: any[]) {
        return parts.map(part => {
            if (part == null) {
                return '';
            }
            if (part.path && part.component) {
                // extract just the text before the '/' in the part.path
                return part.path.split('/')[0];
            } else {
                return part;
            }
        });
    }

    // Hack because of Angular2 bug
    static encodeRouterPart(str: string) {
        if (str == null) {
            return str;
        }
        if (!str.replace) {
            return str;
        } // not a string
        // this works but can cause an exception:
        // 'The request filtering module is configured to deny a request that contains a double escape sequence.''
        // var r = str.replace(/[!'()*]/g, function (c) {
        //     return '%' + c.charCodeAt(0).toString(16);
        // });
        // so we use something simpler

        str = str.replace(/\(/g, '~~1~').replace(/\)/g, '~~2~');
        return encodeURIComponent(str);
    }

    static decodeRouterPart(str: string) {
        if (!str) {
            return str;
        }
        return str.replace('~~1~', '(').replace('~~2~', ')');
    }

    static encodeHackForAnd(str: string) {
        // replace spaces in any 'and' expression i.e. ' and ' ( case insensitive ) with '~|~'
        //     'a and b aNd g AND f' =>
        // =>  'a~|~and~|~b~|~aNd~|~g~|~AND~|~f'
        return str.replace(/ (and) /gi, '~|~$1~|~');

    }

    static printElement(ele: any) {
        const eleClone = ele.cloneNode(true);
        // create or find a element with an id of 'printSection'
        // printSection is styled in the css to print properly.
        let printSelectEle = document.getElementById('printSection');
        if (!printSelectEle) {
            printSelectEle = document.createElement('div');
            printSelectEle.id = 'printSection';
            document.body.appendChild(printSelectEle);
        }
        try {
            // dump the cloned node into the printSection.
            printSelectEle.innerHTML = '';
            printSelectEle.appendChild(eleClone);
            window.print();

        } finally {
            // remove it when done
            if (printSelectEle.parentElement) {
                printSelectEle.parentElement.removeChild(printSelectEle);
            }
        }
    }

    static showModal<T>(component: IModalDialog<T>, parent: any, validateFn?: (x: any) => string): Promise<any> {
        const ele = component.elementRef.nativeElement.firstElementChild;
        const modalEle = <any>$(ele);
        component.modalParent = parent;
        component.validationMessage = null;
        if (validateFn) {
            component.validationFn = validateFn.bind(parent);
        }
        const p = new Promise((resolve) => {
            modalEle.modal({backdrop: 'static', keyboard: false}).on('hidden.bs.modal', () => {
                resolve(component.modalResult);
            });
        });
        return p;
    }

    static returnModal<T>(component: IModalDialog<T>, result: T) {
        if (result != null && component.validationFn) {
            component.validationMessage = component.validationFn(result);
            if (component.validationMessage != null) {
                return;
            }
        }
        component.modalResult = result;
        UtilFns.hideModal(component);
    }

    static hideModal<T>(component: IModalDialog<T>) {
        const ele = component.elementRef.nativeElement.firstElementChild;
        const modalEle = <any>$(ele);
        modalEle.modal('hide');
    }
}

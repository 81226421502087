import { EntityBase } from './EntityBase';
import { AnalyticalResult } from './AnalyticalResult';
import { DermalExposure } from './DermalExposure';
import { EPIData } from './EPIData';
import { EPISuite } from './EPISuite';
import { ExperimentalMaterial } from './ExperimentalMaterial';
import { SpecialLink } from './SpecialLink';
import { WorkflowMaterialRecordedAction } from './WorkflowMaterialRecordedAction';
import { FoodStatus } from './FoodStatus';
import { HouseStatus } from './HouseStatus';
import { MaterialAdditionalIdentifier } from './MaterialAdditionalIdentifier';
import { MaterialAlertRule } from './MaterialAlertRule';
import { MaterialAssessmentCategory } from './MaterialAssessmentCategory';
import { MaterialConsumption } from './MaterialConsumption';
import { MaterialExpertPanelSummary } from './MaterialExpertPanelSummary';
import { MaterialFSAG } from './MaterialFSAG';
import { MaterialHazardCode } from './MaterialHazardCode';
import { MaterialPrecautionCode } from './MaterialPrecautionCode';
import { MaterialRelation } from './MaterialRelation';
import { MaterialTransparencyList } from './MaterialTransparencyList';
import { MaterialUNTransport } from './MaterialUNTransport';
import { NaturalOccurrenceDescriptor } from './NaturalOccurrenceDescriptor';
import { Prediction } from './Prediction';
import { REACHRegistration } from './REACHRegistration';
import { RegulatoryStatus } from './RegulatoryStatus';
import { SafetyAssessmentMaterial } from './SafetyAssessmentMaterial';
import { SafetyComment } from './SafetyComment';
import { SafetyEUComment } from './SafetyEUComment';
import { Synonym } from './Synonym';
import { TypeBotanicalSubdivision } from './TypeBotanicalSubdivision';
import { TypeEssentialOil } from './TypeEssentialOil';
import { TypeNarrativeSummary } from './TypeNarrativeSummary';
import { TypeNaturalOccurrence } from './TypeNaturalOccurrence';
import { TypeTSCAClass } from './TypeTSCAClass';
import { MaterialUses } from './MaterialUses';
import { TypeMaterialUnit } from './TypeMaterialUnit';

import { Validator } from 'breeze-client';

/// <code-import> Place custom imports between <code-import> tags
import { UtilFns } from '../utils/common';
import { EntityTypeAnnotation, EntityPropertyAnnotation} from './EntityTypeAnnotation';
/// </code-import>

export class Material extends EntityBase {

   /// <code> Place custom code between <code> tags
   static getEntityTypeAnnotation(): EntityTypeAnnotation {
        const numberDashValidator = new Validator(
          'numberDash',
            (value: string) => !value || !!(value && value.match(/^[0-9-]*$/)),
            { messageTemplate: 'The %displayName% must contain only numbers and dashes.  '});

        const atLeastOneDashValidator = new Validator(
            'oneDash',
            (value: string) => !value || !!(value && value.indexOf('-') >= 0),
            { messageTemplate: 'The %displayName% must contain at least one dash.  ' });

        const nonZeroCodeValidator = new Validator(
            'NonZeroCodeValidator',
            (value: any) => value != 0,
            { messageTemplate: 'You must specify a %displayName%.  ' });

       const casLengthValidator = new Validator(
           'casLength',
           (value: string) => !value || (value.length >= 3 && value.length <= 50),
           { messageTemplate: 'The %displayName% length must be between 3 and 50. ' });

        const propAnnotations = [
            new EntityPropertyAnnotation('realCASNumber', {
                displayName: 'CAS Number',
                validators: [casLengthValidator, numberDashValidator, atLeastOneDashValidator]
            }),
            new EntityPropertyAnnotation('typeTSCAClassId', {
                displayName: 'Generic Class',
                validators: [nonZeroCodeValidator]
            }),
        ];

        return new EntityTypeAnnotation({
            validators: [],
            propertyAnnotations: propAnnotations
        });
    }

    // will be calculated later.
    base32SmileUrl: string;

    public eINECSELINCS() {
        let eiec: string = null;
        if (this.eINECSNumber) {
            eiec = this.eINECSNumber;
        }
        if (this.eCNNumber) {
            if (eiec) {
                eiec += '/' + this.eCNNumber;
            } else {
                eiec = this.eCNNumber;
            }
        }
        return eiec;
    }

    public mETIENCS() {
        return (this.typeTscaClass && this.typeTscaClass.genericClass == 'Essential Oils and Resins')
            ? 'Exempt' : this.mETI;
    }

    public eCInventoryEINECSELINCSNumbers() {

        const ecinventory: string = (this.eCInventoryNumber) ? 'EC# ' + this.eCInventoryNumber : '-';
        const einecs: string = (this.eINECSNumber) ? this.eINECSNumber : '-';
        const elincs: string = (this.eCNNumber) ? this.eCNNumber : '-';

        return (ecinventory + ' / ' + einecs + ' / ' + elincs);
    }

    public isCaptive() {
        // Modified per GitHub issue #448
        // return this.captiveCompany != null;
        return this.confidential;
    }

    public getCaptiveText() {

        return this.isCaptive() ? 'Captive - ' + this.captiveCompany : '';
    }

    public functionalUseExt() {
        return (this.functionalUse && this.functionalUse.length) ? this.functionalUse : 'None reported';
    }

    public naturalOccurrence() {
        const occurrence = (this.typeNaturalOccurrence && this.typeNaturalOccurrence.occurrenceDetail) || '';

        if (occurrence.indexOf('None') > -1) {
            return occurrence;
        } else {
            const pSynonym = this.principalSynonym();
            return (pSynonym ? pSynonym.synonymWordOrWebVersion() + ' ' : '') + occurrence;
        }
    }

    public addedToTransparencyListDate() {
        if (this.materialTransparencyLists == null || (this.materialTransparencyLists.length == 0)) {
            return null;
        }
        let lists = this.materialTransparencyLists.filter(m => m.removedFromList == null);
        lists = UtilFns.sort(lists, true, m => m.addedToList ? 'a_' : 'z_');
        return lists[0].addedToList;
    }

    public hasRegistration() {
        return this.eINECSRegistration || this.dSLRegistration || this.nDSLRegistration || this.tSCARegistration || this.reachRegistration;
    }

    public registrationText() {
        const text = `${this.dSLRegistration ? 'DSL' : ''} ${this.eCNRegistration ? 'ECN' : ''} ${this.eINECSRegistration ? 'EINECS' : ''} ${this.nDSLRegistration ? 'NDSL' : ''} ${this.reachRegistration ? 'REACH' : ''} ${this.tSCARegistration ? 'TSCA' : ''}`;
        return text.trim();
    }

    public get formattedRIFMId() {
        let result = this.monographNumber ? this.monographNumber.toString() : '-';
        const naturalsId = (this.typeBotanicalSubdivisionId || '') + (this.typeEssentialOilId || '');
        if (naturalsId) {
            result += '-' + naturalsId;
        }
        return result;
    }

    public get molecularWeightWithUnit() {
        const tmu = (this.typeMaterialUnitId != null) ? this.typeMaterialUnitId : '';
        return (this.molecularWeight != null) ? this.molecularWeight + ' ' + tmu : '';
    }

    public principalSynonym() {
        return this.synonyms ? this.synonyms.find(s => s.principal) : null;
    }

    public principalSynonymText() {
        const ps = this.principalSynonym();
        return (ps ? ps.synonymWordOrWebVersion() : '');
    }

    public principalNameSizeFactor(): number {

        const factor: number = (this.principalSynonymText() == null ? 1 : (70 / this.principalSynonymText().length));
        if (factor >= 1.0) {
            return 1;
        }

        return (factor < 0.70) ? 0.70 : factor;
    }

    public summaryDescription() {
        return this.principalSynonymText()
            + `  CAS No.: ${this.realCASNumber || ''}  RIFM ID: ${this.formattedRIFMId}  FEMA No.: ${this.fEMANumber}`;
    }

    constructor() {
        super();
        this.cASNumber = '';
        this.realCASNumber = '';
    }
   /// </code>

   // Generated code. Do not place code below this line.
   materialId: number;
   cASNumber: string;
   fEMANumber: number;
   realCASNumber: string;
   numericalCASNumber: number;
   alternateCASNumber: string;
   monographNumber: number;
   eINECSNumber: string;
   eCB: string;
   eCNNumber: string;
   structure: string;
   formula: string;
   molecularWeight: number;
   eINECSRegistration: boolean;
   dSLRegistration: boolean;
   nDSLRegistration: boolean;
   tSCARegistration: boolean;
   eCNRegistration: boolean;
   typeTSCAClassId: number;
   yearOfPublication: number;
   monographReference: string;
   typeNaturalOccurrenceId: number;
   occurrenceReference: string;
   firstUsed: number;
   typeNarrativeSummaryId: string;
   preparationReference: string;
   smiles: string;
   confidential: boolean;
   toxDataProhibited: boolean;
   captiveCompany: string;
   fFIDSFile: string;
   mETI: string;
   eCL: string;
   functionalUse: string;
   materialDescription: string;
   candidate: string;
   attv: boolean;
   lVE: boolean;
   solelyFunctional: boolean;
   materialNotes: string;
   preparation: string;
   componentSubstances: string;
   internalMaterialNotes: string;
   typeEssentialOilId: string;
   typeBotanicalSubdivisionId: string;
   speciesCAS: string;
   genericCAS: string;
   assessmentReportName: string;
   assessmentReportNameModified: Date;
   noSampleAvail: string;
   noConcSurvAvail: string;
   hasDraft: boolean;
   elsevierReportName: string;
   elsevierReportNameModified: Date;
   reachRegistration: boolean;
   sACategoryComment: string;
   eCInventoryNumber: string;
   typeMaterialUnitId: string;
   created: Date;
   createdUser: string;
   modified: Date;
   modifyUser: string;
   rowVersion: number;
   analyticalResults: AnalyticalResult[];
   dermalExposures: DermalExposure[];
   epiData: EPIData;
   ePISuites: EPISuite[];
   experimentalMaterials: ExperimentalMaterial[];
   foodStatuses: FoodStatus[];
   houseStatuses: HouseStatus[];
   materialAdditionalIdentifiers: MaterialAdditionalIdentifier[];
   materialAlertRules: MaterialAlertRule[];
   materialAssessmentCategories: MaterialAssessmentCategory[];
   materialConsumptions: MaterialConsumption[];
   materialExpertPanelSummaries: MaterialExpertPanelSummary[];
   materialFSAGs: MaterialFSAG[];
   materialHazardCodes: MaterialHazardCode[];
   materialPrecautionCodes: MaterialPrecautionCode[];
   materialRelations: MaterialRelation[];
   materialTransparencyLists: MaterialTransparencyList[];
   materialUnTransport: MaterialUNTransport;
   naturalOccurrenceDescriptors: NaturalOccurrenceDescriptor[];
   predictions: Prediction[];
   rEACHRegistrations: REACHRegistration[];
   regulatoryStatuses: RegulatoryStatus[];
   relatedToMaterials: MaterialRelation[];
   safetyAssessmentAdditionalMaterials: SafetyAssessmentMaterial[];
   safetyAssessmentMaterials: SafetyAssessmentMaterial[];
   safetyComments: SafetyComment[];
   safetyEUComments: SafetyEUComment[];
   specialLinks: SpecialLink[];
   synonyms: Synonym[];
   typeBotanicalSubdivision: TypeBotanicalSubdivision;
   typeEssentialOil: TypeEssentialOil;
   typeNarrativeSummary: TypeNarrativeSummary;
   typeNaturalOccurrence: TypeNaturalOccurrence;
   typeTscaClass: TypeTSCAClass;
   typeMaterialUnit: TypeMaterialUnit;
   uses: MaterialUses[];
   workflowMaterialRecordedActions: WorkflowMaterialRecordedAction[];
}


import { Component, OnInit } from '@angular/core';
import { StateMap } from '../../services/common';
import * as _ from 'lodash';


@Component({
    selector: 'exception-dialog',
    templateUrl: './exception-dialog.html'
})
export class ExceptionDialogComponent implements OnInit {
    _ele: any;
    _result: boolean;
    _errorHeader: string;
    _errorMessages: string;
    _addlErrorText: string;
    _title: string;

    constructor(private _stateMap: StateMap) {

    }


    ngOnInit() {
    }

    showModal(): Promise<boolean> {
        this._ele = this.getModalEle();
        this._errorHeader = 'Error from RIFM web app';
        this.updateErrorVars();
        // HACK because binding isn't working at this level - i.e. when launched from the global exception handler.
        // so using jquery to push the error message text instead.
        $('#errorMessages').text(() => this._errorMessages);
        $('#addlErrorText').text(() => this._addlErrorText);
        const p = new Promise((resolve) => {
            this._ele.modal({backdrop: 'static', keyboard: false}).on('hidden.bs.modal', () => {
                resolve(this._result);
            });
        });
        return <any>p;
    }

    getModalEle(): any {
        return $('#exception-dialog');
    }

    updateErrorVars() {
        if (this._stateMap.errors.length == 0) {
            return '';
        }
        const lastError = this._stateMap.errors[this._stateMap.errors.length - 1];
        const exception = lastError.exception;
        this._errorMessages = _.get(exception, 'rejection.message') || exception.message || '';
        this._addlErrorText = exception.toString();
    }

    onRespond(result: boolean) {
        this._result = result;
        this._ele.modal('hide');
    }

    onSendEmail() {
        // Neither of these approaches works with all browsers - email clients.
        // Best approach is to use a server based email messaging system.

        // Attempt 1:
        //  window.open('mailto:jayt@ideablade.com?subject=' +
        //     this._errorHeader +
        //     '&body=' +
        //     this._errorMessages);

        // Attempt 2:
        // var link = "mailto:jayt@ideablade.com"
        //      // + "?cc=myCCaddress@example.com"
        //      + "&subject=" + encodeURIComponent(this._errorHeader)
        //      + "&body=" + encodeURIComponent(this._errorMessages);

        // window.location.href = link;

    }
}


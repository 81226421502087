import {Component, Input, Output, OnInit, ViewChild, EventEmitter, Inject, forwardRef} from '@angular/core';
import * as _ from 'lodash';

import {EditorService, provideParent, StateMap, UnitOfWork} from '../../services/common';
import {EditManager, UtilFns} from '../../utils/common';
import { EditPropParent } from '../../controls/common';

import { ProtocolMaterialSelectorComponent } from './protocol-material-selector.component';
import { MaterialSelectorComponent } from '../material/material-selector.component';

import {
    ExperimentalMaterial,
    ExperimentalResult,
} from '../../entities/EntityModels';

import {MaterialIdentifiersItem} from '../../entities/projections/MaterialIdentifiersItem';
import { MaterialListItem} from '../../entities/projections/MaterialListItem';

import { ProtocolData, ProtocolEditorComponent } from './protocol-editor.component';

// tslint:disable-next-line:max-line-length
export enum CopyStatus { STARTCOPY, MATERIALSSELECTED, EXPERIMENTALMATERIALSCREATED, EXPERIMENTALMATERIALSAPPLIED, EXPERIMENTALMATERIALSSAVED, EXPERIMENTALRESULTSCREATED, EXPERIMENTALRESULTSAPPLIED, EXPERIMENTALRESULTSSAVED, COPYCOMPLETE }

@Component({
    selector: 'exp-material-from-template',
    templateUrl: './exp-material-from-template.html',
    providers: [provideParent(ExpMaterialFromTemplateComponent, EditPropParent)]
})
export class ExpMaterialFromTemplateComponent implements OnInit, EditPropParent {
    @ViewChild(MaterialSelectorComponent, { static: true }) _materialSelectorComponent: MaterialSelectorComponent;
    @ViewChild(ProtocolMaterialSelectorComponent, { static: true }) _protocolMaterialSelectorComponent: ProtocolMaterialSelectorComponent;

    @Input() expMaterial: ExperimentalMaterial;
    @Input() referenceId: number;
    @Output() stateChange = new EventEmitter<string>();

    public _edmExpMat: EditManager<ExperimentalMaterial>;
    public _edmExpRes: EditManager<ExperimentalResult>;

    public _selectedMaterialIdentifiersItems: MaterialIdentifiersItem[] = [];

    public _expMaterialWarning1 = '';
    public _expMaterialWarning2 = '';
    public _materialWarning = '';

    public _informationalMessage = '';
    public _userMessage = '';
    public _userMessage2 = '';

    _step1Message = 'Choose the Material(s) to be added as Experimental Materials or updated.';
    _step2Message = 'Select more materials or click the "Create Experimental Materials" button. The template data will be copied over for each material.';
    _step3Message = 'The Experimental Materials have been created. The next step is to add the Experimental Results by clicking the button below.';
    _step4Message = 'The creation and/or update of Experimental Materials with the data and Experimental Results from the template is complete.';

    _finalEMCountMessage = '';
    _finalERCountMessage = '';

    public _copyStatus: CopyStatus  = CopyStatus.STARTCOPY;

    public _isLoading = false;
    public _madeDataChanges = false;

    public _savingUnfinishedReferenceDuringCopy = false;
    public _sharedProtocolData: ProtocolData;

    // StateMap is used in html
    constructor(public _uow: UnitOfWork, public _stateMap: StateMap, public _editorService: EditorService,
                @Inject(forwardRef(() => ProtocolEditorComponent)) _parent: ProtocolEditorComponent) {
        this._sharedProtocolData = _parent._sharedProtocolData;
    }

    ngOnInit() {
        this._edmExpMat = this._editorService.createEditManager<ExperimentalMaterial>(this, 'Experimental Material');
        this._edmExpRes = this._editorService.createEditManager<ExperimentalResult>(this, 'Experimental Results');

        this._userMessage = this._step1Message;
    }

    getError(propName: string, id: string) {
        if (id != null) {
            const ix = parseInt(id, 10);
            if (ix != null) {
                return this.expMaterial.experimentalResults[ix].getErrorFor(propName);
            }
        } else {
            return this.expMaterial.getErrorFor(propName);
        }
    }

    fetchSelectedExperimentalMaterials(materialIds: number[]) {
        this._isLoading = true;

        this._edmExpMat.entities = [];

        const params = {
            biologicalDataId: this.expMaterial.biologicalDataId,
            materialIds: materialIds
        };

        return this._uow.fetch('BiologicalDatas/ExperimentalMaterialsByBioDataIdAndMaterialIds', params).then(em => {
            if (em != null) {
                this._edmExpMat.entities    = _.sortBy(em, s => s.material.realCASNumber);
            }
            this._isLoading             = false;
        });
    }

    onSelectingMaterial() {
        UtilFns.showModal(this._materialSelectorComponent, this).then(mli => {
            if (mli == null) { return; }

            this._materialWarning = '';

            if (mli.materialId == this.expMaterial.materialId ) {
                this._materialWarning = 'The Material you selected is the same as the selected template Material.';
                return;
            }

            if (this.checkForMaterialDuplicates(mli.materialId)) {
                this._materialWarning = 'The Material you selected is already in the selected Materials list';
                return;
            }

            if (this.checkForExperimentalMaterialDuplicates(mli.materialId)) {
                this._materialWarning = 'The Material you selected is already in the selected Experimental Materials list';
                return;
            }

            this._selectedMaterialIdentifiersItems.push(this.convertMaterialListItemToMaterialIdentifiersItem(mli));
            this._selectedMaterialIdentifiersItems = _.sortBy(this._selectedMaterialIdentifiersItems, m => m.synonymWordOrWebVersion);

            this._userMessage   = this._step2Message;
            this._copyStatus    = CopyStatus.MATERIALSSELECTED;
        });
    }

    // **************************************************************************
    // *Show dialog to select materials from existing Experimental Materials
    // **************************************************************************
    public onSelectingExperimentalMaterial() {
        if (this._protocolMaterialSelectorComponent == null) {
            return;
        }

        const excludedMaterialIds: number[] = [];
        excludedMaterialIds.push(this.expMaterial.materialId); // don't include the template Experimental Material

        // tslint:disable-next-line:max-line-length
        this._protocolMaterialSelectorComponent.loadExperimentalMaterials(this.expMaterial.biologicalDataId, this._sharedProtocolData.subReference, excludedMaterialIds, this._sharedProtocolData.expMaterialIdsForSorting);

        UtilFns.showModal(this._protocolMaterialSelectorComponent, this).then(r => {
            if (r == false) { return; }

            // fetch the selected existing Experimental Materials into the edm and check to see if any of them already have Exp. Results or details entered.
            const items = this._protocolMaterialSelectorComponent.getselectedMaterialIdentifiersItems();

            // make sure the selected exp material is not already in the new material list
            let duplicatesFound = false;
            let matchesTemplateMaterial = false;
            if (this._selectedMaterialIdentifiersItems != null && this._selectedMaterialIdentifiersItems.length > 0) {
                items.forEach((m) => {
                    if (this.checkForMaterialDuplicates(m.materialId)) {
                        duplicatesFound = true;
                    }

                    if (m.materialId == this.expMaterial.materialId) {
                        matchesTemplateMaterial = true;
                    }
                });
            }

            if (duplicatesFound) {
                this._expMaterialWarning1 = 'An Experimental Material you selected is already in the selected Materials list.';
                this._expMaterialWarning2 = '';
                return Promise.resolve();
            }

            if (matchesTemplateMaterial) {
                this._expMaterialWarning1 = 'An Experimental Material you selected is the same as the selected template Material.';
                this._expMaterialWarning2 = '';
                return Promise.resolve();
            }

            const materialIds = items.map(m => m.materialId);
            return this.fetchSelectedExperimentalMaterials(materialIds).then (() => { // load selected into edm

                this.validateExperimentalMaterial(); // check to see if any already have data entered, since the details would be overwritten

                this._userMessage   = this._step2Message;
                this._copyStatus    = CopyStatus.MATERIALSSELECTED;
            });
        });
    }

    convertMaterialListItemToMaterialIdentifiersItem(mli: MaterialListItem): MaterialIdentifiersItem {
        return {
            materialId:                 mli.materialId,
            realCASNumber:              mli.realCASNumber,
            fEMANumber:                 mli.fEMANumber,
            monographNumber:            mli.monographNumber,
            confidential:               mli.confidential,
            synonymWordOrWebVersion:    mli.synonymWordOrWebVersion,
            typeEssentialOilId:         mli.typeEssentialOilId,
            typeBotanicalSubdivisionId: mli.typeBotanicalSubdivisionId,
            formattedRifmId:            mli.formattedRifmId,
            experimentalMaterialId:     null,
            sortOrder:                  0
        };
    }

    removeMaterial(mi: MaterialIdentifiersItem) {
        _.remove(this._selectedMaterialIdentifiersItems, mi);

        const mcount = (this._selectedMaterialIdentifiersItems != null) ? this._selectedMaterialIdentifiersItems.length : 0;
        const emcount = (this._edmExpMat.entities != null) ? this._edmExpMat.entities.length : 0;

        if ((mcount + emcount) == 0) { // no selected materials so return to Step 1
            this._copyStatus    = CopyStatus.STARTCOPY;
            this._userMessage   = this._step1Message;
        }
    }

    removeExperimentalMaterial(em: ExperimentalMaterial) {
        const idx   = this._edmExpMat.entities.findIndex(m => m.experimentalMaterialId == em.experimentalMaterialId);
        this._edmExpMat.entities.splice(idx, 1);

        if (this._protocolMaterialSelectorComponent != null) {
            this._protocolMaterialSelectorComponent.removeFromSelectedList(em.materialId); // keep the two components in sync
        }

        const mcount = (this._selectedMaterialIdentifiersItems != null) ? this._selectedMaterialIdentifiersItems.length : 0;
        const emcount = (this._edmExpMat.entities != null) ? this._edmExpMat.entities.length : 0;

        if ((mcount + emcount) == 0) { // no selected materials so return to Step 1
            this._copyStatus    = CopyStatus.STARTCOPY;
            this._userMessage   = this._step1Message;

            this._expMaterialWarning1 = '';
            this._expMaterialWarning2 = '';
        } else {
            this.validateExperimentalMaterial();
        }
    }

    canCreateExperimentalMaterials() {
        return (this._copyStatus == CopyStatus.MATERIALSSELECTED);
    }

    canRemoveSelectedMaterials() {
        return (this._copyStatus == CopyStatus.MATERIALSSELECTED);
    }

    canSaveOrCancelExperimentalMaterials() {
        return this._copyStatus == CopyStatus.EXPERIMENTALMATERIALSAPPLIED;
    }

    canCreateExperimentalResults() {
        return (this._copyStatus == CopyStatus.EXPERIMENTALMATERIALSSAVED);
    }

    canSaveOrCancelExperimentalResults() {
        return (this._copyStatus == CopyStatus.EXPERIMENTALRESULTSAPPLIED);
    }

    canSelectMaterials() {
        return (this._copyStatus == CopyStatus.STARTCOPY || this._copyStatus == CopyStatus.MATERIALSSELECTED);
    }

    showStep1() {
        return (this._copyStatus == CopyStatus.STARTCOPY);
    }

    showStep2() {
        return (this._copyStatus == CopyStatus.MATERIALSSELECTED || this._copyStatus == CopyStatus.EXPERIMENTALMATERIALSCREATED || this._copyStatus == CopyStatus.EXPERIMENTALMATERIALSAPPLIED);
    }

    showStep3() {
        return (this._copyStatus == CopyStatus.EXPERIMENTALMATERIALSSAVED || this._copyStatus == CopyStatus.EXPERIMENTALRESULTSCREATED || this._copyStatus == CopyStatus.EXPERIMENTALRESULTSAPPLIED);
    }

    showStep4() {
        return (this._copyStatus == CopyStatus.EXPERIMENTALRESULTSSAVED);
    }

    onBack() {
        this._edmExpMat.entities = null;
        this._edmExpRes.entities = null;
        this._savingUnfinishedReferenceDuringCopy = false;
    }

    // notify parent component to save/commit the new experimental materials
    onSaveExperimentalMaterials() {
        this.stateChange.emit('savechanges');
    }

    onCancelExperimentalMaterials() {
        if (this._edmExpMat.entities != null && this._edmExpMat.entities.length > 0) {
            this._edmExpMat.entities.forEach( em => {
                this._edmExpMat.onSelect(em);

                if (this._edmExpMat.currentEntity.entityAspect.entityState.isAdded() || this._edmExpMat.currentEntity.entityAspect.entityState.isModified()) {
                    this._edmExpMat.currentEntity.entityAspect.rejectChanges();
                }
            });

            this._edmExpMat.clearErrors();
        }

        this._copyStatus = CopyStatus.MATERIALSSELECTED;
    }

    onSaveExperimentalResults() {
        this.stateChange.emit('savechanges');
    }

    onCancelExperimentalResults() {
        this._uow.rollback();
        this._edmExpRes.clearErrors();

        _.remove(this._edmExpRes.entities, er => er.entityAspect.entityState.isDetached());

        this._copyStatus = CopyStatus.EXPERIMENTALMATERIALSSAVED;
    }


    // October 2022
    // Change request to allow staff to add multiple experimental materials at once and to copy over all the existing experimental results of the template
    //  material for each added Experimental Material.
    // Additional change request to allow staff to copy Experimental Materials details to existing Experimental Materials assigned to the same Protocol
   createExperimentalMaterialsFromTemplate() {
        // if there are selected existing Exp. Materials, then copy over the details from the template entity
       //  they should already be in the edm, they are loaded after they are selected from the dialog
        if (this._edmExpMat.entities != null && this._edmExpMat.entities.length > 0) {
            this.copyDetailsToExperimentalMaterials();
        }

        // create new entities and push onto edm
        if (this._selectedMaterialIdentifiersItems != null && this._selectedMaterialIdentifiersItems.length > 0) {
            this.createNewExperimentalMaterials();
        }

        this._copyStatus = CopyStatus.EXPERIMENTALMATERIALSCREATED;

        // notify parent component to apply the new experimental materials
       this.stateChange.emit('applychanges');
   }

    createNewExperimentalMaterials() {
       this._selectedMaterialIdentifiersItems.forEach(m => {
           this.addExperimentalMaterials(m.materialId);
       });
   }

   copyDetailsToExperimentalMaterials() {
        if (this._edmExpMat.entities != null && this._edmExpMat.entities.length > 0) {
           this._edmExpMat.entities.forEach( em => {
               this._edmExpMat.onSelect(em);
               this._edmExpMat.currentEntity.sampleText		            = this.expMaterial.sampleText;
               this._edmExpMat.currentEntity.typeToxicEffectId		    = this.expMaterial.typeToxicEffectId;
               this._edmExpMat.currentEntity.calculatedResult		    = this.expMaterial.calculatedResult;
               this._edmExpMat.currentEntity.experimentalDetails	    = this.expMaterial.experimentalDetails;
               this._edmExpMat.currentEntity.experimentalSummary	    = this.expMaterial.experimentalSummary;
               this._edmExpMat.currentEntity.typeStudyMixtureId	        = this.expMaterial.typeStudyMixtureId;
               this._edmExpMat.currentEntity.typeExperimentVehicleId	= this.expMaterial.typeExperimentVehicleId;
           });
       } else {
           this._expMaterialWarning1 = 'Unexpected error. Please contact the database team.';
       }
  }

    public addExperimentalMaterials(materialId: number) {

        if (this._edmExpMat.entities == null) { this._edmExpMat.entities = []; }

        const newEM = this._uow.experimentalMaterialFactory.create();

        newEM.biologicalDataId          = this.expMaterial.biologicalDataId;
        newEM.materialId		        = materialId;
        newEM.sampleText		        = this.expMaterial.sampleText;
        newEM.typeToxicEffectId		    = this.expMaterial.typeToxicEffectId;
        newEM.calculatedResult		    = this.expMaterial.calculatedResult;
        newEM.fFDSShortVersion		    = this.expMaterial.fFDSShortVersion;
        newEM.fFDSSequenceNo		    = this.expMaterial.fFDSSequenceNo;
        newEM.fFDSModified		        = this.expMaterial.fFDSModified;
        newEM.experimentalDetails	    = this.expMaterial.experimentalDetails;
        newEM.experimentalSummary	    = this.expMaterial.experimentalSummary;
        newEM.fFDSStatement		        = this.expMaterial.fFDSStatement;
        newEM.typeStudyMixtureId	    = this.expMaterial.typeStudyMixtureId;
        newEM.typeExperimentVehicleId	= this.expMaterial.typeExperimentVehicleId;

        this._edmExpMat.entities.push(newEM);
    }

    // add a copy of each experimental results assigned to the template experimental material to the newly created experimental material.
    createExperimentalResultsFromTemplate() {
        this._edmExpMat.entities.forEach(em => {
            this.expMaterial.experimentalResults.forEach(er => {
                this.addExperimentalResults(em.experimentalMaterialId, er);
            });
        });

        this._copyStatus = CopyStatus.EXPERIMENTALRESULTSCREATED;

        this.stateChange.emit('applychanges');
    }

    addExperimentalResults(expMaterialId: number, er: ExperimentalResult) {

        if (this._edmExpRes.entities == null) { this._edmExpRes.entities = []; }

        const newER = this._uow.experimentalResultFactory.create();

        newER.experimentalMaterialId	=   expMaterialId;
        newER.compOp			        =	er.compOp;
        newER.dose				        =	er.dose;
        newER.dPMperLymphNode		    =	er.dPMperLymphNode;
        newER.resultNote			    =	er.resultNote;
        newER.stimulationIndex		    =	er.stimulationIndex;
        newER.typeExperimentVehicleId	=	er.typeExperimentVehicleId;
        newER.typeUnitId			    =	er.typeUnitId;

        this._edmExpRes.entities.push(newER);

        if (er.experimentalToxicEffects != null) {
            this._edmExpRes.onSelect(newER);
            er.experimentalToxicEffects.forEach(te => {
                this.addExperimentalToxicEffect(this._edmExpRes.currentEntity, te.typeToxicEffectId);
            });
        }
    }

    addExperimentalToxicEffect(er: ExperimentalResult, typeToxicEffectId: string) {
        if (typeToxicEffectId == null || typeToxicEffectId == '') {
            return;
        }

        const params = {
            experimentalResultId: er.experimentalResultId,
            typeToxicEffectId: typeToxicEffectId
        };
        const expToxicEffect = this._uow.experimentalToxicEffectFactory.create(params);
    }

    experimentalMaterialsAddedCount() {
        return (this._edmExpMat == null || this._edmExpMat.entities == null) ? 0 : this._edmExpMat.entities.length;
    }

    experimentalResultsAddedCount() {
        return (this._edmExpRes == null || this._edmExpRes.entities == null) ? 0 : this._edmExpRes.entities.length;
    }

    experimentalResultsTemplateCount() {
        return (this.expMaterial == null || this.expMaterial.experimentalResults == null) ? 0 : this.expMaterial.experimentalResults.length;
    }

    // ******************************************************************
    // called from Parent component after receiving event notification
    // ******************************************************************
    applyComplete() {
        if (this._copyStatus == CopyStatus.EXPERIMENTALMATERIALSCREATED) {
            this._copyStatus = CopyStatus.EXPERIMENTALMATERIALSAPPLIED;
        } else if (this._copyStatus == CopyStatus.EXPERIMENTALRESULTSCREATED) {
            this._copyStatus = CopyStatus.EXPERIMENTALRESULTSAPPLIED;
        }
    }

    saveComplete() {
        this._madeDataChanges = true;

        this._userMessage   = '';
        this._finalEMCountMessage = '';
        this._finalERCountMessage = '';

        if (this._copyStatus == CopyStatus.EXPERIMENTALMATERIALSAPPLIED) {
            this._isLoading = true;

            const materialIds = this.allSelectedMaterialIds();

            return this.fetchSelectedExperimentalMaterials(materialIds).then ( () => {
                if (this.expMaterial.experimentalResults == null || this.expMaterial.experimentalResults.length == 0) {
                    this._copyStatus    = CopyStatus.COPYCOMPLETE;
                    this._informationalMessage   = 'There are no Experimental Results assigned to the template Experimental Material so the copy process is complete.';
                    this._informationalMessage   = this._informationalMessage + ' There were (' + this.experimentalMaterialsAddedCount() + ') Experimental Materials added or updated.';
                } else {
                    this._copyStatus    = CopyStatus.EXPERIMENTALMATERIALSSAVED;
                    this._userMessage   = this._step3Message;
                }
                this._isLoading     = false;
            });
        } else if (this._copyStatus == CopyStatus.EXPERIMENTALRESULTSAPPLIED) {
            this._copyStatus    = CopyStatus.EXPERIMENTALRESULTSSAVED;

            this._userMessage           = this._step4Message;
            this._finalEMCountMessage   = 'There were (' + this.experimentalMaterialsAddedCount() + ') Experimental Materials added or updated.';
            this._finalERCountMessage   = 'There were (' + this.experimentalResultsAddedCount() + ') Experimental Results added, (' + this.experimentalResultsTemplateCount() + ') for each added Experimental Material.';
        }
    }

    allSelectedMaterialIds() {
        return this._edmExpMat.entities.map(m => m.materialId);
    }

    public get viewableExperimentalMaterials(): ExperimentalMaterial[] {
        if (this._edmExpMat.entities == null || this._edmExpMat.entities.length == 0) { return []; }

        return this._edmExpMat.entities.filter(em => em.experimentalMaterialId > 0);
    }

    // *************************************************************************************************
    // Validation
    // *************************************************************************************************
    // check to see if selected Experimental Materials already have details or Experimental Materials
    validateExperimentalMaterial() {

        this._expMaterialWarning1 = '';
        this._expMaterialWarning2 = '';

        if (this._edmExpMat.entities.some(em => em.experimentalResults != null && em.experimentalResults.length > 0)) {
            this._expMaterialWarning1 = 'At least one of the selected Experimental Materials already has an Experimental Results.';
        }

        if (this._edmExpMat.entities.some(em => this.isValidString(em.sampleText)
            || em.typeToxicEffectId != null
            || this.isValidString(em.calculatedResult)
            || this.isValidString(em.experimentalDetails)
            || this.isValidString(em.experimentalSummary)
            || em.typeStudyMixtureId != 'Not Evaluated'
            || em.typeExperimentVehicleId.toLowerCase() != 'none')) {

            this._expMaterialWarning2 = 'At least one of the selected Experimental Materials has details entered.';
        }

        return;
    }

    // check to see if the material was selected already.
    checkForMaterialDuplicates(materialId: number): boolean {
        if (this._selectedMaterialIdentifiersItems == null || this._selectedMaterialIdentifiersItems.length == 0) {
            return false;
        }

        if (this._selectedMaterialIdentifiersItems.some(m => m.materialId == materialId)) {
            return true;
        }
        return false;
    }

    checkForExperimentalMaterialDuplicates(materialId: number): boolean {
        if (this._edmExpMat.entities == null || this._edmExpMat.entities.length == 0) {
            return false;
        }

        if (this._edmExpMat.entities.some(m => m.materialId == materialId)) {
            return true;
        }
        return false;
    }

    isValidString(teststring: string) {
        if (!teststring) { return false; }
        return (teststring.trim().length > 0);
    }

    askUserToConfirm(): Promise<boolean> {
        return this._stateMap.yesNoComponent.showModal('At least one of the Experimental Materials you selected already has data.',
            'Continue with the update?').then(ok => {
            if (ok) {
                return true;
            } else {
                return false;
            }
        });
    }
}

import { Location } from '@angular/common';
import { Component, Input, Inject, forwardRef, OnInit } from '@angular/core';

import { Router, ActivatedRoute } from '@angular/router';

import { UnitOfWork, StateMap, UserManager, ErrorLogger } from '../../services/common';
import { UtilFns, IEditHost, EditManager } from '../../utils/common';
import { ExposureSurvey } from '../../entities/EntityModels';
import { ExposureAdditionalMaterialSurvey, ExposureAdditionalMaterialSurveyMeasure } from '../../entities/EntityModels';
import { SelectableEntity } from '../../entities/projections/SelectableEntity';
import { ExposureSurveyListsComponent } from './exposure-survey-lists.component';
import * as _ from 'lodash';

@Component({
    selector: 'exposure-additional-material-surveys',
    templateUrl: './exposure-additional-material-surveys.html',
})

export class ExposureAdditionalMaterialSurveysComponent implements IEditHost<ExposureAdditionalMaterialSurvey>, OnInit {
    @Input() exposureSurvey: ExposureSurvey;

    public _isLoading = false;

    public _edm: EditManager<ExposureAdditionalMaterialSurvey>;
    public _edmMeasures: EditManager<ExposureAdditionalMaterialSurveyMeasure>;
    public _adding: boolean;

    public _selectedExposureAdditionalMaterialSurvey: ExposureAdditionalMaterialSurvey;
    public _exposureAdditionalMaterialSurveys: ExposureAdditionalMaterialSurvey[] = [];
    public _exposureAdditionalMaterialSurveysEx: SelectableEntity<ExposureAdditionalMaterialSurvey>[] = [];

    public _validationMessage: string;

    constructor(@Inject(forwardRef(() => ExposureSurveyListsComponent)) public _parent: ExposureSurveyListsComponent,
                private _uow: UnitOfWork, private _stateMap: StateMap, private _userManager: UserManager, private _router: Router,
                public _route: ActivatedRoute, public _location: Location, public _errorLogger: ErrorLogger) {

    }

    ngOnInit() {

        this._edm = new EditManager<ExposureAdditionalMaterialSurvey>(this, this._uow, this._stateMap, 'Exposure Survey External Materials');
        this._edmMeasures = new EditManager<ExposureAdditionalMaterialSurveyMeasure>(this, this._uow, this._stateMap, 'Exposure Survey External Material Measures');

        this.fetch();
    }

    fetch() {
        this._isLoading = true;

        const params = { exposureSurveyId: this.exposureSurvey.exposureSurveyId };
        this._uow.fetch('ExposureSurveys/ExposureAdditionalMaterialSurveyByExposureSurveyId', params)
            .then(e => {
                this._exposureAdditionalMaterialSurveys = e;
                this._edm.entities = this._exposureAdditionalMaterialSurveys;

                this.formatSelectableEntities();

                this._isLoading = false;
            });
    }

    formatSelectableEntities() {
        this._exposureAdditionalMaterialSurveysEx = [];
        if (this._edm.entities == null || this._edm.entities.length < 1) {
            return;
        }

        _.clone(this._edm.entities).forEach(element => {
            this._exposureAdditionalMaterialSurveysEx.push(new SelectableEntity<ExposureAdditionalMaterialSurvey>(element, '#FFFFFF'));
        });
    }

    filterToExposureAdditionalMaterialSurveyById(exposureAdditionalMaterialSurveyId: number): ExposureAdditionalMaterialSurvey {
        if (this._exposureAdditionalMaterialSurveys == null || this._exposureAdditionalMaterialSurveys.length < 1) {
            return null;
        }

        const additionalMaterial = this._exposureAdditionalMaterialSurveys.filter(a => a.exposureAdditionalMaterialSurveyId == exposureAdditionalMaterialSurveyId);
        if (additionalMaterial != null && additionalMaterial.length > 0) {
            return additionalMaterial[0];
        }
    }

    hasData() {
        if (this._edm.entities == null || this._edm.entities.length < 1) {
            return false;
        }

        if (this._exposureAdditionalMaterialSurveysEx == null || this._exposureAdditionalMaterialSurveysEx.length < 1) {
            return false;
        }
        return true;
    }

    isStaff() {
        return this._stateMap.currentUser.isStaff;
    }

    get utilFns() {
        return UtilFns;
    }

    canAddToMaterialList() {
        if (this._edm.editing) {
            return false;
        }

        // Make sure the Exposure Survey status allows materials to be added to the list
        if (this.exposureSurvey == null) {
            return false;
        }

        return (this.exposureSurvey.typeExposureSurveyStatusId == 'Open for Materials');
    }

    // edit manager overrides
    canApply() {

        if (this._edm.editing) {
            return (this._uow.hasChanges() && !this._edm.currentEntity.entityAspect.hasValidationErrors);
        }
        return false;
    }

    canCancel() {
        return this._edm.hasEdits();
    }

    canDeactivate() {
        return !this.hasEdits();
    }

    hasEdits() {
        return this._uow.hasChanges();
    }

    canSave() {
        if (this._edm.editing) {
            return false;
        }
        if (!this._edm.hasEdits()) {
            return false;
        }
        if (!this._edm.validateBeforeSave()) {
            return false;
        }
        return true;
    }

    canShowBack() {
        if (!this._edm.editing) {
            return false;
        }
        return !this._uow.hasChanges();
    }

    onAdd() {
        if (!this._edm.validateCurrent()) {
            return;
        }

        this._adding = true;
        const e = this._uow.exposureAdditionalMaterialSurveyFactory.create();

        e.exposureSurveyId = this.exposureSurvey.exposureSurveyId;
        e.typeExposureSurveyResponseId = 2;

        this._edm.entities.push(e);
        this._edm.onSelect(e);
    }

    onApply() {
        this._validationMessage = '';
        if (this._edm.editing) {

            if (this._edm.currentEntity.exposureAdditionalMaterial == null) {
                this._validationMessage = 'Please select a material or enter data for a new one.';
                return;
            }

            if (this.isDuplicateAdditionalMaterialEntry(this._edm.currentEntity.exposureAdditionalMaterialId, this._edm.currentEntity.exposureAdditionalMaterial.cASNumber)) {

                this._validationMessage = 'This external material has already been added to the selected exposure survey.';
                return;
            }

            this._edm.onApplyCore();
            this._adding = false;


            this.formatSelectableEntities();
        }

        return true;
    }

    onBack() {
        this._adding = false;
        this._edm.editing = false;
    }

    onCancel() {
        this._validationMessage = '';

        this._adding = false;
        this._edm.onCancelCore();
        this._edm.entities = null;

        this.fetch();
    }

    onEdit(amx: SelectableEntity<ExposureAdditionalMaterialSurvey>) {

        if (amx == null) {
            return;
        }

        this._selectedExposureAdditionalMaterialSurvey = this.filterToExposureAdditionalMaterialSurveyById(amx.data.exposureAdditionalMaterialSurveyId);

        if (this._selectedExposureAdditionalMaterialSurvey == null) {
            return;
        }

        this._edm.onSelect(this._selectedExposureAdditionalMaterialSurvey);
    }

    onRemove(amx: SelectableEntity<ExposureAdditionalMaterialSurvey>) {
        if (amx == null) {
            return;
        }

        this._selectedExposureAdditionalMaterialSurvey = this.filterToExposureAdditionalMaterialSurveyById(amx.data.exposureAdditionalMaterialSurveyId);

        if (this._selectedExposureAdditionalMaterialSurvey == null) {
            this._validationMessage = 'Error occurred, could not remove the selected external material from the list.';
            return;
        }

        if (this._selectedExposureAdditionalMaterialSurvey.entityAspect.entityState.isAdded()) {
            this._selectedExposureAdditionalMaterialSurvey.entityAspect.rejectChanges();
        } else {
            this._selectedExposureAdditionalMaterialSurvey.entityAspect.setDeleted();
            this.removeExposureAdditionalMaterialSurvey();
        }

        _.remove(this._edm.entities, this._selectedExposureAdditionalMaterialSurvey);

        this.formatSelectableEntities();
    }

    removeExposureAdditionalMaterialSurvey() {
        // Delete the measures associated with removed exposure additional material survey record.
        const params = {
            exposureAdditionalMaterialId: this._selectedExposureAdditionalMaterialSurvey.exposureAdditionalMaterialId,
            exposureSurveyId: this._selectedExposureAdditionalMaterialSurvey.exposureSurveyId
        };

        this._uow.fetchTyped('ExposureSurveys/FetchExposureAdditionalMaterialSurveyMeasures', ExposureAdditionalMaterialSurveyMeasure, params).then(m => {
            this._edmMeasures.entities = m;

            if (this._edmMeasures.entities != null && this._edmMeasures.entities.length > 0) {
                this._edmMeasures.entities.forEach(r => r.entityAspect.setDeleted());
            }
        });
    }

    removeAdditionalMaterialMeasures() {
        // Delete the measures associated with removed additional material survey record.
        const params = {
            additionalMaterialId: this._selectedExposureAdditionalMaterialSurvey.exposureAdditionalMaterialId,
            exposureSurveyId: this._selectedExposureAdditionalMaterialSurvey.exposureSurveyId
        };

        this._uow.fetchTyped('ExposureSurveys/FetchExposureAdditionalMaterialSurveyMeasures', ExposureAdditionalMaterialSurveyMeasure, params).then(m => {
            this._edmMeasures.entities = m;

            if (this._edmMeasures.entities != null && this._edmMeasures.entities.length > 0) {
                this._edmMeasures.entities.forEach(r => r.entityAspect.setDeleted());
            }
        });
    }

    onSave() {
        if (this._edm.hasEdits()) {
            this._edm.onSaveCore().then(() => {

                this._adding = false;
                this._edm.editing = false;

                this._edmMeasures.editing = false;

                this.fetch();
            });
        }
    }

    onShowAdditionalMaterialMeasures(amx: SelectableEntity<ExposureAdditionalMaterialSurvey>) {
        if (amx == null) {
            return;
        }

        this._selectedExposureAdditionalMaterialSurvey = this.filterToExposureAdditionalMaterialSurveyById(amx.data.exposureAdditionalMaterialSurveyId);

        if (this._selectedExposureAdditionalMaterialSurvey == null) {
            return;
        }

        this._router.navigate(['../record-exposure-survey-measures', {
            exposureAdditionalMaterialId: this._selectedExposureAdditionalMaterialSurvey.exposureAdditionalMaterialId,
            exposuresurveyid: this.exposureSurvey.exposureSurveyId
        }], { relativeTo: this._parent._route });
    }

    isDuplicateAdditionalMaterialEntry(exposureAdditionalMaterialId: number, casNumber: string): boolean {
        if (this._edm.entities == null) {
            return false;
        }

        const mat = this._edm.entities.filter(m => m.exposureAdditionalMaterialId == exposureAdditionalMaterialId);
        if (mat != null && mat.length > 1) {
            return true;
        }

        const casMat = this._edm.entities.filter(m => m.exposureAdditionalMaterial.cASNumber == casNumber);
        if (casMat != null && casMat.length > 1) {
            return true;
        }

        return false;
    }
}

import { Component, OnInit, Input} from '@angular/core';
import { Location } from '@angular/common';

import { UnitOfWork, UserManager, StateMap } from '../../services/common';
import { UtilFns, LocationFns} from '../../utils/common';
import { PageState } from '../../controls/common';

@Component({
    templateUrl: './system-requirements.html'
})
export class SystemRequirementsComponent implements OnInit   {
    constructor(public _uow: UnitOfWork, public _location: Location, public _stateMap: StateMap ) {
    }

    ngOnInit() {
    }
}

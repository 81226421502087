<div style="margin-bottom: 10px">
    <div class="inline" style="font-size: 18px;" *ngIf="_edm.editing">{{data.adding ? "Adding a Reference" : "Editing a Reference" }}</div>
    <div *ngIf="(!_edm.editing) || _edm.canShowBack()" class="flex-container background-transparent">
        <div class="flex-item">
            <button *ngIf="canAdd()" type="button" class="inline btn btn-sm btn-primary" (click)="_edm.onAdd()">Add a Reference</button>
        </div>
        <div class="flex-item">
            <button type="button" class="inline btn btn-sm btn-primary" (click)="onSelectReference()"> {{ "Edit " + (_edm.editing ? "Another " : "a ") + "Reference" }}</button>
        </div>
        <div class="flex-item">
            <button *ngIf="!isFEMA()" type="button" class="inline btn btn-sm btn-primary" (click)="onSelectAssignedReference()">Select Assigned References</button>
        </div>
        <div *ngIf="_hasReferenceDocumentFile" class="flex-item">
            <button type="button" class="inline btn btn-view-reference-document" style="width: 205px; font-size: 12px;"  *ngIf="canDownloadReferenceDocument()" (click)="showReferenceDocument()">View  or Download Document</button>
        </div>

        <div *ngIf="data.entity != null" class="flex-item">
            <button *ngIf="canEditReferenceDocument()" type="button" class="inline btn btn-edit-rifmdocument" style="width: 190px;" (click)="onNavToReferenceDocument()">Edit Reference Document</button>
        </div>

    </div>
</div>
<div class="row">
    <i *ngIf="_pageState.isLoading" class="fa fa-circle-o-notch fa-spin" style="font-size:38px; color: #337ab7; margin-left: 24px;"></i>
</div>
<div *ngIf="_edm.editing && isFEMA() == false">
    <editor-header [edm]="_edm" [customButtons]="true">
        <span customButtons>
            <span *ngIf="!data.inSubEditor">
                <button type="button" class="btn btn-sm btn-primary" [disabled]="!_edm.canSave()" (click)="_edm.onSave()">Save</button>
                <button *ngIf="!data.adding" type="button" class="btn btn-sm btn-primary" [disabled]="!_edm.canDelete()" (click)="_edm.onDelete()">Delete</button>
                <button *ngIf="canShowBack()" type="button" class="btn btn-sm btn-primary" (click)="_edm.onBack()">Back</button>
                <button *ngIf="!canShowBack()" type="button" class="btn btn-sm btn-primary" [disabled]="!_edm.canCancel()" (click)="_edm.onCancel()">Cancel</button>
                <button *ngIf="!data.adding" type="button" class="btn btn-sm btn-primary" (click)="onMore()">More...</button>
                <span *ngIf="referenceFinishedStatesHaveChanged()" class="workflow-automatic-finish-label">{{_finishedMessage}}</span>
            </span>
        </span>
    </editor-header>
    <div class="refnav-container" *ngIf="!data.inSubEditor">
        <button class="btn-refnav" *ngIf="canShowPrev()" type="button" (click)="onPrev()">
            <img src="assets/images/PreviousTransparent.png" class="image-refnav" />
        </button>
        <span *ngIf="canShowNext() || canShowPrev()" class="bold text-refnav">{{data.entity.referenceId}}</span>
        <button class="btn-refnav" *ngIf="canShowNext()" type="button" (click)="onNext()">
            <img src="assets/images/NextTransparent.png" class="image-refnav" />
        </button>
    </div>
</div>

<div *ngIf="_edm.editing && isFEMA() == false" >
    <tab-container>
        <ng-template tab-pane title="Reference Base">
            <reference-editor-base></reference-editor-base>
        </ng-template>
        <ng-template tab-pane [canDeactivate]="canDeactivateEditor()" title="Author References">
            <reference-editor-author></reference-editor-author>
        </ng-template>
        <ng-template tab-pane title="Related References">
            <reference-editor-relations></reference-editor-relations>
        </ng-template>
        <ng-template tab-pane [canDeactivate]="canDeactivateEditor()"  title="Special Links">
            <reference-editor-special></reference-editor-special>
        </ng-template>
        <ng-template tab-pane title="Linked Physical Properties">
            <reference-analytical-results></reference-analytical-results>
        </ng-template>

    </tab-container>
</div>

<reference-selector></reference-selector>
<workflow-reference-assignments *ngIf="!data.adding"></workflow-reference-assignments>
<show-and-download-pdf [showUVSFile]="false"></show-and-download-pdf>

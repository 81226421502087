<div class="indent-top-sm"></div>
<editor-header [edm]="_edm" [femaRestricted]="true"></editor-header>

<div *ngIf="_edm.hasEntities()" [attr.data-hidden]="_edm.editing">
	<h4 header class="bold">PADI <i>(Possible Average Daily Intake)</i>: {{ _stateMap.utilFns.formatNumber(_PADI) }} mg/person/day</h4>

	<div style="width: 60%">
		<dx-data-grid [dataSource]="_edm.entities"
		              [columnAutoWidth]="true"
		              [showRowLines]="true"
		              [rowAlternationEnabled]="true"
		              [showBorders]="true"
		              [hoverStateEnabled]="true"
		              [allowColumnReordering]="true"
		              [allowColumnResizing]="true"
		              (onSelectionChanged)="onSelectionChanged($event)">
			<dxo-paging [enabled]="false"></dxo-paging>
			<dxo-sorting mode="single"></dxo-sorting>
			<dxo-selection mode="single"></dxo-selection>

			<dxi-column caption="Average Usual (ppm)" dataField="averageUsual" alignment="left">
				<dxo-format
						type="fixedPoint"
						[precision]="2"></dxo-format>
			</dxi-column>
			<dxi-column caption="Average Maximum (ppm)" dataField="averageMaximum" alignment="left">
				<dxo-format
						type="fixedPoint"
						[precision]="2"></dxo-format>
			</dxi-column>
			<dxi-column caption="Mean Daily Consumption (gms)" dataField="typeFoodCategory.meanDailyConsumption" alignment="left">
				<dxo-format
						type="fixedPoint"
						[precision]="2"></dxo-format>
			</dxi-column>
			<dxi-column caption="Food Description" dataField="typeFoodCategory.foodDescription"></dxi-column>
		</dx-data-grid>
	</div>
</div>

<div *ngIf="_edm.editing">
	<flavor-uses-item #self [entity]="_edm.currentEntity"></flavor-uses-item>
</div>

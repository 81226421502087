<tab-container>
    <ng-template tab-pane [canDeactivate]="canDeactivate()" title="Flavor status" [hasData]="_stateMap.hasMaterialDataFor('flavor-statuses')" active="true">
        <flavor-statuses [materialId]="material.materialId" ></flavor-statuses>
    </ng-template>
    <ng-template tab-pane [canDeactivate]="canDeactivate()" title="Uses" [hasData]="_stateMap.hasMaterialDataFor('flavor-uses')" >
        <flavor-uses [materialId]="material.materialId"  ></flavor-uses>
    </ng-template>
    <ng-template tab-pane title="Annual Volume Flavor" [hasData]="_stateMap.hasMaterialDataFor('flavor-volumes')" >
        <flavor-volumes [materialId]="material.materialId"></flavor-volumes>
    </ng-template>
</tab-container>

<div *ngIf="entity" class="form-horizontal indent-top-sm">

    <edit-prop label="Cas No." szs="2,2">
        <span class="form-control-static">{{entity.exposureAdditionalMaterial?.cASNumber}}</span>
    </edit-prop>

    <edit-prop label="External Material Name" szs="2,7">
        <span class="form-control-static">{{entity.exposureAdditionalMaterial?.materialName}}</span>
    </edit-prop>

    <div *ngIf="isAdded()">
        <edit-prop label="Measure Name" prop="typeExposureMeasureId" szs="2,8">
            <select class="form-control" *ngIf="_typeExposureMeasures" [ngModel]="entity.typeExposureMeasureId" (change)="onSelectMeasure($event.target.value)">
                <option *ngFor="let t of _typeExposureMeasures" [value]="t.typeExposureMeasureId">{{t.measureDescription}}</option>
            </select>
        </edit-prop>
    </div>

    <div *ngIf="!isAdded()">
        <edit-prop label="Measure Name" prop="typeExposureMeasureId" szs="2,8">
            <span class="form-control-static">{{entity.typeExposureMeasure.measureDescription}}</span>
        </edit-prop>
    </div>

    <div *ngIf="_currentTypeExposureMeasure">
        <edit-prop label="Measure Value Type" prop="typeExposureMeasureValueId" szs="2,3">
            <span class="form-control-static">{{_currentTypeExposureMeasure.typeExposureMeasureValue?.measureValueType}}</span>
        </edit-prop>
    </div>

    <div *ngIf="hasQualitativeValue()">
        <edit-prop label="Measure Value" prop="measureQualitativeResult" szs="2,5">
            <input class="form-control" type="text" [(ngModel)]="entity.measureQualitativeResult">
        </edit-prop>
    </div>

    <div *ngIf="hasQuantitativeValue()">
        <edit-prop label="Measure Value" prop="measureQuantitativeResult" szs="2,5">
            <input class="form-control" type="text" [(ngModel)]="entity.measureQuantitativeResult">
        </edit-prop>
    </div>

    <div style="display: inline-block; width: 100%;">
        <edit-prop label="Notes" prop="measureNotes" szs="2,5">
            <textarea class="form-control" rows=3 [(ngModel)]="entity.measureNotes"></textarea>
        </edit-prop>
    </div>

    <edit-prop isChild label="Creme Version" szs="2,2">
        <select class="form-control" *ngIf="_typeCremeVersions" [ngModel]="entity.typeCremeVersionId" (change)="entity.typeCremeVersionId=$event.target.value">
            <option *ngFor="let v of _typeCremeVersions" [value]="v.typeCremeVersionId">{{v.cremeVersion}}</option>
        </select>
    </edit-prop>

</div>

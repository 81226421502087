<div style="margin-top: 15px"></div>
<div *ngIf="reference && !_data.inSubEditor" class="form-horizontal">

    <div *ngIf="!_data.isEditingRef" class="form-group">
        <edit-prop isChild label="Reference No." prop="referenceId" szs="1,3">
            <input *ngIf="reference.referenceId > 0" class="form-control" disabled="true" type="number" [ngModel]="reference.referenceId">
            <label *ngIf="reference.referenceId < 0" class="form-control">... Determined on save ...</label>
        </edit-prop>
        <div class="flex-container background-transparent" style="max-width: 852px;">
            <div class="flex-item" *ngIf="isOnHold">
                <button class="btn btn-primary btn-sm btn-warning on-hold-button" type="button" (click)="removeHold()">Remove Hold</button>
                <span class="bold">Hold Reason:</span>
                <span>{{holdReason}}</span>
            </div>
            <div class="flex-item">
                <button class="btn btn-primary btn-sm reference-finish-button" *ngIf="!reference.finished" type="button" (click)="finishReference()">Finish</button>
            </div>
            <div class="flex-item">
                <span *ngIf="reference.finished" class="workflow-status-text">
                    Finished
                </span>
            </div>
            <div class="flex-item">
                <button *ngIf="isProofable()" type="button" class="btn btn-primary btn-sm" (click)="proofAbstract()">Proof Reference</button>
                <span *ngIf="abstractProofed()" class="workflow-status-text">
                    Reference Proofed
                </span>
            </div>
        </div>
        <!--Editing ref number removed per https://github.com/rifm/RIFMWeb/issues/341-->
        <!--<span class="col-md-2">
            <button *ngIf="_data.adding" type="button" class="btn btn-sm btn-success" (click)="onEditRefId()">Edit Reference No.</button>
        </span>-->
        <!--<edit-prop isChild label="Finished" szs="3,1">
            <input class="form-control" type="checkbox" [(ngModel)]="reference.finished">
        </edit-prop>-->
    </div>

    <div *ngIf="_data.isEditingRef" class="form-group">
        <edit-prop isChild label="Reference No." prop="referenceId" szs="1,3">
            <input class="form-control" type="number" disabled [(ngModel)]="reference.referenceId">
        </edit-prop>
        <!--Editing ref number removed per https://github.com/rifm/RIFMWeb/issues/341-->
        <!--<edit-prop isChild label="Reference No." prop="testRefId" szs="1,3">
            <input class="form-control" (change)="onCheckRefId($event.target.value)" type="number" [(ngModel)]="_data.testRefId">
        </edit-prop>
        <span class="col-md-2">
            <button *ngIf="_data.isEditingRef" type="button" class="btn btn-sm btn-success" (click)="onEditRefRevert()"> Cancel Edit </button>
        </span>-->
        <edit-prop isChild label="Finished" szs="3,1">
            <input class="form-control" type="checkbox" [(ngModel)]="reference.finished">
        </edit-prop>
    </div>

    <div class="form-group">
        <edit-prop isChild label="Brief Reference" prop="briefReference" szs="1,3">
            <input class="form-control" type="string" (change)="onCheckBriefRef()" (input)="onInputBriefRef()" [(ngModel)]="reference.briefReference">
            <!--<div class="alert-danger">{{entity.getErrorFor("briefReference")}}</div>-->
        </edit-prop>
        <span class="col-md-8">
            <button type="button" class="btn btn-sm btn-success" (click)="onShowHideMatches()">
                {{ _data.showBriefRefMatches ? 'Hide Matches' : 'Show Matches'}}
            </button>
            <button type="button" class="btn btn-sm btn-success" (click)="onSuggestNext()">Suggest next</button>
            <button *ngIf="canShowAuthors()" type="button" class="btn btn-sm btn-success" (click)="onShowHideAuthorMatches()">
                {{_showAuthorMatches ? 'Hide Author Matches' : 'Show Author Matches'}}
            </button>
        </span>
    </div>
    <div class="form-group">
        <div class="search-results-offset col-md-7" [attr.data-hidden]="!_data.showBriefRefMatches">
            <default-table *ngIf="hasBriefReferenceMatches()">
                <thead>
                <tr>
                    <td>Reference No.</td>
                    <td>Brief Reference</td>
                    <td>Title</td>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let b of _data.briefRefMatches">
                    <td>{{b.referenceId}}</td>
                    <td>{{b.briefReference}}</td>
                    <td>{{b.title}}</td>
                </tr>
                </tbody>
            </default-table>
            <span *ngIf="!hasBriefReferenceMatches()">No matching brief references found.</span>
        </div>
        <div class="search-results-offset col-md-4 vertical-top" *ngIf="canShowAuthorMatches()">
            <div class="float-left" *ngIf="_matchingAuthors && _matchingAuthors.length > 0">
                <span class="inline italic vertical-top">Select a matching Author</span>
                <default-table>
                    <thead>
                        <tr>
                            <td>Author Name</td>
                            <td>Initials</td>
                            <td>Attach</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let a of _matchingAuthors">
                            <td>{{a.author.authorName}}</td>
                            <td>{{a.author.initials}}</td>
                            <input type="checkbox" (change)="onChangeAuthorSelection(a)" [(ngModel)]="a.selected" />
                        </tr>
                    </tbody>
                </default-table>
            </div>
            <div *ngIf="!hasAuthorMatches()">
                <span *ngIf="!_loadingAuthors" class="bold">No matching authors found.</span>
                <span *ngIf="_loadingAuthors" class="italic">Loading matching authors.</span>
                <button type="button" class="btn btn-sm btn-success" (click)="onCreateAuthor()">Create New Author</button>
            </div>
        </div>
    </div>

    <div class="form-group">
        <edit-prop isChild label="Authors" prop="referenceId" szs="1,6">
            <textarea class="form-control" rows=2 type="text" disabled="true">{{reference.authorDisplay()}}</textarea>
        </edit-prop>
    </div>

    <div class="form-group">
        <edit-prop isChild label="Year of Publication" prop="yearOfPublication" szs="1,6">
            <input class="form-control" type="text" [value]="reference.yearOfPublication == 0 ? '... N/A - determined from Brief Reference ...' : reference.yearOfPublication.toString()" readonly="readonly">
        </edit-prop>
        <edit-prop isChild label="Priority" szs="2,2">
            <select class="form-control" [ngModel]="reference.precedence" (change)="reference.precedence=$event.target.value">
                <option *ngFor="let p of ['0','1','2','3']" [value]="p">{{p}}</option>
            </select>
        </edit-prop>
    </div>

    <div class="form-group">
        <edit-prop isChild label="Title" prop="title" szs="1,6">
            <textarea class="form-control" rows=3 type="text" [(ngModel)]="reference.title"></textarea>
        </edit-prop>
        <edit-prop isChild label="Literature Search" szs="2,2">
            <select class="form-control" [ngModel]="reference.typeSearchServiceId" (change)="reference.typeSearchServiceId=$event.target.value">
                <option *ngFor="let ss of _typeSearchServices" [value]="ss.typeSearchServiceId">{{ss.typeSearchServiceId}}</option>
            </select>
        </edit-prop>

        <!--<edit-prop isChild label="Literature Search" szs="2,1">
        <input class="form-control" type="checkbox" [(ngModel)]="reference.litSearch">
    </edit-prop>-->

    </div>

    <div class="form-group">
        <edit-prop isChild label="Project" prop="projectId" szs="1,6">
            <select class="form-control" [ngModel]="reference.projectId" (change)="reference.projectId=$event.target.value">
                <option selected value>-- None  --</option>
                <option *ngFor="let p of _data.projects" [value]="p.projectId">{{p.projectName}}</option>
            </select>
        </edit-prop>
        <edit-prop isChild label="Linkable" szs="2,1">
            <input class="form-control" type="checkbox" [(ngModel)]="reference.linkable">
        </edit-prop>

    </div>

    <div class="form-group">
        <span class="col-md-1">
            <button type="button" class="pull-right btn-no-margin btn-sm btn-success" (click)="onSelectJournal()">Select Journal</button>
        </span>
        <edit-prop isChild prop="journalId" szs="1,6">
            <label class="form-control" type="string">{{reference.journal?.journalName}}</label>
        </edit-prop>
        <edit-prop isChild label="RIFM Article" szs="2,1">
            <input class="form-control" type="checkbox" [(ngModel)]="reference.rIFMArticle">
        </edit-prop>
    </div>

    <div class="form-group" style="margin-left: 145px; margin-top: -12px; background-color: aliceblue; width: 180px;">
        <span class="inline">Journal Id:</span>
        <span class="inline">{{reference.journal?.journalId}}</span>
    </div>

    <div class="form-group">
        <edit-prop isChild label="Journal Detail" prop="journalDetail" szs="1,6">
            <input class="form-control" type="string" [(ngModel)]="reference.journalDetail">
        </edit-prop>
        <edit-prop isChild label="FEMA Article" szs="2,1">
            <input class="form-control" type="checkbox" [(ngModel)]="reference.fEMAArticle">
        </edit-prop>
    </div>

    <div class="form-group">
        <edit-prop isChild label="Online Publications" prop="publicationWebReference" szs="1,6">
            <textarea class="form-control" type="text" rows=1 [(ngModel)]="reference.publicationWebReference"></textarea>
        </edit-prop>
    </div>

    <div class="form-group">
        <edit-prop isChild label="Abstract" prop="abstract" szs="1,6">
            <textarea class="form-control" style="min-width: 100%"
                      rows=6 [(ngModel)]="_abstractText"></textarea>
        </edit-prop>
        <edit-prop isChild label="Abstract Only" szs="2,1">
            <input class="form-control" type="checkbox" [(ngModel)]="reference.abstractOnly">
        </edit-prop>
    </div>

    <div class="form-group">
        <edit-prop isChild label="Abstract Number" prop="abstractNumber" szs="1,6">
            <input class="form-control" type="string" [(ngModel)]="reference.abstractNumber">
        </edit-prop>
    </div>

    <div class="form-group">
        <edit-prop isChild label="Meeting Details" prop="meetingDetails" szs="1,6">
            <textarea class="form-control" style="min-width: 100%"
                      rows=3 [(ngModel)]="reference.meetingDetails"></textarea>
        </edit-prop>
        <edit-prop isChild label="Poster Attached" szs="2,1">
            <input class="form-control" type="checkbox" [(ngModel)]="reference.posterAttached">
        </edit-prop>
    </div>

    <div class="form-group">
        <edit-prop isChild label="Contractor ID" prop="contractorId" szs="1,6">
            <input class="form-control" type="string" [(ngModel)]="reference.contractorId">
        </edit-prop>
        <edit-prop isChild label="RIFM as Author" szs="2,1">
            <input class="form-control" type="checkbox" [(ngModel)]="reference.rIFMAsAuthor">
        </edit-prop>
    </div>

    <div class="form-group">
        <edit-prop isChild label="In Life Portion" szs="1,6">
            <textarea class="form-control" type="text" rows=2 [(ngModel)]="reference.inLifePortion"></textarea>
        </edit-prop>
    </div>

    <div class="form-group">
        <edit-prop isChild label="Staff Comments" szs="1,6">
            <textarea class="form-control" type="text" rows=2 [(ngModel)]="reference.staffComments"></textarea>
        </edit-prop>

    </div>

    <div class="form-group">
        <edit-prop isChild label="Reference Type" prop="publicationType" szs="1,4">
            <select class="form-control" [ngModel]="reference.publicationTypeId" (change)="onSetPublicationType($event.target.value)">
                <option *ngFor="let tp of _typePublications" [value]="tp.publicationTypeId">{{tp.publicationType}}</option>
            </select>
        </edit-prop>
        <edit-prop isChild label="Klimisch Score" szs="4,2">
            <select class="form-control" [ngModel]="reference.typeKlimischId" (change)="reference.typeKlimischId=$event.target.value">
                <option *ngFor="let tk of _typeKlimischs" [value]="tk.typeKlimischId">{{tk.typeKlimischId}}</option>
            </select>
        </edit-prop>
    </div>

    <div *ngIf="isGovernmentDocument()">
        <div class="form-group">
            <edit-prop isChild label="Govt. Sponsors" prop="govSponsors" szs="1,6">
                <textarea class="form-control" type="text" rows=2 [(ngModel)]="reference.govSponsors"></textarea>
            </edit-prop>
            <edit-prop isChild label="Govt. Document No." prop="govDocNumbers" szs="2,3">
                <textarea class="form-control" type="text" rows=2 [(ngModel)]="reference.govDocNumbers"></textarea>
            </edit-prop>
        </div>
    </div>


    <div class="form-group">
        <edit-prop isChild label="Captive Paper" szs="1,1">
            <input class="form-control" type="checkbox" [(ngModel)]="reference.captivePaper" (change)="onCaptivePaperChange($event.target.checked)">
        </edit-prop>
        <edit-prop isChild label="Captive Company" prop="captiveCompany" szs="2,3">
            <input class="form-control" type="text" [disabled]="!reference.captivePaper" [(ngModel)]="reference.captiveCompany">
        </edit-prop>
        <edit-prop *ngIf="reference.referenceId > 0" isChild label="Send To" szs="2,2">
            <select class="form-control" (change)="sendToStaff($event.target.value)" [ngModel]="_selectedContactId">
                <option *ngFor="let c of _sendToContacts" [value]="c.workflowContactId">{{c.firstName + " " + c.lastName}}</option>
                <option value=0 selected></option>
            </select>
        </edit-prop>
    </div>

    <div class="form-group">
        <edit-prop isChild label="Restricted Distribution" szs="1,1">
            <input class="form-control" type="checkbox" [(ngModel)]="reference.restrictedDistribution" (change)="onRestrictedDistributionChange($event.target.checked)">
        </edit-prop>
        <edit-prop isChild label="Sponsoring Company" prop="sponsoringCompany" szs="2,3">
            <input class="form-control" type="text" [(ngModel)]="reference.sponsoringCompany" [disabled]="!reference.restrictedDistribution">
        </edit-prop>
        <div>
            <edit-prop *ngIf="reference.referenceId > 0" isChild label="Notify Staff" szs="2,2">
                <div class="notify-container">
                    <div *ngFor="let contact of _notifyContacts; let ix = index">
                        <div style="margin-left: 2px;">
                            <input type="checkbox" [(ngModel)]="contact._selected" (change)="notifyContact(contact)">
                            <span class="notify-text">{{contact._contactName}}</span>
                        </div>
                    </div>
                </div>
            </edit-prop>
        </div>
    </div>

    <div class="row reference-hold-container">
        <div *ngIf="isOnHold == false && !reference.finished" class="col-sm-4">
            <button *ngIf="showHoldReasonInput == false" class="btn btn-primary btn-sm" type="button" (click)="addHold()">Add Hold</button>
            <div class="hold-reason-container" *ngIf="showHoldReasonInput">
                <span class="workflow-status-text">Hold Reason</span>
                <input class="form-control" type="text" [(ngModel)]="holdReason">
                <button *ngIf="canSaveHold" class="btn btn-primary btn-sm confirm-hold-button" type="button" (click)="saveHold()">Confirm Hold</button>
                <button *ngIf="showHoldReasonInput" class="btn btn-primary btn-sm btn-warning cancel-hold-button" type="button" (click)="cancelAddHold()">Cancel Add Hold</button>
            </div>
        </div>
    </div>
</div>

<div *ngIf="!_data.adding" class="row" style="margin-top: 10px;">
    <workflow-history [entity]="reference" [editable]="true"></workflow-history>
</div>

<reference-selector></reference-selector>
<journal-selector></journal-selector>
<confirm-finish-override-dialog></confirm-finish-override-dialog>

import { Component, OnInit } from '@angular/core';

@Component({
    template: '<router-outlet></router-outlet>'
})
export class HomeComponent {

    constructor() {
        
    }
    
}

import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';

import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';

import { EditorService, StateMap, UnitOfWork } from '../../services/common';
import { EditManager, UtilFns } from '../../utils/common';
import { PageState, TabContainer } from '../../controls/common';

import {Material, Synonym, WorkflowMaterialRecordedAction} from '../../entities/EntityModels';

import { MaterialEditorBaseComponent } from './material-editor-base.component';
import { PredictionsCramerEditorComponent } from './predictions-cramer-editor.component';

import { MaterialSelectorComponent } from '../material/material-selector.component';
import {MaterialWorkflowHistoryComponent} from './material-workflow-history.component';

import { ROUTES } from '../routes';
import { STAFF_ROUTES } from './staff.routes';
import { MaterialLoader } from './services/material-loader';
import {MaxIdBundle} from '../../entities/projections/MaxIdBundle';
import { MaterialWorkflowService } from '../../services/material-workflow-service';
import { WorkflowEntityState } from '../../services/workflow-state';

export class MaterialEditData {
    edm: EditManager<Material>;
    entity: Material;
    adding: boolean;
    inSubEditor: boolean;
    currentTab: number;
    otherErrorMap: { [key: string]: string } = {};
    onCancelMap: { [key: string]: () => void } = {};
    onSaveMap: { [key: string]: () => void } = {};
    maxMonographNumber: number;
    maxFEMANumber: number;
}

export class MaterialWorkflowEventFields {
    monographNumber: number;
    typeBotanicalSubdivisionId: string;
    typeEssentialOilId: string;
    fEMANumber: number;
    realCASNumber: string;
}

class PageStateExt extends PageState {
    materialId: number;
}

@Component({
    selector: 'material-editor',
    templateUrl: './material-editor.html',
    providers: [MaterialWorkflowService],
})

export class MaterialEditorComponent implements OnInit, AfterViewInit {
    @ViewChild(MaterialEditorBaseComponent) _materialEditorBaseComponent: MaterialEditorBaseComponent;
    @ViewChild(PredictionsCramerEditorComponent) _predictionsCramerEditorComponent: PredictionsCramerEditorComponent;
    @ViewChild(MaterialSelectorComponent, { static: true }) _materialSelectorComponent: MaterialSelectorComponent;
    @ViewChild(MaterialWorkflowHistoryComponent) _materialWorkflowHistoryComponent: MaterialWorkflowHistoryComponent;
    @ViewChild(TabContainer) _tabContainer: TabContainer;

    @Input() entity: Material;

    _canShowWorkflowModal = true;
    _edm: EditManager<Material>;
    _data: MaterialEditData = new MaterialEditData();
    _materialWorkflowService: MaterialWorkflowService;
    _workflowEventData = new MaterialWorkflowEventFields();
    _pageState = new PageStateExt('Material editor');
    _userMessage = '';
    _workflowActivityType = 'all';

    _isLoading = false;

    constructor(public _uow: UnitOfWork, public _stateMap: StateMap, public _editorService: EditorService,
                public _location: Location, public _router: Router,
                private route: ActivatedRoute,
                private materialLoader: MaterialLoader,
                public materialWorkflowService: MaterialWorkflowService) {

        this._materialWorkflowService = materialWorkflowService;
    }

    ngOnInit() {
        this._stateMap.currentRouteName = STAFF_ROUTES.Material.name;
        this._edm = this._editorService.createEditManager<Material>(this, 'Material');
        this._data.edm = this._edm;

        this._uow.fetch('Materials/MaxIds', {})
            .then(r => {
                const maxIds = r[0] as MaxIdBundle;
                this._data.maxFEMANumber        = maxIds.maxFEMANumber;
                this._data.maxMonographNumber   = maxIds.maxMonographNumber;
            });

        this.route.params
            .subscribe(params => {
                const materialId = params['materialId'];
                if (materialId) {
                    this.materialLoader.load(materialId)
                        .then(mat => {
                            this.editMaterial(mat);
                        });
                } else {
                    this._data.edm.editing = false;
                }
            });

        this._data.currentTab = 0;
    }

    ngAfterViewInit() {
        this.focusInitialTab();
    }

    focusInitialTab() {
        if (this._tabContainer) {
            this._tabContainer.selectTab(this._data.currentTab);
        }
    }

    isActiveTab(tabNumber: number): boolean {
        return (this._data.currentTab == tabNumber);
    }

    setTabNumber(tabNumber: number) {
        this._data.currentTab = tabNumber;
    }

    editMaterial(m: Material) {

        this.resetMaterialWorkflowModal();

        this._data.edm.editing = false;

        if (m == null) {
            return;
        }
        this._pageState.materialId = m.materialId;

        this._stateMap.currentMaterial = m;
        this._stateMap.staffMaterialId = m.materialId;

        this._data.adding = false;
        this._data.entity = m;
        this._data.edm.onlyEntity = m;
        this._data.edm.editing = true;

        if (this._materialEditorBaseComponent) {
            this._materialEditorBaseComponent.onEdit();
        }

        // certain field changes should be recorded in the material workflow history
        this.storeMaterialWorkflowEventData();

        return m;
    }

    createMaterial() {
        const material = this._edm.uow.materialFactory.create();

        material.fEMANumber         = this._data.maxFEMANumber + 1;
        material.monographNumber    = this._data.maxMonographNumber + 1;

        material.cASNumber = '';
        material.typeNaturalOccurrenceId = 1; // default = None
        this._stateMap.staffMaterialId = material.materialId;
        this._stateMap.currentMaterial = material;
        return material;
    }

    // EditManager overrides
    canCancel() {
        return (this._uow.hasChanges());
    }

    canDeactivate() {
        // The edit-manager checks the base Unit of Work.
        return !this._data.edm.hasEdits() && !this._data.inSubEditor;
    }

    canDeactivateEditor() {
        // Allow the focus to shift to material edit base when adding a new material
        if (this._data.adding) {
            return true;
        }
        return this.canDeactivate();
    }

    canEdit() {
        return ((this._stateMap.currentUser.isStaff) && (this._stateMap.currentUser.isInRole('fema') == false));
    }

    canSave(): boolean {

        const data = this._data;

        if (!data.edm.hasEdits()) {
            return false;
        }

        if (!data.edm.validateBeforeSave()) {
            return false;
        }

        const errMap = data.otherErrorMap;
        for (const key in errMap) {
            if (errMap[key]) {
                return false;
            }
        }
        return true;
    }

    onAdd() {
        this._userMessage = '';

        const data = this._data;
        data.adding = true;
        data.entity = this.createMaterial();

        data.edm.onlyEntity = data.entity;
        data.edm.editing = true;

        if (this._materialEditorBaseComponent) {
            this._materialEditorBaseComponent.onAdd();
        }

        this._data.currentTab = 0;
        this.focusInitialTab();
    }

    onCancel() {
        this._userMessage = '';

        const data = this._data;
        const hadEdits = data.edm.hasEdits();

        this._uow.rollback();
        data.edm.clearErrors();
        data.edm.pageState.loadStatusMsg = null;

        // tslint:disable-next-line:forin
        for (const k in data.onCancelMap) {
            data.onCancelMap[k]();
        }

        if (this._data.adding || !hadEdits) {
            data.edm.editing = false;
            this._stateMap.staffMaterialId = null;
            this._stateMap.currentMaterial = null;
        }
        this._data.adding = false;
    }

    onEdit() {
        this._userMessage = '';

        UtilFns.showModal(this._materialSelectorComponent, this, null).then(mli => {
            if (mli == null) {
                return;
            }
            return this._router.navigate(['/staff/material/', mli.materialId, 'info']);
        });

        this._data.currentTab = 0;
        this.focusInitialTab();
    }

    onMore() {
        return this._router.navigate(UtilFns.asRouterParts(ROUTES.Material, this._data.entity.materialId));
    }

    onSave() {
        this._userMessage = '';

        if (!this.canSave()) {
            return Promise.resolve();
        }

        if (!this._edm.validateBeforeSave()) {
            return;
        }

        if (this._data.adding) {

            if (!this.isValidString(this._materialEditorBaseComponent._synonymName)) {
                this._userMessage = 'Please enter a Principal Name.';
                return;
            }

            const sy = this._uow.synonymFactory.create();
            sy.materialId = this._data.entity.materialId;
            sy.synonymWord = this._materialEditorBaseComponent._synonymName;

            sy.principal = true;
            sy.rootWord = Synonym.rootWordFromSynonymWord(this._materialEditorBaseComponent._synonymName);

            // for legacy purposes.
            if (!this._data.entity.cASNumber) {
                this._data.entity.cASNumber = this._data.entity.realCASNumber;
            }

            this.recordMaterialWorkflowAdd();

        } else {
            this.processMaterialWorkflowEvents();
        }

        return this._data.edm
            .commitSave()
            .then(() => {  // uow saved so will commit all changes
                if (this._data.adding) {
                    this._data.adding = false;
                    return this._router.navigate(['/staff/material/', this._data.entity.materialId, 'info']);
                }

                this.editMaterial(this._data.entity);

                // tslint:disable-next-line:forin
                for (const k in this._data.onSaveMap) {
                    this._data.onSaveMap[k]();
                }
            });
    }

    onDelete() {
        return this._stateMap.yesNoComponent
            .showModal('Delete entire material',
                'Are you sure?').then(ok => {
                if (ok) {
                    return this.deleteMaterial();
                }
            });
    }

    deleteMaterial() {
        this._isLoading = true;

        const materialId = this._edm.currentEntity.materialId;
        const cas = this._edm.currentEntity.realCASNumber;

        // TODO: check that all entries in related tables are deleted.
        // this is done on the db with a cascade delete.
        this._edm.onDeleteCore();

        return this._uow.commitSelected([this._data.entity])
            .then(() => {
                this._edm.setSavedStatus('Deleted');
                // Next line is needed because the onDeleteCore above will have modified all
                // entities in cache that ref'd this material.
                this._uow.rollback();
                this.recordMaterialWorkflowDelete(materialId, cas);
                this._userMessage = 'The material has been successfully deleted.';

                this._isLoading = false;

            }).catch((e) => {
                this._edm.setSaveFailedStatus('Delete failed: ' + e);
                this._isLoading = false;
            });
    }

    isValidString(teststring: string) {
        if (!teststring) { return false; }

        return (teststring.trim().length > 0);
    }

    // ****************************************************************************
    // Material Workflow History Modal
    // ****************************************************************************
    onShowMaterialWorkflowHistory() {
        if (this._materialWorkflowHistoryComponent == null || this._data.entity == null) {
            return;
        }

        UtilFns.showModal(this._materialWorkflowHistoryComponent, this).then(mrun => {
            // this.resetMaterialWorkflowModal();
            return;
        });
    }

    resetMaterialWorkflowModal() {
        this._canShowWorkflowModal = false;

        setTimeout(() => {
            this._canShowWorkflowModal = true;
        }, 1);
    }

    storeMaterialWorkflowEventData() {
        this._workflowEventData.monographNumber             = this._edm.currentEntity.monographNumber;
        this._workflowEventData.typeBotanicalSubdivisionId  = this._edm.currentEntity.typeBotanicalSubdivisionId;
        this._workflowEventData.typeEssentialOilId          = this._edm.currentEntity.typeEssentialOilId;
        this._workflowEventData.fEMANumber                  = this._edm.currentEntity.fEMANumber;
        this._workflowEventData.realCASNumber               = this._edm.currentEntity.realCASNumber;
    }

    processMaterialWorkflowEvents() {
        if (this._edm.currentEntity.monographNumber != this._workflowEventData.monographNumber) {
            // tslint:disable-next-line:max-line-length
            this._materialWorkflowService.recordMaterialMonographNumberChange(this._data.entity.materialId, WorkflowEntityState.Modified, this._data.entity.monographNumber, this._workflowEventData.monographNumber, this._stateMap.currentUser.name);
        }

        if (this._edm.currentEntity.typeBotanicalSubdivisionId != this._workflowEventData.typeBotanicalSubdivisionId) {
            // tslint:disable-next-line:max-line-length
            this._materialWorkflowService.recordMaterialBotanicalPartChange(this._data.entity.materialId, WorkflowEntityState.Modified, this._data.entity.typeBotanicalSubdivisionId, this._workflowEventData.typeBotanicalSubdivisionId, this._stateMap.currentUser.name);
        }

        if (this._edm.currentEntity.typeEssentialOilId != this._workflowEventData.typeEssentialOilId) {
            // tslint:disable-next-line:max-line-length
            this._materialWorkflowService.recordMaterialExtractTypeChange(this._data.entity.materialId, WorkflowEntityState.Modified, this._data.entity.typeEssentialOilId, this._workflowEventData.typeEssentialOilId, this._stateMap.currentUser.name);
        }

        if (this._edm.currentEntity.fEMANumber != this._workflowEventData.fEMANumber) {
            // tslint:disable-next-line:max-line-length
            this._materialWorkflowService.recordMaterialFEMANumberChange(this._data.entity.materialId, WorkflowEntityState.Modified, this._data.entity.fEMANumber, this._workflowEventData.fEMANumber, this._stateMap.currentUser.name);
        }

        if (this._edm.currentEntity.realCASNumber != this._workflowEventData.realCASNumber) {
            // tslint:disable-next-line:max-line-length
            this._materialWorkflowService.recordMaterialRealCASNumberChange(this._data.entity.materialId, WorkflowEntityState.Modified, this._data.entity.realCASNumber, this._workflowEventData.realCASNumber, this._stateMap.currentUser.name);
        }
    }

    recordMaterialWorkflowAdd() {
        this._materialWorkflowService.recordMaterialAdded(this._data.entity.materialId, WorkflowEntityState.Added, this._data.entity.realCASNumber, this._stateMap.currentUser.name);
    }

    // this happens after the material deletion is committed so the new record needs to be committed
    recordMaterialWorkflowDelete(materialId: number, cas: string) {
        this._materialWorkflowService.recordMaterialDeleted(materialId, WorkflowEntityState.Added, cas, this._stateMap.currentUser.name);

        return this._uow.commit();
    }
}

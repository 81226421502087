import { UtilFns } from '../utils/common';
import * as _ from 'lodash';

// tslint:disable-next-line:class-name
interface entityValueFn {
    // tslint:disable-next-line:callable-types
    (entity: any): string;
}

export interface SortMap {
    [K: string]: string | entityValueFn;
}

export class SortColumn {
    constructor(public name: string, public up: boolean) {

    }
}

export class ColumnSorter {

    _defaultSortColumn: SortColumn;
    _sortColumn: SortColumn;

    constructor(public _parentComponent: any, public _sortMap: SortMap, sortColumn?: SortColumn | string, public _sortColumnChanged?: (sc: SortColumn) => void) {
        if (sortColumn) {
            if (sortColumn instanceof SortColumn) {
                this._sortColumn = sortColumn;
            } else {
                this._sortColumn = new SortColumn(<string>sortColumn, true);
            }
        } else {
            this._sortColumn = new SortColumn('', true);
        }
        this._defaultSortColumn = <SortColumn>_.clone(this._sortColumn);
        if (!_sortColumnChanged) {
            this._sortColumnChanged = (sc) => undefined;
        }
        this._sortColumnChanged(this._sortColumn);
    }

    public iconMap: {} = {};

    public get sortColumn(): SortColumn {
        return this._sortColumn;
    }

    public set sortColumn(sc: SortColumn) {
        this.sortColumn.name = sc.name;
        this.sortColumn.up = sc.up;
        this._sortColumnChanged(this.sortColumn);
    }

    public reset() {
        this.sortColumn.name = this._defaultSortColumn.name;
        this.sortColumn.up = this._defaultSortColumn.up;
        this._sortColumnChanged(this.sortColumn);
        this.updateClasses();
    }

    private updateClasses() {
        _.forEach(this.iconMap, (iconChild, colName) => {
            (<any>iconChild).className = this.getClasses(colName);
        });
    }

    public select(columnName: string) {
        if (this.sortColumn.name == columnName) {
            this.sortColumn.up = !this.sortColumn.up;
        } else {
            this.sortColumn.name = columnName;
            this.sortColumn.up = false;
        }
        this._sortColumnChanged(this.sortColumn);
    }

    public applySort(columnName: string) {
        this.select(columnName);
        this._parentComponent.sortWith(this);
    }

    public getClasses(columnName: string) {
        if (this.sortColumn.name == columnName) {
            return this.sortColumn.up ? 'fa fa-caret-up' : 'fa fa-caret-down';
        } else {
            return '';
        }
    }

    // used for client side sorting
    public sort<T>(collection: T[]): T[] {
        if (this.sortColumn.name == null || this.sortColumn.name.length == 0) {
            return collection;
        }
        const sortFn = <entityValueFn>this._sortMap[this.sortColumn.name];
        return UtilFns.sort(collection, this.sortColumn.up, sortFn);
    }

    // used for server side sorting where sort criteria is passed in breeze orderBy clause from the parent
    // not needed if passing the column name to the server.
    public getOrderBy(): string {
        return <string>this._sortMap[this.sortColumn.name] + (this.sortColumn.up ? '' : ' desc');
    }


}


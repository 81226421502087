<div id="ExposureSurveyLastReport" class="modal fade bs-example-modal-lg" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel">
    <div class="modal-dialog modal-lg">
        <div id="report-data" class="modal-content">
            <div class="modal-header custom-header">
                <span class="modal-title inline float-left" id="myModalLabel">Most Recent Exposure Survey for a Material Report</span>
                <span class="no-print inline float-right">
                    <button type="button" class="btn btn-default pull-right text-uppercase text-bold" data-dismiss="modal"><span class="fa fa-times"></span> Close</button>
                    <button (click)="printExposureSurveyLastReport()" type="button" class="btn btn-default pull-right text-uppercase text-bold"><span class="fa fa-print"></span> Print Document</button>
                    <button (click)="exportReportFile()" type="button" class="btn btn-default pull-right text-uppercase text-bold"><span class="fa fa-file-excel-o"></span> Export to File</button>
                </span>
            </div>
            <div class="modal-body custom-body">
                <div class="row">
                    <div class="col-sm-10" *ngIf="reportItems">
                        <table class="table table-striped table-hover table-bordered table-condensed" style="width: 860px;">
                            <thead>
                                <tr>
                                    <td class="text-center" style="width: 80px;">MaterialId</td>
                                    <td class="text-center" style="width: 80px;">CAS No.</td>
                                    <td class="text-center" style="width: 80px;">RIFM ID</td>
                                    <td class="text-left" style="width: 180px;">Principal Name</td>
                                    <td class="text-center" style="width: 80px;">Most Recent Survey Date</td>
                                    <td class="text-center" style="width: 80px;">Most Recent Survey Number</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of reportItems">
                                    <td class="vertical-top text-center" style="width: 80px;">{{item.materialId}}</td>
                                    <td class="vertical-top text-center" style="width: 80px;">{{item.realCASNumber}}</td>
                                    <td class="vertical-top text-center" style="width: 80px;">{{item.rIFMId}}</td>
                                    <td class="vertical-top text-left break-word" style="width: 180px;">{{item.synonymWord}}</td>
                                    <td class="vertical-top text-center" style="width: 80px;">{{utilFns.formatDate(item.exposureSurveyDate, 'MMM, YYYY')}}</td>
                                    <td class="vertical-top text-center" style="width: 80px;">{{item.exposureSurveyNumber}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer border-zero no-print">
    </div>
</div>

<print-to-pdf-doc></print-to-pdf-doc>

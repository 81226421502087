import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { UserManager } from './user-manager';

import { from } from 'rxjs';

@Injectable()
export class AuthGuardAllowAuthorized implements CanActivate {
    constructor(private _userManager: UserManager) {
    }

    canActivate() {
        return from (this._userManager.checkIfLoggedInOAuth());
    }
}

import { Component, Input, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';

import { MolImageService, SearchService, StateMap, UnitOfWork, UserManager } from '../../services/common';
import { LocationFns, UtilFns } from '../../utils/common';
import { ColumnSorter, PageState } from '../../controls/common';
import { Material, MaterialAlertRule } from '../../entities/EntityModels';
import { StructureAlertListItem } from '../../entities/projections/StructureAlertListItem';
import { environment } from '../../../environments/environment';

@Component({
    selector: 'prediction-hh-alert',
    templateUrl: './prediction-hh-alert.html',
})
export class PredictionHHAlertComponent implements OnInit {
    @Input() materialId: number;
    material: Material;
    _score: StructureAlertListItem;
    _scoreNotFound: boolean;
    _materialAlertRules: MaterialAlertRule[];
    _pageState = new PageState('Prediction Alert');
    _colSorter: ColumnSorter;

    constructor(public _uow: UnitOfWork, public _stateMap: StateMap, public _searchService: SearchService,
                public _molImageService: MolImageService, public _location: Location, public _userManager: UserManager,
                public _http: HttpClient) {

    }

    ngOnInit() {
        this.material = this._stateMap.currentMaterial;
        LocationFns.setTab(this._location, '0.0');
        const params = {materialId: this.materialId};

        this._uow.fetch('fetchStructureAlertListItem', params).then(r => {
            this._scoreNotFound = (r.length == 0);
            if (this._scoreNotFound) {
                return;
            }
            this._score = <StructureAlertListItem><any>r[0];
        });

        this._uow.materialAlertRuleRepository.whereWithParams(params).then(r => {
            this._materialAlertRules = r;
        });

        this._colSorter = new ColumnSorter(this, {
            'Rule': (mar: MaterialAlertRule) => UtilFns.formatNumber(mar.rule, 3)
        }, 'Rule', (sortColumn) => this._pageState.sortColumn = sortColumn);
    }

    getSmileUrl(material: Material) {
        return this._molImageService.getImageUrlForSmile(material.smiles);
    }

    hasStockImage(mar: MaterialAlertRule) {
        // Synced up value in TypeAlertRules table so the GIF value is null when the image is missing.
        return (mar.typeAlertRule.gIF != null);
    }

    getStockImage(mar: MaterialAlertRule) {
        return environment.assetsUrl + 'assets/images/' + mar.rule + '.png';
    }

    sortWith(colSorter: ColumnSorter) {
        colSorter.sort(this._materialAlertRules);
    }

    linkTo(fileName: string) {
        const url = this._userManager.getFileServiceFilePathUrl('PublicReferences//' + fileName);
        window.open(url, '_self');
        return false;
    }
}

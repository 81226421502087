import { Location } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';

import { UnitOfWork, StateMap, provideParent } from '../../services/common';
import { UtilFns } from '../../utils/common';
import { PageState, EditPropParent } from '../../controls/common';
import { Material, MaterialExposureSurvey, TypeExposureSurveyResponse } from '../../entities/EntityModels';
import { MaterialSelectorComponent } from '../material/material-selector.component';

@Component({
    selector: 'material-exposure-survey-editor',
    templateUrl: './material-exposure-survey-editor.html',
    providers: [provideParent(MaterialExposureSurveyEditorComponent, EditPropParent)]
})

export class MaterialExposureSurveyEditorComponent implements OnInit, EditPropParent {
    @ViewChild(MaterialSelectorComponent, { static: true }) _materialSelectorComponent: MaterialSelectorComponent;

    _isLoading = false;

    _pageState = new PageState('Material Exposure Survey Editor');
    _typeExposureSurveyResponses: TypeExposureSurveyResponse[];
    _materialExposureSurveys: MaterialExposureSurvey[];
    _previousExposureSurveys: string;

    @Input() entity: MaterialExposureSurvey;

    constructor(public _uow: UnitOfWork, public _stateMap: StateMap, public _location: Location) {
    }

    ngOnInit() {
        this._uow.typeExposureSurveyResponseRepository.all().then(r => {
            this._typeExposureSurveyResponses = r;
        });
    }

    getError(propName: string) {
        if (this.entity == null) {
            return;
        }
        return this.entity.getErrorFor(propName);
    }

    isAdded() {
        return this.entity.entityAspect.entityState.isAdded();
    }

    selectMaterial() {
        UtilFns.showModal(this._materialSelectorComponent, this).then(mli => {
            if (mli == null) { return; }
            this.entity.materialId = mli.materialId;
            return this._uow.materialRepository.withId(mli.materialId);
        }).then(m => {
            if (m) {
                this.entity.material = m;
                this.fetchPreviousSurveys(this.entity.material);
            }
        });
    }

    fetchPreviousSurveys(mat: Material) {

        this._materialExposureSurveys = [];
        this._previousExposureSurveys = '';

        let params = { materialId: this.entity.material.materialId };

        this._isLoading = true;

        this._uow.fetch('ExposureSurveys/MaterialExposureSurveyByMaterialId', params).then(m => {
            this._materialExposureSurveys = m;
            if (this._materialExposureSurveys != null && this._materialExposureSurveys.length > 0) {

                this._previousExposureSurveys = $.map(this._materialExposureSurveys, function (obj) {
                    return obj.exposureSurvey.exposureSurveyMonthYearNumber;
                }).join('; ');
            }
            this._isLoading = false;
        });
    }
}

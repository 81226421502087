import { Entity } from 'breeze-client';
import { Injectable} from '@angular/core';
import { UnitOfWork} from './unit-of-work';
import { StateMap} from './state-map';
import { EditManager } from '../utils/edit-manager';

@Injectable()
export class EditorService {
    constructor(public uow: UnitOfWork, public stateMap: StateMap ) {
    }

    createEditManager<T extends Entity>(component: any, displayName: string): EditManager<T> {
        return new EditManager<T>(component, this.uow, this.stateMap, displayName);
    }
}

import { EntityBase } from './EntityBase';
import { AnnouncementDetail } from './AnnouncementDetail';

import { Validator } from 'breeze-client';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

export class Announcement extends EntityBase {

   /// <code> Place custom code between <code> tags
   
   /// </code>

   // Generated code. Do not place code below this line.
   announcementId: number;
   title: string;
   content: string;
   imageLink: string;
   webLink: string;
   webLinkDescription: string;
   enabled: boolean;
   created: Date;
   createdUser: string;
   modified: Date;
   modifyUser: string;
   rowVersion: number;
   announcementDetails: AnnouncementDetail[];
}


<div *ngIf="entity" class="form-horizontal indent-top-sm">

    <div *ngIf="isAdded()">
        <button type="button" class="btn btn-primary" (click)="selectMaterial()">Choose Material</button>
    </div>
    
    <div class="row">
        <edit-prop label="Cas No." szs="2,2">
            <span class="form-control-static">{{entity.material?.realCASNumber}}</span>
        </edit-prop>

        <edit-prop label="RIFM ID" szs="2,1">
            <span class="form-control-static">{{entity.material?.formattedRIFMId}}</span>
        </edit-prop>

        <edit-prop label="Principal Synonym" szs="2,4">
            <span class="form-control-static">{{entity.material?.principalSynonym().synonymWordOrWebVersion()}}</span>
        </edit-prop>

        <div *ngIf="isAdded()">
            <edit-prop label="Previously Surveyed In" szs="2,4">
                <span class="form-control-static">{{_previousExposureSurveys}}</span>
            </edit-prop>
        </div>

        <edit-prop label="Notes" prop="notes" szs="2,5">
            <textarea class="form-control" rows=3 [(ngModel)]="entity.notes"></textarea>
        </edit-prop>

        <edit-prop label="Expected SA Completion" prop="expectedSACompletion" szs="2,5">
            <input class="form-control" type="text" [(ngModel)]="entity.expectedSACompletion">
        </edit-prop>

        <edit-prop label="Response Type" prop="typeExposureSurveyResponseId" szs="2,3">
            <select class="form-control" *ngIf="_typeExposureSurveyResponses" [ngModel]="entity.typeExposureSurveyResponseId" (change)="entity.typeExposureSurveyResponseId=$event.target.value">
                <option *ngFor="let t of _typeExposureSurveyResponses" [value]="t.typeExposureSurveyResponseId">{{t.responseType}}</option>
            </select>
        </edit-prop>
    </div>
</div>
<br/>
<br/>

<div class="row">
    <i *ngIf="_isLoading" class="fa fa-circle-o-notch fa-spin" style="font-size:38px; color: #337ab7;"></i>
</div>

<material-selector></material-selector>


import { EntityBase } from './EntityBase';
import { Material } from './Material';
import { SynonymNamer } from './SynonymNamer';

import { Validator } from 'breeze-client';

/// <code-import> Place custom imports between <code-import> tags
import { EntityTypeAnnotation, EntityPropertyAnnotation} from './EntityTypeAnnotation'
/// </code-import>

export class Synonym extends EntityBase {

   /// <code> Place custom code between <code> tags
   static getEntityTypeAnnotation() : EntityTypeAnnotation {
        var propAnnotations = [
            new EntityPropertyAnnotation( 'synonymWord', {
                displayName: 'Synonym',
                validators: [ Validator.stringLength({ minLength: 3, maxLength: 400}) ]
            } ),
            new EntityPropertyAnnotation( 'rootWord', {
                displayName: 'Alphabetic version',
                validators: [ Validator.stringLength({ minLength: 3, maxLength: 400}) ]
            } )      
        ]
        return new EntityTypeAnnotation( {
            validators: [],
            propertyAnnotations: propAnnotations
        } )
    }
    
    static rootWordFromSynonymWord(synonymWord: string) {
        // algorithm here is to uppercase the string and then 
        // extract all non-word char 'groups' from string and move them to the end separated by '/' 
        var r = synonymWord.toLocaleUpperCase();
        var root = '';
        var suffix = '';
        var rx = /([(A-z]*)([^A-Z]*)(.*)/;
        var matches = rx.exec(r)
        while ( matches ) {
            root = root + matches[1];
            if (matches[2]) {
                suffix = suffix +  '/' + matches[2];
            }
            if (matches[3] == '') {
                matches = null;
            } else {
                matches = rx.exec(matches[3]);
            }
        }
        return root + suffix;
    }

    formatSynonymWebVersion(): string {

        var syn = this.synonymWord;

        var positions = ["alpha", "beta", "gamma", "delta", "epsilon", "omega"];
        positions.forEach(p => {
            syn = this.escapeNoCase(syn, p);
        });

        var isoandsubs = ["cis", "trans", "endo", "exo", "sec", "tert", "ortho", "meta", "para"];
        isoandsubs.forEach(i => {
            var expr = i + "([^a-z]|$)";
            syn = this.italicizeTerm(syn, expr, i);
        });

        var abbrevs = ["n", "m", "o", "p", "t", "d", "dl", "l"];
        var lpart: string, rpart: string;

        abbrevs.forEach(a => {
            var expr = "(^|[^a-z])" + a + "-";
            syn = this.italicizeAbbreviations(syn, expr, a);
        });

        return syn;
    }

    escapeNoCase(value: string, findterm: string) {
        var expr = new RegExp("(" + findterm + ")", "gi"); //Case insensitive, potential for multiple occurrences
        return value.replace(expr, "&" + findterm + ";");
    }

    italicizeTerm(value: string, findterm: string, term: string) : string {
        var lpart: string, rpart: string;

        var rx = new RegExp("(" + findterm + ")", "i");
        var matches = rx.exec(value);

        if (matches) {
            if (matches.index > 0) {
                lpart = value.substring(0, matches.index);
                rpart = value.substring(matches.index + term.length, value.length);
            }
            else {
                lpart = "";
                rpart = value.substring(matches.index + term.length, value.length);
            }

            value = lpart + "<i>" + term + "</i>" + rpart;
        }

        return value;
    }   

    italicizeAbbreviations(value: string, findterm: string, term: string) : string {
        var lpart: string, rpart: string;

        var rx = new RegExp("(" + findterm + ")"); //Case sensitive
        var matches = rx.exec(value);

        if (matches) {
            if (matches.index > 0) {
                lpart = value.substring(0, matches.index + 1);
                rpart = value.substring(matches.index + 1 + term.length, value.length);
            }
            else {
                lpart = "";
                rpart = value.substring(matches.index + term.length, value.length);
            }

            value = lpart + "<i>" + term + "</i>" + rpart;
        }

        return value;
    }

    public synonymWordOrWebVersion() {
        return (this.webVersion) ? this.webVersion : this.synonymWord;
    }

    public get useList() {
        return this.synonymNamers && this.synonymNamers.map(sn => sn.typeSynonymUse.synonymUseLong).sort().join(", ");
    }
    
    public get useLongNames() {
        return this.synonymNamers && this.synonymNamers.map(sn => sn.typeSynonymUse.synonymUseLong);
    }
    
    public removeSynonymNamer(typeSynonymUseId: string) {
        var ix = this.synonymNamers.map(sn => sn.typeSynonymUseId).indexOf(typeSynonymUseId);
        if (ix != -1) {
            this.synonymNamers[ix].entityAspect.setDeleted();
        }
    }        
    
    public addSynonymNamer(typeSynonymUseId: string) {
        var ix = this.synonymNamers.map(sn => sn.typeSynonymUseId).indexOf(typeSynonymUseId);
        if (ix == -1) {
            let namer= new SynonymNamer();
            namer.synonymId = this.synonymId;
            namer.typeSynonymUseId = typeSynonymUseId;
            this.entityAspect.entityManager.addEntity(namer);
        }
    }
   /// </code>

   // Generated code. Do not place code below this line.
   synonymId: number;
   materialId: number;
   rootWord: string;
   synonymWord: string;
   principal: boolean;
   webVersion: string;
   created: Date;
   createdUser: string;
   modified: Date;
   modifyUser: string;
   rowVersion: number;
   material: Material;
   synonymNamers: SynonymNamer[];
}


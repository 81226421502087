<div class="indent-top-sm"></div>
<div>
    <page-status [attr.data-hidden]='_editing' [pageState]="_pageState"></page-status>
</div>

<default-table *ngIf="_entities && (_entities.length > 0)">
    <thead>
        <tr>
            <td></td>
            <td><a [colSorter]="_colSorter">Kilograms consumed </a></td>
            <td><a [colSorter]="_colSorter">Area </a></td>
        </tr>
    </thead>

    <tbody *ngFor="let group of _groups; let ix=index">
        <tr class="header bold">
            <td>
                <a (click)="collapseGroup(ix)">
                    <span *ngIf="group.collapsed" class="fa fa-caret-down"></span>
                    <span *ngIf="!group.collapsed" class="fa fa-caret-up"></span>
                </a>
            </td>
            <td>{{group.name}}</td>
        </tr>
        <tr class="body " [attr.data-hidden]="group.collapsed" *ngFor="let ent of group.entities ">
            <td></td>
            <td>{{ent.kilogramsConsumed}}</td>
            <td>{{ent.survey?.typeGeographicalArea?.geographicalArea}}</td>
        </tr>
    </tbody>
</default-table>
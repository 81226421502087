<div class="modal" id="license-selector-dialog" role="dialog">
    <div class="modal-dialog  modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                <h3>Software License Agreement for RIFM Members</h3>
                <div class="nav navbar-left navbar-form">
                    <button class="btn btn-primary navbar-btn" type="button" (click)="onAccept(true)" >Accept</button>
                    <button class="btn btn-primary navbar-btn" type="button" (click)="onAccept(false)">Do Not Accept</button>
                </div>
            </div>
            <div class="modal-body">
                <h4>
        PLEASE READ THIS SOFTWARE LICENSE AGREEMENT (AGREEMENT) CAREFULLY BEFORE DOWNLOADING OR USING THE SOFTWARE. BY CLICKING ON
        THE "ACCEPT" BUTTON, OPENING THE PACKAGE, OR DOWNLOADING THE PRODUCT, YOU ARE CONSENTING TO BE BOUND BY THIS AGREEMENT.
        IF YOU DO NOT AGREE TO ALL OF THE TERMS OF THIS AGREEMENT, CLICK THE "DO NOT ACCEPT BUTTON", THE INSTALLATION PROCESS
        WILL NOT CONTINUE AND YOU ARE NOT AUTHORIZED TO USE THE SOFTWARE.
                </h4>

                <div>
                    The Research Institute for Fragrance Materials ("RIFM"), a New York corporation, has compiled a database of toxicology data,
                    literature and general information on fragrance and flavor raw materials (the "RIFM Database"). The RIFM
                    Database is available online to RIFM members under the terms and conditions set forth in this Online
                    Database Subscription Agreement ("Agreement") between RIFM and the undersigned subscriber member ("Subscriber").
                </div>

                <div>
                    <b>Multiple-Users License Grant:</b>   The Research Institute for Fragrance Materials, Inc. ("RIFM")
                    grants to you, the Customer ("Customer"), a nonexclusive, nontransferable license to use the RIFM software
                    ("Software") in executable form: (i) installed in a single location on a hard disk or other storage device
                    for up to 20 employees of the RIFM member company ("Permitted Number of Computers"); or (ii) provided
                    the Software is configured for network use, installed on a single file server for use on a single local
                    area network for either (but not both) of the following purposes: (a) permanent installation onto a hard
                    disk or other storage device of up to the Permitted Number of Computers; or (b) use of the Software over
                    such network, provided the number of computers connected to the server does not exceed the Permitted
                    Number of Computers. Customer shall not: copy, in whole or in part, software or documentation; modify
                    the software; reverse engineer or reverse assemble all or any portion of the software; or rent, lease,
                    distribute, sell, or create derivative works of the software.
                </div>

                <div>
                    <b>DISCLAIMER:</b> THE SOFTWARE IS PROVIDED AS IS AND ALL EXPRESS OR IMPLIED CONDITIONS, REPRESENTATIONS,
                    AND WARRANTIES INCLUDING, WITHOUT LIMITATION, ANY IMPLIED WARRANTY OF MERCHANTABILITY, FITNESS FOR A
                    PARTICULAR PURPOSE, NONINFRINGEMENT OR ARISING FROM A COURSE OF DEALING, USAGE, OR TRADE PRACTICE, ARE
                    HEREBY EXCLUDED TO THE EXTENT ALLOWED BY APPLICABLE LAW. IN NO EVENT WILL RIFM BE LIABLE FOR ANY LOST
                    REVENUE, PROFIT, OR DATA, OR FOR SPECIAL, INDIRECT, CONSEQUENTIAL, INCIDENTAL, OR PUNITIVE DAMAGES HOWEVER
                    CAUSED AND REGARDLESS OF THE THEORY OF LIABILITY ARISING OUT OF THE USE OF OR INABILITY TO USE THE SOFTWARE
                    EVEN IF RIFM HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. IN NO EVENT SHALL RIFM'S OR ARA'S LIABILITY
                    TO CUSTOMER, WHETHER IN CONTRACT, TORT (INCLUDING NEGLIGENCE), OR OTHERWISE, EXCEED THE PRICE PAID BY
                    CUSTOMER.
                </div>

                <div>
                    <b>Termination:</b> This Agreement is effective until terminated. Customer may terminate this License
                    at any time by destroying all copies of Software including any documentation. This License will terminate
                    immediately without notice from RIFM for any reason, including if Customer fails to comply with any provision
                    of this License. Upon termination, Customer must destroy all copies of Software.
                </div>

                <div>
                    <b>Miscellaneous: </b> This Agreement shall be governed by and construed in accordance with the laws
                    of the State of New York, United States of America, as if performed wholly within the state and without
                    giving effect to the principles of conflict of law. If any portion hereof is found to be void or unenforceable,
                    the remaining provisions of this Agreement shall remain in full force and effect. This Agreement constitutes
                    the entire Agreement between the parties with respect to the use of the Software.
                </div>
            </div>
            <div class="modal-footer">
                <div class="nav navbar-left navbar-form">
                    <button class="btn btn-primary navbar-btn" type="button" (click)="onAccept(true)" >Accept</button>
                    <button class="btn btn-primary navbar-btn" type="button" (click)="onAccept(false)">Do Not Accept</button>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="topic-page">
    <span *ngIf="canUseMaterialHeader()">
        <material-header [title]="getPageTitle()" [material]="_stateMap.currentMaterial"></material-header>
    </span>
    <span *ngIf="canUseReferenceHeader()">
        <reference-header [title]="getPageTitle()" [reference]="_stateMap.currentReference"></reference-header>
    </span>
    <button type="button" class="btn btn-primary navbar-right" style="float: right; margin-right: 2em"  (click)="refreshLookups()">Refresh Lookup Tables</button>
    <div *ngIf="canUseDefaultHeader()">
        <div class="row topic-title h1-sm bold inline">{{getPageTitle()}}</div>
    </div>
</div>
<div class="row tab-container-vert ">
    <div class="col-md-1-8 tab-titles  no-print">
        <ul class="nav nav-tabs nav-stacked ">
            <li *ngFor="let r of routes" role="presentation" routerLinkActive="active">
                <a *ngIf="canShow(r) && getPath(r)" [routerLink]="[ getPath(r) ]">
                    <span>{{ r.definition?.title }}</span>
                </a>
                <a *ngIf="canShow(r) && !getPath(r) "><span>{{ r.definition?.title }}</span></a>
            </li>
        </ul>
    </div>
    <div class="col-md-7-8 tab-content ">
        <router-outlet></router-outlet>
    </div>
    <div>
        <!--<modal-dialog></modal-dialog>-->
    </div>
</div>
import {Directive, Input, ViewContainerRef, TemplateRef, ContentChild} from '@angular/core';

@Directive({
  selector: '[tab-pane]'
})
export class TabPane {
  @Input() title: string;
  @Input() hasData: boolean = true;
  @Input() canDeactivate: boolean = true;
  _active: boolean = false;
  
  // constructor(public viewContainer: ViewContainerRef, 
  //             public templateRef: TemplateRef) { }

  constructor(public viewContainer: ViewContainerRef, 
              public templateRef: TemplateRef<any>) { }              

  // add a '#self' attribute to any TabPane to make it available here or within the TabContainer.
  @ContentChild('self') component:any;                
              
  @Input() set active(active: boolean) {
    active = !!active; // insure its a boolean - if coming in from template it can be a string.
    if (active == this._active) return;
    this._active = active;
    if (active) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.remove(0);
    }
  }

  get active(): boolean {
    return this._active;
  }
  

}

<div>
    <page-status [pageState]="_pageState"></page-status>
</div>

<div *ngIf="_pageState.isLoaded" class="indent-left-sm">
    <div class="material-composition-header">
        <span class="composition-title">List of Constituents for</span>
        <span class="composition-title" [htmlGreek]="principalName"></span>
        <br/><br/>
        <p class="composition-title">{{ "Constituent Total Amount: " +  utilFns.formatNumber(_componentAmountTotal, 6, 4) }}</p>
    </div>

    <div style="width: 50%" *ngIf="_materialCompositions && (_materialCompositions.length > 0)">
        <dx-data-grid [dataSource]="_materialCompositions"
                      [showBorders]="true"
                      [allowColumnReordering]="true"
                      [allowColumnResizing]="true">
            <dxo-paging [enabled]="false"></dxo-paging>
            <dxo-sorting mode="multiple"></dxo-sorting>
            <dxo-state-storing [enabled]="true" type="localStorage" storageKey="material-compositions"></dxo-state-storing>

            <dxi-column caption="Constituent CAS No." dataField="materialComponent.material.realCASNumber" cellTemplate="constituent"></dxi-column>
            <div *dxTemplate="let cell of 'constituent'">
                <span *ngIf="cell.data.materialComponent.isMaterial()">
                    <a (click)="goToSynonyms(cell.data.materialComponent.material)">{{ cell.data.materialComponent.material?.realCASNumber }}</a>
                </span>
                <span *ngIf="!cell.data.materialComponent.isMaterial()">
                    {{cell.data.materialComponent.cASNumber}}
                </span>
            </div>

            <dxi-column caption="RIFM ID" dataField="materialComponent.material?formattedRIFMId" cellTemplate="rifmIdTemplate"></dxi-column>
            <div *dxTemplate="let cell of 'rifmIdTemplate'">
                <span *ngIf="cell.data.materialComponent.material">{{ cell.data.materialComponent.material?.formattedRIFMId }}</span>
                <span *ngIf="cell.data.materialComponent.material == null">-</span>
            </div>

            <dxi-column caption="FEMA No." dataField="materialComponent.material.fEMANumber"></dxi-column>
            <dxi-column caption="Constituent Name" dataField="materialComponent.componentName"></dxi-column>

            <dxi-column dataField="componentAmount" caption="Percentage" cellTemplate="percentageTemplate"></dxi-column>
            <div *dxTemplate="let cell of 'percentageTemplate'">
                {{ utilFns.formatNumber(cell.data.componentAmount, 6, 4) }}
            </div>
        </dx-data-grid>
    </div>
</div>

import { Injectable } from '@angular/core';

import { config, EntityManager, EntityQuery, EntityType, MetadataStore, NamingConvention } from 'breeze-client';
// import 'breeze-client/adapters/adapter-data-service-webapi.umd';
// import 'breeze-client/adapters/adapter-model-library-backing-store.umd';
// import 'breeze-client/adapters/adapter-uri-builder-odata.umd';

import { ErrorLogger } from './error-logger';
import { Configuration } from './configuration';
import { _RegistrationHelper } from '../entities/_RegistrationHelper';
import { EntityTypeAnnotation } from '../entities/EntityTypeAnnotation';

@Injectable()
export class EntityManagerProvider {

    _masterManager: EntityManager;
    _uowManager: EntityManager;
    _preparePromise: Promise<any>;

    constructor(public errorLogger: ErrorLogger, private configuration: Configuration) {
        
    }

    // Fetches metadata and lookups - neither of which requires authorization.
    prepare(): Promise<any> {
        if (!this._preparePromise) {
          NamingConvention.camelCase.setAsDefault();
          config.initializeAdapterInstance('dataService', 'webApi');
          config.initializeAdapterInstance('uriBuilder', 'odata');

        
          this._masterManager = new EntityManager(this.configuration.serviceBase);
          this._preparePromise = <Promise<any>><any>this._masterManager.fetchMetadata()
              .then(() => {
                  _RegistrationHelper.register(<MetadataStore><any>this._masterManager.metadataStore);
                  this.registerAnnotations(this._masterManager.metadataStore);

                  return this._masterManager.executeQuery(EntityQuery.from('lookups'))
                      .catch(e => {
                          this.errorLogger.log(e);
                          throw e;
                      });
              })
              .catch(err => {
                  console.log(err);
              });
        }
        return this._preparePromise;
    }


    manager(): EntityManager {
        return this._uowManager || this.newManager();
    }

    newManager(): EntityManager {
        this._uowManager = this._masterManager.createEmptyCopy();
        this._uowManager.importEntities(this._masterManager.exportEntities(null, false));
        this._uowManager.hasChangesChanged.subscribe((...args: any[]) => {

        });
        return this._uowManager;
    }

    registerAnnotations(metadataStore: MetadataStore) {
        metadataStore.getEntityTypes()
            .forEach(t => {
                const et = <EntityType>t;
                const ctor = <any>et.getCtor();
                if (ctor && ctor.getEntityTypeAnnotation) {
                    const etAnnotation = <EntityTypeAnnotation>ctor.getEntityTypeAnnotation();
                    et.validators.push(...etAnnotation.validators);
                    etAnnotation.propertyAnnotations.forEach((pa) => {
                        const prop = et.getProperty(pa.propertyName);
                        prop.validators.push(...pa.validators);
                        // TODO: need to add EntityProperty.displayName to d.ts
                        prop.displayName = pa.displayName;
                    });
                }
            });
    }
}


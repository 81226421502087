import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as _ from 'lodash';

import { StateMap, UnitOfWork, UserManager } from '../../services/common';
import { TypeWorkflowContact, WorkflowRecordedAction } from '../../entities/EntityModels';
import { LabelAndCount } from '../../entities/projections/LabelAndCount';
import { WorkflowActionFilters } from '../../entities/projections/WorkflowActionFilters';
import { WorkflowActivityReportItem } from '../../entities/projections/WorkflowActivityReportItem';
import { WorkflowPendingReportItem } from '../../entities/projections/WorkflowPendingReportItem';
import { UtilFns } from '../../utils/common';
import { ROUTES } from '../routes';
import { STAFF_ROUTES } from './staff.routes';

@Component({
    selector: 'reference-workflow-stats',
    templateUrl: './reference-workflow-stats.html',
})
export class ReferenceWorkflowStatsComponent implements OnInit {
    _filterWorkflowContact: TypeWorkflowContact = null;
    _filterTaskCompletedByContact: TypeWorkflowContact = null;
    _fromDateString: string; // workaround for datepicker flicker
    _toDateString: string;
    _staffContact: TypeWorkflowContact;
    _pendingTasks: WorkflowPendingReportItem[] = [];
    _groups: {};
    _workflowGroups: { name: string, pendingWorkflowRecordedActions: WorkflowPendingReportItem[], collapsed?: boolean }[] = [];
    _pendingTaskCounts: LabelAndCount[];
    _typeWorkflowContacts: TypeWorkflowContact[];
    _typeCompletedByContacts: TypeWorkflowContact[];
    _isLoading = false;
    _hasWorkflowData = false;
    _showFinishDataActions = false;
    _currentFilters: WorkflowActionFilters;
    _filterErrorMsg: string;
    _reportItems: WorkflowActivityReportItem[];

    constructor(private _uow: UnitOfWork, private _stateMap: StateMap, private _userManager: UserManager, private _router: Router) {
    }

    ngOnInit() {

        this._isLoading = true;

        // Initialize the combined filters variable
        this._currentFilters = {
            fromDate: null,
            toDate: null,
            workflowContactId: 0,
            createdContactId: 0,
            createdUser: null,
            actionIds: null
        };

        this._stateMap.currentRouteName = STAFF_ROUTES.RefWorkflowStats.name;

        // Load Staff/Contact information then Workflow data
        // Remote consultants should only see their own work
        this._uow.typeWorkflowContactRepository.all()
            .then(c => {

                this._typeWorkflowContacts = c.filter(s => s.workflowStaff == true);
                this._typeCompletedByContacts = c.filter(s => s.workflowStaff == true);

                this._typeWorkflowContacts = _.sortBy(this._typeWorkflowContacts, x => x.firstName);
                this._typeCompletedByContacts = _.sortBy(this._typeCompletedByContacts, x => x.firstName);

                // Check to see if the current user is a workflow staff and a consultant
                const contact = this._typeWorkflowContacts.filter(x => x.emailAddress.toUpperCase() === this._userManager.currentUser.name.toUpperCase());
                if (contact != null && contact.length > 0) {
                    this._staffContact = contact[0];
                }

                // Default item for all staff records, workflow staff can see any action completed/created by any staff member, as long as they are assigned to the reference.
                const allFilter = new TypeWorkflowContact();
                allFilter.firstName = 'All Staff';
                allFilter.workflowContactId = 0;

                this._typeCompletedByContacts.unshift(allFilter);
                this._filterTaskCompletedByContact = this._typeCompletedByContacts[0];
                this._currentFilters.createdContactId = 0;

                if (this._staffContact != null && this._staffContact.consultant) {  // Then only show Consultants their own assigned tasks

                    this._filterWorkflowContact = this._staffContact;
                    this._currentFilters.workflowContactId = this._filterWorkflowContact.workflowContactId;

                    this.fetchFilteredPendingTaskCounts();

                    this._typeWorkflowContacts = []; // Clear list
                    this._typeWorkflowContacts.push(this._staffContact); // Only show the consultant in the drop down list of staff
                    this._isLoading = false;

                } else {
                    this.fetchPendingTaskCounts();  // All staff assignments
                    this._typeWorkflowContacts.unshift(allFilter);
                    this._filterWorkflowContact = this._typeWorkflowContacts[0];
                    this._currentFilters.workflowContactId = 0;
                }
            });
    }

    private fetchPendingTaskCounts() {

        this._filterErrorMsg = '';
        this._pendingTaskCounts = [];
        this._isLoading = true;
        this._hasWorkflowData = false;
        this._workflowGroups = [];

        this._uow.fetch('Workflow/FetchWorkflowTaskCounts', {}).then(r => {
            this._pendingTaskCounts = r;
            this._isLoading = false;
        });
    }

    private fetchFilteredPendingTaskCounts() {

        this._pendingTaskCounts = [];
        this._isLoading = true;
        this._hasWorkflowData = false;
        this._workflowGroups = [];


        const params = {
            actionIds: (this._currentFilters.actionIds != null && this._currentFilters.actionIds.length > 0) ? this._currentFilters.actionIds : null,
            fromDate: (this._currentFilters.fromDate != null) ? this.formatDateToString(this._currentFilters.fromDate) : null,
            toDate: (this._currentFilters.toDate != null) ? this.formatDateToString(this._currentFilters.toDate) : null,
            workflowContactId: this._currentFilters.workflowContactId,
            createdContactId: this._currentFilters.createdContactId,
            createdUser: this._currentFilters.createdUser
        };

        this._uow.fetch('Workflow/FetchFilteredWorkflowTaskCounts', params).then(r => {
            this._pendingTaskCounts = r;
            this._isLoading = false;
        });
    }

    private fetchFilteredPendingActions() {

        this._filterErrorMsg = '';
        this._workflowGroups = [];

        this._isLoading = true;

        const params = {
            actionIds: (this._currentFilters.actionIds != null && this._currentFilters.actionIds.length > 0) ? this._currentFilters.actionIds : null,
            fromDate: (this._currentFilters.fromDate != null) ? this.formatDateToString(this._currentFilters.fromDate) : null,
            toDate: (this._currentFilters.toDate != null) ? this.formatDateToString(this._currentFilters.toDate) : null,
            workflowContactId: this._currentFilters.workflowContactId,
            createdContactId: this._currentFilters.createdContactId,
            createdUser: this._currentFilters.createdUser
        };

        this._uow.fetch('Workflow/FetchFilteredWorkflowRecordedActions', params).then(r => {
            this._pendingTasks = r;
            if (this._pendingTasks == null || this._pendingTasks.length == 0) {
                this._hasWorkflowData = false;
                this._isLoading = false;
                return;
            }

            this._hasWorkflowData = true;

            const groups = _.groupBy(this._pendingTasks, a => a.toCompleteDescription);

            this._workflowGroups = _.map(groups, (v, k) => {
                return { name: k, pendingWorkflowRecordedActions: v, isCollapsed: false };
            });

            this._workflowGroups = _.sortBy(this._workflowGroups, (g) => g.name);

            this._isLoading = false;
        });
    }

    private workflowGroupCollapse(ix: number) {
        this._workflowGroups[ix].collapsed = !this._workflowGroups[ix].collapsed;
    }

    public get utilFns() {
        return UtilFns;
    }

    public isLoading(): boolean {
        return (this._isLoading);
    }

    public get stillLoadingMsg(): string {
        return 'The Workflow data is still loading.';
    }

    public showEmptyResultsMessage(): boolean {

        if (this.isLoading()) {
            return false;
        }

        return (!this._hasWorkflowData);
    }

    public isStaff() {
        return this._stateMap.currentUser.isStaff;
    }

    public canViewStaffReport() {
        return (this._staffContact != null && this._staffContact.consultant == false);
    }

    public get filterName() {
        if (this._filterWorkflowContact != null) {
            return this._filterWorkflowContact.firstName;
        } else {
            return null;
        }
    }

    public get filterCompletedByName() {
        if (this._filterTaskCompletedByContact != null) {
            return this._filterTaskCompletedByContact.firstName;
        } else {
            return null;
        }
    }

    public get fromDate(): string {
        return this._fromDateString;
    }

    public set fromDate(value: string) {
        this._fromDateString = value;
    }

    public get toDate(): string {
        return this._toDateString;
    }

    public set toDate(value: string) {
        this._toDateString = value;
    }

    public filterContactChange(event: any) {
        const ix = event.currentTarget.selectedIndex;
        this._filterWorkflowContact = this._typeWorkflowContacts[ix];
    }

    public filterCompletedByContactChange(event: any) {
        const ix = event.currentTarget.selectedIndex;
        this._filterTaskCompletedByContact = this._typeCompletedByContacts[ix];
    }

    public onDisplayAction(index: number) {
        const selectedActions = this._pendingTaskCounts.filter(a => a.selected == true);

        if (selectedActions == null || selectedActions.length == 0) {
            this._hasWorkflowData = false;
            return;
        }

        this._currentFilters.actionIds = selectedActions.map(function (a) {
            return a.id;
        });
        this.fetchFilteredPendingActions();
    }

    public showFinishActionsFilter() {
        return (this._filterWorkflowContact == null || this._filterWorkflowContact.firstName == 'All Staff');
    }

    filterResults() {

        this._currentFilters.workflowContactId = this._filterWorkflowContact.workflowContactId;

        this._currentFilters.createdContactId = this._filterTaskCompletedByContact.workflowContactId;

        if (this._fromDateString) {
            // UTC conversion issue resolution
            const dateValue = this._fromDateString.toString().replace(/-/, '/').replace(/-/, '/');
            this._currentFilters.fromDate = new Date(dateValue);
        } else {
            this._currentFilters.fromDate = null;
        }

        if (this._toDateString) {
            // UTC conversion issue resolution
            const dateValue = this._toDateString.toString().replace(/-/, '/').replace(/-/, '/');
            this._currentFilters.toDate = new Date(dateValue);
        } else {
            this._currentFilters.toDate = null;
        }

        if (this.anyFilters() == false) {
            this._filterErrorMsg = 'No filters have been set yet!';
            return;
        }

        this.fetchFilteredPendingTaskCounts();
    }

    filterClear() {
        this.resetFilterBindings();
        this.resetFilterProperties();

        // Reload summary data
        if (this._currentFilters.workflowContactId > 0) {   // The only filter not cleared is the one where consultant staff can only view their own reference assignments
            this.fetchFilteredPendingTaskCounts();
        } else {
            this.fetchPendingTaskCounts();
        }
    }

    public expandAll(expand: boolean) {
        setTimeout(() => {
            this._workflowGroups.forEach(g => g.collapsed = !expand);
        }, 0);
    }

    private formatDateToString(objDate: Date): string {
        return ((objDate.getMonth() + 1) + '/' + objDate.getDate() + '/' + objDate.getFullYear());
    }

    private resetFilterProperties() {
        const contactId = (this._staffContact != null && this._staffContact.consultant) ? this._staffContact.workflowContactId : 0;
        this._currentFilters = {
            fromDate: null,
            toDate: null,
            workflowContactId: contactId,
            createdContactId: 0,
            createdUser: null,
            actionIds: null
        };
    }

    private resetFilterBindings() {
        this._filterWorkflowContact = this._typeWorkflowContacts[0];
        this._filterTaskCompletedByContact = this._typeCompletedByContacts[0];
        this.fromDate = null;
        this.toDate = null;
    }

    private anyFilters(): boolean {

        if (this._currentFilters.actionIds != null) {
            return true;
        }

        if (this._currentFilters.createdContactId != 0) {
            return true;
        }

        if (this._currentFilters.createdUser != null) {
            return true;
        }

        if (this._currentFilters.fromDate != null) {
            return true;
        }

        if (this._currentFilters.toDate != null) {
            return true;
        }

        if (this._currentFilters.workflowContactId != 0) {
            return true;
        }

        return false;
    }

    private navToRef(assigned: WorkflowPendingReportItem) {
        this._router.navigate(UtilFns.asRouterParts(ROUTES.Reference, assigned.referenceId));
    }

    showSearchServiceReport() {
        const url = this._userManager.getSearchServiceReportUrl();
        window.open(url, '_self');
        return false;
    }

    showWorkflowActivityReport() {

        this._isLoading = true;

        if (this.anyReportFilters()) {
            this.fetchFilteredWorkflowActivityReport();
        } else {
            this.fetchWorkflowActivityReport();
        }

        this._isLoading = false;
    }

    anyReportFilters(): boolean {

        if (this._fromDateString) {
            return true;
        }

        if (this._toDateString) {
            return true;
        }

        if (this._filterTaskCompletedByContact.workflowContactId != 0) {
            return true;
        }

        return false;
    }

    fetchWorkflowActivityReport() {

        return this._uow.fetch('Workflow/FetchWorkflowActivitySummaryReport', {}).then(r => {
            this._reportItems = r;
            if (this._reportItems != null && this._reportItems.length > 0) {
                this._isLoading = false;
                setTimeout(() => {
                    const ele = $('#WorkflowActivityReport');
                    (<any>ele).modal('show');
                }, 0);
                return;
            }
        });

    }

    fetchFilteredWorkflowActivityReport() {

        let filterFromDate: string = null;
        let filterToDate: string = null;

        if (this._fromDateString) {
            filterFromDate = this._fromDateString.toString();
        }

        if (this._toDateString) {
            filterToDate = this._toDateString.toString();
        }

        const params = {
            workflowContactId: this._filterTaskCompletedByContact.workflowContactId,
            fromDate: filterFromDate,
            toDate: filterToDate
        };

        return this._uow.fetch('Workflow/FetchFilteredWorkflowActivitySummaryReport', params).then(r => {
            this._reportItems = r;
            if (this._reportItems != null && this._reportItems.length > 0) {
                this._isLoading = false;
                setTimeout(() => {
                    const ele = $('#WorkflowActivityReport');
                    (<any>ele).modal('show');
                }, 0);
                return;
            }
        });
    }
}

import { Component, Input, OnInit } from '@angular/core';

import { provideParent, UnitOfWork } from '../../services/common';
import { EditPropParent } from '../../controls/common';
import { UtilFns } from '../../utils/common';

import { Synonym, TypeSynonymUse } from '../../entities/EntityModels';
import { MaterialListItem } from '../../entities/projections/MaterialListItem';

@Component({
    selector: 'synonym',
    templateUrl: './synonym.html',
    providers: [provideParent(SynonymComponent, EditPropParent)]
})
export class SynonymComponent implements OnInit, EditPropParent {
    @Input() synonym: Synonym;
    @Input() entities: Synonym[];

    allTsus: TypeSynonymUse[];
    tsuChecks: boolean[];
    origTsuChecks: boolean[];
    _synonymChanged: boolean;
    _synonymCollisionWarning: string;
    originalValue: string;

    constructor(public _uow: UnitOfWork) {

    }

    ngOnInit() {
        this._synonymChanged = false;

        this._uow.typeSynonymUseRepository.all().then(tsus => {
            this.allTsus = tsus.sort((a, b) => {
                if (a.synonymUseLong > b.synonymUseLong) {
                    return 1;
                } else {
                    return -1;
                }
            });

            const longNames = this.synonym.useLongNames;
            this.tsuChecks = tsus.map(tsu => longNames.indexOf(tsu.synonymUseLong) >= 0);
            this.origTsuChecks = this.tsuChecks.map(b => b);
        });
    }

    getError(propName: string) {
        return this.synonym.getErrorFor(propName);
    }

    setRootWord() {
        this.synonym.rootWord = Synonym.rootWordFromSynonymWord(this.synonym.synonymWord);
    }

    validateSynonymWord() {
        const synonymWord = this.synonym.synonymWord;
        this._uow.fetch('Materials/MaterialsBySynonym', {synonymWord: UtilFns.encodeHackForAnd(synonymWord)}).then(r => {
            if (this.synonym.material.synonyms.filter(s => s.synonymWord == synonymWord).length > 1) {
                this._synonymCollisionWarning = 'Just a warning but this Synonym has already been added to this material.';
                return;
            }
            const mlis = <MaterialListItem[]>r;
            // if no match
            if (mlis.length == 0 || (mlis.length == 1 && mlis[0].materialId == this.synonym.materialId)) {
                this._synonymCollisionWarning = null;
                return;
            }
            this._synonymCollisionWarning = 'Just a warning but this Synonym has already been used elsewhere.';
        });
    }

    onSetModified() {
        if (this.synonym.entityAspect.entityState.isUnchanged()) {
            this.synonym.entityAspect.setModified();
        }
    }

    setPrincipalName() {
        if (this.synonym && this.synonym.principal) {
            if (this.entities) {
                this.entities.filter(s => s.synonymId != this.synonym.synonymId).forEach(s => s.principal = false);
            }
        }
    }

    apply() {
        if (this.tsuHasChanged()) {
            this.tsuChecks.forEach((tsu, ix) => {
                const tsuId = this.allTsus[ix].typeSynonymUseId;
                if (tsu) {
                    this.synonym.addSynonymNamer(tsuId);
                } else {
                    this.synonym.removeSynonymNamer(tsuId);
                }
            });
        }

        // Only synonyms that have terms to be displayed as symbols will have web versions stored.
        const webVersion = this.synonym.formatSynonymWebVersion();
        this.synonym.webVersion = (webVersion == this.synonym.synonymWord) ? null : webVersion;
    }

    tsuHasChanged() {
        return this.tsuChecks.some((tsu, ix) => tsu !== this.origTsuChecks[ix]);
    }

    onSynonymWordChange(originalSynonymWord: string) {
        if (originalSynonymWord != this.synonym.synonymWord) {
            this._synonymChanged = true;
            this.validateSynonymWord();
            this.setRootWord();
        }
    }
}

import { EntityBase } from './EntityBase';
import { TypeAlertRule } from './TypeAlertRule';

import { Validator } from 'breeze-client';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

export class MaterialAlertRule extends EntityBase {

   /// <code> Place custom code between <code> tags
   
   /// </code>

   // Generated code. Do not place code below this line.
   materialId: number;
   rule: number;
   created: Date;
   createdUser: string;
   modified: Date;
   modifyUser: string;
   rowVersion: number;
   typeAlertRule: TypeAlertRule;
}


import { EntityBase } from './EntityBase';

import { Validator } from 'breeze-client';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

export class ExposureAdditionalMaterial extends EntityBase {

   /// <code> Place custom code between <code> tags
   
   /// </code>

   // Generated code. Do not place code below this line.
   exposureAdditionalMaterialId: number;
   cASNumber: string;
   materialName: string;
   memberCompany: string;
   purpose: string;
   notes: string;
   created: Date;
   createdUser: string;
   modified: Date;
   modifyUser: string;
   rowVersion: number;
}


import { Component, Input, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';

import { StateMap, UnitOfWork, UserManager } from '../../services/common';
import { UtilFns } from '../../utils/common';
import { PageState } from '../../controls/common';
import { ROUTES } from '../routes';
import { Material, MaterialComposition } from '../../entities/EntityModels';

@Component({
    selector: 'material-composition',
    templateUrl: './material-composition.html',
})
export class MaterialCompositionComponent implements OnInit {

    @Input() materialId: number;
    @Input() principalName: string;

    _pageState = new PageState('Constituent');

    _materialCompositions: MaterialComposition[];
    _componentAmountTotal: number;

    constructor(private _uow: UnitOfWork, private _stateMap: StateMap, private _userManager: UserManager, private _router: Router, public _location: Location) {
    }

    ngOnInit() {

        this._componentAmountTotal = 0;

        this._pageState.isLoading = true;

        const pMatId = {materialId: this.materialId};

        this._uow.fetch('Materials/MaterialCompositions', pMatId).then(r => {
            if (r != null && r.length > 0) {
                this._materialCompositions = r;
                this._componentAmountTotal = this._materialCompositions.map(a => a.componentAmount).reduce((a, b) => a + b);
                this._pageState.isLoaded = true;
            } else {
                this._pageState.isLoaded = false;
            }
        });
    }

    goToSynonyms(m: Material) {
        if (m != null) {
            this._router.navigate(UtilFns.asRouterParts(ROUTES.Material, m.materialId));
        }
    }

    public get utilFns() {
        return UtilFns;
    }
}

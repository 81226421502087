<page-status [pageState]="_pageState" ></page-status>

<div *ngIf="_epiGroups == null || _epiGroups.length == 0" style="margin-top: 8px;">
    <span class="bold inline page-subtitle">No BIOWIN Data Available</span>
</div>

<!--Cant use <default-table> here because of ngFor in <tbody>-->
<div *ngIf="_epiGroups && _epiGroups.length > 0" class="outermost table-responsive" style="margin-top: 4px;">
    <div class="inline" style="background-color: lightgray; height: 28px; width: 639px; vertical-align: middle;">
        <a (click)="setBIOWINCollapse()" class="inline" style="margin-left: 4px;">
            <i *ngIf="_biowinCollapsed" class="fa fa-caret-down inline" style="color: #337ab7; font-size:32px; vertical-align: middle;"></i>
            <i *ngIf="!_biowinCollapsed" class="fa fa-caret-up inline" style="color: #337ab7; font-size:32px; vertical-align: middle;"></i>
            <span class="inline" style="color: black !important; font-size: 18px; margin-left: 4px; vertical-align: middle;">BIOWIN Data</span>
        </a>
    </div>

    <div *ngIf="!_biowinCollapsed">
        <table class="table table-striped table-hover table-bordered table-condensed small" style="margin-top: 6px; width: auto;">
            <thead>
            <tr>
                <td></td>
                <td><a [colSorter]="_colSorter">Parameter </a></td>
                <td><a [colSorter]="_colSorter">Value </a></td>
            </tr>
            </thead>
            <tbody *ngFor="let group of _epiGroups; let ix=index" >
            <tr class="header bold">
                <td>
                    <a (click)="collapseGroup(ix)">
                        <span *ngIf="group.collapsed" class="fa fa-caret-down"></span>
                        <span *ngIf="!group.collapsed" class="fa fa-caret-up"></span>
                    </a>
                </td>
                <td>{{group.name}}</td>
            </tr>
            <tr class="body " [attr.data-hidden]="group.collapsed" *ngFor="let epiSuite of group.epiSuites ">
                <td></td>
                <td style="width: 320px;">{{epiSuite.typeEpiSuiteParameter.ePISuiteParameter}}</td>
                <td style="width: 300px;">{{epiSuite.ePIValue}}</td>
            </tr>
            </tbody>
        </table>
    </div>
</div>

<div *ngIf="_ecoSARToxicEffects == null || _ecoSARToxicEffects.length == 0" style="margin-top: 8px;">
    <span class="bold inline page-subtitle">No ECOSAR Toxic Effect Data Available</span>
</div>

<div *ngIf="_ecoSARToxicEffects != null && _ecoSARToxicEffects.length > 0" style="padding-left: 0px !Important; margin-top: 4px;">
    <div class="inline" style="background-color: lightgray; height: 28px; width: 1228px; vertical-align: middle;">
        <a (click)="setECOSARCollapse()" class="inline" style="margin-left: 4px;">
            <i *ngIf="_ecoSARCollapsed" class="fa fa-caret-down inline" style="color: #337ab7; font-size:32px; vertical-align: middle;"></i>
            <i *ngIf="!_ecoSARCollapsed" class="fa fa-caret-up inline" style="color: #337ab7; font-size:32px; vertical-align: middle;"></i>
            <span class="inline" style="color: black !important; font-size: 18px; margin-left: 4px; vertical-align: middle;">ECOSAR Toxic Effect Data</span>
        </a>
    </div>

    <div *ngIf="!_ecoSARCollapsed">
            <span class="inline">
                <span class="workflow-asterisk inline vertical-top">*</span>
	            <span class="inline italic vertical-middle" style="margin-top: 1px;">(asterisk) indicates Chemical may not be soluble enough to measure the predicted effect. If the effect level exceeds the water solubility by 10X, typically no effects at saturation (NES) are reported.</span>
            </span>
        <default-table class="inline" style="clear:both !important; padding: 0px !important; margin-top: -4px">
            <thead>
            <tr style="white-space: nowrap; width: 1520px;">
                <td>Fish, 96-hr, LC50, Class</td>
                <td>Fish, 96-hr, LC50, mg/L (ppm)</td>
                <td>Daphnid, 48-hr, LC50, Class</td>
                <td>Daphnid, 48-hr, LC50, mg/L (ppm)</td>
                <td>Green Algae, 96-hr, EC50, Class</td>
                <td>Green Algae, 96-hr, EC50, mg/L (ppm)</td>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let sar of _ecoSARToxicEffects">
                <td style="text-align: center;">{{sar.fish_96hr_LC50_Class}}</td>
                <td style="text-align: center;">{{sar.fish_96hr_LC50_mg_L_ppm}}</td>
                <td style="text-align: center;">{{sar.daphnid_48hr_LC50_Class}}</td>
                <td style="text-align: center;">{{sar.daphnid_48hr_LC50_mg_L_ppm}}</td>
                <td style="text-align: center;">{{sar.greenAlgae_96hr_EC50_Class}}</td>
                <td style="text-align: center;">{{sar.greenAlgae_96hr_EC50_mg_L_ppm}}</td>
            </tr>
            </tbody>
        </default-table>
    </div>
</div>

<div class="modal" id="survey-whousedmost-dialog" role="dialog">
	<div class="modal-dialog modal-lg">
		<div class="modal-content">
			<div class="modal-header">
				<button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
				<div class="nav navbar-left navbar-form">
					<label class="bold input-lg">Survey Use by Material Showing Use Totals by Company</label>
					<button class="btn btn-primary navbar-btn" type="button" (click)="onSelect(null)">Close</button>
				</div>
			</div>
			<div class="modal-body">
				<div>
					<i *ngIf="_pageState.isLoading" class="fa fa-circle-o-notch fa-spin"
					   style="font-size:28px !important; color: #337ab7 !important; margin-left: 24px !important;"></i>
					<!--<page-status [pageState]="_pageState"></page-status>-->
				</div>
				<div class="companyuses-title"><span class="bold">{{'Reported Use for Material ' + displayCAS + ', Ordered from Most to Least.'}}</span></div>
				<div class="companyuses-container" *ngIf="hasData()">
					<!--
					<dx-data-grid dataSource="_entities"
					              [showBorders]="true"
					              [allowColumnResizing]="true"
					              [allowColumnReordering]="true">

						<dxo-paging [enabled]="false"></dxo-paging>
						<dxo-sorting mode="multiple"></dxo-sorting>
						<dxo-state-storing [enabled]="true" type="localStorage" storageKey="companyuses"></dxo-state-storing>

						<dxi-column caption="Survey Year" dataField="surveyYear"></dxi-column>
						<dxi-column caption="Organization Name" dataField="organizationName"></dxi-column>

						<dxi-column caption="Use Kgs" dataField="materialUseKgs" cellTemplate="materialUseKgs"></dxi-column>
						<div *dxTemplate="let cell of 'materialUseKgs'">
							{{utilFns.formatNumber(cell.data.materialUseKgs, 12, 3)}}
						</div>
					</dx-data-grid>
					-->
					<table class="table table-striped table-bordered" *ngIf="hasData()">
						<thead>
						<tr>
							<td>Survey Year</td>
							<td>Organization Name</td>
							<td>Use Kgs</td>
						</tr>
						</thead>
						<tbody>
						<tr *ngFor="let use of _entities">
							<td>{{use.surveyYear}}</td>
							<td>{{use.organizationName}}</td>
							<td>{{utilFns.formatNumber(use.materialUseKgs, 12, 3)}}</td>
						</tr>
						</tbody>
					</table>
				</div>
				<div *ngIf="validationMessage" style="margin-top: 12px;">
					<p class="alert-danger">{{validationMessage}}</p>
				</div>

				<p class="page-subtitle bold" style="margin-top: 12px;">{{_userMessage}}</p>

			</div>
		</div>
	</div>
</div>
